import { CREATE_ID } from "@constants";
import { useRef, useState } from "react";
import { useResolve } from "@movicoders/di";
import { cifHelper } from "@utils/cif-helper";
import { useTranslation } from "react-i18next";
import { CompanyDTO } from "@clients/aggrego-proxy";
import { CompanyCreateDTO } from "../company-create-model";
import { LastPageState } from "@domain/model/LastPageState";
import { useHandleImage } from "../../../hooks/useHandleImage";
import { useListDetailViewModel, useSnackbar } from "@movicoders/ui";
import { CompanyRepository } from "@infrastructure/repositories/company-repository";
import { ILastPageService, LastPageService } from "@domain/services/ILastPageService";
import { isPasswordValid } from "@components/password/invalid-password-tooltip/InvalidPasswordTooltip";

export const emailRegexp = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-_]+\.[A-Za-z0-9]{2,}$/i;

interface IStepper {
  onClose: () => void;
}

export const useStepper = (props: IStepper) => {
  const { onClose } = props;

  const { t } = useTranslation();
  const { show } = useSnackbar();
  const { handleUploadImage } = useHandleImage();

  const [activeStep, setActiveStep] = useState(0);
  const steps = [t("stepper-company-1"), t("stepper-company-2"), t("stepper-company-3"), t("stepper-company-4")];

  const { save, goToList, getPaginated } = useListDetailViewModel(CompanyRepository);

  const getCompaniesPaginated = useRef(getPaginated).current;

  const service = useResolve<ILastPageService>(LastPageService);
  const persistedState = service.persistedState ?? new LastPageState();

  const getBannedWordsForPassword = (company: CompanyCreateDTO) => {
    const bannedWords: string[] = [];
    if (company?.email) {
      const emailName = company.email.split('@')[0];
      if (emailName) bannedWords.push(emailName);
    }
    return bannedWords;
  }

  const handleValidationStepper = (company: CompanyCreateDTO) => {
    const emailValid =
      (company.email !== null && company.email !== undefined && company.email.trim() !== "" && emailRegexp.test(company.email)) ||
      company.email?.length === 0;
    let isButtonDisabled = true;
    const isValidStep0 =
      company.image !== undefined &&
      company.image !== "" &&
      company.companyName !== "" &&
      company.address?.trim() !== "" &&
      company.address &&
      company.fiscalId &&
      company.fiscalId !== "" &&
      cifHelper(company.fiscalId);
    const isValidStep1 =
      company.firstName !== "" &&
      company.surname !== "" &&
      company.email !== "" &&
      company.username !== "" &&
      company.password !== "" && isPasswordValid(company.password ?? "", getBannedWordsForPassword(company)) &&
      emailValid;
    const isValidStep2 = company.licence && company.warehousePolicy;

    if (activeStep === 0) {
      if (isValidStep0) isButtonDisabled = false;
    }

    if (activeStep === 1) {
      if (isValidStep1) isButtonDisabled = false;
    }

    if (activeStep === 2) {
      if (isValidStep2) isButtonDisabled = false;
    }

    if (activeStep === 3) {
      isButtonDisabled = false;
    }

    return isButtonDisabled;
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleNext = () => {
    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleCreateCompany = (company: CompanyCreateDTO) => {
    company.id = CREATE_ID;
    company.tenantName = company.companyName;
    company.address = company.address?.replaceAll("±", " ");
    save(company)
      .then(() => {
        (company as CompanyDTO).id === CREATE_ID
          ? show(
            t(`company.created.success`, {
              company: company.companyName ? `"${company.companyName}" ` : ""
            }),
            "success"
          )
          : show(
            t(`company.saved.success`, {
              company: company.companyName ? `"${company.companyName}" ` : ""
            }),
            "success"
          );
      })
      .catch(error => {
        (company as CompanyDTO).id === CREATE_ID
          ? show(
            t(`creating.${error}${company.companyName ? "" : ".undefined"}`, {
              value: company.companyName ? `"${company.companyName}"` : "",
              mdNameA: t("a.company"),
              mdNameThe: t("the.company"),
              reason: t("not.found.company"),
              mdNameNew: t("a.new.company")
            }),
            "error"
          )
          : show(
            t(`saving.${error}${company.companyName ? "" : ".undefined"}`, {
              value: company.companyName ? `"${company.companyName}"` : "",
              mdNameA: t("a.company"),
              mdNameThe: t("the.company"),
              reason: t("not.found.company")
            }),
            "error"
          );
      })
      .finally(() => {
        onClose();
        getCompaniesPaginated({
          xTenantId: "",
          limit: persistedState.limit,
          offset: persistedState.offset,
          companyFilterDTO: persistedState.filters
        });
      });
  };
  const handleEnd = () => goToList();

  const handleActionButton = async (company: CompanyCreateDTO) => {
    if (activeStep === 3) {
      const image = company.image ?? "";
      const imageId = await handleUploadImage(image as unknown as Blob);
      company.image = imageId ?? "";
      handleCreateCompany(company);
      handleEnd();
    } else {
      return handleNext();
    }
  };

  const buttonTranslation = () => {
    if (activeStep === 3) {
      return "stepper-button-create";
    } else {
      return "stepper-button-next";
    }
  };

  return {
    steps,
    activeStep,
    handleBack,
    handleStep,
    handleActionButton,
    handleValidationStepper,
    buttonTranslation,
    getBannedWordsForPassword
  };
};
