/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TileTemplateDTO
 */
export interface TileTemplateDTO {
    /**
     * 
     * @type {boolean}
     * @memberof TileTemplateDTO
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TileTemplateDTO
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof TileTemplateDTO
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof TileTemplateDTO
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TileTemplateDTO
     */
    editable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TileTemplateDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TileTemplateDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TileTemplateDTO
     */
    type?: TileTemplateDTOTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TileTemplateDTO
     */
    walkable?: boolean;
}


/**
 * @export
 */
export const TileTemplateDTOTypeEnum = {
    Corridor: 'CORRIDOR',
    Storage: 'STORAGE',
    Wall: 'WALL'
} as const;
export type TileTemplateDTOTypeEnum = typeof TileTemplateDTOTypeEnum[keyof typeof TileTemplateDTOTypeEnum];


/**
 * Check if a given object implements the TileTemplateDTO interface.
 */
export function instanceOfTileTemplateDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TileTemplateDTOFromJSON(json: any): TileTemplateDTO {
    return TileTemplateDTOFromJSONTyped(json, false);
}

export function TileTemplateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TileTemplateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'editable': !exists(json, 'editable') ? undefined : json['editable'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'walkable': !exists(json, 'walkable') ? undefined : json['walkable'],
    };
}

export function TileTemplateDTOToJSON(value?: TileTemplateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'color': value.color,
        'companyId': value.companyId,
        'description': value.description,
        'editable': value.editable,
        'id': value.id,
        'name': value.name,
        'type': value.type,
        'walkable': value.walkable,
    };
}

