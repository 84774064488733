import { TIMEOUT_ID } from "@constants";
import { User } from "@domain/model/User";
import { UUIDGenerator } from "@utils/uuid-generator";
import { LastPageState } from "@domain/model/LastPageState";
import MasterDataConstants from "../../../MasterDataConstants";
import { useResolve } from "@movicoders/di/lib/Container/useResolve";
import { useMasterDataMessagges } from "@hooks/useMasterDataMessages";
import { GetUsersByTenantPaginatedRequest, ResponseError } from "@clients/aggrego-proxy";
import { IUserService, UserService } from "@domain/services/IUserService";
import { IImpersonationService } from "@domain/services/IImpersonationService";
import { handleError } from "@utils/error-helper";

export const useUserSave = (
  currentPagePersistedState: LastPageState,
  selectedUser: User,
  impersonationService: IImpersonationService,
  fetchOne: (id: string) => Promise<void>,
  save: (entity: User) => Promise<void>,
  goToList: (criteria: GetUsersByTenantPaginatedRequest) => Promise<void>,
  getCurrentStatus: (status: "ACTIVE" | "INACTIVE" | "ALL") => boolean | undefined
) => {
  const userService = useResolve<IUserService>(UserService);
  const { successMessaggesSaving, errorMessaggesSaving, errorMessaggesLoading } = useMasterDataMessagges();

  const setNewUserAttributes = (user: User): User => {
    user.tenantId = userService.current()?.tenantId ?? "";
    user.validated = "ACCEPTED";
    user.firstName = user.firstName ?? "";
    user.firstSurname = user.firstSurname ?? "";
    user.secondSurname = user.secondSurname ?? "";
    user.token = "";
    user.preferences = "";
    user.credentialsId = UUIDGenerator();
    user.profileId = UUIDGenerator();
    return user;
  };

  const handleEdit = (id: string) => {
    fetchOne(id).catch(() => {
      errorMessaggesLoading("user", selectedUser.username);
      selectedUser.id = TIMEOUT_ID;
    });
  };

  /**
   * Handles the saving of the user
   * If the user id equals to "-1" sets the user active property to true
   * @param user The user to be saved
   */
  const handleSave = (user: User): void => {
    if (!user.credentialsId) {
      setNewUserAttributes(user);
    }

    save(user)
      .then(() => successMessaggesSaving("user", user.username, user.id))
      .catch(async error => {
        const response = await handleError(error as ResponseError);
        if (response?.status === 400 && response.errors && response.errors[0].field === "username") {
          const errorDescription =
            response.errors[0].message?.toLowerCase().replaceAll(" ", ".").split(">")[2] ?? "unknown error";
          errorMessaggesSaving("", "users", "", "user.error." + errorDescription);
        } else {
          errorMessaggesSaving("user", user.username, user.id, error);
        }
      })
      .finally(() => {
        goToList({
          limit: MasterDataConstants.ITEMS_PER_PAGE,
          offset: 0,
          filterDTO: {
            status: getCurrentStatus(currentPagePersistedState.status),
            ...currentPagePersistedState.filters
          },
          xTenantId: impersonationService.persistedState?.impersonatedTenant ?? ""
        });
      });
  };

  return {
    handleEdit,
    handleSave
  };
};
