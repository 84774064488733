import { ContainerApi } from "../api/container-api";
import { ListMovementEventPageRequest, MovementEventPageDTO } from "@clients/aggrego-proxy";

export class MovementsRepository {
  constructor(private readonly movementsApi: ContainerApi) {}

  getPaginated(pageCriteria: ListMovementEventPageRequest): Promise<MovementEventPageDTO> {
    return this.movementsApi.getMovementsPaginated(pageCriteria);
  }
}
