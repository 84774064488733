import {
  GetParentsByBatchAndMaterialCodeRequest,
  GetParentsBySerialNumberAndMaterialCodeRequest,
  GetProductByBatchAndMaterialCodeRequest,
  GetProductBySerialNumberRequest,
  GetProductHierarchyByBatchAndMaterialCodeRequest,
  ListProductsPaginatedRequest,
  ProductPageDTO
} from "@clients/aggrego-proxy";
import { ProductApi } from "../api/product-api";
import { Product } from "@domain/model/Product";
import { DomainPage, IRepository } from "@movicoders/domain";

export class ProductRepository implements IRepository<Product, Product, ListProductsPaginatedRequest> {
  constructor(private readonly productApi: ProductApi) {}

  getPaginated(pageCriteria: ListProductsPaginatedRequest): Promise<DomainPage<Product>> {
    return this.productApi.getPaginated(pageCriteria);
  }

  getBySerialNumber(criteria: GetProductBySerialNumberRequest): Promise<DomainPage<Product>> {
    return this.productApi.getBySerialNumber(criteria);
  }

  getByBatchAndMaterialCode(criteria: GetProductByBatchAndMaterialCodeRequest): Promise<DomainPage<Product>> {
    return this.productApi.getByBatchAndMaterialCode(criteria);
  }

  getById(id: string): Promise<Product> {
    return this.productApi.getById(id);
  }

  getParentBySerialNumberAndMaterialCode(criteria: GetParentsBySerialNumberAndMaterialCodeRequest): Promise<ProductPageDTO> {
    return this.productApi.getParentBySerialNumberAndMaterialCode(criteria);
  }

  getParentByBatchAndMaterialCode(criteria: GetParentsByBatchAndMaterialCodeRequest): Promise<ProductPageDTO> {
    return this.productApi.getParentByBatchAndMaterialCode(criteria);
  }

  getProductHierarchyByBatchAndMaterialCode(criteria: GetProductHierarchyByBatchAndMaterialCodeRequest): Promise<ProductPageDTO> {
    return this.productApi.getProductHierarchyByBatchAndMaterialCode(criteria);
  }

  get(): Promise<Product[]> {
    throw new Error("Method not implemented.");
  }

  create(entity: Product): Promise<Product> {
    throw new Error("Method not implemented.");
  }
  update(entity: Product): Promise<Product> {
    throw new Error("Method not implemented.");
  }
  remove(id: string): Promise<void> {
    throw new Error("Method not implemented.");
  }
}
