import {
  AdjustmentMaterialStockRequest,
  ConfigDailyStockReportRequest,
  DailyStockReportResponseDTO,
  GetLastConfigDailyStockReportRequest,
  GetLatestStockReportRequest,
  GetStockReportRequest,
  ListResponseDTO,
  StockReportDTO
} from "@clients/aggrego-proxy";
import { ReportApi } from "../api/report-api";
import { StockDTO } from "@domain/model/Stock";
import { DomainPage, IRepository } from "@movicoders/domain";

export class StockRepository implements IRepository<StockDTO, null, GetLatestStockReportRequest> {
  constructor(private readonly reportApi: ReportApi) {}
  get(criteria?: null | undefined): Promise<StockDTO[]> {
    throw new Error("Method not implemented.");
  }
  getById(id: string): Promise<StockDTO> {
    throw new Error("Method not implemented.");
  }
  create(entity: StockDTO): Promise<StockDTO> {
    throw new Error("Method not implemented.");
  }
  update(entity: StockDTO): Promise<StockDTO> {
    throw new Error("Method not implemented.");
  }
  remove(id: string): Promise<void> {
    throw new Error("Method not implemented.");
  }

  getPaginated(pageCriteria: GetLatestStockReportRequest): Promise<DomainPage<StockDTO>> {
    return this.reportApi.getStockPaginated(pageCriteria);
  }

  async getByMaterial(pageCriteria: GetStockReportRequest): Promise<StockReportDTO> {
    const result = this.reportApi.getStockByMaterial(pageCriteria);
    return result;
  }

  async setConfigDailyStock(pageCriteria: ConfigDailyStockReportRequest): Promise<DailyStockReportResponseDTO> {
    const result = this.reportApi.setConfigDailyStockReport(pageCriteria);
    return result;
  }

  async getLastConfigDailyStock(pageCriteria: GetLastConfigDailyStockReportRequest): Promise<DailyStockReportResponseDTO> {
    return this.reportApi.getLastConfigDailyStockReport(pageCriteria);
  }

  async getAdjustedMaterialStock(pageCriteria: AdjustmentMaterialStockRequest): Promise<ListResponseDTO> {
    return this.reportApi.getAdjustedMaterialStock(pageCriteria);
  }
}
