import { Grid } from "@mui/material";
import FamilyType from "@domain/model/FamilyType";
import TypeOfFamilyCard from "./type-of-families-card";
import { useResponsiveGrid } from "@hooks/useResponsiveGrid";
import { DomainPage } from "@movicoders/domain/lib/Entities/DomainPage";
import { NoRecordsFound } from "@components/no-records-found/no-records-found";

interface ICardsDisplay {
  dataPaginated: DomainPage<FamilyType>;
  handleOpenUpsertDialog: (id: string) => void;
  handleOpenToggleActiveStatusDialog: (user: FamilyType) => void;
}

export const CardsDisplay = (props: ICardsDisplay) => {
  const { handleMediaQuery } = useResponsiveGrid();
  const columns = handleMediaQuery(4, 3, 2, 2);
  const CARD_GRID_MD = Math.round(12 / columns);
  const CARD_GRID_SM = Math.round(8 / columns);

  return props.dataPaginated?.content?.length && props.dataPaginated?.content?.length > 0 ? (
    <>
      {props.dataPaginated?.content?.map((typeOfFamily: FamilyType, index: number) => (
        <Grid item xs={12} sm={CARD_GRID_SM} md={CARD_GRID_MD} lg={CARD_GRID_MD} key={`grid-card-${typeOfFamily.id}`}>
          <TypeOfFamilyCard
            typeOfFamily={typeOfFamily}
            handleEdit={props.handleOpenUpsertDialog}
            handleActive={props.handleOpenToggleActiveStatusDialog}
          />
        </Grid>
      ))}
    </>
  ) : (
    <NoRecordsFound />
  );
};
