/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomLocationsFilterDTO
 */
export interface CustomLocationsFilterDTO {
    /**
     * 
     * @type {number}
     * @memberof CustomLocationsFilterDTO
     */
    capacity?: number;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof CustomLocationsFilterDTO
     */
    caseInsensitive?: { [key: string]: boolean; };
    /**
     * 
     * @type {string}
     * @memberof CustomLocationsFilterDTO
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomLocationsFilterDTO
     */
    level?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomLocationsFilterDTO
     */
    positionX?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomLocationsFilterDTO
     */
    positionZ?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomLocationsFilterDTO
     */
    searchText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomLocationsFilterDTO
     */
    status?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomLocationsFilterDTO
     */
    warehouseId?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomLocationsFilterDTO
     */
    zone?: string;
}

/**
 * Check if a given object implements the CustomLocationsFilterDTO interface.
 */
export function instanceOfCustomLocationsFilterDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomLocationsFilterDTOFromJSON(json: any): CustomLocationsFilterDTO {
    return CustomLocationsFilterDTOFromJSONTyped(json, false);
}

export function CustomLocationsFilterDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomLocationsFilterDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'capacity': !exists(json, 'capacity') ? undefined : json['capacity'],
        'caseInsensitive': !exists(json, 'caseInsensitive') ? undefined : json['caseInsensitive'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'positionX': !exists(json, 'positionX') ? undefined : json['positionX'],
        'positionZ': !exists(json, 'positionZ') ? undefined : json['positionZ'],
        'searchText': !exists(json, 'searchText') ? undefined : json['searchText'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'warehouseId': !exists(json, 'warehouseId') ? undefined : json['warehouseId'],
        'zone': !exists(json, 'zone') ? undefined : json['zone'],
    };
}

export function CustomLocationsFilterDTOToJSON(value?: CustomLocationsFilterDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'capacity': value.capacity,
        'caseInsensitive': value.caseInsensitive,
        'code': value.code,
        'level': value.level,
        'positionX': value.positionX,
        'positionZ': value.positionZ,
        'searchText': value.searchText,
        'status': value.status,
        'warehouseId': value.warehouseId,
        'zone': value.zone,
    };
}

