import { useRef } from "react";
import { useResolve } from "@movicoders/di";
import { LastPageState } from "@domain/model/LastPageState";
import { useListDetailPaginatedViewModel } from "@movicoders/ui";
import { ILastPageService, LastPageService } from "@domain/services/ILastPageService";
import { TypeOfFamilyRepository } from "@infrastructure/repositories/type-of-families-repository";

export const useTypeOfFamiliesHandleData = () => {
  const {
    data,
    selected: selectedTypeOfFamily,
    loading,
    loadingDetail,
    create,
    fetchOne,
    save,
    goToList,
    getPaginated
  } = useListDetailPaginatedViewModel(TypeOfFamilyRepository);

  const getTypeOfFamiliesPaginated = useRef(getPaginated).current;

  const currentPageService = useResolve<ILastPageService>(LastPageService);
  const currentPagePersistedState = currentPageService.persistedState ?? new LastPageState();

  return {
    currentPageService,
    currentPagePersistedState,
    typeFamilies: data,
    selectedTypeOfFamily,
    loading,
    loadingDetail,
    save,
    goToList,
    goToDetail: fetchOne,
    goToCreate: create,
    getTypeOfFamiliesPaginated
  };
};
