import { ContainerDTO, FamilyDTO, FamilyTypeDTO, LocationDTO, MaterialDTO } from "../../../../clients/aggrego-proxy";

export type Location3D = {
  id?: string;
  code?: string;
  capacity?: number;
  level?: number;
  active?: boolean;
  families?: Array<FamilyDTO>;
  materials?: Array<MaterialDTO>;
  containers?: Array<ContainerDTO>;
  familyTypes?: Array<FamilyTypeDTO>;
  occupation?: number;
  tileId?: string;
  warehouseId?: string;
};

export const location3DFromDTO = (location: LocationDTO, parentTileId?: string): Location3D => {
  return {
    ...location,
    tileId: parentTileId ?? location.tileId,
    containers: location.containers?.map(container => {
      return { id: container };
    })
  };
};

export const locationDTOFrom3D = (location: Location3D, parentTileId?: string): LocationDTO => {
  return {
    ...location,
    tileId: parentTileId ?? location.tileId,
    containers: location.containers?.map(container => container.id ?? "")
  };
};

export const getLocationLevel = (location: Location3D): number => {
  return location.level ?? 1;
};
