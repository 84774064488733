import { Tooltip } from "@mui/material";
import React, { useRef, useState, ReactNode } from "react";
import { usePeriodicFunction } from "@utils/interval-helper";

export const OverflowTooltip = ({ children }: { children: ReactNode }) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const containerElementRef = useRef<HTMLDivElement>(null);

  let tooltipText = "";
  if (React.isValidElement(children) && typeof children.props.children === "string") {
    tooltipText = children.props.children;
  }

  const checkHasOverflowed = () => {
    const scrollWidth = (containerElementRef.current?.children[0] as HTMLElement).scrollWidth;
    const offsetWidth = (containerElementRef.current?.children[0] as HTMLElement).offsetWidth;
    setIsOverflow(offsetWidth < scrollWidth);
  };

  usePeriodicFunction(checkHasOverflowed, 4000);

  return (
    <Tooltip title={isOverflowed ? tooltipText : ""} placement="bottom-start">
      <div
        ref={containerElementRef}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}>
        {children}
      </div>
    </Tooltip>
  );
};
