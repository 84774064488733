import { GridColDef } from "@mui/x-data-grid";
import { filterValueTypes } from "@constants";
import { CustomTooltipCell } from "@components/tooltip/CustomTooltipCell";

export const movementsColumns = (t: (text: string) => string, lgMediaQuery: boolean): GridColDef[] => {
  return [
    {
      flex: 1,
      field: "origin",
      headerName: t("movements-origin"),
      disableColumnMenu: true,
      minWidth: lgMediaQuery ? undefined : 290,
      valueGetter: row => {
        return row.value ? row.value : "-";
      },
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1,
      field: "destiny",
      headerName: t("movements-destiny"),
      disableColumnMenu: true,
      minWidth: lgMediaQuery ? undefined : 290,
      valueGetter: row => {
        return row.value ? row.value : "-";
      },
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1,
      field: "container",
      headerName: t("movements-container"),
      disableColumnMenu: true,
      minWidth: lgMediaQuery ? undefined : 100,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.8,
      field: "material",
      headerName: t("movements-code"),
      disableColumnMenu: true,
      minWidth: lgMediaQuery ? undefined : 75,
      type: "autocomplete-one" as filterValueTypes,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1,
      field: "description",
      headerName: t("movements-material"),
      disableColumnMenu: true,
      minWidth: lgMediaQuery ? undefined : 100,
      type: "autocomplete-one" as filterValueTypes,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1.2,
      field: "family",
      headerName: t("movements-family"),
      disableColumnMenu: true,
      minWidth: lgMediaQuery ? undefined : 105,
      type: "select-one" as filterValueTypes,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1,
      field: "action",
      headerName: t("movements-action"),
      disableColumnMenu: true,
      minWidth: lgMediaQuery ? undefined : 105,
      type: "multiple-select" as filterValueTypes,
      valueGetter: ({ row }) => {
        return `${t(row.action)}`;
      },
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.7,
      field: "quantity",
      headerName: t("movements-qty"),
      disableColumnMenu: true,
      type: "number",
      valueGetter: ({ row }) => {
        return `${row.quantity} ${t("material.measure.abbreviation." + row.uom.toLowerCase())}`;
      },
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1.5,
      field: "comment",
      headerName: t("movements-comment"),
      disableColumnMenu: true,
      valueGetter: row => {
        if (row.value === "OPERATOR NOT PRESENT") {
          return t("OPERATOR_NOT_PRESENT");
        } else if (row.value) {
          return row.value;
        } else {
          return "-";
        }
      },
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.8,
      field: "actionDate",
      headerName: t("movements-date"),
      disableColumnMenu: true,
      sortable: true,
      minWidth: lgMediaQuery ? undefined : 85,
      type: "range-date" as filterValueTypes,
      valueGetter: ({ row }) => {
        return row.actionDate
          ? row.actionDate.toLocaleString("es-ES", {
              localeMatcher: "lookup",
              month: "numeric",
              day: "numeric",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit"
            })
          : "-";
      },
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.8,
      field: "user",
      headerName: t("movements-license"),
      disableColumnMenu: true,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.8,
      field: "operatorCode",
      headerName: t("movements-operator"),
      disableColumnMenu: true,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    }
  ];
};
