import { useTranslation } from "react-i18next";
import { HomeContentTitle } from "../home-content-title";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { IHomeSummaryStockPackage } from "@pages/home/home-detail";
import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import { useEntriesMediaQueries } from "@pages/logistic/entries/hooks/useEntriesMediaQueries";

interface IHomeSummaryStock {
  homeSummaryStockPackage: IHomeSummaryStockPackage;
  updateReliability: () => void;
  updateTotalStockValue: () => void;
}

const HomeSummaryStock = (props: IHomeSummaryStock) => {
  const { t } = useTranslation();
  const { homeSummaryStockPackage, updateReliability, updateTotalStockValue } = props;
  const { matchesMd } = useEntriesMediaQueries();

  const updateModeSelector = (updateMode: string) => {
    switch (updateMode) {
      case "reliability":
        updateReliability();
        break;
      case "total-stock":
        updateTotalStockValue();
        break;
    }
  };

  const formatNumberToString = (result: number) => {
    if (!Number.isFinite(result)) {
      return "-";
    }
    if (Number.isInteger(result)) {
      return result.toString();
    }
    return result.toFixed(2);
  };

  const renderChild = (
    name: string,
    value: string,
    label: JSX.Element | string,
    sm: number,
    md: number,
    unitOfMeassure?: string,
    updateMode?: string
  ) => {
    let loading;
    switch (name) {
      case "reliability":
        loading = homeSummaryStockPackage.inventoryReliabilityLoading;
        break;
      case "total-stock":
        loading = homeSummaryStockPackage.totalStockValueLoading;
        break;
      default:
        loading = false;
    }
    return (
      <Grid item display="block" id="main-summary-container" xs={12} sm={sm} md={md}>
        {!loading ? (
          <Grid item display="flex" justifyContent={matchesMd ? "flex-end" : "flex-start"}>
            <Typography
              variant="summarydashboard_quantity"
              sx={{
                fontSize: "2rem",
                color: updateMode === "reliability" && Number(value) > 90 ? "#4CAC1C" : "inherit"
              }}
              alignContent={matchesMd ? "end" : "start"}
              textAlign={matchesMd ? "end" : "start"}>
              {value}
            </Typography>
            <Typography
              style={{
                fontSize: "1.3rem",
                display: "flex",
                alignItems: "flex-end",
                marginBottom: "5px",
                marginLeft: "2px"
              }}>
              {value && unitOfMeassure}
              {updateMode ? (
                <Icon
                  icon="sync"
                  color={COLORS.gray}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    updateModeSelector(updateMode);
                  }}
                />
              ) : (
                <></>
              )}
            </Typography>
          </Grid>
        ) : (
          <Grid container display="flex" justifyContent={matchesMd ? "flex-end" : "flex-start"}>
            <CircularProgress color="secondary" size={"3rem"} />
          </Grid>
        )}
        <Typography
          style={{ display: "block", height: "3rem", textAlign: matchesMd ? "end" : "start" }}
          variant="summarydashboard_label">
          {label}
        </Typography>
      </Grid>
    );
  };

  const renderSummaryStock = () => {
    return (
      <Grid container display="flex" xs={12} marginTop={"1rem"}>
        {/* Materials */}
        {renderChild("materials", homeSummaryStockPackage.totalMaterials, t("materials"), 6, 1.5)}
        {/* Families */}
        {renderChild("families", homeSummaryStockPackage.totalFamilies, t("family"), 6, 1.5)}
        {/* Inventory Reliability */}
        {renderChild(
          "reliability",
          formatNumberToString(homeSummaryStockPackage.inventoryReliabilityResult),
          t("home.inventory.reliability"),
          4,
          3,
          "%",
          "reliability"
        )}
        {/* Stock value */}
        {renderChild(
          "total-stock",
          formatNumberToString(homeSummaryStockPackage.totalStockValue),
          t("home.stock.value"),
          4,
          3,
          "€",
          "total-stock"
        )}
        {/* Stock coverage */}
        {renderChild(
          "coverage",
          formatNumberToString(homeSummaryStockPackage.stockCoverageResult ?? 0),
          t("home.stock.coverage"),
          4,
          3,
          t("home.uom.days")
        )}
      </Grid>
    );
  };
  return (
    <>
      <HomeContentTitle name={t("home.summary.stock")} />
      {renderSummaryStock()}
    </>
  );
};

export default HomeSummaryStock;
