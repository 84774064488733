import { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import Stakeholder from "@domain/model/Stakeholder";
import { Autocomplete, TextField } from "@mui/material";
import { ProviderDTOPaymentCurrencyEnum } from "@clients/aggrego-proxy";

interface StakeHolderAutocompleteProps {
  disabled: boolean;
  modifiedStakeholder: Stakeholder;
  onChangeAutocomplete: (event: SyntheticEvent<Element, Event>, value: string | null) => void;
}

export const StakeholderCurrencyAutocomplete = (props: StakeHolderAutocompleteProps) => {
  const { t } = useTranslation();
  const { disabled, modifiedStakeholder, onChangeAutocomplete } = props;
  const currencyOptions = [
    ProviderDTOPaymentCurrencyEnum.Dollar,
    ProviderDTOPaymentCurrencyEnum.Euro,
    ProviderDTOPaymentCurrencyEnum.PoundSterling
  ];
  return (
    <Autocomplete
      id="stakeholder-currency-autocomplete"
      value={modifiedStakeholder.paymentCurrency ?? ProviderDTOPaymentCurrencyEnum.Euro}
      options={currencyOptions}
      disableClearable
      disabled={disabled}
      getOptionLabel={(option: string) => {
        if (option === ProviderDTOPaymentCurrencyEnum.Dollar) {
          return t("currency.dollar");
        } else if (option === ProviderDTOPaymentCurrencyEnum.Euro) {
          return t("currency.euro");
        } else {
          return t("currency.pound");
        }
      }}
      isOptionEqualToValue={(option, value) => {
        return option === value || value === null;
      }}
      onChange={(event, value) => {
        onChangeAutocomplete(event, value);
      }}
      renderInput={params => (
        <TextField
          name={"paymentCurrency"}
          {...params}
          label={t("stakeholders.paymentCurrency")}
          className={
            modifiedStakeholder?.active !== undefined && modifiedStakeholder.active === false
              ? "disabled-input"
              : "blue-border-input"
          }
          disabled={disabled}
          InputProps={{
            ...params.InputProps
          }}
        />
      )}
    />
  );
};
