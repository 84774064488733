import { ROUTER_PATHS } from "@constants";
import { useResolve } from "@movicoders/di";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TLogisticEnum, TMasterDataEnum, TReportEnum, TTrackAndTraceEnum } from "@pages/settings/settings-interfaces";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";

export const useSettings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const settingsService = useResolve<ISettingsService>(SettingsService);

  const [masterDataPermissions, setMasterDataPermissions] = useState<string[]>([]);
  const [reportPermissions, setReportPermissions] = useState<string[]>([]);
  const [logisticPermissions, setLogisticPermissions] = useState<string[]>([]);
  const [trackAndTracePermissions, setTrackAndTracePermissions] = useState<string[]>([]);

  const [invalidRoutes, setInvalidRoutes] = useState<string[]>([]);

  const allMasterDataMap = [
    { key: "WAREHOUSE_VIEWER", value: "warehouse-viewer" },
    { key: "WAREHOUSE", value: "warehouses" },
    { key: "CUSTOMER", value: "customers" },
    { key: "FAMILY", value: "families" },
    { key: "FAMILY_TYPE", value: "type-of-families" },
    { key: "LOCATION", value: "locations" },
    { key: "MANUFACTURER", value: "manufacturers" },
    { key: "MATERIAL", value: "materials" },
    { key: "OPERATOR", value: "operators" },
    { key: "OWNER", value: "owners" },
    { key: "SUPPLIER", value: "suppliers" },
    { key: "USER", value: "users" },
    { key: "ZONE", value: "zones" }
  ];

  const allReportsMap = [
    { key: "INVENTORY_REGISTRY", value: "inventory-registry" },
    { key: "INVENTORY_REPORT", value: "inventory-report" },
    { key: "MOVEMENTS_REPORT", value: "movements-report" },
    { key: "STOCK_REPORT", value: "materials-stock" },
    { key: "WAREHOUSE_STATUS", value: "warehouse-status" },
    { key: "RELABEL_REPORT", value: "relabel-report" }
  ];

  const allLogisticMap = [
    { key: "INVENTORY_MATERIAL", value: "inventory-material" },
    { key: "MATERIAL_ENTRY", value: "inbounds" },
    { key: "MATERIAL_EXIT", value: "outbounds" }
  ];

  const allTrackAndTraceMap = [
    { key: "HIERARCHY", value: "hierarchy-creation" },
    { key: "PRODUCT", value: "products" }
  ];

  useEffect(() => {
    settingsService.persistedState && navigationRouter();
  }, [invalidRoutes, location]);

  useEffect(() => {
    fillAllArrays();
  }, [settingsService.persistedState]);

  const fillAllArrays = () => {
    setInvalidRoutes([]);
    allMasterDataMap.forEach(masterdata => {
      if (!settingsService.persistedState?.masterdataPermissions.includes(masterdata.key as TMasterDataEnum)) {
        setInvalidRoutes(oldArray => [...oldArray, masterdata.value]);
      } else {
        setMasterDataPermissions(oldArray => [...oldArray, masterdata.value]);
      }
    });
    allReportsMap.forEach(report => {
      if (!settingsService.persistedState?.reportsPermissions?.includes(report.key as TReportEnum)) {
        setInvalidRoutes(oldArray => [...oldArray, report.value]);
      } else {
        setReportPermissions(oldArray => [...oldArray, report.value]);
      }
    });
    allLogisticMap.forEach(logistic => {
      if (!settingsService.persistedState?.logisticOperationsPermissions?.includes(logistic.key as TLogisticEnum)) {
        setInvalidRoutes(oldArray => [...oldArray, logistic.value]);
      } else {
        setLogisticPermissions(oldArray => [...oldArray, logistic.value]);
      }
    });
    allTrackAndTraceMap.forEach(track => {
      if (!settingsService.persistedState?.trackAndTracePermissions?.includes(track.key as TTrackAndTraceEnum)) {
        setInvalidRoutes(oldArray => [...oldArray, track.value]);
      } else {
        setTrackAndTracePermissions(oldArray => [...oldArray, track.value]);
      }
    });
  };

  const navigationRouter = () => {
    if (settingsService.persistedState) {
      invalidRoutes.forEach(invalidRoute => {
        if (location.pathname.split("/")[1] === invalidRoute) {
          navigate(ROUTER_PATHS.home);
        }
      });
    }
  };

  return {
    masterDataPermissions,
    reportPermissions,
    logisticPermissions,
    trackAndTracePermissions
  };
};
