import { FullCompanyItemDTO } from "@clients/aggrego-proxy";
import { CompanyRepository } from "@infrastructure/repositories/company-repository";

export class CompanyUpdateUseCase {
  constructor(private readonly companyRepository: CompanyRepository) {}

  public async getDetail(tenant: string) {
    return await this.companyRepository.getDetails(tenant);
  }

  public async execute(company: FullCompanyItemDTO) {
    return await this.companyRepository.update(company);
  }
}
