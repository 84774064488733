import { useTranslation } from "react-i18next";
import { DomainPage } from "@movicoders/domain";
import { Grid, Typography } from "@mui/material";
import FamilyType from "@domain/model/FamilyType";
import { useCheckStatus } from "@hooks/useCheckStatus";
import { CardsDisplay } from "../components/CardsDisplay";
import { DataGridDisplay } from "@components/data-grid-display/data-grid-display";
import { GridColDef, GridRenderCellParams, GridValidRowModel } from "@mui/x-data-grid";

interface ITypesOfFamiliesTable {
  typeFamilies: DomainPage<FamilyType>;
  handleEdit: (id: string) => void;
  handleOpenToggleActiveStatusDialog: (familyType: FamilyType) => void;
  handlePagination: (
    limit: number,
    offset: number,
    status?: "ACTIVE" | "INACTIVE" | "ALL" | undefined,
    filters?: object | undefined
  ) => void;
}

const TypesOfFamiliesViewTable = (props: ITypesOfFamiliesTable) => {
  const { t } = useTranslation();
  const { handleStatusFilter } = useCheckStatus();
  const { typeFamilies, handleEdit, handlePagination, handleOpenToggleActiveStatusDialog } = props;

  const typeOfFamilyViewCols: GridColDef[] = [
    { field: "name", headerName: t("root.name") },
    {
      field: "active",
      headerName: t("type.of.families.status"),
      renderCell: (data: GridRenderCellParams) =>
        data.row.active ? (
          <Typography variant="column_table_typography">{t("type.of.families.active")}</Typography>
        ) : (
          <Typography variant="column_table_typography">{t("type.of.families.deactivate")}</Typography>
        )
    }
  ];

  return (
    <DataGridDisplay
      data={(typeFamilies.content as GridValidRowModel[]) ?? []}
      columns={typeOfFamilyViewCols}
      mode="server"
      viewName="typeOfFamily"
      handleBackendPagination={handlePagination}
      backendTotalElements={typeFamilies.totalElements ?? 0}
      rowsPerPageOptions={[12, 24, 48, 96]}
      customDisplayComponent={() => (
        <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          <CardsDisplay
            dataPaginated={typeFamilies as DomainPage<FamilyType>}
            handleOpenToggleActiveStatusDialog={handleOpenToggleActiveStatusDialog}
            handleOpenUpsertDialog={handleEdit}
          />
        </Grid>
      )}
      toolbarProps={{
        isActiveFilterHidden: handleStatusFilter(),
        disableFilterByFields: ["active"],
        parseCSVInDownload: data => {
          const dataForCsv = { ...data };
          dataForCsv.active = dataForCsv.active ? t("type.of.families.active") : t("type.of.families.deactivate");
          return dataForCsv;
        }
      }}
    />
  );
};

export default TypesOfFamiliesViewTable;
