import FamilyType from "@domain/model/FamilyType";
import { useCheckStatus } from "@hooks/useCheckStatus";
import { useTypeOfFamiliesSave } from "./useTypeOfFamiliesSave";
import { useTypeOfFamiliesPagination } from "./useTypeOfFamiliesPagination";
import { useTypeOfFamiliesHandleData } from "./useTypeOfFamiliesHandleData";
import { useTypeOfFamiliesActiveStatus } from "./useTypeOfFamiliesActiveStatus";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { useResolve } from "@movicoders/di";

export const useTypeOfFamiliesViewModel = () => {
  const settingsService = useResolve<ISettingsService>(SettingsService);

  const {
    currentPagePersistedState,
    currentPageService,
    loading,
    loadingDetail,
    typeFamilies,
    selectedTypeOfFamily,
    save,
    goToCreate,
    goToDetail,
    goToList,
    getTypeOfFamiliesPaginated
  } = useTypeOfFamiliesHandleData();

  const { getCurrentStatus } = useCheckStatus();

  const {
    isActivating,
    selectedTypeOfFamily4Activation,
    toggleTypeOfFamilyStatus,
    handleOpenToggleActiveStatusDialog,
    onCloseDialog
  } = useTypeOfFamiliesActiveStatus(
    currentPageService,
    currentPagePersistedState,
    save,
    getTypeOfFamiliesPaginated,
    getCurrentStatus
  );

  const { handlePagination } = useTypeOfFamiliesPagination(getTypeOfFamiliesPaginated, getCurrentStatus);

  const { handleEdit, handleSave } = useTypeOfFamiliesSave(
    currentPageService,
    currentPagePersistedState,
    selectedTypeOfFamily as FamilyType,
    goToDetail,
    save,
    goToList,
    getCurrentStatus
  );

  return {
    loading,
    loadingDetail,
    typeFamilies,
    isActivating,
    selectedTypeOfFamily4Activation,
    selectedTypeOfFamily,
    toggleTypeOfFamilyStatus,
    handleOpenToggleActiveStatusDialog,
    goToCreate,
    goToList,
    handleSave,
    handleEdit,
    handlePagination,
    onCloseDialog,
    settingsService
  };
};
