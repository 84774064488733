import { CircularProgress, Grid } from "@mui/material";
import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import Family from "@domain/model/Family";
import { useTranslation } from "react-i18next";
import { DomainPage } from "@movicoders/domain";
import { useCheckStatus } from "@hooks/useCheckStatus";
import { FamiliesDetailView } from "./families-detail-view";
import FamilyActiveDialog from "./components/confirms/family-active";
import FamiliesViewTable from "./view-components/families-view-table";
import { MasterDataTitle } from "@components/master-data-view/MasterDataTitle";
import { OutletContainer } from "@components/outlet-container/outlet-container";
import { useFamilyListViewModel } from "./hooks/viewmodel/useFamilyListViewModel";
import { LoadingSpinnerMasterView } from "@components/loading-circular-progress/loading-spinner-master-view";
import { MasterDataEnum } from "@pages/settings/settings-interfaces";

const FamiliesMasterView = () => {
  const { t } = useTranslation();

  const {
    isActivating,
    selectedFamily4Status,
    families,
    selectedFamily,
    loading,
    loadingDetail,
    dataTypesOfFamilies,
    goToCreate,
    goToList,
    handleSave,
    handleEdit,
    handleOpenToggleActiveStatusDialog,
    handlePagination,
    toggleFamilyStatus,
    onCloseDialog,
    settingsService
  } = useFamilyListViewModel();

  const { handleStatusFilter } = useCheckStatus();

  return (
    <>
      {settingsService.persistedState?.masterdataPermissions.includes(MasterDataEnum.Families) ? (
        <>
          <OutletContainer>
            <Grid container display="flex" flexDirection="column">
              <MasterDataTitle
                icon={<Icon icon="category" color={COLORS.primary} />}
                name={t("families.title")}
                buttonText={t("families.add.button")}
                onAddButton={goToCreate}
                showButton={!handleStatusFilter()}
              />

              <FamiliesViewTable
                families={families as DomainPage<Family>}
                dataTypesOfFamilies={dataTypesOfFamilies}
                handleEdit={handleEdit}
                handleOpenToggleActiveStatusDialog={handleOpenToggleActiveStatusDialog}
                handlePagination={handlePagination}
              />

              {selectedFamily4Status && (
                <FamilyActiveDialog
                  open={isActivating}
                  onClose={onCloseDialog}
                  onActive={item => toggleFamilyStatus(item)}
                  family={selectedFamily4Status}
                />
              )}
              <LoadingSpinnerMasterView loading={loading} />
            </Grid>
          </OutletContainer>
          <FamiliesDetailView
            family={selectedFamily as Family}
            typesOfFamilies={dataTypesOfFamilies}
            loading={loadingDetail}
            onSave={handleSave}
            onClose={() => goToList()}
          />
        </>
      ) : (
        <CircularProgress color="secondary" />
      )}
    </>
  );
};

export default FamiliesMasterView;
