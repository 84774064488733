import { COLORS } from "@theme";
import { useTranslation } from "react-i18next";
import { Select, MenuItem } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { ToolbarFieldFilter } from "../config/data-grid-display-status";

export const DataGridDisplayActiveMenu = (props: {
  selectedStatus: string;
  setSelectedStatus: (newStatus: string) => void;
  toolbarFieldFilter: ToolbarFieldFilter;
}) => {
  const { selectedStatus, setSelectedStatus, toolbarFieldFilter } = props;

  const { t } = useTranslation();

  const handleChangeActiveFilter = (e: SelectChangeEvent<string>) => {
    setSelectedStatus(e.target.value);
  };

  return (
    <Select
      id="table-select-status"
      className="table-select"
      onChange={handleChangeActiveFilter}
      value={selectedStatus}
      size="small"
      color="secondary">
      {toolbarFieldFilter.options.map(option => (
        <MenuItem
          id={`table-select-option-${option.value}`}
          key={option.name}
          value={option.value}
          sx={{ fontSize: "small", color: COLORS.grey }}>
          {t(option.name)}
        </MenuItem>
      ))}
    </Select>
  );
};
