import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FamilyType from "@domain/model/FamilyType";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";
import { ButtonsDetail } from "@components/master-data-view/buttons/buttons-detail";

const TypeOfFamilyActivationDialog = (props: {
  open: boolean;
  onClose: () => void;
  onActivation: (typeOfFamily: FamilyType) => void;
  typeOfFamily: FamilyType;
}) => {
  const { t } = useTranslation();
  const { open, typeOfFamily, onClose, onActivation } = props;
  const [pendingRequest, setPendingRequest] = useState<boolean>(false);

  useEffect(() => {
    setPendingRequest(false);
  }, [typeOfFamily]);

  return open ? (
    <AggregoDialog
      title={
        (typeOfFamily.active ? t("type.of.families.deactivate.title") : t("type.of.families.active.title")) +
        typeOfFamily.name +
        "?"
      }
      onClose={onClose}>
      <Grid container flexDirection="column" className="masterdata-box" rowSpacing={2}>
        <Grid item>
          <div className="confirm-dialog-div-content">
            {typeOfFamily.active ? t("type.of.families.form.deactivate") : t("type.of.families.form.active")}
          </div>
        </Grid>
        <Grid item>
          <ButtonsDetail
            handleClose={onClose}
            handleSave={() => {
              setPendingRequest(true);
              onActivation(typeOfFamily);
            }}
            mode={typeOfFamily.active ? "ACTIVE" : "INACTIVE"}
            pendingRequest={pendingRequest}
          />
        </Grid>
      </Grid>
    </AggregoDialog>
  ) : (
    <></>
  );
};

export default TypeOfFamilyActivationDialog;
