import { GridColDef } from "@mui/x-data-grid";
import { filterValueTypes } from "@constants";
import { CustomTooltipCell } from "@components/tooltip/CustomTooltipCell";

export const warehouseStatusColumns = (t: (text: string) => string, lgMediaQuery: boolean): GridColDef[] => {
  return [
    {
      flex: 1,
      field: "location",
      headerName: t("warehouse.status.location"),
      disableColumnMenu: true,
      minWidth: lgMediaQuery ? undefined : 290,
      align: "center",
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1.3,
      field: "container",
      headerName: t("warehouse.status.container"),
      disableColumnMenu: true,
      minWidth: lgMediaQuery ? undefined : 290,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.8,
      field: "material",
      headerName: t("warehouse.status.code"),
      disableColumnMenu: true,
      minWidth: lgMediaQuery ? undefined : 100,
      type: "autocomplete-one" as filterValueTypes,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.8,
      field: "description",
      headerName: t("warehouse.status.description"),
      disableColumnMenu: true,
      minWidth: lgMediaQuery ? undefined : 100,
      type: "autocomplete-one" as filterValueTypes,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1,
      field: "qty",
      headerName: t("warehouse.status.quantity"),
      disableColumnMenu: true,
      minWidth: lgMediaQuery ? undefined : 75,
      type: "number",
      valueGetter: ({ row }) => {
        return `${row.qty}  ${t("material.measure.abbreviation." + row.uom.toLowerCase())}`;
      },
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.7,
      field: "family",
      sortable: false,
      headerName: t("material.family"),
      disableColumnMenu: true,
      valueGetter: ({ row }) => {
        return row.family.map((family: string, index: number) =>
          //Adding all the families names to the row. If index of family != (number of families -1), adds a comma. Finally, always add a blank space
          index !== row.family.length - 1 ? ` ${family} ` : `${family}`
        );
      },
      type: "multiple-select" as filterValueTypes,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1,
      field: "date",
      headerName: t("warehouse.status.date"),
      disableColumnMenu: true,
      minWidth: lgMediaQuery ? undefined : 105,
      type: "range-date" as filterValueTypes,
      valueGetter: ({ row }) => {
        return row.date.toLocaleString("es-ES", {
          localeMatcher: "lookup",
          month: "numeric",
          day: "numeric",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit"
        });
      },
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1,
      field: "user",
      headerName: t("warehouse.status.license"),
      disableColumnMenu: true,
      minWidth: lgMediaQuery ? undefined : 105,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.8,
      field: "operatorCode",
      headerName: t("warehouse.status.operator.code"),
      disableColumnMenu: true,
      minWidth: lgMediaQuery ? undefined : 105,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    }
  ];
};
