/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  CustomMaterialFilter,
  ListResponseDTO,
  MaterialDTO,
  MaterialPageDTO,
  MovicodersBadRequestException,
  MovicodersNotFoundException,
  MovicodersServiceException
} from "../models";
import {
  CustomMaterialFilterFromJSON,
  CustomMaterialFilterToJSON,
  ListResponseDTOFromJSON,
  ListResponseDTOToJSON,
  MaterialDTOFromJSON,
  MaterialDTOToJSON,
  MaterialPageDTOFromJSON,
  MaterialPageDTOToJSON,
  MovicodersBadRequestExceptionFromJSON,
  MovicodersBadRequestExceptionToJSON,
  MovicodersNotFoundExceptionFromJSON,
  MovicodersNotFoundExceptionToJSON,
  MovicodersServiceExceptionFromJSON,
  MovicodersServiceExceptionToJSON
} from "../models";

export interface DeleteMaterialRequest {
  id: string;
  xTenantId?: string;
}

export interface DeleteMaterialsRequest {
  xTenantId?: string;
  requestBody?: Array<string>;
}

export interface GetMaterialByExactCodeRequest {
  code: string;
  xTenantId?: string;
}

export interface GetMaterialByIdRequest {
  id: string;
  fullDTO: boolean;
  xTenantId?: string;
}

export interface GetMaterialByIdsRequest {
  xTenantId?: string;
  requestBody?: Array<string>;
}

export interface GetMaterialsByCodeRequest {
  code: string;
  xTenantId?: string;
}

export interface GetMaterialsByCodesRequest {
  xTenantId?: string;
  requestBody?: Array<string>;
}

export interface ListMaterialsRequest {
  status: ListMaterialsStatusEnum;
  xTenantId?: string;
}

export interface ListMaterialsPaginatedRequest {
  limit: number;
  offset: number;
  fullDTO: boolean;
  xTenantId?: string;
  sortField?: ListMaterialsPaginatedSortFieldEnum;
  sortDirection?: ListMaterialsPaginatedSortDirectionEnum;
  customMaterialFilter?: CustomMaterialFilter;
}

export interface SaveMaterialRequest {
  xTenantId?: string;
  materialDTO?: MaterialDTO;
}

export interface SaveMaterialsRequest {
  xTenantId?: string;
  materialDTO?: Array<MaterialDTO>;
}

export interface UpdateMaterialRequest {
  xTenantId?: string;
  materialDTO?: MaterialDTO;
}

/**
 *
 */
export class MaterialsApi extends runtime.BaseAPI {
  /**
   * Takes an existing material, deletes it, and returns the new object.
   * Delete an existing material by id.
   */
  async deleteMaterialRaw(
    requestParameters: DeleteMaterialRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<MaterialDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteMaterial."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/materials/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => MaterialDTOFromJSON(jsonValue));
  }

  /**
   * Takes an existing material, deletes it, and returns the new object.
   * Delete an existing material by id.
   */
  async deleteMaterial(
    requestParameters: DeleteMaterialRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<MaterialDTO> {
    const response = await this.deleteMaterialRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the deleted Materials
   */
  async deleteMaterialsRaw(
    requestParameters: DeleteMaterialsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<MaterialDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/materials/delete-many`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(MaterialDTOFromJSON));
  }

  /**
   * Returns the deleted Materials
   */
  async deleteMaterials(
    requestParameters: DeleteMaterialsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<MaterialDTO>> {
    const response = await this.deleteMaterialsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns a material by code.
   * Get material object by exact code.
   */
  async getMaterialByExactCodeRaw(
    requestParameters: GetMaterialByExactCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<MaterialDTO>> {
    if (requestParameters.code === null || requestParameters.code === undefined) {
      throw new runtime.RequiredError(
        "code",
        "Required parameter requestParameters.code was null or undefined when calling getMaterialByExactCode."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.code !== undefined) {
      queryParameters["code"] = requestParameters.code;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/materials/code/exact`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => MaterialDTOFromJSON(jsonValue));
  }

  /**
   * Returns a material by code.
   * Get material object by exact code.
   */
  async getMaterialByExactCode(
    requestParameters: GetMaterialByExactCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<MaterialDTO> {
    const response = await this.getMaterialByExactCodeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns one material object by id.
   * Get a material object by id.
   */
  async getMaterialByIdRaw(
    requestParameters: GetMaterialByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<MaterialDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getMaterialById."
      );
    }

    if (requestParameters.fullDTO === null || requestParameters.fullDTO === undefined) {
      throw new runtime.RequiredError(
        "fullDTO",
        "Required parameter requestParameters.fullDTO was null or undefined when calling getMaterialById."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fullDTO !== undefined) {
      queryParameters["fullDTO"] = requestParameters.fullDTO;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/materials/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => MaterialDTOFromJSON(jsonValue));
  }

  /**
   * Returns one material object by id.
   * Get a material object by id.
   */
  async getMaterialById(
    requestParameters: GetMaterialByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<MaterialDTO> {
    const response = await this.getMaterialByIdRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns material objects by ids.
   * Get a material object by id.
   */
  async getMaterialByIdsRaw(
    requestParameters: GetMaterialByIdsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<MaterialDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/materials/find-many-by-id`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(MaterialDTOFromJSON));
  }

  /**
   * Returns material objects by ids.
   * Get a material object by id.
   */
  async getMaterialByIds(
    requestParameters: GetMaterialByIdsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<MaterialDTO>> {
    const response = await this.getMaterialByIdsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns a list of material by code.
   * Get materials object by code.
   */
  async getMaterialsByCodeRaw(
    requestParameters: GetMaterialsByCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<MaterialDTO>>> {
    if (requestParameters.code === null || requestParameters.code === undefined) {
      throw new runtime.RequiredError(
        "code",
        "Required parameter requestParameters.code was null or undefined when calling getMaterialsByCode."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.code !== undefined) {
      queryParameters["code"] = requestParameters.code;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/materials/code`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(MaterialDTOFromJSON));
  }

  /**
   * Returns a list of material by code.
   * Get materials object by code.
   */
  async getMaterialsByCode(
    requestParameters: GetMaterialsByCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<MaterialDTO>> {
    const response = await this.getMaterialsByCodeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns material objects by material code.
   * Get materials by given codes.
   */
  async getMaterialsByCodesRaw(
    requestParameters: GetMaterialsByCodesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<MaterialDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/materials/find-many-by-code`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(MaterialDTOFromJSON));
  }

  /**
   * Returns material objects by material code.
   * Get materials by given codes.
   */
  async getMaterialsByCodes(
    requestParameters: GetMaterialsByCodesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<MaterialDTO>> {
    const response = await this.getMaterialsByCodesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered Materials
   */
  async listMaterialsRaw(
    requestParameters: ListMaterialsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<MaterialDTO>>> {
    if (requestParameters.status === null || requestParameters.status === undefined) {
      throw new runtime.RequiredError(
        "status",
        "Required parameter requestParameters.status was null or undefined when calling listMaterials."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/materials`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(MaterialDTOFromJSON));
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered Materials
   */
  async listMaterials(
    requestParameters: ListMaterialsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<MaterialDTO>> {
    const response = await this.listMaterialsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered Materials paginated
   */
  async listMaterialsPaginatedRaw(
    requestParameters: ListMaterialsPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<MaterialPageDTO>> {
    if (requestParameters.limit === null || requestParameters.limit === undefined) {
      throw new runtime.RequiredError(
        "limit",
        "Required parameter requestParameters.limit was null or undefined when calling listMaterialsPaginated."
      );
    }

    if (requestParameters.offset === null || requestParameters.offset === undefined) {
      throw new runtime.RequiredError(
        "offset",
        "Required parameter requestParameters.offset was null or undefined when calling listMaterialsPaginated."
      );
    }

    if (requestParameters.fullDTO === null || requestParameters.fullDTO === undefined) {
      throw new runtime.RequiredError(
        "fullDTO",
        "Required parameter requestParameters.fullDTO was null or undefined when calling listMaterialsPaginated."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sortField"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sortDirection"] = requestParameters.sortDirection;
    }

    if (requestParameters.fullDTO !== undefined) {
      queryParameters["fullDTO"] = requestParameters.fullDTO;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/materials/paginated`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CustomMaterialFilterToJSON(requestParameters.customMaterialFilter)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => MaterialPageDTOFromJSON(jsonValue));
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered Materials paginated
   */
  async listMaterialsPaginated(
    requestParameters: ListMaterialsPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<MaterialPageDTO> {
    const response = await this.listMaterialsPaginatedRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the registered Material
   */
  async saveMaterialRaw(
    requestParameters: SaveMaterialRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<MaterialDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/materials`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: MaterialDTOToJSON(requestParameters.materialDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => MaterialDTOFromJSON(jsonValue));
  }

  /**
   * Returns the registered Material
   */
  async saveMaterial(
    requestParameters: SaveMaterialRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<MaterialDTO> {
    const response = await this.saveMaterialRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the registered Materials
   */
  async saveMaterialsRaw(
    requestParameters: SaveMaterialsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ListResponseDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/materials/add-many`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.materialDTO?.map(MaterialDTOToJSON)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ListResponseDTOFromJSON(jsonValue));
  }

  /**
   * Returns the registered Materials
   */
  async saveMaterials(
    requestParameters: SaveMaterialsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ListResponseDTO> {
    const response = await this.saveMaterialsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Takes an existing Material object, updates it, and returns the Material.
   * Update an existing Material.
   */
  async updateMaterialRaw(
    requestParameters: UpdateMaterialRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<MaterialDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/materials`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: MaterialDTOToJSON(requestParameters.materialDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => MaterialDTOFromJSON(jsonValue));
  }

  /**
   * Takes an existing Material object, updates it, and returns the Material.
   * Update an existing Material.
   */
  async updateMaterial(
    requestParameters: UpdateMaterialRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<MaterialDTO> {
    const response = await this.updateMaterialRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

/**
 * @export
 */
export const ListMaterialsStatusEnum = {
  Active: "ACTIVE",
  All: "ALL",
  Inactive: "INACTIVE"
} as const;
export type ListMaterialsStatusEnum = (typeof ListMaterialsStatusEnum)[keyof typeof ListMaterialsStatusEnum];
/**
 * @export
 */
export const ListMaterialsPaginatedSortFieldEnum = {
  Code: "CODE",
  Family: "FAMILY",
  Minstock: "MINSTOCK",
  Name: "NAME",
  Status: "STATUS",
  Unitprice: "UNITPRICE"
} as const;
export type ListMaterialsPaginatedSortFieldEnum =
  (typeof ListMaterialsPaginatedSortFieldEnum)[keyof typeof ListMaterialsPaginatedSortFieldEnum];
/**
 * @export
 */
export const ListMaterialsPaginatedSortDirectionEnum = {
  Asc: "ASC",
  Desc: "DESC"
} as const;
export type ListMaterialsPaginatedSortDirectionEnum =
  (typeof ListMaterialsPaginatedSortDirectionEnum)[keyof typeof ListMaterialsPaginatedSortDirectionEnum];
