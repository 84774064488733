import { COLORS } from "@theme";
import { CircularProgress, Grid } from "@mui/material";
import Icon from "@components/icons/icon";
import Material from "@domain/model/Material";
import { useTranslation } from "react-i18next";
import { DomainPage } from "@movicoders/domain";
import { useCheckStatus } from "@hooks/useCheckStatus";
import { MaterialsDetailView } from "./materials-detail-view";
import MaterialsViewTable from "./view-components/materials-view-table";
import { MasterDataTitle } from "@components/master-data-view/MasterDataTitle";
import { OutletContainer } from "@components/outlet-container/outlet-container";
import { useMaterialViewModel } from "./hooks/view-model/useMaterialListViewModel";
import { MaterialsActivationDialog } from "./components/dialogs/materialsActivationDialog";
import { LoadingSpinnerMasterView } from "@components/loading-circular-progress/loading-spinner-master-view";
import { MasterDataEnum } from "@pages/settings/settings-interfaces";

export const MaterialsMasterView = () => {
  const { t } = useTranslation();
  const {
    goToCreate,
    goToList,
    handleActiveConfirm,
    handleEdit,
    handleMaterialStatus,
    isActivating,
    material4Activation,
    onCloseActive,
    handleSave,
    selectedMaterial,
    loading,
    loadingDetail,
    families,
    handlePagination,
    materialData,
    searchBarFilter,
    sortByFieldBackend,
    settingsService
  } = useMaterialViewModel();

  const { handleStatusFilter } = useCheckStatus();

  return (
    <>
      {settingsService.persistedState?.masterdataPermissions.includes(MasterDataEnum.Materials) ? (
        <>
          <OutletContainer>
            <Grid container display="flex" flexDirection="column">
              <MasterDataTitle
                icon={<Icon icon="item" color={COLORS.primary} />}
                name={t("materials")}
                buttonText={t("material.add.button")}
                onAddButton={goToCreate}
                showButton={!handleStatusFilter()}
              />

              <MaterialsViewTable
                materialData={materialData as DomainPage<Material>}
                families={families}
                handleEdit={handleEdit}
                handleActiveConfirm={handleActiveConfirm}
                sortByFieldBackend={sortByFieldBackend}
                handlePagination={handlePagination}
                searchBarFilter={searchBarFilter}
                handleStatusFilter={handleStatusFilter}
              />

              {material4Activation && (
                <MaterialsActivationDialog
                  open={isActivating}
                  onClose={onCloseActive}
                  onActivation={item => handleMaterialStatus(material4Activation)}
                  material={material4Activation}
                />
              )}
              <LoadingSpinnerMasterView loading={loading} />
            </Grid>
          </OutletContainer>

          <MaterialsDetailView
            material={selectedMaterial as Material}
            families={families}
            loading={loadingDetail}
            onClose={() => goToList()}
            onSave={handleSave}
          />
        </>
      ) : (
        <CircularProgress color="secondary" />
      )}
    </>
  );
};
