import { COLORS } from "@theme";
import { CircularProgress, Grid } from "@mui/material";
import Zone from "@domain/model/Zone";
import Icon from "@components/icons/icon";
import { useTranslation } from "react-i18next";
import { DomainPage } from "@movicoders/domain";
import { ZonesDetailView } from "./zones-detail-view";
import { useCheckStatus } from "@hooks/useCheckStatus";
import ZonesViewTable from "./view-components/zones-view-table";
import { MasterDataEnum } from "@pages/settings/settings-interfaces";
import { useZoneViewModel } from "./hooks/viewmodel/useZoneViewModel";
import ZoneActivationDialog from "./components/confirms/zone-activation";
import { PersistedAlert } from "@components/persistedAlert/PersistedAlert";
import { MasterDataTitle } from "@components/master-data-view/MasterDataTitle";
import { useHandleSelectedWarehouse } from "@hooks/useHandleSelectedWarehouse";
import { OutletContainer } from "@components/outlet-container/outlet-container";
import { LoadingSpinnerMasterView } from "@components/loading-circular-progress/loading-spinner-master-view";

export const ZonesMasterView = () => {
  const { t } = useTranslation();

  const {
    zoneData,
    isActivating,
    loadingDetail,
    loading,
    selectedZone4Status,
    selectedZone,
    toggleZoneStatus,
    handleOpenToggleActiveStatusDialog,
    goToList,
    goToCreate,
    handleSave,
    handleEdit,
    handlePagination,
    onCloseDialog,
    settingsService
  } = useZoneViewModel();

  const { handleStatusFilter } = useCheckStatus();
  const { selectedWarehouse } = useHandleSelectedWarehouse();

  return (
    <>
      {settingsService.persistedState?.masterdataPermissions.includes(MasterDataEnum.Zones) ? (
        <>
          <OutletContainer>
            <Grid container display="flex" flexDirection="column">
              <MasterDataTitle
                icon={<Icon icon="zonas" color={COLORS.primary} />}
                name={t("zones")}
                buttonText={t("zones.add.button")}
                onAddButton={goToCreate}
                showButton={!handleStatusFilter()}
                disableButton={!selectedWarehouse}
              />

              {selectedWarehouse && selectedWarehouse !== "" ? (
                <ZonesViewTable
                  zoneData={zoneData as DomainPage<Zone>}
                  handleEdit={handleEdit}
                  handleOpenToggleActiveStatusDialog={handleOpenToggleActiveStatusDialog}
                  handlePagination={handlePagination}
                />
              ) : (
                <PersistedAlert message={t("zone.alert.warehouse.no.created")} goTo={"warehouses"} />
              )}

              {selectedZone4Status && (
                <ZoneActivationDialog
                  zone={selectedZone4Status}
                  open={isActivating}
                  onClose={onCloseDialog}
                  onActivation={item => toggleZoneStatus(selectedZone4Status)}
                />
              )}
              <LoadingSpinnerMasterView loading={loading} />
            </Grid>
          </OutletContainer>

          <ZonesDetailView zone={selectedZone as Zone} loading={loadingDetail} onSave={handleSave} onClose={() => goToList()} />
        </>
      ) : (
        <CircularProgress color="secondary" />
      )}
    </>
  );
};
