import { ROUTER_PATHS } from "@constants";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { HierarchyPair } from "@domain/model/HierarchyState";
import { ProductDTO } from "@clients/aggrego-proxy";

type BreadcrumbValue = {
  id: string;
  value: string;
};

export const useHierarchyBreadcrumbsViewModel = (currentProduct: ProductDTO) => {
  const navigate = useNavigate();

  const [currentParents, setCurrentParents] = useState<BreadcrumbValue[]>([]);

  const handleClickParent = (id: string) => navigate(ROUTER_PATHS.products + "/" + id);

  useEffect(() => {
    if (currentProduct) {
      const hierarchy = getParents(currentProduct as ProductDTO);
      hierarchy.push({ uuid: currentProduct.id ?? "", name: currentProduct.materialCode ?? "" });
      const result = hierarchy.map(({ uuid, name }) => {
        return { id: uuid, value: name } as BreadcrumbValue;
      });
      setCurrentParents(result);
    }
  }, [currentProduct]);

  const getParents = (currentProduct: ProductDTO, hierarchy: HierarchyPair[] = []): HierarchyPair[] => {
    if (currentProduct && currentProduct.parents && currentProduct.parents.length > 0) {
      const parent = currentProduct.parents[0];
      hierarchy.unshift({ uuid: parent.id ?? "", name: parent.materialCode ?? "" });
      return getParents(parent, hierarchy);
    }
    return hierarchy;
  };

  return { handleClickParent, currentParents };
};
