import { ContainerApi } from "@infrastructure/api/container-api";

export class InventoryRegistryUseCase {
  constructor(private readonly containerApi: ContainerApi) {}

  public openNewPeriod(warehouseCode: string): Promise<string> {
    return this.containerApi.createInventoryPeriod({ warehouseCode: warehouseCode });
  }

  public closePeriod(warehouseCode: string): Promise<string> {
    return this.containerApi.closeInventoryPeriod({ warehouseCode: warehouseCode });
  }
}
