import LocationEntity from "@domain/model/Location";
import { LocationRepository } from "@infrastructure/repositories/location-repository";
import { useResolve } from "@movicoders/di";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";

export const useHomeOccupancyData = (selectedWarehouse: string) => {
  const location = useLocation();
  const locationRepository = useResolve<LocationRepository>(LocationRepository);

  const [totalLocations, setTotalLocations] = useState(0);
  const [totalCapacity, setTotalCapacity] = useState(0);
  const [totalContainers, setTotalContainers] = useState(0);
  const [warehouseOcuppancy, setWarehouseOcuppancy] = useState(0);

  useEffect(() => {
    calculateWarehouseOccupancy();
  }, [location.pathname]);

  //Calculates for each location, total capacity available (=SUM of all capacity value of each location)
  //Calculates for each location, total containers available (=SUM of all containers find in each location)
  const calculateWarehouseOccupancy = () => {
    resetStates();
    locationRepository.get().then((locationData: LocationEntity[]) => {
      locationData.forEach((location: LocationEntity) => {
        if (location.warehouseId === selectedWarehouse) {
          setTotalLocations(prevState => prevState + +1);
          setTotalCapacity(prevState => prevState + (location.capacity ?? 0));
          setTotalContainers(prevState => prevState + (location.containers?.length ?? 0));
        }
      });
    });
  };
  //When totalCapacity and totalContainers are obtained, calculates Warehouse Occupancy
  useEffect(() => {
    if (totalContainers && totalCapacity) {
      setWarehouseOcuppancy(Math.round((totalContainers * 100) / totalCapacity ?? 0));
    }
  }, [totalContainers, totalCapacity]);

  const resetStates = () => {
    setTotalLocations(0);
    setWarehouseOcuppancy(0);
    setTotalCapacity(0);
    setTotalContainers(0);
  };

  return { calculateWarehouseOccupancy, totalLocations, warehouseOcuppancy };
};
