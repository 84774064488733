import { create } from "@movicoders/store";
import { IEditionStatesStore, IModifyMinMax } from "./IEditionStatesStore";
import { MODES } from "../../constants/Modes";
import { SELECTION_TYPES } from "../../constants/SelectionTypes";
import TileTemplate, { TileTemplateTypes } from "../../../../../domain/model/TileTemplate";

export const useEditionStatesStore = create<IEditionStatesStore>((set, get) => ({
  selectedMode: "VIEW",
  modifyingWH: false,
  endModifyingWH: "",
  selectionType: "SINGLE",
  canPlace: true,
  templates: new Map<TileTemplateTypes, TileTemplate>(),
  selectedTileTemplate: "STORAGE",
  modifyMinMax: { maxX: 0, minX: 0, maxZ: 0, minZ: 0 },
  modifyMaxLevel: 0,
  onSaveLoading: false,
  canUseZones: true,
  getSelectedMode: () => get().selectedMode,
  getModifyingWH: () => get().modifyingWH,
  getEndModifyingWH: () => get().endModifyingWH,
  getSelectionType: () => get().selectionType,
  getCanPlace: () => get().canPlace,
  getTemplate: (type: TileTemplateTypes) => get().templates.get(type),
  getSelectedTileTemplate: () => get().selectedTileTemplate,
  getModifyMinMax: () => get().modifyMinMax,
  getModifyMaxLevel: () => get().modifyMaxLevel,
  getOnSaveLoading: () => get().onSaveLoading,
  getCanUseZones: () => get().canUseZones,
  setSelectedMode: (newValue: MODES) => set((state) => ({ ...state, selectedMode: newValue })),
  setModifyingWH: (newValue: boolean) => set((state) => ({ ...state, modifyingWH: newValue })),
  setEndModifyingWH: (newValue: string) => set((state) => ({ ...state, endModifyingWH: newValue })),
  setSelectionType: (newValue: SELECTION_TYPES) => set((state) => ({ ...state, selectionType: newValue })),
  setCanPlace: (newValue: boolean) => set((state) => ({ ...state, canPlace: newValue })),
  setTemplate: (typeToReplace: TileTemplateTypes, newValue: TileTemplate) => set((state) => {
    const tempTemplate = get().templates.set(typeToReplace, newValue);
    return { ...state, templates: tempTemplate };
  }),
  setSelectedTileTemplate: (newValue: TileTemplateTypes) => set((state) => ({ ...state, selectedTileTemplate: newValue })),
  setModifyMinMax: (newValue: IModifyMinMax) => set((state) => ({ ...state, modifyMinMax: newValue })),
  setModifyMaxLevel: (newValue: number) => set((state) => ({ ...state, modifyMaxLevel: newValue })),
  setOnSaveLoading: (newValue: boolean) => set((state) => ({ ...state, onSaveLoading: newValue })),
  setCanUseZones: (newValue: boolean) => set((state) => ({ ...state, canUseZones: newValue })),
  checkTemplateTypeById: (typeToCheck: TileTemplateTypes, idToCheck: string | undefined) => get().templates.get(typeToCheck)?.id === idToCheck,
})).build();