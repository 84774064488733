import { create } from "@movicoders/store";
import { ICommon3DObjectsStore } from "./ICommon3DObjectsStore";
import { DoubleSide, Group, Mesh, MeshLambertMaterial, Object3D, TextureLoader, Vector3 } from "three";

export const useCommon3DObjectsStore = create<ICommon3DObjectsStore>((set, get) => ({
  highlightMesh: new Mesh(),
  pivotControls: new Group(),
  pivotControlsContent: new Group(),
  tilesBeingModified: [],
  startPoint: undefined,
  endPoint: undefined,
  wallMaterial: undefined,
  getHighlightMesh: () => get().highlightMesh,
  getPivotControls: () => get().pivotControls,
  getPivotControlsContent: () => get().pivotControlsContent,
  getTilesBeingModified: () => get().tilesBeingModified,
  getStartPoint: () => get().startPoint,
  getEndPoint: () => get().endPoint,
  getWallMaterial: () => {
    const wallMat = get().wallMaterial;
    if (wallMat === undefined) {
      const loader = new TextureLoader();
      const texture = loader.load("/assets/images/dangerous.svg");
      const material = new MeshLambertMaterial({ map: texture, side: DoubleSide });
      set((state) => ({ ...state, wallMaterial: material }));
      return material;
    } else return wallMat;
  },
  setHighlightMesh: (newValue: Mesh) => set((state) => ({ ...state, highlightMesh: newValue })),
  setPivotControls: (newValue: Group) => set((state) => ({ ...state, pivotControls: newValue })),
  setPivotControlsContent: (newValue: Group) => set((state) => ({ ...state, pivotControlsContent: newValue })),
  setTilesBeingModified: (newValue: Object3D[]) => set((state) => ({ ...state, tilesBeingModified: newValue })),
  setStartPoint: (newValue: Mesh | undefined) => set((state) => ({ ...state, startPoint: newValue })),
  setEndPoint: (newValue: Mesh | undefined) => set((state) => ({ ...state, endPoint: newValue })),
  setEndPointPosition: (newValue: Vector3) => set((state) => { state.endPoint?.position.copy(newValue); return state; }),
})).build();