import { COLORS } from "@theme";
import { SyntheticEvent } from "react";
import Icon from "@components/icons/icon";
import { useTranslation } from "react-i18next";
import { stepperStyles } from "../stepper-Styles";
import { CompanyCreateDTO } from "../company-create-model";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";

export const StepCompanyConfiguration = (props: {
  companyCreate: CompanyCreateDTO;
  onChangeAutocomplete: (event: SyntheticEvent<Element, Event>, value: string | null, id: string) => void;
}) => {
  const { t } = useTranslation();
  const styles = stepperStyles();
  const { companyCreate, onChangeAutocomplete } = props;

  return (
    <Grid container flexDirection="column" className="masterdata-box" rowSpacing={2}>
      <Grid item>
        <Typography sx={styles.categories_typography}>{t("step-configuration")}</Typography>
      </Grid>
      <Grid item>
        <Grid container alignItems="center" spacing={{ xs: 1.5 }}>
          <Grid item display="flex" xs={12} lg={3}>
            <Icon icon="company" color={COLORS.primary} />
            <Typography sx={styles.item_typography}>{t("step-configuration-company-license")}</Typography>
          </Grid>

          <Grid item sm={4} lg={3}>
            <Autocomplete
              id="license"
              getOptionLabel={option => (option === "" ? t("autocomplete.default.value") : t(option))}
              value={companyCreate.licence ?? ""}
              options={["", "IM", "WM", "WM_WITH_CONTAINER"]}
              onChange={(ev, val) => {
                onChangeAutocomplete(ev, val, "licence");
              }}
              renderInput={params => (
                <TextField
                  name={"licence"}
                  {...params}
                  label={t("step-configuration-company-license")}
                  className={"blue-border-input"}
                  InputProps={{
                    ...params.InputProps
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container alignItems="center" spacing={{ xs: 1.5 }}>
          <Grid item display="flex" xs={12} lg={3}>
            <Icon icon="company" color={COLORS.primary} />
            <Typography sx={styles.item_typography}>{t("step-configuration-company-warehouse-policy")}</Typography>
          </Grid>

          <Grid item sm={4} lg={3}>
            <Autocomplete
              id="warehousePolicy"
              getOptionLabel={option => (option === "" ? t("autocomplete.default.value") : t(option))}
              value={companyCreate.warehousePolicy ?? ""}
              options={["", "LIFO", "STRICT_FIFO", "TIME_INTERVAL_FIFO"]}
              onChange={(ev, val) => {
                onChangeAutocomplete(ev, val, "warehousePolicy");
              }}
              renderInput={params => (
                <TextField
                  name={"warehousePolicy"}
                  {...params}
                  label={t("step-configuration-company-warehouse-policy")}
                  className={"blue-border-input"}
                  InputProps={{
                    ...params.InputProps
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
