/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WMExplicitMovementRequest
 */
export interface WMExplicitMovementRequest {
    /**
     * 
     * @type {string}
     * @memberof WMExplicitMovementRequest
     */
    containerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof WMExplicitMovementRequest
     */
    destinationLocation?: string;
    /**
     * 
     * @type {string}
     * @memberof WMExplicitMovementRequest
     */
    operatorId?: string;
    /**
     * 
     * @type {string}
     * @memberof WMExplicitMovementRequest
     */
    warehouseId?: string;
}

/**
 * Check if a given object implements the WMExplicitMovementRequest interface.
 */
export function instanceOfWMExplicitMovementRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WMExplicitMovementRequestFromJSON(json: any): WMExplicitMovementRequest {
    return WMExplicitMovementRequestFromJSONTyped(json, false);
}

export function WMExplicitMovementRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WMExplicitMovementRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'containerCode': !exists(json, 'containerCode') ? undefined : json['containerCode'],
        'destinationLocation': !exists(json, 'destinationLocation') ? undefined : json['destinationLocation'],
        'operatorId': !exists(json, 'operatorId') ? undefined : json['operatorId'],
        'warehouseId': !exists(json, 'warehouseId') ? undefined : json['warehouseId'],
    };
}

export function WMExplicitMovementRequestToJSON(value?: WMExplicitMovementRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'containerCode': value.containerCode,
        'destinationLocation': value.destinationLocation,
        'operatorId': value.operatorId,
        'warehouseId': value.warehouseId,
    };
}

