/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyRequestDTO
 */
export interface CompanyRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof CompanyRequestDTO
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyRequestDTO
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyRequestDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyRequestDTO
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyRequestDTO
     */
    fiscalId?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyRequestDTO
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyRequestDTO
     */
    licence?: CompanyRequestDTOLicenceEnum;
    /**
     * 
     * @type {string}
     * @memberof CompanyRequestDTO
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyRequestDTO
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyRequestDTO
     */
    tenantName?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyRequestDTO
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyRequestDTO
     */
    warehousePolicy?: CompanyRequestDTOWarehousePolicyEnum;
}


/**
 * @export
 */
export const CompanyRequestDTOLicenceEnum = {
    Im: 'IM',
    Wm: 'WM',
    WmWithContainer: 'WM_WITH_CONTAINER'
} as const;
export type CompanyRequestDTOLicenceEnum = typeof CompanyRequestDTOLicenceEnum[keyof typeof CompanyRequestDTOLicenceEnum];

/**
 * @export
 */
export const CompanyRequestDTOWarehousePolicyEnum = {
    Lifo: 'LIFO',
    StrictFifo: 'STRICT_FIFO',
    TimeIntervalFifo: 'TIME_INTERVAL_FIFO'
} as const;
export type CompanyRequestDTOWarehousePolicyEnum = typeof CompanyRequestDTOWarehousePolicyEnum[keyof typeof CompanyRequestDTOWarehousePolicyEnum];


/**
 * Check if a given object implements the CompanyRequestDTO interface.
 */
export function instanceOfCompanyRequestDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CompanyRequestDTOFromJSON(json: any): CompanyRequestDTO {
    return CompanyRequestDTOFromJSONTyped(json, false);
}

export function CompanyRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyRequestDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': !exists(json, 'address') ? undefined : json['address'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'fiscalId': !exists(json, 'fiscalId') ? undefined : json['fiscalId'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'licence': !exists(json, 'licence') ? undefined : json['licence'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'tenantName': !exists(json, 'tenantName') ? undefined : json['tenantName'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'warehousePolicy': !exists(json, 'warehousePolicy') ? undefined : json['warehousePolicy'],
    };
}

export function CompanyRequestDTOToJSON(value?: CompanyRequestDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'companyName': value.companyName,
        'email': value.email,
        'firstName': value.firstName,
        'fiscalId': value.fiscalId,
        'image': value.image,
        'licence': value.licence,
        'password': value.password,
        'surname': value.surname,
        'tenantName': value.tenantName,
        'username': value.username,
        'warehousePolicy': value.warehousePolicy,
    };
}

