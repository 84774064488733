import { Grid } from "@mui/material";
import IUser from "@domain/interface/User";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonsDetail } from "@components/master-data-view/buttons/buttons-detail";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";

const UserActiveDialog = (props: { open: boolean; onClose: () => void; onActive: (user: IUser) => void; user: IUser }) => {
  const { t } = useTranslation();
  const { open, user, onClose, onActive } = props;

  const [pendingRequest, setPendingRequest] = useState<boolean>(false);

  useEffect(() => {
    setPendingRequest(false);
  }, [user]);

  return open ? (
    <AggregoDialog
      title={
        user.active ? t("users.deactivate.title", { user: user?.username }) : t("users.active.title", { user: user?.username })
      }
      onClose={onClose}>
      <Grid container flexDirection="column" className="masterdata-box" rowSpacing={2}>
        <Grid item>
          <div className="confirm-dialog-div-content">{user.active ? t("users.form.deactivate") : t("users.form.active")}</div>
        </Grid>
        <Grid item>
          <ButtonsDetail
            handleClose={onClose}
            handleSave={() => {
              setPendingRequest(true);
              onActive(user);
            }}
            mode={user.active ? "ACTIVE" : "INACTIVE"}
            pendingRequest={pendingRequest}
          />
        </Grid>
      </Grid>
    </AggregoDialog>
  ) : (
    <></>
  );
};

export default UserActiveDialog;
