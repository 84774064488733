import { useEffect, useState } from "react";
import { ROUTER_PATHS } from "@constants";
import { useResolve } from "@movicoders/di";
import { useSnackbar } from "@movicoders/ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LoginUseCase } from "@application/login/login-use-case";
import { ApplicationErrors } from "@application/ApplicationConstants";
import { DrawerService, IDrawerService } from "@domain/services/IDrawerService";
import { IImpersonationService, ImpersonationService } from "@domain/services/IImpersonationService";
import { ReportEnum } from "@pages/settings/settings-interfaces";
import { IUserService, UserService } from "@domain/services/IUserService";
import { WarehouseByTenantUseCase } from "@application/warehouse-by-tenant/warehouse-by-tenant-use-case";

export const TwoFactorModeEnum = {
  email: "EMAIL",
  opt: "OPT"
} as const;

export const useLoginStatus = () => {
  const { t } = useTranslation();
  const { show } = useSnackbar();
  const [showForceFinishingRemoteSession, setShowForceFinishingRemoteSession] = useState(false);
  const [isLoginError, setIsLoginError] = useState(false);
  const [twoFactor, setTwoFactor] = useState(false);
  const [twoFactorMode, setTwoFactorMode] = useState<string>("");

  const navigate = useNavigate();
  const loginUseCase = useResolve(LoginUseCase);
  const drawerService = useResolve<IDrawerService>(DrawerService);
  const impersonationState = useResolve<IImpersonationService>(ImpersonationService);
  const userService = useResolve<IUserService>(UserService);

  //UseCase to get warehouses by tenantID (by user tenant or impersonatedTenant)
  const warehousesByTenantUseCase = useResolve(WarehouseByTenantUseCase);

  useEffect(() => {
    if (userService.user?.enabledNFAOPT) {
      setTwoFactorMode(TwoFactorModeEnum.opt);
    } else {
      setTwoFactorMode(TwoFactorModeEnum.email);
    }
  }, [userService]);

  useEffect(() => {
    if (!userService.user?.roles.includes("SUPER_ADMIN")) {
      if (userService.user?.tenantId) {
        warehousesByTenantUseCase.get();
      } else {
        drawerService.persistedState && drawerService.saveDrawerState({ ...drawerService.persistedState, selectedWarehouse: "" });
      }
    }
  }, [userService.user?.tenantId]);

  const handleLogOut = async () => {
    try {
      localStorage.clear();
      await loginUseCase.forceFinishingExistingSession();
      show(t("logout.success"), "success");
    } catch (error) {
      show(t("logout.error"), "error");
      return null;
    } finally {
      sessionStorage.clear();
      window.location.reload();
    }
  };

  const onChangeTwoFactorMode = () => {
    if (twoFactorMode === TwoFactorModeEnum.email) {
      setTwoFactorMode(TwoFactorModeEnum.opt);
    } else if (twoFactorMode === TwoFactorModeEnum.opt) {
      setTwoFactorMode(TwoFactorModeEnum.email);
    }
  };

  const handleLogin = async (username: string, password: string) => {
    const storagedToken = (await sessionStorage.getItem("sessionToken")) ?? "";
    loginUseCase
      .execute(username, password)
      .then(() => {
        loginUseCase.loadUser();
        drawerService.clearDrawerState();
        if (userService.user && storagedToken !== null) {
          if (userService.user?.reportsPermissions?.includes(ReportEnum.WarehousesStatus)) {
            navigate(ROUTER_PATHS.whstatus);
          } else {
            navigate(ROUTER_PATHS.home);
          }
        }
        show(t("login.snackbar"), "success");
      })
      //catch will handle exception sent by loginUseCase.execute (cause users with mfa will not receive token)
      .catch((err: ApplicationErrors) => {
        //if user has any of the mfa options as true, two factor mode is activated and two-factor-card will be showed
        if (
          sessionStorage &&
          (sessionStorage.getItem("enabledNFAEmail") === "true" || sessionStorage.getItem("enabledNFAOPT") === "true")
        ) {
          setTwoFactor(true);
          //else, login will work as usually
        } else {
          if (err === ApplicationErrors.SESSION_ALREADY_IN_USE) {
            setShowForceFinishingRemoteSession(true);
          } else {
            setIsLoginError(true);
            show(t("login.snackbar.error"), "error");
          }
        }
      });
  };

  const handleTwoFactorLogin = (username: string, password: string, twoFactorCode: string) => {
    if (twoFactorMode === TwoFactorModeEnum.email) {
      loginMFAEmail(username, password, twoFactorCode);
    } else if (twoFactorMode === TwoFactorModeEnum.opt) {
      loginMFAOpt(username, password, twoFactorCode);
    }
  };

  const loginMFAEmail = async (username: string, password: string, twoFactorCode: string) => {
    const storagedToken = (await sessionStorage.getItem("sessionToken")) ?? "";
    loginUseCase
      .executeEmail(username, password, twoFactorCode)
      .then(() => {
        loginUseCase.loadUser();
        drawerService.clearDrawerState();
        if (userService.user && storagedToken !== null) {
          if (userService.user?.reportsPermissions?.includes(ReportEnum.WarehousesStatus)) {
            navigate(ROUTER_PATHS.whstatus);
          } else {
            navigate(ROUTER_PATHS.home);
          }
        }
        show(t("login.snackbar"), "success");
      })
      .catch((err: ApplicationErrors) => {
        if (err === ApplicationErrors.SESSION_ALREADY_IN_USE) {
          setShowForceFinishingRemoteSession(true);
        } else {
          setIsLoginError(true);
          show(t("login.snackbar.error"), "error");
        }
      });
  };

  const loginMFAOpt = async (username: string, password: string, twoFactorCode: string) => {
    const storagedToken = (await sessionStorage.getItem("sessionToken")) ?? "";
    loginUseCase
      .executeOpt(username, password, twoFactorCode)
      .then(() => {
        loginUseCase.loadUser();
        drawerService.clearDrawerState();
        if (userService.user && storagedToken !== null) {
          if (userService.user?.reportsPermissions?.includes(ReportEnum.WarehousesStatus)) {
            navigate(ROUTER_PATHS.whstatus);
          } else {
            navigate(ROUTER_PATHS.home);
          }
        }
        show(t("login.snackbar"), "success");
      })
      .catch((err: ApplicationErrors) => {
        if (err === ApplicationErrors.SESSION_ALREADY_IN_USE) {
          setShowForceFinishingRemoteSession(true);
        } else {
          setIsLoginError(true);
          show(t("login.snackbar.error"), "error");
        }
      });
  };

  const handleForceFinishingRemoteSession = (force: boolean) => {
    if (force) {
      loginUseCase
        .forceFinishingExistingSession()
        .then(() => {
          loginUseCase.loadUser();
          show(t("login.snackbar"), "success");
        })
        .catch(() => {
          show(t("login.snackbar.error"), "error");
          loginUseCase.clearLogin();
        });
    } else {
      loginUseCase.clearLogin();
    }
    impersonationState.clearImpersonationState();
    setShowForceFinishingRemoteSession(false);
  };

  const handleLoginInRemoteSession = () => {
    loginUseCase
      .loginInExistingSession()
      .then(() => {
        loginUseCase.loadUser();
        show(t("login.snackbar"), "success");
      })
      .catch(() => {
        show(t("login.snackbar.error"), "error");
        loginUseCase.clearLogin();
      });
    impersonationState.clearImpersonationState();
    setShowForceFinishingRemoteSession(false);
  };

  const resetTwoFactorMode = () => {
    userService.clear();
    sessionStorage.clear();
    setTwoFactor(false);
    setIsLoginError(false);
  };

  return {
    userService,
    handleLogin,
    onChangeTwoFactorMode,
    handleTwoFactorLogin,
    handleLoginInRemoteSession,
    handleForceFinishingRemoteSession,
    handleLogOut,
    isLoginError,
    setIsLoginError,
    showForceFinishingRemoteSession,
    twoFactor,
    twoFactorMode,
    resetTwoFactorMode
  };
};
