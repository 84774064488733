import { Grid } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import Operator from "@domain/model/Operator";
import { CustomTooltipCell } from "@components/tooltip/CustomTooltipCell";
import { TitlePopover } from "@components/master-data-view/card/components/title/title-popover";

export const operatorColumns = (
  t: (text: string) => string,
  handleEdit: (id: string) => void,
  handleActive: (operator: Operator) => void,
  mdMediaQuery?: boolean
): GridColDef[] => {
  return [
    {
      flex: 1,
      field: "code",
      headerName: t("operators.code"),
      disableColumnMenu: true,
      minWidth: mdMediaQuery ? undefined : 100,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1,
      field: "firstName",
      headerName: t("operators.first.name"),
      disableColumnMenu: true,
      minWidth: mdMediaQuery ? undefined : 100,
      valueGetter: ({ row }) => {
        return `${row.firstName} ${row.firstSurname} ${row.secondSurname}`;
      },
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0,
      field: "firstSurname",
      headerName: t("operators.first.surname"),
      disableColumnMenu: true,
      minWidth: 0,
      maxWidth: 0,
      type: "string",
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0,
      field: "secondSurname",
      headerName: t("operators.second.surname"),
      disableColumnMenu: true,
      minWidth: 0,
      maxWidth: 0,
      type: "string",
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1,
      field: "status",
      headerName: t("operators.status"),
      disableColumnMenu: true,
      minWidth: mdMediaQuery ? undefined : 70,
      valueGetter: ({ row }) => {
        return row.active ? t("operators.active") : t("operators.inactive");
      },
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.2,
      field: "actions",
      headerName: t("operators.actions"),
      disableColumnMenu: true,
      sortable: false,
      minWidth: mdMediaQuery ? undefined : 40,
      renderCell: data => (
        <Grid container justifyContent="flex-start">
          <TitlePopover
            handleEdit={() => handleEdit(data.row.id)}
            handleActive={() => handleActive(data.row)}
            activateTitle={data.row.active ? t("root.deactivate") : t("root.activate")}
          />
        </Grid>
      )
    }
  ];
};
