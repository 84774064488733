import { ProductChild } from "./ProductChild";
import { ProductEvent } from "./ProductEvent";
import { ProductState } from "./ProductState";
import { DTOConvertibleEntity } from "./GenericPage";
import { HistoricalEventDTO, ProductDTO } from "@clients/aggrego-proxy";

export class Product extends DTOConvertibleEntity<ProductDTO, Product> {
  public constructor();
  public constructor(obj?: Partial<Product>);
  public constructor(obj?: Partial<Product>) {
    super();
    obj && Object.assign(this, obj);
  }

  active?: boolean;
  id = "";
  serialNumber = "";
  createdAt = "";
  batch = "";
  materialCode = "";
  manufactureOrder = "";
  manufacturingDate?: Date;
  provider = "";
  state = ProductState.READY;
  uom = "";
  qty = 0;
  description = "";
  parents: Array<string> = [];
  children: Array<ProductChild> = [];
  events: Set<ProductEvent> = new Set();

  public fromDTO(obj: Partial<ProductDTO>): Product {
    let events = new Set<ProductEvent>();
    if (obj.historicalEvents) events = new Set(Array.from(obj.historicalEvents)?.map(ProductEvent.fromDTO));

    return new Product({
      id: obj.id ?? "",
      serialNumber: obj.serialNumber ?? "",
      manufacturingDate: new Date(obj.manufacturingDate ?? 0),
      manufactureOrder: obj.manufactureOrder,
      batch: obj.batch ?? "",
      materialCode: obj.materialCode ?? "",
      provider: obj.provider ?? "",
      state: ProductState[obj.state as keyof typeof ProductState] ?? ProductState.AGGREGATED,
      uom: obj.uom ?? "",
      qty: obj.qty ?? 0,
      description: obj.description ?? "",
      parents: (obj.parents ?? new Set<string>()) as unknown as [],
      children: obj.children?.map(child => ProductChild.fromDTO(child)),
      events
    });
  }

  public toDTO(arg: Product): ProductDTO {
    let events = new Set<HistoricalEventDTO>();
    if (arg.events) events = new Set(Array.from(arg.events)?.map(ev => ev.toDTO()));
    return {
      id: arg.id,
      serialNumber: arg.serialNumber,
      materialCode: arg.materialCode,
      provider: arg.provider,
      state: arg.state,
      manufacturingDate: arg.manufacturingDate,
      uom: arg.uom,
      qty: arg.qty,
      description: arg.description,
      parents: arg.parents,
      children: arg.children,
      historicalEvents: events,
      batch: arg.batch
    } as unknown as ProductDTO;
  }
}
