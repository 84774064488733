import { GLTFExporter } from "three/addons/exporters/GLTFExporter.js";

export const exportGLTF = (scene, warehouse) => {
  // Instantiate a exporter
  const exporter = new GLTFExporter();

  // Parse the input and generate the glTF output
  exporter.parse(
    scene,
    // called when the gltf has been generated
    function (gltf) {
      const link = document.createElement("a");
      link.style.display = "none";
      link.download = warehouse.name + ".gltf";
      link.target = "_blank";

      if (gltf instanceof ArrayBuffer) {
        const blob = new Blob([gltf], { type: "application/octet-stream" });
        link.href = URL.createObjectURL(blob);
      } else {
        const output = JSON.stringify(gltf, null, 2);
        const blob = new Blob([output], { type: "text/plain" });
        link.href = URL.createObjectURL(blob);
      }

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // called when there is an error in the generation
    function (/*error*/) {
      // Error
    }
  );
};
