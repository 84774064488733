import { useMaterialsStockConfig } from "./useMaterialsStockConfig";
import { useMaterialsStockPagination } from "./useMaterialsStockPagination";
import { useMaterialsStockDialog } from "./useMaterialsStockDialog";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { useResolve } from "@movicoders/di";
import { useMaterialsStockHandleData } from "./useMaterialsStockHandleData";

export const useMaterialsStockViewModel = () => {
  const settingsService = useResolve<ISettingsService>(SettingsService);

  const { data, families, loading, selectedWarehouseCode, stockRepository, getStockPaginated } = useMaterialsStockHandleData();

  const { handlePagination } = useMaterialsStockPagination(selectedWarehouseCode, getStockPaginated);
  const { latestStock, getConfigStock, setConfigStock } = useMaterialsStockConfig(selectedWarehouseCode, stockRepository);

  const { exportingPaginatedData, handleDialogOpen, materialDetailOpen, onCloseDialog } =
    useMaterialsStockDialog(stockRepository);

  return {
    materialStockData: data,
    families,
    materialDetailOpen,
    exportingPaginatedData,
    latestStock,
    loading,
    handlePagination,
    handleDialogOpen,
    onCloseDialog,
    setConfigStock,
    getConfigStock,
    settingsService
  };
};
