import { TIMEOUT_ID } from "@constants";
import { useSnackbar } from "@movicoders/ui";
import { useTranslation } from "react-i18next";
import Warehouse from "@domain/model/Warehouse";
import { LastPageState } from "@domain/model/LastPageState";
import { useWarehouseFormViewModel } from "../useWarehousesFormViewModel";
import { ListWarehousesPaginatedRequest } from "@clients/aggrego-proxy/apis/WarehousesApi";

export const useWarehouseSave = (
  currentPagePersistedState: LastPageState,
  selectedWarehouse: Warehouse,
  save: (entity: Warehouse) => Promise<void>,
  fetchOne: (id: string) => Promise<void>,
  warehouseGetPaginated: (criteria: ListWarehousesPaginatedRequest) => Promise<void>,
  getCurrentStatus: (status: "ACTIVE" | "INACTIVE" | "ALL") => boolean | undefined
) => {
  const { t } = useTranslation();
  const { show } = useSnackbar();

  const handleEdit = (id: string) => {
    fetchOne(id).catch(() => {
      show(
        t("loading.detail.error", {
          warehouse: selectedWarehouse.name ? ` "${selectedWarehouse.name}"` : "",
          mdNameThe: t("the.warehouse")
        }),
        "error"
      );
      warehouse.id = TIMEOUT_ID;
    });
  };

  const { handleSave, onChange, onChangeNumeric, warehouse } = useWarehouseFormViewModel(
    currentPagePersistedState,
    selectedWarehouse,
    save,
    warehouseGetPaginated,
    getCurrentStatus
  );

  return {
    warehouse,
    handleSave,
    handleEdit,
    onChange,
    onChangeNumeric
  };
};
