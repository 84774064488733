/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomOperatorFilter
 */
export interface CustomOperatorFilter {
    /**
     * 
     * @type {boolean}
     * @memberof CustomOperatorFilter
     */
    active?: boolean;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof CustomOperatorFilter
     */
    caseInsensitive?: { [key: string]: boolean; };
    /**
     * 
     * @type {string}
     * @memberof CustomOperatorFilter
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomOperatorFilter
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomOperatorFilter
     */
    firstSurname?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomOperatorFilter
     */
    searchText?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomOperatorFilter
     */
    secondSurname?: string;
}

/**
 * Check if a given object implements the CustomOperatorFilter interface.
 */
export function instanceOfCustomOperatorFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomOperatorFilterFromJSON(json: any): CustomOperatorFilter {
    return CustomOperatorFilterFromJSONTyped(json, false);
}

export function CustomOperatorFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomOperatorFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'caseInsensitive': !exists(json, 'caseInsensitive') ? undefined : json['caseInsensitive'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'firstSurname': !exists(json, 'firstSurname') ? undefined : json['firstSurname'],
        'searchText': !exists(json, 'searchText') ? undefined : json['searchText'],
        'secondSurname': !exists(json, 'secondSurname') ? undefined : json['secondSurname'],
    };
}

export function CustomOperatorFilterToJSON(value?: CustomOperatorFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'caseInsensitive': value.caseInsensitive,
        'code': value.code,
        'firstName': value.firstName,
        'firstSurname': value.firstSurname,
        'searchText': value.searchText,
        'secondSurname': value.secondSurname,
    };
}

