import { ROUTER_PATHS } from "@constants";
import { useResolve } from "@movicoders/di";
import { useSnackbar } from "@movicoders/ui";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHandleOperator } from "@hooks/useHandleOperator";
import IMMEntryWithDesc from "@domain/model/IMMEntryWithDesc";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { IMMaterialEntryDTO, MaterialDTO } from "@clients/aggrego-proxy";
import { useHandleSelectedWarehouse } from "@hooks/useHandleSelectedWarehouse";
import { StockRepository } from "@infrastructure/repositories/stock-repository";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";

export const useInventoryMaterialViewModel = (
  iMMaterialList: IMMEntryWithDesc[],
  setIMMaterialList: Dispatch<SetStateAction<IMMEntryWithDesc[]>>
) => {
  const navigate = useNavigate();
  const { show } = useSnackbar();
  const { t } = useTranslation();
  const settingsService = useResolve<ISettingsService>(SettingsService);

  const obtainStockRepository = useResolve<StockRepository>(StockRepository);

  const { selectedWarehouseCode } = useHandleSelectedWarehouse();
  const { operatorState } = useHandleOperator();

  const [isSyncronizing, setIsSyncronizing] = useState(false);

  const [selectedIMMaterial, setSelectedIMMaterial] = useState<IMMEntryWithDesc | undefined>(undefined);
  const [iMMaterialDTOList, setIMMaterialDTOList] = useState<IMMaterialEntryDTO[]>([]);

  const [allSynchronized, setAllSynchronized] = useState<IMMaterialEntryDTO[]>([]);
  const [passedSynchronized, setPassedSynchronized] = useState<IMMaterialEntryDTO[]>([]);
  const [failedSynchronized, setFailedSynchronized] = useState<IMMaterialEntryDTO[]>([]);

  const onChangeAutocomplete = (value: MaterialDTO) => {
    obtainStockRepository
      .getByMaterial({ materialCode: value.code ?? "", warehouseCode: selectedWarehouseCode ?? "" })
      .then(result => {
        setSelectedIMMaterial({
          id: value.id,
          materialCode: value.code,
          materialDescription: value.name,
          qty: result.qty,
          uom: value.uom,
          entriesQty: 0,
          comment: "",
          warehouseCode: selectedWarehouseCode
        });
      });
  };

  const handleSynchronize = () => {
    setIsSyncronizing(true);

    const updatedIMMaterialDTOList = iMMaterialList.map((entry: IMMEntryWithDesc) => {
      return IMMEntryWithDesc.toClientImMEntryForAdjustment(entry);
    });

    setIMMaterialDTOList(updatedIMMaterialDTOList);

    obtainStockRepository
      .getAdjustedMaterialStock({
        iMMaterialEntryDTO: updatedIMMaterialDTOList,
        operatorId: operatorState?.operator?.id
      })
      .then(response => {
        if (response?.content) {
          const content = response.content as { [key: string]: string };
          Object.entries(content).forEach(([key, value]) => {
            if (value.includes("INVENTORY_") || value.includes("No action required")) {
              const passedMaterial: IMMEntryWithDesc = iMMaterialList.find(item => item.materialCode === key) ?? {};
              setAllSynchronized(prevList => [...prevList, { ...passedMaterial, errorMessage: "OK" }]);
              setPassedSynchronized(prevList => [...prevList, { ...passedMaterial, errorMessage: "OK" }]);
            } else {
              const failedMaterial: IMMEntryWithDesc = iMMaterialList.find(item => item.materialCode === key) ?? {};
              setAllSynchronized(prevList => [...prevList, { ...failedMaterial, errorMessage: value }]);
              setFailedSynchronized(prevList => [...prevList, { ...failedMaterial, errorMessage: value }]);
            }
          });
        }
        setIsSyncronizing(false);
      })
      .catch(error => {
        show(t("inbounds.error"), "error");
      });
  };

  useEffect(() => {
    allSynchronized.length !== 0 &&
      allSynchronized.length === iMMaterialList.length &&
      navigate(ROUTER_PATHS.inventoryMaterialSync, {
        state: { all: allSynchronized, passed: passedSynchronized, failed: failedSynchronized }
      });
  }, [allSynchronized]);

  return {
    isSyncronizing,
    selectedIMMaterial,
    setSelectedIMMaterial,
    onChangeAutocomplete,
    handleSynchronize,
    iMMaterialDTOList,
    settingsService
  };
};
