import { COLORS } from "@theme";
import { globalFilter } from "@constants";
import Icon from "../../../../icons/icon";
import { useResolve } from "@movicoders/di";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useGridSearchBar } from "./hooks/useGridSearchBar";
import { LastPageState } from "@domain/model/LastPageState";
import { Paper, InputBase, InputAdornment, IconButton } from "@mui/material";
import { ILastPageService, LastPageService } from "@domain/services/ILastPageService";

export const DataGridDisplaySearchBar = (props: {
  searchRecoveredValue: string;
  setSearchRecoveredValue: Dispatch<SetStateAction<string>>;
}) => {
  const { t } = useTranslation();
  const { searchRecoveredValue, setSearchRecoveredValue } = props;

  const { searchValue, searchInput, handleSearchBar } = useGridSearchBar(
    searchRecoveredValue,
    setSearchRecoveredValue
  );

  const lastPageService = useResolve<ILastPageService>(LastPageService);
  const lastPagePersistedState = lastPageService.persistedState ?? new LastPageState();

  useEffect(() => {
    if (lastPagePersistedState && lastPagePersistedState.filters) {
      handleSearchBar((lastPagePersistedState.filters as unknown as globalFilter).searchText as unknown as string);
    }
  }, [lastPagePersistedState.filters]);

  return (
    <Paper
      component="form"
      className="search-bar"
      sx={{
        maxWidth: "15rem"
      }}
      onSubmit={event => {
        event.preventDefault();
        handleSearchBar(searchValue);
        searchInput.current?.blur();
      }}>
      <Icon className="search-icon" icon="search" size="1.6rem" color="inherit" style={{ alignSelf: "center" }} />
      <InputBase
        id="search-bar"
        placeholder={t("search.placeholder")}
        value={searchValue}
        onChange={e => handleSearchBar(e.target.value)}
        inputProps={{ ref: searchInput, enterKeyHint: "search" }}
        endAdornment={
          searchValue ? (
            <InputAdornment position="end">
              <IconButton id="search-clear-button" onClick={() => handleSearchBar("")} size="small">
                <Icon className="clear-icon" icon="close" color="inherit" />
              </IconButton>
            </InputAdornment>
          ) : undefined
        }
        sx={{
          width: "100%",
          fontSize: "small",
          justifyContent: "space-between",
          color: COLORS.darkgray,
          marginLeft: "0.7rem"
        }}
      />
    </Paper>
  );
};
