import { SxProps, Theme } from "@mui/system";

export const dataGridDisplayStyles = () => {
  const data_grid_display_container: SxProps<Theme> = {
    width: "100%",
    padding: "1rem",
    marginTop: "1rem",
    paddingBottom: "1.7rem",
    boxShadow: "0px 3px 6px #00000029"
  };

  return {
    data_grid_display_container
  };
};
