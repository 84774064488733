import { ChangeEvent } from "react";
import { styled } from "@mui/material/styles";
import { Switch, SwitchProps } from "@mui/material";
import { COLORS } from "@theme";

interface CustomSwitchProps extends SwitchProps {
  isEnabled: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  name: string;
}

export const CustomSwitch = styled(({ isEnabled, onChange, name, ...props }: CustomSwitchProps) => (
  <Switch
    data-value={isEnabled}
    inputProps={{ "data-value": isEnabled } as React.InputHTMLAttributes<HTMLInputElement>}
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    checked={isEnabled}
    onChange={onChange}
    name={name}
  />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: COLORS.secondary,
        opacity: 1,
        border: 0
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5
      }
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff"
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100]
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7
    }
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500
    })
  }
}));
