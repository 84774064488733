import { UsersApi } from "@clients/aggrego-proxy";

export class RecoverPasswordUseCase {
  constructor(private readonly userClient: UsersApi) {}

  public async execute(username: string) {
    const response = await this.userClient.recoverUsername({ body: username as unknown as object });
    return response;
  }
}
