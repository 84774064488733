export const cifHelper = (cif: string) => {
  const cifRegex = /^([ABCDEFGHNPQRSUVW])(\d{7})([0-9A-J])$/;

  const calculateLastDigit = () => {
    const evenSum = Number(cif.charAt(2)) + Number(cif.charAt(4)) + Number(cif.charAt(6));
    const oddSum =
      calculate1DigitValue(Number(cif.charAt(1))) +
      calculate1DigitValue(Number(cif.charAt(3))) +
      calculate1DigitValue(Number(cif.charAt(5))) +
      calculate1DigitValue(Number(cif.charAt(7)));
    const sum = evenSum + oddSum;
    const lastDigit = 10 - Number(sum.toString().slice(-1));
    return lastDigit !== 0 ? lastDigit : 0;
  };

  const calculate1DigitValue = (value: number) => {
    let oddSum = 0;
    let n = value;
    n *= 2;
    oddSum += n < 10 ? n : n - 9;
    return oddSum;
  };

  const lastDigit = () => {
    const digitNumber = calculateLastDigit();
    if ("NPQRSW".includes(cif.charAt(0))) {
      return "JABCDEFGHI".at(digitNumber);
    } else {
      return digitNumber.toString();
    }
  };

  return cif.length === 0 || (cifRegex.test(cif) && lastDigit()?.includes(cif.charAt(8)));
};
