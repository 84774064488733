import { ReportApi } from "../api/report-api";
import { DomainPage } from "@movicoders/domain";
import { InvReportDTO } from "@domain/model/InvReport";
import { GetInventoryReportPaginatedRequest } from "@clients/aggrego-proxy";

export class InventoryReportRepository {
  constructor(private readonly reportApi: ReportApi) {}

  getPaginated(pageCriteria: GetInventoryReportPaginatedRequest): Promise<DomainPage<InvReportDTO>> {
    return this.reportApi.getInventoryReportPaginated(pageCriteria);
  }
}
