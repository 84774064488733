/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockReportDTO
 */
export interface StockReportDTO {
    /**
     * 
     * @type {string}
     * @memberof StockReportDTO
     */
    containersIds?: string;
    /**
     * 
     * @type {Date}
     * @memberof StockReportDTO
     */
    date?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof StockReportDTO
     */
    family?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof StockReportDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof StockReportDTO
     */
    materialCode?: string;
    /**
     * 
     * @type {string}
     * @memberof StockReportDTO
     */
    materialId?: string;
    /**
     * 
     * @type {string}
     * @memberof StockReportDTO
     */
    materialName?: string;
    /**
     * 
     * @type {number}
     * @memberof StockReportDTO
     */
    qty?: number;
    /**
     * 
     * @type {string}
     * @memberof StockReportDTO
     */
    uom?: string;
    /**
     * 
     * @type {string}
     * @memberof StockReportDTO
     */
    warehouse?: string;
}

/**
 * Check if a given object implements the StockReportDTO interface.
 */
export function instanceOfStockReportDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StockReportDTOFromJSON(json: any): StockReportDTO {
    return StockReportDTOFromJSONTyped(json, false);
}

export function StockReportDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockReportDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'containersIds': !exists(json, 'containersIds') ? undefined : json['containersIds'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'family': !exists(json, 'family') ? undefined : json['family'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'materialCode': !exists(json, 'materialCode') ? undefined : json['materialCode'],
        'materialId': !exists(json, 'materialId') ? undefined : json['materialId'],
        'materialName': !exists(json, 'materialName') ? undefined : json['materialName'],
        'qty': !exists(json, 'qty') ? undefined : json['qty'],
        'uom': !exists(json, 'uom') ? undefined : json['uom'],
        'warehouse': !exists(json, 'warehouse') ? undefined : json['warehouse'],
    };
}

export function StockReportDTOToJSON(value?: StockReportDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'containersIds': value.containersIds,
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'family': value.family,
        'id': value.id,
        'materialCode': value.materialCode,
        'materialId': value.materialId,
        'materialName': value.materialName,
        'qty': value.qty,
        'uom': value.uom,
        'warehouse': value.warehouse,
    };
}

