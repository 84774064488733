import { IconButton, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { COLORS } from "../../../../theme";
import Icon from "../../../components/icons/icon";
import { StockDTO } from "../../../../domain/model/Stock";
import { CustomTooltipCell } from "../../../components/tooltip/CustomTooltipCell";
import { filterValueTypes } from "../../../../constants";

export const stockColumns = (
  t: (text: string) => string,
  handleDialogOpen: (material: StockDTO) => Promise<void>,
  lgMediaQuery: boolean
): GridColDef[] => [
  {
    flex: 1,
    field: "id",
    headerName: t("stock.id"),
    disableColumnMenu: true,
    minWidth: lgMediaQuery ? undefined : 100,
    renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
  },
  {
    flex: 1,
    field: "materialCode",
    headerName: t("stock.code"),
    disableColumnMenu: true,
    minWidth: lgMediaQuery ? undefined : 100,
    renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
  },
  {
    flex: 1,
    field: "materialName",
    headerName: t("stock.name"),
    disableColumnMenu: true,
    minWidth: lgMediaQuery ? undefined : 100,
    renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
  },
  {
    flex: 1,
    field: "qty",
    headerName: t("stock.qty"),
    disableColumnMenu: true,
    minWidth: lgMediaQuery ? undefined : 100,
    type: "number",
    renderCell: ({ row }) => {
      return (
        <CustomTooltipCell tooltipTitle={row.qty + " " + row.uom}>
          <Typography component="div" variant="column_table_typography">
            <span style={{ color: "#4CAC1C", fontSize: "0.75rem", fontWeight: "600" }}>{row.qty}</span>
            {" " + row.uom}
          </Typography>
        </CustomTooltipCell>
      );
    }
  },
  {
    flex: 1,
    field: "family",
    headerName: t("stock.family"),
    disableColumnMenu: true,
    minWidth: lgMediaQuery ? undefined : 100,
    type: "select-one" as filterValueTypes,
    valueGetter: ({ row }) => {
      return row.family.map((family: string, index: number) => (index !== row.family.length - 1 ? `${family}` : ` ${family} `));
    },
    renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
  },
  {
    flex: 0.5,
    field: "actions",
    headerName: t("stock.actions"),
    disableColumnMenu: true,
    sortable: false,
    renderCell: data => (
      <Tooltip title={t("stock.actions.actual.state")}>
        <IconButton onClick={() => handleDialogOpen(data.row)}>
          <Icon icon="view-status" color={COLORS.secondary} />
        </IconButton>
      </Tooltip>
    )
  }
];
