import Material from "@domain/model/Material";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { Card } from "@components/master-data-view/card/card";
import { FamilyDTO } from "@clients/aggrego-proxy/models/FamilyDTO";

const MaterialCard = (props: {
  material: Material;
  handleEdit: (id: string) => void;
  handleActive: (item: Material) => void;
}) => {
  const { t } = useTranslation();
  const { material, handleEdit, handleActive } = props;

  const generateFamilyNames = (families: FamilyDTO[]) => {
    let concatNames = "";
    families.forEach((family: FamilyDTO) => (concatNames += family.name + " \n"));
    return concatNames;
  };

  const renderChild = (name: string, value: string, xs: number) => {
    return (
      <Grid item xs={xs} md={2}>
        <div className="title-content" data-cy={`material-card-${name}-container`}>
          <Typography variant="cardlabel" display="block">
            {t(name)}
          </Typography>
          <Typography data-cy="value" variant="cardvalue" whiteSpace="pre-line">
            {value}
          </Typography>
        </div>
      </Grid>
    );
  };

  const rendererCard = (data: Material) => {
    return (
      <Card
        name={"material.material"}
        isActive={material.active}
        key={material.id}
        popover={{
          handleEdit: () => handleEdit(material.id ?? ""),
          handleActive: () => handleActive(material),
          activateTitle: material.active ? t("root.deactivate") : t("root.activate")
        }}
        id={"id-card-" + material.id}
        nameValue={material.name}>
        <Grid container spacing={1}>
          {renderChild("material.code", material.code ?? "", 12)}
          {renderChild("material.family", generateFamilyNames(material.families) ?? "", 12)}
          {renderChild("material.price", material.unitPrice + "€" ?? "0", 4)}
          {renderChild("material.total.value", material.unitPrice * material.minStock + "€" ?? "0", 4)}
          <Grid item xs={4} md={3}>
            <div className="title-content">
              <Typography variant="cardlabel" display="block">
                {t("material.quant")}
              </Typography>
              <Typography variant="cardvalue" whiteSpace="pre-line">
                <span style={{ color: material.active ? "#4CAC1C" : "inherit" }}>{material.minStock}</span>
                &nbsp;{t(`material.measure.${material.uom.toLowerCase()}`)}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Card>
    );
  };
  return rendererCard(material);
};

export default MaterialCard;
