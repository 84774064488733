import { GridValidRowModel } from "@mui/x-data-grid";
import { Grid, Theme, useMediaQuery } from "@mui/material";
import { useHandleToolbarFilters } from "../hooks/useHandleToolbarFilters";
import { ColumnsMenu } from "./components/data-grid-display-columns-button";
import { DefaultToolbarStatusOptions } from "./config/data-grid-display-status";
import { DataGridDisplayToolbarProps } from "./config/data-grid-display-toolbar-props";
import { DataGridDisplayActiveMenu } from "./components/data-grid-display-active-menu";
import { DataGridDisplayDownloadMenu } from "./components/data-grid-display-download-menu";
import { DataGridDisplaySearchBar } from "./components/searchbar/data-grid-display-search-bar";
import { DataGridDisplayFilter } from "./components/filter-button/data-grid-display-filter-button";

export const CustomToolbar = (props: DataGridDisplayToolbarProps) => {
  const {
    columns,
    toolbarProps,
    PdfTable,
    data,
    currentViewName,
    currentData,
    setCurrentData,
    unsortedData,
    setUnsortedData,
    searchBarRecoveredFilter
  } = props;

  const matchesXS = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"), { noSsr: true });

  const {
    selectedStatus,
    currentFieldFilter,
    setCurrentFieldFilter,
    setSearchBarValue,
    setSelectedStatus,
    customSearchBarFilterProps,
    getPDFData,
    handleDownloadCSV,
    handlePrintScreen,
    isFieldVisible,
    onChangeColumnVisibility
  } = useHandleToolbarFilters(toolbarProps, data, currentViewName, currentData, setCurrentData, unsortedData, setUnsortedData);

  const renderSearchBar = () => {
    if (toolbarProps.isSearchBarHidden) return <Grid item sm={4} />;
    if (toolbarProps.customSearchBar)
      return toolbarProps?.customSearchBar(value => {
        customSearchBarFilterProps.setData(
          selectedStatus,
          toolbarProps?.customToolbarFieldFilter ? toolbarProps?.customToolbarFieldFilter : DefaultToolbarStatusOptions,
          (initialData: GridValidRowModel[]) => {
            return toolbarProps?.customSearchBarFilter ? toolbarProps?.customSearchBarFilter(initialData, value) : [];
          },
          currentFieldFilter
        );
      });
    return (
      <Grid item xs={12} sm={3}>
        <DataGridDisplaySearchBar
          searchRecoveredValue={searchBarRecoveredFilter ?? ""}
          setSearchRecoveredValue={setSearchBarValue}
        />
      </Grid>
    );
  };

  return (
    <Grid
      container
      id="table-toolbar-grid-container"
      justifyContent="space-between"
      alignItems="center"
      direction="row"
      rowSpacing={1}
      sx={{ mb: 1 }}
      wrap="wrap">
      {renderSearchBar()}

      <Grid
        item
        container
        id="buttons-grid-item-container"
        spacing={1}
        width="fit-content"
        justifyContent={matchesXS ? "center" : "flex-end"}
        sx={{ "> div:first-of-type": { paddingLeft: 0 } }}>
        {!toolbarProps?.isActiveFilterHidden && (
          <Grid item id="gi-table-active-menu">
            <DataGridDisplayActiveMenu
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              toolbarFieldFilter={
                toolbarProps?.customToolbarFieldFilter ? toolbarProps?.customToolbarFieldFilter : DefaultToolbarStatusOptions
              }
            />
          </Grid>
        )}
        {!toolbarProps?.isFilterIconButtonHidden && (
          <Grid item id="gi-table-filter">
            <DataGridDisplayFilter
              disabledFilters={toolbarProps?.disableFilterByFields ?? []}
              setCurrentFilter={setCurrentFieldFilter}
              columns={columns}
              multipleSelectOptions={toolbarProps?.multipleSelectOptions}
              isFilterActive={currentFieldFilter?.isFilterActive ?? false}
              isTable={true}
            />
          </Grid>
        )}
        {!toolbarProps?.isColumnsMenuIconButtonHidden && (
          <Grid item>
            <ColumnsMenu
              onChangeColumnVisibility={onChangeColumnVisibility}
              isFieldVisible={isFieldVisible}
              uncontrolledColumns={toolbarProps?.disableColumnsVisibilityByField ?? []}
              columns={columns}
            />
          </Grid>
        )}
        {!toolbarProps?.isDownloadIconButtonHidden && (
          <Grid item id="gi-download-menu">
            <DataGridDisplayDownloadMenu
              columns={columns}
              disabledFields={toolbarProps?.disableFieldsInDownload ?? []}
              handleDownloadCSV={handleDownloadCSV}
              handlePrintScreen={handlePrintScreen}
              getDataToPDF={getPDFData}
              PdfTable={PdfTable}
              parseCSVInDownload={toolbarProps?.parseCSVInDownload}
            />
          </Grid>
        )}
        {toolbarProps?.addHTMLRight && <Grid item>{toolbarProps.addHTMLRight}</Grid>}
      </Grid>
      {toolbarProps?.detailComponent && (
        <Grid item xs={12}>
          {toolbarProps.detailComponent}
        </Grid>
      )}
    </Grid>
  );
};
