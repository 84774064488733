import { HierarchyState } from "../model/HierarchyState";


export interface IHierarchyService {
  persistedState?: HierarchyState;
  saveHierarchyState(newState: HierarchyState): void;
  clearHierarchyState(): void;
}

export const HierarchyService = Symbol.for("HierarchyService");
