import { useResolve } from "@movicoders/di";
import { LoginUseCase } from "@application/login/login-use-case";
import { IUserService, UserService } from "@domain/services/IUserService";
import { User } from "@domain/model/User";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "@movicoders/ui";
import { useState } from "react";

export const useMFASelectionViewModel = () => {
  const { t } = useTranslation();
  const { show } = useSnackbar();

  const userService = useResolve<IUserService>(UserService);
  const currentUser = userService.user;
  const loginUseCase = useResolve(LoginUseCase);
  const [loading, setLoading] = useState(false);
  const [qrCode, setQrCode] = useState<Blob | undefined>();

  const onChangeNFAEmailOption = async () => {
    setLoading(true);
    try {
      const loginResponse = await loginUseCase.switchMFAEmailOption();
      userService.save(new User({ ...currentUser, enabledNFAEmail: loginResponse.enabledNFAEmail } as Partial<User>));
      show(
        loginResponse.enabledNFAEmail
          ? t("profile.mfa.email.switch.activation.success")
          : t("profile.mfa.email.switch.deactivation.success"),
        "success"
      );
    } catch {
      show(t("profile.mfa.email.switch.error"), "error");
    } finally {
      setLoading(false);
    }
  };

  const onChangeNFAOPTOption = async () => {
    setLoading(true);
    try {
      const loginResponse = await loginUseCase.switchMFAOptOption();
      userService.save(new User({ ...currentUser, enabledNFAOPT: loginResponse.enabledNFAOPT } as Partial<User>));
      show(
        loginResponse.enabledNFAOPT
          ? t("profile.mfa.opt.switch.activation.success")
          : t("profile.mfa.opt.switch.deactivation.success"),
        "success"
      );
      if (loginResponse.secretNFA) {
        generateQRCode(loginResponse.secretNFA);
      } else {
        setQrCode(undefined);
      }
    } catch {
      show(t("profile.mfa.opt.switch.error"), "error");
    } finally {
      setLoading(false);
    }
  };

  const generateQRCode = async (secretNFA: string) => {
    try {
      const resultQrCode = await loginUseCase.generateQrCode(secretNFA);
      setQrCode(resultQrCode);
    } catch {
      onChangeNFAOPTOption();
      show(t("profile.mfa.opt.qr.generation.error"), "error");
    }
  };

  return { loading, qrCode, currentUser, onChangeNFAEmailOption, onChangeNFAOPTOption };
};
