import { ChangeEvent, useState } from "react";
import { Product } from "@domain/model/Product";
import { ChildrenFormErrors } from "../form-children/ChildrenForm";

export const useEditChildViewmodel = (child: Product, onSave: (product: Product) => void) => {
  const [editingChild, setEditingChild] = useState<Product>(child);
  const [errors, setErrors] = useState<ChildrenFormErrors>(new ChildrenFormErrors());

  const defaultErrorMap = new Map<string, boolean>([
    ["materialCode", false],
    ["serialNumber", false],
    ["batch", false],
    ["qty", false],
    ["uom", false]
  ]);

  const [errorMapState, setErrorMapState] = useState(defaultErrorMap);

  const validatingEditingChild = () => {
    const childErrors = defaultErrorMap;
    if (editingChild.qty === undefined || editingChild.qty <= 0) {
      childErrors.set("qty", true);
    }
    if (editingChild.uom === undefined || editingChild.uom.trim() === "") {
      childErrors.set("uom", true);
    }
    if (editingChild.materialCode === undefined || editingChild.materialCode.trim() === "") {
      childErrors.set("materialCode", true);
    }
    if (
      (editingChild.batch === undefined || editingChild.batch.trim() === "") &&
      (editingChild.serialNumber === undefined || editingChild.serialNumber.trim() === "")
    ) {
      childErrors.set("batch", true);
      childErrors.set("serialNumber", true);
    }
    setErrorMapState(childErrors);
    const isValidChild = Array.from(childErrors.values()).every(value => value === false);
    isValidChild && save();
  };

  const inputTextChanged = (field: keyof Product) => (e: ChangeEvent<HTMLInputElement>) => updateChild(field, e.target.value);

  const numericInputChanged = (field: keyof Product) => (e: ChangeEvent<HTMLInputElement>) => {
    const filteredValue = e.target.value.replace(/[^0-9.]+/g, "");
    const numericValue = parseFloat(filteredValue);
    const maxValue = 1000000000;

    // If the user tries to input a point after another point the value is not changed.
    if (!filteredValue.endsWith("..")) {
      // When the value exceeds maxValue it stays at said maxValue.
      if (numericValue > maxValue) updateChild(field, maxValue);
      // When the value is NaN (possibly "") the value turns into 0.
      else if (isNaN(numericValue)) updateChild(field, 0);
      // When the last char is '.', the value is saved as an string to keep that '.'.
      else if (filteredValue.endsWith(".") && filteredValue.indexOf(".") === filteredValue.lastIndexOf("."))
        updateChild(field, filteredValue);
      else {
        // The value is rounded to 3 decimals if needed.
        const roundedValue = RegExp(/\d+\.\d{3}/).exec(filteredValue)?.[0];
        // When the last char is '0', the value is saved as an string to keep that number.
        if (filteredValue.indexOf(".") !== -1 && roundedValue === undefined && filteredValue.endsWith("0"))
          updateChild(field, filteredValue);
        else updateChild(field, roundedValue ?? numericValue);
      }
    }
  };

  const autocompleteInputChange = (field: keyof Product, value: string) => {
    updateChild(field, value);
  };

  const updateChild = (attr: keyof Product, value: Product[keyof Product]) => {
    const updatedChild = {
      ...editingChild,
      [attr]: value as unknown as typeof attr
    };

    setEditingChild(updatedChild as unknown as Product);
  };

  const save = () => {
    onSave(editingChild);
    setErrors(new ChildrenFormErrors());
  };

  return {
    editingChild,
    inputTextChanged,
    numericInputChanged,
    autocompleteInputChange,
    validatingEditingChild,
    errors,
    errorMapState
  };
};
