/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChildDTO } from './ChildDTO';
import {
    ChildDTOFromJSON,
    ChildDTOFromJSONTyped,
    ChildDTOToJSON,
} from './ChildDTO';
import type { HistoricalEventDTO } from './HistoricalEventDTO';
import {
    HistoricalEventDTOFromJSON,
    HistoricalEventDTOFromJSONTyped,
    HistoricalEventDTOToJSON,
} from './HistoricalEventDTO';

/**
 * 
 * @export
 * @interface ProductDTO
 */
export interface ProductDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ProductDTO
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    batch?: string;
    /**
     * 
     * @type {Array<ChildDTO>}
     * @memberof ProductDTO
     */
    children?: Array<ChildDTO>;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    description?: string;
    /**
     * 
     * @type {Set<HistoricalEventDTO>}
     * @memberof ProductDTO
     */
    historicalEvents?: Set<HistoricalEventDTO>;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    locationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    manufactureOrder?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProductDTO
     */
    manufacturingDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    materialCode?: string;
    /**
     * 
     * @type {Array<ProductDTO>}
     * @memberof ProductDTO
     */
    parents?: Array<ProductDTO>;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    provider?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDTO
     */
    qty?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    serialNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    state?: ProductDTOStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    uom?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDTO
     */
    warehouseCode?: string;
}


/**
 * @export
 */
export const ProductDTOStateEnum = {
    Aggregated: 'AGGREGATED',
    Destroyed: 'DESTROYED',
    Ready: 'READY',
    Shipped: 'SHIPPED'
} as const;
export type ProductDTOStateEnum = typeof ProductDTOStateEnum[keyof typeof ProductDTOStateEnum];


/**
 * Check if a given object implements the ProductDTO interface.
 */
export function instanceOfProductDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProductDTOFromJSON(json: any): ProductDTO {
    return ProductDTOFromJSONTyped(json, false);
}

export function ProductDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'batch': !exists(json, 'batch') ? undefined : json['batch'],
        'children': !exists(json, 'children') ? undefined : ((json['children'] as Array<any>).map(ChildDTOFromJSON)),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'historicalEvents': !exists(json, 'historicalEvents') ? undefined : (new Set((json['historicalEvents'] as Array<any>).map(HistoricalEventDTOFromJSON))),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'locationCode': !exists(json, 'locationCode') ? undefined : json['locationCode'],
        'manufactureOrder': !exists(json, 'manufactureOrder') ? undefined : json['manufactureOrder'],
        'manufacturingDate': !exists(json, 'manufacturingDate') ? undefined : (new Date(json['manufacturingDate'])),
        'materialCode': !exists(json, 'materialCode') ? undefined : json['materialCode'],
        'parents': !exists(json, 'parents') ? undefined : ((json['parents'] as Array<any>).map(ProductDTOFromJSON)),
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
        'qty': !exists(json, 'qty') ? undefined : json['qty'],
        'serialNumber': !exists(json, 'serialNumber') ? undefined : json['serialNumber'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'uom': !exists(json, 'uom') ? undefined : json['uom'],
        'warehouseCode': !exists(json, 'warehouseCode') ? undefined : json['warehouseCode'],
    };
}

export function ProductDTOToJSON(value?: ProductDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'batch': value.batch,
        'children': value.children === undefined ? undefined : ((value.children as Array<any>).map(ChildDTOToJSON)),
        'description': value.description,
        'historicalEvents': value.historicalEvents === undefined ? undefined : (Array.from(value.historicalEvents as Set<any>).map(HistoricalEventDTOToJSON)),
        'id': value.id,
        'locationCode': value.locationCode,
        'manufactureOrder': value.manufactureOrder,
        'manufacturingDate': value.manufacturingDate === undefined ? undefined : (value.manufacturingDate.toISOString()),
        'materialCode': value.materialCode,
        'parents': value.parents === undefined ? undefined : ((value.parents as Array<any>).map(ProductDTOToJSON)),
        'provider': value.provider,
        'qty': value.qty,
        'serialNumber': value.serialNumber,
        'state': value.state,
        'uom': value.uom,
        'warehouseCode': value.warehouseCode,
    };
}

