import { COLORS } from "@theme";
import { useTranslation } from "react-i18next";
import { Grid, Select, MenuItem } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";

export const DataGridDisplayPageSizeSelector = (props: {
  pageSize: number;
  setPageSize: (newPageSize: number) => void;
  rowsPerPageOptions: number[];
}) => {
  const { pageSize, setPageSize, rowsPerPageOptions } = props;

  const { t } = useTranslation();

  const handleChangePageSize = (e: SelectChangeEvent<number>) => {
    setPageSize(e.target.value as number);
  };

  return (
    <Grid item display="flex" alignItems="center" columnGap="1rem">
      {t("table.footer.page.size")}
      <Select
        id="table-page-size-selector"
        className="table-select"
        value={pageSize}
        onChange={handleChangePageSize}
        size="small"
        color="secondary">
        {rowsPerPageOptions.map(option => (
          <MenuItem
            id={`table-page-size-option-${option}`}
            value={option}
            key={option}
            sx={{ fontSize: "small", color: COLORS.grey }}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};
