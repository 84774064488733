import { ROUTER_PATHS } from "@constants";
import { User } from "@domain/model/User";
import Material from "@domain/model/Material";
import {
  destinationDashboardSearchEngine,
  searchObjectName,
  searchObjectType
} from "../code-structure/dashboard-search-engine-types";
import LocationEntity from "@domain/model/Location";
import { CompanyDTOLicenceEnum, ContainerDTO, ExtendedUserDTO } from "@clients/aggrego-proxy";
import { Theme, useMediaQuery } from "@mui/material";
import { useCheckLicense } from "@hooks/useCheckLicense";
import { GridRowParams, MuiEvent } from "@mui/x-data-grid";
import { useLocation, useNavigate } from "react-router-dom";
import { dashboardSearchEngineStyles } from "../styles/dashboard-search-engine-styles";
import { dataGridDisplayStyles } from "@components/data-grid-display/table/display-grid-styles";
import { FoundResultData, ResultData } from "../../home/components/interfaces/foundResultData-interface";
import { useDashboardSearchEngineListDataViewModel } from "./helper/useDashboardSearchEngineListDataViewModel";
import { useHandleSelectedWarehouse } from "@hooks/useHandleSelectedWarehouse";
import { useEffect, useState } from "react";
import { useResolve } from "@movicoders/di";
import { IUserService, UserService } from "@domain/services/IUserService";

export const useDashboardSearchEngineViewModel = () => {
  const obtainFilterData = () => {
    const finalPackage: FoundResultData = location.state.finalPackage;

    if (Material.isMaterial(finalPackage.itemSelected ?? {})) {
      filterObject = finalPackage.itemSelected as Material;
      filterType = "material";
    } else if (LocationEntity.isLocation(finalPackage.itemSelected ?? {})) {
      filterObject = finalPackage.itemSelected as LocationEntity;
      filterType = "location";
    } else if (User.isFullUserDTO(finalPackage.itemSelected ?? {})) {
      filterObject = finalPackage.itemSelected as User;
      filterType = "user";
    } else {
      filterObject = finalPackage.itemSelected as ContainerDTO;
      filterType = "container";
    }
  };

  const location = useLocation();
  const { selectedWarehouse } = useHandleSelectedWarehouse();
  const preselectedValue: ResultData = location.state.preselectedValue;

  let filterObject: searchObjectType = {};
  let filterType: searchObjectName = undefined;
  obtainFilterData();

  const { movementsReportDataPaginated, warehouseStatusReportDataPaginated, getDatas, tiles, zones } =
    useDashboardSearchEngineListDataViewModel({
      filterObject,
      filterType
    });

  //VIEWMODEL
  const navigate = useNavigate();

  const matchesMD = useMediaQuery((theme: Theme) => theme.breakpoints.up(900), { noSsr: true });
  const styles = dataGridDisplayStyles();
  const searchEngineStyles = dashboardSearchEngineStyles();

  //END VIEWMODEL
  const { checkLicense } = useCheckLicense();

  //COLUMNS FOR TABLES BY LICENSE
  const userService = useResolve<IUserService>(UserService);
  const currentLicense = userService.user?.companyLicense;
  const [statusColumnsByMode, setStatusColumnsByMode] = useState<string[]>([]);
  const [movementsColumnsByMode, setMovementsColumnsByMode] = useState<string[]>([]);

  const whStatusColumnsByLicense = () => {
    switch (currentLicense) {
      case CompanyDTOLicenceEnum.Im:
        setStatusColumnsByMode(["id", "container", "location", "actions"]);
        break;
      case CompanyDTOLicenceEnum.Wm:
        setStatusColumnsByMode(["id", "container", "actions"]);
        break;
      case CompanyDTOLicenceEnum.WmWithContainer:
        setStatusColumnsByMode(["id", "actions"]);
        break;
    }
  };

  const movementsColumnsByLicense = () => {
    switch (currentLicense) {
      case CompanyDTOLicenceEnum.Im:
        setMovementsColumnsByMode(["id", "origin", "destiny", "container", "actions"]);
        break;
      case CompanyDTOLicenceEnum.Wm:
        setMovementsColumnsByMode(["id", "container", "actions"]);
        break;
      case CompanyDTOLicenceEnum.WmWithContainer:
        setMovementsColumnsByMode(["id", "actions"]);
        break;
    }
  };

  useEffect(() => {
    whStatusColumnsByLicense();
    movementsColumnsByLicense();
  }, []);
  //COLUMNS FOR TABLES BY LICENSE

  const handleNavigationOnRowClick = (
    param: GridRowParams,
    event: MuiEvent<React.MouseEvent<HTMLElement>>,
    destination: destinationDashboardSearchEngine,
    searchObjectName: searchObjectName
  ) => {
    navigateInNewWindow(destination, filterObject, searchObjectName);
  };

  const handleNavigationOnClick = (destination: destinationDashboardSearchEngine, searchObjectName: searchObjectName) => {
    navigateInNewWindow(destination, filterObject, searchObjectName);
  };

  const navigateInNewWindow = (
    destination: destinationDashboardSearchEngine,
    searchObject: searchObjectType,
    searchObjectName: searchObjectName
  ) => {
    switch (destination) {
      case "materials":
        window.open("/" + ROUTER_PATHS.materials + "?search=" + generateSearchUri(searchObject));
        break;
      case "movements":
        window.open(ROUTER_PATHS.movreport + "?search=" + generateSearchUri(searchObject));
        break;
      case "locations":
        window.open("/" + ROUTER_PATHS.locations + "?search=" + generateSearchUri(searchObject));
        break;
      case "users":
        window.open(
          "/" + ROUTER_PATHS.users + "?search=" + generateSearchUri(new User().fromDTO(searchObject as Partial<ExtendedUserDTO>))
        );
        break;
      case "warehouse-status":
        window.open(ROUTER_PATHS.whstatus + "?search=" + generateSearchUri(searchObject));
    }
  };

  const generateSearchUri = (searchObject: Material | Location | ContainerDTO | User) => {
    const searchObjectJson = JSON.stringify(searchObject);
    return encodeURIComponent(searchObjectJson);
  };

  const navigateToDashboard = () => {
    navigate("/" + ROUTER_PATHS.home + "/" + selectedWarehouse);
  };

  return {
    statusColumnsByMode,
    movementsColumnsByMode,
    checkLicense,
    handleNavigationOnRowClick,
    handleNavigationOnClick,
    navigateToDashboard,
    matchesMD,
    styles,
    searchEngineStyles,
    filterObject,
    filterType,
    movementsReportDataPaginated,
    warehouseStatusReportDataPaginated,
    preselectedValue,
    getDatas,
    tiles,
    zones
  };
};
