import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import { Divider, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { logisticStyles } from "../../logistic-styles";

export const EntriesTitle = (props: { iconId: string; textId: string; text: string }) => {
  const { t } = useTranslation();
  const styles = logisticStyles();
  const { text, textId, iconId } = props;

  return (
    <Grid container id="first-grid-container" direction="column" mb={1}>
      <Grid item>
        <Grid container wrap="nowrap" spacing={{ xs: "1rem" }} id="second-grid-container">
          <Grid item alignSelf="center">
            <Icon icon={iconId} style={{ marginTop: 7 }} color={COLORS.primary} />
          </Grid>
          <Grid id={textId} item sx={styles.logistic_sync_title}>
            {t(text)}
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={styles.divider} />
    </Grid>
  );
};
