import { useEffect, useState } from "react";
import { useResolve } from "@movicoders/di";
import { useSnackbar } from "@movicoders/ui";
import { useTranslation } from "react-i18next";
import Company from "@domain/model/CompanyAdminBoard";
import { TUserRoleEnum } from "@domain/interface/User";
import { IUserService, UserService } from "@domain/services/IUserService";
import { MediaRepository } from "@infrastructure/repositories/media-repository";
import { CompanyRepository } from "@infrastructure/repositories/company-repository";
import { CompanyUpdateUseCase } from "@application/master-data/companies/company-update-use-case";
import { IImpersonationService, ImpersonationService } from "@domain/services/IImpersonationService";
import { FullCompanyItemDTO, MultimediaDTO, UpdateMultimediaRequest } from "@clients/aggrego-proxy";
import { User } from "@domain/model/User";

export const useCompanyDataViewModel = () => {
  const companyRepository = useResolve<CompanyRepository>(CompanyRepository);

  const updateCompany = useResolve(CompanyUpdateUseCase);

  const { t } = useTranslation();
  const { show } = useSnackbar();

  const userService = useResolve<IUserService>(UserService);
  const impersonationService = useResolve<IImpersonationService>(ImpersonationService);
  const mediaRepository = useResolve<MediaRepository>(MediaRepository);

  const [showErrors, setShowErrors] = useState(false);
  const [currentCompany, setCurrentCompany] = useState<FullCompanyItemDTO>();
  const [blobFile, setBlobFile] = useState<Blob | undefined>(undefined);
  const [isBlobValid, setIsBlobValid] = useState(false);
  const [multimedia, setMultimedia] = useState<MultimediaDTO>();

  const blankCompany: Company | undefined = undefined;

  const isRole = (role: TUserRoleEnum) => {
    return userService.user?.roles.includes(role);
  };

  useEffect(() => {
    if (userService.user?.companyImage === "") {
      userService.save(new User({ ...userService.user, companyImage: multimedia?.url } as Partial<User>));
    }
  }, [multimedia]);

  useEffect(() => {
    currentCompany?.image &&
      mediaRepository
        .getById(currentCompany?.image ?? "")
        .then((resultMultimedia: MultimediaDTO) => {
          setMultimedia(resultMultimedia);
        })
        .catch(() => {
          setMultimedia(undefined);
        });
  }, [currentCompany?.image]);

  //Gets the user company with the user tenantId.
  useEffect(() => {
    if (userService.current()?.tenantId !== undefined && userService.current()?.tenantId !== "" && !isRole("SUPER_ADMIN")) {
      companyRepository
        .getDetails(userService.current()?.tenantId ?? "")
        .then((result: FullCompanyItemDTO) => {
          setCurrentCompany(result);
        })
        .catch(() => {
          show(t("loading.detail.error", { value: "", mdNameThe: t("the.company") }), "error");
        });
    }
  }, [userService.current()?.tenantId]);

  useEffect(() => {
    if (isRole("SUPER_ADMIN") && impersonationService.persistedState?.impersonatedTenant) {
      companyRepository
        .getDetails(impersonationService.persistedState?.impersonatedTenant ?? "")
        .then((result: FullCompanyItemDTO) => {
          setCurrentCompany(result);
        })
        .catch(() => {
          setCurrentCompany(blankCompany);
        });
    }
  }, [impersonationService.persistedState?.impersonatedTenant]);

  useEffect(() => {
    blobFile && checkUploadedBlobFile();
  }, [blobFile]);

  const checkUploadedBlobFile = () => {
    if (blobFile?.type.includes("image/png") || blobFile?.type.includes("image/jp")) {
      setIsBlobValid(true);
    } else {
      setIsBlobValid(false);
      setBlobFile(undefined);
      show(t("multimedia.image.uploaded.failed"), "error");
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentCompany !== undefined) {
      setCurrentCompany({
        ...currentCompany,
        [event.target.name]: event.target.value
      });
    }
  };

  const validateValue = (value: string) => {
    return value?.toString().trim() !== "" && value !== undefined;
  };

  const saveCompanyEditingImage = () => {
    mediaRepository
      .update({
        body: blobFile,
        id: currentCompany?.image,
        maintainFile: true
      } as UpdateMultimediaRequest)
      .then((result: MultimediaDTO) => {
        setMultimedia(result);
        updateCompany.execute({ ...currentCompany, image: result.id, address: currentCompany?.address }).then(() => {
          userService.save(new User({ ...userService.user, companyImage: result?.url } as Partial<User>));
          show(t("company.saved.success", { company: "" }), "success");
          setBlobFile(undefined);
        });
      });
  };

  const saveCompanyCreatingImage = () => {
    mediaRepository.upload(blobFile ?? new Blob()).then((result: MultimediaDTO) => {
      setMultimedia(result);
      updateCompany.execute({ ...currentCompany, image: result.id, address: currentCompany?.address }).then(() => {
        userService.save(new User({ ...userService.user, companyImage: result?.url } as Partial<User>));
        show(t("company.saved.success", { company: "" }), "success");
        setBlobFile(undefined);
      });
    });
  };

  const saveOnlyCompanyAdress = () => {
    updateCompany.execute({ ...currentCompany, address: currentCompany?.address }).then(() => {
      show(t("company.saved.success", { company: "" }), "success");
      setBlobFile(undefined);
    });
  };

  const handleSave = () => {
    if (currentCompany?.address !== undefined && validateValue(currentCompany.address)) {
      if (isBlobValid) {
        mediaRepository
          .getById(currentCompany?.image ?? "")
          .then(() => {
            saveCompanyEditingImage();
          })
          .catch(() => {
            saveCompanyCreatingImage();
          });
      } else {
        saveOnlyCompanyAdress();
      }
    } else {
      setShowErrors(true);
    }
  };

  return {
    currentCompany,
    multimedia,
    blobFile,
    setBlobFile,
    onChange,
    handleSave,
    showErrors
  };
};
