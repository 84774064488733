import { CREATE_ID } from "@constants";
import Family from "@domain/model/Family";
import FamilyType from "@domain/model/FamilyType";
import { FamilyRepository } from "@infrastructure/repositories/family-repository";
import { TypeOfFamilyRepository } from "@infrastructure/repositories/type-of-families-repository";

export class FamilyCreateUseCase {
  constructor(
    private readonly familyRepository: FamilyRepository,
    private readonly typeOfFamilyRepository: TypeOfFamilyRepository
  ) {}

  public async execute(family: Family, typeOfFamilyName?: string) {
    if (family.familyTypeId === CREATE_ID) {
      return await this.typeOfFamilyRepository.create(new FamilyType(typeOfFamilyName ?? "", true)).then(createdFamilyType => {
        family.familyTypeId = createdFamilyType.id;

        // CREATE NEW FAMILY
        if (family.id === CREATE_ID) {
          family.id = undefined;
          return this.familyRepository.create(family);
          // UPDATE FAMILY
        } else {
          return this.familyRepository.update(family);
        }
      });
    } else {
      // CREATE NEW FAMILY
      if (family.id === CREATE_ID) {
        family.id = undefined;
        return await this.familyRepository.create(family);

        // UPDATE FAMILY
      } else {
        return await this.familyRepository.update(family);
      }
    }
  }
}
