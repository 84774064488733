import { TextField, Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSearchByCodeMenuViewmodel } from "./useSearchByCodeMenuViewmodel";

export const SearchByCodeMenu = () => {

  const { t } = useTranslation();
  const {
    findTileByBinCode,
    handleChangeSearchArg
  } = useSearchByCodeMenuViewmodel();

  return (
    <Grid sx={{ marginTop: "1rem" }}>
      <TextField
        variant="standard"
        size="small"
        label={t("viewer.search.bin")}
        onChange={handleChangeSearchArg}
        inputProps={{ autoComplete: "off" }}
        sx={{ marginTop: "1rem" }}
      />
      <Button
        size="small"
        color="secondary"
        sx={{ marginTop: "1rem" }}
        onClick={findTileByBinCode}
      >
        {t("viewer.search")}
      </Button>
    </Grid>
  );
};
