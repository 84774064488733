import { LastPageState } from "../model/LastPageState";

export interface ILastPageService {
  persistedState?: LastPageState;
  saveLastPageState(newState: LastPageState): void;
  clearLastPageState(): void;
  getCurrentState(): LastPageState | undefined
}

export const LastPageService = Symbol.for("LastPageService");
