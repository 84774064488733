import { Grid } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import Warehouse from "@domain/model/Warehouse";
import { DomainPage } from "@movicoders/domain";
import { useCheckStatus } from "@hooks/useCheckStatus";
import { WarehouseCard } from "../components/warehouse-card";
import { useResponsiveGrid } from "@hooks/useResponsiveGrid";
import { NoRecordsFound } from "@components/no-records-found/no-records-found";
import { DataGridDisplay } from "@components/data-grid-display/data-grid-display";

interface IWarehousesTable {
  warehouses: DomainPage<Warehouse>;
  handleEdit: (id: string) => void;
  handleDeactivateConfirm: (warehouse: Warehouse) => void;
  handlePagination: (
    limit: number,
    offset: number,
    status?: "ACTIVE" | "INACTIVE" | "ALL" | undefined,
    filters?: object | undefined
  ) => void;
}

const WarehousesViewTable = (props: IWarehousesTable) => {
  const { t } = useTranslation();
  const { handleStatusFilter } = useCheckStatus();
  const { warehouses, handleEdit, handlePagination, handleDeactivateConfirm } = props;

  const { handleMediaQuery } = useResponsiveGrid();
  const columns = handleMediaQuery(4, 3, 2, 2);
  const CARD_GRID_MD = Math.round(12 / columns);
  const CARD_GRID_SM = Math.round(8 / columns);

  const warehouseViewCols: GridColDef[] = [
    { field: "address", headerName: t("warehouse.location") },
    { field: "code", headerName: t("warehouse.code") },
    { field: "name", headerName: t("warehouse.name") }
  ];

  return (
    <DataGridDisplay
      data={warehouses.content ?? []}
      columns={warehouseViewCols}
      mode="server"
      viewName="warehouse"
      handleBackendPagination={handlePagination}
      backendTotalElements={warehouses.totalElements ?? 0}
      customDisplayComponent={data =>
        data.length > 0 ? (
          <Grid container id="container-warehouse-cards" spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {data.map(warehouse => {
              return (
                <Grid item xs={12} sm={CARD_GRID_SM} md={CARD_GRID_MD} lg={CARD_GRID_MD} key={`grid-card-${warehouse.id}`}>
                  <WarehouseCard data={warehouse} handleEdit={handleEdit} handleActive={handleDeactivateConfirm} />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <NoRecordsFound />
        )
      }
      toolbarProps={{
        isActiveFilterHidden: handleStatusFilter(),
        disableFilterByFields: ["active"],
        isDownloadIconButtonHidden: true,
        isColumnsMenuIconButtonHidden: true
      }}
    />
  );
};
export default WarehousesViewTable;
