import { t } from "i18next";
import { Grid } from "@mui/material";
import { DomainPage } from "@movicoders/domain";
import { GridSortModel } from "@mui/x-data-grid";
import Stakeholder from "@domain/model/Stakeholder";
import { ALL_COUNTRIES, CountryObject } from "@constants";
import { CardsDisplay } from "../components/cards-display";
import { useHandleCardQueries } from "@hooks/useHandleCardQueries";
import { stakeholderTypesEnum } from "../hooks/useStakeholderSelection";
import { stakeholdersColumns } from "../components/stakeholders-columns";
import { DataGridDisplay } from "@components/data-grid-display/data-grid-display";
import { StakeholderDTOPaymentTermsEnum, StakeholderDTOPaymentCurrencyEnum } from "@clients/aggrego-proxy";
import { ChangeDisplayButton } from "@components/data-grid-display/toolbar/components/ChangeDisplayButton";
import { DataGridDisplayViewNameEnum } from "@components/data-grid-display/table/config/data-grid-display-view-name-enum";
import { useCheckStatus } from "@hooks/useCheckStatus";

interface IStakeholdersTable {
  stakeholderData: DomainPage<Stakeholder>;
  stakeholderType: stakeholderTypesEnum;
  handleStakeholderOpen: (stakeholder: Stakeholder) => Promise<void>;
  handleEdit: (id: string) => void;
  handleActiveConfirm: (stakeholder: Stakeholder) => void;
  handlePagination: (
    limit: number,
    offset: number,
    status?: "ACTIVE" | "INACTIVE" | "ALL" | undefined,
    filters?: object | undefined
  ) => void;
  sortByFieldBackend: (model: GridSortModel) => void;
}

const StakeholdersViewTable = (props: IStakeholdersTable) => {
  const {
    stakeholderData,
    stakeholderType,
    handleStakeholderOpen,
    handleEdit,
    handleActiveConfirm,
    handlePagination,
    sortByFieldBackend
  } = props;
  const { handleStatusFilter, checkUserRoleUseCase } = useCheckStatus();
  const { isCardDisplay, setIsCardDisplay, matchesMD } = useHandleCardQueries();
  const countriesList = [...ALL_COUNTRIES].sort((a: CountryObject, b: CountryObject) => t(a?.label).localeCompare(t(b?.label)));

  return (
    <DataGridDisplay
      mode="server"
      data={(stakeholderData?.content as Stakeholder[]) ?? []}
      viewName={stakeholderType as DataGridDisplayViewNameEnum}
      searchBarFilter={""}
      backendTotalElements={stakeholderData?.totalElements ?? 0}
      handleBackendPagination={handlePagination}
      customDisplayComponent={() =>
        isCardDisplay ? (
          <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <CardsDisplay
              dataPaginated={(stakeholderData.content as Stakeholder[]) ?? []}
              handleActive={handleActiveConfirm}
              handleEdit={handleEdit}
              handleStakeholderOpen={handleStakeholderOpen}
            />
          </Grid>
        ) : undefined
      }
      columns={stakeholdersColumns(
        t,
        handleStakeholderOpen,
        matchesMD,
        handleEdit,
        handleActiveConfirm,
        stakeholderType,
        checkUserRoleUseCase
      )}
      handleBackendSort={sortByFieldBackend}
      toolbarProps={{
        multipleSelectOptions: new Map<string, string[]>([
          ["country", countriesList.map((country: CountryObject) => country.label) ?? []],
          [
            "paymentTerms",
            [
              StakeholderDTOPaymentTermsEnum.Net30,
              StakeholderDTOPaymentTermsEnum.Net60,
              StakeholderDTOPaymentTermsEnum.Net90,
              StakeholderDTOPaymentTermsEnum.Cash
            ]
          ],
          [
            "paymentCurrency",
            [
              StakeholderDTOPaymentCurrencyEnum.Euro,
              StakeholderDTOPaymentCurrencyEnum.Dollar,
              StakeholderDTOPaymentCurrencyEnum.PoundSterling
            ]
          ]
        ]),
        isColumnsMenuIconButtonHidden: false,
        isActiveFilterHidden: handleStatusFilter(),
        disableFilterByFields: [
          "id",
          "address",
          "code",
          "comment",
          "contactPerson",
          "fiscalId",
          "name",
          "phone",
          "postalCode",
          "actions",
          "status"
        ],
        disableFieldsInDownload: ["id", "actions"],
        disableColumnsVisibilityByField: ["city", "country"],
        addHTMLRight: ChangeDisplayButton({ isCardDisplay: isCardDisplay, setIsCardDisplay: setIsCardDisplay })
      }}
    />
  );
};

export default StakeholdersViewTable;
