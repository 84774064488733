import Operator from "@domain/model/Operator";
import { DomainPage } from "@movicoders/domain";
import { OperatorApi } from "../api/operator-api";
import { ListOperatorsPaginatedRequest, ToggleOperatorRequest, ValidateOperatorRequest } from "@clients/aggrego-proxy";

export class OperatorRepository {
  constructor(private readonly operatorApi: OperatorApi) {}

  getPaginated(pageCriteria: ListOperatorsPaginatedRequest): Promise<DomainPage<Operator>> {
    return this.operatorApi.getPaginated(pageCriteria);
  }

  getById(id: string): Promise<Operator> {
    return this.operatorApi.getById(id);
  }

  create(operator: Operator): Promise<Operator> {
    return this.operatorApi.create(operator);
  }

  update(operator: Operator): Promise<Operator> {
    return this.operatorApi.update(operator);
  }

  toggleStatus(criteria: ToggleOperatorRequest): Promise<Operator> {
    return this.operatorApi.toggleStatus(criteria);
  }

  validate(criteria: ValidateOperatorRequest): Promise<Operator> {
    return this.operatorApi.validate(criteria);
  }
}
