import { IconButton } from "@mui/material";
import Icon from "../../../icons/icon";
import { COLORS } from "../../../../../theme";

interface IChangeDisplayButton {
  isCardDisplay: boolean;
  setIsCardDisplay: (isCardDisplay: boolean) => void;
}

export const ChangeDisplayButton = (props: IChangeDisplayButton) => {
  const { isCardDisplay, setIsCardDisplay } = props;
  return (
    <IconButton
      className="table-icon-button"
      id="change-display-button"
      onClick={() => setIsCardDisplay(!isCardDisplay)}>
      <Icon icon={isCardDisplay ? "table" : "view-cards"} color={COLORS.grey} />
    </IconButton>
  );
};
