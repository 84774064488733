import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const useLanguageViewModel = () => {
  const languages = ["en", "es"];
  const { i18n } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = useState<string>("");

  const getSimpleLanguage = (language: string) => {
    const result = language.split("-")[0];
    if (languages.includes(result)) {
      return result;
    } else {
      changeLanguage("en");
      return "en";
    }
  };

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setCurrentLanguage(language);
  };

  useEffect(() => {
    setCurrentLanguage(getSimpleLanguage(i18n.language));
  }, []);

  return { languages, changeLanguage, currentLanguage };
};
