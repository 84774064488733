import { SyntheticEvent, useEffect, useState } from "react";
import { CompanyCreateDTO } from "../company-create-model";

export interface DeleteCallback {
  onError?: (error: string) => void;
  onSuccess?: () => void;
}

export interface IListProps {
  save: (entity: CompanyCreateDTO) => void;
}

export const useCompanyStepperViewModel = (activeStep?: number) => {
  const [company, setCompany] = useState<CompanyCreateDTO>({
    address: "",
    companyName: "",
    email: "",
    firstName: "",
    image: "",
    id: "",
    licence: undefined,
    password: "",
    surname: "",
    tenantName: "",
    username: "",
    warehousePolicy: undefined
  } as CompanyCreateDTO);

  const [isSamePassword, setIsSamePassword] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nameField = event.target.name;
    if (nameField !== "password" && nameField !== "confirmPassword") {
      if (nameField !== "email") {
        setCompany(CompanyCreateDTO.fromDTO({ ...company, [nameField]: event.target.value }));
      } else {
        setCompany(CompanyCreateDTO.fromDTO({ ...company, [nameField]: event.target.value, username: event.target.value }));
      }
    } else {
      if (nameField === "password") {
        setPasswordValue(event.target.value);
      }
      if (nameField === "confirmPassword") {
        setConfirmPasswordValue(event.target.value);
      }
    }
  };

  const onChangeAutocomplete = (event: SyntheticEvent<Element, Event>, value: string | null, id: string) => {
    setCompany(CompanyCreateDTO.fromDTO({ ...company, [id]: value ?? "" }));
  };

  const clearPasswordFields = () => {
    setPasswordValue("");
    setConfirmPasswordValue("");
  };

  useEffect(() => {
    if (activeStep === 1) {
      clearPasswordFields();
      setCompany(CompanyCreateDTO.fromDTO({ ...company, password: "" }));
    }
  }, [activeStep]);

  useEffect(() => {
    setIsSamePassword(passwordValue === confirmPasswordValue && passwordValue !== "" && confirmPasswordValue !== "");
  }, [passwordValue, confirmPasswordValue]);

  useEffect(() => {
    setCompany(CompanyCreateDTO.fromDTO({ ...company, password: isSamePassword ? passwordValue : "" }));
  }, [isSamePassword]);

  return {
    onChange,
    setCompany,
    company,
    onChangeAutocomplete,
    isSamePassword,
    confirmPasswordValue,
    setConfirmPasswordValue,
    passwordValue,
    setPasswordValue
  };
};
