export const ROUTER_PATHS = {
  home: "dashboard",
  login: "/",
  recoverPassword: "/recover-password/:token",
  users: "users",
  warehouseViewer: "/warehouse-viewer",
  warehouseViewerDetails: "/warehouse-viewer/:id",
  warehouses: "warehouses",
  companies: "companies",
  typeOfFamilies: "type-of-families",
  families: "families",
  materials: "materials",
  locations: "locations",
  inventory: "/inventory",
  recommendUbication: "/recommend-ubication",
  downloadApk: "/download-apk",
  settings: "/settings",
  profile: "/profile",
  auth: "/auth",
  whstatus: "/warehouse-status",
  invreport: "/inventory-report",
  movreport: "/movements-report",
  stock: "/materials-stock",
  invregistry: "/inventory-registry",
  search: "/search",
  warehouse: "warehouse",
  zones: "zones",
  products: "/products",
  productHierarchyView: "/products/:id",
  hierarchyCreation: "/hierarchy-creation",
  adminBoard: "admin-board",
  inbounds: "/inbounds",
  inboundsSync: "/inbounds/sync",
  outbounds: "/outbounds",
  outboundsSync: "/outbounds/sync",
  dashboardSearch: "/dashboard/search",
  customers: "customers",
  manufacturers: "manufacturers",
  owners: "owners",
  suppliers: "suppliers",
  operators: "operators",
  inventoryMaterial: "/inventory-material",
  inventoryMaterialSync: "/inventory-material/sync",
  relabelreport: "/relabel-report"
};

export const UNAUTH_ROUTES = [ROUTER_PATHS.login, ROUTER_PATHS.recoverPassword];

export const NO_IM_ROUTES = [
  ROUTER_PATHS.warehouseViewer,
  "/locations",
  "/zones",
  ROUTER_PATHS.invreport,
  ROUTER_PATHS.stock,
  ROUTER_PATHS.invregistry,
  ROUTER_PATHS.productHierarchyView,
  ROUTER_PATHS.warehouseViewerDetails,
  ROUTER_PATHS.relabelreport
];

export const NO_WM_ROUTES = [
  ROUTER_PATHS.inbounds,
  ROUTER_PATHS.inboundsSync,
  ROUTER_PATHS.outbounds,
  ROUTER_PATHS.outboundsSync,
  ROUTER_PATHS.inventoryMaterial,
  ROUTER_PATHS.inventoryMaterialSync,
  ROUTER_PATHS.relabelreport
];

export const NO_WM_WITH_CONTAINER_ROUTES = [
  ROUTER_PATHS.inbounds,
  ROUTER_PATHS.inboundsSync,
  ROUTER_PATHS.outbounds,
  ROUTER_PATHS.outboundsSync,
  ROUTER_PATHS.inventoryMaterial,
  ROUTER_PATHS.inventoryMaterialSync
];

export const MASTER_DATA_DETAIL_ROUTES = [
  ROUTER_PATHS.warehouses + "/",
  ROUTER_PATHS.typeOfFamilies + "/",
  ROUTER_PATHS.families + "/",
  ROUTER_PATHS.materials + "/",
  ROUTER_PATHS.operators + "/",
  ROUTER_PATHS.customers + "/",
  ROUTER_PATHS.manufacturers + "/",
  ROUTER_PATHS.owners + "/",
  ROUTER_PATHS.suppliers + "/"
];

export const ROUTER_QUERY_KEYS = {
  PARENT_ID: "parentId"
};

export const SYSTEM_DATA = [
  { id: "1", name: "Mobility" },
  { id: "2", name: "Portal" },
  { id: "3", name: "Test" }
];

// Filters types for render components
export type filterValueTypes =
  | "string"
  | "number"
  | "select"
  | "select-one"
  | "multiple-select"
  | "autocomplete"
  | "autocomplete-one"
  | "range-date";

export const TIMEOUT_ID = "-2";
export const CREATE_ID = "-1";

export const HOME_AUTOCOMPLETE_DEBOUNCE_TIME = 1000;

export interface globalFilter {
  searchText: string;
}

export interface CountryObject {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}

export const TELTRONIC_FISCALID = "A-50035518";

export const AGGREGO_APK_LINK = "https://apks.movicoders.link/apks/Aggrego-V0-1-38-DEMO(1.0).apk";

// label can not be modified because it's matching with database and translations
export const ALL_COUNTRIES: CountryObject[] = [
  { code: "AD", label: "country.andorra", phone: "376" },
  { code: "AE", label: "country.united.arab.emirates", phone: "971" },
  { code: "AF", label: "country.afghanistan", phone: "93" },
  { code: "AG", label: "country.antigua.and.barbuda", phone: "1-268" },
  { code: "AI", label: "country.anguilla", phone: "1-264" },
  { code: "AL", label: "country.albania", phone: "355" },
  { code: "AM", label: "country.armenia", phone: "374" },
  { code: "AO", label: "country.angola", phone: "244" },
  { code: "AQ", label: "country.antarctica", phone: "672" },
  { code: "AR", label: "country.argentina", phone: "54" },
  { code: "AS", label: "country.american.samoa", phone: "1-684" },
  { code: "AT", label: "country.austria", phone: "43" },
  { code: "AU", label: "country.australia", phone: "61", suggested: true },
  { code: "AW", label: "country.aruba", phone: "297" },
  { code: "AX", label: "country.alland.islands", phone: "358" },
  { code: "AZ", label: "country.azerbaijan", phone: "994" },
  { code: "BA", label: "country.bosnia.and.herzegovina", phone: "387" },
  { code: "BB", label: "country.barbados", phone: "1-246" },
  { code: "BD", label: "country.bangladesh", phone: "880" },
  { code: "BE", label: "country.belgium", phone: "32" },
  { code: "BF", label: "country.burkina.faso", phone: "226" },
  { code: "BG", label: "country.bulgaria", phone: "359" },
  { code: "BH", label: "country.bahrain", phone: "973" },
  { code: "BI", label: "country.burundi", phone: "257" },
  { code: "BJ", label: "country.benin", phone: "229" },
  { code: "BL", label: "country.saint.barthelemy", phone: "590" },
  { code: "BM", label: "country.bermuda", phone: "1-441" },
  { code: "BN", label: "country.brunei.darussalam", phone: "673" },
  { code: "BO", label: "country.bolivia", phone: "591" },
  { code: "BR", label: "country.brazil", phone: "55" },
  { code: "BS", label: "country.bahamas", phone: "1-242" },
  { code: "BT", label: "country.bhutan", phone: "975" },
  { code: "BV", label: "country.bouvet.island", phone: "47" },
  { code: "BW", label: "country.botswana", phone: "267" },
  { code: "BY", label: "country.belarus", phone: "375" },
  { code: "BZ", label: "country.belize", phone: "501" },
  { code: "CA", label: "country.canada", phone: "1", suggested: true },
  { code: "CC", label: "country.cocos.keeling.islands", phone: "61" },
  { code: "CD", label: "country.congo.democratic.republic.of.the", phone: "243" },
  { code: "CF", label: "country.central.african.republic", phone: "236" },
  { code: "CG", label: "country.congo.republic.of.the", phone: "242" },
  { code: "CH", label: "country.switzerland", phone: "41" },
  { code: "CI", label: "country.cote.d.ivoire", phone: "225" },
  { code: "CK", label: "country.cook.islands", phone: "682" },
  { code: "CL", label: "country.chile", phone: "56" },
  { code: "CM", label: "country.cameroon", phone: "237" },
  { code: "CN", label: "country.china", phone: "86" },
  { code: "CO", label: "country.colombia", phone: "57" },
  { code: "CR", label: "country.costa.rica", phone: "506" },
  { code: "CU", label: "country.cuba", phone: "53" },
  { code: "CV", label: "country.cape.verde", phone: "238" },
  { code: "CW", label: "country.curacao", phone: "599" },
  { code: "CX", label: "country.christmas.island", phone: "61" },
  { code: "CY", label: "country.cyprus", phone: "357" },
  { code: "CZ", label: "country.czech.republic", phone: "420" },
  { code: "DE", label: "country.germany", phone: "49", suggested: true },
  { code: "DJ", label: "country.djibouti", phone: "253" },
  { code: "DK", label: "country.denmark", phone: "45" },
  { code: "DM", label: "country.dominica", phone: "1-767" },
  { code: "DO", label: "country.dominican.republic", phone: "1-809" },
  { code: "DZ", label: "country.algeria", phone: "213" },
  { code: "EC", label: "country.ecuador", phone: "593" },
  { code: "EE", label: "country.estonia", phone: "372" },
  { code: "EG", label: "country.egypt", phone: "20" },
  { code: "EH", label: "country.western.sahara", phone: "212" },
  { code: "ER", label: "country.eritrea", phone: "291" },
  { code: "ES", label: "country.spain", phone: "34" },
  { code: "ET", label: "country.ethiopia", phone: "251" },
  { code: "FI", label: "country.finland", phone: "358" },
  { code: "FJ", label: "country.fiji", phone: "679" },
  { code: "FK", label: "country.falkland.islands.malvinas", phone: "500" },
  { code: "FM", label: "country.micronesia.federated.states.of", phone: "691" },
  { code: "FO", label: "country.faroe.islands", phone: "298" },
  { code: "FR", label: "country.france", phone: "33", suggested: true },
  { code: "GA", label: "country.gabon", phone: "241" },
  { code: "GB", label: "country.united.kingdom", phone: "44" },
  { code: "GD", label: "country.grenada", phone: "1-473" },
  { code: "GE", label: "country.georgia", phone: "995" },
  { code: "GF", label: "country.french.guiana", phone: "594" },
  { code: "GG", label: "country.guernsey", phone: "44" },
  { code: "GH", label: "country.ghana", phone: "233" },
  { code: "GI", label: "country.gibraltar", phone: "350" },
  { code: "GL", label: "country.greenland", phone: "299" },
  { code: "GM", label: "country.gambia", phone: "220" },
  { code: "GN", label: "country.guinea", phone: "224" },
  { code: "GP", label: "country.guadeloupe", phone: "590" },
  { code: "GQ", label: "country.equatorial.guinea", phone: "240" },
  { code: "GR", label: "country.greece", phone: "30" },
  { code: "GS", label: "country.south.georgia.and.the.south.sandwich.islands", phone: "500" },
  { code: "GT", label: "country.guatemala", phone: "502" },
  { code: "GU", label: "country.guam", phone: "1-671" },
  { code: "GW", label: "country.guinea.bissau", phone: "245" },
  { code: "GY", label: "country.guyana", phone: "592" },
  { code: "HK", label: "country.hong.kong", phone: "852" },
  { code: "HM", label: "country.heard.island.and.mcdonald.islands", phone: "672" },
  { code: "HN", label: "country.honduras", phone: "504" },
  { code: "HR", label: "country.croatia", phone: "385" },
  { code: "HT", label: "country.haiti", phone: "509" },
  { code: "HU", label: "country.hungary", phone: "36" },
  { code: "ID", label: "country.indonesia", phone: "62" },
  { code: "IE", label: "country.ireland", phone: "353" },
  { code: "IL", label: "country.israel", phone: "972" },
  { code: "IM", label: "country.isle.of.man", phone: "44" },
  { code: "IN", label: "country.india", phone: "91" },
  { code: "IO", label: "country.british.indian.ocean.territory", phone: "246" },
  { code: "IQ", label: "country.iraq", phone: "964" },
  { code: "IR", label: "country.iran.islamic.republic.of", phone: "98" },
  { code: "IS", label: "country.iceland", phone: "354" },
  { code: "IT", label: "country.italy", phone: "39" },
  { code: "JE", label: "country.jersey", phone: "44" },
  { code: "JM", label: "country.jamaica", phone: "1-876" },
  { code: "JO", label: "country.jordan", phone: "962" },
  { code: "JP", label: "country.japan", phone: "81", suggested: true },
  { code: "KE", label: "country.kenya", phone: "254" },
  { code: "KG", label: "country.kyrgyzstan", phone: "996" },
  { code: "KH", label: "country.cambodia", phone: "855" },
  { code: "KI", label: "country.kiribati", phone: "686" },
  { code: "KM", label: "country.comoros", phone: "269" },
  { code: "KN", label: "country.saint.kitts.and.nevis", phone: "1-869" },
  { code: "KP", label: "country.korea.democratic.people.s.republic.of", phone: "850" },
  { code: "KR", label: "country.korea.republic.of", phone: "82" },
  { code: "KW", label: "country.kuwait", phone: "965" },
  { code: "KY", label: "country.cayman.islands", phone: "1-345" },
  { code: "KZ", label: "country.kazakhstan", phone: "7" },
  { code: "LA", label: "country.lao.people.s.democratic.republic", phone: "856" },
  { code: "LB", label: "country.lebanon", phone: "961" },
  { code: "LC", label: "country.saint.lucia", phone: "1-758" },
  { code: "LI", label: "country.liechtenstein", phone: "423" },
  { code: "LK", label: "country.sri.lanka", phone: "94" },
  { code: "LR", label: "country.liberia", phone: "231" },
  { code: "LS", label: "country.lesotho", phone: "266" },
  { code: "LT", label: "country.lithuania", phone: "370" },
  { code: "LU", label: "country.luxembourg", phone: "352" },
  { code: "LV", label: "country.latvia", phone: "371" },
  { code: "LY", label: "country.libya", phone: "218" },
  { code: "MA", label: "country.morocco", phone: "212" },
  { code: "MC", label: "country.monaco", phone: "377" },
  { code: "MD", label: "country.moldova.republic.of", phone: "373" },
  { code: "ME", label: "country.montenegro", phone: "382" },
  { code: "MF", label: "country.saint.martin.french.part", phone: "590" },
  { code: "MG", label: "country.madagascar", phone: "261" },
  { code: "MH", label: "country.marshall.islands", phone: "692" },
  { code: "MK", label: "country.macedonia.the.former.yugoslav.republic.of", phone: "389" },
  { code: "ML", label: "country.mali", phone: "223" },
  { code: "MM", label: "country.myanmar", phone: "95" },
  { code: "MN", label: "country.mongolia", phone: "976" },
  { code: "MO", label: "country.macao", phone: "853" },
  { code: "MP", label: "country.northern.mariana.islands", phone: "1-670" },
  { code: "MQ", label: "country.martinique", phone: "596" },
  { code: "MR", label: "country.mauritania", phone: "222" },
  { code: "MS", label: "country.montserrat", phone: "1-664" },
  { code: "MT", label: "country.malta", phone: "356" },
  { code: "MU", label: "country.mauritius", phone: "230" },
  { code: "MV", label: "country.maldives", phone: "960" },
  { code: "MW", label: "country.malawi", phone: "265" },
  { code: "MX", label: "country.mexico", phone: "52" },
  { code: "MY", label: "country.malaysia", phone: "60" },
  { code: "MZ", label: "country.mozambique", phone: "258" },
  { code: "NA", label: "country.namibia", phone: "264" },
  { code: "NC", label: "country.new.caledonia", phone: "687" },
  { code: "NE", label: "country.niger", phone: "227" },
  { code: "NF", label: "country.norfolk.island", phone: "672" },
  { code: "NG", label: "country.nigeria", phone: "234" },
  { code: "NI", label: "country.nicaragua", phone: "505" },
  { code: "NL", label: "country.netherlands", phone: "31" },
  { code: "NO", label: "country.norway", phone: "47" },
  { code: "NP", label: "country.nepal", phone: "977" },
  { code: "NR", label: "country.nauru", phone: "674" },
  { code: "NU", label: "country.niue", phone: "683" },
  { code: "NZ", label: "country.new.zealand", phone: "64" },
  { code: "OM", label: "country.oman", phone: "968" },
  { code: "PA", label: "country.panama", phone: "507" },
  { code: "PE", label: "country.peru", phone: "51" },
  { code: "PF", label: "country.french.polynesia", phone: "689" },
  { code: "PG", label: "country.papua.new.guinea", phone: "675" },
  { code: "PH", label: "country.philippines", phone: "63" },
  { code: "PK", label: "country.pakistan", phone: "92" },
  { code: "PL", label: "country.poland", phone: "48" },
  { code: "PM", label: "country.saint.pierre.and.miquelon", phone: "508" },
  { code: "PN", label: "country.pitcairn", phone: "870" },
  { code: "PR", label: "country.puerto.rico", phone: "1" },
  { code: "PS", label: "country.palestine.state.of", phone: "970" },
  { code: "PT", label: "country.portugal", phone: "351" },
  { code: "PW", label: "country.palau", phone: "680" },
  { code: "PY", label: "country.paraguay", phone: "595" },
  { code: "QA", label: "country.qatar", phone: "974" },
  { code: "RE", label: "country.reunion", phone: "262" },
  { code: "RO", label: "country.romania", phone: "40" },
  { code: "RS", label: "country.serbia", phone: "381" },
  { code: "RU", label: "country.russian.federation", phone: "7" },
  { code: "RW", label: "country.rwanda", phone: "250" },
  { code: "SA", label: "country.saudi.arabia", phone: "966" },
  { code: "SB", label: "country.solomon.islands", phone: "677" },
  { code: "SC", label: "country.seychelles", phone: "248" },
  { code: "SD", label: "country.sudan", phone: "249" },
  { code: "SE", label: "country.sweden", phone: "46" },
  { code: "SG", label: "country.singapore", phone: "65" },
  { code: "SH", label: "country.saint.helena", phone: "290" },
  { code: "SI", label: "country.slovenia", phone: "386" },
  { code: "SJ", label: "country.svalbard.and.jan.mayen", phone: "47" },
  { code: "SK", label: "country.slovakia", phone: "421" },
  { code: "SL", label: "country.sierra.leone", phone: "232" },
  { code: "SM", label: "country.san.marino", phone: "378" },
  { code: "SN", label: "country.senegal", phone: "221" },
  { code: "SO", label: "country.somalia", phone: "252" },
  { code: "SR", label: "country.suriname", phone: "597" },
  { code: "SS", label: "country.south.sudan", phone: "211" },
  { code: "ST", label: "country.sao.tome.and.principe", phone: "239" },
  { code: "SV", label: "country.el.salvador", phone: "503" },
  { code: "SX", label: "country.sint.maarten.dutch.part", phone: "1-721" },
  { code: "SY", label: "country.syrian.arab.republic", phone: "963" },
  { code: "SZ", label: "country.swaziland", phone: "268" },
  { code: "TC", label: "country.turks.and.caicos.islands", phone: "1-649" },
  { code: "TD", label: "country.chad", phone: "235" },
  { code: "TF", label: "country.french.southern.territories", phone: "262" },
  { code: "TG", label: "country.togo", phone: "228" },
  { code: "TH", label: "country.thailand", phone: "66" },
  { code: "TJ", label: "country.tajikistan", phone: "992" },
  { code: "TK", label: "country.tokelau", phone: "690" },
  { code: "TL", label: "country.timor.leste", phone: "670" },
  { code: "TM", label: "country.turkmenistan", phone: "993" },
  { code: "TN", label: "country.tunisia", phone: "216" },
  { code: "TO", label: "country.tonga", phone: "676" },
  { code: "TR", label: "country.turkey", phone: "90" },
  { code: "TT", label: "country.trinidad.and.tobago", phone: "1-868" },
  { code: "TV", label: "country.tuvalu", phone: "688" },
  { code: "TW", label: "country.taiwan.republic.of.china", phone: "886" },
  { code: "TZ", label: "country.united.republic.of.tanzania", phone: "255" },
  { code: "UA", label: "country.ukraine", phone: "380" },
  { code: "UG", label: "country.uganda", phone: "256" },
  { code: "US", label: "country.united.states", phone: "1", suggested: true },
  { code: "UY", label: "country.uruguay", phone: "598" },
  { code: "UZ", label: "country.uzbekistan", phone: "998" },
  { code: "VA", label: "country.holy.see.vatican.city.state", phone: "379" },
  { code: "VC", label: "country.saint.vincent.and.the.grenadines", phone: "1-784" },
  { code: "VE", label: "country.venezuela", phone: "58" },
  { code: "VG", label: "country.british.virgin.islands", phone: "1-284" },
  { code: "VI", label: "country.us.virgin.islands", phone: "1-340" },
  { code: "VN", label: "country.vietnam", phone: "84" },
  { code: "VU", label: "country.vanuatu", phone: "678" },
  { code: "WF", label: "country.wallis.and.futuna", phone: "681" },
  { code: "WS", label: "country.samoa", phone: "685" },
  { code: "XK", label: "country.kosovo", phone: "383" },
  { code: "YE", label: "country.yemen", phone: "967" },
  { code: "YT", label: "country.mayotte", phone: "262" },
  { code: "ZA", label: "country.south.africa", phone: "27" },
  { code: "ZM", label: "country.zambia", phone: "260" },
  { code: "ZW", label: "country.zimbabwe", phone: "263" }
];
