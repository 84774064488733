import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

export const useGridSearchBar = (searchRecoveredValue: string, setSearchRecoveredValue: Dispatch<SetStateAction<string>>) => {
  const [searchValue, setSearchValue] = useState<string>(searchRecoveredValue);
  const searchInput = useRef<HTMLElement>(null);

  useEffect(() => {
    if (searchRecoveredValue) {
      setSearchValue(searchRecoveredValue);
      setSearchRecoveredValue(searchRecoveredValue);
    }
    const timer = setTimeout(() => setSearchValue(searchRecoveredValue), 500);
    return () => clearTimeout(timer);
  }, [searchRecoveredValue]);

  const handleSearchBar = (value: string) => {
    setSearchValue(value);
    setSearchRecoveredValue(value);
  };

  return {
    handleSearchBar,
    searchInput,
    searchValue
  };
};
