import { SxProps, Theme, linearProgressClasses } from "@mui/material"
import { DEFAULT_COLORS } from "../../../constants/Colors"
import { COLORS } from "../../../../../../theme"

export const boxDialogStyles = () => {

  const getContainerStyles = (visible: boolean): SxProps<Theme> => {
    return {
      height: "100vh",
      width: "20vw",
      display: visible ? "block" : "none",
      position: "absolute",
      right: 0,
      overflowY: "auto",
      px: "1rem",
      fontSize: "small"
    }
  }

  const getLinearProgressStyles = (color: string): SxProps<Theme> => {
    return {
      backgroundColor: DEFAULT_COLORS.grey,
      height: 10,
      width: "16vw",
      [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: color
      }
    }
  }

  const levelIconButtonStyles: SxProps<Theme> = {
    backgroundColor: COLORS.secondary,
    "&:hover": {
      backgroundColor: COLORS.secondary
    }, width: 30,
    height: 30,
    padding: 0,
    borderRadius: 1
  }

  return {
    levelIconButtonStyles,
    getContainerStyles,
    getLinearProgressStyles
  }
}