import { useResolve } from "@movicoders/di";
import { useEffect, useState } from "react";
import { StockRepository } from "../../../../../../infrastructure/repositories/stock-repository";
import { StockReportUseCase } from "../../../../../../application/stock-report/stock-report-get-latest-use-case";
import { MasterDataEnum, ReportEnum } from "@pages/settings/settings-interfaces";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { useLocation } from "react-router-dom";
import { ROUTER_PATHS } from "@constants";

export const useMaterialsStockConfig = (selectedWarehouseCode: string | undefined, stockRepository: StockRepository) => {
  const location = useLocation();
  const stockReportUseCase = useResolve(StockReportUseCase);
  const settingsService = useResolve<ISettingsService>(SettingsService);

  const [latestStock, setLatestStock] = useState<string>();

  useEffect(() => {
    getLatestStock();
  }, []);

  const getLatestStock = async () => {
    if (
      //warehouses view received paginated material-stock from here
      (location.pathname === "/" + ROUTER_PATHS.warehouses &&
        settingsService.persistedState?.masterdataPermissions.includes(MasterDataEnum.Warehouses)) ||
      (location.pathname === ROUTER_PATHS.stock &&
        settingsService.persistedState?.reportsPermissions.includes(ReportEnum.StockReport))
    ) {
      setLatestStock(await stockReportUseCase.getLatest(selectedWarehouseCode ?? ""));
    }
  };

  const setConfigStock = async (hours: number, warehouseCode: string) => {
    try {
      warehouseCode &&
        (await stockRepository.setConfigDailyStock({ dailyStockReportDTO: { hours: hours, warehouse: warehouseCode } }));
    } catch (error) {
      return null;
    }
  };

  const getConfigStock = async (warehouseCode: string) => {
    const result = await stockRepository.getLastConfigDailyStock({ warehouse: warehouseCode });
    return result.hours?.length ?? 0;
  };

  return {
    latestStock,
    setConfigStock,
    getConfigStock
  };
};
