import { ROUTER_PATHS } from "@constants";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Typography, Avatar } from "@mui/material";
import { IUserService } from "@domain/services/IUserService";

export const UserInfo = (props: { userService: IUserService; fullUserMode: boolean }) => {
  const { userService, fullUserMode } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Grid
      container
      id="user-info-container"
      padding="0.5rem"
      marginTop={"0.5rem"}
      onClick={() => {
        !pathname.includes(ROUTER_PATHS.profile) && navigate(ROUTER_PATHS.profile);
      }}>
      <Grid item id="user-info-item" xs={12} display="flex" justifyContent="flex-end" alignItems="center" marginRight={"1rem"}>
        <Grid item id="user-info-cursor" display="flex" justifyContent="flex-end" alignItems="center" sx={{ cursor: "pointer" }}>
          {fullUserMode && (
            <Typography id="user-info-name" marginRight="1rem" variant="body2" color={"secondary"}>
              {userService.user?.firstName} {userService.user?.firstSurname}
            </Typography>
          )}
          <Avatar id="user-info-avatar" src={userService.user?.companyImage} />
        </Grid>
      </Grid>
    </Grid>
  );
};
