import { Grid } from "@mui/material";
import { User } from "@domain/model/User";
import { useTranslation } from "react-i18next";
import { DomainPage } from "@movicoders/domain";
import { GridValidRowModel } from "@mui/x-data-grid";
import { userColumns } from "./../components/user-columns";
import { UsersCardsDisplay } from "../components/CardsDisplay";
import { DataGridDisplay } from "@components/data-grid-display/data-grid-display";

interface IUsersTable {
  userData: DomainPage<User>;
  searchBarFilter: string;
  handleEdit: (id: string) => void;
  handleOpenToggleActiveStatusDialog: (user: User) => void;
  handlePagination: (
    limit: number,
    offset: number,
    status?: "ACTIVE" | "INACTIVE" | "ALL" | undefined,
    filters?: object | undefined
  ) => void;
}

const UsersViewTable = (props: IUsersTable) => {
  const { t } = useTranslation();
  const userViewCols = userColumns(t);
  const { userData, searchBarFilter, handleEdit, handlePagination, handleOpenToggleActiveStatusDialog } = props;

  return (
    <DataGridDisplay
      mode="server"
      data={(userData.content as GridValidRowModel[]) ?? []}
      columns={userViewCols}
      viewName="users"
      backendTotalElements={userData.totalElements ?? 0}
      handleBackendPagination={handlePagination}
      rowsPerPageOptions={[12, 24, 48, 96]}
      searchBarFilter={searchBarFilter}
      customDisplayComponent={() => (
        <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          <UsersCardsDisplay
            dataPaginated={userData as DomainPage<User>}
            handleOpenToggleActiveStatusDialog={handleOpenToggleActiveStatusDialog}
            handleOpenUpsertDialog={handleEdit}
          />
        </Grid>
      )}
      toolbarProps={{
        isColumnsMenuIconButtonHidden: true,
        parseCSVInDownload: data => {
          const dataForCsv = { ...data };
          let concatNames = "";
          dataForCsv.roles.forEach((role: string) => {
            concatNames += `${t("users.role." + role.toLowerCase())}, `;
          });
          concatNames = concatNames.slice(0, -2);
          dataForCsv.roles = concatNames;
          dataForCsv.active = dataForCsv.active ? t("users.active") : t("users.deactivate");
          return dataForCsv;
        },
        disableFilterByFields: ["action", "active"],
        multipleSelectOptions: new Map<string, string[]>([
          ["roles", ["MOB_USER", "MOB_ADMIN", "PORTAL_USER", "PORTAL_ADMIN", "SUPER_ADMIN"]]
        ])
      }}
    />
  );
};

export default UsersViewTable;
