import { UsersApi } from "@clients/aggrego-proxy";

export class ResetPasswordUseCase {
  constructor(private readonly userClient: UsersApi) {}

  public async execute(newPassword: string) {
    const response = await this.userClient.resetPassword1({ newPasswordRequest: { password: newPassword } });
    return response;
  }
}
