import { DomainPage, Pageable, Sort } from "@movicoders/domain";

export default class GenericPage<T extends DTOConvertibleEntity<unknown, T>>{

  _baseClass: T

  constructor(private readonly type: new () => T) {
    this._baseClass = new type()
  }

  public fromDTOPage(entityPageDTO: DTOPage<T>): DomainPage<T> {
    return {
      content: entityPageDTO.content?.map(dto => this._baseClass.fromDTO(dto)),
      first: entityPageDTO.number,
      last: (entityPageDTO.number !== undefined ? entityPageDTO.number : 0) +
        (entityPageDTO.numberOfElements !== undefined ? entityPageDTO.numberOfElements : 0),
      number: entityPageDTO.number,
      numberOfElements: entityPageDTO.numberOfElements,
      pageable: entityPageDTO.pageable,
      size: entityPageDTO.size,
      sort: entityPageDTO.sort,
      totalElements: entityPageDTO.totalElements,
      totalPages: entityPageDTO.totalPages
    }
  }
}

export interface DTOPage<T> {
  content?: T[];
  first?: boolean;
  last?: boolean;
  number?: number;
  numberOfElements?: number;
  pageable?: Pageable;
  size?: number;
  sort?: Sort;
  totalElements?: number;
  totalPages?: number;
}

export abstract class DTOConvertibleEntity<R, T>{

  public fromDTO(arg: R): T {
    throw new Error("Should be overridden on child class");
  }

  public toDTO(arg: T): R {
    throw new Error("Should be overridden on child class");
  }
}