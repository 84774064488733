import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import { useTranslation } from "react-i18next";
import InvRegistryDialog from "./inventoryRegistryDialog";
import { generalReportStyles } from "../../../reports-styles/generalReportStyles";
import { Dispatch, FormEvent, RefObject, SetStateAction, useEffect, useRef } from "react";
import {
  Grid,
  Paper,
  InputBase,
  Select,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  Theme,
  useMediaQuery
} from "@mui/material";
import { InvPeriodDTO } from "@domain/model/InvPeriod";

interface IInventoryRegistrySearchBar {
  showErrors: boolean;
  handleEnterReload: (event: FormEvent<HTMLFormElement>, searchInput: RefObject<HTMLElement>) => void;
  searchValue: string;
  setSelectPeriod: Dispatch<SetStateAction<InvPeriodDTO | undefined>>;
  clearSearchValue: () => void;
  setSearchValue: Dispatch<SetStateAction<string>>;
  selectPeriod: InvPeriodDTO;
  isPeriodActive: boolean;
  sortedPeriods: InvPeriodDTO[];
  showInvRegistryDialog: boolean;
  onCloseInvRegistryDialog: () => void;
  handleInitInventory: () => Promise<void>;
  handleCloseInventory: () => Promise<void>;
  defaultPeriod: InvPeriodDTO;
}

export const InventoryRegistrySearchBar = (props: IInventoryRegistrySearchBar): JSX.Element => {
  const { t } = useTranslation();
  const styles = generalReportStyles();
  const matchesLG = useMediaQuery((theme: Theme) => theme.breakpoints.up(900), { noSsr: true });

  const {
    clearSearchValue,
    handleEnterReload,
    handleInitInventory,
    handleCloseInventory,
    onCloseInvRegistryDialog,
    sortedPeriods,
    isPeriodActive,
    searchValue,
    setSearchValue,
    setSelectPeriod,
    selectPeriod,
    defaultPeriod,
    showErrors,
    showInvRegistryDialog
  } = props;

  const searchInput = useRef<HTMLElement>(null);

  useEffect(() => {
    setSelectPeriod(defaultPeriod);
  }, [defaultPeriod]);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "12.5rem"
      }
    }
  };

  const renderInput = (ref: RefObject<HTMLElement>) => {
    return (
      <>
        <Paper
          component="form"
          className="search-bar Mui-error"
          style={showErrors ? { borderColor: COLORS.alert } : {}}
          onSubmit={event => handleEnterReload(event, searchInput)}>
          <Icon className="search-icon" icon="search" size="1.6rem" color="inherit" />
          <InputBase
            id="inventory-register-search-bar"
            name="invregistry"
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            sx={styles.reports_input_field}
            placeholder={t("search.placeholder")}
            inputProps={{ ref: ref, enterKeyHint: "search" }}
            endAdornment={
              searchValue ? (
                <Icon id="search-clear-button" icon="close" className="clear-icon" onClick={clearSearchValue} />
              ) : (
                <></>
              )
            }
          />
        </Paper>
        {/** ERROR TEXT */}
        {showErrors && <Typography sx={styles.reports_search_label}>{t("invregistry.search.empty")}</Typography>}
      </>
    );
  };

  return (
    <Grid
      item
      id="product-grid-container"
      display="inline-flex"
      xs={12}
      md={7.5}
      flexDirection={{ md: "row", sm: "column", xs: "column" }}>
      <Grid
        item
        id={"invregistry-searchbar-grid"}
        width={!matchesLG ? "50%" : "100%"}
        sx={{ mr: "1rem", width: !matchesLG ? "100%" : "80%" }}
        xs={12}
        md={4}>
        {renderInput(searchInput)}
      </Grid>
      <Grid
        item
        id={"invregistry-select-grid"}
        width={!matchesLG ? "50%" : "100%"}
        sx={{ marginTop: !matchesLG ? "2rem" : "0rem", width: !matchesLG ? "100%" : "50%" }}
        xs={12}
        md={3}>
        {sortedPeriods.length > 0 && selectPeriod && selectPeriod.id && (
          <FormControl>
            <InputLabel
              id="invregistry-date-placeholder"
              style={{ fontSize: "0.625rem", marginLeft: "0.5rem", color: COLORS.secondary }}>
              {t("invregistry.date.placeholder")}
            </InputLabel>
            <Select
              id="invregistry-select"
              color="secondary"
              value={selectPeriod.id}
              onChange={e => {
                setSelectPeriod(sortedPeriods.find(item => item.id === e.target.value));
              }}
              sx={styles.reports_select_styles}
              MenuProps={MenuProps}>
              {sortedPeriods.length > 0 &&
                sortedPeriods?.map((period: InvPeriodDTO) => {
                  return (
                    <MenuItem
                      id={"invregistry-select-option-" + period?.id}
                      key={period.id}
                      value={period.id}
                      sx={{ fontSize: "small", color: COLORS.grey }}>
                      {period.initDate?.toLocaleString("es-ES", {
                        localeMatcher: "lookup",
                        month: "numeric",
                        day: "numeric",
                        year: "numeric"
                      })}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        )}
      </Grid>
      {showInvRegistryDialog && (
        <InvRegistryDialog
          open={showInvRegistryDialog}
          onClose={onCloseInvRegistryDialog}
          isPeriodActive={isPeriodActive}
          onInit={isPeriodActive ? handleCloseInventory : handleInitInventory}
        />
      )}
    </Grid>
  );
};
