import Operator from "@domain/model/Operator";
import { useOperatorSave } from "./useOperatorSave";
import { useOperatorPagination } from "./useOperatorPagination";
import { useOperatorHandleData } from "./useOperatorHandleData";
import { useOperatorActiveStatus } from "./useOperatorActiveStatus";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { useResolve } from "@movicoders/di";

export const useOperatorViewModel = () => {
  const settingsService = useResolve<ISettingsService>(SettingsService);

  const {
    operatorData,
    selectedOperator,
    loading,
    loadingDetail,
    currentPageService,
    currentPagePersistedState,
    getCurrentStatus,
    getOperatorsPaginated,
    goToCreate,
    goToDetail,
    goToList,
    save
  } = useOperatorHandleData();

  const { handleEdit, handleSave } = useOperatorSave(
    currentPagePersistedState,
    selectedOperator as Operator,
    save,
    goToDetail,
    goToList,
    getCurrentStatus
  );

  const { handlePagination, sortByFieldBackend } = useOperatorPagination(
    currentPageService,
    currentPagePersistedState,
    operatorData?.content as Operator[],
    getCurrentStatus,
    getOperatorsPaginated
  );

  const { isActivating, operator4Activation, handleActiveConfirm, handleOperatorStatus, onCloseActive } = useOperatorActiveStatus(
    currentPagePersistedState,
    getOperatorsPaginated,
    getCurrentStatus
  );

  return {
    operatorData,
    selectedOperator,
    isActivating,
    operator4Activation,
    loading,
    loadingDetail,
    onCloseActive,
    goToCreate,
    goToList,
    handleSave,
    handleEdit,
    handlePagination,
    handleActiveConfirm,
    handleOperatorStatus,
    sortByFieldBackend,
    settingsService
  };
};
