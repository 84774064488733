/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EventDTO } from './EventDTO';
import {
    EventDTOFromJSON,
    EventDTOFromJSONTyped,
    EventDTOToJSON,
} from './EventDTO';

/**
 * 
 * @export
 * @interface HandledResponse
 */
export interface HandledResponse {
    /**
     * 
     * @type {string}
     * @memberof HandledResponse
     */
    errorCode?: string;
    /**
     * 
     * @type {EventDTO}
     * @memberof HandledResponse
     */
    item?: EventDTO;
    /**
     * 
     * @type {string}
     * @memberof HandledResponse
     */
    message?: string;
}

/**
 * Check if a given object implements the HandledResponse interface.
 */
export function instanceOfHandledResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HandledResponseFromJSON(json: any): HandledResponse {
    return HandledResponseFromJSONTyped(json, false);
}

export function HandledResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandledResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'errorCode': !exists(json, 'errorCode') ? undefined : json['errorCode'],
        'item': !exists(json, 'item') ? undefined : EventDTOFromJSON(json['item']),
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function HandledResponseToJSON(value?: HandledResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'errorCode': value.errorCode,
        'item': EventDTOToJSON(value.item),
        'message': value.message,
    };
}

