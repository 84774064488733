import Family from "@domain/model/Family";
import { useTranslation } from "react-i18next";
import { DomainPage } from "@movicoders/domain";
import { Grid, Typography } from "@mui/material";
import FamilyType from "@domain/model/FamilyType";
import { useCheckStatus } from "@hooks/useCheckStatus";
import { FamiliesCardsDisplay } from "../components/families-cards-display";
import { DataGridDisplay } from "@components/data-grid-display/data-grid-display";
import { GridColDef, GridRenderCellParams, GridValidRowModel } from "@mui/x-data-grid";

interface IFamiliesTable {
  families: DomainPage<Family>;
  dataTypesOfFamilies: FamilyType[];
  handleEdit: (id: string) => void;
  handleOpenToggleActiveStatusDialog: (family: Family) => void;
  handlePagination: (
    limit: number,
    offset: number,
    status?: "ACTIVE" | "INACTIVE" | "ALL" | undefined,
    filters?: object | undefined
  ) => void;
}

const FamiliesViewTable = (props: IFamiliesTable) => {
  const { t } = useTranslation();
  const { handleStatusFilter } = useCheckStatus();
  const { families, dataTypesOfFamilies, handleEdit, handlePagination, handleOpenToggleActiveStatusDialog } = props;

  const familyViewCols: GridColDef[] = [
    { field: "name", headerName: t("home.name") },
    {
      field: "active",
      headerName: t("families.state"),
      renderCell: (data: GridRenderCellParams) =>
        data.row.active ? (
          <Typography variant="column_table_typography">{t("type.of.families.active")}</Typography>
        ) : (
          <Typography variant="column_table_typography">{t("type.of.families.deactivate")}</Typography>
        )
    },
    { field: "familyType", headerName: t("families.type"), type: "select-one" }
  ];

  return (
    <DataGridDisplay
      data={(families.content as GridValidRowModel[]) ?? []}
      columns={familyViewCols}
      mode="server"
      viewName="families"
      handleBackendPagination={handlePagination}
      backendTotalElements={families?.totalElements ?? 0}
      rowsPerPageOptions={[12, 24, 48, 96]}
      customDisplayComponent={(data: GridValidRowModel[]) => (
        <Grid container id="container-family-cards" spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          <FamiliesCardsDisplay
            gridData={data}
            dataTypesOfFamilies={dataTypesOfFamilies}
            handleOpenToggleActiveStatusDialog={handleOpenToggleActiveStatusDialog}
            handleOpenUpsertDialog={handleEdit}
          />
        </Grid>
      )}
      toolbarProps={{
        multipleSelectOptions: new Map<string, string[]>([
          [
            "familyType",
            dataTypesOfFamilies
              .sort((a: FamilyType, b: FamilyType) => t(a?.name).localeCompare(t(b?.name)))
              .map((familyType: FamilyType) => familyType.name) ?? []
          ]
        ]),
        isActiveFilterHidden: handleStatusFilter(),
        disableFilterByFields: ["active"],
        parseCSVInDownload: (data: GridValidRowModel) => {
          const dataForCsv = { ...data };
          const myFamilyType = dataTypesOfFamilies.find((familyType: FamilyType) => familyType.id === dataForCsv.familyTypeId);
          dataForCsv.familyType = myFamilyType?.name;
          dataForCsv.active = dataForCsv.active ? t("type.of.families.active") : t("type.of.families.deactivate");
          return dataForCsv;
        }
      }}
    />
  );
};

export default FamiliesViewTable;
