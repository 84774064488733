import Operator from "@domain/model/Operator";
import { DomainPage } from "@movicoders/domain";
import { IImpersonationService } from "@domain/services/IImpersonationService";
import {
  ListOperatorsPaginatedRequest,
  ListOperatorsRequest,
  OperatorDTO,
  OperatorsApi,
  ToggleOperatorRequest,
  ValidateOperatorRequest
} from "@clients/aggrego-proxy";

export class OperatorApi {
  constructor(private readonly operatorApi: OperatorsApi, private readonly impersonationService: IImpersonationService) {}

  async get(criteria: ListOperatorsRequest): Promise<Operator[]> {
    const result: Operator[] = [];
    const apiResult = await this.operatorApi.listOperators({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });

    apiResult.forEach((operatorClient: OperatorDTO) => {
      result.push(Operator.fromClientOperator(operatorClient));
    });

    return result;
  }

  async getPaginated(pageCriteria: ListOperatorsPaginatedRequest): Promise<DomainPage<Operator>> {
    const apiResult = (await this.operatorApi.listOperatorsPaginated({
      ...pageCriteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? "",
      customOperatorFilter: pageCriteria.customOperatorFilter ?? {}
    })) as unknown as Promise<DomainPage<Operator>>;
    return apiResult;
  }

  async getById(id: string): Promise<Operator> {
    const apiResult = Operator.fromClientOperator(
      await this.operatorApi.getOperatorById({
        id: id,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return apiResult;
  }

  async create(operator: Operator): Promise<Operator> {
    const operatorDTO = Operator.toClientOperator(operator);
    const apiResult = Operator.fromClientOperator(
      await this.operatorApi.saveOperator({
        operatorDTO: operatorDTO,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return apiResult;
  }

  async update(operator: Operator): Promise<Operator> {
    const operatorDTO = Operator.toClientOperator(operator);
    const apiResult = Operator.fromClientOperator(
      await this.operatorApi.updateOperator({
        operatorDTO: operatorDTO,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return apiResult;
  }

  async toggleStatus(criteria: ToggleOperatorRequest): Promise<Operator> {
    const apiResult = Operator.fromClientOperator(
      await this.operatorApi.toggleOperator({
        ...criteria,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return apiResult;
  }

  async validate(criteria: ValidateOperatorRequest): Promise<Operator> {
    const apiResult = Operator.fromClientOperator(
      await this.operatorApi.validateOperator({
        ...criteria,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return apiResult;
  }
}
