import { useResolve } from "@movicoders/di";
import { TableState } from "@domain/model/TableState";
import { GridColumnVisibilityModel } from "@mui/x-data-grid";
import { ITableService, TableService } from "@domain/services/ITableService";

export const useDataGridDisplayViewModel = () => {
  // Actual rows
  const service = useResolve<ITableService>(TableService);
  const persistedState = service.persistedState ?? new TableState();
  const { views } = { ...persistedState };

  const getVisibilityModel = (disabledColumns: string[], viewName: string): GridColumnVisibilityModel => {
    const tempView = views.find(view => view.viewName === viewName);
    const result: GridColumnVisibilityModel = {};

    if (tempView) {
      tempView.columns.forEach(col => {
        result[col.fieldName] = col.visible;
      });
    }
    disabledColumns.forEach(col => {
      result[col] = false;
    });
    return result;
  };
  return {
    getVisibilityModel
  };
};
