import { TileDTO } from "@clients/aggrego-proxy/models/TileDTO";
import { ZoneDTO } from "@clients/aggrego-proxy/models/ZoneDTO";
import { WarehouseDTO } from "@clients/aggrego-proxy/models/WarehouseDTO";

export default class Warehouse {
  active?: boolean;
  address?: string;
  capacity?: number;
  code?: string;
  companyId?: string;
  id?: string;
  image?: string;
  maxSizeY?: number;
  name?: string;
  sizeX?: number;
  sizeZ?: number;
  tiles?: Array<TileDTO>;
  zones?: Array<ZoneDTO>;

  constructor(
    active?: boolean,
    address?: string,
    capacity?: number,
    code?: string,
    companyId?: string,
    id?: string,
    image?: string,
    maxSizeY?: number,
    name?: string,
    sizeX?: number,
    sizeZ?: number,
    tiles?: Array<TileDTO>,
    zones?: Array<ZoneDTO>
  ) {
    this.active = active;
    this.address = address;
    this.capacity = capacity;
    this.code = code;
    this.companyId = companyId;
    this.id = id;
    this.image = image;
    this.maxSizeY = maxSizeY;
    this.name = name;
    this.sizeX = sizeX;
    this.sizeZ = sizeZ;
    this.tiles = tiles;
    this.zones = zones;
  }

  static fromClientWarehouse(warehouse: WarehouseDTO) {
    const result = new Warehouse(
      warehouse.active,
      warehouse.address,
      warehouse.capacity,
      warehouse.code,
      warehouse.companyId,
      warehouse.id,
      warehouse.image,
      warehouse.maxSizeY,
      warehouse.name,
      warehouse.sizeX,
      warehouse.sizeZ,
      warehouse.tiles,
      warehouse.zones
    );
    return result;
  }

  static toClientWarehouse(warehouse: Warehouse) {
    const result: WarehouseDTO = {};
    result.active = warehouse.active;
    result.address = warehouse.address;
    result.capacity = warehouse.capacity;
    result.code = warehouse.code;
    result.companyId = warehouse.companyId;
    result.id = warehouse.id;
    result.image = warehouse.image;
    result.maxSizeY = warehouse.maxSizeY;
    result.name = warehouse.name;
    result.sizeX = warehouse.sizeX;
    result.sizeZ = warehouse.sizeZ;
    result.tiles = warehouse.tiles;
    result.zones = warehouse.zones;
    return result;
  }
}
