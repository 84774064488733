import { useRef } from "react";
import { LastPageState } from "@domain/model/LastPageState";
import { useListDetailPaginatedViewModel } from "@movicoders/ui";
import { useResolve } from "@movicoders/di/lib/Container/useResolve";
import { UserRepository } from "@infrastructure/repositories/user-repository";
import { ILastPageService, LastPageService } from "@domain/services/ILastPageService";
import { IImpersonationService, ImpersonationService } from "@domain/services/IImpersonationService";

export const useUserHandleData = () => {
  const {
    selected: selectedUser,
    create,
    data,
    getPaginated,
    fetchOne,
    goToList,
    loading,
    loadingDetail,
    save
  } = useListDetailPaginatedViewModel(UserRepository);

  const getUsersPaginated = useRef(getPaginated).current;

  const impersonationService = useResolve<IImpersonationService>(ImpersonationService);

  const currentPageService = useResolve<ILastPageService>(LastPageService);
  const currentPagePersistedState = currentPageService.persistedState ?? new LastPageState();

  return {
    userData: data,
    selectedUser,
    loading,
    loadingDetail,
    currentPagePersistedState,
    impersonationService,
    getUsersPaginated,
    save,
    goToList,
    goToDetail: fetchOne,
    goToCreate: create
  };
};
