import { SxProps, Theme } from "@mui/material";

const warehouse_select_button: SxProps<Theme> = {
  paddingTop: "0.3rem",
  paddingBottom: "0.3rem",
  borderRadius: "4px",
  fontSize: "0.875rem",
  letterSpacing: "1.25px"
};

export const warehouseCardStyles = {
  warehouse_select_button
};
