import { useState } from "react";
import { useResolve } from "@movicoders/di";
import Warehouse from "@domain/model/Warehouse";
import { handleError } from "@utils/error-helper";
import { DrawerState } from "@domain/model/DrawerState";
import { LastPageState } from "@domain/model/LastPageState";
import { useWarehousesMessagges } from "../useWarehousesMessagges";
import { DrawerService, IDrawerService } from "@domain/services/IDrawerService";
import { ListWarehousesPaginatedRequest, ResponseError } from "@clients/aggrego-proxy";
import { WarehouseByTenantUseCase } from "@application/warehouse-by-tenant/warehouse-by-tenant-use-case";
import { WarehouseUpdateStatusUseCase } from "@application/master-data/warehouses/warehouse-update-status-use-case";

export const useWarehouseActiveStatus = (
  currentPagePersistedState: LastPageState,
  getCurrentStatus: (status: "ALL" | "ACTIVE" | "INACTIVE") => boolean | undefined,
  warehouseGetPaginated: (criteria: ListWarehousesPaginatedRequest) => Promise<void>
) => {
  const [isDeactivating, setIsDeactivating] = useState(false);
  const warehousesStatusUseCase = useResolve(WarehouseUpdateStatusUseCase);
  const [selectedWarehouse, setSelectedWarehouse] = useState<Warehouse | undefined>(undefined);
  const warehousesByTenantUseCase = useResolve(WarehouseByTenantUseCase);

  const drawerService = useResolve<IDrawerService>(DrawerService);
  const drawerState = drawerService.getPersistedState() ?? new DrawerState();

  const { errorMessaggesDeactivateWarehouse, successMessaggesDeactivateWarehouse } = useWarehousesMessagges();

  const handleDeactivateConfirm = (warehouse: Warehouse) => {
    setIsDeactivating(!isDeactivating);
    setSelectedWarehouse(warehouse);
  };

  const onCloseDeactivate = () => {
    setIsDeactivating(false);
  };

  const handleWarehouseStatus = async (warehouse: Warehouse) => {
    let response;
    try {
      await warehousesStatusUseCase.execute(warehouse, warehouse.id === drawerState.selectedWarehouse).then(() => {
        warehouseGetPaginated({
          limit: currentPagePersistedState.limit ?? 10,
          offset: currentPagePersistedState.offset ?? 0,
          fullDTO: false,
          customWarehouseFilter: {
            status: getCurrentStatus(currentPagePersistedState.status)
          }
        });
        setIsDeactivating(!isDeactivating);
      });
      successMessaggesDeactivateWarehouse();
      warehousesByTenantUseCase.get();
    } catch (error) {
      response = await handleError(error as ResponseError);
      const errorMessage = response?.errors?.[0]?.errorCode?.toLowerCase().split(" ").join(".") ?? "unknown";
      errorMessaggesDeactivateWarehouse(errorMessage, selectedWarehouse);
    }
  };

  return {
    handleDeactivateConfirm,
    isDeactivating,
    handleWarehouseStatus,
    deactivateWarehouse: selectedWarehouse,
    onCloseDeactivate
  };
};
