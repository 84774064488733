import { COLORS } from "@theme";
import Icon from "../icons/icon";
import { useTranslation } from "react-i18next";
import IMMEntryWithDesc from "@domain/model/IMMEntryWithDesc";
import { cardStyles } from "../master-data-view/card/cardStyles";
import { Grid, IconButton, Paper, Typography } from "@mui/material";

export const LogisticCard = (props: {
  id: string;
  isSynchronizing?: boolean;
  isOk?: boolean;
  IMEntry: IMMEntryWithDesc;
  handleOpenDeleteDialog?: (entry: IMMEntryWithDesc) => void;
  handleOpenEditDialog?: (entry: IMMEntryWithDesc) => void;
  handleErrorDialog?: (errorMessage: string) => void;
  errorMessage?: string;
}) => {
  const styles = cardStyles();
  const { id, isSynchronizing, isOk, IMEntry, handleOpenDeleteDialog, handleOpenEditDialog, handleErrorDialog, errorMessage } =
    props;
  const { t } = useTranslation();

  const renderChild = (name: string, value: string) => {
    return (
      <div className="value-card-container">
        <div className="title-content" data-cy={`family-card-${name}-container`}>
          <Typography variant="cardlabel" textAlign="left">
            {t(name)}
          </Typography>
          <Typography variant="cardvalue" textOverflow={"ellipsis"} overflow={"hidden"} data-cy={`value`}>
            {t(value)}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Paper
      id={id}
      data-cy={`id-card-${isSynchronizing}`}
      sx={{
        ...styles.card_class_logistic
      }}>
      <Grid
        container
        direction="row"
        sx={{ cursor: isOk !== undefined && !isOk ? "pointer" : "auto" }}
        onClick={() => {
          handleErrorDialog && handleErrorDialog(errorMessage ?? "OK");
        }}>
        <Grid item xs={isSynchronizing ? 8 : 10}>
          {renderChild("logistic.material", IMEntry?.materialCode ?? "")}
          {renderChild("logistic.description", IMEntry?.materialDescription ?? "")}
          {renderChild("logistic.quantity", IMEntry?.qty?.toLocaleString("it-IT") ?? "")}
          {IMEntry?.comment && renderChild("logistic.comment", IMEntry?.comment ?? "")}
        </Grid>
        {isSynchronizing ? (
          <Grid
            item
            xs={4}
            justifyContent="flex-end"
            sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-end" }}>
            {isOk ? (
              <Icon id="task-ok" icon="task-ok" size={"44px"} color={COLORS.success} />
            ) : (
              <Icon id="task-error" icon="task-ko" size={"44px"} color={COLORS.alert} />
            )}
          </Grid>
        ) : (
          <Grid
            item
            xs={2}
            justifyContent="flex-end"
            sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-end" }}>
            <IconButton id="edit-card-button">
              <Icon
                icon="edit"
                onClick={() => {
                  handleOpenEditDialog && handleOpenEditDialog(IMEntry ?? {});
                }}
              />
            </IconButton>
            <IconButton id="delete-card-button">
              <Icon
                icon="delete"
                onClick={() => {
                  handleOpenDeleteDialog && handleOpenDeleteDialog(IMEntry ?? {});
                }}
              />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
