import Icon from "../../../../icons/icon";
import { COLORS } from "@theme";
import { useTranslation } from "react-i18next";
import Company from "@domain/model/CompanyAdminBoard";
import { impersonationStyles } from "./impersonation.Styles";
import { useImpersonationViewModel } from "./useImpersonationViewModel";
import { Autocomplete, Box, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField } from "@mui/material";
export interface IImpersonation {
  isDrawerOpen: boolean;
  drawerWidth: number | string;
}

export const Impersonation = (props: IImpersonation) => {
  const { drawerWidth, isDrawerOpen } = props;
  const { t } = useTranslation();
  const {
    allCompanies,
    currentCompany,
    impersonationState,
    loadingCompanies,
    clearImpersonation,
    onClickAutocomplete,
    onChangeAutocomplete
  } = useImpersonationViewModel();

  const styles = impersonationStyles(drawerWidth);

  return (
    <Box component="div" marginTop={"1rem"}>
      {currentCompany === null && (impersonationState?.impersonatedTenant === null || !impersonationState?.impersonatedTenant) ? (
        <ListItem className="drawer-item" sx={styles.list_item}>
          <ListItemIcon>
            <Icon icon="supervisor" />
          </ListItemIcon>
          {isDrawerOpen && (
            <Autocomplete
              fullWidth
              id="impersonation-input"
              loading={loadingCompanies}
              loadingText={t("impersonation.login.loading")}
              options={(allCompanies as Company[]) ?? []}
              onOpen={onClickAutocomplete}
              renderInput={params => (
                <TextField
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  label={t("impersonation.login")}
                  {...params}
                />
              )}
              componentsProps={{
                popper: { onClick: e => e.stopPropagation(), id: "impersonation-popper", sx: { zIndex: 10001 } }
              }}
              value={currentCompany}
              getOptionLabel={c => c.companyName}
              onChange={onChangeAutocomplete}
            />
          )}
        </ListItem>
      ) : (
        <ListItemButton
          id="close-impersonation-option"
          className="drawer-item"
          sx={styles.list_item}
          onClick={clearImpersonation}>
          <ListItemIcon>
            <Icon icon="exit" color={COLORS.alert} />
          </ListItemIcon>
          {isDrawerOpen && (
            <ListItemText
              primaryTypographyProps={{
                variant: "drawer"
              }}
              primary={impersonationState?.impersonationName?.toUpperCase() ?? ""}
              style={{ margin: 0 }}
            />
          )}
        </ListItemButton>
      )}
    </Box>
  );
};
