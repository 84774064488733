import { Canvas } from "@react-three/fiber";
import { GizmoHelper, GizmoViewcube } from "@react-three/drei";
import Warehouse3DContainer from "./components/warehouse-3d-container";
import { BoxDialog } from "./components/dialogs/box-dialog/box-dialog";
import { Grid } from "@mui/material";
import { ModeSelection } from "./components/dialogs/mode-selection/mode-selection-menu";
import { use3DInterfaceViewModel } from "./viewmodels/use3DInterfaceViewModel";
import { ViewerDeleteDialog } from "./components/dialogs/delete-dialog/delete-dialog";
import { ResizeObserver } from "@juggle/resize-observer";
import { RotatingCube } from "./components/rotating-cube/rotating-cube";
import { AddLocationDialog3D } from "./components/dialogs/add-location-dialog-3d/add-location-dialog-3d";
import { ReplaceOrKeepEditDialog3D } from "./components/dialogs/replace-or-keep-dialog/replace-or-keep-edit-dialog";
import { ZoneInfoDialog } from "./components/dialogs/zone-info-dialog/zone-info-dialog";
import { LoadingSpinnerMasterView as LoadingSpinner } from "../../components/loading-circular-progress/loading-spinner-master-view";
import { ViewerLoadErrorDialog } from "./components/dialogs/load-error-dialog/load-error-dialog";

const Warehouse3DViewer = () => {
  const { loading, getWarehouse, getOnSaveLoading, errorDialogProps } = use3DInterfaceViewModel();

  // Placeholder for when the warehouse is not loaded yet.
  if (loading)
    return (
      <Grid container>
        <Grid item height="100vh" width="100vw">
          <Canvas resize={{ polyfill: ResizeObserver }}>
            <RotatingCube />
          </Canvas>
        </Grid>
        <ViewerLoadErrorDialog {...errorDialogProps} />
      </Grid>
    );

  return (
    <Grid container >
      <Grid item position="fixed" height="100vh" width="100vw" zIndex={0}>
        <Canvas
          camera={{
            position: [
              (getWarehouse().sizeX ?? 50) / 2,
              ((getWarehouse().sizeX ?? 50) + (getWarehouse().sizeZ ?? 50)) / 2,
              (getWarehouse().sizeZ ?? 50) / 2
            ]
          }}
          id="warehouse-canvas"
          resize={{ polyfill: ResizeObserver }}
        >
          <Warehouse3DContainer />
          <GizmoHelper alignment="bottom-left" margin={[110, 80]}>
            <group scale={0.85}>
              <GizmoViewcube />
            </group>
          </GizmoHelper>
        </Canvas>
      </Grid>
      <BoxDialog />
      <ModeSelection />
      <ViewerDeleteDialog />
      <AddLocationDialog3D />
      <ReplaceOrKeepEditDialog3D />
      <ZoneInfoDialog />
      <LoadingSpinner loading={getOnSaveLoading()} />
      <ViewerLoadErrorDialog {...errorDialogProps} />
    </Grid >
  );
};

export default Warehouse3DViewer;
