import Material from "@domain/model/Material";
import { useEffect, useRef, useState } from "react";
import { MaterialDTO } from "@clients/aggrego-proxy";
import { AutocompleteChangeReason } from "@mui/material";
import IMMEntryWithDesc from "@domain/model/IMMEntryWithDesc";
import { useListDetailPaginatedViewModel } from "@movicoders/ui";
import { MaterialRepository } from "@infrastructure/repositories/material-repository";
import { useLocation } from "react-router-dom";

export const useLogisticAutocompleteViewModel = (selectedValue: IMMEntryWithDesc) => {
  const { getPaginated, data, loading } = useListDetailPaginatedViewModel(MaterialRepository);
  const getMaterialPaginated = useRef(getPaginated).current;

  const location = useLocation();
  const outboundMode = location.pathname.includes("outbounds");

  const [options, setOptions] = useState<MaterialDTO[]>([]);

  const DEBOUNCE_TIME = 1000;
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);
  const [materialPlaceholder, setMaterialPlaceholder] = useState<MaterialDTO>(
    selectedValue ?? {
      id: "0",
      name: "",
      code: "",
      families: [],
      locations: [],
      minStock: 0,
      unitPrice: 0,
      uom: "",
      active: true
    }
  );

  useEffect(() => {
    const filtered = (data.content as Material[])?.filter(item => {
      return (
        item.code
          ?.toLowerCase()
          .includes(materialPlaceholder.code?.toLowerCase() ? materialPlaceholder.code.toLowerCase() : "") ||
        item.name?.toLowerCase().includes(materialPlaceholder.code?.toLowerCase() ? materialPlaceholder.code.toLowerCase() : "")
      );
    });
    setOptions(filtered ?? []);
  }, [data.content]);

  useEffect(() => {
    setMaterialPlaceholder({
      ...materialPlaceholder,
      code:
        (selectedValue.materialCode ?? "") + (selectedValue.materialDescription ? " - " + selectedValue.materialDescription : "")
    });
  }, [selectedValue]);

  const obtainDataPaginatedForOptions = (limit: number, offset: number, searchValue: string) => {
    getMaterialPaginated({
      xTenantId: "",
      limit: limit,
      offset: offset,
      fullDTO: true,
      customMaterialFilter: { status: outboundMode ? "" : true, searchText: searchValue }
    });
  };

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const textValue = event.target.value;
    setMaterialPlaceholder({ ...materialPlaceholder, code: textValue });
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    const newDebounceTimer = setTimeout(() => {
      if (textValue.length >= 3) {
        textValue !== "" && obtainDataPaginatedForOptions(10, 0, textValue);
      }
    }, DEBOUNCE_TIME);
    setDebounceTimer(newDebounceTimer);
  };

  const onChangeAutocomplete = (value: MaterialDTO, reason: AutocompleteChangeReason) => {
    if (reason === "clear") {
      setMaterialPlaceholder({ ...materialPlaceholder, code: "" });
    }
    setMaterialPlaceholder(value ?? {});
  };

  return {
    options,
    loading,
    handleSearchInputChange,
    onChangeAutocomplete,
    materialPlaceholder
  };
};
