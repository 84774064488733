import { create } from "@movicoders/store";
import { DrawerState } from "@domain/model/DrawerState";
import { IDrawerService } from "@domain/services/IDrawerService";

export const useDrawerService = create<IDrawerService>((set, get) => ({
  saveDrawerState: (newState: DrawerState) => set({ persistedState: newState }),
  clearDrawerState: () => set({ persistedState: undefined }),
  getPersistedState: () => get().persistedState
}))
  .withPersist(DrawerState, "DrawerState")
  .build();
