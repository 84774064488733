import { useTranslation } from "react-i18next";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";
import { DialogContent, Grid, List, ListItem, ListItemText, Tooltip, Typography } from "@mui/material";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import { useResolve } from "@movicoders/di";
import { IUserService, UserService } from "@domain/services/IUserService";
import { CompanyDTOLicenceEnum, StatusReportDTO } from "@clients/aggrego-proxy";
import { ButtonsDetail } from "@components/master-data-view/buttons/buttons-detail";
import { generateUUID } from "three/src/math/MathUtils";
import { materialConsumptionDialogStyles } from "./material-consumption-dialog.styles";

export const MaterialConsumptionDialog = (props: {
  open: boolean;
  onClose: () => void;
  loadingDetail: boolean;
  selectedstatusReportList: StatusReportDTO[];
  consumptionByLicense: () => void;
  deleteItemFromList: (report: StatusReportDTO, index: number) => void;
}) => {
  const { open, onClose, loadingDetail, selectedstatusReportList, consumptionByLicense, deleteItemFromList } = props;

  const { t } = useTranslation();
  const styles = materialConsumptionDialogStyles();
  const userService = useResolve<IUserService>(UserService);
  const isIM = userService.user?.companyLicense === CompanyDTOLicenceEnum.Im;
  const isWMC = userService.user?.companyLicense === CompanyDTOLicenceEnum.WmWithContainer;

  const dialogTitleConstructor = () => {
    if (isWMC) {
      if (selectedstatusReportList.length === 1) {
        return "warehouse.status.container.consumption.detail.singular.title";
      } else {
        return "warehouse.status.container.consumption.detail.plural.title";
      }
    } else {
      if (selectedstatusReportList.length === 1) {
        return "warehouse.status.material.consumption.detail.singular.title";
      } else {
        return "warehouse.status.material.consumption.detail.plural.title";
      }
    }
  };

  const dialogDescriptionConstructor = () => {
    if (isWMC) {
      if (selectedstatusReportList.length === 1) {
        return "warehouse.status.container.consumption.multiple.singular.desc";
      } else {
        return "warehouse.status.container.consumption.multiple.plural.desc";
      }
    } else {
      if (selectedstatusReportList.length === 1) {
        return "warehouse.status.material.consumption.multiple.singular.desc";
      } else {
        return "warehouse.status.material.consumption.multiple.plural.desc";
      }
    }
  };

  return open ? (
    <AggregoDialog maxWidth="md" title={t(dialogTitleConstructor())} onClose={onClose}>
      <Grid container display="flex" spacing={1} justifyContent="space-between">
        <Grid item xs={12}>
          <Typography variant="cardtitle">
            {t(dialogDescriptionConstructor(), { quantity: selectedstatusReportList?.length })}
          </Typography>
          <DialogContent sx={{ maxHeight: "20rem", overflowY: "auto", mt: "1.5rem" }}>
            <List>
              <ListItem key={generateUUID()}>
                <Grid container display={"block"}>
                  {selectedstatusReportList.map((report, index) => (
                    <Grid
                      container
                      key={generateUUID()}
                      display={"flex"}
                      sx={{ backgroundColor: index % 2 === 0 ? "inherit" : "#ECF0F5" }}
                      padding={"0.5rem"}>
                      {/* GRID INDEX */}
                      <Grid item xs={1.5} alignContent="center">
                        <ListItemText
                          primary={
                            <Typography variant="cardtitle" sx={styles.index_grid_container}>
                              {index + 1}
                            </Typography>
                          }
                        />
                      </Grid>
                      {/* GRID CONTAINER + MATERIAL + DESC */}
                      <Grid item xs={5.8}>
                        <Grid container display="block">
                          {/* CONTAINER GRID */}
                          {isWMC ? (
                            <Grid item xs={12} alignItems={"start"}>
                              <ListItemText
                                primary={
                                  <span>
                                    <Typography component="span" sx={styles.bold_field_name}>
                                      {`${t("warehouse.status.container")}:`}
                                    </Typography>
                                    {` ${report.container}`}
                                  </span>
                                }
                              />
                            </Grid>
                          ) : (
                            !isIM && (
                              <Grid item xs={12} alignItems={"start"}>
                                <ListItemText
                                  primary={
                                    <span>
                                      <Typography component="span" sx={styles.bold_field_name}>
                                        {`${t("warehouse.status.location")}:`}
                                      </Typography>
                                      {` ${report.location}`}
                                    </span>
                                  }
                                />
                              </Grid>
                            )
                          )}
                          {/* MATERIAL CODE GRID */}
                          <Grid item xs={12} alignItems={"start"}>
                            <ListItemText
                              primary={
                                <span>
                                  <Typography component="span" sx={styles.bold_field_name}>
                                    {`${t("warehouse.status.code")}:`}
                                  </Typography>
                                  {` ${report.material}`}
                                </span>
                              }
                            />
                          </Grid>
                          {/* MATERIAL DESCRIPTION GRID */}
                          <Grid item xs={12}>
                            <ListItemText
                              primary={
                                <span>
                                  <Typography component="span" sx={styles.bold_field_name}>
                                    {`${t("warehouse.status.description")}:`}
                                  </Typography>
                                  {` ${report.description}`}
                                </span>
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* GRID QUANTITY */}
                      <Grid item xs={4} alignContent="center" textAlign={"end"}>
                        <ListItemText
                          primary={
                            <span>
                              <Typography component="span" sx={styles.bold_field_name}>
                                {report.qty?.toString()}
                              </Typography>{" "}
                              {report.uom ? report.uom : "-"}
                            </span>
                          }
                        />
                      </Grid>
                      {/* RECYCLER BIN QUANTITY */}
                      <Grid item xs={0.7} alignContent="center" textAlign={"end"} sx={styles.icon_color}>
                        <Tooltip title={t("warehouse.status.delete.from.list")}>
                          <PlaylistRemoveIcon
                            onClick={() => {
                              deleteItemFromList(report, index);
                            }}
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </ListItem>
            </List>
          </DialogContent>
        </Grid>
        <Grid item xs={12}>
          <ButtonsDetail
            handleClose={onClose}
            handleSave={() => {
              consumptionByLicense();
            }}
            mode="CONFIRM"
            pendingRequest={loadingDetail}
          />
        </Grid>
      </Grid>
    </AggregoDialog>
  ) : (
    <></>
  );
};
