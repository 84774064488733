import { useState } from "react";
import { useSnackbar } from "@movicoders/ui";
import { useTranslation } from "react-i18next";
import { GetLatestStockReportRequest } from "../../../../../../clients/aggrego-proxy";

export const useMaterialsStockPagination = (
  selectedWarehouseCode: string | undefined,
  getStockPaginated: (criteria: GetLatestStockReportRequest) => Promise<void>
) => {
  const { t } = useTranslation();
  const { show } = useSnackbar();

  const DEBOUNCE_TIME = 500;
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);

  const handlePagination = (
    limit: number,
    offset: number,
    status?: "ALL" | "ACTIVE" | "INACTIVE" | undefined,
    filters?: object | undefined
  ) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    const newDebounceTimer = setTimeout(() => {
      getStockPaginated({
        limit: limit,
        offset: offset,
        warehouseCode: selectedWarehouseCode,
        stockReportFilter: filters ?? {}
      } as GetLatestStockReportRequest).catch(() => {
        show(t("stock.request.error"), "error");
      });
    }, DEBOUNCE_TIME);
    setDebounceTimer(newDebounceTimer);
  };

  return {
    handlePagination
  };
};
