import Family from "@domain/model/Family";
import { useTranslation } from "react-i18next";
import FamilyType from "@domain/model/FamilyType";
import { CREATE_ID, TIMEOUT_ID } from "@constants";
import { useFamilyForm } from "./hooks/detail/useFamilyForm";
import React, { ChangeEvent, FC } from "react";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";
import { ButtonsDetail } from "@components/master-data-view/buttons/buttons-detail";
import { LoadingSpinnerDetailView } from "@components/loading-circular-progress/loading-spinner-detail-view";

interface IDetail {
  family: Family;
  typesOfFamilies: FamilyType[];
  loading: boolean;
  onSave: (family: Family, typeOfFamilyName?: string) => void;
  onClose: () => void;
}

export const FamiliesDetailView: FC<IDetail> = ({ loading, onSave, onClose, family: initialFamily, typesOfFamilies }) => {
  const { t } = useTranslation();
  const {
    options,
    onChange,
    onChangeAutocomplete,
    onChangeNewType,
    resetNewFamilyType,
    validateFormValues,
    formErrors,
    family,
    myFamilyType,
    newTypeOfFamily,
    newFamilyTypeName,
    pendingRequest
  } = useFamilyForm(initialFamily, typesOfFamilies);

  const disabled = family.active !== undefined && family.active === false;

  const renderInput = (
    id: string,
    name: string,
    value: string,
    label: string,
    onChangeTextField: (event: ChangeEvent<HTMLInputElement>) => void
  ) => {
    return (
      <Grid item>
        <TextField
          error={formErrors.get(id) as boolean}
          helperText={(formErrors.get(id) as boolean) ? t("families.text.empty") : ""}
          id={id}
          name={name}
          disabled={disabled}
          className={disabled ? "disabled-input" : undefined}
          value={value}
          onChange={onChangeTextField}
          label={label}
        />
      </Grid>
    );
  };

  return (
    <React.Fragment>
      {initialFamily.id !== undefined && initialFamily.id !== TIMEOUT_ID ? (
        <AggregoDialog
          title={
            initialFamily.id === CREATE_ID ? t("families.detail.title") : t("families.detail.edit.title") + initialFamily.name
          }
          onClose={() => {
            resetNewFamilyType();
            onClose();
          }}>
          <Grid container flexDirection="column" className="masterdata-box" rowSpacing={2}>
            <Grid item>
              <Typography sx={{ marginTop: "-1.25rem", marginBottom: "0.625rem" }} variant="form_info">
                {t("families.detail.desc")}
              </Typography>
            </Grid>

            {renderInput("family-name", "name", family.name ?? "", t("root.name"), onChange)}

            <Grid item>
              <Autocomplete
                id="family-type-autocomplete"
                value={myFamilyType}
                disabled={disabled}
                options={options}
                getOptionLabel={option => {
                  return option ? option.name : "";
                }}
                onChange={(ev, value) => {
                  onChangeAutocomplete(ev, value);
                }}
                isOptionEqualToValue={(option: FamilyType, value: FamilyType) => option.id === value.id}
                renderInput={params => (
                  <TextField
                    error={formErrors.get(params.id) as boolean}
                    helperText={(formErrors.get(params.id) as boolean) ? t("families.type.not.selected") : ""}
                    name={"type-name"}
                    {...params}
                    label={t("type.of.families")}
                    className={disabled ? "disabled-input" : "blue-border-input"}
                    InputProps={{
                      ...params.InputProps
                    }}
                  />
                )}
              />
            </Grid>

            {newTypeOfFamily === true &&
              renderInput("type-name", "type-name", newFamilyTypeName ?? "", t("root.name"), onChangeNewType)}

            <Grid item className="buttons-detail-grid-item">
              <ButtonsDetail
                handleClose={() => {
                  resetNewFamilyType();
                  onClose();
                }}
                handleSave={() => {
                  if (validateFormValues()) {
                    onSave(family, newFamilyTypeName);
                  }
                }}
                mode={family?.id === CREATE_ID ? "CREATE" : "EDIT"}
                disabled={disabled}
                pendingRequest={pendingRequest}
              />
            </Grid>
          </Grid>
        </AggregoDialog>
      ) : (
        <LoadingSpinnerDetailView loading={loading} selectedId={initialFamily?.id ?? ""} />
      )}
    </React.Fragment>
  );
};
