import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import { useTranslation } from "react-i18next";
import { profileViewStyles } from "../profile-view.Styles";
import { Grid, Typography, Select, MenuItem } from "@mui/material";
import { useLanguageViewModel } from "../hooks/useLanguageViewModel";

const LanguageSelect = () => {
  const { t } = useTranslation();
  const { languages, currentLanguage, changeLanguage } = useLanguageViewModel();

  const styles = profileViewStyles();

  return (
    <Grid container spacing={{ xs: 1 }}>
      <Grid item display="flex" alignItems="center" xs={12} sm={3}>
        <Icon icon="language" color={COLORS.primary} />
        <Typography id="profile-language-label" sx={styles.item_typography}>
          {t("profile.languages")}
        </Typography>
      </Grid>
      <Grid item>
        <Select id="language-selector" size="small" color="secondary" value={currentLanguage} className="table-select">
          {languages.map(l => (
            <MenuItem id={`language-selector-option-${l}`} key={l} value={l} onClick={() => changeLanguage(l)}>
              {t("profile.languages." + l)}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
};

export default LanguageSelect;
