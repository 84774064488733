import { CREATE_ID } from "@constants";
import { useEffect, useState } from "react";
import { useResolve } from "@movicoders/di";
import Warehouse from "@domain/model/Warehouse";
import { handleError } from "@utils/error-helper";
import { LastPageState } from "@domain/model/LastPageState";
import { useWarehousesMessagges } from "./useWarehousesMessagges";
import { IUserService, UserService } from "@domain/services/IUserService";
import { ListWarehousesPaginatedRequest, ResponseError } from "@clients/aggrego-proxy";
import { useImpersonationService } from "@infrastructure/services/useImpersonationService";

export const useWarehouseFormViewModel = (
  currentPagePersistedState: LastPageState,
  selectedWarehouse: Warehouse,
  save: (entity: Warehouse) => Promise<void>,
  warehouseGetPaginated: (criteria: ListWarehousesPaginatedRequest) => Promise<void>,
  getCurrentStatus: (status: "ACTIVE" | "INACTIVE" | "ALL") => boolean | undefined
) => {
  const [warehouse, setWarehouse] = useState<Warehouse>(selectedWarehouse);
  const userService = useResolve<IUserService>(UserService);

  const { persistedState } = useImpersonationService();

  const { errorMessaggesSaving, successMessaggesSaving } = useWarehousesMessagges();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWarehouse({ ...warehouse, [event.target.name]: event.target.value });
  };

  const onChangeNumeric = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9]+$/;
    const inputValue = event.target.value;

    if (regex.test(inputValue) || inputValue === "") {
      const newValue = inputValue === "" ? "0" : inputValue.replace(/^0+/, "");
      setWarehouse({ ...warehouse, [event.target.name]: newValue });
    }
  };

  useEffect(() => {
    setWarehouse(selectedWarehouse);
  }, [selectedWarehouse]);

  const handleSave = async (entity: Warehouse) => {
    let response;
    let isCreate = false;
    try {
      if (entity.id === CREATE_ID) {
        isCreate = true;
        entity.active = true;
        entity.companyId = userService.user?.tenantId;
        entity.image = "";
        entity.tiles = [];
        entity.zones = [];
      }
      entity.capacity = Number(entity.capacity);
      entity.maxSizeY = Number(entity.maxSizeY);
      entity.sizeX = Number(entity.sizeX);
      entity.sizeZ = Number(entity.sizeZ);
      persistedState?.impersonatedTenant && (entity.companyId = persistedState?.impersonatedTenant);
      await save(entity);

      successMessaggesSaving(isCreate);
      warehouseGetPaginated({
        limit: currentPagePersistedState.limit ?? 10,
        offset: currentPagePersistedState.offset ?? 0,
        customWarehouseFilter: {
          ...currentPagePersistedState.filters,
          status: getCurrentStatus(currentPagePersistedState.status)
        }
      } as ListWarehousesPaginatedRequest);
    } catch (error) {
      response = await handleError(error as ResponseError);
      const errorMessage = response?.errors?.[0]?.errorCode?.toLowerCase().split(" ").join(".") ?? "unknown";
      errorMessaggesSaving(entity, errorMessage, isCreate);
    }
  };

  return {
    onChange,
    onChangeNumeric,
    handleSave,
    warehouse
  };
};
