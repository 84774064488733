import { User } from "@domain/model/User";
import { create } from "@movicoders/store";
import { IUserService } from "@domain/services/IUserService";

export const useUserService = create<IUserService>((set, get) => ({
  save: (newState: User) => set(() => ({ user: newState })),
  clear: () => set({ user: undefined }),
  current: () => get().user
}))
  .withPersist(User, "user")
  .build();
