import { TUserRoleEnum } from "@domain/interface/User";
import { IUserService } from "@domain/services/IUserService";
import { TypeOfFamilyApi } from "@infrastructure/api/type-of-families-api";
import { IImpersonationService } from "@domain/services/IImpersonationService";

export class FindTypeOfFamiliesByTenantUseCase {
  constructor(
    private readonly typeOfFamilyApi: TypeOfFamilyApi,
    private readonly userService: IUserService,
    private readonly impersonationService: IImpersonationService
  ) {}

  public async execute(status?: "ALL" | "ACTIVE" | "INACTIVE") {
    const isRole = (role: TUserRoleEnum) => {
      return this.userService.user?.roles.includes(role);
    };

    const currentTenant = isRole("SUPER_ADMIN")
      ? this.impersonationService.persistedState?.impersonatedTenant ?? ""
      : this.userService.user?.tenantId ?? "";

    return await this.typeOfFamilyApi.getByTenantId({ tenantId: currentTenant, fullDTO: false, status: status ?? "ALL" });
  }
}
