/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  CustomWarehouseFilter,
  ListResponseDTO,
  MovicodersBadRequestException,
  MovicodersNotFoundException,
  MovicodersServiceException,
  WarehouseDTO,
  WarehousePageDTO
} from "../models";
import {
  CustomWarehouseFilterFromJSON,
  CustomWarehouseFilterToJSON,
  ListResponseDTOFromJSON,
  ListResponseDTOToJSON,
  MovicodersBadRequestExceptionFromJSON,
  MovicodersBadRequestExceptionToJSON,
  MovicodersNotFoundExceptionFromJSON,
  MovicodersNotFoundExceptionToJSON,
  MovicodersServiceExceptionFromJSON,
  MovicodersServiceExceptionToJSON,
  WarehouseDTOFromJSON,
  WarehouseDTOToJSON,
  WarehousePageDTOFromJSON,
  WarehousePageDTOToJSON
} from "../models";

export interface DeleteWarehouseRequest {
  id: string;
  xTenantId?: string;
}

export interface DeleteWarehousesRequest {
  xTenantId?: string;
  requestBody?: Array<string>;
}

export interface GetWarehouseByExactCodeRequest {
  code: string;
  fullDTO: boolean;
  xTenantId?: string;
}

export interface GetWarehouseByIdRequest {
  id: string;
  fullDTO: boolean;
  xTenantId?: string;
}

export interface GetWarehouseByIdsRequest {
  xTenantId?: string;
  requestBody?: Array<string>;
}

export interface GetWarehousesByCodeRequest {
  code: string;
  xTenantId?: string;
}

export interface ListWarehousesRequest {
  fullDTO: boolean;
  status: ListWarehousesStatusEnum;
  xTenantId?: string;
}

export interface ListWarehousesByTenantIdRequest {
  fullDTO: boolean;
  tenantId: string;
  xTenantId?: string;
  body?: boolean;
}

export interface ListWarehousesPaginatedRequest {
  limit: number;
  offset: number;
  fullDTO: boolean;
  xTenantId?: string;
  sortField?: ListWarehousesPaginatedSortFieldEnum;
  sortDirection?: ListWarehousesPaginatedSortDirectionEnum;
  customWarehouseFilter?: CustomWarehouseFilter;
}

export interface SaveWarehouseRequest {
  xTenantId?: string;
  warehouseDTO?: WarehouseDTO;
}

export interface SaveWarehousesRequest {
  xTenantId?: string;
  warehouseDTO?: Array<WarehouseDTO>;
}

export interface UpdateWarehouseRequest {
  xTenantId?: string;
  warehouseDTO?: WarehouseDTO;
}

/**
 *
 */
export class WarehousesApi extends runtime.BaseAPI {
  /**
   * Takes an existing warehouse, deletes it, and returns the new object.
   * Delete an existing warehouse by id.
   */
  async deleteWarehouseRaw(
    requestParameters: DeleteWarehouseRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<WarehouseDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteWarehouse."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/warehouses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => WarehouseDTOFromJSON(jsonValue));
  }

  /**
   * Takes an existing warehouse, deletes it, and returns the new object.
   * Delete an existing warehouse by id.
   */
  async deleteWarehouse(
    requestParameters: DeleteWarehouseRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<WarehouseDTO> {
    const response = await this.deleteWarehouseRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the deleted Warehouses
   */
  async deleteWarehousesRaw(
    requestParameters: DeleteWarehousesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<WarehouseDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/warehouses/delete-many`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(WarehouseDTOFromJSON));
  }

  /**
   * Returns the deleted Warehouses
   */
  async deleteWarehouses(
    requestParameters: DeleteWarehousesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<WarehouseDTO>> {
    const response = await this.deleteWarehousesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns a warehouse by code.
   * Get warehouse object by a given code.
   */
  async getWarehouseByExactCodeRaw(
    requestParameters: GetWarehouseByExactCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<WarehouseDTO>> {
    if (requestParameters.code === null || requestParameters.code === undefined) {
      throw new runtime.RequiredError(
        "code",
        "Required parameter requestParameters.code was null or undefined when calling getWarehouseByExactCode."
      );
    }

    if (requestParameters.fullDTO === null || requestParameters.fullDTO === undefined) {
      throw new runtime.RequiredError(
        "fullDTO",
        "Required parameter requestParameters.fullDTO was null or undefined when calling getWarehouseByExactCode."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.code !== undefined) {
      queryParameters["code"] = requestParameters.code;
    }

    if (requestParameters.fullDTO !== undefined) {
      queryParameters["fullDTO"] = requestParameters.fullDTO;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/warehouses/code/exact`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => WarehouseDTOFromJSON(jsonValue));
  }

  /**
   * Returns a warehouse by code.
   * Get warehouse object by a given code.
   */
  async getWarehouseByExactCode(
    requestParameters: GetWarehouseByExactCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<WarehouseDTO> {
    const response = await this.getWarehouseByExactCodeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns one warehouse object by id.
   * Get a warehouse object by id.
   */
  async getWarehouseByIdRaw(
    requestParameters: GetWarehouseByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<WarehouseDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getWarehouseById."
      );
    }

    if (requestParameters.fullDTO === null || requestParameters.fullDTO === undefined) {
      throw new runtime.RequiredError(
        "fullDTO",
        "Required parameter requestParameters.fullDTO was null or undefined when calling getWarehouseById."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fullDTO !== undefined) {
      queryParameters["fullDTO"] = requestParameters.fullDTO;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/warehouses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => WarehouseDTOFromJSON(jsonValue));
  }

  /**
   * Returns one warehouse object by id.
   * Get a warehouse object by id.
   */
  async getWarehouseById(
    requestParameters: GetWarehouseByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<WarehouseDTO> {
    const response = await this.getWarehouseByIdRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns warehouse objects by ids.
   * Get a warehouse object by id.
   */
  async getWarehouseByIdsRaw(
    requestParameters: GetWarehouseByIdsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<WarehouseDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/warehouses/find-many-by-id`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(WarehouseDTOFromJSON));
  }

  /**
   * Returns warehouse objects by ids.
   * Get a warehouse object by id.
   */
  async getWarehouseByIds(
    requestParameters: GetWarehouseByIdsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<WarehouseDTO>> {
    const response = await this.getWarehouseByIdsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns warehouses by code.
   * Get warehouses object by a given code.
   */
  async getWarehousesByCodeRaw(
    requestParameters: GetWarehousesByCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<WarehouseDTO>>> {
    if (requestParameters.code === null || requestParameters.code === undefined) {
      throw new runtime.RequiredError(
        "code",
        "Required parameter requestParameters.code was null or undefined when calling getWarehousesByCode."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.code !== undefined) {
      queryParameters["code"] = requestParameters.code;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/warehouses/code`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(WarehouseDTOFromJSON));
  }

  /**
   * Returns warehouses by code.
   * Get warehouses object by a given code.
   */
  async getWarehousesByCode(
    requestParameters: GetWarehousesByCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<WarehouseDTO>> {
    const response = await this.getWarehousesByCodeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered Warehouses
   */
  async listWarehousesRaw(
    requestParameters: ListWarehousesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<WarehouseDTO>>> {
    if (requestParameters.fullDTO === null || requestParameters.fullDTO === undefined) {
      throw new runtime.RequiredError(
        "fullDTO",
        "Required parameter requestParameters.fullDTO was null or undefined when calling listWarehouses."
      );
    }

    if (requestParameters.status === null || requestParameters.status === undefined) {
      throw new runtime.RequiredError(
        "status",
        "Required parameter requestParameters.status was null or undefined when calling listWarehouses."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fullDTO !== undefined) {
      queryParameters["fullDTO"] = requestParameters.fullDTO;
    }

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/warehouses`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(WarehouseDTOFromJSON));
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered Warehouses
   */
  async listWarehouses(
    requestParameters: ListWarehousesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<WarehouseDTO>> {
    const response = await this.listWarehousesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Return warehouses by tenantId.
   * Returns the list of all registered Warehouses by tenantId
   */
  async listWarehousesByTenantIdRaw(
    requestParameters: ListWarehousesByTenantIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<WarehouseDTO>>> {
    if (requestParameters.fullDTO === null || requestParameters.fullDTO === undefined) {
      throw new runtime.RequiredError(
        "fullDTO",
        "Required parameter requestParameters.fullDTO was null or undefined when calling listWarehousesByTenantId."
      );
    }

    if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
      throw new runtime.RequiredError(
        "tenantId",
        "Required parameter requestParameters.tenantId was null or undefined when calling listWarehousesByTenantId."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fullDTO !== undefined) {
      queryParameters["fullDTO"] = requestParameters.fullDTO;
    }

    if (requestParameters.tenantId !== undefined) {
      queryParameters["tenantId"] = requestParameters.tenantId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/warehouses/findByTenantId`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.body as any
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(WarehouseDTOFromJSON));
  }

  /**
   * Return warehouses by tenantId.
   * Returns the list of all registered Warehouses by tenantId
   */
  async listWarehousesByTenantId(
    requestParameters: ListWarehousesByTenantIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<WarehouseDTO>> {
    const response = await this.listWarehousesByTenantIdRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered Warehouses paginated
   */
  async listWarehousesPaginatedRaw(
    requestParameters: ListWarehousesPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<WarehousePageDTO>> {
    if (requestParameters.limit === null || requestParameters.limit === undefined) {
      throw new runtime.RequiredError(
        "limit",
        "Required parameter requestParameters.limit was null or undefined when calling listWarehousesPaginated."
      );
    }

    if (requestParameters.offset === null || requestParameters.offset === undefined) {
      throw new runtime.RequiredError(
        "offset",
        "Required parameter requestParameters.offset was null or undefined when calling listWarehousesPaginated."
      );
    }

    if (requestParameters.fullDTO === null || requestParameters.fullDTO === undefined) {
      throw new runtime.RequiredError(
        "fullDTO",
        "Required parameter requestParameters.fullDTO was null or undefined when calling listWarehousesPaginated."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sortField"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sortDirection"] = requestParameters.sortDirection;
    }

    if (requestParameters.fullDTO !== undefined) {
      queryParameters["fullDTO"] = requestParameters.fullDTO;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/warehouses/paginated`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CustomWarehouseFilterToJSON(requestParameters.customWarehouseFilter)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => WarehousePageDTOFromJSON(jsonValue));
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered Warehouses paginated
   */
  async listWarehousesPaginated(
    requestParameters: ListWarehousesPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<WarehousePageDTO> {
    const response = await this.listWarehousesPaginatedRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the registered Warehouse
   */
  async saveWarehouseRaw(
    requestParameters: SaveWarehouseRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<WarehouseDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/warehouses`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: WarehouseDTOToJSON(requestParameters.warehouseDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => WarehouseDTOFromJSON(jsonValue));
  }

  /**
   * Returns the registered Warehouse
   */
  async saveWarehouse(
    requestParameters: SaveWarehouseRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<WarehouseDTO> {
    const response = await this.saveWarehouseRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the registered Warehouses
   */
  async saveWarehousesRaw(
    requestParameters: SaveWarehousesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ListResponseDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/warehouses/add-many`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.warehouseDTO?.map(WarehouseDTOToJSON)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ListResponseDTOFromJSON(jsonValue));
  }

  /**
   * Returns the registered Warehouses
   */
  async saveWarehouses(
    requestParameters: SaveWarehousesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ListResponseDTO> {
    const response = await this.saveWarehousesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update the fields of an existing warehouse all fields except capacity, companyId, zones and tiles.
   * Update the fields of an existing warehouse all fields except capacity, companyId, zones and tiles.
   */
  async updateWarehouseRaw(
    requestParameters: UpdateWarehouseRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<WarehouseDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/warehouses`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: WarehouseDTOToJSON(requestParameters.warehouseDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => WarehouseDTOFromJSON(jsonValue));
  }

  /**
   * Update the fields of an existing warehouse all fields except capacity, companyId, zones and tiles.
   * Update the fields of an existing warehouse all fields except capacity, companyId, zones and tiles.
   */
  async updateWarehouse(
    requestParameters: UpdateWarehouseRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<WarehouseDTO> {
    const response = await this.updateWarehouseRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

/**
 * @export
 */
export const ListWarehousesStatusEnum = {
  Active: "ACTIVE",
  All: "ALL",
  Inactive: "INACTIVE"
} as const;
export type ListWarehousesStatusEnum = (typeof ListWarehousesStatusEnum)[keyof typeof ListWarehousesStatusEnum];
/**
 * @export
 */
export const ListWarehousesPaginatedSortFieldEnum = {
  Address: "ADDRESS",
  Capacity: "CAPACITY",
  Code: "CODE",
  Maxsizey: "MAXSIZEY",
  Name: "NAME",
  Sizex: "SIZEX",
  Sizez: "SIZEZ",
  Status: "STATUS",
  Tiles: "TILES",
  Zones: "ZONES"
} as const;
export type ListWarehousesPaginatedSortFieldEnum =
  (typeof ListWarehousesPaginatedSortFieldEnum)[keyof typeof ListWarehousesPaginatedSortFieldEnum];
/**
 * @export
 */
export const ListWarehousesPaginatedSortDirectionEnum = {
  Asc: "ASC",
  Desc: "DESC"
} as const;
export type ListWarehousesPaginatedSortDirectionEnum =
  (typeof ListWarehousesPaginatedSortDirectionEnum)[keyof typeof ListWarehousesPaginatedSortDirectionEnum];
