import { WarehouseDTO, ZoneDTO } from "../../../../clients/aggrego-proxy";
import { Tile3D, tile3DFromDTO } from "./Tile3D";

export type Warehouse3D = {
  id?: string;
  code?: string;
  name?: string;
  active?: boolean;
  sizeX?: number;
  sizeZ?: number;
  maxSizeY?: number;
  companyId?: string;
  capacity?: number;
  tiles?: Array<Tile3D>;
  image?: string;
  address?: string;
  zones?: Array<ZoneDTO>;
};

export const warehouse3DFromDTO = (warehouse: WarehouseDTO): Warehouse3D => {

  return {
    ...warehouse,
    tiles: warehouse.tiles?.map(tile3DFromDTO)
  }
}
