import { t } from "i18next";
import { useState } from "react";
import { useResolve } from "@movicoders/di";
import { FullUserDTOCompanyLicenseEnum } from "@clients/aggrego-proxy";
import { IUserService, UserService } from "@domain/services/IUserService";
import { IMMaterialEntryWithValidationDTO } from "../../interfaces/immaterial-interface";

export const useHandleErrorDialog = () => {
  const userService = useResolve<IUserService>(UserService).user;

  const [errorMessage, setErrorMessage] = useState("OK");
  const isIMUser = userService?.companyLicense === FullUserDTOCompanyLicenseEnum.Im;

  const handleErrorDialog = (mode: "OPEN" | "CLOSE", errorMessage?: string, material?: IMMaterialEntryWithValidationDTO) => {
    const translatedError: string[] = errorMessage?.trim().toLowerCase().split("id") ?? [];
    const translatedMainErrorText = translatedError[0]?.replaceAll(" ", ".");
    const error =
      mode === "OPEN"
        ? isIMUser && material && translatedMainErrorText !== "material.does.not.exist"
          ? t(translatedMainErrorText + ".IM", { matName: material.materialCode })
          : t(translatedMainErrorText)
        : "OK";
    setErrorMessage(error);
  };
  return {
    errorMessage,
    handleErrorDialog
  };
};
