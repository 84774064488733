/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InventoryRegistryFilterDTO
 */
export interface InventoryRegistryFilterDTO {
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof InventoryRegistryFilterDTO
     */
    caseInsensitive?: { [key: string]: boolean; };
    /**
     * 
     * @type {string}
     * @memberof InventoryRegistryFilterDTO
     */
    consolidated?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryRegistryFilterDTO
     */
    containerCode?: string;
    /**
     * 
     * @type {Date}
     * @memberof InventoryRegistryFilterDTO
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof InventoryRegistryFilterDTO
     */
    error?: string;
    /**
     * 
     * @type {Date}
     * @memberof InventoryRegistryFilterDTO
     */
    initDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof InventoryRegistryFilterDTO
     */
    inventoryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryRegistryFilterDTO
     */
    locationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryRegistryFilterDTO
     */
    materialCode?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryRegistryFilterDTO
     */
    operatorCode?: string;
    /**
     * 
     * @type {number}
     * @memberof InventoryRegistryFilterDTO
     */
    qty?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryRegistryFilterDTO
     */
    searchText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryRegistryFilterDTO
     */
    status?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InventoryRegistryFilterDTO
     */
    user?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryRegistryFilterDTO
     */
    warehouseCode?: string;
}

/**
 * Check if a given object implements the InventoryRegistryFilterDTO interface.
 */
export function instanceOfInventoryRegistryFilterDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InventoryRegistryFilterDTOFromJSON(json: any): InventoryRegistryFilterDTO {
    return InventoryRegistryFilterDTOFromJSONTyped(json, false);
}

export function InventoryRegistryFilterDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryRegistryFilterDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'caseInsensitive': !exists(json, 'caseInsensitive') ? undefined : json['caseInsensitive'],
        'consolidated': !exists(json, 'consolidated') ? undefined : json['consolidated'],
        'containerCode': !exists(json, 'containerCode') ? undefined : json['containerCode'],
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'error': !exists(json, 'error') ? undefined : json['error'],
        'initDate': !exists(json, 'initDate') ? undefined : (new Date(json['initDate'])),
        'inventoryCode': !exists(json, 'inventoryCode') ? undefined : json['inventoryCode'],
        'locationCode': !exists(json, 'locationCode') ? undefined : json['locationCode'],
        'materialCode': !exists(json, 'materialCode') ? undefined : json['materialCode'],
        'operatorCode': !exists(json, 'operatorCode') ? undefined : json['operatorCode'],
        'qty': !exists(json, 'qty') ? undefined : json['qty'],
        'searchText': !exists(json, 'searchText') ? undefined : json['searchText'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'warehouseCode': !exists(json, 'warehouseCode') ? undefined : json['warehouseCode'],
    };
}

export function InventoryRegistryFilterDTOToJSON(value?: InventoryRegistryFilterDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'caseInsensitive': value.caseInsensitive,
        'consolidated': value.consolidated,
        'containerCode': value.containerCode,
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'error': value.error,
        'initDate': value.initDate === undefined ? undefined : (value.initDate.toISOString()),
        'inventoryCode': value.inventoryCode,
        'locationCode': value.locationCode,
        'materialCode': value.materialCode,
        'operatorCode': value.operatorCode,
        'qty': value.qty,
        'searchText': value.searchText,
        'status': value.status,
        'user': value.user,
        'warehouseCode': value.warehouseCode,
    };
}

