/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomWarehouseFilter
 */
export interface CustomWarehouseFilter {
    /**
     * 
     * @type {string}
     * @memberof CustomWarehouseFilter
     */
    address?: string;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof CustomWarehouseFilter
     */
    caseInsensitive?: { [key: string]: boolean; };
    /**
     * 
     * @type {string}
     * @memberof CustomWarehouseFilter
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomWarehouseFilter
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomWarehouseFilter
     */
    searchText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomWarehouseFilter
     */
    status?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomWarehouseFilter
     */
    tenantId?: string;
}

/**
 * Check if a given object implements the CustomWarehouseFilter interface.
 */
export function instanceOfCustomWarehouseFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomWarehouseFilterFromJSON(json: any): CustomWarehouseFilter {
    return CustomWarehouseFilterFromJSONTyped(json, false);
}

export function CustomWarehouseFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomWarehouseFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': !exists(json, 'address') ? undefined : json['address'],
        'caseInsensitive': !exists(json, 'caseInsensitive') ? undefined : json['caseInsensitive'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'searchText': !exists(json, 'searchText') ? undefined : json['searchText'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
    };
}

export function CustomWarehouseFilterToJSON(value?: CustomWarehouseFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'caseInsensitive': value.caseInsensitive,
        'code': value.code,
        'name': value.name,
        'searchText': value.searchText,
        'status': value.status,
        'tenantId': value.tenantId,
    };
}

