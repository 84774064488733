import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import { useTranslation } from "react-i18next";
import { homeTitleStyles } from "./homeTitleStyles";
import { SearchDataEnum } from "@domain/model/SearchDataEnum";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import { ResultData } from "./interfaces/foundResultData-interface";
import { useHomeAutocompleteViewModel } from "./hooks/useHomeAutocompleteViewModel";

export const HomeAutocomplete = (props: {
  id: string;
  showButton: boolean;
  preselectedValue?: ResultData;
  onClick?: (data: ResultData) => void;
}) => {
  const { t } = useTranslation();
  const { id, showButton, preselectedValue, onClick } = props;
  const styles = homeTitleStyles();
  const { options, dataPlaceholder, finalPackage, loading, handleSearchInputChange, onChangeAutocomplete, goToSearchView } =
    useHomeAutocompleteViewModel(preselectedValue);

  return (
    <>
      <Autocomplete
        id={id}
        value={dataPlaceholder}
        options={loading ? [] : options}
        groupBy={option => option.typeData}
        loading={loading}
        isOptionEqualToValue={(option, value) => option.nameData === value.nameData && option.idData === value.idData}
        onChange={(event, value, reason) => {
          onChangeAutocomplete(value ?? { typeData: SearchDataEnum.DEFAULT, idData: "", nameData: "" });
        }}
        disableClearable
        sx={styles.home_search_autocomplete}
        noOptionsText={
          dataPlaceholder.nameData && dataPlaceholder.nameData?.length >= 3 && dataPlaceholder !== undefined
            ? t("autocomplete.default.no.options")
            : t("autocomplete.input.required")
        }
        renderInput={params => (
          <TextField
            placeholder={t("autocomplete.default.value")}
            name={"type-name"}
            {...params}
            label={t("table.filter.search")}
            className={"blue-border-input"}
            fullWidth
            InputProps={{
              ...params.InputProps,
              onChange: handleSearchInputChange
            }}
          />
        )}
        getOptionLabel={resultData => resultData.nameData ?? ""}
      />
      {showButton && (
        <IconButton
          id="dashboard-search-button"
          sx={styles.home_search_button}
          onClick={() => {
            goToSearchView();
            onClick && onClick(dataPlaceholder);
          }}
          disabled={finalPackage?.itemSelected ? false : true}>
          <Icon icon="search" color={COLORS.white} />
        </IconButton>
      )}
    </>
  );
};
