import { SxProps, Theme } from "@mui/material";

const title_divider: SxProps<Theme> = {
  width: "100%",
  height: "2px",
  marginTop: "0.5rem",
  marginBottom: "0.5rem",
  border: "2px solid rgb(235, 235, 235)"
};

export const productViewStyles = {
  title_divider
};
