import { COLORS } from "@theme";
import { useState } from "react";
import Icon from "@components/icons/icon";
import Material from "@domain/model/Material";
import { useTranslation } from "react-i18next";
import { GridValidRowModel } from "@mui/x-data-grid";
import { InvPeriodDTO } from "@domain/model/InvPeriod";
import { Grid, Theme, useMediaQuery } from "@mui/material";
import { ReportTitle } from "@components/report-view/ReportTitle";
import { inventoryRegistryColumns } from "./inventory-register-columns";
import { OutletContainer } from "@components/outlet-container/outlet-container";
import { DataGridDisplay } from "@components/data-grid-display/data-grid-display";
import { InventoryRegistrySearchBar } from "./components/inventoryRegistrySearchBar";
import { useInventoryRegistryViewModel } from "./hooks/viewmodel/useInventoryRegistryViewModel";
import { LoadingSpinnerMasterView } from "@components/loading-circular-progress/loading-spinner-master-view";
import { ReportEnum } from "@pages/settings/settings-interfaces";

export const InventoryRegisterView = () => {
  const { t } = useTranslation();
  const matchesSM = useMediaQuery((theme: Theme) => theme.breakpoints.up(500), { noSsr: true });
  const matchesLG = useMediaQuery((theme: Theme) => theme.breakpoints.up(1250), { noSsr: true });

  const [searchFunction, setSearchFunction] = useState<((searchBarValue: string | Map<string, string>) => void) | undefined>(
    (searchBarValue: string | Map<string, string>) => {
      return null;
    }
  );

  const {
    searchValue,
    filteredMaterialPaginatedData,
    columnsByMode,
    setSearchValue,
    selectedPeriod,
    setSelectedPeriod,
    clearSearchValue,
    handleEnterReload,
    handleDialogVisibility,
    showInvRegistryDialog,
    onCloseInvRegistryDialog,
    handleInitInventory,
    handleCloseInventory,
    showErrors,
    isActivePeriod,
    isInitPeriodButtonDisabled,
    sortedPeriods,
    inventoryRegistries,
    search,
    loading,
    settingsService
  } = useInventoryRegistryViewModel();

  return (
    <>
      <OutletContainer>
        <Grid container id="first-grid-container" direction="column" mb={1}>
          <Grid container display="flex" flexDirection="column" sx={{ mb: 2 }}>
            <ReportTitle
              icon={<Icon icon="reports" color={COLORS.primary} />}
              name={t("invregistry")}
              isPeriodActive={isActivePeriod}
              selectedPeriod={selectedPeriod}
              handleDialogVisibility={handleDialogVisibility}
              isInitPeriodButtonDisabled={isInitPeriodButtonDisabled}
            />
          </Grid>

          {settingsService.persistedState?.reportsPermissions.includes(ReportEnum.InventoryRegistry) && (
            <DataGridDisplay
              data={(inventoryRegistries.content as GridValidRowModel[]) ?? []}
              columns={inventoryRegistryColumns(t, matchesSM, matchesLG)}
              mode="server"
              viewName="invregistry"
              rowId={(row: GridValidRowModel) => row["id"]}
              handleBackendPagination={(
                limit: number,
                offset: number,
                status?: "ALL" | "ACTIVE" | "INACTIVE" | undefined,
                filters?: object | undefined
              ) => {
                search(limit, offset, status, filters);
              }}
              backendTotalElements={inventoryRegistries.totalElements ?? 0}
              hideColumnsByField={columnsByMode}
              customDisplayComponent={() => undefined}
              toolbarProps={{
                multipleSelectOptions: new Map<string, string[]>([
                  ["materialCode", (filteredMaterialPaginatedData as unknown as Material[])?.map(material => material.code) ?? []]
                ]),
                disableColumnsVisibilityByField: [...columnsByMode, "actions"],
                disableFilterByFields: [...columnsByMode, "quant", "consolidated", "error", "actions"],
                disableFieldsInDownload: [...columnsByMode, "actions"],
                customSearchBar: doFilter => {
                  searchFunction && setSearchFunction(doFilter);
                  return (
                    <InventoryRegistrySearchBar
                      clearSearchValue={clearSearchValue}
                      handleEnterReload={handleEnterReload}
                      handleInitInventory={handleInitInventory}
                      handleCloseInventory={handleCloseInventory}
                      onCloseInvRegistryDialog={onCloseInvRegistryDialog}
                      sortedPeriods={sortedPeriods as InvPeriodDTO[]}
                      searchValue={searchValue}
                      selectPeriod={selectedPeriod ?? {}}
                      setSearchValue={setSearchValue}
                      setSelectPeriod={setSelectedPeriod}
                      showErrors={showErrors}
                      defaultPeriod={sortedPeriods[0] as InvPeriodDTO}
                      showInvRegistryDialog={showInvRegistryDialog}
                      isPeriodActive={isActivePeriod}
                    />
                  );
                }
              }}
            />
          )}
        </Grid>
      </OutletContainer>
      <LoadingSpinnerMasterView loading={loading} />
    </>
  );
};
