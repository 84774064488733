import { SxProps, Theme } from "@mui/material";

const absolute_alert: SxProps<Theme> = {
  color: "white",
  letterSpacing: 1.25,
  alignSelf: "center",
  justifySelf: "center",
  width: "fit-content"
};

const alert_grid: SxProps<Theme> = {
  width: "100%",
  position: "static",
  display: "flex",
  flexDirection: "row",
  marginVertical: "10px",
  borderRadius: "6px",
  alignItems: "center",
  backgroundColor:"rgba(242, 201, 66, .3)",
  padding: 12
};

export const persistedSnackbarStyles = {
  absolute_alert,
  alert_grid
};
