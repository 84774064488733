const monthsArrayEN: string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const humanDateByTimeStamp = (timestamp: number, lang?: string): string => {
  const date = new Date(timestamp).toLocaleString(lang || "es-ES", {
    localeMatcher: "lookup",
    month: "numeric",
    day: "numeric",
    year: "numeric"
  });
  return date;
};

export const humanDateByISODateFormat = (fulldate: string, lang?: string): string => {
  const dateParse = Date.parse(fulldate);
  const date = new Date(dateParse).toLocaleString(lang || "es-ES", {
    localeMatcher: "lookup",
    month: "long",
    day: "numeric",
    year: "numeric"
  });
  return date;
};

/**
 * @param dateString the date in string format yyyy-MM-dd'T'HH:mm:ss.SSS'Z'
 */
export const convertDateStringToFirstMillisecond = (dateString: string) => {
  const date = new Date(dateString);
  const timeOffsetInHours = -date.getTimezoneOffset() / 60;
  date.setHours(timeOffsetInHours);
  return date;
};

/**
 * @param dateString the date in string format yyyy-MM-dd'T'HH:mm:ss.SSS'Z'
 */
export const convertDateStringToLastMillisecond = (dateString: string) => {
  const date = new Date(dateString);
  date.setHours(23 - date.getTimezoneOffset() / 60);
  date.setMinutes(59);
  date.setSeconds(59);
  date.setMilliseconds(999);
  return date;
};

/**
 * @param date the date to be formatted
 */
export const convertDateToFirstMillisecond = (date: Date) => {
  const timeOffsetInHours = -date.getTimezoneOffset() / 60;
  date.setHours(timeOffsetInHours);
  return date;
};

/**
 * @param date the date to be formatted
 */
export const convertDateToLastMillisecond = (date: Date) => {
  date.setHours(23 - date.getTimezoneOffset() / 60);
  date.setMinutes(59);
  date.setSeconds(59);
  date.setMilliseconds(999);
  return date;
};

export type DateFormat = "dd/MM/yyyy" | "MM/dd/yyyy" | "yyyy/MM/dd" | "javascript-default";

export const convertStringToDate = (value: string, format: DateFormat): Date | string => {
  let dateParts: string[];
  let date: Date | string = "";

  switch (format) {
    case "dd/MM/yyyy":
      dateParts = value.split("/");
      date = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
      break;
    case "MM/dd/yyyy":
      dateParts = value.split("/");
      date = new Date(Number(dateParts[2]) - 1, Number(dateParts[0]), Number(dateParts[1]));
      break;
    case "yyyy/MM/dd":
      dateParts = value.split("/");
      date = new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]));
      break;
    case "javascript-default":
      date = new Date(value);
      break;
    default:
      date = value;
  }

  return date;
};

export const convertDateToString = (value: Date, format: DateFormat): string => {
  let formattedDate: string;
  const day: string = String(value.getDate()).padStart(2, "0");
  const month: string = String(value.getMonth() + 1).padStart(2, "0");
  const year: number = value.getFullYear();

  switch (format) {
    case "dd/MM/yyyy":
      formattedDate = `${day}/${month}/${year}`;
      break;
    case "MM/dd/yyyy":
      formattedDate = `${month}/${day}/${year}`;
      break;
    case "yyyy/MM/dd":
      formattedDate = `${year}/${month}/${day}`;
      break;
    case "javascript-default":
      formattedDate = value.toString();
  }

  return formattedDate;
};

export const convertMonthToNumber = (monthText: string) => {
  return monthsArrayEN.findIndex(month => month === monthText);
};

export const isValidDate = (stringDate: string) => {
  const date = new Date(stringDate);
  return !isNaN(date.getTime());
};

export const adjustedDate = (dateString: string) => {
  const date = new Date(dateString);
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
};

export const transformDateString = (dateString: string): { isoFormat: string; startOfDay: string; endOfDay: string } => {
  const date = new Date(dateString);
  const isoFormat = date.toISOString();

  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999);

  return {
    isoFormat,
    startOfDay: date.toISOString(),
    endOfDay: endOfDay.toISOString()
  };
};
