import { useState } from "react";
import Operator from "@domain/model/Operator";
import { GridSortModel } from "@mui/x-data-grid";
import { LastPageState } from "@domain/model/LastPageState";
import { ILastPageService } from "@domain/services/ILastPageService";
import { ListOperatorsPaginatedRequest, ListOperatorsPaginatedSortDirectionEnum } from "@clients/aggrego-proxy";

export const useOperatorPagination = (
  currentPageService: ILastPageService,
  currentPagePersistedState: LastPageState,
  operatorData: Operator[],
  getCurrentStatus: (status: "ALL" | "ACTIVE" | "INACTIVE") => boolean | undefined,
  getOperatorsPaginated: (criteria: ListOperatorsPaginatedRequest) => Promise<void>
) => {
  const DEBOUNCE_TIME = 500;
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);

  const sortByFieldBackend = (model: GridSortModel) => {
    operatorData &&
      operatorData?.length > 0 &&
      getOperatorsPaginated({
        limit: currentPagePersistedState.limit,
        offset: currentPagePersistedState.offset,
        customOperatorFilter: {
          ...currentPagePersistedState.filters,
          active: getCurrentStatus(currentPagePersistedState.status)
        },
        sortDirection: model[0]?.sort?.toUpperCase() ?? ListOperatorsPaginatedSortDirectionEnum.Desc,
        sortField: model[0]?.field?.toUpperCase()
      } as ListOperatorsPaginatedRequest);

    currentPageService.saveLastPageState({
      ...currentPagePersistedState,
      sortDirection: model[0]?.sort?.toUpperCase() as "ASC" | "DESC" | undefined,
      sortField: model[0]?.field?.toUpperCase()
    });
  };

  const handlePagination = (limit: number, offset: number, status?: "ALL" | "ACTIVE" | "INACTIVE", filters?: object) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    const newDebounceTimer = setTimeout(() => {
      getOperatorsPaginated({
        limit: limit,
        offset: offset,
        customOperatorFilter: { ...filters, active: getCurrentStatus(status ?? "ALL") },
        sortDirection: currentPagePersistedState.sortDirection,
        sortField: currentPagePersistedState.sortField
      } as ListOperatorsPaginatedRequest);
    }, DEBOUNCE_TIME);
    setDebounceTimer(newDebounceTimer);
  };

  return {
    handlePagination,
    sortByFieldBackend
  };
};
