import {
  UserPermissionsDTOMasterdataPermissionsEnum,
  UserPermissionsDTOReportsPermissionsEnum,
  UserPermissionsDTOLogisticOperationsPermissionsEnum,
  UserPermissionsDTOTrackAndTracePermissionsEnum,
  FullUserDTO
} from "@clients/aggrego-proxy";
import { TUserRoleEnum } from "@domain/interface/User";
import { IUserService } from "@domain/services/IUserService";
import { UserRepository } from "@infrastructure/repositories/user-repository";
import { TLogisticEnum, TMasterDataEnum, TReportEnum, TTrackAndTraceEnum } from "@pages/settings/settings-interfaces";

export interface IUserPermissions {
  selectedRole: TUserRoleEnum;
  accessWarehouseViewer: boolean;
  accessMasterData: TMasterDataEnum[];
  accessReports: TReportEnum[];
  accessLogistic: TLogisticEnum[];
  accessTrackAndTrace: TTrackAndTraceEnum[];
}

export class ChangeSettingsUseCase {
  constructor(private readonly userRepository: UserRepository, private readonly userService: IUserService) {}

  public async recoverFromRole(role: "MOB_USER" | "MOB_ADMIN" | "PORTAL_USER" | "PORTAL_ADMIN" | "SUPER_ADMIN" | null) {
    return this.userRepository.recoverUserRolePermission({ role: role ?? "PORTAL_USER" });
  }

  public async recoverFromCurrentUser(): Promise<FullUserDTO> {
    const currentCredentials = this.userService.user?.credentialsId ?? "";
    return await this.userRepository.getInfoUser(currentCredentials);
  }

  public async execute(permissionData: IUserPermissions) {
    return await this.userRepository.updateUserRolePermissions({
      role: permissionData.selectedRole,
      userPermissionsDTO: {
        masterdataPermissions: permissionData.accessMasterData as unknown as Set<UserPermissionsDTOMasterdataPermissionsEnum>,
        reportsPermissions: permissionData.accessReports as unknown as Set<UserPermissionsDTOReportsPermissionsEnum>,
        logisticOperationsPermissions:
          permissionData.accessLogistic as unknown as Set<UserPermissionsDTOLogisticOperationsPermissionsEnum>,
        trackAndTracePermissions:
          permissionData.accessTrackAndTrace as unknown as Set<UserPermissionsDTOTrackAndTracePermissionsEnum>
      }
    });
  }
}
