import { parseJwt } from "@utils/token-helper";
import { DomainUser } from "@movicoders/domain";
import { checkStrings } from "@utils/string-helper";
import { DTOConvertibleEntity } from "./GenericPage";
import IUser, { TUserRoleEnum, UserValidatedEnum } from "../interface/User";
import { ExtendedUserDTOValidatedEnum, ExtendedUserDTO as UserDTO, User as APIUser, FullUserDTO } from "@clients/aggrego-proxy";
import { TLogisticEnum, TMasterDataEnum, TReportEnum, TTrackAndTraceEnum } from "@pages/settings/settings-interfaces";

export class User extends DTOConvertibleEntity<UserDTO, User> implements DomainUser, IUser {
  public constructor();
  public constructor(obj?: Partial<User>);
  public constructor(obj?: Partial<User>) {
    super();
    obj && Object.assign(this, obj);
  }

  public id? = "";
  public masterdataPermissions? = new Array<TMasterDataEnum>();
  public logisticOperationsPermissions? = new Array<TLogisticEnum>();
  public reportsPermissions? = new Array<TReportEnum>();
  public trackAndTracePermissions? = new Array<TTrackAndTraceEnum>();
  public active = false;
  public validated: UserValidatedEnum = UserValidatedEnum.Accepted;
  public email = "";
  public username = "";
  public token = "";
  public credentialsId = "";
  public companyLicense = "";
  public warehousePolicy = "";
  public profileId = "";
  public preferences = "";
  public roles = new Array<TUserRoleEnum>();
  public tenantId = "";
  public password = "";
  public firstName = "";
  public firstSurname = "";
  public secondSurname = "";
  public companyImage = "";
  public enabledNFAEmail = false;
  public enabledNFAOPT = false;
  public hasVersion = false;

  public hasPermission(permissions: string[]): boolean {
    const includes = this.roles.find(role => permissions.includes(role));
    return includes !== undefined;
  }

  public isCompletelyLoaded(): boolean {
    return checkStrings([this.email, this.username, this.credentialsId, this.roles.join(","), this.tenantId]);
  }

  public getTenantFromToken(): string {
    const userTokenInfo = this.token?.includes(".") ? parseJwt(this.token) : {};
    return userTokenInfo.tenantId ?? "";
  }

  public getUserIdFromToken(): string {
    const userTokenInfo = this.token?.includes(".") ? parseJwt(this.token) : {};
    return userTokenInfo.userId ?? "";
  }

  public getRoleFromToken(): string {
    const userTokenInfo = this.token?.includes(".") ? parseJwt(this.token) : {};
    return userTokenInfo.role ?? "";
  }

  public fromDTO(userDTO: UserDTO): User {
    return new User({
      id: userDTO.id ?? "",
      active: Boolean(userDTO.active).valueOf(),
      validated: this.convertValidatedStatusFromDTO(userDTO.validated),
      email: userDTO.email ?? "",
      token: "",
      masterdataPermissions: userDTO.masterdataPermissions as unknown as TMasterDataEnum[],
      logisticOperationsPermissions: userDTO.logisticOperationsPermissions as unknown as TLogisticEnum[],
      reportsPermissions: userDTO.reportsPermissions as unknown as TReportEnum[],
      trackAndTracePermissions: userDTO.trackAndTracePermissions as unknown as TTrackAndTraceEnum[],
      username: userDTO.username,
      credentialsId: userDTO.credentialsId ?? "",
      profileId: userDTO.id ?? "",
      roles: Array.from(userDTO.roles ?? []) as TUserRoleEnum[],
      tenantId: userDTO.tenantId ?? "",
      firstName: userDTO.firstName ?? "",
      secondSurname: userDTO.secondSurname ?? "",
      firstSurname: userDTO.firstSurname ?? "",
      enabledNFAEmail: userDTO.enabledNFAEmail,
      enabledNFAOPT: userDTO.enabledNFAOPT
    });
  }

  public fromAPIUser(apiUser: APIUser): User {
    return new User({
      active: apiUser.credentials?.active,
      validated: this.convertValidatedStatusFromDTO(apiUser.profile?.status),
      email: apiUser.profile?.email ?? "",
      token: "",
      masterdataPermissions: [],
      logisticOperationsPermissions: [],
      trackAndTracePermissions: [],
      reportsPermissions: [],
      username: apiUser.credentials?.username,
      credentialsId: apiUser.profile?.credentialsId ?? "",
      profileId: apiUser.profile?.profileId ?? "",
      preferences: "",
      firstName: "",
      firstSurname: "",
      secondSurname: "",
      roles: Array.from(apiUser.profile?.roles ?? []) as TUserRoleEnum[],
      tenantId: apiUser.credentials?.tenant ?? ""
    });
  }

  public toDTO(user: User): UserDTO {
    return {
      id: user.id,
      active: Boolean(user.active).valueOf(),
      validated: this.convertValidatedStatusToDTO(user.validated),
      email: user.email || "",
      username: user.username,
      credentialsId: user.credentialsId || "",
      roles: new Set(user.roles),
      tenantId: user.tenantId || "",
      firstName: user.firstName,
      secondSurname: user.secondSurname,
      firstSurname: user.firstSurname
    };
  }

  private convertValidatedStatusFromDTO(status: ExtendedUserDTOValidatedEnum | undefined): UserValidatedEnum {
    let convertedStatus: UserValidatedEnum = UserValidatedEnum.Accepted;
    switch (status) {
      case ExtendedUserDTOValidatedEnum.Accepted:
        convertedStatus = UserValidatedEnum.Accepted;
        break;
      case ExtendedUserDTOValidatedEnum.Requested:
        convertedStatus = UserValidatedEnum.Requested;
        break;
      case ExtendedUserDTOValidatedEnum.Denied:
        convertedStatus = UserValidatedEnum.Denied;
        break;
    }
    return convertedStatus;
  }

  private convertValidatedStatusToDTO(status: UserValidatedEnum): ExtendedUserDTOValidatedEnum {
    let convertedStatus: ExtendedUserDTOValidatedEnum = ExtendedUserDTOValidatedEnum.Accepted;
    switch (status) {
      case UserValidatedEnum.Accepted:
        convertedStatus = ExtendedUserDTOValidatedEnum.Accepted;
        break;
      case UserValidatedEnum.Requested:
        convertedStatus = ExtendedUserDTOValidatedEnum.Requested;
        break;
      case UserValidatedEnum.Denied:
        convertedStatus = ExtendedUserDTOValidatedEnum.Denied;
        break;
    }
    return convertedStatus;
  }

  /**
   * Verifies if the properties of an object match with every property of the User class.
   * This function is useful when using 'instanceof' is not possible, such as when the instance has been lost.
   *
   * @param {object} object - The object to be checked.
   * @returns {boolean} - Returns true if the object matches the User class properties; otherwise, returns false.
   */
  static isUser = (object: unknown): object is User => {
    if (typeof object !== "object" || object === null) {
      return false;
    }

    const expectedKeys: (keyof User)[] = [
      "id",
      "active",
      "validated",
      "email",
      "username",
      "token",
      "credentialsId",
      "companyLicense",
      "warehousePolicy",
      "profileId",
      "preferences",
      "roles",
      "tenantId",
      "password",
      "firstSurname",
      "firstName",
      "secondSurname",
      "masterdataPermissions",
      "logisticOperationsPermissions",
      "reportsPermissions",
      "trackAndTracePermissions"
    ];

    for (const key of expectedKeys) {
      if (!(key in object)) {
        return false;
      }
    }

    return true;
  };

  static isFullUserDTO = (object: unknown): object is FullUserDTO => {
    if (typeof object !== "object" || object === null) {
      return false;
    }

    const expectedKeys: (keyof FullUserDTO)[] = [
      "active",
      "companyLicense",
      "credentialsId",
      "email",
      "firstName",
      "firstSurname",
      "id",
      "profileId",
      "roles",
      "secondSurname",
      "tenantId",
      "username",
      "validated",
      "warehousePolicy"
    ];

    for (const key of expectedKeys) {
      if (!(key in object)) {
        return false;
      }
    }

    return true;
  };
}
