import { useResolve } from "@movicoders/di";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MovementsRepository } from "@infrastructure/repositories/movements-repository";

interface MovementCounts {
  [user: string]: {
    [dayOfWeek: string]: number;
  };
}

export interface MovementData {
  user: string;
  dailyCounts: Record<number, number>;
}

export const useGraphicMovementsData = (maxLimitValue: number, selectedWarehouseCode: string | undefined) => {
  const { t } = useTranslation();
  const movementsRepository = useResolve<MovementsRepository>(MovementsRepository);
  const [lastWeekMovementsData, setLastWeekMovementsData] = useState<MovementData[]>([]);
  //State for paint the graphic lines
  const [graphicData, setGraphicData] = useState<{ label: string; data: number[] }[]>([]);

  const calculateWarehouseLastWeekMovements = () => {
    getLastWeekMovements();
  };

  //gets all last week movements
  const getLastWeekMovements = () => {
    const currentDate = new Date();
    //get time offset from UTC (in milliseconds)
    const utcDifference = currentDate.getTimezoneOffset() * 60 * 1000;
    //get current date in local time
    const localCurrentDate = new Date(currentDate.getTime() - utcDifference);
    const aWeekAgoDate = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);
    aWeekAgoDate.setUTCHours(0, 0, 0, 0);
    movementsRepository
      .getPaginated({
        limit: maxLimitValue,
        offset: 0,
        customMovementEventFilter: {
          warehouse: selectedWarehouseCode,
          initDate: aWeekAgoDate,
          endDate: localCurrentDate
        }
      })
      .then(result => {
        //init an object to store movement counts per user and day of the week.
        const movementCounts: MovementCounts = {};
        //get the date range for the last week
        const dateIterator = new Date(aWeekAgoDate);
        const endDate = new Date(localCurrentDate);
        const dateCounts: Record<string, number> = {};
        while (dateIterator <= endDate) {
          const formattedDate = dateIterator.toLocaleDateString("es-ES", { day: "2-digit", month: "2-digit" });
          dateCounts[formattedDate] = 0;
          dateIterator.setDate(dateIterator.getDate() + 1);
        }
        //for each entry, count the movements
        result.content?.forEach(movement => {
          const user = movement.operatorCode ?? t("home.operator.unknown");
          const actionDate = new Date(movement.actionDate ? movement.actionDate : 0);
          //format date -> DD/MM
          const formattedDate = actionDate.toLocaleDateString("es-ES", { day: "2-digit", month: "2-digit" });
          //increase the movement counter for this user and day of the week
          if (!movementCounts[user]) {
            movementCounts[user] = { ...dateCounts };
          }
          movementCounts[user][formattedDate]++;
        });
        //converts the object into an array
        const userMovementCountsArray = Object.entries(movementCounts).map(([user, counts]) => {
          return {
            user: user,
            dailyCounts: counts
          };
        });
        setLastWeekMovementsData(userMovementCountsArray);
      });
  };
  //When lastWeekMovementsData is obtained
  useEffect(() => {
    const array: { label: string; data: number[] }[] = [];
    setGraphicData([]);
    lastWeekMovementsData.forEach(item => {
      array.push({ label: item.user, data: Object.values(item.dailyCounts) });
    });
    setGraphicData(array);
  }, [lastWeekMovementsData]);

  const resetAllGraphics = () => {
    setLastWeekMovementsData([]);
    setGraphicData([]);
  };

  return { calculateWarehouseLastWeekMovements, lastWeekMovementsData, graphicData, resetAllGraphics };
};
