/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LocationDTO } from './LocationDTO';
import {
    LocationDTOFromJSON,
    LocationDTOFromJSONTyped,
    LocationDTOToJSON,
} from './LocationDTO';
import type { MaterialDTO } from './MaterialDTO';
import {
    MaterialDTOFromJSON,
    MaterialDTOFromJSONTyped,
    MaterialDTOToJSON,
} from './MaterialDTO';

/**
 * 
 * @export
 * @interface FamilyDTO
 */
export interface FamilyDTO {
    /**
     * 
     * @type {boolean}
     * @memberof FamilyDTO
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FamilyDTO
     */
    familyTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof FamilyDTO
     */
    id?: string;
    /**
     * 
     * @type {Array<LocationDTO>}
     * @memberof FamilyDTO
     */
    locations?: Array<LocationDTO>;
    /**
     * 
     * @type {Array<MaterialDTO>}
     * @memberof FamilyDTO
     */
    materials?: Array<MaterialDTO>;
    /**
     * 
     * @type {string}
     * @memberof FamilyDTO
     */
    name?: string;
}

/**
 * Check if a given object implements the FamilyDTO interface.
 */
export function instanceOfFamilyDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FamilyDTOFromJSON(json: any): FamilyDTO {
    return FamilyDTOFromJSONTyped(json, false);
}

export function FamilyDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FamilyDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'familyTypeId': !exists(json, 'familyTypeId') ? undefined : json['familyTypeId'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'locations': !exists(json, 'locations') ? undefined : ((json['locations'] as Array<any>).map(LocationDTOFromJSON)),
        'materials': !exists(json, 'materials') ? undefined : ((json['materials'] as Array<any>).map(MaterialDTOFromJSON)),
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function FamilyDTOToJSON(value?: FamilyDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'familyTypeId': value.familyTypeId,
        'id': value.id,
        'locations': value.locations === undefined ? undefined : ((value.locations as Array<any>).map(LocationDTOToJSON)),
        'materials': value.materials === undefined ? undefined : ((value.materials as Array<any>).map(MaterialDTOToJSON)),
        'name': value.name,
    };
}

