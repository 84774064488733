import { Warehouse3D } from "../types/Warehouse3D";

export const EMPTY_WAREHOUSE: Warehouse3D = {
  id: "",
  sizeX: 1,
  sizeZ: 1,
  maxSizeY: 1,
  tiles: [],
  name: ""
}
