import LocationEntity from "@domain/model/Location";
import { LocationRepository } from "@infrastructure/repositories/location-repository";

export class LocationUpdateStatusUseCase {
  constructor(private readonly locationRepository: LocationRepository) {}

  public async execute(type: LocationEntity) {
    const modifiedType = { ...type, active: !type.active };
    return await this.locationRepository.update(modifiedType);
  }
}
