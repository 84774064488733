import { Grid } from "@mui/material";
import Zone from "@domain/model/Zone";
import { useTranslation } from "react-i18next";
import { DomainPage } from "@movicoders/domain";
import { useCheckStatus } from "@hooks/useCheckStatus";
import { CardsDisplay } from "../components/ZoneCardsDisplay";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid";
import { DataGridDisplay } from "@components/data-grid-display/data-grid-display";

interface IZonesTable {
  zoneData: DomainPage<Zone>;
  handleEdit: (id: string) => void;
  handleOpenToggleActiveStatusDialog: (zone: Zone) => void;
  handlePagination: (
    limit: number,
    offset: number,
    status?: "ACTIVE" | "INACTIVE" | "ALL" | undefined,
    filters?: object | undefined
  ) => void;
}

const ZonesViewTable = (props: IZonesTable) => {
  const { t } = useTranslation();
  const { handleStatusFilter } = useCheckStatus();
  const { zoneData, handleEdit, handlePagination, handleOpenToggleActiveStatusDialog } = props;

  const zoneViewCols: GridColDef[] = [
    { field: "name", headerName: t("home.name") },
    {
      field: "active",
      headerName: t("zones.state"),
      valueGetter: data => {
        return data.row.active ? t("zones.active") : t("zones.inactive");
      }
    },
    { field: "color", headerName: t("zones.color") }
  ];

  return (
    <DataGridDisplay
      data={(zoneData.content as GridValidRowModel[]) ?? []}
      columns={zoneViewCols}
      mode="server"
      viewName="zones"
      handleBackendPagination={handlePagination}
      backendTotalElements={zoneData.totalElements ?? 0}
      rowsPerPageOptions={[12, 24, 48, 96]}
      customDisplayComponent={() => (
        <Grid container id="container-zone-cards" spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          <CardsDisplay
            dataPaginated={zoneData as DomainPage<Zone>}
            handleOpenToggleActiveStatusDialog={handleOpenToggleActiveStatusDialog}
            handleOpenUpsertDialog={handleEdit}
          />
        </Grid>
      )}
      toolbarProps={{
        isActiveFilterHidden: handleStatusFilter(),
        isFilterIconButtonHidden: true,
        isColumnsMenuIconButtonHidden: true,
        parseCSVInDownload: data => {
          const dataForCsv = { ...data };
          dataForCsv.active = dataForCsv.active ? t("zones.active") : t("zones.inactive");
          return dataForCsv;
        }
      }}
    />
  );
};

export default ZonesViewTable;
