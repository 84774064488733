import { useResolve } from "@movicoders/di";
import { useSnackbar } from "@movicoders/ui";
import { ChangeEvent, useState } from "react";
import { IDialogsStatesStore } from "../../../stores/dialogs-states-store/IDialogsStatesStore";
import { useDialogsStatesStore } from "../../../stores/dialogs-states-store/dialogs-states-store";
import { IWarehouse3DStore } from "../../../stores/warehouse-3d-store/IWarehouse3DStore";
import { useWarehouse3DStore } from "../../../stores/warehouse-3d-store/warehouse-3d-store";

export const useSearchByCodeMenuViewmodel = () => {
  const { getWarehouse } = useResolve<IWarehouse3DStore>(useWarehouse3DStore);
  const { setSelectedBox, setBoxDialogOpen } = useResolve<IDialogsStatesStore>(useDialogsStatesStore);
  const [binCodeSearchArgs, setBinCodeSearchArgs] = useState("");
  const { show } = useSnackbar();

  const handleChangeSearchArg = (event: ChangeEvent<HTMLInputElement>) => {
    setBinCodeSearchArgs(event.target.value);
  }

  const findTileByBinCode = () => {
    let found = false;
    getWarehouse().tiles?.forEach(tile => {
      const foundBin = tile.locations?.find(b => b.code === binCodeSearchArgs);
      if (foundBin !== undefined) {
        setSelectedBox(tile);
        setBoxDialogOpen(true);
        found = true;
      }
    });

    if (!found) {
      setBoxDialogOpen(false);
      setSelectedBox(undefined);
      show("Not found", "error");
    }
  };

  return {
    findTileByBinCode,
    handleChangeSearchArg
  };
};
