import { COLORS } from "@theme";
import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";

export const materialConsumptionDialogStyles = () => {
  const index_grid_container: SxProps<Theme> = {
    color: COLORS.gray,
    fontSize: "1.5rem"
  };

  const bold_field_name: SxProps<Theme> = {
    fontWeight: "bold"
  };

  const icon_color: SxProps<Theme> = {
    color: COLORS.gray,
    cursor: "pointer",
    "&:hover": {
      color: COLORS.alert
    }
  };

  return {
    index_grid_container,
    bold_field_name,
    icon_color
  };
};
