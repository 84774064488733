import Icon from "../../icons/icon";
import { AGGREGO_APK_LINK, ROUTER_PATHS } from "@constants";
import { UserRoleEnum } from "@domain/interface/User";
import { PortalTypes } from "@domain/model/PortalTypes";
import { FullUserDTOCompanyLicenseEnum } from "@clients/aggrego-proxy";
import { IImpersonationService } from "@domain/services/IImpersonationService";

export interface IPath {
  name: string;
  path: string;
  icon: JSX.Element;
  permissions: string[];
  id?: string;
  needsTenant?: boolean;
  hiddenByIM?: boolean;
}

export function drawerPaths(companyLicense?: FullUserDTOCompanyLicenseEnum): IPath[] {
  const isIMUser = companyLicense === FullUserDTOCompanyLicenseEnum.Im;
  return [
    {
      name: "warehouse.viewer",
      icon: <Icon icon="3d" />,
      id: "toolbar-drawer-button-warehouse-viewer",
      path: ROUTER_PATHS.warehouseViewer,
      permissions: [],
      needsTenant: true,
      hiddenByIM: isIMUser
    }
  ];
}

export function drawerCompanies(): IPath[] {
  return [
    {
      name: "companies",
      icon: <Icon icon="company" />,
      id: "toolbar-drawer-button-companies",
      path: ROUTER_PATHS.adminBoard,
      permissions: [UserRoleEnum.SuperAdmin]
    }
  ];
}

export function drawerMasterData(companyLicense?: FullUserDTOCompanyLicenseEnum): IPath[] {
  const isIMUser = companyLicense === FullUserDTOCompanyLicenseEnum.Im;

  return [
    {
      name: "warehouses",
      icon: <Icon icon="warehouse" />,
      id: "toolbar-drawer-button-warehouses",
      path: ROUTER_PATHS.warehouses,
      permissions: [],
      needsTenant: true
    },
    {
      name: "users",
      icon: <Icon icon="users" />,
      id: "toolbar-drawer-button-users",
      path: ROUTER_PATHS.users,
      permissions: [UserRoleEnum.PortalAdmin, UserRoleEnum.SuperAdmin],
      needsTenant: true
    },
    {
      name: "type-of-families",
      icon: <Icon icon="categories" />,
      id: "toolbar-drawer-button-type-of-families",
      path: ROUTER_PATHS.typeOfFamilies,
      permissions: [],
      needsTenant: true
    },
    {
      name: "families",
      icon: <Icon icon="category" />,
      id: "toolbar-drawer-button-families",
      path: ROUTER_PATHS.families,
      permissions: [],
      needsTenant: true
    },
    {
      name: "materials",
      icon: <Icon icon="item" />,
      id: "toolbar-drawer-button-materials",
      path: ROUTER_PATHS.materials,
      permissions: [],
      needsTenant: true
    },
    {
      name: "locations",
      icon: <Icon icon="location" />,
      id: "toolbar-drawer-button-locations",
      path: ROUTER_PATHS.locations,
      permissions: [],
      needsTenant: true,
      hiddenByIM: isIMUser
    },
    {
      name: "zones",
      icon: <Icon icon="zonas" />,
      id: "toolbar-drawer-button-zones",
      path: ROUTER_PATHS.zones,
      permissions: [],
      needsTenant: true,
      hiddenByIM: isIMUser
    },
    {
      name: "customers",
      icon: <Icon icon="users" />,
      id: "toolbar-drawer-button-customers",
      path: ROUTER_PATHS.customers,
      permissions: [],
      needsTenant: true
    },
    {
      name: "manufacturers",
      icon: <Icon icon="users" />,
      id: "toolbar-drawer-button-manufacturers",
      path: ROUTER_PATHS.manufacturers,
      permissions: [],
      needsTenant: true
    },
    {
      name: "owners",
      icon: <Icon icon="users" />,
      id: "toolbar-drawer-button-owners",
      path: ROUTER_PATHS.owners,
      permissions: [],
      needsTenant: true
    },
    {
      name: "suppliers",
      icon: <Icon icon="users" />,
      id: "toolbar-drawer-button-suppliers",
      path: ROUTER_PATHS.suppliers,
      permissions: [],
      needsTenant: true
    },
    {
      name: "operators",
      icon: <Icon icon="supervisor" />,
      id: "toolbar-drawer-button-operators",
      path: ROUTER_PATHS.operators,
      permissions: [],
      needsTenant: true
    }
  ];
}

export function drawerReports(companyLicense?: FullUserDTOCompanyLicenseEnum): IPath[] {
  const isIMUser = companyLicense === FullUserDTOCompanyLicenseEnum.Im;
  const isWMCUser = companyLicense === FullUserDTOCompanyLicenseEnum.WmWithContainer;

  return [
    {
      name: "warehouse-status",
      icon: <Icon icon="warehouse" />,
      id: "toolbar-drawer-button-warehouse-status",
      path: ROUTER_PATHS.whstatus,
      permissions: [],
      needsTenant: true
    },
    {
      name: "inventory-report",
      icon: <Icon icon="inventory" />,
      id: "toolbar-drawer-button-inventory-report",
      path: ROUTER_PATHS.invreport,
      permissions: [],
      needsTenant: true,
      hiddenByIM: isIMUser
    },
    {
      name: "movements-report",
      icon: <Icon icon="location" />,
      id: "toolbar-drawer-button-movements-report",
      path: ROUTER_PATHS.movreport,
      permissions: [],
      needsTenant: true
    },
    {
      name: "materials-stock",
      icon: <Icon icon="box" />,
      id: "toolbar-drawer-button-materials-stock",
      path: ROUTER_PATHS.stock,
      permissions: [],
      needsTenant: true,
      hiddenByIM: isIMUser
    },
    {
      name: "inventory-registry",
      icon: <Icon icon="reports" />,
      id: "toolbar-drawer-button-inventory-registry",
      path: ROUTER_PATHS.invregistry,
      permissions: [],
      needsTenant: true,
      hiddenByIM: isIMUser
    },
    {
      name: "relabel-report",
      icon: <Icon icon="sync" />,
      id: "toolbar-drawer-button-relabel-report",
      path: ROUTER_PATHS.relabelreport,
      permissions: [],
      needsTenant: true,
      hiddenByIM: !isWMCUser
    }
  ];
}

export function drawerLogistic(companyLicense?: FullUserDTOCompanyLicenseEnum): IPath[] {
  const isIMUser = companyLicense === FullUserDTOCompanyLicenseEnum.Im;

  return [
    {
      name: "inbounds",
      icon: <Icon icon="material-in" />,
      id: "toolbar-drawer-button-inbounds",
      path: ROUTER_PATHS.inbounds,
      permissions: [UserRoleEnum.PortalAdmin, UserRoleEnum.SuperAdmin],
      needsTenant: true,
      hiddenByIM: !isIMUser
    },
    {
      name: "outbounds",
      icon: <Icon icon="material-out" />,
      id: "toolbar-drawer-button-outbounds",
      path: ROUTER_PATHS.outbounds,
      permissions: [UserRoleEnum.PortalAdmin, UserRoleEnum.SuperAdmin],
      needsTenant: true,
      hiddenByIM: !isIMUser
    },
    {
      name: "inventory-material",
      icon: <Icon icon="box" />,
      id: "toolbar-drawer-button-inventory-material",
      path: ROUTER_PATHS.inventoryMaterial,
      permissions: [UserRoleEnum.PortalAdmin, UserRoleEnum.SuperAdmin],
      needsTenant: true,
      hiddenByIM: !isIMUser
    }
  ];
}

export function drawerTrackAndTrace(companyLicense?: FullUserDTOCompanyLicenseEnum): IPath[] {
  return [
    {
      name: "products",
      icon: <Icon icon="palet" />,
      id: "toolbar-drawer-button-products",
      path: ROUTER_PATHS.products,
      permissions: [],
      needsTenant: true
    },
    {
      name: "hierarchy-creation",
      icon: <Icon icon="hierarchies-product" />,
      id: "toolbar-drawer-button-hierarchy-creation",
      path: ROUTER_PATHS.hierarchyCreation,
      permissions: [],
      needsTenant: true
    }
  ];
}

export const downloadFile = (url: string, filename: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function drawerSettingPaths(
  companyLicense?: FullUserDTOCompanyLicenseEnum,
  impersonationService?: IImpersonationService
): IPath[] {
  return [
    {
      name: impersonationService?.persistedState?.portal === PortalTypes.AGGREGO ? "go.to.admin.portal" : "go.to.aggrego.portal",
      icon: <Icon icon="switch-user" />,
      id: `toolbar-drawer-button-${
        impersonationService?.persistedState?.portal === PortalTypes.AGGREGO
          ? PortalTypes.ADMIN.toLowerCase()
          : PortalTypes.AGGREGO.toLowerCase()
      }-board`,
      path: impersonationService?.persistedState?.portal === PortalTypes.AGGREGO ? ROUTER_PATHS.adminBoard : ROUTER_PATHS.home,
      permissions: [UserRoleEnum.SuperAdmin]
    },
    {
      name: "download.apk",
      icon: (
        <Icon
          icon="phone"
          onClick={() => {
            downloadFile(AGGREGO_APK_LINK, "newestAggregoApk.apk");
          }}
        />
      ),
      id: "toolbar-drawer-button-download-apk",
      path: ROUTER_PATHS.downloadApk,
      permissions: [],
      needsTenant: true
    },
    {
      name: "settings",
      icon: <Icon icon="settings" />,
      id: "toolbar-drawer-button-settings",
      path: ROUTER_PATHS.settings,
      permissions: [UserRoleEnum.SuperAdmin, UserRoleEnum.PortalAdmin],
      needsTenant: true
    },
    {
      name: "profile",
      icon: <Icon icon="user" />,
      id: "toolbar-drawer-button-profile",
      path: ROUTER_PATHS.profile,
      permissions: []
    }
  ];
}
