import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import { useTranslation } from "react-i18next";
import { profileViewStyles } from "../profile-view.Styles";
import { Grid, Typography, TextField, Button } from "@mui/material";
import { usePasswordResetViewModel } from "../hooks/usePasswordResetViewModel";
import { InvalidPasswordTooltip } from "@components/password/invalid-password-tooltip/InvalidPasswordTooltip";

const PasswordReset = () => {
  const { t } = useTranslation();
  const {
    handleResetSubmit,
    newPassword,
    handleChangePassword,
    showEmptyPassError,
    confirmationPass,
    handleChangeConfirmPassword,
    showConfirmationPassError,
    getBannedWordsForPassword
  } = usePasswordResetViewModel();

  const styles = profileViewStyles();

  return (
    <Grid container alignItems="center" spacing={{ xs: 1 }}>
      <Grid item display="flex" xs={12} lg={3}>
        <Icon icon="lock" color={COLORS.primary} />
        <Typography sx={styles.item_typography}>{t("profile.pass.reset.title")}</Typography>
      </Grid>
      <Grid key="profile-user-password" item sm={4} lg={3}>
        <TextField
          name="formConfig"
          value={newPassword}
          id="profile-user-password"
          label={t("profile.pass.reset.field")}
          type="password"
          onChange={handleChangePassword}
          error={showEmptyPassError}
          InputProps={{
            endAdornment:
              <InvalidPasswordTooltip
                currentPassword={newPassword}
                customPlacement="bottom"
                bannedWords={getBannedWordsForPassword()}
              />
          }}
        />
      </Grid>
      <Grid key="profile-user-confirm-password" item sm={4} lg={3}>
        <TextField
          name="formConfig"
          value={confirmationPass}
          id="profile-user-confirm-password"
          label={t("profile.pass.reset.field.repeat")}
          type="password"
          onChange={handleChangeConfirmPassword}
          error={showConfirmationPassError}
        />
      </Grid>
      <Grid item paddingBottom="0.375rem" sm={4} lg={3}>
        <Button id="change-password-button" size="large" color="secondary" variant="outlined" onClick={handleResetSubmit}>
          {t("profile.pass.reset.save")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default PasswordReset;
