import { CompanyDTO, FullCompanyItemDTO } from "@clients/aggrego-proxy";

export const useAdminFormValidations = () => {
  const companyIsNotValid = (company: FullCompanyItemDTO) => {
    return (
      isAnInvalidValue((company as CompanyDTO).name, "text") ||
      isAnInvalidValue(company.address, "text") ||
      isAnInvalidValue(company.fiscalId, "text") ||
      isAnInvalidValue(company.warehousePolicy, "text") ||
      isAnInvalidValue(company.licence, "text")
    );
  };

  const isAnInvalidValue = (value: string | number | undefined, type: string | undefined, sizeToCheck?: number): boolean => {
    if (value?.toString().trim() === "" || value === undefined || (type === "number" && Number(value) <= 0)) {
      return true;
    }

    if (type?.includes("position")) return Number(value) > Number(sizeToCheck);

    return false;
  };

  return { companyIsNotValid, isAnInvalidValue };
};
