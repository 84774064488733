import { COLORS } from "@theme";
import { useTranslation } from "react-i18next";
import Warehouse from "@domain/model/Warehouse";
import { circularProgressStyles } from "./circularProgressStyles";
import { Typography, CircularProgress, Grid } from "@mui/material";
import { useHomeOccupancyData } from "@pages/home/components/hooks/useHomeOccupancyData";

export const WarehouseCircularProgress = (props: { data: Warehouse }): JSX.Element => {
  const { t } = useTranslation();
  const { active } = props.data;
  const { data } = props;
  const { warehouseOcuppancy } = useHomeOccupancyData(data.id ?? "");

  const styles = circularProgressStyles();

  return (
    <Grid item sx={styles.warehouse_progress_container}>
      <CircularProgress variant="determinate" value={100} sx={styles.warehouse_inner_circle} size="8.5rem" />
      <CircularProgress
        id="warehouses-outer-circle"
        variant="determinate"
        value={warehouseOcuppancy}
        size="8.5rem"
        sx={{ color: active ? COLORS.secondary : COLORS.gray, ...styles.warehouses_outer_circle }}
      />
      <Grid id="warehouses-volume-container" sx={styles.warehouses_volume_container}>
        <Typography sx={styles.warehouse_card_volume} className={active ? "" : "disabled"}>
          {warehouseOcuppancy + " %"}
        </Typography>
        <Typography sx={styles.warehouse_card_volume_text}>{t("warehouse.volume")}</Typography>
      </Grid>
    </Grid>
  );
};
