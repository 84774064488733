import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Product } from "@domain/model/Product";
import { renderTextInput } from "./AddedChildren";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";
import { ButtonsDetail } from "@components/master-data-view/buttons/buttons-detail";

const VIEW_I18N = "hierarchy.creation.children.delete.";
const FORM_LABEL_I18N = "hierarchy.creation.children.form.label.";

const DeleteChildDialog = (props: { open: boolean; onClose: () => void; onDelete: (child: Product) => void; child: Product }) => {
  const { t } = useTranslation();
  const { open, child, onClose, onDelete } = props;

  return open ? (
    <AggregoDialog title={t(VIEW_I18N + "item") + "?"} onClose={onClose}>
      <Grid container flexDirection="column" className="masterdata-box" rowSpacing={2}>
        <Grid container spacing={1}>
          {renderTextInput("materialCode", child, 6, t(FORM_LABEL_I18N + "materialCode"))}
          {renderTextInput("qty", child, 3, t(FORM_LABEL_I18N + "qty"))}
          {renderTextInput("uom", child, 3, t(FORM_LABEL_I18N + "uom"))}
          {renderTextInput("serialNumber", child, 6, t(FORM_LABEL_I18N + "serialNumber"))}
          {renderTextInput("batch", child, 6, t(FORM_LABEL_I18N + "batch"))}
        </Grid>
        <Grid item>
          <ButtonsDetail handleClose={onClose} handleSave={() => onDelete(child)} mode="DELETE" />
        </Grid>
      </Grid>
    </AggregoDialog>
  ) : (
    <></>
  );
};

export default DeleteChildDialog;
