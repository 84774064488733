import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Stakeholder from "@domain/model/Stakeholder";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";
import { ButtonsDetail } from "@components/master-data-view/buttons/buttons-detail";

export const StakeholderActivationDialog = (props: {
  open: boolean;
  onClose: () => void;
  onActivation: (provider: Stakeholder) => void;
  stakeholder: Stakeholder;
  stakeholderType: string;
}) => {
  const { t } = useTranslation();
  const { open, onClose, onActivation, stakeholder, stakeholderType } = props;

  const [pendingRequest, setPendingRequest] = useState<boolean>(false);

  useEffect(() => {
    setPendingRequest(false);
  }, [stakeholder]);

  return open ? (
    <AggregoDialog
      title={
        stakeholder.active
          ? t(`${stakeholderType}.deactivate.title`, { stakeholder: stakeholder.code })
          : t(`${stakeholderType}.active.title`, { stakeholder: stakeholder.code })
      }
      onClose={onClose}>
      <Grid container flexDirection="column" className="masterdata-box" rowSpacing={2}>
        <Grid item>
          <div className="confirm-dialog-div-content">
            {stakeholder.active
              ? t(`${stakeholderType}.form.deactivate`, { type: stakeholderType })
              : t(`${stakeholderType}.form.active`, { type: stakeholderType })}
          </div>
        </Grid>
        <Grid item>
          <ButtonsDetail
            handleClose={onClose}
            handleSave={() => {
              setPendingRequest(true);
              onActivation(stakeholder);
            }}
            mode={stakeholder.active ? "ACTIVE" : "INACTIVE"}
            pendingRequest={pendingRequest}
          />
        </Grid>
      </Grid>
    </AggregoDialog>
  ) : (
    <></>
  );
};
