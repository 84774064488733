import { MEDIA } from "@theme";
import Family from "@domain/model/Family";
import Operator from "@domain/model/Operator";
import { useTranslation } from "react-i18next";
import { CREATE_ID, TIMEOUT_ID } from "@constants";
import React, { useState, useEffect } from "react";
import { useOperatorForm } from "./hooks/useOperatorForm";
import { CustomPassword } from "@components/password/CustomPassword";
import { Grid, TextField, Theme, useMediaQuery } from "@mui/material";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";
import { ButtonsDetail } from "@components/master-data-view/buttons/buttons-detail";
import { LoadingSpinnerDetailView } from "@components/loading-circular-progress/loading-spinner-detail-view";

interface IDetail {
  operator: Operator;
  loading: boolean;
  onSave: (operator: Operator) => void;
  onClose: () => void;
}

export const OperatorsDetailView = (props: IDetail) => {
  const { operator: initialOperator, loading, onSave, onClose } = props;
  const {
    modifiedOperator,
    onChange,
    formErrors,
    validateFormValues,
    confirmPasswordValue,
    setConfirmPasswordValue,
    passwordValue,
    setPasswordValue,
    isSamePassword
  } = useOperatorForm({
    selectedOperator: initialOperator
  });

  const { t } = useTranslation();

  const mode = (initialOperator as Operator).id === CREATE_ID ? "CREATE" : "EDIT";
  const disabled = (initialOperator as Operator).active !== undefined && (initialOperator as Operator).active === false;

  const matchesSm = useMediaQuery((theme: Theme) => theme.breakpoints.up(MEDIA.small), { noSsr: true });

  const [pendingRequest, setPendingRequest] = useState<boolean>(false);
  useEffect(() => {
    setPasswordValue("");
    setConfirmPasswordValue("");
    setPendingRequest(false);
  }, [initialOperator]);

  const errorPasswordText = (isConfirm: boolean) => {
    if (formErrors.get("operator-password") as boolean) return t("users.text.empty");
    if (isConfirm && (formErrors.get("operator-equal-password") as boolean)) return t("users.passwords.no.match");
    return "";
  };

  const renderInput = (id: string, name: string, value: string | number, label: string, type?: string) => {
    return (
      <TextField
        error={formErrors.get(id) as boolean}
        helperText={(formErrors.get(id) as boolean) && t(`operators.dialog.empty.field.text`)}
        id={id}
        value={value}
        name={name}
        type={type ?? "text"}
        onChange={onChange}
        label={label}
        disabled={disabled}
        className={disabled ? "disabled-input" : "blue-border-input"}
      />
    );
  };

  return (
    <React.Fragment>
      {(initialOperator as Operator).id !== undefined && (initialOperator as Operator).id !== TIMEOUT_ID ? (
        <AggregoDialog
          title={
            (initialOperator as Operator).id === CREATE_ID
              ? t("operators.detail.title")
              : t("operators.detail.edit.title") + (initialOperator as Operator).code
          }
          onClose={onClose}>
          <Grid
            container
            className="masterdata-box"
            justifyContent="space-between"
            rowSpacing={2}
            columnSpacing={1}
            sx={{ ml: -0.5 }}>
            <Grid item xs={12} md={4}>
              {renderInput("operator-firstName", "firstName", modifiedOperator.firstName ?? "", t("operators.first.name"))}
            </Grid>
            <Grid item xs={12} md={4}>
              {renderInput(
                "operator-firstSurname",
                "firstSurname",
                modifiedOperator.firstSurname ?? "",
                t("operators.first.surname")
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              {renderInput(
                "operator-secondSurname",
                "secondSurname",
                modifiedOperator.secondSurname ?? "",
                t("operators.second.surname")
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              {renderInput("operator-code", "code", modifiedOperator.code ?? "", t("operators.code"))}
            </Grid>
            <Grid item xs={12} md={8}>
              <CustomPassword
                confirmPasswordValue={confirmPasswordValue}
                passwordValue={passwordValue}
                errorTextPass={errorPasswordText(false)}
                errorTextConfirm={errorPasswordText(true)}
                isErrorDisplay={!isSamePassword && passwordValue !== "" && confirmPasswordValue !== ""}
                lgCustom={6}
                mdCustom={6}
                smCustom={12}
                isRowDisplayed={matchesSm}
                setConfirmPasswordValue={setConfirmPasswordValue}
                setPasswordValue={setPasswordValue}
                onChange={onChange}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonsDetail
                handleClose={onClose}
                handleSave={() => {
                  if (validateFormValues(mode === "CREATE")) {
                    setPendingRequest(true);
                    onSave(modifiedOperator);
                  }
                }}
                mode={mode}
                disabled={disabled}
                pendingRequest={pendingRequest}
              />
            </Grid>
          </Grid>
        </AggregoDialog>
      ) : (
        <LoadingSpinnerDetailView loading={loading} selectedId={(initialOperator as Family)?.id ?? ""} />
      )}
    </React.Fragment>
  );
};
