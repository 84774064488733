import { ChangeEvent, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { Product } from "@domain/model/Product";
import { CardTitle } from "../components/CardTitle";
import { Autocomplete, Button, Divider, Grid, TextField } from "@mui/material";
import { FormTextInput } from "../components/FormTextInput";
import { hierarchyCreationStyles } from "../hierarchy-creation-styles";
import { useChildrenFormViewmodel } from "./useChildrenFormViewmodel";

const VIEW_I18N = "hierarchy.creation.children.";
export const VIEW_I18N_ERROR = VIEW_I18N + "error.message.";
export class ChildrenFormErrors {
  materialCode = false;
  serialNumber = false;
  batch = false;
  qty = false;
  uom = false;
  hasErrors = (): boolean => this.materialCode || this.batch || this.qty || this.uom || this.serialNumber;
}
export interface IChildrenFormProps {
  addChildToParent: (product: Product) => ChildrenFormErrors;
  isClearingView: boolean;
  hasVersionLabel: boolean;
}

export const ChildrenForm = ({ addChildToParent, isClearingView, hasVersionLabel }: IChildrenFormProps) => {
  const styles = hierarchyCreationStyles();
  const { t } = useTranslation();
  const { child, updateChild, saveChild, childrenErrors } = useChildrenFormViewmodel(addChildToParent, isClearingView);
  const inputTextChanged = (field: keyof Product) => (e: ChangeEvent<HTMLInputElement>) => updateChild(field, e.target.value);

  const numericInputChanged = (field: keyof Product) => (e: ChangeEvent<HTMLInputElement>) => {
    const filteredValue = e.target.value.replace(/[^0-9.]+/g, "");
    const numericValue = parseFloat(filteredValue);
    const maxValue = 1000000000;

    // If the user tries to input a point after another point the value is not changed.
    if (!filteredValue.endsWith("..")) {
      // When the value exceeds maxValue it stays at said maxValue.
      if (numericValue > maxValue) updateChild(field, maxValue);
      // When the value is NaN (possibly "") the value turns into 0.
      else if (isNaN(numericValue)) updateChild(field, 0);
      // When the last char is '.', the value is saved as an string to keep that '.'.
      else if (filteredValue.endsWith(".") && filteredValue.indexOf(".") === filteredValue.lastIndexOf("."))
        updateChild(field, filteredValue);
      else {
        // The value is rounded to 3 decimals if needed.
        const roundedValue = RegExp(/\d+\.\d{3}/).exec(filteredValue)?.[0];
        // When the last char is '0', the value is saved as an string to keep that number.
        if (filteredValue.indexOf(".") !== -1 && roundedValue === undefined && filteredValue.endsWith("0"))
          updateChild(field, filteredValue);
        else updateChild(field, roundedValue ?? numericValue);
      }
    }
  };

  const autocompleteInputChange = (field: keyof Product, value: string) => {
    updateChild(field, value);
  };

  const selectFieldLabel = (field: keyof Product) => {
    if (field !== "batch" || (field === "batch" && !hasVersionLabel)) {
      return t(VIEW_I18N + "form.label." + field);
    } else {
      return t(VIEW_I18N + "form.label.version");
    }
  };

  const selectErrorFieldMessage = (field: keyof Product) => {
    if (field !== "batch" || (field === "batch" && !hasVersionLabel)) {
      return t(VIEW_I18N_ERROR + field);
    } else {
      return t(VIEW_I18N_ERROR + "version");
    }
  };

  const renderTextInput = (field: keyof Product, width: number) => {
    return (
      <Grid item xs={12} md={6} lg={width}>
        <FormTextInput
          errorText={childrenErrors[field as keyof ChildrenFormErrors] ? selectErrorFieldMessage(field) : ""}
          id={"input_child_" + field}
          name={"child_" + field}
          onChange={inputTextChanged(field)}
          value={child?.[field] as string}
          key={"child_" + field}
          label={selectFieldLabel(field)}
        />
      </Grid>
    );
  };

  const renderNumericInput = (field: keyof Product, width: number) => (
    <Grid item xs={12} md={6} lg={width}>
      <FormTextInput
        errorText={childrenErrors[field as keyof ChildrenFormErrors] ? t(VIEW_I18N_ERROR + field) : ""}
        id={"input_child_" + field}
        name={"child_" + field}
        onChange={numericInputChanged(field)}
        value={child?.[field] as string}
        key={"child_" + field}
        label={t(VIEW_I18N + "form.label." + field)}
      />
    </Grid>
  );

  const renderAutocomplete = (field: keyof Product, width: number) => {
    return (
      <Grid item xs={12} md={6} lg={width}>
        <Autocomplete
          id={"input_" + field}
          value={child.uom}
          options={["Unidades", "Kilogramos", "Litros"]}
          getOptionLabel={(option: string) => {
            if (option === "Unidades") {
              return t("material.measure.units");
            } else if (option === "Kilogramos") {
              return t("material.measure.kilograms");
            } else if (option === "Litros") {
              return t("material.measure.litres");
            } else {
              return "";
            }
          }}
          isOptionEqualToValue={(option, value) => {
            if (value === "") return false;
            return option === value;
          }}
          disableClearable
          onChange={(event: SyntheticEvent<Element, Event>, value: string | null) => {
            autocompleteInputChange(field, value ?? "");
          }}
          renderInput={params => (
            <TextField
              {...params}
              name={field}
              label={t(VIEW_I18N + "form.label." + field)}
              className={"blue-border-input"}
              error={childrenErrors[field as keyof ChildrenFormErrors] === true}
              helperText={childrenErrors[field as keyof ChildrenFormErrors] ? t(VIEW_I18N_ERROR + field) : ""}
            />
          )}
        />
      </Grid>
    );
  };

  return (
    <Grid
      item
      xs={10}
      display="flex"
      justifyContent="flex-end"
      sx={{
        borderLeft: "8px solid transparent",
        ...styles.hierarchy_children_container
      }}>
      <Grid item xs={12}>
        <CardTitle value={t("hierarchy.creation.children.form.element")} iconName="hierarchies-item" />
      </Grid>

      <Grid item xs={12} paddingTop={"0.5rem"}>
        <Divider sx={{ mb: "1.5rem" }} />
      </Grid>

      <Grid container spacing={{ xs: 2, lg: 1 }} alignItems={"flex-start"}>
        {renderTextInput("materialCode", 3)}
        {renderTextInput("serialNumber", 3)}
        {renderTextInput("batch", 2)}
        {renderNumericInput("qty", 1)}
        {renderAutocomplete("uom", 2)}
        <Grid item xs={12} lg={1} display={"flex"} justifyContent="flex-end" style={{ paddingTop: "10px" }}>
          <Button id="save-children-button" color="secondary" onClick={() => saveChild()}>
            {t(VIEW_I18N + "addItem")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
