import Logo from "../../logo/logo";
import Icon from "../../icons/icon";
import List from "@mui/material/List";
import { ROUTER_PATHS } from "@constants";
import { useResolve } from "@movicoders/di";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import { toolbarStyles } from "./toolbarStyles";
import { usePermissions } from "@hooks/usePermissions";
import { PortalTypes } from "@domain/model/PortalTypes";
import { useHandlePortal } from "@hooks/useHandlePortal";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { PathsList } from "../drawer/components/paths-list";
import { Dispatch, SetStateAction, useEffect } from "react";
import { WarehouseList } from "../drawer/components/warehouse-list";
import { TUserRoleEnum, UserRoleEnum } from "@domain/interface/User";
import { FullUserDTOCompanyLicenseEnum } from "@clients/aggrego-proxy";
import { useDrawerViewModel } from "../drawer/hooks/useDrawerViewModel";
import { CollapsibleMenu } from "../drawer/components/collapsible-menu";
import { IUserService, UserService } from "@domain/services/IUserService";
import { Impersonation } from "../drawer/components/impersonation/Impersonation";
import { drawerCompanies, drawerPaths, drawerSettingPaths } from "../drawer/drawer-paths-configuration";
import { WarehouseByTenantUseCase } from "@application/warehouse-by-tenant/warehouse-by-tenant-use-case";
import { useImpersonationViewModel } from "../drawer/components/impersonation/useImpersonationViewModel";
import { Box, AppBar, Toolbar as MUIToolbar, IconButton, Grid, Theme, useMediaQuery } from "@mui/material";
import { UserInfo } from "../user-info/userInfo";
import { ReportEnum } from "@pages/settings/settings-interfaces";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";

export const Toolbar = (props: {
  isToolbarFirstLoading: boolean;
  setIsToolbarFirstLoading: Dispatch<SetStateAction<boolean>>;
}) => {
  const { isToolbarFirstLoading, setIsToolbarFirstLoading } = props;

  const styles = toolbarStyles;
  const navigate = useNavigate();
  // Check if the device is an iOS.
  const iOS = typeof navigator !== undefined && /iPad|iPhone|iPod/.test(navigator.userAgent);
  // Media query for the drawer.
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up(450), { noSsr: true });
  const {
    isDrawerOpen,
    handleDrawerOpen,
    masterdataSubmenuConf,
    reportsSubmenuConf,
    logisticSubmenuConf,
    trackAndTraceSubmenuConf,
    DRAWER_WIDTH,
    persistedState,
    isValidImpersonationUseCase
  } = useDrawerViewModel();

  const { isValidPermissions } = usePermissions();
  const { becomeImpersonated } = useImpersonationViewModel();
  const { isPortalAggrego, impersonationService } = useHandlePortal();

  const userService = useResolve<IUserService>(UserService);
  const settingsService = useResolve<ISettingsService>(SettingsService);

  const isRole = (role: TUserRoleEnum) => {
    return userService.user?.roles.includes(role);
  };
  const userCompanyLicense = (userService.user?.companyLicense as FullUserDTOCompanyLicenseEnum) ?? undefined;

  //UseCase to get warehouses by tenantID (by user tenant or impersonatedTenant)
  const warehousesByTenantUseCase = useResolve(WarehouseByTenantUseCase);

  useEffect(() => {
    if (
      isRole("SUPER_ADMIN") &&
      !impersonationService.persistedState?.isImpersonated &&
      impersonationService.persistedState?.impersonatedTenant
    ) {
      becomeImpersonated();
    }
  }, [impersonationService.persistedState?.impersonatedTenant]);

  useEffect(() => {
    impersonationService.persistedState?.portal === PortalTypes.ADMIN &&
      persistedState.warehouses.length === 0 &&
      isToolbarFirstLoading &&
      warehousesByTenantUseCase.get();
    setIsToolbarFirstLoading(false);
  }, [userService.user?.tenantId]);

  useEffect(() => {
    if (impersonationService.persistedState?.portal) {
      if (impersonationService.persistedState?.portal) {
        persistedState.warehouses.length === 0 &&
          impersonationService.persistedState?.isImpersonated &&
          warehousesByTenantUseCase.get();
      }
    }
  }, [impersonationService.persistedState?.isImpersonated]);

  const selectNavigationPath = () => {
    if (isRole("SUPER_ADMIN")) {
      if (!impersonationService.persistedState?.isImpersonated) {
        navigate(ROUTER_PATHS.adminBoard);
      } else {
        if (settingsService.persistedState?.reportsPermissions.includes(ReportEnum.WarehousesStatus)) {
          navigate(ROUTER_PATHS.whstatus);
        } else {
          navigate(`/${ROUTER_PATHS.home}/${persistedState.selectedWarehouse}`);
        }
      }
    } else {
      if (settingsService.persistedState?.reportsPermissions.includes(ReportEnum.WarehousesStatus)) {
        navigate(ROUTER_PATHS.whstatus);
      } else {
        navigate(`/${ROUTER_PATHS.home}/${persistedState.selectedWarehouse}`);
      }
    }
  };

  return (
    <Box component="div">
      <AppBar position="static" sx={styles.mobile_toolbar} elevation={0}>
        <MUIToolbar sx={{ flexDirection: "column", justifyContent: "center" }}>
          <IconButton size="large" aria-label="open drawer" onClick={handleDrawerOpen} sx={{ alignSelf: "flex-start" }}>
            <Icon icon="menu" />
          </IconButton>
          <Grid position="absolute" onClick={selectNavigationPath} sx={{ cursor: "pointer" }}>
            <Logo completeLogo={true} />
          </Grid>
          <Grid position="fixed" sx={{ alignSelf: "flex-end" }}>
            <UserInfo userService={userService} fullUserMode={false} />
          </Grid>
        </MUIToolbar>
      </AppBar>

      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={isDrawerOpen}
        onOpen={handleDrawerOpen}
        onClose={handleDrawerOpen}
        id="mobile-drawer"
        swipeAreaWidth={ROUTER_PATHS.warehouseViewer === window.location.pathname || isDrawerOpen ? 0 : 20}
        PaperProps={{
          sx: {
            width: matches ? "50vw" : "75vw",
            overflowY: isDrawerOpen ? "scroll" : "hidden",
            ...styles.swipeable_drawer_paper
          }
        }}
        slotProps={{ backdrop: { sx: { zIndex: 0 } } }}
        onClick={e => {
          handleDrawerOpen();
          e.stopPropagation();
        }}>
        <List aria-labelledby="mobile-drawer-options-top">
          {isPortalAggrego && isValidPermissions([UserRoleEnum.SuperAdmin]) ? (
            <>
              {/* Impersonation feature */}
              <Impersonation drawerWidth={DRAWER_WIDTH} isDrawerOpen={isDrawerOpen} />
            </>
          ) : null}
          {isPortalAggrego && isValidImpersonationUseCase.execute() ? (
            <>
              {/* WAREHOUSE LIST */}
              <WarehouseList isDrawerOpen={isDrawerOpen} drawerWidth={DRAWER_WIDTH} />
            </>
          ) : null}
          {/* COMPANY PAGE (ONLY IN PORTALAMDIN) */}
          {!isPortalAggrego && <PathsList drawerWidth={DRAWER_WIDTH} isDrawerOpen={isDrawerOpen} paths={drawerCompanies()} />}
          {isPortalAggrego && isValidImpersonationUseCase.execute() && <Divider />}

          {/* UPPER LIST */}
          {isPortalAggrego && (
            <Grid item xs={12} flexDirection="column">
              {/* Warehouse-Viewer */}
              {masterdataSubmenuConf.settingsArray.includes("warehouse-viewer") ? (
                <PathsList drawerWidth={DRAWER_WIDTH} isDrawerOpen={isDrawerOpen} paths={drawerPaths(userCompanyLicense)} />
              ) : (
                <></>
              )}
              {settingsService.persistedState && settingsService.persistedState?.masterdataPermissions.length > 0 && (
                <CollapsibleMenu {...masterdataSubmenuConf} />
              )}
              {settingsService.persistedState && settingsService.persistedState?.reportsPermissions.length > 0 && (
                <CollapsibleMenu {...reportsSubmenuConf} />
              )}
              {(isRole("SUPER_ADMIN") || isRole("PORTAL_ADMIN")) &&
                userCompanyLicense === "IM" &&
                settingsService.persistedState &&
                settingsService.persistedState?.logisticOperationsPermissions.length > 0 && (
                  <CollapsibleMenu {...logisticSubmenuConf} />
                )}
              {!trackAndTraceSubmenuConf.paths[0].hiddenByIM &&
                settingsService.persistedState &&
                settingsService.persistedState?.trackAndTracePermissions.length > 0 && (
                  <CollapsibleMenu {...trackAndTraceSubmenuConf} />
                )}
            </Grid>
          )}
        </List>

        <List aria-labelledby="mobile-drawer-options-bottom">
          <PathsList isDrawerOpen drawerWidth="100%" paths={drawerSettingPaths(userCompanyLicense, impersonationService)} />
        </List>
      </SwipeableDrawer>
    </Box>
  );
};
