import { ChangeEvent, useState } from "react";

export const useLoginCardViewModel = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [twoFactorCode, setTwoFactorCode] = useState("");

  const handleUsernameChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(event.target.value);
  };

  const handleTwoFactorCodeChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTwoFactorCode(event.target.value);
  };

  const resetFields = () => {
    setUsername("");
    setPassword("");
    setTwoFactorCode("");
  };

  return {
    username,
    password,
    twoFactorCode,
    handleUsernameChange,
    handlePasswordChange,
    handleTwoFactorCodeChange,
    resetFields
  };
};
