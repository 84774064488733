import Stakeholder from "@domain/model/Stakeholder";
import { useStakeholderSave } from "./useStakeholderSave";
import { stakeholderTypesEnum } from "../useStakeholderSelection";
import { useStakeholderPagination } from "./useStakeholderPagination";
import { useStakeholderHandleData } from "./useStakeholderHandleData";
import { useStakeholderActiveStatus } from "./useStakeholderActiveStatus";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { useResolve } from "@movicoders/di";

export const useStakeholderListViewModel = (stakeholderType: stakeholderTypesEnum, stakeholderTypeForFilters: string) => {
  const settingsService = useResolve<ISettingsService>(SettingsService);

  const {
    loading,
    loadingDetail,
    currentPagePersistedState,
    currentPageService,
    stakeholderData,
    selectedStakeholder,
    getStakeholderPaginated,
    goToCreate,
    goToDetail,
    goToList,
    save
  } = useStakeholderHandleData();

  const { handlePagination, sortByFieldBackend } = useStakeholderPagination(
    stakeholderData?.content as Stakeholder[],
    currentPageService,
    currentPagePersistedState,
    stakeholderTypeForFilters,
    getStakeholderPaginated
  );

  const {
    isActivating,
    selectedData,
    stakeholder4Activation,
    stakeholderDetailOpen,
    handleActiveConfirm,
    handleStakeholderOpen,
    handleStakeholderStatus,
    onCloseActive,
    onCloseStakeholderDialog
  } = useStakeholderActiveStatus(
    currentPagePersistedState,
    stakeholderType,
    stakeholderTypeForFilters,
    save,
    getStakeholderPaginated
  );

  const { handleEdit, handleSave } = useStakeholderSave(
    selectedStakeholder as Stakeholder,
    stakeholderType,
    stakeholderTypeForFilters,
    currentPagePersistedState,
    save,
    goToDetail,
    goToList
  );

  return {
    isActivating,
    stakeholder4Activation,
    stakeholderDetailOpen,
    stakeholderData,
    selectedStakeholder,
    loading,
    loadingDetail,
    selectedData,
    handleEdit,
    handleSave,
    handleActiveConfirm,
    handleStakeholderStatus,
    handleStakeholderOpen,
    handlePagination,
    sortByFieldBackend,
    goToCreate,
    goToList,
    onCloseActive,
    onCloseStakeholderDialog,
    settingsService
  };
};
