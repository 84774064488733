import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import { useTranslation } from "react-i18next";
import { InvPeriodDTO } from "@domain/model/InvPeriod";
import { generalReportStyles } from "../../reports-styles/generalReportStyles";
import { Dispatch, FormEvent, RefObject, SetStateAction, useEffect, useRef } from "react";
import { Grid, InputBase, InputLabel, MenuItem, Paper, Select, Typography, FormControl } from "@mui/material";

interface IInventoryReportSearchBar {
  showErrors: boolean;
  handleEnterReload: (event: FormEvent<HTMLFormElement>, searchInput: RefObject<HTMLElement>) => void;
  searchValue: string;
  setSelectPeriod: Dispatch<SetStateAction<InvPeriodDTO | undefined>>;
  clearSearchValue: () => void;
  setSearchValue: Dispatch<SetStateAction<string>>;
  selectPeriod: InvPeriodDTO;
  periods: InvPeriodDTO[];
  defaultPeriod: InvPeriodDTO;
}

export const InventoryReportSearchBar = (props: IInventoryReportSearchBar): JSX.Element => {
  const { t } = useTranslation();
  const styles = generalReportStyles();
  const {
    clearSearchValue,
    handleEnterReload,
    periods,
    searchValue,
    setSearchValue,
    setSelectPeriod,
    selectPeriod,
    defaultPeriod,
    showErrors
  } = props;

  const searchInput = useRef<HTMLElement>(null);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "12.5rem"
      }
    }
  };

  useEffect(() => {
    setSelectPeriod(defaultPeriod);
  }, [defaultPeriod]);

  const renderInput = (ref: RefObject<HTMLElement>) => {
    return (
      <>
        <Paper
          component="form"
          className="search-bar Mui-error"
          style={showErrors ? { borderColor: COLORS.alert } : {}}
          onSubmit={event => handleEnterReload(event, searchInput)}>
          <Icon className="search-icon" icon="search" size="1.6rem" color="inherit" />
          <InputBase
            id="inventory-report-search-bar"
            name="invreport"
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            sx={styles.reports_input_field}
            placeholder={t("search.placeholder")}
            inputProps={{ ref: ref, enterKeyHint: "search" }}
            endAdornment={
              searchValue ? (
                <Icon id="search-clear-button" icon="close" className="clear-icon" onClick={clearSearchValue} />
              ) : (
                <></>
              )
            }
          />
        </Paper>
        {/** ERROR TEXT */}
        {showErrors && <Typography sx={styles.reports_search_label}>{t("invregistry.search.empty")}</Typography>}
      </>
    );
  };

  return (
    <Grid
      item
      id="invrep-grid-container"
      display="inline-flex"
      xs={12}
      sm={12}
      flexDirection={{ md: "row" }}
      //alignItems="flex-end"
      width={"100%"}
      mt="2rem">
      <Grid item width="100%" mr="1rem">
        {renderInput(searchInput)}
      </Grid>
      <Grid item width="60%">
        {periods.length > 0 && selectPeriod && selectPeriod.id && (
          <FormControl>
            <InputLabel style={{ fontSize: "0.625rem", marginLeft: "0.5rem", color: COLORS.secondary }}>
              {t("invrep.date.select")}
            </InputLabel>
            <Select
              id="inventory-date-select"
              color="secondary"
              value={selectPeriod.id}
              className="table-select"
              sx={styles.reports_select_styles}
              MenuProps={MenuProps}
              onChange={e => {
                setSelectPeriod(periods.find(item => item.id === e.target.value));
              }}>
              {periods.length > 0 &&
                periods?.map((option: InvPeriodDTO) => {
                  return (
                    <MenuItem
                      id={`selected-option-${option.id}`}
                      value={option.id}
                      key={option.id}
                      sx={{ fontSize: "small", color: COLORS.grey }}>
                      {option.initDate?.toLocaleString("es-ES", {
                        localeMatcher: "lookup",
                        month: "numeric",
                        day: "numeric",
                        year: "numeric"
                      })}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        )}
      </Grid>
    </Grid>
  );
};
