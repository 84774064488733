/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CompanyDTO,
  CompanyFilterDTO,
  CompanyItemDTO,
  CompanyItemPageDTO,
  CompanyPageDTO,
  CompanyRequestDTO,
  FullCompanyItemDTO,
  MovicodersBadRequestException,
  MovicodersNotFoundException,
  MovicodersServiceException,
} from '../models';
import {
    CompanyDTOFromJSON,
    CompanyDTOToJSON,
    CompanyFilterDTOFromJSON,
    CompanyFilterDTOToJSON,
    CompanyItemDTOFromJSON,
    CompanyItemDTOToJSON,
    CompanyItemPageDTOFromJSON,
    CompanyItemPageDTOToJSON,
    CompanyPageDTOFromJSON,
    CompanyPageDTOToJSON,
    CompanyRequestDTOFromJSON,
    CompanyRequestDTOToJSON,
    FullCompanyItemDTOFromJSON,
    FullCompanyItemDTOToJSON,
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
} from '../models';

export interface DeleteCompaniesRequest {
    xTenantId?: string;
    requestBody?: Array<string>;
}

export interface DeleteCompanyRequest {
    id: string;
    xTenantId?: string;
}

export interface GetCompanyByIdRequest {
    id: string;
    fullDTO: boolean;
    xTenantId?: string;
}

export interface GetCompanyByIdsRequest {
    xTenantId?: string;
    requestBody?: Array<string>;
}

export interface GetCompanyByTenantRequest {
    tenant: string;
    xTenantId?: string;
}

export interface ListCompaniesRequest {
    xTenantId?: string;
}

export interface ListCompaniesPaginatedRequest {
    status: ListCompaniesPaginatedStatusEnum;
    limit: number;
    offset: number;
    xTenantId?: string;
    sortField?: ListCompaniesPaginatedSortFieldEnum;
    sortDirection?: ListCompaniesPaginatedSortDirectionEnum;
}

export interface ListCompanyItemPaginatedRequest {
    limit: number;
    offset: number;
    xTenantId?: string;
    sortField?: ListCompanyItemPaginatedSortFieldEnum;
    sortDirection?: ListCompanyItemPaginatedSortDirectionEnum;
    companyFilterDTO?: CompanyFilterDTO;
}

export interface RegisterCompanyRequest {
    xTenantId?: string;
    companyRequestDTO?: CompanyRequestDTO;
}

export interface UpdateCompanyAndTenantRequest {
    xTenantId?: string;
    fullCompanyItemDTO?: FullCompanyItemDTO;
}

/**
 * 
 */
export class CompaniesApi extends runtime.BaseAPI {

    /**
     * Returns the deleted Companies
     */
    async deleteCompaniesRaw(requestParameters: DeleteCompaniesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CompanyDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/companies/delete-many`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyDTOFromJSON));
    }

    /**
     * Returns the deleted Companies
     */
    async deleteCompanies(requestParameters: DeleteCompaniesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CompanyDTO>> {
        const response = await this.deleteCompaniesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes an existing company, deletes it, and returns the new object.
     * Delete an existing company by id.
     */
    async deleteCompanyRaw(requestParameters: DeleteCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDTOFromJSON(jsonValue));
    }

    /**
     * Takes an existing company, deletes it, and returns the new object.
     * Delete an existing company by id.
     */
    async deleteCompany(requestParameters: DeleteCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyDTO> {
        const response = await this.deleteCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns one company object by id.
     * Get a company object by id.
     */
    async getCompanyByIdRaw(requestParameters: GetCompanyByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCompanyById.');
        }

        if (requestParameters.fullDTO === null || requestParameters.fullDTO === undefined) {
            throw new runtime.RequiredError('fullDTO','Required parameter requestParameters.fullDTO was null or undefined when calling getCompanyById.');
        }

        const queryParameters: any = {};

        if (requestParameters.fullDTO !== undefined) {
            queryParameters['fullDTO'] = requestParameters.fullDTO;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyDTOFromJSON(jsonValue));
    }

    /**
     * Returns one company object by id.
     * Get a company object by id.
     */
    async getCompanyById(requestParameters: GetCompanyByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyDTO> {
        const response = await this.getCompanyByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns company objects by ids.
     * Get a company object by id.
     */
    async getCompanyByIdsRaw(requestParameters: GetCompanyByIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CompanyDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/companies/find-many-by-id`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyDTOFromJSON));
    }

    /**
     * Returns company objects by ids.
     * Get a company object by id.
     */
    async getCompanyByIds(requestParameters: GetCompanyByIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CompanyDTO>> {
        const response = await this.getCompanyByIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns one company object by tenant.
     * Get company details by tenant.
     */
    async getCompanyByTenantRaw(requestParameters: GetCompanyByTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullCompanyItemDTO>> {
        if (requestParameters.tenant === null || requestParameters.tenant === undefined) {
            throw new runtime.RequiredError('tenant','Required parameter requestParameters.tenant was null or undefined when calling getCompanyByTenant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/companies/{tenant}/details`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters.tenant))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FullCompanyItemDTOFromJSON(jsonValue));
    }

    /**
     * Returns one company object by tenant.
     * Get company details by tenant.
     */
    async getCompanyByTenant(requestParameters: GetCompanyByTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullCompanyItemDTO> {
        const response = await this.getCompanyByTenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * SUPER_ADMIN role is the only authorized role.
     * Returns the list of all registered Companies
     */
    async listCompaniesRaw(requestParameters: ListCompaniesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CompanyItemDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/companies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyItemDTOFromJSON));
    }

    /**
     * SUPER_ADMIN role is the only authorized role.
     * Returns the list of all registered Companies
     */
    async listCompanies(requestParameters: ListCompaniesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CompanyItemDTO>> {
        const response = await this.listCompaniesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
     * Returns the list of all registered Companies paginated
     */
    async listCompaniesPaginatedRaw(requestParameters: ListCompaniesPaginatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyPageDTO>> {
        if (requestParameters.status === null || requestParameters.status === undefined) {
            throw new runtime.RequiredError('status','Required parameter requestParameters.status was null or undefined when calling listCompaniesPaginated.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling listCompaniesPaginated.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling listCompaniesPaginated.');
        }

        const queryParameters: any = {};

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/companies/paginated`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyPageDTOFromJSON(jsonValue));
    }

    /**
     * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
     * Returns the list of all registered Companies paginated
     */
    async listCompaniesPaginated(requestParameters: ListCompaniesPaginatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyPageDTO> {
        const response = await this.listCompaniesPaginatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a CompanyItem page which CustomCompanyFilterDTO filter matches
     * Returns a CompanyItem page which CustomCompanyFilterDTO filter matches
     */
    async listCompanyItemPaginatedRaw(requestParameters: ListCompanyItemPaginatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyItemPageDTO>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling listCompanyItemPaginated.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling listCompanyItemPaginated.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/companies/all/paginated`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyFilterDTOToJSON(requestParameters.companyFilterDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyItemPageDTOFromJSON(jsonValue));
    }

    /**
     * Returns a CompanyItem page which CustomCompanyFilterDTO filter matches
     * Returns a CompanyItem page which CustomCompanyFilterDTO filter matches
     */
    async listCompanyItemPaginated(requestParameters: ListCompanyItemPaginatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyItemPageDTO> {
        const response = await this.listCompanyItemPaginatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * A register company request creates a tenant, an admin user and a company in masterdata
     */
    async registerCompanyRaw(requestParameters: RegisterCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyRequestDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/companies/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyRequestDTOToJSON(requestParameters.companyRequestDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyRequestDTOFromJSON(jsonValue));
    }

    /**
     * A register company request creates a tenant, an admin user and a company in masterdata
     */
    async registerCompany(requestParameters: RegisterCompanyRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyRequestDTO> {
        const response = await this.registerCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes an existing Company object, updates it and data associated with the tenant object, and returns the Company.
     * Update an existing Company and data associated with the tenant object.
     */
    async updateCompanyAndTenantRaw(requestParameters: UpdateCompanyAndTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullCompanyItemDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/companies/edit`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FullCompanyItemDTOToJSON(requestParameters.fullCompanyItemDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FullCompanyItemDTOFromJSON(jsonValue));
    }

    /**
     * Takes an existing Company object, updates it and data associated with the tenant object, and returns the Company.
     * Update an existing Company and data associated with the tenant object.
     */
    async updateCompanyAndTenant(requestParameters: UpdateCompanyAndTenantRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullCompanyItemDTO> {
        const response = await this.updateCompanyAndTenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ListCompaniesPaginatedStatusEnum = {
    Active: 'ACTIVE',
    All: 'ALL',
    Inactive: 'INACTIVE'
} as const;
export type ListCompaniesPaginatedStatusEnum = typeof ListCompaniesPaginatedStatusEnum[keyof typeof ListCompaniesPaginatedStatusEnum];
/**
 * @export
 */
export const ListCompaniesPaginatedSortFieldEnum = {
    Active: 'ACTIVE',
    Address: 'ADDRESS',
    Fiscalid: 'FISCALID',
    Name: 'NAME'
} as const;
export type ListCompaniesPaginatedSortFieldEnum = typeof ListCompaniesPaginatedSortFieldEnum[keyof typeof ListCompaniesPaginatedSortFieldEnum];
/**
 * @export
 */
export const ListCompaniesPaginatedSortDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type ListCompaniesPaginatedSortDirectionEnum = typeof ListCompaniesPaginatedSortDirectionEnum[keyof typeof ListCompaniesPaginatedSortDirectionEnum];
/**
 * @export
 */
export const ListCompanyItemPaginatedSortFieldEnum = {
    Active: 'ACTIVE',
    Address: 'ADDRESS',
    Fiscalid: 'FISCALID',
    Name: 'NAME'
} as const;
export type ListCompanyItemPaginatedSortFieldEnum = typeof ListCompanyItemPaginatedSortFieldEnum[keyof typeof ListCompanyItemPaginatedSortFieldEnum];
/**
 * @export
 */
export const ListCompanyItemPaginatedSortDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type ListCompanyItemPaginatedSortDirectionEnum = typeof ListCompanyItemPaginatedSortDirectionEnum[keyof typeof ListCompanyItemPaginatedSortDirectionEnum];
