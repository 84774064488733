import { InvPeriodDTO } from "@domain/model/InvPeriod";
import { LastPageState } from "@domain/model/LastPageState";
import { FormEvent, RefObject, useEffect, useState } from "react";
import { GetInventoryReportPaginatedRequest } from "@clients/aggrego-proxy";
import { useHandleSelectedWarehouse } from "@hooks/useHandleSelectedWarehouse";
import { convertDateStringToFirstMillisecond, convertDateStringToLastMillisecond } from "@utils/date-helper";

type DateFilter = {
  created: string[];
  searchText: string;
};

export const useInventoryReportPagination = (
  selectedPeriod: InvPeriodDTO | undefined,
  currentPagePersistedState: LastPageState,
  getInvReportPaginated: (criteria: GetInventoryReportPaginatedRequest) => Promise<void>
) => {
  const [searchValue, setSearchValue] = useState("");

  const [showErrors, setShowErrors] = useState(false);
  const { selectedWarehouseCode } = useHandleSelectedWarehouse();

  useEffect(() => {
    search(currentPagePersistedState.limit, currentPagePersistedState.offset, currentPagePersistedState.filters);
  }, [searchValue]);

  const search = (limit: number, offset: number, filters?: object | undefined) => {
    let dateFilter = undefined;
    if (currentPagePersistedState.filters && (currentPagePersistedState.filters as DateFilter).created) {
      dateFilter = {
        initDate: convertDateStringToFirstMillisecond((currentPagePersistedState.filters as DateFilter).created[0]),
        endDate: convertDateStringToLastMillisecond((currentPagePersistedState.filters as DateFilter).created[1]),
        searchText: searchValue
      };
    }

    selectedPeriod?.id &&
      getInvReportPaginated({
        limit,
        offset,
        inventoryPeriodId: selectedPeriod.id,
        warehouseCode: selectedWarehouseCode,
        customFilterDTO: dateFilter ?? { ...filters, searchText: searchValue }
      } as GetInventoryReportPaginatedRequest);
  };

  const clearSearchValue = () => {
    setSearchValue("");
    search(currentPagePersistedState.limit, currentPagePersistedState.offset, currentPagePersistedState.filters);
  };

  function handleEnterReload(event: FormEvent<HTMLFormElement>, searchInput: RefObject<HTMLElement>): void {
    event.preventDefault();
    searchInput.current?.blur();
    setShowErrors(searchValue === "");
  }

  return {
    searchValue,
    showErrors,
    setSearchValue,
    search,
    clearSearchValue,
    handleEnterReload
  };
};
