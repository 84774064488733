import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import Material from "@domain/model/Material";
import { useTranslation } from "react-i18next";
import { movementsStyles } from "./movements-styles";
import { GridValidRowModel } from "@mui/x-data-grid";
import { movementsColumns } from "./movements-report-columns";
import { useMediaQuery, Grid, Divider, Theme } from "@mui/material";
import { MovementEventDTOActionEnum } from "@clients/aggrego-proxy";
import { OutletContainer } from "@components/outlet-container/outlet-container";
import { useMovementsViewModel } from "./hooks/viewmodel/useMovementsViewModel";
import { DataGridDisplay } from "@components/data-grid-display/data-grid-display";
import { LoadingSpinnerMasterView } from "@components/loading-circular-progress/loading-spinner-master-view";
import { ReportEnum } from "@pages/settings/settings-interfaces";

export const MovementsReportView = () => {
  const { t } = useTranslation();
  const matchesSM = useMediaQuery((theme: Theme) => theme.breakpoints.up(500), { noSsr: true });
  const { columnsByMode, data, search, loading, searchBarFilter, families, filteredMaterialPaginatedData, settingsService } =
    useMovementsViewModel();
  const styles = movementsStyles;

  return (
    <>
      <OutletContainer>
        <Grid container id="first-grid-container" direction="column" mb={1}>
          <Grid item>
            <Grid container wrap="nowrap" spacing={{ xs: "1rem" }} id="second-grid-container">
              <Grid item alignSelf="center">
                <Icon icon="location" color={COLORS.primary} />
              </Grid>
              <Grid item sx={{ color: COLORS.secondary, fontSize: "x-large", fontWeight: 600 }} id="report-title">
                {t("movements-title")}
              </Grid>
            </Grid>
            <Divider sx={styles.title_divider} />
          </Grid>

          {settingsService.persistedState?.reportsPermissions.includes(ReportEnum.MovementsReport) && (
            <DataGridDisplay
              data={(data.content as GridValidRowModel[]) ?? []}
              columns={movementsColumns(t, matchesSM)}
              mode="server"
              viewName={"movements"}
              rowId={(row: GridValidRowModel) => row["id"]}
              handleBackendPagination={(
                limit: number,
                offset: number,
                status?: "ALL" | "ACTIVE" | "INACTIVE" | undefined,
                filters?: object | undefined
              ) => {
                search(limit, offset, filters);
              }}
              hideColumnsByField={columnsByMode}
              backendTotalElements={data.totalElements ?? 0}
              toolbarProps={{
                multipleSelectOptions: new Map<string, string[]>([
                  [
                    "action",
                    [
                      MovementEventDTOActionEnum.Consumption,
                      MovementEventDTOActionEnum.Entrance,
                      MovementEventDTOActionEnum.Exit,
                      MovementEventDTOActionEnum.Movement,
                      MovementEventDTOActionEnum.InventoryInbound,
                      MovementEventDTOActionEnum.InventoryOutbound,
                      MovementEventDTOActionEnum.InventoryActivation,
                      MovementEventDTOActionEnum.InventoryDeactivation,
                      MovementEventDTOActionEnum.Reactivation,
                      MovementEventDTOActionEnum.Regularization
                    ]
                  ],
                  ["family", families.sort((a, b) => t(a?.name).localeCompare(t(b?.name))).map(family => family.name) ?? []],
                  [
                    "material",
                    (filteredMaterialPaginatedData as unknown as Material[])?.map((value: Material) => value.code) ?? []
                  ],
                  [
                    "description",
                    (filteredMaterialPaginatedData as unknown as Material[])?.map((value: Material) => value.name) ?? []
                  ]
                ]),
                disableColumnsVisibilityByField: [...columnsByMode, "actions"],
                disableFilterByFields: [...columnsByMode, "actions"],
                disableFieldsInDownload: [...columnsByMode, "actions"],
                isActiveFilterHidden: true
              }}
              searchBarFilter={searchBarFilter}
            />
          )}
        </Grid>
      </OutletContainer>
      <LoadingSpinnerMasterView loading={loading} />
    </>
  );
};
