import { ChangeEvent, SyntheticEvent } from "react";
import { Autocomplete, Divider, Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Product } from "@domain/model/Product";
import { CardTitle } from "../components/CardTitle";
import { FormTextInput } from "../components/FormTextInput";
import { ParentFormErrors } from "./parent-form-validations";
import { useParentFormViewmodel } from "./useParentFormViewmodel";
import { hierarchyCreationStyles } from "../hierarchy-creation-styles";
import { DateTimePicker } from "@mui/x-date-pickers";

const VIEW_I18N = "hierarchy.creation.parent.";
const VIEW_I18N_ERROR = VIEW_I18N + "error.message.";

export interface IParentFormProps {
  onParentChange: (product: Product) => void;
  errors: ParentFormErrors;
  isClearingView: boolean;
  hasVersionLabel: boolean;
}

export const ParentForm = ({ onParentChange, errors, isClearingView, hasVersionLabel }: IParentFormProps) => {
  const styles = hierarchyCreationStyles();
  const { t } = useTranslation();
  const { product, updateProduct } = useParentFormViewmodel(onParentChange, isClearingView);

  const inputTextChanged = (field: keyof Product) => (e: ChangeEvent<HTMLInputElement>) => updateProduct(field, e.target.value);

  const inputDateChanged = (value: unknown, field: keyof Product) => updateProduct(field, value as Date);

  const numericInputChanged = (field: keyof Product) => (e: ChangeEvent<HTMLInputElement>) => {
    const filteredValue = e.target.value.replace(/[^0-9.]+/g, "");
    const numericValue = parseFloat(filteredValue);
    const maxValue = 1000000000;

    // If the user tries to input a point after another point the value is not changed.
    if (!filteredValue.endsWith("..")) {
      // When the value exceeds maxValue it stays at said maxValue.
      if (numericValue > maxValue) updateProduct(field, maxValue);
      // When the value is NaN (possibly "") the value turns into 0.
      else if (isNaN(numericValue)) updateProduct(field, 0);
      // When the last char is '.', the value is saved as an string to keep that '.'.
      else if (filteredValue.endsWith(".") && filteredValue.indexOf(".") === filteredValue.lastIndexOf("."))
        updateProduct(field, filteredValue);
      else {
        // The value is rounded to 3 decimals if needed.
        const roundedValue = RegExp(/\d+\.\d{3}/).exec(filteredValue)?.[0];
        // When the last char is '0', the value is saved as an string to keep that number.
        if (filteredValue.indexOf(".") !== -1 && roundedValue === undefined && filteredValue.endsWith("0"))
          updateProduct(field, filteredValue);
        else updateProduct(field, roundedValue ?? numericValue);
      }
    }
  };

  const autocompleteInputChange = (field: keyof Product, value: string) => {
    updateProduct(field, value);
  };

  const selectFieldLabel = (field: keyof Product) => {
    if (field !== "batch" || (field === "batch" && !hasVersionLabel)) {
      return t(VIEW_I18N + "form.label." + field);
    } else {
      return t(VIEW_I18N + "form.label.version");
    }
  };

  const selectErrorFieldMessage = (field: keyof Product) => {
    if (field !== "batch" || (field === "batch" && !hasVersionLabel)) {
      return t(VIEW_I18N_ERROR + field);
    } else {
      return t(VIEW_I18N_ERROR + "version");
    }
  };

  const renderTextInput = (field: keyof Product, width: number) => (
    <Grid item xs={12} md={6} lg={width} sx={{ mt: errors.hasErrors() ? 1 : 0.5 }}>
      <FormTextInput
        errorText={errors[field as keyof ParentFormErrors] ? selectErrorFieldMessage(field) : ""}
        id={"input_" + field}
        name={field}
        onChange={inputTextChanged(field)}
        value={product?.[field] as string}
        key={field}
        label={selectFieldLabel(field)}
      />
    </Grid>
  );

  const renderNumericInput = (field: keyof Product, width: number) => (
    <Grid item xs={12} md={6} lg={width} sx={{ mt: errors.hasErrors() ? 1 : 0.5 }}>
      <FormTextInput
        errorText={errors[field as keyof ParentFormErrors] ? t(VIEW_I18N_ERROR + field) : ""}
        id={"input_" + field}
        name={field}
        onChange={numericInputChanged(field)}
        value={product?.[field] as string}
        key={field}
        label={t(VIEW_I18N + "form.label." + field)}
      />
    </Grid>
  );

  const renderDateInput = (field: keyof Product, width: number) => {
    const error = Boolean(errors[field as keyof ParentFormErrors]);
    const errorText = error ? t(VIEW_I18N_ERROR + field + errors.createdAtError) : "";
    const label = t(`${VIEW_I18N}form.label.${field}`);

    return (
      <Grid item xs={12} md={6} lg={width} sx={{ mt: errors.hasErrors() ? 1 : 0.5 }}>
        <DateTimePicker
          value={product?.[field] ?? null}
          onChange={value => inputDateChanged(value, field)}
          inputFormat="dd/MM/yyyy HH:mm"
          ampm={false}
          disableFuture
          label={label}
          renderInput={props => <TextField {...props} id={"input_" + field} helperText={errorText} error={error} label={label} />}
          OpenPickerButtonProps={{
            id: "creation-date-open-date-picker"
          }}
        />
      </Grid>
    );
  };

  const renderAutocomplete = (field: keyof Product, width: number) => {
    return (
      <Grid item xs={12} md={6} lg={width} sx={{ mt: errors.hasErrors() ? 1 : 0.5 }}>
        <Autocomplete
          id={"input_" + field}
          value={product.uom}
          options={["Unidades", "Kilogramos", "Litros"]}
          getOptionLabel={(option: string) => {
            if (option === "Unidades") {
              return t("material.measure.units");
            } else if (option === "Kilogramos") {
              return t("material.measure.kilograms");
            } else if (option === "Litros") {
              return t("material.measure.litres");
            } else {
              return "";
            }
          }}
          isOptionEqualToValue={(option, value) => {
            if (value === "") return false;
            return option === value;
          }}
          disableClearable
          onChange={(event: SyntheticEvent<Element, Event>, value: string | null) => {
            autocompleteInputChange(field, value ?? "");
          }}
          renderInput={params => (
            <TextField
              {...params}
              name={field}
              label={t(VIEW_I18N + "form.label." + field)}
              className={"blue-border-input"}
              error={errors[field as keyof ParentFormErrors] === true ? true : false}
              helperText={errors[field as keyof ParentFormErrors] ? t(VIEW_I18N_ERROR + field) : ""}
            />
          )}
        />
      </Grid>
    );
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        borderLeft: "8px solid transparent",
        ...styles.hierarchy_parent_product
      }}>
      <Grid container>
        <Grid item xs={12}>
          <CardTitle value={t("products.product")} iconName="hierarchies-product" />
        </Grid>

        <Grid item xs={12} paddingTop={"0.5rem"}>
          <Divider sx={{ mb: "1.5rem" }} />
        </Grid>

        <Grid container item spacing={1}>
          {renderTextInput("materialCode", 4)}
          {renderTextInput("batch", 4)}
          {renderNumericInput("qty", 2)}
          {renderAutocomplete("uom", 2)}
        </Grid>

        <Grid container item spacing={1} sx={{ mt: errors.hasErrors() ? 1 : 0.5 }}>
          {renderTextInput("serialNumber", 4)}
          {renderTextInput("manufactureOrder", 4)}
          {renderTextInput("createdAt", 2)}
          {renderDateInput("manufacturingDate", 2)}
        </Grid>
      </Grid>
    </Grid>
  );
};
