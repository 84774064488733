// Disabled because elements from ThreeJS that start with lowercase
// are not allowed by eslint no-unknown-property rule.
/* eslint-disable react/no-unknown-property */
import { MeshProps } from "@react-three/fiber";
import { Warehouse3D } from "../types/Warehouse3D";
import * as THREE from "three";

// The geometry and material to be used by the columns.
const defaultColumnGeometry = new THREE.BoxGeometry(0.2, 1, 0.2);
const defaultColumnMaterial = new THREE.MeshBasicMaterial({ color: "#323232" });
const columnProps: MeshProps = { geometry: defaultColumnGeometry, material: defaultColumnMaterial };

// Function that returns "Columns" when representing the tiles.
export const isWall = (x: number, y: number, level: number, warehouse: Warehouse3D) => {

  const currentMaxY = warehouse.tiles?.find(t => t.x === x && t.z === y && t.locations?.find(b => b.active === true) && t.active === true)?.maxY ?? 0;

  const tileXPos = warehouse.tiles?.find(
    t => t.x === x + 1 && t.z === y && t.active === true && currentMaxY === t.maxY && t.locations?.find(b => b.active === true)
  );
  const tileXNeg = warehouse.tiles?.find(
    t => t.x === x - 1 && t.z === y && t.active === true && currentMaxY === t.maxY && t.locations?.find(b => b.active === true)
  );
  const tileYPos = warehouse.tiles?.find(
    t => t.x === x && t.z === y + 1 && t.active === true && currentMaxY === t.maxY && t.locations?.find(b => b.active === true)
  );
  const tileYNeg = warehouse.tiles?.find(
    t => t.x === x && t.z === y - 1 && t.active === true && currentMaxY === t.maxY && t.locations?.find(b => b.active === true)
  );

  switch (true) {
    //Right Side
    case tileXPos !== undefined && !tileXNeg && !tileYNeg && !tileYPos:
      return (
        <>
          <mesh position={[-0.5, level, 0.5]} {...columnProps} />
          <mesh position={[-0.5, level, -0.5]} {...columnProps} />
        </>
      );

    //Left Side
    case !tileXPos && tileXNeg !== undefined && !tileYNeg && !tileYPos:
      return (
        <>
          <mesh position={[0.5, level, 0.5]} {...columnProps} />
          <mesh position={[0.5, level, -0.5]} {...columnProps} />
        </>
      );

    //Up Side
    case !tileXPos && !tileXNeg && tileYNeg !== undefined && !tileYPos:
      return (
        <>
          <mesh position={[0.5, level, 0.5]} {...columnProps} />
          <mesh position={[-0.5, level, 0.5]} {...columnProps} />
        </>
      );

    //Down Side
    case !tileXPos && !tileXNeg && !tileYNeg && tileYPos !== undefined:
      return (
        <>
          <mesh position={[0.5, level, -0.5]} {...columnProps} />
          <mesh position={[-0.5, level, -0.5]} {...columnProps} />
        </>
      );

    //Upper left Side
    case !tileXPos && tileXNeg && !tileYPos:
      return <mesh position={[0.5, level, 0.5]} {...columnProps} />;

    //Upper right Side
    case tileXPos && !tileXNeg && !tileYPos:
      return <mesh position={[-0.5, level, 0.5]} {...columnProps} />;

    //Down right Side
    case tileXPos && !tileXNeg && !tileYNeg:
      return <mesh position={[-0.5, level, -0.5]} {...columnProps} />;

    //Down left Side
    case !tileXPos && tileXNeg && !tileYNeg:
      return <mesh position={[0.5, level, -0.5]} {...columnProps} />;

    // All Sides
    case !tileXPos && !tileXNeg && !tileYNeg && !tileYPos:
      return (
        <>
          <mesh position={[0.5, level, 0.5]} {...columnProps} />
          <mesh position={[-0.5, level, 0.5]} {...columnProps} />
          <mesh position={[-0.5, level, -0.5]} {...columnProps} />
          <mesh position={[0.5, level, -0.5]} {...columnProps} />
        </>
      );

    default:
      return null;
  }
};
