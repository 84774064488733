import { SetStateAction, useEffect } from "react";
import { useApplyFilter } from "./useApplyFilters";
import { GridValidRowModel } from "@mui/x-data-grid";
import { useVisibilityColumns } from "./useVisibilityColumns";
import { useHandleDownloadMenu } from "./useHandleDownloadMenu";
import { DataGridDisplayBasicToolbarProps } from "../toolbar/config/data-grid-display-basic-toolbar-props";

export const useHandleToolbarFilters = (
  toolbarProps: DataGridDisplayBasicToolbarProps,
  initialData: GridValidRowModel[],
  currentViewName: string,
  currentData: GridValidRowModel[],
  setCurrentData: (value: SetStateAction<GridValidRowModel[]>) => void,
  unsortedData: GridValidRowModel[],
  setUnsortedData: (value: SetStateAction<GridValidRowModel[]>) => void
) => {
  const {
    currentFieldFilter,
    setCurrentFieldFilter,
    setSearchBarValue,
    selectedStatus,
    setSelectedStatus,
    customSearchBarFilterProps
  } = useApplyFilter(toolbarProps, initialData, setCurrentData, setUnsortedData);

  const { getPDFData, handleDownloadCSV, handlePrintScreen, isFieldVisible } = useHandleDownloadMenu(
    currentViewName,
    currentData
  );

  const { onChangeColumnVisibility } = useVisibilityColumns(currentViewName);

  useEffect(() => {
    setCurrentData(initialData);
    setUnsortedData(initialData);
  }, [initialData]);

  return {
    currentData,
    unsortedData,
    selectedStatus,
    getPDFData,
    handleDownloadCSV,
    handlePrintScreen,
    currentFieldFilter,
    setSelectedStatus,
    setSearchBarValue,
    isFieldVisible,
    setCurrentFieldFilter,
    onChangeColumnVisibility,
    customSearchBarFilterProps
  };
};
