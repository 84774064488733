import Tile from "@domain/model/Tile";
import Zone from "@domain/model/Zone";
import { useTranslation } from "react-i18next";
import { CREATE_ID, TIMEOUT_ID } from "@constants";
import LocationEntity from "@domain/model/Location";
import { UUIDGenerator } from "@utils/uuid-generator";
import { ChangeEvent, useEffect, useState, Fragment } from "react";
import { useLocationForm } from "./hooks/useLocationForm";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";
import { ButtonsDetail } from "@components/master-data-view/buttons/buttons-detail";
import { LoadingSpinnerDetailView } from "@components/loading-circular-progress/loading-spinner-detail-view";

interface IDetail {
  location: LocationEntity;
  tiles: Tile[];
  zones: Zone[];
  loading: boolean;
  onSave: (location: LocationEntity, tile: Tile) => Promise<void>;
  onClose: () => void;
}

export const LocationsDetailView = (props: IDetail) => {
  const { t } = useTranslation();
  const { location: initialLocation, tiles, zones, loading, onSave, onClose } = props;
  const {
    location,
    tile,
    onChange,
    onChangeNumeric,
    warehouseSizes,
    isNotValid,
    isValidData,
    showErrors,
    changeZone,
    locationCapacityError
  } = useLocationForm({
    selectedLocation: initialLocation,
    currentTiles: tiles,
    onSave: onSave
  });

  const zoneNameArray: string[] = zones?.flatMap((content: Zone) => content.name) ?? [];
  const disabled = location.active !== undefined && location.active === false;

  const [selectedZoneInLocation, setSelectedZoneInLocation] = useState(tile.zoneId ?? "");
  const [pendingRequest, setPendingRequest] = useState<boolean>(false);

  const findZoneIDbyName = (zoneName: string) => {
    const zoneFound: Zone | undefined = zones?.find((contentZone: Zone) => {
      return contentZone.name === zoneName;
    });
    return zoneFound?.id;
  };
  const findZoneNameByID = (id: string) => {
    const zoneNameFound: Zone | undefined = zones?.find((contentZone: Zone) => {
      return contentZone.id === id;
    });
    return zoneNameFound?.name ?? "";
  };

  useEffect(() => {
    setPendingRequest(false);
  }, [initialLocation]);

  useEffect(() => {
    if (tile && tile.id === undefined) {
      tile.id = UUIDGenerator();
    }
    setSelectedZoneInLocation(tile.zoneId ?? "");
  }, [tile]);

  const renderInput = (
    id: string,
    name: string,
    value: string | number,
    label: string,
    modifyingTile: boolean,
    xs: number,
    type?: string,
    locationCapacityError?: boolean
  ) => {
    let size = "";
    switch (type) {
      case "positionx":
        size = String(warehouseSizes.x);
        break;
      case "positionz":
        size = String(warehouseSizes.z);
        break;
      case "positiony":
        size = String(warehouseSizes.y);
        break;
    }

    const error = isNotValid(value, type, Number(size)) && showErrors;

    const showErrorMessage = () => {
      if (locationCapacityError) {
        return t("locations.dialog.capacity.less.that.container.by", {
          currentContainersQuantity: initialLocation.containers?.length
        });
      } else if (error) {
        return t(
          `locations.dialog.empty.field.${type?.includes("position") ? type.substring(0, type.length - 1) : type ?? "text"}`,
          {
            size: ` (${size})`
          }
        );
      }
      return "";
    };

    const isNumeric = name === "capacity" || type?.includes("position");

    return (
      <Grid item xs={xs}>
        <TextField
          error={error || locationCapacityError}
          helperText={showErrorMessage()}
          id={id}
          value={isNumeric && value === "" ? "0" : value}
          name={name}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            isNumeric ? onChangeNumeric(event, modifyingTile) : onChange(event)
          }
          label={label}
          disabled={disabled}
          className={disabled ? "disabled-input" : "blue-border-input"}
        />
      </Grid>
    );
  };

  return (
    <Fragment>
      {initialLocation.id !== undefined && initialLocation.id !== TIMEOUT_ID ? (
        <AggregoDialog
          title={
            initialLocation.id === CREATE_ID ? t("locations.detail.title") : t("locations.edit.title") + initialLocation.code
          }
          onClose={onClose}>
          <Grid
            container
            className="masterdata-box"
            justifyContent="space-between"
            rowSpacing={2}
            columnSpacing={1}
            sx={{ ml: -0.5 }}>
            {renderInput("location-code", "code", location.code ?? "", t("locations.code"), false, 6)}
            {renderInput(
              "location-capacity",
              "capacity",
              location.capacity ?? "",
              t("locations.capacity"),
              false,
              6,
              "number",
              locationCapacityError
            )}
            {renderInput("location-coord-x", "x", tile?.x ?? "", t("locations.coord.x"), true, 4, "positionx")}
            {renderInput("location-coord-z", "z", tile?.z ?? "", t("locations.coord.z"), true, 4, "positionz")}
            {renderInput("location-level", "level", location.level ?? "", t("locations.level"), false, 4, "positiony")}
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                id="location-autocomplete-zones"
                loadingText={t("locations.zone")}
                options={zoneNameArray ?? []}
                title={t("locations.zone")}
                disabled={disabled}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t("locations.zone")}
                    className={disabled ? "disabled-input" : "blue-border-input"}
                    InputProps={{
                      ...params.InputProps
                    }}
                  />
                )}
                componentsProps={{ popper: { onClick: e => e.stopPropagation(), id: "zones-popper" } }}
                value={findZoneNameByID(selectedZoneInLocation)}
                getOptionLabel={e => e}
                onChange={(e, value) => {
                  setSelectedZoneInLocation(value ?? "");
                  changeZone(findZoneIDbyName(value ?? "") ?? "");
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonsDetail
                handleClose={onClose}
                handleSave={() => {
                  if (isValidData()) {
                    setPendingRequest(true);
                    onSave(location, tile);
                  }
                }}
                mode={location.id === CREATE_ID ? "CREATE" : "EDIT"}
                disabled={disabled}
                pendingRequest={pendingRequest}
              />
            </Grid>
          </Grid>
        </AggregoDialog>
      ) : (
        <LoadingSpinnerDetailView loading={loading} selectedId={initialLocation.id ?? ""} />
      )}
    </Fragment>
  );
};
