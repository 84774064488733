import {
  IconButton,
  Paper,
  Grid,
  Typography,
  ListItem,
  ListItemText,
  Divider,
  DialogActions,
  Button,
  Box
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../../../../components/icons/icon";
import { TILE_TEMPLATE_TYPES_VALUES, TileTemplateTypes } from "../../../../../domain/model/TileTemplate";
import { IEditionStatesStore } from "../../stores/edition-states-store/IEditionStatesStore";
import { useEditionStatesStore } from "../../stores/edition-states-store/edition-states-store";
import { useResolve } from "@movicoders/di";
import { CAPACITY_COLORS } from "../../constants/Colors";
import { COLORS } from "../../../../../theme";

const getColorFromTemplate = (template: TileTemplateTypes) => {
  switch (template) {
    case "CORRIDOR":
      return CAPACITY_COLORS.lightyellow;
    case "STORAGE":
      return CAPACITY_COLORS.green;
    case "WALL":
      return CAPACITY_COLORS.red;
  }
};

export const InfoDialog = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { getTemplate } = useResolve<IEditionStatesStore>(useEditionStatesStore);

  const handleClickHelp = () => setOpen(!open);

  const handleCloseDialog = () => setOpen(false);

  const INFO_KEYS = [
    {
      primaryElement: t("viewer.info.space"),
      translationKey: "space"
    },
    {
      primaryElement: <>&rarr;</>,
      translationKey: "arrow.right"
    },
    {
      primaryElement: <>&larr;</>,
      translationKey: "arrow.left"
    },
    {
      primaryElement: <>&uarr;</>,
      translationKey: "arrow.up"
    },
    {
      primaryElement: <>&darr;</>,
      translationKey: "arrow.down"
    }
  ];

  return (
    <Grid>
      <IconButton
        onClick={handleClickHelp}
        color="primary"
        aria-label="help"
        sx={{ color: COLORS.secondary }}
      >
        <Icon icon="info" />
      </IconButton>
      <Paper
        sx={{
          height: "27rem",
          width: "30rem",
          position: "absolute",
          p: "1rem",
          display: open ? "block" : "none",
          marginLeft: "3.8rem",
          top: "1rem",
          zIndex: 2
        }}>
        <Grid container flexWrap="nowrap" justifyContent="space-between">
          <Grid item xs={5}>
            <Typography variant="h6">{t("tile.templates")}</Typography>
            {TILE_TEMPLATE_TYPES_VALUES.map(tileTemplate => (
              <ListItem key={tileTemplate} disablePadding>
                <Icon icon={tileTemplate === "WALL" ? "close" : "chevron-right"} color={getColorFromTemplate(tileTemplate)} />
                <ListItemText
                  disableTypography
                  primary={<Box component="div">{t(tileTemplate)}</Box>}
                  secondary={
                    <Box component="div" fontSize="small">
                      {t("viewer.info.walkable") +
                        (getTemplate(tileTemplate)?.walkable
                          ? t("viewer.info.yes")
                          : t("viewer.info.no")) +
                        " | " +
                        t("viewer.info.editable") +
                        (getTemplate(tileTemplate)?.editable ? t("viewer.info.yes") : t("viewer.info.no"))}
                    </Box>
                  }
                />
              </ListItem>
            ))}
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs={6}>
            <Typography variant="h6">{t("viewer.info.keys")}</Typography>
            {INFO_KEYS.map(({ primaryElement, translationKey }) => (
              <ListItem disablePadding key={translationKey}>
                <ListItemText
                  disableTypography
                  primary={primaryElement}
                  secondary={
                    <Box component="div" fontSize="small">
                      {t(`viewer.info.${translationKey}.description`)}
                    </Box>
                  }
                />
              </ListItem>
            ))}
          </Grid>
        </Grid>
        <DialogActions sx={{ mt: "0.5rem" }}>
          <Button onClick={handleCloseDialog}>{t("viewer.details.close")}</Button>
        </DialogActions>
      </Paper>
    </Grid>
  );
};
