import { COLORS } from "@theme";
import { SxProps, Theme } from "@mui/material";

export const generalReportStyles = () => {
  const reports_select_styles: SxProps<Theme> = {
    fontSize: "small",
    justifyContent: "space-between",
    mr: 1,
    minWidth: { md: "23%", xs: "100%" },
    borderWidth: "1px",
    color: COLORS.gray,
    backgroundColor: "white",
    height: "2.5rem",
    fieldset: {
      borderColor: COLORS.gray
    },
    "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px"
    },
    "&:hover": {
      "&& fieldset": {
        borderColor: COLORS.gray
      }
    },
    "&.Mui-focused": {
      "&& fieldset": {
        borderColor: COLORS.gray
      }
    }
  };

  const reports_paper_field: SxProps<Theme> = {
    mb: 1,
    mr: 1,
    width: "90%"
  };

  const reports_input_field: SxProps<Theme> = {
    fontSize: "small",
    justifyContent: "space-between",
    width: "90%",
    paddingTop: "5.5px",
    color: COLORS.darkgray,
    marginLeft: "0.7rem"
  };

  const reports_icon: SxProps<Theme> = {
    background: COLORS.secondary,
    borderRadius: "4px",
    height: "fit-content",
    marginLeft: "1rem"
  };

  const reports_search_label: SxProps<Theme> = {
    fontSize: "0.625rem",
    color: COLORS.alert
  };

  return {
    reports_select_styles,
    reports_paper_field,
    reports_input_field,
    reports_icon,
    reports_search_label
  };
};
