import { COLORS } from "@theme";
import { SetStateAction } from "react";
import { Icon } from "@movicoders/components";
import { useTranslation } from "react-i18next";
import { logisticStyles } from "../../../logistic-styles";
import IMMEntryWithDesc from "@domain/model/IMMEntryWithDesc";
import { Grid, TextField, Button, Divider } from "@mui/material";
import { useEntriesValidations } from "../../hooks/useEntriesValidations";
import { useEntriesMediaQueries } from "../../hooks/useEntriesMediaQueries";
import { AutocompleteIMEntry } from "@pages/logistic/components/logistic-autocomplete";

interface IViewForm {
  createdIMMaterial: IMMEntryWithDesc;
  addButtonText: string;
  commentName: string;
  qtyName: string;
  setCreatedIMMaterial: (value: SetStateAction<IMMEntryWithDesc>) => void;
  handleAddMaterial: () => void;
}

export const EntriesViewForm = (props: IViewForm) => {
  const { t } = useTranslation();
  const styles = logisticStyles();
  const { createdIMMaterial, addButtonText, commentName, qtyName, setCreatedIMMaterial, handleAddMaterial } = props;

  const { formErrors, validateFormValues } = useEntriesValidations(createdIMMaterial);
  const { max, min, matchesSm, getItemGrid } = useEntriesMediaQueries();

  return (
    <>
      <Grid container display={"flex"} flexDirection={matchesSm ? "row" : "column"} spacing={1} sx={{ mt: "1rem", mb: "1.5rem" }}>
        <Grid item={getItemGrid()} pt={1} pl={0} xs={12} sm={6} md={3.5}>
          <AutocompleteIMEntry
            id="search-material-entries-autocomplete"
            iMMaterial={createdIMMaterial}
            onChangeMaterial={value =>
              setCreatedIMMaterial({
                ...createdIMMaterial,
                id: value?.id,
                materialCode: value?.code,
                materialDescription: value?.name
              })
            }
            formErrors={formErrors}
          />
        </Grid>
        <Grid item={matchesSm} xs={12} pt={1} pl={0} sm={6} md={2}>
          <TextField
            id="material-qty-entries-textfield"
            name={qtyName}
            value={createdIMMaterial.qty && createdIMMaterial.qty > 0 ? createdIMMaterial.qty + "" : "0"}
            error={formErrors.get("material-qty") as boolean}
            helperText={(formErrors.get("material-qty") as boolean) ? t(`material.dialog.empty.field.text`) : ""}
            label={t("logistic.quantity")}
            placeholder={t("textfield.qty.input.required")}
            className={"blue-border-input"}
            onChange={event => {
              const regex = /^\d*\.?\d*$/;
              const inputValue = event.target.value;
              if (regex.test(inputValue) || inputValue === "") {
                let newValue = inputValue === "" ? "0" : inputValue.replace(/^0+/, "");
                if (Number(inputValue) > max) {
                  newValue = max.toString();
                } else if (Number(inputValue) <= min) {
                  newValue = min.toString();
                }
                setCreatedIMMaterial({ ...createdIMMaterial, qty: Number(newValue) });
              }
            }}
            InputProps={{
              inputProps: {
                max: max,
                min: min
              }
            }}
          />
        </Grid>
        <Grid item={getItemGrid(true)} pt={1} pl={0} xs={12} sm={6} md={3.5}>
          <TextField
            id="material-comment-entries-textfield"
            name={commentName}
            value={createdIMMaterial.comment ?? ""}
            type="text"
            label={t("logistic.comment")}
            className={"blue-border-input"}
            onChange={event => {
              setCreatedIMMaterial({ ...createdIMMaterial, comment: event.target.value });
            }}
          />
        </Grid>
        <Grid item={matchesSm} xs={12} pt={1} pl={0} sm={6} md={3}>
          <Button
            id={addButtonText.replaceAll(".", "-")}
            startIcon={<Icon icon="plus" color={COLORS.white} />}
            variant="primary"
            fullWidth
            sx={styles.logistic_button}
            onClick={() => {
              validateFormValues() && handleAddMaterial();
            }}>
            {t(addButtonText)}
          </Button>
        </Grid>
      </Grid>
      <Divider sx={styles.content_divider} />
    </>
  );
};
