/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ExtendedUserDTO,
  ExtendedUserPageDTO,
  FilterDTO,
  FullUserDTO,
  MovicodersBadRequestException,
  MovicodersNotFoundException,
  MovicodersServiceException,
  NewPasswordRequest,
  User,
  UserDetailsDTO,
  UserPermissionsDTO,
} from '../models';
import {
    ExtendedUserDTOFromJSON,
    ExtendedUserDTOToJSON,
    ExtendedUserPageDTOFromJSON,
    ExtendedUserPageDTOToJSON,
    FilterDTOFromJSON,
    FilterDTOToJSON,
    FullUserDTOFromJSON,
    FullUserDTOToJSON,
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
    NewPasswordRequestFromJSON,
    NewPasswordRequestToJSON,
    UserFromJSON,
    UserToJSON,
    UserDetailsDTOFromJSON,
    UserDetailsDTOToJSON,
    UserPermissionsDTOFromJSON,
    UserPermissionsDTOToJSON,
} from '../models';

export interface ActivateUserByCredentialsIdRequest {
    credentialsId: string;
    xTenantId?: string;
}

export interface DeactivateUserByIdRequest {
    credentialsId: string;
    xTenantId?: string;
}

export interface DeleteUserByIdRequest {
    id: string;
    xTenantId?: string;
}

export interface GetFullInfoByCredentialsIdRequest {
    credentialsId: string;
    xTenantId?: string;
}

export interface GetPermissionsByRoleRequest {
    role: GetPermissionsByRoleRoleEnum;
    xTenantId?: string;
}

export interface GetUserByCredentialsIdRequest {
    id: string;
    xTenantId?: string;
}

export interface GetUserByTokenRequest {
    xTenantId?: string;
}

export interface GetUsersByTenantRequest {
    xTenantId?: string;
}

export interface GetUsersByTenantPaginatedRequest {
    limit: number;
    offset: number;
    xTenantId?: string;
    filterDTO?: FilterDTO;
}

export interface RecoverUsernameRequest {
    xTenantId?: string;
    body?: object;
}

export interface ResetPasswordRequest {
    username: string;
    newPassword: string;
    xTenantId?: string;
}

export interface ResetPassword1Request {
    xTenantId?: string;
    newPasswordRequest?: NewPasswordRequest;
}

export interface SetUserStatusRequest {
    id: string;
    validated: SetUserStatusValidatedEnum;
    xTenantId?: string;
}

export interface SignUpRequest {
    xTenantId?: string;
    user?: User;
}

export interface UpdateUserByIdRequest {
    id: string;
    xTenantId?: string;
    user?: User;
}

export interface UpdateUserRolePermissionsRequest {
    role: UpdateUserRolePermissionsRoleEnum;
    xTenantId?: string;
    userPermissionsDTO?: UserPermissionsDTO;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     * Activate user profile and credentials for a user
     * Activate user profile and credentials for a user
     */
    async activateUserByCredentialsIdRaw(requestParameters: ActivateUserByCredentialsIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExtendedUserDTO>> {
        if (requestParameters.credentialsId === null || requestParameters.credentialsId === undefined) {
            throw new runtime.RequiredError('credentialsId','Required parameter requestParameters.credentialsId was null or undefined when calling activateUserByCredentialsId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/users/activate/{credentialsId}`.replace(`{${"credentialsId"}}`, encodeURIComponent(String(requestParameters.credentialsId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtendedUserDTOFromJSON(jsonValue));
    }

    /**
     * Activate user profile and credentials for a user
     * Activate user profile and credentials for a user
     */
    async activateUserByCredentialsId(requestParameters: ActivateUserByCredentialsIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExtendedUserDTO> {
        const response = await this.activateUserByCredentialsIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deactivate User
     * Deactivate User
     */
    async deactivateUserByIdRaw(requestParameters: DeactivateUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExtendedUserDTO>> {
        if (requestParameters.credentialsId === null || requestParameters.credentialsId === undefined) {
            throw new runtime.RequiredError('credentialsId','Required parameter requestParameters.credentialsId was null or undefined when calling deactivateUserById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/users/deactivate/{credentialsId}`.replace(`{${"credentialsId"}}`, encodeURIComponent(String(requestParameters.credentialsId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtendedUserDTOFromJSON(jsonValue));
    }

    /**
     * Deactivate User
     * Deactivate User
     */
    async deactivateUserById(requestParameters: DeactivateUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExtendedUserDTO> {
        const response = await this.deactivateUserByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete User
     * Delete User
     */
    async deleteUserByIdRaw(requestParameters: DeleteUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExtendedUserDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUserById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/users/delete/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtendedUserDTOFromJSON(jsonValue));
    }

    /**
     * Delete User
     * Delete User
     */
    async deleteUserById(requestParameters: DeleteUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExtendedUserDTO> {
        const response = await this.deleteUserByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a full user object by credential id.
     * Get an user object by credential id.
     */
    async getFullInfoByCredentialsIdRaw(requestParameters: GetFullInfoByCredentialsIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullUserDTO>> {
        if (requestParameters.credentialsId === null || requestParameters.credentialsId === undefined) {
            throw new runtime.RequiredError('credentialsId','Required parameter requestParameters.credentialsId was null or undefined when calling getFullInfoByCredentialsId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/users/info/{credentialsId}`.replace(`{${"credentialsId"}}`, encodeURIComponent(String(requestParameters.credentialsId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FullUserDTOFromJSON(jsonValue));
    }

    /**
     * Get a full user object by credential id.
     * Get an user object by credential id.
     */
    async getFullInfoByCredentialsId(requestParameters: GetFullInfoByCredentialsIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullUserDTO> {
        const response = await this.getFullInfoByCredentialsIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get permissions by role.
     * Get permissions by role.
     */
    async getPermissionsByRoleRaw(requestParameters: GetPermissionsByRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserPermissionsDTO>> {
        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling getPermissionsByRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/users/users/permissions/{role}`.replace(`{${"role"}}`, encodeURIComponent(String(requestParameters.role))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPermissionsDTOFromJSON(jsonValue));
    }

    /**
     * Get permissions by role.
     * Get permissions by role.
     */
    async getPermissionsByRole(requestParameters: GetPermissionsByRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserPermissionsDTO> {
        const response = await this.getPermissionsByRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an user object by credential id.
     * Get an user object by credential id.
     */
    async getUserByCredentialsIdRaw(requestParameters: GetUserByCredentialsIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExtendedUserDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUserByCredentialsId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtendedUserDTOFromJSON(jsonValue));
    }

    /**
     * Get an user object by credential id.
     * Get an user object by credential id.
     */
    async getUserByCredentialsId(requestParameters: GetUserByCredentialsIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExtendedUserDTO> {
        const response = await this.getUserByCredentialsIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns one profile object by credential id.
     * Get a profile object by credential id.
     */
    async getUserByTokenRaw(requestParameters: GetUserByTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDetailsDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/users/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDetailsDTOFromJSON(jsonValue));
    }

    /**
     * Returns one profile object by credential id.
     * Get a profile object by credential id.
     */
    async getUserByToken(requestParameters: GetUserByTokenRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDetailsDTO> {
        const response = await this.getUserByTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get users on tenant
     * Get users on tenant
     */
    async getUsersByTenantRaw(requestParameters: GetUsersByTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ExtendedUserDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/users/tenant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExtendedUserDTOFromJSON));
    }

    /**
     * Get users on tenant
     * Get users on tenant
     */
    async getUsersByTenant(requestParameters: GetUsersByTenantRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ExtendedUserDTO>> {
        const response = await this.getUsersByTenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get users on tenant paginated
     * Get users on tenant paginated
     */
    async getUsersByTenantPaginatedRaw(requestParameters: GetUsersByTenantPaginatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExtendedUserPageDTO>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getUsersByTenantPaginated.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getUsersByTenantPaginated.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/users/tenant/paginated`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterDTOToJSON(requestParameters.filterDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtendedUserPageDTOFromJSON(jsonValue));
    }

    /**
     * Get users on tenant paginated
     * Get users on tenant paginated
     */
    async getUsersByTenantPaginated(requestParameters: GetUsersByTenantPaginatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExtendedUserPageDTO> {
        const response = await this.getUsersByTenantPaginatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Receive username or email to init password reset request
     * Receive username/email to init password reset request
     */
    async recoverUsernameRaw(requestParameters: RecoverUsernameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/users/recovery`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Receive username or email to init password reset request
     * Receive username/email to init password reset request
     */
    async recoverUsername(requestParameters: RecoverUsernameRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.recoverUsernameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reset a password for an user
     * Reset a password for an user
     */
    async resetPasswordRaw(requestParameters: ResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling resetPassword.');
        }

        if (requestParameters.newPassword === null || requestParameters.newPassword === undefined) {
            throw new runtime.RequiredError('newPassword','Required parameter requestParameters.newPassword was null or undefined when calling resetPassword.');
        }

        const queryParameters: any = {};

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        if (requestParameters.newPassword !== undefined) {
            queryParameters['newPassword'] = requestParameters.newPassword;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/users/recovery/change`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Reset a password for an user
     * Reset a password for an user
     */
    async resetPassword(requestParameters: ResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.resetPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reset a user\'s password.
     * Reset a user\'s password.
     */
    async resetPassword1Raw(requestParameters: ResetPassword1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/users/reset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewPasswordRequestToJSON(requestParameters.newPasswordRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Reset a user\'s password.
     * Reset a user\'s password.
     */
    async resetPassword1(requestParameters: ResetPassword1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.resetPassword1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set User Status 
     * Set User Status
     */
    async setUserStatusRaw(requestParameters: SetUserStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExtendedUserDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling setUserStatus.');
        }

        if (requestParameters.validated === null || requestParameters.validated === undefined) {
            throw new runtime.RequiredError('validated','Required parameter requestParameters.validated was null or undefined when calling setUserStatus.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.validated !== undefined) {
            queryParameters['validated'] = requestParameters.validated;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/users/validate`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtendedUserDTOFromJSON(jsonValue));
    }

    /**
     * Set User Status 
     * Set User Status
     */
    async setUserStatus(requestParameters: SetUserStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExtendedUserDTO> {
        const response = await this.setUserStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns one User object
     * Returns one User object
     */
    async signUpRaw(requestParameters: SignUpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/users/sign-up`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserToJSON(requestParameters.user),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Returns one User object
     * Returns one User object
     */
    async signUp(requestParameters: SignUpRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.signUpRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update User by id
     * Update User by id
     */
    async updateUserByIdRaw(requestParameters: UpdateUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExtendedUserDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUserById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserToJSON(requestParameters.user),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtendedUserDTOFromJSON(jsonValue));
    }

    /**
     * Update User by id
     * Update User by id
     */
    async updateUserById(requestParameters: UpdateUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExtendedUserDTO> {
        const response = await this.updateUserByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update User role permissions
     * Update User role permissions
     */
    async updateUserRolePermissionsRaw(requestParameters: UpdateUserRolePermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ExtendedUserDTO>>> {
        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling updateUserRolePermissions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/users/users/rolePermissions/{role}`.replace(`{${"role"}}`, encodeURIComponent(String(requestParameters.role))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserPermissionsDTOToJSON(requestParameters.userPermissionsDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExtendedUserDTOFromJSON));
    }

    /**
     * Update User role permissions
     * Update User role permissions
     */
    async updateUserRolePermissions(requestParameters: UpdateUserRolePermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ExtendedUserDTO>> {
        const response = await this.updateUserRolePermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetPermissionsByRoleRoleEnum = {
    MobAdmin: 'MOB_ADMIN',
    MobUser: 'MOB_USER',
    PortalAdmin: 'PORTAL_ADMIN',
    PortalUser: 'PORTAL_USER',
    SuperAdmin: 'SUPER_ADMIN'
} as const;
export type GetPermissionsByRoleRoleEnum = typeof GetPermissionsByRoleRoleEnum[keyof typeof GetPermissionsByRoleRoleEnum];
/**
 * @export
 */
export const SetUserStatusValidatedEnum = {
    Accepted: 'ACCEPTED',
    Denied: 'DENIED',
    Requested: 'REQUESTED'
} as const;
export type SetUserStatusValidatedEnum = typeof SetUserStatusValidatedEnum[keyof typeof SetUserStatusValidatedEnum];
/**
 * @export
 */
export const UpdateUserRolePermissionsRoleEnum = {
    MobAdmin: 'MOB_ADMIN',
    MobUser: 'MOB_USER',
    PortalAdmin: 'PORTAL_ADMIN',
    PortalUser: 'PORTAL_USER',
    SuperAdmin: 'SUPER_ADMIN'
} as const;
export type UpdateUserRolePermissionsRoleEnum = typeof UpdateUserRolePermissionsRoleEnum[keyof typeof UpdateUserRolePermissionsRoleEnum];
