import { useEffect, useState } from "react";
import { useInventoryReportPeriod } from "./useInventoryReportPeriod";
import { useInventoryReportPagination } from "./useInventoryReportPagination";
import { useInventoryReportHandleData } from "./useInventoryReportHandleData";
import { useInventoryReportAutocomplete } from "./useInventoryReportAutocomplete";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { useResolve } from "@movicoders/di";

export const useInventoryReportViewModel = () => {
  const [searchTextValue, setSearchTextValue] = useState("");
  const settingsService = useResolve<ISettingsService>(SettingsService);

  const {
    inventoryReports,
    columnsByMode,
    currentPageService,
    currentPagePersistedState,
    loading,
    selectedWarehouseCode,
    getInvReportPaginated
  } = useInventoryReportHandleData();

  const { sortedPeriods, isActivePeriod, selectedPeriod, setSelectedPeriod } = useInventoryReportPeriod(
    currentPagePersistedState,
    selectedWarehouseCode,
    searchTextValue,
    getInvReportPaginated
  );

  const { filteredMaterialPaginatedData } = useInventoryReportAutocomplete(currentPageService, currentPagePersistedState);
  const { searchValue, showErrors, search, clearSearchValue, handleEnterReload, setSearchValue } = useInventoryReportPagination(
    selectedPeriod,
    currentPagePersistedState,
    getInvReportPaginated
  );

  useEffect(() => {
    setSearchTextValue(searchValue);
  }, [searchValue]);

  return {
    sortedPeriods,
    isActivePeriod,
    selectedPeriod,
    inventoryReports,
    columnsByMode,
    searchValue,
    showErrors,
    filteredMaterialPaginatedData,
    loading,
    search,
    setSearchValue,
    clearSearchValue,
    handleEnterReload,
    setSelectedPeriod,
    settingsService
  };
};
