import { SxProps, Theme } from "@mui/material";

export const settingsStyles = () => {
  const settings_view_container: SxProps<Theme> = {
    pl: { xs: 1, sm: 6, md: "16vw" },
    pr: { xs: 1, sm: 1, md: "16vw" },
    pt: "4rem"
  };

  const settings_divider: SxProps<Theme> = {
    border: "2px solid #EBEBEB",
    opacity: 1,
    width: "100%",
    marginTop: 1,
    marginBottom: 1
  };

  return {
    settings_view_container,
    settings_divider
  };
};
