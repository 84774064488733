/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContainerDTO
 */
export interface ContainerDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ContainerDTO
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContainerDTO
     */
    agent?: string;
    /**
     * 
     * @type {string}
     * @memberof ContainerDTO
     */
    code?: string;
    /**
     * 
     * @type {Date}
     * @memberof ContainerDTO
     */
    creationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ContainerDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ContainerDTO
     */
    initialOperatorId?: string;
    /**
     * 
     * @type {string}
     * @memberof ContainerDTO
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof ContainerDTO
     */
    material?: string;
    /**
     * 
     * @type {string}
     * @memberof ContainerDTO
     */
    productId?: string;
    /**
     * 
     * @type {number}
     * @memberof ContainerDTO
     */
    qty?: number;
    /**
     * 
     * @type {string}
     * @memberof ContainerDTO
     */
    uom?: string;
    /**
     * 
     * @type {string}
     * @memberof ContainerDTO
     */
    warehouse?: string;
}

/**
 * Check if a given object implements the ContainerDTO interface.
 */
export function instanceOfContainerDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContainerDTOFromJSON(json: any): ContainerDTO {
    return ContainerDTOFromJSONTyped(json, false);
}

export function ContainerDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContainerDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'agent': !exists(json, 'agent') ? undefined : json['agent'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'initialOperatorId': !exists(json, 'initialOperatorId') ? undefined : json['initialOperatorId'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'material': !exists(json, 'material') ? undefined : json['material'],
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'qty': !exists(json, 'qty') ? undefined : json['qty'],
        'uom': !exists(json, 'uom') ? undefined : json['uom'],
        'warehouse': !exists(json, 'warehouse') ? undefined : json['warehouse'],
    };
}

export function ContainerDTOToJSON(value?: ContainerDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'agent': value.agent,
        'code': value.code,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'id': value.id,
        'initialOperatorId': value.initialOperatorId,
        'location': value.location,
        'material': value.material,
        'productId': value.productId,
        'qty': value.qty,
        'uom': value.uom,
        'warehouse': value.warehouse,
    };
}

