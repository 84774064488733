import { COLORS } from "@theme";
import { SxProps, Theme } from "@mui/material";
import { timelineItemClasses } from "@mui/lab";

export const hierarchyReportStyles = () => {
  const title_container: SxProps<Theme> = {
    flexDirection: "column",
    flexWrap: "nowrap",
    marginBottom: "1rem"
  };

  const hierarchy_go_back: SxProps<Theme> = {
    color: COLORS.gray,
    fontSize: "0.75rem",
    maxWidth: "fit-content"
  };

  const hierarchy_record_paper_right: SxProps<Theme> = {
    marginTop: "20vh",
    overflowY: "scroll",
    overflowX: "hidden",
    borderRadius: "6px",
    boxShadow: "0px 3px 6px #00000029"
  };

  const hierarchy_record_paper_bottom: SxProps<Theme> = {
    top: "unset",
    bottom: 0,
    left: 0,
    right: 0,
    overflowY: "scroll",
    overflowX: "hidden",
    borderRadius: "6px",
    boxShadow: "0px 3px 6px #00000029"
  };

  const hierarchy_record_header: SxProps<Theme> = {
    paddingTop: "1.25rem"
  };

  const hierarchy_record_footer: SxProps<Theme> = {
    borderTop: `1px solid ${COLORS.lightgray}`,
    justifyContent: "space-between",
    alignItems: "center",
    position: "fixed",
    bottom: 0,
    backgroundColor: COLORS.white,
    paddingRight: "1.25rem"
  };

  const hierarchy_record_timeline: SxProps<Theme> = {
    paddingLeft: "1.25rem",
    paddingBottom: "4rem",
    [`& .${timelineItemClasses.root}:before`]: {
      flex: 0,
      padding: 0
    }
  };

  const hierarchy_record_card: SxProps<Theme> = {
    height: "fit-content",
    padding: "1rem",
    border: `1px solid ${COLORS.gray}`,
    backgroundColor: COLORS.white
  };

  const hierarchy_parent_product: SxProps<Theme> = {
    padding: "1rem",
    background: `linear-gradient(white 0 0) padding-box,${COLORS.secondary} border-box`,
    color: "#313149",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "6px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: 0
  };

  const hierarchy_children_container: SxProps<Theme> = {
    justifyContent: "flex-end",
    marginTop: "1rem",
    maxHeight: "63vh",
    overflow: "scroll",
    paddingBottom: "1rem"
  };

  const hierarchy_children_collapse: SxProps<Theme> = {
    alignSelf: "center",
    boxShadow: "-3px 0px 6px #00000029;",
    borderRadius: "10% 0% 0% 10%",
    background: "white",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    cursor: "pointer"
  };

  const hierarchy_children_product: SxProps<Theme> = {
    padding: "1rem",
    background: `linear-gradient(white 0 0) padding-box, ${COLORS.secondaryHighlight} border-box`,
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "6px",
    width: "100%",
    cursor: "pointer",
    minHeight: "5rem"
  };

  const breadcrumbs_text: SxProps<Theme> = {
    fontSize: "0.75rem",
    padding: "0.5rem"
  };

  return {
    title_container,
    hierarchy_go_back,
    hierarchy_record_paper_right,
    hierarchy_record_paper_bottom,
    hierarchy_record_header,
    hierarchy_record_footer,
    hierarchy_record_timeline,
    hierarchy_record_card,
    hierarchy_parent_product,
    hierarchy_children_container,
    hierarchy_children_collapse,
    hierarchy_children_product,
    breadcrumbs_text
  };
};
