import {
  Credentials,
  ExtendedUserDTO,
  FullUserDTO,
  GetUsersByTenantPaginatedRequest,
  Profile,
  ExtendedUserDTO as UserDTO,
  ExtendedUserPageDTO as UserPageDTO,
  UsersApi,
  User as APIUser,
  UpdateUserRolePermissionsRequest,
  GetPermissionsByRoleRequest,
  UserPermissionsDTO
} from "@clients/aggrego-proxy";
import { User } from "@domain/model/User";
import { DomainPage } from "@movicoders/domain";
import GenericPage, { DTOPage } from "@domain/model/GenericPage";
import { IImpersonationService } from "@domain/services/IImpersonationService";

export class UserApi {
  constructor(private readonly usersApi: UsersApi, private readonly impersonationService: IImpersonationService) {}

  async getPaginated(pageCriteria: GetUsersByTenantPaginatedRequest): Promise<DomainPage<User>> {
    return new Promise((res, rej) => {
      this.usersApi
        .getUsersByTenantPaginated({
          ...pageCriteria,
          xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
        })
        .then((userPageDTO: UserPageDTO) => {
          res(new GenericPage<User>(User).fromDTOPage(userPageDTO as DTOPage<User>));
        });
    });
  }

  async getById(id: string): Promise<User> {
    const userDTO: UserDTO = await this.usersApi.getUserByCredentialsId({
      id,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
    if (userDTO === undefined) {
      throw new Error("User not found");
    } else {
      return Promise.resolve(new User().fromDTO(userDTO));
    }
  }

  async getInfoUser(id: string): Promise<FullUserDTO> {
    const userDTO: FullUserDTO = await this.usersApi.getFullInfoByCredentialsId({
      credentialsId: id,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
    if (userDTO === undefined) {
      throw new Error("User not found");
    } else {
      return userDTO;
    }
  }

  async create(entity: User): Promise<User> {
    const apiUser: APIUser = { credentials: this.createCredentials(entity), profile: this.createProfile(entity) };
    const result = await this.usersApi.signUp({
      user: apiUser,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
    return result as User;
  }

  async update(entity: User): Promise<ExtendedUserDTO> {
    return await this.usersApi.updateUserById({
      id: entity.profileId,
      user: {
        credentials: this.createCredentials(entity),
        profile: this.createProfile(entity)
      },
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }

  remove(id: string): Promise<unknown> {
    return this.usersApi.deleteUserById({ id, xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? "" });
  }

  activate(id: string): Promise<unknown> {
    return this.usersApi.activateUserByCredentialsId({
      credentialsId: id,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }

  deactivate(id: string): Promise<unknown> {
    return this.usersApi.deactivateUserById({
      credentialsId: id,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }

  async recoverUserRolePermissions(criteria: GetPermissionsByRoleRequest): Promise<UserPermissionsDTO> {
    const apiResult = await this.usersApi.getPermissionsByRole({
      ...criteria,
      xTenantId: ""
    });
    return apiResult;
  }

  async updateUserRolePermissions(criteria: UpdateUserRolePermissionsRequest): Promise<ExtendedUserDTO[]> {
    const apiResult = await this.usersApi.updateUserRolePermissions({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
    return apiResult;
  }

  private createCredentials(user: User): Credentials {
    return {
      active: true,
      password: user.password !== "" ? user.password : undefined,
      tenant: this.impersonationService.persistedState?.impersonatedTenant ?? user.tenantId,
      username: user.username
    };
  }

  private createProfile(user: User): Profile {
    return {
      active: true,
      tenant: this.impersonationService.persistedState?.impersonatedTenant ?? user.tenantId,
      credentialsId: user.credentialsId,
      email: user.email,
      firstName: user.firstName,
      profileId: user.profileId,
      roles: new Set(user.roles),
      status: user.validated,
      firstSurname: user.firstSurname,
      secondSurname: user.secondSurname
    };
  }
}
