import { COLORS } from "@theme";
import { SxProps, Theme } from "@mui/material";

export const trackingReportStyles = () => {
  const track_prod_select_styles: SxProps<Theme> = {
    fontSize: "small",
    justifyContent: "space-between",
    mr: 1,
    minWidth: { md: "23%", xs: "100%" },
    borderWidth: "1px",
    color: COLORS.gray,
    backgroundColor: "white",
    height: "2.75rem",
    fieldset: {
      borderColor: COLORS.gray
    },
    "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px"
    },
    "&:hover": {
      "&& fieldset": {
        borderColor: COLORS.gray
      }
    },
    "&.Mui-focused": {
      "&& fieldset": {
        borderColor: COLORS.gray
      }
    }
  };

  const track_prod_paper_field: SxProps<Theme> = {
    mb: 1,
    mr: 1,
    width: "90%"
  };

  const track_prod_input_field_left: SxProps<Theme> = {
    fontSize: "small",
    justifyContent: "space-between",
    width: "90%",
    paddingTop: "5.5px",
    backgroundColor: "white",
    borderWidth: "1px",
    color: COLORS.gray,
    root: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px",
        borderColor: COLORS.gray
      }
    },
    "&:hover": {
      "&& fieldset": {
        borderWidth: "1px",
        borderColor: COLORS.gray
      }
    },
    "&:not(:hover)": {
      "&& fieldset": {
        borderWidth: "1px",
        borderColor: COLORS.gray
      }
    },
    "&:has(div)": {
      "& > p": {
        backgroundColor: COLORS.background,
        margin: 0,
        paddingX: "14px",
        paddingTop: "4px"
      },
      "& > label": {
        paddingTop: "5px"
      }
    }
  };

  const track_prod_input_field_right: SxProps<Theme> = {
    marginLeft: "1rem",
    ...track_prod_input_field_left
  };

  const track_prod_search_icon: SxProps<Theme> = {
    background: COLORS.secondary,
    borderRadius: "4px",
    height: "2.75rem",
    marginLeft: "1rem",
    "&:hover": {
      background: COLORS.secondaryHighlight
    },
    "&:disabled": {
      background: COLORS.secondaryOpacity
    }
  };

  const track_prod_clean_icon: SxProps<Theme> = {
    background: COLORS.buttonGray,
    borderRadius: "4px",
    height: "2.75rem",
    marginLeft: "1rem"
  };

  const tracking_search_label: SxProps<Theme> = {
    fontSize: "0.625rem",
    color: COLORS.alert
  };

  return {
    track_prod_select_styles,
    track_prod_paper_field,
    track_prod_input_field_left,
    track_prod_input_field_right,
    track_prod_search_icon,
    track_prod_clean_icon,
    tracking_search_label
  };
};
