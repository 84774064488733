import { COLORS } from "@theme";
import Zone from "@domain/model/Zone";
import { useTranslation } from "react-i18next";
import { useZoneForm } from "./hooks/useZoneForm";
import { CREATE_ID, TIMEOUT_ID } from "@constants";
import { Grid, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";
import { ButtonsDetail } from "@components/master-data-view/buttons/buttons-detail";
import { LoadingSpinnerDetailView } from "@components/loading-circular-progress/loading-spinner-detail-view";

interface IDetail {
  zone: Zone;
  loading: boolean;
  onSave: (zone: Zone) => void;
  onClose: () => void;
}

export const ZonesDetailView: FC<IDetail> = ({ zone: initialZone, loading, onSave, onClose }) => {
  const { t } = useTranslation();
  const { onChange, onChangeColor, validateFormValues, formErrors, zone } = useZoneForm(initialZone);

  const disabled = zone.active !== undefined && zone.active === false;

  const [pendingRequest, setPendingRequest] = useState<boolean>(false);

  useEffect(() => {
    setPendingRequest(false);
  }, [initialZone]);

  const renderInput = (id: string, name: string, value: string | number, label: string, type?: string) => {
    return (
      <TextField
        id={id}
        type={type ?? "text"}
        name={name}
        className={disabled ? "disabled-input" : "blue-border-input"}
        disabled={disabled}
        value={value}
        onChange={onChange}
        label={label}
        error={formErrors.get(id) as boolean}
        helperText={(formErrors.get(id) as boolean) ? t(`zones.dialog.empty.field.text`) : ""}
      />
    );
  };

  const zoneColor = zone.color !== "" ? zone.color : COLORS.primary;

  return (
    <React.Fragment>
      {(initialZone as Zone).id !== undefined && (initialZone as Zone).id !== TIMEOUT_ID ? (
        <AggregoDialog
          title={
            (initialZone as Zone).id === CREATE_ID
              ? t("zones.detail.title")
              : t("zones.detail.edit.title") + (initialZone as Zone).name
          }
          onClose={onClose}>
          <Grid container flexDirection="column" className="masterdata-box" rowSpacing={2}>
            <Grid item xs={12}>
              {renderInput("zone-name", "name", zone.name ?? "", t("zones.name"))}
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: "-1.5rem" }}>
              <Typography variant="cardlabel_secondary" sx={{ ml: "0.313em" }}>
                {t("zones.color")}
              </Typography>
              <br />
              <input
                style={{
                  width: "93px",
                  height: "20px",
                  cursor: disabled ? "" : "pointer"
                }}
                value={!disabled ? zoneColor : COLORS.gray}
                type="color"
                id="colorpicker"
                disabled={disabled}
                onInput={(event: ChangeEvent<HTMLInputElement>) => onChangeColor(event.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <ButtonsDetail
                handleClose={onClose}
                handleSave={() => {
                  if (validateFormValues()) {
                    setPendingRequest(true);
                    onSave(zone);
                  }
                }}
                mode={zone?.id === CREATE_ID ? "CREATE" : "EDIT"}
                disabled={disabled}
                pendingRequest={pendingRequest}
              />
            </Grid>
          </Grid>
        </AggregoDialog>
      ) : (
        <LoadingSpinnerDetailView loading={loading} selectedId={(initialZone as Zone)?.id ?? ""} />
      )}
    </React.Fragment>
  );
};
