import { GridColDef } from "@mui/x-data-grid";
import { filterValueTypes } from "@constants";
import { CustomTooltipCell } from "@components/tooltip/CustomTooltipCell";

export const inventoryColumns = (t: (text: string) => string, smMediaQuery: boolean, lgMediaQuery: boolean): GridColDef[] => [
  {
    flex: 1,
    field: "inventoryCode",
    headerName: t("invrep.inventory"),
    disableColumnMenu: true,
    minWidth: lgMediaQuery ? undefined : 100,
    renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
  },
  {
    flex: 1,
    field: "containerCode",
    headerName: t("invrep.container"),
    disableColumnMenu: true,
    minWidth: lgMediaQuery ? undefined : 100,
    renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
  },
  {
    flex: 1,
    field: "materialCode",
    headerName: t("invrep.material"),
    disableColumnMenu: true,
    minWidth: lgMediaQuery ? undefined : 100,
    type: "autocomplete-one" as filterValueTypes,
    renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
  },
  {
    flex: 1,
    field: "qty",
    headerName: t("invrep.qty"),
    disableColumnMenu: true,
    minWidth: lgMediaQuery ? undefined : 100,
    type: "number",
    valueGetter: ({ row }) => {
      return `${row.qty} ${t("material.measure.abbreviation.units")}`;
    },
    renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
  },
  {
    flex: 1,
    field: "locationCode",
    headerName: t("invrep.location"),
    disableColumnMenu: true,
    minWidth: lgMediaQuery ? undefined : 100,
    renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
  },
  {
    flex: 1,
    field: "user",
    headerName: t("invrep.license"),
    disableColumnMenu: true,
    minWidth: lgMediaQuery ? undefined : 100,
    renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
  },
  {
    flex: 0.8,
    field: "operatorCode",
    headerName: t("invrep.operator"),
    disableColumnMenu: true,
    minWidth: lgMediaQuery ? undefined : 100,
    renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
  },
  {
    flex: 0.8,
    field: "created",
    headerName: t("invrep.date"),
    disableColumnMenu: true,
    minWidth: lgMediaQuery ? undefined : 100,
    type: "range-date" as filterValueTypes,
    valueGetter: ({ row }) => {
      const parseDate = new Date(row.created.toString());
      return row.created
        ? parseDate.toLocaleString("es-ES", {
            localeMatcher: "lookup",
            month: "numeric",
            day: "numeric",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit"
          })
        : "-";
    },
    renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
  }
];
