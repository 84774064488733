import { User } from "@domain/model/User";
import { useState, useEffect, ChangeEvent } from "react";
import IUser, { TUserRoleEnum, UserRoleEnum } from "@domain/interface/User";
import { isPasswordValid } from "@components/password/invalid-password-tooltip/InvalidPasswordTooltip";

export interface IFormErrors {
  username: boolean;
  email: boolean;
}

export const useUserForm = (data: User) => {
  const INITIAL_ERROR_MAP = new Map([
    ["user-username", false],
    ["user-email", false]
  ]);
  const [user, setUser] = useState<IUser>(data);
  const [passwordValidationFailed, setPasswordValidationFailed] = useState(false);

  const [passwordValue, setPasswordValue] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const [formErrors, setFormErrors] = useState<Map<string, boolean>>(INITIAL_ERROR_MAP);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nameField = event.target.name;
    if (nameField !== "password" && nameField !== "confirmPassword") {
      nameField === "email"
        ? setUser({ ...user, [nameField]: event.target.value, username: event.target.value })
        : setUser({ ...user, [nameField]: event.target.value });
    } else {
      if (nameField === "password") {
        setPasswordValue(event.target.value);
      }
      if (nameField === "confirmPassword") {
        setConfirmPasswordValue(event.target.value);
      }
    }
  };

  const onChangeRole = (event: ChangeEvent<HTMLInputElement>) => {
    const valueToUse = event.target.value as TUserRoleEnum;
    const mobRoles: TUserRoleEnum[] = [UserRoleEnum.MobUser, UserRoleEnum.MobAdmin];
    const portalRoles: TUserRoleEnum[] = [UserRoleEnum.PortalUser, UserRoleEnum.PortalAdmin];
    let newRoles: TUserRoleEnum[] = [];

    if (event.target.checked) {
      if (mobRoles.includes(valueToUse)) newRoles = [...user.roles.filter(oldVal => portalRoles.includes(oldVal)), valueToUse];
      else if (portalRoles.includes(valueToUse))
        newRoles = [...user.roles.filter(oldVal => mobRoles.includes(oldVal)), valueToUse];
    } else {
      newRoles = user.roles.filter(item => item !== valueToUse);
    }

    setUser({ ...user, roles: newRoles });
  };

  useEffect(() => {
    setUser(data.roles ? data : { ...data, roles: [] });
    setPasswordValue("");
    setConfirmPasswordValue("");
    setFormErrors(INITIAL_ERROR_MAP);
    setPasswordValidationFailed(false);
  }, [data]);

  const validateFormValues = (isCreation: boolean) => {
    const emailRegexp = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-_]+\.[A-Za-z0-9]{2,}$/i;
    const emailValid =
      user.email !== null && user.email !== undefined && user.email.trim() !== "" && emailRegexp.test(user.email);
    const usernameValid = user.username !== null && user.username !== undefined && user.username.trim() !== "";
    const passwordValid = !isCreation || (passwordValue !== null && passwordValue !== undefined && passwordValue.trim() !== "");
    const passwordConfirmValid =
      !isCreation || (confirmPasswordValue !== null && confirmPasswordValue !== undefined && confirmPasswordValue.trim() !== "");
    const passwordEqualValid = !isCreation || passwordValue === confirmPasswordValue;
    const rolesValid = user.roles.length > 0;
    const nameValid = user.firstName !== null && user.firstName !== undefined && user.firstName.trim() !== "";
    const firstSurnameValid = user.firstSurname !== null && user.firstSurname !== undefined && user.firstSurname.trim() !== "";

    const isPasswordSecure = (!isCreation && passwordValue === "") || isPasswordValid(passwordValue);
    setPasswordValidationFailed(!isPasswordSecure);

    setFormErrors(
      new Map([
        ["user-username", !usernameValid],
        ["user-password", !passwordValid],
        ["user-confirm-password", !passwordConfirmValid],
        ["user-equal-password", !passwordEqualValid],
        ["user-email", !emailValid],
        ["user-roles", !rolesValid],
        ["user-name", !nameValid],
        ["user-firstsurname", !firstSurnameValid]
      ])
    );
    return (
      nameValid &&
      firstSurnameValid &&
      emailValid &&
      usernameValid &&
      rolesValid &&
      passwordValid &&
      passwordConfirmValid &&
      passwordEqualValid &&
      isPasswordSecure
    );
  };

  const getBannedWordsForPassword = () => {
    const bannedWords: string[] = [];
    if (user.email) {
      const emailName = user.email.split('@')[0];
      if (emailName) bannedWords.push(emailName);
    }
    return bannedWords;
  };

  useEffect(() => {
    setFormErrors(prevFormErrors =>
      new Map(prevFormErrors).set("user-confirm-password", false).set("user-equal-password", false)
    );
    if (passwordValue === confirmPasswordValue) {
      setUser({ ...user, password: passwordValue });
    }
  }, [passwordValue, confirmPasswordValue]);

  return {
    onChange,
    user,
    onChangeRole,
    formErrors,
    validateFormValues,
    confirmPasswordValue,
    setConfirmPasswordValue,
    passwordValue,
    setPasswordValue,
    passwordValidationFailed,
    getBannedWordsForPassword
  };
};
