/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  CustomStakeholderFilter,
  ListResponseDTO,
  MovicodersBadRequestException,
  MovicodersNotFoundException,
  MovicodersServiceException,
  StakeholderDTO,
  StakeholderPageDTO
} from "../models";
import {
  CustomStakeholderFilterFromJSON,
  CustomStakeholderFilterToJSON,
  ListResponseDTOFromJSON,
  ListResponseDTOToJSON,
  MovicodersBadRequestExceptionFromJSON,
  MovicodersBadRequestExceptionToJSON,
  MovicodersNotFoundExceptionFromJSON,
  MovicodersNotFoundExceptionToJSON,
  MovicodersServiceExceptionFromJSON,
  MovicodersServiceExceptionToJSON,
  StakeholderDTOFromJSON,
  StakeholderDTOToJSON,
  StakeholderPageDTOFromJSON,
  StakeholderPageDTOToJSON
} from "../models";

export interface AddStakeholderToCompanyRequest {
  xTenantId?: string;
  stakeholderDTO?: StakeholderDTO;
}

export interface DeleteStakeholderRequest {
  id: string;
  xTenantId?: string;
}

export interface GetStakeholderByIdRequest {
  id: string;
  xTenantId?: string;
}

export interface GetStakeholderByIdsRequest {
  xTenantId?: string;
  requestBody?: Array<string>;
}

export interface ListStakeholdersRequest {
  status: ListStakeholdersStatusEnum;
  xTenantId?: string;
}

export interface ListStakeholdersPaginatedRequest {
  status: ListStakeholdersPaginatedStatusEnum;
  limit: number;
  offset: number;
  xTenantId?: string;
  sortField?: ListStakeholdersPaginatedSortFieldEnum;
  sortDirection?: ListStakeholdersPaginatedSortDirectionEnum;
  customStakeholderFilter?: CustomStakeholderFilter;
}

export interface SaveStakeholdersRequest {
  xTenantId?: string;
  stakeholderDTO?: Array<StakeholderDTO>;
}

export interface UpdateStakeholderRequest {
  xTenantId?: string;
  stakeholderDTO?: StakeholderDTO;
}

/**
 *
 */
export class StakeholdersApi extends runtime.BaseAPI {
  /**
   * Returns the registered Stakeholder
   */
  async addStakeholderToCompanyRaw(
    requestParameters: AddStakeholderToCompanyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<StakeholderDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/stakeholder`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: StakeholderDTOToJSON(requestParameters.stakeholderDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => StakeholderDTOFromJSON(jsonValue));
  }

  /**
   * Returns the registered Stakeholder
   */
  async addStakeholderToCompany(
    requestParameters: AddStakeholderToCompanyRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<StakeholderDTO> {
    const response = await this.addStakeholderToCompanyRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Takes an existing stakeholder, deletes it, and returns the new object.
   * Delete an existing stakeholder by id.
   */
  async deleteStakeholderRaw(
    requestParameters: DeleteStakeholderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<StakeholderDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteStakeholder."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/stakeholder/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => StakeholderDTOFromJSON(jsonValue));
  }

  /**
   * Takes an existing stakeholder, deletes it, and returns the new object.
   * Delete an existing stakeholder by id.
   */
  async deleteStakeholder(
    requestParameters: DeleteStakeholderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<StakeholderDTO> {
    const response = await this.deleteStakeholderRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns one stakeholder object by id.
   * Get a stakeholder object by id.
   */
  async getStakeholderByIdRaw(
    requestParameters: GetStakeholderByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<StakeholderDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getStakeholderById."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/stakeholder/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => StakeholderDTOFromJSON(jsonValue));
  }

  /**
   * Returns one stakeholder object by id.
   * Get a stakeholder object by id.
   */
  async getStakeholderById(
    requestParameters: GetStakeholderByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<StakeholderDTO> {
    const response = await this.getStakeholderByIdRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns stakeholder objects by ids.
   * Get a stakeholder object by id.
   */
  async getStakeholderByIdsRaw(
    requestParameters: GetStakeholderByIdsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<StakeholderDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/stakeholder/find-many-by-id`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(StakeholderDTOFromJSON));
  }

  /**
   * Returns stakeholder objects by ids.
   * Get a stakeholder object by id.
   */
  async getStakeholderByIds(
    requestParameters: GetStakeholderByIdsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<StakeholderDTO>> {
    const response = await this.getStakeholderByIdsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered Stakeholders
   */
  async listStakeholdersRaw(
    requestParameters: ListStakeholdersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<StakeholderDTO>>> {
    if (requestParameters.status === null || requestParameters.status === undefined) {
      throw new runtime.RequiredError(
        "status",
        "Required parameter requestParameters.status was null or undefined when calling listStakeholders."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/stakeholder`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(StakeholderDTOFromJSON));
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered Stakeholders
   */
  async listStakeholders(
    requestParameters: ListStakeholdersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<StakeholderDTO>> {
    const response = await this.listStakeholdersRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered Stakeholders paginated
   */
  async listStakeholdersPaginatedRaw(
    requestParameters: ListStakeholdersPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<StakeholderPageDTO>> {
    if (requestParameters.status === null || requestParameters.status === undefined) {
      throw new runtime.RequiredError(
        "status",
        "Required parameter requestParameters.status was null or undefined when calling listStakeholdersPaginated."
      );
    }

    if (requestParameters.limit === null || requestParameters.limit === undefined) {
      throw new runtime.RequiredError(
        "limit",
        "Required parameter requestParameters.limit was null or undefined when calling listStakeholdersPaginated."
      );
    }

    if (requestParameters.offset === null || requestParameters.offset === undefined) {
      throw new runtime.RequiredError(
        "offset",
        "Required parameter requestParameters.offset was null or undefined when calling listStakeholdersPaginated."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sortField"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sortDirection"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/stakeholder/paginated`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CustomStakeholderFilterToJSON(requestParameters.customStakeholderFilter)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => StakeholderPageDTOFromJSON(jsonValue));
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered Stakeholders paginated
   */
  async listStakeholdersPaginated(
    requestParameters: ListStakeholdersPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<StakeholderPageDTO> {
    const response = await this.listStakeholdersPaginatedRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the registered Stakeholders
   */
  async saveStakeholdersRaw(
    requestParameters: SaveStakeholdersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ListResponseDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/stakeholder/add-many`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.stakeholderDTO?.map(StakeholderDTOToJSON)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ListResponseDTOFromJSON(jsonValue));
  }

  /**
   * Returns the registered Stakeholders
   */
  async saveStakeholders(
    requestParameters: SaveStakeholdersRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ListResponseDTO> {
    const response = await this.saveStakeholdersRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Takes an existing Stakeholder object, updates it, and returns the Stakeholder.
   * Update an existing Stakeholder.
   */
  async updateStakeholderRaw(
    requestParameters: UpdateStakeholderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<StakeholderDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/stakeholder`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: StakeholderDTOToJSON(requestParameters.stakeholderDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => StakeholderDTOFromJSON(jsonValue));
  }

  /**
   * Takes an existing Stakeholder object, updates it, and returns the Stakeholder.
   * Update an existing Stakeholder.
   */
  async updateStakeholder(
    requestParameters: UpdateStakeholderRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<StakeholderDTO> {
    const response = await this.updateStakeholderRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

/**
 * @export
 */
export const ListStakeholdersStatusEnum = {
  Active: "ACTIVE",
  All: "ALL",
  Inactive: "INACTIVE"
} as const;
export type ListStakeholdersStatusEnum = (typeof ListStakeholdersStatusEnum)[keyof typeof ListStakeholdersStatusEnum];
/**
 * @export
 */
export const ListStakeholdersPaginatedStatusEnum = {
  Active: "ACTIVE",
  All: "ALL",
  Inactive: "INACTIVE"
} as const;
export type ListStakeholdersPaginatedStatusEnum =
  (typeof ListStakeholdersPaginatedStatusEnum)[keyof typeof ListStakeholdersPaginatedStatusEnum];
/**
 * @export
 */
export const ListStakeholdersPaginatedSortFieldEnum = {
  Address: "ADDRESS",
  City: "CITY",
  Code: "CODE",
  Country: "COUNTRY",
  Email: "EMAIL",
  Fiscalid: "FISCALID",
  IsCustomer: "IS_CUSTOMER",
  IsManufacturer: "IS_MANUFACTURER",
  IsOwner: "IS_OWNER",
  IsSupplier: "IS_SUPPLIER",
  Name: "NAME",
  Paymentcurrency: "PAYMENTCURRENCY",
  Paymentterms: "PAYMENTTERMS",
  Phone: "PHONE",
  Postalcode: "POSTALCODE"
} as const;
export type ListStakeholdersPaginatedSortFieldEnum =
  (typeof ListStakeholdersPaginatedSortFieldEnum)[keyof typeof ListStakeholdersPaginatedSortFieldEnum];
/**
 * @export
 */
export const ListStakeholdersPaginatedSortDirectionEnum = {
  Asc: "ASC",
  Desc: "DESC"
} as const;
export type ListStakeholdersPaginatedSortDirectionEnum =
  (typeof ListStakeholdersPaginatedSortDirectionEnum)[keyof typeof ListStakeholdersPaginatedSortDirectionEnum];
