import { DTOConvertibleEntity } from "@domain/model/GenericPage";
import { CompanyRequestDTO, CompanyRequestDTOLicenceEnum, CompanyRequestDTOWarehousePolicyEnum } from "@clients/aggrego-proxy";

export class CompanyCreateDTO extends DTOConvertibleEntity<CompanyRequestDTO, CompanyCreateDTO> {
  public constructor();
  public constructor(obj?: Partial<CompanyCreateDTO>);
  public constructor(obj?: Partial<CompanyCreateDTO>) {
    super();
    obj && Object.assign(this, obj);
  }
  id?: string = "";
  image?: string = "";
  address?: string = "";
  companyName?: string = "";
  tenantName?: string = "";
  username?: string = "";
  password?: string = "";
  email?: string = "";
  firstName?: string = "";
  surname?: string = "";
  licence?: CompanyRequestDTOLicenceEnum = undefined;
  warehousePolicy?: CompanyRequestDTOWarehousePolicyEnum = undefined;
  fiscalId?: string = "";

  public static fromDTO(event: CompanyRequestDTO): CompanyCreateDTO {
    return new CompanyCreateDTO({ ...event });
  }

  public toDTO(): CompanyRequestDTO {
    return { ...this };
  }
}
