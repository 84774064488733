import { useResolve } from "@movicoders/di";
import { useState, useEffect } from "react";
import { PortalTypes } from "@domain/model/PortalTypes";
import { IImpersonationService, ImpersonationService } from "@domain/services/IImpersonationService";

export const useHandlePortal = () => {
  const impersonationService = useResolve<IImpersonationService>(ImpersonationService);

  const [isPortalAggrego, setIsPortalAggrego] = useState<boolean>(false);

  useEffect(() => {
    setIsPortalAggrego(impersonationService.persistedState?.portal === PortalTypes.AGGREGO ? true : false);
  }, [impersonationService.persistedState?.portal]);

  return { isPortalAggrego, impersonationService };
};
