/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SessionResponses
 */
export interface SessionResponses {
    /**
     * 
     * @type {string}
     * @memberof SessionResponses
     */
    status?: SessionResponsesStatusEnum;
}


/**
 * @export
 */
export const SessionResponsesStatusEnum = {
    CorrectSession: 'CORRECT_SESSION',
    CredentialsNotExist: 'CREDENTIALS_NOT_EXIST',
    DifferentSessionActive: 'DIFFERENT_SESSION_ACTIVE'
} as const;
export type SessionResponsesStatusEnum = typeof SessionResponsesStatusEnum[keyof typeof SessionResponsesStatusEnum];


/**
 * Check if a given object implements the SessionResponses interface.
 */
export function instanceOfSessionResponses(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SessionResponsesFromJSON(json: any): SessionResponses {
    return SessionResponsesFromJSONTyped(json, false);
}

export function SessionResponsesFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionResponses {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function SessionResponsesToJSON(value?: SessionResponses | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
    };
}

