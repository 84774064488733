import { DomainPage } from "@movicoders/domain";
import Company from "@domain/model/CompanyAdminBoard";
import { IUserService } from "@domain/services/IUserService";
import { CompanyDTO, FullCompanyItemDTO } from "@clients/aggrego-proxy";
import { IImpersonationService } from "@domain/services/IImpersonationService";
import { CompaniesApi, ListCompanyItemPaginatedRequest } from "@clients/aggrego-proxy/apis/CompaniesApi";
import { CompanyCreateDTO } from "@pages/portal-admin/admin-board/components/stepper-company/company-create-model";

export class CompanyApi {
  constructor(
    private readonly companiesApi: CompaniesApi,
    private readonly impersonationService: IImpersonationService,
    private readonly userService: IUserService
  ) {}

  async get(): Promise<CompanyDTO[]> {
    const apiResult = await this.companiesApi.listCompanies({
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });

    return apiResult;
  }

  async getPaginated(pageCriteria: ListCompanyItemPaginatedRequest): Promise<DomainPage<Company>> {
    return (await this.companiesApi.listCompanyItemPaginated({
      ...pageCriteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    })) as unknown as Promise<DomainPage<Company>>;
  }

  async getById(id: string): Promise<CompanyDTO> {
    const result = await this.companiesApi.getCompanyById({
      id: id,
      fullDTO: true,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });

    return result;
  }

  async getDetails(tenant: string): Promise<FullCompanyItemDTO> {
    const result = await this.companiesApi.getCompanyByTenant({
      tenant: tenant,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });

    return result;
  }

  async create(entity: CompanyCreateDTO): Promise<CompanyCreateDTO> {
    const result = CompanyCreateDTO.fromDTO(
      await this.companiesApi.registerCompany({
        companyRequestDTO: entity,
        xTenantId: ""
      })
    );
    return result;
  }

  async update(entity: FullCompanyItemDTO): Promise<FullCompanyItemDTO> {
    const result = await this.companiesApi.updateCompanyAndTenant({
      fullCompanyItemDTO: entity,
      xTenantId: ""
    });

    return result;
  }

  async remove(id: string): Promise<void> {
    await this.companiesApi.deleteCompany({
      id: id,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }
}
