import { COLORS } from "@theme";
import Icon from "../../../icons/icon";
import { useTranslation } from "react-i18next";
import { Grid, IconButton } from "@mui/material";
import { useHandlePageSelector } from "../../hooks/useHandlePageSelector";
import { DataGridDisplayFooterProps } from "../config/data-grid-display-footer-props";

export const DataGridDisplayPageSelector = (props: DataGridDisplayFooterProps) => {
  const { t } = useTranslation();
  const { firstElement, lastElement, totalPages, handleNextPage, handlePreviousPage, currentPage, totalElements } =
    useHandlePageSelector(props);

  return (
    <Grid item width="auto" display="flex" alignItems="center">
      {t("table.pagination.of", { start: firstElement + "-" + lastElement, end: totalElements })}
      {/** Previous page. */}
      <IconButton id="button-previous-page" disabled={currentPage <= 0} onClick={handlePreviousPage}>
        <Icon icon="chevron-left" color={currentPage <= 0 ? COLORS.gray : COLORS.secondary} />
      </IconButton>
      {/** Next page. */}
      <IconButton id="button-next-page" disabled={currentPage >= totalPages - 1} onClick={handleNextPage}>
        <Icon icon="chevron-right" color={currentPage >= totalPages - 1 ? COLORS.gray : COLORS.secondary} />
      </IconButton>
    </Grid>
  );
};
