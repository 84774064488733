import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import { GridColDef } from "@mui/x-data-grid";
import { Chip, IconButton, Tooltip } from "@mui/material";
import { ProductState } from "@domain/model/ProductState";
import { CustomTooltipCell } from "@components/tooltip/CustomTooltipCell";
import { Product } from "@domain/model/Product";

export const productsColumns = (
  t: (text: string) => string,
  navigate: (id?: string) => void,
  findAbsoluteProductParentFromInside: (clickedProduct: Product) => void,
  dateMediaQuery: boolean,
  lgMediaQuery: boolean,
  hasVersionLabel: boolean
): GridColDef[] => [
  {
    flex: 1.8,
    field: "serialNumber",
    headerName: t("products.serial"),
    disableColumnMenu: true,
    sortable: false,
    minWidth: lgMediaQuery ? undefined : 290,
    renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
  },
  {
    flex: 1,
    field: "materialCode",
    headerName: t("products.material"),
    disableColumnMenu: true,
    sortable: false,
    minWidth: lgMediaQuery ? undefined : 290,
    renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
  },
  {
    flex: 2.5,
    field: "manufactureOrder",
    headerName: t("products.manufacture.order"),
    disableColumnMenu: true,
    minWidth: lgMediaQuery ? undefined : 100,
    sortable: false,
    renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
  },
  {
    flex: 1,
    field: "batch",
    headerName: hasVersionLabel ? t("products.version") : t("products.batch"),
    disableColumnMenu: true,
    sortable: false,
    minWidth: lgMediaQuery ? undefined : 75,
    renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
  },
  {
    flex: 1.5,
    field: "manufacturingDate",
    type: "date",
    headerName: t(dateMediaQuery ? "products.manufacturing.date" : "products.date"),
    disableColumnMenu: true,
    valueGetter: ({ row }) => {
      return row.manufacturingDate
        ? row.manufacturingDate
            ?.toLocaleString(undefined, {
              localeMatcher: "lookup",
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit"
            })
            .replace(",", "")
        : "-";
    },
    minWidth: lgMediaQuery ? undefined : 100,
    renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
  },
  {
    flex: 1,
    field: "state",
    headerName: t("products.state"),
    disableColumnMenu: true,
    renderCell: data => (
      <Chip
        label={t("products.state." + data.row.state.toLowerCase())}
        className={`product-${(data.row.state as string).toLowerCase()}`}
        sx={{ height: "1.5rem", width: "100%" }}
      />
    ),
    minWidth: lgMediaQuery ? undefined : 105
  },
  {
    flex: 0.8,
    field: "actions",
    headerName: t("products.actions"),
    disableColumnMenu: true,
    sortable: false,
    minWidth: lgMediaQuery ? undefined : 85,
    renderCell: data => {
      return (
        <>
          <Tooltip title={t("tooltip.access.hierarchy")}>
            <IconButton onClick={() => navigate(data.row.id)}>
              <Icon icon="focus" color={COLORS.gray} />
            </IconButton>
          </Tooltip>
          {data.row.parents.length > 0 && (
            <Tooltip title={t("tooltip.access.top.hierarchy")}>
              <IconButton onClick={() => findAbsoluteProductParentFromInside(data.row)}>
                <Icon icon="palet" color={COLORS.gray} />
              </IconButton>
            </Tooltip>
          )}
        </>
      );
    }
  }
];

export const productsStatus = {
  fieldName: "state",
  options: [
    {
      name: "table.status.all",
      value: "*",
      default: true
    },
    {
      name: "products.state.ready",
      value: ProductState.READY,
      default: false
    },
    {
      name: "products.state.aggregated",
      value: ProductState.AGGREGATED,
      default: false
    },
    {
      name: "products.state.shipped",
      value: ProductState.SHIPPED,
      default: false
    },
    {
      name: "products.state.destroyed",
      value: ProductState.DESTROYED,
      default: false
    }
  ]
};

export const productsParentsFilter = {
  fieldName: "state",
  options: [
    {
      name: "products.hierarchy.parents.all",
      value: "ALL_PRODUCTS",
      default: true
    },
    {
      name: "products.hierarchy.parents.only",
      value: "ONLY_PARENTS",
      default: false
    }
  ]
};
