import { Scene, Vector2, Raycaster, Intersection, Camera, Vector3, MeshBasicMaterial } from "three";
import { boxExists } from "../../box-finders";
import { useClickControlsHandlers } from "./click-controls-handlers";
import { useResolve } from "@movicoders/di";
import { IEditionStatesStore } from "../../../stores/edition-states-store/IEditionStatesStore";
import { useEditionStatesStore } from "../../../stores/edition-states-store/edition-states-store";
import { useEffect } from "react";
import { IDialogsStatesStore } from "../../../stores/dialogs-states-store/IDialogsStatesStore";
import { useDialogsStatesStore } from "../../../stores/dialogs-states-store/dialogs-states-store";
import { ICommon3DObjectsStore } from "../../../stores/common-3d-objects-store/ICommon3DObjectsStore";
import { useCommon3DObjectsStore } from "../../../stores/common-3d-objects-store/common-3d-objects-store";

export const useMouse3D = (
  scene: Scene,
  camera: Camera,
  setRotationEnabled: (value: boolean) => void
) => {
  const {
    getSelectedMode,
    getSelectionType,
    getModifyingWH,
    checkTemplateTypeById
  } = useResolve<IEditionStatesStore>(useEditionStatesStore);
  const {
    setSelectedBox,
    setBoxDialogOpen,
    getDeleteDialogOpen,
    getAddLocationDialogOpen
  } = useResolve<IDialogsStatesStore>(useDialogsStatesStore);
  const {
    getTilesBeingModified,
    getHighlightMesh
  } = useResolve<ICommon3DObjectsStore>(useCommon3DObjectsStore);
  const {
    startTileCreation,
    startTileMovement,
    deleteTile,
    startTileReduction,
    startTileEdition
  } = useClickControlsHandlers(scene);

  const mousePosition = new Vector2();
  const raycaster = new Raycaster();
  let intersects: Intersection[];

  const handleMouseDown = (e: MouseEvent) => {
    setRotationEnabled(false);
    if (
      getModifyingWH()
      || getAddLocationDialogOpen()
      || (e.target as Node).nodeName !== "CANVAS"
    ) return;

    // Select Tile
    if (e.button === 0 && getSelectedMode() === "VIEW") {
      mousePosition.x = (e.clientX / window.innerWidth) * 2 - 1;
      mousePosition.y = -(e.clientY / window.innerHeight) * 2 + 1;
      raycaster.setFromCamera(mousePosition, camera);
      const rayIntersects = raycaster.intersectObjects(scene.children);
      let found = false;
      rayIntersects.forEach(intersect => {
        if (intersect.object.parent?.name === "tile" && !found) {
          e.stopPropagation();
          if (checkTemplateTypeById("STORAGE", intersect.object.parent.userData.tile.templateId)) {
            setSelectedBox(intersect.object.parent.userData.tile);
            setBoxDialogOpen(true);
          }
          found = true;
        }
      });
    }

    handleEditStart(e);
  }

  const handleEditStart = (e: MouseEvent) => {

    if ((e.target as HTMLElement).nodeName === "CANVAS") {
      const doBoxExists = boxExists(scene, getHighlightMesh());

      switch (true) {
        // Create tile
        case e.button === 0 &&
          doBoxExists[0] === undefined &&
          getSelectedMode() === "CREATE" &&
          (e.clientX > 250 || e.clientY > 300):
          startTileCreation();
          break;

        // Start moving tile
        case e.button === 0 &&
          doBoxExists.length > 0 &&
          getSelectedMode() === "MOVE" &&
          getTilesBeingModified().length === 0:
          startTileMovement();
          break;

        // Delete and Reduce tile/s.
        case getSelectedMode() === "DELETE" &&
          doBoxExists.length > 0 && !getDeleteDialogOpen():
          e.preventDefault();
          // Delete tile/s.
          if (e.button === 0) deleteTile();
          // Reduce tile/s level.
          else if (e.button === 2) startTileReduction();
          break;

        // Start editing tile.
        case e.button === 0 &&
          getSelectedMode() === "EDIT" &&
          doBoxExists.length > 0 &&
          checkTemplateTypeById("STORAGE", doBoxExists[0].userData.tile.templateId):
          e.preventDefault();
          startTileEdition();
          break;
      }
    }
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!["VIEW", "ZONES"].includes(getSelectedMode())) {
      mousePosition.x = (e.clientX / window.innerWidth) * 2 - 1;
      mousePosition.y = -(e.clientY / window.innerHeight) * 2 + 1;
      raycaster.setFromCamera(mousePosition, camera);
      intersects = raycaster.intersectObjects(scene.children);

      intersects.forEach(intersect => {
        if (intersect.object.name === "ground") {
          //Set position of highlightMesh.
          const highlightPos: Vector3 = new Vector3().copy(intersect.point).round();
          highlightPos.x = highlightPos.x === 0 ? 1 : highlightPos.x;
          highlightPos.z = highlightPos.z === 0 ? 1 : highlightPos.z;
          getHighlightMesh().position.set(highlightPos.x, 0, highlightPos.z);

          // Set color of highlightMesh.
          const currentHLMesh = getHighlightMesh();
          const currentHLMaterial = currentHLMesh.material as MeshBasicMaterial;

          if (boxExists(scene, currentHLMesh)[0] === undefined && getSelectedMode() === "CREATE")
            currentHLMaterial.color.setHex(0x00ff00);
          else if (getTilesBeingModified().length > 0 && getSelectedMode() === "MOVE")
            currentHLMaterial.color.setHex(0x0000ff);
          else if (
            boxExists(scene, currentHLMesh)[0] !== undefined &&
            getTilesBeingModified().length === 0 &&
            getSelectedMode() !== "CREATE"
          ) currentHLMaterial.color.setHex(0x00ff00);
          else currentHLMaterial.color.setHex(0xff0000);
        }
      });
    }
  };

  // UseEffect to update the EventListeners.
  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [getSelectedMode(), getModifyingWH(), getTilesBeingModified(), getSelectionType(), getDeleteDialogOpen()]);

};
