import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import { useTranslation } from "react-i18next";
import { EventDTO } from "@clients/aggrego-proxy";
import { HierarchyRecordCard } from "./hierarchy-record-card";
import { hierarchyReportStyles } from "../../hierarchy-report.Styles";
import { Drawer, Grid, IconButton, Theme, Typography, useMediaQuery } from "@mui/material";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";

export const HierarchyRecord = (props: {
  open: boolean;
  onClickDrawerButton: () => void;
  onClose: () => void;
  data: EventDTO[];
}) => {
  const { t } = useTranslation();
  const styles = hierarchyReportStyles();
  const matchesSM = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"), { noSsr: true });
  const { open, onClose, data, onClickDrawerButton } = props;
  const responsivePaperStyles = matchesSM ? styles.hierarchy_record_paper_right : styles.hierarchy_record_paper_bottom;

  return (
    <Drawer
      anchor="right"
      variant="persistent"
      open
      PaperProps={{
        id: "record-paper",
        sx: {
          ...responsivePaperStyles,
          width: matchesSM ? (open ? "auto" : "4rem") : "100vw",
          height: matchesSM ? "79vh" : open ? "100vh" : "3.25rem"
        }
      }}
      onClose={onClose}>
      {/* Header */}
      {(matchesSM || (!matchesSM && open)) && (
        <Grid container sx={styles.hierarchy_record_header} spacing={1.25}>
          <Grid item>
            <Icon icon="history" size={24} color={COLORS.primary} />
          </Grid>
          {open && (
            <Grid item>
              <Typography variant="product_history_title">{t("products.history")}</Typography>
            </Grid>
          )}
        </Grid>
      )}

      {/* Content */}
      {(matchesSM || (!matchesSM && open)) && (
        <Timeline sx={styles.hierarchy_record_timeline}>
          {data &&
            Array.from(data)
              ?.sort((aEvent, bEvent) => {
                const dateA = new Date(bEvent.eventDate ?? new Date(0)).getTime();
                const dateB = new Date(aEvent.eventDate ?? new Date(0)).getTime();
                if (dateA !== dateB) return dateA - dateB;

                if (aEvent.action === "AGGREGATE" && bEvent.action === "CREATE") return -1;
                if (aEvent.action === "CREATE" && bEvent.action === "AGGREGATE") return 1;

                return 0;
              })
              .map((item, index) => {
                return (
                  <TimelineItem key={item.id} sx={{ height: "fit-content" }}>
                    <TimelineSeparator sx={{ mt: index === 0 ? 3.5 : 0, mb: index === data?.length - 1 ? 5 : 0 }}>
                      {index !== 0 && <TimelineConnector />}
                      <TimelineDot />
                      {index !== data?.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                      {open ? <HierarchyRecordCard event={item} /> : <div style={{ height: "8rem" }} />}
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
        </Timeline>
      )}

      {/* FOOTER */}
      <Grid container sx={styles.hierarchy_record_footer}>
        <IconButton
          id="timeline-open"
          size="large"
          aria-label="open timeline"
          sx={{ color: COLORS.secondary }}
          onClick={onClickDrawerButton}>
          {!open ? (
            matchesSM ? (
              <Icon icon="chevron-left" />
            ) : (
              <Icon icon="chevron-up" />
            )
          ) : matchesSM ? (
            <Icon icon="chevron-right" />
          ) : (
            <Icon icon="chevron-down" />
          )}
        </IconButton>
        {!matchesSM && !open && <Icon icon="history" size={24} color={COLORS.primary} />}
      </Grid>
    </Drawer>
  );
};
