import { useResolve } from "@movicoders/di";
import { useEffect, useRef, useState } from "react";
import { IRepositoryPaginated } from "@movicoders/domain";
import { InvRegistryDTO } from "@domain/model/InvRegistry";
import { LastPageState } from "@domain/model/LastPageState";
import { ObjectType } from "@movicoders/ui/lib/ViewModels/types";
import { useListDetailPaginatedViewModel } from "@movicoders/ui";
import { IUserService, UserService } from "@domain/services/IUserService";
import { useHandleSelectedWarehouse } from "@hooks/useHandleSelectedWarehouse";
import { ILastPageService, LastPageService } from "@domain/services/ILastPageService";
import { InventoryRegistryRepository } from "@infrastructure/repositories/inventory-registry-repository";
import { FullUserDTOCompanyLicenseEnum, GetInventoryRegistryPaginatedByFiltersRequest } from "@clients/aggrego-proxy";

export const useInventoryRegistryHandleData = () => {
  const {
    data: inventoryRegistries,
    loading,
    getPaginated
  } = useListDetailPaginatedViewModel(
    InventoryRegistryRepository as unknown as ObjectType<
      IRepositoryPaginated<InvRegistryDTO, GetInventoryRegistryPaginatedByFiltersRequest>
    >
  );
  const getInvRegistryPaginated = useRef(getPaginated).current;

  const currentPageService = useResolve<ILastPageService>(LastPageService);
  const currentPagePersistedState = currentPageService.persistedState ?? new LastPageState();
  const currentUserPersistedState = useResolve<IUserService>(UserService);

  const [columnsByMode, setColumnsByMode] = useState<string[]>([]);
  const { selectedWarehouseCode } = useHandleSelectedWarehouse();

  useEffect(() => {
    generateColumns();
  }, []);

  const generateColumns = () => {
    switch (currentUserPersistedState.user?.companyLicense) {
      case FullUserDTOCompanyLicenseEnum.Im:
        setColumnsByMode(["id", "containerCode", "locationCode"]);
        break;
      case FullUserDTOCompanyLicenseEnum.Wm:
        setColumnsByMode(["id", "containerCode"]);
        break;
      case FullUserDTOCompanyLicenseEnum.WmWithContainer:
        setColumnsByMode(["id"]);
        break;
    }
  };

  return {
    currentPageService,
    currentPagePersistedState,
    selectedWarehouseCode,
    inventoryRegistries,
    columnsByMode,
    loading,
    getInvRegistryPaginated
  };
};
