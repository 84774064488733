// Mas información en https://www.npmjs.com/package/react-icomoon
import IcoMoon, { IconProps } from "react-icomoon";
import iconSet from "./selection.json";

export type Icons = typeof iconSet;

const Icon = (props: IconProps) => (
  <IcoMoon data-testid={props.icon} size={24} iconSet={iconSet} {...props} />
);

export default Icon;
