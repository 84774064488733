import { useTranslation } from "react-i18next";
import Stakeholder from "@domain/model/Stakeholder";
import { Button, Grid, Typography } from "@mui/material";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";
import { StakeholderDTOPaymentCurrencyEnum, StakeholderDTOPaymentTermsEnum } from "@clients/aggrego-proxy";

export const StakeholderDetail = (props: {
  open: boolean;
  onClose: () => void;
  stakeholder: Stakeholder;
  stakeholderType: string;
}) => {
  const { open, onClose, stakeholder, stakeholderType } = props;

  const { t } = useTranslation();

  const getCurrency = (currency: StakeholderDTOPaymentCurrencyEnum) => {
    if (currency === StakeholderDTOPaymentCurrencyEnum.Dollar) {
      return t("currency.dollar");
    } else if (currency === StakeholderDTOPaymentCurrencyEnum.Euro) {
      return t("currency.euro");
    } else {
      return t("currency.pound");
    }
  };

  const getPayment = (mode: StakeholderDTOPaymentTermsEnum) => {
    if (mode === StakeholderDTOPaymentTermsEnum.Net30) {
      return t("payment.method.30");
    } else if (mode === StakeholderDTOPaymentTermsEnum.Net60) {
      return t("payment.method.60");
    } else {
      return t("payment.method.90");
    }
  };

  const renderInput = (name: string, value: string) => {
    return (
      <Grid item xs={12} sx={{ mt: "0.25rem" }}>
        <div className="title-content" data-cy={`material-card-${name}-container`}>
          <Typography variant="cardlabel" display="block">
            {t(name)}
          </Typography>
          <Typography
            data-cy="value"
            variant="cardvalue"
            whiteSpace="pre-line"
            marginBottom="1rem"
            sx={{ wordBreak: "keep-all" }}>
            {value !== "" ? value : "-"}
          </Typography>
        </div>
      </Grid>
    );
  };

  const getFullAddress = () => {
    const stakeholderCityAndCountry = stakeholder.city && stakeholder.country;
    if (stakeholder.address === "" && stakeholder.city === "" && stakeholder.country === "" && stakeholder.postalCode === "") {
      return "-";
    } else {
      return `${stakeholder?.address ? stakeholder.address + "\n" : ""}${
        stakeholder.postalCode ? stakeholder.postalCode + "\n" : ""
      }${stakeholder.city ? stakeholder.city : ""}${stakeholderCityAndCountry ? "," : ""} ${
        stakeholder?.country ? t(stakeholder.country) : ""
      }`;
    }
  };

  return open ? (
    <>
      <AggregoDialog title={t(`${stakeholderType}.loupe`) + stakeholder.code} onClose={onClose}>
        <>
          <div>
            {renderInput("stakeholders.name", stakeholder?.name ?? "")}
            <Grid container>
              <Grid item xs={7} sx={{ mt: "0.25rem" }}>
                {renderInput("stakeholders.fiscalId", stakeholder?.fiscalId ?? "")}
              </Grid>
              <Grid item xs={5} sx={{ mt: "0.25rem" }}>
                {renderInput("stakeholders.contact", stakeholder?.name ?? "")}
              </Grid>
            </Grid>
            {renderInput("stakeholders.address", getFullAddress())}
            <Grid container>
              <Grid item xs={7} sx={{ mt: "0.25rem" }}>
                {renderInput("stakeholders.email", stakeholder?.email ?? "")}
              </Grid>
              <Grid item xs={5} sx={{ mt: "0.25rem" }}>
                {renderInput("stakeholders.phone", stakeholder?.phone ?? "")}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={7} sx={{ mt: "0.25rem" }}>
                {renderInput(
                  "stakeholders.paymentTerms",
                  getPayment(stakeholder?.paymentTerms ?? StakeholderDTOPaymentTermsEnum.Net30)
                )}
              </Grid>
              <Grid item xs={5} sx={{ mt: "0.25rem" }}>
                {renderInput(
                  "stakeholders.paymentCurrency",
                  getCurrency(stakeholder.paymentCurrency ?? StakeholderDTOPaymentCurrencyEnum.Euro)
                )}
              </Grid>
            </Grid>
            {renderInput("stakeholders.comments", stakeholder?.comment ?? "")}
          </div>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", mt: "0.25rem" }}>
            <Button variant="secondary" onClick={onClose} id="close-stock-button">
              {t("button.close")}
            </Button>
          </Grid>
        </>
      </AggregoDialog>
    </>
  ) : (
    <></>
  );
};
