import { COLORS } from "@theme";
import Zone from "@domain/model/Zone";
import { useEffect, useState } from "react";
import { useHandleSelectedWarehouse } from "@hooks/useHandleSelectedWarehouse";

export const useZoneForm = (data: Zone) => {
  const INITIAL_ERROR_MAP = new Map([["zone-name", false]]);

  const [zone, setZone] = useState<Zone>({
    ...data,
    color: data.color === undefined || data.color === "" ? COLORS.primary : data.color
  });
  const { selectedWarehouse } = useHandleSelectedWarehouse();
  const [formErrors, setFormErrors] = useState<Map<string, boolean>>(INITIAL_ERROR_MAP);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZone({
      ...zone,
      [event.target.name]: event.target.value,
      tiles: [],
      warehouseId: selectedWarehouse
    });
  };

  const onChangeColor = (color: string) => {
    setTimeout(() => {
      setZone({ ...zone, color: color });
    }, 100);
  };

  useEffect(() => {
    setZone({ ...data, color: data.color === undefined || data.color === "" ? COLORS.primary : data.color });
    setFormErrors(INITIAL_ERROR_MAP);
  }, [data]);

  const validateFormValues = () => {
    const nameValid = zone.name !== null && zone.name !== undefined && zone.name !== "";

    setFormErrors(new Map([["zone-name", !nameValid]]));
    return nameValid;
  };

  return {
    onChange,
    onChangeColor,
    zone,
    formErrors,
    validateFormValues
  };
};
