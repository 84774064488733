import { Paper, Button, Grid, Accordion, AccordionSummary, AccordionDetails, Divider, List, ListItem, Typography, IconButton } from "@mui/material";
import { CAPACITY_COLORS } from "../../../constants/Colors";
import LinearProgress from "@mui/material/LinearProgress";
import { getShorterBinCode } from "../../../utils/bin-helper";
import { useTranslation } from "react-i18next";
import { useBoxDialogViewmodel } from "./useBoxDialogViewmodel";
import { boxDialogStyles } from "./box-dialog.styles";
import { COLORS } from "../../../../../../theme";
import Icon from "../../../../../components/icons/icon";

const getColorByPercentage = (percentage: number): string => {
  if (percentage === 0)
    return CAPACITY_COLORS.green
  else if (percentage < 100)
    return CAPACITY_COLORS.yellow

  return CAPACITY_COLORS.red;
};

export const BoxDialog = () => {
  const { t } = useTranslation();
  const {
    expanded,
    tile,
    orderedLocations,
    getTotalMaterials,
    handleChange,
    handleCloseDialog,
    handleNavigateToDetails,
    getBoxDialogOpen,
    handleStartLocationCreation,
    getEmptyLevels
  } = useBoxDialogViewmodel();

  const { levelIconButtonStyles, getContainerStyles, getLinearProgressStyles } = boxDialogStyles();

  const levels = Array.from(new Set(orderedLocations.map(loc => loc.level ?? -1)));
  // Cant use findLast https://github.com/microsoft/TypeScript/issues/48829
  const firstOfTheLevels = levels.map(level => [...orderedLocations].reverse().find(loc => loc.level === level)?.id);

  return (
    <Paper sx={getContainerStyles(getBoxDialogOpen())}>
      <p>
        {t("viewer.tile.id")} {tile?.id ?? ""}
      </p>
      <p style={{ overflowWrap: "anywhere" }}>
        {t("viewer.tile.code")} {tile?.code ?? ""}
      </p>
      <p>
        {t("viewer.tile.x")} {tile?.x ?? ""} <br /> {t("viewer.tile.y")} {tile?.z ?? ""}
      </p>
      <p>{t("viewer.tile.bins")} </p>
      <Grid container flexDirection="column-reverse" rowSpacing={2}>
        {getEmptyLevels(levels).map(lvl => (
          <Grid container item mb="0.7rem" justifyContent="space-between" maxWidth="17vw" key={lvl}>
            <Typography fontWeight={600} color={COLORS.secondary} fontSize="1rem"> Level: {lvl} </Typography>
            <IconButton sx={levelIconButtonStyles} onClick={() => handleStartLocationCreation(lvl)}>
              <Icon icon="plus" color={COLORS.white} />
            </IconButton>
          </Grid>
        ))}

        {orderedLocations
          .filter(b => b.active === true)
          .map((bin) => {
            const fillPercentage = ((bin.containers?.length ?? 0) / (bin.capacity ?? 1)) * 100;
            const color = getColorByPercentage(fillPercentage);
            const totalMaterials = getTotalMaterials(bin.containers ?? []);

            return (
              <Grid item key={bin.id}>
                {firstOfTheLevels.includes(bin.id) &&
                  <Grid container mb="0.7rem" justifyContent="space-between" maxWidth="17vw">
                    <Typography fontWeight={600} color={COLORS.secondary} fontSize="1rem"> Level: {bin.level} </Typography>
                    <IconButton sx={levelIconButtonStyles} onClick={() => handleStartLocationCreation(bin.level)}>
                      <Icon icon="plus" color={COLORS.white} />
                    </IconButton>
                  </Grid>
                }
                <Accordion
                  TransitionProps={{ unmountOnExit: true }}
                  expanded={expanded === bin.id}
                  onChange={handleChange(bin)}
                  sx={{ maxWidth: "17vw" }}>
                  <AccordionSummary>
                    <Grid container direction="column" fontSize="small" color={color}>
                      {t("viewer.bin.code")} {getShorterBinCode(bin, 38)} <br />
                      {t("viewer.bin.level")} {bin.level} <br />
                      {isNaN(fillPercentage) && <>{t("viewer.details.capacity.undefined")}</>}
                      {!isNaN(fillPercentage) && (
                        <>
                          {t("viewer.bin.percentage", { percentage: Math.round(fillPercentage * 100) / 100 })}
                          <LinearProgress sx={getLinearProgressStyles(color)}
                            variant="determinate" value={fillPercentage} />
                        </>
                      )}
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      {Array.from(totalMaterials).map(([key, value], i) => {
                        return (
                          <div key={key}>
                            {i !== 0 && <Divider />}
                            <ListItem sx={{ justifyContent: "space-around" }}>
                              <Typography>{key}</Typography>
                              <Typography>{value}</Typography>
                            </ListItem>
                          </div>
                        );
                      })}
                    </List>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            );
          })}
      </Grid>
      <Grid container my={2} justifyContent="space-between">
        <Button color="secondary" size="small" onClick={handleCloseDialog}>
          {t("viewer.details.close")}
        </Button>
        <Button color="secondary" size="small" onClick={handleNavigateToDetails}>
          {t("viewer.go.to.details")}
        </Button>
      </Grid>
    </Paper>
  );
};
