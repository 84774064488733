import { TileDTO } from "../../../../clients/aggrego-proxy";
import { Location3D, location3DFromDTO, locationDTOFrom3D } from "./Location3D";

export type Tile3D = {
  id?: string;
  code?: string;
  x?: number;
  z?: number;
  maxY?: number;
  warehouseId?: string;
  templateId?: string;
  locations?: Array<Location3D>;
  zoneId?: string;
  active?: boolean;
};

export const tile3DFromDTO = (tile: TileDTO): Tile3D => {
  return {
    ...tile,
    locations: tile.locations?.map(location => location3DFromDTO(location, tile.id)),
    maxY: Math.max(...(tile.locations?.filter(l => l.active).map(l => l.level ?? 0) ?? []), 1)
  };
};

export const tileDTOFrom3D = (tile: Tile3D): TileDTO => {
  return {
    ...tile,
    locations: tile.locations?.map(location => locationDTOFrom3D(location, tile.id))
  };
};
