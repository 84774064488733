import { useEffect } from "react";
import { Mesh, Scene } from "three";
import { END_MODIFYING_OPTIONS } from "../constants/end-modifying-options";
import { boxExistsIn } from "../utils/box-finders";
import { useResolve } from "@movicoders/di";
import { useEndModifyingHandlers } from "../utils/end-modifying-handlers";
import { IEditionStatesStore } from "../stores/edition-states-store/IEditionStatesStore";
import { useEditionStatesStore } from "../stores/edition-states-store/edition-states-store";
import { IDialogsStatesStore } from "../stores/dialogs-states-store/IDialogsStatesStore";
import { useDialogsStatesStore } from "../stores/dialogs-states-store/dialogs-states-store";
import { ICommon3DObjectsStore } from "../stores/common-3d-objects-store/ICommon3DObjectsStore";
import { useCommon3DObjectsStore } from "../stores/common-3d-objects-store/common-3d-objects-store";
import { ZoneRepository } from "../../../../infrastructure/repositories/zone-repository";
import Zone from "../../../../domain/model/Zone";
import { IZonesEdition3DStore } from "../stores/zones-edition-3d-store/IZonesEdition3DStore";
import { useZonesEdition3DStore } from "../stores/zones-edition-3d-store/zones-edition-3d-store";
import { useSnackbar } from "@movicoders/ui";
import { useTranslation } from "react-i18next";
import { IWarehouse3DStore } from "../stores/warehouse-3d-store/IWarehouse3DStore";
import { useWarehouse3DStore } from "../stores/warehouse-3d-store/warehouse-3d-store";

export const use3DViewerViewModel = (scene: Scene) => {
  const {
    getSelectedMode,
    getSelectedTileTemplate,
    getModifyMaxLevel,
    setModifyMaxLevel,
    getEndModifyingWH,
    setEndModifyingWH,
    setModifyingWH
  } = useResolve<IEditionStatesStore>(useEditionStatesStore);
  const { setBoxDialogOpen, setSelectedBox } = useResolve<IDialogsStatesStore>(useDialogsStatesStore);
  const {
    getTilesBeingModified,
    setTilesBeingModified,
    getPivotControlsContent,
    getStartPoint,
    setStartPoint,
    getEndPoint,
    setEndPoint
  } = useResolve<ICommon3DObjectsStore>(useCommon3DObjectsStore);
  const {
    handleCreateVariousTiles,
    handleCreateVariousTemplates,
    handleCreateOneTile,
    handleCreateOneTemplate,
    handlePlaceMovingTiles,
    handleCancelOperation,
    handleApplyDeletion,
    handleApplyEdit
  } = useEndModifyingHandlers(scene);

  const zoneRepository = useResolve<ZoneRepository>(ZoneRepository);
  const { setZones } = useResolve<IZonesEdition3DStore>(useZonesEdition3DStore);
  const { getWarehouse } = useResolve<IWarehouse3DStore>(useWarehouse3DStore);
  const { show } = useSnackbar();
  const { t } = useTranslation();

  // Undo unsaved changes when the mode is changed.
  const handleModeChange = () => {
    if (getTilesBeingModified().length > 0) {
      getTilesBeingModified().forEach(tile => {
        if (tile?.userData?.firstPosition !== undefined) {
          tile.position.set(tile.userData.firstPosition[0], tile.position.y, tile.userData.firstPosition[1]);
        }
      });
    }
    if (getPivotControlsContent().children)
      getPivotControlsContent().children = [];

    scene.remove(scene.getObjectByName("currentLevel") ?? new Mesh());
    scene.remove(scene.getObjectByName("tempEdit") ?? new Mesh());
    scene.remove(scene.getObjectByName("tempReduce") ?? new Mesh());
    scene.remove(scene.getObjectByName("createStart") ?? new Mesh());
    scene.remove(scene.getObjectByName("createEnd") ?? new Mesh());
    scene.remove(scene.getObjectByName("temp") ?? new Mesh());
    scene.remove(scene.getObjectByName("tempTemplates") ?? new Mesh());
    setTilesBeingModified([]);
    setModifyMaxLevel(0);
    setStartPoint(undefined);
    setEndPoint(undefined);
    setEndModifyingWH("");
    setModifyingWH(false);
  };

  // Handle the save and cancel events.
  const handleEndModifying = () => {
    const startPoint = getStartPoint();
    const endPoint = getEndPoint();

    // Zones end modifying handler has its own hook.
    if (getSelectedMode() === "ZONES") return;

    switch (true) {
      //Create various tiles
      case getSelectedMode() === "CREATE" &&
        startPoint && endPoint &&
        getEndModifyingWH() === END_MODIFYING_OPTIONS.SAVE &&
        scene?.getObjectByName("temp") !== undefined:
        handleCreateVariousTiles();
        break;
      // Create various templates
      case getSelectedMode() === "CREATE" &&
        startPoint && endPoint &&
        getEndModifyingWH() === END_MODIFYING_OPTIONS.SAVE &&
        scene?.getObjectByName("tempTemplates") !== undefined:
        handleCreateVariousTemplates();
        break;
      //Create only one tile.
      case getSelectedMode() === "CREATE" &&
        startPoint && endPoint &&
        getEndModifyingWH() === END_MODIFYING_OPTIONS.SAVE &&
        (startPoint.position.x === endPoint.position.x || startPoint.position.z === endPoint.position.z) &&
        boxExistsIn(startPoint.position.x, endPoint.position.x, startPoint.position.z, endPoint.position.z, scene).length <= 0 &&
        getSelectedTileTemplate() === "STORAGE":
        handleCreateOneTile();
        break;
      //Create only one template.
      case getSelectedMode() === "CREATE" &&
        startPoint && endPoint &&
        getEndModifyingWH() === END_MODIFYING_OPTIONS.SAVE &&
        (startPoint.position.x === endPoint.position.x || startPoint.position.z === endPoint.position.z) &&
        boxExistsIn(startPoint.position.x, endPoint.position.x, startPoint.position.z, endPoint.position.z, scene).length <= 0:
        handleCreateOneTemplate();
        break;
      // Place moving tile.
      case getSelectedMode() === "MOVE" && getEndModifyingWH() === END_MODIFYING_OPTIONS.SAVE && getTilesBeingModified().length > 0:
        handlePlaceMovingTiles();
        break;
      //Cancel the reduction of tiles if nothing has changed.
      case getSelectedMode() === "DELETE" && endPoint !== undefined && getModifyMaxLevel() === endPoint.position.y + 1:
        handleCancelOperation();
        break;
      //Open delete dialog to save the changes.
      case getSelectedMode() === "DELETE" && getEndModifyingWH() === END_MODIFYING_OPTIONS.SAVE && endPoint !== undefined:
        handleApplyDeletion();
        break;
      //Cancel the edit if nothing has changed.
      case getSelectedMode() === "EDIT" && endPoint !== undefined && scene.getObjectByName("tempEdit") === undefined:
        handleCancelOperation();
        break;
      //Apply edit changes.
      case getSelectedMode() === "EDIT" && getEndModifyingWH() === "SAVE":
        handleApplyEdit();
        break;
      //Cancel any operation.
      case getEndModifyingWH() === END_MODIFYING_OPTIONS.CANCEL:
        handleCancelOperation();
        break;
      default:
        if (getEndModifyingWH() === END_MODIFYING_OPTIONS.SAVE &&
          scene.getObjectByName("tempEdit") === undefined &&
          scene?.getObjectByName("tempTemplates") === undefined &&
          scene?.getObjectByName("temp") === undefined
        ) {
          handleCancelOperation();
          show(t("viewer.save.error.code") + ": " + t("viewer.save.error"), "error");
        }
        break;
    }
  };

  // UseEffect to remove unsaved changes when changing mode.
  useEffect(() => {
    setBoxDialogOpen(false);
    setSelectedBox(undefined);
    handleModeChange();
    if (getSelectedMode() === "ZONES") {
      zoneRepository.getByWarehouseId({ warehouseId: getWarehouse().id ?? "" })
        .then((res) => setZones(res.map(Zone.fromDTO)))
        .catch(() => show(t("zones.error.fetching"), "error"));
    }
  }, [getSelectedMode()]);

  // UseEffect to finish or cancel the creation of tiles.
  useEffect(() => {
    getEndModifyingWH() !== "" && handleEndModifying();
  }, [getEndModifyingWH(), getStartPoint(), getEndPoint()]);
};
