import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Dialog, DialogActions, DialogTitle, Grid, IconButton, Typography } from "@mui/material"
import { COLORS } from "../../../../../../theme";
import Icon from "../../../../../components/icons/icon";
import { useTranslation } from "react-i18next";
import { useReplaceOrKeepEditDialog } from "./useReplaceOrKeepEditDialog";

export const ReplaceOrKeepEditDialog3D = () => {

  const { t } = useTranslation();
  const {
    getIsOpen,
    handleClose,
    handleChangeKeep,
    comparedTiles,
    expandedItem,
    handleChangeExpanded,
    handleSubmit
  } = useReplaceOrKeepEditDialog();

  return (
    <Dialog open={getIsOpen()} maxWidth="sm" className="save-dialog" onClose={handleClose} >
      <DialogTitle>
        <Typography fontSize="1.125rem" fontWeight={600} textOverflow="ellipsis" overflow="hidden">
          The next locations could not be created
        </Typography>
        <IconButton onClick={handleClose}>
          <Icon icon="close" color={COLORS.white} />
        </IconButton>
      </DialogTitle>
      <Grid container flexDirection="column" rowSpacing={1} wrap="nowrap" sx={{ p: "1rem", pt: 0, mt: 0, overflow: "scroll" }} >
        {comparedTiles.filter(ct => !ct.error).length > 0 && <Typography paddingTop="1rem">
          Disabled locations have been found in the next positions:
        </Typography>}
        {comparedTiles.map(comparedTile => (
          <Grid item key={`${comparedTile.tilesPos.x}${comparedTile.tilesPos.z}`}>
            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              expanded={expandedItem.x === comparedTile.tilesPos.x && expandedItem.z === comparedTile.tilesPos.z}>
              <AccordionSummary sx={{ padding: "0 1rem" }}>
                <IconButton onClick={() => handleChangeExpanded(comparedTile.tilesPos)}>
                  <Icon icon={expandedItem.x === comparedTile.tilesPos.x && expandedItem.z === comparedTile.tilesPos.z ? "chevron-down" : "chevron-right"} />
                </IconButton>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Typography>x: {comparedTile.tilesPos.x}, z: {comparedTile.tilesPos.z}</Typography>
                  {comparedTile.error ?
                    <Icon color={COLORS.alert} icon="warning" />
                    :
                    <Grid item display="flex" flexDirection="row" alignItems="center">
                      <Typography>Re-Enable old locations? </Typography>
                      <Checkbox checked={comparedTile.keep} size="small" color="secondary" onChange={() => handleChangeKeep(comparedTile.tilesPos)} />
                    </Grid>}
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                {comparedTile.error ?
                  <Grid>
                    <Typography>An unexpected error ocurred while creating the location...</Typography>
                  </Grid>
                  :
                  <Grid container display="flex" flexDirection="row" justifyContent="space-between">
                    <Grid item xs={6}>
                      <Typography>Old Locations: </Typography>
                      {comparedTile.oldTileLocations.map(oldLoc => <Typography key={oldLoc?.code}>{oldLoc.code}</Typography>)}
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>New Locations: </Typography>
                      {comparedTile.newTileLocations.map(newLoc => <Typography key={newLoc?.code}>{newLoc.code}</Typography>)}
                    </Grid>
                  </Grid>}
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
      <DialogActions sx={{ justifyContent: "space-between", padding: "1rem" }}>
        <Button onClick={handleClose}>{t("root.cancel")}</Button>
        <Button onClick={handleSubmit}>{t("root.create")}</Button>
      </DialogActions>
    </Dialog >
  )
};
