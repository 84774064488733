import {
  EventsApi,
  ContainersApi,
  ReportsApi,
  ProductsApi,
  FamiliesApi,
  FamilyTypesApi,
  CompaniesApi,
  LocationsApi,
  WarehousesApi,
  MaterialsApi,
  ZonesApi,
  TilesApi,
  TileTemplatesApi,
  LoginApi,
  UsersApi,
  MediaApi,
  OperatorsApi,
  StakeholdersApi
} from "./clients/aggrego-proxy";
import { SNACKBAR, SnackbarService, useSnackbar } from "@movicoders/ui/lib/Snackbar";
import "./di.decorate";
import { Container } from "@movicoders/di";
import { IUserService, UserService } from "./domain/services/IUserService";
import { useUserService } from "./infrastructure/services/user-service";
import { LoginUseCase } from "./application/login/login-use-case";
import { RecoverPasswordUseCase } from "./application/recover-password/recover-password-use-case";
import { DrawerService, IDrawerService } from "./domain/services/IDrawerService";
import { useDrawerService } from "./infrastructure/services/useDrawerService";
import { CompanyRepository } from "./infrastructure/repositories/company-repository";
import { CompanyApi } from "./infrastructure/api/company-api";
import { LocationApi } from "./infrastructure/api/location-api";
import { LocationRepository } from "./infrastructure/repositories/location-repository";
import { WarehouseRepository } from "./infrastructure/repositories/warehouse-repository";
import { WarehouseApi } from "./infrastructure/api/warehouse-api";
import { MaterialRepository } from "./infrastructure/repositories/material-repository";
import { MaterialApi } from "./infrastructure/api/material-api";
import { FamilyRepository } from "./infrastructure/repositories/family-repository";
import { FamilyApi } from "./infrastructure/api/family-api";
import { ZoneRepository } from "./infrastructure/repositories/zone-repository";
import { ZoneApi } from "./infrastructure/api/zone-api";
import { TypeOfFamilyRepository } from "./infrastructure/repositories/type-of-families-repository";
import { TypeOfFamilyApi } from "./infrastructure/api/type-of-families-api";
import { ITableService, TableService } from "./domain/services/ITableService";
import { useTableService } from "./infrastructure/services/useTableService";
import { ProductApi } from "./infrastructure/api/product-api";
import { ProductRepository } from "./infrastructure/repositories/product-repository";
import { UserRepository } from "./infrastructure/repositories/user-repository";
import { CompanyUpdateStatusUseCase } from "./application/master-data/companies/company-update-status-use-case";
import { FamilyUpdateStatusUseCase } from "./application/master-data/families/family-update-status-use-case";
import { LocationsCreateUseCase } from "./application/master-data/locations/locations-create-use-case";
import { LocationUpdateStatusUseCase } from "./application/master-data/locations/location-update-status-use-case";
import { MaterialUpdateStatusUseCase } from "./application/master-data/materials/material-update-status-use-case";
import { TypeOfFamilyUpdateStatusUseCase } from "./application/master-data/type-of-families/type-of-family-update-status-use-case";
import { WarehouseUpdateStatusUseCase } from "./application/master-data/warehouses/warehouse-update-status-use-case";
import { ZoneUpdateStatusUseCase } from "./application/master-data/zones/zone-update-status-use-case";
import { ILastPageService, LastPageService } from "./domain/services/ILastPageService";
import { AggregoProxyApiConfiguration } from "./infrastructure/api/management-token/AggregoProxyApiConfiguration";
import { TileApi } from "./infrastructure/api/tile-api";
import { TileTemplateApi } from "./infrastructure/api/tile-template-api";
import { UserApi } from "./infrastructure/api/user-api";
import { TileRepository } from "./infrastructure/repositories/tile-repository";
import { TileTemplateRepository } from "./infrastructure/repositories/tile-template-repository";
import { useLastPageService } from "./infrastructure/services/useLastPageService";
import { ResetPasswordUseCase } from "./application/reset-password/reset-password-use-case";
import { LogoutUseCase } from "./application/logout/logout-use-case";
import { HierarchyService, IHierarchyService } from "./domain/services/IHierarchyService";
import { useHierarchyService } from "./infrastructure/services/useHierarchyService";
import { FamilyCreateUseCase } from "./application/master-data/families/create-familiy-use-case";
import { IImpersonationService, ImpersonationService } from "./domain/services/IImpersonationService";
import { useImpersonationService } from "./infrastructure/services/useImpersonationService";
import { IsValidImpersonationUseCase } from "./application/impersonation/is-valid-impersonation-use-case";
import { CompanyUpdateUseCase } from "./application/master-data/companies/company-update-use-case";
import { EventRepository } from "./infrastructure/repositories/event-repository";
import { InventoryRegistryRepository } from "./infrastructure/repositories/inventory-registry-repository";
import { InventoryPeriodRepository } from "./infrastructure/repositories/inventory-period-repository";
import { WarehouseStatusRepository } from "./infrastructure/repositories/warehouse-status-repository";
import { ReportApi } from "./infrastructure/api/report-api";
import { ContainerApi } from "./infrastructure/api/container-api";
import { InventoryRegistryUseCase } from "./application/inventory-registry/inventory-registry-create-use-case";
import { InventoryReportRepository } from "./infrastructure/repositories/inventory-report-repository";
import { MovementsRepository } from "./infrastructure/repositories/movements-repository";
import { StockRepository } from "./infrastructure/repositories/stock-repository";
import { StockReportUseCase } from "./application/stock-report/stock-report-get-latest-use-case";
import { WarehouseByTenantUseCase } from "./application/warehouse-by-tenant/warehouse-by-tenant-use-case";
import { ContainerRepository } from "./infrastructure/repositories/container-repository";
import { WarehouseGetByIdUseCase } from "./application/master-data/warehouses/warehouse-get-by-id-use-case";
import { TileGetByIdUseCase } from "./application/master-data/tiles/tile-get-by-id-use-case";
import { IEditionStatesStore } from "./ui/pages/3d-viewer/stores/edition-states-store/IEditionStatesStore";
import { useEditionStatesStore } from "./ui/pages/3d-viewer/stores/edition-states-store/edition-states-store";
import { IDialogsStatesStore } from "./ui/pages/3d-viewer/stores/dialogs-states-store/IDialogsStatesStore";
import { useDialogsStatesStore } from "./ui/pages/3d-viewer/stores/dialogs-states-store/dialogs-states-store";
import { ICommon3DObjectsStore } from "./ui/pages/3d-viewer/stores/common-3d-objects-store/ICommon3DObjectsStore";
import { useCommon3DObjectsStore } from "./ui/pages/3d-viewer/stores/common-3d-objects-store/common-3d-objects-store";
import { MediaRepository } from "./infrastructure/repositories/media-repository";
import { MultimediaApi } from "./infrastructure/api/multimedia-api";
import { IWarehouse3DStore } from "./ui/pages/3d-viewer/stores/warehouse-3d-store/IWarehouse3DStore";
import { useWarehouse3DStore } from "./ui/pages/3d-viewer/stores/warehouse-3d-store/warehouse-3d-store";
import { IZonesEdition3DStore } from "./ui/pages/3d-viewer/stores/zones-edition-3d-store/IZonesEdition3DStore";
import { useZonesEdition3DStore } from "./ui/pages/3d-viewer/stores/zones-edition-3d-store/zones-edition-3d-store";
import { StakeholderRepository } from "./infrastructure/repositories/stakeholders-repository";
import { StakeholderApi } from "./infrastructure/api/stakeholder-api";
import { OperatorRepository } from "./infrastructure/repositories/operator-repository";
import { OperatorApi } from "./infrastructure/api/operator-api";
import { IOperatorService, OperatorService } from "./domain/services/IOperatorService";
import { useOperatorService } from "./infrastructure/services/useOperatorService";
import { FindFamiliesByTenantUseCase } from "./application/master-data/families/find-families-by-tenant-use-case";
import { FindTypeOfFamiliesByTenantUseCase } from "./application/master-data/type-of-families/find-type-of-family-by-tenant-use-case";
import { FindZonesByTenantUseCase } from "./application/master-data/zones/find-zones-by-tenant-use-case";
import { FindTilesUseCase } from "./application/master-data/tiles/find-tiles-use-case";
import { CheckUserRoleUseCase } from "./application/permissions/check-user-role-use-case";
import { ChangeSettingsUseCase } from "@application/settings/change-settings-use-case";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { useSettingsService } from "@infrastructure/services/useSettingsService";
import { HierarchyFiltersService, IHierarchyFiltersService } from "@domain/services/IHierarchyFiltersService";
import { useHierarchyFiltersService } from "@infrastructure/services/useHierarchyFiltersService";

// eslint-disable-next-line max-statements
export const register = (container: typeof Container) => {
  container.unbindAll();
  container.bind(AggregoProxyApiConfiguration).toSelf();

  //REPOSITORIES
  container.bind(CompanyRepository).toSelf();
  container.bind(LocationRepository).toSelf();
  container.bind(FamilyRepository).toSelf();
  container.bind(WarehouseRepository).toSelf();
  container.bind(MaterialRepository).toSelf();
  container.bind(WarehouseStatusRepository).toSelf();
  container.bind(MovementsRepository).toSelf();
  container.bind(ZoneRepository).toSelf();
  container.bind(TypeOfFamilyRepository).toSelf();
  container.bind(UserRepository).toSelf();
  container.bind(ProductRepository).toSelf();
  container.bind(TileRepository).toSelf();
  container.bind(TileTemplateRepository).toSelf();
  container.bind(EventRepository).toSelf();
  container.bind(InventoryRegistryRepository).toSelf();
  container.bind(InventoryReportRepository).toSelf();
  container.bind(InventoryPeriodRepository).toSelf();
  container.bind(StockRepository).toSelf();
  container.bind(ContainerRepository).toSelf();
  container.bind(MediaRepository).toSelf();
  container.bind(StakeholderRepository).toSelf();
  container.bind(OperatorRepository).toSelf();

  //API
  container.bind(FamilyApi).toSelf();
  container.bind(FamiliesApi).toSelf();

  container.bind(CompanyApi).toSelf();
  container.bind(CompaniesApi).toSelf();

  container.bind(LocationApi).toSelf();
  container.bind(LocationsApi).toSelf();
  container.bind(WarehouseApi).toSelf();
  container.bind(WarehousesApi).toSelf();
  container.bind(MaterialApi).toSelf();
  container.bind(MaterialsApi).toSelf();

  container.bind(ZoneApi).toSelf();
  container.bind(ZonesApi).toSelf();

  container.bind(TileApi).toSelf();
  container.bind(TilesApi).toSelf();

  container.bind(StakeholderApi).toSelf();
  container.bind(StakeholdersApi).toSelf();

  container.bind(ReportApi).toSelf();
  container.bind(ReportsApi).toSelf();

  container.bind(ContainerApi).toSelf();
  container.bind(ContainersApi).toSelf();

  container.bind(TypeOfFamilyApi).toSelf();
  container.bind(FamilyTypesApi).toSelf();

  container.bind(ProductApi).toSelf();
  container.bind(ProductsApi).toSelf();

  container.bind(EventsApi).toSelf();

  container.bind(UserApi).toSelf();
  container.bind(UsersApi).toSelf();

  container.bind(TileTemplateApi).toSelf();
  container.bind(TileTemplatesApi).toSelf();

  container.bind(MultimediaApi).toSelf();
  container.bind(MediaApi).toSelf();

  container.bind(OperatorApi).toSelf();
  container.bind(OperatorsApi).toSelf();

  container.bind(LoginApi).toSelf();

  //USE CASES BINDS
  container.bind(LoginUseCase).toSelf();
  container.bind(LogoutUseCase).toSelf();
  container.bind(RecoverPasswordUseCase).toSelf();
  container.bind(ResetPasswordUseCase).toSelf();
  container.bind(TypeOfFamilyUpdateStatusUseCase).toSelf();
  container.bind(LocationUpdateStatusUseCase).toSelf();
  container.bind(LocationsCreateUseCase).toSelf();
  container.bind(ZoneUpdateStatusUseCase).toSelf();
  container.bind(FamilyUpdateStatusUseCase).toSelf();
  container.bind(WarehouseUpdateStatusUseCase).toSelf();
  container.bind(WarehouseGetByIdUseCase).toSelf();
  container.bind(TileGetByIdUseCase).toSelf();
  container.bind(CompanyUpdateStatusUseCase).toSelf();
  container.bind(CompanyUpdateUseCase).toSelf();
  container.bind(MaterialUpdateStatusUseCase).toSelf();
  container.bind(FamilyCreateUseCase).toSelf();
  container.bind(IsValidImpersonationUseCase).toSelf();
  container.bind(InventoryRegistryUseCase).toSelf();
  container.bind(StockReportUseCase).toSelf();
  container.bind(WarehouseByTenantUseCase).toSelf();
  container.bind(FindFamiliesByTenantUseCase).toSelf();
  container.bind(FindTypeOfFamiliesByTenantUseCase).toSelf();
  container.bind(FindTilesUseCase).toSelf();
  container.bind(FindZonesByTenantUseCase).toSelf();
  container.bind(CheckUserRoleUseCase).toSelf();
  container.bind(ChangeSettingsUseCase).toSelf();

  container.bind<IUserService>(UserService).toDynamicValue(() => useUserService());
  container.bind<IOperatorService>(OperatorService).toDynamicValue(() => useOperatorService());
  container.bind<SnackbarService>(SNACKBAR).toDynamicValue(() => useSnackbar());
  container.bind<IDrawerService>(DrawerService).toDynamicValue(() => useDrawerService());
  container.bind<ITableService>(TableService).toDynamicValue(() => useTableService());
  container.bind<ILastPageService>(LastPageService).toDynamicValue(() => useLastPageService());
  container.bind<IHierarchyService>(HierarchyService).toDynamicValue(() => useHierarchyService());
  container.bind<IImpersonationService>(ImpersonationService).toDynamicValue(() => useImpersonationService());
  container.bind<ISettingsService>(SettingsService).toDynamicValue(() => useSettingsService());
  container.bind<IHierarchyFiltersService>(HierarchyFiltersService).toDynamicValue(() => useHierarchyFiltersService());

  container.bind<IEditionStatesStore>(useEditionStatesStore).toDynamicValue(() => useEditionStatesStore());
  container.bind<IDialogsStatesStore>(useDialogsStatesStore).toDynamicValue(() => useDialogsStatesStore());
  container.bind<ICommon3DObjectsStore>(useCommon3DObjectsStore).toDynamicValue(() => useCommon3DObjectsStore());
  container.bind<IWarehouse3DStore>(useWarehouse3DStore).toDynamicValue(() => useWarehouse3DStore());
  container.bind<IZonesEdition3DStore>(useZonesEdition3DStore).toDynamicValue(() => useZonesEdition3DStore());
};
