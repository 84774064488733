import { User } from "@domain/model/User";
import { UserApi } from "@infrastructure/api/user-api";
import { TUserRoleEnum } from "@domain/interface/User";
import { ApplicationErrors } from "../ApplicationConstants";
import { IUserService } from "@domain/services/IUserService";
import { ExtendedLoginResponse, GenerateQRbyCodeRequest, LoginApi } from "@clients/aggrego-proxy";
import { UserRepository } from "@infrastructure/repositories/user-repository";
import { IImpersonationService } from "@domain/services/IImpersonationService";

export class LoginUseCase {
  constructor(
    private readonly userService: IUserService,
    private readonly loginClient: LoginApi,
    private readonly userRepository: UserRepository,
    private readonly usersApi: UserApi,
    private readonly impersonationService: IImpersonationService
  ) {}

  public execute(username: string, password: string): Promise<void> {
    return new Promise((res, rej) => {
      localStorage.clear();
      this.loginClient
        .portalLogin({ loginRequest: { username, password } })
        .then((loginResponse: ExtendedLoginResponse) => {
          //at this point, if loginResponse has token, user has no mfa validation activated. Login will work as usually
          if (loginResponse.token) {
            this.checkServerToken(res, rej, loginResponse);
            sessionStorage.setItem("sessionToken", loginResponse.token);
            this.userService.save(
              new User({
                token: loginResponse.token,
                enabledNFAEmail: loginResponse.enabledNFAEmail,
                enabledNFAOPT: loginResponse.enabledNFAOPT
              })
            );
            //if loginResponse has no token, user may has mfa validation activated or credentials are invalid
          } else {
            //if user has mfa validation activated, store them in sessionStorage (fastest than userService) and inside userService user info
            if (loginResponse.enabledNFAEmail || loginResponse.enabledNFAOPT) {
              sessionStorage.setItem("enabledNFAEmail", loginResponse.enabledNFAEmail + "");
              sessionStorage.setItem("enabledNFAOPT", loginResponse.enabledNFAOPT + "");
              try {
                this.userService.save(
                  new User({
                    enabledNFAEmail: loginResponse.enabledNFAEmail,
                    enabledNFAOPT: loginResponse.enabledNFAOPT
                  })
                );
                //throw rej() useLogin hook will handle it
              } finally {
                rej();
              }
            }
          }
        })
        // credentials are invalid
        .catch(reason => {
          rej(reason);
        });
    });
  }

  public executeEmail(username: string, password: string, twoFactorCode: string): Promise<void> {
    return new Promise((res, rej) => {
      localStorage.clear();
      this.loginClient
        .mfaLoginEmail({ emailCode: twoFactorCode, loginRequest: { username, password } })
        .then((loginResponse: ExtendedLoginResponse) => {
          if (loginResponse.token) {
            this.checkServerToken(res, rej, loginResponse);
            sessionStorage.setItem("sessionToken", loginResponse.token);
            this.userService.save(
              new User({
                token: loginResponse.token,
                enabledNFAEmail: loginResponse.enabledNFAEmail,
                enabledNFAOPT: loginResponse.enabledNFAOPT
              })
            );
          } else {
            rej();
          }
        })
        .catch(reason => {
          rej(reason);
        });
    });
  }

  public executeOpt(username: string, password: string, twoFactorCode: string): Promise<void> {
    return new Promise((res, rej) => {
      localStorage.clear();
      this.loginClient
        .mfaLoginOPT({ otpCode: twoFactorCode, loginRequest: { username, password } })
        .then((loginResponse: ExtendedLoginResponse) => {
          if (loginResponse.token) {
            this.checkServerToken(res, rej, loginResponse);
            sessionStorage.setItem("sessionToken", loginResponse.token);
            this.userService.save(
              new User({
                token: loginResponse.token,
                enabledNFAEmail: loginResponse.enabledNFAEmail,
                enabledNFAOPT: loginResponse.enabledNFAOPT
              })
            );
          } else {
            rej();
          }
        })
        .catch(reason => {
          rej(reason);
        });
    });
  }

  public forceFinishingExistingSession(): Promise<void> {
    return new Promise((res, rej) => {
      if (sessionStorage.getItem("sessionToken") || this.userService.user?.token) {
        this.loginClient
          .refreshSessionToken()
          .then(() => res())
          .catch(err => rej(err));
      } else {
        rej(ApplicationErrors.CREDENTIALS_ERROR);
      }
    });
  }

  public loginInExistingSession(): Promise<void> {
    return new Promise((res, rej) => {
      const storagedToken = sessionStorage.getItem("sessionToken");
      if (storagedToken) {
        this.loginClient
          .refreshSessionToken({ token: storagedToken })
          .then(() => res())
          .catch(err => rej(err));
      } else {
        rej(ApplicationErrors.CREDENTIALS_ERROR);
      }
    });
  }

  public async checkSessionToken(token: string) {
    return (await this.loginClient.checkSessionToken({ token })).token;
  }

  public async isTokenValid(token: string) {
    return await this.loginClient.isValidToken({ token });
  }

  private checkServerToken(res: () => unknown, rej: (arg0: ApplicationErrors) => unknown, response: ExtendedLoginResponse) {
    if (response) {
      switch (response.session?.status) {
        case "CORRECT_SESSION":
          res();
          break;
        case "CREDENTIALS_NOT_EXIST":
          rej(ApplicationErrors.CREDENTIALS_ERROR);
          break;
        case "DIFFERENT_SESSION_ACTIVE":
          rej(ApplicationErrors.SESSION_ALREADY_IN_USE);
          break;
      }
    } else {
      rej(ApplicationErrors.CREDENTIALS_ERROR);
    }
  }

  public switchMFAEmailOption = async () => {
    return await this.loginClient.activateDeactivateMFAEmail({
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  };

  public switchMFAOptOption = async () => {
    return await this.loginClient.activateDeactivateMFAOPT({
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  };

  public generateQrCode(secretNFA: string) {
    return this.loginClient.generateQRbyCode({
      secret: secretNFA,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    } as GenerateQRbyCodeRequest);
  }

  public clearLogin() {
    this.userService.clear();
  }

  public async loadUser() {
    const isRole = (role: TUserRoleEnum) => {
      return this.userService.current()?.getRoleFromToken() === role;
    };
    const storagedToken = sessionStorage.getItem("sessionToken");
    const credentialsId = this.userService.current()?.getUserIdFromToken();
    if (credentialsId) {
      const loadedUser = isRole("SUPER_ADMIN")
        ? await this.usersApi.getById(credentialsId)
        : await this.usersApi.getInfoUser(credentialsId);
      (loadedUser as User).token = storagedToken ?? "token";

      this.userService.save(new User(loadedUser as Partial<User>));
    }
  }

  public async howManyUsers() {
    return await this.loginClient.getUsersWithSession({
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }
}
