import { Event, Mesh, Object3D, Scene } from "three";
import { Location3D, locationDTOFrom3D } from "../types/Location3D";
import { Tile3D, tile3DFromDTO, tileDTOFrom3D } from "../types/Tile3D";
import { generateUUID } from "three/src/math/MathUtils";
import Tile from "../../../../domain/model/Tile";
import { useResolve } from "@movicoders/di";
import { ICommon3DObjectsStore } from "../stores/common-3d-objects-store/ICommon3DObjectsStore";
import { useCommon3DObjectsStore } from "../stores/common-3d-objects-store/common-3d-objects-store";
import { useSnackbar } from "@movicoders/ui";
import { IEditionStatesStore } from "../stores/edition-states-store/IEditionStatesStore";
import { useEditionStatesStore } from "../stores/edition-states-store/edition-states-store";
import { TileRepository } from "../../../../infrastructure/repositories/tile-repository";
import { IDialogsStatesStore, IReplaceOrKeepPairs } from "../stores/dialogs-states-store/IDialogsStatesStore";
import { useDialogsStatesStore } from "../stores/dialogs-states-store/dialogs-states-store";
import { IWarehouse3DStore } from "../stores/warehouse-3d-store/IWarehouse3DStore";
import { useWarehouse3DStore } from "../stores/warehouse-3d-store/warehouse-3d-store";
import { useRef } from "react";
import { LocationRepository } from "../../../../infrastructure/repositories/location-repository";
import { LocationDTO } from "../../../../clients/aggrego-proxy";
import { findExistingLocationByCode } from "./box-finders";

// Methods that help saving or undoing changes in the viewer.

export const useEndModifyingHandlers = (scene: Scene) => {
  const { checkTemplateTypeById } = useResolve<IEditionStatesStore>(useEditionStatesStore);
  const { getWarehouse, setWarehouse } = useResolve<IWarehouse3DStore>(useWarehouse3DStore);
  const { show } = useSnackbar();

  // Repositories
  const tileRepository = useRef(useResolve<TileRepository>(TileRepository)).current;
  const locationRepository = useRef(useResolve<LocationRepository>(LocationRepository)).current;

  const {
    getTilesBeingModified,
    setTilesBeingModified,
    getPivotControlsContent,
    getStartPoint,
    getEndPoint,
    setStartPoint,
    setEndPoint
  } = useResolve<ICommon3DObjectsStore>(useCommon3DObjectsStore);

  const {
    setModifyingWH,
    setEndModifyingWH,
    getSelectedTileTemplate,
    setModifyMaxLevel,
    setOnSaveLoading,
    getTemplate
  } = useResolve<IEditionStatesStore>(useEditionStatesStore);

  const {
    handleOpenDeleteBoxDialog,
    handleOpenReplaceOrKeepDialog
  } = useResolve<IDialogsStatesStore>(useDialogsStatesStore);

  const handleCleanup = () => {
    setStartPoint(undefined);
    setEndPoint(undefined);
    setTilesBeingModified([]);
    scene.remove(scene.getObjectByName("createStart") ?? new Mesh());
    scene.remove(scene.getObjectByName("createEnd") ?? new Mesh());
    scene.remove(scene.getObjectByName("temp") ?? new Mesh());
    scene.remove(scene.getObjectByName("tempTemplates") ?? new Mesh());
    scene.remove(scene.getObjectByName("tempReduce") ?? new Mesh());
    scene.remove(scene.getObjectByName("tempEdit") ?? new Mesh());
    getPivotControlsContent().children = [];
    setEndModifyingWH("");
    setModifyingWH(false);
    setOnSaveLoading(false);
  }

  const handleCreateVariousTiles = (customNewShelf?: Object3D<Event>) => {
    setOnSaveLoading(true);
    const newShelf = customNewShelf ?? scene.getObjectByName("temp");
    const tilesToSave = newShelf?.children.map(c => c.userData.tile as Tile3D) ?? [];
    const tempWH = getWarehouse();

    tileRepository.createMany({ tileDTO: tilesToSave.map(tileDTOFrom3D) }).then((res) => {
      const tilesToEdit: Tile3D[] = [];
      newShelf?.children.forEach(group => {
        const tempTiles = [...(getWarehouse().tiles ?? [])];
        if (res.content?.[(group.userData.tile as Tile3D).code ?? ""] === "OK") tempTiles.push(group.userData.tile);
        else tilesToEdit.push(group.userData.tile);

        tempWH.tiles = tempTiles;
        setWarehouse(tempWH);
      });

      const emptyTiles = getWarehouse().tiles?.filter(tile => tilesToEdit.find(tte => tte.x === tile.x && tte.z === tile.z && tile.locations?.length === 0) !== undefined) ?? [];
      emptyTiles.length > 0 && tileRepository.updateMany({
        tileDTO: emptyTiles.map(emptyTile => {
          const tileToEdit = tilesToSave.find(t => t.x === emptyTile.x && t.z === emptyTile.z)
          if (tileToEdit === undefined) return tileDTOFrom3D(emptyTile);
          const index = tilesToEdit.indexOf(tileToEdit);
          tilesToEdit.splice(index, 1);
          tileToEdit.id = emptyTile.id;
          return tileDTOFrom3D(tileToEdit);
        })
      }).then((emptyRes) => {
        newShelf?.children.forEach(group => {
          const tempTiles = [...(getWarehouse().tiles ?? [])];
          if (emptyRes.content?.[(group.userData.tile as Tile3D).code ?? ""] === "OK") {
            // Condition in a variable so the maximum nested callbacks are not greater than the eslint maximum.
            const findEmptyTileByCoords = (tile: Tile3D) => tile.x === group.userData.tile.x && tile.z === group.userData.tile.z;
            const emptyTileToUpdate = emptyTiles.find(findEmptyTileByCoords);
            const emptyTileIndex = emptyTileToUpdate ? tempTiles.indexOf(emptyTileToUpdate) : -1;
            if (emptyTileIndex !== -1) {
              const tempTileToUpdate = { ...emptyTileToUpdate };
              tempTileToUpdate.active = true;
              tempTileToUpdate.locations = [...group.userData.tile.locations];
              tempTileToUpdate.maxY = group.userData.tile.maxY;
              tempTileToUpdate.templateId = group.userData.tile.templateId;
              tempTileToUpdate.zoneId = group.userData.tile.zoneId;
              tempTiles[emptyTileIndex] = tempTileToUpdate;
            } else tempTiles.push(group.userData.tile);
          } else tilesToEdit.push(group.userData.tile);

          tempWH.tiles = tempTiles;
          setWarehouse(tempWH);
        });
      })
      const oldTiles = getWarehouse().tiles?.filter(tile => tilesToEdit.find(tte => tte.x === tile.x && tte.z === tile.z && (tile.locations?.length ?? 0) > 0) !== undefined) ?? [];
      if (tilesToEdit.length > 0) handleOpenReplaceOrKeepDialog({ tilesToReplace: oldTiles, newTiles: tilesToEdit });
    }).catch(() => {
      show("creating.unknown", "error");
    }).finally(handleCleanup);
  };

  const handleCreateVariousTemplates = () => {
    setOnSaveLoading(true);
    const newTemplates = scene.getObjectByName("tempTemplates");
    const templatesToSave = newTemplates?.children.map(c => c.userData.tile as Tile3D) ?? [];
    const tempWH = getWarehouse();

    tileRepository.createMany({ tileDTO: templatesToSave.map(tileDTOFrom3D) }).then((res) => {
      const templatesToEdit: Tile3D[] = [];
      templatesToSave.forEach(template => {
        const tempTiles = [...(getWarehouse().tiles ?? [])];
        if (res.content?.[template.code ?? ""] === "OK") tempTiles.push(template);
        else templatesToEdit.push(template);

        tempWH.tiles = tempTiles;
        setWarehouse(tempWH);
      });

      const emptyTiles = getWarehouse().tiles?.filter(tile => templatesToEdit.find(tte => tte.x === tile.x && tte.z === tile.z && tile.locations?.length === 0) !== undefined) ?? [];
      emptyTiles.length > 0 && tileRepository.updateMany({
        tileDTO: emptyTiles.map(emptyTile => {
          const templateToEdit = templatesToSave.find(template => template.x === emptyTile.x && template.z === emptyTile.z);
          if (templateToEdit === undefined) return tileDTOFrom3D(emptyTile);
          const index = templatesToEdit.indexOf(templateToEdit);
          templatesToEdit.splice(index, 1);
          templateToEdit.id = emptyTile.id;
          return tileDTOFrom3D(templateToEdit);
        })
      }).then((emptyRes) => {
        templatesToSave.forEach(template => {
          const tempTiles = [...(getWarehouse().tiles ?? [])];
          if (emptyRes.content?.[template.code ?? ""] === "OK") tempTiles.push(template);
          else templatesToEdit.push(template);

          tempWH.tiles = tempTiles;
          setWarehouse(tempWH);
        });
      })
      const oldTiles = getWarehouse().tiles?.filter(tile => templatesToEdit.find(tte => tte.x === tile.x && tte.z === tile.z && (tile.locations?.length ?? 0) > 0) !== undefined) ?? [];
      if (templatesToEdit.length > 0) handleOpenReplaceOrKeepDialog({ tilesToReplace: oldTiles, newTiles: templatesToEdit });
    }).catch(() => {
      show("creating.unknown", "error");
    }).finally(handleCleanup);

  };

  const handleCreateOneTile = () => {
    setOnSaveLoading(true);
    const tileId = generateUUID();
    const newTile = {
      id: tileId,
      code: getStartPoint()?.position.x + "." + getStartPoint()?.position.z,
      x: getStartPoint()?.position.x,
      z: getStartPoint()?.position.z,
      maxY: 1,
      warehouseId: getWarehouse().id,
      templateId: getTemplate("STORAGE")?.id,
      locations: [
        {
          id: generateUUID(),
          code: getStartPoint()?.position.x + "." + ((getEndPoint()?.position.y ?? 0) + 1) + "." + getStartPoint()?.position.z,
          capacity: 1,
          level: (getEndPoint()?.position.y ?? 0) + 1,
          active: true,
          families: [],
          materials: [],
          containers: [],
          familyTypes: [],
          occupation: 0,
          tileId: tileId,
          warehouseId: getWarehouse().id
        }
      ],
      active: true
    } as Tile;

    tileRepository.create(newTile)
      .then(() => getWarehouse().tiles?.push(tile3DFromDTO(newTile)))
      .catch(() => {
        const oldTile = getWarehouse().tiles?.find(tile => tile.x === newTile.x && tile.z === newTile.z)
        if (oldTile?.id && oldTile.locations?.length === 0) {
          newTile.id = oldTile.id;
          newTile.locations = newTile.locations?.map(loc => {
            loc.tileId = newTile.id;
            loc.warehouseId = getWarehouse().id;
            return loc;
          });
          tileRepository.updateMany({ tileDTO: [newTile] }).then((response) => {
            if (response.content?.[newTile.code ?? ""] !== "OK") throw new Error("creating.unknown");
            const tempWH = { ...getWarehouse() };
            const tempTiles = [...tempWH.tiles ?? []];
            const oldTileIndex = tempTiles.indexOf(oldTile);
            if (tempTiles && oldTileIndex !== -1) {
              tempTiles[oldTileIndex] = tile3DFromDTO(newTile);
              tempWH.tiles = tempTiles;
              setWarehouse(tempWH);
            }
          }).catch(() => show("creating.unknown", "error"));
        } else if (oldTile)
          handleOpenReplaceOrKeepDialog({ tilesToReplace: [oldTile], newTiles: [tile3DFromDTO(newTile)] });
        else
          show("creating.unknown", "error")
      }).finally(handleCleanup)
  };

  const handleCreateOneTemplate = () => {
    setOnSaveLoading(true);
    const newTile = {
      id: generateUUID(),
      code: getEndPoint()?.position.x + "." + getEndPoint()?.position.z,
      x: getEndPoint()?.position.x,
      z: getEndPoint()?.position.z,
      maxY: 0,
      warehouseId: getWarehouse().id,
      templateId: getTemplate(getSelectedTileTemplate())?.id,
      locations: [],
      active: true
    } as Tile;

    tileRepository.create(newTile)
      .then(() => getWarehouse().tiles?.push(tile3DFromDTO(newTile)))
      .catch(() => {
        const oldTile = getWarehouse().tiles?.find(tile => tile.x === newTile.x && tile.z === newTile.z)
        if (oldTile?.id && oldTile.locations?.length === 0) {
          newTile.id = oldTile.id;
          tileRepository.update(newTile).then((createdTile) => {
            const tempWH = { ...getWarehouse() };
            const tempTiles = [...tempWH.tiles ?? []];
            const oldTileIndex = tempTiles.indexOf(oldTile);
            if (tempTiles && oldTileIndex !== -1) {
              tempTiles[oldTileIndex] = tile3DFromDTO(createdTile);
              tempWH.tiles = tempTiles;
              setWarehouse(tempWH);
            }
          }).catch(() => show("creating.unknown", "error"));
        } else if (oldTile)
          handleOpenReplaceOrKeepDialog({ tilesToReplace: [oldTile], newTiles: [tile3DFromDTO(newTile)] });
        else
          show("creating.unknown", "error");
      }).finally(handleCleanup);
  };

  const handlePlaceMovingTiles = () => {
    setOnSaveLoading(true);
    const tempTiles = getWarehouse().tiles ?? [];
    const tempMovingTiles = [...getTilesBeingModified()];
    const movingPairs: IReplaceOrKeepPairs[] = [];
    // Moving Tiles that are also being modified by the other moving tiles.
    const crossMovingTiles: Tile3D[] = [];
    const oldTiles: Tile3D[] = tempMovingTiles.map(tempMovTile => {
      const oldT = tempTiles.find(tile => tile.x === tempMovTile.position.x && tile.z === tempMovTile.position.z && ((tile.locations?.length ?? 0) > 0 || !checkTemplateTypeById("STORAGE", tile.templateId)));
      if (oldT !== undefined && !tempMovingTiles.map(t => t.userData.tile.id).includes(oldT.id)) movingPairs.push({ newTileId: tempMovTile.userData.tile.id ?? "", oldTileId: oldT.id ?? "" });
      if (oldT !== undefined && tempMovingTiles.map(t => t.userData.tile.id).includes(oldT?.id)) crossMovingTiles.push(oldT)
      return oldT;
    }).filter(t => t !== undefined) as Tile3D[];

    // If there are no conflicting tiles
    if (movingPairs.length !== tempMovingTiles.length) {
      const tileIdsToIgnore = movingPairs.map(pair => pair.newTileId);
      const tilesToReEnable: Tile3D[] = [];
      const tilesToCreate: Tile3D[] = [];
      const tilesToDisable: Tile3D[] = [];
      let locationsToUpdate: Location3D[] = [];
      const oldEmptyTiles: Tile3D[] = tempMovingTiles.map(tempMovTile => {
        const oldT = tempTiles.find(tile => tile.x === tempMovTile.position.x && tile.z === tempMovTile.position.z && (tile.locations?.length ?? 0) === 0);
        return oldT;
      }).filter(t => t !== undefined) as Tile3D[];

      [...tempMovingTiles].forEach(tempMovTile => {
        const movedTile = tempMovTile.userData.tile as Tile3D;
        if (!tileIdsToIgnore.includes(movedTile.id ?? "")) {
          let oldT = oldEmptyTiles.find(ot => ot.x === tempMovTile.position.x && ot.z === tempMovTile.position.z);
          // Check if the old tile is also a moving tile.
          if (oldT === undefined) {
            oldT = crossMovingTiles.find(ot => ot.x === tempMovTile.position.x && ot.z === tempMovTile.position.z)
          }
          if (oldT) {
            // Re-Enable old tile in current pos
            const editableOldT = { ...oldT };
            editableOldT.active = true;
            const newLocationsToSave = [...movedTile.locations ?? []].map(location => {
              location.warehouseId = getWarehouse().id;
              return location;
            });
            editableOldT.locations = [...editableOldT.locations ?? []].concat([...newLocationsToSave]);
            editableOldT.maxY = Math.max(...(editableOldT.locations?.filter((l) => l.active).map((l) => l.level ?? 0) ?? []), 1)
            editableOldT.templateId = movedTile.templateId;
            editableOldT.zoneId = movedTile.zoneId;
            tilesToReEnable.push(editableOldT);
          } else {
            // Create new tile in current pos
            const newId = generateUUID();
            const newLocations: Location3D[] = movedTile.locations?.map(l => {
              l.tileId = newId;
              l.warehouseId = getWarehouse().id;
              return l;
            }).filter(l => l !== undefined) as Location3D[];
            const newTile: Tile3D = {
              ...movedTile,
              id: newId,
              code: `${tempMovTile.position.x}.${tempMovTile.position.z}`,
              active: true,
              x: tempMovTile.position.x,
              z: tempMovTile.position.z,
              locations: newLocations
            }
            tilesToCreate.push(newTile);
            locationsToUpdate = locationsToUpdate.concat(newLocations);
          }
          // If the moved tile is an overrwritten tile it is not disabled for now.
          if (!crossMovingTiles.map(t => t.id).includes(movedTile?.id) && !tileIdsToIgnore.includes(movedTile?.id ?? "")) {
            // Disable moved tile
            const editableMovedTile = { ...movedTile };
            editableMovedTile.active = false;
            editableMovedTile.locations = [];
            editableMovedTile.maxY = 1;
            editableMovedTile.zoneId = undefined;
            tilesToDisable.push(editableMovedTile);
          }
        }
      })
      const tilesToUpdate = tilesToReEnable.concat(tilesToDisable);

      Promise.all([
        tileRepository.updateMany({ tileDTO: tilesToUpdate.map(tileDTOFrom3D) }),
        tileRepository.createMany({
          tileDTO: [...tilesToCreate].map(ttc => {
            const modifiedTile = { ...ttc };
            modifiedTile.locations = [];
            return modifiedTile;
          }).map(tileDTOFrom3D)
        }).then(() =>
          tileRepository.updateMany({ tileDTO: tilesToCreate.map(tileDTOFrom3D) })
            .finally(() => setOnSaveLoading(false))
        )
      ]).then(() => {
        if (crossMovingTiles.length > 0)
          window.location.reload();
        else {
          tilesToUpdate.forEach((ttu) => {
            const oldToReplace = tempTiles.find(t => t.id === ttu.id);
            const index = oldToReplace ? tempTiles.indexOf(oldToReplace) : -1;
            if (oldToReplace && index !== -1 && !movingPairs.map(mp => mp.newTileId).includes(ttu.id ?? "")) {
              tempTiles[index] = ttu;
            }
          })

          tilesToCreate.forEach((ttc) => {
            if (!movingPairs.map(mp => mp.newTileId).includes(ttc.id ?? ""))
              tempTiles.push(ttc);
          })
        }
      }).catch(() => show("moving.unknown", "error"))
        .finally(() => {
          if (movingPairs.length === 0) handleCleanup();
        });
    }

    if (movingPairs.length > 0) {
      getTilesBeingModified().forEach(tile => {
        if (tile?.userData?.firstPosition !== undefined && movingPairs.map(mp => mp.newTileId).includes(tile?.userData?.tile?.id ?? "")) {
          tile.position.set(tile.userData.firstPosition[0], tile.position.y, tile.userData.firstPosition[1]);
        }
      });
      handleOpenReplaceOrKeepDialog({
        tilesToReplace: oldTiles,
        newTiles: tempMovingTiles.map(obj => obj.userData.tile),
        movingIdPairs: movingPairs
      });
    }
  };

  const handleCancelOperation = () => {
    if (getTilesBeingModified().length > 0) {
      getTilesBeingModified().forEach(tile => {
        if (tile?.userData?.firstPosition !== undefined) {
          tile.position.set(tile.userData.firstPosition[0], tile.position.y, tile.userData.firstPosition[1]);
        }
      });
    }
    handleCleanup();
  };

  const handleApplyDeletion = () => {
    const tempBins: Location3D[] = [];
    getTilesBeingModified()
      .map(g => g.userData.tile)
      .forEach((t: Tile3D) => {
        t.locations?.forEach(b => {
          if ((b.level ?? 0) > (getEndPoint()?.position.y ?? 0) + 1) {
            tempBins.push(b);
          }
        });
      });

    handleOpenDeleteBoxDialog([], tempBins.filter(b => b.active));

    setTilesBeingModified([]);
    setModifyMaxLevel(0);
    setEndPoint(undefined);
    scene.remove(scene.getObjectByName("tempReduce") ?? new Mesh());
    getPivotControlsContent().children = [];
    setEndModifyingWH("");
    setModifyingWH(false);
  };

  const handleApplyEdit = () => {
    setOnSaveLoading(true);
    const newShelf = scene.getObjectByName("tempEdit");
    const zones = new Set(getTilesBeingModified().map((obj) => obj.userData.tile.zoneId as string));

    const tempTiles = getWarehouse().tiles ?? [];

    const newTiles = newShelf?.children.filter(child => {
      const currentTile = child.userData.tile as Tile3D;
      const found = tempTiles.find(tile => tile.x === currentTile.x && tile.z === currentTile.z && tile.active === true);
      return found === undefined;
    }) ?? [];

    const levelUpTiles = newShelf?.children.filter(child => {
      const currentTile = child.userData.tile as Tile3D;
      const found = tempTiles.find(tile => tile.x === currentTile.x && tile.z === currentTile.z && tile.active === true && (tile.maxY ?? 0) < (currentTile.maxY ?? 0));
      return found !== undefined;
    }) ?? [];

    // Tiles create many
    if (newTiles.length > 0) {

      if (zones.size === 1) {
        newShelf?.children.forEach((obj) => {
          obj.userData.tile.zoneId = Array.from(zones)[0];
        })
      }

      handleCreateVariousTiles(newShelf);
    }

    // Locations create many
    if (levelUpTiles.length > 0) {
      const locationsToCreate: Location3D[] = [];
      const locationsToEdit: Location3D[] = [];

      levelUpTiles?.forEach(group => {
        const existingTile = tempTiles.find(tile => tile.x === group.userData.tile.x && tile.z === group.userData.tile.z);
        if (existingTile) {
          group.userData.tile.locations.forEach((loc: Location3D) => {
            if ((loc.level ?? 0) > (existingTile.maxY ?? 0)) {
              loc.tileId = existingTile.id;
              loc.warehouseId = getWarehouse().id;
              locationsToCreate.push(loc);
            }
          });
        }
      })

      if (locationsToCreate.length > 0) {
        locationRepository.createMany({
          locationDTO: locationsToCreate.map(loc => {
            loc.containers = [];
            loc.warehouseId = getWarehouse().id;
            return loc as LocationDTO;
          }) ?? []
        }).then((res) => {
          locationsToCreate.forEach((loc) => {
            const tile = tempTiles.find(tile => tile.id === loc.tileId);
            const newMaxY = levelUpTiles[0].userData.tile.maxY as number;
            if (res.content?.[loc.code ?? ""] === "OK" && tile) {
              tile.locations?.push(loc);
              tile.maxY = newMaxY;
              tempTiles[tempTiles.indexOf(tile)] = { ...tile };
              const tempWH = { ...getWarehouse() };
              tempWH.tiles = [...tempTiles];
              setWarehouse(tempWH);
            } else locationsToEdit.push(loc);
          })

          // Re-enable old locations with same code
          if (locationsToEdit.length > 0) {
            const tilesToUpdate: Tile3D[] = [];
            setOnSaveLoading(true);
            Promise.all(
              locationsToEdit.map(locToEd => {
                const tile = tempTiles.find(tile => tile.id === locToEd.tileId);
                const oldLoc = tile?.locations?.find(l => l.code === locToEd.code && !l.active);
                if (tile && oldLoc) {
                  oldLoc.active = true;
                  oldLoc.warehouseId = getWarehouse().id;
                  tilesToUpdate.push(tile);
                  return locationRepository.update(locationDTOFrom3D(oldLoc));
                } else {
                  let canBeCreated = false;
                  let newCode = locToEd.code;
                  let count = 0;
                  while (!canBeCreated && locToEd.code) {
                    canBeCreated = findExistingLocationByCode(scene, locToEd.code, count) === undefined;
                    if (canBeCreated) newCode = locToEd.code + `(${count > 0 ? count : ""})`;
                    count++;
                  }
                  if (tile) {
                    tile.locations?.push({ ...locToEd, code: newCode });
                    tilesToUpdate.push(tile);
                  }
                  return locationRepository.create(locationDTOFrom3D({ ...locToEd, code: newCode, warehouseId: getWarehouse().id }));
                }
              })
            ).then(() => {
              new Set(tilesToUpdate).forEach(tile => {
                const newMaxY = levelUpTiles[0].userData.tile.maxY as number;
                tile.maxY = newMaxY;
                tempTiles[tempTiles.indexOf(tile)] = tile;
                const tempWH = { ...getWarehouse() };
                tempWH.tiles = [...tempTiles];
                setWarehouse(tempWH);
              })
            }).catch(() => {
              show("edit.unknown", "error");
              setOnSaveLoading(false);
            }).finally(() => {
              setOnSaveLoading(false);
            })
          } else {
            setOnSaveLoading(false);
          }
        })
      }
    }

    setTilesBeingModified([]);
    setModifyMaxLevel(0);
    setEndPoint(undefined);
    scene.remove(scene.getObjectByName("tempEdit") ?? new Mesh());
    getPivotControlsContent().children = [];
    setEndModifyingWH("");
    setModifyingWH(false);
  };

  return {
    handleCreateVariousTiles,
    handleCreateVariousTemplates,
    handleCreateOneTile,
    handleCreateOneTemplate,
    handlePlaceMovingTiles,
    handleCancelOperation,
    handleApplyDeletion,
    handleApplyEdit
  }
};
