import { DTOConvertibleEntity } from "./GenericPage";
import { UUIDGenerator } from "@utils/uuid-generator";
import { InventoryReportDTO } from "@clients/aggrego-proxy";

export class InvReportDTO extends DTOConvertibleEntity<InventoryReportDTO, InvReportDTO> {
  public constructor();
  public constructor(obj?: Partial<InvReportDTO>);
  public constructor(obj?: Partial<InvReportDTO>) {
    super();
    obj && Object.assign(this, obj);
  }
  id?: string = "";
  containerCode?: string = "";
  inventoryCode?: string = "";
  locationCode?: string = "";
  materialCode?: string = "";
  operatorCode?: string = "";
  qty?: number = 0;
  user?: string = "";
  created?: string = "";

  public fromDTO(obj: Partial<InventoryReportDTO>): InvReportDTO {
    return new InvReportDTO({
      id: UUIDGenerator(),
      containerCode: obj.containerCode ?? "",
      inventoryCode: obj.inventoryCode ?? "",
      locationCode: obj.locationCode ?? "",
      materialCode: obj.materialCode ?? "",
      operatorCode: obj.operatorCode ?? "",
      qty: obj.qty ?? 0,
      created: obj.created,
      user: obj.user
    });
  }

  public toDTO(arg: InvReportDTO): InventoryReportDTO {
    return {
      id: arg.id,
      containerCode: arg.containerCode,
      inventoryCode: arg.inventoryCode,
      locationCode: arg.locationCode,
      materialCode: arg.materialCode,
      operatorCode: arg.operatorCode,
      qty: arg.qty,
      user: arg.user,
      created: arg.created
    } as unknown as InvReportDTO;
  }
}
