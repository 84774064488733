import { InvPeriodDTO } from "@domain/model/InvPeriod";
import {
  AddIMMaterialListRequest,
  ConsumeIMMaterialRequest,
  ContainersApi,
  CreateInventoryPeriodRequest,
  GetInventoryPeriodByWarehouseRequest,
  CloseInventoryPeriodRequest,
  ListMovementEventPageRequest,
  GetContainerByIdsRequest,
  ListContainersPaginatedRequest,
  GetContainerByIdRequest,
  PartialConsumeForWMWithExplicitContainerRequest,
  PartialConsumeForWMWithGenericContainerRequest,
  ListRelabelPaginatedRequest
} from "@clients/aggrego-proxy/apis";
import { IImpersonationService } from "@domain/services/IImpersonationService";
import {
  ConsumeResponseDTO,
  ContainerDTO,
  ContainerPageDTO,
  ListResponseDTO,
  MovementEventPageDTO,
  RelabelPageDTO
} from "@clients/aggrego-proxy";

export class ContainerApi {
  constructor(private readonly containerApi: ContainersApi, private readonly impersonationService: IImpersonationService) {}

  async getInventoryPeriodByWarehouseCode(criteria: GetInventoryPeriodByWarehouseRequest): Promise<InvPeriodDTO[]> {
    return await this.containerApi.getInventoryPeriodByWarehouse({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }

  async getContainerById(criteria: GetContainerByIdRequest): Promise<ContainerDTO> {
    return await this.containerApi.getContainerById({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }

  async getContainersPaginated(criteria: ListContainersPaginatedRequest): Promise<ContainerPageDTO> {
    return await this.containerApi.listContainersPaginated({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }

  createInventoryPeriod(criteria: CreateInventoryPeriodRequest): Promise<string> {
    return this.containerApi.createInventoryPeriod({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }

  closeInventoryPeriod(criteria: CloseInventoryPeriodRequest): Promise<string> {
    return this.containerApi.closeInventoryPeriod({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }

  async getMovementsPaginated(criteria: ListMovementEventPageRequest): Promise<MovementEventPageDTO> {
    return new Promise((res, rej) => {
      this.containerApi
        .listMovementEventPage({
          ...criteria,
          xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
        })
        .then(movementEventPageDTO => {
          res(movementEventPageDTO);
        })
        .catch(err => rej());
    });
  }

  async getRelabelPaginated(criteria: ListRelabelPaginatedRequest): Promise<RelabelPageDTO> {
    return new Promise((res, rej) => {
      this.containerApi
        .listRelabelPaginated({
          ...criteria,
          xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
        })
        .then(movementEventPageDTO => {
          res(movementEventPageDTO);
        })
        .catch(err => rej());
    });
  }

  addManyIMMaterial(criteria: AddIMMaterialListRequest): Promise<ListResponseDTO> {
    return this.containerApi.addIMMaterialList({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }

  consumeIMMaterial(criteria: ConsumeIMMaterialRequest): Promise<ConsumeResponseDTO> {
    return this.containerApi.consumeIMMaterial({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }

  consumeWMMaterial(criteria: PartialConsumeForWMWithGenericContainerRequest): Promise<ConsumeResponseDTO> {
    return this.containerApi.partialConsumeForWMWithGenericContainer({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }

  consumeWMCMaterial(criteria: PartialConsumeForWMWithExplicitContainerRequest): Promise<ConsumeResponseDTO> {
    return this.containerApi.partialConsumeForWMWithExplicitContainer({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }

  getManyByIds(criteria: GetContainerByIdsRequest): Promise<Array<ContainerDTO>> {
    return this.containerApi.getContainerByIds({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }
}
