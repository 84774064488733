/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * ExtendedUserDTO extends User model to provide username
 * @export
 * @interface ExtendedUserDTO
 */
export interface ExtendedUserDTO {
    /**
     * 
     * @type {string}
     * @memberof ExtendedUserDTO
     */
    activationToken?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExtendedUserDTO
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExtendedUserDTO
     */
    credentialsId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedUserDTO
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExtendedUserDTO
     */
    enabledNFAEmail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExtendedUserDTO
     */
    enabledNFAOPT?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExtendedUserDTO
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedUserDTO
     */
    firstSurname?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedUserDTO
     */
    id?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof ExtendedUserDTO
     */
    logisticOperationsPermissions?: Set<ExtendedUserDTOLogisticOperationsPermissionsEnum>;
    /**
     * 
     * @type {Set<string>}
     * @memberof ExtendedUserDTO
     */
    masterdataPermissions?: Set<ExtendedUserDTOMasterdataPermissionsEnum>;
    /**
     * 
     * @type {Set<string>}
     * @memberof ExtendedUserDTO
     */
    reportsPermissions?: Set<ExtendedUserDTOReportsPermissionsEnum>;
    /**
     * 
     * @type {Set<string>}
     * @memberof ExtendedUserDTO
     */
    roles?: Set<ExtendedUserDTORolesEnum>;
    /**
     * 
     * @type {string}
     * @memberof ExtendedUserDTO
     */
    secondSurname?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedUserDTO
     */
    tenantId?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof ExtendedUserDTO
     */
    trackAndTracePermissions?: Set<ExtendedUserDTOTrackAndTracePermissionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof ExtendedUserDTO
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedUserDTO
     */
    validated?: ExtendedUserDTOValidatedEnum;
}


/**
 * @export
 */
export const ExtendedUserDTOLogisticOperationsPermissionsEnum = {
    InventoryMaterial: 'INVENTORY_MATERIAL',
    MaterialEntry: 'MATERIAL_ENTRY',
    MaterialExit: 'MATERIAL_EXIT'
} as const;
export type ExtendedUserDTOLogisticOperationsPermissionsEnum = typeof ExtendedUserDTOLogisticOperationsPermissionsEnum[keyof typeof ExtendedUserDTOLogisticOperationsPermissionsEnum];

/**
 * @export
 */
export const ExtendedUserDTOMasterdataPermissionsEnum = {
    Company: 'COMPANY',
    Customer: 'CUSTOMER',
    Family: 'FAMILY',
    FamilyType: 'FAMILY_TYPE',
    Location: 'LOCATION',
    Manufacturer: 'MANUFACTURER',
    Material: 'MATERIAL',
    Operator: 'OPERATOR',
    Owner: 'OWNER',
    Supplier: 'SUPPLIER',
    TileTemplate: 'TILE_TEMPLATE',
    User: 'USER',
    Warehouse: 'WAREHOUSE',
    WarehouseViewer: 'WAREHOUSE_VIEWER',
    Zone: 'ZONE'
} as const;
export type ExtendedUserDTOMasterdataPermissionsEnum = typeof ExtendedUserDTOMasterdataPermissionsEnum[keyof typeof ExtendedUserDTOMasterdataPermissionsEnum];

/**
 * @export
 */
export const ExtendedUserDTOReportsPermissionsEnum = {
    InventoryRegistry: 'INVENTORY_REGISTRY',
    InventoryReport: 'INVENTORY_REPORT',
    MovementsReport: 'MOVEMENTS_REPORT',
    RelabelReport: 'RELABEL_REPORT',
    StockReport: 'STOCK_REPORT',
    WarehouseStatus: 'WAREHOUSE_STATUS'
} as const;
export type ExtendedUserDTOReportsPermissionsEnum = typeof ExtendedUserDTOReportsPermissionsEnum[keyof typeof ExtendedUserDTOReportsPermissionsEnum];

/**
 * @export
 */
export const ExtendedUserDTORolesEnum = {
    MobAdmin: 'MOB_ADMIN',
    MobUser: 'MOB_USER',
    PortalAdmin: 'PORTAL_ADMIN',
    PortalUser: 'PORTAL_USER',
    SuperAdmin: 'SUPER_ADMIN'
} as const;
export type ExtendedUserDTORolesEnum = typeof ExtendedUserDTORolesEnum[keyof typeof ExtendedUserDTORolesEnum];

/**
 * @export
 */
export const ExtendedUserDTOTrackAndTracePermissionsEnum = {
    Hierarchy: 'HIERARCHY',
    Product: 'PRODUCT'
} as const;
export type ExtendedUserDTOTrackAndTracePermissionsEnum = typeof ExtendedUserDTOTrackAndTracePermissionsEnum[keyof typeof ExtendedUserDTOTrackAndTracePermissionsEnum];

/**
 * @export
 */
export const ExtendedUserDTOValidatedEnum = {
    Accepted: 'ACCEPTED',
    Denied: 'DENIED',
    Requested: 'REQUESTED'
} as const;
export type ExtendedUserDTOValidatedEnum = typeof ExtendedUserDTOValidatedEnum[keyof typeof ExtendedUserDTOValidatedEnum];


/**
 * Check if a given object implements the ExtendedUserDTO interface.
 */
export function instanceOfExtendedUserDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExtendedUserDTOFromJSON(json: any): ExtendedUserDTO {
    return ExtendedUserDTOFromJSONTyped(json, false);
}

export function ExtendedUserDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtendedUserDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activationToken': !exists(json, 'activationToken') ? undefined : json['activationToken'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'credentialsId': !exists(json, 'credentialsId') ? undefined : json['credentialsId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'enabledNFAEmail': !exists(json, 'enabledNFAEmail') ? undefined : json['enabledNFAEmail'],
        'enabledNFAOPT': !exists(json, 'enabledNFAOPT') ? undefined : json['enabledNFAOPT'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'firstSurname': !exists(json, 'firstSurname') ? undefined : json['firstSurname'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'logisticOperationsPermissions': !exists(json, 'logisticOperationsPermissions') ? undefined : json['logisticOperationsPermissions'],
        'masterdataPermissions': !exists(json, 'masterdataPermissions') ? undefined : json['masterdataPermissions'],
        'reportsPermissions': !exists(json, 'reportsPermissions') ? undefined : json['reportsPermissions'],
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
        'secondSurname': !exists(json, 'secondSurname') ? undefined : json['secondSurname'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'trackAndTracePermissions': !exists(json, 'trackAndTracePermissions') ? undefined : json['trackAndTracePermissions'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'validated': !exists(json, 'validated') ? undefined : json['validated'],
    };
}

export function ExtendedUserDTOToJSON(value?: ExtendedUserDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activationToken': value.activationToken,
        'active': value.active,
        'credentialsId': value.credentialsId,
        'email': value.email,
        'enabledNFAEmail': value.enabledNFAEmail,
        'enabledNFAOPT': value.enabledNFAOPT,
        'firstName': value.firstName,
        'firstSurname': value.firstSurname,
        'id': value.id,
        'logisticOperationsPermissions': value.logisticOperationsPermissions === undefined ? undefined : Array.from(value.logisticOperationsPermissions as Set<any>),
        'masterdataPermissions': value.masterdataPermissions === undefined ? undefined : Array.from(value.masterdataPermissions as Set<any>),
        'reportsPermissions': value.reportsPermissions === undefined ? undefined : Array.from(value.reportsPermissions as Set<any>),
        'roles': value.roles === undefined ? undefined : Array.from(value.roles as Set<any>),
        'secondSurname': value.secondSurname,
        'tenantId': value.tenantId,
        'trackAndTracePermissions': value.trackAndTracePermissions === undefined ? undefined : Array.from(value.trackAndTracePermissions as Set<any>),
        'username': value.username,
        'validated': value.validated,
    };
}

