import { Grid, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import IMMEntryWithDesc from "@domain/model/IMMEntryWithDesc";

interface IEntriesCounter {
  noMaterials: boolean;
  iMMaterialList: IMMEntryWithDesc[];
  entryTextSingular: string;
  entryTextPlural: string;
}

export const EntriesCounter = (props: IEntriesCounter) => {
  const { t } = useTranslation();
  const { noMaterials, iMMaterialList, entryTextSingular, entryTextPlural } = props;

  return (
    <Grid item pb={1} id={"logistic-materials-added"}>
      {noMaterials ? (
        <Trans>
          {t("logistic.immaterial.count.message", { materialCount: iMMaterialList.length ?? "0" })}{" "}
          {iMMaterialList.length === 1 ? t(entryTextSingular) : t(entryTextPlural)}
          {t("inbounds.immaterial.continue.message")}
        </Trans>
      ) : (
        <Typography id="logistic-no-materials" fontSize="1rem">
          {t("logistic.no.materials")}
        </Typography>
      )}
    </Grid>
  );
};
