import { useEffect, useRef } from "react";
import {
  ContainerDTO,
  CustomMovementEventFilter,
  GetWarehouseStatusReportByWarehouseAndFilterRequest,
  ListMovementEventPageRequest,
  ListZonesPaginatedRequest,
  MovementEventPageDTO
} from "@clients/aggrego-proxy";
import { User } from "@domain/model/User";
import Material from "@domain/model/Material";
import { IRepository } from "@movicoders/domain";
import { useListViewModel } from "@movicoders/ui";
import LocationEntity from "@domain/model/Location";
import { ObjectType } from "@movicoders/ui/lib/ViewModels/types";
import { TileRepository } from "@infrastructure/repositories/tile-repository";
import { ZoneRepository } from "@infrastructure/repositories/zone-repository";
import { useHandleSelectedWarehouse } from "@hooks/useHandleSelectedWarehouse";
import { MovementsRepository } from "@infrastructure/repositories/movements-repository";
import { ResultData } from "../../../home/components/interfaces/foundResultData-interface";
import { searchObjectName, searchObjectType } from "../../code-structure/dashboard-search-engine-types";
import { WarehouseStatusRepository } from "@infrastructure/repositories/warehouse-status-repository";

export const useDashboardSearchEngineListDataViewModel = (props: {
  filterObject: searchObjectType;
  filterType: searchObjectName;
}) => {
  const { filterObject, filterType: filterField } = props;

  const { selectedWarehouseCode, selectedWarehouse } = useHandleSelectedWarehouse();

  const { dataPaginated: movementsReportDataPaginated, getPaginated: getMovementsReportPaginated } = useListViewModel(
    MovementsRepository as unknown as ObjectType<IRepository<MovementEventPageDTO, null, ListMovementEventPageRequest>>
  );
  const { dataPaginated: warehouseStatusReportDataPaginated, getPaginated: getWSReportPaginated } =
    useListViewModel(WarehouseStatusRepository);

  const movementsFetch = useRef(getMovementsReportPaginated).current;
  const warehouseStatusFetch = useRef(getWSReportPaginated).current;

  //Call to receive all tiles. Sent as props to LocationCard & LocationDetailView(Form)
  const { data: tiles, fetch: fetchTiles } = useListViewModel(TileRepository);
  const tilesFetch = useRef(fetchTiles).current;

  //Call to receive all zones. Sent as props to ZoneCard & ZoneDetailView(Form)
  const { dataPaginated: zones, getPaginated: fetchZones } = useListViewModel(ZoneRepository);
  const zonesFetch = useRef(fetchZones).current;

  const getMovementsReport = (data?: ResultData) => {
    movementsFetch({
      limit: 10,
      offset: 0,
      customMovementEventFilter: data
        ? { searchText: data?.nameData, warehouse: selectedWarehouseCode }
        : { ...getCustomFilter(filterField), warehouse: selectedWarehouseCode }
    } as ListMovementEventPageRequest);
  };

  const getWarehouseStatusReport = (data?: ResultData) => {
    warehouseStatusFetch({
      limit: 10,
      offset: 0,
      warehouseCode: selectedWarehouseCode,
      statusReportFilterDTO: data ? { searchText: data?.nameData } : getCustomFilter(filterField)
    } as GetWarehouseStatusReportByWarehouseAndFilterRequest);
  };

  const getZones = () => {
    zonesFetch({
      limit: 1000000,
      offset: 0,
      status: "ACTIVE",
      customZoneFilter: { warehouseId: selectedWarehouse }
    } as ListZonesPaginatedRequest);
  };

  const getDatas = (data: ResultData) => {
    getMovementsReport(data);
    getWarehouseStatusReport(data);
  };

  function getCustomFilter(filterField: searchObjectName) {
    if (filterField === "material") return { [filterField as string]: (filterObject as Material).code };
    else if (filterField === "container") return { [filterField as string]: (filterObject as ContainerDTO).code };
    else if (filterField === "user") return { [filterField as string]: (filterObject as User).username };
    else if (filterField === "location")
      return { searchText: (filterObject as LocationEntity).code } as CustomMovementEventFilter;
  }

  useEffect(() => {
    getMovementsReport();
    getWarehouseStatusReport();
    tilesFetch({});
    getZones();
  }, []);

  return {
    movementsReportDataPaginated,
    warehouseStatusReportDataPaginated,
    getDatas,
    tiles,
    zones
  };
};
