import { useTranslation } from "react-i18next";
import { Grid, TextField } from "@mui/material";
import FamilyType from "@domain/model/FamilyType";
import { CREATE_ID, TIMEOUT_ID } from "@constants";
import React, { FC, useEffect, useState } from "react";
import { useTypeOfFamilyForm } from "./components/form/useTypeOfFamilyForm";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";
import { ButtonsDetail } from "@components/master-data-view/buttons/buttons-detail";
import { LoadingSpinnerDetailView } from "@components/loading-circular-progress/loading-spinner-detail-view";

interface IDetail {
  typeOfFamily: FamilyType;
  loading: boolean;
  onSave: (typeOfFamily: FamilyType) => void;
  onClose: () => void;
}

const TypeOfFamiliesDetailView: FC<IDetail> = ({ typeOfFamily: initialTypeOfFamily, loading, onSave, onClose }) => {
  const { t } = useTranslation();
  const { onChange, validateFormValues, formErrors, typeOfFamily } = useTypeOfFamilyForm(initialTypeOfFamily);

  const disabled = typeOfFamily.active !== undefined && typeOfFamily.active === false;
  const [pendingRequest, setPendingRequest] = useState<boolean>(false);

  useEffect(() => {
    setPendingRequest(false);
  }, [initialTypeOfFamily]);

  const renderInput = (id: string, name: string, value: string, label: string) => {
    return (
      <Grid item>
        <TextField
          error={formErrors.get(id) as boolean}
          helperText={(formErrors.get(id) as boolean) ? t("type.of.families.text.empty") : ""}
          id={id}
          name={name}
          disabled={disabled}
          className={disabled ? "disabled-input" : undefined}
          value={value}
          onChange={onChange}
          label={label}
        />
      </Grid>
    );
  };

  return (
    <React.Fragment>
      {(initialTypeOfFamily as FamilyType).id !== undefined && (initialTypeOfFamily as FamilyType).id !== TIMEOUT_ID ? (
        <AggregoDialog
          title={
            (initialTypeOfFamily as FamilyType).id === CREATE_ID
              ? t("type.of.families.detail.title")
              : t("type.of.families.detail.edit.title") + (initialTypeOfFamily as FamilyType).name
          }
          onClose={onClose}>
          <Grid container flexDirection="column" className="masterdata-box" rowSpacing={2}>
            {renderInput("type-of-family-name", "name", typeOfFamily.name ?? "", t("type.of.families.name"))}
            <Grid item>
              <ButtonsDetail
                handleClose={onClose}
                handleSave={() => {
                  if (validateFormValues()) {
                    setPendingRequest(true);
                    onSave(typeOfFamily);
                  }
                }}
                mode={typeOfFamily?.id === CREATE_ID ? "CREATE" : "EDIT"}
                disabled={disabled}
                pendingRequest={pendingRequest}
              />
            </Grid>
          </Grid>
        </AggregoDialog>
      ) : (
        <LoadingSpinnerDetailView loading={loading} selectedId={(initialTypeOfFamily as FamilyType)?.id ?? ""} />
      )}
    </React.Fragment>
  );
};

export default TypeOfFamiliesDetailView;
