/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomFilterDTO
 */
export interface CustomFilterDTO {
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof CustomFilterDTO
     */
    caseInsensitive?: { [key: string]: boolean; };
    /**
     * 
     * @type {string}
     * @memberof CustomFilterDTO
     */
    containerCode?: string;
    /**
     * 
     * @type {Date}
     * @memberof CustomFilterDTO
     */
    endDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CustomFilterDTO
     */
    initDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof CustomFilterDTO
     */
    inventoryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomFilterDTO
     */
    locationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomFilterDTO
     */
    materialCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomFilterDTO
     */
    operatorCode?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomFilterDTO
     */
    qty?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomFilterDTO
     */
    searchText?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomFilterDTO
     */
    user?: string;
}

/**
 * Check if a given object implements the CustomFilterDTO interface.
 */
export function instanceOfCustomFilterDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomFilterDTOFromJSON(json: any): CustomFilterDTO {
    return CustomFilterDTOFromJSONTyped(json, false);
}

export function CustomFilterDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomFilterDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'caseInsensitive': !exists(json, 'caseInsensitive') ? undefined : json['caseInsensitive'],
        'containerCode': !exists(json, 'containerCode') ? undefined : json['containerCode'],
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'initDate': !exists(json, 'initDate') ? undefined : (new Date(json['initDate'])),
        'inventoryCode': !exists(json, 'inventoryCode') ? undefined : json['inventoryCode'],
        'locationCode': !exists(json, 'locationCode') ? undefined : json['locationCode'],
        'materialCode': !exists(json, 'materialCode') ? undefined : json['materialCode'],
        'operatorCode': !exists(json, 'operatorCode') ? undefined : json['operatorCode'],
        'qty': !exists(json, 'qty') ? undefined : json['qty'],
        'searchText': !exists(json, 'searchText') ? undefined : json['searchText'],
        'user': !exists(json, 'user') ? undefined : json['user'],
    };
}

export function CustomFilterDTOToJSON(value?: CustomFilterDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'caseInsensitive': value.caseInsensitive,
        'containerCode': value.containerCode,
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'initDate': value.initDate === undefined ? undefined : (value.initDate.toISOString()),
        'inventoryCode': value.inventoryCode,
        'locationCode': value.locationCode,
        'materialCode': value.materialCode,
        'operatorCode': value.operatorCode,
        'qty': value.qty,
        'searchText': value.searchText,
        'user': value.user,
    };
}

