/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FamilyDTO } from './FamilyDTO';
import {
    FamilyDTOFromJSON,
    FamilyDTOFromJSONTyped,
    FamilyDTOToJSON,
} from './FamilyDTO';
import type { LocationDTO } from './LocationDTO';
import {
    LocationDTOFromJSON,
    LocationDTOFromJSONTyped,
    LocationDTOToJSON,
} from './LocationDTO';

/**
 * 
 * @export
 * @interface MaterialDTO
 */
export interface MaterialDTO {
    /**
     * 
     * @type {boolean}
     * @memberof MaterialDTO
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MaterialDTO
     */
    code?: string;
    /**
     * 
     * @type {Array<FamilyDTO>}
     * @memberof MaterialDTO
     */
    families?: Array<FamilyDTO>;
    /**
     * 
     * @type {string}
     * @memberof MaterialDTO
     */
    id?: string;
    /**
     * 
     * @type {Array<LocationDTO>}
     * @memberof MaterialDTO
     */
    locations?: Array<LocationDTO>;
    /**
     * 
     * @type {number}
     * @memberof MaterialDTO
     */
    minStock?: number;
    /**
     * 
     * @type {string}
     * @memberof MaterialDTO
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof MaterialDTO
     */
    unitPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof MaterialDTO
     */
    uom?: string;
}

/**
 * Check if a given object implements the MaterialDTO interface.
 */
export function instanceOfMaterialDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MaterialDTOFromJSON(json: any): MaterialDTO {
    return MaterialDTOFromJSONTyped(json, false);
}

export function MaterialDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaterialDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'families': !exists(json, 'families') ? undefined : ((json['families'] as Array<any>).map(FamilyDTOFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'locations': !exists(json, 'locations') ? undefined : ((json['locations'] as Array<any>).map(LocationDTOFromJSON)),
        'minStock': !exists(json, 'minStock') ? undefined : json['minStock'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'unitPrice': !exists(json, 'unitPrice') ? undefined : json['unitPrice'],
        'uom': !exists(json, 'uom') ? undefined : json['uom'],
    };
}

export function MaterialDTOToJSON(value?: MaterialDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'code': value.code,
        'families': value.families === undefined ? undefined : ((value.families as Array<any>).map(FamilyDTOToJSON)),
        'id': value.id,
        'locations': value.locations === undefined ? undefined : ((value.locations as Array<any>).map(LocationDTOToJSON)),
        'minStock': value.minStock,
        'name': value.name,
        'unitPrice': value.unitPrice,
        'uom': value.uom,
    };
}

