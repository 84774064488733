import { useTranslation } from "react-i18next";
import { generateUUID } from "three/src/math/MathUtils";
import { CompanyDTOLicenceEnum, StatusReportDTO } from "@clients/aggrego-proxy";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";
import { Button, DialogContent, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import { materialConsumptionDialogStyles } from "./material-consumption-dialog.styles";
import { IUserService, UserService } from "@domain/services/IUserService";
import { useResolve } from "@movicoders/di";

export const FailedConsumptionDialog = (props: {
  open: boolean;
  onClose: () => void;
  failedstatusReportList: StatusReportDTO[];
}) => {
  const { open, onClose, failedstatusReportList } = props;

  const { t } = useTranslation();
  const styles = materialConsumptionDialogStyles();
  const userService = useResolve<IUserService>(UserService);
  const isIM = userService.user?.companyLicense === CompanyDTOLicenceEnum.Im;
  const isWMC = userService.user?.companyLicense === CompanyDTOLicenceEnum.WmWithContainer;

  return open ? (
    <AggregoDialog title={t("warehouse.status.failed.consumption.title")} maxWidth="md" onClose={onClose}>
      <Grid container display="flex" spacing={1} justifyContent="space-between">
        <Grid item xs={12}>
          <Typography variant="cardtitle">{t("warehouse.status.failed.consumption.description")}</Typography>
        </Grid>
        <DialogContent sx={{ maxHeight: "25rem", overflowY: "auto", mt: "1.5rem" }}>
          <List>
            <ListItem key={generateUUID()}>
              <Grid container display={"block"}>
                {failedstatusReportList.map((report, index) => (
                  <Grid
                    container
                    key={generateUUID()}
                    display={"flex"}
                    sx={{ backgroundColor: index % 2 === 0 ? "inherit" : "#ECF0F5" }}
                    padding={"0.5rem"}>
                    {/* GRID INDEX */}
                    <Grid item xs={1.5} alignContent="center">
                      <ListItemText
                        primary={
                          <Typography variant="cardtitle" sx={styles.index_grid_container}>
                            {index + 1}
                          </Typography>
                        }
                      />
                    </Grid>
                    {/* GRID CONTAINER + MATERIAL + DESC */}
                    <Grid item xs={5.8}>
                      <Grid container display="block">
                        {/* CONTAINER GRID */}
                        {isWMC ? (
                          <Grid item xs={12} alignItems={"start"}>
                            <ListItemText
                              primary={
                                <span>
                                  <Typography component="span" sx={styles.bold_field_name}>
                                    {`${t("warehouse.status.container")}:`}
                                  </Typography>
                                  {` ${report.container}`}
                                </span>
                              }
                            />
                          </Grid>
                        ) : (
                          !isIM && (
                            <Grid item xs={12} alignItems={"start"}>
                              <ListItemText
                                primary={
                                  <span>
                                    <Typography component="span" sx={styles.bold_field_name}>
                                      {`${t("warehouse.status.location")}:`}
                                    </Typography>
                                    {` ${report.location}`}
                                  </span>
                                }
                              />
                            </Grid>
                          )
                        )}
                        {/* MATERIAL CODE GRID */}
                        <Grid item xs={12} alignItems={"start"}>
                          <ListItemText
                            primary={
                              <span>
                                <Typography component="span" sx={styles.bold_field_name}>
                                  {`${t("warehouse.status.code")}:`}
                                </Typography>
                                {` ${report.material}`}
                              </span>
                            }
                          />
                        </Grid>
                        {/* MATERIAL DESCRIPTION GRID */}
                        <Grid item xs={12}>
                          <ListItemText
                            primary={
                              <span>
                                <Typography component="span" sx={styles.bold_field_name}>
                                  {`${t("warehouse.status.description")}:`}
                                </Typography>
                                {` ${report.description}`}
                              </span>
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* GRID QUANTITY */}
                    <Grid item xs={4} alignContent="center" textAlign={"end"}>
                      <ListItemText
                        primary={
                          <span>
                            <Typography component="span" sx={styles.bold_field_name}>
                              {report.qty?.toString()}
                            </Typography>{" "}
                            {report.uom ? report.uom : "-"}
                          </span>
                        }
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </ListItem>
          </List>
        </DialogContent>
        <Grid item id="grid-item-failed-burron" display="flex" xs={12} alignContent={"end"} justifyContent={"end"}>
          <Button variant="primary" onClick={onClose}>
            {t("button.confirm")}
          </Button>
        </Grid>
      </Grid>
    </AggregoDialog>
  ) : (
    <></>
  );
};
