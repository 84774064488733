import { FamilyTypeDTO } from "@clients/aggrego-proxy/models";

export default class FamilyType {
  id?: string;
  name: string;
  active: boolean;

  constructor(name: string, active: boolean, id?: string) {
    this.id = id;
    this.name = name;
    this.active = active;
  }

  static toTypeOfFamilyModel(typeOfFamily: FamilyTypeDTO) {
    const result = new FamilyType(typeOfFamily.name ?? "", typeOfFamily.active ?? true, typeOfFamily.id ?? "");
    return result;
  }

  static toFamilyTypeDTO(typeOfFamily: FamilyType) {
    const result: FamilyTypeDTO = {};
    result.id = typeOfFamily.id;
    result.name = typeOfFamily.name;
    result.active = typeOfFamily.active;

    return result;
  }
}
