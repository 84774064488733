import { useEffect, useState } from "react";
import { cifHelper } from "@utils/cif-helper";
import Stakeholder from "@domain/model/Stakeholder";
import { EXTRA_LENGTH, MAX_LENGTH, MEDIUM_LENGTH, SHORT_LENGTH } from "../../components/detail/stakeholder-detail-constants";

export const useStakeholderInputValidation = (modifiedStakeholder: Stakeholder) => {
  const INITIAL_ERROR_MAP = new Map([
    ["stakeholder-fiscalId", false],
    ["stakeholder-name", false],
    ["stakeholder-address", false],
    ["stakeholder-city", false],
    ["stakeholder-postalCode", false],
    ["stakeholder-country", false],
    ["stakeholder-phone", false],
    ["stakeholder-email", false],
    ["stakeholder-contact", false],
    ["stakeholder-comments", false]
  ]);

  const [formErrors, setFormErrors] = useState<Map<string, boolean>>(INITIAL_ERROR_MAP);

  useEffect(() => {
    setFormErrors(INITIAL_ERROR_MAP);
  }, [modifiedStakeholder]);

  const validateFormValues = () => {
    let fiscalIdValid;
    if (modifiedStakeholder.isCustomer || modifiedStakeholder.isSupplier) {
      fiscalIdValid =
        modifiedStakeholder.fiscalId !== null &&
        modifiedStakeholder.fiscalId !== undefined &&
        modifiedStakeholder.fiscalId !== "" &&
        modifiedStakeholder.fiscalId.length <= MEDIUM_LENGTH &&
        cifHelper(modifiedStakeholder.fiscalId);
    } else {
      if (modifiedStakeholder.fiscalId) {
        fiscalIdValid = modifiedStakeholder.fiscalId.length <= MEDIUM_LENGTH && cifHelper(modifiedStakeholder.fiscalId);
      } else {
        fiscalIdValid = true;
      }
    }
    const nameValid =
      modifiedStakeholder.name !== null &&
      modifiedStakeholder.name !== undefined &&
      modifiedStakeholder.name !== "" &&
      modifiedStakeholder.name.length <= MAX_LENGTH;
    const addressValid =
      modifiedStakeholder.address === undefined ||
      (modifiedStakeholder.address?.length !== undefined && modifiedStakeholder.address?.length <= MAX_LENGTH);
    const cityValid =
      modifiedStakeholder.city === undefined ||
      (modifiedStakeholder.city?.length !== undefined && modifiedStakeholder.city?.length <= MAX_LENGTH);
    const postalCodeValid =
      modifiedStakeholder.postalCode === undefined ||
      (modifiedStakeholder.postalCode?.length !== undefined && modifiedStakeholder.postalCode?.length <= SHORT_LENGTH);
    const countryValid =
      modifiedStakeholder.country === undefined ||
      (modifiedStakeholder.country?.length !== undefined && modifiedStakeholder.country?.length <= MAX_LENGTH);
    const phoneValid =
      modifiedStakeholder.phone === undefined ||
      (modifiedStakeholder.phone?.length !== undefined && modifiedStakeholder.phone?.length <= MEDIUM_LENGTH);
    const emailValid =
      modifiedStakeholder.email === undefined ||
      (modifiedStakeholder.email?.length !== undefined && modifiedStakeholder.email?.length <= MAX_LENGTH);
    const contactPersonValid =
      modifiedStakeholder.contactPerson === undefined ||
      (modifiedStakeholder.contactPerson?.length !== undefined && modifiedStakeholder.contactPerson?.length <= MAX_LENGTH);
    const commentsValid =
      modifiedStakeholder.comment === undefined ||
      (modifiedStakeholder.comment?.length !== undefined && modifiedStakeholder.comment?.length <= EXTRA_LENGTH);
    const stakeholderSelectionValid =
      modifiedStakeholder.isCustomer === true ||
      modifiedStakeholder.isManufacturer === true ||
      modifiedStakeholder.isOwner === true ||
      modifiedStakeholder.isSupplier === true;
    setFormErrors(
      new Map([
        ["stakeholder-fiscalId", !fiscalIdValid],
        ["stakeholder-name", !nameValid],
        ["stakeholder-address", !addressValid],
        ["stakeholder-city", !cityValid],
        ["stakeholder-postalCode", !postalCodeValid],
        ["stakeholder-country", !countryValid],
        ["stakeholder-phone", !phoneValid],
        ["stakeholder-email", !emailValid],
        ["stakeholder-contact", !contactPersonValid],
        ["stakeholder-comments", !commentsValid],
        ["stakeholder-stakeholders", !stakeholderSelectionValid]
      ])
    );
    return (
      fiscalIdValid &&
      nameValid &&
      addressValid &&
      cityValid &&
      postalCodeValid &&
      countryValid &&
      phoneValid &&
      emailValid &&
      contactPersonValid &&
      commentsValid &&
      stakeholderSelectionValid
    );
  };

  return { formErrors, validateFormValues };
};
