import React from "react";
import { EntriesTabs } from "../components/entries-tabs";
import { EntriesTitle } from "../components/entries-title";
import { EntriesSyncContent } from "../components/entries-sync-content";
import { OutletContainer } from "@components/outlet-container/outlet-container";
import { useLogisticSyncViewModel } from "../../hooks/useLosgisticSyncViewModel";
import { LogisticErrorDialog } from "../../components/logistic-error-dialog/logistic-error-dialog";
import { useHandleErrorDialog } from "../../components/logistic-error-dialog/useHandleErrorDialog";

export const InboundsSyncView = () => {
  const { cardsArray, handleTabSelect, handleReturnBackToMainInboundsView } = useLogisticSyncViewModel();
  const { errorMessage, handleErrorDialog } = useHandleErrorDialog();

  return (
    <>
      <OutletContainer>
        <React.Fragment>
          <EntriesTitle text="inbounds" textId="inbounds-title" iconId="material-in" />
          <EntriesTabs onClick={handleTabSelect} />
          <EntriesSyncContent
            buttonId="inbounds-sync-return-button"
            cardsArray={cardsArray}
            handleErrorDialog={handleErrorDialog}
            handleReturnBackTo={handleReturnBackToMainInboundsView}
          />
        </React.Fragment>
      </OutletContainer>
      <LogisticErrorDialog onClose={() => handleErrorDialog("CLOSE")} error={errorMessage} />
    </>
  );
};
