import { GridProps, TooltipComponentsPropsOverrides } from "@mui/material";
import { COLORS } from "@theme"
import { IconProps } from "react-icomoon"


const commonIconStyles: IconProps = {
  icon: "",
  size: "24px",
  style: { minWidth: "24px", marginRight: "10px" }
}

export const doneIconStyles: IconProps = {
  ...commonIconStyles,
  icon: "done",
  color: COLORS.success
};

export const notDoneIconStyles: IconProps = {
  ...commonIconStyles,
  icon: "close",
  color: COLORS.alert
};

export const validationLineStyles: GridProps = {
  item: true,
  container: true,
  direction: "row",
  alignItems: "center",
  wrap: "nowrap"
}

export const tooltipStyles: TooltipComponentsPropsOverrides = {
  sx: {
    backgroundColor: 'white',
    color: "black",
    border: `solid ${COLORS.secondary} 1px`
  },
  style: { marginLeft: "20px" }
}
