import { COLORS } from "@theme";
import { ReactNode } from "react";
import { Backdrop, CircularProgress, Link, Typography } from "@mui/material";

type TSplash = {
  loading: boolean;
  children?: ReactNode;
};

//Because this component is rendered while react is loading, it needs to have the styles in here.
export const Splash = ({ loading, children }: TSplash) => {
  return (
    <Backdrop
      open={loading ?? true}
      id="splash"
      style={{
        backgroundImage: "url('/assets/images/background-splash.jpg')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        flex: 1,
        flexDirection: "column",
        zIndex: 6
      }}>
      <img src="/assets/images/logo-expanded-white.svg" alt="movicoders-logo" width="300rem" />
      <CircularProgress thickness={3.6} size="80px" style={{ color: COLORS.secondary, marginTop: "3rem" }} />
      {children}
      <Link
        href="https://movicoders.com"
        target="_blank"
        underline="hover"
        rel="noreferrer"
        variant="copyright"
        style={{
          fontSize: "0.75rem",
          color: COLORS.lightgray,
          bottom: "2rem",
          position: "absolute"
        }}>
        <Typography>Aggrego &reg; Copyright &copy; Movicoders {new Date().getUTCFullYear().toString()}</Typography>
      </Link>
    </Backdrop>
  );
};
