import { useResolve } from "@movicoders/di";
import { SelectChangeEvent } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { ILastPageService, LastPageService } from "@domain/services/ILastPageService";
import { isValidDate, transformDateString } from "@utils/date-helper";
import { ProductsBasicFilterDTO } from "@clients/aggrego-proxy";
import { LastPageState } from "@domain/model/LastPageState";
import { HierarchyFiltersService, IHierarchyFiltersService } from "@domain/services/IHierarchyFiltersService";
import { HierarchyParentsFilterEnum, THierarchyParentsFilterEnum } from "../../hooks/useProductsViewModel";
import { IUserService, UserService } from "@domain/services/IUserService";

export const SearchModeEnum = {
  Serial: "SERIAL",
  SerialRange: "SERIAL_RANGE",
  SerialMaterial: "SERIAL_MATERIAL",
  MaterialBatch: "MATERIAL_BATCH",
  ManufactureOrder: "MANUFACTURE_ORDER",
  ManufacturingDate: "MANUFACTURING_DATE",
  ManufacturingDateRange: "MANUFACTURING_DATE_RANGE"
} as const;
export type TSearchModeEnum = (typeof SearchModeEnum)[keyof typeof SearchModeEnum];

export const useProductSearchBar = (
  searchMode: TSearchModeEnum,
  setSearchMode: Dispatch<SetStateAction<TSearchModeEnum>>,
  selectedParentFilter: THierarchyParentsFilterEnum,
  setSelectedParentFilter: Dispatch<SetStateAction<THierarchyParentsFilterEnum>>
) => {
  // Filter and pagination related variables
  const lastPageService = useResolve<ILastPageService>(LastPageService);
  const lastPagePersistedState = lastPageService.getCurrentState() ?? new LastPageState();
  const hierarchyFiltersService = useResolve<IHierarchyFiltersService>(HierarchyFiltersService);
  const hierarchyFiltersPersistedState = hierarchyFiltersService.persistedState;

  const [productFilter, setProductFilter] = useState<ProductsBasicFilterDTO>({});

  const [mode, setMode] = useState("simple");
  const [firstSearchParameter, setFirstSearchParameter] = useState<string | undefined>(
    hierarchyFiltersPersistedState?.firstParameter ?? undefined
  );
  const [secondSearchParameter, setSecondSearchParameter] = useState<string | undefined>(
    hierarchyFiltersPersistedState?.secondParameter ?? undefined
  );
  const [filterArray, setFilterArray] = useState<string[]>([]);

  // Error handling
  let errorsInForm = true;
  const [firstFieldError, setFirstFieldError] = useState({ isError: false, message: "" });
  const [secondFieldError, setSecondFieldError] = useState({ isError: false, message: "" });

  // Gets if batch or versionlabel is showed (teltronic)
  const userService = useResolve<IUserService>(UserService);
  const hasVersionLabel = userService.user?.hasVersion ?? false;

  const resetErrors = () => {
    setFirstFieldError({ isError: false, message: "" });
    setSecondFieldError({ isError: false, message: "" });
  };

  const setErrors = () => {
    let error = false;
    //Check if first field is empty
    if (firstSearchParameter === undefined || firstSearchParameter?.length === 0) {
      setFirstFieldError({ isError: true, message: "products.search.empty" });
      error = true;
    }
    //if double filter mode, check if second field is empty
    if ((mode === "double" && secondSearchParameter === undefined) || secondSearchParameter?.length === 0) {
      setSecondFieldError({ isError: true, message: "products.search.empty" });
      error = true;
    }
    //if date or date range mode...
    if (searchMode === SearchModeEnum.ManufacturingDate || searchMode === SearchModeEnum.ManufacturingDateRange) {
      //if something typed/entered on first field, check if is a valid date
      if (firstSearchParameter !== undefined && !isValidDate(firstSearchParameter + "")) {
        setFirstFieldError({ isError: true, message: "products.date.invalid" });
        error = true;
      }
      //if something typed/entered on second field, check if is a valid date
      if (secondSearchParameter !== undefined && !isValidDate(secondSearchParameter + "")) {
        setSecondFieldError({ isError: true, message: "products.date.invalid" });
        error = true;
      }
    }
    //if date range mode and first and second fields are typed/entered text (and as checked before, are correct dates),
    //checks if first date is greater that second date
    if (
      searchMode === SearchModeEnum.ManufacturingDateRange &&
      firstSearchParameter &&
      secondSearchParameter &&
      new Date(firstSearchParameter) > new Date(secondSearchParameter)
    ) {
      setFirstFieldError({ isError: true, message: "products.init.date.over.final" });
      setSecondFieldError({ isError: true, message: "products.final.date.below.init" });
      error = true;
    }
    return error;
  };

  const onChangeSelectSearchBy = (event: SelectChangeEvent<string>) => {
    resetErrors();
    setSearchMode(event.target.value as SetStateAction<TSearchModeEnum>);
    setFirstSearchParameter(undefined);
    setSecondSearchParameter(undefined);
    setProductFilter({});
  };

  const onChangeTextField = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: string, order: number) => {
    if (order === 1) {
      setFirstSearchParameter(event.target.value);
    } else {
      setSecondSearchParameter(event.target.value);
    }
  };

  const onChangeDatePicker = (value: unknown | null, order: number) => {
    if (order === 1) {
      setFirstSearchParameter(value + "");
    } else {
      setSecondSearchParameter(value + "");
    }
  };

  const handleSearch = () => {
    resetErrors();
    errorsInForm = setErrors();
    const isOnlyParentsSelected = optionSelected === HierarchyParentsFilterEnum.ParentsOnly;
    if (!errorsInForm) {
      let newProductFilter: ProductsBasicFilterDTO = {};
      //and filter has only one field to fill...
      if (secondSearchParameter === undefined) {
        //if filter selected is 'manufacturingDate', filter object should be constructed with two parameters...
        if (searchMode === SearchModeEnum.ManufacturingDate) {
          const selectedDate = transformDateString(firstSearchParameter ?? "");
          newProductFilter = {
            [filterArray[0]]: new Date(selectedDate.startOfDay),
            [filterArray[1]]: new Date(selectedDate.endOfDay),
            onlyParent: isOnlyParentsSelected
          };
          //if any other simple filter is selected...
        } else {
          newProductFilter = {
            [filterArray[0]]: firstSearchParameter,
            onlyParent: isOnlyParentsSelected
          };
        }
        //else, filter has two fields to fill...
      } else {
        //if filter selected is 'manufacturingDateRange', filter object should be constructed with two parameters...
        if (searchMode === SearchModeEnum.ManufacturingDateRange) {
          newProductFilter = {
            [filterArray[0]]: new Date(firstSearchParameter ?? ""),
            [filterArray[1]]: new Date(secondSearchParameter ?? ""),
            onlyParent: isOnlyParentsSelected
          };
          //if any other double filter is selected...
        } else {
          newProductFilter = {
            [filterArray[0]]: firstSearchParameter,
            [filterArray[1]]: secondSearchParameter,
            onlyParent: isOnlyParentsSelected
          };
        }
      }
      //doSearch
      newProductFilter.onlyParent = isOnlyParentsSelected;
      setProductFilter(newProductFilter);
      lastPageService.saveLastPageState({ ...lastPagePersistedState, filters: newProductFilter });
      hierarchyFiltersService.saveHierarchyFiltersState({
        ...hierarchyFiltersPersistedState,
        searchMode: searchMode,
        firstParameter: firstSearchParameter ?? "",
        secondParameter: secondSearchParameter ?? "",
        filter: newProductFilter
      });
    }
  };

  const resetSearch = () => {
    setFirstSearchParameter(undefined);
    setSecondSearchParameter(undefined);
    setFirstFieldError({ isError: false, message: "" });
    setSecondFieldError({ isError: false, message: "" });
    optionSelected = HierarchyParentsFilterEnum.All;
    setSelectedParentFilter(optionSelected);
    setProductFilter({});
    lastPageService.saveLastPageState({ ...lastPagePersistedState, filters: {} });
    hierarchyFiltersService.clearHierarchyFiltersState();
  };

  useEffect(() => {
    switch (searchMode) {
      case SearchModeEnum.Serial:
        setMode("simple");
        setFilterArray(["serialNumber"]);
        break;
      case SearchModeEnum.SerialRange:
        setMode("double");
        setFilterArray(["serialNumberInit", "serialNumberEnd"]);
        break;
      case SearchModeEnum.SerialMaterial:
        setMode("double");
        setFilterArray(["serialNumber", "materialCode"]);
        break;
      case SearchModeEnum.MaterialBatch:
        setMode("double");
        setFilterArray(["materialCode", "batch"]);
        break;
      case SearchModeEnum.ManufactureOrder:
        setMode("simple");
        setFilterArray(["manufactureOrder"]);
        break;
      case SearchModeEnum.ManufacturingDate:
        setMode("simple");
        setFilterArray(["manufacturingInitDate", "manufacturingEndDate"]);
        break;
      case SearchModeEnum.ManufacturingDateRange:
        setMode("double");
        setFilterArray(["manufacturingInitDate", "manufacturingEndDate"]);
        break;
    }
    hierarchyFiltersService.saveHierarchyFiltersState({
      ...hierarchyFiltersPersistedState,
      searchMode: searchMode
    });
  }, [searchMode]);

  let optionSelected: THierarchyParentsFilterEnum;

  const onChangeSelectedParentFilter = (event: SelectChangeEvent<string>) => {
    optionSelected = event.target.value as THierarchyParentsFilterEnum;
    const isOnlyParentsSelected = optionSelected === HierarchyParentsFilterEnum.ParentsOnly;
    setSelectedParentFilter(optionSelected);
    setProductFilter(prevFilter => ({
      ...prevFilter,
      onlyParent: isOnlyParentsSelected
    }));
    lastPageService.saveLastPageState({
      ...lastPagePersistedState,
      filters: {
        ...productFilter,
        onlyParent: isOnlyParentsSelected
      }
    });
    hierarchyFiltersService.saveHierarchyFiltersState({
      ...hierarchyFiltersPersistedState,
      searchMode: searchMode,
      firstParameter: firstSearchParameter ?? "",
      secondParameter: secondSearchParameter ?? "",
      filter: {
        ...productFilter,
        onlyParent: isOnlyParentsSelected
      }
    });
  };

  return {
    setMode,
    searchMode,
    firstSearchParameter,
    secondSearchParameter,
    firstFieldError,
    secondFieldError,
    onChangeSelectSearchBy,
    onChangeTextField,
    onChangeDatePicker,
    handleSearch,
    resetSearch,
    onChangeSelectedParentFilter,
    hasVersionLabel
  };
};
