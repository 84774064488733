import { COLORS } from "@theme";
import Family from "@domain/model/Family";
import Icon from "@components/icons/icon";
import Material from "@domain/model/Material";
import { useTranslation } from "react-i18next";
import { CREATE_ID, TIMEOUT_ID } from "@constants";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useMaterialsForm } from "./components/form/useMaterialsForm";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";
import { ButtonsDetail } from "@components/master-data-view/buttons/buttons-detail";
import { Autocomplete, Button, Chip, Grid, TextField, Typography } from "@mui/material";
import { LoadingSpinnerDetailView } from "@components/loading-circular-progress/loading-spinner-detail-view";

interface IDetail {
  material: Material;
  families: Family[];
  loading: boolean;
  onSave: (material: Material) => void;
  onClose: () => void;
}

export const MaterialsDetailView = (props: IDetail) => {
  const { material: initialMaterial, families, loading, onSave, onClose } = props;
  const {
    onChange,
    onChangeNumeric,
    material,
    formErrors,
    validateFormValues,
    selectedFamilies,
    selectedUom,
    onChangeAutocomplete
  } = useMaterialsForm({
    selectedMaterial: initialMaterial,
    onSave: onSave
  });

  const { t } = useTranslation();

  const disabled = material.active !== undefined && material.active === false;
  const mode = (initialMaterial as Material).id === CREATE_ID ? "CREATE" : "EDIT";
  const [pendingRequest, setPendingRequest] = useState<boolean>(false);

  const forceDisable = (name: string) => {
    if (initialMaterial.active !== undefined && name !== "code" && name !== "minStock") {
      return !material.active;
    } else {
      return (name === "code" && mode === "EDIT") || name === "minStock";
    }
  };

  useEffect(() => {
    setPendingRequest(false);
  }, [initialMaterial]);

  const renderInput = (id: string, name: string, value: string | number, label: string, type?: string) => {
    return (
      <TextField
        error={formErrors.get(id) as boolean}
        helperText={
          (formErrors.get(id) as boolean)
            ? type === "number"
              ? t(`material.dialog.empty.field.number`)
              : t(`material.dialog.empty.field.text`)
            : ""
        }
        id={id}
        value={type === "number" && value === "" ? "0" : value}
        name={name}
        onChange={type === "number" ? onChangeNumeric : onChange}
        label={label}
        disabled={forceDisable(name)}
        className={forceDisable(name) ? "disabled-input" : "blue-border-input"}
      />
    );
  };

  return (
    <React.Fragment>
      {(initialMaterial as Material).id !== undefined && (initialMaterial as Material).id !== TIMEOUT_ID ? (
        <AggregoDialog
          title={
            (initialMaterial as Material).id === CREATE_ID
              ? t("material.detail.title")
              : t("material.detail.edit.title") + (initialMaterial as Material).name
          }
          onClose={onClose}>
          <Grid
            container
            className="masterdata-box"
            justifyContent="space-between"
            rowSpacing={2}
            columnSpacing={1}
            sx={{ ml: -0.5 }}>
            <Grid item xs={12} md={6}>
              {renderInput("material-material", "name", material.name ?? "", t("material.material"))}
            </Grid>
            <Grid item xs={12} md={6}>
              {renderInput("material-code", "code", material.code ?? "", t("material.code"))}
            </Grid>
            <Grid item xs={12}>
              {/* MULTIPLE SELECT FOR FAMILY TYPE */}
              <Autocomplete
                multiple
                id="material-families-select"
                value={selectedFamilies ?? []}
                disabled={disabled}
                onChange={(event, value) => {
                  onChangeAutocomplete(event, value, "families");
                }}
                noOptionsText={t("material.no.families")}
                openOnFocus
                options={families}
                disableCloseOnSelect
                ListboxProps={{ style: { display: disabled ? "none" : "inherit" } }}
                getOptionLabel={option => option.name}
                fullWidth
                limitTags={1}
                disableListWrap
                renderOption={(props, option, { selected }) => (
                  <li {...props} style={{ backgroundColor: selected ? COLORS.secondaryOpacity : "transparent" }}>
                    {<Typography variant="autocomplete_text">{option.name}</Typography>}
                  </li>
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderTags={(families, getTagProps) =>
                  families.map((family: Family, index: number) => (
                    <Chip
                      size="small"
                      color="secondary"
                      variant="outlined"
                      label={family.name}
                      {...getTagProps({ index })}
                      key={family.id}
                      sx={{ color: COLORS.secondary }}
                    />
                  ))
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t("material.family")}
                    disabled={disabled}
                    className={disabled ? "disabled-input" : "blue-border-input"}
                    error={formErrors.get(params.id) as boolean}
                    helperText={(formErrors.get(params.id) as boolean) ? t(`material.dialog.empty.field.text`) : ""}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3.5}>
              {renderInput(
                "material-price",
                "unitPrice",
                material.unitPrice ? material.unitPrice.toString() : "",
                t("material.price"),
                "number"
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={3.5}>
              {renderInput(
                "material-quant",
                "minStock",
                material.minStock ? material.minStock.toString() : "0",
                t("material.min.stock"),
                "number"
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              {/* SELECT FOR UNIT OF MEASURE */}
              <Autocomplete
                id="material-uom-select"
                value={material.uom ? selectedUom : t("material.measure.units")}
                disableClearable
                disabled={disabled}
                options={["Unidades", "Kilogramos", "Litros"]}
                getOptionLabel={(option: string) => {
                  if (option.toLowerCase() === "unidades" || option.toLowerCase() === "uds") {
                    return t("material.measure.units");
                  } else if (option.toLowerCase() === "kilogramos" || option.toLowerCase() === "kg") {
                    return t("material.measure.kilograms");
                  } else {
                    return t("material.measure.litres");
                  }
                }}
                isOptionEqualToValue={(option, value) => {
                  if (value === "") return true;
                  return option === value;
                }}
                onChange={(event: SyntheticEvent<Element, Event>, value: string | null) => {
                  onChangeAutocomplete(event, value ?? "", "uom");
                }}
                renderInput={params => (
                  <TextField
                    error={formErrors.get(params.id) as boolean}
                    helperText={(formErrors.get(params.id) as boolean) ? t(`material.dialog.empty.field.text`) : ""}
                    name={"uom"}
                    {...params}
                    label={t("material.uom")}
                    className={
                      material?.active !== undefined && material.active === false ? "disabled-input" : "blue-border-input"
                    }
                    InputProps={{
                      ...params.InputProps
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Button
                id="material-notify-button"
                disabled={true}
                variant="notify"
                sx={{ marginTop: "-0.3rem", width: "100%" }}
                startIcon={<Icon icon="bell" />}>
                {t("material.notify")}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <ButtonsDetail
                handleClose={onClose}
                handleSave={() => {
                  if (validateFormValues()) {
                    setPendingRequest(true);
                    onSave(material);
                  }
                }}
                mode={mode}
                disabled={disabled}
                pendingRequest={pendingRequest}
              />
            </Grid>
          </Grid>
        </AggregoDialog>
      ) : (
        <LoadingSpinnerDetailView loading={loading} selectedId={(initialMaterial as Material)?.id ?? ""} />
      )}
    </React.Fragment>
  );
};
