import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Grid, TextField, Typography } from "@mui/material";
import IMMEntryWithDesc from "@domain/model/IMMEntryWithDesc";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";
import { ButtonsDetail } from "@components/master-data-view/buttons/buttons-detail";

interface IDetail {
  title: string;
  entry: IMMEntryWithDesc;
  closeRequest: () => void;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  onChangeNumeric(event: React.ChangeEvent<HTMLInputElement>): void;
  handleEdit: () => void;
  handleSave: () => void;
  mode: string;
  dialogErrors: Map<string, boolean>;
  validateDialogValues: () => boolean;
}

export const InventoryMaterialDetailView: FC<IDetail> = ({
  title,
  entry,
  closeRequest,
  onChange,
  onChangeNumeric,
  handleEdit,
  handleSave,
  mode,
  dialogErrors,
  validateDialogValues
}) => {
  const { t } = useTranslation();

  const disabled = false;

  const renderInput = (id: string, name: string, value: string | number, label: string, type?: string) => {
    return (
      <TextField
        id={id}
        name={name}
        className={disabled ? "disabled-input" : "blue-border-input"}
        disabled={disabled}
        value={value}
        onChange={type === "number" ? onChangeNumeric : onChange}
        label={label}
        multiline={name === "comment"}
        rows={3}
        error={dialogErrors.get(id) as boolean}
        helperText={
          dialogErrors.get(id) &&
          (name === "comment"
            ? t("inventory.material.dialog.too.large.field.text", { maxLength: 1000 })
            : t("inventory.material.dialog.empty.field.number"))
        }
      />
    );
  };

  return (
    <AggregoDialog title={t(title)} onClose={closeRequest}>
      <Grid
        container
        className="masterdata-box"
        justifyContent="space-between"
        rowSpacing={2}
        columnSpacing={1}
        sx={{ ml: -0.5 }}>
        {/* name */}
        <Grid item xs={12}>
          <Typography variant="cardlabel" textAlign="left">
            {t("logistic.material")}
          </Typography>
          <Typography variant="cardvalue" data-cy={`materialDescription`} sx={{ wordBreak: "keep-all" }}>
            {`${entry.materialCode} - ${entry.materialDescription}`}
          </Typography>
        </Grid>
        {/* stock */}
        <Grid item xs={12} md={6} sx={{ mt: "-0.5rem" }}>
          <Typography variant="cardlabel" textAlign="left">
            {t("logistic.system.stock")}
          </Typography>
          <Typography variant="cardvalue" data-cy={`value`} sx={{ wordBreak: "keep-all" }}>
            {`${entry.qty} ${entry.uom}`}
          </Typography>
          {/* entriesQty stock */}
        </Grid>
        <Grid item xs={12} md={6}>
          {renderInput("logistic-physical-stock", "entriesQty", entry.entriesQty ?? 0, t("logistic.physical.stock"), "number")}
        </Grid>
        {/* comment */}
        <Grid item xs={12}>
          {renderInput("logistic-comment", "comment", entry.comment ?? "", t("logistic.comment"))}
        </Grid>
        <Grid item xs={12}>
          <ButtonsDetail
            handleClose={closeRequest}
            handleSave={() => {
              if (mode === "request") {
                validateDialogValues() && handleSave();
              } else {
                validateDialogValues() && handleEdit();
              }
            }}
            mode={"EDIT"}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </AggregoDialog>
  );
};
