import { Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ErrorView = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Grid height="100vh" container direction="row" justifyContent="center" alignItems="center">
        <Grid item textAlign="center" xs={8} md={4}>
          <Typography fontSize="2.4rem" fontWeight="700" letterSpacing={"-0.02rem"}>
            404 Error
          </Typography>
          <Typography>{t("error.message")}</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ErrorView;
