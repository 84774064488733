import { COLORS } from "@theme";
import Icon from "../icons/icon";
import { useTranslation } from "react-i18next";
import { OverflowTooltip } from "../tooltip/OverflowTooltip";
import IMMEntryWithDesc from "@domain/model/IMMEntryWithDesc";
import { cardStyles } from "../master-data-view/card/cardStyles";
import { Grid, IconButton, Paper, Typography } from "@mui/material";

export const InventoryLogisticCard = (props: {
  id: string;
  isSynchronizing?: boolean;
  isOk?: boolean;
  IMEntry: IMMEntryWithDesc;
  handleOpenDeleteDialog?: (entry: IMMEntryWithDesc) => void;
  handleOpenEditDialog?: (entry: IMMEntryWithDesc) => void;
  handleErrorDialog?: (errorMessage: string) => void;
  errorMessage?: string;
}) => {
  const styles = cardStyles();
  const { id, isSynchronizing, isOk, IMEntry, handleOpenDeleteDialog, handleOpenEditDialog, handleErrorDialog, errorMessage } =
    props;
  const { t } = useTranslation();

  /**
   * Render a child component with an optional container width.
   *
   * @param {string} name - The name to be displayed.
   * @param {string} value - The value to be displayed.
   * @param {number} [containerWidth] - The width of the container in percentage (optional) Default: 100%.
   * @returns {JSX.Element} The rendered child component.
   */
  const renderChild = (id: string, name: string, value: string, containerWidth?: number) => {
    const containerStyle = containerWidth !== undefined ? { width: `${containerWidth}%` } : {};
    return (
      <div className="value-card-container" style={containerStyle}>
        <div className="title-content" data-cy={`${id}-container`}>
          <Typography variant="cardlabel" textAlign="left">
            {t(name)}
          </Typography>
          <OverflowTooltip>
            <Typography noWrap variant="cardvalue">
              {value ? t(value) : "-"}
            </Typography>
          </OverflowTooltip>
        </div>
      </div>
    );
  };

  const getUomIfExists = (uom: string | undefined) => uom ?? "";

  const getFullEntriesQty = (qtyPropertyName: "qty" | "entriesQty") => {
    const qty = IMEntry[qtyPropertyName];
    if (qty === undefined) return "-";

    return `${qty.toLocaleString("it-IT")} ${getUomIfExists(IMEntry.uom)}`;
  };
  return (
    <Paper
      id={id}
      data-cy={`id-card-${isSynchronizing}`}
      sx={{
        ...styles.card_class_logistic
      }}>
      <Grid
        container
        sx={{ cursor: !isOk ? "pointer" : "auto" }}
        onClick={() => {
          handleErrorDialog?.(errorMessage ?? "OK");
        }}>
        <Grid item xs={isSynchronizing ? 8 : 10}>
          {renderChild("logistic-material", "logistic.material", IMEntry?.materialCode ?? "-")}
          {renderChild("logistic-description", "logistic.description", IMEntry?.materialDescription ?? "-")}
          {renderChild("logistic-system-stock", "logistic.system.stock", getFullEntriesQty("qty"), 50)}
          {renderChild("logistic-physical-stock", "logistic.physical.stock", getFullEntriesQty("entriesQty"), 50)}
          {IMEntry?.comment && renderChild("logistic-comment", "logistic.comment", IMEntry?.comment ?? "-")}
        </Grid>
        {isSynchronizing ? (
          <Grid item xs={4} display="flex" flexDirection="column" justifyContent="center" alignItems="flex-end">
            {isOk ? (
              <Icon id="task-ok" icon="task-ok" size="44px" color={COLORS.success} />
            ) : (
              <Icon id="task-error" icon="task-ko" size="44px" color={COLORS.alert} />
            )}
          </Grid>
        ) : (
          <Grid item xs={2} display="flex" flexDirection="column" justifyContent="space-between" alignItems="flex-end">
            <IconButton id="edit-card-button" onClick={() => handleOpenEditDialog?.(IMEntry ?? {})}>
              <Icon icon="edit" />
            </IconButton>
            <IconButton id="delete-card-button" onClick={() => handleOpenDeleteDialog?.(IMEntry ?? {})}>
              <Icon icon="delete" />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
