import { COLORS } from "@theme";
import { CircularProgress, Grid } from "@mui/material";
import Icon from "@components/icons/icon";
import { useTranslation } from "react-i18next";
import { DomainPage } from "@movicoders/domain";
import FamilyType from "@domain/model/FamilyType";
import { useCheckStatus } from "@hooks/useCheckStatus";
import TypeOfFamiliesDetailView from "./type-of-families-detail-view";
import { MasterDataTitle } from "@components/master-data-view/MasterDataTitle";
import { OutletContainer } from "@components/outlet-container/outlet-container";
import TypesOfFamiliesViewTable from "./view-components/type-of-families-view-table";
import TypeOfFamilyActivationDialog from "./components/dialogs/type-of-family-active";
import { useTypeOfFamiliesViewModel } from "./hooks/viewmodel/useTypeOfFamiliesListViewModel";
import { LoadingSpinnerMasterView } from "@components/loading-circular-progress/loading-spinner-master-view";
import { MasterDataEnum } from "@pages/settings/settings-interfaces";

const TypesOfFamiliesMasterView = () => {
  const { t } = useTranslation();
  const {
    typeFamilies,
    isActivating,
    loadingDetail,
    loading,
    selectedTypeOfFamily4Activation,
    selectedTypeOfFamily,
    onCloseDialog,
    toggleTypeOfFamilyStatus,
    goToCreate,
    goToList,
    handleSave,
    handleEdit,
    handlePagination,
    handleOpenToggleActiveStatusDialog,
    settingsService
  } = useTypeOfFamiliesViewModel();

  const { handleStatusFilter } = useCheckStatus();

  return (
    <>
      {settingsService.persistedState?.masterdataPermissions.includes(MasterDataEnum.TypeOfFamilies) ? (
        <>
          <OutletContainer>
            <Grid container display="flex" flexDirection="column">
              <MasterDataTitle
                icon={<Icon icon="categories" color={COLORS.primary} />}
                name={t("type.of.families")}
                buttonText={t("type.of.families.add.button")}
                onAddButton={goToCreate}
                showButton={!handleStatusFilter()}
              />

              <TypesOfFamiliesViewTable
                typeFamilies={typeFamilies as DomainPage<FamilyType>}
                handleEdit={handleEdit}
                handleOpenToggleActiveStatusDialog={handleOpenToggleActiveStatusDialog}
                handlePagination={handlePagination}
              />

              {selectedTypeOfFamily4Activation && (
                <TypeOfFamilyActivationDialog
                  open={isActivating}
                  onClose={onCloseDialog}
                  onActivation={item => toggleTypeOfFamilyStatus(selectedTypeOfFamily4Activation)}
                  typeOfFamily={selectedTypeOfFamily4Activation}
                />
              )}
              <LoadingSpinnerMasterView loading={loading} />
            </Grid>
          </OutletContainer>

          <TypeOfFamiliesDetailView
            typeOfFamily={selectedTypeOfFamily as FamilyType}
            loading={loadingDetail}
            onSave={handleSave}
            onClose={() => goToList()}
          />
        </>
      ) : (
        <CircularProgress color="secondary" />
      )}
      ;
    </>
  );
};

export default TypesOfFamiliesMasterView;
