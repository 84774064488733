/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DailyStockReportResponseDTO
 */
export interface DailyStockReportResponseDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof DailyStockReportResponseDTO
     */
    hours?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DailyStockReportResponseDTO
     */
    warehouse?: string;
}

/**
 * Check if a given object implements the DailyStockReportResponseDTO interface.
 */
export function instanceOfDailyStockReportResponseDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DailyStockReportResponseDTOFromJSON(json: any): DailyStockReportResponseDTO {
    return DailyStockReportResponseDTOFromJSONTyped(json, false);
}

export function DailyStockReportResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DailyStockReportResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hours': !exists(json, 'hours') ? undefined : json['hours'],
        'warehouse': !exists(json, 'warehouse') ? undefined : json['warehouse'],
    };
}

export function DailyStockReportResponseDTOToJSON(value?: DailyStockReportResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hours': value.hours,
        'warehouse': value.warehouse,
    };
}

