import { GridColDef } from "@mui/x-data-grid";
import { filterValueTypes } from "@constants";
import { CustomTooltipCell } from "@components/tooltip/CustomTooltipCell";

export const dashboardSearchUserColumns = (t: (text: string) => string): GridColDef[] => {
  return [
    {
      flex: 1.5,
      field: "username",
      headerName: t("users.username"),
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>,
      type: "string" as filterValueTypes
    },
    {
      flex: 1.5,
      field: "email",
      headerName: t("users.email"),
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>,
      type: "string" as filterValueTypes
    },
    {
      flex: 1.5,
      field: "roles",
      headerName: t("users.roles"),
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>,
      type: "multiple-select" as filterValueTypes
    },
    {
      flex: 1.5,
      field: "active",
      headerName: t("users.status"),
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>,
      valueGetter: ({ row }) => {
        return row.active ? t("users.active") : t("users.inactive");
      },
      type: "select" as filterValueTypes
    }
  ];
};
