import { useEffect, RefObject } from "react"
import { exportGLTF } from "../gltf-export-helper";
import { Scene, Camera, Vector3 } from "three";
import { ROUTER_PATHS } from "../../../../../constants";
import { OrbitControls } from "three-stdlib";
import { useResolve } from "@movicoders/di";
import { IEditionStatesStore } from "../../stores/edition-states-store/IEditionStatesStore";
import { useEditionStatesStore } from "../../stores/edition-states-store/edition-states-store";
import { IDialogsStatesStore } from "../../stores/dialogs-states-store/IDialogsStatesStore";
import { useDialogsStatesStore } from "../../stores/dialogs-states-store/dialogs-states-store";
import { ICommon3DObjectsStore } from "../../stores/common-3d-objects-store/ICommon3DObjectsStore";
import { useCommon3DObjectsStore } from "../../stores/common-3d-objects-store/common-3d-objects-store";
import { IWarehouse3DStore } from "../../stores/warehouse-3d-store/IWarehouse3DStore";
import { useWarehouse3DStore } from "../../stores/warehouse-3d-store/warehouse-3d-store";

export const useKeyboard = (
  scene: Scene,
  controls: RefObject<OrbitControls>,
  camera: Camera
) => {
  const { getWarehouse } = useResolve<IWarehouse3DStore>(useWarehouse3DStore);
  const { getSelectionType, getModifyingWH, getSelectedMode } = useResolve<IEditionStatesStore>(useEditionStatesStore);
  const { getDeleteDialogOpen, getAddLocationDialogOpen } = useResolve<IDialogsStatesStore>(useDialogsStatesStore);
  const { getTilesBeingModified } = useResolve<ICommon3DObjectsStore>(useCommon3DObjectsStore);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [getSelectedMode(), getModifyingWH(), getTilesBeingModified(), getSelectionType(), getDeleteDialogOpen(), getAddLocationDialogOpen()]);


  const handleKeyDown = (e: KeyboardEvent) => {
    if (!controls.current || getDeleteDialogOpen() || getAddLocationDialogOpen()) return

    if (window.location.pathname === ROUTER_PATHS.warehouseViewer) {
      const whX = getWarehouse().sizeX as number;
      const whZ = getWarehouse().sizeZ as number;

      // Reset camera target to center.
      if (e.code === "Space") {
        camera.position.set(Number((getWarehouse().sizeX as number) / 2), (Number(whZ) + Number(whX)) / 3, Number(whZ / 2));
        controls.current.target = new Vector3(Number(whX) / 2, 0, Number(whZ) / 2);
      }

      // Rotate the camera.
      if (e.code.includes("Arrow")) {
        switch (e.code) {
          case "ArrowUp":
            controls.current.setPolarAngle(controls.current.getPolarAngle() - 0.1);
            break;
          case "ArrowDown":
            controls.current.setPolarAngle(controls.current.getPolarAngle() + 0.1);
            break;
          case "ArrowLeft":
            controls.current.setAzimuthalAngle(controls.current.getAzimuthalAngle() - 0.2);
            break;
          case "ArrowRight":
            controls.current.setAzimuthalAngle(controls.current.getAzimuthalAngle() + 0.2);
            break;
          default:
            break;
        }
      }

      // Download model.
      if (e.ctrlKey && e.code === "KeyS") {
        e.preventDefault();
        exportGLTF(scene, getWarehouse());
      }

    }
  };

};