import { Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useResolve } from "@movicoders/di";
import { IEditionStatesStore } from "../../stores/edition-states-store/IEditionStatesStore";
import { useEditionStatesStore } from "../../stores/edition-states-store/edition-states-store";
import { TILE_TEMPLATE_TYPES_VALUES, TileTemplateTypes } from "../../../../../domain/model/TileTemplate";

export const TemplateSelectionDialog = () => {
  const {
    getSelectedTileTemplate,
    getModifyingWH,
    setSelectedTileTemplate
  } = useResolve<IEditionStatesStore>(useEditionStatesStore);
  const { t } = useTranslation();

  const handleChange = (e: SelectChangeEvent) => {
    setSelectedTileTemplate(e.target.value as TileTemplateTypes);
  };

  return (
    <Select value={getSelectedTileTemplate()} onChange={handleChange} disabled={getModifyingWH()} sx={{ mt: "1rem" }} fullWidth>
      {TILE_TEMPLATE_TYPES_VALUES.map(template => (
        <MenuItem key={template} value={template}>
          {t(template)}
        </MenuItem>
      ))}
    </Select>
  );
};
