import { MeshBasicMaterial } from "three";
import { CAPACITY_COLORS, DEFAULT_COLORS } from "./Colors";
import { COLORS } from "../../../../theme";

export const DEFAULT_MATERIAL_CREATING = new MeshBasicMaterial({
  color: "#0000ff",
  transparent: true,
  opacity: 0.6
});

export const DEFAULT_MATERIAL_REDUCING = new MeshBasicMaterial({
  color: "#ff0000"
});

export const DEFAULT_PLANE_MATERIAL = new MeshBasicMaterial({
  color: DEFAULT_COLORS.darkgrey,
  transparent: true,
  opacity: 0.8
});

export const DEFAULT_CYLINDER_MATERIAL_BLUE = new MeshBasicMaterial({
  color: CAPACITY_COLORS.selectedblue,
  transparent: true,
  opacity: 0.8
});

export const CORRIDOR_MATERIAL = new MeshBasicMaterial({
  color: COLORS.warning,
  transparent: true,
  opacity: 0.4
});
