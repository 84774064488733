import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import { logisticStyles } from "../logistic-styles";
import { Trans, useTranslation } from "react-i18next";
import { LogisticTitle } from "../components/logistic-title";
import { AutocompleteIMEntry } from "../components/logistic-autocomplete";
import { useInventoryMaterialForm } from "./hooks/useInventoryMaterialForm";
import { LogisticDeleteDialog } from "../components/logistic-delete-dialog";
import { InventoryMaterialDetailView } from "./inventory-material-detail-view";
import { OutletContainer } from "@components/outlet-container/outlet-container";
import { LogisticDeleteAllDialog } from "../components/logistic-delete-all-dialog";
import { useInventoryMaterialViewModel } from "./hooks/useInventoryMaterialViewModel";
import { InventoryLogisticCard } from "@components/logistic-view/inventory-logistic-card";
import { useInventoryMaterialValidations } from "./hooks/useInventoryMaterialValidations";
import { Button, CircularProgress, Divider, Grid, Paper, Theme, Typography, useMediaQuery } from "@mui/material";
import { LoginOperatorDialog } from "../components/logistic-login-dialog/logistic-login-dialog";
import { LoadingSpinnerMasterView } from "@components/loading-circular-progress/loading-spinner-master-view";
import { LogisticEnum } from "@pages/settings/settings-interfaces";

export const InventoryMaterialView = () => {
  const { t } = useTranslation();
  const styles = logisticStyles();

  const {
    entryForModify,
    iMMaterialList,
    setIMMaterialList,
    isRequesting,
    isEditing,
    isDeleting,
    isClearingAll,
    handleRequestDialog,
    handleOpenEditDialog,
    handleOpenDeleteDialog,
    handleOpenClearAllDialog,
    onChange,
    onChangeNumeric,
    onCloseDialog,
    handleSave,
    handleEdit,
    handleDelete,
    handleClearAll,
    noEntries,
    isOperatorLogged
  } = useInventoryMaterialForm();

  const { isSyncronizing, selectedIMMaterial, setSelectedIMMaterial, onChangeAutocomplete, handleSynchronize, settingsService } =
    useInventoryMaterialViewModel(iMMaterialList, setIMMaterialList);

  const { formErrors, dialogErrors, validateDialogValues } = useInventoryMaterialValidations(
    selectedIMMaterial ?? {},
    entryForModify
  );

  const matchesMd = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"), { noSsr: true });
  const matchesSm = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"), { noSsr: true });

  return (
    <>
      {settingsService.persistedState?.logisticOperationsPermissions.includes(LogisticEnum.InventoryMaterial) ? (
        <>
          <OutletContainer>
            <>
              <Grid container id="first-grid-container" direction="column" mb={1}>
                <Grid item>
                  <LogisticTitle
                    id={"inventory-material-title"}
                    name={t("inventory.material")}
                    icon={<Icon icon="box" style={{ marginTop: 7 }} color={COLORS.primary} />}
                  />
                </Grid>
              </Grid>
              <Paper sx={{ p: 1, borderRadius: "6px 6px 6px 6px", boxShadow: "0px 3px 6px #00000029" }}>
                <Grid item sx={{ pb: 0.7 }}>
                  <Typography fontSize="1rem">{t("inventory.material.title.description")}</Typography>
                </Grid>
                <Grid
                  display="flex"
                  container
                  columnSpacing={{ xs: 1 }}
                  alignItems="flex-start"
                  sx={{ mt: "2rem", mb: "1.5rem" }}>
                  <Grid item xs={12} md={6}>
                    <AutocompleteIMEntry
                      id="search-material-entries-autocomplete"
                      iMMaterial={selectedIMMaterial ?? {}}
                      onChangeMaterial={value => {
                        value && onChangeAutocomplete(value);
                      }}
                      formErrors={formErrors}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} sx={{ mt: !matchesSm || !matchesMd ? 1 : 0 }}>
                    <Button
                      id="inventory-material-add-button"
                      startIcon={<Icon icon="plus" color={COLORS.white} />}
                      variant={selectedIMMaterial ? "primary" : "disabled"}
                      disabled={!selectedIMMaterial}
                      fullWidth={!matchesMd}
                      sx={styles.logistic_button}
                      onClick={() => {
                        handleRequestDialog(selectedIMMaterial ?? {});
                      }}>
                      {t("inventory.material.add.button")}
                    </Button>
                  </Grid>
                </Grid>
                <Divider sx={styles.content_divider} />
                <Grid item pb={1} id="inventory-material-materials-added">
                  {noEntries ? (
                    <Trans>
                      {t("inventory.material.immaterial.count.message", { materialCount: iMMaterialList.length ?? 0 })}&nbsp;
                      {iMMaterialList.length === 1 ? t("inventory.material.in.singular") : t("inventory.material.in.plural")}
                      {t("inventory.material.immaterial.continue.message")}
                    </Trans>
                  ) : (
                    <Typography id="inventory-material-no-materials" fontSize="1rem">
                      {t("inventory.material.no.materials")}
                    </Typography>
                  )}
                </Grid>

                <Grid container spacing={{ xs: 1 }} mt={1}>
                  {iMMaterialList.map(iMMaterial => (
                    <Grid item xs={12} md={6} lg={4} key={`grid-card-${iMMaterial.id}`}>
                      <InventoryLogisticCard
                        id={iMMaterial?.id ?? ""}
                        IMEntry={iMMaterial ?? {}}
                        isSynchronizing={false}
                        handleOpenDeleteDialog={handleOpenDeleteDialog}
                        handleOpenEditDialog={handleOpenEditDialog}
                      />
                    </Grid>
                  ))}
                </Grid>

                <Grid container>
                  <Grid item xs={12} md={6}>
                    <Button
                      id="inventory-material-clear-button"
                      variant={"secondary"}
                      sx={{ mt: "1rem", mb: matchesMd ? "1rem" : "0.5rem", width: matchesMd ? "auto" : "100%" }}
                      disabled={!noEntries}
                      onClick={handleOpenClearAllDialog}>
                      {t("inventory.material.clear.button")}
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
                    <Button
                      id="sync-button"
                      startIcon={<Icon icon="sync" />}
                      variant={!noEntries ? undefined : "primary"}
                      sx={{ mt: "1rem", mb: matchesMd ? "1rem" : "0.5rem", width: matchesMd ? "auto" : "100%" }}
                      disabled={!noEntries}
                      onClick={handleSynchronize}>
                      {t("inventory.material.sync.button")}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </>
          </OutletContainer>
          {/* Request dialog */}
          {(isRequesting || isEditing) && (
            <InventoryMaterialDetailView
              title={isRequesting ? "inventory.material.adding" : "inventory.material.editing"}
              entry={entryForModify}
              closeRequest={onCloseDialog}
              onChange={onChange}
              onChangeNumeric={onChangeNumeric}
              handleSave={() => {
                handleSave();
                setSelectedIMMaterial(undefined);
              }}
              handleEdit={() => {
                handleEdit();
                setSelectedIMMaterial(undefined);
              }}
              mode={isRequesting ? "request" : "edit"}
              dialogErrors={dialogErrors}
              validateDialogValues={validateDialogValues}
            />
          )}
          {/* Delete dialog */}
          {entryForModify && <LogisticDeleteDialog open={isDeleting} onClose={onCloseDialog} handleDelete={handleDelete} />}
          {/* Clear all dialog */}
          {isClearingAll && <LogisticDeleteAllDialog open onClose={onCloseDialog} onDelete={handleClearAll} />}
          {!isOperatorLogged && <LoginOperatorDialog />}
          <LoadingSpinnerMasterView loading={isSyncronizing} />
        </>
      ) : (
        <CircularProgress color="secondary" />
      )}
    </>
  );
};
