import { ROUTER_PATHS } from "@constants";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EventDTO, ProductDTO, ResponseError } from "@clients/aggrego-proxy";
import { ProductChild } from "@domain/model/ProductChild";
import { useResolve } from "@movicoders/di/lib/Container/useResolve";
import { useHandleHierarchyProduct } from "./useHandleHierarchyProduct";
import { EventRepository } from "@infrastructure/repositories/event-repository";
import { ProductRepository } from "@infrastructure/repositories/product-repository";
import { handleError } from "@utils/error-helper";
import { useSnackbar } from "@movicoders/ui";
import { useTranslation } from "react-i18next";
import { IUserService, UserService } from "@domain/services/IUserService";

export const useHierarchyReportViewModel = (id?: string) => {
  const productRepository = useResolve<ProductRepository>(ProductRepository);
  const eventRepository = useResolve<EventRepository>(EventRepository);

  const { currentProduct, handleSelectProduct, hasMoreParents, persistedState, productChildren } = useHandleHierarchyProduct(
    productRepository,
    eventRepository
  );

  const { t } = useTranslation();
  const { show } = useSnackbar();
  const navigate = useNavigate();
  const [selectedCard, setSelectedCard] = useState("");
  const [selectedData, setSelectedData] = useState<EventDTO[]>([]);
  const [recordOpen, setRecordOpen] = useState(false);
  const [filterData, setFilterData] = useState<ProductChild[]>([]);

  // Gets if batch or versionlabel is showed (teltronic)
  const userService = useResolve<IUserService>(UserService);
  const hasVersionLabel = userService.user?.hasVersion ?? false;

  const handleSelectedCard = (value: string) => {
    if (selectedCard === value) {
      setSelectedCard("");
      setRecordOpen(false);
    } else {
      setSelectedCard(value);
      setRecordOpen(true);
    }
  };

  const onChangeHierarchyLevel = (id?: string) => {
    navigate(`${ROUTER_PATHS.products}/${id}`);
  };

  const levelUpHierarchyLevel = () => {
    const parentProduct = currentProduct as ProductDTO;
    if (parentProduct && parentProduct.parents && parentProduct.parents.length > 0) {
      const parentId = parentProduct.parents[0]?.id;
      if (parentId) {
        navigate(`${ROUTER_PATHS.products}/${parentId}`);
      }
    }
  };

  const handleFilterData = (filterArg: string) => {
    const newFilterData = filterProductChildren(productChildren, filterArg);
    setFilterData(newFilterData);
  };

  function filterProductChildren(productChildren: ProductChild[] | undefined, filterArg: string) {
    return (
      productChildren?.filter(child => {
        const text = Object.values(child).join(" ");
        return text && text.toLowerCase().includes(filterArg.toLowerCase());
      }) ?? []
    );
  }

  const showEventsOnProductSelect = () => {
    const selectedChild = findSelectedChild();

    const { selectedCardIsParent, selectedCardIsChild, noSelectedCard } = checkSelectedCard(selectedChild);

    if (selectedCardIsParent) {
      getParentEvents();
    } else if (selectedCardIsChild) {
      getChildEvents(selectedChild);
    } else if (noSelectedCard) {
      setSelectedData([]);
    }
  };

  function findSelectedChild() {
    return productChildren?.find(c => c.id === selectedCard);
  }

  function checkSelectedCard(selectedChild: ProductChild | undefined) {
    const selectedCardIsParent: boolean = checkHasSelectedParent();
    const selectedCardIsChild: boolean = checkHasSelectedChild(selectedChild);
    const noSelectedCard: boolean = checkHasNotSelectedCard();

    return {
      selectedCardIsParent,
      selectedCardIsChild,
      noSelectedCard
    };
  }

  function checkHasSelectedParent() {
    return selectedCard === currentProduct?.id;
  }

  function checkHasSelectedChild(selectedChild: ProductChild | undefined) {
    return selectedChild !== undefined;
  }

  function checkHasNotSelectedCard() {
    return selectedCard === "";
  }

  function getParentEvents() {
    if (currentProduct) {
      eventRepository
        .getManyById(Array.from(currentProduct.events)?.map(e => e.eventId ?? ""))
        .then(events => {
          setSelectedData(events);
        })
        .catch(async error => {
          const response = await handleError(error as ResponseError);
          switch (response?.status) {
            case 404:
              show(t("historical.parent.product.has.no.event"), "error");
              break;
            case 500:
              show(t("historical.unknown.get.events"), "error");
              break;
            default:
              show(t("historical.unknown.error"), "error");
              break;
          }
        });
    }
  }

  function getChildEvents(selectedChild: ProductChild | undefined) {
    productRepository.getById(selectedChild?.id ?? "").then(childWithEvents => {
      eventRepository
        .getManyById(Array.from(childWithEvents.events)?.map(e => e.eventId ?? ""))
        .then(events => {
          setSelectedData(events);
        })
        .catch(async error => {
          const response = await handleError(error as ResponseError);
          switch (response?.status) {
            case 404:
              show(t("historical.child.product.has.no.event"), "error");
              break;
            case 500:
              show(t("historical.unknown.get.events"), "error");
              break;
            default:
              show(t("historical.unknown.error"), "error");
              break;
          }
        });
    });
  }

  useEffect(() => {
    setFilterData(productChildren ?? []);
  }, [productChildren]);

  useEffect(() => {
    persistedState?.currentParentId &&
      id &&
      handleSelectProduct(persistedState?.currentParentId, id, persistedState?.currentHierarchy);
  }, [id]);

  useEffect(() => {
    showEventsOnProductSelect();
  }, [selectedCard, currentProduct]);

  return {
    hasVersionLabel,
    selectedCard,
    selectedData,
    recordOpen,
    setRecordOpen,
    handleSelectedCard,
    currentProduct,
    productChildren,
    hasMoreParents,
    onChangeHierarchyLevel,
    levelUpHierarchyLevel,
    handleFilterData,
    filterData,
    ...persistedState
  };
};
