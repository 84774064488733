import Family from "@domain/model/Family";
import Material from "@domain/model/Material";
import { SyntheticEvent, useEffect, useState } from "react";

interface IMaterialForm {
  selectedMaterial: Material;
  onSave: (material: Material) => void;
}

export const useMaterialsForm = (props: IMaterialForm) => {
  const INITIAL_ERROR_MAP = new Map([
    ["material-material", false],
    ["material-code", false],
    ["material-families-select", false],
    ["material-price", false],
    ["material-uom-select", false]
  ]);
  const { selectedMaterial } = props;

  const [material, setMaterial] = useState<Material>(selectedMaterial);
  const [selectedFamilies, setSelectedFamilies] = useState<Family[]>(selectedMaterial.families as Family[]);
  const [selectedUom, setSelectedUom] = useState<string>("");

  const [formErrors, setFormErrors] = useState<Map<string, boolean>>(INITIAL_ERROR_MAP);

  useEffect(() => {
    setSelectedUom(selectedMaterial.uom ?? "Unidades");
    setMaterial(selectedMaterial);
    setSelectedFamilies(selectedMaterial.families as Family[]);

    setFormErrors(INITIAL_ERROR_MAP);
  }, [selectedMaterial]);

  useEffect(() => {
    setMaterial({ ...material, uom: selectedUom ?? "Unidades" });
  }, [material.id]);

  const onChangeAutocomplete = <T>(event: SyntheticEvent<Element, Event>, value: T, name: string) => {
    setMaterial({ ...material, [name]: value });
    if (name === "families") {
      setSelectedFamilies(value as Family[]);
    } else if (name === "uom") {
      setSelectedUom(value as string);
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMaterial({ ...material, [event.target.name]: event.target.value });
  };

  const onChangeNumeric = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^\d*\.?\d*$/;
    const inputValue = event.target.value;

    if (regex.test(inputValue) || inputValue === "") {
      const newValue = inputValue === "" ? "0" : inputValue.replace(/^0+/, "");
      setMaterial({ ...material, [event.target.name]: newValue });
    }
  };

  const validateFormValues = () => {
    const nameValid = material.name !== null && material.name !== undefined && material.name !== "";
    const codeValid = material.code !== null && material.code !== undefined && material.code !== "";
    const familyValid = material.families !== undefined && material.families.length > 0;
    const unitPriceValid = material.unitPrice !== null && material.unitPrice !== undefined && material.unitPrice > 0;
    const uomValid = material.uom !== null && material.uom !== undefined && material.uom !== "";
    setFormErrors(
      new Map([
        ["material-material", !nameValid],
        ["material-code", !codeValid],
        ["material-families-select", !familyValid],
        ["material-price", !unitPriceValid],
        ["material-uom-select", !uomValid]
      ])
    );
    return nameValid && codeValid && familyValid && unitPriceValid && uomValid;
  };

  /**
   * If the material uom is undefined, set it to "Unidades"
   */
  useEffect(() => {
    if (selectedMaterial.uom === undefined) selectedMaterial.uom = "Unidades";
  }, []);

  return {
    onChange,
    onChangeNumeric,
    material,
    formErrors,
    validateFormValues,
    onChangeAutocomplete,
    selectedFamilies,
    selectedUom
  };
};
