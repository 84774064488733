import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import LocationEntity from "@domain/model/Location";
import { ButtonsDetail } from "@components/master-data-view/buttons/buttons-detail";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";

export const LocationActivationDialog = (props: {
  open: boolean;
  onClose: () => void;
  onActive: (location: LocationEntity) => void;
  location: LocationEntity;
}) => {
  const { t } = useTranslation();
  const { open, onClose, onActive, location } = props;

  const [pendingRequest, setPendingRequest] = useState<boolean>(false);

  useEffect(() => {
    setPendingRequest(false);
  }, [location]);

  return open ? (
    <AggregoDialog
      title={t(location.active ? "locations.deactivate.title" : "locations.activate.title", { location: location.code })}
      onClose={onClose}>
      <Grid container flexDirection="column" className="masterdata-box" rowSpacing={2}>
        <Grid item>
          <div className="confirm-dialog-div-content">
            {t(location.active ? "locations.deactivate.text" : "locations.activate.text")}
          </div>
        </Grid>
        <Grid item>
          <ButtonsDetail
            handleClose={onClose}
            handleSave={() => {
              setPendingRequest(true);
              onActive(location);
            }}
            mode={location.active ? "ACTIVE" : "INACTIVE"}
            pendingRequest={pendingRequest}
          />
        </Grid>
      </Grid>
    </AggregoDialog>
  ) : (
    <></>
  );
};
