/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FullCompanyItemDTO
 */
export interface FullCompanyItemDTO {
    /**
     * 
     * @type {boolean}
     * @memberof FullCompanyItemDTO
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FullCompanyItemDTO
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof FullCompanyItemDTO
     */
    companyName?: string;
    /**
     * 
     * @type {Date}
     * @memberof FullCompanyItemDTO
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof FullCompanyItemDTO
     */
    fiscalId?: string;
    /**
     * 
     * @type {string}
     * @memberof FullCompanyItemDTO
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof FullCompanyItemDTO
     */
    licence?: FullCompanyItemDTOLicenceEnum;
    /**
     * 
     * @type {Date}
     * @memberof FullCompanyItemDTO
     */
    modified?: Date;
    /**
     * 
     * @type {string}
     * @memberof FullCompanyItemDTO
     */
    tenant?: string;
    /**
     * 
     * @type {string}
     * @memberof FullCompanyItemDTO
     */
    tenantName?: string;
    /**
     * 
     * @type {string}
     * @memberof FullCompanyItemDTO
     */
    warehousePolicy?: FullCompanyItemDTOWarehousePolicyEnum;
}


/**
 * @export
 */
export const FullCompanyItemDTOLicenceEnum = {
    Im: 'IM',
    Wm: 'WM',
    WmWithContainer: 'WM_WITH_CONTAINER'
} as const;
export type FullCompanyItemDTOLicenceEnum = typeof FullCompanyItemDTOLicenceEnum[keyof typeof FullCompanyItemDTOLicenceEnum];

/**
 * @export
 */
export const FullCompanyItemDTOWarehousePolicyEnum = {
    Lifo: 'LIFO',
    StrictFifo: 'STRICT_FIFO',
    TimeIntervalFifo: 'TIME_INTERVAL_FIFO'
} as const;
export type FullCompanyItemDTOWarehousePolicyEnum = typeof FullCompanyItemDTOWarehousePolicyEnum[keyof typeof FullCompanyItemDTOWarehousePolicyEnum];


/**
 * Check if a given object implements the FullCompanyItemDTO interface.
 */
export function instanceOfFullCompanyItemDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FullCompanyItemDTOFromJSON(json: any): FullCompanyItemDTO {
    return FullCompanyItemDTOFromJSONTyped(json, false);
}

export function FullCompanyItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FullCompanyItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'fiscalId': !exists(json, 'fiscalId') ? undefined : json['fiscalId'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'licence': !exists(json, 'licence') ? undefined : json['licence'],
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'tenant': !exists(json, 'tenant') ? undefined : json['tenant'],
        'tenantName': !exists(json, 'tenantName') ? undefined : json['tenantName'],
        'warehousePolicy': !exists(json, 'warehousePolicy') ? undefined : json['warehousePolicy'],
    };
}

export function FullCompanyItemDTOToJSON(value?: FullCompanyItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'address': value.address,
        'companyName': value.companyName,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'fiscalId': value.fiscalId,
        'image': value.image,
        'licence': value.licence,
        'modified': value.modified === undefined ? undefined : (value.modified.toISOString()),
        'tenant': value.tenant,
        'tenantName': value.tenantName,
        'warehousePolicy': value.warehousePolicy,
    };
}

