import {
  GetProductByBatchAndMaterialCodeRequest,
  GetProductByBatchAndMaterialCodeSortDirectionEnum,
  GetProductByBatchAndMaterialCodeSortFieldEnum,
  GetProductBySerialNumberRequest,
  GetProductBySerialNumberSortDirectionEnum,
  GetProductBySerialNumberSortFieldEnum
} from "@clients/aggrego-proxy";
import { useResolve } from "@movicoders/di";
import { useSnackbar } from "@movicoders/ui";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Product } from "@domain/model/Product";
import { ProductChild } from "@domain/model/ProductChild";
import { HierarchyPair } from "@domain/model/HierarchyState";
import { EventRepository } from "@infrastructure/repositories/event-repository";
import { ProductRepository } from "@infrastructure/repositories/product-repository";
import { IHierarchyService, HierarchyService } from "@domain/services/IHierarchyService";

export const useHandleHierarchyProduct = (productRepository: ProductRepository, eventRepository: EventRepository) => {
  const { show } = useSnackbar();
  const { t } = useTranslation();
  const [productChildren, setProductChildren] = useState<ProductChild[]>();
  const [currentProduct, setCurrentProduct] = useState<Product>();
  const [hasMoreParents, setHasMoreParents] = useState(false);
  const { persistedState, saveHierarchyState } = useResolve<IHierarchyService>(HierarchyService);

  const searchProduct = (id: string): Promise<Product> => {
    return productRepository.getById(id);
  };

  const handleSelectProduct = useCallback(
    async (parentId: string, productId: string, hierarchy: Array<HierarchyPair> = []) => {
      const product = await searchProduct(productId);
      const hierarchyIndex = hierarchy.findIndex(item => item.uuid === product.id);

      hierarchyIndex === -1
        ? addProductToTheHierarchy(hierarchy, product, parentId)
        : removeProductToTheHierarchy(hierarchyIndex, hierarchy, product);

      const childrenPromise = findChildProductsBySerialNumberOrMaterialCodeAndBatch(product.children);
      handleChildProductsPromise(childrenPromise);
    },
    [saveHierarchyState, searchProduct]
  );

  function handleChildProductsPromise(childrenPromise: Promise<Product[]>) {
    childrenPromise
      .then((children: (Product | undefined)[]) => {
        showChildProducts(children);
      })
      .catch(() => {
        show(t("products.get.error"), "error");
      });
  }

  function showChildProducts(children: (Product | undefined)[]) {
    const filteredChildren = filterArrayByNonUndefined(children);
    setProductChildren(filteredChildren as unknown as ProductChild[]);
  }

  function addProductToTheHierarchy(hierarchy: HierarchyPair[], product: Product, oldParentId: string) {
    let newParentId = "";

    newParentId = updatesParentIdIfNecessary(hierarchy.length > 0, hierarchy, oldParentId) ?? oldParentId;
    hierarchy = saveNewProductToThePersistedHierarchy(hierarchy, product);
    saveAllStates(product, hierarchy, newParentId);
  }

  function removeProductToTheHierarchy(hierarchyIndex: number, hierarchy: HierarchyPair[], product: Product) {
    let newParentId = "";
    hierarchy.splice(hierarchyIndex + 1);
    if (hierarchy.length > 1) {
      newParentId = hierarchy[hierarchy.length - 2].uuid;
    } else if (hierarchyIndex === 0) {
      newParentId = hierarchy[0].uuid;
    }
    saveAllStates(product, hierarchy, newParentId);
  }

  const updatesParentIdIfNecessary = (condition: boolean, hierarchy: HierarchyPair[], parentId: string) => {
    if (condition) parentId = hierarchy[hierarchy.length - 1].uuid;
    return parentId;
  };

  const saveNewProductToThePersistedHierarchy = (hierarchy: HierarchyPair[], product: Product) => {
    hierarchy.push({
      uuid: product.id ?? "",
      name: product?.serialNumber ? product.serialNumber : product?.materialCode + `(${product?.batch})`
    });
    return hierarchy;
  };

  const saveAllStates = (product: Product, hierarchy: HierarchyPair[], parentId?: string) => {
    setCurrentProduct(product);
    setHasMoreParents(product.parents.length > 0);
    saveHierarchyState({ currentHierarchy: hierarchy, currentParentId: parentId });
  };

  const findChildProductsBySerialNumberOrMaterialCodeAndBatch = (children: ProductChild[]): Promise<Product[]> => {
    return Promise.all(
      children.map(child => {
        if (child.serialNumber) {
          return getProductBySerialNumber(child);
        } else if (child.batch && child.materialCode) {
          return getProductByMaterialCodeAndBatch(child);
        } else {
          return new Promise<Product>((res, rej) => rej("No batch, amtCode nor SN present"));
        }
      })
    );
  };

  const getProductBySerialNumber = (child: ProductChild): Promise<Product> => {
    return new Promise((res, rej) => {
      if (child.serialNumber) {
        productRepository
          .getBySerialNumber({
            serialNumber: child.serialNumber,
            limit: 20,
            offset: 0,
            productsBasicFilterDTO: {
              description: "",
              state: "AGGREGATED"
            },
            sortDirection: GetProductBySerialNumberSortDirectionEnum.Asc,
            sortField: GetProductBySerialNumberSortFieldEnum.Manufacturingdate
          } as GetProductBySerialNumberRequest)
          .then(response => {
            const exactProduct = response.content?.find(product => product.serialNumber === child.serialNumber);
            handleExactProduct(exactProduct, res, rej);
          })
          .catch(err => rej(err));
      } else {
        rej();
      }
    });
  };

  const handleExactProduct = (
    exactProduct: Product | undefined,
    res: (value: Product | PromiseLike<Product>) => void,
    rej: (reason?: string) => void
  ) => {
    if (exactProduct) {
      return res(exactProduct);
    } else {
      rej("Product not found in list coming from backend");
    }
  };

  const getProductByMaterialCodeAndBatch = (child: ProductChild): Promise<Product> => {
    return new Promise((res, rej) => {
      if (child.batch && child.materialCode) {
        productRepository
          .getByBatchAndMaterialCode({
            batch: child.batch,
            materialCode: child.materialCode,
            limit: 1,
            offset: 0,
            productsBasicFilterDTO: {
              description: "",
              manufacturingDate: undefined,
              state: "AGGREGATED"
            },
            sortDirection: GetProductByBatchAndMaterialCodeSortDirectionEnum.Asc,
            sortField: GetProductByBatchAndMaterialCodeSortFieldEnum.Manufacturingdate
          } as GetProductByBatchAndMaterialCodeRequest)
          .then(response => {
            const product = response.content?.find(
              prod => prod.materialCode === child.materialCode && prod.batch === child.batch
            );
            if (product) {
              res(product);
            } else {
              rej("Product not found in list coming from backend");
            }
          })
          .catch(err => rej(err));
      } else {
        rej();
      }
    });
  };

  const filterArrayByNonUndefined = <T>(array: T[]) => {
    return array.filter(element => element !== undefined);
  };

  return {
    handleSelectProduct,
    productChildren,
    currentProduct,
    hasMoreParents,
    persistedState
  };
};
