import { useState } from "react";
import { ListZonesPaginatedRequest } from "@clients/aggrego-proxy";

interface IFilterZone {
  searchText: string;
  status: boolean;
  warehouseId: string;
}

export const useZonePagination = (
  selectedWarehouse: string,
  getCurrentStatus: (status: "ALL" | "ACTIVE" | "INACTIVE") => boolean | undefined,
  getZonesPaginated: (criteria: ListZonesPaginatedRequest) => Promise<void>
) => {
  const DEBOUNCE_TIME = 500;
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);

  const handlePagination = (limit: number, offset: number, status?: "ALL" | "ACTIVE" | "INACTIVE", filters?: object) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    const newDebounceTimer = setTimeout(() => {
      if (selectedWarehouse !== undefined && selectedWarehouse !== "") {
        getZonesPaginated({
          limit: limit,
          offset: offset,
          customZoneFilter: {
            status: getCurrentStatus(status ?? "ALL"),
            name: (filters as IFilterZone)?.searchText ?? "",
            warehouseId: selectedWarehouse,
            ...filters
          }
        });
      }
    }, DEBOUNCE_TIME);
    setDebounceTimer(newDebounceTimer);
  };

  return {
    handlePagination
  };
};
