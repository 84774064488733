import { Dispatch, SetStateAction, useEffect } from "react";
import { ResultData } from "../../interfaces/foundResultData-interface";

interface HandlePreselectedValueProps {
  setDataPlaceholder: Dispatch<SetStateAction<ResultData>>;
  preselectedValue?: ResultData;
}
export const HomeAutocompleteViewModelHelper = (props: HandlePreselectedValueProps) => {
  const { setDataPlaceholder, preselectedValue } = props;

  useEffect(() => {
    if (preselectedValue) setDataPlaceholder(preselectedValue);
  }, [preselectedValue]);
};
