import { Button, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { HomeContentTitle } from "./components/home-content-title";
import HomeSummaryStock from "./components/summaries/home-summary-stock";
import { WarehouseCircularProgress } from "../master-data/warehouses/components/circular-progress/circular-progress";
import { useHomeViewModel } from "./components/hooks/useHomeViewModel";
import { MovementEventDTO } from "@clients/aggrego-proxy";
import HomeLastMovements from "./components/home-tables/home-last-movements-table";
import { LineChart } from "@mui/x-charts";
import { useResolve } from "@movicoders/di";
import { DrawerService, IDrawerService } from "@domain/services/IDrawerService";
import { useTranslation } from "react-i18next";
import { COLORS } from "@theme";
export interface IHomeSummaryStockPackage {
  totalMaterials: string;
  totalFamilies: string;
  totalStockValue: number;
  totalStockValueLoading: boolean;
  inventoryReliabilityResult: number;
  inventoryReliabilityLoading: boolean;
  stockCoverageResult?: number;
}

export interface IHomeLastMovementsPackage {
  lastEntranceMovements: MovementEventDTO[];
  lastExitMovements: MovementEventDTO[];
}

const HomeDetail = () => {
  const { t, i18n } = useTranslation();

  const {
    isDashboardInitialized,
    dashboardInitiator,
    selectedWarehouse,
    totalMaterials,
    totalFamilies,
    totalStockValue,
    totalStockValueLoading,
    inventoryReliabilityResult,
    inventoryReliabilityLoading,
    stockCoverageResult,
    lastInboundsMovements,
    lastOutboundsMovements,
    lastWeekMovementsData,
    graphicData,
    portalSessionsAmount,
    mobilitySessionsAmount,
    updateReliability,
    updateTotalStockValue
  } = useHomeViewModel();

  const drawerService = useResolve<IDrawerService>(DrawerService);

  const homeSummaryStockPackage = {
    totalMaterials,
    totalFamilies,
    totalStockValue,
    totalStockValueLoading,
    inventoryReliabilityResult,
    inventoryReliabilityLoading,
    stockCoverageResult
  };

  const generateGraphic = () => {
    return (
      <Grid item>
        <div style={{ marginLeft: 10, marginRight: 10 }}>
          <HomeContentTitle name={t("home.movements.by.user")} />
        </div>
        <br />
        {lastWeekMovementsData && lastWeekMovementsData.length > 0 ? (
          <LineChart
            xAxis={[
              {
                scaleType: "utc",
                tickNumber: 7,
                data: [
                  new Date(new Date(new Date().setUTCHours(0, 0, 0, 0)).getTime() - 6 * 24 * 60 * 60 * 1000),
                  new Date(new Date(new Date().setUTCHours(0, 0, 0, 0)).getTime() - 5 * 24 * 60 * 60 * 1000),
                  new Date(new Date(new Date().setUTCHours(0, 0, 0, 0)).getTime() - 4 * 24 * 60 * 60 * 1000),
                  new Date(new Date(new Date().setUTCHours(0, 0, 0, 0)).getTime() - 3 * 24 * 60 * 60 * 1000),
                  new Date(new Date(new Date().setUTCHours(0, 0, 0, 0)).getTime() - 2 * 24 * 60 * 60 * 1000),
                  new Date(new Date(new Date().setUTCHours(0, 0, 0, 0)).getTime() - 1 * 24 * 60 * 60 * 1000),
                  new Date(new Date().setUTCHours(0, 0, 0, 0))
                ],
                valueFormatter: value =>
                  new Date(value).toLocaleString(i18n.language === "en" ? "en-EN" : "es-ES", {
                    localeMatcher: "lookup",
                    month: "long",
                    day: "numeric"
                  })
              }
            ]}
            series={graphicData}
            height={200}
            margin={{ top: 20, bottom: 20 }}
          />
        ) : (
          <Typography variant="title" color={COLORS.gray} align="center" marginTop={"5rem"}>
            {t("table.no.rows")}
          </Typography>
        )}
      </Grid>
    );
  };

  return (
    <Paper sx={{ p: 1, borderRadius: "0px 6px 6px 6px", boxShadow: "none" }}>
      {isDashboardInitialized ? (
        <>
          <HomeSummaryStock
            homeSummaryStockPackage={homeSummaryStockPackage}
            updateReliability={updateReliability}
            updateTotalStockValue={updateTotalStockValue}
          />
          <br />
          <HomeLastMovements title={"home.last.inbounds"} data={lastInboundsMovements} />
          <br />
          <HomeLastMovements title={"home.last.outbounds"} data={lastOutboundsMovements} />
          <br />
          <Paper sx={{ p: 1, width: "99%", height: "20rem" }}>{lastWeekMovementsData && generateGraphic()}</Paper>
          <br />
          <Grid container xs={12} id={"footer-container"} display={"flex"} justifyContent={"space-between"}>
            <Grid item xs={12} md={3}>
              <Paper sx={{ p: 1, width: "99%", height: "16rem" }}>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <HomeContentTitle name={"home.warehouse.occupancy"} />
                  </Grid>
                  <Grid item xs={8} md={8} sx={{ mt: 2, ml: 1 }}>
                    <WarehouseCircularProgress
                      data={
                        drawerService.persistedState?.warehouses.find(warehouse => warehouse.id === selectedWarehouse) ?? {
                          active: true
                        }
                      }
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} sx={{ ml: 1 }}>
              <Paper sx={{ p: 1, width: "99%", height: "16rem" }}>
                <Grid container id={"user-counter-container"} margin={"1rem"}>
                  <Grid item display="flex" xs={4}>
                    <HomeContentTitle name="home.portal.users" />
                  </Grid>
                  <Grid item xs={8}>
                    {portalSessionsAmount !== undefined ? (
                      <Typography variant="summarydashboard_quantity" align="right" marginRight="10rem">
                        {portalSessionsAmount}
                      </Typography>
                    ) : (
                      <Grid container alignItems="center" justifyContent="center">
                        <CircularProgress color="secondary" />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item display="flex" xs={4}>
                    <HomeContentTitle name="home.mobility.users" />
                  </Grid>
                  <Grid item xs={8}>
                    {mobilitySessionsAmount !== undefined ? (
                      <Typography variant="summarydashboard_quantity" align="right" marginRight="10rem">
                        {mobilitySessionsAmount}
                      </Typography>
                    ) : (
                      <Grid container alignItems="center" justifyContent="center">
                        <CircularProgress color="secondary" />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </>
      ) : (
        <Button id="master-data-create-button" variant="primary" onClick={dashboardInitiator}>
          {t("home.init.dashboard")}
        </Button>
      )}
    </Paper>
  );
};

export default HomeDetail;
