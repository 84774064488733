export class TableState {
  views: ViewTable[];

  constructor() {
    this.views = [];
  }
}

type ViewTable = {
  viewName: string;
  columns: Columns[];
};

type Columns = {
  fieldName: string;
  visible: boolean;
};
