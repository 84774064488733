import { useEffect, useState } from "react";
import { Product } from "@domain/model/Product";
import { ChildrenFormErrors } from "./ChildrenForm";
import { UUIDGenerator } from "@utils/uuid-generator";

export const useChildrenFormViewmodel = (addChildToParent: (product: Product) => void, isClearingView: boolean) => {
  const [child, setChild] = useState(new Product());
  const [childrenErrors, setChildrenErrors] = useState<ChildrenFormErrors>(new ChildrenFormErrors());

  useEffect(() => {
    if (isClearingView) {
      setChild(new Product());
    }
  }, [isClearingView]);

  const updateChild = (attr: keyof Product, value: Product[keyof Product]) => {
    const updatedChild = {
      ...child,
      [attr]: value as unknown as typeof attr
    };
    setChild(updatedChild as unknown as Product);
    if (attr === "batch" || attr === "serialNumber") {
      setChildrenErrors({ ...childrenErrors, batch: false, serialNumber: false });
    } else {
      setChildrenErrors({ ...childrenErrors, [attr]: false });
    }
  };

  const saveChild = () => {
    if (child && checkChildErrors()) {
      addChildToParent({ ...child, id: UUIDGenerator() } as Product);
      setChild(new Product());
    }
  };

  const checkChildErrors = (): boolean => {
    const childrenFormErrors = new ChildrenFormErrors();
    if (child.qty === undefined || child.qty <= 0) {
      childrenFormErrors.qty = true;
    }

    if (child.uom === undefined || child.uom.trim() === "") {
      childrenFormErrors.uom = true;
    }

    if (child.materialCode === undefined || child.materialCode.trim() === "") {
      childrenFormErrors.materialCode = true;
    }

    if (
      (child.batch === undefined || child.batch.trim() === "") &&
      (child.serialNumber === undefined || child.serialNumber.trim() === "")
    ) {
      childrenFormErrors.batch = true;
      childrenFormErrors.serialNumber = true;
    }

    setChildrenErrors(childrenFormErrors);
    return !childrenFormErrors.hasErrors();
  };

  return { updateChild, child, saveChild, childrenErrors };
};
