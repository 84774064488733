import { CREATE_ID } from "@constants";
import { useTranslation } from "react-i18next";
import { ChangeEvent, useEffect, useState } from "react";
import { useAdminBoardForm } from "./hooks/useAdminBoardForm";
import { CustomSwitch } from "@components/switch/CustomSwitch";
import { CompanyDTO, FullCompanyItemDTO } from "@clients/aggrego-proxy";
import { useHandleCompanyStatus } from "./hooks/useHandleCompanyStatus";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";
import { ButtonsDetail } from "@components/master-data-view/buttons/buttons-detail";
import { AdminBoardActivationDialog } from "./components/dialogs/admin-board-activation-dialog";

interface IDetail {
  title: string;
  company: CompanyDTO;
  onClose: () => void;
  onSave: (company: FullCompanyItemDTO) => Promise<void>;
}

export const AdminBoardDetailView = (props: IDetail) => {
  const { title, company: initialCompany, onClose, onSave } = props;
  const { company, onChange, isAnInvalidValue, handleSave, showErrors, onChangeCheckbox, onChangeAutocomplete } =
    useAdminBoardForm({
      selectedCompany: initialCompany,
      onSave: onSave
    });

  const { t } = useTranslation();
  const { togglingStatusCompany, isTogglingState, onCloseChangeStatus, handleCompanyChangeStatus } = useHandleCompanyStatus();

  const disabled = initialCompany.active !== undefined && initialCompany.active === false;
  const [pendingRequest, setPendingRequest] = useState<boolean>(false);

  const checkCompanyStatusHaveChanged = () => {
    if (initialCompany.active !== company.active) {
      handleCompanyChangeStatus(company);
    } else {
      setPendingRequest(true);
      handleSave();
    }
  };

  useEffect(() => {
    setPendingRequest(false);
  }, [initialCompany]);

  const renderInput = (
    id: string,
    name: string,
    value: string | number,
    label: string,
    xs: number,
    nonEditable: boolean,
    type?: string
  ) => {
    const error = isAnInvalidValue(value, type) && showErrors;

    return (
      <Grid item xs={xs}>
        <TextField
          error={error}
          id={id}
          value={value}
          name={name}
          type={type?.includes("position") ? "number" : type ?? "text"}
          onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event)}
          label={label}
          disabled={disabled || nonEditable}
          className={disabled || id === "admin-board-fiscal-id" ? "disabled-input" : "blue-border-input"}
        />
      </Grid>
    );
  };

  return (
    <>
      <AggregoDialog title={title} onClose={onClose}>
        <Grid
          container
          className="masterdata-box"
          justifyContent="space-between"
          rowSpacing={2}
          columnSpacing={1}
          sx={{ ml: -0.5 }}>
          {renderInput(
            "admin-board-company-name",
            "name",
            (company as CompanyDTO).name ?? "",
            t("admin.board.company.name"),
            6,
            false,
            "text"
          )}
          {renderInput(
            "admin-board-company-address",
            "address",
            company.address ?? "",
            t("admin.board.company.address"),
            6,
            false,
            "text"
          )}
          {renderInput("admin-board-fiscal-id", "fiscalId", company.fiscalId ?? "", t("admin.board.fiscal.id"), 6, true, "text")}
          <Grid item xs={6}>
            <Autocomplete
              id="admin-board-warehouse-policy"
              getOptionLabel={option => (option === "" ? t("autocomplete.default.value") : t(option))}
              value={company.warehousePolicy ?? ""}
              disabled={disabled}
              options={["", "LIFO", "STRICT_FIFO", "TIME_INTERVAL_FIFO"]}
              onChange={(ev, val) => {
                onChangeAutocomplete(ev, val, "warehousePolicy");
              }}
              renderInput={params => (
                <TextField
                  name={"warehousePolicy"}
                  {...params}
                  label={t("step-configuration-company-warehouse-policy")}
                  InputProps={{
                    ...params.InputProps
                  }}
                  className={disabled ? "disabled-input" : "blue-border-input"}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="admin-board-license"
              getOptionLabel={option => (option === "" ? t("autocomplete.default.value") : t(option))}
              value={company.licence}
              disabled={true}
              options={["", "IM", "WM", "WM_WITH_CONTAINER"]}
              onChange={(ev, val) => {
                onChangeAutocomplete(ev, val, "licence");
              }}
              renderInput={params => (
                <TextField
                  name={"license"}
                  {...params}
                  label={t("step-configuration-company-license")}
                  InputProps={{
                    ...params.InputProps
                  }}
                  className={"disabled-input"}
                />
              )}
            />
          </Grid>
          {renderInput("admin-board-image", "image", company.image ?? "", t("admin.board.image"), 6, false, "text")}

          <Grid item xs={3} sx={{}}>
            <Typography sx={{ ml: 0.5 }} variant="title">
              {t("root.activated")}
            </Typography>
          </Grid>

          <Grid item xs={3} sx={{ textAlign: "right" }}>
            <CustomSwitch
              id="admin-board-company-active"
              isEnabled={company.active ?? false}
              onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeCheckbox(event)}
              name="active"
            />
          </Grid>

          <Grid item xs={12}>
            <ButtonsDetail
              handleClose={onClose}
              handleSave={() => {
                checkCompanyStatusHaveChanged();
              }}
              mode={initialCompany.id === CREATE_ID ? "CREATE" : "EDIT"}
              disabled={false}
              pendingRequest={pendingRequest}
            />
          </Grid>
        </Grid>
      </AggregoDialog>

      {togglingStatusCompany && (
        <AdminBoardActivationDialog
          open={isTogglingState}
          onClose={onCloseChangeStatus}
          onActive={handleSave}
          company={togglingStatusCompany}
        />
      )}
    </>
  );
};
