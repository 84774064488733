/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MovicodersNotFoundException,
  MovicodersServiceException,
  MultimediaDTO,
} from '../models';
import {
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
    MultimediaDTOFromJSON,
    MultimediaDTOToJSON,
} from '../models';

export interface CreateExternalMultimediaRequest {
    xTenantId?: string;
    multimediaDTO?: MultimediaDTO;
}

export interface DeleteMultimediaRequest {
    id: string;
    maintainFile: boolean;
    xTenantId?: string;
}

export interface GetAllMultimediaRequest {
    xTenantId?: string;
    offset?: number;
    limit?: number;
}

export interface GetMultimediaByIDRequest {
    id: string;
    xTenantId?: string;
}

export interface GetMultipleMediasByIdsRequest {
    xTenantId?: string;
    requestBody?: Array<string>;
}

export interface MultimediaUploadRequest {
    body: Blob;
    xTenantId?: string;
}

export interface UpdateMultimediaRequest {
    id: string;
    maintainFile: boolean;
    body: Blob;
    xTenantId?: string;
}

export interface UpdateMultimediaToRollbackRequest {
    xTenantId?: string;
    requestBody?: Array<string>;
}

/**
 * 
 */
export class MediaApi extends runtime.BaseAPI {

    /**
     * Create external multimedia.
     * Create external multimedia.
     */
    async createExternalMultimediaRaw(requestParameters: CreateExternalMultimediaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MultimediaDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/media/external`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MultimediaDTOToJSON(requestParameters.multimediaDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MultimediaDTOFromJSON(jsonValue));
    }

    /**
     * Create external multimedia.
     * Create external multimedia.
     */
    async createExternalMultimedia(requestParameters: CreateExternalMultimediaRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MultimediaDTO> {
        const response = await this.createExternalMultimediaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes an existing Multimedia, deletes it, and returns the new object.
     * Delete an existing Multimedia by id.
     */
    async deleteMultimediaRaw(requestParameters: DeleteMultimediaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MultimediaDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteMultimedia.');
        }

        if (requestParameters.maintainFile === null || requestParameters.maintainFile === undefined) {
            throw new runtime.RequiredError('maintainFile','Required parameter requestParameters.maintainFile was null or undefined when calling deleteMultimedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.maintainFile !== undefined) {
            queryParameters['maintainFile'] = requestParameters.maintainFile;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/media/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MultimediaDTOFromJSON(jsonValue));
    }

    /**
     * Takes an existing Multimedia, deletes it, and returns the new object.
     * Delete an existing Multimedia by id.
     */
    async deleteMultimedia(requestParameters: DeleteMultimediaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MultimediaDTO> {
        const response = await this.deleteMultimediaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all the multimedia.
     * Get all Multimedia objects.
     */
    async getAllMultimediaRaw(requestParameters: GetAllMultimediaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MultimediaDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/media`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MultimediaDTOFromJSON));
    }

    /**
     * Returns all the multimedia.
     * Get all Multimedia objects.
     */
    async getAllMultimedia(requestParameters: GetAllMultimediaRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MultimediaDTO>> {
        const response = await this.getAllMultimediaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns one Multimedia object by id.
     * Get a Multimedia object by id.
     */
    async getMultimediaByIDRaw(requestParameters: GetMultimediaByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MultimediaDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMultimediaByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/media/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MultimediaDTOFromJSON(jsonValue));
    }

    /**
     * Returns one Multimedia object by id.
     * Get a Multimedia object by id.
     */
    async getMultimediaByID(requestParameters: GetMultimediaByIDRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MultimediaDTO> {
        const response = await this.getMultimediaByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recovers a list of multimediaDTO from a list of uuids.
     * Recovers a list of multimediaDTO from a list of UUIDs.
     */
    async getMultipleMediasByIdsRaw(requestParameters: GetMultipleMediasByIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MultimediaDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/media/multiples`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MultimediaDTOFromJSON));
    }

    /**
     * Recovers a list of multimediaDTO from a list of uuids.
     * Recovers a list of multimediaDTO from a list of UUIDs.
     */
    async getMultipleMediasByIds(requestParameters: GetMultipleMediasByIdsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MultimediaDTO>> {
        const response = await this.getMultipleMediasByIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Uploads a file and returns a multimedia object.
     * Uploads a file.
     */
    async multimediaUploadRaw(requestParameters: MultimediaUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MultimediaDTO>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling multimediaUpload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.body !== undefined) {
            formParams.append('body', requestParameters.body as any);
        }

        const response = await this.request({
            path: `/media`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MultimediaDTOFromJSON(jsonValue));
    }

    /**
     * Uploads a file and returns a multimedia object.
     * Uploads a file.
     */
    async multimediaUpload(requestParameters: MultimediaUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MultimediaDTO> {
        const response = await this.multimediaUploadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes an existing Multimedia object, updates it, and returns the object.
     * Update an existing Multimedia.
     */
    async updateMultimediaRaw(requestParameters: UpdateMultimediaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MultimediaDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMultimedia.');
        }

        if (requestParameters.maintainFile === null || requestParameters.maintainFile === undefined) {
            throw new runtime.RequiredError('maintainFile','Required parameter requestParameters.maintainFile was null or undefined when calling updateMultimedia.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateMultimedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.maintainFile !== undefined) {
            queryParameters['maintain-file'] = requestParameters.maintainFile;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.body !== undefined) {
            formParams.append('body', requestParameters.body as any);
        }

        const response = await this.request({
            path: `/media/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MultimediaDTOFromJSON(jsonValue));
    }

    /**
     * Takes an existing Multimedia object, updates it, and returns the object.
     * Update an existing Multimedia.
     */
    async updateMultimedia(requestParameters: UpdateMultimediaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MultimediaDTO> {
        const response = await this.updateMultimediaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update multimedia to rollback.
     * Update multimedia to rollback.
     */
    async updateMultimediaToRollbackRaw(requestParameters: UpdateMultimediaToRollbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/media/rollback`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Update multimedia to rollback.
     * Update multimedia to rollback.
     */
    async updateMultimediaToRollback(requestParameters: UpdateMultimediaToRollbackRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.updateMultimediaToRollbackRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
