import { create } from "@movicoders/store";
import { IZonesEdition3DStore } from "./IZonesEdition3DStore";
import Zone from "../../../../../domain/model/Zone";
import { ZONE_EDITION_MODES } from "../../constants/ZoneEditionModes";

export const useZonesEdition3DStore = create<IZonesEdition3DStore>((set, get) => ({
  zones: [],
  selectedZone: undefined,
  selectedTilesIds: [],
  zonesEditingMode: "WRITE",
  hoveredZone: "",
  getZones: () => get().zones,
  getSelectedZone: () => get().selectedZone,
  getSelectedTilesIds: () => get().selectedTilesIds,
  getZonesEditingMode: () => get().zonesEditingMode,
  getHoveredZone: () => get().hoveredZone,
  setZones: (newValue: Zone[]) => set((state) => ({ ...state, zones: newValue })),
  setSelectedZone: (newValue: Zone | undefined) => set((state) => ({ ...state, selectedZone: newValue })),
  setSelectedTilesIds: (newValue: string[]) => set((state) => ({ ...state, selectedTilesIds: newValue })),
  setZonesEditingMode: (newValue: ZONE_EDITION_MODES) => set((state) => ({ ...state, zonesEditingMode: newValue })),
  setHoveredZone: (newValue: string) => set((state) => ({ ...state, hoveredZone: newValue }))
})).build();