import { COLORS } from "@theme";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Button } from "@mui/material";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";

export const LogisticErrorDialog = (props: { onClose: () => void; error: string }) => {
  const { t } = useTranslation();
  const { error, onClose } = props;

  return (
    <>
      {error?.toUpperCase() !== "OK" && (
        <AggregoDialog title={t("dialog.error.title")} onClose={onClose}>
          <Grid container sx={{ display: "flex", flexDirection: "column" }}>
            <Grid item>
              <Typography sx={{ marginTop: "0.25rem", marginBottom: "0.625rem", color: COLORS.secondary }}>
                {t("dialog.error.body")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ marginTop: "0.25rem" }} variant="form_info">
                {t("dialog.error.code")}
              </Typography>
              <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>{error ?? t("internal.error")}</Typography>
            </Grid>

            <Button
              id="ok-button"
              variant="text"
              title="OK"
              sx={{ alignSelf: "end", color: COLORS.buttonGrayText }}
              onClick={onClose}>
              {"Ok"}
            </Button>
          </Grid>
        </AggregoDialog>
      )}
    </>
  );
};
