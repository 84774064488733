import { CompanyDTO } from "@clients/aggrego-proxy";
import { CompanyRepository } from "@infrastructure/repositories/company-repository";

export class CompanyUpdateStatusUseCase {
  constructor(private readonly companyRepository: CompanyRepository) {}

  public async execute(company: CompanyDTO) {
    const modifiedType = { ...company, active: !company.active };
    return await this.companyRepository.update(modifiedType);
  }
}
