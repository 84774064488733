import { useResolve } from "@movicoders/di";
import { IUserService, UserService } from "@domain/services/IUserService";

export const useCheckLicense = () => {
  const userService = useResolve<IUserService>(UserService);

  const checkLicense = (type: string) => {
    return userService.user?.companyLicense === type;
  };

  return { checkLicense };
};
