import Material from "@domain/model/Material";
import { useEffect, useRef, useState } from "react";
import { LastPageState } from "@domain/model/LastPageState";
import { useListDetailPaginatedViewModel } from "@movicoders/ui";
import { ILastPageService } from "@domain/services/ILastPageService";
import { ListMaterialsPaginatedRequest } from "@clients/aggrego-proxy";
import { MaterialRepository } from "@infrastructure/repositories/material-repository";

export const useMovementsFilterAutocomplete = (
  currentPageService: ILastPageService,
  currentPagePersistedState: LastPageState
) => {
  const [filteredMaterialPaginatedData, setFilteredMaterialPaginatedData] = useState<Material[]>([]);

  const { data: materialPaginatedData, getPaginated: getMaterialPaginated } = useListDetailPaginatedViewModel(MaterialRepository);
  const getMaterialPaginatedForFilter = useRef(getMaterialPaginated).current;

  useEffect(() => {
    if (currentPagePersistedState.autocompleteFilterText !== undefined && !currentPagePersistedState.autocompleteFilterLoading) {
      currentPageService.saveLastPageState({ ...currentPagePersistedState, autocompleteFilterLoading: true });
      getMaterialPaginatedForFilter({
        xTenantId: "",
        limit: 10,
        offset: 0,
        fullDTO: false,
        customMaterialFilter: {
          ...currentPagePersistedState.filters,
          searchText: currentPagePersistedState.autocompleteFilterText
        }
      } as ListMaterialsPaginatedRequest).finally(() => {
        currentPageService.saveLastPageState({
          ...currentPagePersistedState,
          autocompleteFilterText: undefined,
          autocompleteFilterLoading: false
        });
      });
    }
  }, [currentPagePersistedState.autocompleteFilterText]);

  useEffect(() => {
    setFilteredMaterialPaginatedData(
      (materialPaginatedData.content as unknown as Material[])?.filter(
        material =>
          material.code.toLowerCase().includes(currentPagePersistedState.autocompleteFilterText?.toLowerCase() ?? "") ||
          material.name.toLowerCase().includes(currentPagePersistedState.autocompleteFilterText?.toLowerCase() ?? "")
      )
    );
  }, [materialPaginatedData]);

  return {
    filteredMaterialPaginatedData
  };
};
