import { COLORS } from "@theme";
import { cardStyles } from "./cardStyles";
import { Divider, Paper } from "@mui/material";
import { CardTitle } from "./components/title/card-title";
import IPopover from "./components/title/popover-interface";

export const Card = (props: {
  id: string;
  name?: string;
  nameValue: string;
  popover: IPopover;
  isActive: boolean;
  children?: React.ReactNode;
  color?: string;
}) => {
  const styles = cardStyles();
  const { id, children, nameValue, popover, isActive, name, color } = props;

  const handleCardColorByStatus = () => {
    if (isActive) {
      return color ? `linear-gradient(white 0 0) padding-box, ${color} border-box` : COLORS.cardGradientActive;
    } else {
      return COLORS.cardGradientDeactive;
    }
  };

  return (
    <Paper
      id={id}
      data-cy={`id-card-${isActive}`}
      sx={{
        ...styles.card_style,
        ...(isActive === false && styles.card_class_deactivate),
        background: handleCardColorByStatus()
      }}>
      <>
        <CardTitle name={name} value={nameValue} popover={popover} />
        <Divider sx={{ mt: "0.5rem", mb: "0.5rem", borderColor: isActive ? color : "#EBEBEB" }}></Divider>
      </>
      {children}
    </Paper>
  );
};
