import { useTranslation } from "react-i18next";
import { DataGridDisplay } from "@components/data-grid-display/data-grid-display";
import { DataGridDisplayBasicToolbarProps } from "@components/data-grid-display/toolbar/config/data-grid-display-basic-toolbar-props";
import { GridColDef } from "@mui/x-data-grid";
import { IUserService, UserService } from "@domain/services/IUserService";
import { useResolve } from "@movicoders/di";
import { FullUserDTOCompanyLicenseEnum, MovementEventDTO } from "@clients/aggrego-proxy";
import { CircularProgress, Grid } from "@mui/material";
import { HomeContentTitle } from "../home-content-title";

interface IHomeLastMovements {
  title: string;
  data: MovementEventDTO[];
}

const HomeLastMovements = (props: IHomeLastMovements) => {
  const { t } = useTranslation();
  const { title, data } = props;
  const currentUserPersistedState = useResolve<IUserService>(UserService);

  const tablePropsAllHidden = {
    isSearchBarHidden: true,
    isActiveFilterHidden: true,
    isColumnsMenuIconButtonHidden: true,
    isDownloadIconButtonHidden: true,
    isFilterIconButtonHidden: true
  } as DataGridDisplayBasicToolbarProps;

  const movementsCols: GridColDef[] = [
    { flex: 0.6, field: "origin", headerName: t("movements-origin") },
    { flex: 0.6, field: "destiny", headerName: t("movements-destiny") },
    { flex: 1.2, field: "container", headerName: t("movements-container") },
    { flex: 1.2, field: "material", headerName: t("movements-code") },
    { flex: 1, field: "description", headerName: t("movements-material") },
    { flex: 0.5, field: "family", headerName: t("movements-family") },
    { flex: 0.4, field: "quantity", headerName: t("movements-qty") },
    { flex: 0.8, field: "comment", headerName: t("movements-comment") },
    {
      flex: 0.8,
      field: "actionDate",
      headerName: t("movements-date"),
      valueGetter: ({ row }) => {
        return row.actionDate
          ? row.actionDate.toLocaleString("es-ES", {
              localeMatcher: "lookup",
              month: "numeric",
              day: "numeric",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit"
            })
          : "-";
      }
    },
    { flex: 0.7, field: "user", headerName: t("movements-license") },
    { flex: 0.7, field: "operatorCode", headerName: t("movements-operator") }
  ];

  return (
    <Grid className="dashboard-table">
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        <HomeContentTitle name={t(title)} />
      </div>
      {data ? (
        <DataGridDisplay
          toolbarProps={tablePropsAllHidden}
          data={data}
          columns={movementsCols}
          mode="server"
          viewName="materials"
          hideColumnsByField={[
            "id",
            "actions",
            currentUserPersistedState.user?.companyLicense !== FullUserDTOCompanyLicenseEnum.WmWithContainer ? "container" : ""
          ]}
          handleBackendPagination={() => null}
          backendTotalElements={data.length ?? 0}
          hideRowCount={true}
          hideFooter={true}
        />
      ) : (
        <Grid container alignItems="center" justifyContent="center">
          <CircularProgress color="secondary" />
        </Grid>
      )}
    </Grid>
  );
};

export default HomeLastMovements;
