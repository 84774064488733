import { useState } from "react";
import { ListFamilyTypesPaginatedRequest } from "@clients/aggrego-proxy";

export const useTypeOfFamiliesPagination = (
  getTypeOfFamiliesPaginated: (criteria: ListFamilyTypesPaginatedRequest) => Promise<void>,
  getCurrentStatus: (status: "ALL" | "ACTIVE" | "INACTIVE") => boolean | undefined
) => {
  const DEBOUNCE_TIME = 500;
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);

  const handlePagination = (limit: number, offset: number, status?: "ALL" | "ACTIVE" | "INACTIVE", filters?: object) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    const newDebounceTimer = setTimeout(() => {
      getTypeOfFamiliesPaginated({
        limit: limit,
        offset: offset,
        basicFilterDTO: { status: getCurrentStatus(status ?? "ALL"), ...filters }
      });
    }, DEBOUNCE_TIME);
    setDebounceTimer(newDebounceTimer);
  };

  return {
    handlePagination
  };
};
