import Family from "@domain/model/Family";
import { FamilyRepository } from "@infrastructure/repositories/family-repository";

export class FamilyUpdateStatusUseCase {
  constructor(private readonly familyRepository: FamilyRepository) {}

  public async execute(type: Family) {
    const modifiedType = { ...type, active: !type.active };
    return await this.familyRepository.update(modifiedType);
  }
}
