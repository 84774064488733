import { SxProps, Theme } from "@mui/material";

export const loginCardStyles = () => {
  const login_box: SxProps<Theme> = {
    backgroundColor: "white",
    borderRadius: "6px",
    padding: "1rem",
    marginTop: "2.8rem",
    width: "420px",
    height: "fit-content",

    "&& > *:not(:last-child)": {
      marginBottom: "2rem"
    }
  };

  return {
    login_box
  };
};
