import { useEffect } from "react";
import { COLORS, MEDIA } from "@theme";
import Icon from "@components/icons/icon";
import { useTranslation } from "react-i18next";
import { emailRegexp } from "../hooks/useStepper";
import { stepperStyles } from "../stepper-Styles";
import { CompanyCreateDTO } from "../company-create-model";
import { CustomPassword } from "@components/password/CustomPassword";
import { useCompanyStepperViewModel } from "../hooks/useFormCompanyStepper";
import { Grid, TextField, Theme, Typography, useMediaQuery } from "@mui/material";

export const StepCreateUser = (props: {
  companyCreate: CompanyCreateDTO;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  bannedWordsForPassword: string[];
}) => {
  const { t } = useTranslation();
  const { companyCreate, onChange, bannedWordsForPassword } = props;
  const styles = stepperStyles();

  const { confirmPasswordValue, passwordValue, setPasswordValue, setConfirmPasswordValue, isSamePassword } =
    useCompanyStepperViewModel();

  const matchesMd = useMediaQuery((theme: Theme) => theme.breakpoints.up(MEDIA.medium), { noSsr: true });

  const emailValid =
    (companyCreate.email !== null &&
      companyCreate.email !== undefined &&
      companyCreate.email.trim() !== "" &&
      emailRegexp.test(companyCreate.email)) ||
    companyCreate.email?.length === 0;

  useEffect(() => {
    companyCreate.password = "";
  }, []);

  const renderInput = (id: string, name: string, value: string | number, label: string, type?: string, error?: boolean) => {
    return (
      <Grid item>
        <Grid container alignItems="center" spacing={{ xs: 1 }}>
          <Grid item display="flex" xs={12} lg={3}>
            <Icon icon="company" color={COLORS.primary} />
            <Typography sx={styles.item_typography}>{label}</Typography>
          </Grid>

          <Grid item sm={4} lg={3}>
            <TextField
              id={id}
              type={type ?? "text"}
              value={value}
              name={name}
              onChange={onChange}
              label={label}
              className={"blue-border-input"}
              error={error ?? false}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container flexDirection="column" className="masterdata-box" rowSpacing={2}>
      <Grid item>
        <Typography sx={styles.categories_typography}>{t("step-create-user-admin-data")}</Typography>
      </Grid>
      <Grid item>
        <Grid container alignItems="center" spacing={{ xs: 1 }}>
          <Grid item display="flex" xs={12} lg={3}>
            <Icon icon="company" color={COLORS.primary} />
            <Typography sx={styles.item_typography}>{t("step-master-data-company-name")}</Typography>
          </Grid>

          <Grid item sm={4} lg={3}>
            <TextField
              id="stepper-company-firstname"
              value={companyCreate.firstName ?? ""}
              name={"firstName"}
              onChange={onChange}
              label={t("step-create-user-firstname")}
              className={"blue-border-input"}
            />
          </Grid>
          <Grid item sm={4} lg={3}>
            <TextField
              id={"stepper-company-surname"}
              value={companyCreate.surname ?? ""}
              name={"surname"}
              onChange={onChange}
              label={t("step-create-user-surname")}
              className={"blue-border-input"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography sx={styles.categories_typography}>{t("step-create-user-access-data")}</Typography>
      </Grid>
      {renderInput("stepper-company-email", "email", companyCreate.email ?? "", t("step-create-user-email"), "text", !emailValid)}
      <Grid item>
        <Grid container alignItems="center" spacing={{ xs: 1.5 }}>
          <Grid item display="flex" xs={12} lg={3}>
            <Icon icon="company" color={COLORS.primary} />
            <Typography sx={styles.item_typography}>{t("step-create-user-password")}</Typography>
          </Grid>
          <Grid item display="flex" xs={12} lg={6}>
            <CustomPassword
              isRowDisplayed={matchesMd}
              smCustom={3.83}
              mdCustom={3.83}
              lgCustom={6}
              onChange={onChange}
              confirmPasswordValue={confirmPasswordValue}
              setConfirmPasswordValue={setConfirmPasswordValue}
              setPasswordValue={setPasswordValue}
              passwordValue={passwordValue}
              isErrorDisplay={!isSamePassword && passwordValue !== "" && confirmPasswordValue !== ""}
              errorTextPass=""
              enablePassswordValidation
              bannedPasswordWords={bannedWordsForPassword}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
