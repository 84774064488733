import { SxProps, Theme } from "@mui/material";
import { COLORS } from "../../../../theme";

export const materialsStockStyles = () => {
  const stock_title_divider: SxProps<Theme> = {
    border: "2px solid #EBEBEB",
    opacity: 1,
    width: "100%",
    marginTop: 1,
    marginBottom: 1
  };

  const stock_select_styles: SxProps<Theme> = {
    fontSize: "small",
    justifyContent: "space-between",
    mr: 1,
    minWidth: { md: "23%", xs: "100%" },
    borderWidth: "1px",
    color: COLORS.gray,
    backgroundColor: "white",
    height: "2.5rem",
    fieldset: {
      borderColor: COLORS.gray
    },
    "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px"
    },
    "&:hover": {
      "&& fieldset": {
        borderColor: COLORS.gray
      }
    },
    "&.Mui-focused": {
      "&& fieldset": {
        borderColor: COLORS.gray
      }
    }
  };

  const stock_paper_field: SxProps<Theme> = {
    mb: 1,
    mr: 1,
    width: "90%"
  };

  const stock_input_field: SxProps<Theme> = {
    fontSize: "small",
    justifyContent: "space-between",
    width: "90%",
    paddingTop: "5.5px",
    color: COLORS.darkgray,
    marginLeft: "0.7rem"
  };

  const stock_icon: SxProps<Theme> = {
    background: COLORS.secondary,
    borderRadius: "4px",
    height: "fit-content",
    marginLeft: "1rem"
  };

  const stock_search_label: SxProps<Theme> = {
    fontSize: "0.625rem",
    color: COLORS.alert
  };

  return {
    stock_title_divider,
    stock_select_styles,
    stock_paper_field,
    stock_input_field,
    stock_icon,
    stock_search_label
  };
};
