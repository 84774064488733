import { useResolve } from "@movicoders/di";
import { UserRoleEnum } from "@domain/interface/User";
import { CheckUserRoleUseCase } from "@application/permissions/check-user-role-use-case";

export const useCheckStatus = () => {
  const checkUserRoleUseCase = useResolve(CheckUserRoleUseCase);

  const getCurrentStatus = (status: "ACTIVE" | "INACTIVE" | "ALL"): boolean | undefined => {
    const STATUS_ACTIVE = true;
    const STATUS_INACTIVE = false;

    if (checkUserRoleUseCase.isRole(UserRoleEnum.PortalAdmin) || checkUserRoleUseCase.isRole(UserRoleEnum.SuperAdmin)) {
      if (status === "ACTIVE") {
        return STATUS_ACTIVE;
      }
      if (status === "INACTIVE") {
        return STATUS_INACTIVE;
      } else {
        return undefined;
      }
    } else {
      return STATUS_ACTIVE;
    }
  };

  const handleStatusFilter = () => {
    if (!(checkUserRoleUseCase.isRole(UserRoleEnum.PortalAdmin) || checkUserRoleUseCase.isRole(UserRoleEnum.SuperAdmin))) {
      return true;
    } else {
      return false;
    }
  };

  return { checkUserRoleUseCase, handleStatusFilter, getCurrentStatus };
};
