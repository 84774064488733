import React from "react";
import { EntriesTabs } from "../components/entries-tabs";
import { EntriesTitle } from "../components/entries-title";
import { EntriesSyncContent } from "../components/entries-sync-content";
import { useLogisticSyncViewModel } from "../../hooks/useLosgisticSyncViewModel";
import { OutletContainer } from "../../../../components/outlet-container/outlet-container";
import { LogisticErrorDialog } from "../../components/logistic-error-dialog/logistic-error-dialog";
import { useHandleErrorDialog } from "../../components/logistic-error-dialog/useHandleErrorDialog";

export const OutboundsSyncView = () => {
  const { cardsArray, handleTabSelect, handleReturnBackToMainOutboundsView } = useLogisticSyncViewModel();
  const { errorMessage, handleErrorDialog } = useHandleErrorDialog();

  return (
    <>
      <OutletContainer>
        <React.Fragment>
          <EntriesTitle text="outbounds" textId="outbounds-title" iconId="material-out" />
          <EntriesTabs onClick={handleTabSelect} />
          <EntriesSyncContent
            buttonId="outbounds-sync-return-button"
            cardsArray={cardsArray}
            handleErrorDialog={handleErrorDialog}
            handleReturnBackTo={handleReturnBackToMainOutboundsView}
          />
        </React.Fragment>
      </OutletContainer>
      <LogisticErrorDialog onClose={() => handleErrorDialog("CLOSE")} error={errorMessage} />
    </>
  );
};
