import { StockDTO } from "@domain/model/Stock";
import { ReportApi } from "@infrastructure/api/report-api";

export class StockReportUseCase {
  constructor(private readonly reportApi: ReportApi) {}

  public async getLatest(selectedWarehouseCode: string) {
    const allStocks = (
      await this.reportApi.getStockPaginated({
        limit: 1,
        offset: 0,
        warehouseCode: selectedWarehouseCode ?? "",
        stockReportFilter: {}
      })
    ).content;
    const sortedStocks = allStocks?.sort(
      (a: StockDTO, b: StockDTO) => (b?.date ?? new Date()).getTime() - (a?.date ?? new Date()).getTime()
    );
    const latestStock =
      sortedStocks !== undefined && sortedStocks.length && sortedStocks[0].date ? sortedStocks[0].date?.toLocaleString() : "";
    return latestStock;
  }
}
