import { COLORS } from "@theme";
import { useEffect } from "react";
import { useResolve } from "@movicoders/di";
import Icon from "../../components/icons/icon";
import { useTranslation } from "react-i18next";
import { Grid as MUIGrid } from "@mui/material";
import { HomeTabs } from "./components/home-tabs";
import { useListViewModel } from "@movicoders/ui";
import { HomeTitle } from "./components/home-title";
import { DrawerState } from "@domain/model/DrawerState";
import { PortalTypes } from "@domain/model/PortalTypes";
import { DrawerService, IDrawerService } from "@domain/services/IDrawerService";
import { OutletContainer } from "../../components/outlet-container/outlet-container";
import { WarehouseRepository } from "@infrastructure/repositories/warehouse-repository";
import { useImpersonationViewModel } from "../../components/layout/drawer/components/impersonation/useImpersonationViewModel";

const HomeView = () => {
  const { t } = useTranslation();
  const { data, goToDetail } = useListViewModel(WarehouseRepository);
  const service = useResolve<IDrawerService>(DrawerService);
  const persistedState = service.persistedState ?? new DrawerState();

  const handleWarehouseSelect = (id: string) => {
    service.saveDrawerState({ ...persistedState, selectedWarehouse: id });
    goToDetail(id);
  };

  const { onChangePortal } = useImpersonationViewModel();

  useEffect(() => {
    onChangePortal(PortalTypes.AGGREGO);
  }, [data]);

  return sessionStorage.getItem("sessionToken") !== null ? (
    <OutletContainer>
      <MUIGrid container display="flex" flexDirection="column">
        <HomeTitle id="home-title" icon={<Icon icon="home" color={COLORS.primary} />} name={t("home.title")} />
        <p />
        <HomeTabs data={persistedState.warehouses.filter(wh => wh.active)} onClick={handleWarehouseSelect} />
      </MUIGrid>
    </OutletContainer>
  ) : (
    <></>
  );
};

export default HomeView;
