import { useSnackbar } from "@movicoders/ui";
import { useTranslation } from "react-i18next";
import Warehouse from "@domain/model/Warehouse";

export const useWarehousesMessagges = () => {
  const { t } = useTranslation();
  const { show } = useSnackbar();

  const errorMessaggesSaving = (warehouse: Warehouse, error: string, isCreating: boolean) => {
    const errorText = warehouse.name ? "" : ".undefined";
    show(
      isCreating
        ? t(`creating.${error}${errorText}`, {
            value: warehouse.name ? `"${warehouse.name}"` : "",
            mdNameA: t("a.warehouse"),
            mdNameThe: t("the.warehouse"),
            reason: t("not.found.warehouse"),
            mdNameNew: t("a.new.warehouse")
          })
        : t(`saving.${error}${errorText}`, {
            value: warehouse.name ? `"${warehouse.name}"` : "",
            mdNameA: t("a.warehouse"),
            mdNameThe: t("the.warehouse"),
            reason: t("not.found.warehouse")
          }),
      "error"
    );
  };

  const successMessaggesSaving = (creating: boolean) => {
    show(t(`warehouse.${creating ? "create" : "edit"}.success`), "success");
  };

  const errorMessaggesDeactivateWarehouse = (error: string, warehouse?: Warehouse) => {
    const errorText = warehouse?.name ? "" : ".undefined";
    show(
      !warehouse?.active
        ? t(`activating.${error}${errorText}`, {
            value: warehouse?.name ? `"${warehouse.name}"` : "",
            mdNameA: t("a.warehouse"),
            mdNameThe: t("the.warehouse"),
            reason: t("not.found.warehouse")
          })
        : t(`deactivating.${error}${errorText}`, {
            value: warehouse?.name ? `"${warehouse.name}"` : "",
            mdNameA: t("a.warehouse"),
            mdNameThe: t("the.warehouse"),
            reason: t("not.found.warehouse")
          }),
      "error"
    );
  };

  const successMessaggesDeactivateWarehouse = () => {
    show(t(`warehouse.deactivate.success`), "success");
  };

  return {
    successMessaggesSaving,
    errorMessaggesSaving,
    errorMessaggesDeactivateWarehouse,
    successMessaggesDeactivateWarehouse
  };
};
