import { IUserService } from "@domain/services/IUserService";
import { Configuration } from "@clients/aggrego-proxy/runtime";

export class AggregoProxyApiConfiguration extends Configuration {
  constructor(private readonly userService: IUserService) {
    super({ basePath: `${process.env.REACT_APP_BASE_BACK_URL}:50010` });
  }

  get apiKey(): ((name: string) => string) | undefined {
    const apiKey = this.userService.current()?.token;
    return apiKey ? () => `Bearer ${apiKey}` : undefined;
  }
}
