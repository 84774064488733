import { COLORS } from "@theme";
import Zone from "@domain/model/Zone";
import Icon from "@components/icons/icon";
import { ROUTER_PATHS } from "@constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card } from "@components/master-data-view/card/card";
import { Typography, Grid, IconButton, Tooltip } from "@mui/material";

export const ZoneCard = (props: { zone: Zone; handleEdit: (id: string) => void; handleActive: (item: Zone) => void }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { zone, handleEdit, handleActive } = props;

  const navigateToViewer = () => navigate(ROUTER_PATHS.warehouseViewer);

  const renderChild = (name: string, value: string, key?: string) => {
    return (
      <div className="value-card-container">
        <div className="title-content" data-cy={`zone-card-${name}-container`}>
          <Grid container direction="row">
            <Grid item xs={10}>
              <Typography variant="cardlabel" textAlign="left">
                {t(name)}
              </Typography>
              <Typography variant="cardvalue" data-cy={`value`} className="value-typography" whiteSpace="pre-line">
                {t(value)}
              </Typography>
            </Grid>
            <Grid item xs={2} justifyContent="flex-end" sx={{ display: "flex" }}>
              <Tooltip title={t("tooltip.access.warehouse.viewer")}>
                <IconButton disabled={!zone.active} onClick={navigateToViewer}>
                  <Icon color={zone.active ? COLORS.primary : COLORS.gray} icon="3d" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

  const rendererCard = (data: Zone) => {
    return (
      <Card
        isActive={data.active}
        color={data.color !== COLORS.primary ? data.color : COLORS.cardGradientActive}
        key={data.id}
        popover={{
          handleEdit: () => handleEdit(data.id ?? ""),
          handleActive: () => handleActive(data),
          activateTitle: data.active ? t("root.deactivate") : t("root.activate")
        }}
        id={"id-card-" + data.id}
        nameValue={data.name}>
        {renderChild("zones.state", data.active ? t("zones.active") : t("zones.inactive"), data.id)}
      </Card>
    );
  };

  return rendererCard(zone);
};
