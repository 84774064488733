import { COLORS } from "@theme";
import { ROUTER_PATHS } from "@constants";
import Icon from "@components/icons/icon";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { hierarchyReportStyles } from "./hierarchy-report.Styles";
import { SimpleSearchBox } from "@components/search/SimpleSearchBox";
import { HierarchyRecord } from "./components/record/hierarchy-record";
import { OutletContainer } from "@components/outlet-container/outlet-container";
import { useHierarchyReportViewModel } from "./hooks/useHierarchyReportViewModel";
import { HierarchyBreadcrumbs } from "./components/breadcrumbs/hierarchy-breadcrumbs";
import { HierarchyTrackingParentCard } from "./components/hierarchy-tracking-parent-card";
import { ProductDTO } from "@clients/aggrego-proxy";

const ProductHierarchyView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const styles = hierarchyReportStyles();
  const {
    recordOpen,
    setRecordOpen,
    selectedCard,
    handleSelectedCard,
    currentProduct,
    hasMoreParents,
    onChangeHierarchyLevel,
    levelUpHierarchyLevel,
    currentParentId,
    selectedData,
    handleFilterData,
    filterData,
    hasVersionLabel
  } = useHierarchyReportViewModel(id);

  const navigateToProductList = () => navigate(ROUTER_PATHS.products);

  return (
    <OutletContainer>
      {/** TITLE */}
      <Grid container mb="4rem">
        <Grid item container rowSpacing="1.5rem" sx={styles.title_container}>
          <Grid item container wrap="nowrap" spacing={{ xs: "1rem" }}>
            <Grid item alignSelf="center">
              <Icon icon="palet" color={COLORS.primary} />
            </Grid>
            <Grid item className="main-view-title" sx={{ cursor: "pointer" }} onClick={navigateToProductList}>
              <Typography>{t("hierarchy.report.title")}</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography id="hierarchy-go-back" onClick={navigateToProductList} className="hover" sx={styles.hierarchy_go_back}>
              {t("hierarchy.report.back")}
            </Typography>
          </Grid>
          <Grid item>
            <Divider variant="fullWidth" />
          </Grid>
        </Grid>

        {/** BREADCRUMBS */}
        <Grid container style={{ padding: 2, paddingBottom: 10 }}>
          <HierarchyBreadcrumbs currentProduct={currentProduct as ProductDTO} />
        </Grid>

        <Grid container sx={{ pb: 0.7, ml: -1 }} spacing={1}>
          <Grid item>
            <Button
              id="hierarchy-go-up"
              disabled={!hasMoreParents || !currentParentId}
              variant="primary"
              sx={{ backgroundColor: !hasMoreParents ? COLORS.lightgray : "" }}
              onClick={levelUpHierarchyLevel}>
              <Icon icon="arrow-up" style={{ marginRight: 10 }} />
              {t("hierarchy.report.parent.button")}
            </Button>
          </Grid>
          <Grid item sx={{ width: "40rem" }}>
            <SimpleSearchBox placeholder={t("search.placeholder")} clear={true} handleSearch={handleFilterData} />
          </Grid>
        </Grid>

        {/** CARDS */}
        <Grid container>
          {currentProduct && (
            <Grid item xs={12}>
              <HierarchyTrackingParentCard
                product={currentProduct}
                productChildren={filterData}
                selectedCard={selectedCard}
                setSelectedCard={handleSelectedCard}
                onChangeHierarchyLevel={onChangeHierarchyLevel}
                hasVersionLabel={hasVersionLabel}
              />
            </Grid>
          )}
          {/** HISTORY */}
          <HierarchyRecord
            onClickDrawerButton={() => {
              setRecordOpen(!recordOpen);
            }}
            open={recordOpen}
            onClose={() => {
              setRecordOpen(false);
            }}
            data={selectedData}
          />
        </Grid>
      </Grid>
    </OutletContainer>
  );
};

export default ProductHierarchyView;
