import { COLORS } from "@theme";
import Tile from "@domain/model/Tile";
import Zone from "@domain/model/Zone";
import { ROUTER_PATHS } from "@constants";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LocationEntity from "@domain/model/Location";
import Icon from "../../../../components/icons/icon";
import { Grid, IconButton, Typography } from "@mui/material";
import { Card } from "@components/master-data-view/card/card";

const LocationCard = (props: {
  location: LocationEntity;
  handleEdit: (id: string) => void;
  handleActive: (item: LocationEntity) => void;
  tiles: Tile[];
  zones: Zone[];
}) => {
  const { t } = useTranslation();
  const { location, handleEdit, handleActive, tiles, zones } = props;
  const navigate = useNavigate();

  const currentZone = zones?.find(zone => zone.id === tiles?.find(tile => tile?.id === location?.tileId)?.zoneId);

  const renderChild = (name: string, value: string, xs: number) => {
    return (
      <Grid item xs={xs} md={2}>
        <div className="title-content" data-cy={`material-card-${name}-container`}>
          <Typography variant="cardlabel" display="block">
            {t(name)}
          </Typography>
          <Typography variant="cardvalue" data-cy="value" whiteSpace="pre-line">
            {value}
          </Typography>
        </div>
      </Grid>
    );
  };

  const rendererCard = () => {
    return (
      <Card
        name="locations.code"
        isActive={location.active ?? false}
        key={location.id}
        popover={{
          handleEdit: () => handleEdit(location.id ?? ""),
          handleActive: () => handleActive(location),
          activateTitle: location.active ? t("root.deactivate") : t("root.activate")
        }}
        id={"id-card-" + location.id}
        nameValue={location.code ?? ""}>
        <Grid container spacing={1}>
          {renderChild("locations.capacity", location.capacity?.toString() ?? "", 12)}
          {renderChild("locations.coord.x", tiles.find(t => t.id === location.tileId)?.x?.toString() ?? "", 4)}
          {renderChild("locations.coord.z", tiles.find(t => t.id === location.tileId)?.z?.toString() ?? "", 4)}
          {renderChild("locations.level", location.level?.toString() ?? "", 4)}
          {renderChild("zona", currentZone?.name ?? t("locations.zone.unassigned"), 9)}
          <Grid item xs={3} sm={2} display="flex" justifyContent="flex-end" pr="0.5rem">
            <IconButton
              disabled={!location.active}
              onClick={() => {
                navigate(ROUTER_PATHS.warehouseViewer + "/" + location.id);
              }}>
              <Icon icon="3d" color={location.active ? COLORS.primary : COLORS.gray} />
            </IconButton>
          </Grid>
        </Grid>
      </Card>
    );
  };
  return rendererCard();
};

export default LocationCard;
