/* eslint-disable no-useless-escape */
import Icon from "@components/icons/icon";
import { Grid, Tooltip, Typography } from "@mui/material";
import { COLORS } from "@theme";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { doneIconStyles, notDoneIconStyles, tooltipStyles, validationLineStyles } from "./invalid-password-tooltip-styles";

interface IInvalidPasswordTooltip {
  currentPassword: string;
  customPlacement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  bannedWords?: string[];
}

const DoneIcon = () => <Icon {...doneIconStyles} />;
const NotDoneIcon = () => <Icon {...notDoneIconStyles} />;

export const isPasswordValid = (password: string, bannedWords?: string[]) => {
  const nonBannedWords =
    (bannedWords ?? []).find(word => password.toLocaleLowerCase().includes(word.toLowerCase())) === undefined;
  return (
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[*.,\-_$%&#@|\/\\<>ñç!])([A-Za-z\d*.,\-_$%&#@|\/\\<>ñç!]){12,}$/.test(password) &&
    nonBannedWords
  );
};

export const InvalidPasswordTooltip = ({ currentPassword, customPlacement, bannedWords }: IInvalidPasswordTooltip) => {
  const { t } = useTranslation();
  // Conditions
  const [lengthCon, setLengthCon] = useState(false);
  const [upperCon, setUpperCon] = useState(false);
  const [lowerCon, setLowerCon] = useState(false);
  const [numberCon, setNumberCon] = useState(false);
  const [symbolCon, setSymbolCon] = useState(false);
  const [validCharsCon, setValidCharsCon] = useState(false);
  const [bannedWordsCon, setBannedWordsCon] = useState(false);

  const applyChecks = () => {
    // 12 characters minimum check
    setLengthCon(currentPassword.length >= 12);

    // Uppercase regex
    setUpperCon(/^.*[A-Z].*/.test(currentPassword));

    // Lowercase regex
    setLowerCon(/^.*[a-z].*/.test(currentPassword));

    // Special character regex
    setNumberCon(/^.*\d.*/.test(currentPassword));

    // Number regex
    setSymbolCon(/^.*[*.,\-_$%&#@|\/\\<>ñç!].*/.test(currentPassword));

    // Only specified characters regex
    setValidCharsCon(/^[A-Za-z\d*.,\-_$%&#@|\/\\<>ñç!]+$/.test(currentPassword));

    // Does not contain banned words (this is used to prevent users to use for example their name in the password).
    setBannedWordsCon(bannedWords?.find(word => currentPassword.toLocaleLowerCase().includes(word.toLowerCase())) === undefined);
  };

  useEffect(applyChecks, [currentPassword, bannedWords]);

  return (
    <Tooltip
      leaveDelay={1000}
      placement={customPlacement ?? "right"}
      title={
        <Grid container direction="column" p="0.5rem" rowSpacing={{ xs: 1 }}>
          <Grid {...validationLineStyles}>
            {lengthCon ? <DoneIcon /> : <NotDoneIcon />}
            <Typography fontSize="14px">{t("password.validation.length")}</Typography>
          </Grid>
          <Grid {...validationLineStyles}>
            {upperCon ? <DoneIcon /> : <NotDoneIcon />}
            <Typography fontSize="14px">{t("password.validation.uppercase")}</Typography>
          </Grid>
          <Grid {...validationLineStyles}>
            {lowerCon ? <DoneIcon /> : <NotDoneIcon />}
            <Typography fontSize="14px">{t("password.validation.lowercase")}</Typography>
          </Grid>
          <Grid {...validationLineStyles}>
            {numberCon ? <DoneIcon /> : <NotDoneIcon />}
            <Typography fontSize="14px">{t("password.validation.numbers")}</Typography>
          </Grid>
          <Grid {...validationLineStyles}>
            {symbolCon ? <DoneIcon /> : <NotDoneIcon />}
            <Typography fontSize="14px">
              {t("password.validation.symbols")} <br /> {"*.,-_$%&#@|/\\<>ñç!"}
            </Typography>
          </Grid>
          <Grid {...validationLineStyles}>
            {validCharsCon ? <DoneIcon /> : <NotDoneIcon />}
            <Typography fontSize="14px">{t("password.validation.valid.chars")}</Typography>
          </Grid>
          {bannedWords !== undefined && (
            <Grid {...validationLineStyles}>
              {bannedWordsCon ? <DoneIcon /> : <NotDoneIcon />}
              <Typography fontSize="14px">{t("password.validation.valid.banned")}</Typography>
            </Grid>
          )}
        </Grid>
      }
      componentsProps={{ tooltip: tooltipStyles }}>
      {/** Grid needed so the tooltip can get the anchor. */}
      <Grid>
        <Icon icon="info" color={COLORS.secondary} />
      </Grid>
    </Tooltip>
  );
};
