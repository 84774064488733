// Disabled because elements from ThreeJS that start with lowercase
// are not allowed by eslint no-unknown-property rule.
/* eslint-disable react/no-unknown-property */

import { Text } from "@react-three/drei";
import { CylinderGeometry } from "three";
import { Location3D } from "../../types/Location3D";
import { getShorterBinCode } from "../../utils/bin-helper";
import { getCylinderHeight } from "../utils/cylinder-height-helper";
import { defaultVerticalPlaneGeometry } from "../utils/detail-geometries";
import { defaultWhiteMaterial, getEmptyMaterial, getFilledMaterial } from "../utils/capacity-materials";
import { DEFAULT_PLANE_MATERIAL } from "../../constants/default-materials";
import { DEFAULT_PLANE_GEOMETRY } from "../../constants/default-geometries";

export const SingleBin = (props: { location: Location3D; handleClick: (bins: Location3D[], pos: number[]) => void }) => {
  const { location, handleClick } = props;
  const totalContainers = location.containers?.length ?? 0;
  const capacity = location.capacity ?? 0;
  const level = location.level ?? 0;

  return (
    <group>
      {totalContainers / capacity !== 0 && location.capacity !== undefined && location.capacity !== 0 && (
        <mesh
          position={[0, level - 1 + getCylinderHeight(totalContainers / capacity) / 2, 0]}
          geometry={new CylinderGeometry(0.3, 0.3, getCylinderHeight(totalContainers / capacity), 16)}
          material={getFilledMaterial(totalContainers, capacity)}
          onClick={() => {
            handleClick([location], [0, level - 1 + getCylinderHeight(totalContainers / capacity) / 2, 0]);
          }}
        />
      )}
      {totalContainers / capacity !== 1 && location.capacity !== undefined && location.capacity !== 0 && (
        <mesh
          position={[0, level - 0.7 + getCylinderHeight(totalContainers / capacity) / 2, 0]}
          geometry={new CylinderGeometry(0.3, 0.3, getCylinderHeight(1 - totalContainers / capacity), 16)}
          material={getEmptyMaterial(totalContainers, capacity)}
          onClick={() => {
            handleClick([location], [0, level - 0.7 + getCylinderHeight(totalContainers / capacity) / 2, 0]);
          }}
        />
      )}
      {(location.capacity === undefined || location.capacity === 0) && (
        <mesh
          position={[0, level - 0.7, 0]}
          geometry={new CylinderGeometry(0.3, 0.3, 0.6, 16)}
          material={defaultWhiteMaterial}
          onClick={() => {
            handleClick([location], [0, level - 0.7, 0]);
          }}
        />
      )}
      <mesh position={[0, level - 0.95, 0.499]} material={defaultWhiteMaterial} geometry={defaultVerticalPlaneGeometry} />
      <Text color="black" anchorX="center" anchorY="bottom" position={[0, level - 0.99, 0.5]} fontSize={0.1}>
        {getShorterBinCode(location, 15)}
      </Text>
      <mesh
        position={[0, level - 1, 0]}
        geometry={DEFAULT_PLANE_GEOMETRY}
        material={DEFAULT_PLANE_MATERIAL}
        userData={{ code: location.code }}
      />
    </group>
  );
};
