import {
  ProductsApi,
  ContainersApi,
  ReportsApi,
  EventsApi,
  LoginApi,
  UsersApi,
  LocationsApi,
  FamilyTypesApi,
  ZonesApi,
  FamiliesApi,
  WarehousesApi,
  TilesApi,
  CompaniesApi,
  MaterialsApi,
  TileTemplatesApi,
  MediaApi,
  OperatorsApi,
  StakeholdersApi
} from "./clients/aggrego-proxy";
import { MovementsRepository } from "./infrastructure/repositories/movements-repository";
import { decorate, injectable, inject } from "inversify";
import { LoginUseCase } from "./application/login/login-use-case";
import { CompanyUpdateStatusUseCase } from "./application/master-data/companies/company-update-status-use-case";
import { FamilyUpdateStatusUseCase } from "./application/master-data/families/family-update-status-use-case";
import { LocationsCreateUseCase } from "./application/master-data/locations/locations-create-use-case";
import { LocationUpdateStatusUseCase } from "./application/master-data/locations/location-update-status-use-case";
import { MaterialUpdateStatusUseCase } from "./application/master-data/materials/material-update-status-use-case";
import { TypeOfFamilyUpdateStatusUseCase } from "./application/master-data/type-of-families/type-of-family-update-status-use-case";
import { WarehouseUpdateStatusUseCase } from "./application/master-data/warehouses/warehouse-update-status-use-case";
import { ZoneUpdateStatusUseCase } from "./application/master-data/zones/zone-update-status-use-case";
import { RecoverPasswordUseCase } from "./application/recover-password/recover-password-use-case";
import { DrawerService } from "./domain/services/IDrawerService";
import { UserService } from "./domain/services/IUserService";
import { CompanyApi } from "./infrastructure/api/company-api";
import { FamilyApi } from "./infrastructure/api/family-api";
import { LocationApi } from "./infrastructure/api/location-api";
import { AggregoProxyApiConfiguration } from "./infrastructure/api/management-token/AggregoProxyApiConfiguration";
import { MaterialApi } from "./infrastructure/api/material-api";
import { ProductApi } from "./infrastructure/api/product-api";
import { TileApi } from "./infrastructure/api/tile-api";
import { TileTemplateApi } from "./infrastructure/api/tile-template-api";
import { TypeOfFamilyApi } from "./infrastructure/api/type-of-families-api";
import { UserApi } from "./infrastructure/api/user-api";
import { WarehouseApi } from "./infrastructure/api/warehouse-api";
import { ZoneApi } from "./infrastructure/api/zone-api";
import { CompanyRepository } from "./infrastructure/repositories/company-repository";
import { FamilyRepository } from "./infrastructure/repositories/family-repository";
import { LocationRepository } from "./infrastructure/repositories/location-repository";
import { MaterialRepository } from "./infrastructure/repositories/material-repository";
import { ProductRepository } from "./infrastructure/repositories/product-repository";
import { TileRepository } from "./infrastructure/repositories/tile-repository";
import { TileTemplateRepository } from "./infrastructure/repositories/tile-template-repository";
import { ResetPasswordUseCase } from "./application/reset-password/reset-password-use-case";
import { TypeOfFamilyRepository } from "./infrastructure/repositories/type-of-families-repository";
import { UserRepository } from "./infrastructure/repositories/user-repository";
import { WarehouseRepository } from "./infrastructure/repositories/warehouse-repository";
import { ZoneRepository } from "./infrastructure/repositories/zone-repository";
import { LogoutUseCase } from "./application/logout/logout-use-case";
import { FamilyCreateUseCase } from "./application/master-data/families/create-familiy-use-case";
import { IsValidImpersonationUseCase } from "./application/impersonation/is-valid-impersonation-use-case";
import { ImpersonationService } from "./domain/services/IImpersonationService";
import { CompanyUpdateUseCase } from "./application/master-data/companies/company-update-use-case";
import { ReportApi } from "./infrastructure/api/report-api";
import { ContainerApi } from "./infrastructure/api/container-api";
import { StockRepository } from "./infrastructure/repositories/stock-repository";
import { EventRepository } from "./infrastructure/repositories/event-repository";
import { StockReportUseCase } from "./application/stock-report/stock-report-get-latest-use-case";
import { InventoryReportRepository } from "./infrastructure/repositories/inventory-report-repository";
import { InventoryPeriodRepository } from "./infrastructure/repositories/inventory-period-repository";
import { WarehouseStatusRepository } from "./infrastructure/repositories/warehouse-status-repository";
import { InventoryRegistryRepository } from "./infrastructure/repositories/inventory-registry-repository";
import { InventoryRegistryUseCase } from "./application/inventory-registry/inventory-registry-create-use-case";
import { WarehouseByTenantUseCase } from "./application/warehouse-by-tenant/warehouse-by-tenant-use-case";
import { ContainerRepository } from "./infrastructure/repositories/container-repository";
import { WarehouseGetByIdUseCase } from "./application/master-data/warehouses/warehouse-get-by-id-use-case";
import { TileGetByIdUseCase } from "./application/master-data/tiles/tile-get-by-id-use-case";
import { MultimediaApi } from "./infrastructure/api/multimedia-api";
import { MediaRepository } from "./infrastructure/repositories/media-repository";
import { StakeholderRepository } from "./infrastructure/repositories/stakeholders-repository";
import { StakeholderApi } from "./infrastructure/api/stakeholder-api";
import { OperatorRepository } from "./infrastructure/repositories/operator-repository";
import { OperatorApi } from "./infrastructure/api/operator-api";
import { FindFamiliesByTenantUseCase } from "./application/master-data/families/find-families-by-tenant-use-case";
import { FindTypeOfFamiliesByTenantUseCase } from "./application/master-data/type-of-families/find-type-of-family-by-tenant-use-case";
import { FindZonesByTenantUseCase } from "./application/master-data/zones/find-zones-by-tenant-use-case";
import { FindTilesUseCase } from "./application/master-data/tiles/find-tiles-use-case";
import { CheckUserRoleUseCase } from "./application/permissions/check-user-role-use-case";
import { ChangeSettingsUseCase } from "@application/settings/change-settings-use-case";

decorate(injectable(), AggregoProxyApiConfiguration);

decorate(injectable(), LoginApi);
decorate(injectable(), UsersApi);
decorate(injectable(), ProductsApi);

decorate(inject(UserService), AggregoProxyApiConfiguration, 0);

//SEND TOKEN INFO APIS
decorate(inject(AggregoProxyApiConfiguration), LocationsApi, 0);
decorate(inject(AggregoProxyApiConfiguration), FamilyTypesApi, 0);
decorate(inject(AggregoProxyApiConfiguration), ZonesApi, 0);
decorate(inject(AggregoProxyApiConfiguration), FamiliesApi, 0);
decorate(inject(AggregoProxyApiConfiguration), WarehousesApi, 0);
decorate(inject(AggregoProxyApiConfiguration), TilesApi, 0);
decorate(inject(AggregoProxyApiConfiguration), CompaniesApi, 0);
decorate(inject(AggregoProxyApiConfiguration), MaterialsApi, 0);
decorate(inject(AggregoProxyApiConfiguration), TileTemplatesApi, 0);
decorate(inject(AggregoProxyApiConfiguration), ReportsApi, 0);
decorate(inject(AggregoProxyApiConfiguration), ContainersApi, 0);
decorate(inject(AggregoProxyApiConfiguration), UsersApi, 0);
decorate(inject(AggregoProxyApiConfiguration), LoginApi, 0);
decorate(inject(AggregoProxyApiConfiguration), MediaApi, 0);
decorate(inject(AggregoProxyApiConfiguration), StakeholdersApi, 0);
decorate(inject(AggregoProxyApiConfiguration), OperatorsApi, 0);

decorate(inject(AggregoProxyApiConfiguration), ProductsApi, 0);
decorate(inject(AggregoProxyApiConfiguration), EventsApi, 0);

decorate(injectable(), CompanyRepository);
decorate(injectable(), CompanyApi);
decorate(injectable(), CompaniesApi);
decorate(inject(CompaniesApi), CompanyApi, 0);
decorate(inject(ImpersonationService), CompanyApi, 1);
decorate(inject(UserService), CompanyApi, 2);
decorate(inject(CompanyApi), CompanyRepository, 0);

decorate(injectable(), MaterialRepository);
decorate(injectable(), MaterialApi);
decorate(injectable(), MaterialsApi);
decorate(inject(MaterialsApi), MaterialApi, 0);
decorate(inject(ImpersonationService), MaterialApi, 1);
decorate(inject(MaterialApi), MaterialRepository, 0);

decorate(injectable(), WarehouseStatusRepository);
decorate(injectable(), ReportApi);
decorate(injectable(), ReportsApi);
decorate(inject(ReportsApi), ReportApi, 0);
decorate(inject(ImpersonationService), ReportApi, 1);
decorate(inject(ReportApi), WarehouseStatusRepository, 0);

decorate(injectable(), LocationRepository);
decorate(injectable(), LocationApi);
decorate(inject(LocationApi), LocationRepository, 0);
decorate(injectable(), LocationsApi);
decorate(inject(LocationsApi), LocationApi, 0);
decorate(inject(ImpersonationService), LocationApi, 1);

decorate(injectable(), LocationUpdateStatusUseCase);
decorate(inject(LocationRepository), LocationUpdateStatusUseCase, 0);
decorate(injectable(), LocationsCreateUseCase);
decorate(inject(LocationRepository), LocationsCreateUseCase, 0);
decorate(inject(TileRepository), LocationsCreateUseCase, 1);
decorate(inject(TileTemplateRepository), LocationsCreateUseCase, 2);
decorate(inject(DrawerService), LocationsCreateUseCase, 3);

decorate(injectable(), FamilyRepository);
decorate(injectable(), FamiliesApi);
decorate(injectable(), FamilyApi);
decorate(inject(FamiliesApi), FamilyApi, 0);
decorate(inject(ImpersonationService), FamilyApi, 1);
decorate(inject(FamilyApi), FamilyRepository, 0);

decorate(injectable(), FamilyCreateUseCase);
decorate(inject(FamilyRepository), FamilyCreateUseCase, 0);
decorate(inject(TypeOfFamilyRepository), FamilyCreateUseCase, 1);

decorate(injectable(), FindFamiliesByTenantUseCase);
decorate(inject(FamilyRepository), FindFamiliesByTenantUseCase, 0);
decorate(inject(UserService), FindFamiliesByTenantUseCase, 1);
decorate(inject(ImpersonationService), FindFamiliesByTenantUseCase, 2);

decorate(injectable(), TileRepository);
decorate(injectable(), TilesApi);
decorate(injectable(), TileApi);
decorate(inject(TilesApi), TileApi, 0);
decorate(inject(ImpersonationService), TileApi, 1);
decorate(inject(TileApi), TileRepository, 0);

decorate(injectable(), FindTilesUseCase);
decorate(inject(TileApi), FindTilesUseCase, 0);
decorate(inject(UserService), FindTilesUseCase, 1);
decorate(inject(ImpersonationService), FindTilesUseCase, 2);

decorate(injectable(), WarehouseRepository);
decorate(injectable(), WarehouseApi);
decorate(inject(WarehouseApi), WarehouseRepository, 0);
decorate(injectable(), WarehousesApi);
decorate(inject(WarehousesApi), WarehouseApi, 0);
decorate(inject(ImpersonationService), WarehouseApi, 1);

decorate(injectable(), ZoneRepository);
decorate(injectable(), ZoneApi);
decorate(injectable(), ZonesApi);
decorate(inject(ZonesApi), ZoneApi, 0);
decorate(inject(ImpersonationService), ZoneApi, 1);
decorate(inject(ZoneApi), ZoneRepository, 0);

decorate(injectable(), FindZonesByTenantUseCase);
decorate(inject(ZoneApi), FindZonesByTenantUseCase, 0);
decorate(inject(UserService), FindZonesByTenantUseCase, 1);
decorate(inject(ImpersonationService), FindZonesByTenantUseCase, 2);

decorate(injectable(), TypeOfFamilyRepository);
decorate(injectable(), TypeOfFamilyApi);
decorate(injectable(), FamilyTypesApi);
decorate(inject(FamilyTypesApi), TypeOfFamilyApi, 0);
decorate(inject(ImpersonationService), TypeOfFamilyApi, 1);
decorate(inject(TypeOfFamilyApi), TypeOfFamilyRepository, 0);

decorate(injectable(), ProductRepository);
decorate(injectable(), ProductApi);
decorate(inject(ProductApi), ProductRepository, 0);
decorate(inject(ProductsApi), ProductApi, 0);
decorate(inject(ImpersonationService), ProductApi, 1);

decorate(injectable(), EventsApi);
decorate(injectable(), EventRepository);
decorate(inject(EventsApi), EventRepository, 0);
decorate(inject(ImpersonationService), EventRepository, 1);

decorate(injectable(), InventoryPeriodRepository);
decorate(injectable(), ContainerApi);
decorate(injectable(), ContainersApi);
decorate(inject(ContainersApi), ContainerApi, 0);
decorate(inject(ImpersonationService), ContainerApi, 1);
decorate(inject(ContainerApi), InventoryPeriodRepository, 0);

decorate(injectable(), MovementsRepository);
decorate(inject(ContainerApi), MovementsRepository, 0);

decorate(injectable(), InventoryRegistryRepository);
decorate(injectable(), InventoryReportRepository);
decorate(inject(ReportApi), InventoryRegistryRepository, 0);
decorate(inject(ReportApi), InventoryReportRepository, 0);

decorate(injectable(), StockRepository);
decorate(inject(ReportApi), StockRepository, 0);

decorate(injectable(), ContainerRepository);
decorate(inject(ContainerApi), ContainerRepository, 0);

decorate(injectable(), UserRepository);
decorate(injectable(), UserApi);
decorate(inject(UsersApi), UserApi, 0);
decorate(inject(ImpersonationService), UserApi, 1);
decorate(inject(UserApi), UserRepository, 0);

decorate(injectable(), CheckUserRoleUseCase);
decorate(inject(UserService), CheckUserRoleUseCase, 0);

decorate(injectable(), TileTemplatesApi);
decorate(injectable(), TileTemplateRepository);
decorate(injectable(), TileTemplateApi);
decorate(inject(TileTemplatesApi), TileTemplateApi, 0);
decorate(inject(ImpersonationService), TileTemplateApi, 1);
decorate(inject(TileTemplateApi), TileTemplateRepository, 0);

decorate(injectable(), MediaApi);
decorate(injectable(), MediaRepository);
decorate(injectable(), MultimediaApi);
decorate(inject(MediaApi), MultimediaApi, 0);
decorate(inject(ImpersonationService), MultimediaApi, 1);
decorate(inject(MultimediaApi), MediaRepository, 0);

decorate(injectable(), StakeholderRepository);
decorate(injectable(), StakeholderApi);
decorate(injectable(), StakeholdersApi);
decorate(inject(StakeholdersApi), StakeholderApi, 0);
decorate(inject(ImpersonationService), StakeholderApi, 1);
decorate(inject(UserService), StakeholderApi, 2);
decorate(inject(StakeholderApi), StakeholderRepository, 0);

decorate(injectable(), OperatorRepository);
decorate(injectable(), OperatorApi);
decorate(injectable(), OperatorsApi);
decorate(inject(OperatorsApi), OperatorApi, 0);
decorate(inject(ImpersonationService), OperatorApi, 1);
decorate(inject(OperatorApi), OperatorRepository, 0);

decorate(injectable(), LoginUseCase);
decorate(inject(UserService), LoginUseCase, 0);
decorate(inject(LoginApi), LoginUseCase, 1);
decorate(inject(UserRepository), LoginUseCase, 2);
decorate(inject(UserApi), LoginUseCase, 3);
decorate(inject(ImpersonationService), LoginUseCase, 4);

decorate(injectable(), LogoutUseCase);
decorate(inject(LoginApi), LogoutUseCase, 0);

decorate(injectable(), InventoryRegistryUseCase);
decorate(inject(ContainersApi), InventoryRegistryUseCase, 0);

decorate(injectable(), StockReportUseCase);
decorate(inject(ReportApi), StockReportUseCase, 0);

decorate(injectable(), RecoverPasswordUseCase);
decorate(inject(UsersApi), RecoverPasswordUseCase, 0);

decorate(injectable(), ResetPasswordUseCase);
decorate(inject(UsersApi), ResetPasswordUseCase, 0);

decorate(injectable(), TypeOfFamilyUpdateStatusUseCase);
decorate(inject(TypeOfFamilyRepository), TypeOfFamilyUpdateStatusUseCase, 0);

decorate(injectable(), FindTypeOfFamiliesByTenantUseCase);
decorate(inject(TypeOfFamilyApi), FindTypeOfFamiliesByTenantUseCase, 0);
decorate(inject(UserService), FindTypeOfFamiliesByTenantUseCase, 1);
decorate(inject(ImpersonationService), FindTypeOfFamiliesByTenantUseCase, 2);

decorate(injectable(), ZoneUpdateStatusUseCase);
decorate(inject(ZoneRepository), ZoneUpdateStatusUseCase, 0);

decorate(injectable(), FamilyUpdateStatusUseCase);
decorate(inject(FamilyRepository), FamilyUpdateStatusUseCase, 0);

decorate(injectable(), WarehouseUpdateStatusUseCase);
decorate(inject(WarehouseRepository), WarehouseUpdateStatusUseCase, 0);
decorate(inject(DrawerService), WarehouseUpdateStatusUseCase, 1);

decorate(injectable(), WarehouseGetByIdUseCase);
decorate(inject(WarehouseRepository), WarehouseGetByIdUseCase, 0);

decorate(injectable(), TileGetByIdUseCase);
decorate(inject(TileRepository), TileGetByIdUseCase, 0);

decorate(injectable(), CompanyUpdateStatusUseCase);
decorate(inject(CompanyRepository), CompanyUpdateStatusUseCase, 0);

decorate(injectable(), CompanyUpdateUseCase);
decorate(inject(CompanyRepository), CompanyUpdateUseCase, 0);

decorate(injectable(), MaterialUpdateStatusUseCase);
decorate(inject(MaterialRepository), MaterialUpdateStatusUseCase, 0);

decorate(injectable(), IsValidImpersonationUseCase);
decorate(inject(UserService), IsValidImpersonationUseCase, 0);
decorate(inject(ImpersonationService), IsValidImpersonationUseCase, 1);

decorate(injectable(), WarehouseByTenantUseCase);
decorate(inject(WarehouseApi), WarehouseByTenantUseCase, 0);
decorate(inject(UserService), WarehouseByTenantUseCase, 1);
decorate(inject(DrawerService), WarehouseByTenantUseCase, 2);
decorate(inject(ImpersonationService), WarehouseByTenantUseCase, 3);

decorate(injectable(), ChangeSettingsUseCase);
decorate(inject(UserRepository), ChangeSettingsUseCase, 0);
decorate(inject(UserService), ChangeSettingsUseCase, 1);
