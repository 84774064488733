export class LastPageState {
  limit: number;
  offset: number;
  fullDTO: boolean;
  status: "ALL" | "ACTIVE" | "INACTIVE";
  filters?: object;
  sortDirection?: "ASC" | "DESC";
  sortField?: string;
  autocompleteFilterText?: string;
  autocompleteFilterLoading?: boolean;

  constructor() {
    this.limit = 0;
    this.offset = 0;
    this.fullDTO = true;
    this.status = "ALL";
  }
}
