import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Warehouse from "@domain/model/Warehouse";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";
import { ButtonsDetail } from "@components/master-data-view/buttons/buttons-detail";

export const WarehouseDeactivateDialog = (props: {
  open: boolean;
  handleClose: () => void;
  onDeactivate: (warehouse: Warehouse) => void;
  warehouse: Warehouse;
}): JSX.Element => {
  const { t } = useTranslation();
  const { open, handleClose, onDeactivate, warehouse } = props;

  const [pendingRequest, setPendingRequest] = useState<boolean>(false);

  useEffect(() => {
    setPendingRequest(false);
  }, [warehouse]);

  return open ? (
    <AggregoDialog
      title={
        warehouse.active
          ? t("warehouse.deactivate.title", { warehouse: warehouse?.name })
          : t("warehouse.activate.title", { warehouse: warehouse?.name })
      }
      onClose={handleClose}>
      <Grid container flexDirection="column" className="masterdata-box" rowSpacing={2}>
        <Grid item>
          <div className="confirm-dialog-div-content">{t("warehouse.deactivate.text")}</div>
        </Grid>

        <Grid item>
          <ButtonsDetail
            handleClose={handleClose}
            handleSave={() => {
              setPendingRequest(true);
              onDeactivate(warehouse);
            }}
            mode={warehouse.active ? "ACTIVE" : "INACTIVE"}
            pendingRequest={pendingRequest}
          />
        </Grid>
      </Grid>
    </AggregoDialog>
  ) : (
    <></>
  );
};
