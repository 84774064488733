import { TIMEOUT_ID } from "@constants";
import { LoadingSpinnerMasterView } from "./loading-spinner-master-view";

export const LoadingSpinnerDetailView = (props: { loading: boolean; selectedId: string }) => {
  const { loading, selectedId } = props;

  const renderLoadingDetail = () => {
    if (loading && selectedId !== TIMEOUT_ID) return <LoadingSpinnerMasterView loading={loading} />;
    return <></>;
  };

  return renderLoadingDetail();
};
