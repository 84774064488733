import { COLORS } from "@theme";
import { CircularProgress, Grid } from "@mui/material";
import Icon from "@components/icons/icon";
import { useTranslation } from "react-i18next";
import { useCheckStatus } from "@hooks/useCheckStatus";
import WarehousesDetailView from "./warehouses-detail-view";
import { MasterDataEnum } from "@pages/settings/settings-interfaces";
import WarehousesViewTable from "./view-components/warehouses-view-table";
import { MasterDataTitle } from "@components/master-data-view/MasterDataTitle";
import { OutletContainer } from "@components/outlet-container/outlet-container";
import { useWarehouseViewModel } from "./hooks/viewmodel/useWarehousesViewModel";
import { WarehouseDeactivateDialog } from "./components/confirmation-dialogs/warehouse-deactivate-dialog";
import { LoadingSpinnerMasterView } from "@components/loading-circular-progress/loading-spinner-master-view";

export const WarehousesMasterView = (): JSX.Element => {
  const { t } = useTranslation();
  const { handleStatusFilter } = useCheckStatus();

  const {
    goToCreate,
    warehouse,
    goToList,
    handleEdit,
    onChange,
    onChangeNumeric,
    handleSave,
    loading,
    loadingDetail,
    deactivateWarehouse,
    isDeactivating,
    onCloseDeactivate,
    handleWarehouseStatus,
    handleDeactivateConfirm,
    warehouses,
    handlePagination,
    settingsService
  } = useWarehouseViewModel();

  return (
    <>
      {settingsService.persistedState?.masterdataPermissions.includes(MasterDataEnum.Warehouses) ? (
        <>
          <OutletContainer>
            <Grid container direction="column" mb={1}>
              <MasterDataTitle
                icon={<Icon icon="warehouse" color={COLORS.primary} />}
                name={t("warehouses")}
                buttonText={t("warehouse.add")}
                onAddButton={goToCreate}
                showButton={!handleStatusFilter()}
              />

              <WarehousesViewTable
                warehouses={warehouses}
                handleEdit={handleEdit}
                handleDeactivateConfirm={handleDeactivateConfirm}
                handlePagination={handlePagination}
              />

              {deactivateWarehouse && (
                <WarehouseDeactivateDialog
                  open={isDeactivating}
                  handleClose={onCloseDeactivate}
                  onDeactivate={item => handleWarehouseStatus(item)}
                  warehouse={deactivateWarehouse}
                />
              )}
              <LoadingSpinnerMasterView loading={loading} />
            </Grid>
          </OutletContainer>

          <WarehousesDetailView
            warehouse={warehouse}
            loading={loadingDetail}
            onChange={onChange}
            onChangeNumeric={onChangeNumeric}
            onSave={handleSave}
            onClose={() => goToList()}
          />
        </>
      ) : (
        <CircularProgress color="secondary" />
      )}
    </>
  );
};
