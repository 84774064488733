import { UserApi } from "../api/user-api";
import { User } from "@domain/model/User";
import { DomainPage, IRepositoryPaginated } from "@movicoders/domain";
import {
  ExtendedUserDTO,
  FullUserDTO,
  GetPermissionsByRoleRequest,
  GetUsersByTenantPaginatedRequest,
  UpdateUserRolePermissionsRequest,
  UserPermissionsDTO
} from "@clients/aggrego-proxy";

export class UserRepository implements IRepositoryPaginated<User, GetUsersByTenantPaginatedRequest> {
  constructor(private readonly userApi: UserApi) {}

  getPaginated(userPageCriteria: GetUsersByTenantPaginatedRequest): Promise<DomainPage<User>> {
    return this.userApi.getPaginated(userPageCriteria);
  }
  getById(id: string): Promise<User> {
    return this.userApi.getById(id);
  }

  getInfoUser(id: string): Promise<FullUserDTO> {
    return this.userApi.getInfoUser(id);
  }

  async create(entity: User): Promise<User> {
    const newUser = this.userApi.create(entity);
    return newUser;
  }
  update(entity: User): Promise<User> {
    return this.userApi.update(entity) as ExtendedUserDTO as Promise<User>;
  }
  remove(id: string): Promise<void> {
    return this.userApi.remove(id) as Promise<void>;
  }

  activate(id: string): Promise<void> {
    return this.userApi.activate(id) as Promise<void>;
  }

  deactivate(id: string): Promise<void> {
    return this.userApi.deactivate(id) as Promise<void>;
  }

  async recoverUserRolePermission(criteria: GetPermissionsByRoleRequest): Promise<UserPermissionsDTO> {
    return this.userApi.recoverUserRolePermissions(criteria);
  }

  async updateUserRolePermissions(criteria: UpdateUserRolePermissionsRequest): Promise<ExtendedUserDTO[]> {
    return this.userApi.updateUserRolePermissions(criteria);
  }
}
