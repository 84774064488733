import { useEffect, useState } from "react";
import { DrawerService, IDrawerService } from "../../../../domain/services/IDrawerService";
import { useResolve } from "@movicoders/di";
import { DrawerState } from "../../../../domain/model/DrawerState";
import { WarehouseGetByIdUseCase } from "../../../../application/master-data/warehouses/warehouse-get-by-id-use-case";
import { warehouse3DFromDTO } from "../types/Warehouse3D";
import { IWarehouse3DStore } from "../stores/warehouse-3d-store/IWarehouse3DStore";
import { useWarehouse3DStore } from "../stores/warehouse-3d-store/warehouse-3d-store";
import { IEditionStatesStore } from "../stores/edition-states-store/IEditionStatesStore";
import { useEditionStatesStore } from "../stores/edition-states-store/edition-states-store";
import { ZoneRepository } from "../../../../infrastructure/repositories/zone-repository";
import { IZonesEdition3DStore } from "../stores/zones-edition-3d-store/IZonesEdition3DStore";
import { useZonesEdition3DStore } from "../stores/zones-edition-3d-store/zones-edition-3d-store";
import Zone from "../../../../domain/model/Zone";
import { TileTemplateRepository } from "../../../../infrastructure/repositories/tile-template-repository";
import { ILoadErrorDialogProps } from "../components/dialogs/load-error-dialog/load-error-dialog";

const defaultErrorDialogProps = { errors: [], open: false, handleClose: () => null };

export const use3DInterfaceViewModel = () => {
  const serviceDrawer = useResolve<IDrawerService>(DrawerService);
  const persistedDrawerState = serviceDrawer.persistedState ?? new DrawerState();

  const warehousesGetByIdUseCase = useResolve(WarehouseGetByIdUseCase);
  const zoneRepository = useResolve<ZoneRepository>(ZoneRepository);
  const ttemplateRepository = useResolve<TileTemplateRepository>(TileTemplateRepository);

  const { getWarehouse, setWarehouse } = useResolve<IWarehouse3DStore>(useWarehouse3DStore);
  const { setSelectedMode, getOnSaveLoading, setTemplate, setCanUseZones } = useResolve<IEditionStatesStore>(useEditionStatesStore);
  const { setZones } = useResolve<IZonesEdition3DStore>(useZonesEdition3DStore);


  // Indicates if the screen is loading the current warehouse from backend.
  const [loading, setLoading] = useState(true);
  // Error dialog to indicate if anything could not be loaded.
  const [errorDialogProps, setErrorDialogProps] = useState<ILoadErrorDialogProps>({ ...defaultErrorDialogProps, errors: [] });

  const handleCloseErrorDialog = () => {
    setErrorDialogProps({ ...defaultErrorDialogProps });
  };

  const handleOpenErrorDialog = (error: string) => {
    const newErrors = errorDialogProps.errors;
    newErrors.push(error);
    setErrorDialogProps({ errors: newErrors, open: true, handleClose: handleCloseErrorDialog });
  };

  const recoverZones = (warehouseId: string) => {
    return zoneRepository.getByWarehouseId({ warehouseId })
      .then((res) => {
        setZones(res.map(Zone.fromDTO));
        setCanUseZones(true);
      }).catch(() => {
        setCanUseZones(false);
        handleOpenErrorDialog("viewer.error.not.found.zones");
      });
  };

  const recoverTemplates = () => {
    return ttemplateRepository.get().then((res) => {
      const corridor = res.find(tt => tt.type === "CORRIDOR");
      const storage = res.find(tt => tt.type === "STORAGE");
      const wall = res.find(tt => tt.type === "WALL");
      if (corridor && storage && wall) {
        setTemplate("CORRIDOR", corridor);
        setTemplate("STORAGE", storage);
        setTemplate("WALL", wall);
        setLoading(false);
      } else {
        handleOpenErrorDialog("viewer.error.not.found.templates");
      }
    }).catch(() => {
      handleOpenErrorDialog("viewer.error.not.found.templates");
    });
  }

  const getSelectedWarehouse = () => {
    warehousesGetByIdUseCase.execute(persistedDrawerState.selectedWarehouse)
      .then((warehouse) => {
        setSelectedMode("VIEW");
        setWarehouse(warehouse3DFromDTO(warehouse));
        recoverZones(warehouse.id ?? "");
        recoverTemplates();
      }).catch(() => {
        handleOpenErrorDialog("viewer.error.not.found.warehouse");
      })
  };

  useEffect(() => {
    getSelectedWarehouse();
  }, []);

  return { loading, errorDialogProps, getWarehouse, getOnSaveLoading };
};
