import { create } from "@movicoders/store";
import { TableState } from "@domain/model/TableState";
import { ITableService } from "@domain/services/ITableService";

export const useTableService = create<ITableService>(set => ({
  saveTableState: (newState: TableState) => set({ persistedState: newState }),
  clearTableState: () => set({ persistedState: undefined })
}))
  .withPersist(TableState, "TableState")
  .build();
