import { useEffect, useState } from "react";
import Material from "@domain/model/Material";

export const useMaterialGetDataByUrl = () => {
  const [searchBarFilter, setSearchBarFilter] = useState("");

  useEffect(() => {
    getURIFilters();
  }, []);

  const getURIFilters = () => {
    const params = new URLSearchParams(window.location.search);
    const movementReport = params.get("search");
    if (movementReport) {
      const preselectedFilterObject = JSON.parse(decodeURIComponent(movementReport));
      applyUrlObtainedFilter(preselectedFilterObject);
    }
  };

  const applyUrlObtainedFilter = (preselectedFilterObject: object) => {
    if (Material.isMaterial(preselectedFilterObject)) {
      setSearchBarFilter(preselectedFilterObject.code);
    }
  };

  return {
    searchBarFilter
  };
};
