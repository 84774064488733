import { useState } from "react";
import {
  ListLocationsItemsPaginatedRequest,
  ListLocationsItemsPaginatedSortDirectionEnum,
  ListLocationsPaginatedRequest
} from "@clients/aggrego-proxy";
import { GridSortModel } from "@mui/x-data-grid";
import LocationEntity from "@domain/model/Location";
import { LastPageState } from "@domain/model/LastPageState";
import { ILastPageService } from "@domain/services/ILastPageService";

export const useLocationPagination = (
  currentPageService: ILastPageService,
  currentPagePersistedState: LastPageState,
  locationData: LocationEntity[],
  selectedWarehouse: string,
  getCurrentStatus: (status: "ALL" | "ACTIVE" | "INACTIVE") => boolean | undefined,
  getLocationData: (criteria: ListLocationsPaginatedRequest) => Promise<void>
) => {
  const DEBOUNCE_TIME = 500;
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);

  const handlePagination = (limit: number, offset: number, status: "ALL" | "ACTIVE" | "INACTIVE", filters?: object) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    const newDebounceTimer = setTimeout(() => {
      getLocationData({
        xTenantId: "",
        limit: limit,
        offset: offset,
        customLocationsFilterDTO: {
          ...filters,
          status: getCurrentStatus(status),
          warehouseId: selectedWarehouse
        }
      });
    }, DEBOUNCE_TIME);
    setDebounceTimer(newDebounceTimer);
  };

  const sortByFieldBackend = (model: GridSortModel) => {
    locationData &&
      locationData?.length > 0 &&
      getLocationData({
        limit: currentPagePersistedState.limit,
        offset: currentPagePersistedState.offset,
        sortDirection: model[0]?.sort?.toUpperCase() ?? ListLocationsItemsPaginatedSortDirectionEnum.Desc,
        sortField: model[0]?.field?.toUpperCase(),
        customLocationsFilterDTO: {
          ...currentPagePersistedState.filters,
          warehouseId: selectedWarehouse,
          status: getCurrentStatus(currentPagePersistedState.status)
        }
      } as ListLocationsItemsPaginatedRequest);
    currentPageService.saveLastPageState({
      ...currentPagePersistedState,
      sortDirection: model[0]?.sort?.toUpperCase() as "ASC" | "DESC" | undefined,
      sortField: model[0]?.field?.toUpperCase()
    });
  };

  return {
    handlePagination,
    sortByFieldBackend
  };
};
