import { ROUTER_PATHS } from "@constants";
import { useNavigate } from "react-router";
import { useResolve } from "@movicoders/di";
import { useEffect, useState } from "react";
import { useHandlePortal } from "@hooks/useHandlePortal";
import { TUserRoleEnum, UserRoleEnum } from "@domain/interface/User";
import { IUserService, UserService } from "@domain/services/IUserService";
import { ReportEnum } from "@pages/settings/settings-interfaces";

export const useLoginViewModel = () => {
  const navigate = useNavigate();
  const userService = useResolve<IUserService>(UserService);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const { impersonationService } = useHandlePortal();

  const isRole = (role: TUserRoleEnum) => {
    return userService.user?.roles.includes(role);
  };

  useEffect(() => {
    impersonationService.clearImpersonationState();
  }, []);

  useEffect(() => {
    const storagedToken = sessionStorage.getItem("sessionToken");
    if (storagedToken) {
      setTimeout(() => setLoading(false), 200);
      if (userService.user?.isCompletelyLoaded()) {
        if (isRole(UserRoleEnum.SuperAdmin)) {
          navigate(ROUTER_PATHS.home);
        } else if (userService.user.reportsPermissions?.includes(ReportEnum.WarehousesStatus)) {
          navigate(ROUTER_PATHS.whstatus);
        } else {
          navigate(ROUTER_PATHS.home);
        }
      }
    }
  }, [navigate, userService.user]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return {
    loading,
    open,
    handleOpen,
    handleClose
  };
};
