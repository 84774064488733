import React from "react";
import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import { styled, StepIconProps, Typography } from "@mui/material";

const CustomStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    background: COLORS.stepperGradient,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)"
  }),
  ...(ownerState.completed && {
    background: COLORS.stepperGradient
  })
}));

export function CustomStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: (
      <Typography fontSize={"1.5rem"} fontWeight={600}>
        1
      </Typography>
    ),
    2: (
      <Typography fontSize={"1.5rem"} fontWeight={600}>
        2
      </Typography>
    ),
    3: (
      <Typography fontSize={"1.5rem"} fontWeight={600}>
        3
      </Typography>
    ),
    4: (
      <Typography fontSize={"1.5rem"} fontWeight={600}>
        4
      </Typography>
    )
  };

  return (
    <CustomStepIconRoot ownerState={{ completed, active }} className={className}>
      {completed ? <Icon icon="done" /> : icons[String(props.icon)]}
    </CustomStepIconRoot>
  );
}
