import { COLORS } from "@theme";
import { User } from "@domain/model/User";
import { useTranslation } from "react-i18next";
import { CREATE_ID, TIMEOUT_ID } from "@constants";
import { useUserForm } from "./components/form/useUserForm";
import { CustomPassword } from "@components/password/CustomPassword";
import React, { FC, useEffect, useState } from "react";
import { UserRoleEnum, interfaceToModel } from "@domain/interface/User";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";
import { ButtonsDetail } from "@components/master-data-view/buttons/buttons-detail";
import { CheckUserRoleUseCase } from "@application/permissions/check-user-role-use-case";
import { LoadingSpinnerDetailView } from "@components/loading-circular-progress/loading-spinner-detail-view";
import { Grid, TextField, FormGroup, FormControl, Typography, FormControlLabel, Checkbox } from "@mui/material";

interface IDetail {
  loading: boolean;
  user: User;
  onSave: (user: User) => void;
  onClose: () => void;
  checkUserRoleUseCase: CheckUserRoleUseCase;
}

const UsersDetailView: FC<IDetail> = ({ user: initialUser, loading, onSave, onClose, checkUserRoleUseCase }) => {
  const { t } = useTranslation();
  const {
    onChange,
    onChangeRole,
    validateFormValues,
    formErrors,
    user,
    confirmPasswordValue,
    setConfirmPasswordValue,
    passwordValue,
    setPasswordValue,
    passwordValidationFailed,
    getBannedWordsForPassword
  } = useUserForm(initialUser);

  const mode = initialUser.id === CREATE_ID ? "CREATE" : "EDIT";
  const disabled = user.active !== undefined && user.active === false;
  const style = disabled ? COLORS.grey : COLORS.secondary;

  const [pendingRequest, setPendingRequest] = useState<boolean>(false);

  useEffect(() => {
    setPendingRequest(false);
  }, [initialUser]);

  const renderInput = (id: string, name: string, value: string, label: string, errorText: string) => {
    return (
      <Grid item>
        <TextField
          error={formErrors.get(id)}
          helperText={formErrors.get(id) ? errorText : ""}
          id={id}
          name={name}
          disabled={disabled}
          className={disabled ? "disabled-input" : undefined}
          value={value}
          onChange={onChange}
          label={label}
        />
      </Grid>
    );
  };

  const errorPasswordText = (isConfirm: boolean) => {
    if (formErrors.get("user-password")) return t("users.text.empty");
    if (isConfirm && formErrors.get("user-equal-password")) return t("users.passwords.no.match");
    if (!isConfirm && passwordValidationFailed) return t("profile.pass.reset.error.invalid");
    return "";
  };

  return (
    <React.Fragment>
      {((initialUser.credentialsId !== undefined && initialUser.credentialsId !== "") ||
        (initialUser.id !== undefined && initialUser.id === CREATE_ID)) &&
      initialUser.id !== TIMEOUT_ID ? (
        <AggregoDialog
          title={initialUser.id === CREATE_ID ? t("users.detail.title") : t("users.detail.edit.title") + initialUser.username}
          onClose={onClose}>
          <Grid container flexDirection="column" className="masterdata-box" rowSpacing={2}>
            {renderInput("user-name", "firstName", user.firstName ?? "", t("users.name"), t("users.text.empty"))}
            {renderInput(
              "user-firstsurname",
              "firstSurname",
              user.firstSurname ?? "",
              t("users.firstsurname"),
              t("users.text.empty")
            )}
            {renderInput(
              "user-secondsurname",
              "secondSurname",
              user.secondSurname ?? "",
              t("users.secondsurname"),
              t("users.text.empty")
            )}
            {mode === "CREATE" &&
              renderInput("user-email", "email", user.email ?? "", t("users.email"), t("users.email.invalid"))}
            <Grid item>
              {(checkUserRoleUseCase.isRole(UserRoleEnum.SuperAdmin) ||
                checkUserRoleUseCase.isRole(UserRoleEnum.PortalAdmin)) && (
                <CustomPassword
                  smCustom={12}
                  mdCustom={12}
                  lgCustom={12}
                  onChange={onChange}
                  disabled={disabled}
                  confirmPasswordValue={confirmPasswordValue}
                  setConfirmPasswordValue={setConfirmPasswordValue}
                  passwordValue={passwordValue}
                  setPasswordValue={setPasswordValue}
                  errorTextPass={errorPasswordText(false)}
                  errorTextConfirm={errorPasswordText(true)}
                  isErrorDisplay={
                    (passwordValue !== confirmPasswordValue && passwordValue !== "" && confirmPasswordValue !== "") ||
                    passwordValidationFailed
                  }
                  enablePassswordValidation
                  bannedPasswordWords={getBannedWordsForPassword()}
                />
              )}
            </Grid>
            {/* ROLES */}
            <Grid item container rowSpacing="1rem" justifyContent="space-between" alignItems="flex-end">
              <Grid item>
                <FormGroup id="user-form-group-portal">
                  <FormControl>
                    <Typography color={formErrors.get("user-roles") ? COLORS.alert : style} variant="cardlabel_secondary">
                      {t("users.roles.portal")}
                    </Typography>
                    {formErrors.get("user-roles") && (
                      <Typography sx={{ contain: "inline-size" }} color={COLORS.alert} variant="cardlabel_secondary">
                        {t("users.roles.empty")}
                      </Typography>
                    )}
                    <FormControlLabel
                      label={t(`users.role.${UserRoleEnum.PortalUser.toLowerCase()}`)}
                      control={
                        <Checkbox
                          id={`user-role-${UserRoleEnum.PortalUser}`}
                          checked={user.roles?.includes(UserRoleEnum.PortalUser) ?? false}
                          disabled={disabled}
                          value={UserRoleEnum.PortalUser}
                          onChange={onChangeRole}
                        />
                      }
                    />
                    <FormControlLabel
                      label={t(`users.role.${UserRoleEnum.PortalAdmin.toLowerCase()}`)}
                      control={
                        <Checkbox
                          id={`user-role-${UserRoleEnum.PortalAdmin}`}
                          checked={user.roles?.includes(UserRoleEnum.PortalAdmin) ?? false}
                          disabled={disabled}
                          value={UserRoleEnum.PortalAdmin}
                          onChange={onChangeRole}
                        />
                      }
                    />
                  </FormControl>
                </FormGroup>
              </Grid>
              <Grid item>
                <FormGroup id="user-form-group-mob">
                  <FormControl>
                    <Typography color={formErrors.get("user-roles") ? COLORS.alert : style} variant="cardlabel_secondary">
                      {t("users.roles.mob")}
                    </Typography>
                    {formErrors.get("user-roles") && (
                      <Typography sx={{ contain: "inline-size" }} color={COLORS.alert} variant="cardlabel_secondary">
                        {t("users.roles.empty")}
                      </Typography>
                    )}
                    <FormControlLabel
                      label={t(`users.role.${UserRoleEnum.MobUser.toLowerCase()}`)}
                      control={
                        <Checkbox
                          id={`user-role-${UserRoleEnum.MobUser}`}
                          checked={user.roles?.includes(UserRoleEnum.MobUser) ?? false}
                          disabled={disabled}
                          value={UserRoleEnum.MobUser}
                          onChange={onChangeRole}
                        />
                      }
                    />
                    <FormControlLabel
                      label={t(`users.role.${UserRoleEnum.MobAdmin.toLowerCase()}`)}
                      control={
                        <Checkbox
                          id={`user-role-${UserRoleEnum.MobAdmin}`}
                          checked={user.roles?.includes(UserRoleEnum.MobAdmin) ?? false}
                          disabled={disabled}
                          value={UserRoleEnum.MobAdmin}
                          onChange={onChangeRole}
                        />
                      }
                    />
                  </FormControl>
                </FormGroup>
              </Grid>
            </Grid>

            <Grid item>
              <ButtonsDetail
                handleClose={onClose}
                handleSave={() => {
                  if (validateFormValues(mode === "CREATE")) {
                    setPendingRequest(true);
                    onSave(interfaceToModel(user));
                  }
                }}
                mode={mode}
                disabled={disabled}
                pendingRequest={pendingRequest}
              />
            </Grid>
          </Grid>
        </AggregoDialog>
      ) : (
        <LoadingSpinnerDetailView loading={loading} selectedId={initialUser?.id ?? ""} />
      )}
    </React.Fragment>
  );
};

export default UsersDetailView;
