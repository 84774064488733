import { User } from "@domain/model/User";
import { useEffect, useState } from "react";

export const useUserGetDataByUrl = () => {
  const [searchBarFilter, setSearchBarFilter] = useState("");

  useEffect(() => {
    searchByPreviousObtainedUser();
  }, []);

  const searchByPreviousObtainedUser = () => {
    const params = new URLSearchParams(window.location.search);
    const foundUser = params.get("search");
    if (foundUser) {
      const preselectedFilterObject = JSON.parse(decodeURIComponent(foundUser));
      applyUrlObtainedFilter(preselectedFilterObject);
    }
  };

  const applyUrlObtainedFilter = (preselectedFilterObject: object) => {
    if (User.isUser(preselectedFilterObject)) {
      setSearchBarFilter((preselectedFilterObject as User).username);
    }
  };

  return {
    searchBarFilter
  };
};
