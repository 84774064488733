import { AGGREGO_APK_LINK, ROUTER_PATHS } from "@constants";
import { useResolve } from "@movicoders/di";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IPath, downloadFile } from "../drawer-paths-configuration";
import { usePermissions } from "@hooks/usePermissions";
import { checkIconColor } from "@utils/drawer-icon-helper";
import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { IsValidImpersonationUseCase } from "@application/impersonation/is-valid-impersonation-use-case";
export interface IPathList {
  isDrawerOpen: boolean;
  drawerWidth: number | string;
  paths: IPath[];
}

export const PathsList = ({ drawerWidth, isDrawerOpen, paths }: IPathList) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isValidPermissions } = usePermissions();
  const isValidImpersonationUseCase = useResolve(IsValidImpersonationUseCase);

  return (
    <>
      {paths.map((path: IPath) => {
        const color = checkIconColor(path.path);
        const showPath =
          (!path.permissions.length || isValidPermissions(path.permissions)) &&
          (isValidImpersonationUseCase.execute() || !path.needsTenant);
        const isIM = path.hiddenByIM ?? false;

        return showPath && isIM === false ? (
          <Tooltip title={isDrawerOpen ? "" : t(path.name)} placement="right" key={path.name}>
            <ListItemButton
              id={path.id}
              className="drawer-item"
              sx={{ width: drawerWidth }}
              onClick={() => {
                if (path.path !== ROUTER_PATHS.downloadApk) {
                  navigate(path.path);
                } else {
                  downloadFile(AGGREGO_APK_LINK, "newestAggregoApk.apk");
                }
              }}>
              <ListItemIcon style={{ color: color }}>{path.icon}</ListItemIcon>
              {isDrawerOpen && (
                <ListItemText
                  primaryTypographyProps={{
                    variant: "drawer",
                    color: color
                  }}
                  primary={t(path.name).toUpperCase()}
                  style={{ margin: 0 }}
                />
              )}
            </ListItemButton>
          </Tooltip>
        ) : null;
      })}
    </>
  );
};
