import Material from "@domain/model/Material";
import { MaterialRepository } from "@infrastructure/repositories/material-repository";

export class MaterialUpdateStatusUseCase {
  constructor(private readonly materialRepository: MaterialRepository) {}

  public async execute(material: Material) {
    const modifiedMaterial = { ...material, active: !material.active };
    return await this.materialRepository.update(modifiedMaterial);
  }
}
