import { COLORS } from "@theme";
import { CircularProgress, Grid } from "@mui/material";
import Icon from "@components/icons/icon";
import Operator from "@domain/model/Operator";
import { useTranslation } from "react-i18next";
import { DomainPage } from "@movicoders/domain";
import { useCheckStatus } from "@hooks/useCheckStatus";
import { OperatorsDetailView } from "./operators-detail-view";
import OperatorsViewTable from "./view-components/operators-view-table";
import { MasterDataTitle } from "@components/master-data-view/MasterDataTitle";
import { OutletContainer } from "@components/outlet-container/outlet-container";
import { useOperatorViewModel } from "./hooks/viewmodel/useOperatorListViewModel";
import { OperatorsActivationDialog } from "./components/OperatorsActivationDialog";
import { LoadingSpinnerMasterView } from "@components/loading-circular-progress/loading-spinner-master-view";
import { MasterDataEnum } from "@pages/settings/settings-interfaces";

export const OperatorsMasterView = () => {
  const { t } = useTranslation();
  const {
    operatorData,
    selectedOperator,
    isActivating,
    operator4Activation,
    loading,
    loadingDetail,
    goToCreate,
    goToList,
    handleEdit,
    handleSave,
    handlePagination,
    handleActiveConfirm,
    handleOperatorStatus,
    onCloseActive,
    sortByFieldBackend,
    settingsService
  } = useOperatorViewModel();

  const { handleStatusFilter } = useCheckStatus();

  return (
    <>
      {settingsService.persistedState?.masterdataPermissions.includes(MasterDataEnum.Operators) ? (
        <>
          <OutletContainer>
            <Grid container display="flex" flexDirection="column">
              <MasterDataTitle
                icon={<Icon icon="supervisor" color={COLORS.primary} />}
                name={t("operators")}
                buttonText={t("operators.add.button")}
                showButton={!handleStatusFilter()}
                onAddButton={goToCreate}
              />

              <OperatorsViewTable
                operatorData={operatorData as DomainPage<Operator>}
                handleEdit={handleEdit}
                handleActiveConfirm={handleActiveConfirm}
                handlePagination={handlePagination}
                sortByFieldBackend={sortByFieldBackend}
                handleStatusFilter={handleStatusFilter}
              />

              {operator4Activation && (
                <OperatorsActivationDialog
                  open={isActivating}
                  onClose={onCloseActive}
                  onActivation={item => handleOperatorStatus(operator4Activation)}
                  operator={operator4Activation}
                />
              )}
              <LoadingSpinnerMasterView loading={loading} />
            </Grid>
          </OutletContainer>

          <OperatorsDetailView
            operator={selectedOperator as Operator}
            loading={loadingDetail}
            onClose={() => goToList()}
            onSave={handleSave}
          />
        </>
      ) : (
        <CircularProgress color="secondary" />
      )}
    </>
  );
};
