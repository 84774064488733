import { ROUTER_PATHS } from "@constants";
import { useEffect, useState } from "react";
import { useResolve } from "@movicoders/di";
import { useSnackbar } from "@movicoders/ui";
import { useTranslation } from "react-i18next";
import { UUID_REGEX } from "@utils/regex-helper";
import { handleError } from "@utils/error-helper";
import { useLocation, useNavigate } from "react-router-dom";
import { useHandleOperator } from "@hooks/useHandleOperator";
import IMMEntryWithDesc from "@domain/model/IMMEntryWithDesc";
import { IMMaterialEntryDTO, ResponseError } from "@clients/aggrego-proxy";
import { useHandleSelectedWarehouse } from "@hooks/useHandleSelectedWarehouse";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { ContainerRepository } from "@infrastructure/repositories/container-repository";
import { IMMaterialEntryWithValidationDTO } from "../../interfaces/immaterial-interface";

export const useEntriesViewModel = () => {
  const navigate = useNavigate();
  const { selectedWarehouseCode } = useHandleSelectedWarehouse();
  const settingsService = useResolve<ISettingsService>(SettingsService);

  const containerRepository = useResolve<ContainerRepository>(ContainerRepository);

  const emptyIMMaterial = { id: "", materialCode: "", qty: 0, warehouseCode: selectedWarehouseCode, comment: "" };

  const [createdIMMaterial, setCreatedIMMaterial] = useState<IMMEntryWithDesc>(emptyIMMaterial);

  const { show } = useSnackbar();
  const { state } = useLocation();
  const { t } = useTranslation();

  const [isSyncronizing, setIsSyncronizing] = useState(false);

  const [iMMaterialList, setIMMaterialList] = useState<IMMEntryWithDesc[]>(state?.failedSync ?? []);

  const [allSynchronized, setAllSynchronized] = useState<IMMaterialEntryDTO[]>([]);
  const [passedSynchronized, setPassedSynchronized] = useState<IMMaterialEntryDTO[]>([]);
  const [failedSynchronized, setFailedSynchronized] = useState<IMMaterialEntryDTO[]>([]);

  const [entryMode, setEntryMode] = useState("");

  const noMaterials: boolean = iMMaterialList.length > 0;

  const isOperatorLogged = state?.isOperatorLogged ?? false;

  const handleAddMaterial = () => {
    if (
      createdIMMaterial.materialCode &&
      createdIMMaterial.materialCode !== "" &&
      createdIMMaterial.qty !== 0 &&
      !iMMaterialList.find(imm => imm.id === createdIMMaterial.id)
    ) {
      setIMMaterialList(prevList => [...prevList, createdIMMaterial]);
      setCreatedIMMaterial(emptyIMMaterial);
    } else {
      show(t("inbounds.repeated"), "error");
    }
  };

  const { operatorState } = useHandleOperator();

  const handleInSynchronize = () => {
    setEntryMode("inbound");
    setIsSyncronizing(true);
    containerRepository
      .createMany({ iMMaterialEntryDTO: iMMaterialList, operatorId: operatorState?.operator?.id })
      .then(response => {
        if (response?.content) {
          const content = response.content as { [key: string]: string };
          Object.entries(content).forEach(([key, value]) => {
            if (value.match(UUID_REGEX)) {
              const passedMaterial: IMMaterialEntryWithValidationDTO = iMMaterialList.find(item => item.id === value + "") ?? {};
              setAllSynchronized(prevList => [...prevList, { ...passedMaterial, errorMessage: "OK" }]);
              setPassedSynchronized(prevList => [...prevList, { ...passedMaterial, errorMessage: "OK" }]);
            } else {
              const failedMaterial: IMMaterialEntryWithValidationDTO =
                iMMaterialList.find(item => item.materialCode === key + "") ?? {};
              setAllSynchronized(prevList => [...prevList, { ...failedMaterial, errorMessage: value }]);
              setFailedSynchronized(prevList => [...prevList, { ...failedMaterial, errorMessage: value }]);
            }
          });
        }
        setIsSyncronizing(false);
      })
      .catch(error => {
        show(t("inbounds.error"), "error");
      });
  };

  const handleOutSynchronize = async () => {
    setEntryMode("outbound");
    setIsSyncronizing(true);
    for (const currentIMMaterial of iMMaterialList) {
      try {
        await containerRepository.consume({
          iMMaterialConsumeDTO: {
            materialCode: currentIMMaterial.materialCode ?? "",
            qty: currentIMMaterial.qty ?? 0,
            warehouseCode: currentIMMaterial.warehouseCode ?? "",
            comment: currentIMMaterial.comment ?? ""
          },
          operatorId: operatorState?.operator?.id
        });
        const passedMaterial: IMMaterialEntryWithValidationDTO = {
          ...currentIMMaterial,
          errorCode: "",
          errorMessage: "OK"
        };
        setAllSynchronized(prevList => [...prevList, passedMaterial]);
        setPassedSynchronized(prevList => [...prevList, passedMaterial]);
      } catch (error) {
        const response = await handleError(error as ResponseError);
        const failedMaterial: IMMaterialEntryWithValidationDTO = {
          ...currentIMMaterial,
          errorCode: response?.errors && response.errors[0].errorCode,
          errorMessage: response?.errors && response?.errors[0].message?.split(">")[2]?.slice(1)
        };
        setAllSynchronized(prevList => [...prevList, failedMaterial]);
        setFailedSynchronized(prevList => [...prevList, failedMaterial]);
      }
    }
    setIsSyncronizing(false);
  };

  /**Used to navigate to inbounds sync result when allSynchronized size was
   * the same as iMMaterialList size, created by user adding IMMaterials
   */
  useEffect(() => {
    allSynchronized.length !== 0 &&
      allSynchronized?.length === iMMaterialList.length &&
      navigate(entryMode === "inbound" ? ROUTER_PATHS.inboundsSync : ROUTER_PATHS.outboundsSync, {
        state: { all: allSynchronized, passed: passedSynchronized, failed: failedSynchronized }
      });
  }, [allSynchronized]);

  return {
    isSyncronizing,
    iMMaterialList,
    setIMMaterialList,
    createdIMMaterial,
    setCreatedIMMaterial,
    handleAddMaterial,
    handleInSynchronize,
    handleOutSynchronize,
    noMaterials,
    isOperatorLogged,
    settingsService
  };
};
