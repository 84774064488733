export const MasterDataEnum = {
  WarehouseViewer: "WAREHOUSE_VIEWER",
  Warehouses: "WAREHOUSE",
  Users: "USER",
  TypeOfFamilies: "FAMILY_TYPE",
  Families: "FAMILY",
  Materials: "MATERIAL",
  Locations: "LOCATION",
  Zones: "ZONE",
  Customers: "CUSTOMER",
  Manufactures: "MANUFACTURER",
  Owners: "OWNER",
  Suppliers: "SUPPLIER",
  Operators: "OPERATOR"
} as const;
export type TMasterDataEnum = (typeof MasterDataEnum)[keyof typeof MasterDataEnum];

export const ReportEnum = {
  WarehousesStatus: "WAREHOUSE_STATUS",
  InventoryReport: "INVENTORY_REPORT",
  MovementsReport: "MOVEMENTS_REPORT",
  StockReport: "STOCK_REPORT",
  InventoryRegistry: "INVENTORY_REGISTRY",
  RelabelReport: "RELABEL_REPORT"
} as const;
export type TReportEnum = (typeof ReportEnum)[keyof typeof ReportEnum];

export const LogisticEnum = {
  Inbounds: "MATERIAL_ENTRY",
  Outbounds: "MATERIAL_EXIT",
  InventoryMaterial: "INVENTORY_MATERIAL"
} as const;
export type TLogisticEnum = (typeof LogisticEnum)[keyof typeof LogisticEnum];

export const TrackAndTraceEnum = {
  TrackingReport: "PRODUCT",
  Hierarchy: "HIERARCHY"
} as const;
export type TTrackAndTraceEnum = (typeof TrackAndTraceEnum)[keyof typeof TrackAndTraceEnum];
