import { useEffect, useRef } from "react";
import { useResolve } from "@movicoders/di";
import Warehouse from "@domain/model/Warehouse";
import { useCheckStatus } from "@hooks/useCheckStatus";
import { useListDetailViewModel } from "@movicoders/ui";
import { LastPageState } from "@domain/model/LastPageState";
import { ILastPageService, LastPageService } from "@domain/services/ILastPageService";
import { WarehouseRepository } from "@infrastructure/repositories/warehouse-repository";
import { IImpersonationService, ImpersonationService } from "@domain/services/IImpersonationService";
import { WarehouseByTenantUseCase } from "@application/warehouse-by-tenant/warehouse-by-tenant-use-case";
import { MasterDataEnum } from "@pages/settings/settings-interfaces";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";

export const useWarehouseHandleData = () => {
  const {
    loading,
    loadingDetail,
    data,
    selected: selectedWarehouse,
    dataPaginated,
    save,
    create,
    fetchOne,
    goToList,
    getPaginated
  } = useListDetailViewModel(WarehouseRepository);

  const warehouseGetPaginated = useRef(getPaginated).current;

  const settingsService = useResolve<ISettingsService>(SettingsService);
  const currentPageService = useResolve<ILastPageService>(LastPageService);
  const impersonationService = useResolve<IImpersonationService>(ImpersonationService);
  const warehousesByTenantUseCase = useResolve(WarehouseByTenantUseCase);

  const currentPagePersistedState = currentPageService.persistedState ?? new LastPageState();

  const { getCurrentStatus } = useCheckStatus();

  const compare = (a: Warehouse, b: Warehouse) => {
    if (a.active === b.active) {
      return a.name?.toLowerCase() ?? "" < (b.name?.toLowerCase() ?? "") ? -1 : 1;
    } else {
      return a.active ?? false > (b.active ?? false) ? -1 : 1;
    }
  };

  useEffect(() => {
    if (settingsService.persistedState?.masterdataPermissions.includes(MasterDataEnum.Warehouses)) {
      (dataPaginated.content ?? []).sort(compare);
      warehousesByTenantUseCase.get();
    }
  }, [dataPaginated.content, data, settingsService.persistedState]);

  return {
    loading,
    loadingDetail,
    impersonationService,
    currentPagePersistedState,
    warehouses: dataPaginated,
    selectedWarehouse,
    warehouseGetPaginated,
    save,
    getCurrentStatus,
    goToCreate: create,
    goToDetail: fetchOne,
    goToList
  };
};
