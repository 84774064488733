import { useTranslation } from "react-i18next";
import { Button, Grid, Paper } from "@mui/material";
import { logisticStyles } from "../../logistic-styles";
import IMMEntryWithDesc from "@domain/model/IMMEntryWithDesc";
import { LogisticCard } from "@components/logistic-view/logistic-card";
import { IMMaterialEntryWithValidationDTO } from "../../interfaces/immaterial-interface";

export const EntriesSyncContent = (props: {
  cardsArray: IMMEntryWithDesc[];
  buttonId: string;
  handleReturnBackTo: () => void;
  handleErrorDialog: (
    mode: "OPEN" | "CLOSE",
    errorMessage?: string | undefined,
    material?: IMMaterialEntryWithValidationDTO | undefined
  ) => void;
}) => {
  const { t } = useTranslation();
  const styles = logisticStyles();
  const { cardsArray, buttonId, handleErrorDialog, handleReturnBackTo } = props;

  return (
    <Paper sx={styles.logistic_sync_paper}>
      <Grid item sx={{ pt: 1, pb: 0.7 }}>
        <Grid container spacing={{ xs: 1, md: 1 }} mt={1} sx={{ pt: 0.5, pb: 0.7 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {cardsArray?.map((IMEntry: IMMaterialEntryWithValidationDTO) => (
            <Grid item xs={12} sm={4} key={`grid-card-${IMEntry.id}`}>
              <LogisticCard
                id={IMEntry?.id ?? ""}
                IMEntry={IMEntry}
                isSynchronizing={true}
                isOk={IMEntry.errorMessage === "OK"}
                errorMessage={IMEntry.errorMessage}
                handleErrorDialog={error => {
                  handleErrorDialog("OPEN", error);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Button id={buttonId} variant="primary" onClick={handleReturnBackTo}>
        {t("root.return")}
      </Button>
    </Paper>
  );
};
