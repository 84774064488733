import Zone from "@domain/model/Zone";
import { useZoneSave } from "./useZoneSave";
import { useZonePagination } from "./useZonePagination";
import { useZoneHandleData } from "./useZoneHandleData";
import { useZoneActiveStatus } from "./useZoneActiveStatus";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { useResolve } from "@movicoders/di";

export const useZoneViewModel = () => {
  const settingsService = useResolve<ISettingsService>(SettingsService);
  const {
    currentPageService,
    currentPagePersistedState,
    zoneData,
    selectedZone,
    selectedWarehouse,
    loading,
    loadingDetail,
    getCurrentStatus,
    getZonesPaginated,
    goToCreate,
    goToDetail,
    goToList,
    save
  } = useZoneHandleData();

  const { isActivating, selectedZone4Status, handleOpenToggleActiveStatusDialog, onCloseDialog, toggleZoneStatus } =
    useZoneActiveStatus(currentPageService, currentPagePersistedState, selectedWarehouse, getCurrentStatus, getZonesPaginated);

  const { handleEdit, handleSave } = useZoneSave(
    currentPageService,
    currentPagePersistedState,
    selectedZone as Zone,
    selectedWarehouse,
    goToDetail,
    save,
    getCurrentStatus,
    goToList
  );

  const { handlePagination } = useZonePagination(selectedWarehouse, getCurrentStatus, getZonesPaginated);

  return {
    loading,
    loadingDetail,
    isActivating,
    selectedZone4Status,
    zoneData,
    selectedZone,
    toggleZoneStatus,
    handleOpenToggleActiveStatusDialog,
    handleEdit,
    goToCreate,
    goToList,
    handleSave,
    handlePagination,
    onCloseDialog,
    settingsService
  };
};
