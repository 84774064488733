import { FamilyDTO, LocationDTO, MaterialDTO } from "@clients/aggrego-proxy/models";

export default class Material {
  id?: string;
  code: string;
  name: string;
  families: Array<FamilyDTO>;
  locations: Array<LocationDTO>;
  minStock: number;
  unitPrice: number;
  uom: string;
  active: boolean;

  constructor(
    code: string,
    name: string,
    families: Array<FamilyDTO>,
    locations: Array<LocationDTO>,
    minStock: number,
    unitPrice: number,
    uom: string,
    active: boolean,
    id?: string
  ) {
    this.id = id;
    this.code = code;
    this.name = name;
    this.families = families;
    this.locations = locations;
    this.minStock = minStock;
    this.unitPrice = unitPrice;
    this.uom = uom;
    this.active = active;
  }

  static fromClientMaterial(material: MaterialDTO) {
    const result = new Material(
      material.code ?? "",
      material.name ?? "",
      material.families ?? [],
      material.locations ?? [],
      material.minStock ?? 0,
      material.unitPrice ?? 0,
      material.uom ?? "",
      material.active ?? true,
      material.id ?? ""
    );
    return result;
  }

  static toClientMaterial(material: Material) {
    const result: MaterialDTO = {};
    result.id = material.id;
    result.code = material.code;
    result.name = material.name;
    result.families = material.families;
    result.locations = material.locations;
    result.minStock = material.minStock;
    result.unitPrice = material.unitPrice;
    result.uom = material.uom;
    result.active = material.active;

    return result;
  }

  /**
   * Verifies if the properties of an object match with every property of the Material class.
   * This function is useful when using 'instanceof' is not possible such as when the instance has been lost.
   *
   * @param {object} object - The object to be checked.
   * @returns {boolean} - Returns true if the object matches the Material class properties; otherwise, returns false.
   */
  static isMaterial = (object: unknown): object is Material => {
    if (typeof object !== "object" || object === null) {
      return false;
    }

    const expectedKeys: (keyof Material)[] = ["code", "name", "families", "locations", "minStock", "unitPrice", "uom", "active"];

    for (const key of expectedKeys) {
      if (!(key in object)) {
        return false;
      }
    }

    return true;
  };
}
