import { ROUTER_PATHS } from "@constants";
import { useResolve } from "@movicoders/di";
import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Family from "../../../../../../domain/model/Family";
import { useListDetailPaginatedViewModel } from "@movicoders/ui";
import { MasterDataEnum, ReportEnum } from "@pages/settings/settings-interfaces";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { useHandleSelectedWarehouse } from "../../../../../../hooks/useHandleSelectedWarehouse";
import { StockRepository } from "../../../../../../infrastructure/repositories/stock-repository";
import { FindFamiliesByTenantUseCase } from "../../../../../../application/master-data/families/find-families-by-tenant-use-case";

export const useMaterialsStockHandleData = () => {
  const location = useLocation();

  const { data, getPaginated, loading } = useListDetailPaginatedViewModel(StockRepository);

  const stockRepository = useResolve<StockRepository>(StockRepository);
  const settingsService = useResolve<ISettingsService>(SettingsService);

  const getStockPaginated = useRef(getPaginated).current;

  const findFamiliesByTenantUseCase = useResolve(FindFamiliesByTenantUseCase);
  const [families, setFamilies] = useState<Family[]>([]);

  const { selectedWarehouseCode } = useHandleSelectedWarehouse();

  useEffect(() => {
    if (
      //warehouses view also receives pall families by tenant from here
      (location.pathname === "/" + ROUTER_PATHS.warehouses &&
        settingsService.persistedState?.masterdataPermissions.includes(MasterDataEnum.Warehouses)) ||
      (location.pathname === ROUTER_PATHS.stock &&
        settingsService.persistedState?.reportsPermissions.includes(ReportEnum.StockReport))
    ) {
      findFamiliesByTenantUseCase.execute("ALL").then(result => {
        setFamilies(result);
      });
    }
  }, [settingsService.persistedState]);

  return {
    data,
    families,
    loading,
    selectedWarehouseCode,
    stockRepository,
    getStockPaginated
  };
};
