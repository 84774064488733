import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import { GridColDef } from "@mui/x-data-grid";
import { filterValueTypes } from "@constants";
import { CustomTooltipCell } from "@components/tooltip/CustomTooltipCell";

export const inventoryRegistryColumns = (
  t: (text: string) => string,
  dateMediaQuery: boolean,
  lgMediaQuery: boolean
): GridColDef[] => [
  {
    flex: 0.9,
    field: "inventoryCode",
    headerName: t("invregistry.inventory"),
    disableColumnMenu: true,
    minWidth: lgMediaQuery ? undefined : 100,
    renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
  },
  {
    flex: 1,
    field: "containerCode",
    headerName: t("invregistry.container"),
    disableColumnMenu: true,
    minWidth: lgMediaQuery ? undefined : 100,
    renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
  },
  {
    flex: 0.8,
    field: "materialCode",
    headerName: t("invregistry.material"),
    disableColumnMenu: true,
    minWidth: lgMediaQuery ? undefined : 100,
    type: "autocomplete-one" as filterValueTypes,
    renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
  },
  {
    flex: 0.8,
    field: "qty",
    headerName: t("invregistry.quant"),
    disableColumnMenu: true,
    minWidth: lgMediaQuery ? undefined : 60,
    type: "number",
    renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
  },
  {
    flex: 1,
    field: "locationCode",
    headerName: t("invregistry.location"),
    disableColumnMenu: true,
    minWidth: lgMediaQuery ? undefined : 100,
    renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
  },
  {
    flex: 0.7,
    field: "user",
    headerName: t("invregistry.license"),
    disableColumnMenu: true,
    minWidth: lgMediaQuery ? undefined : 80,
    renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
  },
  {
    flex: 0.7,
    field: "operatorCode",
    headerName: t("invregistry.operator"),
    disableColumnMenu: true,
    minWidth: lgMediaQuery ? undefined : 80,
    renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
  },
  {
    flex: 1,
    field: "created",
    headerName: t("invregistry.date"),
    disableColumnMenu: true,
    minWidth: lgMediaQuery ? undefined : 90,
    type: "range-date" as filterValueTypes,
    valueGetter: ({ row }) => {
      const parseDate = new Date(row.created.toString());
      return row.created
        ? parseDate.toLocaleString("es-ES", {
            localeMatcher: "lookup",
            month: "numeric",
            day: "numeric",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit"
          })
        : "-";
    },
    renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
  },
  {
    flex: 0.6,
    field: "consolidated",
    headerName: t("invregistry.status"),
    disableColumnMenu: true,
    minWidth: lgMediaQuery ? undefined : 70,
    align: "center",
    renderCell: data => {
      const tooltipText =
        data.row.consolidated === "NOT_CONSOLIDATED" ? t("invregistry.not.consolidated") : t("invregistry.consolidated");
      return (
        <CustomTooltipCell tooltipTitle={tooltipText} customContainerStyles={{ fontSize: "0rem" }}>
          <Icon
            id="task-completion"
            icon={data.row.consolidated === "NOT_CONSOLIDATED" ? "task-ko" : "task-ok"}
            color={data.row.consolidated === "NOT_CONSOLIDATED" ? COLORS.alert : COLORS.success}
          />
        </CustomTooltipCell>
      );
    }
  },
  {
    flex: 1,
    field: "error",
    headerName: t("invregistry.error"),
    disableColumnMenu: true,
    minWidth: lgMediaQuery ? undefined : 200,
    renderCell: data => {
      if (data.row.error) {
        const formattedError = ("invregistry." + data.row.error).replaceAll(" ", ".").toLowerCase();
        return <CustomTooltipCell>{t(formattedError)}</CustomTooltipCell>;
      }
    }
  }
];
