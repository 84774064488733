import { COLORS } from "@theme";
import { CircularProgress, Grid } from "@mui/material";
import { User } from "@domain/model/User";
import Icon from "@components/icons/icon";
import { useTranslation } from "react-i18next";
import { DomainPage } from "@movicoders/domain";
import UsersDetailView from "./users-detail-view";
import UserActiveDialog from "./components/dialogs/user-active";
import UsersViewTable from "./view-components/users-view-table";
import { useUserListViewModel } from "./hooks/viewmodel/useUserListViewModel";
import { MasterDataTitle } from "@components/master-data-view/MasterDataTitle";
import { OutletContainer } from "@components/outlet-container/outlet-container";
import { LoadingSpinnerMasterView } from "@components/loading-circular-progress/loading-spinner-master-view";
import { MasterDataEnum } from "@pages/settings/settings-interfaces";

export const UsersMasterView = () => {
  const { t } = useTranslation();
  const {
    userData,
    isActivating,
    loadingDetail,
    loading,
    selectedUser4Status,
    selectedUser,
    handleEdit,
    handleOpenToggleActiveStatusDialog,
    onCloseDialog,
    goToList,
    goToCreate,
    toggleUserStatus,
    handleSave,
    handlePagination,
    searchBarFilter,
    checkUserRoleUseCase,
    settingsService
  } = useUserListViewModel();

  return (
    <>
      {settingsService.persistedState?.masterdataPermissions.includes(MasterDataEnum.Users) ? (
        <>
          <OutletContainer>
            <Grid container display="flex" flexDirection="column">
              <MasterDataTitle
                icon={<Icon icon="users" color={COLORS.primary} />}
                name={t("users")}
                buttonText={t("users.add.button")}
                onAddButton={goToCreate}
                showButton={true}
              />

              <UsersViewTable
                userData={userData as DomainPage<User>}
                searchBarFilter={searchBarFilter}
                handleEdit={handleEdit}
                handleOpenToggleActiveStatusDialog={handleOpenToggleActiveStatusDialog}
                handlePagination={handlePagination}
              />

              {selectedUser4Status && (
                <UserActiveDialog
                  open={isActivating}
                  onClose={onCloseDialog}
                  onActive={item => toggleUserStatus(item.credentialsId, selectedUser4Status.active, item.username)}
                  user={selectedUser4Status}
                />
              )}
              <LoadingSpinnerMasterView loading={loading} />
            </Grid>
          </OutletContainer>

          <UsersDetailView
            loading={loadingDetail}
            onSave={handleSave}
            onClose={() => goToList()}
            user={selectedUser as User}
            checkUserRoleUseCase={checkUserRoleUseCase}
          />
        </>
      ) : (
        <CircularProgress color="secondary" />
      )}
    </>
  );
};
