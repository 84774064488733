import { settingsStyles } from "../settings-styles";
import { Grid, Typography, Divider } from "@mui/material";

export const SettingsTitle = (props: { name: string; icon: JSX.Element }) => {
  const { name, icon } = props;
  const styles = settingsStyles();

  return (
    <>
      <Grid item display="flex" flexDirection="row" justifyContent="space-between" md={12}>
        <Grid id="settings-data-title" className="main-view-title" item>
          {icon}
          <Typography id="settings-data-title" ml="1rem">
            {name}
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={styles.settings_divider} />
    </>
  );
};
