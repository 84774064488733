import { useTranslation } from "react-i18next";
import { EventDTO } from "@clients/aggrego-proxy";
import { humanDateByTimeStamp } from "@utils/date-helper";
import { hierarchyReportStyles } from "../../hierarchy-report.Styles";
import { Paper, Grid, Typography, Chip, useMediaQuery, Theme } from "@mui/material";

export const HierarchyRecordCard = (props: { event: EventDTO }) => {
  const { t } = useTranslation();
  const { event } = props;
  const styles = hierarchyReportStyles();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"), { noSsr: true });

  return (
    <Paper elevation={0} sx={{ ...styles.hierarchy_record_card, width: matches ? "22.2rem" : "70vw" }}>
      <Grid container rowSpacing="0.5rem">
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant="cardlabel" textAlign="left">
            {t("products.status")}
          </Typography>
          <Chip
            label={t(`products.history.events.status.${event.action?.toLocaleLowerCase()}`)}
            className={`action-${event.action?.toLocaleLowerCase()}`}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant="cardlabel" textAlign={matches ? "right" : "left"}>
            {t("products.date")}
          </Typography>
          <Typography variant="product_history_value" textAlign={matches ? "right" : "left"}>
            {humanDateByTimeStamp(event?.eventDate?.getTime() ?? 0)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="cardlabel" textAlign="left">
            {t("products.facility")}
          </Typography>
          <Typography variant="product_history_value">{event.departure}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="cardlabel" textAlign="left">
            {t("products.qty")}
          </Typography>
          <Typography variant="product_history_value">{`${event.qty} ${event.uom}`}</Typography>
        </Grid>
        {event.destination && (
          <Grid item xs={12}>
            <Typography variant="cardlabel" textAlign="left">
              {t("products.destination")}
            </Typography>
            <Typography variant="product_history_value">{event.destination}</Typography>
          </Grid>
        )}
        {event.agent && (
          <Grid item xs={12}>
            <Typography variant="cardlabel" textAlign="left">
              {t("products.operator")}
            </Typography>
            <Typography
              variant="product_history_value"
              title={event.agent}
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden"
              }}>
              {event.agent}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
