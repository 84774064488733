import Family from "@domain/model/Family";
import { FamilyApi } from "../api/family-api";
import { DomainPage, IRepository } from "@movicoders/domain";
import { ListFamiliesByTenantIdRequest, ListFamiliesPaginatedRequest } from "@clients/aggrego-proxy/apis/FamiliesApi";

export class FamilyRepository implements IRepository<Family, ListFamiliesPaginatedRequest, ListFamiliesPaginatedRequest> {
  constructor(private readonly familyApi: FamilyApi) {}
  async get(): Promise<Family[]> {
    return await this.familyApi.get();
  }
  getPaginated(pageCriteria: ListFamiliesPaginatedRequest): Promise<DomainPage<Family>> {
    return this.familyApi.getPaginated(pageCriteria);
  }
  getByTenant(criteria: ListFamiliesByTenantIdRequest): Promise<Family[]> {
    return this.familyApi.getByTenant(criteria);
  }
  getById(id: string): Promise<Family> {
    return this.familyApi.getById(id);
  }
  async create(entity: Family): Promise<Family> {
    await this.familyApi.create(entity);
    return entity;
  }
  async update(entity: Family): Promise<Family> {
    return await this.familyApi.update(entity);
  }
  async remove(id: string): Promise<void> {
    await this.familyApi.remove(id);
  }
}
