import { CREATE_ID } from "@constants";
import Stakeholder from "@domain/model/Stakeholder";
import { SyntheticEvent, useEffect, useState } from "react";
import { stakeholderTypesEnum } from "./useStakeholderSelection";
import { StakeholderDTOPaymentCurrencyEnum, StakeholderDTOPaymentTermsEnum } from "@clients/aggrego-proxy";

interface IStakeholderForm {
  selectedStakeholder: Stakeholder;
  stakeholderType: stakeholderTypesEnum;
}

export const useStakeholderForm = (props: IStakeholderForm) => {
  const { selectedStakeholder, stakeholderType } = props;
  const [modifiedStakeholder, setModifiedStakeholder] = useState<Stakeholder>(selectedStakeholder);

  useEffect(() => {
    setModifiedStakeholder(selectedStakeholder);
    if (selectedStakeholder.id === CREATE_ID) {
      setModifiedStakeholder({
        ...modifiedStakeholder,
        id: CREATE_ID,
        code: "-",
        address: "",
        city: "",
        postalCode: "",
        country: "",
        phone: "",
        email: "",
        contactPerson: "",
        comment: "",
        paymentCurrency: StakeholderDTOPaymentCurrencyEnum.Euro,
        paymentTerms: StakeholderDTOPaymentTermsEnum.Net30,
        isCustomer: stakeholderType === stakeholderTypesEnum.customer,
        isManufacturer: stakeholderType === stakeholderTypesEnum.manuafacturers,
        isOwner: stakeholderType === stakeholderTypesEnum.owners,
        isSupplier: stakeholderType === stakeholderTypesEnum.suppliers
      });
    }
  }, [selectedStakeholder]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModifiedStakeholder({ ...modifiedStakeholder, [event.target.name]: event.target.value });
  };

  const onChangeNumeric = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = event.target.name;
    let fieldValue = event.target.value;

    if (fieldValue === "") {
      fieldValue = "0";
    } else {
      fieldValue = fieldValue.replace(/[^0-9]/g, "");

      const regex = /^[0-9]+$/;
      if (fieldName === "postalCode" && regex.test(event.target.value)) {
        fieldValue = event.target.value;
        if (Number(fieldValue) > 10000) {
          fieldValue = fieldValue.replace(/^0+/, "");
        }
      }
    }
    setModifiedStakeholder({ ...modifiedStakeholder, [fieldName]: fieldValue });
  };

  const onChangeAutocomplete = (event: SyntheticEvent<Element, Event>, value: string, name: string) => {
    setModifiedStakeholder({ ...modifiedStakeholder, [name]: value });
  };

  const onChangeCheckboxes = (event: SyntheticEvent<Element, Event>, type: stakeholderTypesEnum) => {
    switch (type) {
      case stakeholderTypesEnum.customer:
        setModifiedStakeholder({ ...modifiedStakeholder, isCustomer: !modifiedStakeholder.isCustomer });
        break;
      case stakeholderTypesEnum.manuafacturers:
        setModifiedStakeholder({ ...modifiedStakeholder, isManufacturer: !modifiedStakeholder.isManufacturer });
        break;
      case stakeholderTypesEnum.owners:
        setModifiedStakeholder({ ...modifiedStakeholder, isOwner: !modifiedStakeholder.isOwner });
        break;
      case stakeholderTypesEnum.suppliers:
        setModifiedStakeholder({ ...modifiedStakeholder, isSupplier: !modifiedStakeholder.isSupplier });
        break;
    }
  };

  return {
    modifiedStakeholder,
    onChange,
    onChangeNumeric,
    onChangeAutocomplete,
    onChangeCheckboxes
  };
};
