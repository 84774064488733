import { Scene, Mesh, Group, BoxGeometry, CylinderGeometry } from "three";
import { Warehouse3D } from "../../types/Warehouse3D";
import { DEFAULT_MATERIAL_CREATING } from "../../constants/default-materials";
import { generateUUID } from "three/src/math/MathUtils";
import { Location3D } from "../../types/Location3D";
import { useResolve } from "@movicoders/di";
import { IEditionStatesStore } from "../../stores/edition-states-store/IEditionStatesStore";
import { useEditionStatesStore } from "../../stores/edition-states-store/edition-states-store";

// Functions that help with the creation of temporary tiles in the viewer.
interface IGetDefaultLocation {
  locationCode: string,
  locationLevel: number,
  warehouseId?: string
}

export const getDefaultLocation = (data: IGetDefaultLocation) => {
  return {
    code: data.locationCode,
    name: data.locationCode,
    level: data.locationLevel,
    id: generateUUID(),
    capacity: 1,
    active: true,
    families: [],
    materials: [],
    containers: [],
    familyTypes: [],
    occupation: 0,
    warehouseId: data.warehouseId
  } as Location3D;
}

export const useCreateTilesHelper = (scene: Scene) => {

  const { getTemplate } = useResolve<IEditionStatesStore>(useEditionStatesStore);

  const showCreatedVerticalTiles = (
    warehouse: Warehouse3D,
    startPoint: Mesh,
    endPoint: Mesh,
    zoneGroup: Group
  ) => {
    const startGenPoint = startPoint.position.x < endPoint.position.x ? startPoint.position.x : endPoint.position.x;
    const endGenPoint = startGenPoint === endPoint.position.x ? startPoint.position.x : endPoint.position.x;
    for (let x = startGenPoint; x <= endGenPoint; x++) {
      const group = new Group();
      group.position.set(x, 0, startPoint.position.z);
      group.name = "tile";
      const tileId = generateUUID();
      group.userData.tile = {
        id: tileId,
        code: x + "." + startPoint.position.z,
        x: x,
        z: startPoint.position.z,
        maxY: endPoint.position.y + 1,
        warehouseId: warehouse.id,
        templateId: getTemplate("STORAGE")?.id,
        description: null,
        locations: [],
        zoneId: undefined,
        active: true
      };

      for (let level = 0; level <= endPoint.position.y; level++) {
        const box = new Mesh(new BoxGeometry(1, 1, 0.05), DEFAULT_MATERIAL_CREATING);
        box.position.set(0, level, 0);
        box.rotation.x = Math.PI * 0.5;
        box.rotation.z = Math.PI * 0.5;
        box.name = "createdBox";
        group.add(box);

        const cylinder = new Mesh(new CylinderGeometry(0.3, 0.3, 0.6, 16), DEFAULT_MATERIAL_CREATING);
        cylinder.position.set(0, level + 0.28, 0);
        cylinder.name = "createdCylinder";
        group.add(cylinder);

        group.userData.tile.locations.push(getDefaultLocation({
          locationCode: x + "." + (level + 1) + "." + startPoint.position.z,
          locationLevel: level + 1,
          warehouseId: warehouse.id
        }));
      }
      zoneGroup.add(group);
    }
    scene.add(zoneGroup);
  };

  const showCreatedHorizontalTiles = (
    warehouse: Warehouse3D,
    startPoint: Mesh,
    endPoint: Mesh,
    zoneGroup: Group
  ) => {
    const startGenPoint = startPoint.position.z < endPoint.position.z ? startPoint.position.z : endPoint.position.z;
    const endGenPoint = startGenPoint === endPoint.position.z ? startPoint.position.z : endPoint.position.z;
    for (let z = startGenPoint; z <= endGenPoint; z++) {
      const group = new Group();
      group.position.set(startPoint.position.x, 0, z);
      group.name = "tile";
      const tileId = generateUUID();
      group.userData.tile = {
        id: tileId,
        code: startPoint.position.x + "." + z,
        x: startPoint.position.x,
        z: z,
        maxY: endPoint.position.y + 1,
        warehouseId: warehouse.id,
        templateId: getTemplate("STORAGE")?.id,
        description: null,
        locations: [],
        zoneId: undefined,
        active: true
      };

      for (let level = 0; level <= endPoint.position.y; level++) {
        const box = new Mesh(new BoxGeometry(1, 1, 0.05), DEFAULT_MATERIAL_CREATING);
        box.position.set(0, level, 0);
        box.rotation.x = Math.PI * 0.5;
        box.rotation.z = Math.PI * 0.5;
        box.name = "createdBox";
        group.add(box);

        const cylinder = new Mesh(new CylinderGeometry(0.3, 0.3, 0.6, 16), DEFAULT_MATERIAL_CREATING);
        cylinder.position.set(0, level + 0.28, 0);
        cylinder.name = "createdCylinder";
        group.add(cylinder);

        group.userData.tile.locations.push(getDefaultLocation({
          locationCode: startPoint.position.x + "." + (level + 1) + "." + z,
          locationLevel: level + 1,
          warehouseId: warehouse.id
        }));
      }
      zoneGroup.add(group);
    }
    scene.add(zoneGroup);
  };

  const showCreatedUpperLevels = (
    warehouse: Warehouse3D,
    startPoint: Mesh,
    endPoint: Mesh,
    zoneGroup: Group
  ) => {
    const group = new Group();
    group.position.set(startPoint.position.x, 0, startPoint.position.z);
    group.name = "tile";
    const tileId = generateUUID();
    group.userData.tile = {
      id: tileId,
      code: startPoint.position.x + "." + startPoint.position.z,
      x: startPoint.position.x,
      z: startPoint.position.z,
      maxY: endPoint.position.y + 1,
      warehouseId: warehouse.id,
      templateId: getTemplate("STORAGE")?.id,
      description: null,
      locations: [],
      zoneId: undefined,
      active: true
    };

    for (let level = 0; level <= endPoint.position.y; level++) {
      const box = new Mesh(new BoxGeometry(1, 1, 0.05), DEFAULT_MATERIAL_CREATING);
      box.position.set(0, level, 0);
      box.rotation.x = Math.PI * 0.5;
      box.rotation.z = Math.PI * 0.5;
      box.name = "createdBox";
      group.add(box);

      const cylinder = new Mesh(new CylinderGeometry(0.3, 0.3, 0.6, 16), DEFAULT_MATERIAL_CREATING);
      cylinder.position.set(0, level + 0.28, 0);
      cylinder.name = "createdCylinder";
      group.add(cylinder);

      group.userData.tile.locations.push(getDefaultLocation({
        locationCode: startPoint.position.x + "." + (level + 1) + "." + startPoint.position.z,
        locationLevel: level + 1,
        warehouseId: warehouse.id
      }));
    }
    zoneGroup.add(group);
    scene.add(zoneGroup);
  };

  return {
    showCreatedVerticalTiles,
    showCreatedHorizontalTiles,
    showCreatedUpperLevels
  };
}

