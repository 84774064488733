import { Scene, Mesh, Group, BoxGeometry, CylinderGeometry } from "three";
import { DEFAULT_MATERIAL_CREATING } from "../../constants/default-materials";
import { generateUUID } from "three/src/math/MathUtils";
import { useResolve } from "@movicoders/di";
import { ICommon3DObjectsStore } from "../../stores/common-3d-objects-store/ICommon3DObjectsStore";
import { useCommon3DObjectsStore } from "../../stores/common-3d-objects-store/common-3d-objects-store";
import { IEditionStatesStore } from "../../stores/edition-states-store/IEditionStatesStore";
import { useEditionStatesStore } from "../../stores/edition-states-store/edition-states-store";
import { getDefaultLocation } from "./create-tiles-helper";
import { IWarehouse3DStore } from "../../stores/warehouse-3d-store/IWarehouse3DStore";
import { useWarehouse3DStore } from "../../stores/warehouse-3d-store/warehouse-3d-store";

// Functions that help with the creation of temporary tiles in the viewer.

export const useEditTilesHelpers = (scene: Scene) => {
  const { getWarehouse } = useResolve<IWarehouse3DStore>(useWarehouse3DStore);
  const { getTilesBeingModified } = useResolve<ICommon3DObjectsStore>(useCommon3DObjectsStore);
  const { getModifyMinMax, getTemplate } = useResolve<IEditionStatesStore>(useEditionStatesStore);

  const createEditHorizontalTiles = (
    negZ: boolean,
    levelingTilesCurrentLevelObj: Mesh,
    zoneGroup: Group
  ) => {
    const startZ = negZ ? getModifyMinMax().minZ - 1 : getModifyMinMax().maxZ + 1;

    const startPoint = startZ < levelingTilesCurrentLevelObj.position.z ? startZ : levelingTilesCurrentLevelObj.position.z;
    const endPoint = startPoint === levelingTilesCurrentLevelObj.position.z ? startZ : levelingTilesCurrentLevelObj.position.z;

    const startBorderTilesZ = negZ
      ? getTilesBeingModified().filter(t => t.userData.tile.z === getModifyMinMax().minZ)
      : getTilesBeingModified().filter(t => t.userData.tile.z === getModifyMinMax().maxZ);

    startBorderTilesZ.forEach(startBorderTile => {
      const tile = startBorderTile.userData.tile;

      for (let z = startPoint; z <= endPoint; z++) {
        const levelToUse =
          tile.maxY - 1 > levelingTilesCurrentLevelObj.position.y
            ? tile.maxY - 1
            : levelingTilesCurrentLevelObj.position.y;

        const group = new Group();
        group.position.set(tile.x, 0, z);
        group.name = "tile";
        const tileId = generateUUID();
        group.userData.tile = {
          id: tileId,
          code: tile.x + "." + z,
          x: tile.x,
          z: z,
          maxY: levelToUse + 1,
          warehouseId: getWarehouse().id,
          templateId: getTemplate("STORAGE")?.id,
          description: null,
          locations: [],
          zoneId: undefined,
          active: true
        };

        for (let level = 0; level <= levelToUse; level++) {
          const box = new Mesh(new BoxGeometry(1, 1, 0.05), DEFAULT_MATERIAL_CREATING);
          box.position.set(0, level, 0);
          box.rotation.x = Math.PI * 0.5;
          box.rotation.z = Math.PI * 0.5;
          box.name = "editedBox";
          group.add(box);

          const cylinder = new Mesh(new CylinderGeometry(0.3, 0.3, 0.6, 16), DEFAULT_MATERIAL_CREATING);
          cylinder.position.set(0, level + 0.28, 0);
          cylinder.name = "editedCylinder";
          group.add(cylinder);

          group.userData.tile.locations.push(getDefaultLocation({
            locationCode: tile.x + "." + (level + 1) + "." + z,
            locationLevel: level + 1,
            warehouseId: getWarehouse().id
          }));
        }
        zoneGroup.add(group);
      }
    });
    scene.add(zoneGroup);
  };

  const createEditVerticalTiles = (
    negX: boolean,
    levelingTilesCurrentLevelObj: Mesh,
    zoneGroup: Group
  ) => {
    const startX = negX ? getModifyMinMax().minX - 1 : getModifyMinMax().maxX + 1;

    const startPoint = startX < levelingTilesCurrentLevelObj.position.x ? startX : levelingTilesCurrentLevelObj.position.x;
    const endPoint = startPoint === levelingTilesCurrentLevelObj.position.x ? startX : levelingTilesCurrentLevelObj.position.x;

    const startBorderTilesX = negX
      ? getTilesBeingModified().filter(t => t.userData.tile.x === getModifyMinMax().minX)
      : getTilesBeingModified().filter(t => t.userData.tile.x === getModifyMinMax().maxX);

    startBorderTilesX.forEach(startBorderTile => {
      const tile = startBorderTile.userData.tile;

      for (let x = startPoint; x <= endPoint; x++) {
        const levelToUse =
          tile.maxY - 1 > levelingTilesCurrentLevelObj.position.y
            ? tile.maxY - 1
            : levelingTilesCurrentLevelObj.position.y;

        const group = new Group();
        group.position.set(x, 0, tile.z);
        group.name = "tile";
        const tileId = generateUUID();
        group.userData.tile = {
          id: tileId,
          code: x + "." + tile.z,
          x: x,
          z: tile.z,
          maxY: levelToUse + 1,
          warehouseId: getWarehouse().id,
          templateId: getTemplate("STORAGE")?.id,
          description: null,
          locations: [],
          zoneId: undefined,
          active: true
        };

        for (let level = 0; level <= levelToUse; level++) {
          const box = new Mesh(new BoxGeometry(1, 1, 0.05), DEFAULT_MATERIAL_CREATING);
          box.position.set(0, level, 0);
          box.rotation.x = Math.PI * 0.5;
          box.rotation.z = Math.PI * 0.5;
          box.name = "editedBox";
          group.add(box);

          const cylinder = new Mesh(new CylinderGeometry(0.3, 0.3, 0.6, 16), DEFAULT_MATERIAL_CREATING);
          cylinder.position.set(0, level + 0.28, 0);
          cylinder.name = "editedCylinder";
          group.add(cylinder);

          group.userData.tile.locations.push(getDefaultLocation({
            locationCode: x + "." + (level + 1) + "." + tile.z,
            locationLevel: level + 1,
            warehouseId: getWarehouse().id
          }));
        }
        zoneGroup.add(group);
      }
    })
    scene.add(zoneGroup);
  };

  const createEditLevels = (
    levelingTilesCurrentLevelObj: Mesh,
    editStartPoint: Mesh,
    zoneGroup: Group
  ) => {
    getTilesBeingModified().forEach(tileBeingModified => {
      const tile = tileBeingModified.userData.tile;

      const group = new Group();
      group.position.set(tile.x, 0, tile.z);
      group.name = "tile";
      const tileId = generateUUID();
      group.userData.tile = {
        id: tileId,
        code: tile.x + "." + tile.z,
        x: tile.x,
        z: tile.z,
        maxY: levelingTilesCurrentLevelObj.position.y + 1,
        warehouseId: getWarehouse().id,
        templateId: getTemplate("STORAGE")?.id,
        description: null,
        locations: [],
        zoneId: undefined,
        active: true
      };

      for (let level = editStartPoint.position.y + 1; level <= levelingTilesCurrentLevelObj.position.y; level++) {
        if (tile.maxY <= level) {
          const box = new Mesh(new BoxGeometry(1, 1, 0.05), DEFAULT_MATERIAL_CREATING);
          box.position.set(0, level, 0);
          box.rotation.x = Math.PI * 0.5;
          box.rotation.z = Math.PI * 0.5;
          box.name = "editedBox";
          group.add(box);

          const cylinder = new Mesh(new CylinderGeometry(0.3, 0.3, 0.6, 16), DEFAULT_MATERIAL_CREATING);
          cylinder.position.set(0, level + 0.28, 0);
          cylinder.name = "editedCylinder";
          group.add(cylinder);

          group.userData.tile.locations.push(getDefaultLocation({
            locationCode: tile.x + "." + (level + 1) + "." + tile.z,
            locationLevel: level + 1,
            warehouseId: getWarehouse().id
          }));
        }
      }
      zoneGroup.add(group);
      scene.add(zoneGroup);
    });
  };

  return {
    createEditHorizontalTiles,
    createEditVerticalTiles,
    createEditLevels
  }
}
