import { useState } from "react";
import FamilyType from "@domain/model/FamilyType";
import { LastPageState } from "@domain/model/LastPageState";
import MasterDataConstants from "../../../MasterDataConstants";
import { ILastPageService } from "@domain/services/ILastPageService";
import { useMasterDataMessagges } from "@hooks/useMasterDataMessages";
import { ListFamilyTypesPaginatedRequest } from "@clients/aggrego-proxy";

export const useTypeOfFamiliesActiveStatus = (
  currentPageService: ILastPageService,
  currentPagePersistedState: LastPageState,
  save: (entity: FamilyType) => Promise<void>,
  getTypeOfFamiliesPaginated: (criteria: ListFamilyTypesPaginatedRequest) => Promise<void>,
  getCurrentStatus: (status: "ACTIVE" | "INACTIVE" | "ALL") => boolean | undefined
) => {
  const [isActivating, setIsActivating] = useState(false);
  const [selectedTypeOfFamily4Activation, setSelectedTypeOfFamily4Activation] = useState<FamilyType | undefined>(undefined);
  const { successMessaggesStatus, errorMessaggesStatus } = useMasterDataMessagges();

  const handleOpenToggleActiveStatusDialog = (typeOfFamily: FamilyType) => {
    setIsActivating(!isActivating);
    setSelectedTypeOfFamily4Activation(typeOfFamily);
  };

  const onCloseDialog = () => {
    setIsActivating(false);
  };

  const toggleTypeOfFamilyStatus = (typeOfFamily: FamilyType) => {
    const promise = save({ ...typeOfFamily, active: !typeOfFamily.active });
    promise
      .then(() => successMessaggesStatus("typeoffamily", typeOfFamily.active, typeOfFamily.name))
      .catch(error => errorMessaggesStatus("typeoffamily", typeOfFamily.active, typeOfFamily.name, error))
      .finally(() => {
        setIsActivating(false);
        getTypeOfFamiliesPaginated({
          limit: MasterDataConstants.ITEMS_PER_PAGE,
          offset: currentPageService.persistedState?.offset ?? 0,
          basicFilterDTO: {
            status: getCurrentStatus(currentPagePersistedState.status),
            ...currentPagePersistedState.filters
          }
        });
      });
  };

  return {
    isActivating,
    selectedTypeOfFamily4Activation,
    handleOpenToggleActiveStatusDialog,
    onCloseDialog,
    toggleTypeOfFamilyStatus
  };
};
