import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";
import { InvalidPasswordTooltip } from "./invalid-password-tooltip/InvalidPasswordTooltip";

export interface IPassword {
  smCustom: number;
  mdCustom: number;
  lgCustom: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  passwordValue?: string;
  confirmPasswordValue: string;
  setConfirmPasswordValue: Dispatch<SetStateAction<string>>;
  setPasswordValue: Dispatch<SetStateAction<string>>;
  isErrorDisplay: boolean;
  errorTextPass: string;
  errorTextConfirm?: string;
  disabled?: boolean;
  isRowDisplayed?: boolean;
  enablePassswordValidation?: boolean;
  bannedPasswordWords?: string[];
}

export const CustomPassword = (props: IPassword) => {
  const { t } = useTranslation();

  const {
    smCustom,
    mdCustom,
    lgCustom,
    onChange,
    passwordValue = "",
    confirmPasswordValue,
    isRowDisplayed,
    setConfirmPasswordValue,
    setPasswordValue,
    isErrorDisplay,
    errorTextPass,
    errorTextConfirm,
    disabled,
    enablePassswordValidation,
    bannedPasswordWords
  } = props;

  const textErrorDisplay = () => {
    if (errorTextConfirm) return errorTextConfirm;
    if (isErrorDisplay) {
      return t("users.passwords.no.match");
    }

    return "";
  };
  const isErrorPrinted = () => {
    if (errorTextConfirm || isErrorDisplay) return true;

    return false;
  };

  return (
    <Grid container sx={{ marginLeft: "0rem" }} display={isRowDisplayed ? "flex" : "block"}>
      <Grid
        item
        sm={smCustom}
        md={mdCustom}
        lg={lgCustom}
        sx={{ paddingLeft: "0", paddingRight: isRowDisplayed ? 0.5 : 0 }}>
        <TextField
          id="password"
          label={t("users.password")}
          type="password"
          name="password"
          value={passwordValue}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPasswordValue(event.target.value);
            onChange(event);
          }}
          helperText={errorTextPass}
          error={errorTextPass !== ""}
          disabled={disabled}
          className={disabled ? "disabled-input" : "blue-border-input"}
          InputProps={enablePassswordValidation ? {
            endAdornment: <InvalidPasswordTooltip currentPassword={passwordValue} bannedWords={bannedPasswordWords} />
          } : undefined}
        />
      </Grid>
      <Grid
        item
        sm={smCustom}
        md={mdCustom}
        lg={lgCustom}
        sx={{ mt: isRowDisplayed ? 0 : "2rem", paddingLeft: isRowDisplayed ? 0.5 : 0 }}>
        <TextField
          id="confirm-password"
          label={t("profile.pass.reset.field.repeat")}
          type="password"
          name="confirmPassword"
          value={confirmPasswordValue}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setConfirmPasswordValue(event.target.value);
            onChange(event);
          }}
          helperText={textErrorDisplay()}
          error={isErrorPrinted()}
          disabled={disabled}
          className={disabled ? "disabled-input" : "blue-border-input"}
        />
      </Grid>
    </Grid>
  );
};
