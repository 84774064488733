import { SyntheticEvent, useEffect, useState } from "react";
import { useAdminFormValidations } from "./useAdminFormValidation";
import { CompanyDTO, FullCompanyItemDTO } from "@clients/aggrego-proxy";

interface IAdminBoardForm {
  selectedCompany: CompanyDTO;
  onSave: (company: CompanyDTO) => Promise<void>;
}

export const useAdminBoardForm = (props: IAdminBoardForm) => {
  const { selectedCompany, onSave } = props;
  const [showErrors, setShowErrors] = useState(false);
  const [company, setCompany] = useState<FullCompanyItemDTO>(selectedCompany);

  const { companyIsNotValid, isAnInvalidValue } = useAdminFormValidations();

  useEffect(() => {
    setCompany(selectedCompany);
  }, [selectedCompany]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompany({ ...company, [event.target.name]: event.target.value });
  };

  const onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompany({ ...company, [event.target.name]: event.target.checked });
  };

  const onChangeAutocomplete = (event: SyntheticEvent<Element, Event>, value: string | null, id: string) => {
    setCompany({ ...company, [id]: value ?? "" });
  };

  const handleSave = () => {
    !companyIsNotValid(company) ? onSave(company) : setShowErrors(true);
  };

  return { onChange, company, handleSave, showErrors, onChangeCheckbox, isAnInvalidValue, onChangeAutocomplete };
};
