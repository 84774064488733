import { useResolve } from "@movicoders/di";
import { GridValidRowModel } from "@mui/x-data-grid";
import { SetStateAction, useEffect, useState } from "react";
import { LastPageState } from "@domain/model/LastPageState";
import { ILastPageService, LastPageService } from "@domain/services/ILastPageService";
import { DataGridDisplayBasicToolbarProps } from "../toolbar/config/data-grid-display-basic-toolbar-props";
import { DefaultToolbarStatusOptions, ToolbarFieldFilter, ToolbarFilter } from "../toolbar/config/data-grid-display-status";

export const useApplyFilter = (
  toolbarProps: DataGridDisplayBasicToolbarProps,
  initialData: GridValidRowModel[],
  setCurrentData: (value: SetStateAction<GridValidRowModel[]>) => void,
  setUnsortedData: (value: SetStateAction<GridValidRowModel[]>) => void
) => {
  const lastPageService = useResolve<ILastPageService>(LastPageService);
  const lastPagePersistedState = lastPageService.persistedState ?? new LastPageState();

  const defaultSelectedStatus =
    toolbarProps?.customToolbarFieldFilter?.options.find(op => op.default === true)?.value ??
    DefaultToolbarStatusOptions.options.find(op => op.default === true)?.value ??
    DefaultToolbarStatusOptions.options[0].value;

  const [selectedStatus, setSelectedStatus] = useState<string>(defaultSelectedStatus);
  const [searchBarValue, setSearchBarValue] = useState("");
  const [currentFieldFilter, setCurrentFieldFilter] = useState<ToolbarFilter>();

  const customSearchBarApplyFilters = (
    selectedStatus: string,
    toolbarFieldFilter: ToolbarFieldFilter,
    customSearchBarFilter: (initialData: GridValidRowModel[]) => GridValidRowModel[],
    currentFieldFilter?: ToolbarFilter
  ) => {
    let newData = [...initialData];
    newData = customSearchBarFilter(newData);
    newData = applySingleFieldFilter(newData, selectedStatus, toolbarFieldFilter);
    newData = applyFilterMenuFilter(newData, currentFieldFilter);
    setCurrentData(newData);
    setUnsortedData(newData);
  };

  const customSearchBarFilterProps = { setData: customSearchBarApplyFilters };

  useEffect(() => {
    toolbarProps?.customSearchBar !== undefined
      ? customSearchBarFilterProps.setData(
          selectedStatus,
          toolbarProps?.customToolbarFieldFilter ?? DefaultToolbarStatusOptions,
          (initialData: GridValidRowModel[]) => {
            return toolbarProps?.customSearchBarFilter ? toolbarProps?.customSearchBarFilter(initialData, searchBarValue) : [];
          },
          currentFieldFilter
        )
      : applyFilters(
          selectedStatus,
          searchBarValue,
          toolbarProps?.customToolbarFieldFilter ?? DefaultToolbarStatusOptions,
          currentFieldFilter
        );

    setFiltersLocalStorage();
  }, [selectedStatus, searchBarValue, currentFieldFilter]);

  const setFiltersLocalStorage = () => {
    const getStatus = () => {
      if (selectedStatus === "true") return "ACTIVE";
      if (selectedStatus === "false") return "INACTIVE";
      return "ALL";
    };

    const getFilters = () => {
      if (currentFieldFilter) return { [currentFieldFilter.field]: currentFieldFilter.value, searchText: searchBarValue };
      if (searchBarValue !== "") return { searchText: searchBarValue };
      return undefined;
    };

    lastPageService.saveLastPageState({
      ...lastPagePersistedState,
      status: getStatus(),
      filters: getFilters()
    });
  };

  const applySearchBarFilter = (initialData: GridValidRowModel[], searchBarValue: string) => {
    // Apply filter by the value in the search bar.
    const valuecaps = searchBarValue ? searchBarValue.toUpperCase() : "";
    const newData = initialData.filter((data: GridValidRowModel) => {
      let matches = false;
      // Compare the value with each field of the object.
      Object.values(data).forEach((val: unknown) => {
        const stringVal = String(val);
        if (stringVal.toUpperCase().includes(valuecaps)) matches = true;
      });
      return matches;
    });
    return newData;
  };

  const applySingleFieldFilter = (
    initialData: GridValidRowModel[],
    selectedStatus: string,
    toolbarFieldFilter: ToolbarFieldFilter
  ) => {
    if (String(selectedStatus) !== "*") {
      initialData = initialData.filter((data: GridValidRowModel) =>
        String(data[toolbarFieldFilter.fieldName]).includes(String(selectedStatus))
      );
    }

    return initialData;
  };

  const applyFilterMenuFilter = (initialData: GridValidRowModel[], currentFieldFilter?: ToolbarFilter) => {
    if (currentFieldFilter && currentFieldFilter.isFilterActive === true) {
      initialData = initialData.filter((data: GridValidRowModel) => {
        const dataValue = data[currentFieldFilter.field];
        const searchedValue = currentFieldFilter.value;
        switch (true) {
          case typeof dataValue === "string" && typeof searchedValue === "string" && !Array.isArray(searchedValue):
            return dataValue.toUpperCase().includes((searchedValue as string).toUpperCase());

          case typeof dataValue === "string" && Array.isArray(searchedValue):
            return (searchedValue as string[]).some(item =>
              dataValue.toString().toUpperCase().includes(item.toString().toUpperCase())
            );

          case typeof dataValue === "number":
            return dataValue === Number(searchedValue);
          default:
            return dataValue === (searchedValue as unknown);
        }
      });
    }
    return initialData;
  };

  const applyFilters = (
    selectedStatus: string,
    searchBarValue: string,
    toolbarFieldFilter: ToolbarFieldFilter,
    currentFieldFilter?: ToolbarFilter
  ) => {
    let newData = [...initialData];
    newData = applySearchBarFilter(newData, searchBarValue);
    newData = applySingleFieldFilter(newData, selectedStatus, toolbarFieldFilter);
    newData = applyFilterMenuFilter(newData, currentFieldFilter);

    setCurrentData(newData);
    setUnsortedData(newData);
  };

  return {
    customSearchBarFilterProps,
    currentFieldFilter,
    selectedStatus,
    setSearchBarValue,
    setCurrentFieldFilter,
    setSelectedStatus
  };
};
