import Family from "@domain/model/Family";
import { GridColDef } from "@mui/x-data-grid";
import Material from "@domain/model/Material";
import { filterValueTypes } from "@constants";
import { Grid, Typography } from "@mui/material";
import { CustomTooltipCell } from "@components/tooltip/CustomTooltipCell";
import { TitlePopover } from "@components/master-data-view/card/components/title/title-popover";

export const materialColumns = (
  t: (text: string) => string,
  handleEdit?: (id: string) => void,
  handleActive?: (material: Material) => void,
  mdMediaQuery?: boolean
): GridColDef[] => {
  return [
    {
      flex: 0.5,
      field: "code",
      headerName: t("material.code"),
      disableColumnMenu: true,
      minWidth: mdMediaQuery ? undefined : 100,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.9,
      field: "name",
      headerName: t("material.material"),
      disableColumnMenu: true,
      minWidth: mdMediaQuery ? undefined : 100,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.3,
      field: "unitPrice",
      headerName: t("material.price"),
      disableColumnMenu: true,
      minWidth: mdMediaQuery ? undefined : 60,
      type: "number",
      valueGetter: ({ row }) => {
        return `${row.unitPrice.toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`;
      },
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.4,
      field: "minStock",
      headerName: t("material.quant"),
      disableColumnMenu: true,
      minWidth: mdMediaQuery ? undefined : 80,
      type: "string",
      renderCell: data => {
        const materialMeasure = ` ${t("material.measure.abbreviation." + data.row.uom.toLowerCase())}`;
        return (
          <CustomTooltipCell tooltipTitle={data.row.minStock + materialMeasure}>
            <Typography component="div" variant="column_table_typography">
              <span style={{ color: "#4CAC1C", fontSize: "0.75rem", fontWeight: "600" }}>{data.row.minStock}</span>
              {materialMeasure}
            </Typography>
          </CustomTooltipCell>
        );
      }
    },
    {
      flex: 0.7,
      field: "family",
      headerName: t("material.family"),
      disableColumnMenu: true,
      minWidth: mdMediaQuery ? undefined : 150,
      valueGetter: ({ row }) => {
        return row.families.map((family: Family, index: number) =>
          //Adding all the families names to the row. If index of family != (number of families -1), adds a comma. Finally, always add a blank space
          index !== row.families.length - 1 ? `${family.name}` : ` ${family.name} `
        );
      },
      type: "select-one" as filterValueTypes,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.25,
      field: "status",
      headerName: t("material.status"),
      disableColumnMenu: true,
      minWidth: mdMediaQuery ? undefined : 70,
      valueGetter: ({ row }) => {
        return row.active ? t("material.active") : t("material.inactive");
      },
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.2,
      field: "actions",
      headerName: t("material.actions"),
      disableColumnMenu: true,
      sortable: false,
      minWidth: mdMediaQuery ? undefined : 40,
      renderCell: data => (
        <Grid container justifyContent="flex-end">
          <TitlePopover
            handleEdit={() => handleEdit && handleEdit(data.row.id)}
            handleActive={() => handleActive && handleActive(data.row)}
            activateTitle={data.row.active ? t("root.deactivate") : t("root.activate")}
          />
        </Grid>
      )
    }
  ];
};
