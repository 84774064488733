/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  EventDTO,
  EventDTOPage,
  EventResponse,
  MovicodersBadRequestException,
  MovicodersNotFoundException,
  MovicodersServiceException,
  ProductIdentificationFilter
} from "../models";
import {
  EventDTOFromJSON,
  EventDTOToJSON,
  EventDTOPageFromJSON,
  EventDTOPageToJSON,
  EventResponseFromJSON,
  EventResponseToJSON,
  MovicodersBadRequestExceptionFromJSON,
  MovicodersBadRequestExceptionToJSON,
  MovicodersNotFoundExceptionFromJSON,
  MovicodersNotFoundExceptionToJSON,
  MovicodersServiceExceptionFromJSON,
  MovicodersServiceExceptionToJSON,
  ProductIdentificationFilterFromJSON,
  ProductIdentificationFilterToJSON
} from "../models";

export interface DeleteEventRequest {
  id: string;
  xTenantId?: string;
}

export interface DeleteEventsRequest {
  xTenantId?: string;
  requestBody?: Array<string>;
}

export interface FindAllByActionRequest {
  action: string;
  xTenantId?: string;
  limit?: number;
  offset?: number;
}

export interface FindAllByAgentRequest {
  agent: string;
  xTenantId?: string;
  limit?: number;
  offset?: number;
}

export interface FindAllByChildRequest {
  xTenantId?: string;
  limit?: number;
  offset?: number;
  productIdentificationFilter?: ProductIdentificationFilter;
}

export interface FindAllByDatesRequest {
  from: Date;
  to: Date;
  xTenantId?: string;
  limit?: number;
  offset?: number;
}

export interface FindAllByDepartureRequest {
  departure: string;
  xTenantId?: string;
  limit?: number;
  offset?: number;
}

export interface FindAllByDestinationRequest {
  destination: string;
  xTenantId?: string;
  limit?: number;
  offset?: number;
}

export interface FindAllByParentIdentificationRequest {
  xTenantId?: string;
  limit?: number;
  offset?: number;
  productIdentificationFilter?: ProductIdentificationFilter;
}

export interface FindAllByUserRequest {
  user: string;
  xTenantId?: string;
  limit?: number;
  offset?: number;
}

export interface GetEventByIdRequest {
  id: string;
  xTenantId?: string;
}

export interface GetEventByIdsRequest {
  xTenantId?: string;
  requestBody?: Array<string>;
}

export interface ListEventsRequest {
  xTenantId?: string;
}

export interface ListEventsPaginatedRequest {
  limit: number;
  offset: number;
  xTenantId?: string;
}

export interface SaveEventRequest {
  xTenantId?: string;
  eventDTO?: EventDTO;
}

export interface SaveEventsRequest {
  xTenantId?: string;
  eventDTO?: Array<EventDTO>;
}

export interface UpdateEventRequest {
  xTenantId?: string;
  eventDTO?: EventDTO;
}

/**
 *
 */
export class EventsApi extends runtime.BaseAPI {
  /**
   * Takes an existing event, deletes it, and returns the new object.
   * Delete an existing event by id.
   */
  async deleteEventRaw(
    requestParameters: DeleteEventRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<EventDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteEvent."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/events/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => EventDTOFromJSON(jsonValue));
  }

  /**
   * Takes an existing event, deletes it, and returns the new object.
   * Delete an existing event by id.
   */
  async deleteEvent(
    requestParameters: DeleteEventRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<EventDTO> {
    const response = await this.deleteEventRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the deleted Events
   */
  async deleteEventsRaw(
    requestParameters: DeleteEventsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<EventDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/events/delete-many`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(EventDTOFromJSON));
  }

  /**
   * Returns the deleted Events
   */
  async deleteEvents(
    requestParameters: DeleteEventsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<EventDTO>> {
    const response = await this.deleteEventsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns all events by action.
   * Get events by action.
   */
  async findAllByActionRaw(
    requestParameters: FindAllByActionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<EventDTO>>> {
    if (requestParameters.action === null || requestParameters.action === undefined) {
      throw new runtime.RequiredError(
        "action",
        "Required parameter requestParameters.action was null or undefined when calling findAllByAction."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.action !== undefined) {
      queryParameters["action"] = requestParameters.action;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/events/filter/action`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(EventDTOFromJSON));
  }

  /**
   * Returns all events by action.
   * Get events by action.
   */
  async findAllByAction(
    requestParameters: FindAllByActionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<EventDTO>> {
    const response = await this.findAllByActionRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns all events by agent.
   * Get events by agent.
   */
  async findAllByAgentRaw(
    requestParameters: FindAllByAgentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<EventDTO>>> {
    if (requestParameters.agent === null || requestParameters.agent === undefined) {
      throw new runtime.RequiredError(
        "agent",
        "Required parameter requestParameters.agent was null or undefined when calling findAllByAgent."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.agent !== undefined) {
      queryParameters["agent"] = requestParameters.agent;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/events/filter/agent`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(EventDTOFromJSON));
  }

  /**
   * Returns all events by agent.
   * Get events by agent.
   */
  async findAllByAgent(
    requestParameters: FindAllByAgentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<EventDTO>> {
    const response = await this.findAllByAgentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns all events by child provided the identification
   * Get events from a child provided the identification (serial number OR batch and material code)
   */
  async findAllByChildRaw(
    requestParameters: FindAllByChildRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<EventDTO>>> {
    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/events/filter/child`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ProductIdentificationFilterToJSON(requestParameters.productIdentificationFilter)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(EventDTOFromJSON));
  }

  /**
   * Returns all events by child provided the identification
   * Get events from a child provided the identification (serial number OR batch and material code)
   */
  async findAllByChild(
    requestParameters: FindAllByChildRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<EventDTO>> {
    const response = await this.findAllByChildRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns all events within given dates.
   * Get events from within two dates.
   */
  async findAllByDatesRaw(
    requestParameters: FindAllByDatesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<EventDTO>>> {
    if (requestParameters.from === null || requestParameters.from === undefined) {
      throw new runtime.RequiredError(
        "from",
        "Required parameter requestParameters.from was null or undefined when calling findAllByDates."
      );
    }

    if (requestParameters.to === null || requestParameters.to === undefined) {
      throw new runtime.RequiredError(
        "to",
        "Required parameter requestParameters.to was null or undefined when calling findAllByDates."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = (requestParameters.from as any).toISOString();
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = (requestParameters.to as any).toISOString();
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/events/filter/dates`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(EventDTOFromJSON));
  }

  /**
   * Returns all events within given dates.
   * Get events from within two dates.
   */
  async findAllByDates(
    requestParameters: FindAllByDatesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<EventDTO>> {
    const response = await this.findAllByDatesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns all events by departure.
   * Get events by departure.
   */
  async findAllByDepartureRaw(
    requestParameters: FindAllByDepartureRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<EventDTO>>> {
    if (requestParameters.departure === null || requestParameters.departure === undefined) {
      throw new runtime.RequiredError(
        "departure",
        "Required parameter requestParameters.departure was null or undefined when calling findAllByDeparture."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.departure !== undefined) {
      queryParameters["departure"] = requestParameters.departure;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/events/filter/departure`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(EventDTOFromJSON));
  }

  /**
   * Returns all events by departure.
   * Get events by departure.
   */
  async findAllByDeparture(
    requestParameters: FindAllByDepartureRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<EventDTO>> {
    const response = await this.findAllByDepartureRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns all events by destination.
   * Get events by destination.
   */
  async findAllByDestinationRaw(
    requestParameters: FindAllByDestinationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<EventDTO>>> {
    if (requestParameters.destination === null || requestParameters.destination === undefined) {
      throw new runtime.RequiredError(
        "destination",
        "Required parameter requestParameters.destination was null or undefined when calling findAllByDestination."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.destination !== undefined) {
      queryParameters["destination"] = requestParameters.destination;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/events/filter/destination`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(EventDTOFromJSON));
  }

  /**
   * Returns all events by destination.
   * Get events by destination.
   */
  async findAllByDestination(
    requestParameters: FindAllByDestinationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<EventDTO>> {
    const response = await this.findAllByDestinationRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns all events by parent identification.
   * Get events provided a parent batch and material code a OR a serial number.
   */
  async findAllByParentIdentificationRaw(
    requestParameters: FindAllByParentIdentificationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<EventDTO>>> {
    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/events/filter/parent`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ProductIdentificationFilterToJSON(requestParameters.productIdentificationFilter)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(EventDTOFromJSON));
  }

  /**
   * Returns all events by parent identification.
   * Get events provided a parent batch and material code a OR a serial number.
   */
  async findAllByParentIdentification(
    requestParameters: FindAllByParentIdentificationRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<EventDTO>> {
    const response = await this.findAllByParentIdentificationRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns all events by user.
   * Get events by user.
   */
  async findAllByUserRaw(
    requestParameters: FindAllByUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<EventDTO>>> {
    if (requestParameters.user === null || requestParameters.user === undefined) {
      throw new runtime.RequiredError(
        "user",
        "Required parameter requestParameters.user was null or undefined when calling findAllByUser."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.user !== undefined) {
      queryParameters["user"] = requestParameters.user;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/events/filter/user`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(EventDTOFromJSON));
  }

  /**
   * Returns all events by user.
   * Get events by user.
   */
  async findAllByUser(
    requestParameters: FindAllByUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<EventDTO>> {
    const response = await this.findAllByUserRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns one event object by id.
   * Get a event object by id.
   */
  async getEventByIdRaw(
    requestParameters: GetEventByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<EventDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getEventById."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/events/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => EventDTOFromJSON(jsonValue));
  }

  /**
   * Returns one event object by id.
   * Get a event object by id.
   */
  async getEventById(
    requestParameters: GetEventByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<EventDTO> {
    const response = await this.getEventByIdRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns event objects by ids.
   * Get a event object by id.
   */
  async getEventByIdsRaw(
    requestParameters: GetEventByIdsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<EventDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/events/find-many-by-id`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(EventDTOFromJSON));
  }

  /**
   * Returns event objects by ids.
   * Get a event object by id.
   */
  async getEventByIds(
    requestParameters: GetEventByIdsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<EventDTO>> {
    const response = await this.getEventByIdsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the list of all registered Events
   */
  async listEventsRaw(
    requestParameters: ListEventsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<EventDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/events`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(EventDTOFromJSON));
  }

  /**
   * Returns the list of all registered Events
   */
  async listEvents(
    requestParameters: ListEventsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<EventDTO>> {
    const response = await this.listEventsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the list of all registered Events paginated
   */
  async listEventsPaginatedRaw(
    requestParameters: ListEventsPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<EventDTOPage>> {
    if (requestParameters.limit === null || requestParameters.limit === undefined) {
      throw new runtime.RequiredError(
        "limit",
        "Required parameter requestParameters.limit was null or undefined when calling listEventsPaginated."
      );
    }

    if (requestParameters.offset === null || requestParameters.offset === undefined) {
      throw new runtime.RequiredError(
        "offset",
        "Required parameter requestParameters.offset was null or undefined when calling listEventsPaginated."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/events/paginated`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => EventDTOPageFromJSON(jsonValue));
  }

  /**
   * Returns the list of all registered Events paginated
   */
  async listEventsPaginated(
    requestParameters: ListEventsPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<EventDTOPage> {
    const response = await this.listEventsPaginatedRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the registered Event
   */
  async saveEventRaw(
    requestParameters: SaveEventRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<EventDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/events`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: EventDTOToJSON(requestParameters.eventDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => EventDTOFromJSON(jsonValue));
  }

  /**
   * Returns the registered Event
   */
  async saveEvent(
    requestParameters: SaveEventRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<EventDTO> {
    const response = await this.saveEventRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the registered Events
   */
  async saveEventsRaw(
    requestParameters: SaveEventsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<EventResponse>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/events/add-many`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.eventDTO?.map(EventDTOToJSON)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(EventResponseFromJSON));
  }

  /**
   * Returns the registered Events
   */
  async saveEvents(
    requestParameters: SaveEventsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<EventResponse>> {
    const response = await this.saveEventsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Takes an existing Event object, updates it, and returns the Event.
   * Update an existing Event.
   */
  async updateEventRaw(
    requestParameters: UpdateEventRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<EventDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/events`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: EventDTOToJSON(requestParameters.eventDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => EventDTOFromJSON(jsonValue));
  }

  /**
   * Takes an existing Event object, updates it, and returns the Event.
   * Update an existing Event.
   */
  async updateEvent(
    requestParameters: UpdateEventRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<EventDTO> {
    const response = await this.updateEventRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
