import {
  DeleteMultimediaRequest,
  GetAllMultimediaRequest,
  MediaApi,
  MultimediaDTO,
  MultimediaDTOFromJSON,
  UpdateMultimediaRequest
} from "@clients/aggrego-proxy";
import { IImpersonationService } from "@domain/services/IImpersonationService";

export class MultimediaApi {
  constructor(private readonly mediaApi: MediaApi, private readonly impersonationService: IImpersonationService) {}

  async get(pageCriteria: GetAllMultimediaRequest): Promise<MultimediaDTO[]> {
    const result: MultimediaDTO[] = [];

    const apiResult = await this.mediaApi.getAllMultimedia({
      ...pageCriteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
    apiResult.forEach(mediaClient => {
      result.push(MultimediaDTOFromJSON(mediaClient));
    });
    return result;
  }

  async getById(id: string): Promise<MultimediaDTO> {
    const result = MultimediaDTOFromJSON(
      await this.mediaApi.getMultimediaByID({
        id: id,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return result;
  }

  async upload(entity: Blob): Promise<MultimediaDTO> {
    const result = MultimediaDTOFromJSON(
      await this.mediaApi.multimediaUpload({
        body: entity,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return result;
  }

  async uploadExternal(entity: MultimediaDTO): Promise<MultimediaDTO> {
    const result = MultimediaDTOFromJSON(
      await this.mediaApi.createExternalMultimedia({
        multimediaDTO: entity,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return result;
  }

  async update(criteria: UpdateMultimediaRequest): Promise<MultimediaDTO> {
    const result = MultimediaDTOFromJSON(
      await this.mediaApi.updateMultimedia({
        ...criteria,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return result;
  }

  async delete(criteria: DeleteMultimediaRequest): Promise<void> {
    await this.mediaApi.deleteMultimedia({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }
}
