import { t } from "i18next";
import { useResolve } from "@movicoders/di";
import { useSnackbar } from "@movicoders/ui";
import { ChangeEvent, useState } from "react";
import { OperatorRepository } from "@infrastructure/repositories/operator-repository";
import { IOperatorService, OperatorService } from "@domain/services/IOperatorService";

export const useLogisticLoginViewModel = () => {
  const { show } = useSnackbar();

  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  const operatorRepository = useResolve<OperatorRepository>(OperatorRepository);
  const { saveOperatorState } = useResolve<IOperatorService>(OperatorService);

  const handleUsernameChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(event.target.value);
  };

  const handleLogin = async () => {
    const userField = !username.trim();
    const passField = !password.trim();

    setUsernameError(userField);
    setPasswordError(passField);

    operatorRepository
      .validate({ operatorValidationRequestDTO: { code: username, password: password } })
      .then(operator => {
        show(t("login.snackbar"), "success");
        setIsClosed(true);
        saveOperatorState({ operator: operator });
      })
      .catch(() => {
        show(t("login.snackbar.error"), "error");
      });
  };

  return {
    username,
    usernameError,
    password,
    passwordError,
    isClosed,
    handleLogin,
    handleUsernameChange,
    handlePasswordChange
  };
};
