import { DomainPage } from "@movicoders/domain";
import { CompanyApi } from "../api/company-api";
import Company from "@domain/model/CompanyAdminBoard";
import { CompanyDTO, FullCompanyItemDTO } from "@clients/aggrego-proxy";
import { ListCompanyItemPaginatedRequest } from "@clients/aggrego-proxy/apis/CompaniesApi";
import { CompanyCreateDTO } from "@pages/portal-admin/admin-board/components/stepper-company/company-create-model";

export class CompanyRepository {
  constructor(private readonly companyApi: CompanyApi) {}
  get(): Promise<CompanyDTO[]> {
    return this.companyApi.get();
  }
  getPaginated(pageCriteria: ListCompanyItemPaginatedRequest): Promise<DomainPage<Company>> {
    return this.companyApi.getPaginated(pageCriteria);
  }
  getById(id: string): Promise<CompanyDTO> {
    return this.companyApi.getById(id);
  }
  getDetails(tenant: string): Promise<FullCompanyItemDTO> {
    return this.companyApi.getDetails(tenant);
  }
  async create(entity: CompanyCreateDTO): Promise<CompanyCreateDTO> {
    await this.companyApi.create(entity);
    return entity;
  }
  async update(entity: FullCompanyItemDTO): Promise<FullCompanyItemDTO> {
    return this.companyApi.update(entity);
  }

  async remove(id: string): Promise<void> {
    return this.companyApi.remove(id);
  }
}
