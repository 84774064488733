import { Grid } from "@mui/material";
import Zone from "@domain/model/Zone";
import { ZoneCard } from "./zone-card";
import { useResponsiveGrid } from "@hooks/useResponsiveGrid";
import { DomainPage } from "@movicoders/domain/lib/Entities/DomainPage";
import { NoRecordsFound } from "@components/no-records-found/no-records-found";

interface ZoneCardsDisplay {
  dataPaginated: DomainPage<Zone>;
  handleOpenUpsertDialog: (id: string) => void;
  handleOpenToggleActiveStatusDialog: (zone: Zone) => void;
}

export const CardsDisplay = (props: ZoneCardsDisplay) => {
  const { handleMediaQuery } = useResponsiveGrid();
  const columns = handleMediaQuery(3, 3, 2, 2);
  const CARD_GRID_MD = Math.round(12 / columns);
  const CARD_GRID_SM = Math.round(8 / columns);

  return props.dataPaginated?.content?.length && props.dataPaginated?.content?.length > 0 ? (
    <>
      {props.dataPaginated?.content?.map((zone: Zone, index: number) => (
        <Grid item xs={12} sm={CARD_GRID_SM} md={CARD_GRID_MD} lg={CARD_GRID_MD} key={`grid-card-${zone.id}`}>
          <ZoneCard
            zone={zone}
            handleEdit={props.handleOpenUpsertDialog}
            handleActive={props.handleOpenToggleActiveStatusDialog}
          />
        </Grid>
      ))}
    </>
  ) : (
    <NoRecordsFound />
  );
};
