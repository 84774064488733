import Warehouse from "@domain/model/Warehouse";
import { WarehouseApi } from "../api/warehouse-api";
import { DomainPage, IRepository } from "@movicoders/domain";
import { ListWarehousesByTenantIdRequest, ListWarehousesPaginatedRequest } from "@clients/aggrego-proxy/apis/WarehousesApi";

export class WarehouseRepository implements IRepository<Warehouse, null, ListWarehousesPaginatedRequest> {
  constructor(private readonly warehouseApi: WarehouseApi) {}

  get(): Promise<Warehouse[]> {
    return this.warehouseApi.get();
  }
  getByTenant(pageCriteria: ListWarehousesByTenantIdRequest): Promise<Warehouse[]> {
    return this.warehouseApi.getByTenantId(pageCriteria);
  }
  getPaginated(pageCriteria: ListWarehousesPaginatedRequest): Promise<DomainPage<Warehouse>> {
    return this.warehouseApi.getPaginated(pageCriteria);
  }
  getById(id: string): Promise<Warehouse> {
    return this.warehouseApi.getById(id);
  }
  getFullDTOById(id: string): Promise<Warehouse> {
    return this.warehouseApi.getFullDTOById(id);
  }
  async create(entity: Warehouse): Promise<Warehouse> {
    return await this.warehouseApi.create(entity);
  }
  async update(entity: Warehouse): Promise<Warehouse> {
    return await this.warehouseApi.update(entity);
  }
  async remove(id: string): Promise<void> {
    await this.warehouseApi.remove(id);
  }
}
