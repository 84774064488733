import { create } from "@movicoders/store";
import { LastPageState } from "@domain/model/LastPageState";
import { ILastPageService } from "@domain/services/ILastPageService";

export const useLastPageService = create<ILastPageService>((set, get) => ({
  saveLastPageState: (newState: LastPageState) => set({ persistedState: newState }),
  clearLastPageState: () => set({ persistedState: undefined }),
  getCurrentState: () => get().persistedState
}))
  .withPersist(LastPageState, "LastPageState")
  .build();
