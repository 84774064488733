import Papa from "papaparse";
import { useResolve } from "@movicoders/di";
import { useTranslation } from "react-i18next";
import { TableState } from "@domain/model/TableState";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid";
import { ITableService, TableService } from "@domain/services/ITableService";

export const useHandleDownloadMenu = (currentViewName: string, currentData: GridValidRowModel[]) => {
  // State
  const service = useResolve<ITableService>(TableService);
  const persistedState = service.persistedState ?? new TableState();
  const { views } = { ...persistedState };
  const { t } = useTranslation();

  const getFileDate = () => {
    const separatedDate = new Date().toLocaleString().split(/[,:]/g).join("").split(" ");
    const time = separatedDate[1];
    const reversedDate = separatedDate[0].split("/").reverse();
    const finalDate =
      reversedDate[0] +
      (reversedDate[1].length > 1 ? reversedDate[1] : "0" + reversedDate[1]) +
      (reversedDate[2].length > 1 ? reversedDate[2] : "0" + reversedDate[2]);
    return finalDate + time;
  };
  const isFieldVisible = (field: string): boolean => {
    const tempView = views.find(view => view.viewName === currentViewName);
    if (tempView) {
      const tempColumn = tempView.columns.find(col => col.fieldName === field);
      return tempColumn !== undefined ? tempColumn.visible : true;
    }
    return true;
  };

  const getPDFData = (
    disabledFields: string[],
    columns: GridColDef[]
  ): { name: string; headers: GridColDef[]; data: GridValidRowModel[]; tableName: string } => {
    const avaliableColumns = columns.filter(col => isFieldVisible(col.field) && !disabledFields.includes(col.field));
    return {
      name: t(currentViewName) + "_" + getFileDate(),
      headers: avaliableColumns,
      data: currentData,
      tableName: t(currentViewName)
    };
  };

  const handlePrintScreen = () => {
    window.print();
  };

  const handleDownloadCSV = (
    disabledFields: string[],
    columns: GridColDef[],
    parseCSVInDownload?: (data: GridValidRowModel) => GridValidRowModel
  ) => {
    const avaliableColumns = columns.filter(col => isFieldVisible(col.field) && !disabledFields.includes(col.field));
    const columnsToDownload = avaliableColumns.map(col => col.field);
    const columnTitles = avaliableColumns.map(col => (col.headerName ? col.headerName : col.field));

    let dataToExport = [...currentData];
    if (parseCSVInDownload !== undefined) dataToExport = dataToExport.map(data => parseCSVInDownload(data));

    const csv = Papa.unparse(dataToExport, { header: true, escapeFormulae: true, columns: columnsToDownload, delimiter: ";" });

    const splitCSV = csv.split("\r\n");
    splitCSV[0] = "";
    const translatedHeaders = columnTitles.join(";") + ";";
    const finalCSV = translatedHeaders.concat(splitCSV.join("\r\n"));
    const csvData = new Blob(["\ufeff", finalCSV], { type: "text/csv;charset=utf-8;" });

    const csvURL = window.URL.createObjectURL(csvData);
    const tempLink = document.createElement("a");
    tempLink.href = csvURL;

    const fileName = t(currentViewName) + "_" + getFileDate() + ".csv";
    tempLink.setAttribute("download", fileName);
    tempLink.click();
  };

  return {
    getPDFData,
    handleDownloadCSV,
    handlePrintScreen,
    isFieldVisible
  };
};
