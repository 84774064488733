import {
  ContainerDTO,
  FullUserDTO,
  FullUserDTOCompanyLicenseEnum,
  GetWarehouseStatusReportByWarehouseAndFilterRequest,
  GetWarehouseStatusReportByWarehouseAndFilterSortDirectionEnum,
  GetWarehouseStatusReportByWarehouseAndFilterSortFieldEnum,
  ListMaterialsPaginatedRequest
} from "@clients/aggrego-proxy";
import { User } from "@domain/model/User";
import { useResolve } from "@movicoders/di";
import Material from "@domain/model/Material";
import { GridSortModel } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import LocationEntity from "@domain/model/Location";
import { IKeyable } from "@domain/interface/IKeyable";
import { LastPageState } from "@domain/model/LastPageState";
import { useListDetailPaginatedViewModel } from "@movicoders/ui";
import { WarehouseStatusDTO } from "@domain/model/WarehouseStatus";
import { useWarehouseStatusHandleData } from "./useWarehouseStatusHandleData";
import { ILastPageService, LastPageService } from "@domain/services/ILastPageService";
import { MaterialRepository } from "@infrastructure/repositories/material-repository";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { convertDateStringToFirstMillisecond, convertDateStringToLastMillisecond } from "@utils/date-helper";

type DateFilter = {
  date: string[];
  searchText: string;
};

export const useWarehouseStatusViewModel = () => {
  const settingsService = useResolve<ISettingsService>(SettingsService);

  const {
    dataPaginated,
    families,
    loading,
    currentPageService,
    currentUserPersistedState,
    currentPagePersistedState,
    warehouseStatusFetch,
    selectedWarehouseCode,
    isViewPageReadyToLoad
  } = useWarehouseStatusHandleData();

  /* Block Autocomplete-one */
  const { data: materialPaginatedData, getPaginated: getMaterialPaginated } = useListDetailPaginatedViewModel(MaterialRepository);
  const getMaterialPaginatedForFilter = useRef(getMaterialPaginated).current;

  const [filteredMaterialPaginatedData, setFilteredMaterialPaginatedData] = useState<Material[]>([]);
  /* End block Autocomplete-one */

  const [columnsByMode, setColumnsByMode] = useState<string[]>([]);

  const DEBOUNCE_TIME = 750;
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);

  const [searchBarFilter, setSearchBarFilter] = useState("");

  /* Block Autocomplete-one */
  const lastPageService = useResolve<ILastPageService>(LastPageService);
  const lastPagePersistedState = lastPageService.persistedState ?? new LastPageState();

  useEffect(() => {
    if (lastPagePersistedState.autocompleteFilterText !== undefined && !currentPagePersistedState.autocompleteFilterLoading) {
      lastPageService.saveLastPageState({ ...lastPagePersistedState, autocompleteFilterLoading: true });
      getMaterialPaginatedForFilter({
        xTenantId: "",
        limit: 10,
        offset: 0,
        fullDTO: false,
        customMaterialFilter: {
          ...lastPagePersistedState.filters,
          family: undefined,
          autocompleteFilterText: undefined,
          searchText: lastPagePersistedState.autocompleteFilterText
        }
      } as ListMaterialsPaginatedRequest).finally(() => {
        lastPageService.saveLastPageState({ ...lastPagePersistedState, autocompleteFilterLoading: false });
      });
    }
  }, [lastPagePersistedState.autocompleteFilterText]);

  useEffect(() => {
    setFilteredMaterialPaginatedData(
      (materialPaginatedData.content as Material[])?.filter(
        material =>
          material.code.toLowerCase().includes(lastPagePersistedState.autocompleteFilterText?.toLowerCase() ?? "") ||
          material.name.toLowerCase().includes(lastPagePersistedState.autocompleteFilterText?.toLowerCase() ?? "")
      )
    );
  }, [materialPaginatedData]);
  /* End block Autocomplete-one */

  useEffect(() => {
    generateColumns();
    searchByPreviousObtainedUser();
  }, [selectedWarehouseCode]);

  const generateColumns = () => {
    switch (currentUserPersistedState.user?.companyLicense) {
      case FullUserDTOCompanyLicenseEnum.Im:
        setColumnsByMode(["id", "container", "location"]);
        break;
      case FullUserDTOCompanyLicenseEnum.Wm:
        setColumnsByMode(["id", "container"]);
        break;
      case FullUserDTOCompanyLicenseEnum.WmWithContainer:
        setColumnsByMode(["id"]);
        break;
    }
  };

  const search = (limit: number, offset: number, filters?: object | undefined) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    const newDebounceTimer = setTimeout(() => {
      let dateFilter = undefined;
      if (currentPagePersistedState.filters && (currentPagePersistedState.filters as DateFilter).date) {
        dateFilter = {
          initDate: convertDateStringToFirstMillisecond((currentPagePersistedState.filters as DateFilter).date[0]),
          endDate: convertDateStringToLastMillisecond((currentPagePersistedState.filters as DateFilter).date[1]),
          searchText: (currentPageService.persistedState?.filters as IKeyable)?.["searchText"] ?? undefined,
          //if container is inactive, the row appears in table, but filters doesn't take into account. Now, only rows with active containers will appear in table.
          status: true
        };
      }
      warehouseStatusFetch({
        offset,
        limit,
        sortDirection: GetWarehouseStatusReportByWarehouseAndFilterSortDirectionEnum.Desc,
        sortField: GetWarehouseStatusReportByWarehouseAndFilterSortFieldEnum.Date,
        warehouseCode: selectedWarehouseCode,
        //if container is inactive, the row appears in table, but filters doesn't take into account. Now, only rows with active containers will appear in table.
        statusReportFilterDTO: dateFilter ?? { ...filters, status: true } ?? {}
      } as GetWarehouseStatusReportByWarehouseAndFilterRequest);
    }, DEBOUNCE_TIME);
    setDebounceTimer(newDebounceTimer);
  };

  const sortByFieldBackend = (model: GridSortModel) => {
    (dataPaginated?.content as WarehouseStatusDTO[]).length > 0 &&
      warehouseStatusFetch({
        limit: currentPagePersistedState.limit,
        offset: currentPagePersistedState.offset,
        sortDirection: model[0]?.sort?.toUpperCase() ?? GetWarehouseStatusReportByWarehouseAndFilterSortDirectionEnum.Desc,
        warehouseCode: selectedWarehouseCode,
        sortField:
          model[0]?.field && model[0]?.field?.toUpperCase() === "OPERATORCODE"
            ? GetWarehouseStatusReportByWarehouseAndFilterSortFieldEnum.Operator
            : model[0]?.field?.toUpperCase() ?? GetWarehouseStatusReportByWarehouseAndFilterSortFieldEnum.Date,
        //if container is inactive, the row appears in table, but filters doesn't take into account. Now, only rows with active containers will appear in table.
        statusReportFilterDTO: { ...currentPagePersistedState.filters, status: true } ?? {}
      } as GetWarehouseStatusReportByWarehouseAndFilterRequest);
  };

  const searchByPreviousObtainedUser = () => {
    const params = new URLSearchParams(window.location.search);
    const foundUser = params.get("search");
    if (foundUser) {
      const preselectedFilterObject = JSON.parse(decodeURIComponent(foundUser));
      applyUrlObtainedFilter(preselectedFilterObject);
    }
  };

  const applyUrlObtainedFilter = (preselectedFilterObject: object) => {
    if (Material.isMaterial(preselectedFilterObject)) {
      setSearchBarFilter((preselectedFilterObject as Material).code);
    } else if (LocationEntity.isLocation(preselectedFilterObject)) {
      setSearchBarFilter((preselectedFilterObject as LocationEntity).code ?? "");
    } else if (User.isFullUserDTO(preselectedFilterObject as FullUserDTO)) {
      setSearchBarFilter((preselectedFilterObject as User).username);
    } else {
      setSearchBarFilter((preselectedFilterObject as ContainerDTO).code ?? "");
    }
  };

  return {
    dataPaginated,
    filteredMaterialPaginatedData,
    loading,
    search,
    families,
    columnsByMode,
    sortByFieldBackend,
    searchBarFilter,
    isViewPageReadyToLoad,
    settingsService
  };
};
