import Tile from "@domain/model/Tile";
import Zone from "@domain/model/Zone";
import { useResolve } from "@movicoders/di";
import { useEffect, useRef, useState } from "react";
import { LastPageState } from "@domain/model/LastPageState";
import { useListDetailPaginatedViewModel } from "@movicoders/ui";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { MasterDataEnum } from "@pages/settings/settings-interfaces";
import { GetTilesByWarehouseIDRequest } from "@clients/aggrego-proxy";
import { ILastPageService, LastPageService } from "@domain/services/ILastPageService";
import { LocationRepository } from "@infrastructure/repositories/location-repository";
import { FindTilesUseCase } from "@application/master-data/tiles/find-tiles-use-case";
import { FindZonesByTenantUseCase } from "@application/master-data/zones/find-zones-by-tenant-use-case";

export const useLocationHandleDatas = (selectedWarehouse: string) => {
  const {
    selected: selectedLocation,
    create,
    fetchOne,
    goToList,
    loading,
    loadingDetail,
    data,
    getPaginated
  } = useListDetailPaginatedViewModel(LocationRepository);

  const getLocationsPaginated = useRef(getPaginated).current;
  const currentPageService = useResolve<ILastPageService>(LastPageService);
  const settingsService = useResolve<ISettingsService>(SettingsService);

  const currentPagePersistedState = currentPageService.persistedState ?? new LastPageState();

  //To receive all tiles
  const findTilesUseCase = useResolve(FindTilesUseCase);
  const [tilesByWarehouse, setTilesByWarehouse] = useState<Tile[]>([]);

  //To receive all zones
  const findZonesByTenantUseCase = useResolve(FindZonesByTenantUseCase);
  const [zonesByWarehouse, setZonesByWarehouse] = useState<Zone[]>([]);

  useEffect(() => {
    if (settingsService.persistedState?.masterdataPermissions.includes(MasterDataEnum.Locations)) {
      selectedWarehouse &&
        selectedWarehouse !== "" &&
        findTilesUseCase
          .byWarehouseId({ warehouseId: selectedWarehouse, fullDTO: false } as GetTilesByWarehouseIDRequest)
          .then(tilesResult => {
            setTilesByWarehouse(tilesResult);
          });
      findZonesByTenantUseCase.execute("ALL").then(zonesResult => {
        setZonesByWarehouse(zonesResult.filter((zone: Zone) => zone.warehouseId === selectedWarehouse));
      });
    }
  }, [settingsService.persistedState]);

  return {
    currentPageService,
    currentPagePersistedState,
    tilesByWarehouse,
    setTilesByWarehouse,
    findTilesUseCase,
    zonesByWarehouse,
    setZonesByWarehouse,
    findZonesByTenantUseCase,
    loading,
    loadingDetail,
    locationData: data,
    selectedLocation,
    goToCreate: create,
    goToDetail: fetchOne,
    goToList,
    getLocationData: getLocationsPaginated
  };
};
