/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  CustomLocationsFilterDTO,
  ListResponseDTO,
  LocationDTO,
  LocationItemDTOPage,
  LocationPageDTO,
  MovicodersBadRequestException,
  MovicodersNotFoundException,
  MovicodersServiceException
} from "../models";
import {
  CustomLocationsFilterDTOFromJSON,
  CustomLocationsFilterDTOToJSON,
  ListResponseDTOFromJSON,
  ListResponseDTOToJSON,
  LocationDTOFromJSON,
  LocationDTOToJSON,
  LocationItemDTOPageFromJSON,
  LocationItemDTOPageToJSON,
  LocationPageDTOFromJSON,
  LocationPageDTOToJSON,
  MovicodersBadRequestExceptionFromJSON,
  MovicodersBadRequestExceptionToJSON,
  MovicodersNotFoundExceptionFromJSON,
  MovicodersNotFoundExceptionToJSON,
  MovicodersServiceExceptionFromJSON,
  MovicodersServiceExceptionToJSON
} from "../models";

export interface DeleteLocationRequest {
  id: string;
  xTenantId?: string;
}

export interface DeleteLocationsRequest {
  xTenantId?: string;
  requestBody?: Array<string>;
}

export interface GetLocationByContainerRequest {
  container: string;
  xTenantId?: string;
  warehouseId?: string;
}

export interface GetLocationByIdRequest {
  id: string;
  fullDTO: boolean;
  xTenantId?: string;
}

export interface GetLocationsByCodeRequest {
  code: string;
  xTenantId?: string;
  warehouseId?: string;
}

export interface GetLocationsByExactCodeRequest {
  code: string;
  xTenantId?: string;
  warehouseId?: string;
}

export interface GetLocationsByIdsRequest {
  xTenantId?: string;
  requestBody?: Array<string>;
}

export interface ListLocationsRequest {
  status: ListLocationsStatusEnum;
  xTenantId?: string;
}

export interface ListLocationsItemsPaginatedRequest {
  limit: number;
  offset: number;
  xTenantId?: string;
  sortField?: ListLocationsItemsPaginatedSortFieldEnum;
  sortDirection?: ListLocationsItemsPaginatedSortDirectionEnum;
}

export interface ListLocationsPaginatedRequest {
  limit: number;
  offset: number;
  xTenantId?: string;
  sortField?: ListLocationsPaginatedSortFieldEnum;
  sortDirection?: ListLocationsPaginatedSortDirectionEnum;
  customLocationsFilterDTO?: CustomLocationsFilterDTO;
}

export interface RemoveContainersFromLocationRequest {
  id: string;
  xTenantId?: string;
  requestBody?: Array<string>;
}

export interface SaveContainersByLocationRequest {
  id: string;
  xTenantId?: string;
  requestBody?: Array<string>;
}

export interface SaveLocationRequest {
  xTenantId?: string;
  locationDTO?: LocationDTO;
}

export interface SaveLocationsRequest {
  xTenantId?: string;
  locationDTO?: Array<LocationDTO>;
}

export interface UpdateLocationRequest {
  xTenantId?: string;
  locationDTO?: LocationDTO;
}

/**
 *
 */
export class LocationsApi extends runtime.BaseAPI {
  /**
   * Takes an existing location, deletes it logically, and returns the new object.
   * Logical delete of an existing location by id.
   */
  async deleteLocationRaw(
    requestParameters: DeleteLocationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<LocationDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteLocation."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/locations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => LocationDTOFromJSON(jsonValue));
  }

  /**
   * Takes an existing location, deletes it logically, and returns the new object.
   * Logical delete of an existing location by id.
   */
  async deleteLocation(
    requestParameters: DeleteLocationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<LocationDTO> {
    const response = await this.deleteLocationRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the deleted locations
   */
  async deleteLocationsRaw(
    requestParameters: DeleteLocationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<LocationDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/locations/delete-many`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(LocationDTOFromJSON));
  }

  /**
   * Returns the deleted locations
   */
  async deleteLocations(
    requestParameters: DeleteLocationsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<LocationDTO>> {
    const response = await this.deleteLocationsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns location by name.
   * Find location by container.
   */
  async getLocationByContainerRaw(
    requestParameters: GetLocationByContainerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<LocationDTO>> {
    if (requestParameters.container === null || requestParameters.container === undefined) {
      throw new runtime.RequiredError(
        "container",
        "Required parameter requestParameters.container was null or undefined when calling getLocationByContainer."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.container !== undefined) {
      queryParameters["container"] = requestParameters.container;
    }

    if (requestParameters.warehouseId !== undefined) {
      queryParameters["warehouseId"] = requestParameters.warehouseId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/locations/container`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => LocationDTOFromJSON(jsonValue));
  }

  /**
   * Returns location by name.
   * Find location by container.
   */
  async getLocationByContainer(
    requestParameters: GetLocationByContainerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<LocationDTO> {
    const response = await this.getLocationByContainerRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns one location object by id.
   * Get a location object by id.
   */
  async getLocationByIdRaw(
    requestParameters: GetLocationByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<LocationDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getLocationById."
      );
    }

    if (requestParameters.fullDTO === null || requestParameters.fullDTO === undefined) {
      throw new runtime.RequiredError(
        "fullDTO",
        "Required parameter requestParameters.fullDTO was null or undefined when calling getLocationById."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fullDTO !== undefined) {
      queryParameters["fullDTO"] = requestParameters.fullDTO;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/locations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => LocationDTOFromJSON(jsonValue));
  }

  /**
   * Returns one location object by id.
   * Get a location object by id.
   */
  async getLocationById(
    requestParameters: GetLocationByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<LocationDTO> {
    const response = await this.getLocationByIdRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns locations by name.
   * Find locations by code.
   */
  async getLocationsByCodeRaw(
    requestParameters: GetLocationsByCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<LocationDTO>>> {
    if (requestParameters.code === null || requestParameters.code === undefined) {
      throw new runtime.RequiredError(
        "code",
        "Required parameter requestParameters.code was null or undefined when calling getLocationsByCode."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.code !== undefined) {
      queryParameters["code"] = requestParameters.code;
    }

    if (requestParameters.warehouseId !== undefined) {
      queryParameters["warehouseId"] = requestParameters.warehouseId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/locations/code`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(LocationDTOFromJSON));
  }

  /**
   * Returns locations by name.
   * Find locations by code.
   */
  async getLocationsByCode(
    requestParameters: GetLocationsByCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<LocationDTO>> {
    const response = await this.getLocationsByCodeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns a lcoation by code.
   * Find locations by exact code.
   */
  async getLocationsByExactCodeRaw(
    requestParameters: GetLocationsByExactCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<LocationDTO>> {
    if (requestParameters.code === null || requestParameters.code === undefined) {
      throw new runtime.RequiredError(
        "code",
        "Required parameter requestParameters.code was null or undefined when calling getLocationsByExactCode."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.code !== undefined) {
      queryParameters["code"] = requestParameters.code;
    }

    if (requestParameters.warehouseId !== undefined) {
      queryParameters["warehouseId"] = requestParameters.warehouseId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/locations/code/exact`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => LocationDTOFromJSON(jsonValue));
  }

  /**
   * Returns a lcoation by code.
   * Find locations by exact code.
   */
  async getLocationsByExactCode(
    requestParameters: GetLocationsByExactCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<LocationDTO> {
    const response = await this.getLocationsByExactCodeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns locations objects by ids.
   * Get a location object by ids.
   */
  async getLocationsByIdsRaw(
    requestParameters: GetLocationsByIdsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<LocationDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/locations/find-many-by-id`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(LocationDTOFromJSON));
  }

  /**
   * Returns locations objects by ids.
   * Get a location object by ids.
   */
  async getLocationsByIds(
    requestParameters: GetLocationsByIdsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<LocationDTO>> {
    const response = await this.getLocationsByIdsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered locations
   */
  async listLocationsRaw(
    requestParameters: ListLocationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<LocationDTO>>> {
    if (requestParameters.status === null || requestParameters.status === undefined) {
      throw new runtime.RequiredError(
        "status",
        "Required parameter requestParameters.status was null or undefined when calling listLocations."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/locations`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(LocationDTOFromJSON));
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered locations
   */
  async listLocations(
    requestParameters: ListLocationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<LocationDTO>> {
    const response = await this.listLocationsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the list of custom location for a table view
   */
  async listLocationsItemsPaginatedRaw(
    requestParameters: ListLocationsItemsPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<LocationItemDTOPage>> {
    if (requestParameters.limit === null || requestParameters.limit === undefined) {
      throw new runtime.RequiredError(
        "limit",
        "Required parameter requestParameters.limit was null or undefined when calling listLocationsItemsPaginated."
      );
    }

    if (requestParameters.offset === null || requestParameters.offset === undefined) {
      throw new runtime.RequiredError(
        "offset",
        "Required parameter requestParameters.offset was null or undefined when calling listLocationsItemsPaginated."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sortField"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sortDirection"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/locations/info/paginated`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => LocationItemDTOPageFromJSON(jsonValue));
  }

  /**
   * Returns the list of custom location for a table view
   */
  async listLocationsItemsPaginated(
    requestParameters: ListLocationsItemsPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<LocationItemDTOPage> {
    const response = await this.listLocationsItemsPaginatedRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered locations paginated
   */
  async listLocationsPaginatedRaw(
    requestParameters: ListLocationsPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<LocationPageDTO>> {
    if (requestParameters.limit === null || requestParameters.limit === undefined) {
      throw new runtime.RequiredError(
        "limit",
        "Required parameter requestParameters.limit was null or undefined when calling listLocationsPaginated."
      );
    }

    if (requestParameters.offset === null || requestParameters.offset === undefined) {
      throw new runtime.RequiredError(
        "offset",
        "Required parameter requestParameters.offset was null or undefined when calling listLocationsPaginated."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sortField"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sortDirection"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/locations/paginated`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CustomLocationsFilterDTOToJSON(requestParameters.customLocationsFilterDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => LocationPageDTOFromJSON(jsonValue));
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered locations paginated
   */
  async listLocationsPaginated(
    requestParameters: ListLocationsPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<LocationPageDTO> {
    const response = await this.listLocationsPaginatedRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns The location code with the removed containers IDs.
   * Remove containers from location.
   */
  async removeContainersFromLocationRaw(
    requestParameters: RemoveContainersFromLocationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ListResponseDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling removeContainersFromLocation."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/locations/container/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ListResponseDTOFromJSON(jsonValue));
  }

  /**
   * Returns The location code with the removed containers IDs.
   * Remove containers from location.
   */
  async removeContainersFromLocation(
    requestParameters: RemoveContainersFromLocationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ListResponseDTO> {
    const response = await this.removeContainersFromLocationRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns location by ID.
   * Save containers by location.
   */
  async saveContainersByLocationRaw(
    requestParameters: SaveContainersByLocationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ListResponseDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling saveContainersByLocation."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/locations/container/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ListResponseDTOFromJSON(jsonValue));
  }

  /**
   * Returns location by ID.
   * Save containers by location.
   */
  async saveContainersByLocation(
    requestParameters: SaveContainersByLocationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ListResponseDTO> {
    const response = await this.saveContainersByLocationRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the registered Location
   */
  async saveLocationRaw(
    requestParameters: SaveLocationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<LocationDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/locations`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: LocationDTOToJSON(requestParameters.locationDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => LocationDTOFromJSON(jsonValue));
  }

  /**
   * Returns the registered Location
   */
  async saveLocation(
    requestParameters: SaveLocationRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<LocationDTO> {
    const response = await this.saveLocationRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the registered location.
   */
  async saveLocationsRaw(
    requestParameters: SaveLocationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ListResponseDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/locations/add-many`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.locationDTO?.map(LocationDTOToJSON)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ListResponseDTOFromJSON(jsonValue));
  }

  /**
   * Returns the registered location.
   */
  async saveLocations(
    requestParameters: SaveLocationsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ListResponseDTO> {
    const response = await this.saveLocationsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Takes an existing location object, updates it, and returns the location.
   * Update an existing location.
   */
  async updateLocationRaw(
    requestParameters: UpdateLocationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<LocationDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/locations`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: LocationDTOToJSON(requestParameters.locationDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => LocationDTOFromJSON(jsonValue));
  }

  /**
   * Takes an existing location object, updates it, and returns the location.
   * Update an existing location.
   */
  async updateLocation(
    requestParameters: UpdateLocationRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<LocationDTO> {
    const response = await this.updateLocationRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

/**
 * @export
 */
export const ListLocationsStatusEnum = {
  Active: "ACTIVE",
  All: "ALL",
  Inactive: "INACTIVE"
} as const;
export type ListLocationsStatusEnum = (typeof ListLocationsStatusEnum)[keyof typeof ListLocationsStatusEnum];
/**
 * @export
 */
export const ListLocationsItemsPaginatedSortFieldEnum = {
  Capacity: "CAPACITY",
  Code: "CODE",
  Level: "LEVEL",
  Positionx: "POSITIONX",
  Positionz: "POSITIONZ",
  Status: "STATUS",
  Zone: "ZONE"
} as const;
export type ListLocationsItemsPaginatedSortFieldEnum =
  (typeof ListLocationsItemsPaginatedSortFieldEnum)[keyof typeof ListLocationsItemsPaginatedSortFieldEnum];
/**
 * @export
 */
export const ListLocationsItemsPaginatedSortDirectionEnum = {
  Asc: "ASC",
  Desc: "DESC"
} as const;
export type ListLocationsItemsPaginatedSortDirectionEnum =
  (typeof ListLocationsItemsPaginatedSortDirectionEnum)[keyof typeof ListLocationsItemsPaginatedSortDirectionEnum];
/**
 * @export
 */
export const ListLocationsPaginatedSortFieldEnum = {
  Capacity: "CAPACITY",
  Code: "CODE",
  Level: "LEVEL",
  Positionx: "POSITIONX",
  Positionz: "POSITIONZ",
  Status: "STATUS",
  Zone: "ZONE"
} as const;
export type ListLocationsPaginatedSortFieldEnum =
  (typeof ListLocationsPaginatedSortFieldEnum)[keyof typeof ListLocationsPaginatedSortFieldEnum];
/**
 * @export
 */
export const ListLocationsPaginatedSortDirectionEnum = {
  Asc: "ASC",
  Desc: "DESC"
} as const;
export type ListLocationsPaginatedSortDirectionEnum =
  (typeof ListLocationsPaginatedSortDirectionEnum)[keyof typeof ListLocationsPaginatedSortDirectionEnum];
