import { useHandleSelectedWarehouse } from "@hooks/useHandleSelectedWarehouse";
import { useHomeSummaryStock } from "./useHomeSummaryStock";
import { useHomeLastMovements } from "./useHomeLastMovements";
import { useGraphicMovementsData } from "./useGraphicMovementsData";
import { useHomeHowMayUsers } from "./useHomeHowMayUsers";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const useHomeViewModel = () => {
  const { pathname } = useLocation();

  const minLimitValue = 1;
  const maxLimitValue = 10000;
  const minOffsetValue = 0;

  const { selectedWarehouse, selectedWarehouseCode } = useHandleSelectedWarehouse();
  const [isDashboardInitialized, setIsDashboardInitialized] = useState(false);

  //Home Summary Stock Data
  const {
    calculateWarehouseSummaryStock,
    totalMaterials,
    totalFamilies,
    totalStockValue,
    totalStockValueLoading,
    inventoryReliabilityResult,
    inventoryReliabilityLoading,
    totalOutboundsMoves,
    stockCoverageResult,
    resetAllSummaryStock,
    updateReliability,
    updateTotalStockValue
  } = useHomeSummaryStock(isDashboardInitialized, minLimitValue, maxLimitValue, minOffsetValue, selectedWarehouseCode);

  //Home Last Movements Data
  const { calculateWarehouseLastMovements, lastInboundsMovements, lastOutboundsMovements, resetAllMovements } =
    useHomeLastMovements(selectedWarehouseCode);

  //[GRAPHIC] Last week movements done by each user last week
  const { calculateWarehouseLastWeekMovements, lastWeekMovementsData, graphicData, resetAllGraphics } = useGraphicMovementsData(
    maxLimitValue,
    selectedWarehouseCode
  );

  //Home how many users are logged in Aggrego
  const { calculateCompanyUsersConnected, portalSessionsAmount, mobilitySessionsAmount, resetAllUsers } = useHomeHowMayUsers();

  const dashboardInitiator = () => {
    setIsDashboardInitialized(true);
    calculateWarehouseSummaryStock();
    calculateWarehouseLastMovements();
    calculateWarehouseLastWeekMovements();
    calculateCompanyUsersConnected();
  };

  useEffect(() => {
    setIsDashboardInitialized(false);
    resetAllSummaryStock();
    resetAllMovements();
    resetAllGraphics();
    resetAllUsers();
  }, [pathname]);

  return {
    isDashboardInitialized,
    dashboardInitiator,
    selectedWarehouse,
    totalMaterials,
    totalFamilies,
    totalStockValue,
    totalStockValueLoading,
    inventoryReliabilityResult,
    inventoryReliabilityLoading,
    totalOutboundsMoves,
    stockCoverageResult,
    lastInboundsMovements,
    lastOutboundsMovements,
    lastWeekMovementsData,
    graphicData,
    portalSessionsAmount,
    mobilitySessionsAmount,
    updateReliability,
    updateTotalStockValue
  };
};
