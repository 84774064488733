import FamilyType from "@domain/model/FamilyType";
import { CREATE_ID, TIMEOUT_ID } from "@constants";
import { LastPageState } from "@domain/model/LastPageState";
import MasterDataConstants from "../../../MasterDataConstants";
import { ILastPageService } from "@domain/services/ILastPageService";
import { useMasterDataMessagges } from "@hooks/useMasterDataMessages";
import { ListFamilyTypesPaginatedRequest } from "@clients/aggrego-proxy";

export const useTypeOfFamiliesSave = (
  currentPageService: ILastPageService,
  currentPagePersistedState: LastPageState,
  selectedTypeOfFamily: FamilyType,
  fetchOne: (id: string) => Promise<void>,
  save: (entity: FamilyType) => Promise<void>,
  goToList: (criteria?: ListFamilyTypesPaginatedRequest) => Promise<void>,
  getCurrentStatus: (status: "ACTIVE" | "INACTIVE" | "ALL") => boolean | undefined
) => {
  const { successMessaggesSaving, errorMessaggesSaving, errorMessaggesLoading } = useMasterDataMessagges();

  const handleEdit = (id: string) => {
    fetchOne(id).catch(() => {
      errorMessaggesLoading("typeoffamily", selectedTypeOfFamily.name);
      selectedTypeOfFamily.id = TIMEOUT_ID;
    });
  };
  const handleSave = (typeOfFamily: FamilyType): void => {
    if (typeOfFamily.id === CREATE_ID) typeOfFamily.active = true;
    save(typeOfFamily)
      .then(() => successMessaggesSaving("typeoffamily", typeOfFamily.name, typeOfFamily.id))
      .catch(error => errorMessaggesSaving("typeoffamily", typeOfFamily.name, typeOfFamily.id, error))
      .finally(() => {
        goToList({
          limit: MasterDataConstants.ITEMS_PER_PAGE,
          offset: currentPageService.persistedState?.offset ?? 0,
          basicFilterDTO: {
            status: getCurrentStatus(currentPagePersistedState.status),
            ...currentPagePersistedState.filters
          }
        });
      });
  };

  return {
    handleSave,
    handleEdit
  };
};
