import { MultimediaApi } from "../api/multimedia-api";
import { DeleteMultimediaRequest, GetAllMultimediaRequest, MultimediaDTO, UpdateMultimediaRequest } from "@clients/aggrego-proxy";

export class MediaRepository {
  constructor(private readonly mediaApi: MultimediaApi) {}

  get(criteria: GetAllMultimediaRequest): Promise<MultimediaDTO[]> {
    return this.mediaApi.get(criteria);
  }

  getById(id: string): Promise<MultimediaDTO> {
    return this.mediaApi.getById(id);
  }

  async upload(entity: Blob): Promise<MultimediaDTO> {
    return await this.mediaApi.upload(entity);
  }

  async uploadExternal(entity: MultimediaDTO): Promise<MultimediaDTO> {
    return await this.mediaApi.uploadExternal(entity);
  }

  async update(criteria: UpdateMultimediaRequest): Promise<MultimediaDTO> {
    return await this.mediaApi.update(criteria);
  }

  async delete(criteria: DeleteMultimediaRequest): Promise<void> {
    await this.mediaApi.delete(criteria);
  }
}
