/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConsumeResponseDTO
 */
export interface ConsumeResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof ConsumeResponseDTO
     */
    consumedMaterial?: number;
    /**
     * 
     * @type {string}
     * @memberof ConsumeResponseDTO
     */
    containerCode?: string;
    /**
     * 
     * @type {number}
     * @memberof ConsumeResponseDTO
     */
    containerQty?: number;
    /**
     * 
     * @type {string}
     * @memberof ConsumeResponseDTO
     */
    locationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumeResponseDTO
     */
    materialCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumeResponseDTO
     */
    warning?: string;
}

/**
 * Check if a given object implements the ConsumeResponseDTO interface.
 */
export function instanceOfConsumeResponseDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConsumeResponseDTOFromJSON(json: any): ConsumeResponseDTO {
    return ConsumeResponseDTOFromJSONTyped(json, false);
}

export function ConsumeResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsumeResponseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'consumedMaterial': !exists(json, 'consumedMaterial') ? undefined : json['consumedMaterial'],
        'containerCode': !exists(json, 'containerCode') ? undefined : json['containerCode'],
        'containerQty': !exists(json, 'containerQty') ? undefined : json['containerQty'],
        'locationCode': !exists(json, 'locationCode') ? undefined : json['locationCode'],
        'materialCode': !exists(json, 'materialCode') ? undefined : json['materialCode'],
        'warning': !exists(json, 'warning') ? undefined : json['warning'],
    };
}

export function ConsumeResponseDTOToJSON(value?: ConsumeResponseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'consumedMaterial': value.consumedMaterial,
        'containerCode': value.containerCode,
        'containerQty': value.containerQty,
        'locationCode': value.locationCode,
        'materialCode': value.materialCode,
        'warning': value.warning,
    };
}

