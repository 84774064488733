import { User } from "../model/User";

export default interface IUser {
  active: boolean;
  validated: UserValidatedEnum;
  email: string;
  firstName: string;
  firstSurname: string;
  secondSurname: string;
  username: string;
  password: string;
  token: string;
  id?: string;
  credentialsId: string;
  profileId: string;
  preferences: string;
  roles: TUserRoleEnum[];
  tenantId: string;
}

export interface IRole {
  id: string;
  name: string;
}

export const UserRoleEnum = {
  MobUser: "MOB_USER",
  MobAdmin: "MOB_ADMIN",
  PortalUser: "PORTAL_USER",
  PortalAdmin: "PORTAL_ADMIN",
  SuperAdmin: "SUPER_ADMIN"
} as const;
export type TUserRoleEnum = (typeof UserRoleEnum)[keyof typeof UserRoleEnum];

export const ALL_ROLES: TUserRoleEnum[] = [
  UserRoleEnum.SuperAdmin,
  UserRoleEnum.PortalAdmin,
  UserRoleEnum.MobAdmin,
  UserRoleEnum.PortalUser,
  UserRoleEnum.MobUser
];

export interface IUserPageCriteria {
  limit: number;
  offset: number;
}

export const UserValidatedEnum = {
  Accepted: "ACCEPTED",
  Denied: "DENIED",
  Requested: "REQUESTED"
} as const;

export const modelToInterface = (user: User) => {
  const iUser = {} as IUser;
  iUser.id = user.id;
  iUser.active = user.active;
  iUser.validated = user.validated;
  iUser.email = user.email;
  iUser.username = user.username;
  iUser.token = user.token;
  iUser.credentialsId = user.credentialsId;
  iUser.profileId = user.profileId;
  iUser.preferences = user.preferences;
  iUser.roles = user.roles;
  iUser.tenantId = user.tenantId;
  iUser.password = user.password;
  iUser.firstName = user.firstName;
  iUser.firstSurname = user.firstSurname;
  iUser.secondSurname = user.secondSurname;

  return iUser;
};

export const interfaceToModel = (iUser: IUser) => {
  const user = new User();
  user.id = iUser.id;
  user.active = iUser.active;
  user.validated = iUser.validated;
  user.email = iUser.email;
  user.username = iUser.username;
  user.token = iUser.token;
  user.credentialsId = iUser.credentialsId;
  user.profileId = iUser.profileId;
  user.preferences = iUser.preferences;
  user.roles = iUser.roles;
  user.tenantId = iUser.tenantId;
  user.password = iUser.password;
  user.firstName = iUser.firstName;
  user.firstSurname = iUser.firstSurname;
  user.secondSurname = iUser.secondSurname;

  return user;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type UserValidatedEnum = (typeof UserValidatedEnum)[keyof typeof UserValidatedEnum];
