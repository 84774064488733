import { COLORS } from "@theme";
import Icon from "../../icons/icon";
import { Dialog as MUIDialog, DialogTitle, DialogContent, IconButton, Breakpoint } from "@mui/material";

export const AggregoDialog = function (props: {
  title: string;
  children: JSX.Element;
  onClose: () => void;
  maxWidth?: Breakpoint;
}) {
  const { title, children, onClose, maxWidth } = props;

  return (
    <MUIDialog open id="mui-dialog" maxWidth={maxWidth ?? "sm"} className="save-dialog" onClose={onClose}>
      <DialogTitle>
        <div id="dialog-title-name" style={{ textOverflow: "ellipsis", fontSize: "1.125rem", overflow: "hidden" }}>
          {title}
        </div>
        <IconButton id="close-dialog" onClick={onClose}>
          <Icon icon="close" color={COLORS.white} />
        </IconButton>
      </DialogTitle>
      <DialogContent id="dialog-content">{children}</DialogContent>
    </MUIDialog>
  );
};
