import { TUserRoleEnum } from "@domain/interface/User";
import { DrawerState } from "@domain/model/DrawerState";
import { IUserService } from "@domain/services/IUserService";
import { IDrawerService } from "@domain/services/IDrawerService";
import { WarehouseApi } from "@infrastructure/api/warehouse-api";
import { IImpersonationService } from "@domain/services/IImpersonationService";

export class WarehouseByTenantUseCase {
  constructor(
    private readonly warehouseApi: WarehouseApi,
    private readonly userService: IUserService,
    private readonly drawerService: IDrawerService,
    private readonly impersonationService: IImpersonationService,
    private readonly persistedState = drawerService.getPersistedState() ?? new DrawerState()
  ) {}

  public get(): void {
    const isRole = (role: TUserRoleEnum) => {
      return this.userService.user?.roles.includes(role);
    };

    const currentTenant = isRole("SUPER_ADMIN")
      ? this.impersonationService.persistedState?.impersonatedTenant ?? ""
      : this.userService.user?.tenantId ?? "";

    this.warehouseApi.getByTenantId({ tenantId: currentTenant, fullDTO: false, body: true }).then(result => {
      const firstWarehouseId =
        this.persistedState.selectedWarehouse === ""
          ? (result[0] && result[0].id) ?? ""
          : (this.persistedState.selectedWarehouse && this.persistedState.selectedWarehouse) ?? "";
      this.drawerService.saveDrawerState({
        ...this.persistedState,
        warehouses: result,
        selectedWarehouse: firstWarehouseId ? firstWarehouseId : ""
      });
    });
  }

  public clear(): void {
    this.drawerService.saveDrawerState({ ...this.persistedState, warehouses: [], selectedWarehouse: "" });
  }
}
