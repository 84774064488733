/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  ChildDTO,
  ContainerWithProductDTO,
  HistoricalEventDTO,
  HistoricalEventRequestDTO,
  MovicodersBadRequestException,
  MovicodersNotFoundException,
  MovicodersServiceException,
  ProductDTO,
  ProductPageDTO,
  ProductsBasicFilterDTO
} from "../models";
import {
  ChildDTOFromJSON,
  ChildDTOToJSON,
  ContainerWithProductDTOFromJSON,
  ContainerWithProductDTOToJSON,
  HistoricalEventDTOFromJSON,
  HistoricalEventDTOToJSON,
  HistoricalEventRequestDTOFromJSON,
  HistoricalEventRequestDTOToJSON,
  MovicodersBadRequestExceptionFromJSON,
  MovicodersBadRequestExceptionToJSON,
  MovicodersNotFoundExceptionFromJSON,
  MovicodersNotFoundExceptionToJSON,
  MovicodersServiceExceptionFromJSON,
  MovicodersServiceExceptionToJSON,
  ProductDTOFromJSON,
  ProductDTOToJSON,
  ProductPageDTOFromJSON,
  ProductPageDTOToJSON,
  ProductsBasicFilterDTOFromJSON,
  ProductsBasicFilterDTOToJSON
} from "../models";

export interface AddChildrenToProductRequest {
  id: string;
  xTenantId?: string;
  childDTO?: Array<ChildDTO>;
}

export interface AddHistoricalEventRequest {
  xTenantId?: string;
  historicalEventRequestDTO?: HistoricalEventRequestDTO;
}

export interface AddProductToContainerRequest {
  xTenantId?: string;
  containerCode?: string;
  productDTO?: ProductDTO;
}

export interface DeleteProductRequest {
  id: string;
  xTenantId?: string;
}

export interface DeleteProductsRequest {
  xTenantId?: string;
  requestBody?: Array<string>;
}

export interface GetChildrenByBatchAndMaterialCodeRequest {
  batch: string;
  materialCode: string;
  sortField: GetChildrenByBatchAndMaterialCodeSortFieldEnum;
  sortDirection: GetChildrenByBatchAndMaterialCodeSortDirectionEnum;
  xTenantId?: string;
}

export interface GetChildrenBySerialNumberRequest {
  serialNumber: string;
  sortField: GetChildrenBySerialNumberSortFieldEnum;
  sortDirection: GetChildrenBySerialNumberSortDirectionEnum;
  xTenantId?: string;
}

export interface GetParentsByBatchAndMaterialCodeRequest {
  batch: string;
  materialCode: string;
  sortField: GetParentsByBatchAndMaterialCodeSortFieldEnum;
  sortDirection: GetParentsByBatchAndMaterialCodeSortDirectionEnum;
  xTenantId?: string;
}

export interface GetParentsBySerialNumberAndMaterialCodeRequest {
  serialNumber: string;
  materialCode: string;
  sortField: GetParentsBySerialNumberAndMaterialCodeSortFieldEnum;
  sortDirection: GetParentsBySerialNumberAndMaterialCodeSortDirectionEnum;
  xTenantId?: string;
}

export interface GetProductByBatchAndMaterialCodeRequest {
  batch: string;
  materialCode: string;
  sortField: GetProductByBatchAndMaterialCodeSortFieldEnum;
  sortDirection: GetProductByBatchAndMaterialCodeSortDirectionEnum;
  xTenantId?: string;
  limit?: number;
  offset?: number;
  productsBasicFilterDTO?: ProductsBasicFilterDTO;
}

export interface GetProductByIdRequest {
  id: string;
  xTenantId?: string;
}

export interface GetProductByIdsRequest {
  xTenantId?: string;
  requestBody?: Array<string>;
}

export interface GetProductBySerialNumberRequest {
  serialNumber: string;
  sortField: GetProductBySerialNumberSortFieldEnum;
  sortDirection: GetProductBySerialNumberSortDirectionEnum;
  xTenantId?: string;
  limit?: number;
  offset?: number;
  productsBasicFilterDTO?: ProductsBasicFilterDTO;
}

export interface GetProductHierarchyByBatchAndMaterialCodeRequest {
  batch: string;
  materialCode: string;
  onlyParent: boolean;
  sortField: GetProductHierarchyByBatchAndMaterialCodeSortFieldEnum;
  sortDirection: GetProductHierarchyByBatchAndMaterialCodeSortDirectionEnum;
  xTenantId?: string;
  limit?: number;
  offset?: number;
}

export interface ListProductsRequest {
  xTenantId?: string;
}

export interface ListProductsPaginatedRequest {
  sortField: ListProductsPaginatedSortFieldEnum;
  sortDirection: ListProductsPaginatedSortDirectionEnum;
  limit: number;
  offset: number;
  xTenantId?: string;
  productsBasicFilterDTO?: ProductsBasicFilterDTO;
}

export interface RemoveChildrenFromParentRequest {
  id: string;
  xTenantId?: string;
  childDTO?: Array<ChildDTO>;
}

export interface SaveProductRequest {
  xTenantId?: string;
  productDTO?: ProductDTO;
}

export interface SaveProductsRequest {
  xTenantId?: string;
  productDTO?: Array<ProductDTO>;
}

export interface UpdateProductRequest {
  xTenantId?: string;
  productDTO?: ProductDTO;
}

/**
 *
 */
export class ProductsApi extends runtime.BaseAPI {
  /**
   * Add children to product
   */
  async addChildrenToProductRaw(
    requestParameters: AddChildrenToProductRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling addChildrenToProduct."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.id !== undefined) {
      queryParameters["id"] = requestParameters.id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/products/add-children`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.childDTO?.map(ChildDTOToJSON)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ProductDTOFromJSON(jsonValue));
  }

  /**
   * Add children to product
   */
  async addChildrenToProduct(
    requestParameters: AddChildrenToProductRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductDTO> {
    const response = await this.addChildrenToProductRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the added historical event
   * Add an historical event for a product
   */
  async addHistoricalEventRaw(
    requestParameters: AddHistoricalEventRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<HistoricalEventDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/products/historical-event`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: HistoricalEventRequestDTOToJSON(requestParameters.historicalEventRequestDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => HistoricalEventDTOFromJSON(jsonValue));
  }

  /**
   * Returns the added historical event
   * Add an historical event for a product
   */
  async addHistoricalEvent(
    requestParameters: AddHistoricalEventRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<HistoricalEventDTO> {
    const response = await this.addHistoricalEventRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Add product to container and returns an ContainerWithProductDTO object
   */
  async addProductToContainerRaw(
    requestParameters: AddProductToContainerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ContainerWithProductDTO>> {
    const queryParameters: any = {};

    if (requestParameters.containerCode !== undefined) {
      queryParameters["containerCode"] = requestParameters.containerCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/products/container`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ProductDTOToJSON(requestParameters.productDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ContainerWithProductDTOFromJSON(jsonValue));
  }

  /**
   * Add product to container and returns an ContainerWithProductDTO object
   */
  async addProductToContainer(
    requestParameters: AddProductToContainerRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ContainerWithProductDTO> {
    const response = await this.addProductToContainerRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Takes an existing product, deletes it, and returns the new object.
   * Delete an existing product by id.
   */
  async deleteProductRaw(
    requestParameters: DeleteProductRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteProduct."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.id !== undefined) {
      queryParameters["id"] = requestParameters.id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/products/id`,
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ProductDTOFromJSON(jsonValue));
  }

  /**
   * Takes an existing product, deletes it, and returns the new object.
   * Delete an existing product by id.
   */
  async deleteProduct(
    requestParameters: DeleteProductRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductDTO> {
    const response = await this.deleteProductRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the deleted Products
   */
  async deleteProductsRaw(
    requestParameters: DeleteProductsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<ProductDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/products/delete-many`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(ProductDTOFromJSON));
  }

  /**
   * Returns the deleted Products
   */
  async deleteProducts(
    requestParameters: DeleteProductsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<ProductDTO>> {
    const response = await this.deleteProductsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns children of the product or products with the given batch and material code.
   * Get the children of a product or products searched total o partially by batch and material code with pagination.
   */
  async getChildrenByBatchAndMaterialCodeRaw(
    requestParameters: GetChildrenByBatchAndMaterialCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductPageDTO>> {
    if (requestParameters.batch === null || requestParameters.batch === undefined) {
      throw new runtime.RequiredError(
        "batch",
        "Required parameter requestParameters.batch was null or undefined when calling getChildrenByBatchAndMaterialCode."
      );
    }

    if (requestParameters.materialCode === null || requestParameters.materialCode === undefined) {
      throw new runtime.RequiredError(
        "materialCode",
        "Required parameter requestParameters.materialCode was null or undefined when calling getChildrenByBatchAndMaterialCode."
      );
    }

    if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
      throw new runtime.RequiredError(
        "sortField",
        "Required parameter requestParameters.sortField was null or undefined when calling getChildrenByBatchAndMaterialCode."
      );
    }

    if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
      throw new runtime.RequiredError(
        "sortDirection",
        "Required parameter requestParameters.sortDirection was null or undefined when calling getChildrenByBatchAndMaterialCode."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.batch !== undefined) {
      queryParameters["batch"] = requestParameters.batch;
    }

    if (requestParameters.materialCode !== undefined) {
      queryParameters["materialCode"] = requestParameters.materialCode;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sortField"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sortDirection"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/products/children/batch/materialCode`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ProductPageDTOFromJSON(jsonValue));
  }

  /**
   * Returns children of the product or products with the given batch and material code.
   * Get the children of a product or products searched total o partially by batch and material code with pagination.
   */
  async getChildrenByBatchAndMaterialCode(
    requestParameters: GetChildrenByBatchAndMaterialCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductPageDTO> {
    const response = await this.getChildrenByBatchAndMaterialCodeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns children of the product or products with the given serial number.
   * Get the children of a product or products searched total o partially by serial number with pagination.
   */
  async getChildrenBySerialNumberRaw(
    requestParameters: GetChildrenBySerialNumberRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductPageDTO>> {
    if (requestParameters.serialNumber === null || requestParameters.serialNumber === undefined) {
      throw new runtime.RequiredError(
        "serialNumber",
        "Required parameter requestParameters.serialNumber was null or undefined when calling getChildrenBySerialNumber."
      );
    }

    if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
      throw new runtime.RequiredError(
        "sortField",
        "Required parameter requestParameters.sortField was null or undefined when calling getChildrenBySerialNumber."
      );
    }

    if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
      throw new runtime.RequiredError(
        "sortDirection",
        "Required parameter requestParameters.sortDirection was null or undefined when calling getChildrenBySerialNumber."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.serialNumber !== undefined) {
      queryParameters["serialNumber"] = requestParameters.serialNumber;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sortField"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sortDirection"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/products/children/serialNumber`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ProductPageDTOFromJSON(jsonValue));
  }

  /**
   * Returns children of the product or products with the given serial number.
   * Get the children of a product or products searched total o partially by serial number with pagination.
   */
  async getChildrenBySerialNumber(
    requestParameters: GetChildrenBySerialNumberRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductPageDTO> {
    const response = await this.getChildrenBySerialNumberRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns a list of parents provided the batch and material code.
   * Get parents for an object provided the batch and material code.
   */
  async getParentsByBatchAndMaterialCodeRaw(
    requestParameters: GetParentsByBatchAndMaterialCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductPageDTO>> {
    if (requestParameters.batch === null || requestParameters.batch === undefined) {
      throw new runtime.RequiredError(
        "batch",
        "Required parameter requestParameters.batch was null or undefined when calling getParentsByBatchAndMaterialCode."
      );
    }

    if (requestParameters.materialCode === null || requestParameters.materialCode === undefined) {
      throw new runtime.RequiredError(
        "materialCode",
        "Required parameter requestParameters.materialCode was null or undefined when calling getParentsByBatchAndMaterialCode."
      );
    }

    if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
      throw new runtime.RequiredError(
        "sortField",
        "Required parameter requestParameters.sortField was null or undefined when calling getParentsByBatchAndMaterialCode."
      );
    }

    if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
      throw new runtime.RequiredError(
        "sortDirection",
        "Required parameter requestParameters.sortDirection was null or undefined when calling getParentsByBatchAndMaterialCode."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.batch !== undefined) {
      queryParameters["batch"] = requestParameters.batch;
    }

    if (requestParameters.materialCode !== undefined) {
      queryParameters["materialCode"] = requestParameters.materialCode;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sortField"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sortDirection"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/products/parents/batch/materialCode`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ProductPageDTOFromJSON(jsonValue));
  }

  /**
   * Returns a list of parents provided the batch and material code.
   * Get parents for an object provided the batch and material code.
   */
  async getParentsByBatchAndMaterialCode(
    requestParameters: GetParentsByBatchAndMaterialCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductPageDTO> {
    const response = await this.getParentsByBatchAndMaterialCodeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns a list of parents provided the serial number of a product and material code
   * Get parents for an object provided the serial number and material code.
   */
  async getParentsBySerialNumberAndMaterialCodeRaw(
    requestParameters: GetParentsBySerialNumberAndMaterialCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductPageDTO>> {
    if (requestParameters.serialNumber === null || requestParameters.serialNumber === undefined) {
      throw new runtime.RequiredError(
        "serialNumber",
        "Required parameter requestParameters.serialNumber was null or undefined when calling getParentsBySerialNumberAndMaterialCode."
      );
    }

    if (requestParameters.materialCode === null || requestParameters.materialCode === undefined) {
      throw new runtime.RequiredError(
        "materialCode",
        "Required parameter requestParameters.materialCode was null or undefined when calling getParentsBySerialNumberAndMaterialCode."
      );
    }

    if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
      throw new runtime.RequiredError(
        "sortField",
        "Required parameter requestParameters.sortField was null or undefined when calling getParentsBySerialNumberAndMaterialCode."
      );
    }

    if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
      throw new runtime.RequiredError(
        "sortDirection",
        "Required parameter requestParameters.sortDirection was null or undefined when calling getParentsBySerialNumberAndMaterialCode."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.serialNumber !== undefined) {
      queryParameters["serialNumber"] = requestParameters.serialNumber;
    }

    if (requestParameters.materialCode !== undefined) {
      queryParameters["materialCode"] = requestParameters.materialCode;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sortField"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sortDirection"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/products/parents/serialNumber/materialCode`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ProductPageDTOFromJSON(jsonValue));
  }

  /**
   * Returns a list of parents provided the serial number of a product and material code
   * Get parents for an object provided the serial number and material code.
   */
  async getParentsBySerialNumberAndMaterialCode(
    requestParameters: GetParentsBySerialNumberAndMaterialCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductPageDTO> {
    const response = await this.getParentsBySerialNumberAndMaterialCodeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns one product object by batch and material code
   * Get a product object by batch and material code.
   */
  async getProductByBatchAndMaterialCodeRaw(
    requestParameters: GetProductByBatchAndMaterialCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductPageDTO>> {
    if (requestParameters.batch === null || requestParameters.batch === undefined) {
      throw new runtime.RequiredError(
        "batch",
        "Required parameter requestParameters.batch was null or undefined when calling getProductByBatchAndMaterialCode."
      );
    }

    if (requestParameters.materialCode === null || requestParameters.materialCode === undefined) {
      throw new runtime.RequiredError(
        "materialCode",
        "Required parameter requestParameters.materialCode was null or undefined when calling getProductByBatchAndMaterialCode."
      );
    }

    if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
      throw new runtime.RequiredError(
        "sortField",
        "Required parameter requestParameters.sortField was null or undefined when calling getProductByBatchAndMaterialCode."
      );
    }

    if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
      throw new runtime.RequiredError(
        "sortDirection",
        "Required parameter requestParameters.sortDirection was null or undefined when calling getProductByBatchAndMaterialCode."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.batch !== undefined) {
      queryParameters["batch"] = requestParameters.batch;
    }

    if (requestParameters.materialCode !== undefined) {
      queryParameters["materialCode"] = requestParameters.materialCode;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sortField"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sortDirection"] = requestParameters.sortDirection;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/products/batch/materialCode`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ProductsBasicFilterDTOToJSON(requestParameters.productsBasicFilterDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ProductPageDTOFromJSON(jsonValue));
  }

  /**
   * Returns one product object by batch and material code
   * Get a product object by batch and material code.
   */
  async getProductByBatchAndMaterialCode(
    requestParameters: GetProductByBatchAndMaterialCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductPageDTO> {
    const response = await this.getProductByBatchAndMaterialCodeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns one product object by id.
   * Get a product object by id.
   */
  async getProductByIdRaw(
    requestParameters: GetProductByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getProductById."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.id !== undefined) {
      queryParameters["id"] = requestParameters.id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/products/id`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ProductDTOFromJSON(jsonValue));
  }

  /**
   * Returns one product object by id.
   * Get a product object by id.
   */
  async getProductById(
    requestParameters: GetProductByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductDTO> {
    const response = await this.getProductByIdRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns product objects by ids.
   * Get a product object by id.
   */
  async getProductByIdsRaw(
    requestParameters: GetProductByIdsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<ProductDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/products/find-many-by-id`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(ProductDTOFromJSON));
  }

  /**
   * Returns product objects by ids.
   * Get a product object by id.
   */
  async getProductByIds(
    requestParameters: GetProductByIdsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<ProductDTO>> {
    const response = await this.getProductByIdsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns one product object by serial number.
   * Get a product object by serial number.
   */
  async getProductBySerialNumberRaw(
    requestParameters: GetProductBySerialNumberRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductPageDTO>> {
    if (requestParameters.serialNumber === null || requestParameters.serialNumber === undefined) {
      throw new runtime.RequiredError(
        "serialNumber",
        "Required parameter requestParameters.serialNumber was null or undefined when calling getProductBySerialNumber."
      );
    }

    if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
      throw new runtime.RequiredError(
        "sortField",
        "Required parameter requestParameters.sortField was null or undefined when calling getProductBySerialNumber."
      );
    }

    if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
      throw new runtime.RequiredError(
        "sortDirection",
        "Required parameter requestParameters.sortDirection was null or undefined when calling getProductBySerialNumber."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.serialNumber !== undefined) {
      queryParameters["serialNumber"] = requestParameters.serialNumber;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sortField"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sortDirection"] = requestParameters.sortDirection;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/products/serialNumber`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ProductsBasicFilterDTOToJSON(requestParameters.productsBasicFilterDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ProductPageDTOFromJSON(jsonValue));
  }

  /**
   * Returns one product object by serial number.
   * Get a product object by serial number.
   */
  async getProductBySerialNumber(
    requestParameters: GetProductBySerialNumberRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductPageDTO> {
    const response = await this.getProductBySerialNumberRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns one product object with hierarchy by batch and material code
   * Get a product hierarchy object by batch and material code.
   */
  async getProductHierarchyByBatchAndMaterialCodeRaw(
    requestParameters: GetProductHierarchyByBatchAndMaterialCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductPageDTO>> {
    if (requestParameters.batch === null || requestParameters.batch === undefined) {
      throw new runtime.RequiredError(
        "batch",
        "Required parameter requestParameters.batch was null or undefined when calling getProductHierarchyByBatchAndMaterialCode."
      );
    }

    if (requestParameters.materialCode === null || requestParameters.materialCode === undefined) {
      throw new runtime.RequiredError(
        "materialCode",
        "Required parameter requestParameters.materialCode was null or undefined when calling getProductHierarchyByBatchAndMaterialCode."
      );
    }

    if (requestParameters.onlyParent === null || requestParameters.onlyParent === undefined) {
      throw new runtime.RequiredError(
        "onlyParent",
        "Required parameter requestParameters.onlyParent was null or undefined when calling getProductHierarchyByBatchAndMaterialCode."
      );
    }

    if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
      throw new runtime.RequiredError(
        "sortField",
        "Required parameter requestParameters.sortField was null or undefined when calling getProductHierarchyByBatchAndMaterialCode."
      );
    }

    if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
      throw new runtime.RequiredError(
        "sortDirection",
        "Required parameter requestParameters.sortDirection was null or undefined when calling getProductHierarchyByBatchAndMaterialCode."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.batch !== undefined) {
      queryParameters["batch"] = requestParameters.batch;
    }

    if (requestParameters.materialCode !== undefined) {
      queryParameters["materialCode"] = requestParameters.materialCode;
    }

    if (requestParameters.onlyParent !== undefined) {
      queryParameters["onlyParent"] = requestParameters.onlyParent;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sortField"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sortDirection"] = requestParameters.sortDirection;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/products/batch/materialCodeWithHierarchy`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ProductPageDTOFromJSON(jsonValue));
  }

  /**
   * Returns one product object with hierarchy by batch and material code
   * Get a product hierarchy object by batch and material code.
   */
  async getProductHierarchyByBatchAndMaterialCode(
    requestParameters: GetProductHierarchyByBatchAndMaterialCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductPageDTO> {
    const response = await this.getProductHierarchyByBatchAndMaterialCodeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the list of all registered Products
   */
  async listProductsRaw(
    requestParameters: ListProductsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<ProductDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/products`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(ProductDTOFromJSON));
  }

  /**
   * Returns the list of all registered Products
   */
  async listProducts(
    requestParameters: ListProductsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<ProductDTO>> {
    const response = await this.listProductsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the list of all registered Products paginated
   */
  async listProductsPaginatedRaw(
    requestParameters: ListProductsPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductPageDTO>> {
    if (requestParameters.sortField === null || requestParameters.sortField === undefined) {
      throw new runtime.RequiredError(
        "sortField",
        "Required parameter requestParameters.sortField was null or undefined when calling listProductsPaginated."
      );
    }

    if (requestParameters.sortDirection === null || requestParameters.sortDirection === undefined) {
      throw new runtime.RequiredError(
        "sortDirection",
        "Required parameter requestParameters.sortDirection was null or undefined when calling listProductsPaginated."
      );
    }

    if (requestParameters.limit === null || requestParameters.limit === undefined) {
      throw new runtime.RequiredError(
        "limit",
        "Required parameter requestParameters.limit was null or undefined when calling listProductsPaginated."
      );
    }

    if (requestParameters.offset === null || requestParameters.offset === undefined) {
      throw new runtime.RequiredError(
        "offset",
        "Required parameter requestParameters.offset was null or undefined when calling listProductsPaginated."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.sortField !== undefined) {
      queryParameters["sortField"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sortDirection"] = requestParameters.sortDirection;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/products/paginated`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ProductsBasicFilterDTOToJSON(requestParameters.productsBasicFilterDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ProductPageDTOFromJSON(jsonValue));
  }

  /**
   * Returns the list of all registered Products paginated
   */
  async listProductsPaginated(
    requestParameters: ListProductsPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductPageDTO> {
    const response = await this.listProductsPaginatedRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Remove children from product
   */
  async removeChildrenFromParentRaw(
    requestParameters: RemoveChildrenFromParentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling removeChildrenFromParent."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.id !== undefined) {
      queryParameters["id"] = requestParameters.id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/products/remove-children`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.childDTO?.map(ChildDTOToJSON)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ProductDTOFromJSON(jsonValue));
  }

  /**
   * Remove children from product
   */
  async removeChildrenFromParent(
    requestParameters: RemoveChildrenFromParentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductDTO> {
    const response = await this.removeChildrenFromParentRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the registered Product
   */
  async saveProductRaw(
    requestParameters: SaveProductRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/products`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ProductDTOToJSON(requestParameters.productDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ProductDTOFromJSON(jsonValue));
  }

  /**
   * Returns the registered Product
   */
  async saveProduct(
    requestParameters: SaveProductRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductDTO> {
    const response = await this.saveProductRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the registered Products
   */
  async saveProductsRaw(
    requestParameters: SaveProductsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<ProductDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/products/add-many`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.productDTO?.map(ProductDTOToJSON)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(ProductDTOFromJSON));
  }

  /**
   * Returns the registered Products
   */
  async saveProducts(
    requestParameters: SaveProductsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<ProductDTO>> {
    const response = await this.saveProductsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Takes an existing Product object, updates it, and returns the Product.
   * Update an existing Product.
   */
  async updateProductRaw(
    requestParameters: UpdateProductRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ProductDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/products`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: ProductDTOToJSON(requestParameters.productDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ProductDTOFromJSON(jsonValue));
  }

  /**
   * Takes an existing Product object, updates it, and returns the Product.
   * Update an existing Product.
   */
  async updateProduct(
    requestParameters: UpdateProductRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ProductDTO> {
    const response = await this.updateProductRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

/**
 * @export
 */
export const GetChildrenByBatchAndMaterialCodeSortFieldEnum = {
  Description: "DESCRIPTION",
  Manufacturingdate: "MANUFACTURINGDATE",
  State: "STATE"
} as const;
export type GetChildrenByBatchAndMaterialCodeSortFieldEnum =
  (typeof GetChildrenByBatchAndMaterialCodeSortFieldEnum)[keyof typeof GetChildrenByBatchAndMaterialCodeSortFieldEnum];
/**
 * @export
 */
export const GetChildrenByBatchAndMaterialCodeSortDirectionEnum = {
  Asc: "ASC",
  Desc: "DESC"
} as const;
export type GetChildrenByBatchAndMaterialCodeSortDirectionEnum =
  (typeof GetChildrenByBatchAndMaterialCodeSortDirectionEnum)[keyof typeof GetChildrenByBatchAndMaterialCodeSortDirectionEnum];
/**
 * @export
 */
export const GetChildrenBySerialNumberSortFieldEnum = {
  Description: "DESCRIPTION",
  Manufacturingdate: "MANUFACTURINGDATE",
  State: "STATE"
} as const;
export type GetChildrenBySerialNumberSortFieldEnum =
  (typeof GetChildrenBySerialNumberSortFieldEnum)[keyof typeof GetChildrenBySerialNumberSortFieldEnum];
/**
 * @export
 */
export const GetChildrenBySerialNumberSortDirectionEnum = {
  Asc: "ASC",
  Desc: "DESC"
} as const;
export type GetChildrenBySerialNumberSortDirectionEnum =
  (typeof GetChildrenBySerialNumberSortDirectionEnum)[keyof typeof GetChildrenBySerialNumberSortDirectionEnum];
/**
 * @export
 */
export const GetParentsByBatchAndMaterialCodeSortFieldEnum = {
  Description: "DESCRIPTION",
  Manufacturingdate: "MANUFACTURINGDATE",
  State: "STATE"
} as const;
export type GetParentsByBatchAndMaterialCodeSortFieldEnum =
  (typeof GetParentsByBatchAndMaterialCodeSortFieldEnum)[keyof typeof GetParentsByBatchAndMaterialCodeSortFieldEnum];
/**
 * @export
 */
export const GetParentsByBatchAndMaterialCodeSortDirectionEnum = {
  Asc: "ASC",
  Desc: "DESC"
} as const;
export type GetParentsByBatchAndMaterialCodeSortDirectionEnum =
  (typeof GetParentsByBatchAndMaterialCodeSortDirectionEnum)[keyof typeof GetParentsByBatchAndMaterialCodeSortDirectionEnum];
/**
 * @export
 */
export const GetParentsBySerialNumberAndMaterialCodeSortFieldEnum = {
  Description: "DESCRIPTION",
  Manufacturingdate: "MANUFACTURINGDATE",
  State: "STATE"
} as const;
export type GetParentsBySerialNumberAndMaterialCodeSortFieldEnum =
  (typeof GetParentsBySerialNumberAndMaterialCodeSortFieldEnum)[keyof typeof GetParentsBySerialNumberAndMaterialCodeSortFieldEnum];
/**
 * @export
 */
export const GetParentsBySerialNumberAndMaterialCodeSortDirectionEnum = {
  Asc: "ASC",
  Desc: "DESC"
} as const;
export type GetParentsBySerialNumberAndMaterialCodeSortDirectionEnum =
  (typeof GetParentsBySerialNumberAndMaterialCodeSortDirectionEnum)[keyof typeof GetParentsBySerialNumberAndMaterialCodeSortDirectionEnum];
/**
 * @export
 */
export const GetProductByBatchAndMaterialCodeSortFieldEnum = {
  Description: "DESCRIPTION",
  Manufacturingdate: "MANUFACTURINGDATE",
  State: "STATE"
} as const;
export type GetProductByBatchAndMaterialCodeSortFieldEnum =
  (typeof GetProductByBatchAndMaterialCodeSortFieldEnum)[keyof typeof GetProductByBatchAndMaterialCodeSortFieldEnum];
/**
 * @export
 */
export const GetProductByBatchAndMaterialCodeSortDirectionEnum = {
  Asc: "ASC",
  Desc: "DESC"
} as const;
export type GetProductByBatchAndMaterialCodeSortDirectionEnum =
  (typeof GetProductByBatchAndMaterialCodeSortDirectionEnum)[keyof typeof GetProductByBatchAndMaterialCodeSortDirectionEnum];
/**
 * @export
 */
export const GetProductBySerialNumberSortFieldEnum = {
  Description: "DESCRIPTION",
  Manufacturingdate: "MANUFACTURINGDATE",
  State: "STATE"
} as const;
export type GetProductBySerialNumberSortFieldEnum =
  (typeof GetProductBySerialNumberSortFieldEnum)[keyof typeof GetProductBySerialNumberSortFieldEnum];
/**
 * @export
 */
export const GetProductBySerialNumberSortDirectionEnum = {
  Asc: "ASC",
  Desc: "DESC"
} as const;
export type GetProductBySerialNumberSortDirectionEnum =
  (typeof GetProductBySerialNumberSortDirectionEnum)[keyof typeof GetProductBySerialNumberSortDirectionEnum];
/**
 * @export
 */
export const GetProductHierarchyByBatchAndMaterialCodeSortFieldEnum = {
  Description: "DESCRIPTION",
  Manufacturingdate: "MANUFACTURINGDATE",
  State: "STATE"
} as const;
export type GetProductHierarchyByBatchAndMaterialCodeSortFieldEnum =
  (typeof GetProductHierarchyByBatchAndMaterialCodeSortFieldEnum)[keyof typeof GetProductHierarchyByBatchAndMaterialCodeSortFieldEnum];
/**
 * @export
 */
export const GetProductHierarchyByBatchAndMaterialCodeSortDirectionEnum = {
  Asc: "ASC",
  Desc: "DESC"
} as const;
export type GetProductHierarchyByBatchAndMaterialCodeSortDirectionEnum =
  (typeof GetProductHierarchyByBatchAndMaterialCodeSortDirectionEnum)[keyof typeof GetProductHierarchyByBatchAndMaterialCodeSortDirectionEnum];
/**
 * @export
 */
export const ListProductsPaginatedSortFieldEnum = {
  Description: "DESCRIPTION",
  Manufacturingdate: "MANUFACTURINGDATE",
  State: "STATE"
} as const;
export type ListProductsPaginatedSortFieldEnum =
  (typeof ListProductsPaginatedSortFieldEnum)[keyof typeof ListProductsPaginatedSortFieldEnum];
/**
 * @export
 */
export const ListProductsPaginatedSortDirectionEnum = {
  Asc: "ASC",
  Desc: "DESC"
} as const;
export type ListProductsPaginatedSortDirectionEnum =
  (typeof ListProductsPaginatedSortDirectionEnum)[keyof typeof ListProductsPaginatedSortDirectionEnum];
