import { COLORS } from "@theme";
import { useEffect } from "react";
import { Grid } from "@mui/material";
import Icon from "@components/icons/icon";
import { useTranslation } from "react-i18next";
import { DomainPage } from "@movicoders/domain";
import { CREATE_ID, TIMEOUT_ID } from "@constants";
import Company from "@domain/model/CompanyAdminBoard";
import { PortalTypes } from "@domain/model/PortalTypes";
import { AdminBoardDetailView } from "./admin-board-detail-view";
import { AdminTitle } from "@components/admin-view/AdminBoardTitle";
import { CompanyDTO, FullCompanyItemDTO } from "@clients/aggrego-proxy";
import AdminBoardViewTable from "./view-components/admin-board-view-table";
import { StepperCompany } from "./components/stepper-company/stepper-company";
import { OutletContainer } from "@components/outlet-container/outlet-container";
import { useAdminBoardListViewModel } from "./hooks/useAdminBoardListViewModel";
import { CompanyCreateDTO } from "./components/stepper-company/company-create-model";
import { LoadingSpinnerMasterView } from "@components/loading-circular-progress/loading-spinner-master-view";
import { useImpersonationViewModel } from "@components/layout/drawer/components/impersonation/useImpersonationViewModel";

export const AdminBoardView = () => {
  const { t } = useTranslation();

  const {
    dataPaginated,
    handleEdit,
    handleSave,
    selectedCompany,
    create,
    loading,
    goToList,
    handlePagination,
    sortByFieldBackend
  } = useAdminBoardListViewModel();

  const { onChangePortal } = useImpersonationViewModel();

  useEffect(() => {
    onChangePortal(PortalTypes.ADMIN);
  }, []);

  return (
    <>
      <OutletContainer>
        <>
          {(selectedCompany as CompanyDTO)?.id === CREATE_ID && <StepperCompany onClose={() => goToList()} />}

          <Grid
            container
            display={(selectedCompany as CompanyDTO).id !== CREATE_ID ? "flex" : "none"}
            flexDirection="column"
            id="container-admin-table">
            <AdminTitle
              icon={<Icon icon="location" color={COLORS.primary} />}
              name={t("admin.board.title")}
              buttonText={t("admin.board.button")}
              onAddButton={create}
              showButton
            />
            <AdminBoardViewTable
              dataPaginated={dataPaginated as DomainPage<CompanyDTO | Company | CompanyCreateDTO | FullCompanyItemDTO>}
              handleEdit={handleEdit}
              handlePagination={handlePagination}
              sortByFieldBackend={sortByFieldBackend}
            />
          </Grid>
        </>
      </OutletContainer>
      {(selectedCompany as CompanyDTO)?.id !== undefined &&
      (selectedCompany as CompanyDTO).id !== TIMEOUT_ID &&
      (selectedCompany as CompanyDTO).id !== CREATE_ID ? (
        <AdminBoardDetailView
          onClose={() => goToList()}
          onSave={handleSave}
          title={t("admin.board.edit.title") + (selectedCompany as CompanyDTO).name}
          company={selectedCompany as FullCompanyItemDTO}
        />
      ) : null}

      <LoadingSpinnerMasterView loading={loading} />
    </>
  );
};
