import { useEffect, useRef, useState } from "react";
import { OrbitControls as OrbitControlsType } from "three-stdlib";
import { Tile3D, tile3DFromDTO } from "../types/Tile3D";
import { Vector3 } from "three";
import { Location3D, location3DFromDTO } from "../types/Location3D";
import { TileGetByIdUseCase } from "../../../../application/master-data/tiles/tile-get-by-id-use-case";
import { useResolve } from "@movicoders/di";
import { LocationDTO } from "../../../../clients/aggrego-proxy";
import Tile from "../../../../domain/model/Tile";
import { ContainerRepository } from "../../../../infrastructure/repositories/container-repository";

export const useDetailViewerViewmodel = (id?: string) => {
  const tileGetByIdUseCase = useResolve(TileGetByIdUseCase);
  const containerRepository = useResolve<ContainerRepository>(ContainerRepository);

  // BinDetailsDialog related variables.
  const [selected, setSelected] = useState<Location3D[]>();
  const [dialogOpen, setDialogOpen] = useState(false);

  // The controls of the current canvas.
  const controls = useRef<OrbitControlsType>(null);

  //Data
  const [tiles, setTiles] = useState<Tile3D[]>();
  const [maxBinsLength, setMaxBinsLength] = useState(1);

  // Target the controls will move to.
  const [target, setTarget] = useState<Vector3>();

  // Resets the camera to the default position.
  const handleReset = () => {
    if (controls.current !== undefined) {
      //The camera cant be modified when the controls are enabled.
      (controls.current as OrbitControlsType).enabled = false;
      const v3pos = new Vector3(0, 2, 5);
      setTarget(v3pos);

      setSelected([]);
      setDialogOpen(false);
    }
  };

  // Handles the movement of the camera to the next bin and shows the BinDetailsDialog.
  const handleClick = (bins: Location3D[], pos: number[]) => {
    //The camera cant be modified when the controls are enabled.
    (controls.current as OrbitControlsType).enabled = false;

    const v3pos = new Vector3(pos[0], (bins[0].level ?? 0) - 0.72, 1.5);
    setTarget(v3pos);

    setSelected(bins);
    setDialogOpen(true);
  };

  // When the dialog closes, reset the position of the camera.
  useEffect(() => {
    if (!dialogOpen && controls.current !== null) {
      handleReset();
    }
  }, [dialogOpen]);

  // Gets the containers from a LocationDTO object.
  const getContainersFromLocation = async (location: LocationDTO) => {
    return await containerRepository.getManyByIds({ requestBody: location.containers ?? [] }).then(containers => {
      const location3d = location3DFromDTO(location);
      location3d.containers = containers ?? [];
      return location3d;
    });
  };

  // Gets the selected tile locations data and assigns a new tile.
  const handleSearchTileData = async (tileToSearch: Tile) => {
    const locations = tileToSearch.locations ?? [];

    const locationsWithContainers = await Promise.all(locations.map(getContainersFromLocation)).catch(() =>
      locations.map(location => location3DFromDTO(location, tileToSearch.id))
    );

    const newTile: Tile3D = tile3DFromDTO(tileToSearch);
    newTile.locations = locationsWithContainers;
    return newTile;
  };

  const handleSetNewData = (data: Tile[]) => {
    const searchedTilesPromises = data.map(handleSearchTileData);
    Promise.all(searchedTilesPromises).then(tilesToSave => {
      let locationsToUse: Location3D[] = [];
      tilesToSave.forEach(t => {
        locationsToUse = locationsToUse.concat(t.locations ?? []);
      });
      const maxLevel = Math.max(...(locationsToUse?.map(l => l.level ?? 0) ?? []));
      setTiles([...tilesToSave]);
      setMaxBinsLength(maxLevel ?? 1);
    });
  };

  // When the id is loaded, searches for the tile and updates it
  useEffect(() => {
    if (id)
      tileGetByIdUseCase.execute(id).then(tile => {
        handleSetNewData([tile]);
      });
  }, [id]);

  useEffect(() => {
    if (controls.current !== null) controls.current.maxDistance = maxBinsLength === 1 ? 3 : maxBinsLength * 1.5;
  }, [controls.current, maxBinsLength]);

  useEffect(() => {
    if (controls.current && !target && selected?.length === 0) controls.current.enabled = true;
  }, [target]);

  return {
    tiles,
    controls,
    selected,
    dialogOpen,
    handleReset,
    handleClick,
    setDialogOpen,
    target,
    setTarget
  };
};
