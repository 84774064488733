import { create } from "@movicoders/store";
import { HierarchyState } from "@domain/model/HierarchyState";
import { IHierarchyService } from "@domain/services/IHierarchyService";

export const useHierarchyService = create<IHierarchyService>(set => ({
  clearHierarchyState: () => set({ persistedState: undefined }),
  saveHierarchyState: (newState: HierarchyState) => set({ persistedState: newState })
}))
  .withPersist(HierarchyState, "HierarchyState")
  .build();
