/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MovementEventDTO
 */
export interface MovementEventDTO {
    /**
     * 
     * @type {string}
     * @memberof MovementEventDTO
     */
    action?: MovementEventDTOActionEnum;
    /**
     * 
     * @type {Date}
     * @memberof MovementEventDTO
     */
    actionDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MovementEventDTO
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof MovementEventDTO
     */
    container?: string;
    /**
     * 
     * @type {string}
     * @memberof MovementEventDTO
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof MovementEventDTO
     */
    destiny?: string;
    /**
     * 
     * @type {string}
     * @memberof MovementEventDTO
     */
    family?: string;
    /**
     * 
     * @type {string}
     * @memberof MovementEventDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MovementEventDTO
     */
    material?: string;
    /**
     * 
     * @type {string}
     * @memberof MovementEventDTO
     */
    operatorCode?: string;
    /**
     * 
     * @type {string}
     * @memberof MovementEventDTO
     */
    origin?: string;
    /**
     * 
     * @type {number}
     * @memberof MovementEventDTO
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof MovementEventDTO
     */
    uom?: string;
    /**
     * 
     * @type {string}
     * @memberof MovementEventDTO
     */
    user?: string;
    /**
     * 
     * @type {string}
     * @memberof MovementEventDTO
     */
    warehouse?: string;
}


/**
 * @export
 */
export const MovementEventDTOActionEnum = {
    Consumption: 'CONSUMPTION',
    Entrance: 'ENTRANCE',
    Exit: 'EXIT',
    InventoryActivation: 'INVENTORY_ACTIVATION',
    InventoryDeactivation: 'INVENTORY_DEACTIVATION',
    InventoryInbound: 'INVENTORY_INBOUND',
    InventoryOutbound: 'INVENTORY_OUTBOUND',
    Movement: 'MOVEMENT',
    Reactivation: 'REACTIVATION',
    Regularization: 'REGULARIZATION'
} as const;
export type MovementEventDTOActionEnum = typeof MovementEventDTOActionEnum[keyof typeof MovementEventDTOActionEnum];


/**
 * Check if a given object implements the MovementEventDTO interface.
 */
export function instanceOfMovementEventDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MovementEventDTOFromJSON(json: any): MovementEventDTO {
    return MovementEventDTOFromJSONTyped(json, false);
}

export function MovementEventDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MovementEventDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': !exists(json, 'action') ? undefined : json['action'],
        'actionDate': !exists(json, 'actionDate') ? undefined : (new Date(json['actionDate'])),
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'container': !exists(json, 'container') ? undefined : json['container'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'destiny': !exists(json, 'destiny') ? undefined : json['destiny'],
        'family': !exists(json, 'family') ? undefined : json['family'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'material': !exists(json, 'material') ? undefined : json['material'],
        'operatorCode': !exists(json, 'operatorCode') ? undefined : json['operatorCode'],
        'origin': !exists(json, 'origin') ? undefined : json['origin'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'uom': !exists(json, 'uom') ? undefined : json['uom'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'warehouse': !exists(json, 'warehouse') ? undefined : json['warehouse'],
    };
}

export function MovementEventDTOToJSON(value?: MovementEventDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': value.action,
        'actionDate': value.actionDate === undefined ? undefined : (value.actionDate.toISOString()),
        'comment': value.comment,
        'container': value.container,
        'description': value.description,
        'destiny': value.destiny,
        'family': value.family,
        'id': value.id,
        'material': value.material,
        'operatorCode': value.operatorCode,
        'origin': value.origin,
        'quantity': value.quantity,
        'uom': value.uom,
        'user': value.user,
        'warehouse': value.warehouse,
    };
}

