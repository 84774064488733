import { SxProps, Theme } from "@mui/material";

export const homeContentTitleStyles = () => {
  const title_summary_container: SxProps<Theme> = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  };
  return {
    title_summary_container
  };
};
