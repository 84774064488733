import { useRef } from "react";
import { useResolve } from "@movicoders/di";
import { LastPageState } from "@domain/model/LastPageState";
import { useListDetailPaginatedViewModel } from "@movicoders/ui";
import { ILastPageService, LastPageService } from "@domain/services/ILastPageService";
import { StakeholderRepository } from "@infrastructure/repositories/stakeholders-repository";

export const useStakeholderHandleData = () => {
  const currentPageService = useResolve<ILastPageService>(LastPageService);
  const currentPagePersistedState = currentPageService.persistedState ?? new LastPageState();

  const {
    selected: selectedStakeholder,
    data,
    loading,
    loadingDetail,
    fetchOne,
    getPaginated,
    goToList,
    create,
    save
  } = useListDetailPaginatedViewModel(StakeholderRepository);

  const getStakeholderPaginated = useRef(getPaginated).current;

  return {
    currentPageService,
    currentPagePersistedState,
    stakeholderData: data,
    selectedStakeholder,
    loading,
    loadingDetail,
    save,
    goToDetail: fetchOne,
    goToCreate: create,
    goToList,
    getStakeholderPaginated
  };
};
