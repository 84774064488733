import { COLORS } from "@theme";
import { SxProps, Theme } from "@mui/material";

export const loginViewStyles = () => {
  const full_height: SxProps<Theme> = {
    height: "100vh"
  };

  //Transition for login screen grids when first enter
  const move_transition: SxProps<Theme> = {
    transition: theme =>
      theme.transitions.create(["max-width", "flex-basis"], {
        duration: "0.3s",
        easing: theme.transitions.easing.easeOut
      })
  };

  const background_gradient: SxProps<Theme> = {
    background: COLORS.gradient
  };

  return {
    full_height,
    move_transition,
    background_gradient
  };
};
