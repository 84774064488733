import Icon from "@components/icons/icon";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const WorkInProgessView = () => {
  const { t } = useTranslation();

  return (
    <Grid container id="wip-container-grid" display="flex">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh"
        }}>
        <Grid item id="wip-icon-and-texts" display="flex" flexDirection="column" alignItems="center">
          <Icon icon="wip" style={{ width: "5.5rem", height: "5.234rem" }} />
          <Grid item id="wip-title-message-grid" className="main-view-title" padding={"1.5rem"}>
            <Typography id="master-data-title" variant="product_history_title">
              {t("work.in.progress.title")}
            </Typography>
          </Grid>
          <Grid item padding={"1.5rem"}>
            <Typography variant="subtitle2">{t("work.in.progress.message")}</Typography>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default WorkInProgessView;
