/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MovementEventDTO } from './MovementEventDTO';
import {
    MovementEventDTOFromJSON,
    MovementEventDTOFromJSONTyped,
    MovementEventDTOToJSON,
} from './MovementEventDTO';
import type { Pageable } from './Pageable';
import {
    PageableFromJSON,
    PageableFromJSONTyped,
    PageableToJSON,
} from './Pageable';
import type { Sort } from './Sort';
import {
    SortFromJSON,
    SortFromJSONTyped,
    SortToJSON,
} from './Sort';

/**
 * 
 * @export
 * @interface MovementEventPageDTO
 */
export interface MovementEventPageDTO {
    /**
     * 
     * @type {Array<MovementEventDTO>}
     * @memberof MovementEventPageDTO
     */
    content?: Array<MovementEventDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof MovementEventPageDTO
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MovementEventPageDTO
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MovementEventPageDTO
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof MovementEventPageDTO
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof MovementEventPageDTO
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof MovementEventPageDTO
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof MovementEventPageDTO
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof MovementEventPageDTO
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof MovementEventPageDTO
     */
    totalPages?: number;
}

/**
 * Check if a given object implements the MovementEventPageDTO interface.
 */
export function instanceOfMovementEventPageDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MovementEventPageDTOFromJSON(json: any): MovementEventPageDTO {
    return MovementEventPageDTOFromJSONTyped(json, false);
}

export function MovementEventPageDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MovementEventPageDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': !exists(json, 'content') ? undefined : ((json['content'] as Array<any>).map(MovementEventDTOFromJSON)),
        'first': !exists(json, 'first') ? undefined : json['first'],
        'last': !exists(json, 'last') ? undefined : json['last'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'numberOfElements': !exists(json, 'numberOfElements') ? undefined : json['numberOfElements'],
        'pageable': !exists(json, 'pageable') ? undefined : PageableFromJSON(json['pageable']),
        'size': !exists(json, 'size') ? undefined : json['size'],
        'sort': !exists(json, 'sort') ? undefined : SortFromJSON(json['sort']),
        'totalElements': !exists(json, 'totalElements') ? undefined : json['totalElements'],
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
    };
}

export function MovementEventPageDTOToJSON(value?: MovementEventPageDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content === undefined ? undefined : ((value.content as Array<any>).map(MovementEventDTOToJSON)),
        'first': value.first,
        'last': value.last,
        'number': value.number,
        'numberOfElements': value.numberOfElements,
        'pageable': PageableToJSON(value.pageable),
        'size': value.size,
        'sort': SortToJSON(value.sort),
        'totalElements': value.totalElements,
        'totalPages': value.totalPages,
    };
}

