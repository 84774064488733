/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TileDTO } from './TileDTO';
import {
    TileDTOFromJSON,
    TileDTOFromJSONTyped,
    TileDTOToJSON,
} from './TileDTO';

/**
 * 
 * @export
 * @interface ZoneDTO
 */
export interface ZoneDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ZoneDTO
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ZoneDTO
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof ZoneDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ZoneDTO
     */
    name?: string;
    /**
     * 
     * @type {Array<TileDTO>}
     * @memberof ZoneDTO
     */
    tiles?: Array<TileDTO>;
    /**
     * 
     * @type {string}
     * @memberof ZoneDTO
     */
    warehouseId?: string;
}

/**
 * Check if a given object implements the ZoneDTO interface.
 */
export function instanceOfZoneDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ZoneDTOFromJSON(json: any): ZoneDTO {
    return ZoneDTOFromJSONTyped(json, false);
}

export function ZoneDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZoneDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'tiles': !exists(json, 'tiles') ? undefined : ((json['tiles'] as Array<any>).map(TileDTOFromJSON)),
        'warehouseId': !exists(json, 'warehouseId') ? undefined : json['warehouseId'],
    };
}

export function ZoneDTOToJSON(value?: ZoneDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'color': value.color,
        'id': value.id,
        'name': value.name,
        'tiles': value.tiles === undefined ? undefined : ((value.tiles as Array<any>).map(TileDTOToJSON)),
        'warehouseId': value.warehouseId,
    };
}

