import { useHandlePeriods } from "@hooks/useHandlePeriods";
import { useInventoryRegistryInventory } from "./useInventoryRegistryInventory";
import { useInventoryRegistryPagination } from "./useInventoryRegistryPagination";
import { useInventoryRegistryHandleData } from "./useInventoryRegistryHandleData";
import { useInventoryRegistryAutocomplete } from "./useInventoryRegistryAutocomplete";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { useResolve } from "@movicoders/di";

export const useInventoryRegistryViewModel = () => {
  const settingsService = useResolve<ISettingsService>(SettingsService);

  const {
    currentPageService,
    currentPagePersistedState,
    columnsByMode,
    loading,
    inventoryRegistries,
    selectedWarehouseCode,
    getInvRegistryPaginated
  } = useInventoryRegistryHandleData();

  const {
    periods,
    sortedPeriods,
    isActivePeriod,
    selectedPeriod,
    getAllPeriods,
    sortPeriods,
    setIsActivePeriod,
    setSelectedPeriod
  } = useHandlePeriods(selectedWarehouseCode);

  const {
    isInitPeriodButtonDisabled,
    showInvRegistryDialog,
    handleCloseInventory,
    handleDialogVisibility,
    handleInitInventory,
    onCloseInvRegistryDialog
  } = useInventoryRegistryInventory(selectedWarehouseCode, periods, getAllPeriods, sortPeriods, setIsActivePeriod);

  const { filteredMaterialPaginatedData } = useInventoryRegistryAutocomplete(currentPageService, currentPagePersistedState);
  const { searchValue, showErrors, search, setSearchValue, clearSearchValue, handleEnterReload } = useInventoryRegistryPagination(
    isActivePeriod,
    currentPagePersistedState,
    selectedPeriod,
    sortedPeriods,
    selectedWarehouseCode,
    getInvRegistryPaginated
  );

  return {
    inventoryRegistries,
    filteredMaterialPaginatedData,
    columnsByMode,
    searchValue,
    showErrors,
    isActivePeriod,
    isInitPeriodButtonDisabled,
    sortedPeriods,
    selectedPeriod,
    showInvRegistryDialog,
    loading,
    setSearchValue,
    setSelectedPeriod,
    clearSearchValue,
    handleEnterReload,
    handleDialogVisibility,
    handleCloseInventory,
    handleInitInventory,
    onCloseInvRegistryDialog,
    search,
    settingsService
  };
};
