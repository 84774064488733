import { useEffect } from "react";

export const usePeriodicFunction = (callback: () => void, interval: number) => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      callback();
    }, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [callback, interval]);

  useEffect(() => {
    callback();
  }, []);
};
