import { COLORS } from "@theme";
import Icon from "../../../icons/icon";
import { ROUTER_PATHS } from "@constants";
import { useResolve } from "@movicoders/di";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DrawerState } from "@domain/model/DrawerState";
import { IWarehouseList } from "../interfaces/drawer-interfaces";
import { IDrawerService, DrawerService } from "@domain/services/IDrawerService";
import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material";

export const WarehouseList = ({ isDrawerOpen, drawerWidth }: IWarehouseList) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const service = useResolve<IDrawerService>(DrawerService);
  const persistedState = service.persistedState ?? new DrawerState();

  const handleWarehouseSelect = (id: string) => {
    service.saveDrawerState({ ...persistedState, selectedWarehouse: id });
    navigate(`${ROUTER_PATHS.home}/${id}`);
  };

  return (
    <>
      {persistedState.warehouses
        .filter(wh => wh.active)
        .map((wh, index) => {
          const warehouseIconColor = wh.id === persistedState.selectedWarehouse ? COLORS.primary : "inherit";
          return (
            <Tooltip title={isDrawerOpen ? "" : wh.name} placement="right" key={wh.id}>
              <ListItemButton
                data-cy="warehouse-drawer-button"
                id={`warehouse-icon-${index}`}
                className="drawer-item"
                onClick={e => {
                  e.stopPropagation();
                  handleWarehouseSelect(wh.id ?? "");
                }}
                sx={{
                  width: drawerWidth
                }}>
                <ListItemIcon
                  style={{
                    color: warehouseIconColor
                  }}>
                  <Icon icon="warehouse" />
                </ListItemIcon>
                {isDrawerOpen && (
                  <ListItemText
                    primaryTypographyProps={{
                      variant: "drawer",
                      color: warehouseIconColor
                    }}
                    primary={t(wh.name?.toUpperCase() ?? "")}
                    style={{ margin: 0 }}
                  />
                )}
              </ListItemButton>
            </Tooltip>
          );
        })}
    </>
  );
};
