import { IMMaterialEntryDTO } from "@clients/aggrego-proxy";

export default class IMMEntryWithDesc {
  id?: string;
  materialCode?: string;
  materialDescription?: string;
  qty?: number;
  entriesQty?: number;
  comment?: string;
  uom?: string;
  warehouseCode?: string;
  errorMessage?: string;

  constructor(
    id: string,
    materialCode: string,
    materialDescription: string,
    qty: number,
    entriesQty: number,
    comment: string,
    uom: string,
    warehouseCode: string,
    errorMessage?: string
  ) {
    this.id = id;
    this.materialCode = materialCode;
    this.materialDescription = materialDescription;
    this.comment = comment;
    this.qty = qty;
    this.entriesQty = entriesQty;
    this.comment = comment;
    this.uom = uom;
    this.warehouseCode = warehouseCode;
    this.errorMessage = errorMessage;
  }

  static fromClientImMEntry(imMEntry: IMMaterialEntryDTO) {
    const result = new IMMEntryWithDesc(
      imMEntry.id ?? "",
      imMEntry.materialCode ?? "",
      "",
      imMEntry.qty ?? 0,
      0,
      imMEntry.comment ?? "",
      "",
      imMEntry.warehouseCode ?? "",
      ""
    );
    return result;
  }

  static toClientImMEntry(imMEntry: IMMEntryWithDesc) {
    const result: IMMaterialEntryDTO = {};
    result.id = imMEntry.id;
    result.materialCode = imMEntry.materialCode;
    result.comment = imMEntry.comment;
    result.qty = imMEntry.qty;
    result.warehouseCode = imMEntry.warehouseCode;
    return result;
  }

  static toClientImMEntryForAdjustment(imMEntry: IMMEntryWithDesc) {
    const result: IMMaterialEntryDTO = {};
    result.id = imMEntry.id;
    result.materialCode = imMEntry.materialCode;
    result.comment = imMEntry.comment;
    result.qty = imMEntry.entriesQty;
    result.warehouseCode = imMEntry.warehouseCode;
    return result;
  }
}
