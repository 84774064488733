import { useResolve } from "@movicoders/di";
import { useEffect, useState } from "react";
import { LastPageState } from "@domain/model/LastPageState";
import { ILastPageService, LastPageService } from "@domain/services/ILastPageService";

export enum stakeholderTypesEnum {
  customer = "customers",
  manuafacturers = "manufacturers",
  owners = "owners",
  suppliers = "suppliers"
}

export const useStakehodlerSelection = () => {
  const stakeholderType: stakeholderTypesEnum = window.location.pathname.split("/")?.[1] as stakeholderTypesEnum;

  const lastPageService = useResolve<ILastPageService>(LastPageService);
  const lastPagePersistedState = lastPageService.persistedState ?? new LastPageState();

  const [stakeholderTypeForFilters, setStakeholderTypeForFilters] = useState("");

  useEffect(() => {
    chooseStakeholderType();
  }, [stakeholderType]);

  const chooseStakeholderType = () => {
    const typeForFilter: string[] = [];
    switch (stakeholderType) {
      case stakeholderTypesEnum.customer:
        typeForFilter.splice(0, typeForFilter.length);
        typeForFilter.push("CUSTOMER");
        setStakeholderTypeForFilters("CUSTOMER");
        break;
      case stakeholderTypesEnum.manuafacturers:
        typeForFilter.splice(0, typeForFilter.length);
        typeForFilter.push("MANUFACTURER");
        setStakeholderTypeForFilters("MANUFACTURER");
        break;
      case stakeholderTypesEnum.owners:
        typeForFilter.splice(0, typeForFilter.length);
        typeForFilter.push("OWNER");
        setStakeholderTypeForFilters("OWNER");
        break;
      case stakeholderTypesEnum.suppliers:
        typeForFilter.splice(0, typeForFilter.length);
        typeForFilter.push("SUPPLIER");
        setStakeholderTypeForFilters("SUPPLIER");
        break;
    }
    const resetFilters: object = {
      ...lastPagePersistedState.filters,
      types: typeForFilter,
      searchText: "",
      city: undefined,
      country: undefined,
      email: undefined,
      paymentTerms: undefined,
      paymentCurrency: undefined
    };
    lastPageService.saveLastPageState({ ...lastPagePersistedState, filters: resetFilters });
  };

  return { stakeholderType, stakeholderTypeForFilters };
};
