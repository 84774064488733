import { t } from "i18next";
import Tile from "@domain/model/Tile";
import Warehouse from "@domain/model/Warehouse";
import { useEffect, useRef, useState } from "react";
import LocationEntity from "@domain/model/Location";
import { useListViewModel, useSnackbar } from "@movicoders/ui";
import { useHandleSelectedWarehouse } from "@hooks/useHandleSelectedWarehouse";
import { WarehouseRepository } from "@infrastructure/repositories/warehouse-repository";

interface ILocationForm {
  selectedLocation: LocationEntity;
  currentTiles: Tile[];
  onSave: (location: LocationEntity, tile: Tile) => Promise<void>;
}

interface IWarehouseSizes {
  x?: number;
  y?: number;
  z?: number;
}

export const useLocationForm = (props: ILocationForm) => {
  const { currentTiles, selectedLocation } = props;
  const { show } = useSnackbar();

  // Warehouses
  const { data, fetch: fetchWarehouses } = useListViewModel(WarehouseRepository);
  const warehouseFetch = useRef(fetchWarehouses).current;

  // Drawer State
  const { selectedWarehouse } = useHandleSelectedWarehouse();

  // Values that are currently being modified.
  const [location, setLocation] = useState<LocationEntity>(selectedLocation);
  const [tile, setTile] = useState<Tile>({});
  const [showErrors, setShowErrors] = useState(false);
  const [locationCapacityError, setLocationCapacityError] = useState<boolean>(false);

  // Size of the currently selected warehouse
  const [warehouseSizes, setWarehouseSizes] = useState<IWarehouseSizes>({});

  useEffect(() => {
    setLocation(selectedLocation);
    setTile(
      currentTiles.find(t => t.id === selectedLocation.tileId) ?? { x: undefined, z: undefined, zoneId: undefined, id: undefined }
    );
    warehouseFetch({});
    setShowErrors(false);
  }, [selectedLocation]);

  useEffect(() => {
    setLocationCapacityError(false);
  }, [location.capacity]);

  useEffect(() => {
    setWarehouseSizes(getWarehouseSizes());
  }, []);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocation({ ...location, [event.target.name]: event.target.value });
  };

  const onChangeNumeric = (event: React.ChangeEvent<HTMLInputElement>, modifyingTile: boolean) => {
    const regex = /^[0-9]+$/;
    const inputValue = event.target.value;

    if (regex.test(inputValue) || inputValue === "") {
      const newValue = inputValue === "" ? "0" : inputValue.replace(/^0+/, "");
      if (modifyingTile) {
        setTile({ ...tile, [event.target.name]: newValue });
      } else {
        setLocation({ ...location, [event.target.name]: newValue });
      }
    }
  };

  const changeZone = (newZoneId: string) => {
    setTile({ ...tile, zoneId: newZoneId });
  };

  // Returns the three sizes of the current warehouse.
  const getWarehouseSizes = (): IWarehouseSizes => {
    const warehouseId: string = selectedWarehouse;
    let warehouse: Warehouse = new Warehouse();

    if (warehouseId === "") {
      show(t("locations.get.warehouse.error"), "error");
    } else {
      data.every(element => {
        if (element.id === warehouseId) {
          warehouse = element;
          return false; // end of the loop
        }
        return true;
      });

      if (warehouse.id === null) show(t("locations.get.warehouse.error.not.found"), "error");
      else return warehouse ? { x: warehouse.sizeX, z: warehouse.sizeZ, y: warehouse.maxSizeY } : {};
    }
    return {};
  };

  // Check if a value is not valid.
  const isNotValid = (value: string | number | undefined, type: string | undefined, sizeToCheck?: number): boolean => {
    if (
      LocationEntity.isTextValueNotValid(value?.toString() ?? "") ||
      ((type === "number" || type?.includes("position")) && LocationEntity.isNumberValueNotValid(Number(value)))
    ) {
      return true;
    }

    if (type?.includes("position")) return LocationEntity.isLevelValueNotValid(Number(value), sizeToCheck ?? 0);

    return false;
  };

  const isNewCapacityNotValid = (location: LocationEntity) => {
    const validation = LocationEntity.IsCapacityLowerThanContainers(location);
    setLocationCapacityError(validation);
    return validation;
  };

  // Checks all of the fields and saves if all of them are valid.
  const isValidData = () => {
    const warehouseSizes = getWarehouseSizes();
    if (
      LocationEntity.isTextValueNotValid(location.code ?? "") ||
      LocationEntity.isNumberValueNotValid(location.capacity ?? 0) ||
      isNewCapacityNotValid(location) ||
      Tile.isPositionValueNotValid(tile.x ?? 0, warehouseSizes?.x ?? 0) ||
      Tile.isPositionValueNotValid(tile.z ?? 0, warehouseSizes?.z ?? 0) ||
      LocationEntity.isLevelValueNotValid(location.level ?? 0, warehouseSizes?.y ?? 0)
    ) {
      setShowErrors(true);
      return false;
    } else {
      setShowErrors(false);
      return true;
    }
  };

  return {
    onChange,
    onChangeNumeric,
    location,
    tile,
    warehouseSizes,
    isNotValid,
    isValidData,
    showErrors,
    changeZone,
    locationCapacityError
  };
};
