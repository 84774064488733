import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import { CountryObject } from "@constants";
import { cifHelper } from "@utils/cif-helper";
import { useTranslation } from "react-i18next";
import { stepperStyles } from "../stepper-Styles";
import { CompanyCreateDTO } from "../company-create-model";
import { SyntheticEvent, useEffect, useState } from "react";
import { FileInput } from "../components/file-input/file-input";
import { Grid, TextField, Theme, Typography, useMediaQuery } from "@mui/material";
import { DefaultCountriesAutocomplete } from "@components/autocompletes/default-countries-autocomplete";

export const StepMasterData = (props: {
  companyCreate: CompanyCreateDTO;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeAddress: (address: string) => void;
  onChangeImage: (image: Blob) => void;
}) => {
  const { t } = useTranslation();
  const styles = stepperStyles();
  const { companyCreate, onChange, onChangeAddress, onChangeImage } = props;

  const matchesMd = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"), { noSsr: true });
  const matchesSm = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"), { noSsr: true });

  const [address, setAddress] = useState({
    street: "",
    postalCode: "",
    province: "",
    country: ""
  });

  useEffect(() => {
    if (address.street !== "" && address.postalCode !== "" && address.province !== "" && address.country !== "") {
      const formatedAddress = address.street + "±" + address.postalCode + "±" + address.province + "±" + address.country;
      onChangeAddress(formatedAddress);
    }
  }, [address]);

  useEffect(() => {
    const splitAddress = companyCreate.address !== undefined ? companyCreate.address?.split("±") : ["", "", "", ""];
    setAddress({
      ...address,
      street: splitAddress[0] ?? "",
      postalCode: splitAddress[1] ?? "",
      province: splitAddress[2] ?? "",
      country: splitAddress[3] ?? ""
    });
  }, []);

  const renderInput = (id: string, name: string, value: string | number, label: string, error?: boolean, errorText?: string) => {
    return (
      <Grid item>
        <Grid container alignItems="flex-start" spacing={{ xs: 1.5 }}>
          <Grid item display="flex" xs={12} lg={3}>
            <Icon icon="company" color={COLORS.primary} />
            <Typography sx={styles.item_typography}>{label}</Typography>
          </Grid>
          <Grid item sm={4} lg={3} alignItems="flex-start" marginBottom={error && (matchesMd || matchesSm) ? "-4rem" : 0}>
            <TextField
              id={id}
              value={value}
              name={name}
              onChange={onChange}
              label={label}
              className={"blue-border-input"}
              error={error}
              helperText={error ? errorText : ""}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container flexDirection="column" className="masterdata-box" rowSpacing={2}>
      <Grid item>
        <Typography sx={styles.categories_typography}>{t("step-master-data-image")}</Typography>
      </Grid>

      <Grid item>
        <Grid container alignItems="center" spacing={{ xs: 1.5 }}>
          <Grid item display="flex" xs={12} lg={3}>
            <Icon icon="company" color={COLORS.primary} />
            <Typography sx={styles.item_typography}>{t("step-master-data-logo")}</Typography>
          </Grid>

          <Grid item sm={4} lg={3}>
            <FileInput handleImage={onChangeImage} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Typography sx={styles.categories_typography}>{t("company.data")}</Typography>
      </Grid>
      {renderInput("stepper-company-name", "companyName", companyCreate.companyName ?? "", t("step-master-data-company-name"))}
      {renderInput(
        "stepper-company-fiscalId",
        "fiscalId",
        companyCreate.fiscalId ?? "",
        t("step-master-data-fiscalid"),
        !cifHelper(companyCreate?.fiscalId ?? ""),
        t("companies.cif.incorrect")
      )}

      <Grid item>
        <Grid container alignItems="center" spacing={{ xs: 1.5 }}>
          <Grid item display="flex" xs={12} lg={3}>
            <Icon icon="company" color={COLORS.primary} />
            <Typography sx={styles.item_typography}>{t("step-master-data-company-address")}</Typography>
          </Grid>

          <Grid item sm={4} lg={3}>
            <TextField
              id={"step-master-data-company-street"}
              value={address.street}
              name={"street"}
              onChange={e => {
                setAddress({ ...address, street: e.target.value });
              }}
              label={t("step-master-data-company-street")}
              className={"blue-border-input"}
            />
          </Grid>
          <Grid item sm={4} lg={3}>
            <TextField
              id={"step-master-data-company-postalcode"}
              value={address.postalCode}
              name={"postalcode"}
              onChange={e => {
                setAddress({ ...address, postalCode: e.target.value });
              }}
              label={t("step-master-data-company-postalcode")}
              className={"blue-border-input"}
            />
          </Grid>
        </Grid>

        <Grid container display={"flex"} pt={2} spacing={{ xs: 1.5 }}>
          <Grid item display="flex" xs={12} lg={3} />
          <Grid item sm={4} lg={3}>
            <TextField
              id={"step-master-data-company-province"}
              value={address.province}
              name={"province"}
              onChange={e => {
                setAddress({ ...address, province: e.target.value });
              }}
              label={t("step-master-data-company-province")}
              className={"blue-border-input"}
            />
          </Grid>
          <Grid item sm={4} lg={3}>
            <DefaultCountriesAutocomplete
              disabled={false}
              initialValue={address.country ?? ""}
              onChangeAutocomplete={(event: SyntheticEvent<Element, Event>, value: CountryObject | null) => {
                setAddress({ ...address, country: value !== null ? t(value.label) : "" });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
