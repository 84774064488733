import { Mesh, Object3D, Scene } from "three";
import { Location3D } from "../types/Location3D";

// Function that checks if there is a tile in the highlightMesh position.
export const boxExists = (scene: Scene, highlightMesh: Mesh) => {
  return scene.children.filter(
    child =>
      child.type === "Group" &&
      child.name === "tile" &&
      child.position.x === highlightMesh.position.x &&
      child.position.z === highlightMesh.position.z &&
      child.children.length > 0
  );
};

// Function that checks if there is a tile in the coords passed by parameters.
export const boxExistsWithCoords = (x: number, z: number, scene: Scene) => {
  return scene.children.filter(
    child =>
      child.type === "Group" &&
      child.name === "tile" &&
      child.position.x === x &&
      child.position.z === z &&
      child.children.length > 0
  );
};

// Function that checks if there is a tile within the coords passed by parameters.
export const boxExistsIn = (fromX: number, toX: number, fromZ: number, toZ: number, scene: Scene) => {
  const minX = Math.min(fromX, toX);
  const maxX = Math.max(fromX, toX);
  const minZ = Math.min(fromZ, toZ);
  const maxZ = Math.max(fromZ, toZ);

  return scene.children.filter(
    child =>
      child.type === "Group" &&
      child.name === "tile" &&
      child.children.length > 0 &&
      child.position.x >= minX &&
      child.position.x <= maxX &&
      child.position.z >= minZ &&
      child.position.z <= maxZ
  );
};

// Gets the tiles near the specified coords and sets them in the list passed.
export const getAllNear = (scene: Scene, x: number, z: number, itemList: Object3D[], templateId: string) => {
  const item = scene.children.find(
    child =>
      child.type === "Group" &&
      child.name === "tile" &&
      child.position.x === x &&
      child.position.z === z &&
      child.children.length > 0 &&
      child.userData.tile.templateId === templateId
  );

  if (item !== undefined && itemList.find(i => i.id === item.id) === undefined) {
    itemList.push(item);
    getAllNear(scene, x + 1, z, itemList, templateId);
    getAllNear(scene, x - 1, z, itemList, templateId);
    getAllNear(scene, x, z + 1, itemList, templateId);
    getAllNear(scene, x, z - 1, itemList, templateId);
  }
};

// Get tiles by zoneId
export const getByZone = (scene: Scene, zone: string) => {
  return scene.children.filter(itm => {
    const tile = itm.userData.tile;
    return (
      tile?.zoneId === zone &&
      tile?.active &&
      itm.type === "Group" &&
      itm.name === "tile" &&
      itm.children.length > 0
    );
  })
};

export const findExistingLocationByCode = (scene: Scene, initialCode: string, count: number): Location3D | undefined => {
  const codeToSearch = count > 0 ? `(${count})` : "";
  let foundLocation: Location3D | undefined = undefined;

  scene.children.forEach(t => {
    if (!foundLocation) {
      const tile = t.userData.tile;
      if (tile?.locations) {
        tile.locations?.forEach((loc: Location3D) => {
          if (loc.code === initialCode + codeToSearch)
            foundLocation = loc;
        })
      }
    }
  })

  return foundLocation;
};
