import {
  AdjustmentMaterialStockRequest,
  ConfigDailyStockReportRequest,
  DailyStockReportResponseDTO,
  GetInventoryRegistryPaginatedByFiltersRequest,
  GetInventoryReportPaginatedRequest,
  GetLastConfigDailyStockReportRequest,
  GetLatestStockReportRequest,
  GetStockReportRequest,
  GetWarehouseStatusReportByWarehouseAndFilterRequest,
  ListResponseDTO,
  ReportsApi,
  StockReportDTO
} from "@clients/aggrego-proxy";
import { StockDTO } from "@domain/model/Stock";
import { DomainPage } from "@movicoders/domain";
import { InvReportDTO } from "@domain/model/InvReport";
import { InvRegistryDTO } from "@domain/model/InvRegistry";
import GenericPage, { DTOPage } from "@domain/model/GenericPage";
import { WarehouseStatusDTO } from "@domain/model/WarehouseStatus";
import { IImpersonationService } from "@domain/services/IImpersonationService";

export class ReportApi {
  constructor(private readonly reportApi: ReportsApi, private readonly impersonationService: IImpersonationService) {}

  getWarehouseStatusPaginated(
    criteria: GetWarehouseStatusReportByWarehouseAndFilterRequest
  ): Promise<DomainPage<WarehouseStatusDTO>> {
    return new Promise((res, rej) => {
      this.reportApi
        .getWarehouseStatusReportByWarehouseAndFilter({
          ...criteria,
          xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
        })
        .then(warehouseStatusPageDTO => {
          res(
            new GenericPage<WarehouseStatusDTO>(WarehouseStatusDTO).fromDTOPage(
              warehouseStatusPageDTO as DTOPage<WarehouseStatusDTO>
            )
          );
        })
        .catch(err => rej());
    });
  }

  getInventoryReportPaginated(criteria: GetInventoryReportPaginatedRequest): Promise<DomainPage<InvReportDTO>> {
    return new Promise((res, rej) => {
      this.reportApi
        .getInventoryReportPaginated({
          ...criteria,
          xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
        })
        .then(invReportPageDTO => {
          res(new GenericPage<InvReportDTO>(InvReportDTO).fromDTOPage(invReportPageDTO as DTOPage<InvReportDTO>));
        })
        .catch(err => rej());
    });
  }

  getInventoryRegistryPaginated(criteria: GetInventoryRegistryPaginatedByFiltersRequest): Promise<DomainPage<InvRegistryDTO>> {
    return new Promise((res, rej) => {
      this.reportApi
        .getInventoryRegistryPaginatedByFilters({
          ...criteria,
          xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
        })
        .then(invRegistryPageDTO => {
          res(new GenericPage<InvRegistryDTO>(InvRegistryDTO).fromDTOPage(invRegistryPageDTO as DTOPage<InvRegistryDTO>));
        })
        .catch(err => rej());
    });
  }

  getStockPaginated(criteria: GetLatestStockReportRequest): Promise<DomainPage<StockDTO>> {
    return new Promise((res, rej) => {
      this.reportApi
        .getLatestStockReport({ ...criteria, xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? "" })
        .then(stockPageDTO => {
          res(new GenericPage<StockDTO>(StockDTO).fromDTOPage(stockPageDTO as DTOPage<StockDTO>));
        })
        .catch(err => rej());
    });
  }

  getStockByMaterial(criteria: GetStockReportRequest): Promise<StockReportDTO> {
    return this.reportApi.getStockReport({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }

  setConfigDailyStockReport(criteria: ConfigDailyStockReportRequest): Promise<DailyStockReportResponseDTO> {
    return this.reportApi.configDailyStockReport({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }

  getLastConfigDailyStockReport(criteria: GetLastConfigDailyStockReportRequest): Promise<DailyStockReportResponseDTO> {
    return this.reportApi.getLastConfigDailyStockReport({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }

  getAdjustedMaterialStock(criteria: AdjustmentMaterialStockRequest): Promise<ListResponseDTO> {
    return this.reportApi.adjustmentMaterialStock({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }
}
