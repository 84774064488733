import { useState } from "react";
import { useResolve } from "@movicoders/di";
import { useSnackbar } from "@movicoders/ui";
import { useTranslation } from "react-i18next";
import { Product } from "@domain/model/Product";
import { EventDTO, ResponseError } from "@clients/aggrego-proxy";
import { ChildrenFormErrors } from "./form-children/ChildrenForm";
import { IUserService, UserService } from "@domain/services/IUserService";
import { EventRepository } from "@infrastructure/repositories/event-repository";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { IParentFormErrors, ParentFormErrors } from "./form-parent/parent-form-validations";
import { handleError } from "@utils/error-helper";

const VIEW_I18N = "hierarchy.creation.";

export const useHierarchyCreationViewmodel = () => {
  const { t } = useTranslation();
  const [parent, setParent] = useState<Product>(new Product());
  const [children, setChildren] = useState<Product[]>([]);
  const [parentErrors, setParentErrors] = useState<ParentFormErrors>(new ParentFormErrors());
  const [isLoadingView, setIsLoadingView] = useState(false);
  const [isClearingView, setIsClearingView] = useState(false);

  const settingsService = useResolve<ISettingsService>(SettingsService);

  const eventRepository = useResolve<EventRepository>(EventRepository);
  const userService = useResolve<IUserService>(UserService);
  const { show } = useSnackbar();

  // Gets if batch or versionlabel is showed (teltronic)
  const hasVersionLabel = userService.user?.hasVersion ?? false;

  const updateParent = (updatedParent: Product) => {
    setIsClearingView(false);
    clearParentErrors(updatedParent);
    setParent(updatedParent as unknown as Product);
  };

  const addChildToParent = (child: Product): ChildrenFormErrors => {
    setIsClearingView(false);
    setChildren([...children, child]);
    return new ChildrenFormErrors();
  };

  const checkParent = (): boolean => {
    const errors: IParentFormErrors = {
      qty: parent?.qty === undefined || parent?.qty <= 0,
      manufacturingDate: parent?.manufacturingDate === undefined || parent?.manufacturingDate === null,
      materialCode: checkMaterialCode(),
      batch: checkMaterialBatch(),
      serialNumber: checkSerialNumber(),
      uom: parent?.uom === undefined || parent?.uom.trim() === ""
    };

    let currentParentErrors: ParentFormErrors = Object.assign(new ParentFormErrors(), errors);
    currentParentErrors = Object.setPrototypeOf(currentParentErrors, Object.getPrototypeOf(new ParentFormErrors()));
    currentParentErrors.checkDate(parent?.manufacturingDate);

    setParentErrors(currentParentErrors);
    return !currentParentErrors.hasErrors();
  };

  const checkMaterialCode = () => {
    return parent?.materialCode === undefined || parent?.materialCode.trim() === "";
  };

  const checkMaterialBatch = () => {
    if (parent?.serialNumber === undefined || parent?.serialNumber.trim() === "") {
      return parent?.batch === undefined || parent?.batch.trim() === "";
    } else {
      return false;
    }
  };

  const checkSerialNumber = () => {
    if (parent?.batch === undefined || parent?.batch.trim() === "") {
      return parent?.serialNumber === undefined || parent?.serialNumber.trim() === "";
    } else {
      return false;
    }
  };

  const sendCreationRequest = async () => {
    setIsLoadingView(true);
    const newEvent: EventDTO = {
      action: "AGGREGATE",
      actionDate: parent?.manufacturingDate,
      agent: userService.current()?.email,
      departure: parent?.createdAt,
      destination: "",
      eventDate: new Date(),
      extension: "",
      order: parent?.manufactureOrder,
      parent: {
        batch: parent?.batch,
        materialCode: parent?.materialCode,
        qty: (parent?.qty ?? "0").toString(),
        serialNumber: parent?.serialNumber,
        uom: parent?.uom
      },
      qty: (parent?.qty ?? "0").toString(),
      uom: parent?.uom,
      user: userService.current()?.id,
      children: children.map(child => {
        return {
          batch: child?.batch,
          materialCode: child?.materialCode,
          qty: String(child.qty),
          serialNumber: child?.serialNumber,
          uom: child?.uom
        };
      })
    };
    try {
      await eventRepository.create(newEvent);
      show(t(VIEW_I18N + "success"), "success");
      clearView();
    } catch (error) {
      const response = await handleError(error as ResponseError);
      const errorMessage = response?.errors && VIEW_I18N + response.errors[0].errorCode?.replaceAll(/[ _]/g, ".").toLowerCase();
      show(t(errorMessage ?? t(VIEW_I18N + "error")), "error");
    } finally {
      setIsLoadingView(false);
    }
  };

  const doCreation = () => {
    checkParent() && isFinalParentValid() && sendCreationRequest();
  };

  const updateChild = (child: Product) => {
    setChildren(children.map(item => (item.id === child.id ? child : item)));
  };

  const removeChild = (child: Product) => {
    setChildren(children.filter(item => item.id !== child.id));
  };

  const clearView = () => {
    setChildren([]);
    setParent(new Product());
    setIsClearingView(true);
  };

  const isFinalParentValid = () => {
    if (
      parent?.materialCode !== undefined &&
      parent?.materialCode.trim() !== "" &&
      children !== undefined &&
      children.length > 0
    ) {
      return true;
    } else {
      show(t("products.create.hierarchy.no.children"), "error");
      return false;
    }
  };

  const clearParentErrors = (updatedParent: Product) => {
    for (const key in parentErrors) {
      if (parentErrors[key as keyof ParentFormErrors]) {
        if (updatedParent[key as keyof Product] !== parent?.[key as keyof Product]) {
          checkParentProductErrorsByKey(key);
        }
      }
    }
  };

  const checkParentProductErrorsByKey = (key: string) => {
    if (key === "batch" || key === "serialNumber") {
      let currentParentErrors = Object.assign({}, parentErrors, { batch: false, serialNumber: false });
      currentParentErrors = Object.setPrototypeOf(currentParentErrors, Object.getPrototypeOf(new ParentFormErrors()));
      setParentErrors(currentParentErrors);
    } else {
      let currentParentErrors = Object.assign({}, parentErrors, { [key as keyof ParentFormErrors]: false });
      currentParentErrors = Object.setPrototypeOf(currentParentErrors, Object.getPrototypeOf(new ParentFormErrors()));
      setParentErrors(currentParentErrors);
    }
  };

  return {
    updateParent,
    parent,
    children,
    parentErrors,
    addChildToParent,
    doCreation,
    updateChild,
    removeChild,
    isLoadingView,
    isClearingView,
    settingsService,
    hasVersionLabel
  };
};
