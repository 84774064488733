import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import { useTranslation } from "react-i18next";
import Stakeholder from "@domain/model/Stakeholder";
import { Grid, IconButton, Typography } from "@mui/material";
import { Card } from "@components/master-data-view/card/card";

export const StakeholdersCard = (props: {
  stakeholder: Stakeholder;
  handleEdit: (id: string) => void;
  handleActive: (item: Stakeholder) => void;
  handleStakeholderOpen: (stakeholder: Stakeholder) => void;
}) => {
  const { t } = useTranslation();
  const { stakeholder, handleEdit, handleActive, handleStakeholderOpen } = props;

  const renderChild = (name: string[], value: string[]) => {
    return (
      <div className="value-card-container" data-cy={`type-of-family-card-${name}-container`}>
        <div className="title-content">
          <Grid container direction="row" columnSpacing={{ xs: 1 }}>
            <Grid item xs={5}>
              <Typography variant="cardlabel" textAlign="left">
                {t(name[0])}
              </Typography>
              <Typography variant="cardvalue" data-cy={`value`} sx={{ wordBreak: "keep-all" }}>
                {value[0]}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="cardlabel" textAlign="left">
                {t(name[1])}
              </Typography>
              <Typography variant="cardvalue" data-cy={`value`} sx={{ wordBreak: "break-word" }}>
                {value[1]}
              </Typography>
            </Grid>
            <Grid item xs={2} justifyContent="flex-end" sx={{ display: "flex" }}>
              <IconButton onClick={() => handleStakeholderOpen(stakeholder)}>
                <Icon id="stakeholder-loupe-icon" icon="search" color={COLORS.secondary} />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

  const rendererCard = (data: Stakeholder) => {
    return (
      <Card
        isActive={data?.active}
        key={data?.id}
        popover={{
          handleEdit: () => handleEdit(data.id ?? ""),
          handleActive: () => handleActive(data),
          activateTitle: data.active ? t("root.deactivate") : t("root.activate")
        }}
        id={"id-card-" + data.id}
        nameValue={data?.name ?? ""}>
        {renderChild(["stakeholders.code", "stakeholders.fiscalId"], [data?.code ?? "", data?.fiscalId ?? ""])}
      </Card>
    );
  };

  return rendererCard(stakeholder);
};
