import { COLORS } from "@theme";
import Icon from "../../../icons/icon";
import { useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { useTranslation } from "react-i18next";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid";
import { Button, Dialog, Grid, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";

export const DataGridDisplayDownloadMenu = (props: {
  columns: GridColDef[];
  disabledFields: string[];
  handleDownloadCSV: (
    disabledFields: string[],
    columns: GridColDef[],
    parseCSVInDownload?: (data: GridValidRowModel) => GridValidRowModel
  ) => void;
  handlePrintScreen: () => void;
  getDataToPDF: (
    disabledFields: string[],
    columns: GridColDef[]
  ) => { name: string; headers: GridColDef[]; data: GridValidRowModel[]; tableName: string };
  PdfTable: (props: { headers: GridColDef[]; data: GridValidRowModel[]; tableName: string }) => JSX.Element;
  parseCSVInDownload?: (data: GridValidRowModel) => GridValidRowModel;
}) => {
  const { columns, disabledFields, handleDownloadCSV, handlePrintScreen, getDataToPDF, PdfTable, parseCSVInDownload } = props;

  const { t } = useTranslation();

  const [openDownloadDialog, setOpenDownloadDialog] = useState(false);

  // Defines if the menu is open or closed and its position.
  const [anchorElDownloadMenu, setAnchorElDownloadMenu] = useState<null | HTMLElement>(null);
  const openDownloadMenu = Boolean(anchorElDownloadMenu);

  const handleOpenDownloadMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElDownloadMenu(event.currentTarget);
  };

  const handleCloseDownloadMenu = () => {
    setAnchorElDownloadMenu(null);
  };

  const handleOpenDownloadDialog = () => {
    setOpenDownloadDialog(true);
  };

  const handleCloseDownloadDialog = () => {
    setOpenDownloadDialog(false);
  };

  // PDF Related variables and functions.
  const componentPDF = useRef(null);

  return (
    <>
      <Tooltip title={t("tooltip.access.download.menu")}>
        <IconButton id="table-download-menu-open" onClick={handleOpenDownloadMenu} className="table-icon-button">
          <Icon icon="download" color={COLORS.gray} />
        </IconButton>
      </Tooltip>
      <Menu
        id="download-management-menu"
        aria-labelledby="download-management-menu"
        anchorEl={anchorElDownloadMenu}
        open={openDownloadMenu}
        onClose={handleCloseDownloadMenu}>
        <Grid container direction="column" mx="0.5rem" rowSpacing="0.5rem">
          <Grid
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mx="0.5rem"
            sx={{ fontSize: "small", fontWeight: 600, color: COLORS.secondary }}>
            {t("table.download.title")}
            <IconButton onClick={handleCloseDownloadMenu}>
              <Icon icon="close" />
            </IconButton>
          </Grid>
          <MenuItem
            id="table-download-csv-option"
            onClick={() => handleDownloadCSV(disabledFields, columns, parseCSVInDownload)}
            sx={{ fontSize: "small" }}>
            {t("table.download.csv")}
          </MenuItem>
          <MenuItem id="table-download-pdf-option" onClick={handleOpenDownloadDialog} sx={{ fontSize: "small" }}>
            {t("table.download.pdf")}
          </MenuItem>
          <MenuItem onClick={handlePrintScreen} sx={{ fontSize: "small" }}>
            {t("table.download.print")}
          </MenuItem>
        </Grid>
      </Menu>
      {/* Component to be printed when downloading PDF */}
      <Dialog
        open={openDownloadDialog}
        fullScreen
        onClose={handleCloseDownloadDialog}
        sx={{
          ml: 4,
          padding: 1,
          rowSpacing: 1
        }}
        id="table-download-pdf-preview">
        <DialogTitle px="1rem">
          <Grid container justifyContent="space-between">
            <ReactToPrint
              documentTitle={getDataToPDF(disabledFields, columns).name}
              trigger={() => (
                <Button color="secondary">
                  <Typography>
                    {t("table.download.title") + " " + getDataToPDF(disabledFields, columns).name.split("_")[0]}
                  </Typography>
                </Button>
              )}
              content={() => componentPDF.current}
            />
            <IconButton onClick={handleCloseDownloadDialog}>
              <Icon icon="close" color={COLORS.white} />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent ref={componentPDF}>
          <PdfTable
            headers={getDataToPDF(disabledFields, columns).headers}
            data={getDataToPDF(disabledFields, columns).data}
            tableName={getDataToPDF(disabledFields, columns).tableName}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
