import { useEffect, useState } from "react";
import { Product } from "@domain/model/Product";

export const useParentFormViewmodel = (updateParent: (product: Product) => void, isClearingView: boolean) => {
  const [product, setProduct] = useState(new Product());

  useEffect(() => {
    if (isClearingView) {
      setProduct(new Product());
    }
  }, [isClearingView]);

  const updateProduct = (attr: keyof Product, value: Product[keyof Product]) => {
    const updatedProd = {
      ...product,
      [attr]: value as unknown as typeof attr
    };

    setProduct(updatedProd as unknown as Product);
    updateParent(updatedProd as unknown as Product);
  };

  return { updateProduct, product };
};
