import { t } from "i18next";
import { COLORS } from "@theme";
import Icon from "../icons/icon";
import { ROUTER_PATHS } from "@constants";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import { persistedSnackbarStyles } from "./persistedAlertStyles";

export const PersistedAlert = (props: { message: string; goTo: string }) => {
  const styles = persistedSnackbarStyles;

  const navigate = useNavigate();

  return (
    <Grid style={{ ...styles.alert_grid, marginBottom: 10 }}>
      <Grid flex={0.5} style={{ marginLeft: 6, paddingBottom: 16 }}>
        <Icon icon="warning" color={COLORS.alert} />
      </Grid>
      <Grid flex={9}>
        <Typography id="report-title-typo" fontWeight={"bold"}>
          {t("atention.title")}
        </Typography>
        <Typography id="report-title-typo" fontSize={15}>
          {props.message}
        </Typography>
      </Grid>
      <Grid flex={2} style={{ textAlign: "end" }}>
        {props.goTo === "users" ? (
          <Button
            variant="primary"
            onClick={() => {
              navigate("/" + ROUTER_PATHS.users);
            }}
            id="master-data-create-button">
            {t("go.to.users.view")}
          </Button>
        ) : (
          <Button
            variant="primary"
            onClick={() => {
              navigate("/" + ROUTER_PATHS.warehouses);
            }}
            id="master-data-create-button">
            {t("go.to.warehouses.view")}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
