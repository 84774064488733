/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RelabelDTO
 */
export interface RelabelDTO {
    /**
     * 
     * @type {string}
     * @memberof RelabelDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RelabelDTO
     */
    newContainerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RelabelDTO
     */
    oldContainerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RelabelDTO
     */
    operatorCode?: string;
    /**
     * 
     * @type {number}
     * @memberof RelabelDTO
     */
    quantity?: number;
    /**
     * 
     * @type {Date}
     * @memberof RelabelDTO
     */
    relabelDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof RelabelDTO
     */
    uom?: string;
    /**
     * 
     * @type {string}
     * @memberof RelabelDTO
     */
    user?: string;
    /**
     * 
     * @type {string}
     * @memberof RelabelDTO
     */
    warehouseCode?: string;
}

/**
 * Check if a given object implements the RelabelDTO interface.
 */
export function instanceOfRelabelDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RelabelDTOFromJSON(json: any): RelabelDTO {
    return RelabelDTOFromJSONTyped(json, false);
}

export function RelabelDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelabelDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'newContainerCode': !exists(json, 'newContainerCode') ? undefined : json['newContainerCode'],
        'oldContainerCode': !exists(json, 'oldContainerCode') ? undefined : json['oldContainerCode'],
        'operatorCode': !exists(json, 'operatorCode') ? undefined : json['operatorCode'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'relabelDate': !exists(json, 'relabelDate') ? undefined : (new Date(json['relabelDate'])),
        'uom': !exists(json, 'uom') ? undefined : json['uom'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'warehouseCode': !exists(json, 'warehouseCode') ? undefined : json['warehouseCode'],
    };
}

export function RelabelDTOToJSON(value?: RelabelDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'newContainerCode': value.newContainerCode,
        'oldContainerCode': value.oldContainerCode,
        'operatorCode': value.operatorCode,
        'quantity': value.quantity,
        'relabelDate': value.relabelDate === undefined ? undefined : (value.relabelDate.toISOString()),
        'uom': value.uom,
        'user': value.user,
        'warehouseCode': value.warehouseCode,
    };
}

