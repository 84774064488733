/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PolygonCoordinateDTO
 */
export interface PolygonCoordinateDTO {
    /**
     * 
     * @type {boolean}
     * @memberof PolygonCoordinateDTO
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PolygonCoordinateDTO
     */
    endX?: number;
    /**
     * 
     * @type {number}
     * @memberof PolygonCoordinateDTO
     */
    endZ?: number;
    /**
     * 
     * @type {number}
     * @memberof PolygonCoordinateDTO
     */
    startX?: number;
    /**
     * 
     * @type {number}
     * @memberof PolygonCoordinateDTO
     */
    startZ?: number;
    /**
     * 
     * @type {number}
     * @memberof PolygonCoordinateDTO
     */
    y?: number;
}

/**
 * Check if a given object implements the PolygonCoordinateDTO interface.
 */
export function instanceOfPolygonCoordinateDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PolygonCoordinateDTOFromJSON(json: any): PolygonCoordinateDTO {
    return PolygonCoordinateDTOFromJSONTyped(json, false);
}

export function PolygonCoordinateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PolygonCoordinateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'endX': !exists(json, 'endX') ? undefined : json['endX'],
        'endZ': !exists(json, 'endZ') ? undefined : json['endZ'],
        'startX': !exists(json, 'startX') ? undefined : json['startX'],
        'startZ': !exists(json, 'startZ') ? undefined : json['startZ'],
        'y': !exists(json, 'y') ? undefined : json['y'],
    };
}

export function PolygonCoordinateDTOToJSON(value?: PolygonCoordinateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'endX': value.endX,
        'endZ': value.endZ,
        'startX': value.startX,
        'startZ': value.startZ,
        'y': value.y,
    };
}

