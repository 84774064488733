import { TUserRoleEnum } from "@domain/interface/User";
import { TileApi } from "@infrastructure/api/tile-api";
import { IUserService } from "@domain/services/IUserService";
import { GetTilesByWarehouseIDRequest } from "@clients/aggrego-proxy";
import { IImpersonationService } from "@domain/services/IImpersonationService";

export class FindTilesUseCase {
  constructor(
    private readonly tileApi: TileApi,
    private readonly userService: IUserService,
    private readonly impersonationService: IImpersonationService
  ) {}

  public async byTenant(status?: "ALL" | "ACTIVE" | "INACTIVE") {
    const isRole = (role: TUserRoleEnum) => {
      return this.userService.user?.roles.includes(role);
    };

    const currentTenant = isRole("SUPER_ADMIN")
      ? this.impersonationService.persistedState?.impersonatedTenant ?? ""
      : this.userService.user?.tenantId ?? "";

    return await this.tileApi.getByTenantId({ tenantId: currentTenant, fullDTO: false, status: status ?? "ALL" });
  }

  public async byWarehouseId(criteria: GetTilesByWarehouseIDRequest) {
    return await this.tileApi.getByWarehouseId(criteria);
  }
}
