import Warehouse from "./Warehouse";

export class DrawerState {
  drawer: boolean;
  warehouses: Warehouse[];
  selectedWarehouse: string;
  masterdataMenu: boolean;
  logiscticMenu: boolean;
  reportsMenu: boolean;
  trackAndTraceMenu: boolean;

  constructor() {
    this.drawer = false;
    this.warehouses = [];
    this.selectedWarehouse = "";
    this.masterdataMenu = false;
    this.logiscticMenu = false;
    this.reportsMenu = false;
    this.trackAndTraceMenu = false;
  }
}
