import Family from "@domain/model/Family";
import { useFamilySave } from "./useFamilySave";
import { useCheckStatus } from "@hooks/useCheckStatus";
import { useFamilyPagination } from "./useFamilyPagination";
import { useFamilyHandleData } from "./useFamilyHandleData";
import { useFamilyActiveStatus } from "./useFamilyActiveStatus";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { useResolve } from "@movicoders/di";

export const useFamilyListViewModel = () => {
  const settingsService = useResolve<ISettingsService>(SettingsService);

  const {
    currentPageService,
    currentPagePersistedState,
    loading,
    loadingDetail,
    families,
    setDataTypesOfFamilies,
    findTypeOfFamiliesByTenantUseCase,
    selectedFamily,
    dataTypesOfFamilies,
    getFamiliesPaginated,
    goToCreate,
    goToDetail,
    goToList
  } = useFamilyHandleData();

  const { getCurrentStatus } = useCheckStatus();

  const { handlePagination } = useFamilyPagination(getFamiliesPaginated, getCurrentStatus);
  const { handleEdit, handleSave } = useFamilySave(
    currentPageService,
    currentPagePersistedState,
    selectedFamily as Family,
    goToDetail,
    goToList,
    getCurrentStatus,
    setDataTypesOfFamilies,
    findTypeOfFamiliesByTenantUseCase
  );
  const { isActivating, selectedFamily4Status, toggleFamilyStatus, handleOpenToggleActiveStatusDialog, onCloseDialog } =
    useFamilyActiveStatus(currentPageService, currentPagePersistedState, getFamiliesPaginated, getCurrentStatus);

  return {
    families,
    selectedFamily,
    isActivating,
    selectedFamily4Status,
    dataTypesOfFamilies,
    loading,
    loadingDetail,
    goToCreate,
    goToList,
    onCloseDialog,
    toggleFamilyStatus,
    handleOpenToggleActiveStatusDialog,
    handleSave,
    handleEdit,
    handlePagination,
    settingsService
  };
};
