import { COLORS } from "@theme";
import { Divider, Grid } from "@mui/material";
import { MasterDataTitleStyles } from "@components/master-data-view/MasterDataTitleStyles";

export const LogisticTitle = (props: { id: string; name: string; icon: JSX.Element }) => {
  const { id, name, icon } = props;
  const styles = MasterDataTitleStyles();

  return (
    <>
      <Grid container wrap="nowrap" spacing={{ xs: "1rem" }} id="second-grid-container">
        <Grid item alignSelf="center">
          {icon}
        </Grid>
        <Grid id={id} item sx={{ color: COLORS.secondary, fontSize: "x-large", fontWeight: 600 }}>
          {name}
        </Grid>
      </Grid>
      <Divider sx={styles.divider} />
    </>
  );
};
