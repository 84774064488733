import { useState } from "react";
import Paper from "@mui/material/Paper";
import { GridSortModel } from "@mui/x-data-grid";
import { Button, Grid, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import { Trans, useTranslation } from "react-i18next";
import { useHandlePagination } from "./hooks/useHandlePagination";
import { CustomToolbar } from "./toolbar/data-grid-display-toolbar";
import { dataGridDisplayStyles } from "./table/display-grid-styles";
import { DataDisplayGridProps } from "./table/config/data-grid-display-props";
import { CSSSelectorObjectOrCssVariables } from "@mui/system/styleFunctionSx";
import { useDataGridDisplayViewModel } from "./table/hooks/useDataGridDisplayViewModel";
import { DataGridDisplayGenericPDF } from "./toolbar/components/data-grid-display-genericpdf";
import { DataGridDisplayPageSizeSelector } from "./footer/components/data-grid-display-footer-page-size-selector";
import { DataGridDisplayPageSelector } from "./footer/components/data-grid-display-footer-page-selector";
import { dataGridDisplayFooterStyles } from "./footer/styles/data-grid-display-footer-styles";
import Icon from "@components/icons/icon";
import { COLORS } from "@theme";

export const DataGridDisplay = (props: DataDisplayGridProps) => {
  const {
    data,
    columns,
    mode,
    viewName,
    toolbarProps,
    hideColumnsByField,
    headerHeight,
    rowHeight,
    rowsPerPageOptions,
    hideRowCount,
    hideFooter,
    rowId,
    customPDF,
    backendTotalElements,
    handleBackendPagination,
    handleBackendSort,
    customDisplayComponent = () => undefined,
    searchBarFilter,
    customContainerStyles,
    customDataGridStyles,
    customToolbarComponent,
    onRowClick,
    checkboxSelection,
    fieldToUseAsId,
    selectionRowState,
    setSelectionRowState,
    handleCheckboxSelection,
    handleMultipleSelectionAction
  } = props;

  const { getVisibilityModel } = useDataGridDisplayViewModel();

  const [currentData, setCurrentData] = useState(data);
  const [unsortedData, setUnsortedData] = useState(data);

  const { t } = useTranslation();
  const styles = dataGridDisplayStyles();
  const footerStyles = dataGridDisplayFooterStyles();
  const validRowsPerPageOptions = rowsPerPageOptions ?? [10, 25, 50, 100];

  const { pageSize, setPageSize, currentPage, setCurrentPage } = useHandlePagination(
    mode,
    data,
    currentData,
    validRowsPerPageOptions,
    handleBackendPagination,
    viewName
  );

  const handleFrontendSort = (changeArgs: GridSortModel) => {
    if (changeArgs[0]) {
      const sortedData = [...currentData].sort((a, b) => {
        const firstValue = a[changeArgs[0].field];
        const secondValue = b[changeArgs[0].field];
        // Sort string.
        if (typeof firstValue === "string") {
          if (changeArgs[0].sort === "asc") {
            return firstValue.localeCompare(secondValue);
          }
          return secondValue.localeCompare(firstValue);
        }
        // Sort number | boolean.
        else {
          if (changeArgs[0].sort === "asc") {
            return firstValue - secondValue;
          }
          return secondValue - firstValue;
        }
      });

      setCurrentData(sortedData);
    } else {
      setCurrentData(unsortedData);
    }
  };

  return (
    <Grid id="table-grid-container" container>
      <CustomToolbar
        data={data}
        columns={columns}
        currentViewName={viewName}
        currentData={currentData}
        setCurrentData={setCurrentData}
        toolbarProps={toolbarProps}
        unsortedData={unsortedData}
        searchBarRecoveredFilter={searchBarFilter}
        setUnsortedData={setUnsortedData}
        PdfTable={customPDF ?? DataGridDisplayGenericPDF}
      />

      {!hideRowCount && (
        <Grid container alignItems="center">
          <Typography id="data-result">
            <Trans>{t("table.current.rows", { quantity: backendTotalElements ?? currentData.length })}</Trans>
          </Typography>
        </Grid>
      )}

      {customDisplayComponent && customDisplayComponent([]) === undefined ? (
        <Paper
          sx={{
            ...(customContainerStyles as CSSSelectorObjectOrCssVariables),
            ...styles.data_grid_display_container
          }}>
          {customToolbarComponent && <div>{customToolbarComponent}</div>}
          {/* DELETE MULTIPLE STATUS REPORT BUTTON */}
          {checkboxSelection ? (
            <Button
              variant={!selectionRowState || selectionRowState.length === 0 ? "disabled" : "alert"}
              disabled={!selectionRowState || selectionRowState.length === 0}
              onClick={handleMultipleSelectionAction}
              sx={{ marginBottom: "1rem", width: "10rem" }}
              startIcon={<Icon icon="delete" color={COLORS.white} />}>
              {t("root.delete")}
            </Button>
          ) : (
            <></>
          )}
          {/* END DELETE MULTIPLE STATUS REPORT BUTTON */}
          <DataGrid
            columns={columns}
            rows={currentData}
            rowsPerPageOptions={validRowsPerPageOptions}
            autoHeight
            page={currentPage}
            pageSize={pageSize}
            columnVisibilityModel={getVisibilityModel(hideColumnsByField ?? [], viewName)}
            sortingMode={mode}
            paginationMode={mode}
            onSortModelChange={handleBackendSort ?? handleFrontendSort}
            sx={{ ...customDataGridStyles, border: "none" }}
            rowCount={currentData.length}
            headerHeight={headerHeight ?? 48}
            rowHeight={rowHeight ?? 48}
            getRowId={rowId}
            onRowClick={onRowClick}
            checkboxSelection={checkboxSelection}
            selectionModel={selectionRowState && selectionRowState.map(row => row[fieldToUseAsId ?? "id"])}
            onSelectionModelChange={ids => {
              const selectedIDs = new Set(ids);
              const selectedRows = currentData.filter(row => selectedIDs.has(row[fieldToUseAsId ?? "id"]));
              setSelectionRowState && setSelectionRowState(selectedRows);
              handleCheckboxSelection && handleCheckboxSelection(selectedRows);
            }}
            components={{
              Footer: () => <></>,
              NoRowsOverlay: () => (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "1rem" }}>
                  <Typography variant="summarydashboard" justifyItems="center" sx={{ fontWeight: 500 }}>
                    {t("table.no.rows")}
                  </Typography>
                  <Typography variant="summarydashboard" sx={{ fontSize: "0.875rem" }}>
                    {t("table.no.rows.description")}
                  </Typography>
                </div>
              )
            }}
          />
        </Paper>
      ) : (
        currentData.length > 0 && customDisplayComponent(currentData)
      )}

      {!hideFooter && (
        <Grid container columnSpacing={1} sx={footerStyles.data_grid_display_footer}>
          <DataGridDisplayPageSizeSelector
            pageSize={pageSize}
            rowsPerPageOptions={validRowsPerPageOptions}
            setPageSize={setPageSize}
          />
          <DataGridDisplayPageSelector
            dataLength={currentData.length}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            backendTotalElements={backendTotalElements}
          />
        </Grid>
      )}
    </Grid>
  );
};
