import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";

export const impersonationStyles = (drawerWidth: number | string) => {
  const list_item: SxProps<Theme> = {
    width: drawerWidth,
    height: "3.5rem"
  };
  return { list_item };
};
