import { Tab, Tabs } from "@mui/material";
import { useResolve } from "@movicoders/di";
import { useEffect, useState } from "react";
import Warehouse from "@domain/model/Warehouse";
import { homeTabStyles } from "../homeTabsStyles";
import { DrawerState } from "@domain/model/DrawerState";
import { DrawerService, IDrawerService } from "@domain/services/IDrawerService";

export const HomeTabs = (props: { data: Warehouse[]; onClick: (id: string) => void }) => {
  const { data, onClick } = props;
  const service = useResolve<IDrawerService>(DrawerService);
  const persistedState = service.persistedState ?? new DrawerState();

  const [selectedWarehouse, setSelectedWarehouse] = useState(persistedState.selectedWarehouse);

  const styles = homeTabStyles;

  const handleLocationChange = (pathname: string) => {
    const newId = pathname.split("/").pop();

    if (newId !== undefined) {
      if (newId === "" || newId === "dashboard" || newId === "dashboard/undefined") {
        setSelectedWarehouse(data[0]?.id ?? "");
        onClick(data[0]?.id ?? "");
      } else {
        setSelectedWarehouse(newId);
      }
    }
  };

  useEffect(() => {
    if (data.length > 0) handleLocationChange(window.location.pathname);
  }, [window.location.pathname, data.length]);

  return (
    <Tabs
      value={selectedWarehouse === "" || selectedWarehouse === "dashboard" ? false : selectedWarehouse}
      TabIndicatorProps={{ style: { display: "none" } }}>
      {data.map((warehouse: Warehouse) => {
        return (
          <Tab
            value={warehouse.id}
            sx={selectedWarehouse === warehouse.id ? styles.dashboard_tabs_true : styles.dashboard_tabs_false}
            key={warehouse.id}
            label={warehouse.name}
            onClick={() => {
              onClick(warehouse.id ?? "");
            }}
          />
        );
      })}
    </Tabs>
  );
};
