import { useResolve } from "@movicoders/di";
import { useEffect, useRef, useState } from "react";
import { InvReportDTO } from "@domain/model/InvReport";
import { IRepositoryPaginated } from "@movicoders/domain";
import { LastPageState } from "@domain/model/LastPageState";
import { useListDetailPaginatedViewModel } from "@movicoders/ui";
import { ObjectType } from "@movicoders/ui/lib/ViewModels/types";
import { IUserService, UserService } from "@domain/services/IUserService";
import { useHandleSelectedWarehouse } from "@hooks/useHandleSelectedWarehouse";
import { ILastPageService, LastPageService } from "@domain/services/ILastPageService";
import { InventoryReportRepository } from "@infrastructure/repositories/inventory-report-repository";
import { FullUserDTOCompanyLicenseEnum, GetInventoryReportPaginatedRequest } from "@clients/aggrego-proxy";

export const useInventoryReportHandleData = () => {
  const {
    data: inventoryReports,
    loading,
    getPaginated
  } = useListDetailPaginatedViewModel(
    InventoryReportRepository as unknown as ObjectType<IRepositoryPaginated<InvReportDTO, GetInventoryReportPaginatedRequest>>
  );
  const getInvReportPaginated = useRef(getPaginated).current;

  const currentPageService = useResolve<ILastPageService>(LastPageService);
  const currentPagePersistedState = currentPageService.persistedState ?? new LastPageState();
  const currentUserPersistedState = useResolve<IUserService>(UserService);

  const [columnsByMode, setColumnsByMode] = useState<string[]>([]);

  const { selectedWarehouseCode } = useHandleSelectedWarehouse();

  useEffect(() => {
    generateColumns();
  }, []);

  const generateColumns = () => {
    switch (currentUserPersistedState.user?.companyLicense) {
      case FullUserDTOCompanyLicenseEnum.Im:
        setColumnsByMode(["id", "containerCode", "locationCode"]);
        break;
      case FullUserDTOCompanyLicenseEnum.Wm:
        setColumnsByMode(["id", "containerCode"]);
        break;
      case FullUserDTOCompanyLicenseEnum.WmWithContainer:
        setColumnsByMode(["id"]);
        break;
    }
  };

  return {
    inventoryReports,
    columnsByMode,
    selectedWarehouseCode,
    currentPageService,
    currentPagePersistedState,
    loading,
    getInvReportPaginated
  };
};
