import Family from "@domain/model/Family";
import { useResolve } from "@movicoders/di";
import { useListViewModel } from "@movicoders/ui";
import { useEffect, useRef, useState } from "react";
import { PortalTypes } from "@domain/model/PortalTypes";
import { LastPageState } from "@domain/model/LastPageState";
import { ReportEnum } from "@pages/settings/settings-interfaces";
import { StatusOptionsEnum } from "@domain/model/StatusOptionsEnum";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { IUserService, UserService } from "@domain/services/IUserService";
import { useHandleSelectedWarehouse } from "@hooks/useHandleSelectedWarehouse";
import { ILastPageService, LastPageService } from "@domain/services/ILastPageService";
import { WarehouseStatusRepository } from "@infrastructure/repositories/warehouse-status-repository";
import { IImpersonationService, ImpersonationService } from "@domain/services/IImpersonationService";
import { FindFamiliesByTenantUseCase } from "@application/master-data/families/find-families-by-tenant-use-case";
import { useImpersonationViewModel } from "@components/layout/drawer/components/impersonation/useImpersonationViewModel";

export const useWarehouseStatusHandleData = () => {
  const { dataPaginated, getPaginated, loading } = useListViewModel(WarehouseStatusRepository);

  const warehouseStatusFetch = useRef(getPaginated).current;
  const { selectedWarehouseCode } = useHandleSelectedWarehouse();

  const currentPageService = useResolve<ILastPageService>(LastPageService);
  const currentPagePersistedState = currentPageService.persistedState ?? new LastPageState();
  const settingsService = useResolve<ISettingsService>(SettingsService);
  const currentUserPersistedState = useResolve<IUserService>(UserService);

  /* Using Warehouse Status as home page */
  const userService = useResolve<IUserService>(UserService);
  const impersonationService = useResolve<IImpersonationService>(ImpersonationService);
  const impersonationPersistedState = impersonationService.persistedState;

  const findFamiliesByTenantUseCase = useResolve(FindFamiliesByTenantUseCase);
  const [families, setFamilies] = useState<Family[]>([]);

  const { onChangePortal } = useImpersonationViewModel();

  useEffect(() => {
    onChangePortal(PortalTypes.AGGREGO);
  }, []);

  useEffect(() => {
    if (settingsService.persistedState?.reportsPermissions.includes(ReportEnum.WarehousesStatus)) {
      selectedWarehouseCode &&
        findFamiliesByTenantUseCase.execute(StatusOptionsEnum.All).then(result => {
          setFamilies(result);
        });
    }
  }, [settingsService.persistedState, selectedWarehouseCode]);

  const isViewPageReadyToLoad = () => {
    if (selectedWarehouseCode) {
      if (
        userService.user?.isCompletelyLoaded() ||
        (impersonationPersistedState?.impersonatedTenant && impersonationPersistedState.impersonatedTenant !== "")
      ) {
        return true;
      }
    }
    return false;
  };

  return {
    dataPaginated,
    families,
    loading,
    currentPageService,
    currentUserPersistedState,
    currentPagePersistedState,
    warehouseStatusFetch,
    selectedWarehouseCode,
    isViewPageReadyToLoad
  };
};
