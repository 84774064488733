import { useEffect, useState } from "react";
import { MovementEventDTO } from "@clients/aggrego-proxy";
import { WarehouseStatusDTO } from "@domain/model/WarehouseStatus";
import { FamilyRepository } from "@infrastructure/repositories/family-repository";
import { MaterialRepository } from "@infrastructure/repositories/material-repository";
import { WarehouseStatusRepository } from "@infrastructure/repositories/warehouse-status-repository";
import { useResolve } from "@movicoders/di";
import Material from "@domain/model/Material";
import { MovementsRepository } from "@infrastructure/repositories/movements-repository";

export const useHomeSummaryStock = (
  isDashboardInitialized: boolean,
  minLimitValue: number,
  maxLimitValue: number,
  minOffsetValue: number,
  selectedWarehouseCode: string | undefined
) => {
  const familyRepository = useResolve<FamilyRepository>(FamilyRepository);
  const materialRepository = useResolve<MaterialRepository>(MaterialRepository);
  const movementsRepository = useResolve<MovementsRepository>(MovementsRepository);
  const whStatusRepository = useResolve<WarehouseStatusRepository>(WarehouseStatusRepository);

  const [materialData, setMaterialData] = useState<Material[]>([]);
  const [totalMaterials, setTotalMaterials] = useState("");
  const [totalFamilies, setTotalFamilies] = useState("");
  //total of material's qty from warehouse status table
  const [totalStockUnits, setTotalStockUnits] = useState(0);
  //total of material's qty from movements report (action: "INVENTORY", "INVENTORY_INBOUND" or "INVENTORY_OUTBOUND")
  const [totalInventoryUnits, setTotalInventoryUnits] = useState(0);
  //total of material's qty from movements report (action: "EXIT")
  const [totalOutboundsMoves, setTotalOutboundsMoves] = useState(0);
  //total of material's price from warehouse status table (price is obtained from material table for each material)
  const [totalStockValue, setTotalStockValue] = useState(0);
  const [totalStockValueLoading, setTotalStockValueLoading] = useState(false);
  const [inventoryReliabilityResult, setInventoryReliabilityResult] = useState(0);
  const [inventoryReliabilityLoading, setInventoryReliabilityLoading] = useState(false);
  const [stockCoverageResult, setStockCoverageResult] = useState(0);

  const calculateWarehouseSummaryStock = () => {
    getAllMaterials();
    getAllFamilies();

    calculateTotalInventoryUnits();
    getLastMonthOutbounds();
  };

  /** MATERIAL BLOCK **/
  //Gets total of materials
  const getAllMaterials = () => {
    materialRepository.get().then(result => {
      setMaterialData(result);
    });
  };
  //When MaterialData is obtained
  useEffect(() => {
    if (isDashboardInitialized) {
      setTotalMaterials(materialData.length.toString() ?? "-");
      calculateTotalStockValue();
    }
  }, [materialData]);
  /** FINAL MATERIAL BLOCK **/

  //Gets total of families
  const getAllFamilies = () => {
    familyRepository.getPaginated({ limit: minLimitValue, offset: minOffsetValue, customFamilyFilter: {} }).then(result => {
      setTotalFamilies(result.totalElements?.toString() ?? "-");
    });
  };

  //Calculates Total Stock Value (SUM=(material qty from wh-status * this material price searched by code form material table))
  const calculateTotalStockValue = () => {
    setTotalStockValueLoading(true);
    whStatusRepository
      .getPaginated({
        limit: maxLimitValue,
        offset: 0,
        warehouseCode: selectedWarehouseCode ?? "",
        statusReportFilterDTO: { status: true }
      })
      .then(result => {
        setTotalStockUnits(0);
        setTotalStockValue(0);
        result.content?.forEach((row: WarehouseStatusDTO) => {
          const currentMaterialQty = row.qty ?? 0;
          setTotalStockUnits(prevState => prevState + currentMaterialQty);
          const currentMaterialCode = row.material;
          const currentMaterialPrice = materialData.find(material => material.code === currentMaterialCode)?.unitPrice ?? 0;
          setTotalStockValue(prevState => prevState + currentMaterialQty * currentMaterialPrice);
        });
      })
      .finally(() => {
        setTotalStockValueLoading(false);
      });
  };

  /** TOTAL INVENTORY BLOCK **/
  //Finds all movements where action is "INVENTORY", "INVENTORY_INBOUND" or "INVENTORY_OUTBOUND", and store total material qtys
  const calculateTotalInventoryUnits = () => {
    setInventoryReliabilityLoading(true);
    setTotalInventoryUnits(0);
    movementsRepository
      .getPaginated({
        limit: maxLimitValue,
        offset: 0,
        customMovementEventFilter: {
          warehouse: selectedWarehouseCode,
          action: ["ENTRANCE", "EXIT"]
        }
      })
      .then(result => {
        if (result.content && result.content?.length > 0) {
          result.content?.forEach((row: MovementEventDTO) => {
            setTotalInventoryUnits(prevState => prevState + (row?.quantity ?? 1));
          });
        } else {
          setTotalInventoryUnits(0);
        }
      })
      .catch(() => {
        setTotalInventoryUnits(0);
        setInventoryReliabilityResult(0);
      })
      .finally(() => {
        setInventoryReliabilityLoading(false);
      });
  };
  //When totalStockUnits and totalInventoryUnits are obtained, calculates Inventory Reliability
  useEffect(() => {
    const value = (Math.abs(totalInventoryUnits - totalStockUnits) / totalStockUnits) * 100;
    setInventoryReliabilityResult(100 - value);
    setInventoryReliabilityLoading(false);
  }, [totalStockUnits, totalInventoryUnits]);
  /** END TOTAL INVENTORY BLOCK **/

  /** TOTAL STOCK BLOCK **/
  //Finds all "OUTBOUND" movements from the last month and store total material qtys
  const getLastMonthOutbounds = () => {
    const currentDate = new Date();
    const lastMonth = new Date().setMonth(currentDate.getMonth() - 1);
    movementsRepository
      .getPaginated({
        limit: maxLimitValue,
        offset: 0,
        customMovementEventFilter: {
          warehouse: selectedWarehouseCode,
          action: ["EXIT"],
          initDate: new Date(lastMonth),
          endDate: currentDate
        }
      })
      .then(result => {
        setTotalOutboundsMoves(result.totalElements ?? 0);
      })
      .catch(() => {
        setTotalOutboundsMoves(0);
      });
  };
  //When totalStockUnits and totalOutboundsMoves are obtained, calculates Stock Coverage
  useEffect(() => {
    const value = totalStockUnits / (totalOutboundsMoves / 30);
    if (Number(value) && !isNaN(value) && value !== Infinity) {
      setStockCoverageResult(value);
    } else {
      setStockCoverageResult(0);
    }
  }, [totalStockUnits, totalOutboundsMoves]);
  /** END TOTAL STOCK BLOCK **/

  const updateReliability = () => {
    setInventoryReliabilityResult(0);
    calculateTotalInventoryUnits();
  };

  const updateTotalStockValue = () => {
    setTotalStockValue(0);
    calculateTotalStockValue();
  };

  const recalculateInventoryReliability = () => {
    calculateTotalStockValue();
    calculateTotalInventoryUnits();
  };

  const resetAllSummaryStock = () => {
    setMaterialData([]);
    setTotalMaterials("0");
    setTotalFamilies("0");
    setTotalStockUnits(0);
    setTotalInventoryUnits(0);
    setTotalOutboundsMoves(0);
    setTotalStockValue(0);
    setInventoryReliabilityResult(0);
    setStockCoverageResult(0);
  };

  return {
    calculateWarehouseSummaryStock,
    totalMaterials,
    totalFamilies,
    totalStockValue,
    totalStockValueLoading,
    inventoryReliabilityResult,
    inventoryReliabilityLoading,
    totalOutboundsMoves,
    stockCoverageResult,
    resetAllSummaryStock,
    updateReliability,
    updateTotalStockValue,
    recalculateInventoryReliability
  };
};
