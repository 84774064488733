/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OperatorDTO
 */
export interface OperatorDTO {
    /**
     * 
     * @type {boolean}
     * @memberof OperatorDTO
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OperatorDTO
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorDTO
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorDTO
     */
    firstSurname?: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorDTO
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof OperatorDTO
     */
    secondSurname?: string;
}

/**
 * Check if a given object implements the OperatorDTO interface.
 */
export function instanceOfOperatorDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OperatorDTOFromJSON(json: any): OperatorDTO {
    return OperatorDTOFromJSONTyped(json, false);
}

export function OperatorDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperatorDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'firstSurname': !exists(json, 'firstSurname') ? undefined : json['firstSurname'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'secondSurname': !exists(json, 'secondSurname') ? undefined : json['secondSurname'],
    };
}

export function OperatorDTOToJSON(value?: OperatorDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'code': value.code,
        'firstName': value.firstName,
        'firstSurname': value.firstSurname,
        'id': value.id,
        'password': value.password,
        'secondSurname': value.secondSurname,
    };
}

