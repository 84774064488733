import { Backdrop, CircularProgress } from "@mui/material";

export const LoadingSpinnerMasterView = (props: { loading: boolean }) => {
  const { loading } = props;

  return (
    <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
