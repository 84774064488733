import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface DataGridDisplayFilterValidationsProps {
  dateEntry: string;
  dateFinal: string;
  selectedType: string;
}

export const useHandleFilterValidations = (props: DataGridDisplayFilterValidationsProps) => {
  const { t } = useTranslation();

  const [dateEntryErrorText, setDateEntryErrorText] = useState("");
  const [dateFinalErrorText, setDateFinalErrorText] = useState("");
  const [filterButtonIsDisabled, setFilterButtonIsDisabled] = useState(false);

  const checkAllFields = () => {
    switch (props.selectedType) {
      case "range-date":
        checkBothDateInputs();
        break;
      default:
        setFilterButtonIsDisabled(false);
        break;
    }
  };

  const checkBothDateInputs = (dateEntry?: string, dateFinal?: string) => {
    const dateEntryIsValid = checkDate(dateEntry ?? props.dateEntry, true);
    const dateFinalIsValid = checkDate(dateFinal ?? props.dateFinal, false);
    dateEntryIsValid.valueOf();
    setFilterButtonIsDisabled(!(dateEntryIsValid && props.dateEntry !== "" && dateFinalIsValid && props.dateFinal !== ""));
    return dateEntryIsValid && dateFinalIsValid;
  };

  const checkInitDateBelowFinal = () => {
    if (props.dateEntry && props.dateFinal && props.dateEntry > props.dateFinal) {
      setDateEntryErrorText(t("movements.date.error.init.over.final"));
      setDateFinalErrorText(t("movements.date.error.final.below.init"));
      setFilterButtonIsDisabled(true);
      return false;
    } else {
      setDateEntryErrorText("");
      setDateFinalErrorText("");
      setFilterButtonIsDisabled(false);
      return true;
    }
  };

  /**
   *
   * @param dateString The date in string format 'MM/dd/yyyy'
   * @returns {boolean} if the date is valid or not
   */
  const checkDate = (dateString: string, isDateEntry: boolean) => {
    const date = new Date(dateString);
    const endDateLimit = new Date();
    endDateLimit.setDate(endDateLimit.getDate() + 1);

    const isValidDate = dateString !== null && dateString !== undefined && dateString.toString() !== "Invalid Date";
    const isBelowTheMinDate = date < new Date("1900-01-01");
    const isAboveTheMaxDate = date > endDateLimit;

    if (!isValidDate) {
      showInvalidDateError(isDateEntry);
    } else if (isBelowTheMinDate) {
      showBelowMinDateError(isDateEntry);
    } else if (isAboveTheMaxDate) {
      showAboveTheMaxDateError(isDateEntry);
    } else {
      removeError(isDateEntry);
    }
    return isValidDate && !isBelowTheMinDate && !isAboveTheMaxDate;
  };

  const showInvalidDateError = (isDateEntry: boolean) => {
    if (isDateEntry) setDateEntryErrorText(t("movements.date.error.not.valid"));
    else setDateFinalErrorText(t("movements.date.error.not.valid"));
  };

  const showBelowMinDateError = (isDateEntry: boolean) => {
    if (isDateEntry) setDateEntryErrorText(t("movements.date.error.below.min"));
    else setDateFinalErrorText(t("movements.date.error.below.min"));
  };

  const showAboveTheMaxDateError = (isDateEntry: boolean) => {
    if (isDateEntry) setDateEntryErrorText(t("movements.date.error.above.max"));
    else setDateFinalErrorText(t("movements.date.error.above.max"));
  };

  const removeError = (isDateEntry: boolean) => {
    if (isDateEntry) setDateEntryErrorText("");
    else setDateFinalErrorText("");
  };

  useEffect(() => {
    checkBothDateInputs();
  }, [props.dateEntry, props.dateFinal]);

  useEffect(() => {
    checkAllFields();
  }, [props.selectedType, props.dateEntry, props.dateFinal]);

  return {
    dateEntryErrorText,
    dateFinalErrorText,
    checkBothDateInputs,
    checkAllFields,
    filterButtonIsDisabled,
    setFilterButtonIsDisabled,
    checkInitDateBelowFinal
  };
};
