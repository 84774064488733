/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  ConsumeResponseDTO,
  ContainerDTO,
  ContainerPageDTO,
  ContainerWithProductDTO,
  CustomMovementEventFilter,
  Error0,
  IMMaterialConsumeDTO,
  IMMaterialEntryDTO,
  InventoryPeriodDTO,
  ListResponseDTO,
  MovementEventDTO,
  MovementEventPageDTO,
  MovicodersBadRequestException,
  MovicodersNotFoundException,
  MovicodersServiceException,
  RelabelFilterDTO,
  RelabelPageDTO,
  WMExplicitMovementRequest,
  WMExplicitPartialConsumeRequest,
  WMGenericMovementRequest,
  WMGenericPartialConsumeRequest,
  WMMaterialEntryDTO,
  WMReportRelabelRequest
} from "../models";
import {
  ConsumeResponseDTOFromJSON,
  ConsumeResponseDTOToJSON,
  ContainerDTOFromJSON,
  ContainerDTOToJSON,
  ContainerPageDTOFromJSON,
  ContainerPageDTOToJSON,
  ContainerWithProductDTOFromJSON,
  ContainerWithProductDTOToJSON,
  CustomMovementEventFilterFromJSON,
  CustomMovementEventFilterToJSON,
  Error0FromJSON,
  Error0ToJSON,
  IMMaterialConsumeDTOFromJSON,
  IMMaterialConsumeDTOToJSON,
  IMMaterialEntryDTOFromJSON,
  IMMaterialEntryDTOToJSON,
  InventoryPeriodDTOFromJSON,
  InventoryPeriodDTOToJSON,
  ListResponseDTOFromJSON,
  ListResponseDTOToJSON,
  MovementEventDTOFromJSON,
  MovementEventDTOToJSON,
  MovementEventPageDTOFromJSON,
  MovementEventPageDTOToJSON,
  MovicodersBadRequestExceptionFromJSON,
  MovicodersBadRequestExceptionToJSON,
  MovicodersNotFoundExceptionFromJSON,
  MovicodersNotFoundExceptionToJSON,
  MovicodersServiceExceptionFromJSON,
  MovicodersServiceExceptionToJSON,
  RelabelFilterDTOFromJSON,
  RelabelFilterDTOToJSON,
  RelabelPageDTOFromJSON,
  RelabelPageDTOToJSON,
  WMExplicitMovementRequestFromJSON,
  WMExplicitMovementRequestToJSON,
  WMExplicitPartialConsumeRequestFromJSON,
  WMExplicitPartialConsumeRequestToJSON,
  WMGenericMovementRequestFromJSON,
  WMGenericMovementRequestToJSON,
  WMGenericPartialConsumeRequestFromJSON,
  WMGenericPartialConsumeRequestToJSON,
  WMMaterialEntryDTOFromJSON,
  WMMaterialEntryDTOToJSON,
  WMReportRelabelRequestFromJSON,
  WMReportRelabelRequestToJSON
} from "../models";

export interface AddIMMaterialRequest {
  xTenantId?: string;
  operatorId?: string;
  iMMaterialEntryDTO?: IMMaterialEntryDTO;
}

export interface AddIMMaterialListRequest {
  xTenantId?: string;
  operatorId?: string;
  iMMaterialEntryDTO?: Array<IMMaterialEntryDTO>;
}

export interface AddMovementEventRequest {
  xTenantId?: string;
  movementEventDTO?: MovementEventDTO;
}

export interface AddWMMaterialRequest {
  xTenantId?: string;
  operatorId?: string;
  wMMaterialEntryDTO?: WMMaterialEntryDTO;
}

export interface AddWMMaterialListRequest {
  xTenantId?: string;
  operatorId?: string;
  wMMaterialEntryDTO?: Array<WMMaterialEntryDTO>;
}

export interface CloseInventoryPeriodRequest {
  warehouseCode: string;
  xTenantId?: string;
}

export interface ConsumeIMMaterialRequest {
  xTenantId?: string;
  operatorId?: string;
  iMMaterialConsumeDTO?: IMMaterialConsumeDTO;
}

export interface ContainerHasHierarchyRequest {
  containerId: string;
  xTenantId?: string;
}

export interface CreateInventoryPeriodRequest {
  warehouseCode: string;
  xTenantId?: string;
}

export interface DeleteContainerRequest {
  id: string;
  xTenantId?: string;
}

export interface DeleteContainersRequest {
  xTenantId?: string;
  requestBody?: Array<string>;
}

export interface DeleteInventoryPeriodByIdRequest {
  inventoryPeriodId: string;
  xTenantId?: string;
}

export interface FindOldestActiveContainerByMaterialAndWarehouseCodeRequest {
  materialCode: string;
  warehouseCode: string;
  xTenantId?: string;
}

export interface GetContainerByCodeRequest {
  code: string;
  xTenantId?: string;
}

export interface GetContainerByIdRequest {
  id: string;
  xTenantId?: string;
  warehouseCode?: string;
}

export interface GetContainerByIdsRequest {
  xTenantId?: string;
  requestBody?: Array<string>;
}

export interface GetContainerWithProductByIdRequest {
  containerId: string;
  xTenantId?: string;
}

export interface GetInventoryPeriodByWarehouseRequest {
  warehouseCode: string;
  xTenantId?: string;
}

export interface ListContainersRequest {
  xTenantId?: string;
  warehouseCode?: string;
}

export interface ListContainersByMaterialRequest {
  materialCode: string;
  xTenantId?: string;
  warehouseCode?: string;
}

export interface ListContainersPaginatedRequest {
  limit: number;
  offset: number;
  xTenantId?: string;
  warehouseCode?: string;
}

export interface ListMovementRequest {
  xTenantId?: string;
  customMovementEventFilter?: CustomMovementEventFilter;
}

export interface ListMovementEventPageRequest {
  limit: number;
  offset: number;
  xTenantId?: string;
  customMovementEventFilter?: CustomMovementEventFilter;
}

export interface ListRelabelPaginatedRequest {
  limit: number;
  offset: number;
  xTenantId?: string;
  relabelFilterDTO?: RelabelFilterDTO;
}

export interface MoveMaterialWithExplicitContainerRequest {
  xTenantId?: string;
  wMExplicitMovementRequest?: WMExplicitMovementRequest;
}

export interface MoveMaterialWithGenericContainerRequest {
  xTenantId?: string;
  wMGenericMovementRequest?: WMGenericMovementRequest;
}

export interface PartialConsumeForWMWithExplicitContainerRequest {
  xTenantId?: string;
  wMExplicitPartialConsumeRequest?: WMExplicitPartialConsumeRequest;
}

export interface PartialConsumeForWMWithGenericContainerRequest {
  xTenantId?: string;
  wMGenericPartialConsumeRequest?: WMGenericPartialConsumeRequest;
}

export interface RelabelContainerRequest {
  xTenantId?: string;
  wMReportRelabelRequest?: WMReportRelabelRequest;
}

export interface SaveContainerRequest {
  xTenantId?: string;
  containerDTO?: ContainerDTO;
}

export interface SaveContainersRequest {
  xTenantId?: string;
  containerDTO?: Array<ContainerDTO>;
}

export interface TotalConsumeForWMRequest {
  containerCode: string;
  xTenantId?: string;
  operatorId?: string;
}

export interface UpdateContainerRequest {
  xTenantId?: string;
  containerDTO?: ContainerDTO;
}

/**
 *
 */
export class ContainersApi extends runtime.BaseAPI {
  /**
   * Add material to IM environment
   * Add material for IM
   */
  async addIMMaterialRaw(
    requestParameters: AddIMMaterialRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ContainerDTO>> {
    const queryParameters: any = {};

    if (requestParameters.operatorId !== undefined) {
      queryParameters["operatorId"] = requestParameters.operatorId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/im/material/add`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: IMMaterialEntryDTOToJSON(requestParameters.iMMaterialEntryDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ContainerDTOFromJSON(jsonValue));
  }

  /**
   * Add material to IM environment
   * Add material for IM
   */
  async addIMMaterial(
    requestParameters: AddIMMaterialRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ContainerDTO> {
    const response = await this.addIMMaterialRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Add materials to IM environment
   * Add material list for IM
   */
  async addIMMaterialListRaw(
    requestParameters: AddIMMaterialListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ListResponseDTO>> {
    const queryParameters: any = {};

    if (requestParameters.operatorId !== undefined) {
      queryParameters["operatorId"] = requestParameters.operatorId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/im/material/add-many`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.iMMaterialEntryDTO?.map(IMMaterialEntryDTOToJSON)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ListResponseDTOFromJSON(jsonValue));
  }

  /**
   * Add materials to IM environment
   * Add material list for IM
   */
  async addIMMaterialList(
    requestParameters: AddIMMaterialListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ListResponseDTO> {
    const response = await this.addIMMaterialListRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the MovementEvent that has been saved.
   * Add a MovementEvent
   */
  async addMovementEventRaw(
    requestParameters: AddMovementEventRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<MovementEventDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/event/movement/add`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: MovementEventDTOToJSON(requestParameters.movementEventDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => MovementEventDTOFromJSON(jsonValue));
  }

  /**
   * Returns the MovementEvent that has been saved.
   * Add a MovementEvent
   */
  async addMovementEvent(
    requestParameters: AddMovementEventRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<MovementEventDTO> {
    const response = await this.addMovementEventRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Creates the material entry, if the container is not provided, a generic container is created
   * Add a material for WM
   */
  async addWMMaterialRaw(
    requestParameters: AddWMMaterialRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ContainerDTO>> {
    const queryParameters: any = {};

    if (requestParameters.operatorId !== undefined) {
      queryParameters["operatorId"] = requestParameters.operatorId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/wm/material/add`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: WMMaterialEntryDTOToJSON(requestParameters.wMMaterialEntryDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ContainerDTOFromJSON(jsonValue));
  }

  /**
   * Creates the material entry, if the container is not provided, a generic container is created
   * Add a material for WM
   */
  async addWMMaterial(
    requestParameters: AddWMMaterialRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ContainerDTO> {
    const response = await this.addWMMaterialRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Creates the material entry, if the container is not provided, a generic container is created
   * Add material list for WM environment
   */
  async addWMMaterialListRaw(
    requestParameters: AddWMMaterialListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ListResponseDTO>> {
    const queryParameters: any = {};

    if (requestParameters.operatorId !== undefined) {
      queryParameters["operatorId"] = requestParameters.operatorId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/wm/material/add-many`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.wMMaterialEntryDTO?.map(WMMaterialEntryDTOToJSON)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ListResponseDTOFromJSON(jsonValue));
  }

  /**
   * Creates the material entry, if the container is not provided, a generic container is created
   * Add material list for WM environment
   */
  async addWMMaterialList(
    requestParameters: AddWMMaterialListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ListResponseDTO> {
    const response = await this.addWMMaterialListRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Close a warehouse inventory period.
   * Close a warehouse inventory period
   */
  async closeInventoryPeriodRaw(
    requestParameters: CloseInventoryPeriodRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<string>> {
    if (requestParameters.warehouseCode === null || requestParameters.warehouseCode === undefined) {
      throw new runtime.RequiredError(
        "warehouseCode",
        "Required parameter requestParameters.warehouseCode was null or undefined when calling closeInventoryPeriod."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.warehouseCode !== undefined) {
      queryParameters["warehouseCode"] = requestParameters.warehouseCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/inventory-period/close`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   * Close a warehouse inventory period.
   * Close a warehouse inventory period
   */
  async closeInventoryPeriod(
    requestParameters: CloseInventoryPeriodRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<string> {
    const response = await this.closeInventoryPeriodRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Takes an existing Container object, consume Immaterial to it, and returns the Container.
   * Consume a IMmaterial an existing Container.
   */
  async consumeIMMaterialRaw(
    requestParameters: ConsumeIMMaterialRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ConsumeResponseDTO>> {
    const queryParameters: any = {};

    if (requestParameters.operatorId !== undefined) {
      queryParameters["operatorId"] = requestParameters.operatorId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/im/material/consume`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: IMMaterialConsumeDTOToJSON(requestParameters.iMMaterialConsumeDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ConsumeResponseDTOFromJSON(jsonValue));
  }

  /**
   * Takes an existing Container object, consume Immaterial to it, and returns the Container.
   * Consume a IMmaterial an existing Container.
   */
  async consumeIMMaterial(
    requestParameters: ConsumeIMMaterialRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ConsumeResponseDTO> {
    const response = await this.consumeIMMaterialRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns true if ContainerWithProductDTO object by containerId has a Hierarchy.
   * Get a ContainerWithProductDTO object by containerId and return true if it has a Hierarchy.
   */
  async containerHasHierarchyRaw(
    requestParameters: ContainerHasHierarchyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<boolean>> {
    if (requestParameters.containerId === null || requestParameters.containerId === undefined) {
      throw new runtime.RequiredError(
        "containerId",
        "Required parameter requestParameters.containerId was null or undefined when calling containerHasHierarchy."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/{containerId}/hierarchy`.replace(
          `{${"containerId"}}`,
          encodeURIComponent(String(requestParameters.containerId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   * Returns true if ContainerWithProductDTO object by containerId has a Hierarchy.
   * Get a ContainerWithProductDTO object by containerId and return true if it has a Hierarchy.
   */
  async containerHasHierarchy(
    requestParameters: ContainerHasHierarchyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<boolean> {
    const response = await this.containerHasHierarchyRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Create an active inventoryperiod and disable all containers.
   * Create an active inventoryperiod and disable all containers
   */
  async createInventoryPeriodRaw(
    requestParameters: CreateInventoryPeriodRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<string>> {
    if (requestParameters.warehouseCode === null || requestParameters.warehouseCode === undefined) {
      throw new runtime.RequiredError(
        "warehouseCode",
        "Required parameter requestParameters.warehouseCode was null or undefined when calling createInventoryPeriod."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.warehouseCode !== undefined) {
      queryParameters["warehouseCode"] = requestParameters.warehouseCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/inventory-period/add`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   * Create an active inventoryperiod and disable all containers.
   * Create an active inventoryperiod and disable all containers
   */
  async createInventoryPeriod(
    requestParameters: CreateInventoryPeriodRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<string> {
    const response = await this.createInventoryPeriodRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Takes an existing container, deletes it, and returns the new object.
   * Delete an existing container by id.
   */
  async deleteContainerRaw(
    requestParameters: DeleteContainerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ContainerDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteContainer."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ContainerDTOFromJSON(jsonValue));
  }

  /**
   * Takes an existing container, deletes it, and returns the new object.
   * Delete an existing container by id.
   */
  async deleteContainer(
    requestParameters: DeleteContainerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ContainerDTO> {
    const response = await this.deleteContainerRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the deleted Containers
   */
  async deleteContainersRaw(
    requestParameters: DeleteContainersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<ContainerDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/delete-many`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(ContainerDTOFromJSON));
  }

  /**
   * Returns the deleted Containers
   */
  async deleteContainers(
    requestParameters: DeleteContainersRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<ContainerDTO>> {
    const response = await this.deleteContainersRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Delete an inventory period by id.
   * Delete an inventory period by id
   */
  async deleteInventoryPeriodByIdRaw(
    requestParameters: DeleteInventoryPeriodByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<InventoryPeriodDTO>> {
    if (requestParameters.inventoryPeriodId === null || requestParameters.inventoryPeriodId === undefined) {
      throw new runtime.RequiredError(
        "inventoryPeriodId",
        "Required parameter requestParameters.inventoryPeriodId was null or undefined when calling deleteInventoryPeriodById."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.inventoryPeriodId !== undefined) {
      queryParameters["inventoryPeriodId"] = requestParameters.inventoryPeriodId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/inventory-period`,
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => InventoryPeriodDTOFromJSON(jsonValue));
  }

  /**
   * Delete an inventory period by id.
   * Delete an inventory period by id
   */
  async deleteInventoryPeriodById(
    requestParameters: DeleteInventoryPeriodByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<InventoryPeriodDTO> {
    const response = await this.deleteInventoryPeriodByIdRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Find oldest active Container witch contains material
   * Find oldest Container by Material and Warehouse Code
   */
  async findOldestActiveContainerByMaterialAndWarehouseCodeRaw(
    requestParameters: FindOldestActiveContainerByMaterialAndWarehouseCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ContainerDTO>> {
    if (requestParameters.materialCode === null || requestParameters.materialCode === undefined) {
      throw new runtime.RequiredError(
        "materialCode",
        "Required parameter requestParameters.materialCode was null or undefined when calling findOldestActiveContainerByMaterialAndWarehouseCode."
      );
    }

    if (requestParameters.warehouseCode === null || requestParameters.warehouseCode === undefined) {
      throw new runtime.RequiredError(
        "warehouseCode",
        "Required parameter requestParameters.warehouseCode was null or undefined when calling findOldestActiveContainerByMaterialAndWarehouseCode."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/container/find-oldest/{materialCode}/{warehouseCode}`
          .replace(`{${"materialCode"}}`, encodeURIComponent(String(requestParameters.materialCode)))
          .replace(`{${"warehouseCode"}}`, encodeURIComponent(String(requestParameters.warehouseCode))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ContainerDTOFromJSON(jsonValue));
  }

  /**
   * Find oldest active Container witch contains material
   * Find oldest Container by Material and Warehouse Code
   */
  async findOldestActiveContainerByMaterialAndWarehouseCode(
    requestParameters: FindOldestActiveContainerByMaterialAndWarehouseCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ContainerDTO> {
    const response = await this.findOldestActiveContainerByMaterialAndWarehouseCodeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns one container object by code.
   * Get a container object by code.
   */
  async getContainerByCodeRaw(
    requestParameters: GetContainerByCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ContainerDTO>> {
    if (requestParameters.code === null || requestParameters.code === undefined) {
      throw new runtime.RequiredError(
        "code",
        "Required parameter requestParameters.code was null or undefined when calling getContainerByCode."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.code !== undefined) {
      queryParameters["code"] = requestParameters.code;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/code`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ContainerDTOFromJSON(jsonValue));
  }

  /**
   * Returns one container object by code.
   * Get a container object by code.
   */
  async getContainerByCode(
    requestParameters: GetContainerByCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ContainerDTO> {
    const response = await this.getContainerByCodeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns one container object by id. If warehouseCode is null returns all items
   * Get a container object by id and warehouseCode.
   */
  async getContainerByIdRaw(
    requestParameters: GetContainerByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ContainerDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getContainerById."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.warehouseCode !== undefined) {
      queryParameters["warehouseCode"] = requestParameters.warehouseCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ContainerDTOFromJSON(jsonValue));
  }

  /**
   * Returns one container object by id. If warehouseCode is null returns all items
   * Get a container object by id and warehouseCode.
   */
  async getContainerById(
    requestParameters: GetContainerByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ContainerDTO> {
    const response = await this.getContainerByIdRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns container objects by ids. If warehouseCode is null returns all elements
   * Get a container object by id.
   */
  async getContainerByIdsRaw(
    requestParameters: GetContainerByIdsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<ContainerDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/find-many-by-id`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(ContainerDTOFromJSON));
  }

  /**
   * Returns container objects by ids. If warehouseCode is null returns all elements
   * Get a container object by id.
   */
  async getContainerByIds(
    requestParameters: GetContainerByIdsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<ContainerDTO>> {
    const response = await this.getContainerByIdsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns one ContainerWithProductDTO object by containerId.
   * Get a ContainerWithProductDTO object by containerId.
   */
  async getContainerWithProductByIdRaw(
    requestParameters: GetContainerWithProductByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ContainerWithProductDTO>> {
    if (requestParameters.containerId === null || requestParameters.containerId === undefined) {
      throw new runtime.RequiredError(
        "containerId",
        "Required parameter requestParameters.containerId was null or undefined when calling getContainerWithProductById."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/{containerId}/product-info`.replace(
          `{${"containerId"}}`,
          encodeURIComponent(String(requestParameters.containerId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ContainerWithProductDTOFromJSON(jsonValue));
  }

  /**
   * Returns one ContainerWithProductDTO object by containerId.
   * Get a ContainerWithProductDTO object by containerId.
   */
  async getContainerWithProductById(
    requestParameters: GetContainerWithProductByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ContainerWithProductDTO> {
    const response = await this.getContainerWithProductByIdRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get inventory periods by warehouseCode
   */
  async getInventoryPeriodByWarehouseRaw(
    requestParameters: GetInventoryPeriodByWarehouseRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<InventoryPeriodDTO>>> {
    if (requestParameters.warehouseCode === null || requestParameters.warehouseCode === undefined) {
      throw new runtime.RequiredError(
        "warehouseCode",
        "Required parameter requestParameters.warehouseCode was null or undefined when calling getInventoryPeriodByWarehouse."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.warehouseCode !== undefined) {
      queryParameters["warehouseCode"] = requestParameters.warehouseCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/inventory-period`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(InventoryPeriodDTOFromJSON));
  }

  /**
   * Get inventory periods by warehouseCode
   */
  async getInventoryPeriodByWarehouse(
    requestParameters: GetInventoryPeriodByWarehouseRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<InventoryPeriodDTO>> {
    const response = await this.getInventoryPeriodByWarehouseRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the list of all registered Containers by warehouseCode. If warehouseCode is null returns all elements
   * Returns the list of all registered Containers by warehouseCode
   */
  async listContainersRaw(
    requestParameters: ListContainersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<ContainerDTO>>> {
    const queryParameters: any = {};

    if (requestParameters.warehouseCode !== undefined) {
      queryParameters["warehouseCode"] = requestParameters.warehouseCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(ContainerDTOFromJSON));
  }

  /**
   * Returns the list of all registered Containers by warehouseCode. If warehouseCode is null returns all elements
   * Returns the list of all registered Containers by warehouseCode
   */
  async listContainers(
    requestParameters: ListContainersRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<ContainerDTO>> {
    const response = await this.listContainersRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the list of all registered Containers by warehouseCode and material. If warehouseCode is null returns all elements
   * Returns the list of all registered Containers by warehouseCode and material
   */
  async listContainersByMaterialRaw(
    requestParameters: ListContainersByMaterialRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<ContainerDTO>>> {
    if (requestParameters.materialCode === null || requestParameters.materialCode === undefined) {
      throw new runtime.RequiredError(
        "materialCode",
        "Required parameter requestParameters.materialCode was null or undefined when calling listContainersByMaterial."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.warehouseCode !== undefined) {
      queryParameters["warehouseCode"] = requestParameters.warehouseCode;
    }

    if (requestParameters.materialCode !== undefined) {
      queryParameters["materialCode"] = requestParameters.materialCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/material/warehouse`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(ContainerDTOFromJSON));
  }

  /**
   * Returns the list of all registered Containers by warehouseCode and material. If warehouseCode is null returns all elements
   * Returns the list of all registered Containers by warehouseCode and material
   */
  async listContainersByMaterial(
    requestParameters: ListContainersByMaterialRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<ContainerDTO>> {
    const response = await this.listContainersByMaterialRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the list of all registered Containers. If warehouseCode is null, returns all items
   * Returns the list of all registered Containers paginated by warehouseCode
   */
  async listContainersPaginatedRaw(
    requestParameters: ListContainersPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ContainerPageDTO>> {
    if (requestParameters.limit === null || requestParameters.limit === undefined) {
      throw new runtime.RequiredError(
        "limit",
        "Required parameter requestParameters.limit was null or undefined when calling listContainersPaginated."
      );
    }

    if (requestParameters.offset === null || requestParameters.offset === undefined) {
      throw new runtime.RequiredError(
        "offset",
        "Required parameter requestParameters.offset was null or undefined when calling listContainersPaginated."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.warehouseCode !== undefined) {
      queryParameters["warehouseCode"] = requestParameters.warehouseCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/paginated`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ContainerPageDTOFromJSON(jsonValue));
  }

  /**
   * Returns the list of all registered Containers. If warehouseCode is null, returns all items
   * Returns the list of all registered Containers paginated by warehouseCode
   */
  async listContainersPaginated(
    requestParameters: ListContainersPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ContainerPageDTO> {
    const response = await this.listContainersPaginatedRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Return all events that match the filters.
   * Returns the list of all registered MovementEvents that match the filters
   */
  async listMovementRaw(
    requestParameters: ListMovementRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<MovementEventDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/event/movement/all`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CustomMovementEventFilterToJSON(requestParameters.customMovementEventFilter)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(MovementEventDTOFromJSON));
  }

  /**
   * Return all events that match the filters.
   * Returns the list of all registered MovementEvents that match the filters
   */
  async listMovement(
    requestParameters: ListMovementRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<MovementEventDTO>> {
    const response = await this.listMovementRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Return the events paginated that match the filters.
   * Returns the list of all registered MovementEvents paginated that match the filters
   */
  async listMovementEventPageRaw(
    requestParameters: ListMovementEventPageRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<MovementEventPageDTO>> {
    if (requestParameters.limit === null || requestParameters.limit === undefined) {
      throw new runtime.RequiredError(
        "limit",
        "Required parameter requestParameters.limit was null or undefined when calling listMovementEventPage."
      );
    }

    if (requestParameters.offset === null || requestParameters.offset === undefined) {
      throw new runtime.RequiredError(
        "offset",
        "Required parameter requestParameters.offset was null or undefined when calling listMovementEventPage."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/event/movement/paginated`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CustomMovementEventFilterToJSON(requestParameters.customMovementEventFilter)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => MovementEventPageDTOFromJSON(jsonValue));
  }

  /**
   * Return the events paginated that match the filters.
   * Returns the list of all registered MovementEvents paginated that match the filters
   */
  async listMovementEventPage(
    requestParameters: ListMovementEventPageRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<MovementEventPageDTO> {
    const response = await this.listMovementEventPageRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Return the relabels paginated that match the filters.
   * Returns the list of all registered Relabels paginated that match the filters
   */
  async listRelabelPaginatedRaw(
    requestParameters: ListRelabelPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<RelabelPageDTO>> {
    if (requestParameters.limit === null || requestParameters.limit === undefined) {
      throw new runtime.RequiredError(
        "limit",
        "Required parameter requestParameters.limit was null or undefined when calling listRelabelPaginated."
      );
    }

    if (requestParameters.offset === null || requestParameters.offset === undefined) {
      throw new runtime.RequiredError(
        "offset",
        "Required parameter requestParameters.offset was null or undefined when calling listRelabelPaginated."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/relabel/paginated`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: RelabelFilterDTOToJSON(requestParameters.relabelFilterDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => RelabelPageDTOFromJSON(jsonValue));
  }

  /**
   * Return the relabels paginated that match the filters.
   * Returns the list of all registered Relabels paginated that match the filters
   */
  async listRelabelPaginated(
    requestParameters: ListRelabelPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<RelabelPageDTO> {
    const response = await this.listRelabelPaginatedRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Move material for WM with a specific container
   * Move material for WM with a specific container
   */
  async moveMaterialWithExplicitContainerRaw(
    requestParameters: MoveMaterialWithExplicitContainerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ContainerDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/wm/container/movement/explicit`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: WMExplicitMovementRequestToJSON(requestParameters.wMExplicitMovementRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ContainerDTOFromJSON(jsonValue));
  }

  /**
   * Move material for WM with a specific container
   * Move material for WM with a specific container
   */
  async moveMaterialWithExplicitContainer(
    requestParameters: MoveMaterialWithExplicitContainerRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ContainerDTO> {
    const response = await this.moveMaterialWithExplicitContainerRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Takes an existing generic container and move the quantity to other generic container
   * Generic container material movement
   */
  async moveMaterialWithGenericContainerRaw(
    requestParameters: MoveMaterialWithGenericContainerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ConsumeResponseDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/wm/container/movement/generic`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: WMGenericMovementRequestToJSON(requestParameters.wMGenericMovementRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ConsumeResponseDTOFromJSON(jsonValue));
  }

  /**
   * Takes an existing generic container and move the quantity to other generic container
   * Generic container material movement
   */
  async moveMaterialWithGenericContainer(
    requestParameters: MoveMaterialWithGenericContainerRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ConsumeResponseDTO> {
    const response = await this.moveMaterialWithGenericContainerRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Consume partial material for WM with explicit container.
   * Consume partial material for WM with explicit container
   */
  async partialConsumeForWMWithExplicitContainerRaw(
    requestParameters: PartialConsumeForWMWithExplicitContainerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ConsumeResponseDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/wm/container/partial-consume/explicit`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: WMExplicitPartialConsumeRequestToJSON(requestParameters.wMExplicitPartialConsumeRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ConsumeResponseDTOFromJSON(jsonValue));
  }

  /**
   * Consume partial material for WM with explicit container.
   * Consume partial material for WM with explicit container
   */
  async partialConsumeForWMWithExplicitContainer(
    requestParameters: PartialConsumeForWMWithExplicitContainerRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ConsumeResponseDTO> {
    const response = await this.partialConsumeForWMWithExplicitContainerRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Takes an existing Container object, consume material to it, and returns the Generic Container.
   * Consume a material an existing Generic Container.
   */
  async partialConsumeForWMWithGenericContainerRaw(
    requestParameters: PartialConsumeForWMWithGenericContainerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ConsumeResponseDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/wm/container/partial-consume/generic`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: WMGenericPartialConsumeRequestToJSON(requestParameters.wMGenericPartialConsumeRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ConsumeResponseDTOFromJSON(jsonValue));
  }

  /**
   * Takes an existing Container object, consume material to it, and returns the Generic Container.
   * Consume a material an existing Generic Container.
   */
  async partialConsumeForWMWithGenericContainer(
    requestParameters: PartialConsumeForWMWithGenericContainerRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ConsumeResponseDTO> {
    const response = await this.partialConsumeForWMWithGenericContainerRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Relabel, reassign or regularization a Container.
   * Relabel, reassign or regularization a Container.
   */
  async relabelContainerRaw(
    requestParameters: RelabelContainerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ContainerDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/relabel`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: WMReportRelabelRequestToJSON(requestParameters.wMReportRelabelRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ContainerDTOFromJSON(jsonValue));
  }

  /**
   * Relabel, reassign or regularization a Container.
   * Relabel, reassign or regularization a Container.
   */
  async relabelContainer(
    requestParameters: RelabelContainerRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ContainerDTO> {
    const response = await this.relabelContainerRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Adds a container and returns it
   */
  async saveContainerRaw(
    requestParameters: SaveContainerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ContainerDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ContainerDTOToJSON(requestParameters.containerDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ContainerDTOFromJSON(jsonValue));
  }

  /**
   * Adds a container and returns it
   */
  async saveContainer(
    requestParameters: SaveContainerRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ContainerDTO> {
    const response = await this.saveContainerRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the registered Containers
   */
  async saveContainersRaw(
    requestParameters: SaveContainersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ListResponseDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/add-many`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.containerDTO?.map(ContainerDTOToJSON)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ListResponseDTOFromJSON(jsonValue));
  }

  /**
   * Returns the registered Containers
   */
  async saveContainers(
    requestParameters: SaveContainersRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ListResponseDTO> {
    const response = await this.saveContainersRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Consume total material for WM.
   * Consume total material for WM
   */
  async totalConsumeForWMRaw(
    requestParameters: TotalConsumeForWMRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ConsumeResponseDTO>> {
    if (requestParameters.containerCode === null || requestParameters.containerCode === undefined) {
      throw new runtime.RequiredError(
        "containerCode",
        "Required parameter requestParameters.containerCode was null or undefined when calling totalConsumeForWM."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.containerCode !== undefined) {
      queryParameters["containerCode"] = requestParameters.containerCode;
    }

    if (requestParameters.operatorId !== undefined) {
      queryParameters["operatorId"] = requestParameters.operatorId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers/wm/container/total-consume`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ConsumeResponseDTOFromJSON(jsonValue));
  }

  /**
   * Consume total material for WM.
   * Consume total material for WM
   */
  async totalConsumeForWM(
    requestParameters: TotalConsumeForWMRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ConsumeResponseDTO> {
    const response = await this.totalConsumeForWMRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Takes an existing Container object, updates it, and returns the Container.
   * Update an existing Container.
   */
  async updateContainerRaw(
    requestParameters: UpdateContainerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ContainerDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/containers`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: ContainerDTOToJSON(requestParameters.containerDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ContainerDTOFromJSON(jsonValue));
  }

  /**
   * Takes an existing Container object, updates it, and returns the Container.
   * Update an existing Container.
   */
  async updateContainer(
    requestParameters: UpdateContainerRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ContainerDTO> {
    const response = await this.updateContainerRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
