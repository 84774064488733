import { COLORS } from "@theme";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { CREATE_ID, TIMEOUT_ID } from "@constants";
import React, { useEffect, useState } from "react";
import Stakeholder from "@domain/model/Stakeholder";
import { useStakeholderForm } from "./hooks/useStakeholderForm";
import { stakeholderTypesEnum } from "./hooks/useStakeholderSelection";
import { useStakeholderTypesHandler } from "./hooks/useStakeholderTypesHandler";
import { StakeholderTextfield } from "./components/detail/stakeholder-textfield";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";
import { ButtonsDetail } from "@components/master-data-view/buttons/buttons-detail";
import { StakeholderTypeCheckboxes } from "./components/stakeholder-type-checkboxes";
import { useStakeholderInputValidation } from "./hooks/viewmodel/useStakeholderInputValidation";
import { StakeholderCountryAutocomplete } from "./components/detail/stakeholder-country-autocomplete";
import { StakeholderCurrencyAutocomplete } from "./components/detail/stakeholder-currency-autocomplete";
import { LoadingSpinnerDetailView } from "@components/loading-circular-progress/loading-spinner-detail-view";
import { StakeholdersConfirmationChangesDialog } from "./components/stakeholders-confirmation-changes-dialog";
import { StakeholderPaymenMethodAutocomplete } from "./components/detail/stakeholder-payment-method-autocomplete";

interface IDetail {
  stakeholder: Stakeholder;
  stakeholderType: stakeholderTypesEnum;
  loading: boolean;
  onSave: (stakeholder: Stakeholder) => void;
  onClose: () => void;
}

export const StakeholderDetailView = (props: IDetail) => {
  const { t } = useTranslation();
  const { stakeholder: initialStakeholder, stakeholderType, loading, onSave, onClose } = props;
  const disabled = initialStakeholder.active !== undefined && initialStakeholder.active === false;

  const { modifiedStakeholder, onChange, onChangeNumeric, onChangeAutocomplete, onChangeCheckboxes } = useStakeholderForm({
    selectedStakeholder: initialStakeholder,
    stakeholderType
  });

  const { formErrors, validateFormValues } = useStakeholderInputValidation(modifiedStakeholder);

  const { newStakeholdersList, handleAgentsEquality, newStakeholdersListHandler, confirmationDialogVisibility } =
    useStakeholderTypesHandler(modifiedStakeholder, validateFormValues);

  const [pendingRequest, setPendingRequest] = useState<boolean>(false);

  useEffect(() => {
    setPendingRequest(false);
  }, [initialStakeholder]);

  return (
    <React.Fragment>
      {(initialStakeholder as Stakeholder).id !== undefined && (initialStakeholder as Stakeholder).id !== TIMEOUT_ID ? (
        <AggregoDialog
          title={
            (initialStakeholder as Stakeholder).id === CREATE_ID
              ? t(`${stakeholderType}.detail.title`)
              : t(`${stakeholderType}.detail.edit.title`) + (initialStakeholder as Stakeholder).code
          }
          onClose={onClose}
          maxWidth="md">
          <Grid
            container
            className="masterdata-box"
            justifyContent="space-between"
            rowSpacing={2}
            columnSpacing={1}
            sx={{ ml: -0.5 }}>
            <Grid item xs={5}>
              <Typography
                variant="subText"
                textAlign="left"
                sx={{ fontSize: "0.7rem", fontWeight: "400", color: disabled ? COLORS.gray : COLORS.secondary }}>
                {t("stakeholders.code")}
              </Typography>
              <Typography
                variant="cardvalue"
                data-cy={`value`}
                sx={{ wordBreak: "keep-all", color: disabled ? COLORS.gray : COLORS.text }}>
                {modifiedStakeholder.code}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <StakeholderTextfield
                id="stakeholder-name"
                name="name"
                value={modifiedStakeholder.name ?? ""}
                label={t("stakeholders.name")}
                onChange={onChange}
                formErrors={formErrors}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StakeholderTextfield
                id="stakeholder-fiscalId"
                name="fiscalId"
                value={modifiedStakeholder.fiscalId ?? ""}
                label={t("stakeholders.fiscalId")}
                onChange={onChange}
                formErrors={formErrors}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StakeholderTextfield
                id="stakeholder-contact"
                name="contactPerson"
                value={modifiedStakeholder.contactPerson ?? ""}
                label={t("stakeholders.contact")}
                onChange={onChange}
                formErrors={formErrors}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12}>
              <StakeholderTextfield
                id="stakeholder-address"
                name="address"
                value={modifiedStakeholder.address ?? ""}
                label={t("stakeholders.address")}
                onChange={onChange}
                formErrors={formErrors}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <StakeholderTextfield
                id="stakeholder-postalCode"
                name="postalCode"
                value={modifiedStakeholder.postalCode ? modifiedStakeholder.postalCode.toString() : "0"}
                label={t("stakeholders.postalCode")}
                onChange={onChangeNumeric}
                formErrors={formErrors}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <StakeholderTextfield
                id="stakeholder-city"
                name="city"
                value={modifiedStakeholder.city ?? ""}
                label={t("stakeholders.city")}
                onChange={onChange}
                formErrors={formErrors}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <StakeholderCountryAutocomplete
                disabled={disabled}
                modifiedStakeholder={modifiedStakeholder}
                onChangeAutocomplete={(event, value) => {
                  onChangeAutocomplete(event, value?.label ?? "", "country");
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StakeholderTextfield
                id="stakeholder-email"
                name="email"
                value={modifiedStakeholder.email ?? ""}
                label={t("stakeholders.email")}
                onChange={onChange}
                formErrors={formErrors}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StakeholderTextfield
                id="stakeholder-phone"
                name="phone"
                value={modifiedStakeholder.phone ?? ""}
                label={t("stakeholders.phone")}
                onChange={onChange}
                formErrors={formErrors}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StakeholderPaymenMethodAutocomplete
                disabled={disabled}
                modifiedStakeholder={modifiedStakeholder}
                onChangeAutocomplete={(event, value) => {
                  onChangeAutocomplete(event, value ?? "", "paymentTerms");
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StakeholderCurrencyAutocomplete
                disabled={disabled}
                modifiedStakeholder={modifiedStakeholder}
                onChangeAutocomplete={(event, value) => {
                  onChangeAutocomplete(event, value ?? "", "paymentCurrency");
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <StakeholderTextfield
                id="stakeholder-comments"
                name="comment"
                value={modifiedStakeholder.comment ?? ""}
                label={t("stakeholders.comments")}
                onChange={onChange}
                formErrors={formErrors}
                disabled={disabled}
              />
            </Grid>

            <StakeholderTypeCheckboxes
              stakeholder={modifiedStakeholder}
              newStakeholdersListHandler={newStakeholdersListHandler}
              onChangeCheckboxes={onChangeCheckboxes}
              formErrors={formErrors}
            />

            <Grid item xs={12}>
              <ButtonsDetail
                handleClose={onClose}
                handleSave={() => {
                  if (handleAgentsEquality()) {
                    setPendingRequest(true);
                    onSave(modifiedStakeholder);
                  }
                }}
                mode={(initialStakeholder as Stakeholder).id === CREATE_ID ? "CREATE" : "EDIT"}
                disabled={disabled}
                pendingRequest={pendingRequest}
              />
            </Grid>
            {!confirmationDialogVisibility && modifiedStakeholder.code !== "-" && (
              <StakeholdersConfirmationChangesDialog
                stakeholder={modifiedStakeholder}
                newStakeholdersList={newStakeholdersList}
                onClose={() => handleAgentsEquality(true)}
                onSave={() => {
                  setPendingRequest(true);
                  onSave(modifiedStakeholder);
                }}
                pendingRequest={pendingRequest}
              />
            )}
          </Grid>
        </AggregoDialog>
      ) : (
        <LoadingSpinnerDetailView loading={loading} selectedId={(initialStakeholder as Stakeholder)?.id ?? ""} />
      )}
    </React.Fragment>
  );
};
