import { Dialog, DialogTitle, Typography, DialogActions, Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface ILoadErrorDialogProps {
  errors: string[];
  open: boolean;
  handleClose: () => void;
}

export const ViewerLoadErrorDialog = (props: ILoadErrorDialogProps) => {
  const { t } = useTranslation();
  const { errors, handleClose, open } = props;

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle>{t("viewer.loading.error.dialog.title")}</DialogTitle>
      <Grid container flexDirection="column" className="masterdata-box" rowSpacing={2}>
        <Grid item ml="2rem" mr="2rem">
          {errors.map((err) => (
            <Grid key={err}>
              <Typography mb="1rem" fontWeight={600}>
                {t("viewer.error.code") + ": " + t(`${err}.code`)}
              </Typography>
              <Typography mb="2rem">
                {t(err)}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Grid item>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">{t("viewer.ok")}</Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  );
};
