import { UserRoleEnum } from "@domain/interface/User";
import { IUserService } from "@domain/services/IUserService";
import { IImpersonationService } from "@domain/services/IImpersonationService";

export class IsValidImpersonationUseCase {
  constructor(private readonly userService: IUserService, private readonly impersonationService: IImpersonationService) {}

  public execute() {
    if (this.userService.user?.roles.includes(UserRoleEnum.SuperAdmin)) {
      return (
        this.impersonationService.persistedState?.impersonatedTenant !== undefined &&
        this.impersonationService.persistedState?.impersonatedTenant !== null
      );
    } else return true;
  }
}
