import Zone from "@domain/model/Zone";
import {
  GetZonesByWarehouseIDRequest,
  ListZonesByTenantIdRequest,
  ListZonesPaginatedRequest
} from "@clients/aggrego-proxy/apis/ZonesApi";
import { ZoneApi } from "../api/zone-api";
import { ZoneDTO } from "@clients/aggrego-proxy";
import { DomainPage, IRepository } from "@movicoders/domain";

export class ZoneRepository implements IRepository<Zone, ListZonesPaginatedRequest, ListZonesPaginatedRequest> {
  constructor(private readonly zoneApi: ZoneApi) {}
  get(): Promise<Zone[]> {
    return this.zoneApi.get();
  }

  getPaginated(pageCriteria: ListZonesPaginatedRequest): Promise<DomainPage<Zone>> {
    return this.zoneApi.getPaginated(pageCriteria);
  }

  getById(id: string): Promise<Zone> {
    return this.zoneApi.getById(id);
  }

  getByTenantId(criteria: ListZonesByTenantIdRequest): Promise<Zone[]> {
    return this.zoneApi.getByTenantId(criteria);
  }

  async create(entity: Zone): Promise<Zone> {
    await this.zoneApi.create(entity);
    return entity;
  }

  update(entity: Zone): Promise<Zone> {
    return this.zoneApi.update(entity);
  }

  remove(id: string): Promise<void> {
    return this.zoneApi.remove(id);
  }

  getByWarehouseId(request: GetZonesByWarehouseIDRequest): Promise<Array<ZoneDTO>> {
    return this.zoneApi.getByWarehouseId(request);
  }
}
