import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridValidRowModel } from "@mui/x-data-grid";

// Props to use by the generic pdf component.
interface IGenericPDF {
  /** The headers the pdf table is going to use. */
  headers: GridColDef[];
  /** Data that the pdf table is going to show. */
  data: GridValidRowModel[];
  /** The name of the current table. */
  tableName: string;
}

export const DataGridDisplayGenericPDF = (props: IGenericPDF) => {
  const { t } = useTranslation();
  const { headers, data, tableName } = props;

  const newHeaders = headers.map(header => {
    header.sortable = false;
    header.flex = 1;
    return header;
  });

  return (
    <Grid container direction="column" rowGap="1rem">
      <Grid container justifyContent="space-between">
        <Typography variant="title">{t("table.download.pdf.title", { name: tableName })}</Typography>
        <Typography variant="title">{new Date().toLocaleString()}</Typography>
      </Grid>
      <DataGrid
        columns={newHeaders}
        rows={data}
        autoHeight
        pageSize={data.length}
        components={{
          Toolbar: () => null,
          Footer: () => null
        }}
      />
    </Grid>
  );
};
