import {
  AddIMMaterialListRequest,
  ConsumeIMMaterialRequest,
  ConsumeResponseDTO,
  ContainerDTO,
  GetContainerByIdsRequest,
  ContainerPageDTO,
  GetContainerByIdRequest,
  ListContainersPaginatedRequest,
  ListResponseDTO,
  PartialConsumeForWMWithExplicitContainerRequest,
  PartialConsumeForWMWithGenericContainerRequest,
  ListRelabelPaginatedRequest
} from "@clients/aggrego-proxy";
import { ContainerApi } from "../api/container-api";

export class ContainerRepository {
  constructor(private readonly containerApi: ContainerApi) {}

  createMany(criteria: AddIMMaterialListRequest): Promise<ListResponseDTO> {
    return this.containerApi.addManyIMMaterial(criteria);
  }

  consume(criteria: ConsumeIMMaterialRequest): Promise<ConsumeResponseDTO> {
    return this.containerApi.consumeIMMaterial(criteria);
  }

  consumeWM(criteria: PartialConsumeForWMWithGenericContainerRequest): Promise<ConsumeResponseDTO> {
    return this.containerApi.consumeWMMaterial(criteria);
  }

  consumeWMC(criteria: PartialConsumeForWMWithExplicitContainerRequest): Promise<ConsumeResponseDTO> {
    return this.containerApi.consumeWMCMaterial(criteria);
  }

  getManyByIds(criteria: GetContainerByIdsRequest): Promise<Array<ContainerDTO>> {
    return this.containerApi.getManyByIds(criteria);
  }

  getById(criteria: GetContainerByIdRequest): Promise<ContainerDTO> {
    return this.containerApi.getContainerById(criteria);
  }

  getPaginated(criteria: ListContainersPaginatedRequest): Promise<ContainerPageDTO> {
    return this.containerApi.getContainersPaginated(criteria);
  }

  getRelabelPaginated(criteria: ListRelabelPaginatedRequest): Promise<ContainerPageDTO> {
    return this.containerApi.getRelabelPaginated(criteria);
  }
}
