import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import { useResolve } from "@movicoders/di";
import { useTranslation } from "react-i18next";
import { MasterDataEnum, ReportEnum } from "../settings-interfaces";
import { SyntheticEvent, useEffect, useState } from "react";
import { FullUserDTOCompanyLicenseEnum } from "@clients/aggrego-proxy";
import { IUserService, UserService } from "@domain/services/IUserService";
import { Checkbox, Collapse, FormControl, FormControlLabel, FormGroup, Grid } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { TUserRoleEnum, UserRoleEnum } from "@domain/interface/User";

interface IAccessFormComponent<T> {
  accessData: T[];
  setAccessData: (value: T[]) => void;
  options: T[];
  label: string;
  role?: TUserRoleEnum;
}

const AccessCheckboxForm = <T,>(props: IAccessFormComponent<T>) => {
  const { t } = useTranslation();
  const userService = useResolve<IUserService>(UserService);
  const { options, label, accessData, setAccessData, role } = props;

  const [isBlockSelected, setIsBlockSelected] = useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  const onChange = (event: SyntheticEvent<Element, Event>, checked: boolean, masterData: T) => {
    const newMasterData = !checked ? accessData.filter(item => item !== masterData) : [...accessData, masterData];
    setAccessData(newMasterData);
  };

  useEffect(() => {
    if (accessData.length > 0) {
      setIsBlockSelected(true);
    } else if (accessData.length === 0) {
      setIsBlockSelected(false);
    }
  }, [accessData]);

  const isCheckboxShowed = (value: T) => {
    if (role === UserRoleEnum.PortalUser && value === MasterDataEnum.Users) {
      return false;
    } else if (userService.user?.companyLicense !== FullUserDTOCompanyLicenseEnum.Im) {
      return true;
    } else
      return (
        value !== MasterDataEnum.WarehouseViewer &&
        value !== MasterDataEnum.Zones &&
        value !== MasterDataEnum.Locations &&
        value !== ReportEnum.InventoryReport &&
        value !== ReportEnum.StockReport &&
        value !== ReportEnum.InventoryRegistry
      );
  };

  return (
    <FormGroup id="user-form-group">
      <FormControl>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Icon
            style={{ marginRight: 10, cursor: "pointer" }}
            icon={isCollapsed ? "less" : "plus"}
            onClick={() => setIsCollapsed(!isCollapsed)}
            color={COLORS.primary}
          />
          <Grid id={`${label}-grid`} width={"12rem"}>
            <FormControlLabel
              key={`checkbox-form`}
              control={
                <Checkbox
                  id={`access-form-checkbox-${label.split(".")[0]}`}
                  checked={isBlockSelected}
                  checkedIcon={accessData.length === options.length ? <CheckBoxIcon /> : <IndeterminateCheckBoxIcon />}
                  onChange={(event: SyntheticEvent<Element, Event>, checked: boolean) => {
                    setIsBlockSelected(checked);
                  }}
                />
              }
              label={t(label)}
              onClick={() => {
                !isBlockSelected ? setAccessData(options) : setAccessData([]);
              }}
            />
          </Grid>
        </div>

        <Collapse in={isCollapsed} timeout="auto" unmountOnExit>
          <Grid item id={`${label}-grid`} display={"flex"} flexDirection={"column"} sx={{ ml: "4rem" }} width={"16rem"}>
            {Object.values(options).map(access => {
              return (
                isCheckboxShowed(access) && (
                  <FormControlLabel
                    key={`checkbox-${access}`}
                    control={<Checkbox id={"access-" + access} checked={accessData?.includes(access)} />}
                    label={t(`${(access as string).toLowerCase()}`)}
                    onChange={(event: SyntheticEvent<Element, Event>, checked: boolean) => {
                      onChange(event, checked, access);
                    }}
                  />
                )
              );
            })}
          </Grid>
        </Collapse>
      </FormControl>
    </FormGroup>
  );
};

export default AccessCheckboxForm;
