import { LoginUseCase } from "@application/login/login-use-case";
import { useResolve } from "@movicoders/di";
import { useState } from "react";

export const useHomeHowMayUsers = () => {
  const loginUseCase = useResolve(LoginUseCase);

  const regex = /(\d+)/g;
  const [portalSessionsAmount, setPortalSessionsAmount] = useState<number | undefined>(undefined);
  const [mobilitySessionsAmount, setMobilitySessionsAmount] = useState<number | undefined>(undefined);

  const calculateCompanyUsersConnected = () => {
    setPortalSessionsAmount(0);
    setMobilitySessionsAmount(0);
    loginUseCase.howManyUsers().then(result => {
      const numbers = result.match(regex);
      if (numbers) {
        setPortalSessionsAmount(parseInt(numbers[0], 10));
        setMobilitySessionsAmount(parseInt(numbers[1], 10));
      }
    });
  };

  const resetAllUsers = () => {
    setPortalSessionsAmount(0);
    setMobilitySessionsAmount(0);
  };

  return { calculateCompanyUsersConnected, portalSessionsAmount, mobilitySessionsAmount, resetAllUsers };
};
