import { createTheme } from "@mui/material/styles";
import type {} from "@mui/x-data-grid/themeAugmentation";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import type {} from "@mui/lab/themeAugmentation";
import React, { CSSProperties } from "react";

export const COLORS = {
  primary: "#0F407A",
  secondary: "#4BADB1",
  secondaryOpacity: "rgb(75, 173, 177,0.5)",
  text: "#121212",
  white: "#ffffff",
  background: "#f9f9f9",
  gradient: "transparent linear-gradient(42deg, #0F407A 0%, #4BADB1 100%) 0% 0% no-repeat padding-box",
  stepperGradient: "transparent linear-gradient(180deg, #0F407A 0%, #4BADB1 100%) 0% 0% no-repeat padding-box",
  cardGradientActive: `linear-gradient(white 0 0) padding-box,
  linear-gradient(180deg, #0F407A 0%, #4BADB1 100%) border-box`,
  cardGradientDeactive: `linear-gradient(white 0 0) padding-box,
  linear-gradient(180deg, #A2A2A2 0%, #A2A2A2 100%) border-box`,
  gray: "#AFAFAF",
  grey: "#A2A2A2",
  buttonGrayText: "#00000061",
  buttonGray: "#0000001F",
  lightgray: "#EBEBEB",
  darkgray: "#393939",
  alert: "#A20F0F",
  success: "#4CAC1C",
  info: "#0B79FB",
  warning: "#C2C208",
  disaggregate: "#7D7D0C",
  reception: "#2A6D09",
  snackbarSuccess: "#43a047",
  snackbarError: "#d32f2f",
  snackbarWarning: "#ffa000",
  snackbarInfo: "#1976d2",
  secondaryHighlight: "#32809A"
};

export const MEDIA = {
  large: 1536,
  medium: 1200,
  small: 900,
  xsmall: 600
};

//Needed to create custom variants for the typography component
declare module "@mui/material/styles" {
  interface TypographyVariants {
    popover_option_text: React.CSSProperties;
    cardtitle: React.CSSProperties;
    cardlabel: React.CSSProperties;
    cardlabel_secondary: React.CSSProperties;
    cardvalue: React.CSSProperties;
    copyright: React.CSSProperties;
    title: React.CSSProperties;
    video_text: React.CSSProperties;
    subText: React.CSSProperties;
    drawer: React.CSSProperties;
    summarydashboard: React.CSSProperties;
    summarydashboard_quantity: React.CSSProperties;
    summarydashboard_label: React.CSSProperties;
    product_history_value: React.CSSProperties;
    product_history_title: React.CSSProperties;
    column_table_typography: React.CSSProperties;
    autocomplete_text: React.CSSProperties;
    form_info: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    popover_option_text?: React.CSSProperties;
    cardtitle?: React.CSSProperties;
    cardlabel?: React.CSSProperties;
    cardlabel_secondary?: React.CSSProperties;
    cardvalue?: React.CSSProperties;
    copyright?: React.CSSProperties;
    title?: React.CSSProperties;
    video_text?: React.CSSProperties;
    subText: React.CSSProperties;
    drawer: React.CSSProperties;
    summarydashboard: React.CSSProperties;
    summarydashboard_quantity: React.CSSProperties;
    summarydashboard_label: React.CSSProperties;
    product_history_value: React.CSSProperties;
    product_history_title: React.CSSProperties;
    column_table_typography?: React.CSSProperties;
    autocomplete_text?: React.CSSProperties;
    form_info: React.CSSProperties;
  }

  interface ButtonVariants {
    primary: React.CSSProperties;
    secondary: React.CSSProperties;
    alert: React.CSSProperties;
    notify: React.CSSProperties;
    disabled: React.CSSProperties;
  }

  interface ButtonVariantsOptions {
    primary?: React.CSSProperties;
    secondary?: React.CSSProperties;
    alert?: React.CSSProperties;
    notify?: React.CSSProperties;
    disabled?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    popover_option_text: true;
    cardtitle: true;
    cardlabel: true;
    cardlabel_secondary: true;
    cardvalue: true;
    copyright: true;
    title: true;
    video_text: true;
    subText: true;
    drawer: true;
    summarydashboard: true;
    summarydashboard_quantity: true;
    summarydashboard_label: true;
    product_history_value: true;
    product_history_title: true;
    column_table_typography: true;
    autocomplete_text: true;
    form_info: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    alert: true;
    notify: true;
    disabled: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.primary
    },
    secondary: {
      main: COLORS.secondary
    },
    background: {
      default: COLORS.background
    },
    warning: {
      main: COLORS.warning
    },
    error: {
      main: COLORS.alert
    }
  },
  typography: {
    fontFamily: ["Poppins", "Roboto", "Helvetica neue", "Arial", "sans-serif"].join(","),
    popover_option_text: { fontSize: "0.625rem" },
    cardtitle: {
      color: COLORS.darkgray,
      fontWeight: 600,
      lineHeight: 1,
      /* Word Break css */
      overflowWrap: "break-word",
      wordWrap: "break-word",
      msWordBreak: "break-all",
      wordBreak: "break-all"
    },
    cardlabel: {
      color: COLORS.gray,
      fontSize: "0.625rem"
    },
    cardlabel_secondary: {
      color: COLORS.secondary,
      fontSize: "0.7rem"
    },
    cardvalue: {
      color: COLORS.darkgray,
      lineHeight: 1.25,
      fontWeight: 500,
      /* Word Break css */
      overflowWrap: "break-word",
      wordWrap: "break-word",
      msWordBreak: "break-all",
      wordBreak: "break-all"
    },
    summarydashboard: {
      color: COLORS.gray,
      fontSize: "18px",
      fontWeight: 600
    },
    summarydashboard_quantity: {
      fontSize: "2.625rem",
      color: COLORS.darkgray,
      fontWeight: 600
    },
    summarydashboard_label: {
      color: COLORS.gray,
      fontSize: "0.938rem"
    },
    subText: {
      fontSize: "0.75rem",
      color: COLORS.grey,
      fontWeight: "normal",
      textAlign: "right"
    },
    title: { fontSize: "1.2rem", color: COLORS.secondary, fontWeight: 600, textAlign: "left" },
    video_text: { fontSize: "2.18rem", color: COLORS.primary, textAlign: "right", margin: "0rem 3rem 4rem 0rem" },
    copyright: {
      fontSize: "0.75rem",
      color: COLORS.lightgray,
      bottom: "2rem",
      position: "absolute"
    },
    drawer: { fontSize: "0.75rem", color: COLORS.darkgray },
    product_history_value: { fontSize: "1.125rem", color: COLORS.darkgray, fontWeight: "bold" },
    product_history_title: {
      fontSize: "1.2rem",
      fontWeight: 600,
      color: COLORS.secondary
    },

    column_table_typography: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "100%",
      fontSize: "0.75rem"
    },
    autocomplete_text: {
      margin: 0,
      fontSize: "0.75rem",
      marginBottom: "0.313rem"
    },
    form_info: { fontSize: "0.75rem", color: COLORS.grey }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          subText: "p",
          copyright: "p",
          title: "p",
          video_text: "h2",
          summarydashboard_quantity: "p",
          summarydashboard_label: "p",
          cardlabel: "p",
          cardvalue: "p",

          product_history_value: "p",
          product_history_title: "p",
          column_table_typography: "p",
          form_info: "p"
        }
      },
      styleOverrides: {
        root: {
          "&.hover:hover": {
            textDecoration: "underline",
            cursor: "pointer"
          },
          "&.disabled": {
            color: COLORS.gray
          }
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          "&.MuiStepLabel-alternativeLabel": {
            fontWeight: 600,
            fontSize: "1rem",
            color: COLORS.primary
          }
        }
      }
    },
    MuiTimelineContent: {
      styleOverrides: {
        root: {
          height: "fit-content"
        }
      }
    },
    MuiTimeline: {
      styleOverrides: {
        root: {
          display: "block",
          padding: 0
        }
      }
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          width: "1.313rem",
          height: "1.313rem",
          marginTop: 0,
          marginBottom: 0,
          backgroundColor: COLORS.secondary
        }
      }
    },
    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          width: "0.438rem",
          backgroundColor: COLORS.secondary
        }
      }
    },
    MuiTimelineItem: {
      styleOverrides: {
        root: {
          height: "100%"
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: COLORS.lightgray
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: COLORS.secondary,
          "&.Mui-checked": {
            color: COLORS.secondary
          },
          "&.Mui-disabled": {
            color: COLORS.grey
          }
        }
      }
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
        size: "small",
        color: "secondary"
      },
      styleOverrides: {
        root: {
          label: {
            fontSize: "0.8rem",
            lineHeight: "1.2rem",
            "&.MuiInputLabel-shrink": {
              fontSize: "0.7rem",
              transform: "translate(0.3rem, -1.5rem)",
              color: COLORS.secondary
            }
          }
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        size: "small",
        color: "secondary"
      },
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-input": {
            marginBottom: " 4px",
            padding: "7.5px 14px",
            height: "1.25rem"
          },
          "& .MuiOutlinedInput-root": {
            fontSize: "0.75rem"
          },
          fieldset: {
            borderColor: COLORS.secondary,
            borderWidth: "2px",
            legend: {
              display: "none"
            }
          },
          "div:not(.Mui-error):hover": {
            fieldset: {
              borderColor: COLORS.secondary,
              borderWidth: "2px"
            }
          },
          label: {
            fontSize: "0.8rem",
            lineHeight: "1.2rem",
            "&.MuiInputLabel-shrink": {
              fontSize: "0.7rem",
              transform: "translate(0.3rem, -1.5rem)",
              color: COLORS.secondary
            }
          },
          "&.disabled-input": {
            label: {
              color: COLORS.gray,
              fontSize: "0.7rem",
              lineHeight: "1.2rem"
            },
            fieldset: {
              borderColor: COLORS.grey,
              borderWidth: "2px"
            },
            "div:not(.Mui-error):hover": {
              fieldset: {
                borderColor: COLORS.grey,
                borderWidth: "2px"
              }
            }
          }
        }
      }
    },
    MuiDialogContent: {
      defaultProps: {
        style: {
          paddingTop: "0.8rem"
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.table-icon-button": {
            width: "2.5rem",
            height: "2.5rem",
            borderRadius: "6px",
            backgroundColor: "white",
            boxShadow: "0px 1px 2px #0000001A",
            border: "1px solid #DFDFDF"
          }
        }
      }
    },
    MuiGrid: {
      styleOverrides: {
        container: {
          width: "100%",
          marginLeft: 0
        },
        root: {
          "&.dashboard-table": {
            border: `1px solid ${COLORS.gray}`,
            borderRadius: 6,
            "& #data-result": {
              marginLeft: 10
            },

            "& .MuiPaper-root": {
              backgroundColor: "transparent",
              margin: 0,
              boxShadow: "none"
            }
          },
          "&.main-view-title": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            "& .MuiSvgIcon-root": {
              color: COLORS.primary
            },
            "& .MuiTypography-root": {
              color: COLORS.secondary,
              fontWeight: "600",
              fontSize: "1.6rem"
            }
          },
          "&.masterdata-box": {
            "& .confirm-dialog-div-content": {
              display: "inline"
            },
            backgroundColor: "white",
            borderRadius: "6px",
            marginTop: "0rem"
          },
          "&.padding": {
            padding: "16px 16px 0 16px"
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.search-bar": {
            display: "flex",
            height: "2.5rem",
            padding: "0.5rem",
            color: COLORS.gray,
            ":hover": {
              color: COLORS.secondary
            },
            borderRadius: "6px",
            backgroundColor: "white",
            boxShadow: "0px 1px 2px #0000001A",
            border: `1px solid ${COLORS.gray}`,
            div: {
              "#search-bar": {
                color: COLORS.darkgray,
                alignSelf: "flex-start",
                marginLeft: "0.7rem"
              },
              ".search-icon": {
                marginRight: "0.69rem",
                size: "24px",
                minWidth: "24px",
                minHeight: "24px"
              },
              ".clear-icon": {
                cursor: "pointer",
                size: "24px",
                color: COLORS.grey,
                minWidth: "24px",
                minHeight: "24px"
              }
            }
          }
        }
      }
    },

    MuiToolbar: {
      styleOverrides: {
        root: {
          padding: 0,
          "& .MuiList-root": {
            padding: "0px"
          },
          "& svg": {
            color: COLORS.secondary
          },
          "& svg:hover": {
            color: COLORS.secondary
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        variant: "contained"
      },
      styleOverrides: {
        contained: {
          padding: "10px 16px",
          fontWeight: 600,
          color: COLORS.white
        }
      },
      variants: [
        {
          props: { variant: "primary" },
          style: {
            "&:hover": {
              backgroundColor: COLORS.secondary
            },
            backgroundColor: COLORS.secondary,
            padding: "10px 16px",
            fontWeight: 600,
            color: COLORS.white,
            letterSpacing: 1.25,
            height: 40
          }
        },
        {
          props: { variant: "secondary" },
          style: {
            backgroundColor: "transparent",
            padding: "10px 16px",
            color: COLORS.gray,
            letterSpacing: 1.25,
            height: 40
          }
        },
        {
          props: { variant: "alert" },
          style: {
            backgroundColor: COLORS.alert,
            "&:hover": {
              backgroundColor: COLORS.alert
            },
            padding: "10px 16px",
            fontWeight: 600,
            color: COLORS.white,
            letterSpacing: 1.25,
            height: 40
          }
        },
        {
          props: { variant: "notify" },
          style: {
            background: "white",
            maxHeight: "40px",
            boxShadow: "none",
            border: "1px solid #0000001F",
            color: COLORS.secondary
          }
        },
        {
          props: { variant: "disabled" },
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.12)",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.12)"
            },
            padding: "10px 16px",
            fontWeight: 600,
            color: "rgba(0, 0, 0, 0.26)",
            letterSpacing: 1.25,
            height: 40
          }
        }
      ]
    },
    MuiDialog: {
      defaultProps: {
        fullWidth: true
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          background: COLORS.gradient,
          padding: "8px",
          paddingLeft: "16px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          color: COLORS.white,
          fontWeight: "600",
          lineHeight: 1.2,
          letterSpacing: "0.07rem",
          /* Word Break css */
          overflowWrap: "break-word",
          wordWrap: "break-word",
          msWordBreak: "break-all"
        }
      }
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center"
        },
        autoHideDuration: 3000
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .MuiDataGrid-iconSeparator": {
            display: "none"
          }
        },
        columnHeaderTitle: {
          fontSize: "small",
          whiteSpace: "initial",
          lineHeight: "1.4rem",
          fontWeight: 600
        },
        columnHeader: {
          backgroundColor: "#ECF7F7"
        },
        cellContent: {
          fontSize: "0.75rem"
        },
        cell: {
          border: "none"
        },
        columnHeaders: {
          border: "none"
        },
        columnHeaderTitleContainer: {
          justifyContent: "space-between"
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: COLORS.lightgray
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.drawer-item": {
            color: COLORS.gray,
            height: "3.5rem"
          },
          "&.drawer-item:hover": {
            color: COLORS.secondary
          }
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "44px"
        }
      }
    },
    MuiInputBase: {
      defaultProps: {
        type: "text",
        inputProps: { autoCapitalize: "none" }
      },
      styleOverrides: {
        root: {
          "&.table-select": {
            color: COLORS.grey,
            backgroundColor: "white",
            height: "2.5rem",
            fieldset: {
              borderColor: COLORS.secondary
            },
            ":hover": {
              fieldset: {
                borderColor: COLORS.secondary
              }
            },
            ul: {
              paddingTop: 0,
              paddingBottom: 0
            }
          },
          // Applies a borderWidth of 2px to every single Select
          "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderWidth: "2px"
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        endAdornment: {
          top: "0px",
          right: "0px"
        },
        listbox: {
          maxHeight: 200,
          boxShadow: "none",
          border: `1px solid ${COLORS.secondary}`,
          fontSize: "0.75rem",
          "&& .MuiAutocomplete-option[aria-selected=true]": {
            backgroundColor: COLORS.secondaryOpacity
          }
        },
        tag: {
          fontSize: "0.75rem",
          marginBottom: "0.313rem"
        },
        popupIndicator: {
          color: COLORS.secondary,
          "& svg": {
            width: "1.25em",
            height: "1.25em"
          } as CSSProperties
        },
        root: {
          "&.MuiAutocomplete-popper": {
            border: `1px ${COLORS.secondary}`
          },

          "& #material-families-select-label": {
            "&.Mui-disabled": {
              color: COLORS.gray
            }
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: "white",
          "&.product-ready": {
            backgroundColor: COLORS.info
          },
          "&.product-aggregated": {
            backgroundColor: COLORS.warning
          },
          "&.product-shipped": {
            backgroundColor: COLORS.success
          },
          "&.product-destroyed": {
            backgroundColor: COLORS.alert
          },
          "&.action-create": {
            backgroundColor: COLORS.info
          },
          "&.action-aggregate": {
            backgroundColor: COLORS.warning
          },
          "&.action-shipment": {
            backgroundColor: COLORS.success
          },
          "&.action-destroy": {
            backgroundColor: COLORS.alert
          },
          "&.action-disaggregate": {
            backgroundColor: COLORS.disaggregate
          },
          "&.action-reception": {
            backgroundColor: COLORS.reception
          }
        }
      }
    },
    MuiSwipeableDrawer: {
      defaultProps: {
        anchor: "left",
        disableSwipeToOpen: false,
        SwipeAreaProps: { sx: { marginTop: "3.5rem" } },
        sx: { zIndex: 3 }
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          "&.custom-item": {
            fontSize: "1rem",
            "&.Mui-selected": { borderRadius: "4px", backgroundColor: COLORS.white },
            ":not(.Mui-selected)": { border: "none", color: COLORS.gray }
          }
        }
      }
    }
  },
  spacing: 16,
  shape: {
    borderRadius: 6
  }
});

export default theme;
