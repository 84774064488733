import { Typography } from "@mui/material";
import IPopover from "./popover-interface";
import { useTranslation } from "react-i18next";
import { TitlePopover } from "./title-popover";
import { useCheckStatus } from "@hooks/useCheckStatus";

export const CardTitle = (props: { value: string; popover: IPopover; name?: string }) => {
  const { value, popover, name } = props;
  const { t } = useTranslation();
  const { handleStatusFilter } = useCheckStatus();

  return (
    <div className="title-card-container">
      <div className="title-content">
        <Typography variant="cardlabel" textAlign="left">
          {name ? t(name) : t("root.name")}
        </Typography>
        <Typography variant="cardtitle" sx={{ wordBreak: "keep-all" }}>
          {value}
        </Typography>
      </div>
      {!handleStatusFilter() && <TitlePopover {...popover} />}
    </div>
  );
};
