import { FamilyDTO } from "@clients/aggrego-proxy/models";

export default class Family {
  id?: string;
  name: string;
  familyTypeId?: string;
  active: boolean;

  constructor(name: string, type: string, active: boolean, id?: string) {
    this.id = id;
    this.name = name;
    this.familyTypeId = type;
    this.active = active;
  }

  static fromClientFamily(family: FamilyDTO) {
    const result = new Family(family.name ?? "", family.familyTypeId ?? "", family.active ?? true, family.id ?? "");
    return result;
  }

  static toClientFamily(family: Family) {
    const result: FamilyDTO = { id: family.id, name: family.name, familyTypeId: family.familyTypeId, active: family.active };
    return result;
  }
}
