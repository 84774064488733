import { useState } from "react";
import { useSnackbar } from "@movicoders/ui";
import { useTranslation } from "react-i18next";
import { IKeyable } from "@domain/interface/IKeyable";
import { LastPageState } from "@domain/model/LastPageState";
import { ILastPageService } from "@domain/services/ILastPageService";
import { useHandleSelectedWarehouse } from "@hooks/useHandleSelectedWarehouse";
import { CustomMovementEventFilter, ListMovementEventPageRequest } from "@clients/aggrego-proxy";
import { convertDateStringToFirstMillisecond, convertDateStringToLastMillisecond } from "@utils/date-helper";

type DateFilter = {
  actionDate: string[];
  searchText: string;
};

export const useMovementsPagination = (
  currentPageService: ILastPageService,
  currentPagePersistedState: LastPageState,
  movementsFetch: (criteria: unknown) => Promise<void>
) => {
  const { t } = useTranslation();
  const { show } = useSnackbar();

  const DEBOUNCE_TIME = 500;
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);
  const { selectedWarehouseCode } = useHandleSelectedWarehouse();

  const search = (limit: number, offset: number, filters?: object | undefined) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    const newDebounceTimer = setTimeout(() => {
      let dateFilter = undefined;
      if (currentPagePersistedState.filters && (currentPagePersistedState.filters as DateFilter).actionDate) {
        dateFilter = {
          initDate: convertDateStringToFirstMillisecond((currentPagePersistedState.filters as DateFilter).actionDate[0]),
          endDate: convertDateStringToLastMillisecond((currentPagePersistedState.filters as DateFilter).actionDate[1]),
          searchText: (currentPageService.persistedState?.filters as IKeyable)?.["searchText"] ?? undefined,
          warehouse: selectedWarehouseCode
        };
      }
      movementsFetch({
        offset,
        limit,
        customMovementEventFilter:
          dateFilter ?? { ...filters, warehouse: selectedWarehouseCode } ??
          ({
            warehouse: selectedWarehouseCode
          } as CustomMovementEventFilter)
      } as ListMovementEventPageRequest).catch(() => {
        show(t("movements.request.error"), "error");
      });
    }, DEBOUNCE_TIME);
    setDebounceTimer(newDebounceTimer);
  };

  return {
    search
  };
};
