import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import { useTranslation } from "react-i18next";
import { useLoginStatus } from "@hooks/useLogin";
import PasswordReset from "./components/password-reset";
import { CompanyData } from "./components/company-data";
import LanguageSelect from "./components/language-select";
import { profileViewStyles } from "./profile-view.Styles";
import { Button, Container, Divider, Grid, Paper, Typography } from "@mui/material";
import MFASelection from "./components/mfa-selection";

const ProfileView = () => {
  const { t } = useTranslation();
  const { handleLogOut } = useLoginStatus();

  const styles = profileViewStyles();

  return (
    <Container sx={styles.profile_view_container} style={{ maxWidth: "none" }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item sx={styles.title_container}>
          <Icon icon="user" color={COLORS.primary} />
          <Typography sx={styles.view_title_typography}>{t("profile")}</Typography>
        </Grid>
        <Grid item>
          <Button size="small" color="secondary" onClick={handleLogOut} sx={{ height: "2.25rem" }}>
            {t("profile.logout")}
          </Button>
        </Grid>
      </Grid>
      <Divider sx={styles.profile_divider} />

      <Paper sx={styles.profile_content_paper}>
        <Grid container display="grid" direction="column" rowSpacing={1.5}>
          <CompanyData />

          <Typography sx={styles.categories_typography}>{t("profile.category.interface")}</Typography>
          <LanguageSelect />

          <Typography sx={styles.categories_typography}>{t("profile.category.mfa")}</Typography>
          <MFASelection />

          <Typography sx={styles.categories_typography}>{t("profile.category.security")}</Typography>
          <PasswordReset />
        </Grid>
      </Paper>
    </Container>
  );
};

export default ProfileView;
