/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserPermissionsDTO
 */
export interface UserPermissionsDTO {
    /**
     * 
     * @type {Set<string>}
     * @memberof UserPermissionsDTO
     */
    logisticOperationsPermissions?: Set<UserPermissionsDTOLogisticOperationsPermissionsEnum>;
    /**
     * 
     * @type {Set<string>}
     * @memberof UserPermissionsDTO
     */
    masterdataPermissions?: Set<UserPermissionsDTOMasterdataPermissionsEnum>;
    /**
     * 
     * @type {Set<string>}
     * @memberof UserPermissionsDTO
     */
    reportsPermissions?: Set<UserPermissionsDTOReportsPermissionsEnum>;
    /**
     * 
     * @type {Set<string>}
     * @memberof UserPermissionsDTO
     */
    trackAndTracePermissions?: Set<UserPermissionsDTOTrackAndTracePermissionsEnum>;
}


/**
 * @export
 */
export const UserPermissionsDTOLogisticOperationsPermissionsEnum = {
    InventoryMaterial: 'INVENTORY_MATERIAL',
    MaterialEntry: 'MATERIAL_ENTRY',
    MaterialExit: 'MATERIAL_EXIT'
} as const;
export type UserPermissionsDTOLogisticOperationsPermissionsEnum = typeof UserPermissionsDTOLogisticOperationsPermissionsEnum[keyof typeof UserPermissionsDTOLogisticOperationsPermissionsEnum];

/**
 * @export
 */
export const UserPermissionsDTOMasterdataPermissionsEnum = {
    Company: 'COMPANY',
    Customer: 'CUSTOMER',
    Family: 'FAMILY',
    FamilyType: 'FAMILY_TYPE',
    Location: 'LOCATION',
    Manufacturer: 'MANUFACTURER',
    Material: 'MATERIAL',
    Operator: 'OPERATOR',
    Owner: 'OWNER',
    Supplier: 'SUPPLIER',
    TileTemplate: 'TILE_TEMPLATE',
    User: 'USER',
    Warehouse: 'WAREHOUSE',
    WarehouseViewer: 'WAREHOUSE_VIEWER',
    Zone: 'ZONE'
} as const;
export type UserPermissionsDTOMasterdataPermissionsEnum = typeof UserPermissionsDTOMasterdataPermissionsEnum[keyof typeof UserPermissionsDTOMasterdataPermissionsEnum];

/**
 * @export
 */
export const UserPermissionsDTOReportsPermissionsEnum = {
    InventoryRegistry: 'INVENTORY_REGISTRY',
    InventoryReport: 'INVENTORY_REPORT',
    MovementsReport: 'MOVEMENTS_REPORT',
    RelabelReport: 'RELABEL_REPORT',
    StockReport: 'STOCK_REPORT',
    WarehouseStatus: 'WAREHOUSE_STATUS'
} as const;
export type UserPermissionsDTOReportsPermissionsEnum = typeof UserPermissionsDTOReportsPermissionsEnum[keyof typeof UserPermissionsDTOReportsPermissionsEnum];

/**
 * @export
 */
export const UserPermissionsDTOTrackAndTracePermissionsEnum = {
    Hierarchy: 'HIERARCHY',
    Product: 'PRODUCT'
} as const;
export type UserPermissionsDTOTrackAndTracePermissionsEnum = typeof UserPermissionsDTOTrackAndTracePermissionsEnum[keyof typeof UserPermissionsDTOTrackAndTracePermissionsEnum];


/**
 * Check if a given object implements the UserPermissionsDTO interface.
 */
export function instanceOfUserPermissionsDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserPermissionsDTOFromJSON(json: any): UserPermissionsDTO {
    return UserPermissionsDTOFromJSONTyped(json, false);
}

export function UserPermissionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPermissionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'logisticOperationsPermissions': !exists(json, 'logisticOperationsPermissions') ? undefined : json['logisticOperationsPermissions'],
        'masterdataPermissions': !exists(json, 'masterdataPermissions') ? undefined : json['masterdataPermissions'],
        'reportsPermissions': !exists(json, 'reportsPermissions') ? undefined : json['reportsPermissions'],
        'trackAndTracePermissions': !exists(json, 'trackAndTracePermissions') ? undefined : json['trackAndTracePermissions'],
    };
}

export function UserPermissionsDTOToJSON(value?: UserPermissionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'logisticOperationsPermissions': value.logisticOperationsPermissions === undefined ? undefined : Array.from(value.logisticOperationsPermissions as Set<any>),
        'masterdataPermissions': value.masterdataPermissions === undefined ? undefined : Array.from(value.masterdataPermissions as Set<any>),
        'reportsPermissions': value.reportsPermissions === undefined ? undefined : Array.from(value.reportsPermissions as Set<any>),
        'trackAndTracePermissions': value.trackAndTracePermissions === undefined ? undefined : Array.from(value.trackAndTracePermissions as Set<any>),
    };
}

