import { TUserRoleEnum } from "@domain/interface/User";
import { ZoneApi } from "@infrastructure/api/zone-api";
import { IUserService } from "@domain/services/IUserService";
import { IImpersonationService } from "@domain/services/IImpersonationService";

export class FindZonesByTenantUseCase {
  constructor(
    private readonly zoneApi: ZoneApi,
    private readonly userService: IUserService,
    private readonly impersonationService: IImpersonationService
  ) {}

  public async execute(status?: "ALL" | "ACTIVE" | "INACTIVE") {
    const isRole = (role: TUserRoleEnum) => {
      return this.userService.user?.roles.includes(role);
    };

    const currentTenant = isRole("SUPER_ADMIN")
      ? this.impersonationService.persistedState?.impersonatedTenant ?? ""
      : this.userService.user?.tenantId ?? "";

    return await this.zoneApi.getByTenantId({ tenantId: currentTenant, fullDTO: false, status: status ?? "ALL" });
  }
}
