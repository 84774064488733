import { COLORS } from "@theme";
import { CircularProgress, Grid } from "@mui/material";
import Icon from "@components/icons/icon";
import { useTranslation } from "react-i18next";
import { DomainPage } from "@movicoders/domain";
import Stakeholder from "@domain/model/Stakeholder";
import { useCheckStatus } from "@hooks/useCheckStatus";
import { StakeholderDetailView } from "./stakeholder-detail-view";
import { StakeholderDetail } from "./components/stakeholders-detail";
import { useStakehodlerSelection } from "./hooks/useStakeholderSelection";
import StakeholdersViewTable from "./view-components/stakeholders-view-table";
import { MasterDataTitle } from "@components/master-data-view/MasterDataTitle";
import { OutletContainer } from "@components/outlet-container/outlet-container";
import { StakeholderActivationDialog } from "./components/stakeholders-activation-dialog";
import { useStakeholderListViewModel } from "./hooks/viewmodel/useStakeholderListViewModel";
import { LoadingSpinnerMasterView } from "@components/loading-circular-progress/loading-spinner-master-view";
import { MasterDataEnum } from "@pages/settings/settings-interfaces";

export const StakeholderMasterView = () => {
  const { t } = useTranslation();

  const { stakeholderType, stakeholderTypeForFilters } = useStakehodlerSelection();

  const {
    selectedStakeholder,
    isActivating,
    stakeholder4Activation,
    stakeholderData,
    stakeholderDetailOpen,
    selectedData,
    loading,
    loadingDetail,
    handleEdit,
    handleSave,
    handlePagination,
    goToList,
    onCloseActive,
    handleActiveConfirm,
    handleStakeholderOpen,
    goToCreate,
    onCloseStakeholderDialog,
    handleStakeholderStatus,
    sortByFieldBackend,
    settingsService
  } = useStakeholderListViewModel(stakeholderType, stakeholderTypeForFilters);

  const { handleStatusFilter } = useCheckStatus();

  return (
    <>
      {(stakeholderTypeForFilters === "CUSTOMER" &&
        settingsService.persistedState?.masterdataPermissions.includes(MasterDataEnum.Customers)) ||
      (stakeholderTypeForFilters === "MANUFACTURER" &&
        settingsService.persistedState?.masterdataPermissions.includes(MasterDataEnum.Manufactures)) ||
      (stakeholderTypeForFilters === "OWNER" &&
        settingsService.persistedState?.masterdataPermissions.includes(MasterDataEnum.Owners)) ||
      (stakeholderTypeForFilters === "SUPPLIER" &&
        settingsService.persistedState?.masterdataPermissions.includes(MasterDataEnum.Suppliers)) ? (
        <>
          <OutletContainer>
            <Grid container display="flex" flexDirection="column">
              <MasterDataTitle
                icon={<Icon icon="users" color={COLORS.primary} />}
                name={t(`${stakeholderType}.title`)}
                buttonText={t(`${stakeholderType}.add.button`)}
                onAddButton={goToCreate}
                showButton={!handleStatusFilter()}
              />

              <StakeholdersViewTable
                stakeholderData={stakeholderData as DomainPage<Stakeholder>}
                stakeholderType={stakeholderType}
                handleStakeholderOpen={handleStakeholderOpen}
                handleEdit={handleEdit}
                handleActiveConfirm={handleActiveConfirm}
                handlePagination={handlePagination}
                sortByFieldBackend={sortByFieldBackend}
              />

              {stakeholder4Activation && (
                <StakeholderActivationDialog
                  open={isActivating}
                  onClose={onCloseActive}
                  onActivation={item => handleStakeholderStatus(stakeholder4Activation)}
                  stakeholder={stakeholder4Activation}
                  stakeholderType={stakeholderType}
                />
              )}
              {selectedData && (
                <StakeholderDetail
                  open={stakeholderDetailOpen}
                  onClose={onCloseStakeholderDialog}
                  stakeholder={selectedData}
                  stakeholderType={stakeholderType}
                />
              )}
              <LoadingSpinnerMasterView loading={loading} />
            </Grid>
          </OutletContainer>

          <StakeholderDetailView
            stakeholder={selectedStakeholder as Stakeholder}
            stakeholderType={stakeholderType}
            loading={loadingDetail}
            onClose={() => goToList()}
            onSave={handleSave}
          />
        </>
      ) : (
        <CircularProgress color="secondary" />
      )}
    </>
  );
};
