import { MeshBasicMaterial } from "three";
import { DEFAULT_COLORS, CAPACITY_COLORS } from "../../constants/Colors";

export const defaultCylinderMaterialGreen = new MeshBasicMaterial({
  color: CAPACITY_COLORS.green,
  transparent: true,
  opacity: 0.8
});

export const defaultCylinderMaterialEmptyGreen = new MeshBasicMaterial({
  color: CAPACITY_COLORS.lightgreen,
  transparent: true,
  opacity: 0.8
});

export const defaultCylinderMaterialYellow = new MeshBasicMaterial({
  color: CAPACITY_COLORS.materialyellow,
  transparent: true,
  opacity: 0.8
});

export const defaultCylinderMaterialEmptyYellow = new MeshBasicMaterial({
  color: CAPACITY_COLORS.lightyellow,
  transparent: true,
  opacity: 0.8
});

export const defaultCylinderMaterialRed = new MeshBasicMaterial({
  color: CAPACITY_COLORS.red,
  transparent: true,
  opacity: 0.8
});

export const defaultCylinderMaterialEmptyRed = new MeshBasicMaterial({
  color: CAPACITY_COLORS.lightred,
  transparent: true,
  opacity: 0.8
});

export const defaultWhiteMaterial = new MeshBasicMaterial({
  color: DEFAULT_COLORS.white,
  transparent: true,
  opacity: 0.6
});

export const getFilledMaterial = (totalContainers: number, capacity: number) => {
  if ((totalContainers / capacity) * 100 === 0)
    return defaultCylinderMaterialGreen
  else if ((totalContainers / capacity) * 100 < 100)
    return defaultCylinderMaterialYellow
  else
    return defaultCylinderMaterialRed
};

export const getEmptyMaterial = (totalContainers: number, capacity: number) => {
  if ((totalContainers / capacity) * 100 === 0)
    return defaultCylinderMaterialEmptyGreen
  else if ((totalContainers / capacity) * 100 < 100)
    return defaultCylinderMaterialEmptyYellow
  else
    return defaultCylinderMaterialEmptyRed
};