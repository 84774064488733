import { LocationDTO, TileDTO } from "@clients/aggrego-proxy/models";

export default class Tile {
  active?: boolean;
  code?: string;
  id?: string;
  locations?: Array<LocationDTO>;
  templateId?: string;
  warehouseId?: string;
  x?: number;
  z?: number;
  zoneId?: string;

  constructor(
    active?: boolean,
    code?: string,
    id?: string,
    locations?: Array<LocationDTO>,
    templateId?: string,
    warehouseId?: string,
    x?: number,
    z?: number,
    zoneId?: string
  ) {
    this.active = active;
    this.code = code;
    this.id = id;
    this.locations = locations;
    this.templateId = templateId;
    this.warehouseId = warehouseId;
    this.x = x;
    this.z = z;
    this.zoneId = zoneId;
  }

  static isPositionValueNotValid(value: number, warehouseSizesAxis: number) {
    return Number(value) > Number(warehouseSizesAxis);
  }

  static fromClientTile(tile: TileDTO) {
    const result = new Tile(
      tile.active,
      tile.code,
      tile.id,
      tile.locations,
      tile.templateId,
      tile.warehouseId,
      tile.x,
      tile.z,
      tile.zoneId
    );
    return result;
  }

  static toClientTile(tile: Tile) {
    const result: TileDTO = {};
    result.active = tile.active;
    result.code = tile.code;
    result.id = tile.id;
    result.locations = tile.locations;
    result.templateId = tile.templateId;
    result.warehouseId = tile.warehouseId;
    result.x = tile.x;
    result.z = tile.z;
    result.zoneId = tile.zoneId;

    return result;
  }
}
