import { useResolve } from "@movicoders/di";
import { LocationRepository } from "../../../../../../infrastructure/repositories/location-repository";
import { TileRepository } from "../../../../../../infrastructure/repositories/tile-repository";
import { Location3D, getLocationLevel } from "../../../types/Location3D";
import { IDialogsStatesStore } from "../../../stores/dialogs-states-store/IDialogsStatesStore";
import { useDialogsStatesStore } from "../../../stores/dialogs-states-store/dialogs-states-store";
import { IWarehouse3DStore } from "../../../stores/warehouse-3d-store/IWarehouse3DStore";
import { useWarehouse3DStore } from "../../../stores/warehouse-3d-store/warehouse-3d-store";
import { useRef } from "react";
import { Tile3D } from "../../../types/Tile3D";
import { useSnackbar } from "@movicoders/ui";
import { useTranslation } from "react-i18next";

export const useDeleteDialogViewmodel = () => {
  const { getWarehouse, setWarehouse } = useResolve<IWarehouse3DStore>(useWarehouse3DStore);
  const {
    getDeleteDialogOpen,
    setDeleteDialogOpen,
    getLocationsToDelete,
    getTilesToDelete
  } = useResolve<IDialogsStatesStore>(useDialogsStatesStore);

  const tileRepository = useRef(useResolve<TileRepository>(TileRepository)).current;
  const locationRepository = useRef(useResolve<LocationRepository>(LocationRepository)).current;

  const { show } = useSnackbar();
  const { t } = useTranslation();

  const handleCloseDialog = () => {
    setDeleteDialogOpen(false);
  };

  // Remove the selected tiles from the warehouse.
  const handleConfirmTileDialog = () => {
    const tilesToRemove: Tile3D[] = [];
    let locationsToRemove: Location3D[] = [];

    getTilesToDelete().forEach(t => {
      const emptyLocations = t.locations?.filter(loc => (loc.containers?.length ?? 1) === 0) ?? [];
      locationsToRemove = locationsToRemove.concat(emptyLocations.filter(loc => loc.active));

      if (emptyLocations.length === t.locations?.length)
        tilesToRemove.push(t);
    });

    // Call to API
    tilesToRemove.length > 0 && tileRepository.removeMany({
      requestBody: tilesToRemove.map(t => t.id ?? "")
    }).catch(() => show(t("viewer.delete.error.code") + ": " + t("viewer.delete.error"), "error"));

    // Call to API
    locationsToRemove.length > 0 && locationRepository.removeMany({
      requestBody: locationsToRemove.map(l => l.id ?? "")
    }).catch(() => show(t("viewer.delete.error.code") + ": " + t("viewer.delete.error"), "error"));

    const tempWH = getWarehouse();
    const tempTiles = [...(getWarehouse().tiles ?? [])];

    getTilesToDelete().forEach(tile => {
      const index = tempTiles.indexOf(tile);
      if (index !== -1) {
        const deletedTile = tile;
        // Remove only deleted tiles (with only empty locations)
        if (tilesToRemove.includes(tile)) deletedTile.active = false;
        // Remove empty locations
        deletedTile.locations
          ?.filter(l => locationsToRemove.includes(l))
          .map(loc => {
            loc.active = false;
            return loc;
          })
        tempTiles[index] = deletedTile;
      } else show(t("viewer.delete.error.code") + ": " + t("viewer.delete.error"), "error");
    });
    tempWH.tiles = tempTiles;
    setWarehouse({ ...tempWH });
    setDeleteDialogOpen(false);
  };

  // Remove the selected bins from the warehouse.
  const handleConfirmBinDialog = () => {
    const locationsToRemove: Location3D[] = getLocationsToDelete().filter(loc => (loc.containers?.length ?? 1) === 0) ?? [];
    const locationsToIgnore: Location3D[] = getLocationsToDelete().filter(loc => (loc.containers?.length ?? 0) > 0) ?? [];

    locationsToRemove.length > 0 && locationRepository.removeMany({
      requestBody: locationsToRemove.map(b => b.id ?? "")
    }).then(() => {
      const tempWH = getWarehouse();
      const tempTiles = [...(getWarehouse().tiles ?? [])];
      tempTiles.forEach(tile => {
        const locationsWithContainers = tile.locations?.filter(loc => locationsToIgnore.includes(loc)) ?? [];
        tile.locations?.forEach(bin => {
          if (locationsToRemove.includes(bin) && bin.active) {
            if ((locationsWithContainers?.length ?? 0) > 0) {
              tile.maxY = Math.max(...locationsWithContainers.map(getLocationLevel));
              bin.active = false;
            } else {
              tile.maxY = Math.min(...locationsToRemove.map(getLocationLevel)) - 1;
              bin.active = false;
            }
          }
        });
      });
      tempWH.tiles = tempTiles;
      setWarehouse({ ...tempWH });
    })
    setDeleteDialogOpen(false);
  };

  return {
    handleCloseDialog,
    handleConfirmTileDialog,
    handleConfirmBinDialog,
    getDeleteDialogOpen,
    getTilesToDelete,
    getLocationsToDelete
  };
};
