/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  CustomFilterDTO,
  DailyStockReportDTO,
  DailyStockReportResponseDTO,
  IMMaterialEntryDTO,
  InventoryRegistryFilterDTO,
  InventoryRegistryPageDTO,
  InventoryReportDTO,
  InventoryReportPageDTO,
  ListResponseDTO,
  MovicodersBadRequestException,
  MovicodersNotFoundException,
  MovicodersServiceException,
  StatusReportFilterDTO,
  StatusReportPageDTO,
  StockReportDTO,
  StockReportFilter,
  StockReportPageDTO
} from "../models";
import {
  CustomFilterDTOFromJSON,
  CustomFilterDTOToJSON,
  DailyStockReportDTOFromJSON,
  DailyStockReportDTOToJSON,
  DailyStockReportResponseDTOFromJSON,
  DailyStockReportResponseDTOToJSON,
  IMMaterialEntryDTOFromJSON,
  IMMaterialEntryDTOToJSON,
  InventoryRegistryFilterDTOFromJSON,
  InventoryRegistryFilterDTOToJSON,
  InventoryRegistryPageDTOFromJSON,
  InventoryRegistryPageDTOToJSON,
  InventoryReportDTOFromJSON,
  InventoryReportDTOToJSON,
  InventoryReportPageDTOFromJSON,
  InventoryReportPageDTOToJSON,
  ListResponseDTOFromJSON,
  ListResponseDTOToJSON,
  MovicodersBadRequestExceptionFromJSON,
  MovicodersBadRequestExceptionToJSON,
  MovicodersNotFoundExceptionFromJSON,
  MovicodersNotFoundExceptionToJSON,
  MovicodersServiceExceptionFromJSON,
  MovicodersServiceExceptionToJSON,
  StatusReportFilterDTOFromJSON,
  StatusReportFilterDTOToJSON,
  StatusReportPageDTOFromJSON,
  StatusReportPageDTOToJSON,
  StockReportDTOFromJSON,
  StockReportDTOToJSON,
  StockReportFilterFromJSON,
  StockReportFilterToJSON,
  StockReportPageDTOFromJSON,
  StockReportPageDTOToJSON
} from "../models";

export interface AdjustmentMaterialStockRequest {
  xTenantId?: string;
  operatorId?: string;
  iMMaterialEntryDTO?: Array<IMMaterialEntryDTO>;
}

export interface ConfigDailyStockReportRequest {
  xTenantId?: string;
  dailyStockReportDTO?: DailyStockReportDTO;
}

export interface GetInventoryRegistryPaginatedByFiltersRequest {
  limit: number;
  offset: number;
  inventoryPeriodId: string;
  xTenantId?: string;
  inventoryRegistryFilterDTO?: InventoryRegistryFilterDTO;
}

export interface GetInventoryReportPaginatedRequest {
  limit: number;
  offset: number;
  inventoryPeriodId: string;
  xTenantId?: string;
  warehouseCode?: string;
  customFilterDTO?: CustomFilterDTO;
}

export interface GetLastConfigDailyStockReportRequest {
  warehouse: string;
  xTenantId?: string;
}

export interface GetLatestStockReportRequest {
  limit: number;
  offset: number;
  warehouseCode: string;
  xTenantId?: string;
  stockReportFilter?: StockReportFilter;
}

export interface GetStockReportRequest {
  materialCode: string;
  xTenantId?: string;
  warehouseCode?: string;
}

export interface GetWarehouseStatusReportByWarehouseAndFilterRequest {
  limit: number;
  offset: number;
  warehouseCode: string;
  xTenantId?: string;
  sortDirection?: GetWarehouseStatusReportByWarehouseAndFilterSortDirectionEnum;
  sortField?: GetWarehouseStatusReportByWarehouseAndFilterSortFieldEnum;
  statusReportFilterDTO?: StatusReportFilterDTO;
}

export interface MakeInventoryRequest {
  warehouseCode: string;
  xTenantId?: string;
  operatorId?: string;
  inventoryReportDTO?: Array<InventoryReportDTO>;
}

/**
 *
 */
export class ReportsApi extends runtime.BaseAPI {
  /**
   * Returns the ListResponseDTO by material code and quantity.
   * Adjustment the stock report by IMMaterialEntryDTO
   */
  async adjustmentMaterialStockRaw(
    requestParameters: AdjustmentMaterialStockRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ListResponseDTO>> {
    const queryParameters: any = {};

    if (requestParameters.operatorId !== undefined) {
      queryParameters["operatorId"] = requestParameters.operatorId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/reports/stock/material/adjustment/im`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.iMMaterialEntryDTO?.map(IMMaterialEntryDTOToJSON)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ListResponseDTOFromJSON(jsonValue));
  }

  /**
   * Returns the ListResponseDTO by material code and quantity.
   * Adjustment the stock report by IMMaterialEntryDTO
   */
  async adjustmentMaterialStock(
    requestParameters: AdjustmentMaterialStockRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ListResponseDTO> {
    const response = await this.adjustmentMaterialStockRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Configures the daily stock report schedule with the provided settings.
   * Configures the daily stock report schedule and returns DailyStockReportResponseDTO
   */
  async configDailyStockReportRaw(
    requestParameters: ConfigDailyStockReportRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<DailyStockReportResponseDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/reports/stock/config/daily`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: DailyStockReportDTOToJSON(requestParameters.dailyStockReportDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => DailyStockReportResponseDTOFromJSON(jsonValue));
  }

  /**
   * Configures the daily stock report schedule with the provided settings.
   * Configures the daily stock report schedule and returns DailyStockReportResponseDTO
   */
  async configDailyStockReport(
    requestParameters: ConfigDailyStockReportRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<DailyStockReportResponseDTO> {
    const response = await this.configDailyStockReportRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns a inventory registry between two dates by warehouseCode and filters. If warehouseCode is null, returns all items
   * Returns a inventory registry between two dates by warehouseCode and filters
   */
  async getInventoryRegistryPaginatedByFiltersRaw(
    requestParameters: GetInventoryRegistryPaginatedByFiltersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<InventoryRegistryPageDTO>> {
    if (requestParameters.limit === null || requestParameters.limit === undefined) {
      throw new runtime.RequiredError(
        "limit",
        "Required parameter requestParameters.limit was null or undefined when calling getInventoryRegistryPaginatedByFilters."
      );
    }

    if (requestParameters.offset === null || requestParameters.offset === undefined) {
      throw new runtime.RequiredError(
        "offset",
        "Required parameter requestParameters.offset was null or undefined when calling getInventoryRegistryPaginatedByFilters."
      );
    }

    if (requestParameters.inventoryPeriodId === null || requestParameters.inventoryPeriodId === undefined) {
      throw new runtime.RequiredError(
        "inventoryPeriodId",
        "Required parameter requestParameters.inventoryPeriodId was null or undefined when calling getInventoryRegistryPaginatedByFilters."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.inventoryPeriodId !== undefined) {
      queryParameters["inventoryPeriodId"] = requestParameters.inventoryPeriodId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/reports/inventory-registry/paginated`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: InventoryRegistryFilterDTOToJSON(requestParameters.inventoryRegistryFilterDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => InventoryRegistryPageDTOFromJSON(jsonValue));
  }

  /**
   * Returns a inventory registry between two dates by warehouseCode and filters. If warehouseCode is null, returns all items
   * Returns a inventory registry between two dates by warehouseCode and filters
   */
  async getInventoryRegistryPaginatedByFilters(
    requestParameters: GetInventoryRegistryPaginatedByFiltersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<InventoryRegistryPageDTO> {
    const response = await this.getInventoryRegistryPaginatedByFiltersRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns a inventory report between two dates by warehouseCode. If warehouseCode is null, returns all items
   * Returns a inventory report between two dates by warehouseCode
   */
  async getInventoryReportPaginatedRaw(
    requestParameters: GetInventoryReportPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<InventoryReportPageDTO>> {
    if (requestParameters.limit === null || requestParameters.limit === undefined) {
      throw new runtime.RequiredError(
        "limit",
        "Required parameter requestParameters.limit was null or undefined when calling getInventoryReportPaginated."
      );
    }

    if (requestParameters.offset === null || requestParameters.offset === undefined) {
      throw new runtime.RequiredError(
        "offset",
        "Required parameter requestParameters.offset was null or undefined when calling getInventoryReportPaginated."
      );
    }

    if (requestParameters.inventoryPeriodId === null || requestParameters.inventoryPeriodId === undefined) {
      throw new runtime.RequiredError(
        "inventoryPeriodId",
        "Required parameter requestParameters.inventoryPeriodId was null or undefined when calling getInventoryReportPaginated."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.inventoryPeriodId !== undefined) {
      queryParameters["inventoryPeriodId"] = requestParameters.inventoryPeriodId;
    }

    if (requestParameters.warehouseCode !== undefined) {
      queryParameters["warehouseCode"] = requestParameters.warehouseCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/reports/inventory-report/paginated`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CustomFilterDTOToJSON(requestParameters.customFilterDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => InventoryReportPageDTOFromJSON(jsonValue));
  }

  /**
   * Returns a inventory report between two dates by warehouseCode. If warehouseCode is null, returns all items
   * Returns a inventory report between two dates by warehouseCode
   */
  async getInventoryReportPaginated(
    requestParameters: GetInventoryReportPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<InventoryReportPageDTO> {
    const response = await this.getInventoryReportPaginatedRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the last ConfigDailyStockReport by warehouse code. If there are not any saved config, returns null
   * Returns the last ConfigDailyStockReport by warehouse code
   */
  async getLastConfigDailyStockReportRaw(
    requestParameters: GetLastConfigDailyStockReportRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<DailyStockReportResponseDTO>> {
    if (requestParameters.warehouse === null || requestParameters.warehouse === undefined) {
      throw new runtime.RequiredError(
        "warehouse",
        "Required parameter requestParameters.warehouse was null or undefined when calling getLastConfigDailyStockReport."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.warehouse !== undefined) {
      queryParameters["warehouse"] = requestParameters.warehouse;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/reports/stock/config/daily`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => DailyStockReportResponseDTOFromJSON(jsonValue));
  }

  /**
   * Returns the last ConfigDailyStockReport by warehouse code. If there are not any saved config, returns null
   * Returns the last ConfigDailyStockReport by warehouse code
   */
  async getLastConfigDailyStockReport(
    requestParameters: GetLastConfigDailyStockReportRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<DailyStockReportResponseDTO> {
    const response = await this.getLastConfigDailyStockReportRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the latest stock report by warehouseCode. If warehouseCode is null, returns all items
   * Returns the latest stock report by warehouseCode
   */
  async getLatestStockReportRaw(
    requestParameters: GetLatestStockReportRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<StockReportPageDTO>> {
    if (requestParameters.limit === null || requestParameters.limit === undefined) {
      throw new runtime.RequiredError(
        "limit",
        "Required parameter requestParameters.limit was null or undefined when calling getLatestStockReport."
      );
    }

    if (requestParameters.offset === null || requestParameters.offset === undefined) {
      throw new runtime.RequiredError(
        "offset",
        "Required parameter requestParameters.offset was null or undefined when calling getLatestStockReport."
      );
    }

    if (requestParameters.warehouseCode === null || requestParameters.warehouseCode === undefined) {
      throw new runtime.RequiredError(
        "warehouseCode",
        "Required parameter requestParameters.warehouseCode was null or undefined when calling getLatestStockReport."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.warehouseCode !== undefined) {
      queryParameters["warehouseCode"] = requestParameters.warehouseCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/reports/stock`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: StockReportFilterToJSON(requestParameters.stockReportFilter)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => StockReportPageDTOFromJSON(jsonValue));
  }

  /**
   * Returns the latest stock report by warehouseCode. If warehouseCode is null, returns all items
   * Returns the latest stock report by warehouseCode
   */
  async getLatestStockReport(
    requestParameters: GetLatestStockReportRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<StockReportPageDTO> {
    const response = await this.getLatestStockReportRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the stock report by material, warehouseCode. If warehouseCode is null, returns all items
   * Returns the stock report by material code, warehouseCode
   */
  async getStockReportRaw(
    requestParameters: GetStockReportRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<StockReportDTO>> {
    if (requestParameters.materialCode === null || requestParameters.materialCode === undefined) {
      throw new runtime.RequiredError(
        "materialCode",
        "Required parameter requestParameters.materialCode was null or undefined when calling getStockReport."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.materialCode !== undefined) {
      queryParameters["materialCode"] = requestParameters.materialCode;
    }

    if (requestParameters.warehouseCode !== undefined) {
      queryParameters["warehouseCode"] = requestParameters.warehouseCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/reports/stock/material`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => StockReportDTOFromJSON(jsonValue));
  }

  /**
   * Returns the stock report by material, warehouseCode. If warehouseCode is null, returns all items
   * Returns the stock report by material code, warehouseCode
   */
  async getStockReport(
    requestParameters: GetStockReportRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<StockReportDTO> {
    const response = await this.getStockReportRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the warehouse status report which match filter by warehouse
   */
  async getWarehouseStatusReportByWarehouseAndFilterRaw(
    requestParameters: GetWarehouseStatusReportByWarehouseAndFilterRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<StatusReportPageDTO>> {
    if (requestParameters.limit === null || requestParameters.limit === undefined) {
      throw new runtime.RequiredError(
        "limit",
        "Required parameter requestParameters.limit was null or undefined when calling getWarehouseStatusReportByWarehouseAndFilter."
      );
    }

    if (requestParameters.offset === null || requestParameters.offset === undefined) {
      throw new runtime.RequiredError(
        "offset",
        "Required parameter requestParameters.offset was null or undefined when calling getWarehouseStatusReportByWarehouseAndFilter."
      );
    }

    if (requestParameters.warehouseCode === null || requestParameters.warehouseCode === undefined) {
      throw new runtime.RequiredError(
        "warehouseCode",
        "Required parameter requestParameters.warehouseCode was null or undefined when calling getWarehouseStatusReportByWarehouseAndFilter."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sortDirection"] = requestParameters.sortDirection;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sortField"] = requestParameters.sortField;
    }

    if (requestParameters.warehouseCode !== undefined) {
      queryParameters["warehouseCode"] = requestParameters.warehouseCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/reports/warehouse/status`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: StatusReportFilterDTOToJSON(requestParameters.statusReportFilterDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => StatusReportPageDTOFromJSON(jsonValue));
  }

  /**
   * Returns the warehouse status report which match filter by warehouse
   */
  async getWarehouseStatusReportByWarehouseAndFilter(
    requestParameters: GetWarehouseStatusReportByWarehouseAndFilterRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<StatusReportPageDTO> {
    const response = await this.getWarehouseStatusReportByWarehouseAndFilterRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Makes inventory given a warehouse code
   */
  async makeInventoryRaw(
    requestParameters: MakeInventoryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<string>> {
    if (requestParameters.warehouseCode === null || requestParameters.warehouseCode === undefined) {
      throw new runtime.RequiredError(
        "warehouseCode",
        "Required parameter requestParameters.warehouseCode was null or undefined when calling makeInventory."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.operatorId !== undefined) {
      queryParameters["operatorId"] = requestParameters.operatorId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/reports/inventory/{warehouseCode}`.replace(
          `{${"warehouseCode"}}`,
          encodeURIComponent(String(requestParameters.warehouseCode))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.inventoryReportDTO?.map(InventoryReportDTOToJSON)
      },
      initOverrides
    );

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   * Makes inventory given a warehouse code
   */
  async makeInventory(
    requestParameters: MakeInventoryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<string> {
    const response = await this.makeInventoryRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

/**
 * @export
 */
export const GetWarehouseStatusReportByWarehouseAndFilterSortDirectionEnum = {
  Asc: "ASC",
  Desc: "DESC"
} as const;
export type GetWarehouseStatusReportByWarehouseAndFilterSortDirectionEnum =
  (typeof GetWarehouseStatusReportByWarehouseAndFilterSortDirectionEnum)[keyof typeof GetWarehouseStatusReportByWarehouseAndFilterSortDirectionEnum];
/**
 * @export
 */
export const GetWarehouseStatusReportByWarehouseAndFilterSortFieldEnum = {
  Container: "CONTAINER",
  Date: "DATE",
  Description: "DESCRIPTION",
  Location: "LOCATION",
  Material: "MATERIAL",
  Operator: "OPERATOR",
  Qty: "QTY",
  Uom: "UOM",
  User: "USER"
} as const;
export type GetWarehouseStatusReportByWarehouseAndFilterSortFieldEnum =
  (typeof GetWarehouseStatusReportByWarehouseAndFilterSortFieldEnum)[keyof typeof GetWarehouseStatusReportByWarehouseAndFilterSortFieldEnum];
