import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import { Grid, Typography } from "@mui/material";

export const CardTitle = ({ value, iconName }: { value: string; iconName?: string }) => {
  return (
    <Grid item container wrap="nowrap" spacing={{ xs: "1rem" }}>
      <Grid item alignSelf="center">
        <Icon icon={iconName ?? "palet"} color={COLORS.primary} />
      </Grid>
      <Grid item>
        <Typography variant="cardtitle">{value}</Typography>
      </Grid>
    </Grid>
  );
};
