import { COLORS } from "@theme";
import { useEffect } from "react";
import Logo from "../../logo/logo";
import Icon from "../../icons/icon";
import { ROUTER_PATHS } from "@constants";
import { useResolve } from "@movicoders/di";
import { drawerStyles } from "./drawerStyles";
import { useNavigate } from "react-router-dom";
import { PathsList } from "./components/paths-list";
import { DRAWER_OPEN_WIDTH } from "./drawer-constants";
import { usePermissions } from "@hooks/usePermissions";
import { useHandlePortal } from "@hooks/useHandlePortal";
import { WarehouseList } from "./components/warehouse-list";
import { useDrawerViewModel } from "./hooks/useDrawerViewModel";
import { CollapsibleMenu } from "./components/collapsible-menu";
import { TUserRoleEnum, UserRoleEnum } from "@domain/interface/User";
import { FullUserDTOCompanyLicenseEnum } from "@clients/aggrego-proxy";
import { Impersonation } from "./components/impersonation/Impersonation";
import { IUserService, UserService } from "@domain/services/IUserService";
import { IconButton, Divider, Grid, Paper, List, Backdrop } from "@mui/material";
import { drawerCompanies, drawerPaths, drawerSettingPaths } from "./drawer-paths-configuration";
import { useImpersonationViewModel } from "./components/impersonation/useImpersonationViewModel";
import { WarehouseByTenantUseCase } from "@application/warehouse-by-tenant/warehouse-by-tenant-use-case";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { ReportEnum } from "@pages/settings/settings-interfaces";

export const Drawer = () => {
  const styles = drawerStyles;
  const navigate = useNavigate();

  const {
    handleDrawerOpen,
    reportsSubmenuConf,
    masterdataSubmenuConf,
    trackAndTraceSubmenuConf,
    logisticSubmenuConf,
    DRAWER_WIDTH,
    persistedState,
    isValidImpersonationUseCase,
    isDrawerOpen
  } = useDrawerViewModel();

  const { isValidPermissions } = usePermissions();
  const { becomeImpersonated } = useImpersonationViewModel();
  const { isPortalAggrego, impersonationService } = useHandlePortal();

  const userService = useResolve<IUserService>(UserService);
  const settingsService = useResolve<ISettingsService>(SettingsService);
  const isRole = (role: TUserRoleEnum) => {
    return userService.user?.roles.includes(role);
  };
  const userCompanyLicense = (userService.user?.companyLicense as FullUserDTOCompanyLicenseEnum) ?? undefined;

  //UseCase to get warehouses by tenantID (by user tenant or impersonatedTenant)
  const warehousesByTenantUseCase = useResolve(WarehouseByTenantUseCase);

  useEffect(() => {
    if (
      isRole("SUPER_ADMIN") &&
      !impersonationService.persistedState?.isImpersonated &&
      impersonationService.persistedState?.impersonatedTenant
    ) {
      becomeImpersonated();
    }
  }, [impersonationService.persistedState?.impersonatedTenant]);

  useEffect(() => {
    !isRole("SUPER_ADMIN") &&
      impersonationService.persistedState?.portal &&
      persistedState.warehouses.length === 0 &&
      userService.user?.tenantId !== "" &&
      warehousesByTenantUseCase.get();
  }, [userService.user?.tenantId]);

  useEffect(() => {
    if (impersonationService.persistedState?.portal) {
      if (impersonationService.persistedState?.portal) {
        persistedState.warehouses.length === 0 &&
          impersonationService.persistedState?.isImpersonated &&
          warehousesByTenantUseCase.get();
      }
    }
  }, [impersonationService.persistedState?.isImpersonated]);

  const selectNavigationPath = () => {
    if (isRole("SUPER_ADMIN")) {
      if (!impersonationService.persistedState?.isImpersonated) {
        navigate(ROUTER_PATHS.adminBoard);
      } else {
        if (settingsService.persistedState?.reportsPermissions.includes(ReportEnum.WarehousesStatus)) {
          navigate(ROUTER_PATHS.whstatus);
        } else {
          navigate(`/${ROUTER_PATHS.home}/${persistedState.selectedWarehouse}`);
        }
      }
    } else {
      if (settingsService.persistedState?.reportsPermissions.includes(ReportEnum.WarehousesStatus)) {
        navigate(ROUTER_PATHS.whstatus);
      } else {
        navigate(`/${ROUTER_PATHS.home}/${persistedState.selectedWarehouse}`);
      }
    }
  };

  return (
    <>
      <Paper
        id="drawer"
        role="button"
        onClick={e => {
          if (isDrawerOpen) {
            handleDrawerOpen();
          }
          e.stopPropagation();
        }}
        sx={{ width: DRAWER_WIDTH, ...styles.drawer_paper }}>
        <Grid item>
          {/* LOGO */}
          <Grid sx={{ ml: "0.8rem", pt: "0.5rem", mr: isDrawerOpen ? "2rem" : 0 }} onClick={selectNavigationPath}>
            <Logo completeLogo={isDrawerOpen} />
          </Grid>
          <Divider />
        </Grid>
        <List
          sx={{ width: DRAWER_WIDTH, zIndex: 4 }}
          component="div"
          aria-labelledby="nested-list-subheader"
          id="drawer-warehouses-list"
          onClick={handleDrawerOpen}>
          {isPortalAggrego && isValidPermissions([UserRoleEnum.SuperAdmin]) ? (
            <>
              {/* Impersonation feature */}
              <Impersonation drawerWidth={DRAWER_WIDTH} isDrawerOpen={isDrawerOpen} />
            </>
          ) : null}
          {isPortalAggrego && isValidImpersonationUseCase.execute() ? (
            <>
              {/* WAREHOUSE LIST */}
              <WarehouseList isDrawerOpen={isDrawerOpen} drawerWidth={DRAWER_WIDTH} />
            </>
          ) : null}
        </List>
        {/* COMPANY PAGE (ONLY IN PORTALAMDIN) */}
        {!isPortalAggrego && <PathsList drawerWidth={DRAWER_WIDTH} isDrawerOpen={isDrawerOpen} paths={drawerCompanies()} />}

        {isPortalAggrego && isValidImpersonationUseCase.execute() && <Divider />}

        <Grid container sx={{ overflowX: "hidden", overflowY: "auto", height: "100%" }}>
          {isPortalAggrego && (
            <Grid item xs={12} flexDirection="column">
              {/* UPPER LIST */}
              {/* Warehouse-Viewer */}
              {masterdataSubmenuConf.settingsArray.includes("warehouse-viewer") ? (
                <PathsList drawerWidth={DRAWER_WIDTH} isDrawerOpen={isDrawerOpen} paths={drawerPaths(userCompanyLicense)} />
              ) : (
                <></>
              )}
              {settingsService.persistedState && settingsService.persistedState?.masterdataPermissions.length > 0 && (
                <CollapsibleMenu {...masterdataSubmenuConf} />
              )}
              {settingsService.persistedState && settingsService.persistedState?.reportsPermissions.length > 0 && (
                <CollapsibleMenu {...reportsSubmenuConf} />
              )}
              {(isRole("SUPER_ADMIN") || isRole("PORTAL_ADMIN")) &&
                userCompanyLicense === "IM" &&
                settingsService.persistedState &&
                settingsService.persistedState?.logisticOperationsPermissions.length > 0 && (
                  <CollapsibleMenu {...logisticSubmenuConf} />
                )}
              {!trackAndTraceSubmenuConf.paths[0].hiddenByIM &&
                settingsService.persistedState &&
                settingsService.persistedState?.trackAndTracePermissions.length > 0 && (
                  <CollapsibleMenu {...trackAndTraceSubmenuConf} />
                )}
            </Grid>
          )}

          <Grid container item id="drawer-settings-grid-container" xs={12} flexDirection="column" justifyContent="flex-end">
            {/* BOTTOM LIST */}
            <List
              sx={{
                width: DRAWER_WIDTH,
                zIndex: 4,
                alignSelf: "flex-end"
              }}
              component="nav"
              aria-labelledby="nested-list-subheader">
              <PathsList
                drawerWidth={DRAWER_WIDTH}
                isDrawerOpen={isDrawerOpen}
                paths={drawerSettingPaths(userCompanyLicense, impersonationService)}
              />
            </List>
          </Grid>
        </Grid>

        <Grid item>
          <Divider />
          <IconButton
            id="drawer-open"
            data-is-drawer-open={isDrawerOpen}
            size="large"
            aria-label="open drawer"
            sx={{ ml: 0, color: COLORS.gray }}
            onClick={handleDrawerOpen}>
            {isDrawerOpen ? <Icon icon="chevron-left" /> : <Icon icon="chevron-right" />}
          </IconButton>
        </Grid>
      </Paper>
      {DRAWER_WIDTH === DRAWER_OPEN_WIDTH && (
        <Backdrop
          component={"div"}
          onClick={() => handleDrawerOpen()}
          sx={{ zIndex: 5, backgroundColor: "transparent" }}
          open={true}
        />
      )}
    </>
  );
};
