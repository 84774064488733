import { ProductState } from "./ProductState";
import { ChildDTO } from "@clients/aggrego-proxy";
import { DTOConvertibleEntity } from "./GenericPage";

export class ProductChild extends DTOConvertibleEntity<ChildDTO, ProductChild> {
  public constructor();
  public constructor(obj?: Partial<ProductChild>);
  public constructor(obj?: Partial<ProductChild>) {
    super();
    obj && Object.assign(this, obj);
  }
  /**
   *
   * @type {string}
   * @memberof ChildDTO
   */
  batch?: string;
  /**
   *
   * @type {string}
   * @memberof ChildDTO
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ChildDTO
   */
  materialCode?: string;
  /**
   *
   * @type {number}
   * @memberof ChildDTO
   */
  qty?: number;
  /**
   *
   * @type {string}
   * @memberof ChildDTO
   */
  serialNumber?: string;
  /**
   *
   * @type {string}
   * @memberof ChildDTO
   */
  tenant?: string;
  /**
   *
   * @type {string}
   * @memberof ChildDTO
   */
  uom?: string;

  state?: ProductState;

  public static fromDTO(child: ChildDTO): ProductChild {
    return new ProductChild({ ...child });
  }

  public toDTO(): ProductChild {
    return { ...this };
  }
}
