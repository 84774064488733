import { COLORS } from "@theme";
import { SxProps, Theme } from "@mui/material";

export const dataGridDisplayFooterStyles = () => {
  const data_grid_display_footer: SxProps<Theme> = {
    height: "2.5rem",
    justifyContent: "flex-end",
    alignContent: "center",
    marginTop: "1.5rem",
    marginBottom: "1rem",
    fontSize: "small",
    color: COLORS.gray
  };

  return {
    data_grid_display_footer
  };
};
