import Zone from "@domain/model/Zone";
import { ZoneRepository } from "@infrastructure/repositories/zone-repository";

export class ZoneUpdateStatusUseCase {
  constructor(private readonly zoneRepository: ZoneRepository) {}

  public async execute(zone: Zone) {
    const modifiedZone = { ...zone, active: !zone.active };
    return await this.zoneRepository.update(modifiedZone);
  }
}
