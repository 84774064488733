import { useRef, useState } from "react";
import { useResolve } from "@movicoders/di";
import { useTranslation } from "react-i18next";
import { useListDetailViewModel, useSnackbar } from "@movicoders/ui";
import {
  CompanyDTO,
  FullCompanyItemDTO,
  ListCompaniesPaginatedSortDirectionEnum,
  ListCompanyItemPaginatedRequest
} from "@clients/aggrego-proxy";
import { GridSortModel } from "@mui/x-data-grid";
import { CREATE_ID, TIMEOUT_ID } from "@constants";
import { IKeyable } from "@domain/interface/IKeyable";
import { useAdminMessagges } from "./useAdminMessagges";
import { LastPageState } from "@domain/model/LastPageState";
import { CompanyRepository } from "@infrastructure/repositories/company-repository";
import { ILastPageService, LastPageService } from "@domain/services/ILastPageService";
import { convertDateStringToFirstMillisecond, convertDateStringToLastMillisecond } from "@utils/date-helper";

type DateFilter = {
  created: string[];
  modified: string[];
  searchText: string;
};

export const useAdminBoardListViewModel = () => {
  const {
    selected: selectedCompany,
    save,
    create,
    fetchOne,
    goToList,
    loading,
    dataPaginated,
    getPaginated
  } = useListDetailViewModel(CompanyRepository);

  const { t } = useTranslation();
  const { show } = useSnackbar();
  const { successMessaggesSaving, errorMessaggesSaving } = useAdminMessagges();

  const service = useResolve<ILastPageService>(LastPageService);
  const persistedState = service.persistedState ?? new LastPageState();

  const getCompaniesPaginated = useRef(getPaginated).current;

  const DEBOUNCE_TIME = 500;
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);

  const handleEdit = (id: string) => {
    fetchOne(id).catch(() => {
      show(
        t("loading.detail.error", {
          value: (selectedCompany as FullCompanyItemDTO)?.companyName
            ? ` "${(selectedCompany as FullCompanyItemDTO)?.companyName}"`
            : "",
          mdNameThe: t("the.company")
        }),
        "error"
      );
      (selectedCompany as CompanyDTO).id = TIMEOUT_ID;
    });
  };

  const handleSave = (company: FullCompanyItemDTO) => {
    if ((company as CompanyDTO).id === CREATE_ID) {
      company.active = true;
    }

    return save({
      ...company,
      companyName: company.companyName ?? (company as CompanyDTO).name,
      tenant: company.tenant ?? company.companyName ?? company.tenantName ?? (company as CompanyDTO).id,
      tenantName: company.tenantName ?? company.companyName ?? company.tenant ?? (company as CompanyDTO).id
    })
      .then(() => successMessaggesSaving(company))
      .catch(error => errorMessaggesSaving(company, error))
      .finally(() => {
        getCompaniesPaginated({
          xTenantId: "",
          limit: persistedState.limit,
          offset: persistedState.offset,
          fullDTO: false,
          companyFilterDTO: persistedState.filters,
          sortDirection: persistedState.sortDirection,
          sortField: persistedState.sortField
        } as ListCompanyItemPaginatedRequest);
        goToList();
      });
  };

  const getStatus = (status: "ACTIVE" | "INACTIVE" | "ALL"): boolean | undefined => {
    const STATUS_ACTIVE = true;
    const STATUS_INACTIVE = false;

    if (status === "ACTIVE") {
      return STATUS_ACTIVE;
    }
    if (status === "INACTIVE") {
      return STATUS_INACTIVE;
    } else {
      return undefined;
    }
  };

  const handlePagination = (limit: number, offset: number, status?: "ALL" | "ACTIVE" | "INACTIVE", filters?: object) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    const newDebounceTimer = setTimeout(() => {
      let dateFilter = undefined;
      if (persistedState.filters && (persistedState.filters as DateFilter).created) {
        dateFilter = {
          createdInitDate: convertDateStringToFirstMillisecond((persistedState.filters as DateFilter).created[0]),
          createdEndDate: convertDateStringToLastMillisecond((persistedState.filters as DateFilter).created[1]),
          searchText: (persistedState?.filters as IKeyable)?.["searchText"] ?? undefined
        };
      } else if (persistedState.filters && (persistedState.filters as DateFilter).modified) {
        dateFilter = {
          modifiedInitDate: convertDateStringToFirstMillisecond((persistedState.filters as DateFilter).modified[0]),
          modifiedEndDate: convertDateStringToLastMillisecond((persistedState.filters as DateFilter).modified[1]),
          searchText: (persistedState?.filters as IKeyable)?.["searchText"] ?? undefined
        };
      }
      getCompaniesPaginated({
        xTenantId: "",
        limit: limit,
        offset: offset,
        companyFilterDTO: dateFilter ?? { active: getStatus(status ?? "ALL"), ...persistedState.filters },
        sortDirection: persistedState.sortDirection,
        sortField: persistedState.sortField
      } as ListCompanyItemPaginatedRequest);
    }, DEBOUNCE_TIME);
    setDebounceTimer(newDebounceTimer);
  };

  const columnNameConverter = (model: string | undefined) => {
    switch (model) {
      case "COMPANYNAME":
        return "NAME";
      case "STATUS":
        return "ACTIVE";
      default:
        return model;
    }
  };

  const sortByFieldBackend = (model: GridSortModel) => {
    dataPaginated.content &&
      dataPaginated?.content.length > 0 &&
      getCompaniesPaginated({
        limit: persistedState.limit,
        offset: persistedState.offset,
        companyFilterDTO: persistedState.filters,
        sortDirection: model[0]?.sort?.toUpperCase() ?? ListCompaniesPaginatedSortDirectionEnum.Asc,
        sortField: columnNameConverter(model[0]?.field?.toUpperCase())
      } as ListCompanyItemPaginatedRequest);
    service.saveLastPageState({
      ...persistedState,
      sortDirection: model[0]?.sort?.toUpperCase() as "ASC" | "DESC" | undefined,
      sortField: columnNameConverter(model[0]?.field?.toUpperCase())
    });
  };

  return {
    selectedCompany,
    dataPaginated,
    handleEdit,
    handleSave,
    create,
    goToList,
    handlePagination,
    sortByFieldBackend,
    loading
  };
};
