/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductResume } from './ProductResume';
import {
    ProductResumeFromJSON,
    ProductResumeFromJSONTyped,
    ProductResumeToJSON,
} from './ProductResume';

/**
 * 
 * @export
 * @interface EventDTO
 */
export interface EventDTO {
    /**
     * 
     * @type {string}
     * @memberof EventDTO
     */
    action?: EventDTOActionEnum;
    /**
     * 
     * @type {Date}
     * @memberof EventDTO
     */
    actionDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof EventDTO
     */
    agent?: string;
    /**
     * 
     * @type {Array<ProductResume>}
     * @memberof EventDTO
     */
    children?: Array<ProductResume>;
    /**
     * 
     * @type {string}
     * @memberof EventDTO
     */
    departure?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDTO
     */
    destination?: string;
    /**
     * 
     * @type {Date}
     * @memberof EventDTO
     */
    eventDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof EventDTO
     */
    extension?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDTO
     */
    operatorId?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDTO
     */
    order?: string;
    /**
     * 
     * @type {ProductResume}
     * @memberof EventDTO
     */
    parent?: ProductResume;
    /**
     * 
     * @type {string}
     * @memberof EventDTO
     */
    qty?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDTO
     */
    uom?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDTO
     */
    user?: string;
}


/**
 * @export
 */
export const EventDTOActionEnum = {
    Aggregate: 'AGGREGATE',
    Create: 'CREATE',
    Destroy: 'DESTROY',
    Disaggregate: 'DISAGGREGATE',
    Reception: 'RECEPTION',
    Shipment: 'SHIPMENT'
} as const;
export type EventDTOActionEnum = typeof EventDTOActionEnum[keyof typeof EventDTOActionEnum];


/**
 * Check if a given object implements the EventDTO interface.
 */
export function instanceOfEventDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EventDTOFromJSON(json: any): EventDTO {
    return EventDTOFromJSONTyped(json, false);
}

export function EventDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': !exists(json, 'action') ? undefined : json['action'],
        'actionDate': !exists(json, 'actionDate') ? undefined : (new Date(json['actionDate'])),
        'agent': !exists(json, 'agent') ? undefined : json['agent'],
        'children': !exists(json, 'children') ? undefined : ((json['children'] as Array<any>).map(ProductResumeFromJSON)),
        'departure': !exists(json, 'departure') ? undefined : json['departure'],
        'destination': !exists(json, 'destination') ? undefined : json['destination'],
        'eventDate': !exists(json, 'eventDate') ? undefined : (new Date(json['eventDate'])),
        'extension': !exists(json, 'extension') ? undefined : json['extension'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'operatorId': !exists(json, 'operatorId') ? undefined : json['operatorId'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'parent': !exists(json, 'parent') ? undefined : ProductResumeFromJSON(json['parent']),
        'qty': !exists(json, 'qty') ? undefined : json['qty'],
        'uom': !exists(json, 'uom') ? undefined : json['uom'],
        'user': !exists(json, 'user') ? undefined : json['user'],
    };
}

export function EventDTOToJSON(value?: EventDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': value.action,
        'actionDate': value.actionDate === undefined ? undefined : (value.actionDate.toISOString()),
        'agent': value.agent,
        'children': value.children === undefined ? undefined : ((value.children as Array<any>).map(ProductResumeToJSON)),
        'departure': value.departure,
        'destination': value.destination,
        'eventDate': value.eventDate === undefined ? undefined : (value.eventDate.toISOString()),
        'extension': value.extension,
        'id': value.id,
        'operatorId': value.operatorId,
        'order': value.order,
        'parent': ProductResumeToJSON(value.parent),
        'qty': value.qty,
        'uom': value.uom,
        'user': value.user,
    };
}

