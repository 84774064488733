import { COLORS } from "@theme";
import Stakeholder from "@domain/model/Stakeholder";
import { Trans, useTranslation } from "react-i18next";
import { stakeholderTypesEnum } from "../hooks/useStakeholderSelection";
import { Button, Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";

interface IDetail {
  stakeholder: Stakeholder;
  newStakeholdersList: stakeholderTypesEnum[];
  onSave: (stakeholder: Stakeholder) => void;
  onClose: () => void;
  pendingRequest: boolean;
}

export const StakeholdersConfirmationChangesDialog = (props: IDetail) => {
  const { stakeholder, newStakeholdersList, onSave, onClose, pendingRequest } = props;
  const { t } = useTranslation();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up(350), { noSsr: true });

  return (
    <AggregoDialog
      key={"stakeholders-confirm-dialog"}
      title={t("stakeholders.confirm.dialog.title", { stakeholder: stakeholder.code })}
      onClose={() => {
        onClose();
      }}
      maxWidth="sm">
      <Grid container>
        {newStakeholdersList.length > 0 && (
          <Grid item xs={12} sx={{ mt: "1rem" }}>
            <Typography id="stakeholders-gain-types">{t("stakeholders.will.obtain")}</Typography>
            {newStakeholdersList.map((element, key) => {
              return (
                <Grid item key={key} sx={{ ml: "1rem", mt: "0.5rem" }}>
                  <li>
                    <Trans>{t(`${element}.loupe`)}</Trans>
                  </li>
                </Grid>
              );
            })}
          </Grid>
        )}
        <Grid container marginTop="2rem" flexGrow={1} justifyContent="space-between" direction={matches ? "row" : "column"}>
          <Button id="stkhldr-dialog-back-button" variant="secondary" onClick={() => onClose()}>
            {t("button.back")}
          </Button>
          <Button
            id="stkhldr-dialog-confirm-button"
            variant="primary"
            autoFocus
            sx={{
              fontSize: "0.8rem",
              lineHeight: 1.2,
              backgroundColor: pendingRequest ? COLORS.lightgray : ""
            }}
            onClick={() => {
              onSave(stakeholder);
            }}
            disabled={pendingRequest}>
            {t("button.confirm")}
          </Button>
        </Grid>
      </Grid>
    </AggregoDialog>
  );
};
