/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FamilyDTO } from './FamilyDTO';
import {
    FamilyDTOFromJSON,
    FamilyDTOFromJSONTyped,
    FamilyDTOToJSON,
} from './FamilyDTO';
import type { FamilyTypeDTO } from './FamilyTypeDTO';
import {
    FamilyTypeDTOFromJSON,
    FamilyTypeDTOFromJSONTyped,
    FamilyTypeDTOToJSON,
} from './FamilyTypeDTO';
import type { MaterialDTO } from './MaterialDTO';
import {
    MaterialDTOFromJSON,
    MaterialDTOFromJSONTyped,
    MaterialDTOToJSON,
} from './MaterialDTO';

/**
 * 
 * @export
 * @interface LocationDTO
 */
export interface LocationDTO {
    /**
     * 
     * @type {boolean}
     * @memberof LocationDTO
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LocationDTO
     */
    capacity?: number;
    /**
     * 
     * @type {string}
     * @memberof LocationDTO
     */
    code?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LocationDTO
     */
    containers?: Array<string>;
    /**
     * 
     * @type {Array<FamilyDTO>}
     * @memberof LocationDTO
     */
    families?: Array<FamilyDTO>;
    /**
     * 
     * @type {Array<FamilyTypeDTO>}
     * @memberof LocationDTO
     */
    familyTypes?: Array<FamilyTypeDTO>;
    /**
     * 
     * @type {string}
     * @memberof LocationDTO
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof LocationDTO
     */
    level?: number;
    /**
     * 
     * @type {Array<MaterialDTO>}
     * @memberof LocationDTO
     */
    materials?: Array<MaterialDTO>;
    /**
     * 
     * @type {number}
     * @memberof LocationDTO
     */
    occupation?: number;
    /**
     * 
     * @type {string}
     * @memberof LocationDTO
     */
    tileId?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDTO
     */
    warehouseId?: string;
}

/**
 * Check if a given object implements the LocationDTO interface.
 */
export function instanceOfLocationDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LocationDTOFromJSON(json: any): LocationDTO {
    return LocationDTOFromJSONTyped(json, false);
}

export function LocationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'capacity': !exists(json, 'capacity') ? undefined : json['capacity'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'containers': !exists(json, 'containers') ? undefined : json['containers'],
        'families': !exists(json, 'families') ? undefined : ((json['families'] as Array<any>).map(FamilyDTOFromJSON)),
        'familyTypes': !exists(json, 'familyTypes') ? undefined : ((json['familyTypes'] as Array<any>).map(FamilyTypeDTOFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'materials': !exists(json, 'materials') ? undefined : ((json['materials'] as Array<any>).map(MaterialDTOFromJSON)),
        'occupation': !exists(json, 'occupation') ? undefined : json['occupation'],
        'tileId': !exists(json, 'tileId') ? undefined : json['tileId'],
        'warehouseId': !exists(json, 'warehouseId') ? undefined : json['warehouseId'],
    };
}

export function LocationDTOToJSON(value?: LocationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'capacity': value.capacity,
        'code': value.code,
        'containers': value.containers,
        'families': value.families === undefined ? undefined : ((value.families as Array<any>).map(FamilyDTOToJSON)),
        'familyTypes': value.familyTypes === undefined ? undefined : ((value.familyTypes as Array<any>).map(FamilyTypeDTOToJSON)),
        'id': value.id,
        'level': value.level,
        'materials': value.materials === undefined ? undefined : ((value.materials as Array<any>).map(MaterialDTOToJSON)),
        'occupation': value.occupation,
        'tileId': value.tileId,
        'warehouseId': value.warehouseId,
    };
}

