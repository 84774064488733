import { t } from "i18next";
import { Grid } from "@mui/material";
import Operator from "@domain/model/Operator";
import { DomainPage } from "@movicoders/domain";
import { operatorColumns } from "../components/operator-columns";
import { useHandleCardQueries } from "@hooks/useHandleCardQueries";
import { GridSortModel, GridValidRowModel } from "@mui/x-data-grid";
import { OperatorsCardsDisplay } from "../components/OperatorsCardDisplay";
import { DataGridDisplay } from "@components/data-grid-display/data-grid-display";
import { ChangeDisplayButton } from "@components/data-grid-display/toolbar/components/ChangeDisplayButton";

interface IFamiliesTable {
  operatorData: DomainPage<Operator>;
  handleEdit: (id: string) => void;
  handleActiveConfirm: (operator: Operator) => void;
  handlePagination: (
    limit: number,
    offset: number,
    status?: "ACTIVE" | "INACTIVE" | "ALL" | undefined,
    filters?: object | undefined
  ) => void;
  sortByFieldBackend: (model: GridSortModel) => void;
  handleStatusFilter: () => boolean;
}

const OperatorsViewTable = (props: IFamiliesTable) => {
  const { operatorData, handleEdit, handleActiveConfirm, handlePagination, handleStatusFilter, sortByFieldBackend } = props;
  const { isCardDisplay, setIsCardDisplay, matchesMD } = useHandleCardQueries();

  return (
    <DataGridDisplay
      data={(operatorData?.content as GridValidRowModel[]) ?? []}
      columns={operatorColumns(t, handleEdit, handleActiveConfirm, matchesMD)}
      mode="server"
      viewName="operators"
      hideColumnsByField={
        handleStatusFilter() ? ["id", "actions", "firstSurname", "secondSurname"] : ["id", "firstSurname", "secondSurname"]
      }
      handleBackendPagination={handlePagination}
      backendTotalElements={operatorData?.totalElements ?? 0}
      handleBackendSort={sortByFieldBackend}
      customDisplayComponent={() =>
        isCardDisplay ? (
          <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <OperatorsCardsDisplay
              dataPaginated={(operatorData.content as Operator[]) ?? []}
              handleActive={handleActiveConfirm}
              handleEdit={handleEdit}
            />
          </Grid>
        ) : undefined
      }
      toolbarProps={{
        isActiveFilterHidden: handleStatusFilter(),
        disableColumnsVisibilityByField: ["id", "firstName", "firstSurname", "secondSurname"],
        disableFilterByFields: ["id", "actions", "status"],
        disableFieldsInDownload: ["id", "actions"],
        addHTMLRight: ChangeDisplayButton({ isCardDisplay: isCardDisplay, setIsCardDisplay: setIsCardDisplay })
      }}
      searchBarFilter={undefined}
    />
  );
};

export default OperatorsViewTable;
