import { COLORS } from "@theme";
import { SxProps, Theme } from "@mui/material";

const mobile_toolbar: SxProps<Theme> = {
  backgroundColor: COLORS.white,
  width: "100vw",
  zIndex: 4,
  position: "fixed",
  minHeight: "3.5rem",
  borderBottom: "1px solid " + COLORS.lightgray
};

const swipeable_drawer_paper: SxProps<Theme> = {
  paddingTop: "3.2rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  boxShadow: "none",
  borderRight: "1px solid " + COLORS.lightgray
};

export const toolbarStyles = { mobile_toolbar, swipeable_drawer_paper };
