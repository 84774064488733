import { OperatorDTO } from "@clients/aggrego-proxy";

export default class Operator {
  active: boolean;
  id: string;
  code?: string;
  firstName?: string;
  firstSurname?: string;
  secondSurname?: string;
  password?: string;

  constructor(
    active: boolean,
    id: string,
    code?: string,
    firstName?: string,
    firstSurname?: string,
    secondSurname?: string,
    password?: string
  ) {
    this.active = active;
    this.id = id;
    this.code = code;
    this.firstName = firstName;
    this.firstSurname = firstSurname;
    this.secondSurname = secondSurname;
    this.password = password;
  }

  static fromClientOperator(operator: OperatorDTO) {
    const result = new Operator(
      operator.active ?? true,
      operator.id ?? "",
      operator.code ?? "",
      operator.firstName ?? "",
      operator.firstSurname ?? "",
      operator.secondSurname ?? "",
      operator.password ?? ""
    );
    return result;
  }

  static toClientOperator(operator: Operator) {
    const result: OperatorDTO = {};
    result.active = operator.active;
    result.id = operator.id;
    result.code = operator.code;
    result.firstName = operator.firstName;
    result.firstSurname = operator.firstSurname;
    result.secondSurname = operator.secondSurname;
    result.password = operator.password;
    return result;
  }
}
