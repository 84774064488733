import {
  CompanyDTOLicenceEnum,
  IMMaterialConsumeDTO,
  StatusReportDTO,
  WMExplicitPartialConsumeRequest,
  WMGenericPartialConsumeRequest
} from "@clients/aggrego-proxy";
import { useState } from "react";
import { useResolve } from "@movicoders/di";
import { useSnackbar } from "@movicoders/ui";
import { useTranslation } from "react-i18next";
import { GridValidRowModel } from "@mui/x-data-grid";
import { IUserService, UserService } from "@domain/services/IUserService";
import { useHandleSelectedWarehouse } from "@hooks/useHandleSelectedWarehouse";
import { ILastPageService, LastPageService } from "@domain/services/ILastPageService";
import { ContainerRepository } from "@infrastructure/repositories/container-repository";

export const useMaterialConsumptionDialogViewModel = (
  search?: (limit: number, offset: number, filters?: object | undefined) => void
) => {
  const { show } = useSnackbar();
  const { t } = useTranslation();

  const containerRepository = useResolve<ContainerRepository>(ContainerRepository);
  const userService = useResolve<IUserService>(UserService);
  const lastPagePersistedState = useResolve<ILastPageService>(LastPageService);
  const currentLicense = userService.user?.companyLicense;

  const { selectedWarehouse, selectedWarehouseCode } = useHandleSelectedWarehouse();

  const [loadingDetail, setLoadingDetail] = useState(false);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [openFailedDialog, setOpenFailedDialog] = useState(false);
  const [selectedstatusReportList, setSelectedstatusReportList] = useState<StatusReportDTO[]>([]);

  let failedConsumptions: StatusReportDTO[] = [];
  const [failedstatusReportConsumptionList, setFailedstatusReportConsumptionList] = useState<StatusReportDTO[]>([]);

  const handleMultipleSelectionDialogOpen = () => {
    setOpenDetailDialog(!openDetailDialog);
  };

  const onCloseDetailDialog = () => {
    setOpenDetailDialog(false);
  };

  const onCloseFailedDialog = () => {
    setOpenFailedDialog(false);
  };

  const goToDetail = () => {
    search &&
      search(
        lastPagePersistedState.persistedState?.limit ?? 10,
        lastPagePersistedState.persistedState?.offset ?? 0,
        lastPagePersistedState.persistedState?.filters
      );
  };

  const handleStatusReportSelection = (selection: GridValidRowModel[]) => {
    setSelectedstatusReportList(selection as StatusReportDTO[]);
  };

  const deleteItemFromList = (reportToRemove: StatusReportDTO, index: number) => {
    const updatedReportList = selectedstatusReportList.filter(item => item !== reportToRemove);
    setSelectedstatusReportList(updatedReportList);
    if (updatedReportList.length === 0) {
      onCloseDetailDialog();
    }
  };

  const multipleTotalConsumptionByLicense = async () => {
    setLoadingDetail(true);
    failedConsumptions = [];
    setFailedstatusReportConsumptionList([]);
    try {
      if (currentLicense === CompanyDTOLicenceEnum.Im) {
        await multipleTotalConsumptionIM();
      } else if (currentLicense === CompanyDTOLicenceEnum.Wm) {
        await multipleTotalConsumptionWM();
      } else {
        await multipleTotalConsumptionWMC();
      }
      goToDetail();
    } finally {
      setLoadingDetail(false);
      onCloseDetailDialog();
      if (failedConsumptions.length === 0) {
        show(t("warehouse.status.all.consumption.success"), "success");
      } else {
        setFailedstatusReportConsumptionList(failedConsumptions);
        setOpenFailedDialog(true);
      }
    }
  };

  /* MULTIPLE CONSUMPTION */
  const multipleTotalConsumptionIM = async () => {
    const promises =
      selectedstatusReportList?.map(report => {
        const consume = {
          materialCode: report.material,
          qty: report.qty,
          warehouseCode: selectedWarehouseCode
        } as IMMaterialConsumeDTO;

        return containerRepository.consume({ iMMaterialConsumeDTO: consume }).catch(() => {
          failedConsumptions.push(report);
        });
      }) ?? [];

    await Promise.all(promises);
  };

  const multipleTotalConsumptionWM = async () => {
    const promises =
      selectedstatusReportList?.map(report => {
        const consume = {
          locationCode: report.location,
          materialCode: report.material,
          materialQty: report.qty,
          operatorId: "",
          warehouseId: selectedWarehouse
        } as WMGenericPartialConsumeRequest;

        return containerRepository.consumeWM({ wMGenericPartialConsumeRequest: consume }).catch(() => {
          failedConsumptions.push(report);
        });
      }) ?? [];

    await Promise.all(promises);
  };

  const multipleTotalConsumptionWMC = async () => {
    const promises =
      selectedstatusReportList?.map(report => {
        const consume = {
          containerCode: report.container,
          quantity: report.qty
        } as WMExplicitPartialConsumeRequest;

        return containerRepository.consumeWMC({ wMExplicitPartialConsumeRequest: consume }).catch(() => {
          failedConsumptions.push(report);
        });
      }) ?? [];

    await Promise.all(promises);
  };
  /* END MULTIPLE CONSUMPTION */

  return {
    loadingDetail,
    openDetailDialog,
    openFailedDialog,
    handleMultipleSelectionDialogOpen,
    onCloseDetailDialog,
    onCloseFailedDialog,
    selectedstatusReportList,
    setSelectedstatusReportList,
    failedstatusReportConsumptionList,
    multipleTotalConsumptionByLicense,
    handleStatusReportSelection,
    deleteItemFromList
  };
};
