import Tile from "@domain/model/Tile";
import Zone from "@domain/model/Zone";
import LocationCard from "./location-card";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import LocationEntity from "@domain/model/Location";
import { GridValidRowModel } from "@mui/x-data-grid";

interface ICardDisplay {
  gridData: GridValidRowModel[];
  handleEdit: (id: string) => void;
  handleActiveConfirm: (item: LocationEntity) => void;
  tiles: Tile[];
  zones: Zone[];
}

export const CardDisplay = (props: ICardDisplay) => {
  const { gridData, handleActiveConfirm, handleEdit, tiles, zones } = props;
  return (
    <Grid container id="container-location-cards" rowSpacing={1} mt="0.75rem" mb="0.5rem">
      {(gridData as LocationEntity[]).map((location: LocationEntity) => (
        <Grid item xs={12} key={location.id}>
          <LocationCard
            location={location}
            handleEdit={handleEdit}
            handleActive={handleActiveConfirm}
            tiles={tiles}
            zones={zones}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export const PDFCardDisplay = (props: ICardDisplay & { tableName: string }) => {
  const { tableName, gridData, handleActiveConfirm, handleEdit, tiles, zones } = props;
  const { t } = useTranslation();
  return (
    <>
      <Grid container justifyContent="space-between">
        <Typography variant="title">{t("table.download.pdf.title", { name: tableName })}</Typography>
        <Typography variant="title">{new Date().toLocaleString()}</Typography>
      </Grid>
      <CardDisplay
        gridData={gridData}
        handleActiveConfirm={handleActiveConfirm}
        handleEdit={handleEdit}
        tiles={tiles}
        zones={zones}
      />
    </>
  );
};
