import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import Operator from "@domain/model/Operator";
import { useTranslation } from "react-i18next";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";
import { ButtonsDetail } from "@components/master-data-view/buttons/buttons-detail";

export const OperatorsActivationDialog = (props: {
  open: boolean;
  onClose: () => void;
  onActivation: (operator: Operator) => void;
  operator: Operator;
}) => {
  const { t } = useTranslation();
  const { open, onClose, onActivation, operator } = props;

  const [pendingRequest, setPendingRequest] = useState<boolean>(false);
  useEffect(() => {
    setPendingRequest(false);
  }, [operator]);

  return open ? (
    <AggregoDialog
      title={t(operator.active ? "operators.deactivate.title" : "operators.activate.title", { operator: operator.code })}
      onClose={onClose}>
      <Grid container flexDirection="column" className="masterdata-box" rowSpacing={2}>
        <Grid item>
          <div className="confirm-dialog-div-content">
            {operator.active ? t("operators.form.deactivate") : t("operators.form.activate")}
          </div>
        </Grid>
        <Grid item>
          <ButtonsDetail
            handleClose={onClose}
            handleSave={() => {
              setPendingRequest(true);
              onActivation(operator);
            }}
            mode={operator.active ? "ACTIVE" : "INACTIVE"}
            pendingRequest={pendingRequest}
          />
        </Grid>
      </Grid>
    </AggregoDialog>
  ) : (
    <></>
  );
};
