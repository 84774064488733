/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  ListResponseDTO,
  MovicodersBadRequestException,
  MovicodersNotFoundException,
  MovicodersServiceException,
  TileTemplateDTO,
  TileTemplatePageDTO
} from "../models";
import {
  ListResponseDTOFromJSON,
  ListResponseDTOToJSON,
  MovicodersBadRequestExceptionFromJSON,
  MovicodersBadRequestExceptionToJSON,
  MovicodersNotFoundExceptionFromJSON,
  MovicodersNotFoundExceptionToJSON,
  MovicodersServiceExceptionFromJSON,
  MovicodersServiceExceptionToJSON,
  TileTemplateDTOFromJSON,
  TileTemplateDTOToJSON,
  TileTemplatePageDTOFromJSON,
  TileTemplatePageDTOToJSON
} from "../models";

export interface DeleteTileTemplateRequest {
  id: string;
  xTenantId?: string;
}

export interface DeleteTileTemplatesRequest {
  xTenantId?: string;
  requestBody?: Array<string>;
}

export interface GetTileTemplateByIdRequest {
  id: string;
  xTenantId?: string;
}

export interface GetTileTemplateByIdsRequest {
  xTenantId?: string;
  requestBody?: Array<string>;
}

export interface GetTileTemplatesByNameRequest {
  name: string;
  xTenantId?: string;
}

export interface ListTileTemplatesRequest {
  status: ListTileTemplatesStatusEnum;
  xTenantId?: string;
}

export interface ListTileTemplatesPaginatedRequest {
  status: ListTileTemplatesPaginatedStatusEnum;
  limit: number;
  offset: number;
  xTenantId?: string;
  sortField?: ListTileTemplatesPaginatedSortFieldEnum;
  sortDirection?: ListTileTemplatesPaginatedSortDirectionEnum;
}

export interface SaveTileTemplateRequest {
  xTenantId?: string;
  tileTemplateDTO?: TileTemplateDTO;
}

export interface SaveTileTemplatesRequest {
  xTenantId?: string;
  tileTemplateDTO?: Array<TileTemplateDTO>;
}

export interface UpdateTileTemplateRequest {
  xTenantId?: string;
  tileTemplateDTO?: TileTemplateDTO;
}

/**
 *
 */
export class TileTemplatesApi extends runtime.BaseAPI {
  /**
   * Takes an existing tiletemplate, deletes it, and returns the new object.
   * Delete an existing tiletemplate by id.
   */
  async deleteTileTemplateRaw(
    requestParameters: DeleteTileTemplateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TileTemplateDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteTileTemplate."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/tiletemplates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => TileTemplateDTOFromJSON(jsonValue));
  }

  /**
   * Takes an existing tiletemplate, deletes it, and returns the new object.
   * Delete an existing tiletemplate by id.
   */
  async deleteTileTemplate(
    requestParameters: DeleteTileTemplateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TileTemplateDTO> {
    const response = await this.deleteTileTemplateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the deleted TileTemplates
   */
  async deleteTileTemplatesRaw(
    requestParameters: DeleteTileTemplatesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<TileTemplateDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/tiletemplates/delete-many`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(TileTemplateDTOFromJSON));
  }

  /**
   * Returns the deleted TileTemplates
   */
  async deleteTileTemplates(
    requestParameters: DeleteTileTemplatesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<TileTemplateDTO>> {
    const response = await this.deleteTileTemplatesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns one tiletemplate object by id.
   * Get a tiletemplate object by id.
   */
  async getTileTemplateByIdRaw(
    requestParameters: GetTileTemplateByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TileTemplateDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getTileTemplateById."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/tiletemplates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => TileTemplateDTOFromJSON(jsonValue));
  }

  /**
   * Returns one tiletemplate object by id.
   * Get a tiletemplate object by id.
   */
  async getTileTemplateById(
    requestParameters: GetTileTemplateByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TileTemplateDTO> {
    const response = await this.getTileTemplateByIdRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns tiletemplate objects by ids.
   * Get a tiletemplate object by id.
   */
  async getTileTemplateByIdsRaw(
    requestParameters: GetTileTemplateByIdsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<TileTemplateDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/tiletemplates/find-many-by-id`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(TileTemplateDTOFromJSON));
  }

  /**
   * Returns tiletemplate objects by ids.
   * Get a tiletemplate object by id.
   */
  async getTileTemplateByIds(
    requestParameters: GetTileTemplateByIdsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<TileTemplateDTO>> {
    const response = await this.getTileTemplateByIdsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns tile template objects by name.
   * Get tile template objects by name.
   */
  async getTileTemplatesByNameRaw(
    requestParameters: GetTileTemplatesByNameRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<TileTemplateDTO>>> {
    if (requestParameters.name === null || requestParameters.name === undefined) {
      throw new runtime.RequiredError(
        "name",
        "Required parameter requestParameters.name was null or undefined when calling getTileTemplatesByName."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.name !== undefined) {
      queryParameters["name"] = requestParameters.name;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/tiletemplates/name`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(TileTemplateDTOFromJSON));
  }

  /**
   * Returns tile template objects by name.
   * Get tile template objects by name.
   */
  async getTileTemplatesByName(
    requestParameters: GetTileTemplatesByNameRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<TileTemplateDTO>> {
    const response = await this.getTileTemplatesByNameRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered TileTemplates
   */
  async listTileTemplatesRaw(
    requestParameters: ListTileTemplatesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<TileTemplateDTO>>> {
    if (requestParameters.status === null || requestParameters.status === undefined) {
      throw new runtime.RequiredError(
        "status",
        "Required parameter requestParameters.status was null or undefined when calling listTileTemplates."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/tiletemplates`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(TileTemplateDTOFromJSON));
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered TileTemplates
   */
  async listTileTemplates(
    requestParameters: ListTileTemplatesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<TileTemplateDTO>> {
    const response = await this.listTileTemplatesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered TileTemplates paginated
   */
  async listTileTemplatesPaginatedRaw(
    requestParameters: ListTileTemplatesPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TileTemplatePageDTO>> {
    if (requestParameters.status === null || requestParameters.status === undefined) {
      throw new runtime.RequiredError(
        "status",
        "Required parameter requestParameters.status was null or undefined when calling listTileTemplatesPaginated."
      );
    }

    if (requestParameters.limit === null || requestParameters.limit === undefined) {
      throw new runtime.RequiredError(
        "limit",
        "Required parameter requestParameters.limit was null or undefined when calling listTileTemplatesPaginated."
      );
    }

    if (requestParameters.offset === null || requestParameters.offset === undefined) {
      throw new runtime.RequiredError(
        "offset",
        "Required parameter requestParameters.offset was null or undefined when calling listTileTemplatesPaginated."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sortField"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sortDirection"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/tiletemplates/paginated`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => TileTemplatePageDTOFromJSON(jsonValue));
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered TileTemplates paginated
   */
  async listTileTemplatesPaginated(
    requestParameters: ListTileTemplatesPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TileTemplatePageDTO> {
    const response = await this.listTileTemplatesPaginatedRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the registered TileTemplate
   */
  async saveTileTemplateRaw(
    requestParameters: SaveTileTemplateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TileTemplateDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/tiletemplates`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: TileTemplateDTOToJSON(requestParameters.tileTemplateDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => TileTemplateDTOFromJSON(jsonValue));
  }

  /**
   * Returns the registered TileTemplate
   */
  async saveTileTemplate(
    requestParameters: SaveTileTemplateRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TileTemplateDTO> {
    const response = await this.saveTileTemplateRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the registered TileTemplates
   */
  async saveTileTemplatesRaw(
    requestParameters: SaveTileTemplatesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ListResponseDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/tiletemplates/add-many`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.tileTemplateDTO?.map(TileTemplateDTOToJSON)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ListResponseDTOFromJSON(jsonValue));
  }

  /**
   * Returns the registered TileTemplates
   */
  async saveTileTemplates(
    requestParameters: SaveTileTemplatesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ListResponseDTO> {
    const response = await this.saveTileTemplatesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Takes an existing TileTemplate object, updates it, and returns the TileTemplate.
   * Update an existing TileTemplate.
   */
  async updateTileTemplateRaw(
    requestParameters: UpdateTileTemplateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TileTemplateDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/tiletemplates`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: TileTemplateDTOToJSON(requestParameters.tileTemplateDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => TileTemplateDTOFromJSON(jsonValue));
  }

  /**
   * Takes an existing TileTemplate object, updates it, and returns the TileTemplate.
   * Update an existing TileTemplate.
   */
  async updateTileTemplate(
    requestParameters: UpdateTileTemplateRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TileTemplateDTO> {
    const response = await this.updateTileTemplateRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

/**
 * @export
 */
export const ListTileTemplatesStatusEnum = {
  Active: "ACTIVE",
  All: "ALL",
  Inactive: "INACTIVE"
} as const;
export type ListTileTemplatesStatusEnum = (typeof ListTileTemplatesStatusEnum)[keyof typeof ListTileTemplatesStatusEnum];
/**
 * @export
 */
export const ListTileTemplatesPaginatedStatusEnum = {
  Active: "ACTIVE",
  All: "ALL",
  Inactive: "INACTIVE"
} as const;
export type ListTileTemplatesPaginatedStatusEnum =
  (typeof ListTileTemplatesPaginatedStatusEnum)[keyof typeof ListTileTemplatesPaginatedStatusEnum];
/**
 * @export
 */
export const ListTileTemplatesPaginatedSortFieldEnum = {
  Description: "DESCRIPTION",
  Name: "NAME"
} as const;
export type ListTileTemplatesPaginatedSortFieldEnum =
  (typeof ListTileTemplatesPaginatedSortFieldEnum)[keyof typeof ListTileTemplatesPaginatedSortFieldEnum];
/**
 * @export
 */
export const ListTileTemplatesPaginatedSortDirectionEnum = {
  Asc: "ASC",
  Desc: "DESC"
} as const;
export type ListTileTemplatesPaginatedSortDirectionEnum =
  (typeof ListTileTemplatesPaginatedSortDirectionEnum)[keyof typeof ListTileTemplatesPaginatedSortDirectionEnum];
