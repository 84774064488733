import { COLORS } from "@theme";
import { SxProps, Theme } from "@mui/material";

export const hierarchyCreationStyles = () => {
  const title_container: SxProps<Theme> = {
    flexDirection: "column",
    flexWrap: "nowrap",
    marginBottom: "1rem"
  };

  const hierarchy_parent_product: SxProps<Theme> = {
    padding: "1rem",
    background: `linear-gradient(white 0 0) padding-box,${COLORS.secondary} border-box`,
    color: "#313149",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "6px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: 0
  };

  const hierarchy_children_container: SxProps<Theme> = {
    padding: "1rem",
    background: `linear-gradient(white 0 0) padding-box,${COLORS.secondary} border-box`,
    color: "#313149",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "6px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    margin: 0,
    marginBottom: "1rem"
  };

  const hierarchy_children_product: SxProps<Theme> = {
    padding: "1rem",
    background: `linear-gradient(white 0 0) padding-box, ${COLORS.secondaryHighlight} border-box`,
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "6px",
    width: "100%",
    cursor: "pointer",
    minHeight: "5rem"
  };

  const add_icon_button: SxProps<Theme> = {
    backgroundColor: COLORS.secondary,
    "&:hover": {
      backgroundColor: COLORS.secondary
    },
    width: 40,
    height: 40
  };

  return {
    title_container,
    hierarchy_parent_product,
    hierarchy_children_container,
    hierarchy_children_product,
    add_icon_button
  };
};
