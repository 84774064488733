import { Typography } from "@mui/material";
import Operator from "@domain/model/Operator";
import { useTranslation } from "react-i18next";
import { Card } from "@components/master-data-view/card/card";

export const OperatorCard = (props: {
  operator: Operator;
  handleEdit: (id: string) => void;
  handleActive: (item: Operator) => void;
}) => {
  const { t } = useTranslation();
  const { operator, handleEdit, handleActive } = props;

  const renderChild = (name: string, value: string) => {
    return (
      <div className="value-card-container" data-cy={`type-of-family-card-${name}-container`}>
        <div className="title-content">
          <Typography variant="cardlabel" textAlign="left">
            {t(name)}
          </Typography>
          <Typography variant="cardvalue" data-cy={`value`}>
            {value}
          </Typography>
        </div>
      </div>
    );
  };

  const rendererCard = (data: Operator) => {
    return (
      <Card
        isActive={data.active}
        key={data.id}
        popover={{
          handleEdit: () => handleEdit(data.id ?? ""),
          handleActive: () => handleActive(data),
          activateTitle: data.active ? t("root.deactivate") : t("root.activate")
        }}
        id={"id-card-" + data.id}
        nameValue={`${data.firstName} ${data.firstSurname} ${data.secondSurname}`}>
        {renderChild("operators.code", data?.code ?? "")}
      </Card>
    );
  };

  return rendererCard(operator);
};
