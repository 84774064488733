import { create } from "@movicoders/store";
import { IDialogsStatesStore, IReplaceOrKeepProps } from "./IDialogsStatesStore";
import { Tile3D } from "../../types/Tile3D";
import { Location3D } from "../../types/Location3D";

export const useDialogsStatesStore = create<IDialogsStatesStore>((set, get) => ({
  selectedBox: undefined,
  boxDialogOpen: false,
  deleteDialogOpen: false,
  tilesToDelete: [],
  locationsToDelete: [],
  addLocationDialogOpen: false,
  addLocationPositionProps: { level: 0, tileId: "" },
  replaceOrKeepDialogOpen: false,
  replaceOrKeepDialogProps: { newTiles: [], tilesToReplace: [] },
  getSelectedBox: () => get().selectedBox,
  getBoxDialogOpen: () => get().boxDialogOpen,
  getDeleteDialogOpen: () => get().deleteDialogOpen,
  getTilesToDelete: () => get().tilesToDelete,
  getLocationsToDelete: () => get().locationsToDelete,
  getAddLocationDialogOpen: () => get().addLocationDialogOpen,
  getAddLocationPositionProps: () => get().addLocationPositionProps,
  getReplaceOrKeepDialogOpen: () => get().replaceOrKeepDialogOpen,
  getReplaceOrKeepDialogProps: () => get().replaceOrKeepDialogProps,
  setSelectedBox: (newValue: Tile3D | undefined) => set((state) => ({ ...state, selectedBox: newValue })),
  setBoxDialogOpen: (newValue: boolean) => set((state) => ({ ...state, boxDialogOpen: newValue })),
  setDeleteDialogOpen: (newValue: boolean) => set((state) => ({ ...state, deleteDialogOpen: newValue })),
  setTilesToDelete: (newValue: Tile3D[]) => set((state) => ({ ...state, tilesToDelete: newValue })),
  setLocationsToDelete: (newValue: Location3D[]) => set((state) => ({ ...state, locationsToDelete: newValue })),
  setAddLocationDialogOpen: (newValue: boolean) => set((state) => ({ ...state, addLocationDialogOpen: newValue })),
  setReplaceOrKeepDialogOpen: (newValue: boolean) => set((state) => ({ ...state, replaceOrKeepDialogOpen: newValue })),
  handleOpenDeleteBoxDialog: (tilesToDelete, locationsToDelete) =>
    set((state) => ({ ...state, tilesToDelete, locationsToDelete, deleteDialogOpen: true })),
  handleOpenAddLocationDialog: (level, tileId) =>
    set((state) => ({ ...state, addLocationPositionProps: { level, tileId }, addLocationDialogOpen: true })),
  handleOpenReplaceOrKeepDialog: (newValue: IReplaceOrKeepProps) =>
    set((state) => ({ ...state, replaceOrKeepDialogProps: newValue, replaceOrKeepDialogOpen: true })),
})).build();
