import { Grid, Switch, Typography } from "@mui/material";
import { useMFASelectionViewModel } from "../hooks/useMFASelectionViewModel";
import { useTranslation } from "react-i18next";
import { LoadingSpinnerMasterView } from "@components/loading-circular-progress/loading-spinner-master-view";
import { profileViewStyles } from "../profile-view.Styles";

const MFASelection = () => {
  const { t } = useTranslation();
  const styles = profileViewStyles();

  const { loading, qrCode, currentUser, onChangeNFAEmailOption, onChangeNFAOPTOption } = useMFASelectionViewModel();

  return (
    <>
      <Grid container display="flex" spacing={{ xs: 1 }}>
        <Grid item id="email-mfa-selector-grid" display="flex" alignItems="center" justifyContent="space-between" xs={11}>
          <Typography sx={styles.item_typography}>{t("profile.email.switch.option")}: </Typography>
          <Switch
            color="secondary"
            disabled={loading}
            checked={currentUser?.enabledNFAEmail}
            onChange={onChangeNFAEmailOption}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Grid>
        <Grid item id="apk-mfa-selector-grid" display="flex" alignItems="center" justifyContent="space-between" xs={11}>
          <Typography sx={styles.item_typography}>{t("profile.opt.switch.option")}: </Typography>
          <Switch
            color="secondary"
            disabled={loading}
            checked={currentUser?.enabledNFAOPT}
            onChange={onChangeNFAOPTOption}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Grid>
        {qrCode && (
          <Grid item id="qr-description-parent-grid" display="flex">
            <Grid item id="qr-description-first-grid" display="flex" xs={3}>
              <img src={URL.createObjectURL(qrCode)} alt="qr-code"></img>
            </Grid>
            <Grid item id="qr-description-second-grid" display="flex" flexDirection="column" justifyContent="center" xs={6}>
              <Typography textAlign={"justify"}>{t("profile.mfa.qr.first.description")}</Typography>
              <Typography textAlign={"justify"} sx={{ mt: "1rem" }}>
                {t("profile.mfa.qr.second.description")}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      <LoadingSpinnerMasterView loading={loading} />
    </>
  );
};

export default MFASelection;
