import { Grid, Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useSelectionTypeButtonsViewmodel } from "./useSelectionTypeButtonsViewmodel";

const defaultButtonStyles = {
  color: "secondary" as const,
  sx: {
    width: "100%",
    height: "2.5rem"
  }
};

export const SelectionTypeButtons = () => {
  const { t } = useTranslation();

  const {
    getSelectedMode,
    getSelectionType,
    getCanUseZones,
    handleChangeSelectionType,
  } = useSelectionTypeButtonsViewmodel();

  return (
    <Grid container rowSpacing={0.5}>
      <Grid item xs={12}>
        <Button {...defaultButtonStyles}
          onClick={() => handleChangeSelectionType("SINGLE")}
          disabled={getSelectionType() === "SINGLE"}>
          {t("viewer.mode.single")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button {...defaultButtonStyles}
          onClick={() => handleChangeSelectionType("MULTIPLE")}
          disabled={getSelectionType() === "MULTIPLE"}>
          {t("viewer.mode.multiple")}
        </Button>
      </Grid>
      {getSelectedMode() !== "EDIT" && <Grid item xs={12}>
        <Button {...defaultButtonStyles}
          onClick={() => handleChangeSelectionType("BY_ZONE")}
          disabled={getSelectionType() === "BY_ZONE" || !getCanUseZones()}>
          {t("viewer.mode.zone")}
        </Button>
      </Grid>}
    </Grid>
  )
}