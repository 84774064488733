/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  CustomZoneFilter,
  Error0,
  ListResponseDTO,
  MovicodersBadRequestException,
  MovicodersNotFoundException,
  MovicodersServiceException,
  TileDTO,
  ZoneDTO,
  ZonePageDTO
} from "../models";
import {
  CustomZoneFilterFromJSON,
  CustomZoneFilterToJSON,
  Error0FromJSON,
  Error0ToJSON,
  ListResponseDTOFromJSON,
  ListResponseDTOToJSON,
  MovicodersBadRequestExceptionFromJSON,
  MovicodersBadRequestExceptionToJSON,
  MovicodersNotFoundExceptionFromJSON,
  MovicodersNotFoundExceptionToJSON,
  MovicodersServiceExceptionFromJSON,
  MovicodersServiceExceptionToJSON,
  TileDTOFromJSON,
  TileDTOToJSON,
  ZoneDTOFromJSON,
  ZoneDTOToJSON,
  ZonePageDTOFromJSON,
  ZonePageDTOToJSON
} from "../models";

export interface AddTilesToZoneRequest {
  id: string;
  xTenantId?: string;
  tileDTO?: Array<TileDTO>;
}

export interface DeleteZonesRequest {
  id: string;
  xTenantId?: string;
}

export interface DeleteZones1Request {
  xTenantId?: string;
  requestBody?: Array<string>;
}

export interface FindByNameRequest {
  name: string;
  xTenantId?: string;
}

export interface GetZonesByIdRequest {
  id: string;
  fullDTO: boolean;
  xTenantId?: string;
}

export interface GetZonesByIdsRequest {
  xTenantId?: string;
  requestBody?: Array<string>;
}

export interface GetZonesByWarehouseIDRequest {
  warehouseId: string;
  xTenantId?: string;
}

export interface ListZonesRequest {
  fullDTO: boolean;
  status: ListZonesStatusEnum;
  xTenantId?: string;
}

export interface ListZonesByTenantIdRequest {
  fullDTO: boolean;
  tenantId: string;
  status: ListZonesByTenantIdStatusEnum;
  xTenantId?: string;
}

export interface ListZonesPaginatedRequest {
  limit: number;
  offset: number;
  xTenantId?: string;
  sortField?: ListZonesPaginatedSortFieldEnum;
  sortDirection?: ListZonesPaginatedSortDirectionEnum;
  customZoneFilter?: CustomZoneFilter;
}

export interface SaveZoneRequest {
  xTenantId?: string;
  zoneDTO?: Array<ZoneDTO>;
}

export interface SaveZone1Request {
  xTenantId?: string;
  zoneDTO?: ZoneDTO;
}

export interface UpdateZonesRequest {
  xTenantId?: string;
  zoneDTO?: ZoneDTO;
}

/**
 *
 */
export class ZonesApi extends runtime.BaseAPI {
  /**
   * Save the tiles inside zone
   */
  async addTilesToZoneRaw(
    requestParameters: AddTilesToZoneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ListResponseDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling addTilesToZone."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/zones/{id}/tiles`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.tileDTO?.map(TileDTOToJSON)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ListResponseDTOFromJSON(jsonValue));
  }

  /**
   * Save the tiles inside zone
   */
  async addTilesToZone(
    requestParameters: AddTilesToZoneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ListResponseDTO> {
    const response = await this.addTilesToZoneRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Takes an existing zones, deletes it, and returns the new object.
   * Delete an existing zones by id.
   */
  async deleteZonesRaw(
    requestParameters: DeleteZonesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ZoneDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteZones."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/zones/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ZoneDTOFromJSON(jsonValue));
  }

  /**
   * Takes an existing zones, deletes it, and returns the new object.
   * Delete an existing zones by id.
   */
  async deleteZones(
    requestParameters: DeleteZonesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ZoneDTO> {
    const response = await this.deleteZonesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the deleted Zones
   */
  async deleteZones1Raw(
    requestParameters: DeleteZones1Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<ZoneDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/zones/delete-many`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(ZoneDTOFromJSON));
  }

  /**
   * Returns the deleted Zones
   */
  async deleteZones1(
    requestParameters: DeleteZones1Request = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<ZoneDTO>> {
    const response = await this.deleteZones1Raw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Return a zone by name.
   * Returns a zone by the provided name.
   */
  async findByNameRaw(
    requestParameters: FindByNameRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<ZoneDTO>>> {
    if (requestParameters.name === null || requestParameters.name === undefined) {
      throw new runtime.RequiredError(
        "name",
        "Required parameter requestParameters.name was null or undefined when calling findByName."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.name !== undefined) {
      queryParameters["name"] = requestParameters.name;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/zones/name`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(ZoneDTOFromJSON));
  }

  /**
   * Return a zone by name.
   * Returns a zone by the provided name.
   */
  async findByName(
    requestParameters: FindByNameRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<ZoneDTO>> {
    const response = await this.findByNameRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns one zones object by id.
   * Get a zones object by id.
   */
  async getZonesByIdRaw(
    requestParameters: GetZonesByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ZoneDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getZonesById."
      );
    }

    if (requestParameters.fullDTO === null || requestParameters.fullDTO === undefined) {
      throw new runtime.RequiredError(
        "fullDTO",
        "Required parameter requestParameters.fullDTO was null or undefined when calling getZonesById."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fullDTO !== undefined) {
      queryParameters["fullDTO"] = requestParameters.fullDTO;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/zones/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ZoneDTOFromJSON(jsonValue));
  }

  /**
   * Returns one zones object by id.
   * Get a zones object by id.
   */
  async getZonesById(
    requestParameters: GetZonesByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ZoneDTO> {
    const response = await this.getZonesByIdRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns zones objects by ids.
   * Get a zones object by id.
   */
  async getZonesByIdsRaw(
    requestParameters: GetZonesByIdsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<ZoneDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/zones/find-many-by-id`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(ZoneDTOFromJSON));
  }

  /**
   * Returns zones objects by ids.
   * Get a zones object by id.
   */
  async getZonesByIds(
    requestParameters: GetZonesByIdsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<ZoneDTO>> {
    const response = await this.getZonesByIdsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Return an existing Zones object by Warehouse id
   * Get Zone by Warehouse id.
   */
  async getZonesByWarehouseIDRaw(
    requestParameters: GetZonesByWarehouseIDRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<ZoneDTO>>> {
    if (requestParameters.warehouseId === null || requestParameters.warehouseId === undefined) {
      throw new runtime.RequiredError(
        "warehouseId",
        "Required parameter requestParameters.warehouseId was null or undefined when calling getZonesByWarehouseID."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/zones/warehouses/{warehouseId}`.replace(
          `{${"warehouseId"}}`,
          encodeURIComponent(String(requestParameters.warehouseId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(ZoneDTOFromJSON));
  }

  /**
   * Return an existing Zones object by Warehouse id
   * Get Zone by Warehouse id.
   */
  async getZonesByWarehouseID(
    requestParameters: GetZonesByWarehouseIDRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<ZoneDTO>> {
    const response = await this.getZonesByWarehouseIDRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered Zones
   */
  async listZonesRaw(
    requestParameters: ListZonesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<ZoneDTO>>> {
    if (requestParameters.fullDTO === null || requestParameters.fullDTO === undefined) {
      throw new runtime.RequiredError(
        "fullDTO",
        "Required parameter requestParameters.fullDTO was null or undefined when calling listZones."
      );
    }

    if (requestParameters.status === null || requestParameters.status === undefined) {
      throw new runtime.RequiredError(
        "status",
        "Required parameter requestParameters.status was null or undefined when calling listZones."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fullDTO !== undefined) {
      queryParameters["fullDTO"] = requestParameters.fullDTO;
    }

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/zones`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(ZoneDTOFromJSON));
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered Zones
   */
  async listZones(
    requestParameters: ListZonesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<ZoneDTO>> {
    const response = await this.listZonesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Return Zones by tenantId.
   * Returns the list of all registered Zones by tenantId
   */
  async listZonesByTenantIdRaw(
    requestParameters: ListZonesByTenantIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<ZoneDTO>>> {
    if (requestParameters.fullDTO === null || requestParameters.fullDTO === undefined) {
      throw new runtime.RequiredError(
        "fullDTO",
        "Required parameter requestParameters.fullDTO was null or undefined when calling listZonesByTenantId."
      );
    }

    if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
      throw new runtime.RequiredError(
        "tenantId",
        "Required parameter requestParameters.tenantId was null or undefined when calling listZonesByTenantId."
      );
    }

    if (requestParameters.status === null || requestParameters.status === undefined) {
      throw new runtime.RequiredError(
        "status",
        "Required parameter requestParameters.status was null or undefined when calling listZonesByTenantId."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fullDTO !== undefined) {
      queryParameters["fullDTO"] = requestParameters.fullDTO;
    }

    if (requestParameters.tenantId !== undefined) {
      queryParameters["tenantId"] = requestParameters.tenantId;
    }

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/zones/findByTenantId`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(ZoneDTOFromJSON));
  }

  /**
   * Return Zones by tenantId.
   * Returns the list of all registered Zones by tenantId
   */
  async listZonesByTenantId(
    requestParameters: ListZonesByTenantIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<ZoneDTO>> {
    const response = await this.listZonesByTenantIdRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered Zones paginated
   */
  async listZonesPaginatedRaw(
    requestParameters: ListZonesPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ZonePageDTO>> {
    if (requestParameters.limit === null || requestParameters.limit === undefined) {
      throw new runtime.RequiredError(
        "limit",
        "Required parameter requestParameters.limit was null or undefined when calling listZonesPaginated."
      );
    }

    if (requestParameters.offset === null || requestParameters.offset === undefined) {
      throw new runtime.RequiredError(
        "offset",
        "Required parameter requestParameters.offset was null or undefined when calling listZonesPaginated."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sortField"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sortDirection"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/zones/paginated`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CustomZoneFilterToJSON(requestParameters.customZoneFilter)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ZonePageDTOFromJSON(jsonValue));
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered Zones paginated
   */
  async listZonesPaginated(
    requestParameters: ListZonesPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ZonePageDTO> {
    const response = await this.listZonesPaginatedRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the registered zone
   */
  async saveZoneRaw(
    requestParameters: SaveZoneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ListResponseDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/zones/add-many`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.zoneDTO?.map(ZoneDTOToJSON)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ListResponseDTOFromJSON(jsonValue));
  }

  /**
   * Returns the registered zone
   */
  async saveZone(
    requestParameters: SaveZoneRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ListResponseDTO> {
    const response = await this.saveZoneRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Save the zone and returns it
   */
  async saveZone1Raw(
    requestParameters: SaveZone1Request,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ZoneDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/zones`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ZoneDTOToJSON(requestParameters.zoneDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ZoneDTOFromJSON(jsonValue));
  }

  /**
   * Save the zone and returns it
   */
  async saveZone1(
    requestParameters: SaveZone1Request = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ZoneDTO> {
    const response = await this.saveZone1Raw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Takes an existing Zones object, updates it, and returns the Zones.
   * Update an existing Zones.
   */
  async updateZonesRaw(
    requestParameters: UpdateZonesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ZoneDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/zones`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: ZoneDTOToJSON(requestParameters.zoneDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ZoneDTOFromJSON(jsonValue));
  }

  /**
   * Takes an existing Zones object, updates it, and returns the Zones.
   * Update an existing Zones.
   */
  async updateZones(
    requestParameters: UpdateZonesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ZoneDTO> {
    const response = await this.updateZonesRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

/**
 * @export
 */
export const ListZonesStatusEnum = {
  Active: "ACTIVE",
  All: "ALL",
  Inactive: "INACTIVE"
} as const;
export type ListZonesStatusEnum = (typeof ListZonesStatusEnum)[keyof typeof ListZonesStatusEnum];
/**
 * @export
 */
export const ListZonesByTenantIdStatusEnum = {
  Active: "ACTIVE",
  All: "ALL",
  Inactive: "INACTIVE"
} as const;
export type ListZonesByTenantIdStatusEnum = (typeof ListZonesByTenantIdStatusEnum)[keyof typeof ListZonesByTenantIdStatusEnum];
/**
 * @export
 */
export const ListZonesPaginatedSortFieldEnum = {
  Color: "COLOR",
  Name: "NAME",
  Status: "STATUS",
  Tiles: "TILES",
  Warehouseid: "WAREHOUSEID"
} as const;
export type ListZonesPaginatedSortFieldEnum =
  (typeof ListZonesPaginatedSortFieldEnum)[keyof typeof ListZonesPaginatedSortFieldEnum];
/**
 * @export
 */
export const ListZonesPaginatedSortDirectionEnum = {
  Asc: "ASC",
  Desc: "DESC"
} as const;
export type ListZonesPaginatedSortDirectionEnum =
  (typeof ListZonesPaginatedSortDirectionEnum)[keyof typeof ListZonesPaginatedSortDirectionEnum];
