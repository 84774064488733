import { COLORS } from "@theme";
import { SxProps, Theme } from "@mui/material";

export const circularProgressStyles = () => {
  const warehouses_outer_circle: SxProps<Theme> = {
    position: "absolute",
    circle: {
      strokeLinecap: "round",
      strokeWidth: 2.5,
      r: "1.26rem"
    }
  };

  const warehouse_inner_circle: SxProps<Theme> = {
    color: COLORS.lightgray,
    circle: {
      strokeLinecap: "round",
      strokeWidth: 1.5,
      r: "1.26rem"
    }
  };

  const warehouses_volume_container: SxProps<Theme> = {
    position: "absolute",
    alignItems: "center",
    display: "flex",
    flexDirection: "column"
  };

  const warehouse_card_volume: SxProps<Theme> = {
    marginLeft: "10px",
    fontSize: "2rem",
    fontWeight: 600,
    color: COLORS.darkgray
  };

  const warehouse_card_volume_text: SxProps<Theme> = {
    width: "60%",
    fontSize: "0.55rem",
    textAlign: "center"
  };

  const warehouse_progress_container: SxProps<Theme> = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "fit-content"
  };

  return {
    warehouses_outer_circle,
    warehouse_inner_circle,
    warehouses_volume_container,
    warehouse_card_volume,
    warehouse_card_volume_text,
    warehouse_progress_container
  };
};
