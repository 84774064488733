import Tile from "@domain/model/Tile";
import { CREATE_ID } from "@constants";
import LocationEntity from "@domain/model/Location";
import { IDrawerService } from "@domain/services/IDrawerService";
import { TileRepository } from "@infrastructure/repositories/tile-repository";
import { LocationRepository } from "@infrastructure/repositories/location-repository";
import { TileTemplateRepository } from "@infrastructure/repositories/tile-template-repository";

export class LocationsCreateUseCase {
  constructor(
    private readonly locationRepository: LocationRepository,
    private readonly tileRepository: TileRepository,
    private readonly tileTemplateRepository: TileTemplateRepository,
    private readonly drawerService: IDrawerService
  ) {}

  public async execute(locationToSave: LocationEntity, tileToSave: Tile) {
    const tilesData = await this.tileRepository.get();
    const foundExistingTile = tilesData.find(
      t =>
        t.x === Number(tileToSave.x) &&
        t.z === Number(tileToSave.z) &&
        t.warehouseId === this.drawerService.getPersistedState()?.selectedWarehouse
    );

    const editing = locationToSave.id !== CREATE_ID;
    locationToSave.materials = [];
    locationToSave.families = [];
    locationToSave.familyTypes = [];

    if (foundExistingTile) {
      locationToSave.tileId = foundExistingTile.id;
      if (editing) {
        await this.tileRepository.update(tileToSave);
        return await this.locationRepository.update(locationToSave);
      } else {
        locationToSave.id = undefined;
        return await this.locationRepository.create(locationToSave);
      }
    } else {
      const templates = await this.tileTemplateRepository.get();

      const selectedWarehouse = this.drawerService.persistedState?.selectedWarehouse;
      tileToSave.id = undefined;
      tileToSave.active = true;
      tileToSave.locations = [];
      // TODO: Get tile code from preferences.
      tileToSave.code = `${selectedWarehouse}.${locationToSave.code}.${tileToSave.x}.${tileToSave.z}`;
      tileToSave.templateId = templates.find(tt => tt.editable && !tt.walkable)?.id ?? templates[0].id;
      tileToSave.warehouseId = selectedWarehouse;

      return await this.tileRepository.create(tileToSave).then(createdTile => {
        locationToSave.tileId = createdTile.id;
        locationToSave.warehouseId = this.drawerService.getPersistedState()?.selectedWarehouse;
        if (editing) {
          return this.locationRepository.update(locationToSave);
        } else {
          locationToSave.id = undefined;
          return this.locationRepository.create(locationToSave);
        }
      });
    }
  }
}
