import {
  ListResponseDTO,
  ListStakeholdersPaginatedRequest,
  ListStakeholdersRequest,
  SaveStakeholdersRequest
} from "@clients/aggrego-proxy";
import Stakeholder from "@domain/model/Stakeholder";
import { StakeholderApi } from "../api/stakeholder-api";
import { DomainPage, IRepositoryPaginated } from "@movicoders/domain";

export class StakeholderRepository implements IRepositoryPaginated<Stakeholder, ListStakeholdersPaginatedRequest> {
  constructor(private readonly stakeholderApi: StakeholderApi) {}

  get(criteria: ListStakeholdersRequest): Promise<Stakeholder[]> {
    return this.stakeholderApi.get(criteria);
  }

  getPaginated(pageCriteria: ListStakeholdersPaginatedRequest): Promise<DomainPage<Stakeholder>> {
    return this.stakeholderApi.getPaginated(pageCriteria);
  }

  getById(id: string): Promise<Stakeholder> {
    return this.stakeholderApi.getById(id);
  }

  getManyById(idList: string[]): Promise<Stakeholder[]> {
    return this.stakeholderApi.getManyById(idList);
  }

  create(criteria: Stakeholder): Promise<Stakeholder> {
    return this.stakeholderApi.add(criteria);
  }

  addMany(criteria: SaveStakeholdersRequest): Promise<ListResponseDTO> {
    return this.stakeholderApi.addMany(criteria);
  }

  update(criteria: Stakeholder): Promise<Stakeholder> {
    return this.stakeholderApi.update(criteria);
  }

  remove(id: string): Promise<void> {
    return this.stakeholderApi.delete(id);
  }
}
