import { CREATE_ID } from "@constants";
import { useState, useEffect } from "react";
import Stakeholder from "@domain/model/Stakeholder";
import { isArrayEquals } from "@utils/array-helper";
import { stakeholderTypesEnum } from "./useStakeholderSelection";

export const useStakeholderTypesHandler = (modifiedStakeholder: Stakeholder, validateFormValues: () => boolean | undefined) => {
  const [confirmationDialogVisibility, setConfirmationDialogVisibility] = useState<boolean>(true);
  const [prevStakeholdersList, setPrevStakeholdersList] = useState<stakeholderTypesEnum[]>([]);
  const [newStakeholdersList, setNewStakeholdersList] = useState<stakeholderTypesEnum[]>(prevStakeholdersList);

  const getAgents = () => {
    const agents: stakeholderTypesEnum[] = [];
    if (modifiedStakeholder.isCustomer) agents.push(stakeholderTypesEnum.customer);
    if (modifiedStakeholder.isManufacturer) agents.push(stakeholderTypesEnum.manuafacturers);
    if (modifiedStakeholder.isOwner) agents.push(stakeholderTypesEnum.owners);
    if (modifiedStakeholder.isSupplier) agents.push(stakeholderTypesEnum.suppliers);
    return agents;
  };

  useEffect(() => {
    setConfirmationDialogVisibility(true);
    setPrevStakeholdersList(getAgents());
  }, [modifiedStakeholder.code]);

  useEffect(() => {
    setNewStakeholdersList(prevStakeholdersList);
  }, [prevStakeholdersList]);

  const newStakeholdersListHandler = (type: stakeholderTypesEnum) => {
    if (type === stakeholderTypesEnum.customer) {
      if (newStakeholdersList.find((element: stakeholderTypesEnum) => element === stakeholderTypesEnum.customer)) {
        setNewStakeholdersList(
          newStakeholdersList.filter((element: stakeholderTypesEnum) => element !== stakeholderTypesEnum.customer)
        );
      } else {
        setNewStakeholdersList((prevList: stakeholderTypesEnum[]) => [...prevList, stakeholderTypesEnum.customer]);
      }
    }
    if (type === stakeholderTypesEnum.manuafacturers) {
      if (newStakeholdersList.find((element: stakeholderTypesEnum) => element === stakeholderTypesEnum.manuafacturers)) {
        setNewStakeholdersList(
          newStakeholdersList.filter((element: stakeholderTypesEnum) => element !== stakeholderTypesEnum.manuafacturers)
        );
      } else {
        setNewStakeholdersList((prevList: stakeholderTypesEnum[]) => [...prevList, stakeholderTypesEnum.manuafacturers]);
      }
    }
    if (type === stakeholderTypesEnum.owners) {
      if (newStakeholdersList.find((element: stakeholderTypesEnum) => element === stakeholderTypesEnum.owners)) {
        setNewStakeholdersList(
          newStakeholdersList.filter((element: stakeholderTypesEnum) => element !== stakeholderTypesEnum.owners)
        );
      } else {
        setNewStakeholdersList((prevList: stakeholderTypesEnum[]) => [...prevList, stakeholderTypesEnum.owners]);
      }
    }
    if (type === stakeholderTypesEnum.suppliers) {
      if (newStakeholdersList.find((element: stakeholderTypesEnum) => element === stakeholderTypesEnum.suppliers)) {
        setNewStakeholdersList(
          newStakeholdersList.filter((element: stakeholderTypesEnum) => element !== stakeholderTypesEnum.suppliers)
        );
      } else {
        setNewStakeholdersList((prevList: stakeholderTypesEnum[]) => [...prevList, stakeholderTypesEnum.suppliers]);
      }
    }
  };

  const handleAgentsEquality = (forceEquality?: boolean) => {
    if (validateFormValues()) {
      const isCreating = modifiedStakeholder.id === CREATE_ID;
      const isEquality =
        forceEquality ??
        isArrayEquals(
          newStakeholdersList.sort((a, b) => a.localeCompare(b)),
          prevStakeholdersList.sort((a, b) => a.localeCompare(b))
        );
      setConfirmationDialogVisibility(isCreating ? false : isEquality);
      return isCreating ? true : isEquality;
    }
    return false;
  };

  return {
    confirmationDialogVisibility,
    handleAgentsEquality,
    newStakeholdersList,
    newStakeholdersListHandler
  };
};
