import { CREATE_ID } from "@constants";
import { useSnackbar } from "@movicoders/ui";
import { useTranslation } from "react-i18next";
import { CompanyDTO, FullCompanyItemDTO } from "@clients/aggrego-proxy";

export const useAdminMessagges = () => {
  const { t } = useTranslation();
  const { show } = useSnackbar();

  const successMessaggesStatus = (company: CompanyDTO) => {
    company.active
      ? show(
          t(`company.deactivated.success`, {
            company: company.name ?? ""
          }),
          "success"
        )
      : show(
          t(`company.activated.success`, {
            company: company.name ?? ""
          }),
          "success"
        );
  };

  const errorMessaggesStatus = (company: CompanyDTO, error: string) => {
    const errorText = company.name ? "" : ".undefined";
    company.active
      ? show(
          t(`deactivating.${error}${errorText}`, {
            value: company.name ? `"${company.name}"` : "",
            mdNameA: t("a.company"),
            mdNameThe: t("the.company"),
            reason: t("not.found.company"),
            mdNameNew: t("a.new.company")
          }),
          "error"
        )
      : show(
          t(`activating.${error}${errorText}`, {
            value: company.name ? `"${company.name}"` : "",
            mdNameA: t("a.company"),
            mdNameThe: t("the.company"),
            reason: t("not.found.company"),
            mdNameNew: t("a.new.company")
          }),
          "error"
        );
  };

  const successMessaggesSaving = (company: FullCompanyItemDTO) => {
    (company as CompanyDTO).id === CREATE_ID
      ? show(
          t(`company.created.success`, {
            company: company.companyName ?? ""
          }),
          "success"
        )
      : show(
          t(`company.saved.success`, {
            company: company.companyName ?? ""
          }),
          "success"
        );
  };

  const errorMessaggesSaving = (company: FullCompanyItemDTO, error: string) => {
    const errorText = company.companyName ? "" : ".undefined";
    (company as CompanyDTO).id === CREATE_ID
      ? show(
          t(`creating.${error}${errorText}`, {
            value: company.companyName ?? "",
            mdNameA: t("a.company"),
            mdNameThe: t("the.company"),
            reason: t("not.found.company"),
            mdNameNew: t("a.new.company")
          }),
          "error"
        )
      : show(
          t(`saving.${error}${errorText}`, {
            value: company.companyName ?? "",
            mdNameA: t("a.company"),
            mdNameThe: t("the.company"),
            reason: t("not.found.company")
          }),
          "error"
        );
  };

  return {
    successMessaggesStatus,
    successMessaggesSaving,
    errorMessaggesStatus,
    errorMessaggesSaving
  };
};
