import { useState } from "react";
import IMMEntryWithDesc from "@domain/model/IMMEntryWithDesc";

export const useInventoryMaterialValidations = (createdIMMaterial: IMMEntryWithDesc, selectedEntry: IMMEntryWithDesc) => {
  const EXTRA_LENGTH = 1000;
  const [formErrors, setFormErrors] = useState<Map<string, boolean>>(
    new Map([
      ["material-code", false],
      ["material-qty", false]
    ])
  );
  const [dialogErrors, setDialogErrors] = useState<Map<string, boolean>>(
    new Map([
      ["material-code", false],
      ["material-qty", false]
    ])
  );

  const validateFormValues = () => {
    const materialValid =
      createdIMMaterial.materialCode !== null &&
      createdIMMaterial.materialCode !== undefined &&
      createdIMMaterial.materialCode !== "";

    const qtyValid = createdIMMaterial.qty !== null && createdIMMaterial.qty !== undefined && createdIMMaterial.qty >= 0;

    setFormErrors(
      new Map([
        ["material-code", !materialValid],
        ["material-qty", !qtyValid]
      ])
    );
    return materialValid && qtyValid;
  };

  const validateDialogValues = () => {
    const dialogCommentValid = selectedEntry.comment !== undefined && selectedEntry.comment.length < EXTRA_LENGTH;

    const dialogQtyValid =
      selectedEntry.entriesQty !== null && selectedEntry.entriesQty !== undefined && selectedEntry.entriesQty >= 0;

    setDialogErrors(
      new Map([
        ["logistic.comment", !dialogCommentValid],
        ["logistic.physical.stock", !dialogQtyValid]
      ])
    );
    return dialogCommentValid && dialogQtyValid;
  };

  return {
    validateFormValues,
    formErrors,
    validateDialogValues,
    dialogErrors
  };
};
