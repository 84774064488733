import { DomainPage } from "@movicoders/domain";
import TileTemplate from "@domain/model/TileTemplate";
import { IImpersonationService } from "@domain/services/IImpersonationService";
import { ListTileTemplatesPaginatedRequest, TileTemplatesApi } from "@clients/aggrego-proxy/apis/TileTemplatesApi";

export class TileTemplateApi {
  constructor(
    private readonly tileTemplatesApi: TileTemplatesApi,
    private readonly impersonationService: IImpersonationService
  ) {}

  async get(): Promise<TileTemplate[]> {
    const result: TileTemplate[] = [];

    const apiResult = await this.tileTemplatesApi.listTileTemplates({
      status: "ALL",
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
    apiResult.forEach(tileTemplateFromBE => {
      result.push(TileTemplate.fromClientTileTemplate(tileTemplateFromBE));
    });
    return result;
  }

  async getPaginated(pageCriteria: ListTileTemplatesPaginatedRequest): Promise<DomainPage<TileTemplate>> {
    return (await this.tileTemplatesApi.listTileTemplatesPaginated({
      ...pageCriteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    })) as unknown as Promise<DomainPage<TileTemplate>>;
  }

  async getById(id: string): Promise<TileTemplate> {
    return TileTemplate.fromClientTileTemplate(
      await this.tileTemplatesApi.getTileTemplateById({
        id: id,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
  }

  async create(entity: TileTemplate): Promise<TileTemplate> {
    return TileTemplate.fromClientTileTemplate(
      await this.tileTemplatesApi.saveTileTemplate({
        tileTemplateDTO: entity,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
  }

  async update(entity: TileTemplate): Promise<TileTemplate> {
    return TileTemplate.fromClientTileTemplate(
      await this.tileTemplatesApi.updateTileTemplate({
        tileTemplateDTO: entity,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
  }

  async remove(id: string): Promise<void> {
    await this.tileTemplatesApi.deleteTileTemplate({
      id: id,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }
}
