import { ROUTER_PATHS } from "@constants";
import { User } from "@domain/model/User";
import { useResolve } from "@movicoders/di";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Company from "@domain/model/CompanyAdminBoard";
import { PortalTypes } from "@domain/model/PortalTypes";
import { useListViewModel, useSnackbar } from "@movicoders/ui";
import { SyntheticEvent, useRef, useState } from "react";
import { IUserService, UserService } from "@domain/services/IUserService";
import { CompanyRepository } from "@infrastructure/repositories/company-repository";
import { IImpersonationService, ImpersonationService } from "@domain/services/IImpersonationService";
import { WarehouseByTenantUseCase } from "@application/warehouse-by-tenant/warehouse-by-tenant-use-case";

export const useImpersonationViewModel = () => {
  const {
    loading: loadingCompanies,
    data: allCompanies,
    dataPaginated: companiesPaginated,
    fetch: fetchCompanies
  } = useListViewModel(CompanyRepository);
  const companiesFetch = useRef(fetchCompanies).current;

  const currentUser = useResolve<IUserService>(UserService);

  const { show } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    saveImpersonationState,
    clearImpersonationState,
    persistedState: impersonationState
  } = useResolve<IImpersonationService>(ImpersonationService);

  const warehousesByTenantUseCase = useResolve(WarehouseByTenantUseCase);

  const [currentCompany, setCurrentCompany] = useState<Company | null>(null);

  const onChangeAutocomplete = (e: SyntheticEvent<Element, Event>, value: Company | null) => {
    setCurrentCompany(value);
    saveImpersonationState({
      impersonatedTenant: value?.tenant,
      impersonationName: value?.companyName,
      isImpersonated: false,
      portal: PortalTypes.AGGREGO
    });
    currentUser.save(
      new User({
        ...currentUser.user,
        companyLicense: value?.licence + "",
        warehousePolicy: value?.warehousePolicy
      })
    );

    if (value) {
      show(t("impersonation.login.message", { tenantName: value.companyName }), "success");
    }
    //if a superadmin has not wh-status permission, check here where to navigate
    //currentSettings.persistedState?.reportsPermissions?.includes(ReportEnum.WarehousesStatus) ? navigate(ROUTER_PATHS.whstatus) : navigate(ROUTER_PATHS.home)
    navigate(ROUTER_PATHS.whstatus);
  };

  const resetToDefaultImpersonationState = () => {
    saveImpersonationState({
      impersonatedTenant: null,
      impersonationName: "",
      isImpersonated: false,
      portal: PortalTypes.AGGREGO
    });
    //Cleans warehouse id from url
    navigate(ROUTER_PATHS.home);
  };

  const becomeImpersonated = () => {
    saveImpersonationState({
      ...impersonationState,
      isImpersonated: true
    });
  };

  const onChangePortal = (portal: PortalTypes) => {
    saveImpersonationState({
      ...impersonationState,
      portal: portal
    });
  };

  const clearImpersonation = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();
    setCurrentCompany(null);
    clearImpersonationState();
    resetToDefaultImpersonationState();
    //cleans warehouses from drawer persisted state
    warehousesByTenantUseCase.clear();
  };

  const onClickAutocomplete = () => {
    allCompanies.length === 0 && companiesFetch({});
  };

  return {
    allCompanies,
    companiesPaginated,
    currentCompany,
    impersonationState,
    loadingCompanies,
    onChangeAutocomplete,
    onClickAutocomplete,
    onChangePortal,
    clearImpersonation,
    becomeImpersonated
  };
};
