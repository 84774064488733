import { useState } from "react";
import Stakeholder from "@domain/model/Stakeholder";
import { LastPageState } from "@domain/model/LastPageState";
import { stakeholderTypesEnum } from "../useStakeholderSelection";
import { useMasterDataMessagges } from "@hooks/useMasterDataMessages";
import { ListStakeholdersPaginatedRequest } from "@clients/aggrego-proxy";

export const useStakeholderActiveStatus = (
  currentPagePersistedState: LastPageState,
  stakeholderType: stakeholderTypesEnum,
  stakeholderTypeForFilters: string,
  save: (entity: unknown) => Promise<void>,
  getStakeholderPaginated: (criteria: ListStakeholdersPaginatedRequest) => Promise<void>
) => {
  const [isActivating, setIsActivating] = useState(false);
  const [stakeholder4Activation, setStakeholder4Activation] = useState<Stakeholder | undefined>(undefined);
  const [selectedData, setSelectedData] = useState<Stakeholder>();
  const [stakeholderDetailOpen, setStakeholderDetailOpen] = useState(false);
  const { successMessaggesStatus, errorMessaggesStatus } = useMasterDataMessagges();

  const handleActiveConfirm = (stakeholder: Stakeholder) => {
    setIsActivating(!isActivating);
    setStakeholder4Activation(stakeholder);
  };

  const handleStakeholderStatus = async (stakeholder: Stakeholder) => {
    const promise = save({ ...stakeholder, active: !stakeholder.active });
    promise
      .then(() => successMessaggesStatus(stakeholderType, stakeholder.active ?? true, stakeholder.name ?? ""))
      .catch(error => errorMessaggesStatus(stakeholderType, stakeholder.active ?? true, stakeholder.name ?? "", error))
      .finally(() => {
        setIsActivating(false);
        getStakeholderPaginated({
          limit: currentPagePersistedState.limit,
          offset: currentPagePersistedState.offset,
          status: currentPagePersistedState.status,
          customStakeholderFilter: {
            ...currentPagePersistedState.filters,
            types: [stakeholderTypeForFilters]
          },
          sortDirection: currentPagePersistedState.sortDirection,
          sortField: currentPagePersistedState.sortField
        } as ListStakeholdersPaginatedRequest);
      });
  };

  const handleStakeholderOpen = async (stakeholder: Stakeholder) => {
    setSelectedData(stakeholder);
    setStakeholderDetailOpen(!stakeholderDetailOpen);
  };

  const onCloseActive = () => {
    setIsActivating(false);
  };

  const onCloseStakeholderDialog = () => {
    setStakeholderDetailOpen(false);
  };

  return {
    selectedData,
    stakeholderDetailOpen,
    isActivating,
    stakeholder4Activation,
    onCloseActive,
    handleActiveConfirm,
    handleStakeholderStatus,
    onCloseStakeholderDialog,
    handleStakeholderOpen
  };
};
