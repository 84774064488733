import { useState } from "react";
import { useResolve } from "@movicoders/di";
import Operator from "@domain/model/Operator";
import { LastPageState } from "@domain/model/LastPageState";
import { useMasterDataMessagges } from "@hooks/useMasterDataMessages";
import { ListOperatorsPaginatedRequest } from "@clients/aggrego-proxy";
import { OperatorRepository } from "@infrastructure/repositories/operator-repository";

export const useOperatorActiveStatus = (
  currentPagePersistedState: LastPageState,
  getOperatorsPaginated: (criteria: ListOperatorsPaginatedRequest) => Promise<void>,
  getCurrentStatus: (status: "ALL" | "ACTIVE" | "INACTIVE") => boolean | undefined
) => {
  const { successMessaggesStatus, errorMessaggesStatus } = useMasterDataMessagges();
  const operatorRepository = useResolve<OperatorRepository>(OperatorRepository);

  const [isActivating, setIsActivating] = useState(false);
  const [operator4Activation, setOperator4Activation] = useState<Operator | undefined>(undefined);

  const onCloseActive = () => {
    setIsActivating(false);
  };

  const handleActiveConfirm = (operator: Operator) => {
    setIsActivating(!isActivating);
    setOperator4Activation(operator);
  };

  const handleOperatorStatus = async (operator: Operator) => {
    operatorRepository
      .toggleStatus({ id: operator.id, attributeInfoDTO: { active: !operator.active } })
      .then(() => {
        successMessaggesStatus("operator", operator.active ?? true, operator.firstName ?? "");
      })
      .catch(error => {
        errorMessaggesStatus("operator", operator.active ?? true, operator.firstName ?? "", error);
      })
      .finally(() => {
        setIsActivating(false);
        getOperatorsPaginated({
          limit: currentPagePersistedState.limit,
          offset: currentPagePersistedState.offset,
          customOperatorFilter: {
            ...currentPagePersistedState.filters,
            active: getCurrentStatus(currentPagePersistedState.status)
          },
          sortDirection: currentPagePersistedState.sortDirection,
          sortField: currentPagePersistedState.sortField
        } as ListOperatorsPaginatedRequest);
      });
  };

  return {
    isActivating,
    operator4Activation,
    onCloseActive,
    handleActiveConfirm,
    handleOperatorStatus
  };
};
