import Material from "@domain/model/Material";
import { DomainPage } from "@movicoders/domain";
import { IImpersonationService } from "@domain/services/IImpersonationService";
import { ListMaterialsPaginatedRequest, MaterialsApi } from "@clients/aggrego-proxy/apis/MaterialsApi";

export class MaterialApi {
  constructor(private readonly materialApi: MaterialsApi, private readonly impersonationService: IImpersonationService) {}
  async get(): Promise<Material[]> {
    const result: Material[] = [];

    const apiResult = await this.materialApi.listMaterials({
      status: "ALL",
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
    apiResult.forEach(materialClient => {
      result.push(Material.fromClientMaterial(materialClient));
    });
    return result;
  }

  async getPaginated(pageCriteria: ListMaterialsPaginatedRequest): Promise<DomainPage<Material>> {
    return (await this.materialApi.listMaterialsPaginated({
      ...pageCriteria,
      fullDTO: true,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    })) as unknown as Promise<DomainPage<Material>>;
  }

  async getPaginatedDTOselection(pageCriteria: ListMaterialsPaginatedRequest): Promise<DomainPage<Material>> {
    return (await this.materialApi.listMaterialsPaginated({
      ...pageCriteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    })) as unknown as Promise<DomainPage<Material>>;
  }

  async getById(id: string): Promise<Material> {
    const result = Material.fromClientMaterial(
      await this.materialApi.getMaterialById({
        id: id,
        fullDTO: true,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return result;
  }

  async getByExactCode(code: string): Promise<Material> {
    const result = Material.fromClientMaterial(
      await this.materialApi.getMaterialByExactCode({
        code: code,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return result;
  }

  async create(entity: Material): Promise<Material> {
    const result = Material.fromClientMaterial(
      await this.materialApi.saveMaterial({
        materialDTO: entity,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return result;
  }

  async update(entity: Material): Promise<Material> {
    const result = Material.fromClientMaterial(
      await this.materialApi.updateMaterial({
        materialDTO: entity,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return result;
  }

  async remove(id: string): Promise<void> {
    await this.materialApi.deleteMaterial({
      id: id,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }
}
