import { InvPeriodDTO } from "@domain/model/InvPeriod";
import { LastPageState } from "@domain/model/LastPageState";
import { FormEvent, RefObject, useEffect, useState } from "react";
import { GetInventoryRegistryPaginatedByFiltersRequest } from "@clients/aggrego-proxy";
import { convertDateStringToFirstMillisecond, convertDateStringToLastMillisecond } from "@utils/date-helper";
import { useSnackbar } from "@movicoders/ui";
import { useTranslation } from "react-i18next";

type DateFilter = {
  created: string[];
  searchText: string;
};

export const useInventoryRegistryPagination = (
  isActivePeriod: boolean,
  currentPagePersistedState: LastPageState,
  selectedPeriod: InvPeriodDTO | undefined,
  sortedPeriods: InvPeriodDTO[],
  selectedWarehouseCode: string | undefined,
  getInvRegistryPaginated: (criteria: GetInventoryRegistryPaginatedByFiltersRequest) => Promise<void>
) => {
  const { t } = useTranslation();
  const { show } = useSnackbar();

  const SECOND_IN_MILLIS = 1000;
  const REFRESH_TIME = 30;

  const [searchValue, setSearchValue] = useState("");
  const [showErrors, setShowErrors] = useState(false);
  const [dataRefreshCounter, setDataRefreshCounter] = useState(1);

  useEffect(() => {
    search(
      currentPagePersistedState.limit,
      currentPagePersistedState.offset,
      currentPagePersistedState.status,
      currentPagePersistedState.filters
    );
  }, [selectedPeriod]);

  useEffect(() => {
    search(
      currentPagePersistedState.limit,
      currentPagePersistedState.offset,
      currentPagePersistedState.status,
      currentPagePersistedState.filters
    );
    let intervalCountdown = REFRESH_TIME;
    setInterval(() => {
      intervalCountdown = intervalCountdown - 1;
      setDataRefreshCounter(intervalCountdown);
      if (intervalCountdown === 0) {
        intervalCountdown = REFRESH_TIME;
      }
    }, SECOND_IN_MILLIS);
  }, []);

  useEffect(() => {
    if (sortedPeriods.length && sortedPeriods[0].id === selectedPeriod?.id && dataRefreshCounter === 0 && isActivePeriod) {
      search(
        currentPagePersistedState.limit,
        currentPagePersistedState.offset,
        currentPagePersistedState.status,
        currentPagePersistedState.filters
      );
    }
  }, [dataRefreshCounter]);

  useEffect(() => {
    search(
      currentPagePersistedState.limit,
      currentPagePersistedState.offset,
      currentPagePersistedState.status,
      currentPagePersistedState.filters
    );
  }, [searchValue]);

  const search = (limit: number, offset: number, status?: "ALL" | "ACTIVE" | "INACTIVE", filters?: object | undefined) => {
    let dateFilter = undefined;
    if (currentPagePersistedState.filters && (currentPagePersistedState.filters as DateFilter).created) {
      dateFilter = {
        initDate: convertDateStringToFirstMillisecond((currentPagePersistedState.filters as DateFilter).created[0]),
        endDate: convertDateStringToLastMillisecond((currentPagePersistedState.filters as DateFilter).created[1]),
        consolidated: status === "ACTIVE" ? "OK" : status === "INACTIVE" ? "NOT_CONSOLIDATED" : "",
        searchText: searchValue
      };
    }
    selectedPeriod?.id &&
      getInvRegistryPaginated({
        limit,
        offset,
        inventoryPeriodId: selectedPeriod.id,
        inventoryRegistryFilterDTO:
          dateFilter ?? {
            ...filters,
            warehouseCode: selectedWarehouseCode,
            consolidated: status === "ACTIVE" ? "OK" : status === "INACTIVE" ? "NOT_CONSOLIDATED" : "",
            searchText: searchValue
          } ??
          {}
      }).catch(() => {
        show(t("invregistry.request.error"), "error");
      });
  };

  const clearSearchValue = () => {
    setSearchValue("");
  };

  function handleEnterReload(event: FormEvent<HTMLFormElement>, searchInput: RefObject<HTMLElement>): void {
    event.preventDefault();
    setSearchValue(searchValue);
    searchInput.current?.blur();
    setShowErrors(searchValue === "");
    search(
      currentPagePersistedState.limit,
      currentPagePersistedState.offset,
      currentPagePersistedState.status,
      currentPagePersistedState.filters
    );
  }

  return {
    searchValue,
    setSearchValue,
    clearSearchValue,
    handleEnterReload,
    showErrors,
    search
  };
};
