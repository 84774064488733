import { PortalTypes } from "./PortalTypes";

export class ImpersonationState {
  impersonatedTenant?: string | null;
  impersonationName?: string;
  isImpersonated?: boolean;
  portal?: PortalTypes;
  constructor() {
    this.impersonatedTenant = null;
    this.portal = PortalTypes.AGGREGO;
  }
}
