import { User } from "@domain/model/User";
import { ROUTER_PATHS } from "@constants";
import { useResolve } from "@movicoders/di";
import { useEffect, useState } from "react";
import { useSnackbar } from "@movicoders/ui";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { parseJwt } from "@utils/token-helper";
import { LoginUseCase } from "@application/login/login-use-case";
import { IUserService, UserService } from "@domain/services/IUserService";
import { DrawerService, IDrawerService } from "@domain/services/IDrawerService";
import { ResetPasswordUseCase } from "@application/reset-password/reset-password-use-case";
import { isPasswordValid } from "@components/password/invalid-password-tooltip/InvalidPasswordTooltip";

const useRecoverPasswordViewModel = (token: string | undefined) => {
  const { show } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const loginUseCase = useResolve(LoginUseCase);
  const resetUseCase = useResolve(ResetPasswordUseCase);
  const { save } = useResolve<IUserService>(UserService);
  const { clearDrawerState } = useResolve<IDrawerService>(DrawerService);

  const [pass, setPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  const isValidToken = () => {
    if (token === undefined) return false;
    const parsedToken = parseJwt(token);
    // Check if the token is valid with the exp date.
    const now = Date.now();
    if (parsedToken.exp ?? 0 > now) {
      return true;
    }
    return false;
  };

  const getBannedWordsForPassword = () => {
    if (token === undefined) return [];
    const parsedToken = parseJwt(token);
    const userIdentifier = parsedToken.sub;
    const bannedWords: string[] = [];

    if (userIdentifier) {
      if (userIdentifier.includes("@")) {
        const emailName = userIdentifier.split("@")[0];
        if (emailName) bannedWords.push(emailName);
      } else {
        bannedWords.push(userIdentifier);
      }
    }

    return bannedWords;
  };

  const handleRecover = () => {
    save(new User({ token: token }));
    sessionStorage.setItem("sessionToken", token ?? "");
    switch (true) {
      case pass !== confirmPass:
        show(t("profile.pass.reset.error.match"), "error");
        break;

      case pass.trim() === "" || confirmPass.trim() === "":
        show(t("profile.pass.reset.error.empty"), "error");
        break;

      case !isPasswordValid(pass, getBannedWordsForPassword()):
        show(t("profile.pass.reset.error.invalid"), "error");
        break;

      case !isValidToken():
        show(t("password.recovery.error.invalid.token"), "error");
        break;

      default:
        //Force finish session.
        loginUseCase
          .forceFinishingExistingSession()
          .then(() => {
            //Reset the password.
            resetUseCase
              .execute(pass)
              .then(() => {
                //Load the user.
                loginUseCase.loadUser();
                clearDrawerState();
                navigate("/" + ROUTER_PATHS.home);
                show(t("password.recovery.success"), "success");
              })
              .catch(() => {
                show(t("password.recovery.error.unexpected"), "error");
              });
          })
          .catch(() => {
            show(t("password.recovery.error.unexpected"), "error");
          });
    }
  };

  useEffect(() => {
    if (token !== undefined) {
      save(new User({ token: token }));
    }
  }, [token]);

  return {
    pass,
    setPass,
    confirmPass,
    setConfirmPass,
    handleRecover,
    getBannedWordsForPassword
  };
};

export default useRecoverPasswordViewModel;
