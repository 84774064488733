import { useState } from "react";
import { COLORS, MEDIA } from "@theme";
import Icon from "@components/icons/icon";
import { useSnackbar } from "@movicoders/ui";
import { useTranslation } from "react-i18next";
import { profileViewStyles } from "../../../../../../profile/profile-view.Styles";
import { Button, Grid, IconButton, Theme, Typography, useMediaQuery } from "@mui/material";

export const FileInput = (props: { handleImage: (image: Blob) => void }) => {
  const [image, setImage] = useState<File>();
  const { t } = useTranslation();
  const { show } = useSnackbar();
  const { handleImage } = props;
  const styles = profileViewStyles();
  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.up(MEDIA.small), { noSsr: true });

  return (
    <div className="App">
      <label htmlFor="upload-photo">
        <input
          style={{ display: "none" }}
          id="upload-photo"
          name="upload-photo"
          type="file"
          accept="image/png, image/jpeg"
          onChange={event => {
            if (event.target.files) {
              handleImage(event.target.files[0]);
              if (event.target.files[0].type.includes("image/png") || event.target.files[0].type.includes("image/jp")) {
                setImage(event.target.files[0]);
              } else {
                show(t("multimedia.image.uploaded.failed"), "error");
                setImage(undefined);
              }
            }
          }}
        />
        <Grid item display={"flex"} flexDirection={"row"}>
          <Grid item>
            {matchesXs ? (
              <Button
                component="span"
                startIcon={<Icon icon="plus" color={COLORS.white} />}
                variant="primary"
                sx={{ width: "100%" }}
                id="add-image-button">
                {t("step-master-data-image-button")}
              </Button>
            ) : (
              <IconButton component="span" sx={styles.add_icon_button} id="add-image-button">
                <Icon icon="plus" color={COLORS.white} />
              </IconButton>
            )}
          </Grid>
          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <Typography sx={styles.fileName} id={"file-name-label"}>
              {image?.name}
            </Typography>
          </Grid>
        </Grid>
      </label>
    </div>
  );
};
