import { useResolve } from "@movicoders/di";
import { IEditionStatesStore } from "../../../stores/edition-states-store/IEditionStatesStore";
import { useEditionStatesStore } from "../../../stores/edition-states-store/edition-states-store";
import { ChangeEvent } from "react";
import { MODES } from "../../../constants/Modes";

export const useModeSelectionMenuViewmodel = () => {
  const {
    getSelectedMode,
    setSelectedMode,
    getModifyingWH,
    getCanUseZones
  } = useResolve<IEditionStatesStore>(useEditionStatesStore);

  const handleModeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedMode(event.target.value as MODES);
  }

  const getCanUseByMode = (mode: MODES) => {
    return mode !== "ZONES" || (mode === "ZONES" && getCanUseZones())
  };

  return {
    getSelectedMode,
    getModifyingWH,
    handleModeChange,
    getCanUseByMode
  }
}