import { useResolve } from "@movicoders/di";
import { TableState } from "@domain/model/TableState";
import { ITableService, TableService } from "@domain/services/ITableService";

export const useVisibilityColumns = (currentViewName: string) => {
  const service = useResolve<ITableService>(TableService);
  const persistedState = service.persistedState ?? new TableState();
  const { views } = { ...persistedState };

  const onChangeColumnVisibility = (field: string, visible: boolean) => {
    let tempView = views.find(view => view.viewName === currentViewName);
    if (tempView) {
      const tempColumn = tempView.columns.find(col => col.fieldName === field);
      if (tempColumn) {
        tempView.columns[tempView.columns.indexOf(tempColumn)] = { fieldName: field, visible: visible };
      } else {
        tempView.columns.push({ fieldName: field, visible: visible });
      }
    } else {
      tempView = { viewName: currentViewName, columns: [] };
      tempView.columns.push({ fieldName: field, visible: visible });
      views.push(tempView);
    }

    service.saveTableState({
      ...persistedState,
      views: views
    });
  };

  return {
    onChangeColumnVisibility
  };
};
