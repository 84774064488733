import Material from "@domain/model/Material";
import { CREATE_ID, TIMEOUT_ID } from "@constants";
import { LastPageState } from "@domain/model/LastPageState";
import { useMasterDataMessagges } from "@hooks/useMasterDataMessages";
import { ListMaterialsPaginatedRequest } from "@clients/aggrego-proxy/apis/MaterialsApi";

export const useMaterialSave = (
  selectedMaterial: Material,
  persistedState: LastPageState,
  save: (entity: Material) => Promise<void>,
  fetchOne: (id: string) => Promise<void>,
  goToList: (criteria?: ListMaterialsPaginatedRequest) => Promise<void>
) => {
  const { successMessaggesSaving, errorMessaggesSaving, errorMessaggesLoading } = useMasterDataMessagges();

  const getStatus = (status: "ACTIVE" | "INACTIVE" | "ALL"): boolean | undefined => {
    const STATUS_ACTIVE = true;
    const STATUS_INACTIVE = false;

    if (status === "ACTIVE") {
      return STATUS_ACTIVE;
    }
    if (status === "INACTIVE") {
      return STATUS_INACTIVE;
    } else {
      return undefined;
    }
  };

  // Opens the edit dialog.
  const handleEdit = (id: string) => {
    fetchOne(id).catch(() => {
      errorMessaggesLoading("material", selectedMaterial.name);
      selectedMaterial.id = TIMEOUT_ID;
    });
  };

  const handleSave = async (material: Material) => {
    if (material.id === CREATE_ID) {
      material.active = true;
    }

    save(material)
      .then(() => successMessaggesSaving("material", material.name, material.id))
      .catch(error => errorMessaggesSaving("material", material.name, material.id, error))
      .finally(() => {
        goToList({
          xTenantId: "",
          limit: persistedState.limit,
          offset: persistedState.offset,
          fullDTO: true,
          customMaterialFilter: { ...persistedState.filters, status: getStatus(persistedState.status) },
          sortDirection: persistedState.sortDirection,
          sortField: persistedState.sortField
        } as ListMaterialsPaginatedRequest);
      });
  };

  return {
    handleSave,
    handleEdit
  };
};
