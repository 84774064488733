import { FamilyDTO, FamilyTypeDTO, LocationDTO, MaterialDTO } from "@clients/aggrego-proxy/models";

export default class LocationEntity {
  capacity?: number;
  code?: string;
  containers?: Array<string>;
  families?: Array<FamilyDTO>;
  familyTypes?: Array<FamilyTypeDTO>;
  id?: string;
  level?: number;
  materials?: Array<MaterialDTO>;
  tileId?: string;
  active?: boolean;
  occupation?: number;
  warehouseId?: string;

  constructor(
    capacity?: number,
    code?: string,
    containers?: Array<string>,
    families?: Array<FamilyDTO>,
    familyTypes?: Array<FamilyTypeDTO>,
    id?: string,
    level?: number,
    materials?: Array<MaterialDTO>,
    tileId?: string,
    active?: boolean,
    occupation?: number,
    warehouseId?: string
  ) {
    this.capacity = capacity;
    this.code = code;
    this.containers = containers;
    this.families = families;
    this.familyTypes = familyTypes;
    this.id = id;
    this.level = level;
    this.materials = materials;
    this.tileId = tileId;
    this.active = active;
    this.occupation = occupation;
    this.warehouseId = warehouseId;
  }

  static isTextValueNotValid(value: string) {
    return value.toString().trim() === "" || value === undefined;
  }

  static isNumberValueNotValid(value: number) {
    return Number(value) <= 0;
  }

  static isLevelValueNotValid(value: number, warehouseSizesY: number) {
    return Number(value) > Number(warehouseSizesY);
  }

  static IsCapacityLowerThanContainers(location: LocationEntity) {
    const validation =
      location.containers?.length && location.capacity && location.capacity < location.containers.length ? true : false;
    return validation;
  }

  static fromClientLocation(location: LocationDTO) {
    const result = new LocationEntity(
      location.capacity ?? 0,
      location.code ?? "",
      location.containers ?? [],
      location.families ?? [],
      location.familyTypes ?? [],
      location.id ?? "",
      location.level ?? 0,
      location.materials ?? [],
      location.tileId ?? "",
      location.active ?? true,
      location.occupation ?? 0,
      location.warehouseId ?? ""
    );
    return result;
  }

  static toClientLocation(location: LocationEntity) {
    const result: LocationDTO = {};
    result.active = location.active;
    result.capacity = location.capacity;
    result.code = location.code;
    result.containers = location.containers;
    result.families = location.families;
    result.familyTypes = location.familyTypes;
    result.id = location.id;
    result.level = location.level;
    result.materials = location.materials;
    result.tileId = location.tileId;
    result.occupation = location.occupation;
    result.warehouseId = location.warehouseId;
    return result;
  }

  /**
   * Verifies if the properties of an object match with every property of the Location class.
   * This function is useful when using 'instanceof' is not possible, such as when the instance has been lost.
   *
   * @param {object} object - The object to be checked.
   * @returns {boolean} - Returns true if the object matches the Location class properties; otherwise, returns false.
   */
  static isLocation = (object: unknown): object is Location => {
    if (typeof object !== "object" || object === null) {
      return false;
    }

    const expectedKeys: (keyof LocationEntity)[] = [
      "capacity",
      "code",
      "containers",
      "families",
      "familyTypes",
      "id",
      "level",
      "materials",
      "tileId",
      "active",
      "occupation",
      "warehouseId"
    ];

    for (const key of expectedKeys) {
      if (!(key in object)) {
        return false;
      }
    }

    return true;
  };
}
