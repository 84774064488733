import { COLORS } from "@theme";
import Icon from "../../../icons/icon";
import { useResolve } from "@movicoders/di";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IPath } from "../drawer-paths-configuration";
import { usePermissions } from "@hooks/usePermissions";
import { checkIconColor } from "@utils/drawer-icon-helper";
import { ISubmenusConf } from "../interfaces/drawer-interfaces";
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { IsValidImpersonationUseCase } from "@application/impersonation/is-valid-impersonation-use-case";

export const CollapsibleMenu = ({
  isDrawerOpen,
  tooltipKey,
  iconName,
  paths,
  onChange,
  menuOpen,
  handleOpen,
  menuId,
  drawerWidth,
  settingsArray
}: ISubmenusConf) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isValidPermissions } = usePermissions();
  const isValidImpersonationUseCase = useResolve(IsValidImpersonationUseCase);

  const handleSettingsPermisions = (name: string) => {
    return settingsArray.includes(name);
  };

  return (
    <>
      {isValidImpersonationUseCase.execute() || paths.filter(p => !p.needsTenant).length > 0 ? (
        <>
          <Tooltip title={isDrawerOpen ? "" : t(tooltipKey)} placement="right">
            <ListItemButton
              id={menuId}
              data-is-open={menuOpen}
              className="drawer-item"
              sx={{
                width: drawerWidth
              }}
              onClick={e => {
                onChange();
                e.stopPropagation();
              }}>
              <ListItemIcon style={{ color: menuOpen ? COLORS.secondary : "inherit" }}>
                <Icon icon={iconName} />
              </ListItemIcon>
              {isDrawerOpen && (
                <>
                  <ListItemText
                    primaryTypographyProps={{
                      variant: "drawer",
                      color: menuOpen ? COLORS.secondary : "inherit"
                    }}
                    primary={t(tooltipKey)}
                    style={{ margin: 0 }}
                  />
                  <Icon
                    icon="chevron-up"
                    color={menuOpen ? COLORS.secondary : "inherit"}
                    style={{ transform: menuOpen ? "rotate(0deg)" : "rotate(180deg)", transition: "transform 0.2s linear" }}
                  />
                </>
              )}
            </ListItemButton>
          </Tooltip>
          <Collapse in={menuOpen && isDrawerOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {paths.map((path: IPath) => {
                const color = checkIconColor(path.name);
                const isIMUser = path.hiddenByIM ?? false;
                const showPath =
                  (!path.permissions.length || isValidPermissions(path.permissions)) &&
                  (isValidImpersonationUseCase.execute() || !path.needsTenant) &&
                  isIMUser === false &&
                  handleSettingsPermisions(path.name);
                return showPath ? (
                  <ListItemButton
                    id={path.id}
                    key={path.name}
                    className="drawer-item"
                    sx={{ pl: "3rem" }}
                    onClick={() => {
                      navigate(path.path);
                      handleOpen();
                    }}
                    style={{ width: drawerWidth }}>
                    {isDrawerOpen && (
                      <>
                        <ListItemIcon style={{ color: color }}>{path.icon}</ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{ variant: "drawer", color: color }}
                          primary={t(path.name).toUpperCase()}
                          style={{ margin: 0 }}
                        />
                      </>
                    )}
                  </ListItemButton>
                ) : (
                  <div key={path.path} />
                );
              })}
            </List>
          </Collapse>
        </>
      ) : null}
    </>
  );
};
