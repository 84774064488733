import { COLORS } from "@theme";
import Icon from "../../icons/icon";
import { useResolve } from "@movicoders/di";
import { Trans, useTranslation } from "react-i18next";
import { TUserRoleEnum } from "@domain/interface/User";
import { impersonationInfoStyles } from "./impersonation-info.Styles";
import { useDrawerViewModel } from "../drawer/hooks/useDrawerViewModel";
import { IUserService, UserService } from "@domain/services/IUserService";
import { Grid, Typography, IconButton, Theme, useMediaQuery } from "@mui/material";
import { IImpersonationService, ImpersonationService } from "@domain/services/IImpersonationService";
import { useImpersonationViewModel } from "../drawer/components/impersonation/useImpersonationViewModel";

export const ImpersonationInfo = () => {
  const { t } = useTranslation();
  const styles = impersonationInfoStyles;
  const userService = useResolve<IUserService>(UserService);
  const { persistedState: impersonationState } = useResolve<IImpersonationService>(ImpersonationService);
  const { clearImpersonation } = useImpersonationViewModel();

  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"), { noSsr: true });

  const { persistedState } = useDrawerViewModel();

  const isRole = (role: TUserRoleEnum) => {
    return userService.user?.roles.includes(role);
  };

  return (
    <>
      {isRole("SUPER_ADMIN") &&
        impersonationState?.impersonatedTenant !== null &&
        impersonationState?.impersonatedTenant &&
        (matches || (!persistedState.drawer && !matches)) && (
          <Grid container sx={styles.container} id="impersonation-info">
            <Grid item sx={styles.text_container}>
              <Typography>
                <Trans>{t("impersonation.login.message", { tenantName: impersonationState.impersonationName ?? "" })}</Trans>
              </Typography>
            </Grid>
            <Grid item sx={styles.logout_container}>
              <IconButton
                id="impersonation-info-logout"
                sx={{ p: 0 }}
                onClick={e => {
                  e.stopPropagation();
                  clearImpersonation(e);
                }}>
                <Icon icon="exit" color={COLORS.alert} />
              </IconButton>
            </Grid>
          </Grid>
        )}
    </>
  );
};
