import {
  AddLocationToTileRequest,
  DeleteTilesRequest,
  GetTilesByWarehouseIDRequest,
  ListTilesByTenantIdRequest,
  SaveTilesRequest,
  TilesApi,
  UpdateTilesRequest
} from "@clients/aggrego-proxy/apis/TilesApi";
import Tile from "@domain/model/Tile";
import { ListResponseDTO, TileDTO } from "@clients/aggrego-proxy";
import { IImpersonationService } from "@domain/services/IImpersonationService";

export class TileApi {
  constructor(private readonly tileApi: TilesApi, private readonly impersonationService: IImpersonationService) {}
  async get(): Promise<Tile[]> {
    const result: Tile[] = [];

    const apiResult = await this.tileApi.listTiles({
      fullDTO: false,
      status: "ALL",
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
    apiResult.forEach(tileClient => {
      result.push(Tile.fromClientTile(tileClient));
    });
    return result;
  }

  async getById(id: string): Promise<Tile> {
    const result = Tile.fromClientTile(
      await this.tileApi.getTileById({
        id: id,
        fullDTO: true,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return result;
  }

  async getByIdFalseDTO(id: string): Promise<Tile> {
    const result = Tile.fromClientTile(
      await this.tileApi.getTileById({
        id: id,
        fullDTO: false,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return result;
  }

  async getByTenantId(criteria: ListTilesByTenantIdRequest): Promise<Tile[]> {
    const result: Tile[] = [];

    const apiResult = await this.tileApi.listTilesByTenantId({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });

    apiResult.forEach((clientTile: TileDTO) => {
      result.push(Tile.fromClientTile(clientTile));
    });
    return result;
  }

  async getByWarehouseId(criteria: GetTilesByWarehouseIDRequest): Promise<Tile[]> {
    const result: Tile[] = [];

    const apiResult = await this.tileApi.getTilesByWarehouseID({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });

    apiResult.forEach((clientTile: TileDTO) => {
      result.push(Tile.fromClientTile(clientTile));
    });
    return result;
  }

  async create(entity: Tile): Promise<Tile> {
    const result = Tile.fromClientTile(
      await this.tileApi.saveTile({
        tileDTO: entity,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return result;
  }

  async createMany(criteria: SaveTilesRequest): Promise<ListResponseDTO> {
    const result = await this.tileApi.saveTiles({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
    return result;
  }

  async update(entity: Tile): Promise<Tile> {
    const result = Tile.fromClientTile(
      await this.tileApi.updateTile({
        tileDTO: entity,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return result;
  }

  async updateMany(criteria: UpdateTilesRequest): Promise<ListResponseDTO> {
    const result = await this.tileApi.updateTiles({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
    return result;
  }

  async remove(id: string): Promise<void> {
    await this.tileApi.deleteTile({ id: id, xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? "" });
  }

  async removeMany(criteria: DeleteTilesRequest): Promise<TileDTO[]> {
    const result = this.tileApi.deleteTiles({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
    return result;
  }

  async addLocation(criteria: AddLocationToTileRequest): Promise<TileDTO> {
    const result = this.tileApi.addLocationToTile({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
    return result;
  }
}
