/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LocationItemDTO
 */
export interface LocationItemDTO {
    /**
     * 
     * @type {boolean}
     * @memberof LocationItemDTO
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LocationItemDTO
     */
    capacity?: number;
    /**
     * 
     * @type {string}
     * @memberof LocationItemDTO
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof LocationItemDTO
     */
    coordinateX?: number;
    /**
     * 
     * @type {number}
     * @memberof LocationItemDTO
     */
    coordinateZ?: number;
    /**
     * 
     * @type {string}
     * @memberof LocationItemDTO
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof LocationItemDTO
     */
    level?: number;
    /**
     * 
     * @type {string}
     * @memberof LocationItemDTO
     */
    tileId?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationItemDTO
     */
    warehouseId?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationItemDTO
     */
    zoneName?: string;
}

/**
 * Check if a given object implements the LocationItemDTO interface.
 */
export function instanceOfLocationItemDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LocationItemDTOFromJSON(json: any): LocationItemDTO {
    return LocationItemDTOFromJSONTyped(json, false);
}

export function LocationItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'capacity': !exists(json, 'capacity') ? undefined : json['capacity'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'coordinateX': !exists(json, 'coordinateX') ? undefined : json['coordinateX'],
        'coordinateZ': !exists(json, 'coordinateZ') ? undefined : json['coordinateZ'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'tileId': !exists(json, 'tileId') ? undefined : json['tileId'],
        'warehouseId': !exists(json, 'warehouseId') ? undefined : json['warehouseId'],
        'zoneName': !exists(json, 'zoneName') ? undefined : json['zoneName'],
    };
}

export function LocationItemDTOToJSON(value?: LocationItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'capacity': value.capacity,
        'code': value.code,
        'coordinateX': value.coordinateX,
        'coordinateZ': value.coordinateZ,
        'id': value.id,
        'level': value.level,
        'tileId': value.tileId,
        'warehouseId': value.warehouseId,
        'zoneName': value.zoneName,
    };
}

