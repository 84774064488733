import { Grid, Button } from "@mui/material";
import { t } from "i18next";
import { useEndModifyingButtons } from "./useEndModifyingButtons";

export const EndModifyingButtons = () => {

  const {
    getSelectedMode,
    getModifyingWH,
    getCanPlace,
    handleCancelChanges,
    handleSaveChanges
  } = useEndModifyingButtons();

  return (
    <Grid container justifyContent="space-between" rowSpacing={0.5}>
      <Grid item xs={12}>
        <Button
          color="secondary"
          sx={{ width: "100%", height: "2.5rem" }}
          onClick={handleSaveChanges}
          disabled={!getModifyingWH() || (getSelectedMode() === "MOVE" && !getCanPlace())}>
          {t("viewer.mode.apply")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          color="error"
          sx={{ width: "100%", height: "2.5rem" }}
          onClick={handleCancelChanges}
          disabled={!getModifyingWH()}>
          {t("viewer.mode.cancel")}
        </Button>
      </Grid>
    </Grid>
  )
}