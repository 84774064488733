import { SxProps, Theme } from "@mui/material";

export const recoverPasswordCardStyles = () => {
  const recover_password: SxProps<Theme> = {
    backgroundColor: "white",
    borderRadius: "6px",
    padding: "1rem",
    marginTop: "2.8rem",
    width: "420px",
    height: "fit-content",

    "&& > *:not(:last-child):not(:nth-of-type(2))": {
      marginBottom: "2rem"
    }
  };

  return {
    recover_password
  };
};
