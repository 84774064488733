import { useResolve } from "@movicoders/di";
import { IDialogsStatesStore } from "../../../stores/dialogs-states-store/IDialogsStatesStore";
import { useDialogsStatesStore } from "../../../stores/dialogs-states-store/dialogs-states-store";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { LocationRepository } from "../../../../../../infrastructure/repositories/location-repository";
import { LocationDTO } from "../../../../../../clients/aggrego-proxy";
import { TileRepository } from "../../../../../../infrastructure/repositories/tile-repository";
import { location3DFromDTO } from "../../../types/Location3D";
import { IWarehouse3DStore } from "../../../stores/warehouse-3d-store/IWarehouse3DStore";
import { useWarehouse3DStore } from "../../../stores/warehouse-3d-store/warehouse-3d-store";

export const useAddLocationDialog3D = () => {
  const { getWarehouse } = useResolve<IWarehouse3DStore>(useWarehouse3DStore);
  const {
    getAddLocationDialogOpen,
    setAddLocationDialogOpen,
    getAddLocationPositionProps,
    setSelectedBox
  } = useResolve<IDialogsStatesStore>(useDialogsStatesStore);

  const locationRepository = useRef(useResolve<LocationRepository>(LocationRepository)).current;
  const tileRepository = useRef(useResolve<TileRepository>(TileRepository)).current;

  const [newLocCode, setNewLocCode] = useState("");
  const [codeError, setCodeError] = useState("");
  const [newLocCapacity, setNewLocCapacity] = useState(1);
  const [capacityError, setCapacityError] = useState(false);

  useEffect(() => {
    const currentTile = getWarehouse().tiles?.find(t => t.id === getAddLocationPositionProps().tileId);
    setNewLocCode(`${currentTile?.x}.${getAddLocationPositionProps().level}.${currentTile?.z}`)
  }, [getAddLocationPositionProps().tileId])

  const handleClose = () => {
    setAddLocationDialogOpen(false);
  }

  const handleChangeCode = (e: ChangeEvent<HTMLInputElement>) => setNewLocCode(e.target.value);

  const handleChangeCapacity = (e: ChangeEvent<HTMLInputElement>) => setNewLocCapacity(Number(e.target.value));

  const handleSave = () => {
    const usedProps = getAddLocationPositionProps();
    //Checks if code already exists.
    locationRepository.getByExactCode({ code: newLocCode, warehouseId: getWarehouse().id }).then((foundLoc) => {
      if (getWarehouse().tiles?.find(tile => tile.id === foundLoc.tileId))
        setCodeError("viewer.create.location.error.code.exists");
    }).catch(() => {

      if (newLocCode.trim() === "") {
        setCodeError("locations.dialog.empty.field.text");
        return;
      }

      if (newLocCapacity <= 0) {
        setCapacityError(true);
        return;
      }

      setSelectedBox(undefined);

      const locationToSave: LocationDTO = {
        code: newLocCode,
        capacity: newLocCapacity,
        level: usedProps.level,
        tileId: usedProps.tileId,
        active: true,
        containers: [],
        families: [],
        familyTypes: [],
        materials: [],
        occupation: 0,
        warehouseId: getWarehouse().id
      }

      locationRepository.create(locationToSave).then((newLoc) => {
        const whTiles = getWarehouse().tiles ?? [];
        const currentTile = whTiles.find(t => t.id === getAddLocationPositionProps().tileId);

        if (currentTile?.id && newLoc.id) {
          tileRepository.addLocation({ locationId: newLoc.id, tileId: currentTile.id }).then(() => {
            currentTile.locations?.push(location3DFromDTO(newLoc));
            setSelectedBox(currentTile);
          })
        }
      })
      setCodeError("");
      handleClose();
    })
  }

  return {
    newLocCode,
    newLocCapacity,
    codeError,
    capacityError,
    getIsOpen: getAddLocationDialogOpen,
    handleClose,
    handleChangeCode,
    handleChangeCapacity,
    handleSave
  }
}