/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomMaterialFilter
 */
export interface CustomMaterialFilter {
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof CustomMaterialFilter
     */
    caseInsensitive?: { [key: string]: boolean; };
    /**
     * 
     * @type {string}
     * @memberof CustomMaterialFilter
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomMaterialFilter
     */
    family?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomMaterialFilter
     */
    minStock?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomMaterialFilter
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomMaterialFilter
     */
    searchText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomMaterialFilter
     */
    status?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CustomMaterialFilter
     */
    unitPrice?: number;
}

/**
 * Check if a given object implements the CustomMaterialFilter interface.
 */
export function instanceOfCustomMaterialFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomMaterialFilterFromJSON(json: any): CustomMaterialFilter {
    return CustomMaterialFilterFromJSONTyped(json, false);
}

export function CustomMaterialFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomMaterialFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'caseInsensitive': !exists(json, 'caseInsensitive') ? undefined : json['caseInsensitive'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'family': !exists(json, 'family') ? undefined : json['family'],
        'minStock': !exists(json, 'minStock') ? undefined : json['minStock'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'searchText': !exists(json, 'searchText') ? undefined : json['searchText'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'unitPrice': !exists(json, 'unitPrice') ? undefined : json['unitPrice'],
    };
}

export function CustomMaterialFilterToJSON(value?: CustomMaterialFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'caseInsensitive': value.caseInsensitive,
        'code': value.code,
        'family': value.family,
        'minStock': value.minStock,
        'name': value.name,
        'searchText': value.searchText,
        'status': value.status,
        'unitPrice': value.unitPrice,
    };
}

