import { TUserRoleEnum } from "../../domain/interface/User";
import { IUserService } from "../../domain/services/IUserService";

export class CheckUserRoleUseCase {
  constructor(private readonly userService: IUserService) {}

  isRole = (role: TUserRoleEnum) => {
    return this.userService.user?.roles.includes(role);
  };
}
