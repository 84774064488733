import Icon from "../icons/icon";
import { useTranslation } from "react-i18next";
import { Paper, InputBase } from "@mui/material";
import { FormEvent, useEffect, useRef, useState } from "react";

/**
 * @export
 * @interface ISimpleSearch
 */
export interface ISimpleSearch {
  placeholder: string;
  className?: string;
  clear?: boolean;
  handleSearch: (term: string) => void;
  /**
   * The preselected value of the search bar filter, the search bar will automatically
   * presets its value to the provided in this property.
   */
  searchBarFilterPreselectedValue?: string;
}

/**
 * Accepts search bar type.
 * @param {ISearchBox} params
 */
export const SimpleSearchBox = function (params: ISimpleSearch) {
  const [searchText, setSearchText] = useState<string>();
  const { t } = useTranslation();
  const searchInput = useRef<HTMLElement>(null);

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleClear = () => {
    setSearchText("");
  };

  useEffect(() => {
    if (searchText !== undefined) {
      const timer = setTimeout(() => params.handleSearch(searchText), 500);
      return () => clearTimeout(timer);
    }
  }, [searchText]);

  /**
   * This avoids Paper to recharge the page
   * @param event Event on Paper
   */
  function handleEnterKey(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    searchInput.current?.blur();
  }

  useEffect(() => {
    if (params.searchBarFilterPreselectedValue) {
      setSearchText(params.searchBarFilterPreselectedValue ?? "");
    }
  }, [params.searchBarFilterPreselectedValue]);

  return (
    <Paper component="form" className="search-bar" sx={{ mb: 1 }} onSubmit={handleEnterKey}>
      <Icon icon="search" size="1.6rem" color="inherit" />
      <InputBase
        value={searchText ?? ""}
        onChange={handleChangeText}
        id="search-bar"
        sx={{ fontSize: "small", justifyContent: "space-between", width: "100%" }}
        placeholder={`${t("search.placeholder")}`}
        endAdornment={
          searchText ? <Icon icon="close" className="search-icon" onClick={handleClear} id="search-clear-button" /> : <></>
        }
        inputProps={{ ref: searchInput, enterKeyHint: "search" }}
      />
    </Paper>
  );
};
