import Zone from "@domain/model/Zone";
import { TIMEOUT_ID } from "@constants";
import { LastPageState } from "@domain/model/LastPageState";
import MasterDataConstants from "../../../MasterDataConstants";
import { ListZonesPaginatedRequest } from "@clients/aggrego-proxy";
import { ILastPageService } from "@domain/services/ILastPageService";
import { useMasterDataMessagges } from "@hooks/useMasterDataMessages";

export const useZoneSave = (
  currentPageService: ILastPageService,
  currentPagePersistedState: LastPageState,
  selectedZone: Zone,
  selectedWarehouse: string,
  fetchOne: (id: string) => Promise<void>,
  save: (entity: Zone) => Promise<void>,
  getCurrentStatus: (status: "ALL" | "ACTIVE" | "INACTIVE") => boolean | undefined,
  goToList: (criteria: ListZonesPaginatedRequest) => Promise<void>
) => {
  const { successMessaggesSaving, errorMessaggesSaving, errorMessaggesLoading } = useMasterDataMessagges();

  const handleEdit = (id: string) => {
    fetchOne(id).catch(() => {
      errorMessaggesLoading("zone", (selectedZone as Zone).name);
      (selectedZone as Zone).id = TIMEOUT_ID;
    });
  };

  const handleSave = (zone: Zone): void => {
    if (!zone.id) {
      zone.name = "";
      zone.color = "";
      zone.tiles = [];
      zone.warehouseId = "";
    }
    save(zone)
      .then(() => successMessaggesSaving("zone", zone.name, zone.id))
      .catch(error => errorMessaggesSaving("zone", zone.name, zone.id, error))
      .finally(() => {
        goToList({
          limit: MasterDataConstants.ITEMS_PER_PAGE,
          offset: currentPageService.persistedState?.offset ?? 0,
          customZoneFilter: {
            status: getCurrentStatus(currentPagePersistedState.status),
            ...currentPagePersistedState.filters,
            warehouseId: selectedWarehouse
          }
        });
      });
  };

  return {
    handleSave,
    handleEdit
  };
};
