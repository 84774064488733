import { useState } from "react";
import { useSnackbar } from "@movicoders/ui";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import IMMEntryWithDesc from "@domain/model/IMMEntryWithDesc";

export const useInventoryMaterialForm = () => {
  const { t } = useTranslation();
  const { show } = useSnackbar();
  const { state } = useLocation();

  const MIN = 0;
  const MAX = 1000000;

  const [entryForModify, setEntryForModify] = useState<IMMEntryWithDesc>({});
  const [iMMaterialList, setIMMaterialList] = useState<IMMEntryWithDesc[]>(state?.failedSync ?? []);
  const isOperatorLogged = state?.isOperatorLogged ?? false;

  const [isRequesting, setIsRequesting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isClearingAll, setIsClearingAll] = useState(false);

  const noEntries: boolean = iMMaterialList.length > 0;

  const handleRequestDialog = (value: IMMEntryWithDesc) => {
    setEntryForModify(value);
    setIsRequesting(!isRequesting);
  };

  const handleOpenEditDialog = (entry: IMMEntryWithDesc) => {
    setIsEditing(true);
    setEntryForModify(entry);
  };

  const handleOpenDeleteDialog = (entry: IMMEntryWithDesc) => {
    setIsDeleting(!isDeleting);
    setEntryForModify(entry);
  };

  const handleOpenClearAllDialog = () => {
    setIsClearingAll(true);
  };

  const onCloseDialog = () => {
    setIsRequesting(false);
    setIsDeleting(false);
    setIsEditing(false);
    setIsClearingAll(false);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEntryForModify({
      ...entryForModify,
      [event.target.name]: event.target.value
    });
  };

  const onChangeNumeric = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9]+$/;
    const inputValue = event.target.value;
    let newValue = "0";
    if (regex.test(inputValue) || inputValue === "") {
      if (Number(inputValue) <= MIN) {
        newValue = MIN.toString();
      } else if (Number(inputValue) > MAX) {
        newValue = MAX.toString();
      } else {
        newValue = inputValue === "" ? "0" : inputValue.replace(/^0+/, "");
      }
      setEntryForModify({ ...entryForModify, [event.target.name]: newValue });
    }
  };

  const handleSave = () => {
    if (!iMMaterialList.find(entry => entry.id === entryForModify.id)) {
      setIMMaterialList(prevList => [...prevList, entryForModify]);
    } else {
      show(t("inbounds.repeated"), "error");
    }
    onCloseDialog();
  };

  const handleEdit = () => {
    setIMMaterialList(prevEntries =>
      prevEntries.map((entry: IMMEntryWithDesc) => (entry.id === entryForModify.id ? entryForModify : entry))
    );
    onCloseDialog();
  };

  const handleDelete = () => {
    iMMaterialList.map(iMMaterial => {
      setIMMaterialList(iMMaterialList.filter((item: IMMEntryWithDesc) => item.id !== entryForModify.id));
      return true;
    });
    onCloseDialog();
  };

  const handleClearAll = () => {
    setIMMaterialList([]);
    onCloseDialog();
  };

  return {
    entryForModify,
    iMMaterialList,
    setIMMaterialList,
    isRequesting,
    isEditing,
    isDeleting,
    isClearingAll,
    handleRequestDialog,
    handleOpenEditDialog,
    handleOpenDeleteDialog,
    handleOpenClearAllDialog,
    onCloseDialog,
    onChange,
    onChangeNumeric,
    handleSave,
    handleEdit,
    handleDelete,
    handleClearAll,
    noEntries,
    isOperatorLogged
  };
};
