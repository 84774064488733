import { COLORS } from "@theme";
import { SxProps, Theme } from "@mui/material";

export const homeTitleStyles = () => {
  const home_data_title: SxProps<Theme> = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: "20px",
    "& .MuiSvgIcon-root": {
      color: COLORS.primary
    },
    "& .MuiTypography-root": {
      color: COLORS.secondary,
      fontWeight: "600",
      fontSize: "1.6rem"
    }
  };

  const home_search_autocomplete: SxProps<Theme> = {
    width: "20rem",
    backgroundColor: "white",
    paddingTop: "0.320rem",
    borderRadius: "0.5rem",
    "& .MuiInputBase-root": { height: "2.20rem" }
  };

  const home_search_button: SxProps<Theme> = {
    background: COLORS.secondary,
    borderRadius: "4px",
    height: "fit-content",
    marginLeft: "1rem",
    "&:hover": {
      background: COLORS.secondaryHighlight
    },
    "&:disabled": {
      background: COLORS.secondaryOpacity
    }
  };

  const divider: SxProps<Theme> = {
    border: "2px solid #EBEBEB",
    opacity: 1,
    width: "100%",
    marginTop: 1,
    marginBottom: 1
  };

  return {
    home_data_title,
    home_search_autocomplete,
    home_search_button,
    divider
  };
};
