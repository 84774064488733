import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";
import { COLORS } from "@theme";

export const stepperStyles = () => {
  const categories_typography: SxProps<Theme> = {
    color: COLORS.gray,
    fontSize: "1.125rem",
    fontWeight: 600
  };

  const item_typography: SxProps<Theme> = {
    ml: "1rem",
    fontSize: "1.125rem",
    fontWeight: 600
  };

  const company_data_blue: SxProps<Theme> = {
    fontSize: "1rem",
    fontWeight: 500,
    color: COLORS.secondary
  };
  return { categories_typography, item_typography, company_data_blue };
};
