import { DTOConvertibleEntity } from "./GenericPage";
import { UUIDGenerator } from "@utils/uuid-generator";
import { InventoryRegistryDTO } from "@clients/aggrego-proxy";

/**
 * Aggrego Logistic Operations service (dev)
 * Warehouse status report
 */
export class InvRegistryDTO extends DTOConvertibleEntity<InventoryRegistryDTO, InvRegistryDTO> {
  public constructor();
  public constructor(obj?: Partial<InvRegistryDTO>);
  public constructor(obj?: Partial<InvRegistryDTO>) {
    super();
    obj && Object.assign(this, obj);
  }
  id?: string = "";
  warehouseCode?: string = "";
  containerCode?: string = "";
  inventoryCode?: string = "";
  locationCode?: string = "";
  materialCode?: string = "";
  operatorCode?: string = "";
  created?: string = "";
  qty?: number = 0;
  user?: string = "";
  consolidated?: string = "";
  error?: string = "";

  public fromDTO(obj: Partial<InventoryRegistryDTO>): InvRegistryDTO {
    return new InvRegistryDTO({
      id: UUIDGenerator(),
      warehouseCode: obj.warehouseCode ?? "",
      containerCode: obj.containerCode ?? "",
      inventoryCode: obj.inventoryCode ?? "",
      locationCode: obj.locationCode ?? "",
      materialCode: obj.materialCode ?? "",
      operatorCode: obj.operatorCode ?? "",
      created: obj.created ?? "",
      qty: obj.qty ?? 0,
      user: obj.user ?? "",
      consolidated: obj.consolidated ?? "",
      error: obj.error ?? ""
    });
  }

  public toDTO(arg: InvRegistryDTO): InventoryRegistryDTO {
    return {
      id: arg.id,
      warehouseCode: arg.warehouseCode,
      containerCode: arg.containerCode,
      inventoryCode: arg.inventoryCode,
      locationCode: arg.locationCode,
      materialCode: arg.materialCode,
      operatorCode: arg.operatorCode,
      created: arg.created,
      qty: arg.qty,
      user: arg.user,
      consolidated: arg.consolidated,
      error: arg.error
    } as unknown as InvRegistryDTO;
  }
}
