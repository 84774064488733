/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FullUserDTO
 */
export interface FullUserDTO {
    /**
     * 
     * @type {boolean}
     * @memberof FullUserDTO
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FullUserDTO
     */
    companyLicense?: FullUserDTOCompanyLicenseEnum;
    /**
     * 
     * @type {string}
     * @memberof FullUserDTO
     */
    credentialsId?: string;
    /**
     * 
     * @type {string}
     * @memberof FullUserDTO
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FullUserDTO
     */
    enabledNFAEmail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FullUserDTO
     */
    enabledNFAOPT?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FullUserDTO
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof FullUserDTO
     */
    firstSurname?: string;
    /**
     * 
     * @type {string}
     * @memberof FullUserDTO
     */
    id?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof FullUserDTO
     */
    logisticOperationsPermissions?: Set<FullUserDTOLogisticOperationsPermissionsEnum>;
    /**
     * 
     * @type {Set<string>}
     * @memberof FullUserDTO
     */
    masterdataPermissions?: Set<FullUserDTOMasterdataPermissionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof FullUserDTO
     */
    profileId?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof FullUserDTO
     */
    reportsPermissions?: Set<FullUserDTOReportsPermissionsEnum>;
    /**
     * 
     * @type {Set<string>}
     * @memberof FullUserDTO
     */
    roles?: Set<FullUserDTORolesEnum>;
    /**
     * 
     * @type {string}
     * @memberof FullUserDTO
     */
    secondSurname?: string;
    /**
     * 
     * @type {string}
     * @memberof FullUserDTO
     */
    tenantId?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof FullUserDTO
     */
    trackAndTracePermissions?: Set<FullUserDTOTrackAndTracePermissionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof FullUserDTO
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof FullUserDTO
     */
    validated?: FullUserDTOValidatedEnum;
    /**
     * 
     * @type {string}
     * @memberof FullUserDTO
     */
    warehousePolicy?: FullUserDTOWarehousePolicyEnum;
}


/**
 * @export
 */
export const FullUserDTOCompanyLicenseEnum = {
    Im: 'IM',
    Wm: 'WM',
    WmWithContainer: 'WM_WITH_CONTAINER'
} as const;
export type FullUserDTOCompanyLicenseEnum = typeof FullUserDTOCompanyLicenseEnum[keyof typeof FullUserDTOCompanyLicenseEnum];

/**
 * @export
 */
export const FullUserDTOLogisticOperationsPermissionsEnum = {
    InventoryMaterial: 'INVENTORY_MATERIAL',
    MaterialEntry: 'MATERIAL_ENTRY',
    MaterialExit: 'MATERIAL_EXIT'
} as const;
export type FullUserDTOLogisticOperationsPermissionsEnum = typeof FullUserDTOLogisticOperationsPermissionsEnum[keyof typeof FullUserDTOLogisticOperationsPermissionsEnum];

/**
 * @export
 */
export const FullUserDTOMasterdataPermissionsEnum = {
    Company: 'COMPANY',
    Customer: 'CUSTOMER',
    Family: 'FAMILY',
    FamilyType: 'FAMILY_TYPE',
    Location: 'LOCATION',
    Manufacturer: 'MANUFACTURER',
    Material: 'MATERIAL',
    Operator: 'OPERATOR',
    Owner: 'OWNER',
    Supplier: 'SUPPLIER',
    TileTemplate: 'TILE_TEMPLATE',
    User: 'USER',
    Warehouse: 'WAREHOUSE',
    WarehouseViewer: 'WAREHOUSE_VIEWER',
    Zone: 'ZONE'
} as const;
export type FullUserDTOMasterdataPermissionsEnum = typeof FullUserDTOMasterdataPermissionsEnum[keyof typeof FullUserDTOMasterdataPermissionsEnum];

/**
 * @export
 */
export const FullUserDTOReportsPermissionsEnum = {
    InventoryRegistry: 'INVENTORY_REGISTRY',
    InventoryReport: 'INVENTORY_REPORT',
    MovementsReport: 'MOVEMENTS_REPORT',
    RelabelReport: 'RELABEL_REPORT',
    StockReport: 'STOCK_REPORT',
    WarehouseStatus: 'WAREHOUSE_STATUS'
} as const;
export type FullUserDTOReportsPermissionsEnum = typeof FullUserDTOReportsPermissionsEnum[keyof typeof FullUserDTOReportsPermissionsEnum];

/**
 * @export
 */
export const FullUserDTORolesEnum = {
    MobAdmin: 'MOB_ADMIN',
    MobUser: 'MOB_USER',
    PortalAdmin: 'PORTAL_ADMIN',
    PortalUser: 'PORTAL_USER',
    SuperAdmin: 'SUPER_ADMIN'
} as const;
export type FullUserDTORolesEnum = typeof FullUserDTORolesEnum[keyof typeof FullUserDTORolesEnum];

/**
 * @export
 */
export const FullUserDTOTrackAndTracePermissionsEnum = {
    Hierarchy: 'HIERARCHY',
    Product: 'PRODUCT'
} as const;
export type FullUserDTOTrackAndTracePermissionsEnum = typeof FullUserDTOTrackAndTracePermissionsEnum[keyof typeof FullUserDTOTrackAndTracePermissionsEnum];

/**
 * @export
 */
export const FullUserDTOValidatedEnum = {
    Accepted: 'ACCEPTED',
    Denied: 'DENIED',
    Requested: 'REQUESTED'
} as const;
export type FullUserDTOValidatedEnum = typeof FullUserDTOValidatedEnum[keyof typeof FullUserDTOValidatedEnum];

/**
 * @export
 */
export const FullUserDTOWarehousePolicyEnum = {
    Lifo: 'LIFO',
    StrictFifo: 'STRICT_FIFO',
    TimeIntervalFifo: 'TIME_INTERVAL_FIFO'
} as const;
export type FullUserDTOWarehousePolicyEnum = typeof FullUserDTOWarehousePolicyEnum[keyof typeof FullUserDTOWarehousePolicyEnum];


/**
 * Check if a given object implements the FullUserDTO interface.
 */
export function instanceOfFullUserDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FullUserDTOFromJSON(json: any): FullUserDTO {
    return FullUserDTOFromJSONTyped(json, false);
}

export function FullUserDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FullUserDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'companyLicense': !exists(json, 'companyLicense') ? undefined : json['companyLicense'],
        'credentialsId': !exists(json, 'credentialsId') ? undefined : json['credentialsId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'enabledNFAEmail': !exists(json, 'enabledNFAEmail') ? undefined : json['enabledNFAEmail'],
        'enabledNFAOPT': !exists(json, 'enabledNFAOPT') ? undefined : json['enabledNFAOPT'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'firstSurname': !exists(json, 'firstSurname') ? undefined : json['firstSurname'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'logisticOperationsPermissions': !exists(json, 'logisticOperationsPermissions') ? undefined : json['logisticOperationsPermissions'],
        'masterdataPermissions': !exists(json, 'masterdataPermissions') ? undefined : json['masterdataPermissions'],
        'profileId': !exists(json, 'profileId') ? undefined : json['profileId'],
        'reportsPermissions': !exists(json, 'reportsPermissions') ? undefined : json['reportsPermissions'],
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
        'secondSurname': !exists(json, 'secondSurname') ? undefined : json['secondSurname'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'trackAndTracePermissions': !exists(json, 'trackAndTracePermissions') ? undefined : json['trackAndTracePermissions'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'validated': !exists(json, 'validated') ? undefined : json['validated'],
        'warehousePolicy': !exists(json, 'warehousePolicy') ? undefined : json['warehousePolicy'],
    };
}

export function FullUserDTOToJSON(value?: FullUserDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'companyLicense': value.companyLicense,
        'credentialsId': value.credentialsId,
        'email': value.email,
        'enabledNFAEmail': value.enabledNFAEmail,
        'enabledNFAOPT': value.enabledNFAOPT,
        'firstName': value.firstName,
        'firstSurname': value.firstSurname,
        'id': value.id,
        'logisticOperationsPermissions': value.logisticOperationsPermissions === undefined ? undefined : Array.from(value.logisticOperationsPermissions as Set<any>),
        'masterdataPermissions': value.masterdataPermissions === undefined ? undefined : Array.from(value.masterdataPermissions as Set<any>),
        'profileId': value.profileId,
        'reportsPermissions': value.reportsPermissions === undefined ? undefined : Array.from(value.reportsPermissions as Set<any>),
        'roles': value.roles === undefined ? undefined : Array.from(value.roles as Set<any>),
        'secondSurname': value.secondSurname,
        'tenantId': value.tenantId,
        'trackAndTracePermissions': value.trackAndTracePermissions === undefined ? undefined : Array.from(value.trackAndTracePermissions as Set<any>),
        'username': value.username,
        'validated': value.validated,
        'warehousePolicy': value.warehousePolicy,
    };
}

