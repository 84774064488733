import { useResolve } from "@movicoders/di";
import { IZonesEdition3DStore } from "../../stores/zones-edition-3d-store/IZonesEdition3DStore";
import { useZonesEdition3DStore } from "../../stores/zones-edition-3d-store/zones-edition-3d-store";
import { Event, Object3D } from "three";
import { useEffect, useRef } from "react";
import { IEditionStatesStore } from "../../stores/edition-states-store/IEditionStatesStore";
import { useEditionStatesStore } from "../../stores/edition-states-store/edition-states-store";
import { END_MODIFYING_OPTIONS } from "../../constants/end-modifying-options";
import { ThreeEvent } from "@react-three/fiber";
import { IWarehouse3DStore } from "../../stores/warehouse-3d-store/IWarehouse3DStore";
import { useWarehouse3DStore } from "../../stores/warehouse-3d-store/warehouse-3d-store";
import { TileRepository } from "../../../../../infrastructure/repositories/tile-repository";
import { useSnackbar } from "@movicoders/ui";
import { useTranslation } from "react-i18next";
import { tileDTOFrom3D } from "../../types/Tile3D";

export const useZoneModeHandler = () => {
  const { show } = useSnackbar();
  const { t } = useTranslation();
  const {
    getSelectedTilesIds,
    setSelectedTilesIds,
    getSelectedZone,
    setSelectedZone,
    getZonesEditingMode,
    setZonesEditingMode,
    getZones,
    getHoveredZone,
    setHoveredZone
  } = useResolve<IZonesEdition3DStore>(useZonesEdition3DStore);
  const {
    getEndModifyingWH,
    setEndModifyingWH,
    getSelectedMode,
    getModifyingWH,
    checkTemplateTypeById
  } = useResolve<IEditionStatesStore>(useEditionStatesStore);
  const { getWarehouse } = useResolve<IWarehouse3DStore>(useWarehouse3DStore);
  const tileRepository = useRef(useResolve<TileRepository>(TileRepository)).current;

  const handleCleanup = () => {
    setZonesEditingMode("WRITE");
    setSelectedTilesIds([]);
    setSelectedZone(undefined);
    setEndModifyingWH("");
    setHoveredZone("");
  };

  const handleChangeSelectedTiles = (selectedObjects: Object3D<Event>[]) => {
    if (getZonesEditingMode() === "WRITE" && getSelectedZone() !== undefined) {
      setHoveredZone("");
      const newIds = selectedObjects.filter(obj => {
        const zoneId = obj.parent?.userData.tile?.zoneId;
        return (zoneId === undefined || zoneId === getSelectedZone()?.id) && checkTemplateTypeById("STORAGE", obj.parent?.userData.tile?.templateId)
      }).map(tile => tile.parent?.userData.tile?.id);

      const result = new Set(getSelectedTilesIds().concat(newIds));
      if (result.size !== getSelectedTilesIds().length) setSelectedTilesIds(Array.from(result));
    } else if (getZonesEditingMode() === "REMOVE" && getSelectedZone() !== undefined) {
      setHoveredZone("");
      const idsToDelete = selectedObjects.map(tile => tile.parent?.userData.tile?.id);
      const tempTilesIds = [...getSelectedTilesIds()].filter(tid => !idsToDelete.includes(tid));
      if (tempTilesIds.length !== getSelectedTilesIds().length) setSelectedTilesIds(tempTilesIds);
    }
  }

  const handleEndModifyingZones = () => {
    if (getEndModifyingWH() === END_MODIFYING_OPTIONS.SAVE) {
      const oldTilesInZone = [...getWarehouse().tiles ?? []].filter(tile => tile.zoneId === getSelectedZone()?.id);
      const oldTilesInZoneIds = oldTilesInZone.map(tile => tile.id);
      const tilesToEditIds = getSelectedTilesIds().filter(tile => !oldTilesInZoneIds.includes(tile));
      const tilesToEdit = [...getWarehouse().tiles ?? []].filter(tile => tilesToEditIds.includes(tile.id ?? ""))
      const tilesToRemove = oldTilesInZone.filter(tile => !getSelectedTilesIds().includes(tile.id ?? ""));

      const request = tilesToEdit.map(tileTE => {
        tileTE.zoneId = getSelectedZone()?.id;
        return tileTE;
      }).concat(tilesToRemove.map(tileTR => {
        tileTR.zoneId = undefined;
        return tileTR;
      }))
      tileRepository.updateMany({ tileDTO: request.map(tileDTOFrom3D) })
        .catch(() => show(t("viewer.zone.error.code") + ": " + t("viewer.zone.error"), "error"))

      handleCleanup();
    } else if (getEndModifyingWH() === END_MODIFYING_OPTIONS.CANCEL)
      handleCleanup();
  };

  const handleHover = (event: ThreeEvent<PointerEvent>) => {
    if (!getModifyingWH()) {
      const hoveredTile = event.object.parent?.userData.tile;
      if (hoveredTile && checkTemplateTypeById("STORAGE", hoveredTile.templateId)) {
        if (hoveredTile.zoneId) {
          const zoneToShow = getZones().find(z => z.id === hoveredTile.zoneId);
          const newZoneName = zoneToShow?.name ?? "viewer.zone.undefined";
          getHoveredZone() !== newZoneName && setHoveredZone(newZoneName);
        } else
          getHoveredZone() !== "viewer.zone.undefined" && setHoveredZone("viewer.zone.undefined");
      }
    }
  }

  const handlePointerLeave = () => {
    getHoveredZone() !== "" && setHoveredZone("");
  }

  // UseEffect to finish or cancel the edition of zones.
  useEffect(() => {
    if (getEndModifyingWH() !== "" && getSelectedMode() === "ZONES")
      handleEndModifyingZones();
  }, [getEndModifyingWH()]);

  // UseEffect to deselect zone when mode is changed
  useEffect(() => {
    handleCleanup();
  }, [getSelectedMode()]);

  return {
    handleChangeSelectedTiles,
    handleHover,
    handlePointerLeave
  }
}