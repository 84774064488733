import { Theme, useMediaQuery } from "@mui/material";

export const useEntriesMediaQueries = () => {
  const min = 0;
  const max = 1000000;

  const matchesMd = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"), { noSsr: true });
  const matchesSm = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"), { noSsr: true });

  const matchesGridMd = useMediaQuery((theme: Theme) => theme.breakpoints.between("sm", "md"), { noSsr: true });
  const matchesGridSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"), { noSsr: true });

  const getItemGrid = (isMiddle?: boolean) => {
    if (matchesGridSm) {
      return false;
    }
    if (matchesMd && !isMiddle) {
      return false;
    }
    if (matchesGridMd) {
      return true;
    }

    return true;
  };

  return {
    min,
    max,
    matchesSm,
    matchesMd,
    getItemGrid
  };
};
