import { COLORS } from "@theme";
import { Box, Chip, Grid } from "@mui/material";

interface IChips {
  unfolded: boolean;
  displayedOptions: string[];
}

export const ExpandableChips = (props: IChips) => {
  const { unfolded, displayedOptions } = props;

  const handleChipsExpansion = (options: string[]) => {
    if (unfolded) {
      return options.map(value => (
        <Chip key={value} label={value} size="small" color="secondary" variant="outlined" sx={{ color: COLORS.secondary }} />
      ));
    } else {
      const value = options.at(0);
      const numberOfOptions = options.length;
      return (
        <Grid container id="grid-container-multiple-select-chips" display={"flex"}>
          <Grid item id="grid-item-multiple-select-chip" xs={11} marginTop={numberOfOptions > 1 ? "1rem" : "0rem"}>
            <Chip key={value} label={value} size="small" color="secondary" variant="outlined" sx={{ color: COLORS.secondary }} />
          </Grid>
          <Grid item id="grid-item-multiple-select-plus-others" xs={1}>
            {numberOfOptions - 1 > 0 ? <p> +{numberOfOptions - 1}</p> : <></>}
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
          margin: 0.5,
          marginLeft: 0
        }
      }}>
      {handleChipsExpansion(displayedOptions)}
    </Box>
  );
};
