import { useEffect } from "react";
import { useHandlePeriods } from "@hooks/useHandlePeriods";
import { LastPageState } from "@domain/model/LastPageState";
import { GetInventoryReportPaginatedRequest } from "@clients/aggrego-proxy";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "@movicoders/ui";

export const useInventoryReportPeriod = (
  currentPagePersistedState: LastPageState,
  selectedWarehouseCode: string | undefined,
  searchValue: string,
  getInvReportPaginated: (criteria: GetInventoryReportPaginatedRequest) => Promise<void>
) => {
  const { t } = useTranslation();
  const { show } = useSnackbar();
  const { isActivePeriod, sortedPeriods, selectedPeriod, setSelectedPeriod } = useHandlePeriods(selectedWarehouseCode);

  useEffect(() => {
    if (selectedPeriod?.id !== undefined && selectedPeriod?.id !== "") {
      getInvReportPaginated({
        limit: currentPagePersistedState.limit,
        offset: currentPagePersistedState.offset,
        inventoryPeriodId: selectedPeriod?.id ?? "",
        warehouseCode: selectedWarehouseCode,
        customFilterDTO: { ...currentPagePersistedState.filters, searchText: searchValue }
      }).catch(() => {
        show(t("invrep.request.error"), "error");
      });
    }
  }, [selectedPeriod]);

  return {
    sortedPeriods,
    isActivePeriod,
    selectedPeriod,
    setSelectedPeriod
  };
};
