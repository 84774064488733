import "reflect-metadata";
import "./index.css";
import App from "./App";
import i18n from "./i18n";
import theme from "./theme";
import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import { StyledEngineProvider, CssBaseline, ThemeProvider } from "@mui/material";
import { Splash } from "./ui/components/splash/Splash";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <Suspense fallback={<Splash loading={true} />}>
    <I18nextProvider i18n={i18n}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CssBaseline />
            <App />
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </I18nextProvider>
  </Suspense>
);

serviceWorkerRegistration.unregister();

reportWebVitals();
