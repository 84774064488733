import { COLORS } from "@theme";
import { useState } from "react";
import Icon from "../../../icons/icon";
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { IconButton, Menu, MenuItem, Checkbox, Grid, Tooltip } from "@mui/material";

export const ColumnsMenu = (props: {
  onChangeColumnVisibility: (field: string, visible: boolean) => void;
  isFieldVisible: (field: string) => boolean;
  columns: GridColDef[];
  uncontrolledColumns: string[];
}) => {
  const { columns, isFieldVisible, onChangeColumnVisibility, uncontrolledColumns } = props;

  const { t } = useTranslation();

  // Defines if the menu is open or closed and its position.
  const [anchorElColumnMenu, setAnchorElColumnMenu] = useState<null | HTMLElement>(null);
  const openColumnMenu = Boolean(anchorElColumnMenu);

  const handleOpenColumnMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElColumnMenu(event.currentTarget);
  };

  const handleCloseColumnMenu = () => {
    setAnchorElColumnMenu(null);
  };

  return (
    <>
      <Tooltip title={t("table.column.menu.title")}>
        <IconButton id="table-columns-menu-open" onClick={handleOpenColumnMenu} className="table-icon-button">
          <Icon icon="columns" color={COLORS.gray} />
        </IconButton>
      </Tooltip>
      <Menu
        id="column-management-menu"
        aria-labelledby="column-management-menu"
        anchorEl={anchorElColumnMenu}
        open={openColumnMenu}
        onClose={handleCloseColumnMenu}>
        <Grid item px="0.5rem">
          <Grid
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ fontSize: "small", fontWeight: 600, color: COLORS.secondary }}>
            {t("table.column.menu.title")}
            <IconButton onClick={handleCloseColumnMenu}>
              <Icon icon="close" color={COLORS.gray} />
            </IconButton>
          </Grid>
          {columns.map(column => {
            return uncontrolledColumns.includes(column.field) ? null : (
              <MenuItem
                id={`table-columns-menu-${column.field}`}
                value={column.field}
                key={column.field}
                sx={{ display: "flex", fontSize: "small", p: 0, height: "1.5rem", my: "0.25rem" }}
                onClick={() => onChangeColumnVisibility(column.field, !isFieldVisible(column.field))}>
                <Checkbox checked={isFieldVisible(column.field)} sx={{ pl: 0 }} />
                {column.headerName || column.field}
              </MenuItem>
            );
          })}
        </Grid>
      </Menu>
    </>
  );
};
