import { DomainPage, IRepository } from "@movicoders/domain";
import { EventDTO, EventsApi } from "@clients/aggrego-proxy";
import { IImpersonationService } from "@domain/services/IImpersonationService";

export class EventRepository implements IRepository<EventDTO, EventDTO, IEventPageCriteria> {
  constructor(private readonly eventsApi: EventsApi, private readonly impersonationService: IImpersonationService) {}

  get(): Promise<EventDTO[]> {
    throw new Error("Method not implemented.");
  }
  getPaginated(pageCriteria?: IEventPageCriteria | undefined): Promise<DomainPage<EventDTO>> {
    throw new Error("Method not implemented.");
  }
  create(event: EventDTO): Promise<EventDTO> {
    return this.eventsApi.saveEvent({
      eventDTO: event,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }
  update(entity: EventDTO): Promise<EventDTO> {
    throw new Error("Method not implemented.");
  }
  remove(id: string): Promise<void> {
    throw new Error("Method not implemented.");
  }

  getManyById(ids: string[]): Promise<EventDTO[]> {
    return this.eventsApi.getEventByIds({
      requestBody: ids,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }

  getById(id: string): Promise<EventDTO> {
    return this.eventsApi.getEventById({ id, xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? "" });
  }
}

export interface IEventPageCriteria {
  limit: number;
  offset: number;
}
