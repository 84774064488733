export type ToolbarFieldFilter = {
  fieldName: string;
  options: { name: string; value: string | "*"; default: boolean }[];
};

export type ToolbarFilter = {
  field: string;
  value: string | string[];
  isFilterActive: boolean;
};

/**
 * The default options of the active items filter in the toolbar.
 */
export const DefaultToolbarStatusOptions: ToolbarFieldFilter = {
  fieldName: "active",
  options: [
    {
      name: "table.status.all",
      value: "*",
      default: true
    },
    {
      name: "table.status.active",
      value: "true",
      default: false
    },
    {
      name: "table.status.inactive",
      value: "false",
      default: false
    }
  ]
};
