import { COLORS } from "@theme";
import { SxProps, Theme } from "@mui/material";

export const cardStyles = () => {
  const card_style: SxProps<Theme> = {
    boxShadow: "0px 3px 6px #00000029",
    borderLeft: "8px solid transparent",
    borderRadius: "8px",
    height: "100%",
    "&& .title-content": {
      display: "inline-block",
      textOverflow: "ellipsis",
      overflow: "hidden",
      width: "100%"
    },
    "& .title-content": {
      display: "inline-block",
      textOverflow: "ellipsis",
      overflow: "hidden",
      width: "100%"
    },
    "& .title-card-container": {
      display: "flex",
      justifyContent: "space-between",
      padding: "5px 16px 0px 16px",
      paddingBottom: 0
    },
    "& .value-card-container": {
      display: "inline-block",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: "5px 16px 5px 16px",
      width: "100%"
    },
    "& .location-grid": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      paddingRight: "10px",
      alignItems: "flex-end"
    },
    "& .warehouse-bottom-buttons": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingRight: 24,
      paddingLeft: 12,
      "& .select-button": {
        height: "2.25rem",
        fontSize: "0.875rem",
        letterSpacing: "1.25px",
        backgroundColor: "transparent",
        boxShadow: "none",
        borderRadius: 4,
        border: `1px solid ${COLORS.buttonGray}`
      }
    }
  };

  const card_class_deactivate: SxProps<Theme> = {
    "&& span": {
      color: COLORS.gray
    },
    "&& MuiTypography-root": {
      color: COLORS.gray
    },
    "& .location-grid": {
      color: COLORS.gray,
      display: "flex",
      paddingRight: "10px",
      alignItems: "flex-end"
    },
    "&& .title-content": {
      "& .MuiTypography-root": {
        color: COLORS.gray
      }
    }
  };

  const card_class_logistic: SxProps<Theme> = {
    borderLeft: "0px",
    padding: "16px",
    boxShadow: "0px 3px 6px #00000029",
    "& .title-card-container": {
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: 0
    },
    "& .value-card-container": {
      display: "inline-block",
      flexDirection: "column",
      justifyContent: "space-between",
      paddingTop: "5px",
      width: "100%"
    }
  };

  return {
    card_style,
    card_class_deactivate,
    card_class_logistic
  };
};
