import {
  FamilyTypesApi,
  ListFamilyTypesByTenantIdRequest,
  ListFamilyTypesPaginatedRequest
} from "@clients/aggrego-proxy/apis/FamilyTypesApi";
import { DomainPage } from "@movicoders/domain";
import FamilyType from "@domain/model/FamilyType";
import { FamilyTypeDTO } from "@clients/aggrego-proxy";
import { IImpersonationService } from "@domain/services/IImpersonationService";

export class TypeOfFamilyApi {
  constructor(private readonly familyTypesApi: FamilyTypesApi, private readonly impersonationService: IImpersonationService) {}
  async get(): Promise<FamilyType[]> {
    const result: FamilyType[] = [];

    const apiResult = await this.familyTypesApi.listFamilyTypes({
      fullDTO: false,
      status: "ALL",
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
    apiResult.forEach(typeOfFamilyClient => {
      result.push(FamilyType.toTypeOfFamilyModel(typeOfFamilyClient));
    });
    return result;
  }

  async getPaginated(pageCriteria: ListFamilyTypesPaginatedRequest): Promise<DomainPage<FamilyType>> {
    return (await this.familyTypesApi.listFamilyTypesPaginated({
      ...pageCriteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    })) as unknown as Promise<DomainPage<FamilyType>>;
  }

  async getById(id: string): Promise<FamilyType> {
    const result = FamilyType.toTypeOfFamilyModel(
      await this.familyTypesApi.getFamilyTypeById({
        id: id,
        fullDTO: true,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return result;
  }

  async getByTenantId(criteria: ListFamilyTypesByTenantIdRequest): Promise<FamilyType[]> {
    const result: FamilyType[] = [];

    const apiResult = await this.familyTypesApi.listFamilyTypesByTenantId({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });

    apiResult.forEach((clientFamilyTyoe: FamilyTypeDTO) => {
      result.push(FamilyType.toTypeOfFamilyModel(clientFamilyTyoe));
    });
    return result;
  }

  async create(entity: FamilyType): Promise<FamilyType> {
    const result = FamilyType.toTypeOfFamilyModel(
      await this.familyTypesApi.saveFamilyType({
        familyTypeDTO: entity,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return result;
  }

  async update(entity: FamilyType): Promise<FamilyType> {
    const result = FamilyType.toTypeOfFamilyModel(
      await this.familyTypesApi.updateFamilyType({
        familyTypeDTO: entity,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return result;
  }

  async remove(id: string): Promise<void> {
    await this.familyTypesApi.deleteFamilyType({
      id: id,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }
}
