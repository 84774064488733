/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LocationDTO } from './LocationDTO';
import {
    LocationDTOFromJSON,
    LocationDTOFromJSONTyped,
    LocationDTOToJSON,
} from './LocationDTO';

/**
 * 
 * @export
 * @interface TileDTO
 */
export interface TileDTO {
    /**
     * 
     * @type {boolean}
     * @memberof TileDTO
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TileDTO
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof TileDTO
     */
    id?: string;
    /**
     * 
     * @type {Array<LocationDTO>}
     * @memberof TileDTO
     */
    locations?: Array<LocationDTO>;
    /**
     * 
     * @type {string}
     * @memberof TileDTO
     */
    templateId?: string;
    /**
     * 
     * @type {string}
     * @memberof TileDTO
     */
    warehouseId?: string;
    /**
     * 
     * @type {number}
     * @memberof TileDTO
     */
    x?: number;
    /**
     * 
     * @type {number}
     * @memberof TileDTO
     */
    z?: number;
    /**
     * 
     * @type {string}
     * @memberof TileDTO
     */
    zoneId?: string;
}

/**
 * Check if a given object implements the TileDTO interface.
 */
export function instanceOfTileDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TileDTOFromJSON(json: any): TileDTO {
    return TileDTOFromJSONTyped(json, false);
}

export function TileDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TileDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'locations': !exists(json, 'locations') ? undefined : ((json['locations'] as Array<any>).map(LocationDTOFromJSON)),
        'templateId': !exists(json, 'templateId') ? undefined : json['templateId'],
        'warehouseId': !exists(json, 'warehouseId') ? undefined : json['warehouseId'],
        'x': !exists(json, 'x') ? undefined : json['x'],
        'z': !exists(json, 'z') ? undefined : json['z'],
        'zoneId': !exists(json, 'zoneId') ? undefined : json['zoneId'],
    };
}

export function TileDTOToJSON(value?: TileDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'code': value.code,
        'id': value.id,
        'locations': value.locations === undefined ? undefined : ((value.locations as Array<any>).map(LocationDTOToJSON)),
        'templateId': value.templateId,
        'warehouseId': value.warehouseId,
        'x': value.x,
        'z': value.z,
        'zoneId': value.zoneId,
    };
}

