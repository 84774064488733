import { t } from "i18next";
import { IMMaterialEntryDTO, MaterialDTO } from "@clients/aggrego-proxy";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useLogisticAutocompleteViewModel } from "./hooks/useLogisticAutocompleteViewModel";

export const AutocompleteIMEntry = (props: {
  id: string;
  iMMaterial: IMMaterialEntryDTO;
  onChangeMaterial: (value: MaterialDTO | null) => void;
  formErrors: Map<string, boolean>;
}) => {
  const { id, iMMaterial, formErrors, onChangeMaterial } = props;

  const { options, loading, handleSearchInputChange, onChangeAutocomplete, materialPlaceholder } =
    useLogisticAutocompleteViewModel(iMMaterial);

  return (
    <Autocomplete
      id={id}
      value={materialPlaceholder}
      options={options ?? []}
      loading={loading}
      onChange={(event, value, reason) => {
        onChangeMaterial(value);
        onChangeAutocomplete(value ?? {}, reason);
      }}
      disableClearable
      noOptionsText={
        materialPlaceholder.code && materialPlaceholder.code?.length >= 3 && materialPlaceholder !== undefined
          ? t("autocomplete.default.no.options")
          : t("autocomplete.input.material.required")
      }
      renderInput={params => (
        <TextField
          placeholder={t("autocomplete.default.value")}
          name={"type-name"}
          {...params}
          label={t("logistic.material")}
          error={formErrors.get("material-code") as boolean}
          helperText={(formErrors.get("material-code") as boolean) ? t(`material.dialog.empty.autocomplete.text`) : ""}
          className={"blue-border-input"}
          InputProps={{
            ...params.InputProps,
            onChange: handleSearchInputChange,
            endAdornment: (
              <>
                {loading ? <CircularProgress sx={{ ml: "2rem", mb: "0.5rem" }} color="secondary" size={16} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
      getOptionLabel={material => material.code ?? ""}
      renderOption={(props, material) => (
        <li {...props}>
          <div>
            {material.code} - {material.name}
          </div>
        </li>
      )}
      filterOptions={(material, { inputValue }) => {
        const lowerCaseInputValue = inputValue.toLowerCase();
        return material.filter(
          material =>
            (material.code && material.code.toLowerCase().includes(lowerCaseInputValue)) ||
            (material.name && material.name.toLowerCase().includes(lowerCaseInputValue))
        );
      }}
    />
  );
};
