import { useResolve } from "@movicoders/di";
import { useSnackbar } from "@movicoders/ui";
import { MediaRepository } from "@infrastructure/repositories/media-repository";

export const useHandleImage = () => {
  const { show } = useSnackbar();
  const mediaRepository = useResolve<MediaRepository>(MediaRepository);

  const checkUploadedBlobFile = (image: Blob) => {
    return image?.type.includes("image/png") || image?.type.includes("image/jpg") || image?.type.includes("image/jpeg");
  };

  const handleUploadImage = async (image: Blob) => {
    if (checkUploadedBlobFile(image)) {
      const result = await mediaRepository.upload(image ?? new Blob());
      show("Imagen subida correctamente", "success");
      return result.id;
    } else {
      return "";
    }
  };

  return {
    handleUploadImage
  };
};
