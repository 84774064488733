import { User } from "@domain/model/User";
import { useResolve } from "@movicoders/di";
import Material from "@domain/model/Material";
import LocationEntity from "@domain/model/Location";
import { GridValidRowModel } from "@mui/x-data-grid";
import { ContainerDTO, FullUserDTO } from "@clients/aggrego-proxy";
import { useEffect, useState } from "react";
import { LastPageState } from "@domain/model/LastPageState";
import { ILastPageService, LastPageService } from "@domain/services/ILastPageService";
import { DataGridDisplayViewNameEnum } from "../table/config/data-grid-display-view-name-enum";

export const useHandlePagination = (
  mode: "client" | "server",
  initialData: GridValidRowModel[],
  currentData: GridValidRowModel[],
  rowsPerPageOptions: number[],
  handleBackendPagination: (
    limit: number,
    offset: number,
    status: "ALL" | "ACTIVE" | "INACTIVE",
    filters?: object,
    field?: string,
    direction?: "ASC" | "DESC"
  ) => void,
  viewName: DataGridDisplayViewNameEnum
) => {
  const [pageSize, setPageSize] = useState(rowsPerPageOptions[0]);
  const lastPageService = useResolve<ILastPageService>(LastPageService);
  const lastPagePersistedState = lastPageService.persistedState ?? new LastPageState();
  const [currentPage, setCurrentPage] = useState(0);

  const getURIFilters = () => {
    const params = new URLSearchParams(window.location.search);
    const movementReport = params.get("search");

    if (movementReport) {
      const preselectedFilterObject = JSON.parse(decodeURIComponent(movementReport));
      if (viewName === "materials") {
        return (preselectedFilterObject as Material).code;
      }

      if (viewName === "movements") {
        if (Material.isMaterial(preselectedFilterObject)) {
          return (preselectedFilterObject as Material).code;
        } else if (LocationEntity.isLocation(preselectedFilterObject)) {
          return (preselectedFilterObject as LocationEntity).code ?? "";
        } else if (User.isFullUserDTO(preselectedFilterObject as FullUserDTO)) {
          return (preselectedFilterObject as User).username;
        } else if (preselectedFilterObject as ContainerDTO) return preselectedFilterObject.code ?? "";
      }

      if (viewName === "whstatus") {
        if (Material.isMaterial(preselectedFilterObject)) {
          return (preselectedFilterObject as Material).code;
        } else if (LocationEntity.isLocation(preselectedFilterObject)) {
          return (preselectedFilterObject as LocationEntity).code ?? "";
        } else if (User.isFullUserDTO(preselectedFilterObject as FullUserDTO)) {
          return (preselectedFilterObject as User).username;
        } else {
          return (preselectedFilterObject as ContainerDTO).code ?? "";
        }
      }
      if (viewName === "locations") {
        if (LocationEntity.isLocation(preselectedFilterObject)) {
          return (preselectedFilterObject as LocationEntity).code ?? "";
        }
      }
      if (viewName === "users") {
        if (User.isUser(preselectedFilterObject)) {
          return (preselectedFilterObject as User).username;
        }
      }
    }
  };

  const defaultFilter: LastPageState = {
    limit: pageSize,
    offset: currentPage * pageSize,
    filters: { searchText: getURIFilters() },
    status: "ALL",
    fullDTO: true
  };

  const [filter, setFilter] = useState<LastPageState>(defaultFilter);

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (initialData.length === 0 && isFirstRender) {
      setCurrentPage(0);
      setFilter({ ...defaultFilter });
      setIsFirstRender(false);
    } else {
      setFilter(lastPagePersistedState);
    }
  }, [lastPagePersistedState.status, lastPagePersistedState.filters, currentPage]);

  useEffect(() => {
    lastPageService.saveLastPageState({
      ...lastPagePersistedState,
      limit: 10,
      offset: 0,
      filters: { searchText: "" },
      sortDirection: "DESC",
      status: "ALL",
      autocompleteFilterLoading: false,
      autocompleteFilterText: undefined
    });
  }, []);

  useEffect(() => {
    if (mode === "server" && currentPage !== -1) {
      lastPageService.saveLastPageState({ ...lastPagePersistedState, limit: pageSize });
      handleBackendPagination(
        pageSize,
        currentPage * pageSize,
        filter.status,
        filter.filters,
        filter.sortField,
        filter.sortDirection
      );
      lastPageService.saveLastPageState({
        ...lastPagePersistedState,
        limit: pageSize,
        offset: currentPage * pageSize,
        filters: isFirstRender ? { searchText: "" } : lastPagePersistedState.filters,
        status: isFirstRender ? "ALL" : lastPagePersistedState.status,
        sortDirection: isFirstRender ? "DESC" : lastPagePersistedState.sortDirection,
        sortField: isFirstRender ? "" : lastPagePersistedState.sortField,
        autocompleteFilterText: isFirstRender ? undefined : lastPagePersistedState.autocompleteFilterText,
        autocompleteFilterLoading: isFirstRender ? false : lastPagePersistedState.autocompleteFilterLoading
      });
    }
  }, [pageSize, filter]);

  useEffect(() => {
    if (mode === "client") {
      if (currentData.length > 0 && (currentPage + 1) * pageSize >= currentData.length + pageSize) {
        const newPage = currentData.length === 0 ? 0 : Math.ceil(currentData.length / pageSize) - 1;
        setCurrentPage(newPage);
      }
    }
  }, [currentPage, pageSize, mode, currentData]);

  return { pageSize, setPageSize, currentData, currentPage, setCurrentPage };
};
