import { useRef } from "react";
import { useResolve } from "@movicoders/di";
import { useCheckStatus } from "@hooks/useCheckStatus";
import { LastPageState } from "@domain/model/LastPageState";
import { useListDetailPaginatedViewModel } from "@movicoders/ui";
import { ZoneRepository } from "@infrastructure/repositories/zone-repository";
import { useHandleSelectedWarehouse } from "@hooks/useHandleSelectedWarehouse";
import { ILastPageService, LastPageService } from "@domain/services/ILastPageService";

export const useZoneHandleData = () => {
  const {
    data,
    selected: selectedZone,
    loading,
    loadingDetail,
    create,
    fetchOne,
    goToList,
    getPaginated,
    save
  } = useListDetailPaginatedViewModel(ZoneRepository);
  const getZonesPaginated = useRef(getPaginated).current;

  const currentPageService = useResolve<ILastPageService>(LastPageService);
  const currentPagePersistedState = currentPageService.persistedState ?? new LastPageState();

  const { selectedWarehouse } = useHandleSelectedWarehouse();
  const { getCurrentStatus } = useCheckStatus();

  return {
    loading,
    loadingDetail,
    zoneData: data,
    selectedZone,
    selectedWarehouse,
    currentPageService,
    currentPagePersistedState,
    save,
    goToDetail: fetchOne,
    getCurrentStatus,
    getZonesPaginated,
    goToCreate: create,
    goToList
  };
};
