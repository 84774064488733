import { useResolve } from "@movicoders/di";
import { END_MODIFYING_OPTIONS } from "../../../../constants/end-modifying-options";
import { IEditionStatesStore } from "../../../../stores/edition-states-store/IEditionStatesStore";
import { useEditionStatesStore } from "../../../../stores/edition-states-store/edition-states-store";

export const useEndModifyingButtons = () => {
  const {
    getSelectedMode,
    getModifyingWH,
    setModifyingWH,
    setEndModifyingWH,
    getCanPlace
  } = useResolve<IEditionStatesStore>(useEditionStatesStore);

  const handleCancelChanges = () => {
    setModifyingWH(false);
    setEndModifyingWH(END_MODIFYING_OPTIONS.CANCEL);
  };

  const handleSaveChanges = () => {
    setModifyingWH(false);
    setEndModifyingWH(END_MODIFYING_OPTIONS.SAVE);
  };

  return {
    getSelectedMode,
    getModifyingWH,
    getCanPlace,
    handleCancelChanges,
    handleSaveChanges
  };

};
