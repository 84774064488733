import Grid from "@mui/material/Grid";
import { COLORS, MEDIA } from "@theme";
import Icon from "@components/icons/icon";
import { useTranslation } from "react-i18next";
import { trackingReportStyles } from "../tracking-report.Styles";
import { SearchModeEnum, TSearchModeEnum, useProductSearchBar } from "./hooks/useProductSearchBarViewModel";
import { IconButton, MenuItem, Select, TextField, Theme, useMediaQuery } from "@mui/material";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { Dispatch, SetStateAction } from "react";
import { HierarchyParentsFilterEnum, THierarchyParentsFilterEnum } from "../hooks/useProductsViewModel";
interface IProductSearchBar {
  loading: boolean;
  searchMode: TSearchModeEnum;
  setSearchMode: Dispatch<SetStateAction<TSearchModeEnum>>;
  selectedParentFilter: THierarchyParentsFilterEnum;
  setSelectedParentFilter: Dispatch<SetStateAction<THierarchyParentsFilterEnum>>;
}

export const ProductsSearchBar = (props: IProductSearchBar): JSX.Element => {
  const { t } = useTranslation();
  const styles = trackingReportStyles();
  const { loading, searchMode, setSearchMode, selectedParentFilter, setSelectedParentFilter } = props;

  const matchesSm = useMediaQuery((theme: Theme) => theme.breakpoints.up(MEDIA.small), { noSsr: true });

  const {
    firstSearchParameter,
    secondSearchParameter,
    onChangeSelectSearchBy,
    onChangeTextField,
    onChangeDatePicker,
    handleSearch,
    resetSearch,
    firstFieldError,
    secondFieldError,
    onChangeSelectedParentFilter,
    hasVersionLabel
  } = useProductSearchBar(searchMode, setSearchMode, selectedParentFilter, setSelectedParentFilter);

  const renderInput = (id: string, name: string, textValue: string, label: string, order: number) => {
    const style = order === 1 ? styles.track_prod_input_field_left : styles.track_prod_input_field_right;
    const errorObject = order === 1 ? firstFieldError : secondFieldError;
    return (
      <TextField
        id={id}
        name={name}
        value={textValue}
        label={label}
        sx={style}
        error={errorObject.isError}
        helperText={t(errorObject.message)}
        onChange={event => onChangeTextField(event, name, order)}
        InputProps={{
          endAdornment: (
            <>
              {textValue ? (
                <Icon icon={"close"} color="secondary" size={16} style={{ cursor: "pointer" }} onClick={resetSearch} />
              ) : (
                <></>
              )}
            </>
          )
        }}
      />
    );
  };

  const renderDatePicker = (id: string, name: string, value: string, label: string, order: number) => {
    const style = order === 1 ? styles.track_prod_input_field_left : styles.track_prod_input_field_right;
    return (
      <DatePicker
        value={value}
        onChange={value => {
          onChangeDatePicker(value, order);
        }}
        inputFormat="dd/MM/yyyy"
        renderInput={props => (
          <TextField
            {...props}
            id={id}
            name={name}
            label={label}
            sx={style}
            helperText={t(firstFieldError.message)}
            error={firstFieldError.isError}
          />
        )}
      />
    );
  };

  const renderTimeDateInput = (id: string, name: string, textValue: string, label: string, order: number) => {
    const style = order === 1 ? styles.track_prod_input_field_left : styles.track_prod_input_field_right;
    const errorObject = order === 1 ? firstFieldError : secondFieldError;
    return (
      <DateTimePicker
        value={textValue ?? null}
        onChange={value => {
          onChangeDatePicker(value, order);
        }}
        inputFormat="dd/MM/yyyy HH:mm:ss"
        ampm={false}
        disableFuture
        views={["year", "month", "day", "hours", "minutes", "seconds"]}
        renderInput={props => (
          <TextField
            {...props}
            id={id}
            name={name}
            label={label}
            sx={style}
            error={errorObject.isError}
            helperText={t(errorObject.message)}
          />
        )}
        OpenPickerButtonProps={{
          id: "creation-date-open-date-picker"
        }}
      />
    );
  };

  const renderSearchMode = () => {
    switch (searchMode) {
      case SearchModeEnum.Serial:
        return <>{renderInput("search-serial-field", searchMode, firstSearchParameter ?? "", t("products.serial"), 1)}</>;
      case SearchModeEnum.SerialRange:
        return (
          <>
            {renderInput("search-initial-serial-field", searchMode, firstSearchParameter ?? "", t("products.initial.serial"), 1)}
            {renderInput("search-final-serial-field", searchMode, secondSearchParameter ?? "", t("products.final.serial"), 2)}
          </>
        );
      case SearchModeEnum.SerialMaterial:
        return (
          <>
            {renderInput("search-serial-field", searchMode, firstSearchParameter ?? "", t("products.serial"), 1)}
            {renderInput("search-material-field", searchMode, secondSearchParameter ?? "", t("products.material"), 2)}
          </>
        );
      case SearchModeEnum.MaterialBatch:
        return (
          <>
            {renderInput("search-material-field", searchMode, firstSearchParameter ?? "", t("products.material"), 1)}
            {renderInput(
              "search-batch-field",
              searchMode,
              secondSearchParameter ?? "",
              hasVersionLabel ? t("products.version") : t("products.batch"),
              2
            )}
          </>
        );
      case SearchModeEnum.ManufactureOrder:
        return (
          <>
            {renderInput(
              "search-manufacture-order-field",
              searchMode,
              firstSearchParameter ?? "",
              t("products.manufacture.order"),
              1
            )}
          </>
        );
      case SearchModeEnum.ManufacturingDate:
        return (
          <>
            {renderDatePicker(
              "search-manufacturing-date",
              searchMode,
              firstSearchParameter ?? "",
              t("products.manufacturing.date"),
              1
            )}
          </>
        );
      case SearchModeEnum.ManufacturingDateRange:
        return (
          <>
            {renderTimeDateInput(
              "search-manufacturing-init-date-field",
              searchMode,
              firstSearchParameter ?? "",
              t("products.initial.manufacturing.date"),
              1
            )}
            {renderTimeDateInput(
              "search-manufacturing-end-date-field",
              searchMode,
              secondSearchParameter ?? "",
              t("products.end.manufacturing.date"),
              2
            )}
          </>
        );
    }
  };

  return (
    <Grid
      item
      id="product-grid-container"
      display="flex"
      xs={12}
      md={10}
      flexDirection={{ md: "row", xs: "column" }}
      sx={{ mt: "1rem" }}>
      <Select
        id="tracking-simple-select"
        value={searchMode}
        onChange={onChangeSelectSearchBy}
        sx={styles.track_prod_select_styles}>
        <MenuItem value={SearchModeEnum.Serial} id="options-serial">
          {t("products.serial")}
        </MenuItem>
        <MenuItem value={SearchModeEnum.SerialRange} id="options-serial-range">
          {t("products.serial.range")}
        </MenuItem>
        <MenuItem value={SearchModeEnum.SerialMaterial} id="options-serial-material">
          {t("products.serial.material")}
        </MenuItem>
        <MenuItem value={SearchModeEnum.MaterialBatch} id="options-material-batch">
          {hasVersionLabel ? t("products.material.version") : t("products.material.batch")}
        </MenuItem>
        <MenuItem value={SearchModeEnum.ManufactureOrder} id="options-order">
          {t("products.manufacture.order")}
        </MenuItem>
        <MenuItem value={SearchModeEnum.ManufacturingDate} id="options-manufacturing">
          {t("products.manufacturing.date")}
        </MenuItem>
        <MenuItem value={SearchModeEnum.ManufacturingDateRange} id="options-manufacturing-range">
          {t("products.manufacturing.date.range")}
        </MenuItem>
      </Select>
      <Grid
        item
        id="materialbatch-grid-container"
        display="flex"
        xs={12}
        justifyContent="flex-start"
        sx={{ marginTop: !matchesSm ? 1 : 0 }}>
        {renderSearchMode()}
        <IconButton
          id="hierarchy-search-button"
          sx={styles.track_prod_search_icon}
          onClick={() => {
            handleSearch();
          }}>
          <Icon icon="search" color={COLORS.white} />
        </IconButton>
        <IconButton id="hierarchy-search-button" sx={styles.track_prod_clean_icon} onClick={resetSearch} disabled={loading}>
          <Icon icon="close" color={COLORS.white} />
        </IconButton>
      </Grid>

      <Grid item id="parent-select-grid-item" sx={{ marginTop: !matchesSm ? 1 : 0, marginLeft: matchesSm ? 1 : 0 }}>
        <Select
          id="table-select-status"
          className="table-select"
          disabled={searchMode === SearchModeEnum.MaterialBatch}
          onChange={event => {
            onChangeSelectedParentFilter(event);
          }}
          value={selectedParentFilter}
          size="small"
          color="secondary"
          sx={styles.track_prod_select_styles}
          fullWidth>
          <MenuItem value={HierarchyParentsFilterEnum.All} id="options-hierarchy-parents-all">
            {t("products.hierarchy.parents.all")}
          </MenuItem>
          <MenuItem value={HierarchyParentsFilterEnum.ParentsOnly} id="options-hierarchy-parents-only">
            {t("products.hierarchy.parents.only")}
          </MenuItem>
        </Select>
      </Grid>
    </Grid>
  );
};
