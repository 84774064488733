import { User } from "@domain/model/User";
import { useUserSave } from "./useUserSave";
import { useCheckStatus } from "@hooks/useCheckStatus";
import { useUserPagination } from "./useUserPagination";
import { useUserHandleData } from "./useUserHandleData";
import { useUserGetDataByUrl } from "./useUserGetDataByUrl";
import { useUserActiveStatus } from "./useUserActiveStatus";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { useResolve } from "@movicoders/di";

export const useUserListViewModel = () => {
  const settingsService = useResolve<ISettingsService>(SettingsService);

  const {
    userData,
    selectedUser,
    loading,
    loadingDetail,
    impersonationService,
    currentPagePersistedState,
    getUsersPaginated,
    save,
    goToCreate,
    goToDetail,
    goToList
  } = useUserHandleData();
  const { checkUserRoleUseCase, getCurrentStatus } = useCheckStatus();

  const { isActivating, selectedUser4Status, handleOpenToggleActiveStatusDialog, toggleUserStatus, onCloseDialog } =
    useUserActiveStatus(currentPagePersistedState, impersonationService, getUsersPaginated, getCurrentStatus);

  const { handleEdit, handleSave } = useUserSave(
    currentPagePersistedState,
    selectedUser as User,
    impersonationService,
    goToDetail,
    save,
    goToList,
    getCurrentStatus
  );
  const { handlePagination } = useUserPagination(getUsersPaginated, getCurrentStatus);

  const { searchBarFilter } = useUserGetDataByUrl();

  return {
    userData,
    selectedUser4Status,
    selectedUser,
    isActivating,
    loading,
    loadingDetail,
    searchBarFilter,
    toggleUserStatus,
    onCloseDialog,
    goToCreate,
    goToList,
    handleSave,
    handleEdit,
    handlePagination,
    handleOpenToggleActiveStatusDialog,
    checkUserRoleUseCase,
    settingsService
  };
};
