import { COLORS } from "@theme";
import { Grid, Divider, Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridValidRowModel } from "@mui/x-data-grid";
import { OutletContainer } from "@components/outlet-container/outlet-container";
import { DataGridDisplay } from "@components/data-grid-display/data-grid-display";
import { LoadingSpinnerMasterView } from "@components/loading-circular-progress/loading-spinner-master-view";
import { useRelabelViewModel } from "./hooks/viewmodel/useRelabelReportViewModel";
import { relabelStyles } from "./relabel-styles";
import { relabelColumns } from "./relabel-report-columns";
import { ReportEnum } from "@pages/settings/settings-interfaces";
import Icon from "@components/icons/icon";

export const RelabelReportView = () => {
  const { t } = useTranslation();
  const styles = relabelStyles;
  const matchesSM = useMediaQuery((theme: Theme) => theme.breakpoints.up(500), { noSsr: true });

  const { relabelDataPaginated, loading, columnsByMode, search, settingsService } = useRelabelViewModel();

  return (
    <>
      <OutletContainer>
        <Grid container id="first-grid-container" direction="column" mb={1}>
          <Grid item>
            <Grid container wrap="nowrap" spacing={{ xs: "1rem" }} id="second-grid-container">
              <Grid item alignSelf="center">
                <Icon icon="sync" color={COLORS.primary} />
              </Grid>
              <Grid item sx={{ color: COLORS.secondary, fontSize: "x-large", fontWeight: 600 }} id="report-title">
                {t("relabel-title")}
              </Grid>
            </Grid>
            <Divider sx={styles.title_divider} />
          </Grid>

          {settingsService.persistedState?.reportsPermissions.includes(ReportEnum.RelabelReport) && (
            <DataGridDisplay
              data={(relabelDataPaginated?.content as GridValidRowModel[]) ?? []}
              columns={relabelColumns(t, matchesSM)}
              mode="server"
              viewName={"movements"}
              rowId={(row: GridValidRowModel) => row["id"]}
              handleBackendPagination={(
                limit: number,
                offset: number,
                status?: "ALL" | "ACTIVE" | "INACTIVE" | undefined,
                filters?: object | undefined
              ) => {
                search(limit, offset, filters);
              }}
              hideColumnsByField={columnsByMode}
              backendTotalElements={relabelDataPaginated?.totalElements ?? 0}
              toolbarProps={{
                disableColumnsVisibilityByField: [...columnsByMode, "actions"],
                disableFilterByFields: [...columnsByMode, "actions"],
                disableFieldsInDownload: [...columnsByMode, "actions"],
                isActiveFilterHidden: true
              }}
            />
          )}
        </Grid>
      </OutletContainer>
      <LoadingSpinnerMasterView loading={loading} />
    </>
  );
};
