import { useState } from "react";
import { LastPageState } from "@domain/model/LastPageState";
import { IImpersonationService } from "@domain/services/IImpersonationService";
import { ListWarehousesPaginatedRequest } from "@clients/aggrego-proxy/apis/WarehousesApi";

export const useWarehousePagination = (
  currentPagePersistedState: LastPageState,
  warehouseGetPaginated: (criteria: ListWarehousesPaginatedRequest) => Promise<void>,
  getCurrentStatus: (status: "ALL" | "ACTIVE" | "INACTIVE") => boolean | undefined,
  currentImpersonationService: IImpersonationService
) => {
  const DEBOUNCE_TIME = 500;
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);

  const handlePagination = (limit: number, offset: number, status?: "ALL" | "ACTIVE" | "INACTIVE", filters?: object) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    const newDebounceTimer = setTimeout(() => {
      warehouseGetPaginated({
        limit: limit,
        offset: offset,
        fullDTO: false,
        customWarehouseFilter: {
          ...filters,
          tenantId: currentImpersonationService.persistedState?.impersonatedTenant ?? "",
          status: getCurrentStatus(currentPagePersistedState.status)
        }
      });
    }, DEBOUNCE_TIME);
    setDebounceTimer(newDebounceTimer);
  };

  return {
    handlePagination
  };
};
