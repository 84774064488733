/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HistoricalEventDTO
 */
export interface HistoricalEventDTO {
    /**
     * 
     * @type {string}
     * @memberof HistoricalEventDTO
     */
    delegatedFrom?: string;
    /**
     * 
     * @type {Date}
     * @memberof HistoricalEventDTO
     */
    eventDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof HistoricalEventDTO
     */
    eventId?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoricalEventDTO
     */
    eventType?: HistoricalEventDTOEventTypeEnum;
}


/**
 * @export
 */
export const HistoricalEventDTOEventTypeEnum = {
    Aggregate: 'AGGREGATE',
    Create: 'CREATE',
    Destroy: 'DESTROY',
    Disaggregate: 'DISAGGREGATE',
    Reception: 'RECEPTION',
    Shipment: 'SHIPMENT'
} as const;
export type HistoricalEventDTOEventTypeEnum = typeof HistoricalEventDTOEventTypeEnum[keyof typeof HistoricalEventDTOEventTypeEnum];


/**
 * Check if a given object implements the HistoricalEventDTO interface.
 */
export function instanceOfHistoricalEventDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HistoricalEventDTOFromJSON(json: any): HistoricalEventDTO {
    return HistoricalEventDTOFromJSONTyped(json, false);
}

export function HistoricalEventDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): HistoricalEventDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'delegatedFrom': !exists(json, 'delegatedFrom') ? undefined : json['delegatedFrom'],
        'eventDate': !exists(json, 'eventDate') ? undefined : (new Date(json['eventDate'])),
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'eventType': !exists(json, 'eventType') ? undefined : json['eventType'],
    };
}

export function HistoricalEventDTOToJSON(value?: HistoricalEventDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delegatedFrom': value.delegatedFrom,
        'eventDate': value.eventDate === undefined ? undefined : (value.eventDate.toISOString()),
        'eventId': value.eventId,
        'eventType': value.eventType,
    };
}

