import { DTOConvertibleEntity } from "./GenericPage";
import { StatusReportDTO } from "@clients/aggrego-proxy";

/**
 * Aggrego Logistic Operations service (dev)
 * Warehouse status report
 */
export class WarehouseStatusDTO extends DTOConvertibleEntity<StatusReportDTO, WarehouseStatusDTO> {
  public constructor();
  public constructor(obj?: Partial<WarehouseStatusDTO>);
  public constructor(obj?: Partial<WarehouseStatusDTO>) {
    super();
    obj && Object.assign(this, obj);
  }
  container?: string = "";
  date?: Date = new Date();
  family?: Array<string> = [];
  location?: string = "";
  material?: string = "";
  description?: string = "";
  operatorCode?: string = "";
  qty?: number = 0;
  uom?: string = "";
  user?: string = "";

  public fromDTO(obj: Partial<StatusReportDTO>): WarehouseStatusDTO {
    return new WarehouseStatusDTO({
      container: obj.container ?? "",
      date: new Date(obj.date ?? 0),
      family: obj.family ?? [],
      location: obj.location ?? "",
      material: obj.material ?? "",
      description: obj.description ?? "",
      qty: obj.qty ?? 0,
      uom: obj.uom ?? "",
      user: obj.user ?? "",
      operatorCode: obj.operatorCode ?? ""
    });
  }

  public toDTO(arg: WarehouseStatusDTO): StatusReportDTO {
    return {
      container: arg.container,
      date: arg.date,
      family: arg.family,
      location: arg.location,
      material: arg.material,
      description: arg.description,
      operatorCode: arg.operatorCode,
      qty: arg.qty,
      uom: arg.uom,
      user: arg.user
    } as unknown as WarehouseStatusDTO;
  }
}
