import "./video-styles.css";
import LoginCard from "./components/login-card";
import { loginViewStyles } from "./login-view-styles";
import { Trans, useTranslation } from "react-i18next";
import { useLoginViewModel } from "./hooks/useLoginViewModel";
import RecoverPasswordCard from "./components/recover-password-card";
import { Grid, Link, Theme, Typography, useMediaQuery } from "@mui/material";

const LoginView = () => {
  const { t } = useTranslation();
  const styles = loginViewStyles();
  const { open, handleClose, handleOpen } = useLoginViewModel();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"), { noSsr: true });

  return (
    <Grid container sx={styles.full_height} zIndex={5} position="absolute" justifyContent="flex-end">
      {matches && (
        <Grid
          item
          container
          alignItems="flex-end"
          justifyContent="flex-end"
          position="absolute"
          left={0}
          bottom={0}
          sm={6}
          sx={styles.move_transition}
          zIndex={1}>
          <img src="assets/images/background-login.jpg" alt="Aggrego" className="video" />

          <Typography variant="video_text">
            <Trans t={t}>{t("login.warehouse_operations")}</Trans>
          </Typography>
        </Grid>
      )}
      <Grid
        data-testid="login-grid"
        item
        container
        spacing={0}
        paddingX="1rem"
        direction="row"
        alignItems="center"
        justifyContent="center"
        alignContent="center"
        md={open ? 12 : 6}
        sx={{ ...styles.move_transition, ...styles.background_gradient }}
        zIndex={2}>
        <Grid item justifyContent="center">
          <img
            src="assets/images/logo-expanded-white.svg"
            alt="aggrego-logo"
            style={{ imageRendering: "-moz-initial", width: "100%", maxWidth: "278px" }}
          />
        </Grid>

        <Grid item container justifyContent="center" xs={12}>
          <LoginCard onClick={handleOpen} open={open} />
          <RecoverPasswordCard open={open} handleClose={handleClose} />
        </Grid>
        <Link
          href="https://movicoders.com"
          target="_blank"
          underline="hover"
          rel="noreferrer"
          variant="copyright"
          id="movicoders-copyright">
          <Trans>{t("copyright.message", { year: new Date().getUTCFullYear() })}</Trans>
        </Link>
      </Grid>
    </Grid>
  );
};

export default LoginView;
