import Family from "@domain/model/Family";
import { useResolve } from "@movicoders/di";
import { useEffect, useRef, useState } from "react";
import { LastPageState } from "@domain/model/LastPageState";
import { useListDetailPaginatedViewModel } from "@movicoders/ui";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { MasterDataEnum } from "@pages/settings/settings-interfaces";
import { MaterialRepository } from "@infrastructure/repositories/material-repository";
import { ILastPageService, LastPageService } from "@domain/services/ILastPageService";
import { FindFamiliesByTenantUseCase } from "@application/master-data/families/find-families-by-tenant-use-case";

export const useMaterialHandleData = () => {
  const {
    selected: selectedMaterial,
    save,
    create,
    fetchOne,
    goToList,
    loading,
    loadingDetail,
    data,
    getPaginated
  } = useListDetailPaginatedViewModel(MaterialRepository);
  const getMaterialPaginated = useRef(getPaginated).current;

  const settingsService = useResolve<ISettingsService>(SettingsService);
  const currentPageService = useResolve<ILastPageService>(LastPageService);
  const currentPagePersistedState = currentPageService.persistedState ?? new LastPageState();

  const findFamiliesByTenantUseCase = useResolve(FindFamiliesByTenantUseCase);
  const [families, setFamilies] = useState<Family[]>([]);

  const getCurrentStatus = (status: "ACTIVE" | "INACTIVE" | "ALL"): boolean | undefined => {
    const STATUS_ACTIVE = true;
    const STATUS_INACTIVE = false;

    if (status === "ACTIVE") {
      return STATUS_ACTIVE;
    }
    if (status === "INACTIVE") {
      return STATUS_INACTIVE;
    } else {
      return undefined;
    }
  };

  useEffect(() => {
    if (settingsService.persistedState?.masterdataPermissions.includes(MasterDataEnum.Materials)) {
      findFamiliesByTenantUseCase.execute("ALL").then(result => {
        setFamilies(result);
      });
    }
  }, [settingsService.persistedState]);

  return {
    currentPageService,
    currentPagePersistedState,
    loading,
    loadingDetail,
    families,
    materialData: data,
    selectedMaterial,
    save,
    goToDetail: fetchOne,
    goToCreate: create,
    goToList,
    getCurrentStatus,
    getMaterialData: getMaterialPaginated
  };
};
