import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Product } from "@domain/model/Product";
import EditChildDialog from "./EditChildDialog";
import { Grid, Typography } from "@mui/material";
import DeleteChildDialog from "./DeleteChildDialog";
import { hierarchyCreationStyles } from "../hierarchy-creation-styles";
import { TitlePopover } from "@components/master-data-view/card/components/title/title-popover";

const VIEW_I18N = "hierarchy.creation.children.form.label.";

export interface IAlreadyAddedChildren {
  addedChildren: Product[];
  updateChild: (child: Product) => void;
  deleteChild: (child: Product) => void;
  hasVersionLabel: boolean;
}

export const renderTextInput = (field: keyof Product, child: Product, width: number, label: string) => {
  return (
    <Grid item xs={12} md={6} lg={width}>
      <Typography variant="cardlabel" textAlign="left">
        {label}
      </Typography>
      <Typography key={`key-${child[field]}`} variant="cardvalue" data-cy={`value`}>
        {(child[field] as string) !== "" ? (child[field] as string) : "-"}
      </Typography>
    </Grid>
  );
};

export const AddedChildren = ({ addedChildren, updateChild, deleteChild, hasVersionLabel }: IAlreadyAddedChildren) => {
  const styles = hierarchyCreationStyles();
  const { t } = useTranslation();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [editingChild, setEditingChild] = useState<Product>();

  return (
    <Grid container style={{ padding: 2, paddingBottom: 10 }} display="flex" justifyContent="flex-end">
      <Grid item xs={10} display="flex" justifyContent="flex-start" sx={{ mb: "1rem" }}>
        <Typography variant="cardvalue" data-cy={`value`}>
          {addedChildren.length} {t("hierarchy.creation.children.form.element" + (addedChildren.length === 1 ? "" : ".plural"))}{" "}
          {t("hierarchy.creation.children.form.added" + (addedChildren.length === 1 ? "" : ".plural"))}
        </Typography>
      </Grid>

      {addedChildren.map(child => {
        return (
          <Grid
            key={child.batch + child.materialCode + child.serialNumber}
            item
            xs={10}
            display="flex"
            justifyContent="flex-end"
            sx={{
              borderLeft: "8px solid transparent",
              ...styles.hierarchy_children_container
            }}>
            <Grid container>
              <Grid item xs={10}>
                <Grid container spacing={1}>
                  {renderTextInput("materialCode", child, 5, t(VIEW_I18N + "materialCode"))}
                  {renderTextInput("qty", child, 3, t(VIEW_I18N + "qty"))}
                  {renderTextInput("uom", child, 3, t(VIEW_I18N + "uom"))}
                  {renderTextInput("serialNumber", child, 5, t(VIEW_I18N + "serialNumber"))}
                  {renderTextInput("batch", child, 6, hasVersionLabel ? t(VIEW_I18N + "version") : t(VIEW_I18N + "batch"))}
                </Grid>
              </Grid>
              <Grid item xs={2} display={"flex"} justifyContent={"flex-end"}>
                <TitlePopover
                  handleDelete={() => {
                    setOpenDeleteDialog(true);
                    setEditingChild(child);
                  }}
                  handleEdit={() => {
                    setEditingChild(child);
                    setOpenEditDialog(true);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        );
      })}

      {openEditDialog && (
        <EditChildDialog
          onSave={child => {
            updateChild(child);
            setOpenEditDialog(false);
            setEditingChild(undefined);
          }}
          onClose={() => {
            setOpenEditDialog(false);
            setEditingChild(undefined);
          }}
          child={editingChild ?? new Product()}
          hasVersionLabel={hasVersionLabel}
        />
      )}

      {openDeleteDialog && (
        <DeleteChildDialog
          open={openDeleteDialog}
          onDelete={child => {
            deleteChild(child);
            setOpenDeleteDialog(false);
          }}
          onClose={() => setOpenDeleteDialog(false)}
          child={editingChild ?? new Product()}
        />
      )}
    </Grid>
  );
};
