import { useResolve } from "@movicoders/di";
import { IDialogsStatesStore } from "../../../stores/dialogs-states-store/IDialogsStatesStore";
import { useDialogsStatesStore } from "../../../stores/dialogs-states-store/dialogs-states-store";
import { useEffect, useState } from "react";
import { useSnackbar } from "@movicoders/ui";
import { useReplaceOrKeepDialogHelpers } from "./useReplaceOrKeepDialogHelpers";
import { Tile3D } from "../../../types/Tile3D";
import { IEditionStatesStore } from "../../../stores/edition-states-store/IEditionStatesStore";
import { useEditionStatesStore } from "../../../stores/edition-states-store/edition-states-store";
import { ICommon3DObjectsStore } from "../../../stores/common-3d-objects-store/ICommon3DObjectsStore";
import { useCommon3DObjectsStore } from "../../../stores/common-3d-objects-store/common-3d-objects-store";
import { Location3D } from "../../../types/Location3D";
import { IWarehouse3DStore } from "../../../stores/warehouse-3d-store/IWarehouse3DStore";
import { useWarehouse3DStore } from "../../../stores/warehouse-3d-store/warehouse-3d-store";

interface ITilesPos {
  x: number,
  z: number
}

interface INewOldPairs {
  new?: string,
  old?: string
}

export interface ITilesComparison {
  oldTileLocations: Location3D[],
  newTileLocations: Location3D[],
  tilesPos: ITilesPos,
  keep: boolean,
  error: boolean,
  templates: INewOldPairs,
  zones: INewOldPairs
}

export const useReplaceOrKeepEditDialog = () => {
  const {
    getReplaceOrKeepDialogOpen,
    setReplaceOrKeepDialogOpen,
    getReplaceOrKeepDialogProps
  } = useResolve<IDialogsStatesStore>(useDialogsStatesStore);
  const {
    getSelectedMode,
    setModifyingWH,
    setEndModifyingWH,
    setOnSaveLoading
  } = useResolve<IEditionStatesStore>(useEditionStatesStore);
  const {
    setTilesBeingModified,
    getPivotControlsContent,
    getTilesBeingModified
  } = useResolve<ICommon3DObjectsStore>(useCommon3DObjectsStore);
  const { getWarehouse } = useResolve<IWarehouse3DStore>(useWarehouse3DStore);
  const {
    getItemToKeep,
    getItemsToSave,
    handleUpdateTiles
  } = useReplaceOrKeepDialogHelpers();

  const { show } = useSnackbar();

  const [comparedTiles, setComparedTiles] = useState<ITilesComparison[]>([]);
  const [expandedItem, setExpandedItem] = useState<ITilesPos>({ x: 0, z: 0 });

  const getIsOpen = () => getReplaceOrKeepDialogOpen();

  const handleClose = () => {
    // When crossmoving tiles is handled and the page is reloaded, the dialog needs to be cleaned.
    if (getSelectedMode() === "MOVE" && getTilesBeingModified().length > 0) {
      getTilesBeingModified().forEach(tile => {
        if (tile?.userData?.firstPosition !== undefined) {
          tile.position.set(tile.userData.firstPosition[0], tile.position.y, tile.userData.firstPosition[1]);
        }
      });
      setTilesBeingModified([]);
      setEndModifyingWH("");
      setModifyingWH(false);
      getPivotControlsContent().children = [];
    }
    setReplaceOrKeepDialogOpen(false)
    setOnSaveLoading(false);
  };

  const assignWarehouseIdToLocations = (locationsList: Location3D[] | undefined): Location3D[] => {
    return [...(locationsList ?? [])].map(location => {
      location.warehouseId = getWarehouse().id;
      return location;
    })
  };

  const loadComparedTiles = () => {
    setExpandedItem({ x: 0, z: 0 });
    const { newTiles, tilesToReplace, movingIdPairs } = getReplaceOrKeepDialogProps();
    const tempComparedTiles: ITilesComparison[] = [];

    if (getSelectedMode() !== "MOVE" && movingIdPairs === undefined) {
      newTiles.forEach((nTile) => {
        const oldTile = tilesToReplace.find(ttr => ttr.x === nTile.x && ttr.z === nTile.z);
        const newTileLocations = assignWarehouseIdToLocations(nTile.locations ?? []);
        const oldTileLocations = assignWarehouseIdToLocations(oldTile?.locations ?? []);
        tempComparedTiles.push({
          newTileLocations,
          oldTileLocations,
          tilesPos: { x: nTile.x ?? 0, z: nTile.z ?? 0 },
          keep: false,
          error: oldTile === undefined || nTile.x === undefined || nTile.z === undefined,
          templates: { new: nTile.templateId, old: oldTile?.templateId },
          zones: { new: nTile.zoneId, old: oldTile?.zoneId }
        })
      })
    } else {
      movingIdPairs?.forEach((pair) => {
        const oldTile = tilesToReplace.find(t => t.id === pair.oldTileId);
        const movedTile = newTiles.find(t => t.id === pair.newTileId);
        const newTileLocations = assignWarehouseIdToLocations(movedTile?.locations ?? []);
        const oldTileLocations = assignWarehouseIdToLocations(oldTile?.locations ?? []);
        tempComparedTiles.push({
          newTileLocations,
          oldTileLocations,
          tilesPos: { x: oldTile?.x ?? 0, z: oldTile?.z ?? 0 },
          keep: false,
          error: oldTile === undefined || movedTile === undefined || oldTile?.x === undefined || oldTile?.z === undefined,
          templates: { new: movedTile?.templateId, old: oldTile?.templateId },
          zones: { new: movedTile?.zoneId, old: oldTile?.zoneId }
        })
      })
    }

    setComparedTiles(tempComparedTiles);
  };

  const handleChangeKeep = (tPos: ITilesPos) => {
    const tempComparedTiles = [...comparedTiles];
    const item = tempComparedTiles.find(itm => itm.tilesPos === tPos);
    if (item) {
      tempComparedTiles[tempComparedTiles.indexOf(item)].keep = !item.keep;
      setComparedTiles(tempComparedTiles);
    } else {
      show("internal.error", "error");
    }
  }

  const handleChangeExpanded = (newItm: ITilesPos) => {
    if (expandedItem === newItm)
      setExpandedItem({ x: 0, z: 0 });
    else
      setExpandedItem(newItm);
  }

  const handleSubmit = async () => {
    const disabledItems: Tile3D[] = [];
    const itemsToSave = comparedTiles.map((itm) => {
      if (itm.keep)
        return getItemToKeep(itm);
      else {
        const { disabledTile, tileToSave } = getItemsToSave(itm);
        disabledTile && disabledItems.push(disabledTile);
        return tileToSave;
      }
    });

    let error = false;
    try {
      const success = await handleUpdateTiles(itemsToSave.concat(disabledItems).filter(item => item !== undefined) as Tile3D[])
      error = !success;
    } catch {
      error = true;
    } finally {
      if (error)
        show("creating.unknown", "error");

      if (getSelectedMode() === "MOVE") {
        setTilesBeingModified([]);
        setEndModifyingWH("");
        setModifyingWH(false);
        getPivotControlsContent().children = [];
      }
      setOnSaveLoading(false);
      handleClose()
      if (getSelectedMode() === "VIEW") window.location.reload();
    }
  }

  useEffect(loadComparedTiles, [getReplaceOrKeepDialogProps()])

  return {
    getIsOpen,
    handleClose,
    comparedTiles,
    handleChangeKeep,
    expandedItem,
    handleChangeExpanded,
    handleSubmit
  }
};
