import { useResolve } from "@movicoders/di";
import Material from "@domain/model/Material";
import { useMaterialSave } from "./useMaterialSave";
import { useMaterialPagination } from "./useMaterialPagination";
import { useMaterialHandleData } from "./useMaterialHandleData";
import { useMaterialGetDataByUrl } from "./useMaterialGetDataByUrl";
import { useMaterialActiveStatus } from "./useMaterialActiveStatus";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";

export const useMaterialViewModel = () => {
  const settingsService = useResolve<ISettingsService>(SettingsService);

  const {
    currentPagePersistedState,
    currentPageService,
    materialData,
    selectedMaterial,
    families,
    loading,
    loadingDetail,
    goToCreate,
    goToDetail,
    goToList,
    save,
    getMaterialData,
    getCurrentStatus
  } = useMaterialHandleData();

  const { handleEdit, handleSave } = useMaterialSave(
    selectedMaterial as Material,
    currentPagePersistedState,
    save,
    goToDetail,
    goToList
  );

  const { searchBarFilter } = useMaterialGetDataByUrl();

  const { handlePagination, sortByFieldBackend } = useMaterialPagination(
    currentPageService,
    currentPagePersistedState,
    materialData?.content as Material[],
    getCurrentStatus,
    getMaterialData
  );

  const { isActivating, material4Activation, handleActiveConfirm, onCloseActive, handleMaterialStatus } = useMaterialActiveStatus(
    currentPagePersistedState,
    save,
    getMaterialData,
    getCurrentStatus
  );

  return {
    loading,
    loadingDetail,
    searchBarFilter,
    materialData,
    selectedMaterial,
    isActivating,
    material4Activation,
    families,
    goToCreate,
    goToList,
    sortByFieldBackend,
    onCloseActive,
    handleActiveConfirm,
    handleSave,
    handleEdit,
    handleMaterialStatus,
    handlePagination,
    settingsService
  };
};
