import { COLORS } from "@theme";
import { SxProps, Theme } from "@mui/material";

export const dataGridDisplayFilterStyles = () => {
  const table_input: SxProps<Theme> = {
    height: "2.5rem",
    fieldset: { height: "2.5rem", marginTop: "0.3rem" },
    label: { lineHeight: "1.5rem" },
    "div:hover": {
      fieldset: {
        borderColor: COLORS.secondary,
        marginTop: "0.3rem"
      }
    }
  };

  const table_filter_container: SxProps<Theme> = {
    padding: "1rem",
    fontSize: "0.75rem",
    fontWeight: 600,
    color: COLORS.secondary,
    rowGap: "1rem"
  };

  const table_filter_select: SxProps<Theme> = {
    width: "100%",
    height: "2.5rem",
    fontSize: "small",
    fieldset: {
      legend: {
        transform: "translate(-10px, -18px)",
        overflow: "visible",
        color: COLORS.secondary,
        span: {
          opacity: "initial"
        }
      }
    }
  };

  const select_variable_height: SxProps<Theme> = {
    width: "100%",
    color: COLORS.grey,
    backgroundColor: "white",
    height: "2.5rem",
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: COLORS.secondary
    },
    fieldset: {
      borderColor: COLORS.secondary
    },
    ul: {
      paddingTop: 0,
      paddingBottom: 0
    }
  };

  return {
    table_input,
    table_filter_container,
    table_filter_select,
    select_variable_height
  };
};
