import { COLORS } from "@theme";
import { CircularProgress, Grid } from "@mui/material";
import Icon from "@components/icons/icon";
import { useTranslation } from "react-i18next";
import { DomainPage } from "@movicoders/domain";
import LocationEntity from "@domain/model/Location";
import { useCheckStatus } from "@hooks/useCheckStatus";
import { LocationsDetailView } from "./locations-detail-view";
import LocationsViewTable from "./view-components/locations-view-table";
import { MasterDataTitle } from "@components/master-data-view/MasterDataTitle";
import { useHandleSelectedWarehouse } from "@hooks/useHandleSelectedWarehouse";
import { OutletContainer } from "@components/outlet-container/outlet-container";
import { useLocationViewModel } from "./hooks/view-model/useLocationListViewModel";
import { LocationActivationDialog } from "./components/dialogs/LocationActivationDialog";
import { LoadingSpinnerMasterView } from "@components/loading-circular-progress/loading-spinner-master-view";
import { MasterDataEnum } from "@pages/settings/settings-interfaces";
import { PersistedAlert } from "@components/persistedAlert/PersistedAlert";

export const LocationsMasterView = () => {
  const { t } = useTranslation();
  const {
    goToCreate,
    goToList,
    handleActiveConfirm,
    handleEdit,
    handleLocationStatus,
    isActivating,
    activeLocation,
    onCloseActive,
    handleSave,
    selectedLocation,
    loading,
    loadingDetail,
    tilesByWarehouse,
    zonesByWarehouse,
    handlePagination,
    locationData,
    searchBarFilter,
    sortByFieldBackend,
    settingsService
  } = useLocationViewModel();

  const { handleStatusFilter } = useCheckStatus();
  const { selectedWarehouse } = useHandleSelectedWarehouse();

  return (
    <>
      {settingsService.persistedState?.masterdataPermissions.includes(MasterDataEnum.Locations) ? (
        <>
          <OutletContainer>
            <Grid container display="flex" flexDirection="column" id="container-location-cards">
              <MasterDataTitle
                icon={<Icon icon="location" color={COLORS.primary} />}
                name={t("locations")}
                buttonText={t("locations.add.button")}
                onAddButton={goToCreate}
                showButton={!handleStatusFilter()}
                disableButton={!selectedWarehouse}
              />

              {selectedWarehouse !== "" ? (
                <LocationsViewTable
                  locationData={locationData as DomainPage<LocationEntity>}
                  tilesByWarehouse={tilesByWarehouse}
                  zonesByWarehouse={zonesByWarehouse}
                  selectedWarehouse={selectedWarehouse}
                  handleEdit={handleEdit}
                  handleActiveConfirm={handleActiveConfirm}
                  handlePagination={handlePagination}
                  searchBarFilter={searchBarFilter}
                  sortByFieldBackend={sortByFieldBackend}
                />
              ) : (
                <PersistedAlert message={t("location.alert.warehouse.no.created")} goTo="warehouses" />
              )}

              {activeLocation && (
                <LocationActivationDialog
                  open={isActivating}
                  onClose={onCloseActive}
                  onActive={item => handleLocationStatus(item)}
                  location={activeLocation}
                />
              )}
              <LoadingSpinnerMasterView loading={loading} />
            </Grid>
          </OutletContainer>

          {selectedWarehouse !== "" && (
            <LocationsDetailView
              location={selectedLocation as LocationEntity}
              tiles={tilesByWarehouse}
              zones={zonesByWarehouse}
              loading={loadingDetail}
              onSave={handleSave}
              onClose={() => goToList()}
            />
          )}
        </>
      ) : (
        <CircularProgress color="secondary" />
      )}
    </>
  );
};
