import { COLORS, MEDIA } from "@theme";
import Icon from "@components/icons/icon";
import { useTranslation } from "react-i18next";
import { UserRoleEnum } from "@domain/interface/User";
import { usePermissions } from "@hooks/usePermissions";
import { profileViewStyles } from "../profile-view.Styles";
import { useCompanyDataViewModel } from "../hooks/useCompanyDataViewModel";
import { Grid, Typography, TextField, Button, useMediaQuery, Theme, Avatar, IconButton } from "@mui/material";

export const CompanyData = () => {
  const { t } = useTranslation();
  const styles = profileViewStyles();

  const { currentCompany, multimedia, blobFile, setBlobFile, onChange, handleSave, showErrors } = useCompanyDataViewModel();

  const { isValidPermissions } = usePermissions();
  const matchesMd = useMediaQuery((theme: Theme) => theme.breakpoints.up(MEDIA.medium), { noSsr: true });
  const matchesSM = useMediaQuery((theme: Theme) => theme.breakpoints.up(MEDIA.xsmall), { noSsr: true });

  return (
    <Grid container direction="column" rowSpacing={2}>
      {/** Title */}
      <Grid item>
        <Typography sx={styles.categories_typography}>{t("company.data")}</Typography>
      </Grid>
      <Grid item>
        <Avatar
          id="profile-company-image"
          alt="Company logo"
          src={blobFile ? URL.createObjectURL(blobFile) : multimedia?.url ?? "/assets/images/profile-picture.png"}
          sx={{ width: matchesMd ? "15rem" : "12rem", height: matchesMd ? "15rem" : "12rem", mb: "1rem" }}
        />
        {isValidPermissions([UserRoleEnum.PortalAdmin, UserRoleEnum.SuperAdmin]) && (
          <div className="App">
            <input
              id="upload-photo"
              style={{ display: "none" }}
              name="upload-photo"
              type="file"
              accept="image/png, image/jpeg"
              onChange={event => {
                event.target.files && setBlobFile(event.target.files[0]);
              }}
            />
            <Grid item display="flex" flexDirection="row">
              <label htmlFor="upload-photo" style={{ width: "fit-content" }}>
                <Grid>
                  {matchesSM ? (
                    <Button
                      component="span"
                      startIcon={<Icon icon="plus" color={COLORS.white} />}
                      variant="primary"
                      id="add-image-button">
                      {t("step-master-data-image-button")}
                    </Button>
                  ) : (
                    <IconButton component="span" sx={styles.add_icon_button} id="add-image-button">
                      <Icon icon="plus" color={COLORS.white} />
                    </IconButton>
                  )}
                </Grid>
              </label>
              <Grid item id="file-name-label" style={{ display: "flex", alignItems: "center" }}>
                <Typography sx={styles.fileName}>{blobFile ? blobFile?.name : ""}</Typography>
              </Grid>
            </Grid>
          </div>
        )}
      </Grid>

      {/** Name */}
      <Grid item>
        <Grid container alignItems="center" spacing={{ xs: 1.5 }}>
          <Grid item display="flex" xs={12} md={3}>
            <Icon {...styles.companyIconStyle} />
            <Typography sx={styles.item_typography}>{t("companies.name")}</Typography>
          </Grid>
          <Grid item id="profile-company-name" sx={styles.company_data_blue} xs={12} md={9}>
            {currentCompany?.companyName ?? t("admin.board.no.company")}
          </Grid>
        </Grid>
      </Grid>

      {/** CIF */}
      <Grid item>
        <Grid container alignItems="center" spacing={{ xs: 1.5 }}>
          <Grid item display="flex" xs={12} md={3}>
            <Icon {...styles.companyIconStyle} />
            <Typography sx={styles.item_typography}>{t("companies.cif")}</Typography>
          </Grid>
          <Grid item id="profile-company-cif" sx={styles.company_data_blue} xs={12} md={9}>
            {currentCompany?.fiscalId ?? t("admin.board.no.company")}
          </Grid>
        </Grid>
      </Grid>

      {/** Address */}
      <Grid item>
        <Grid container alignItems="flex-start" spacing={{ xs: 1.5 }}>
          <Grid item display="flex" xs={12} md={3}>
            <Icon {...styles.companyIconStyle} />
            <Typography sx={styles.item_typography}>{t("companies.form.address")}</Typography>
          </Grid>
          {isValidPermissions([UserRoleEnum.PortalAdmin, UserRoleEnum.SuperAdmin]) ? (
            <Grid item xs={12} md={9} display="flex" alignItems="flex-end" >
              <TextField
                id="profile-company-address-editable"
                name="address"
                label={t("companies.form.address")}
                value={currentCompany?.address?.replaceAll("±", " ") ?? ""}
                onChange={onChange}
                error={showErrors}
                disabled={currentCompany === undefined}
              />
              <Button
                id="edit-company-button"
                size="large"
                color="secondary"
                variant="outlined"
                sx={{ ml: "1rem" }}
                onClick={handleSave}>
                {t("button.save")}
              </Button>
            </Grid>
          ) :
            <Grid item xs={12} md={9} id="profile-company-address-no-editable" sx={styles.company_data_blue}>
              {currentCompany?.address?.replaceAll("±", " ") ?? ""}
            </Grid>
          }
        </Grid>
      </Grid>
    </Grid>
  );
};
