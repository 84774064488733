import Tile from "@domain/model/Tile";
import Zone from "@domain/model/Zone";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DomainPage } from "@movicoders/domain";
import LocationEntity from "@domain/model/Location";
import { useCheckStatus } from "@hooks/useCheckStatus";
import { useHandleCardQueries } from "@hooks/useHandleCardQueries";
import { locationsColumns } from "../components/locations-columns";
import { GridSortModel, GridValidRowModel } from "@mui/x-data-grid";
import { CardDisplay, PDFCardDisplay } from "../components/CardDisplay";
import { DataGridDisplay } from "@components/data-grid-display/data-grid-display";
import { ChangeDisplayButton } from "@components/data-grid-display/toolbar/components/ChangeDisplayButton";

interface ILocationsTable {
  locationData: DomainPage<LocationEntity>;
  tilesByWarehouse: Tile[];
  zonesByWarehouse: Zone[];
  selectedWarehouse: string;
  handleEdit: (id: string) => void;
  handleActiveConfirm: (location: LocationEntity) => void;
  handlePagination: (limit: number, offset: number, status: "ALL" | "ACTIVE" | "INACTIVE", filters?: object | undefined) => void;
  searchBarFilter: string;
  sortByFieldBackend: (model: GridSortModel) => void;
}

const LocationsViewTable = (props: ILocationsTable) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleStatusFilter } = useCheckStatus();
  const { isCardDisplay, setIsCardDisplay, matchesMD } = useHandleCardQueries();
  const {
    locationData,
    tilesByWarehouse,
    zonesByWarehouse,
    handleEdit,
    handleActiveConfirm,
    handlePagination,
    searchBarFilter,
    sortByFieldBackend
  } = props;

  return (
    <DataGridDisplay
      data={(locationData.content as GridValidRowModel[]) ?? []}
      columns={locationsColumns(t, tilesByWarehouse, zonesByWarehouse, matchesMD, handleEdit, handleActiveConfirm, navigate)}
      mode="server"
      viewName="locations"
      hideColumnsByField={handleStatusFilter() ? ["id", "actions"] : ["id"]}
      handleBackendPagination={handlePagination}
      backendTotalElements={locationData.totalElements ?? 0}
      customDisplayComponent={data =>
        isCardDisplay
          ? CardDisplay({
              gridData: data,
              handleActiveConfirm: handleActiveConfirm,
              handleEdit: handleEdit,
              tiles: tilesByWarehouse,
              zones: zonesByWarehouse
            })
          : undefined
      }
      customPDF={
        isCardDisplay
          ? ({ data, tableName }) =>
              PDFCardDisplay({
                gridData: data,
                handleActiveConfirm: handleActiveConfirm,
                handleEdit: handleEdit,
                tiles: tilesByWarehouse,
                zones: zonesByWarehouse,
                tableName: tableName
              })
          : undefined
      }
      handleBackendSort={sortByFieldBackend}
      toolbarProps={{
        multipleSelectOptions: new Map<string, string[]>([
          ["zone", zonesByWarehouse.sort((a, b) => t(a?.name).localeCompare(t(b?.name))).map(zone => zone.name) ?? []]
        ]),
        isActiveFilterHidden: handleStatusFilter(),
        disableColumnsVisibilityByField: ["id"],
        disableFilterByFields: ["id", "actions", "status"],
        disableFieldsInDownload: ["id", "actions"],
        addHTMLRight: ChangeDisplayButton({ isCardDisplay: isCardDisplay, setIsCardDisplay: setIsCardDisplay }),
        parseCSVInDownload: data => {
          const locationTile = tilesByWarehouse.find(t => t.id === data.tileId) ?? {};
          const zoneName = zonesByWarehouse.find(
            (z: Zone) => locationTile.zoneId !== undefined && z.id?.includes(locationTile.zoneId)
          );
          data.status = data.active ? t("zones.active") : t("zones.inactive");
          data.zone = zoneName?.name ?? t("locations.zone.unassigned");
          data.positionX = locationTile.x;
          data.positionZ = locationTile.z;
          return data;
        }
      }}
      searchBarFilter={searchBarFilter}
    />
  );
};

export default LocationsViewTable;
