/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HandledResponse } from './HandledResponse';
import {
    HandledResponseFromJSON,
    HandledResponseFromJSONTyped,
    HandledResponseToJSON,
} from './HandledResponse';

/**
 * 
 * @export
 * @interface EventResponse
 */
export interface EventResponse {
    /**
     * 
     * @type {Array<HandledResponse>}
     * @memberof EventResponse
     */
    acceptedItems?: Array<HandledResponse>;
    /**
     * 
     * @type {Array<HandledResponse>}
     * @memberof EventResponse
     */
    rejectedItems?: Array<HandledResponse>;
    /**
     * 
     * @type {number}
     * @memberof EventResponse
     */
    totalAccepted?: number;
    /**
     * 
     * @type {number}
     * @memberof EventResponse
     */
    totalRejected?: number;
    /**
     * 
     * @type {number}
     * @memberof EventResponse
     */
    totalScanned?: number;
}

/**
 * Check if a given object implements the EventResponse interface.
 */
export function instanceOfEventResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EventResponseFromJSON(json: any): EventResponse {
    return EventResponseFromJSONTyped(json, false);
}

export function EventResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'acceptedItems': !exists(json, 'acceptedItems') ? undefined : ((json['acceptedItems'] as Array<any>).map(HandledResponseFromJSON)),
        'rejectedItems': !exists(json, 'rejectedItems') ? undefined : ((json['rejectedItems'] as Array<any>).map(HandledResponseFromJSON)),
        'totalAccepted': !exists(json, 'totalAccepted') ? undefined : json['totalAccepted'],
        'totalRejected': !exists(json, 'totalRejected') ? undefined : json['totalRejected'],
        'totalScanned': !exists(json, 'totalScanned') ? undefined : json['totalScanned'],
    };
}

export function EventResponseToJSON(value?: EventResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'acceptedItems': value.acceptedItems === undefined ? undefined : ((value.acceptedItems as Array<any>).map(HandledResponseToJSON)),
        'rejectedItems': value.rejectedItems === undefined ? undefined : ((value.rejectedItems as Array<any>).map(HandledResponseToJSON)),
        'totalAccepted': value.totalAccepted,
        'totalRejected': value.totalRejected,
        'totalScanned': value.totalScanned,
    };
}

