import { TileDTO } from "@clients/aggrego-proxy/models/TileDTO";
import { ZoneDTO } from "@clients/aggrego-proxy/models/ZoneDTO";

export default class Zone {
  active: boolean;
  color: string;
  id?: string;
  name: string;
  tiles: TileDTO[];
  warehouseId: string;

  constructor(active: boolean, color: string, name: string, tiles: TileDTO[], warehouseId: string, id?: string) {
    this.active = active;
    this.color = color;
    this.name = name;
    this.tiles = tiles;
    this.warehouseId = warehouseId;
    this.id = id;
  }

  static fromDTO(zone: ZoneDTO) {
    return {
      active: zone.active ?? true,
      color: zone.color ?? "",
      name: zone.name ?? "",
      tiles: zone.tiles ?? [],
      warehouseId: zone.warehouseId ?? "",
      id: zone.id ?? ""
    };
  }

  static toDTO(zone: Zone) {
    return {
      id: zone.id,
      active: zone.active,
      color: zone.color,
      name: zone.name,
      tiles: zone.tiles,
      warehouseId: zone.warehouseId
    };
  }
}
