import { Button, Grid, Typography } from "@mui/material";
import { AggregoDialog } from "../../../../components/master-data-view/dialog/AggregoDialog";
import { useTranslation } from "react-i18next";
import { StockDTO } from "../../../../../domain/model/Stock";

export const StockDetail = (props: { open: boolean; onClose: () => void; material: StockDTO }) => {
  const { open, onClose, material } = props;

  const { t } = useTranslation();

  const generateFamilyNames = (families: string[]) => {
    let concatNames = "";
    families.forEach((family: string) => (concatNames += family + " \n"));
    return concatNames;
  };

  const renderInput = (name: string, value: string, uom?: string) => {
    return (
      <Grid item xs={12} sx={{ mt: "0.25rem" }}>
        <div className="title-content" data-cy={`material-card-${name}-container`}>
          <Typography variant="cardlabel" display="block">
            {t(name)}
          </Typography>
          <Typography data-cy="value" variant="cardvalue" whiteSpace="pre-line">
            {uom !== undefined ? (
              <>
                <span style={{ color: "#4CAC1C" }}>{value}</span>&nbsp;{uom}
              </>
            ) : (
              <>{value}</>
            )}
          </Typography>
        </div>
      </Grid>
    );
  };

  return open ? (
    <AggregoDialog title={t("stock.detail")} onClose={onClose}>
      <>
        <div>
          {renderInput("stock.code", material.materialCode ?? "")}
          {renderInput("stock.name", material.materialName ?? "")}
          {renderInput("stock.qty", (material?.qty ?? "") + "", material.uom)}
          {renderInput("stock.family", generateFamilyNames(material.family ?? []).toString())}
        </div>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", mt: "0.25rem" }}>
          <Button variant="primary" onClick={onClose} id="close-stock-button">
            {t("button.close")}
          </Button>
        </Grid>
      </>
    </AggregoDialog>
  ) : (
    <></>
  );
};
