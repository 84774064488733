/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IMMaterialEntryDTO
 */
export interface IMMaterialEntryDTO {
    /**
     * 
     * @type {string}
     * @memberof IMMaterialEntryDTO
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof IMMaterialEntryDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof IMMaterialEntryDTO
     */
    materialCode?: string;
    /**
     * 
     * @type {number}
     * @memberof IMMaterialEntryDTO
     */
    qty?: number;
    /**
     * 
     * @type {string}
     * @memberof IMMaterialEntryDTO
     */
    warehouseCode?: string;
}

/**
 * Check if a given object implements the IMMaterialEntryDTO interface.
 */
export function instanceOfIMMaterialEntryDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IMMaterialEntryDTOFromJSON(json: any): IMMaterialEntryDTO {
    return IMMaterialEntryDTOFromJSONTyped(json, false);
}

export function IMMaterialEntryDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): IMMaterialEntryDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'materialCode': !exists(json, 'materialCode') ? undefined : json['materialCode'],
        'qty': !exists(json, 'qty') ? undefined : json['qty'],
        'warehouseCode': !exists(json, 'warehouseCode') ? undefined : json['warehouseCode'],
    };
}

export function IMMaterialEntryDTOToJSON(value?: IMMaterialEntryDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comment': value.comment,
        'id': value.id,
        'materialCode': value.materialCode,
        'qty': value.qty,
        'warehouseCode': value.warehouseCode,
    };
}

