import {
  ContainerPageDTO,
  FullUserDTOCompanyLicenseEnum,
  ListRelabelPaginatedRequest,
  RelabelFilterDTO
} from "@clients/aggrego-proxy";
import { IKeyable } from "@domain/interface/IKeyable";
import { LastPageState } from "@domain/model/LastPageState";
import { ILastPageService, LastPageService } from "@domain/services/ILastPageService";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { IUserService, UserService } from "@domain/services/IUserService";
import { ContainerRepository } from "@infrastructure/repositories/container-repository";
import { useResolve } from "@movicoders/di";
import { useSnackbar } from "@movicoders/ui";
import { convertDateStringToFirstMillisecond, convertDateStringToLastMillisecond } from "@utils/date-helper";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type DateFilter = {
  relabelDate: string[];
  searchText: string;
};

export const useRelabelViewModel = () => {
  const { t } = useTranslation();
  const { show } = useSnackbar();
  const settingsService = useResolve<ISettingsService>(SettingsService);
  const currentPageService = useResolve<ILastPageService>(LastPageService);
  const containerRepository = useResolve<ContainerRepository>(ContainerRepository);
  const currentPagePersistedState = currentPageService.persistedState ?? new LastPageState();
  const currentUserPersistedState = useResolve<IUserService>(UserService);

  const [loading, setLoading] = useState(false);
  const [columnsByMode, setColumnsByMode] = useState<string[]>([]);
  const [relabelDataPaginated, setRelabelDataPaginated] = useState<ContainerPageDTO>();

  //HandleData (first load)
  useEffect(() => {
    setLoading(true);
    if (settingsService.persistedState?.reportsPermissions.includes("RELABEL_REPORT")) {
      generateColumns();
      containerRepository
        .getRelabelPaginated({ limit: 10, offset: 0, relabelFilterDTO: {} })
        .then(result => {
          setRelabelDataPaginated(result);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const generateColumns = () => {
    if (currentUserPersistedState.user?.companyLicense === FullUserDTOCompanyLicenseEnum.WmWithContainer) {
      setColumnsByMode(["id"]);
    }
  };
  //END - HandleData (first load)

  //Pagination
  const DEBOUNCE_TIME = 500;
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);

  const search = (limit: number, offset: number, filters?: object | undefined) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    const newDebounceTimer = setTimeout(() => {
      setLoading(true);
      let dateFilter = undefined;
      if (currentPagePersistedState.filters && (currentPagePersistedState.filters as DateFilter).relabelDate) {
        dateFilter = {
          initDate: convertDateStringToFirstMillisecond((currentPagePersistedState.filters as DateFilter).relabelDate[0]),
          endDate: convertDateStringToLastMillisecond((currentPagePersistedState.filters as DateFilter).relabelDate[1]),
          searchText: (currentPageService.persistedState?.filters as IKeyable)?.["searchText"] ?? undefined
        };
      }
      containerRepository
        .getRelabelPaginated({
          offset,
          limit,
          relabelFilterDTO: dateFilter ?? filters ?? ({} as RelabelFilterDTO)
        } as ListRelabelPaginatedRequest)
        .then(result => {
          setRelabelDataPaginated(result);
        })
        .catch(() => {
          show(t("relabel.request.error"), "error");
        })
        .finally(() => {
          setLoading(false);
        });
    }, DEBOUNCE_TIME);
    setDebounceTimer(newDebounceTimer);
  };
  //END - Pagination

  return { relabelDataPaginated, loading, columnsByMode, search, settingsService };
};
