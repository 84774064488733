import { User } from "@domain/model/User";
import { useTranslation } from "react-i18next";
import { TUserRoleEnum } from "@domain/interface/User";
import { Card } from "@components/master-data-view/card/card";
import { Grid, SxProps, Theme, Typography } from "@mui/material";

export interface RenderChildProps {
  name: string;
  value: string;
  className?: string;
  sx?: SxProps<Theme>;
  key?: string;
}

const UserCard = (props: { user: User; handleEdit: (id: string) => void; handleActive: (item: User) => void }) => {
  const { t } = useTranslation();
  const { user, handleEdit, handleActive } = props;

  const generateRolesList = (allRoles: TUserRoleEnum[]) => {
    let concatRoles = "";
    allRoles.forEach((role: TUserRoleEnum) => (concatRoles += role + "\n"));
    return [concatRoles];
  };

  const renderChild = (name: string, values: string[], className?: string, key?: string) => {
    return (
      <div key={key} className={className}>
        <div className="title-content">
          <Typography variant="cardlabel" textAlign="left">
            {t(name)}
          </Typography>
          <div style={{ display: "flex" }}>
            <Typography variant="cardvalue" data-cy={`${name.split(".")[1]}-value`} whiteSpace="pre-line">
              {values.map(val => val + " ")}
            </Typography>
          </div>
        </div>
      </div>
    );
  };

  const rendererCard = (data: User) => {
    return (
      <Card
        isActive={data.active}
        key={data.credentialsId}
        popover={{
          handleEdit: () => handleEdit(data.credentialsId),
          handleActive: () => handleActive(data),
          activateTitle: data.active ? t("root.deactivate") : t("root.activate")
        }}
        id={"id-card-" + data.credentialsId}
        nameValue={data.email}
        name="users.email"
        data-cy="id-card-">
        <>
          <Grid item sx={{ ml: "1rem", mb: "0.5rem" }}>
            {(data.firstName || data.firstSurname || data.secondSurname) &&
              renderChild("users.name", [data.firstName, data.firstSurname, data.secondSurname], "user-value-card-container")}
          </Grid>
          <Grid item sx={{ ml: "1rem", mb: "0.5rem" }}>
            {renderChild("users.roles", generateRolesList(data.roles), "roles-value-card-container")}
          </Grid>
        </>
      </Card>
    );
  };

  return rendererCard(user);
};

export default UserCard;
