import { COLORS } from "@theme";
import { SxProps, Theme } from "@mui/material";

const dashboard_tabs_true: SxProps<Theme> = {
  zIndex: 2,
  textTransform: "none",
  "&& ": {
    color: COLORS.secondary
  },
  fontSize: "12px",
  fontWeight: 600,
  borderRadius: "6px 6px 0px 0px",
  boxShadow: "3px 3px 6px #00000029",
  backgroundColor: COLORS.white
};

const dashboard_tabs_false: SxProps<Theme> = {
  textTransform: "none",
  fontSize: "12px",
  color: COLORS.gray,
  borderRadius: "6px 6px 0px 0px",
  backgroundColor: COLORS.lightgray
};

const logistic_tabs_true: SxProps<Theme> = {
  zIndex: 2,
  textTransform: "none",
  "&& ": {
    color: COLORS.secondary
  },
  fontSize: "12px",
  fontWeight: 600,
  borderRadius: "6px 6px 0px 0px",
  boxShadow: "3px 3px 6px #00000029",
  backgroundColor: COLORS.white,
  maxHeight: "3rem",
  minHeight: "3rem"
};

const logistic_tabs_false: SxProps<Theme> = {
  textTransform: "none",
  fontSize: "12px",
  color: COLORS.gray,
  borderRadius: "6px 6px 0px 0px",
  backgroundColor: COLORS.lightgray,
  maxHeight: "3rem",
  minHeight: "3rem"
};

export const homeTabStyles = {
  dashboard_tabs_true,
  dashboard_tabs_false,
  logistic_tabs_true,
  logistic_tabs_false
};
