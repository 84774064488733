/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SessionResponses } from './SessionResponses';
import {
    SessionResponsesFromJSON,
    SessionResponsesFromJSONTyped,
    SessionResponsesToJSON,
} from './SessionResponses';

/**
 * 
 * @export
 * @interface ExtendedLoginResponse
 */
export interface ExtendedLoginResponse {
    /**
     * 
     * @type {string}
     * @memberof ExtendedLoginResponse
     */
    codeEmail?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExtendedLoginResponse
     */
    enabledNFAEmail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExtendedLoginResponse
     */
    enabledNFAOPT?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExtendedLoginResponse
     */
    secretNFA?: string;
    /**
     * 
     * @type {SessionResponses}
     * @memberof ExtendedLoginResponse
     */
    session?: SessionResponses;
    /**
     * 
     * @type {string}
     * @memberof ExtendedLoginResponse
     */
    token?: string;
}

/**
 * Check if a given object implements the ExtendedLoginResponse interface.
 */
export function instanceOfExtendedLoginResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExtendedLoginResponseFromJSON(json: any): ExtendedLoginResponse {
    return ExtendedLoginResponseFromJSONTyped(json, false);
}

export function ExtendedLoginResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtendedLoginResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'codeEmail': !exists(json, 'codeEmail') ? undefined : json['codeEmail'],
        'enabledNFAEmail': !exists(json, 'enabledNFAEmail') ? undefined : json['enabledNFAEmail'],
        'enabledNFAOPT': !exists(json, 'enabledNFAOPT') ? undefined : json['enabledNFAOPT'],
        'secretNFA': !exists(json, 'secretNFA') ? undefined : json['secretNFA'],
        'session': !exists(json, 'session') ? undefined : SessionResponsesFromJSON(json['session']),
        'token': !exists(json, 'token') ? undefined : json['token'],
    };
}

export function ExtendedLoginResponseToJSON(value?: ExtendedLoginResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'codeEmail': value.codeEmail,
        'enabledNFAEmail': value.enabledNFAEmail,
        'enabledNFAOPT': value.enabledNFAOPT,
        'secretNFA': value.secretNFA,
        'session': SessionResponsesToJSON(value.session),
        'token': value.token,
    };
}

