/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomStakeholderFilter
 */
export interface CustomStakeholderFilter {
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof CustomStakeholderFilter
     */
    caseInsensitive?: { [key: string]: boolean; };
    /**
     * 
     * @type {string}
     * @memberof CustomStakeholderFilter
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomStakeholderFilter
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomStakeholderFilter
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomStakeholderFilter
     */
    paymentCurrency?: CustomStakeholderFilterPaymentCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomStakeholderFilter
     */
    paymentTerms?: CustomStakeholderFilterPaymentTermsEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomStakeholderFilter
     */
    searchText?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomStakeholderFilter
     */
    types?: Array<CustomStakeholderFilterTypesEnum>;
}


/**
 * @export
 */
export const CustomStakeholderFilterPaymentCurrencyEnum = {
    Dollar: 'DOLLAR',
    Euro: 'EURO',
    PoundSterling: 'POUND_STERLING'
} as const;
export type CustomStakeholderFilterPaymentCurrencyEnum = typeof CustomStakeholderFilterPaymentCurrencyEnum[keyof typeof CustomStakeholderFilterPaymentCurrencyEnum];

/**
 * @export
 */
export const CustomStakeholderFilterPaymentTermsEnum = {
    Cash: 'CASH',
    Net30: 'NET30',
    Net60: 'NET60',
    Net90: 'NET90'
} as const;
export type CustomStakeholderFilterPaymentTermsEnum = typeof CustomStakeholderFilterPaymentTermsEnum[keyof typeof CustomStakeholderFilterPaymentTermsEnum];

/**
 * @export
 */
export const CustomStakeholderFilterTypesEnum = {
    Customer: 'CUSTOMER',
    Manufacturer: 'MANUFACTURER',
    Owner: 'OWNER',
    Supplier: 'SUPPLIER'
} as const;
export type CustomStakeholderFilterTypesEnum = typeof CustomStakeholderFilterTypesEnum[keyof typeof CustomStakeholderFilterTypesEnum];


/**
 * Check if a given object implements the CustomStakeholderFilter interface.
 */
export function instanceOfCustomStakeholderFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomStakeholderFilterFromJSON(json: any): CustomStakeholderFilter {
    return CustomStakeholderFilterFromJSONTyped(json, false);
}

export function CustomStakeholderFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomStakeholderFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'caseInsensitive': !exists(json, 'caseInsensitive') ? undefined : json['caseInsensitive'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'paymentCurrency': !exists(json, 'paymentCurrency') ? undefined : json['paymentCurrency'],
        'paymentTerms': !exists(json, 'paymentTerms') ? undefined : json['paymentTerms'],
        'searchText': !exists(json, 'searchText') ? undefined : json['searchText'],
        'types': !exists(json, 'types') ? undefined : json['types'],
    };
}

export function CustomStakeholderFilterToJSON(value?: CustomStakeholderFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'caseInsensitive': value.caseInsensitive,
        'city': value.city,
        'country': value.country,
        'email': value.email,
        'paymentCurrency': value.paymentCurrency,
        'paymentTerms': value.paymentTerms,
        'searchText': value.searchText,
        'types': value.types,
    };
}

