import { DrawerState } from "../model/DrawerState";

export interface IDrawerService {
  persistedState?: DrawerState;
  getPersistedState(): DrawerState | undefined;
  saveDrawerState(newState: DrawerState): void;
  clearDrawerState(): void;
}

export const DrawerService = Symbol.for("DrawerService");
