import { useResolve } from "@movicoders/di";
import { IZonesEdition3DStore } from "../../../../stores/zones-edition-3d-store/IZonesEdition3DStore";
import { useZonesEdition3DStore } from "../../../../stores/zones-edition-3d-store/zones-edition-3d-store";
import { SelectChangeEvent } from "@mui/material";
import { IEditionStatesStore } from "../../../../stores/edition-states-store/IEditionStatesStore";
import { useEditionStatesStore } from "../../../../stores/edition-states-store/edition-states-store";
import { IWarehouse3DStore } from "../../../../stores/warehouse-3d-store/IWarehouse3DStore";
import { useWarehouse3DStore } from "../../../../stores/warehouse-3d-store/warehouse-3d-store";

export const useZoneSelector3D = () => {
  const { getZones, getSelectedZone, setSelectedZone, setSelectedTilesIds } = useResolve<IZonesEdition3DStore>(useZonesEdition3DStore);
  const { getModifyingWH, setModifyingWH } = useResolve<IEditionStatesStore>(useEditionStatesStore);
  const { getWarehouse } = useResolve<IWarehouse3DStore>(useWarehouse3DStore);

  const handleChange = (event: SelectChangeEvent) => {
    const id = event.target.value;
    const zoneToBeSelected = getZones().find(zone => zone.id === id);
    if (zoneToBeSelected && id !== "") {
      const tilesInsideZone = getWarehouse().tiles
        ?.filter(tile => tile.zoneId === id &&
          tile.id !== undefined &&
          (tile.locations?.length ?? 0) > 0 &&
          tile.active)
        .map(t => t.id ?? "") ?? [];

      setSelectedTilesIds(tilesInsideZone);
      setSelectedZone(zoneToBeSelected)
      setModifyingWH(true);
    } else {
      setSelectedZone(undefined);
    }
  };

  const getActiveZones = () => {
    return getZones().filter(zone => zone.active) ?? [];
  }

  return {
    getActiveZones,
    getSelectedZone,
    handleChange,
    getModifyingWH
  };
};
