/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BasicFilterDTO
 */
export interface BasicFilterDTO {
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof BasicFilterDTO
     */
    caseInsensitive?: { [key: string]: boolean; };
    /**
     * 
     * @type {string}
     * @memberof BasicFilterDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof BasicFilterDTO
     */
    searchText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasicFilterDTO
     */
    status?: boolean;
}

/**
 * Check if a given object implements the BasicFilterDTO interface.
 */
export function instanceOfBasicFilterDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BasicFilterDTOFromJSON(json: any): BasicFilterDTO {
    return BasicFilterDTOFromJSONTyped(json, false);
}

export function BasicFilterDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BasicFilterDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'caseInsensitive': !exists(json, 'caseInsensitive') ? undefined : json['caseInsensitive'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'searchText': !exists(json, 'searchText') ? undefined : json['searchText'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function BasicFilterDTOToJSON(value?: BasicFilterDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'caseInsensitive': value.caseInsensitive,
        'name': value.name,
        'searchText': value.searchText,
        'status': value.status,
    };
}

