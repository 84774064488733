import { Dialog, DialogTitle, Typography, DialogContent, DialogActions, Button, Grid, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDeleteDialogViewmodel } from "./useDeleteDialogViewmodel";
import { Location3D } from "../../../types/Location3D";
import { COLORS } from "../../../../../../theme";
import Icon from "../../../../../components/icons/icon";

const ViewerDeleteDialogLocation = (props: { location: Location3D }) => {
  const { location } = props;

  if ((location.containers?.length ?? 0) > 0)
    return (
      <Tooltip title="This location have active containers inside so it wont be deleted" followCursor sx={{ maxWidth: "fit-content" }}>
        <Grid display="flex" flexDirection="row">
          <Typography color={COLORS.alert} ml="2rem" mr="1rem" mb="0.5rem">
            {location.code}
          </Typography>
          <Icon icon="warning" color={COLORS.alert} />
        </Grid>
      </Tooltip>
    )
  else
    return (
      <Typography ml="2rem" mb="0.5rem">
        {location.code}
      </Typography>
    );
};

export const ViewerDeleteDialog = () => {
  const { t } = useTranslation();
  const {
    handleCloseDialog,
    handleConfirmTileDialog,
    handleConfirmBinDialog,
    getDeleteDialogOpen,
    getTilesToDelete,
    getLocationsToDelete
  } = useDeleteDialogViewmodel();

  return (
    <Dialog fullWidth open={getDeleteDialogOpen()}>
      <DialogTitle>{t("viewer.delete.location.title")}</DialogTitle>
      <Grid container flexDirection="column" className="masterdata-box" rowSpacing={2}>
        <Grid item>
          <Typography ml="2rem" mr="2rem">
            {t("viewer.delete.location.message")}
          </Typography>
        </Grid>
        {getTilesToDelete().length > 0 && (
          <Grid item>
            <DialogContent sx={{ maxHeight: "40vh", display: "flex", flexDirection: "column" }}>
              {getTilesToDelete()?.map(tile => (
                <Grid key={tile.id}>
                  {tile.locations?.filter(l => l.active).map(loc => <ViewerDeleteDialogLocation key={loc.id} location={loc} />)}
                </Grid>
              ))}
            </DialogContent>
          </Grid>
        )}
        {getLocationsToDelete().length > 0 && (
          <Grid item>
            <DialogContent sx={{ maxHeight: "40vh", display: "flex", flexDirection: "column" }}>
              {getLocationsToDelete()?.map(bin => <ViewerDeleteDialogLocation key={bin.id} location={bin} />)}
            </DialogContent>
          </Grid>
        )}
        <Grid item>
          <DialogActions>
            <Button onClick={handleCloseDialog}>{t("viewer.delete.cancel")}</Button>
            {getTilesToDelete().length > 0 && <Button onClick={handleConfirmTileDialog}>{t("viewer.delete.apply")}</Button>}
            {getLocationsToDelete().length > 0 && <Button onClick={handleConfirmBinDialog}>{t("viewer.delete.apply")}</Button>}
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  );
};
