/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyFilterDTO
 */
export interface CompanyFilterDTO {
    /**
     * 
     * @type {boolean}
     * @memberof CompanyFilterDTO
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyFilterDTO
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyFilterDTO
     */
    companyName?: string;
    /**
     * 
     * @type {Date}
     * @memberof CompanyFilterDTO
     */
    createdEndDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CompanyFilterDTO
     */
    createdInitDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof CompanyFilterDTO
     */
    fiscalId?: string;
    /**
     * 
     * @type {Date}
     * @memberof CompanyFilterDTO
     */
    modifiedEndDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CompanyFilterDTO
     */
    modifiedInitDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof CompanyFilterDTO
     */
    searchText?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyFilterDTO
     */
    tenant?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyFilterDTO
     */
    tenantName?: string;
}

/**
 * Check if a given object implements the CompanyFilterDTO interface.
 */
export function instanceOfCompanyFilterDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CompanyFilterDTOFromJSON(json: any): CompanyFilterDTO {
    return CompanyFilterDTOFromJSONTyped(json, false);
}

export function CompanyFilterDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyFilterDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'createdEndDate': !exists(json, 'createdEndDate') ? undefined : (new Date(json['createdEndDate'])),
        'createdInitDate': !exists(json, 'createdInitDate') ? undefined : (new Date(json['createdInitDate'])),
        'fiscalId': !exists(json, 'fiscalId') ? undefined : json['fiscalId'],
        'modifiedEndDate': !exists(json, 'modifiedEndDate') ? undefined : (new Date(json['modifiedEndDate'])),
        'modifiedInitDate': !exists(json, 'modifiedInitDate') ? undefined : (new Date(json['modifiedInitDate'])),
        'searchText': !exists(json, 'searchText') ? undefined : json['searchText'],
        'tenant': !exists(json, 'tenant') ? undefined : json['tenant'],
        'tenantName': !exists(json, 'tenantName') ? undefined : json['tenantName'],
    };
}

export function CompanyFilterDTOToJSON(value?: CompanyFilterDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'address': value.address,
        'companyName': value.companyName,
        'createdEndDate': value.createdEndDate === undefined ? undefined : (value.createdEndDate.toISOString()),
        'createdInitDate': value.createdInitDate === undefined ? undefined : (value.createdInitDate.toISOString()),
        'fiscalId': value.fiscalId,
        'modifiedEndDate': value.modifiedEndDate === undefined ? undefined : (value.modifiedEndDate.toISOString()),
        'modifiedInitDate': value.modifiedInitDate === undefined ? undefined : (value.modifiedInitDate.toISOString()),
        'searchText': value.searchText,
        'tenant': value.tenant,
        'tenantName': value.tenantName,
    };
}

