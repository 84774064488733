/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatusReportFilterDTO
 */
export interface StatusReportFilterDTO {
    /**
     * 
     * @type {string}
     * @memberof StatusReportFilterDTO
     */
    container?: string;
    /**
     * 
     * @type {string}
     * @memberof StatusReportFilterDTO
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof StatusReportFilterDTO
     */
    endDate?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof StatusReportFilterDTO
     */
    family?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof StatusReportFilterDTO
     */
    initDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof StatusReportFilterDTO
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof StatusReportFilterDTO
     */
    material?: string;
    /**
     * 
     * @type {string}
     * @memberof StatusReportFilterDTO
     */
    operatorCode?: string;
    /**
     * 
     * @type {number}
     * @memberof StatusReportFilterDTO
     */
    qty?: number;
    /**
     * 
     * @type {string}
     * @memberof StatusReportFilterDTO
     */
    searchText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StatusReportFilterDTO
     */
    status?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StatusReportFilterDTO
     */
    uom?: string;
    /**
     * 
     * @type {string}
     * @memberof StatusReportFilterDTO
     */
    user?: string;
}

/**
 * Check if a given object implements the StatusReportFilterDTO interface.
 */
export function instanceOfStatusReportFilterDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StatusReportFilterDTOFromJSON(json: any): StatusReportFilterDTO {
    return StatusReportFilterDTOFromJSONTyped(json, false);
}

export function StatusReportFilterDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusReportFilterDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'container': !exists(json, 'container') ? undefined : json['container'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'family': !exists(json, 'family') ? undefined : json['family'],
        'initDate': !exists(json, 'initDate') ? undefined : (new Date(json['initDate'])),
        'location': !exists(json, 'location') ? undefined : json['location'],
        'material': !exists(json, 'material') ? undefined : json['material'],
        'operatorCode': !exists(json, 'operatorCode') ? undefined : json['operatorCode'],
        'qty': !exists(json, 'qty') ? undefined : json['qty'],
        'searchText': !exists(json, 'searchText') ? undefined : json['searchText'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'uom': !exists(json, 'uom') ? undefined : json['uom'],
        'user': !exists(json, 'user') ? undefined : json['user'],
    };
}

export function StatusReportFilterDTOToJSON(value?: StatusReportFilterDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'container': value.container,
        'description': value.description,
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'family': value.family,
        'initDate': value.initDate === undefined ? undefined : (value.initDate.toISOString()),
        'location': value.location,
        'material': value.material,
        'operatorCode': value.operatorCode,
        'qty': value.qty,
        'searchText': value.searchText,
        'status': value.status,
        'uom': value.uom,
        'user': value.user,
    };
}

