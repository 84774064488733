import { DomainPage } from "@movicoders/domain";
import { IImpersonationService } from "@domain/services/IImpersonationService";
import {
  DeleteLocationsRequest,
  GetLocationsByExactCodeRequest,
  ListLocationsPaginatedRequest,
  LocationsApi,
  SaveLocationsRequest
} from "@clients/aggrego-proxy/apis/LocationsApi";
import { ListResponseDTO, LocationDTO } from "@clients/aggrego-proxy";
import LocationEntity from "@domain/model/Location";

export class LocationApi {
  constructor(private readonly locationsApi: LocationsApi, private readonly impersonationService: IImpersonationService) {}

  async get(): Promise<LocationEntity[]> {
    const allLocation: LocationEntity[] = [];
    await this.locationsApi
      .listLocations({
        status: "ALL",
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
      .then(allLocationsDTO => {
        allLocationsDTO.forEach(locationDTO => {
          allLocation.push(LocationEntity.fromClientLocation(locationDTO));
        });
      });
    return allLocation;
  }

  async getPaginated(pageCriteria: ListLocationsPaginatedRequest): Promise<DomainPage<LocationEntity>> {
    return (await this.locationsApi.listLocationsPaginated({
      ...pageCriteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    })) as unknown as Promise<DomainPage<LocationEntity>>;
  }

  async getById(id: string): Promise<LocationEntity> {
    return LocationEntity.fromClientLocation(
      await this.locationsApi.getLocationById({
        id: id,
        fullDTO: true,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
  }

  async create(entity: LocationEntity): Promise<LocationEntity> {
    return LocationEntity.fromClientLocation(
      await this.locationsApi.saveLocation({
        locationDTO: entity,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
  }

  async createMany(criteria: SaveLocationsRequest): Promise<ListResponseDTO> {
    const result = await this.locationsApi.saveLocations({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
    return result;
  }

  async update(entity: LocationEntity): Promise<LocationEntity> {
    return LocationEntity.fromClientLocation(
      await this.locationsApi.updateLocation({
        locationDTO: entity,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
  }

  async remove(id: string): Promise<void> {
    await this.locationsApi.deleteLocation({
      id: id,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }

  async removeMany(criteria: DeleteLocationsRequest): Promise<Array<LocationDTO>> {
    const result = await this.locationsApi.deleteLocations({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
    return result;
  }

  async getByExactCode(criteria: GetLocationsByExactCodeRequest): Promise<LocationDTO> {
    const result = await this.locationsApi.getLocationsByExactCode({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
    return result;
  }
}
