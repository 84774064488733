import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";
import { COLORS } from "../../../../theme";

const container: SxProps<Theme> = {
  backgroundColor: COLORS.lightgray,
  justifyContent: "flex-end",
  alignItems: "center",
  width: "100%",
  minHeight: "2rem",
  position: "absolute"
};

const text_container: SxProps<Theme> = {
  mr: "1.5rem",
  fontSize: "0.75rem",
  color: COLORS.secondary
};

const logout_container: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  mr: "1.5rem"
};

export const impersonationInfoStyles = {
  container,
  text_container,
  logout_container
};
