import { GridColDef } from "@mui/x-data-grid";
import { filterValueTypes } from "@constants";

export const userColumns = (t: (text: string) => string): GridColDef[] => {
  return [
    { field: "firstName", headerName: t("users.name"), type: "string" as filterValueTypes },
    { field: "email", headerName: t("users.email"), type: "string" as filterValueTypes },
    { field: "roles", headerName: t("users.roles"), type: "multiple-select" as filterValueTypes },
    {
      field: "active",
      headerName: t("users.status"),
      type: "select" as filterValueTypes,
      valueGetter: ({ row }) => {
        return row.active ? t("material.active") : t("material.inactive");
      }
    }
  ];
};
