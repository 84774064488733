import FamilyType from "@domain/model/FamilyType";
import { DomainPage, IRepository } from "@movicoders/domain";
import { TypeOfFamilyApi } from "../api/type-of-families-api";
import { ListFamilyTypesByTenantIdRequest, ListFamilyTypesPaginatedRequest } from "@clients/aggrego-proxy/apis/FamilyTypesApi";

export class TypeOfFamilyRepository
  implements IRepository<FamilyType, ListFamilyTypesPaginatedRequest, ListFamilyTypesPaginatedRequest>
{
  constructor(private readonly typeOfFamilyApi: TypeOfFamilyApi) {}

  async get(): Promise<FamilyType[]> {
    return await this.typeOfFamilyApi.get();
  }

  getPaginated(typeOfFamilyPageCriteria: ListFamilyTypesPaginatedRequest): Promise<DomainPage<FamilyType>> {
    return this.typeOfFamilyApi.getPaginated(typeOfFamilyPageCriteria);
  }

  getById(id: string): Promise<FamilyType> {
    return this.typeOfFamilyApi.getById(id);
  }

  getByTenantId(criteria: ListFamilyTypesByTenantIdRequest): Promise<FamilyType[]> {
    return this.typeOfFamilyApi.getByTenantId(criteria);
  }

  async create(entity: FamilyType): Promise<FamilyType> {
    return await this.typeOfFamilyApi.create(entity);
  }

  async update(entity: FamilyType): Promise<FamilyType> {
    return await this.typeOfFamilyApi.update(entity);
  }

  async remove(id: string): Promise<void> {
    await this.typeOfFamilyApi.remove(id);
  }
}
