import { t } from "i18next";
import { useEffect, useRef } from "react";
import { Grid, TextField, Button } from "@mui/material";
import { useHandleOperator } from "@hooks/useHandleOperator";
import { useLogisticLoginViewModel } from "../hooks/useLogisticLoginModel";
import { Dialog as MUIDialog, DialogTitle, DialogContent, Breakpoint } from "@mui/material";

const DialogOnlyAcceppt = function (props: { title: string; children: JSX.Element; maxWidth?: Breakpoint; open: boolean }) {
  const { title, children, maxWidth, open } = props;

  const { clearOperatorState } = useHandleOperator();

  useEffect(() => {
    clearOperatorState();
  }, []);

  return (
    <MUIDialog open={open} id="mui-dialog" maxWidth={maxWidth ?? "sm"} className="save-dialog">
      <DialogTitle>
        <div
          id="dialog-title-name"
          style={{
            textOverflow: "ellipsis",
            fontSize: "1.125rem",
            overflow: "hidden",
            marginTop: "0.5rem",
            marginBottom: "0.5rem"
          }}>
          {title}
        </div>
      </DialogTitle>
      <DialogContent id="dialog-content">{children}</DialogContent>
    </MUIDialog>
  );
};

export const LoginOperatorDialog = () => {
  const passwordTextField = useRef<HTMLElement>(null);
  const { usernameError, passwordError, isClosed, handleLogin, handlePasswordChange, handleUsernameChange } =
    useLogisticLoginViewModel();

  return (
    <DialogOnlyAcceppt title={t("operators.login.access")} open={!isClosed}>
      <Grid container flexDirection={"column"}>
        <Grid item>
          <TextField
            id="username-input"
            onChange={handleUsernameChange}
            label={t("login.username")}
            error={usernameError}
            onKeyUp={event => {
              if (event.key === "Enter") passwordTextField.current?.focus();
            }}
            inputProps={{ enterKeyHint: "next" }}
            sx={{ marginTop: "1rem", marginBottom: "1rem" }}
          />
        </Grid>
        <Grid item>
          <TextField
            id="password-input"
            type="password"
            onChange={handlePasswordChange}
            label={t("login.password")}
            error={passwordError}
            onKeyUp={event => {
              if (event.key === "Enter") {
                passwordTextField.current?.blur();
                handleLogin();
              }
            }}
            sx={{ marginTop: "1rem", marginBottom: "1rem" }}
            inputProps={{ ref: passwordTextField, enterKeyHint: "done" }}
          />
        </Grid>
        <Button id="login-button" color="secondary" fullWidth={true} onClick={handleLogin}>
          {t("root.accept")}
        </Button>
      </Grid>
    </DialogOnlyAcceppt>
  );
};
