import { COLORS } from "@theme";
import { CSSProperties } from "react";
import { SxProps, Theme } from "@mui/material";
import { IconProps } from "react-icomoon";

export const profileViewStyles = () => {
  const profile_view_container: SxProps<Theme> = {
    pl: { xs: 1, sm: 6, md: "16vw" },
    pr: { xs: 1, sm: 1, md: "16vw" },
    pt: "4rem"
  };

  const title_container: SxProps<Theme> = {
    display: "flex",
    alignItems: "center",
    color: COLORS.secondary
  };

  const view_title_typography: SxProps<Theme> = {
    fontSize: "1.6rem",
    fontWeight: 600,
    ml: "1rem"
  };

  const categories_typography: SxProps<Theme> = {
    color: COLORS.gray,
    fontSize: "1.125rem",
    fontWeight: 600,
    marginTop: 1
  };

  const profile_divider: SxProps<Theme> = {
    mb: "2rem",
    mt: "1.5rem",
    borderBottomWidth: "medium"
  };

  const profile_content_paper: SxProps<Theme> = {
    borderRadius: "6px",
    boxShadow: "none",
    padding: "1rem"
  };

  const item_typography: SxProps<Theme> = {
    ml: "1rem",
    fontSize: "1.125rem",
    fontWeight: 600,
    flex: "none"
  };

  const company_data_blue: SxProps<Theme> = {
    fontSize: "1rem",
    fontWeight: 500,
    color: COLORS.secondary
  };

  const company_logo: CSSProperties = {
    objectFit: "cover",
    borderRadius: "50%"
  };

  const add_icon_button: SxProps<Theme> = {
    backgroundColor: COLORS.secondary,
    "&:hover": {
      backgroundColor: COLORS.secondary
    },
    width: 40,
    height: 40
  };

  const fileName: CSSProperties = {
    fontSize: "16px",
    color: COLORS.secondary,
    fontWeight: 600,
    marginLeft: "1.5rem",
    maxWidth: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  };

  const companyIconStyle: IconProps = {
    icon: "company",
    color: COLORS.primary,
    style: { minWidth: "24px" }
  }

  return {
    profile_view_container,
    title_container,
    view_title_typography,
    profile_divider,
    profile_content_paper,
    item_typography,
    categories_typography,
    company_data_blue,
    company_logo,
    fileName,
    add_icon_button,
    companyIconStyle
  };
};
