import Operator from "@domain/model/Operator";
import { CREATE_ID, TIMEOUT_ID } from "@constants";
import { LastPageState } from "@domain/model/LastPageState";
import { useMasterDataMessagges } from "@hooks/useMasterDataMessages";
import { ListOperatorsPaginatedRequest } from "@clients/aggrego-proxy";

export const useOperatorSave = (
  currentPagePersistedState: LastPageState,
  selectedOperator: Operator,
  save: (entity: Operator) => Promise<void>,
  fetchOne: (id: string) => Promise<void>,
  goToList: (criteria: ListOperatorsPaginatedRequest) => Promise<void>,
  getCurrentStatus: (status: "ACTIVE" | "INACTIVE" | "ALL") => boolean | undefined
) => {
  const { successMessaggesSaving, errorMessaggesSaving, errorMessaggesLoading } = useMasterDataMessagges();

  const handleEdit = (id: string) => {
    fetchOne(id).catch(() => {
      errorMessaggesLoading("operator", selectedOperator.firstName ?? "");
      selectedOperator.id = TIMEOUT_ID;
    });
  };

  const handleSave = async (operator: Operator) => {
    if (operator.id === CREATE_ID) {
      operator.active = true;
    }

    save(operator)
      .then(() => successMessaggesSaving("operator", operator.firstName ?? "", operator.id))
      .catch(error => errorMessaggesSaving("operator", operator.firstName ?? "", operator.id, error))
      .finally(() => {
        goToList({
          limit: currentPagePersistedState.limit,
          offset: currentPagePersistedState.offset,
          customOperatorFilter: {
            ...currentPagePersistedState.filters,
            active: getCurrentStatus(currentPagePersistedState.status)
          },
          sortDirection: currentPagePersistedState.sortDirection,
          sortField: currentPagePersistedState.sortField
        } as ListOperatorsPaginatedRequest);
      });
  };

  return {
    handleEdit,
    handleSave
  };
};
