import "./no-records-found.css";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const NoRecordsFound = function () {
  const { t } = useTranslation();
  return (
    <div id="no_records_container">
      <Typography variant="summarydashboard" justifyItems="center" sx={{ fontWeight: 500 }}>
        {t("table.no.rows")}
      </Typography>
      <Typography variant="summarydashboard" sx={{ fontSize: "0.875rem" }}>
        {t("table.no.rows.description")}
      </Typography>
    </div>
  );
};
