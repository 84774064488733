import Family from "@domain/model/Family";
import { useResolve } from "@movicoders/di";
import { useEffect, useRef, useState } from "react";
import { IRepositoryPaginated } from "@movicoders/domain";
import { LastPageState } from "@domain/model/LastPageState";
import { ObjectType } from "@movicoders/ui/lib/ViewModels/types";
import { useListDetailPaginatedViewModel } from "@movicoders/ui";
import { IUserService, UserService } from "@domain/services/IUserService";
import { ILastPageService, LastPageService } from "@domain/services/ILastPageService";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { MovementsRepository } from "@infrastructure/repositories/movements-repository";
import { FindFamiliesByTenantUseCase } from "@application/master-data/families/find-families-by-tenant-use-case";
import { FullUserDTOCompanyLicenseEnum, ListMovementEventPageRequest, MovementEventPageDTO } from "@clients/aggrego-proxy";
import { ReportEnum } from "@pages/settings/settings-interfaces";

export const useMovementsHandleData = () => {
  const { data, getPaginated, loading } = useListDetailPaginatedViewModel(
    MovementsRepository as unknown as ObjectType<IRepositoryPaginated<MovementEventPageDTO, ListMovementEventPageRequest>>
  );
  const movementsFetch = useRef(getPaginated).current;

  const settingsService = useResolve<ISettingsService>(SettingsService);
  const currentPageService = useResolve<ILastPageService>(LastPageService);
  const currentPagePersistedState = currentPageService.persistedState ?? new LastPageState();
  const currentUserPersistedState = useResolve<IUserService>(UserService);

  const [columnsByMode, setColumnsByMode] = useState<string[]>([]);

  const findFamiliesByTenantUseCase = useResolve(FindFamiliesByTenantUseCase);
  const [families, setFamilies] = useState<Family[]>([]);

  const generateColumns = () => {
    switch (currentUserPersistedState.user?.companyLicense) {
      case FullUserDTOCompanyLicenseEnum.Im:
        setColumnsByMode(["id", "origin", "destiny", "container"]);
        break;
      case FullUserDTOCompanyLicenseEnum.Wm:
        setColumnsByMode(["id", "container"]);
        break;
      case FullUserDTOCompanyLicenseEnum.WmWithContainer:
        setColumnsByMode(["id"]);
        break;
    }
  };

  useEffect(() => {
    if (settingsService.persistedState?.reportsPermissions.includes(ReportEnum.MovementsReport)) {
      findFamiliesByTenantUseCase.execute("ALL").then(result => {
        setFamilies(result);
      });
      generateColumns();
    }
  }, [settingsService.persistedState]);

  return {
    currentPageService,
    currentPagePersistedState,
    columnsByMode,
    data,
    loading,
    families,
    movementsFetch
  };
};
