// Disabled because elements from ThreeJS that start with lowercase
// are not allowed by eslint no-unknown-property rule.
/* eslint-disable react/no-unknown-property */
import { useThree } from "@react-three/fiber";
import { OrbitControls, Select } from "@react-three/drei";
import { ROUTER_PATHS } from "../../../../constants";
import { OrbitControls as OrbitControlsType } from "three-stdlib";
import { useKeyboard } from "../utils/controls/useKeyboard";
import { generateUUID } from "three/src/math/MathUtils";
import { Tile } from "./generate-tile";
import { generateGridHelpers } from "./grid-helper-generator";
import { DEFAULT_COLORS } from "../constants/Colors";
import { CustomPivotControls } from "./custom-pivot-controls/custom-pivot-controls";
import { use3DViewerViewModel } from "../viewmodels/use3DViewerViewModel";
import { Fragment, useEffect, useRef, useState } from "react";
import { Mesh, Vector3 } from "three";
import { IEditionStatesStore } from "../stores/edition-states-store/IEditionStatesStore";
import { useEditionStatesStore } from "../stores/edition-states-store/edition-states-store";
import { useResolve } from "@movicoders/di";
import { useMouse3D } from "../utils/controls/click-controls/click-controls-3d";
import { ICommon3DObjectsStore } from "../stores/common-3d-objects-store/ICommon3DObjectsStore";
import { useCommon3DObjectsStore } from "../stores/common-3d-objects-store/common-3d-objects-store";
import { IWarehouse3DStore } from "../stores/warehouse-3d-store/IWarehouse3DStore";
import { useWarehouse3DStore } from "../stores/warehouse-3d-store/warehouse-3d-store";
import { MODES } from "../constants/Modes";
import { useZoneModeHandler } from "../utils/zones/useZoneModeHandler";

const Warehouse3DContainer = () => {
  const { getWarehouse } = useResolve<IWarehouse3DStore>(useWarehouse3DStore);
  const { getSelectedMode } = useResolve<IEditionStatesStore>(useEditionStatesStore);
  const { setHighlightMesh } = useResolve<ICommon3DObjectsStore>(useCommon3DObjectsStore);

  // Hook that gets specific objects from the canvas, can only be used inside it.
  const { camera, scene } = useThree();

  // Main Viewmodel of the viewer.
  use3DViewerViewModel(scene);

  // Zone mode handler.
  const { handleChangeSelectedTiles, handleHover, handlePointerLeave } = useZoneModeHandler();

  // Warehouse variables.
  const whX = getWarehouse().sizeX ?? 0;
  const whY = getWarehouse().sizeZ ?? 0;
  const whZ = getWarehouse().maxSizeY ?? 0;

  // Modes without hightlightMesh
  const MODES_WITHOUT_MESH: MODES[] = ["VIEW", "ZONES"];

  // Controls of the canvas.
  const controls = useRef<OrbitControlsType>(null);

  // Camera rotation
  const [rotationEnabled, setRotationEnabled] = useState(true);

  // Selection Square
  const highlightMesh = useRef<Mesh>(null);

  // Keyboard controls handler.
  useKeyboard(scene, controls, camera);

  // Mouse controls handler.
  useMouse3D(scene, camera, setRotationEnabled)

  // Sets the highlight mesh when it is first created.
  useEffect(() => {
    highlightMesh.current && setHighlightMesh(highlightMesh.current);
  }, [highlightMesh.current])

  // UseEffect to remove unsaved changes when changing mode.
  useEffect(() => {
    setRotationEnabled(true);
  }, [getSelectedMode()]);

  // Remove right click context menu in the viewer.
  document.addEventListener("contextmenu", event => {
    if (window.location.pathname === ROUTER_PATHS.warehouseViewer) {
      event.preventDefault();
    }
    return false;
  });

  return (
    <>
      <ambientLight color="#ffffff" intensity={0.1} />
      <hemisphereLight color="#ffffff" groundColor="#080820" intensity={1.0} />
      <CustomPivotControls />
      <OrbitControls
        ref={controls}
        target={new Vector3(whX / 2, 0, whY / 2)}
        maxDistance={(whX + whY) / 2 > whZ ? (whX + whY) / 2 : whZ + whZ / 2}
        enablePan={false}
        makeDefault
        autoRotate={getSelectedMode() === "VIEW" && rotationEnabled}
        autoRotateSpeed={0.2}
        maxPolarAngle={1.5}
        dampingFactor={0.2}
      />
      {(getSelectedMode() === "ZONES") ?
        <Select
          multiple box
          onChange={handleChangeSelectedTiles}
          onPointerOver={handleHover}
          onPointerLeave={handlePointerLeave}
        >
          {getWarehouse().tiles?.map(tile => {
            if (tile.active)
              return <Tile key={tile.id} tile={tile} />

            return null;
          })}
        </Select>
        :
        <Fragment>
          {getWarehouse().tiles?.map(tile => {
            if (tile.active)
              return <Tile key={tile.id} tile={tile} />

            return null;
          })}
        </Fragment>
      }

      {generateGridHelpers(whX, whY).map(helper => (
        <gridHelper args={[helper.size, helper.size]} position={[helper.x, 0, helper.z]} key={generateUUID()} />
      ))}

      <mesh rotation-x={Math.PI * -0.5} position={[whX / 2, 0, whY / 2]} name="ground">
        <planeGeometry args={[whX, whY]} />
        <meshBasicMaterial color={DEFAULT_COLORS.darkgrey} visible={false} />
      </mesh>

      <mesh ref={highlightMesh} position={[1, 0, 1]} rotation-x={Math.PI * -0.5} visible={!MODES_WITHOUT_MESH.includes(getSelectedMode())}>
        <planeGeometry args={[1, 1]} />
        <meshBasicMaterial />
      </mesh>
    </>
  );
};

export default Warehouse3DContainer;
