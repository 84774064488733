// Disabled because elements from ThreeJS that start with lowercase
// are not allowed by eslint no-unknown-property rule.
/* eslint-disable react/no-unknown-property */

import { Text } from "@react-three/drei";
import { useTranslation } from "react-i18next";
import { defaultVerticalPlaneGeometry } from "../utils/detail-geometries";
import { defaultWhiteMaterial } from "../utils/capacity-materials";
import { DEFAULT_PLANE_MATERIAL } from "../../constants/default-materials";
import { DEFAULT_PLANE_GEOMETRY } from "../../constants/default-geometries";

export const EmptyLevel = (props: { level: number }) => {
  const { t } = useTranslation();
  return (
    <group>
      <mesh position={[0, props.level - 1, 0]} geometry={DEFAULT_PLANE_GEOMETRY} material={DEFAULT_PLANE_MATERIAL} />
      <mesh position={[0, props.level - 0.95, 0.499]} material={defaultWhiteMaterial} geometry={defaultVerticalPlaneGeometry} />
      <Text color="black" anchorX="center" anchorY="bottom" position={[0, props.level - 0.99, 0.5]} fontSize={0.1}>
        {t("viewer.details.empty.level")}
      </Text>
    </group>
  );
};
