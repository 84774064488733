import { ReportApi } from "../api/report-api";
import { DomainPage } from "@movicoders/domain";
import { InvRegistryDTO } from "@domain/model/InvRegistry";
import { GetInventoryRegistryPaginatedByFiltersRequest } from "@clients/aggrego-proxy";

export class InventoryRegistryRepository {
  constructor(private readonly reportApi: ReportApi) {}

  getPaginated(pageCriteria: GetInventoryRegistryPaginatedByFiltersRequest): Promise<DomainPage<InvRegistryDTO>> {
    return this.reportApi.getInventoryRegistryPaginated(pageCriteria);
  }
}
