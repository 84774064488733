export const SHORT_LENGTH = 10;
export const MEDIUM_LENGTH = 15;
export const MAX_LENGTH = 250;
export const EXTRA_LENGTH = 1000;

export const MAX_LENGTH_MAP: { [key: string]: number } = {
  postalCode: SHORT_LENGTH,
  fiscalId: MEDIUM_LENGTH,
  phone: MEDIUM_LENGTH,
  comments: EXTRA_LENGTH
};
