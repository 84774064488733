/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Credentials } from './Credentials';
import {
    CredentialsFromJSON,
    CredentialsFromJSONTyped,
    CredentialsToJSON,
} from './Credentials';
import type { Profile } from './Profile';
import {
    ProfileFromJSON,
    ProfileFromJSONTyped,
    ProfileToJSON,
} from './Profile';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {Credentials}
     * @memberof User
     */
    credentials?: Credentials;
    /**
     * 
     * @type {Profile}
     * @memberof User
     */
    profile?: Profile;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'credentials': !exists(json, 'credentials') ? undefined : CredentialsFromJSON(json['credentials']),
        'profile': !exists(json, 'profile') ? undefined : ProfileFromJSON(json['profile']),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'credentials': CredentialsToJSON(value.credentials),
        'profile': ProfileToJSON(value.profile),
    };
}

