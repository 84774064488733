import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import { useTranslation } from "react-i18next";
import { logisticStyles } from "../logistic-styles";
import { Button, Divider, Grid, Paper } from "@mui/material";
import IMMEntryWithDesc from "@domain/model/IMMEntryWithDesc";
import { InventoryMaterialTabs } from "./inventory-material-tabs";
import { useLogisticSyncViewModel } from "../hooks/useLosgisticSyncViewModel";
import { OutletContainer } from "@components/outlet-container/outlet-container";
import { InventoryLogisticCard } from "@components/logistic-view/inventory-logistic-card";
import { LogisticErrorDialog } from "../components/logistic-error-dialog/logistic-error-dialog";
import { useHandleErrorDialog } from "../components/logistic-error-dialog/useHandleErrorDialog";

export const InventoryMaterialSyncView = () => {
  const { t } = useTranslation();
  const styles = logisticStyles();

  const { cardsArray, handleTabSelect, handleReturnBackToMainInventoryMaterialView } = useLogisticSyncViewModel();
  const { errorMessage, handleErrorDialog } = useHandleErrorDialog();

  return (
    <>
      <OutletContainer>
        <>
          <Grid container id="first-grid-container" direction="column" mb={1}>
            <Grid item>
              <Grid container wrap="nowrap" spacing={{ xs: "1rem" }} id="second-grid-container">
                <Grid item alignSelf="center">
                  <Icon icon="box" style={{ marginTop: 7 }} color={COLORS.primary} />
                </Grid>
                <Grid id="inbounds-title" item sx={{ color: COLORS.secondary, fontSize: "x-large", fontWeight: 600 }}>
                  {t("inventory.material")}
                </Grid>
              </Grid>
            </Grid>
            <Divider sx={styles.divider} />
          </Grid>
          <InventoryMaterialTabs onClick={handleTabSelect} />
          <Paper sx={{ p: 1, borderRadius: "6px 6px 6px 6px", boxShadow: "0px 3px 6px #00000029" }}>
            <Grid item sx={{ pt: 1, pb: 0.7 }}>
              <Grid container spacing={{ xs: 1, md: 1 }} mt={1} sx={{ pt: 0.5, pb: 0.7 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {cardsArray?.map((iMMaterial: IMMEntryWithDesc, index: number) => (
                  <Grid item xs={12} sm={4} key={`grid-card-${iMMaterial.id}`}>
                    <InventoryLogisticCard
                      id={iMMaterial?.id ?? ""}
                      IMEntry={iMMaterial}
                      isSynchronizing={true}
                      isOk={iMMaterial.errorMessage === "OK"}
                      errorMessage={iMMaterial.errorMessage}
                      handleErrorDialog={error => {
                        handleErrorDialog("OPEN", error);
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Button id="inbounds-sync-return-button" variant="primary" onClick={handleReturnBackToMainInventoryMaterialView}>
              {t("root.return")}
            </Button>
          </Paper>
        </>
      </OutletContainer>
      <LogisticErrorDialog onClose={() => handleErrorDialog("CLOSE")} error={errorMessage} />
    </>
  );
};
