import {
  IconButton,
  Menu,
  Grid,
  Select,
  MenuItem,
  TextField,
  Button,
  InputLabel,
  Box,
  Theme,
  useMediaQuery,
  OutlinedInput,
  Tooltip,
  Typography,
  Autocomplete,
  CircularProgress
} from "@mui/material";
import { COLORS } from "@theme";
import Icon from "../../../../icons/icon";
import { GridColDef } from "@mui/x-data-grid";
import { filterValueTypes } from "@constants";
import { useTranslation } from "react-i18next";
import { convertDateToString } from "@utils/date-helper";
import { useHandleFilter } from "./hooks/useHandleFilterInputState";
import { ToolbarFilter } from "../../config/data-grid-display-status";
import { ExpandableChips } from "./components/data-grid-display-expandable-chips";
import { dataGridDisplayFilterStyles } from "./styles/data-grid-display-filter-styles";
import { DataGridDisplayDateInput } from "./components/data-grid-display-form-date-input";

export const DataGridDisplayFilter = (props: {
  disabledFilters: string[];
  columns: GridColDef[];
  setCurrentFilter: (newFilter: ToolbarFilter) => void;
  isFilterActive: boolean;
  multipleSelectOptions?: Map<string, string[]>;
  isTable: boolean;
}) => {
  const { disabledFilters, columns, setCurrentFilter, isFilterActive, multipleSelectOptions: valueOptions, isTable } = props;
  const { t } = useTranslation();
  const styles = dataGridDisplayFilterStyles();
  const matchesLg = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"), { noSsr: true });
  const matchesMd = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"), { noSsr: true });
  const matchesSmPlus = useMediaQuery((theme: Theme) => theme.breakpoints.up(650), { noSsr: true });
  const matchesSm = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"), { noSsr: true });

  const {
    selectedField,
    selectedColumn,
    currentValue,
    selectOneValue,
    multipleSelectedValues,
    dateEntry,
    dateFinal,
    setDateEntry,
    setDateFinal,
    anchorElFilterMenu,
    checkInitDateBelowFinal,
    dateEntryErrorText,
    dateFinalErrorText,
    filterButtonIsDisabled,
    handleChangeField,
    handleChangeSelectOneValues,
    handleChangeMultipleValues,
    masterdataPlaceholder,
    handleChangeAutocompleteOneValue,
    handleSearchInputChange,
    handleChangeValue,
    handleOpenFilterMenu,
    handleSubmitFilter,
    handleToggleSelectOpened,
    openFilterMenu,
    valuesSelectUnfolded,
    handleCloseFilterMenu,
    handleClearFilter,
    lastPagePersistedState
  } = useHandleFilter(columns, disabledFilters, isTable, setCurrentFilter);

  const renderFilterComponent = () => {
    if ((selectedColumn?.type as filterValueTypes) === "range-date")
      return (
        <>
          <DataGridDisplayDateInput
            value={dateEntry as unknown as Date}
            onChange={(value, keyInput) => {
              value = value as Date;
              setDateEntry(value as string);
            }}
            id="date-entry-filter"
            name={"date-entry-filter"}
            label={t("table.filter.init.date")}
            errorText={dateEntryErrorText}
            helperText={""}
            minDate={"01/01/1900"}
            maxDate={convertDateToString(new Date(), "MM/dd/yyyy")}
            size="small"
            openPickerButtonId="date-entry-open-date-picker"
          />
          <br />
          <DataGridDisplayDateInput
            value={dateFinal as unknown as Date}
            onChange={(value, keyInput) => {
              value = value as Date;
              setDateFinal(value as string);
            }}
            id="date-final-filter"
            name={"date-final-filter"}
            label={t("table.filter.final.date")}
            errorText={dateFinalErrorText}
            helperText={""}
            minDate={"01/01/1900"}
            maxDate={convertDateToString(new Date(), "MM/dd/yyyy")}
            size="small"
            openPickerButtonId="date-final-open-date-picker"
          />
        </>
      );

    if (
      (selectedColumn?.type as filterValueTypes) === "multiple-select" ||
      (selectedColumn?.type as filterValueTypes) === "select"
    )
      return (
        <Select
          multiple
          id="table-filter-multiple-select"
          value={multipleSelectedValues}
          size="small"
          color="secondary"
          sx={{
            ...styles.select_variable_height,
            height: valuesSelectUnfolded ? "unset" : "2.5rem",
            marginTop: !matchesSm ? "1rem" : "0rem"
          }}
          MenuProps={{ style: { maxHeight: 725 } }}
          onOpen={handleToggleSelectOpened}
          onClose={handleToggleSelectOpened}
          renderValue={selected => (
            <ExpandableChips displayedOptions={selected.map(item => t(item))} unfolded={valuesSelectUnfolded} />
          )}
          onChange={event => {
            handleChangeMultipleValues(event);
          }}
          input={<OutlinedInput id="select-multiple-chip" />}
          fullWidth>
          {valueOptions !== undefined ? (
            valueOptions.get(selectedColumn?.field ?? "")?.map(option => (
              <MenuItem id={option} key={option} value={option} sx={{ fontSize: "small", color: COLORS.grey }}>
                {t(option)}
              </MenuItem>
            ))
          ) : (
            <MenuItem key="" value="" sx={{ fontSize: "small", color: COLORS.grey }}>
              {""}
            </MenuItem>
          )}
        </Select>
      );
    if ((selectedColumn?.type as filterValueTypes) === "select-one")
      return (
        <Select
          id="table-filter-select-one"
          label="buscqueda"
          value={selectOneValue}
          size="small"
          color="secondary"
          sx={{
            ...styles.select_variable_height,
            height: valuesSelectUnfolded ? "unset" : "2.5rem",
            marginTop: !matchesSm ? "1rem" : "0rem"
          }}
          MenuProps={{ style: { maxHeight: 725 } }}
          onOpen={handleToggleSelectOpened}
          onClose={handleToggleSelectOpened}
          renderValue={() => <Typography sx={{ fontSize: "small", color: COLORS.grey }}>{t(selectOneValue)}</Typography>}
          onChange={event => {
            handleChangeSelectOneValues(event);
          }}
          input={<OutlinedInput id="select-multiple-chip" />}
          fullWidth>
          {valueOptions !== undefined ? (
            valueOptions.get(selectedColumn?.field ?? "")?.map(option => (
              <MenuItem id={option} key={option} value={option} sx={{ fontSize: "small", color: COLORS.grey }}>
                {t(option)}
              </MenuItem>
            ))
          ) : (
            <MenuItem key="" value="" sx={{ fontSize: "small", color: COLORS.grey }}>
              {""}
            </MenuItem>
          )}
        </Select>
      );

    if ((selectedColumn?.type as filterValueTypes) === "autocomplete-one")
      return (
        <Autocomplete
          id="table-filter-autocomplete-one"
          value={masterdataPlaceholder}
          options={
            valueOptions && valueOptions !== undefined
              ? (valueOptions.get(selectedColumn?.field ?? "") as unknown as string[])
              : []
          }
          loading={lastPagePersistedState.autocompleteFilterLoading}
          onChange={(event, value, reason) => {
            handleChangeAutocompleteOneValue(value ?? "");
          }}
          size="small"
          sx={{ marginTop: !matchesSm ? "1.75rem" : "0rem" }}
          disableClearable
          noOptionsText={
            masterdataPlaceholder && masterdataPlaceholder?.length >= 3 && masterdataPlaceholder !== undefined
              ? t("autocomplete.default.no.options")
              : t("autocomplete.input.material.required")
          }
          //Avoids warning: The value provided to Autocomplete is invalid. None of the options match with "". You can use the `isOptionEqualToValue` prop to customize the equality test.
          freeSolo
          renderInput={params => (
            <TextField
              placeholder={t("autocomplete.default.value")}
              name={"type-name"}
              {...params}
              label={t(`table.filter.autocomplete.${selectedColumn?.field}`)}
              className={"blue-border-input"}
              size="small"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {lastPagePersistedState.autocompleteFilterLoading ? (
                      <CircularProgress sx={{ ml: "2rem", mb: "0.5rem" }} color="secondary" size={16} />
                    ) : (
                      <Grid sx={{ mt: "-0.4rem" }}>
                        <Icon icon="chevron-down" color={COLORS.secondary} />
                      </Grid>
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
                style: { height: "2.2rem", marginTop: "0.3rem" },
                onChange: handleSearchInputChange
              }}
              onKeyDown={e => e.stopPropagation()}
            />
          )}
          getOptionLabel={masterdata => masterdata ?? ""}
          renderOption={(props, masterdata) => (
            <li {...props}>
              <div>{masterdata}</div>
            </li>
          )}
        />
      );

    return (
      <TextField
        id="table-filter-menu-textfield"
        fullWidth
        sx={{ ...styles.table_input, marginTop: !matchesSm ? "1.7rem" : "0rem" }}
        label={t("table.filter.search")}
        value={currentValue}
        onChange={handleChangeValue}
        type={selectedColumn?.type}
        onKeyDown={e => e.stopPropagation()}
      />
    );
  };

  return (
    <>
      <Tooltip title={t("tooltip.filters")}>
        <IconButton id="table-filter-menu-open" onClick={handleOpenFilterMenu} className="table-icon-button">
          <Icon icon="filter" color={isFilterActive ? COLORS.secondary : COLORS.gray} />
        </IconButton>
      </Tooltip>
      <Menu id="table-filter-menu" anchorEl={anchorElFilterMenu} open={openFilterMenu} onClose={handleCloseFilterMenu}>
        <Box
          sx={{ width: matchesLg ? "40vw" : matchesMd ? "55vw" : matchesSmPlus ? "70vw" : matchesSm ? "80vw" : "93vw" }}
          component="form"
          onSubmit={e => {
            e.preventDefault();
            if (checkInitDateBelowFinal()) {
              handleSubmitFilter();
            }
          }}>
          <Grid container direction="column" sx={styles.table_filter_container} data-cy="filters-grid">
            {t("table.filter.title")}
            <Grid container mt="1rem" justifyContent="space-between" columnSpacing={{ xs: 1 }}>
              <Grid item sm={6} xs={12}>
                <InputLabel
                  shrink={true}
                  style={{ position: "absolute", top: "3.46rem", left: "1.25rem", fontSize: "0.95rem", color: COLORS.secondary }}>
                  {t("table.filter.columns")}
                </InputLabel>
                <Select
                  id="table-filter-menu-select"
                  size="small"
                  color="secondary"
                  sx={styles.table_filter_select}
                  className="table-select"
                  value={selectedField}
                  onChange={handleChangeField}>
                  {columns.map(column => {
                    return disabledFilters?.includes(column.field) ? null : (
                      <MenuItem
                        id={`table-filter-menu-option-${column.field}`}
                        value={column.field}
                        key={column.field}
                        sx={{ fontSize: "small", color: COLORS.grey }}>
                        {column.headerName || column.field}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item sm={6} xs={12}>
                {renderFilterComponent()}
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Button id="table-filter-menu-clean" onClick={handleClearFilter} size="small" variant="secondary">
                {t("table.filter.clear")}
              </Button>
              <Button id="table-filter-menu-apply" disabled={filterButtonIsDisabled} size="small" color="secondary" type="submit">
                {t("table.filter.filter")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Menu>
    </>
  );
};
