import {
  Button,
  TextField,
  Grid,
  Typography,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  Divider
} from "@mui/material";
import Icon from "@components/icons/icon";
import { ChangeEvent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLoginStatus } from "@hooks/useLogin";
import { loginCardStyles } from "./login-card-styles";
import { useLoginCardViewModel } from "../hooks/useLoginCardViewModel";
import { COLORS } from "@theme";

type TLoginCard = {
  onClick: () => void;
  open: boolean;
};

const LoginCard = ({ onClick, open }: TLoginCard) => {
  const { t } = useTranslation();

  const {
    username,
    password,
    twoFactorCode,
    handleUsernameChange,
    handlePasswordChange,
    handleTwoFactorCodeChange,
    resetFields
  } = useLoginCardViewModel();

  const {
    userService,
    isLoginError,
    showForceFinishingRemoteSession,
    setIsLoginError,
    handleLogin,
    handleTwoFactorLogin,
    onChangeTwoFactorMode,
    handleForceFinishingRemoteSession,
    handleLoginInRemoteSession,
    twoFactor,
    twoFactorMode,
    resetTwoFactorMode
  } = useLoginStatus();

  const styles = loginCardStyles();
  const passwordTextField = useRef<HTMLElement>(null);
  const mfaTextField = useRef<HTMLElement>(null);

  return (
    <Grid container flexDirection="column" sx={styles.login_box} style={{ display: open ? "none" : "" }}>
      <Typography variant="title">
        {!twoFactor ? t("login.title") : t(`login.two.factor.title.${twoFactorMode.toLowerCase()}`)}
      </Typography>
      {!twoFactor ? (
        <>
          {/* LOGIN MODE */}
          <TextField
            id="username-input"
            onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              setIsLoginError(false);
              handleUsernameChange(event);
            }}
            label={t("login.username")}
            error={isLoginError}
            onKeyUp={event => {
              if (event.key === "Enter") passwordTextField.current?.focus();
            }}
            inputProps={{ enterKeyHint: "next" }}
          />
          <Collapse in={!open} timeout={{ appear: 300, enter: 300, exit: 300 }}>
            <TextField
              id="password-input"
              type="password"
              onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                setIsLoginError(false);
                handlePasswordChange(event);
              }}
              label={t("login.password")}
              error={isLoginError}
              onKeyUp={event => {
                if (event.key === "Enter") {
                  handleLogin(username, password);
                  passwordTextField.current?.blur();
                }
              }}
              inputProps={{ ref: passwordTextField, enterKeyHint: "done" }}
            />
          </Collapse>
          <Button
            id="login-button"
            color="secondary"
            onClick={() => handleLogin(username, password)}
            disabled={username.length === 0 || password.length === 0}>
            {t("root.accept")}
          </Button>
          <Typography
            component="span"
            onClick={onClick}
            className="hover"
            variant="subText"
            data-testid="recover-link"
            width="fit-content"
            alignSelf="flex-end">
            {t("login.forget_password")}
          </Typography>
        </>
      ) : (
        /* TWO FACTOR MODE */
        <Grid container display={"flex"} flexDirection={"column"} spacing={1}>
          <Grid item xs={12}>
            <TextField
              id="username-input"
              label={t(`login.mfa.code.${twoFactorMode.toLowerCase()}`)}
              onChange={handleTwoFactorCodeChange}
              value={twoFactorCode}
              onKeyUp={event => {
                if (event.key === "Enter") {
                  handleTwoFactorLogin(username, password, twoFactorCode);
                  mfaTextField.current?.blur();
                }
              }}
              inputProps={{ ref: mfaTextField, enterKeyHint: "done" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              id="two-factor-button-accept"
              color="secondary"
              onClick={() => handleTwoFactorLogin(username, password, twoFactorCode)}
              disabled={twoFactorCode.length === 0}
              fullWidth>
              {t("root.accept")}
            </Button>
          </Grid>
          {userService.user?.enabledNFAEmail && userService.user.enabledNFAOPT && (
            <>
              <Grid item xs={12}>
                <Divider>
                  <Typography variant="subText">{t("login.two.factor.prepostion")}</Typography>
                </Divider>
              </Grid>
              <Grid item xs={12}>
                <Button
                  id="two-factor-button-change"
                  color="secondary"
                  sx={{ fontSize: "0.70rem" }}
                  onClick={onChangeTwoFactorMode}
                  fullWidth>
                  {t(`login.change.mfa.mode.from.${twoFactorMode.toLowerCase()}`)}
                </Button>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Typography
              component="span"
              onClick={() => {
                resetFields();
                resetTwoFactorMode();
              }}
              className="hover"
              variant="subText"
              width="fit-content">
              {t("recover.return")}
            </Typography>
          </Grid>
        </Grid>
      )}

      <Dialog
        open={showForceFinishingRemoteSession}
        id="mui-dialog"
        maxWidth="sm"
        className="save-dialog"
        onClose={() => handleForceFinishingRemoteSession(false)}>
        <DialogTitle>
          <div id="dialog-title-name" style={{ textOverflow: "ellipsis", fontSize: "1.125rem", overflow: "hidden" }}>
            {t("login.finish.remote.session")}
          </div>
          <IconButton id="close-dialog" onClick={() => handleForceFinishingRemoteSession(false)}>
            <Icon icon="close" color={COLORS.white} />
          </IconButton>
        </DialogTitle>
        <DialogContent id="dialog-content">
          <Grid container flexDirection="column" className="masterdata-box">
            {t("login.finish.remote.session.description")}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button id="dialog-cancel-button" variant="secondary" onClick={() => handleForceFinishingRemoteSession(false)}>
            {t("button.cancel")}
          </Button>
          <Button autoFocus type="submit" id="dialog-accept-button" onClick={() => handleLoginInRemoteSession()}>
            {t("button.accept")}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default LoginCard;
