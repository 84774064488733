import { useEffect } from "react";
import { useResolve } from "@movicoders/di";
import { LastPageState } from "@domain/model/LastPageState";
import { ILastPageService, LastPageService } from "@domain/services/ILastPageService";
import { DataGridDisplayFooterProps } from "../footer/config/data-grid-display-footer-props";

export const useHandlePageSelector = (props: DataGridDisplayFooterProps) => {
  const { currentPage, dataLength, setCurrentPage, pageSize, backendTotalElements } = props;

  const lastPageService = useResolve<ILastPageService>(LastPageService);
  const lastPagePersistedState = lastPageService.persistedState ?? new LastPageState();

  const totalElements = backendTotalElements ?? dataLength;
  const totalPages = Math.ceil(totalElements / pageSize);
  const firstElement = totalElements === 0 ? 0 : lastPagePersistedState.offset + 1;
  const lastElement =
    lastPagePersistedState.offset + 1 + pageSize > totalElements ? totalElements : lastPagePersistedState.offset + pageSize;

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    if (currentPage >= totalPages - 1) {
      const newPage = totalPages !== 0 ? totalPages - 1 : 0;
      setCurrentPage(newPage);
    }
  }, [totalPages]);

  return { totalPages, firstElement, lastElement, handleNextPage, handlePreviousPage, totalElements, currentPage };
};
