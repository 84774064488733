// Disabled because elements from ThreeJS that start with lowercase
// are not allowed by eslint no-unknown-property rule.
/* eslint-disable react/no-unknown-property */
import { Text } from "@react-three/drei";
import { Fragment } from "react";
import { BoxGeometry, MeshBasicMaterial, MeshLambertMaterial, MeshPhongMaterial } from "three";
import { defaultCylinderMaterialRed, defaultCylinderMaterialYellow, defaultCylinderMaterialGreen } from "../detail/utils/capacity-materials";
import { Tile3D } from "../types/Tile3D";
import { DEFAULT_PLANE_GEOMETRY, DEFAULT_CYLINDER_GEOMETRY, DEFAULT_TILE_TEMPLATE_GEOMETRY } from "../constants/default-geometries";
import { DEFAULT_PLANE_MATERIAL, DEFAULT_CYLINDER_MATERIAL_BLUE, CORRIDOR_MATERIAL } from "../constants/default-materials";
import { isWall } from "./column-helper";
import { useResolve } from "@movicoders/di";
import { IDialogsStatesStore } from "../stores/dialogs-states-store/IDialogsStatesStore";
import { useDialogsStatesStore } from "../stores/dialogs-states-store/dialogs-states-store";
import { COLORS } from "../../../../theme";
import { IWarehouse3DStore } from "../stores/warehouse-3d-store/IWarehouse3DStore";
import { useWarehouse3DStore } from "../stores/warehouse-3d-store/warehouse-3d-store";
import { IEditionStatesStore } from "../stores/edition-states-store/IEditionStatesStore";
import { useEditionStatesStore } from "../stores/edition-states-store/edition-states-store";
import { IZonesEdition3DStore } from "../stores/zones-edition-3d-store/IZonesEdition3DStore";
import { useZonesEdition3DStore } from "../stores/zones-edition-3d-store/zones-edition-3d-store";
import { generateUUID } from "three/src/math/MathUtils";
import { ICommon3DObjectsStore } from "../stores/common-3d-objects-store/ICommon3DObjectsStore";
import { useCommon3DObjectsStore } from "../stores/common-3d-objects-store/common-3d-objects-store";

interface ITile {
  tile: Tile3D;
}

export const Tile = ({ tile }: ITile) => {
  const { getWarehouse } = useResolve<IWarehouse3DStore>(useWarehouse3DStore);
  const { getSelectedBox } = useResolve<IDialogsStatesStore>(useDialogsStatesStore);
  const { getZones, getSelectedTilesIds, getSelectedZone } = useResolve<IZonesEdition3DStore>(useZonesEdition3DStore);
  const { getSelectedMode, checkTemplateTypeById } = useResolve<IEditionStatesStore>(useEditionStatesStore);
  const { getWallMaterial } = useResolve<ICommon3DObjectsStore>(useCommon3DObjectsStore);

  if (checkTemplateTypeById("STORAGE", tile.templateId)) {
    //Zone related variables
    const selectedInZone = getSelectedTilesIds().includes(tile.id ?? "");
    const highlightedTileGeometry = new BoxGeometry(1.21, (tile.maxY ?? 1) + 0.1, 1.21);

    const zoneColor = getZones().find(z => z.id === tile.zoneId)?.color ?? COLORS.secondary;
    const zoneMaterial = new MeshBasicMaterial({ color: tile.zoneId ? zoneColor : COLORS.gray });
    const selectedInZoneMaterial = new MeshPhongMaterial({ color: getSelectedZone()?.color ?? COLORS.secondary });

    // Variable to store each levels locations quantity and occupation percentage.
    const repeatedBinLevels: { binQuantity: number; percentage: number }[] = [];

    for (let i = 0; i < (tile.maxY ?? 0); i++) {
      const binsInLevel = tile.locations?.filter(b => b.level === i + 1 && b.active) ?? [];

      if (binsInLevel.length > 0) {
        const containersLength = binsInLevel.map(b => (b.containers ?? []).length).reduce((sum, containers) => sum + containers);
        const containersCapacity = binsInLevel.map(b => b.capacity ?? 0).reduce((sum, capacity) => sum + capacity);

        const percentage = containersCapacity !== 0 ? (containersLength / containersCapacity) * 100 : 0;
        repeatedBinLevels[i] = { binQuantity: binsInLevel.length, percentage: percentage };

      } else {
        repeatedBinLevels[i] = { binQuantity: 0, percentage: 0 };
      }
    }

    const getMaterial = (percentage: number) => {
      if (getSelectedMode() === "ZONES")
        return zoneMaterial
      else if (getSelectedBox()?.code === tile.code)
        return DEFAULT_CYLINDER_MATERIAL_BLUE
      else if (percentage === 0)
        return defaultCylinderMaterialGreen
      else if (percentage < 100)
        return defaultCylinderMaterialYellow
      else
        return defaultCylinderMaterialRed
    }

    return (
      <group
        position={[tile.x ?? -1, 0, tile.z ?? -1]}
        key={tile.code}
        name="tile"
        userData={{ tile: tile, firstPosition: [tile.x, tile.z] }}>
        {selectedInZone && <mesh position={[0, (tile.maxY ?? 1) / 2 - 0.45, 0]} geometry={highlightedTileGeometry} material={selectedInZoneMaterial} />}
        {repeatedBinLevels.map((binData, index) => {
          return (
            <Fragment key={generateUUID()}>
              {isWall(tile.x ?? -1, tile.z ?? -1, index, getWarehouse())}
              <mesh position={[0, index, 0]} geometry={DEFAULT_PLANE_GEOMETRY} material={DEFAULT_PLANE_MATERIAL} />
              {binData.binQuantity > 0 && (
                <>
                  <mesh
                    position={[0, index + 0.28, 0]}
                    geometry={DEFAULT_CYLINDER_GEOMETRY}
                    material={getMaterial(binData.percentage)}
                  />
                  {binData.binQuantity > 1 &&
                    <>
                      <Text color="black" fontSize={0.4} position={[0, index + 0.28, 0.3]} rotation={[0, 0, 0]}>
                        {binData.binQuantity}
                      </Text>
                      <Text color="black" fontSize={0.4} position={[0, index + 0.28, -0.3]} rotation={[0, Math.PI, 0]}>
                        {binData.binQuantity}
                      </Text>
                      <Text color="black" fontSize={0.4} position={[-0.3, index + 0.28, 0]} rotation={[0, -Math.PI / 2, 0]}>
                        {binData.binQuantity}
                      </Text>
                      <Text color="black" fontSize={0.4} position={[0.3, index + 0.28, 0]} rotation={[0, Math.PI / 2, 0]}>
                        {binData.binQuantity}
                      </Text>
                    </>
                  }
                </>
              )}
            </Fragment>
          );
        })}
      </group>
    );
  }
  const getTemplateMaterial = (id?: string): MeshLambertMaterial | MeshBasicMaterial | undefined => {
    if (checkTemplateTypeById("CORRIDOR", id)) return CORRIDOR_MATERIAL;
    if (checkTemplateTypeById("WALL", id)) return getWallMaterial();
    return undefined;
  }

  return (
    <group
      position={[tile.x ?? -1, 0, tile.z ?? -1]}
      key={tile.code}
      name="tile"
      userData={{ tile: tile, firstPosition: [tile.x, tile.z] }}>
      <mesh
        position={[0, 0.001, 0]}
        rotation-x={Math.PI * -0.5}
        geometry={DEFAULT_TILE_TEMPLATE_GEOMETRY}
        material={getTemplateMaterial(tile.templateId)}
      />
    </group>
  )
};
