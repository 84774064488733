import { useState } from "react";
import { ROUTER_PATHS } from "@constants";
import { useLocation, useNavigate } from "react-router-dom";
import IMMEntryWithDesc from "@domain/model/IMMEntryWithDesc";

export const useLogisticSyncViewModel = () => {
  const { state } = useLocation();
  const { all, passed, failed } = state;
  const [cardsArray, setCardsArray] = useState<IMMEntryWithDesc[]>(all);
  const navigate = useNavigate();

  const handleTabSelect = (id: string) => {
    if (id === "*") {
      setCardsArray(all);
    } else if (id === "correct") {
      setCardsArray(passed);
    } else if (id === "failed") {
      setCardsArray(failed);
    }
  };

  const handleReturnBackToMainInboundsView = () => {
    navigate(ROUTER_PATHS.inbounds, {
      state: { failedSync: failed, isOperatorLogged: failed.length > 0 ? true : false }
    });
  };

  const handleReturnBackToMainOutboundsView = () => {
    navigate(ROUTER_PATHS.outbounds, {
      state: { failedSync: failed, isOperatorLogged: failed.length > 0 ? true : false }
    });
  };

  const handleReturnBackToMainInventoryMaterialView = () => {
    navigate(ROUTER_PATHS.inventoryMaterial, {
      state: { failedSync: failed, isOperatorLogged: failed.length > 0 ? true : false }
    });
  };

  return {
    cardsArray,
    handleTabSelect,
    handleReturnBackToMainInboundsView,
    handleReturnBackToMainOutboundsView,
    handleReturnBackToMainInventoryMaterialView
  };
};
