import Family from "@domain/model/Family";
import { useResolve } from "@movicoders/di";
import { Dispatch, SetStateAction } from "react";
import FamilyType from "@domain/model/FamilyType";
import { CREATE_ID, TIMEOUT_ID } from "@constants";
import { LastPageState } from "@domain/model/LastPageState";
import { ILastPageService } from "@domain/services/ILastPageService";
import { useMasterDataMessagges } from "@hooks/useMasterDataMessages";
import { ListFamiliesPaginatedRequest } from "@clients/aggrego-proxy";
import { FamilyCreateUseCase } from "@application/master-data/families/create-familiy-use-case";
import { FindTypeOfFamiliesByTenantUseCase } from "@application/master-data/type-of-families/find-type-of-family-by-tenant-use-case";

export const useFamilySave = (
  currentPageService: ILastPageService,
  currentPagePersistedState: LastPageState,
  selectedFamily: Family,
  fetchOne: (id: string) => Promise<void>,
  goToList: (criteria?: ListFamiliesPaginatedRequest) => Promise<void>,
  getCurrentStatus: (status: "ALL" | "ACTIVE" | "INACTIVE") => boolean | undefined,
  setDataTypesOfFamilies: Dispatch<SetStateAction<FamilyType[]>>,
  findTypeOfFamiliesByTenantUseCase: FindTypeOfFamiliesByTenantUseCase
) => {
  const familyCreateUseCase = useResolve(FamilyCreateUseCase);
  const { successMessaggesSaving, errorMessaggesSaving, errorMessaggesLoading } = useMasterDataMessagges();

  const handleEdit = (id: string) => {
    fetchOne(id).catch(() => {
      errorMessaggesLoading("family", selectedFamily.name);
      selectedFamily.id = TIMEOUT_ID;
    });
  };

  /**
   * Handles the saving of the family
   * If the family id equals to "-1" sets the family active property to true
   * @param family The family to be saved
   * @param typeOfFamilyName The name of the new type of family for the family in case it has not been created yet
   */
  const handleSave = (family: Family, typeOfFamilyName?: string): void => {
    if (family.id === CREATE_ID) {
      family.active = true;
    }

    familyCreateUseCase
      .execute(family, typeOfFamilyName)
      .then(() => successMessaggesSaving("family", family.name, family.id))
      .catch(error => errorMessaggesSaving("family", family.name, family.id, error))
      .finally(() => {
        findTypeOfFamiliesByTenantUseCase.execute("ALL").then(result => {
          setDataTypesOfFamilies(result);
        });
        goToList({
          limit: currentPageService.persistedState?.limit ?? 12,
          offset: currentPageService.persistedState?.offset ?? 0,
          customFamilyFilter: { ...currentPagePersistedState.filters, status: getCurrentStatus(currentPagePersistedState.status) }
        });
      });
  };

  return {
    handleEdit,
    handleSave
  };
};
