import { Scene, Vector3, Quaternion } from "three";
import { boxExistsWithCoords } from "../box-finders";
import { IEditionStatesStore } from "../../stores/edition-states-store/IEditionStatesStore";
import { useResolve } from "@movicoders/di";
import { ICommon3DObjectsStore } from "../../stores/common-3d-objects-store/ICommon3DObjectsStore";
import { useCommon3DObjectsStore } from "../../stores/common-3d-objects-store/common-3d-objects-store";
import { useEditionStatesStore } from "../../stores/edition-states-store/edition-states-store";
import { IWarehouse3DStore } from "../../stores/warehouse-3d-store/IWarehouse3DStore";
import { useWarehouse3DStore } from "../../stores/warehouse-3d-store/warehouse-3d-store";

export const useHandleMoveTiles = (scene: Scene) => {
  const { getWarehouse } = useResolve<IWarehouse3DStore>(useWarehouse3DStore);
  const {
    getTilesBeingModified,
    setTilesBeingModified,
    getPivotControls
  } = useResolve<ICommon3DObjectsStore>(useCommon3DObjectsStore);

  const {
    setCanPlace,
    setModifyMinMax
  } = useResolve<IEditionStatesStore>(useEditionStatesStore);

  // Checks if the moving tiles can be placed.
  const canPlaceAll = () => {
    let canPlace = true;
    //Outside of wh boundaries
    getTilesBeingModified().forEach(tile => {
      if (
        tile.position.x <= 0 ||
        tile.position.x > (getWarehouse().sizeX ?? 0) ||
        tile.position.z <= 0 ||
        tile.position.z > (getWarehouse().sizeZ ?? 0) ||
        boxExistsWithCoords(tile.position.x, tile.position.z, scene).length > 1
      ) {
        canPlace = false;
      }
    });
    return canPlace;
  };

  const handleMoveTiles = () => {
    const pos = new Vector3();
    const rotation = new Quaternion();
    const scale = new Vector3();
    getPivotControls().matrix.decompose(pos, rotation, scale);

    const tempTiles = [...getTilesBeingModified()];
    tempTiles.forEach(group => {
      if (group !== undefined) {
        const nextX = Math.floor(pos.x) + group.position.x;
        const nextZ = Math.floor(pos.z) + group.position.z;
        group.position.set(nextX, group.position.y, nextZ);
      }
    });
    setTilesBeingModified(tempTiles);

    const maxX = Math.max(...tempTiles.map(g => g.position.x));
    const minX = Math.min(...tempTiles.map(g => g.position.x));
    const maxZ = Math.max(...tempTiles.map(g => g.position.z));
    const minZ = Math.min(...tempTiles.map(g => g.position.z));
    setModifyMinMax({ maxX: maxX, minX: minX, maxZ: maxZ, minZ: minZ });

    setCanPlace(canPlaceAll());
  };

  return { handleMoveTiles }
}