import Family from "@domain/model/Family";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FamilyType from "@domain/model/FamilyType";
import { Card } from "@components/master-data-view/card/card";

const FamilyCard = (props: {
  family: Family;
  typesOfFamilies: FamilyType[];
  handleEdit: (id: string) => void;
  handleActive: (item: Family) => void;
}) => {
  const { t } = useTranslation();
  const { family, typesOfFamilies, handleEdit, handleActive } = props;

  /**This filter returns the Type of family which has the same id as family's typeOfFamily id.
   * Used to set typeOfFamily name in card.
   */
  const myFamilyType = typesOfFamilies.find(ft => ft.id === family.familyTypeId);

  const renderChild = (name: string, value: string, active: boolean) => {
    return (
      <div className="value-card-container">
        <div className="title-content" data-cy={`family-card-${name}-container`}>
          <Typography variant="cardlabel" textAlign="left">
            {t(name)}
          </Typography>
          <Typography variant="cardvalue" data-cy={`value`}>
            {t(value)}
          </Typography>
        </div>
      </div>
    );
  };

  const rendererCard = (data: Family) => {
    return (
      <Card
        isActive={data.active}
        key={data.id}
        popover={{
          handleEdit: () => handleEdit(data.id ?? ""),
          handleActive: () => handleActive(data),
          activateTitle: data.active ? t("root.deactivate") : t("root.activate")
        }}
        id={"id-card-" + data.id}
        nameValue={data.name}>
        <>
          {renderChild("families.type", myFamilyType?.name ?? "", data.active)}
          <div className="location-grid">
            {renderChild("families.state", data.active ? t("root.activated") : t("root.deactivated"), data.active)}
          </div>
        </>
      </Card>
    );
  };

  return rendererCard(family);
};

export default FamilyCard;
