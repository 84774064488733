/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PrefabDTO } from './PrefabDTO';
import {
    PrefabDTOFromJSON,
    PrefabDTOFromJSONTyped,
    PrefabDTOToJSON,
} from './PrefabDTO';
import type { StakeholderDTO } from './StakeholderDTO';
import {
    StakeholderDTOFromJSON,
    StakeholderDTOFromJSONTyped,
    StakeholderDTOToJSON,
} from './StakeholderDTO';
import type { WarehouseDTO } from './WarehouseDTO';
import {
    WarehouseDTOFromJSON,
    WarehouseDTOFromJSONTyped,
    WarehouseDTOToJSON,
} from './WarehouseDTO';

/**
 * 
 * @export
 * @interface CompanyDTO
 */
export interface CompanyDTO {
    /**
     * 
     * @type {boolean}
     * @memberof CompanyDTO
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    fiscalId?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    licence?: CompanyDTOLicenceEnum;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    name?: string;
    /**
     * 
     * @type {Array<PrefabDTO>}
     * @memberof CompanyDTO
     */
    prefabs?: Array<PrefabDTO>;
    /**
     * 
     * @type {Array<StakeholderDTO>}
     * @memberof CompanyDTO
     */
    stakeholders?: Array<StakeholderDTO>;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    warehousePolicy?: CompanyDTOWarehousePolicyEnum;
    /**
     * 
     * @type {Array<WarehouseDTO>}
     * @memberof CompanyDTO
     */
    warehouses?: Array<WarehouseDTO>;
}


/**
 * @export
 */
export const CompanyDTOLicenceEnum = {
    Im: 'IM',
    Wm: 'WM',
    WmWithContainer: 'WM_WITH_CONTAINER'
} as const;
export type CompanyDTOLicenceEnum = typeof CompanyDTOLicenceEnum[keyof typeof CompanyDTOLicenceEnum];

/**
 * @export
 */
export const CompanyDTOWarehousePolicyEnum = {
    Lifo: 'LIFO',
    StrictFifo: 'STRICT_FIFO',
    TimeIntervalFifo: 'TIME_INTERVAL_FIFO'
} as const;
export type CompanyDTOWarehousePolicyEnum = typeof CompanyDTOWarehousePolicyEnum[keyof typeof CompanyDTOWarehousePolicyEnum];


/**
 * Check if a given object implements the CompanyDTO interface.
 */
export function instanceOfCompanyDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CompanyDTOFromJSON(json: any): CompanyDTO {
    return CompanyDTOFromJSONTyped(json, false);
}

export function CompanyDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'fiscalId': !exists(json, 'fiscalId') ? undefined : json['fiscalId'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'licence': !exists(json, 'licence') ? undefined : json['licence'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'prefabs': !exists(json, 'prefabs') ? undefined : ((json['prefabs'] as Array<any>).map(PrefabDTOFromJSON)),
        'stakeholders': !exists(json, 'stakeholders') ? undefined : ((json['stakeholders'] as Array<any>).map(StakeholderDTOFromJSON)),
        'warehousePolicy': !exists(json, 'warehousePolicy') ? undefined : json['warehousePolicy'],
        'warehouses': !exists(json, 'warehouses') ? undefined : ((json['warehouses'] as Array<any>).map(WarehouseDTOFromJSON)),
    };
}

export function CompanyDTOToJSON(value?: CompanyDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'address': value.address,
        'fiscalId': value.fiscalId,
        'id': value.id,
        'image': value.image,
        'licence': value.licence,
        'name': value.name,
        'prefabs': value.prefabs === undefined ? undefined : ((value.prefabs as Array<any>).map(PrefabDTOToJSON)),
        'stakeholders': value.stakeholders === undefined ? undefined : ((value.stakeholders as Array<any>).map(StakeholderDTOToJSON)),
        'warehousePolicy': value.warehousePolicy,
        'warehouses': value.warehouses === undefined ? undefined : ((value.warehouses as Array<any>).map(WarehouseDTOToJSON)),
    };
}

