import { useTranslation } from "react-i18next";
import { IRepository } from "@movicoders/domain";
import { useEffect, useRef, useState } from "react";
import { InvPeriodDTO } from "@domain/model/InvPeriod";
import { useListViewModel, useSnackbar } from "@movicoders/ui";
import { ObjectType } from "@movicoders/ui/lib/ViewModels/types";
import { GetInventoryPeriodByWarehouseRequest } from "@clients/aggrego-proxy";
import { InventoryPeriodRepository } from "@infrastructure/repositories/inventory-period-repository";
import { useLocation } from "react-router-dom";
import { ROUTER_PATHS } from "@constants";
import { ReportEnum } from "@pages/settings/settings-interfaces";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { useResolve } from "@movicoders/di";

export const useHandlePeriods = (warehouseCode: string | undefined) => {
  const { t } = useTranslation();
  const { show } = useSnackbar();
  const location = useLocation();

  const settingsService = useResolve<ISettingsService>(SettingsService);

  const [isActivePeriod, setIsActivePeriod] = useState(false);

  const [sortedPeriods, setSortedPeriods] = useState<InvPeriodDTO[]>([]);
  const [selectedPeriod, setSelectedPeriod] = useState<InvPeriodDTO>();

  const { fetch: fetchPeriods, data: periods } = useListViewModel(
    InventoryPeriodRepository as unknown as ObjectType<IRepository<InvPeriodDTO, GetInventoryPeriodByWarehouseRequest, null>>
  );
  const periodsFetch = useRef(fetchPeriods).current;

  const getAllPeriods = () => {
    warehouseCode &&
      periodsFetch({
        warehouseCode: warehouseCode
      } as GetInventoryPeriodByWarehouseRequest).catch(error => {
        show(t("invperiod.no.results"), "error");
        setIsActivePeriod(false);
      });
  };

  const sortPeriods = () => {
    if (periods.length > 0) {
      let order = Array.from(periods as InvPeriodDTO[]);
      order = order.sort(
        (a: InvPeriodDTO, b: InvPeriodDTO) => (b?.initDate ?? new Date()).getTime() - (a?.initDate ?? new Date()).getTime()
      );
      setSortedPeriods(order);
    }
  };

  //First page load
  useEffect(() => {
    if (
      //route is inventory-report and settingsService inv-report access is allowed...
      (location.pathname === ROUTER_PATHS.invreport &&
        settingsService.persistedState?.reportsPermissions.includes(ReportEnum.InventoryReport)) ||
      //or route is inventory-registry and settingsService inv-registry access is allowed...
      (location.pathname === ROUTER_PATHS.invregistry &&
        settingsService.persistedState?.reportsPermissions.includes(ReportEnum.InventoryRegistry))
    )
      getAllPeriods();
  }, [settingsService.persistedState]);

  useEffect(() => {
    periods.length > 0 && sortPeriods();
    setSelectedPeriod(sortedPeriods[0]);
    setIsActivePeriod((periods as InvPeriodDTO[]).find(item => item.periodActive === true)?.periodActive ?? false);
  }, [periods]);

  return {
    isActivePeriod,
    setIsActivePeriod,
    sortedPeriods,
    selectedPeriod,
    setSelectedPeriod,
    getAllPeriods,
    sortPeriods,
    periods
  };
};
