import { COLORS, MEDIA } from "@theme";
import Icon from "@components/icons/icon";
import { useTranslation } from "react-i18next";
import { ParentForm } from "./form-parent/ParentForm";
import { ChildrenForm } from "./form-children/ChildrenForm";
import { AddedChildren } from "./added-children/AddedChildren";
import { hierarchyCreationStyles } from "./hierarchy-creation-styles";
import { OutletContainer } from "@components/outlet-container/outlet-container";
import { useHierarchyCreationViewmodel } from "./useHierarchyCreationViewmodel";
import { Button, CircularProgress, Divider, Grid, IconButton, Theme, Typography, useMediaQuery } from "@mui/material";
import { LoadingSpinnerMasterView } from "@components/loading-circular-progress/loading-spinner-master-view";
import { TrackAndTraceEnum } from "@pages/settings/settings-interfaces";

export const HierarchyCreationView = () => {
  const { t } = useTranslation();
  const styles = hierarchyCreationStyles();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up(MEDIA.xsmall), { noSsr: true });
  const {
    updateParent,
    children,
    parentErrors,
    addChildToParent,
    doCreation,
    updateChild,
    removeChild,
    isLoadingView,
    isClearingView,
    settingsService,
    hasVersionLabel
  } = useHierarchyCreationViewmodel();

  return (
    <>
      {settingsService.persistedState?.trackAndTracePermissions.includes(TrackAndTraceEnum.Hierarchy) ? (
        <OutletContainer>
          <Grid container mb="4rem">
            <Grid container spacing={1}>
              <Grid item container rowSpacing="1.5rem" sx={styles.title_container}>
                <Grid item container wrap="nowrap" spacing={{ xs: "1rem" }}>
                  <Grid item alignSelf="center">
                    <Icon icon="hierarchies-product" color={COLORS.primary} />
                  </Grid>
                  <Grid item className="main-view-title" sx={{ cursor: "pointer" }}>
                    <Typography>{t("hierarchy.creation")}</Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Divider variant="fullWidth" />
                </Grid>
              </Grid>
            </Grid>
            <Grid container style={{ padding: 2, paddingBottom: "1rem" }}>
              <ParentForm
                onParentChange={updateParent}
                errors={parentErrors}
                isClearingView={isClearingView}
                hasVersionLabel={hasVersionLabel}
              />
            </Grid>
            <Grid container style={{ paddingTop: "1rem", paddingBottom: 10 }} display="flex" justifyContent="flex-end">
              <ChildrenForm
                addChildToParent={addChildToParent}
                isClearingView={isClearingView}
                hasVersionLabel={hasVersionLabel}
              />
            </Grid>
            <Grid item xs={12} paddingTop={"0.5rem"}>
              <Divider sx={{ mb: "1.5rem" }} />
            </Grid>
            <Grid container style={{ padding: 2, paddingBottom: 10 }} display="flex" justifyContent="flex-end">
              <AddedChildren
                deleteChild={child => removeChild(child)}
                updateChild={child => updateChild(child)}
                addedChildren={children}
                hasVersionLabel={hasVersionLabel}
              />
            </Grid>

            <Grid item xs={12} paddingTop={"0.5rem"}>
              <Divider sx={{ mb: "1.5rem" }} />
            </Grid>

            <Grid container style={{ padding: 2, paddingBottom: 10 }} display="flex" justifyContent="flex-end">
              {matches ? (
                <Button
                  startIcon={<Icon icon="plus" color={COLORS.white} />}
                  variant="primary"
                  onClick={() => {
                    doCreation();
                  }}
                  id="hierarchy-create-button">
                  {t("button.create")}
                </Button>
              ) : (
                <IconButton
                  sx={styles.add_icon_button}
                  onClick={() => {
                    doCreation();
                  }}
                  id="create-hierarchy">
                  <Icon icon="plus" color={COLORS.white} />
                </IconButton>
              )}
            </Grid>
            <LoadingSpinnerMasterView loading={isLoadingView} />
          </Grid>
        </OutletContainer>
      ) : (
        <CircularProgress color="secondary" />
      )}
    </>
  );
};
