import { useResolve } from "@movicoders/di";
import { DomainPage } from "@movicoders/domain";
import { Product } from "@domain/model/Product";
import { useListViewModel, useSnackbar } from "@movicoders/ui";
import { useEffect, useRef, useState } from "react";
import {
  GetProductHierarchyByBatchAndMaterialCodeRequest,
  ListProductsPaginatedRequest,
  ListProductsPaginatedSortDirectionEnum,
  ListProductsPaginatedSortFieldEnum,
  ProductsBasicFilterDTO
} from "@clients/aggrego-proxy";
import { LastPageState } from "@domain/model/LastPageState";
import { GridSortModel, GridValidRowModel } from "@mui/x-data-grid";
import { ProductRepository } from "@infrastructure/repositories/product-repository";
import { ILastPageService, LastPageService } from "@domain/services/ILastPageService";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { useTranslation } from "react-i18next";
import { SearchModeEnum, TSearchModeEnum } from "../components/hooks/useProductSearchBarViewModel";
import { HierarchyFiltersService, IHierarchyFiltersService } from "@domain/services/IHierarchyFiltersService";
import { IUserService, UserService } from "@domain/services/IUserService";

export const HierarchyParentsFilterEnum = {
  All: "PARENTS_ALL",
  ParentsOnly: "PARENTS_ONLY"
} as const;
export type THierarchyParentsFilterEnum = (typeof HierarchyParentsFilterEnum)[keyof typeof HierarchyParentsFilterEnum];

export const useProductViewModel = (onNavigateToHierarchy: (id?: string) => void) => {
  const { t } = useTranslation();
  const { show } = useSnackbar();
  const productsRepository = useResolve<ProductRepository>(ProductRepository);
  const { dataPaginated, getPaginated, loading } = useListViewModel(ProductRepository);
  const [repositoryLoading, setRepositoryLoading] = useState(false);
  const productFetch = useRef(getPaginated).current;

  // Filter and pagination related variables
  const settingsService = useResolve<ISettingsService>(SettingsService);
  const lastPageService = useResolve<ILastPageService>(LastPageService);
  const hierarchyFiltersService = useResolve<IHierarchyFiltersService>(HierarchyFiltersService);

  const lastPagePersistedState = lastPageService.getCurrentState() ?? new LastPageState();

  //Set mode to search
  const [searchMode, setSearchMode] = useState<TSearchModeEnum>(
    hierarchyFiltersService.persistedState?.searchMode ?? SearchModeEnum.Serial
  );

  //parents filter
  const [selectedParentFilter, setSelectedParentFilter] = useState<THierarchyParentsFilterEnum>(HierarchyParentsFilterEnum.All);

  const [exportingPaginatedData, setExportingPaginatedData] = useState<DomainPage<Product>>();

  //gets if batch or versionlabel is showed (teltronic)
  const userService = useResolve<IUserService>(UserService);
  const hasVersionLabel = userService.user?.hasVersion ?? false;

  useEffect(() => {
    setRepositoryLoading(loading);
  }, [loading]);

  useEffect(() => {
    setExportingPaginatedData(dataPaginated);
  }, [dataPaginated]);

  const saveSearchValues = (
    initialData: GridValidRowModel[],
    searchBarValue: string | Map<string, string>
  ): GridValidRowModel[] => {
    if (typeof searchBarValue === "string") {
      lastPageService.saveLastPageState({ ...lastPagePersistedState, filters: { serialNumber: searchBarValue } });
    } else if (searchBarValue instanceof Map && searchBarValue.get("material") && searchBarValue.get("batch")) {
      lastPageService.saveLastPageState({
        ...lastPagePersistedState,
        filters: { materialCode: searchBarValue.get("material"), batch: searchBarValue.get("batch") }
      });
    }
    return initialData;
  };

  const searchPaginated = (limit: number, offset: number, filters?: object | undefined) => {
    if (
      searchMode === SearchModeEnum.MaterialBatch &&
      (filters as ProductsBasicFilterDTO).materialCode &&
      (filters as ProductsBasicFilterDTO).materialCode !== "" &&
      (filters as ProductsBasicFilterDTO).batch &&
      (filters as ProductsBasicFilterDTO).batch !== "" &&
      Object.keys(filters as ProductsBasicFilterDTO).length > 0
    ) {
      setRepositoryLoading(true);
      productsRepository
        .getProductHierarchyByBatchAndMaterialCode({
          limit: limit,
          offset: offset,
          materialCode: (filters as ProductsBasicFilterDTO).materialCode ?? "",
          batch: (filters as ProductsBasicFilterDTO).batch ?? "",
          onlyParent: selectedParentFilter === HierarchyParentsFilterEnum.ParentsOnly,
          sortDirection: lastPagePersistedState.sortDirection ?? ListProductsPaginatedSortDirectionEnum.Asc,
          sortField:
            lastPagePersistedState.sortField && String(lastPagePersistedState.sortField) !== ""
              ? lastPagePersistedState.sortField
              : ListProductsPaginatedSortFieldEnum.Manufacturingdate
        } as GetProductHierarchyByBatchAndMaterialCodeRequest)
        .then(result => {
          setExportingPaginatedData(result as unknown as DomainPage<Product>);
        })
        .finally(() => {
          setRepositoryLoading(false);
        });
    } else {
      productFetch({
        limit: limit,
        offset: offset,
        productsBasicFilterDTO: (filters as ProductsBasicFilterDTO) ?? {},
        sortDirection: lastPagePersistedState.sortDirection ?? ListProductsPaginatedSortDirectionEnum.Asc,
        sortField:
          lastPagePersistedState.sortField && String(lastPagePersistedState.sortField) !== ""
            ? lastPagePersistedState.sortField
            : ListProductsPaginatedSortFieldEnum.Manufacturingdate
      } as ListProductsPaginatedRequest);
    }
  };

  const sortByFieldBackend = (model: GridSortModel) => {
    const filterMaterialCode = (lastPageService.persistedState?.filters as ProductsBasicFilterDTO).materialCode;
    const filterBatch = (lastPageService.persistedState?.filters as ProductsBasicFilterDTO).batch;
    if ((dataPaginated?.content as Product[]).length > 0) {
      if (searchMode === SearchModeEnum.MaterialBatch && filterMaterialCode !== undefined && filterBatch !== undefined) {
        setRepositoryLoading(true);
        productsRepository
          .getProductHierarchyByBatchAndMaterialCode({
            limit: lastPageService.persistedState?.limit,
            offset: lastPageService.persistedState?.offset,
            materialCode: filterMaterialCode ?? "",
            batch: filterBatch ?? "",
            onlyParent: selectedParentFilter === HierarchyParentsFilterEnum.ParentsOnly,
            sortDirection: model[0]?.sort?.toUpperCase() ?? ListProductsPaginatedSortDirectionEnum.Asc,
            sortField: model[0]?.field?.toUpperCase() ?? ListProductsPaginatedSortFieldEnum.Manufacturingdate
          } as GetProductHierarchyByBatchAndMaterialCodeRequest)
          .then(result => {
            setExportingPaginatedData(result as unknown as DomainPage<Product>);
          })
          .finally(() => {
            setRepositoryLoading(false);
          });
      } else {
        productFetch({
          limit: lastPageService.persistedState?.limit,
          offset: lastPageService.persistedState?.offset,
          sortDirection: model[0]?.sort?.toUpperCase() ?? ListProductsPaginatedSortDirectionEnum.Asc,
          sortField: model[0]?.field?.toUpperCase() ?? ListProductsPaginatedSortFieldEnum.Manufacturingdate,
          productsBasicFilterDTO: (lastPageService.persistedState?.filters as ProductsBasicFilterDTO) ?? {}
        } as ListProductsPaginatedRequest);
      }
      lastPageService.saveLastPageState({
        ...lastPagePersistedState,
        sortDirection: model[0]?.sort?.toUpperCase() as "ASC" | "DESC" | undefined,
        sortField: model[0]?.field?.toUpperCase()
      });
    }
  };

  const findAbsoluteProductParent = async (clickedProduct: Product) => {
    const findParent = async (product: Product) => {
      if (!product || !product.materialCode) {
        return;
      }

      if (product.parents && product.parents.length === 0) {
        onNavigateToHierarchy(product.id);
        return;
      }

      if (product.batch !== "") {
        try {
          const childrenMaterialCode = product.materialCode;
          const childrenBatch = product.batch;
          const parentProduct = await productsRepository.getParentByBatchAndMaterialCode({
            materialCode: childrenMaterialCode,
            batch: childrenBatch,
            sortField: "MANUFACTURINGDATE",
            sortDirection: "ASC"
          });

          if (parentProduct && parentProduct.content && parentProduct.content.length > 0) {
            const product = new Product().fromDTO(parentProduct.content[0]);
            await findParent(product);
          } else {
            onNavigateToHierarchy(product.id);
          }
        } catch (error) {
          show(t("error.parent.not found"), "error");
        }
      } else if (product.serialNumber !== "") {
        try {
          const childrenMaterialCode = product.materialCode;
          const childrenSerialNumber = product.serialNumber;
          const parentProduct = await productsRepository.getParentBySerialNumberAndMaterialCode({
            materialCode: childrenMaterialCode,
            serialNumber: childrenSerialNumber,
            sortField: "MANUFACTURINGDATE",
            sortDirection: "ASC"
          });

          if (parentProduct && parentProduct.content && parentProduct.content.length > 0) {
            const product = new Product().fromDTO(parentProduct.content[0]);
            await findParent(product);
          } else {
            onNavigateToHierarchy(product.id);
          }
        } catch (error) {
          show(t("error.parent.not found"), "error");
        }
      } else {
        show(t("error.parent.not found"), "error");
      }
    };

    await findParent(clickedProduct);
  };

  const findAbsoluteProductParentFromInside = (clickedProduct: Product) => {
    if (clickedProduct.parents.length === 0) {
      onNavigateToHierarchy(clickedProduct.id);
    } else {
      findAbsoluteProductParentFromInside(clickedProduct.parents[0] as unknown as Product);
    }
  };

  return {
    exportingPaginatedData,
    getPaginated: productFetch,
    saveSearchValues,
    searchPaginated,
    repositoryLoading,
    sortByFieldBackend,
    settingsService,
    findAbsoluteProductParent,
    findAbsoluteProductParentFromInside,
    searchMode,
    setSearchMode,
    hierarchyFiltersService,
    selectedParentFilter,
    setSelectedParentFilter,
    hasVersionLabel
  };
};
