import { MEDIA } from "@theme";
import Zone from "@domain/model/Zone";
import { User } from "@domain/model/User";
import Material from "@domain/model/Material";
import Location from "@domain/model/Location";
import { useTranslation } from "react-i18next";
import { ContainerDTO } from "@clients/aggrego-proxy";
import { UUIDGenerator } from "@utils/uuid-generator";
import { WarehouseStatusDTO } from "@domain/model/WarehouseStatus";
import { HomeAutocomplete } from "../../home/components/home-autocomplete";
import { dashboardSearchUserColumns } from "../dashboard-search-user-columns";
import { OutletContainer } from "@components/outlet-container/outlet-container";
import { DataGridDisplay } from "@components/data-grid-display/data-grid-display";
import { Box, Button, Paper, Theme, Typography, useMediaQuery } from "@mui/material";
import { materialColumns } from "../../master-data/materials/components/material-columns";
import { movementsColumns } from "../../reports/movements-report/movements-report-columns";
import { locationsColumns } from "../../master-data/locations/components/locations-columns";
import { warehouseStatusColumns } from "@track-and-trace/status/main/warehouse-status-columns";
import { useDashboardSearchEngineViewModel } from "../hooks/useDashboardSearchEngineViewModel";
import { DataGridDisplayBasicToolbarProps } from "@components/data-grid-display/toolbar/config/data-grid-display-basic-toolbar-props";

export type searchObjectName = "material" | "location" | "container" | "user";
export type searchObjectType = Material | Location | ContainerDTO | User;

export const DashboardSearchEngineView = () => {
  const { t } = useTranslation();

  const matchesSM = useMediaQuery((theme: Theme) => theme.breakpoints.up(MEDIA.small), { noSsr: true });

  const {
    statusColumnsByMode,
    movementsColumnsByMode,
    checkLicense,
    handleNavigationOnRowClick,
    handleNavigationOnClick,
    navigateToDashboard,
    matchesMD,
    styles,
    searchEngineStyles,
    filterObject,
    filterType,
    movementsReportDataPaginated,
    warehouseStatusReportDataPaginated,
    preselectedValue,
    getDatas,
    tiles,
    zones
  } = useDashboardSearchEngineViewModel();

  const tablePropsAllHidden = {
    isSearchBarHidden: true,
    isActiveFilterHidden: true,
    isColumnsMenuIconButtonHidden: true,
    isDownloadIconButtonHidden: true,
    isFilterIconButtonHidden: true
  } as DataGridDisplayBasicToolbarProps;

  const returnButton = () => {
    return (
      <Button variant="primary" onClick={navigateToDashboard} sx={{ mt: 2 }}>
        {t("root.return")}
      </Button>
    );
  };

  return (
    <OutletContainer>
      <>
        {/* SEARCH BAR */}
        <Box
          component="div"
          display="flex"
          justifyContent={matchesSM ? "flex-end" : "center"}
          sx={{ mt: matchesSM ? "0rem" : "1rem", ml: "1rem" }}>
          <HomeAutocomplete id={"home-autocomplete"} showButton={true} preselectedValue={preselectedValue} onClick={getDatas} />
        </Box>
        {/* MATERIAL */}
        {filterType === "material" && (
          <Paper sx={{ ...styles.data_grid_display_container, mb: 2 }}>
            <Typography variant="title" sx={{ marginBottom: "0px", paddingTop: "20px", marginLeft: "5px" }}>
              {t("dashboard.search.results.search.by.title")}
            </Typography>

            <DataGridDisplay
              toolbarProps={tablePropsAllHidden}
              data={[filterObject]}
              columns={materialColumns(t, undefined, undefined, matchesMD)}
              mode="server"
              viewName="materials"
              hideColumnsByField={["id", "actions"]}
              handleBackendPagination={() => null}
              backendTotalElements={[filterObject].length ?? 0}
              hideRowCount={true}
              hideFooter={true}
              onRowClick={(param, event) => handleNavigationOnRowClick(param, event, "materials", "material")}
              customToolbarComponent={
                <Typography
                  id="dashboard-search-material-subtitle"
                  variant="subtitle1"
                  sx={{ ...searchEngineStyles.clickable_title, marginBottom: "10px", marginLeft: "5px" }}
                  onClick={() => handleNavigationOnClick("materials", "material")}>
                  {t("material.material")}
                </Typography>
              }
            />

            <DataGridDisplay
              toolbarProps={tablePropsAllHidden}
              data={(movementsReportDataPaginated?.content as []) ?? []}
              columns={movementsColumns(t, matchesMD)}
              mode="server"
              viewName="movements"
              hideColumnsByField={movementsColumnsByMode}
              handleBackendPagination={() => null}
              backendTotalElements={movementsReportDataPaginated?.totalElements ?? 0}
              hideRowCount={true}
              hideFooter={true}
              onRowClick={(param, event) => handleNavigationOnRowClick(param, event, "movements", "material")}
              customToolbarComponent={
                <Typography
                  id="dashboard-search-movements-report-subtitle"
                  variant="subtitle1"
                  sx={{ ...searchEngineStyles.clickable_title, marginBottom: "10px", marginLeft: "5px" }}
                  onClick={() => handleNavigationOnClick("movements", "material")}>
                  {t("movreport")}
                </Typography>
              }
            />

            <DataGridDisplay
              toolbarProps={tablePropsAllHidden}
              data={
                warehouseStatusReportDataPaginated?.content?.map((item: WarehouseStatusDTO) => {
                  return { ...item, id: UUIDGenerator() };
                }) ?? []
              }
              columns={warehouseStatusColumns(t, matchesMD)}
              mode="server"
              viewName="whstatus"
              hideColumnsByField={statusColumnsByMode}
              handleBackendPagination={() => null}
              backendTotalElements={warehouseStatusReportDataPaginated?.totalElements ?? 0}
              hideRowCount={true}
              hideFooter={true}
              onRowClick={(param, event) => handleNavigationOnRowClick(param, event, "warehouse-status", "material")}
              customToolbarComponent={
                <Typography
                  id="dashboard-search-warehouse-status-subtitle"
                  variant="subtitle1"
                  sx={{ ...searchEngineStyles.clickable_title, marginBottom: "10px", marginLeft: "5px" }}
                  onClick={() => handleNavigationOnClick("warehouse-status", "material")}>
                  {t("whstatus")}
                </Typography>
              }
            />

            {returnButton()}
          </Paper>
        )}
        {/* LOCATION */}
        {filterType === "location" && (checkLicense("WM") || checkLicense("WM_WITH_CONTAINER")) && (
          <Paper sx={{ ...styles.data_grid_display_container, mb: 2 }}>
            <Typography variant="title" sx={{ paddingTop: "20px", marginLeft: "5px" }}>
              {t("dashboard.search.results.search.by.title")}
            </Typography>

            <DataGridDisplay
              toolbarProps={tablePropsAllHidden}
              data={[filterObject]}
              columns={locationsColumns(t, tiles, zones?.content as Zone[], matchesMD)}
              mode="server"
              viewName="locations"
              hideColumnsByField={["id", "actions"]}
              handleBackendPagination={() => null}
              backendTotalElements={[filterObject].length ?? 0}
              hideRowCount={true}
              hideFooter={true}
              onRowClick={(param, event) => handleNavigationOnRowClick(param, event, "locations", "location")}
              customToolbarComponent={
                <Typography
                  id="dashboard-search-location-subtitle"
                  variant="subtitle1"
                  sx={{ ...searchEngineStyles.clickable_title, marginBottom: "10px", marginLeft: "5px" }}
                  onClick={() => handleNavigationOnClick("locations", "material")}>
                  {t("dashboard.search.results.location.title")}
                </Typography>
              }
            />

            <DataGridDisplay
              toolbarProps={tablePropsAllHidden}
              data={movementsReportDataPaginated.content ?? []}
              columns={movementsColumns(t, matchesMD)}
              mode="server"
              viewName="movements"
              hideColumnsByField={movementsColumnsByMode}
              handleBackendPagination={() => null}
              backendTotalElements={movementsReportDataPaginated?.totalElements ?? 0}
              hideRowCount={true}
              hideFooter={true}
              onRowClick={(param, event) => handleNavigationOnRowClick(param, event, "movements", "location")}
              customToolbarComponent={
                <Typography
                  id="dashboard-search-movements-report-subtitle"
                  variant="subtitle1"
                  sx={{ ...searchEngineStyles.clickable_title, marginBottom: "10px", marginLeft: "5px" }}
                  onClick={() => handleNavigationOnClick("movements", "location")}>
                  {t("movreport")}
                </Typography>
              }
            />

            <DataGridDisplay
              toolbarProps={tablePropsAllHidden}
              data={
                warehouseStatusReportDataPaginated?.content?.map((item: WarehouseStatusDTO) => {
                  return { ...item, id: UUIDGenerator() };
                }) ?? []
              }
              columns={warehouseStatusColumns(t, matchesMD)}
              mode="server"
              viewName="whstatus"
              hideColumnsByField={statusColumnsByMode}
              handleBackendPagination={() => null}
              backendTotalElements={warehouseStatusReportDataPaginated?.totalElements ?? 0}
              hideRowCount={true}
              hideFooter={true}
              onRowClick={(param, event) => handleNavigationOnRowClick(param, event, "warehouse-status", "location")}
              customToolbarComponent={
                <Typography
                  id="dashboard-search-warehouse-status-subtitle"
                  variant="subtitle1"
                  sx={{ ...searchEngineStyles.clickable_title, marginBottom: "10px", marginLeft: "5px" }}
                  onClick={() => handleNavigationOnClick("warehouse-status", "location")}>
                  {t("whstatus")}
                </Typography>
              }
            />

            {returnButton()}
          </Paper>
        )}
        {/* CONTAINER */}
        {filterType === "container" && checkLicense("WM_WITH_CONTAINER") && (
          <Paper sx={{ ...styles.data_grid_display_container, mb: 2 }}>
            <Typography variant="title" sx={{ paddingTop: "20px", marginLeft: "5px" }}>
              {t("dashboard.search.results.search.by.container.title")}
            </Typography>

            <DataGridDisplay
              toolbarProps={tablePropsAllHidden}
              data={movementsReportDataPaginated.content ?? []}
              columns={movementsColumns(t, matchesMD)}
              mode="server"
              viewName="movements"
              hideColumnsByField={movementsColumnsByMode}
              handleBackendPagination={() => null}
              backendTotalElements={movementsReportDataPaginated?.totalElements ?? 0}
              hideRowCount={true}
              hideFooter={true}
              onRowClick={(param, event) => handleNavigationOnRowClick(param, event, "movements", "container")}
              customToolbarComponent={
                <Typography
                  id="dashboard-search-movements-report-subtitle"
                  variant="subtitle1"
                  sx={{ ...searchEngineStyles.clickable_title, marginBottom: "10px", marginLeft: "5px" }}
                  onClick={() => handleNavigationOnClick("movements", "container")}>
                  {t("movreport")}
                </Typography>
              }
            />

            <DataGridDisplay
              toolbarProps={tablePropsAllHidden}
              data={
                warehouseStatusReportDataPaginated?.content?.map((item: WarehouseStatusDTO) => {
                  return { ...item, id: UUIDGenerator() };
                }) ?? []
              }
              columns={warehouseStatusColumns(t, matchesMD)}
              mode="server"
              viewName="whstatus"
              hideColumnsByField={statusColumnsByMode}
              handleBackendPagination={() => null}
              backendTotalElements={warehouseStatusReportDataPaginated?.totalElements ?? 0}
              hideRowCount={true}
              hideFooter={true}
              onRowClick={(param, event) => handleNavigationOnRowClick(param, event, "warehouse-status", "container")}
              customToolbarComponent={
                <Typography
                  id="dashboard-search-warehouse-status-subtitle"
                  variant="subtitle1"
                  sx={{ ...searchEngineStyles.clickable_title, marginBottom: "10px", marginLeft: "5px" }}
                  onClick={() => handleNavigationOnClick("warehouse-status", "container")}>
                  {t("whstatus")}
                </Typography>
              }
            />

            {returnButton()}
          </Paper>
        )}
        {/* USER */}
        {filterType === "user" && (
          <Paper sx={{ ...styles.data_grid_display_container, mb: 2 }}>
            <Typography variant="title" sx={{ paddingTop: "20px", marginLeft: "5px" }}>
              {t("dashboard.search.results.search.by.title")}
            </Typography>

            <DataGridDisplay
              toolbarProps={tablePropsAllHidden}
              data={[filterObject]}
              columns={dashboardSearchUserColumns(t)}
              mode="server"
              viewName="materials"
              hideColumnsByField={["id", "actions"]}
              handleBackendPagination={() => null}
              backendTotalElements={movementsReportDataPaginated?.totalElements ?? 0}
              hideRowCount={true}
              hideFooter={true}
              onRowClick={(param, event) => handleNavigationOnRowClick(param, event, "users", "user")}
              customToolbarComponent={
                <Typography
                  id="dashboard-search-user-subtitle"
                  variant="subtitle1"
                  sx={{ ...searchEngineStyles.clickable_title, marginBottom: "10px", marginLeft: "5px" }}
                  onClick={() => handleNavigationOnClick("users", "user")}>
                  {t("dashboard.search.results.user.title")}
                </Typography>
              }
            />

            <DataGridDisplay
              toolbarProps={tablePropsAllHidden}
              data={movementsReportDataPaginated.content ?? []}
              columns={movementsColumns(t, matchesMD)}
              mode="server"
              viewName="movements"
              hideColumnsByField={movementsColumnsByMode}
              handleBackendPagination={() => null}
              backendTotalElements={movementsReportDataPaginated?.totalElements ?? 0}
              hideRowCount={true}
              hideFooter={true}
              onRowClick={(param, event) => handleNavigationOnRowClick(param, event, "movements", "user")}
              customToolbarComponent={
                <Typography
                  id="dashboard-search-movements-report-subtitle"
                  variant="subtitle1"
                  sx={{ ...searchEngineStyles.clickable_title, marginBottom: "10px", marginLeft: "5px" }}
                  onClick={() => handleNavigationOnClick("movements", "user")}>
                  {t("movreport")}
                </Typography>
              }
            />

            <DataGridDisplay
              toolbarProps={tablePropsAllHidden}
              data={
                warehouseStatusReportDataPaginated?.content?.map((item: WarehouseStatusDTO) => {
                  return { ...item, id: UUIDGenerator() };
                }) ?? []
              }
              columns={warehouseStatusColumns(t, matchesMD)}
              mode="server"
              viewName="whstatus"
              hideColumnsByField={statusColumnsByMode}
              handleBackendPagination={() => null}
              backendTotalElements={warehouseStatusReportDataPaginated?.totalElements ?? 0}
              hideRowCount={true}
              hideFooter={true}
              onRowClick={(param, event) => handleNavigationOnRowClick(param, event, "warehouse-status", "user")}
              customToolbarComponent={
                <Typography
                  id="dashboard-search-warehouse-status-subtitle"
                  variant="subtitle1"
                  sx={{ ...searchEngineStyles.clickable_title, marginBottom: "10px", marginLeft: "5px" }}
                  onClick={() => handleNavigationOnClick("warehouse-status", "user")}>
                  {t("whstatus")}
                </Typography>
              }
            />

            {returnButton()}
          </Paper>
        )}
      </>
    </OutletContainer>
  );
};
