import { Button, Dialog, DialogActions, DialogTitle, Grid, IconButton, TextField, Typography } from "@mui/material"
import { COLORS } from "../../../../../../theme";
import Icon from "../../../../../components/icons/icon";
import { useAddLocationDialog3D } from "./useAddLocationDialog3D";
import { useTranslation } from "react-i18next";

export const AddLocationDialog3D = () => {

  const {
    newLocCode,
    newLocCapacity,
    codeError,
    capacityError,
    getIsOpen,
    handleClose,
    handleChangeCode,
    handleChangeCapacity,
    handleSave
  } = useAddLocationDialog3D();

  const { t } = useTranslation();

  return (
    <Dialog open={getIsOpen()} maxWidth="sm" className="save-dialog" onClose={handleClose} >
      <DialogTitle>
        <Typography fontSize="1.125rem" fontWeight={600} textOverflow="ellipsis" overflow="hidden">
          ADD LOCATION
        </Typography>
        <IconButton onClick={handleClose}>
          <Icon icon="close" color={COLORS.white} />
        </IconButton>
      </DialogTitle>
      <Grid container flexDirection="column" rowSpacing={2} sx={{ p: "1rem", pt: 0, mt: "0" }} >
        <Grid item>
          <TextField
            error={codeError !== ""}
            helperText={t(codeError)}
            value={newLocCode}
            type="text"
            onChange={handleChangeCode}
            label={t("locations.code")}
            className="blue-border-input"
          />
        </Grid>
        <Grid item>
          <TextField
            error={capacityError}
            helperText={capacityError ? t("locations.dialog.empty.field.number") : ""}
            value={newLocCapacity}
            type="number"
            onChange={handleChangeCapacity}
            label={t("locations.capacity")}
            className="blue-border-input"
          />
        </Grid>
      </Grid>
      <DialogActions sx={{ justifyContent: "space-between", p: "1rem" }}>
        <Button onClick={handleClose}>{t("root.cancel")}</Button>
        <Button onClick={handleSave}>{t("root.create")}</Button>
      </DialogActions>
    </Dialog >
  )
}