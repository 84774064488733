import { DomainPage, IRepository } from "@movicoders/domain";
import Tile from "@domain/model/Tile";
import { TileApi } from "../api/tile-api";
import {
  AddLocationToTileRequest,
  DeleteTilesRequest,
  GetTilesByWarehouseIDRequest,
  ListTilesByTenantIdRequest,
  ListTilesPaginatedRequest,
  SaveTilesRequest,
  UpdateTilesRequest
} from "@clients/aggrego-proxy/apis/TilesApi";
import { ListResponseDTO, TileDTO } from "@clients/aggrego-proxy";

export class TileRepository implements IRepository<Tile, ListTilesPaginatedRequest, ListTilesPaginatedRequest> {
  constructor(private readonly tileApi: TileApi) {}
  get(): Promise<Tile[]> {
    return this.tileApi.get();
  }

  getPaginated(pageCriteria?: ListTilesPaginatedRequest): Promise<DomainPage<Tile>> {
    throw new Error("Method not implemented.");
  }

  getById(id: string): Promise<Tile> {
    return this.tileApi.getById(id);
  }

  getByIdFalseDTO(id: string): Promise<Tile> {
    return this.tileApi.getByIdFalseDTO(id);
  }

  getByTenantId(criteria: ListTilesByTenantIdRequest): Promise<Tile[]> {
    return this.tileApi.getByTenantId(criteria);
  }

  getByWarehouseId(criteria: GetTilesByWarehouseIDRequest): Promise<Tile[]> {
    return this.tileApi.getByWarehouseId(criteria);
  }

  async create(entity: Tile): Promise<Tile> {
    return await this.tileApi.create(entity);
  }

  async createMany(criteria: SaveTilesRequest): Promise<ListResponseDTO> {
    return await this.tileApi.createMany(criteria);
  }

  async update(entity: Tile): Promise<Tile> {
    return await this.tileApi.update(entity);
  }

  async updateMany(criteria: UpdateTilesRequest): Promise<ListResponseDTO> {
    return await this.tileApi.updateMany(criteria);
  }

  async remove(id: string): Promise<void> {
    await this.tileApi.remove(id);
  }

  async removeMany(criteria: DeleteTilesRequest): Promise<TileDTO[]> {
    return await this.tileApi.removeMany(criteria);
  }

  async addLocation(criteria: AddLocationToTileRequest): Promise<TileDTO> {
    return await this.tileApi.addLocation(criteria);
  }
}
