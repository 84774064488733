/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Pageable } from './Pageable';
import {
    PageableFromJSON,
    PageableFromJSONTyped,
    PageableToJSON,
} from './Pageable';
import type { RelabelDTO } from './RelabelDTO';
import {
    RelabelDTOFromJSON,
    RelabelDTOFromJSONTyped,
    RelabelDTOToJSON,
} from './RelabelDTO';
import type { Sort } from './Sort';
import {
    SortFromJSON,
    SortFromJSONTyped,
    SortToJSON,
} from './Sort';

/**
 * 
 * @export
 * @interface RelabelPageDTO
 */
export interface RelabelPageDTO {
    /**
     * 
     * @type {Array<RelabelDTO>}
     * @memberof RelabelPageDTO
     */
    content?: Array<RelabelDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof RelabelPageDTO
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RelabelPageDTO
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RelabelPageDTO
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof RelabelPageDTO
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof RelabelPageDTO
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof RelabelPageDTO
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof RelabelPageDTO
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof RelabelPageDTO
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof RelabelPageDTO
     */
    totalPages?: number;
}

/**
 * Check if a given object implements the RelabelPageDTO interface.
 */
export function instanceOfRelabelPageDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RelabelPageDTOFromJSON(json: any): RelabelPageDTO {
    return RelabelPageDTOFromJSONTyped(json, false);
}

export function RelabelPageDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelabelPageDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': !exists(json, 'content') ? undefined : ((json['content'] as Array<any>).map(RelabelDTOFromJSON)),
        'first': !exists(json, 'first') ? undefined : json['first'],
        'last': !exists(json, 'last') ? undefined : json['last'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'numberOfElements': !exists(json, 'numberOfElements') ? undefined : json['numberOfElements'],
        'pageable': !exists(json, 'pageable') ? undefined : PageableFromJSON(json['pageable']),
        'size': !exists(json, 'size') ? undefined : json['size'],
        'sort': !exists(json, 'sort') ? undefined : SortFromJSON(json['sort']),
        'totalElements': !exists(json, 'totalElements') ? undefined : json['totalElements'],
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
    };
}

export function RelabelPageDTOToJSON(value?: RelabelPageDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content === undefined ? undefined : ((value.content as Array<any>).map(RelabelDTOToJSON)),
        'first': value.first,
        'last': value.last,
        'number': value.number,
        'numberOfElements': value.numberOfElements,
        'pageable': PageableToJSON(value.pageable),
        'size': value.size,
        'sort': SortToJSON(value.sort),
        'totalElements': value.totalElements,
        'totalPages': value.totalPages,
    };
}

