import { SxProps, Theme } from "@mui/material";

const title_divider: SxProps<Theme> = {
  border: "2px solid #EBEBEB",
  opacity: 1,
  width: "100%",
  marginTop: 1,
  marginBottom: 1
};

export const relabelStyles = {
  title_divider
};
