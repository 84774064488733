// Disabled because elements from ThreeJS that start with lowercase
// are not allowed by eslint no-unknown-property rule.
/* eslint-disable react/no-unknown-property */
import { Button, Grid } from "@mui/material";
import { Loader, OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Fragment } from "react";
import { useParams } from "react-router";
import { Location3D } from "../types/Location3D";
import { Tile3D } from "../types/Tile3D";
import { SingleBin } from "./components/SingleBin";
import { MultipleBin } from "./components/MultipleBin";
import { EmptyLevel } from "./components/EmptyLevel";
import DetailDialog from "./components/DetailDialog";
import { ROUTER_PATHS } from "../../../../constants";
import { ResizeObserver } from "@juggle/resize-observer";
import { useTranslation } from "react-i18next";
import { useDetailViewerViewmodel } from "./useDetailViewerViewmodel";
import { useNavigate } from "react-router-dom";
import { generateUUID } from "three/src/math/MathUtils";
import { ClickAnimationProvider } from "./components/ClickAnimationProvider";

const Detail3DViewer = () => {

  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    tiles,
    controls,
    selected,
    dialogOpen,
    handleClick,
    handleReset,
    setDialogOpen,
    target,
    setTarget
  } = useDetailViewerViewmodel(id);

  // Avoid key errors when in this view.
  document.onkeydown = function () {
    return null;
  };

  return (
    <Grid container flexDirection="row">
      <Grid item height="100vh" width="100vw">
        <Canvas camera={{ position: [0, 2, 5] }} id="detail-canvas" resize={{ polyfill: ResizeObserver }}>
          <OrbitControls
            enablePan={false}
            enableRotate={false}
            makeDefault
            target={[0, 1.5, 0]}
            ref={controls}
          />
          <ClickAnimationProvider controls={controls} setTarget={setTarget} target={target} />
          {tiles?.map((tile: Tile3D, i: number) => {
            const repeatedBinLevels = [];
            for (let j = 0; j < (tile.maxY ?? 0); j++) {
              repeatedBinLevels[j] = tile.locations?.filter(loc => loc.level === j + 1 && loc.active).length ?? 0;
            }
            return (
              <group key={tile?.code} userData={{ tile: tile }} position={[i, 0, 0]}>
                {repeatedBinLevels.map((quant: number, index: number) => (
                  <Fragment key={generateUUID()}>
                    {quant === 1 && (
                      <SingleBin
                        location={(tile.locations as Location3D[]).filter(loc => loc.level === index + 1 && loc.active)[0]}
                        handleClick={handleClick}
                      />
                    )}
                    {quant > 1 && (
                      <MultipleBin
                        locationQuant={quant}
                        level={index + 1}
                        locations={(tile.locations as Location3D[]).filter(loc => loc.level === index + 1 && loc.active)}
                        handleClick={handleClick}
                      />
                    )}
                    {quant === 0 && <EmptyLevel level={index + 1} />}
                  </Fragment>
                ))}
              </group>
            );
          })}
        </Canvas>
        <Loader />
      </Grid>
      <DetailDialog selectedBins={selected ?? []} open={dialogOpen} setOpen={setDialogOpen} />
      <Button
        size="small"
        onClick={() => navigate(ROUTER_PATHS.warehouseViewer)}
        sx={{ position: "absolute", top: "1.5rem", left: "6rem" }}>
        {t("viewer.details.back")}
      </Button>
      <Button size="small" onClick={handleReset} sx={{ position: "absolute", top: "5rem", left: "6rem" }}>
        {t("viewer.details.reset.camera")}
      </Button>
    </Grid>
  );
};

export default Detail3DViewer;
