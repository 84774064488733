import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import Material from "@domain/model/Material";
import { useTranslation } from "react-i18next";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";
import { ButtonsDetail } from "@components/master-data-view/buttons/buttons-detail";

export const MaterialsActivationDialog = (props: {
  open: boolean;
  onClose: () => void;
  onActivation: (material: Material) => void;
  material: Material;
}) => {
  const { t } = useTranslation();
  const { open, onClose, onActivation, material } = props;
  const [pendingRequest, setPendingRequest] = useState<boolean>(false);

  useEffect(() => {
    setPendingRequest(false);
  }, [material]);

  return open ? (
    <AggregoDialog
      title={t(material.active ? "material.deactivate.title" : "material.active.title", { material: material.code })}
      onClose={onClose}>
      <Grid container flexDirection="column" className="masterdata-box" rowSpacing={2}>
        <Grid item>
          <div className="confirm-dialog-div-content">
            {material.active ? t("material.form.deactivate") : t("material.form.active")}
          </div>
        </Grid>
        <Grid item>
          <ButtonsDetail
            handleClose={onClose}
            handleSave={() => {
              setPendingRequest(true);
              onActivation(material);
            }}
            mode={material.active ? "ACTIVE" : "INACTIVE"}
            pendingRequest={pendingRequest}
          />
        </Grid>
      </Grid>
    </AggregoDialog>
  ) : (
    <></>
  );
};
