import React from "react";
import { useTranslation } from "react-i18next";
import { useStepper } from "./hooks/useStepper";
import { StepPreview } from "./steps/step-preview";
import { StepCreateUser } from "./steps/step-create-user";
import { StepMasterData } from "./steps/step-master-data";
import {
  Box,
  Stepper,
  Step,
  Button,
  StepLabel,
  StepConnector,
  stepConnectorClasses,
  styled,
  Grid,
  Paper,
  useMediaQuery,
  Theme
} from "@mui/material";
import { COLORS, MEDIA } from "@theme";
import Icon from "@components/icons/icon";
import { CustomStepIcon } from "./components/step-icon";
import { CompanyCreateDTO } from "./company-create-model";
import { AdminTitle } from "@components/admin-view/AdminBoardTitle";
import { useCompanyStepperViewModel } from "./hooks/useFormCompanyStepper";
import { StepCompanyConfiguration } from "./steps/step-company-configuration";

export const StepperCompany = (props: { onClose: () => void }) => {
  const { onClose } = props;

  const { t } = useTranslation();
  const { steps, activeStep, buttonTranslation, handleBack, handleActionButton, handleValidationStepper, getBannedWordsForPassword } = useStepper({
    onClose: onClose
  });
  const { company, onChange, onChangeAutocomplete, setCompany } = useCompanyStepperViewModel(activeStep);

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background: COLORS.gradient
      }
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background: COLORS.gradient
      }
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1
    }
  }));

  const matchesMd = useMediaQuery((theme: Theme) => theme.breakpoints.up(MEDIA.medium), { noSsr: true });

  return (
    <Grid container display="flex" flexDirection="column" id="container-admin-table">
      <AdminTitle icon={<Icon icon="company" color={COLORS.primary} />} name={t("stepper-title")} />
      <Paper component={"div"} style={{ padding: 30 }}>
        <Box component={"div"} width="100%">
          <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
            {steps.map((label, index) => (
              <Step key={label} id={`step-${index}`}>
                <StepLabel StepIconComponent={CustomStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <div>
            <React.Fragment>
              {activeStep === 0 && (
                <StepMasterData
                  companyCreate={company}
                  onChange={onChange}
                  onChangeImage={async image => {
                    image?.type.includes("image/png") || image?.type.includes("image/jp")
                      ? setCompany(CompanyCreateDTO.fromDTO({ ...company, image: image as unknown as string }))
                      : setCompany(CompanyCreateDTO.fromDTO({ ...company, image: undefined }));
                  }}
                  onChangeAddress={address => {
                    setCompany(CompanyCreateDTO.fromDTO({ ...company, address: address }));
                  }}
                />
              )}
              {activeStep === 1 && <StepCreateUser companyCreate={company} onChange={onChange} bannedWordsForPassword={getBannedWordsForPassword(company)} />}
              {activeStep === 2 && (
                <StepCompanyConfiguration companyCreate={company} onChangeAutocomplete={onChangeAutocomplete} />
              )}
              {activeStep === 3 && <StepPreview companyCreate={company} />}
              <Box
                component={"div"}
                display={"flex"}
                flexDirection={"row"}
                paddingTop={"16px"}
                marginTop={!matchesMd ? "4rem" : "2rem"}>
                {activeStep !== 0 && (
                  <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                    {t("stepper-button-back")}
                  </Button>
                )}
                <Button color="inherit" onClick={onClose} sx={{ mr: 1 }}>
                  {t("stepper-button-go-to-list")}
                </Button>
                <Box component={"div"} flex={"1 1 auto"} />
                <Button
                  id="next-button"
                  color="secondary"
                  disabled={handleValidationStepper(company)}
                  onClick={() => {
                    handleActionButton(company);
                  }}
                  sx={{ mr: 1 }}>
                  {t(buttonTranslation())}
                </Button>
              </Box>
            </React.Fragment>
          </div>
        </Box>
      </Paper>
    </Grid>
  );
};
