/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BiometryRequest,
  Error0,
  ExtendedLoginResponse,
  LoginRequest,
  LoginResponse,
  MovicodersBadRequestException,
  MovicodersNotFoundException,
  MovicodersServiceException,
  NfcRequest,
} from '../models';
import {
    BiometryRequestFromJSON,
    BiometryRequestToJSON,
    Error0FromJSON,
    Error0ToJSON,
    ExtendedLoginResponseFromJSON,
    ExtendedLoginResponseToJSON,
    LoginRequestFromJSON,
    LoginRequestToJSON,
    LoginResponseFromJSON,
    LoginResponseToJSON,
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
    NfcRequestFromJSON,
    NfcRequestToJSON,
} from '../models';

export interface ActivateDeactivateMFAEmailRequest {
    xTenantId?: string;
}

export interface ActivateDeactivateMFAOPTRequest {
    xTenantId?: string;
}

export interface BiometryOperationRequest {
    xTenantId?: string;
    biometryRequest?: BiometryRequest;
}

export interface CheckSessionTokenRequest {
    token: string;
    xTenantId?: string;
}

export interface DeactivateMFARequest {
    xTenantId?: string;
}

export interface GenerateQRbyCodeRequest {
    secret: string;
    xTenantId?: string;
}

export interface GetUsersWithSessionRequest {
    xTenantId?: string;
}

export interface IsValidTokenRequest {
    token: string;
    xTenantId?: string;
}

export interface LoginOperationRequest {
    xTenantId?: string;
    loginRequest?: LoginRequest;
}

export interface MfaLoginEmailRequest {
    emailCode: string;
    xTenantId?: string;
    loginRequest?: LoginRequest;
}

export interface MfaLoginOPTRequest {
    otpCode: string;
    xTenantId?: string;
    loginRequest?: LoginRequest;
}

export interface NfcOperationRequest {
    xTenantId?: string;
    nfcRequest?: NfcRequest;
}

export interface PortalLoginRequest {
    xTenantId?: string;
    loginRequest?: LoginRequest;
}

export interface RefreshSessionTokenRequest {
    xTenantId?: string;
    token?: string;
}

/**
 * 
 */
export class LoginApi extends runtime.BaseAPI {

    /**
     * Activate or Deactivate MFA Email by credentials
     * activateDeactivateMFAEmail
     */
    async activateDeactivateMFAEmailRaw(requestParameters: ActivateDeactivateMFAEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/login/credentials/mfa/email/activate-deactivate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     * Activate or Deactivate MFA Email by credentials
     * activateDeactivateMFAEmail
     */
    async activateDeactivateMFAEmail(requestParameters: ActivateDeactivateMFAEmailRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse> {
        const response = await this.activateDeactivateMFAEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Activate or Deactivate MFA OPT by credentials
     * activateDeactivateMFAOPT
     */
    async activateDeactivateMFAOPTRaw(requestParameters: ActivateDeactivateMFAOPTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/login/credentials/mfa/opt/activate-deactivate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     * Activate or Deactivate MFA OPT by credentials
     * activateDeactivateMFAOPT
     */
    async activateDeactivateMFAOPT(requestParameters: ActivateDeactivateMFAOPTRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse> {
        const response = await this.activateDeactivateMFAOPTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Authentication using biometry
     * Biometry
     */
    async biometryRaw(requestParameters: BiometryOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/login/biometry`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BiometryRequestToJSON(requestParameters.biometryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     * Authentication using biometry
     * Biometry
     */
    async biometry(requestParameters: BiometryOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse> {
        const response = await this.biometryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set session token 
     * Check session token
     */
    async checkSessionTokenRaw(requestParameters: CheckSessionTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling checkSessionToken.');
        }

        const queryParameters: any = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/login/session`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     * Set session token 
     * Check session token
     */
    async checkSessionToken(requestParameters: CheckSessionTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse> {
        const response = await this.checkSessionTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deactivate MFA OPT and Email by credentials
     * deactivateMFA
     */
    async deactivateMFARaw(requestParameters: DeactivateMFARequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/login/credentials/mfa/deactivate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     * Deactivate MFA OPT and Email by credentials
     * deactivateMFA
     */
    async deactivateMFA(requestParameters: DeactivateMFARequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse> {
        const response = await this.deactivateMFARaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate a QR Code by Op1 Code
     * generateQRbyCode
     */
    async generateQRbyCodeRaw(requestParameters: GenerateQRbyCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.secret === null || requestParameters.secret === undefined) {
            throw new runtime.RequiredError('secret','Required parameter requestParameters.secret was null or undefined when calling generateQRbyCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/login/credentials/mfa-code-qr/{secret}`.replace(`{${"secret"}}`, encodeURIComponent(String(requestParameters.secret))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Generate a QR Code by Op1 Code
     * generateQRbyCode
     */
    async generateQRbyCode(requestParameters: GenerateQRbyCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.generateQRbyCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get users with active session
     * Get users with active session
     */
    async getUsersWithSessionRaw(requestParameters: GetUsersWithSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/login/getUsersWithSession`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Get users with active session
     * Get users with active session
     */
    async getUsersWithSession(requestParameters: GetUsersWithSessionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getUsersWithSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check if session token is active
     */
    async isValidTokenRaw(requestParameters: IsValidTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling isValidToken.');
        }

        const queryParameters: any = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/login/session/active`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Check if session token is active
     */
    async isValidToken(requestParameters: IsValidTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.isValidTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Authentication using credentials with unencrypted password in apk
     * Login
     */
    async loginRaw(requestParameters: LoginOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExtendedLoginResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/login/credentials`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginRequestToJSON(requestParameters.loginRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtendedLoginResponseFromJSON(jsonValue));
    }

    /**
     * Authentication using credentials with unencrypted password in apk
     * Login
     */
    async login(requestParameters: LoginOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExtendedLoginResponse> {
        const response = await this.loginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Authentication using email mfa in portal
     * mfaLoginEmail
     */
    async mfaLoginEmailRaw(requestParameters: MfaLoginEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExtendedLoginResponse>> {
        if (requestParameters.emailCode === null || requestParameters.emailCode === undefined) {
            throw new runtime.RequiredError('emailCode','Required parameter requestParameters.emailCode was null or undefined when calling mfaLoginEmail.');
        }

        const queryParameters: any = {};

        if (requestParameters.emailCode !== undefined) {
            queryParameters['emailCode'] = requestParameters.emailCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/login/mfaLogin/email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginRequestToJSON(requestParameters.loginRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtendedLoginResponseFromJSON(jsonValue));
    }

    /**
     * Authentication using email mfa in portal
     * mfaLoginEmail
     */
    async mfaLoginEmail(requestParameters: MfaLoginEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExtendedLoginResponse> {
        const response = await this.mfaLoginEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Authentication using OPT mfa in portal
     * mfaLoginOPT
     */
    async mfaLoginOPTRaw(requestParameters: MfaLoginOPTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExtendedLoginResponse>> {
        if (requestParameters.otpCode === null || requestParameters.otpCode === undefined) {
            throw new runtime.RequiredError('otpCode','Required parameter requestParameters.otpCode was null or undefined when calling mfaLoginOPT.');
        }

        const queryParameters: any = {};

        if (requestParameters.otpCode !== undefined) {
            queryParameters['otpCode'] = requestParameters.otpCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/login/mfaLogin/opt`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginRequestToJSON(requestParameters.loginRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtendedLoginResponseFromJSON(jsonValue));
    }

    /**
     * Authentication using OPT mfa in portal
     * mfaLoginOPT
     */
    async mfaLoginOPT(requestParameters: MfaLoginOPTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExtendedLoginResponse> {
        const response = await this.mfaLoginOPTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Authentication using nfc id
     * NFC
     */
    async nfcRaw(requestParameters: NfcOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/login/nfc`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NfcRequestToJSON(requestParameters.nfcRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     * Authentication using nfc id
     * NFC
     */
    async nfc(requestParameters: NfcOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse> {
        const response = await this.nfcRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Authentication using credentials with unencrypted password in portal
     * portalLogin
     */
    async portalLoginRaw(requestParameters: PortalLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExtendedLoginResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/login/portalLogin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginRequestToJSON(requestParameters.loginRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtendedLoginResponseFromJSON(jsonValue));
    }

    /**
     * Authentication using credentials with unencrypted password in portal
     * portalLogin
     */
    async portalLogin(requestParameters: PortalLoginRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExtendedLoginResponse> {
        const response = await this.portalLoginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Refresh session token 
     * Refresh session token
     */
    async refreshSessionTokenRaw(requestParameters: RefreshSessionTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>> {
        const queryParameters: any = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/login/refresh`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     * Refresh session token 
     * Refresh session token
     */
    async refreshSessionToken(requestParameters: RefreshSessionTokenRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse> {
        const response = await this.refreshSessionTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
