import { useResolve } from "@movicoders/di";
import { DrawerState } from "@domain/model/DrawerState";
import { IDrawerService, DrawerService } from "@domain/services/IDrawerService";

export const useHandleSelectedWarehouse = () => {
  const serviceDrawer = useResolve<IDrawerService>(DrawerService);
  const persistedDrawerState = serviceDrawer.persistedState ?? new DrawerState();
  const selectedWarehouse = persistedDrawerState.selectedWarehouse;
  const selectedWarehouseCode = selectedWarehouse
    ? persistedDrawerState.warehouses.filter(item => item.id === selectedWarehouse)[0]?.code
    : "";
  return { selectedWarehouseCode, selectedWarehouse };
};
