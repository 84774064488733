import { useEffect, useState } from "react";
import LocationEntity from "@domain/model/Location";

export const useLocationGetDataByUrl = () => {
  const [searchBarFilter, setSearchBarFilter] = useState("");

  useEffect(() => {
    getURIFilters();
  }, []);

  const getURIFilters = () => {
    const params = new URLSearchParams(window.location.search);
    const movementReport = params.get("search");
    if (movementReport) {
      const preselectedFilterObject = JSON.parse(decodeURIComponent(movementReport));
      applyUrlObtainedFilter(preselectedFilterObject);
    }
  };

  const applyUrlObtainedFilter = (preselectedFilterObject: LocationEntity) => {
    LocationEntity.isLocation(preselectedFilterObject) && setSearchBarFilter(preselectedFilterObject.code ?? "");
  };

  return {
    searchBarFilter
  };
};
