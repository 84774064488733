/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WMReportRelabelRequest
 */
export interface WMReportRelabelRequest {
    /**
     * 
     * @type {string}
     * @memberof WMReportRelabelRequest
     */
    newContainerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof WMReportRelabelRequest
     */
    oldContainerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof WMReportRelabelRequest
     */
    operatorId?: string;
    /**
     * 
     * @type {number}
     * @memberof WMReportRelabelRequest
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof WMReportRelabelRequest
     */
    warehouseCode?: string;
}

/**
 * Check if a given object implements the WMReportRelabelRequest interface.
 */
export function instanceOfWMReportRelabelRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WMReportRelabelRequestFromJSON(json: any): WMReportRelabelRequest {
    return WMReportRelabelRequestFromJSONTyped(json, false);
}

export function WMReportRelabelRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WMReportRelabelRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'newContainerCode': !exists(json, 'newContainerCode') ? undefined : json['newContainerCode'],
        'oldContainerCode': !exists(json, 'oldContainerCode') ? undefined : json['oldContainerCode'],
        'operatorId': !exists(json, 'operatorId') ? undefined : json['operatorId'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'warehouseCode': !exists(json, 'warehouseCode') ? undefined : json['warehouseCode'],
    };
}

export function WMReportRelabelRequestToJSON(value?: WMReportRelabelRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'newContainerCode': value.newContainerCode,
        'oldContainerCode': value.oldContainerCode,
        'operatorId': value.operatorId,
        'quantity': value.quantity,
        'warehouseCode': value.warehouseCode,
    };
}

