import { t } from "i18next";
import { OverridableStringUnion } from "@mui/types";
import { UserRoleEnum } from "@domain/interface/User";
import { InvPeriodDTO } from "@domain/model/InvPeriod";
import { usePermissions } from "@hooks/usePermissions";
import { MasterDataTitleStyles } from "../master-data-view/MasterDataTitleStyles";
import { Button, ButtonPropsVariantOverrides, Divider, Grid, Typography } from "@mui/material";

export const ReportTitle = (props: {
  name: string;
  icon: JSX.Element;
  isPeriodActive?: boolean;
  selectedPeriod?: InvPeriodDTO;
  handleDialogVisibility?: () => void;
  isInitPeriodButtonDisabled?: boolean;
}) => {
  const { name, icon, isPeriodActive, handleDialogVisibility, isInitPeriodButtonDisabled, selectedPeriod } = props;
  const { isValidPermissions } = usePermissions();
  const styles = MasterDataTitleStyles();

  const renderButton = (
    id: string,
    variant: OverridableStringUnion<"text" | "outlined" | "contained", ButtonPropsVariantOverrides> | undefined,
    onClick: (() => void) | undefined,
    text: string
  ) => {
    return (
      <Button id={id} variant={variant} onClick={onClick} fullWidth disabled={isInitPeriodButtonDisabled}>
        {t(text)}
      </Button>
    );
  };

  const renderButtonByPeriodStatus = () => {
    if (selectedPeriod?.endDate !== undefined && isPeriodActive)
      return renderButton("inventory-registry-close-button", "disabled", undefined, "invregistry.close.button");
    if (isPeriodActive)
      return renderButton("inventory-registry-close-button", "alert", handleDialogVisibility, "invregistry.close.button");
    return renderButton("inventory-registry-start-button", "primary", handleDialogVisibility, "invregistry.init.button");
  };

  return (
    <>
      <Grid item display="flex" flexDirection="row" justifyContent="space-between" md={12}>
        <Grid height={40} alignItems="flex-end" item id="report-title-grid" className="main-view-title" xs={9}>
          {icon}
          <Typography id="report-title-typo" ml="1rem">
            {name}
          </Typography>
        </Grid>
        <Grid item id="report-button-grid" className="main-view-title" xs={3}>
          {isValidPermissions([UserRoleEnum.PortalAdmin]) && name === t("invregistry") && renderButtonByPeriodStatus()}
        </Grid>
      </Grid>
      <Divider sx={styles.divider} />
    </>
  );
};
