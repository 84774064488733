import { DatePicker } from "@mui/x-date-pickers";
import { Grid, SxProps, TextField, Theme } from "@mui/material";
import { formDateInputStyles } from "./data-grid-display-form-date-styles";
import { CSSSelectorObjectOrCssVariables } from "@mui/system/styleFunctionSx";

export interface IDateInput {
  id: string;
  name: string;
  value: Date | undefined;
  label: string;
  errorText: string;
  helperText: string;
  minDate?: string;
  maxDate?: string;
  size?: "small" | "medium";
  onChange: (value: unknown, keyboardInputValue?: string | undefined) => void;
  disabled?: boolean;
  openPickerButtonId?: string;
  customContainerStyles?: CSSSelectorObjectOrCssVariables<Theme>;
  customDatePickerStyles?: SxProps<Theme>;
}

export const DataGridDisplayDateInput = (dateProps: IDateInput) => {
  const styles = formDateInputStyles(dateProps.size);
  return (
    <Grid item sx={{ paddingTop: dateProps.customContainerStyles?.paddingTop ?? "5px", ...styles.form_control }}>
      <DatePicker
        renderInput={props => (
          <TextField
            {...props}
            id={dateProps.id}
            helperText={dateProps.errorText || ""}
            error={Boolean(dateProps.errorText)}
            sx={dateProps.customDatePickerStyles}
          />
        )}
        minDate={dateProps.minDate}
        maxDate={dateProps.maxDate}
        value={dateProps.value || null}
        label={dateProps.label}
        onChange={dateProps.onChange}
        inputFormat="dd/MM/yyyy"
        disabled={dateProps.disabled}
        OpenPickerButtonProps={{
          id: dateProps.openPickerButtonId
        }}
      />
    </Grid>
  );
};
