import { Grid } from "@mui/material";
import Operator from "@domain/model/Operator";
import { OperatorCard } from "./operator-card";
import { useResponsiveGrid } from "@hooks/useResponsiveGrid";
import { NoRecordsFound } from "@components/no-records-found/no-records-found";

interface ICardsDisplay {
  dataPaginated: Operator[];
  handleEdit: (id: string) => void;
  handleActive: (item: Operator) => void;
}

export const OperatorsCardsDisplay = (props: ICardsDisplay) => {
  const { handleMediaQuery } = useResponsiveGrid();
  const columns = handleMediaQuery(4, 3, 2, 2);
  const CARD_GRID_MD = Math.round(12 / columns);
  const CARD_GRID_SM = Math.round(8 / columns);

  return props.dataPaginated?.length && props.dataPaginated.length > 0 ? (
    <Grid container id="container-operator-cards" rowSpacing={1} columnSpacing={1} mt="0.75rem" mb="0.5rem">
      {props.dataPaginated?.map((operator: Operator, index: number) => (
        <Grid item xs={12} sm={CARD_GRID_SM} md={CARD_GRID_MD} lg={CARD_GRID_MD} key={`grid-card-${operator?.code ?? ""}`}>
          <OperatorCard operator={operator} handleEdit={props.handleEdit} handleActive={props.handleActive} />
        </Grid>
      ))}
    </Grid>
  ) : (
    <NoRecordsFound />
  );
};
