import { useEffect, useState } from "react";
import { ISettingsService } from "@domain/services/ISettingsService";
import {
  LogisticEnum,
  MasterDataEnum,
  ReportEnum,
  TMasterDataEnum,
  TrackAndTraceEnum
} from "@pages/settings/settings-interfaces";
import { UserRoleEnum } from "@domain/interface/User";
import { IUserService, UserService } from "@domain/services/IUserService";
import { useResolve } from "@movicoders/di";
import { ChangeSettingsUseCase } from "@application/settings/change-settings-use-case";

export const useWarehouseStatusRechargeView = (settingsService: ISettingsService) => {
  const userService = useResolve<IUserService>(UserService);
  const changeSettingsUseCase = useResolve(ChangeSettingsUseCase);
  const [isPermissionForPageLoaded, setIsPermissionForPageLoaded] = useState<boolean>();

  const checkPermission = () => {
    return (
      settingsService.persistedState?.reportsPermissions !== undefined &&
      settingsService.persistedState?.reportsPermissions.length > 0 &&
      settingsService.persistedState?.reportsPermissions.includes(ReportEnum.WarehousesStatus)
    );
  };

  useEffect(() => {
    setIsPermissionForPageLoaded(checkPermission());
  }, [checkPermission(), settingsService.persistedState?.reportsPermissions.length]);

  useEffect(() => {
    if (userService.user?.credentialsId && !settingsService.persistedState) {
      if (userService.user?.roles.includes(UserRoleEnum.SuperAdmin)) {
        settingsService.saveSettigsState({
          masterdataPermissions: Object.values(MasterDataEnum),
          reportsPermissions: Object.values(ReportEnum),
          logisticOperationsPermissions: Object.values(LogisticEnum),
          trackAndTracePermissions: Object.values(TrackAndTraceEnum)
        });
      } else {
        changeSettingsUseCase.recoverFromCurrentUser().then(response => {
          settingsService.saveSettigsState({
            masterdataPermissions: Array.from(response.masterdataPermissions ?? []) as TMasterDataEnum[],
            reportsPermissions: Array.from(response.reportsPermissions ?? []),
            logisticOperationsPermissions: Array.from(response.logisticOperationsPermissions ?? []),
            trackAndTracePermissions: Array.from(response.trackAndTracePermissions ?? [])
          });
        });
      }
    }
  }, [userService.user?.credentialsId]);

  return { isPermissionForPageLoaded };
};
