import { SxProps, Theme } from "@mui/material";

export const recoverPasswordStyles = () => {
  const view_container: SxProps = {
    px: 1,
    pt: "4rem"
  };

  const view_title: SxProps<Theme> = {
    fontSize: "1.6rem",
    fontWeight: 600,
    ml: "1rem"
  };

  const title_divider: SxProps<Theme> = {
    mb: "2rem",
    mt: "1.5rem",
    borderBottomWidth: "medium"
  };

  const content_container: SxProps<Theme> = {
    borderRadius: "6px",
    boxShadow: "none",
    padding: "1rem",
    minHeight: "70vh",
    mb: "1rem",
    pt: "4rem"
  };

  return {
    view_title,
    title_divider,
    content_container,
    view_container
  };
};
