import { useResolve } from "@movicoders/di";
import { useState, useEffect, SyntheticEvent } from "react";
import { useNavigate } from "react-router-dom";
import { TileGetByIdUseCase } from "../../../../../../application/master-data/tiles/tile-get-by-id-use-case";
import { ContainerDTO, LocationDTO } from "../../../../../../clients/aggrego-proxy";
import { Location3D, location3DFromDTO } from "../../../types/Location3D";
import { ROUTER_PATHS } from "../../../../../../constants";
import { Tile3D, tile3DFromDTO } from "../../../types/Tile3D";
import { ContainerRepository } from "../../../../../../infrastructure/repositories/container-repository";
import Tile from "../../../../../../domain/model/Tile";
import { IDialogsStatesStore } from "../../../stores/dialogs-states-store/IDialogsStatesStore";
import { useDialogsStatesStore } from "../../../stores/dialogs-states-store/dialogs-states-store";

export const useBoxDialogViewmodel = () => {
  const { getBoxDialogOpen, getSelectedBox, setBoxDialogOpen, setSelectedBox, handleOpenAddLocationDialog } =
    useResolve<IDialogsStatesStore>(useDialogsStatesStore);

  const [expanded, setExpanded] = useState("");
  const [tile, setTile] = useState(getSelectedBox());
  const navigate = useNavigate();

  const tileGetByIdUseCase = useResolve(TileGetByIdUseCase);
  const containerRepository = useResolve<ContainerRepository>(ContainerRepository);

  const compareLocations = (a: Location3D, b: Location3D) => {
    if ((a.level ?? 0) >= (b.level ?? 0)) {
      return 1;
    } else return -1;
  };

  const orderedLocations = tile?.locations?.filter(loc => loc.active)?.sort(compareLocations) ?? [];

  const handleStartLocationCreation = (level: number | undefined) => {
    handleOpenAddLocationDialog(level ?? 0, tile?.id ?? "");
  };

  // Gets the containers from a LocationDTO object.
  const getContainersFromLocation = async (location: LocationDTO) => {
    const containers = location.containers ?? [];
    if (containers.length > 0)
      return await containerRepository.getManyByIds({ requestBody: containers }).then(containers => {
        const location3d = location3DFromDTO(location);
        location3d.containers = containers ?? [];
        return location3d;
      });
    else return Promise.resolve(location3DFromDTO(location));
  };

  // Gets the selected tile locations data and assigns a new tile.
  const handleSearchTileData = async (tileToSearch: Tile) => {
    const locations = tileToSearch.locations ?? [];

    const locationsWithContainers = await Promise.all(locations.map(getContainersFromLocation)).catch(() =>
      locations.map(location => location3DFromDTO(location, tileToSearch.id))
    );

    const newTile: Tile3D = tile3DFromDTO(tileToSearch);
    newTile.locations = locationsWithContainers;
    setTile(newTile);
  };

  // Get the new selected tile data.
  useEffect(() => {
    if (getSelectedBox())
      tileGetByIdUseCase
        .execute(getSelectedBox()?.id ?? "")
        .then(handleSearchTileData)
        .catch(() => setTile(getSelectedBox()));
  }, [getSelectedBox()]);

  const handleCloseDialog = () => {
    setSelectedBox(undefined);
    setExpanded("");
    setBoxDialogOpen(false);
  };

  // Handler to change the currently open accordion.
  const handleChange = (panel: Location3D) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded && (panel.containers?.length ?? 0) > 0 ? panel.id ?? "" : "");
  };

  // Get a summary of the materials inside the received containers.
  const getTotalMaterials = (containers: ContainerDTO[]) => {
    const result: Map<string, number> = new Map<string, number>();
    containers.forEach(container => {
      const temp = result.get(container.material as string);
      if (temp) {
        result.set(container.material ?? "", temp + (container.qty ?? 0));
      } else {
        result.set(container.material ?? "", container.qty ?? 0);
      }
    });
    return result;
  };

  // Returns an array of lower levels without locations so they can be created from the menu.
  const getEmptyLevels = (currentLevels: number[]) => {
    const emptyLevels: number[] = [];
    for (let i = 1; i < currentLevels[0]; i++) {
      emptyLevels.push(i);
    }
    return emptyLevels;
  };

  const handleNavigateToDetails = () => navigate(ROUTER_PATHS.warehouseViewer + "/" + tile?.id);

  return {
    expanded,
    tile,
    orderedLocations,
    getBoxDialogOpen,
    handleCloseDialog,
    handleChange,
    getTotalMaterials,
    handleNavigateToDetails,
    handleStartLocationCreation,
    getEmptyLevels
  };
};
