import { useResolve } from "@movicoders/di";
import { Grid, Paper, Typography } from "@mui/material"
import { IZonesEdition3DStore } from "../../../stores/zones-edition-3d-store/IZonesEdition3DStore";
import { useZonesEdition3DStore } from "../../../stores/zones-edition-3d-store/zones-edition-3d-store";
import { useTranslation } from "react-i18next";

export const ZoneInfoDialog = () => {
  const { t } = useTranslation();
  const { getHoveredZone } = useResolve<IZonesEdition3DStore>(useZonesEdition3DStore);

  if (getHoveredZone() === "")
    return null;

  return (
    <Grid position="absolute" top="1rem" right="1rem" display="block">
      <Paper sx={{ padding: 0.5, backgroundColor: "rgba(0, 0, 0, 0.6)" }}>
        <Typography color="white">{t(getHoveredZone())}</Typography>
      </Paper>
    </Grid>
  );
};
