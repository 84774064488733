import { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { ALL_COUNTRIES, CountryObject } from "@constants";
import { Autocomplete, Box, TextField } from "@mui/material";

interface CountriesAutocompleteProps {
  disabled: boolean;
  onChangeAutocomplete: (event: SyntheticEvent<Element, Event>, value: CountryObject | null) => void;
  initialValue?: string;
}

export const DefaultCountriesAutocomplete = (props: CountriesAutocompleteProps) => {
  const { t } = useTranslation();
  const { disabled, onChangeAutocomplete, initialValue } = props;

  return (
    <Autocomplete
      id="stakeholder-country-autocomplete"
      value={{ label: initialValue ?? "", code: "", phone: "" } ?? ALL_COUNTRIES}
      options={ALL_COUNTRIES.sort((a, b) => t(a?.label).localeCompare(t(b?.label)))}
      disableClearable
      disabled={disabled}
      autoHighlight
      getOptionLabel={option => t(option.label)}
      onChange={onChangeAutocomplete}
      renderOption={(props, option) => (
        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {t(option.label)}
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          className={"blue-border-input"}
          label={t("stakeholders.country")}
          disabled={disabled}
          inputProps={{
            ...params.inputProps,
            style: { height: "1.12rem" }
          }}
        />
      )}
    />
  );
};
