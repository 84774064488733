import { useEffect, useState } from "react";
import { useResolve } from "@movicoders/di";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { parseJwt } from "@utils/token-helper";
import { TUserRoleEnum, UserRoleEnum } from "@domain/interface/User";
import { PortalTypes } from "@domain/model/PortalTypes";
import { useHandleError, useSnackbar } from "@movicoders/ui";
import { LoginUseCase } from "@application/login/login-use-case";
import { FullUserDTOCompanyLicenseEnum } from "@clients/aggrego-proxy";
import { IUserService, UserService } from "@domain/services/IUserService";
import { IImpersonationService, ImpersonationService } from "@domain/services/IImpersonationService";
import {
  MASTER_DATA_DETAIL_ROUTES,
  NO_IM_ROUTES,
  NO_WM_ROUTES,
  NO_WM_WITH_CONTAINER_ROUTES,
  ROUTER_PATHS,
  UNAUTH_ROUTES
} from "@constants";
import { ChangeSettingsUseCase } from "@application/settings/change-settings-use-case";
import {
  LogisticEnum,
  MasterDataEnum,
  ReportEnum,
  TMasterDataEnum,
  TrackAndTraceEnum
} from "@pages/settings/settings-interfaces";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { HierarchyFiltersService, IHierarchyFiltersService } from "@domain/services/IHierarchyFiltersService";

export const useAppNavigation = () => {
  const { t } = useTranslation();
  const { show } = useSnackbar();
  const [canGetRoles, setCanGetRoles] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const loginUseCase = useResolve(LoginUseCase);
  const userService = useResolve<IUserService>(UserService);
  const impersonationService = useResolve<IImpersonationService>(ImpersonationService);
  const changeSettingsUseCase = useResolve(ChangeSettingsUseCase);
  const settingsService = useResolve<ISettingsService>(SettingsService);
  const hierarchyFiltersService = useResolve<IHierarchyFiltersService>(HierarchyFiltersService);

  const isRole = (role: TUserRoleEnum) => userService.user?.roles.includes(role);

  useEffect(() => {
    if (!location.pathname.includes("products")) {
      hierarchyFiltersService.clearHierarchyFiltersState();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!validToken() && showLayout()) {
      navigate(ROUTER_PATHS.login);
      window.location.reload();
    }

    if (canGetRoles && validToken() && (userService.user?.roles.length ?? 0) > 0) {
      doUpdateSettings();
      setCanGetRoles(false);
    }
  }, [navigate, sessionStorage, location.pathname]);

  useEffect(() => {
    if (
      impersonationService.persistedState?.portal === PortalTypes.ADMIN &&
      window.location.pathname !== "admin-board" &&
      showLayout()
    ) {
      navigate(ROUTER_PATHS.adminBoard);
    }
  }, [impersonationService.persistedState?.portal]);

  useEffect(() => {
    (isRole("PORTAL_USER") || (isRole("MOB_USER") && !isRole("PORTAL_ADMIN") && !isRole("SUPER_ADMIN"))) &&
      MASTER_DATA_DETAIL_ROUTES.forEach(route => {
        if (window.location.pathname.includes(route)) {
          navigate(ROUTER_PATHS.home);
          window.location.reload();
        }
      });

    if (userService.user?.companyLicense === FullUserDTOCompanyLicenseEnum.Im) {
      NO_IM_ROUTES.forEach(route => {
        if (window.location.pathname.includes(route)) {
          navigate(ROUTER_PATHS.home);
        }
      });
    }

    if (userService.user?.companyLicense !== FullUserDTOCompanyLicenseEnum.Im) {
      if (userService.user?.companyLicense === FullUserDTOCompanyLicenseEnum.Wm) {
        NO_WM_ROUTES.forEach(route => {
          if (window.location.pathname.includes(route)) {
            navigate(ROUTER_PATHS.home);
          }
        });
      } else if (userService.user?.companyLicense === FullUserDTOCompanyLicenseEnum.WmWithContainer) {
        NO_WM_WITH_CONTAINER_ROUTES.forEach(route => {
          if (window.location.pathname.includes(route)) {
            navigate(ROUTER_PATHS.home);
          }
        });
      }
    }

    if (!isRole("PORTAL_ADMIN") && !isRole("SUPER_ADMIN") && window.location.pathname.includes("/settings")) {
      navigate(ROUTER_PATHS.home);
    }
  }, [window.location.pathname]);

  useHandleError((param: PromiseRejectionEvent) => {
    if (param.reason?.response?.status === 401 && userService.user?.token) {
      logout();
    } else if (param.reason?.response?.status === 401) {
      show(t("root.unauthorized"), "error");
    }
  });

  const logout = () => {
    loginUseCase.forceFinishingExistingSession();
    show(t("root.unauthorized"), "error");
    userService.clear();
    navigate("/");
  };

  const validToken = () => {
    const storagedToken = sessionStorage.getItem("sessionToken");
    if (storagedToken === null) {
      return false;
    }
    const parsedToken = parseJwt(storagedToken);
    // Check if the token is valid with the exp date.
    const now = Date.now();
    if (parsedToken.exp ?? 0 > now) {
      return true;
    }
    return false;
  };

  const showLayout = (): boolean => {
    const pathname = window.location.pathname;
    const parts = pathname.split("/");
    const result = UNAUTH_ROUTES.find(r => r.includes(parts[1]));
    return !result;
  };

  const doUpdateSettings = () => {
    if (userService.user?.roles.includes(UserRoleEnum.SuperAdmin)) {
      settingsService.saveSettigsState({
        masterdataPermissions: Object.values(MasterDataEnum),
        reportsPermissions: Object.values(ReportEnum),
        logisticOperationsPermissions: Object.values(LogisticEnum),
        trackAndTracePermissions: Object.values(TrackAndTraceEnum)
      });
    } else {
      changeSettingsUseCase
        .recoverFromCurrentUser()
        .then(response => {
          settingsService.saveSettigsState({
            masterdataPermissions: Array.from(response.masterdataPermissions ?? []) as TMasterDataEnum[],
            reportsPermissions: Array.from(response.reportsPermissions ?? []),
            logisticOperationsPermissions: Array.from(response.logisticOperationsPermissions ?? []),
            trackAndTracePermissions: Array.from(response.trackAndTracePermissions ?? [])
          });
        })
        .catch(() => show(`${t("permissions.fetch.error.code")}: ${t("permissions.fetch.error")}`, "error"));
    }
  };

  return { showLayout };
};
