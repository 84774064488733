import { Grid } from "@mui/material";
import Family from "@domain/model/Family";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ButtonsDetail } from "@components/master-data-view/buttons/buttons-detail";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";

const FamilyActiveDialog = (props: {
  open: boolean;
  onClose: () => void;
  onActive: (company: Family) => void;
  family: Family;
}) => {
  const { t } = useTranslation();
  const { open, family, onClose, onActive } = props;

  const [pendingRequest, setPendingRequest] = useState<boolean>(false);

  useEffect(() => {
    setPendingRequest(false);
  }, [family]);

  return open ? (
    <AggregoDialog
      title={(family.active ? t("families.deactivate.title") : t("families.active.title")) + family.name + "?"}
      onClose={onClose}>
      <Grid container flexDirection="column" className="masterdata-box" rowSpacing={2}>
        <Grid item>
          <div className="confirm-dialog-div-content">
            {family.active ? t("families.form.deactivate") : t("families.form.active")}
          </div>
        </Grid>

        <Grid item>
          <ButtonsDetail
            handleClose={onClose}
            handleSave={() => {
              setPendingRequest(true);
              onActive(family);
            }}
            mode={family.active ? "ACTIVE" : "INACTIVE"}
            pendingRequest={pendingRequest}
          />
        </Grid>
      </Grid>
    </AggregoDialog>
  ) : (
    <></>
  );
};

export default FamilyActiveDialog;
