import { LocationApi } from "../api/location-api";
import {
  DeleteLocationsRequest,
  GetLocationsByExactCodeRequest,
  ListLocationsPaginatedRequest,
  SaveLocationsRequest
} from "@clients/aggrego-proxy/apis/LocationsApi";
import LocationEntity from "@domain/model/Location";
import { DomainPage, IRepositoryPaginated } from "@movicoders/domain";
import { ListResponseDTO, LocationDTO } from "@clients/aggrego-proxy";

export class LocationRepository implements IRepositoryPaginated<LocationEntity, ListLocationsPaginatedRequest> {
  constructor(private readonly locationApi: LocationApi) {}

  get(): Promise<LocationEntity[]> {
    return this.locationApi.get();
  }

  getPaginated(pageCriteria: ListLocationsPaginatedRequest): Promise<DomainPage<LocationEntity>> {
    return this.locationApi.getPaginated(pageCriteria);
  }
  getById(id: string): Promise<LocationEntity> {
    return this.locationApi.getById(id);
  }
  async create(entity: LocationEntity): Promise<LocationEntity> {
    return await this.locationApi.create(entity);
  }
  async update(entity: LocationEntity): Promise<LocationEntity> {
    return await this.locationApi.update(entity);
  }
  async remove(id: string): Promise<void> {
    await this.locationApi.remove(id);
  }
  async createMany(criteria: SaveLocationsRequest): Promise<ListResponseDTO> {
    return await this.locationApi.createMany(criteria);
  }
  async removeMany(criteria: DeleteLocationsRequest): Promise<Array<LocationDTO>> {
    return await this.locationApi.removeMany(criteria);
  }
  async getByExactCode(criteria: GetLocationsByExactCodeRequest): Promise<LocationDTO> {
    return await this.locationApi.getByExactCode(criteria);
  }
}
