/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WMGenericPartialConsumeRequest
 */
export interface WMGenericPartialConsumeRequest {
    /**
     * 
     * @type {string}
     * @memberof WMGenericPartialConsumeRequest
     */
    locationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof WMGenericPartialConsumeRequest
     */
    materialCode?: string;
    /**
     * 
     * @type {number}
     * @memberof WMGenericPartialConsumeRequest
     */
    materialQty?: number;
    /**
     * 
     * @type {string}
     * @memberof WMGenericPartialConsumeRequest
     */
    operatorId?: string;
    /**
     * 
     * @type {string}
     * @memberof WMGenericPartialConsumeRequest
     */
    warehouseId?: string;
}

/**
 * Check if a given object implements the WMGenericPartialConsumeRequest interface.
 */
export function instanceOfWMGenericPartialConsumeRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WMGenericPartialConsumeRequestFromJSON(json: any): WMGenericPartialConsumeRequest {
    return WMGenericPartialConsumeRequestFromJSONTyped(json, false);
}

export function WMGenericPartialConsumeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WMGenericPartialConsumeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locationCode': !exists(json, 'locationCode') ? undefined : json['locationCode'],
        'materialCode': !exists(json, 'materialCode') ? undefined : json['materialCode'],
        'materialQty': !exists(json, 'materialQty') ? undefined : json['materialQty'],
        'operatorId': !exists(json, 'operatorId') ? undefined : json['operatorId'],
        'warehouseId': !exists(json, 'warehouseId') ? undefined : json['warehouseId'],
    };
}

export function WMGenericPartialConsumeRequestToJSON(value?: WMGenericPartialConsumeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locationCode': value.locationCode,
        'materialCode': value.materialCode,
        'materialQty': value.materialQty,
        'operatorId': value.operatorId,
        'warehouseId': value.warehouseId,
    };
}

