import { DTOConvertibleEntity } from "./GenericPage";
import { HistoricalEventDTO, HistoricalEventDTOEventTypeEnum } from "@clients/aggrego-proxy";

export class ProductEvent extends DTOConvertibleEntity<HistoricalEventDTO, ProductEvent> {
  public constructor();
  public constructor(obj?: Partial<ProductEvent>);
  public constructor(obj?: Partial<ProductEvent>) {
    super();
    obj && Object.assign(this, obj);
  }
  /**
   *
   * @type {string}
   * @memberof HistoricalEventDTO
   */
  delegatedFrom?: string;
  /**
   *
   * @type {Date}
   * @memberof HistoricalEventDTO
   */
  eventDate?: Date;
  /**
   *
   * @type {string}
   * @memberof HistoricalEventDTO
   */
  eventId?: string;
  /**
   *
   * @type {string}
   * @memberof HistoricalEventDTO
   */
  eventType?: HistoricalEventDTOEventTypeEnum;

  public static fromDTO(event: HistoricalEventDTO): ProductEvent {
    return new ProductEvent({ ...event });
  }

  public toDTO(): HistoricalEventDTO {
    return { ...this };
  }
}
