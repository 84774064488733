import { COLORS } from "@theme";
import { useState } from "react";
import Icon from "@components/icons/icon";
import Material from "@domain/model/Material";
import { useTranslation } from "react-i18next";
import { GridValidRowModel } from "@mui/x-data-grid";
import { InvPeriodDTO } from "@domain/model/InvPeriod";
import { inventoryColumns } from "./inventory-report-columns";
import { ReportTitle } from "@components/report-view/ReportTitle";
import { Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import { OutletContainer } from "@components/outlet-container/outlet-container";
import { DataGridDisplay } from "@components/data-grid-display/data-grid-display";
import { InventoryReportSearchBar } from "./components/inventory-report-search-bar";
import { useInventoryReportViewModel } from "./hooks/viewmodel/useInventoryReportViewModel";
import { LoadingSpinnerMasterView } from "@components/loading-circular-progress/loading-spinner-master-view";
import { ReportEnum } from "@pages/settings/settings-interfaces";

export const InventoryReportView = () => {
  const { t } = useTranslation();
  const matchesSM = useMediaQuery((theme: Theme) => theme.breakpoints.up(500), { noSsr: true });
  const matchesLG = useMediaQuery((theme: Theme) => theme.breakpoints.up(1250), { noSsr: true });

  const [searchFunction, setSearchFunction] = useState<((searchBarValue: string | Map<string, string>) => void) | undefined>(
    (searchBarValue: string | Map<string, string>) => {
      return null;
    }
  );

  const {
    searchValue,
    columnsByMode,
    setSearchValue,
    clearSearchValue,
    handleEnterReload,
    showErrors,
    sortedPeriods,
    inventoryReports,
    search,
    selectedPeriod,
    setSelectedPeriod,
    loading,
    filteredMaterialPaginatedData,
    settingsService
  } = useInventoryReportViewModel();

  const showPeriodStatus = () => {
    return (
      <Typography fontSize="1.125rem" color={COLORS.primary} fontWeight="600" mt="1rem">
        {selectedPeriod?.periodActive ? t("invrep.open.process") : t("invrep.closed.process")}
      </Typography>
    );
  };

  return (
    <>
      <OutletContainer>
        <Grid container id="first-grid-container" direction="column" mb={1}>
          <Grid container display="flex" flexDirection="column" sx={{ mb: 2 }}>
            <ReportTitle icon={<Icon icon="inventory" color={COLORS.primary} />} name={t("invreport")} />
          </Grid>

          {settingsService.persistedState?.reportsPermissions.includes(ReportEnum.InventoryReport) && (
            <DataGridDisplay
              data={(inventoryReports?.content as GridValidRowModel[]) ?? []}
              columns={inventoryColumns(t, matchesSM, matchesLG)}
              mode="server"
              viewName="invreport"
              handleBackendPagination={(
                limit: number,
                offset: number,
                status?: "ALL" | "ACTIVE" | "INACTIVE" | undefined,
                filters?: object | undefined
              ) => {
                search(limit, offset, filters);
              }}
              hideColumnsByField={columnsByMode}
              backendTotalElements={inventoryReports?.totalElements ?? 0}
              toolbarProps={{
                multipleSelectOptions: new Map<string, string[]>([
                  ["materialCode", (filteredMaterialPaginatedData as unknown as Material[])?.map(material => material.code) ?? []]
                ]),
                isActiveFilterHidden: true,
                disableColumnsVisibilityByField: [...columnsByMode, "actions"],
                disableFilterByFields: [...columnsByMode, "actions"],
                disableFieldsInDownload: [...columnsByMode, "actions"],
                customSearchBar: doFilter => {
                  searchFunction && setSearchFunction(doFilter);
                  return (
                    <Grid item id="invreport-customtable-grid" display="inline-block" sm={8} sx={{ mt: "-2rem" }}>
                      <InventoryReportSearchBar
                        clearSearchValue={clearSearchValue}
                        handleEnterReload={handleEnterReload}
                        periods={sortedPeriods}
                        searchValue={searchValue}
                        selectPeriod={selectedPeriod ?? {}}
                        setSearchValue={setSearchValue}
                        setSelectPeriod={setSelectedPeriod}
                        showErrors={showErrors}
                        defaultPeriod={sortedPeriods[0] as InvPeriodDTO}
                      />
                    </Grid>
                  );
                },
                detailComponent: showPeriodStatus()
              }}
            />
          )}
        </Grid>
      </OutletContainer>
      <LoadingSpinnerMasterView loading={loading} />
    </>
  );
};
