import { convertDateToString } from "@utils/date-helper";

export enum DateErrors {
  DATE_BELOW_MIN = ".below.min",
  DATE_ABOVE_MAX = ".above.max",
  DATE_INVALID = ".not.valid",
  DATE_CORRECT = ""
}

export interface IParentFormErrors {
  materialCode: boolean;
  batch: boolean;
  qty: boolean;
  uom: boolean;
  serialNumber: boolean;
  manufactureOrder?: boolean;
  createdAt?: boolean;
  createdAtError?: DateErrors;
  manufacturingDate: boolean;
}
export class ParentFormErrors implements IParentFormErrors {
  materialCode = false;
  batch = false;
  qty = false;
  uom = false;
  serialNumber = false;
  manufactureOrder = false;
  createdAt = false;
  createdAtError = DateErrors.DATE_CORRECT;
  manufacturingDate = false;
  hasErrors(): boolean {
    return (
      this.materialCode ||
      this.batch ||
      this.qty ||
      this.uom ||
      this.serialNumber ||
      this.manufactureOrder ||
      this.createdAt ||
      this.manufacturingDate
    );
  }

  getCreatedAtError(field: string): DateErrors {
    if (field === "createdAt" && this.createdAtError) return this.createdAtError;
    return DateErrors.DATE_CORRECT;
  }

  checkDate = (date: Date | undefined) => {
    if (date === undefined || date === null || date.toString() === "Invalid Date") {
      this.showInvalidDateError();
      return false;
    }
    const dateString = convertDateToString(date, "MM/dd/yyyy");

    const isValidDate =
      dateString !== null && dateString !== undefined && dateString !== "" && dateString.toString() !== "Invalid Date";
    const isBelowTheMinDate = date < new Date("1900-01-01");
    const isAboveTheMaxDate = date > new Date();

    if (!isValidDate) {
      this.showInvalidDateError();
    } else if (isBelowTheMinDate) {
      this.showBelowMinDateError();
    } else if (isAboveTheMaxDate) {
      this.showAboveTheMaxDateError();
    } else {
      this.removeError();
    }

    return isValidDate && !isBelowTheMinDate && !isAboveTheMaxDate;
  };

  showInvalidDateError = () => {
    this.createdAtError = DateErrors.DATE_INVALID;
    this.manufacturingDate = true;
  };

  showBelowMinDateError = () => {
    this.createdAtError = DateErrors.DATE_BELOW_MIN;
    this.manufacturingDate = true;
  };

  showAboveTheMaxDateError = () => {
    this.createdAtError = DateErrors.DATE_ABOVE_MAX;
    this.manufacturingDate = true;
  };

  removeError = () => {
    this.createdAtError = DateErrors.DATE_CORRECT;
    this.manufacturingDate = false;
  };
}
