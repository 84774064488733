import { COLORS } from "../../../../theme";
import { useTranslation } from "react-i18next";
import Icon from "../../../components/icons/icon";
import { StockDetail } from "./components/stock-detail";
import { stockColumns } from "./materials-stock-columns";
import { StockDTO } from "../../../../domain/model/Stock";
import { materialsStockStyles } from "./materials-stock-styles";
import { UUIDGenerator } from "../../../../utils/uuid-generator";
import { CircularProgress, Divider, Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import { useMaterialsStockViewModel } from "./hooks/viewmodel/useMaterialsStockViewModel";
import { OutletContainer } from "../../../components/outlet-container/outlet-container";
import { DataGridDisplay } from "../../../components/data-grid-display/data-grid-display";
import { LoadingSpinnerMasterView } from "../../../components/loading-circular-progress/loading-spinner-master-view";
import { GridValidRowModel } from "@mui/x-data-grid";
import { ReportEnum } from "@pages/settings/settings-interfaces";
import Family from "@domain/model/Family";

export const MaterialsStockView = () => {
  const { t } = useTranslation();
  const matchesLG = useMediaQuery((theme: Theme) => theme.breakpoints.up(1250), { noSsr: true });

  const styles = materialsStockStyles();

  const {
    handlePagination,
    families,
    materialStockData,
    materialDetailOpen,
    exportingPaginatedData,
    handleDialogOpen,
    onCloseDialog,
    latestStock,
    loading,
    settingsService
  } = useMaterialsStockViewModel();

  return (
    <>
      {settingsService.persistedState?.reportsPermissions.includes(ReportEnum.StockReport) ? (
        <>
          <OutletContainer>
            <Grid container id="first-grid-container" direction="column" mb={1}>
              <Grid item>
                <Grid container wrap="nowrap" spacing={{ xs: "1rem" }} id="second-grid-container">
                  <Grid item alignSelf="center">
                    <Icon icon="box" color={COLORS.primary} />
                  </Grid>
                  <Grid id="report-title" item sx={{ color: COLORS.secondary, fontSize: "x-large", fontWeight: 600 }}>
                    {t("stock")}
                  </Grid>
                </Grid>
                <Divider sx={styles.stock_title_divider} />
              </Grid>
              <Typography sx={{ mb: 2 }} fontSize="1.125rem" color={COLORS.primary} fontWeight="600">
                {`${t("stock.last")} ${latestStock ?? ""}`}
              </Typography>

              <DataGridDisplay
                backendTotalElements={materialStockData.totalElements ?? 0}
                data={(materialStockData.content as GridValidRowModel[]) ?? []}
                columns={stockColumns(t, handleDialogOpen, matchesLG)}
                mode="server"
                viewName="stock"
                hideColumnsByField={["id"]}
                rowId={UUIDGenerator}
                handleBackendPagination={handlePagination}
                toolbarProps={{
                  multipleSelectOptions: new Map<string, string[]>([
                    [
                      "family",
                      families
                        .sort((a: Family, b: Family) => t(a?.name).localeCompare(t(b?.name)))
                        .map((family: Family) => family.name) ?? []
                    ]
                  ]),
                  isActiveFilterHidden: true,
                  disableColumnsVisibilityByField: ["id"],
                  disableFieldsInDownload: ["id", "actions"],
                  disableFilterByFields: ["id", "actions", "families"]
                }}
              />
              {exportingPaginatedData && (
                <StockDetail open={materialDetailOpen} onClose={onCloseDialog} material={exportingPaginatedData as StockDTO} />
              )}
            </Grid>
          </OutletContainer>
          <LoadingSpinnerMasterView loading={loading} />
        </>
      ) : (
        <CircularProgress color="secondary" />
      )}
    </>
  );
};
