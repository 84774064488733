import LocationEntity from "@domain/model/Location";
import { useLocationSave } from "./useLocationSave";
import { useCheckStatus } from "@hooks/useCheckStatus";
import { useLocationPagination } from "./useLocationPagination";
import { useLocationHandleDatas } from "./useLocationHandleDatas";
import { useLocationGetDataByUrl } from "./useLocationGetDataByUrl";
import { useLocationActiveStatus } from "./useLocationActiveStatus";
import { useHandleSelectedWarehouse } from "@hooks/useHandleSelectedWarehouse";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { useResolve } from "@movicoders/di";

export const useLocationViewModel = () => {
  const settingsService = useResolve<ISettingsService>(SettingsService);

  const { getCurrentStatus } = useCheckStatus();
  const { searchBarFilter } = useLocationGetDataByUrl();
  const { selectedWarehouse } = useHandleSelectedWarehouse();

  const {
    currentPageService,
    currentPagePersistedState,
    tilesByWarehouse,
    setTilesByWarehouse,
    findTilesUseCase,
    zonesByWarehouse,
    setZonesByWarehouse,
    findZonesByTenantUseCase,
    loading,
    loadingDetail,
    selectedLocation,
    locationData,
    getLocationData,
    goToCreate,
    goToDetail,
    goToList
  } = useLocationHandleDatas(selectedWarehouse);

  const { activeLocation, handleActiveConfirm, handleLocationStatus, isActivating, onCloseActive } = useLocationActiveStatus(
    currentPagePersistedState,
    selectedWarehouse,
    getLocationData,
    getCurrentStatus
  );

  const { handlePagination, sortByFieldBackend } = useLocationPagination(
    currentPageService,
    currentPagePersistedState,
    locationData?.content as LocationEntity[],
    selectedWarehouse,
    getCurrentStatus,
    getLocationData
  );

  const { handleEdit, handleSave } = useLocationSave(
    selectedWarehouse,
    selectedLocation as LocationEntity,
    currentPagePersistedState,
    getCurrentStatus,
    setTilesByWarehouse,
    findTilesUseCase,
    setZonesByWarehouse,
    findZonesByTenantUseCase,
    goToList,
    goToDetail
  );

  return {
    tilesByWarehouse,
    zonesByWarehouse,
    locationData,
    loading,
    loadingDetail,
    activeLocation,
    selectedLocation,
    isActivating,
    searchBarFilter,
    goToCreate,
    goToList,
    onCloseActive,
    handleSave,
    handleEdit,
    handleActiveConfirm,
    handleLocationStatus,
    handlePagination,
    sortByFieldBackend,
    settingsService
  };
};
