import { Location3D } from "../types/Location3D";

export const getShorterBinCode = (bin: Location3D, length: number): string => {
  const code = bin?.code ?? "";
  const newCode = code.substring(0, length);
  if (newCode.length < code.length) {
    return newCode.concat("...");
  }
  return code;
};
