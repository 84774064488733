import React from "react";
import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import { useTranslation } from "react-i18next";
import { logisticStyles } from "../../logistic-styles";
import { CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { useEntriesForm } from "../hooks/useEntriesForm";
import { LogisticTitle } from "../../components/logistic-title";
import { EntriesBottom } from "../components/view/entries-bottom";
import { useEntriesViewModel } from "../hooks/useEntriesViewModel";
import { EntriesCounter } from "../components/view/entries-counter";
import { EntriesViewForm } from "../components/view/entries-view-form";
import { LogisticCard } from "@components/logistic-view/logistic-card";
import { LogisticDetailView } from "../../components/logistic-detail-view";
import { LogisticDeleteDialog } from "../../components/logistic-delete-dialog";
import { OutletContainer } from "@components/outlet-container/outlet-container";
import { LogisticDeleteAllDialog } from "../../components/logistic-delete-all-dialog";
import { LoginOperatorDialog } from "../../components/logistic-login-dialog/logistic-login-dialog";
import { LoadingSpinnerMasterView } from "@components/loading-circular-progress/loading-spinner-master-view";
import { LogisticEnum } from "@pages/settings/settings-interfaces";

export const InboundsView = () => {
  const { t } = useTranslation();
  const styles = logisticStyles();

  const {
    iMMaterialList,
    isSyncronizing,
    isOperatorLogged,
    noMaterials,
    createdIMMaterial,
    setIMMaterialList,
    setCreatedIMMaterial,
    handleAddMaterial,
    handleInSynchronize,
    settingsService
  } = useEntriesViewModel();

  const {
    isEditing,
    isDeleting,
    isClearingAll,
    selectedEntry,
    setSelectedEntry,
    onChange,
    onCloseDialog,
    handleOpenEditDialog,
    handleOpenDeleteDialog,
    handleSave,
    handleDelete,
    handleOpenClearAllDialog,
    handleClearMaterials
  } = useEntriesForm(iMMaterialList, setIMMaterialList);

  return (
    <>
      {settingsService.persistedState?.logisticOperationsPermissions.includes(LogisticEnum.Inbounds) ? (
        <>
          {!isOperatorLogged && <LoginOperatorDialog />}
          <LoadingSpinnerMasterView loading={isSyncronizing} />
          {isClearingAll && <LogisticDeleteAllDialog open onClose={onCloseDialog} onDelete={handleClearMaterials} />}
          {selectedEntry && <LogisticDeleteDialog open={isDeleting} onClose={onCloseDialog} handleDelete={handleDelete} />}
          {isEditing && selectedEntry && (
            <LogisticDetailView
              title={t("root.edit")}
              entry={selectedEntry}
              setEntry={setSelectedEntry}
              onChange={onChange}
              handleSave={handleSave}
              onClose={onCloseDialog}
              createdIMMaterial={createdIMMaterial}
            />
          )}

          <OutletContainer>
            <React.Fragment>
              <Grid container id="first-grid-container" direction="column" mb={1}>
                <Grid item>
                  <LogisticTitle
                    id={"inbounds-title"}
                    name={t("inbounds")}
                    icon={<Icon icon="material-in" style={{ marginTop: 7 }} color={COLORS.primary} />}
                  />
                </Grid>
              </Grid>

              <Paper sx={styles.logistic_sync_paper}>
                <Grid item sx={{ pb: 0.7 }}>
                  <Typography fontSize="1rem">{t("inbounds.title.description")}</Typography>
                </Grid>

                <EntriesViewForm
                  addButtonText="inbounds.add.button"
                  commentName="material-inbounds-comment"
                  qtyName="material-inbounds-qty"
                  createdIMMaterial={createdIMMaterial}
                  handleAddMaterial={handleAddMaterial}
                  setCreatedIMMaterial={setCreatedIMMaterial}
                />

                <EntriesCounter
                  entryTextPlural="inbounds.in.plural"
                  entryTextSingular="inbounds.in.singular"
                  noMaterials={noMaterials}
                  iMMaterialList={iMMaterialList}
                />

                <Grid container spacing={{ xs: 1, md: 1 }} mt={1}>
                  {iMMaterialList.map(iMMaterial => (
                    <Grid item xs={12} sm={12} md={6} lg={4} key={`grid-card-${iMMaterial.id}`}>
                      <LogisticCard
                        id={iMMaterial?.id ?? ""}
                        IMEntry={iMMaterial ?? {}}
                        isSynchronizing={false}
                        handleOpenDeleteDialog={handleOpenDeleteDialog}
                        handleOpenEditDialog={handleOpenEditDialog}
                      />
                    </Grid>
                  ))}
                </Grid>

                <EntriesBottom
                  noMaterials={noMaterials}
                  handleInSynchronize={handleInSynchronize}
                  handleOpenClearAllDialog={handleOpenClearAllDialog}
                />
              </Paper>
            </React.Fragment>
          </OutletContainer>
        </>
      ) : (
        <CircularProgress color="secondary" />
      )}
    </>
  );
};
