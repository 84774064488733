import { MEDIA } from "@theme";
import { homeTitleStyles } from "./homeTitleStyles";
import { HomeAutocomplete } from "./home-autocomplete";
import { Divider, Grid, Theme, Typography, useMediaQuery } from "@mui/material";

export const HomeTitle = (props: { id: string; name: string; icon: JSX.Element }) => {
  const { id, name, icon } = props;
  const styles = homeTitleStyles();
  const matchesSM = useMediaQuery((theme: Theme) => theme.breakpoints.up(MEDIA.small), { noSsr: true });
  return (
    <>
      <Grid item display={"flex"} flexDirection={matchesSM ? "row" : "column"} justifyContent="space-between" md={12}>
        <Grid sx={styles.home_data_title} item xs={12} md={7}>
          {icon}
          <Typography id={id}>{name}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          id="grid-item-home-autocomplete"
          display="flex"
          justifyContent={matchesSM ? "flex-end" : "flex-start"}
          sx={{ mt: matchesSM ? "0rem" : "1rem", ml: matchesSM ? "1rem" : "0rem" }}>
          <HomeAutocomplete id={"home-autocomplete"} showButton={true}></HomeAutocomplete>
        </Grid>
      </Grid>
      <Grid item display={"flex"} flexDirection={"row"} justifyContent="space-between" alignItems="flex-end"></Grid>
      <Divider sx={styles.divider} />
    </>
  );
};
