import { useSnackbar } from "@movicoders/ui";
import { useTranslation } from "react-i18next";

export const useMasterDataMessagges = () => {
  const { t } = useTranslation();
  const { show } = useSnackbar();

  const successMessaggesStatus = (type: string, status: boolean, name: string) => {
    status
      ? show(
          t(`${type}.deactivated.success`, {
            [type]: `${name}`
          }),
          "success"
        )
      : show(
          t(type + `.activated.success`, {
            [type]: `${name}`
          }),
          "success"
        );
  };

  const errorMessaggesStatus = (type: string, status: boolean, name: string, error: string) => {
    const errorText = name ? "" : ".undefined";
    status
      ? show(
          t(`deactivating.${error}${errorText}`, {
            value: name ? `"${name}"` : "",
            mdNameA: t(`a.${type}`),
            mdNameThe: t(`the.${type}`),
            reason: t(`not.found.${type}`),
            mdNameNew: t(`a.new.${type}`)
          }),
          "error"
        )
      : show(
          t(`activating.${error}${errorText}`, {
            value: name ? `"${name}"` : "",
            mdNameA: t(`a.${type}`),
            mdNameThe: t(`the.${type}`),
            reason: t(`not.found.${type}`),
            mdNameNew: t(`a.new.${type}`)
          }),
          "error"
        );
  };

  const successMessaggesSaving = (type: string, name: string, id: unknown) => {
    id === undefined
      ? show(
          t(`${type}.created.success`, {
            [type]: name ?? ""
          }),
          "success"
        )
      : show(
          t(`${type}.saved.success`, {
            [type]: name ?? ""
          }),
          "success"
        );
  };

  const errorMessaggesSaving = (type: string, name: string, id: unknown, error: string) => {
    const errorText = name ? "" : ".undefined";
    id === undefined
      ? show(
          t(`creating.${error}${errorText}`, {
            value: name ?? "",
            mdNameA: t(`a.${type}`),
            mdNameThe: t(`the.${type}`),
            reason: t(`not.found.${type}`),
            mdNameNew: t(`a.new.${type}`)
          }),
          "error"
        )
      : show(
          t(`saving.${error}${errorText}`, {
            value: name ?? "",
            mdNameA: t(`a.${type}`),
            mdNameThe: t(`the.${type}`),
            reason: t(`not.found.${type}`)
          }),
          "error"
        );
  };

  const errorMessaggesLoading = (type: string, name: string) => {
    show(
      t("loading.detail.error", { value: name ?? "", mdNameThe: t(`the.${type}`) }),
      "error"
    );
  };

  return {
    successMessaggesStatus,
    successMessaggesSaving,
    errorMessaggesStatus,
    errorMessaggesSaving,
    errorMessaggesLoading
  };
};
