import { useResolve } from "@movicoders/di";
import { IUserService, UserService } from "@domain/services/IUserService";

export const usePermissions = () => {
  const userService = useResolve<IUserService>(UserService);

  const isValidPermissions = (permissions: string[]): boolean => {
    return userService.user?.hasPermission(permissions) ?? true;
  };

  return { isValidPermissions };
};
