import { COLORS } from "@theme";
import { useState } from "react";
import Icon from "../../../../icons/icon";
import IPopover from "./popover-interface";
import { useTranslation } from "react-i18next";
import { IconButton, Popover, Typography, SxProps, Theme, Box, Tooltip } from "@mui/material";

const popover_option_styles: SxProps<Theme> = {
  p: 0.4,
  pt: "2px",
  pb: "2px",
  cursor: "pointer",
  width: "7rem"
};

/**
 * Popover custom component.
 * The props are now optional so the options inside the popover can be
 * hidden by not passing the respective methods.
 * @returns The popover component.
 */
export const TitlePopover = (props: IPopover) => {
  const { t } = useTranslation();
  const { handleDelete, handleEdit, activateTitle, handleActive } = props;

  const [isOpenPopover, setIsOpenPopover] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpenPopover(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpenPopover(false);
  };

  return (
    <>
      <Tooltip title={t("tooltip.popover.actions")}>
        <IconButton
          id="popover-button"
          sx={{ padding: 0, height: 35, width: 35 }}
          onClick={event => {
            handleClick(event);
            setIsOpenPopover(true);
          }}>
          <Icon icon="more" color={COLORS.primary} />
        </IconButton>
      </Tooltip>

      <Popover
        id={"popover"}
        open={isOpenPopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        onClose={handleClose}>
        {handleEdit && (
          <Box
            component="div"
            sx={popover_option_styles}
            id="popover-option-edit"
            onClick={() => {
              handleEdit();
              handleClose();
            }}>
            <Typography variant="popover_option_text">{t("root.edit").toLocaleUpperCase()}</Typography>
          </Box>
        )}
        {handleDelete && (
          <Box
            component="div"
            sx={popover_option_styles}
            id="popover-option-delete"
            onClick={() => {
              handleDelete();
              handleClose();
            }}>
            <Typography variant="popover_option_text">{t("root.delete").toLocaleUpperCase()}</Typography>
          </Box>
        )}
        {handleActive && activateTitle && (
          <Box
            component="div"
            sx={popover_option_styles}
            id="popover-option-deactivate"
            onClick={() => {
              handleActive();
              handleClose();
            }}>
            <Typography variant="popover_option_text">{activateTitle.toLocaleUpperCase()}</Typography>
          </Box>
        )}
      </Popover>
    </>
  );
};
