import { useResolve } from "@movicoders/di";
import { useSnackbar } from "@movicoders/ui";
import { ChangeEvent, useState } from "react";
import { ResetPasswordUseCase } from "@application/reset-password/reset-password-use-case";
import { isPasswordValid } from "@components/password/invalid-password-tooltip/InvalidPasswordTooltip";
import { IUserService, UserService } from "@domain/services/IUserService";

export const usePasswordResetViewModel = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmationPass, setConfirmationPass] = useState("");
  const [showEmptyPassError, setShowEmptyPassError] = useState(false);
  const [showConfirmationPassError, setShowConfirmationPassError] = useState(false);

  const { show } = useSnackbar();
  const resetUseCase = useResolve(ResetPasswordUseCase);
  const currentUser = useResolve<IUserService>(UserService).user;

  const handleShowPasswordError = (errorType: string) => {
    setShowEmptyPassError(true);
    setTimeout(() => {
      setShowEmptyPassError(false);
    }, 2000);
    show("profile.pass.reset.error." + errorType, "error");
  };

  const handleShowConfirmationPassError = (errorType: string) => {
    setShowConfirmationPassError(true);
    setTimeout(() => {
      setShowConfirmationPassError(false);
    }, 2000);
    show("profile.pass.reset.error." + errorType, "error");
  };

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value);

  const handleChangeConfirmPassword = (e: ChangeEvent<HTMLInputElement>) => setConfirmationPass(e.target.value);

  const getBannedWordsForPassword = () => {
    const bannedWords: string[] = [];
    if (currentUser?.email) {
      const emailName = currentUser?.email.split('@')[0];
      if (emailName) bannedWords.push(emailName);
    }
    return bannedWords;
  }

  const handleResetSubmit = async () => {
    switch (true) {
      case newPassword.trim() === "" && confirmationPass.trim() === "":
        handleShowPasswordError("empty");
        handleShowConfirmationPassError("empty");
        break;

      case newPassword.trim() === "":
        handleShowPasswordError("empty");
        break;

      case !isPasswordValid(newPassword, getBannedWordsForPassword()):
        handleShowPasswordError("invalid");
        break;

      case confirmationPass.trim() === "":
        handleShowConfirmationPassError("empty");
        break;

      case newPassword !== confirmationPass:
        handleShowPasswordError("match");
        handleShowConfirmationPassError("match");
        break;

      default: {
        const result = await resetUseCase.execute(newPassword);
        if (result) {
          show("profile.pass.reset.success", "success");
        } else {
          show("profile.pass.reset.error", "error");
        }
        setNewPassword("");
        setConfirmationPass("");
      }
    }
  };

  return {
    handleResetSubmit,
    newPassword,
    handleChangePassword,
    showEmptyPassError,
    confirmationPass,
    handleChangeConfirmPassword,
    showConfirmationPassError,
    getBannedWordsForPassword
  };
};
