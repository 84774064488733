import { Scene, Mesh, Group, BoxGeometry, CylinderGeometry, Object3D } from "three";
import { generateUUID } from "three/src/math/MathUtils";
import { Tile3D } from "../types/Tile3D";
import { boxExistsIn } from "./box-finders";
import { DEFAULT_TILE_TEMPLATE_GEOMETRY } from "../constants/default-geometries";
import { CORRIDOR_MATERIAL, DEFAULT_MATERIAL_REDUCING } from "../constants/default-materials";
import { useCreateTilesHelper } from "./tile-helpers/create-tiles-helper";
import { useEditTilesHelpers } from "./tile-helpers/edit-tiles-helper";
import { IEditionStatesStore } from "../stores/edition-states-store/IEditionStatesStore";
import { useResolve } from "@movicoders/di";
import { ICommon3DObjectsStore } from "../stores/common-3d-objects-store/ICommon3DObjectsStore";
import { useCommon3DObjectsStore } from "../stores/common-3d-objects-store/common-3d-objects-store";
import { useEditionStatesStore } from "../stores/edition-states-store/edition-states-store";
import { IWarehouse3DStore } from "../stores/warehouse-3d-store/IWarehouse3DStore";
import { useWarehouse3DStore } from "../stores/warehouse-3d-store/warehouse-3d-store";

export const useDragEndHelpers = (scene: Scene) => {
  const { getWarehouse } = useResolve<IWarehouse3DStore>(useWarehouse3DStore);
  const {
    getTilesBeingModified,
    getStartPoint,
    getEndPoint,
    getWallMaterial
  } = useResolve<ICommon3DObjectsStore>(useCommon3DObjectsStore);
  const {
    getSelectedTileTemplate,
    getModifyMinMax,
    getTemplate
  } = useResolve<IEditionStatesStore>(useEditionStatesStore);
  const {
    createEditHorizontalTiles,
    createEditLevels,
    createEditVerticalTiles
  } = useEditTilesHelpers(scene)
  const {
    showCreatedHorizontalTiles,
    showCreatedUpperLevels,
    showCreatedVerticalTiles
  } = useCreateTilesHelper(scene);

  const showCreatedTiles = () => {
    const startPoint = getStartPoint();
    const endPoint = getEndPoint();
    if (startPoint && endPoint) {
      const sameX = startPoint.position.x === endPoint.position.x;
      const sameY = startPoint.position.y === endPoint.position.y;
      const sameZ = startPoint.position.z === endPoint.position.z;
      const tempObjects = scene.getObjectByName("temp");
      if (tempObjects !== undefined) scene.remove(tempObjects);

      const zoneGroup = new Group();
      zoneGroup.position.set(0, 0, 0);
      zoneGroup.name = "temp";

      switch (true) {
        case boxExistsIn(startPoint.position.x, endPoint.position.x, startPoint.position.z, endPoint.position.z, scene).length > 0:
          break;
        case !sameX && sameZ:
          showCreatedVerticalTiles(getWarehouse(), startPoint, endPoint, zoneGroup);
          break;
        case !sameZ && sameX:
          showCreatedHorizontalTiles(getWarehouse(), startPoint, endPoint, zoneGroup);
          break;
        case !sameY:
          showCreatedUpperLevels(getWarehouse(), startPoint, endPoint, zoneGroup);
          break;
        default: break;
      }
    }
  };

  const showCreatedTemplates = () => {
    const startPoint = getStartPoint();
    const endPoint = getEndPoint();
    scene.remove(scene.getObjectByName("tempTemplates") ?? new Mesh());
    const group = new Group();
    group.name = "tempTemplates";
    const material = getSelectedTileTemplate() === "WALL" ? getWallMaterial() : CORRIDOR_MATERIAL;

    if (
      startPoint && endPoint &&
      boxExistsIn(endPoint.position.x, startPoint.position.x, endPoint.position.z, startPoint.position.z, scene).length === 0
    ) {
      const biggerX = startPoint.position.x > endPoint.position.x ? startPoint.position.x : endPoint.position.x;
      const smallerX = startPoint.position.x < endPoint.position.x ? startPoint.position.x : endPoint.position.x;

      for (let i = smallerX; i <= biggerX; i++) {
        const biggerZ = startPoint.position.z > endPoint.position.z ? startPoint.position.z : endPoint.position.z;
        const smallerZ = startPoint.position.z < endPoint.position.z ? startPoint.position.z : endPoint.position.z;

        for (let j = smallerZ; j <= biggerZ; j++) {
          const newTemplate = new Mesh(DEFAULT_TILE_TEMPLATE_GEOMETRY, material);
          newTemplate.userData.tile = {
            id: generateUUID(),
            code: i + "." + j,
            x: i,
            z: j,
            maxY: 0,
            warehouseId: getWarehouse().id,
            templateId: getTemplate(getSelectedTileTemplate())?.id,
            description: null,
            locations: [],
            zoneId: undefined,
            active: true
          };
          newTemplate.position.set(i, 0.02, j);
          newTemplate.rotation.x = Math.PI * -0.5;
          group.add(newTemplate);
        }
      }
      scene.add(group);
    }
  };

  const showReducedTiles = () => {
    const endPoint = getEndPoint();
    if (endPoint !== undefined) {
      scene.remove(scene.getObjectByName("tempReduce") ?? new Mesh());
      const reduceGroup = new Group();
      reduceGroup.position.set(0, 0, 0);
      reduceGroup.name = "tempReduce";
      getTilesBeingModified()
        .map(g => g.userData.tile)
        .forEach((tile: Tile3D) => {
          const group = new Group();
          group.position.set(tile.x ?? 0, 0, tile.z ?? 0);

          tile.locations?.filter((loc => loc.active)).forEach(location => {
            if ((location.level ?? 0) <= endPoint.position.y + 1) {
              const box = new Mesh(new BoxGeometry(1.1, 1.1, 0.1), DEFAULT_MATERIAL_REDUCING);
              box.position.set(0, (location.level ?? 0) - 1, 0);
              box.rotation.x = Math.PI * 0.5;
              box.rotation.z = Math.PI * 0.5;
              box.name = "reducedBox";
              group.add(box);

              const cylinder = new Mesh(new CylinderGeometry(0.35, 0.35, 0.65, 8), DEFAULT_MATERIAL_REDUCING);
              cylinder.position.set(0, (location.level ?? 0) - 1 + 0.28, 0);
              cylinder.name = "reducedCylinder";
              group.add(cylinder);
            }
          });

          reduceGroup.add(group);
        });
      scene.add(reduceGroup);
    }
  };

  const showEditedTiles = () => {
    const startPoint = getStartPoint();
    const endPoint = getEndPoint();

    if (startPoint && endPoint) {
      const sameX = startPoint.position.x === endPoint.position.x;
      const sameY = startPoint.position.y === endPoint.position.y;
      const sameZ = startPoint.position.z === endPoint.position.z;
      const negX = startPoint.position.x > endPoint.position.x;
      const negZ = startPoint.position.z > endPoint.position.z;

      scene.remove(scene.getObjectByName("tempEdit") ?? new Mesh());

      const zoneGroup = new Group();
      zoneGroup.position.set(0, 0, 0);
      zoneGroup.name = "tempEdit";

      let cantCreateThere = false;
      const boxesInEditZone: Object3D[] = boxExistsIn(
        negX ? getModifyMinMax().maxX : getModifyMinMax().minX,
        endPoint.position.x,
        negZ ? getModifyMinMax().maxZ : getModifyMinMax().minZ,
        endPoint.position.z,
        scene
      );

      boxesInEditZone.forEach(box => {
        if (!getTilesBeingModified().includes(box))
          cantCreateThere = true;
      });

      if (
        getModifyMinMax().minX <= endPoint.position.x &&
        getModifyMinMax().maxX >= endPoint.position.x &&
        getModifyMinMax().minZ <= endPoint.position.z &&
        getModifyMinMax().maxZ >= endPoint.position.z &&
        sameY
      ) cantCreateThere = true;

      switch (true) {
        case cantCreateThere: break;
        case !sameX && sameZ:
          createEditVerticalTiles(negX, endPoint, zoneGroup);
          break;
        case !sameZ && sameX:
          createEditHorizontalTiles(negZ, endPoint, zoneGroup);
          break;
        case !sameY:
          createEditLevels(endPoint, startPoint, zoneGroup);
          break;
        default: break;
      }
    }
  };

  return {
    showCreatedTiles,
    showCreatedTemplates,
    showReducedTiles,
    showEditedTiles
  }
};
