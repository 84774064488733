import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { recoverPasswordStyles } from "./recover-password.Styles";
import useRecoverPasswordViewModel from "./hooks/useRecoverPasswordViewModel";
import { Grid, Typography, Divider, Paper, Container, TextField, Button } from "@mui/material";
import { InvalidPasswordTooltip } from "@components/password/invalid-password-tooltip/InvalidPasswordTooltip";

const RecoverPasswordView = () => {
  const { token } = useParams();
  const { t } = useTranslation();
  const styles = recoverPasswordStyles();

  const { pass, setPass, confirmPass, setConfirmPass, handleRecover, getBannedWordsForPassword } = useRecoverPasswordViewModel(token);

  return (
    <Container sx={styles.view_container} style={{ maxWidth: "none" }}>
      <Grid container wrap="nowrap" alignItems="center" sx={{ color: COLORS.secondary }}>
        <Icon icon="user" color={COLORS.primary} />
        <Typography sx={styles.view_title}>{t("password.recovery")}</Typography>
      </Grid>
      <Divider sx={styles.title_divider} />
      <Paper sx={styles.content_container}>
        <Grid container justifyContent="center">
          <Grid item xs={10} sm={8} md={6} lg={4}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Typography>{t("password.recovery.message")}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t("profile.pass.reset.field")}
                  type="password"
                  value={pass}
                  onChange={e => setPass(e.target.value)}
                  InputProps={{
                    endAdornment: <InvalidPasswordTooltip currentPassword={pass} bannedWords={getBannedWordsForPassword()} />
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t("profile.pass.reset.field.repeat")}
                  type="password"
                  value={confirmPass}
                  onChange={e => setConfirmPass(e.target.value)}
                />
              </Grid>
              <Grid item xs={10} sm={6} display="flex" justifyContent="center">
                <Button color="secondary" variant="outlined" size="large" onClick={handleRecover}>
                  {t("password.recovery.save")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default RecoverPasswordView;
