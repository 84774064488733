import { useEffect, useState } from "react";
import { Theme, useMediaQuery } from "@mui/material";

export const useHandleCardQueries = () => {
  const [isCardDisplay, setIsCardDisplay] = useState(false);
  const matchesMD = useMediaQuery((theme: Theme) => theme.breakpoints.up(900), { noSsr: true });

  useEffect(() => {
    setIsCardDisplay(!matchesMD);
  }, [matchesMD]);

  return {
    isCardDisplay,
    setIsCardDisplay,
    matchesMD
  };
};
