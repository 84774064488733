import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import { GridColDef } from "@mui/x-data-grid";
import { filterValueTypes } from "@constants";
import Stakeholder from "@domain/model/Stakeholder";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { CustomTooltipCell } from "@components/tooltip/CustomTooltipCell";
import { CheckUserRoleUseCase } from "@application/permissions/check-user-role-use-case";
import { TitlePopover } from "@components/master-data-view/card/components/title/title-popover";
import { StakeholderDTOPaymentCurrencyEnum, StakeholderDTOPaymentTermsEnum } from "@clients/aggrego-proxy";
import { UserRoleEnum } from "@domain/interface/User";

export const stakeholdersColumns = (
  t: (text: string, object?: object) => string,
  handleStakeholderOpen: (stakeholder: Stakeholder) => Promise<void>,
  mdMediaQuery: boolean,
  handleEdit: (id: string) => void,
  handleActive: (stakeholder: Stakeholder) => void,
  stakeholderType: string,
  checkUserRoleUseCase: CheckUserRoleUseCase
): GridColDef[] => {
  return [
    {
      flex: 0.45,
      field: "code",
      headerName: t("stakeholders.code"),
      disableColumnMenu: true,
      minWidth: mdMediaQuery ? undefined : 100,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.47,
      field: "fiscalId",
      headerName: t("stakeholders.fiscalId"),
      disableColumnMenu: true,
      minWidth: mdMediaQuery ? undefined : 100,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1,
      field: "name",
      headerName: t("stakeholders.name"),
      disableColumnMenu: true,
      minWidth: mdMediaQuery ? undefined : 60,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.65,
      field: "address",
      headerName: t("stakeholders.address"),
      disableColumnMenu: true,
      minWidth: mdMediaQuery ? undefined : 80,
      type: "string",
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0,
      field: "city",
      headerName: t("stakeholders.city"),
      disableColumnMenu: true,
      minWidth: 0,
      maxWidth: 0,
      type: "string",
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0,
      field: "country",
      headerName: t("stakeholders.country"),
      disableColumnMenu: true,
      minWidth: 0,
      maxWidth: 0,
      type: "select-one" as filterValueTypes,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.65,
      field: "phone",
      headerName: t("stakeholders.phone"),
      disableColumnMenu: true,
      minWidth: mdMediaQuery ? undefined : 150,
      type: "string",
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.7,
      field: "email",
      headerName: t("stakeholders.email"),
      disableColumnMenu: true,
      minWidth: mdMediaQuery ? undefined : 70,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.45,
      field: "paymentTerms",
      headerName: t("stakeholders.paymentTerms"),
      disableColumnMenu: true,
      sortable: false,
      valueGetter: ({ row }) => {
        switch (row.paymentTerms) {
          case StakeholderDTOPaymentTermsEnum.Net30:
            return t("payment.method.30");
          case StakeholderDTOPaymentTermsEnum.Net60:
            return t("payment.method.60");
          case StakeholderDTOPaymentTermsEnum.Net90:
            return t("payment.method.90");
          case StakeholderDTOPaymentTermsEnum.Cash:
            return t("payment.method.cash");
          default:
            return t("payment.method.cash");
        }
      },
      minWidth: 165,
      type: "select-one" as filterValueTypes,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.45,
      field: "paymentCurrency",
      headerName: t("stakeholders.paymentCurrency"),
      disableColumnMenu: true,
      sortable: false,
      minWidth: mdMediaQuery ? undefined : 40,
      valueGetter: ({ row }) => {
        if (row.paymentCurrency === StakeholderDTOPaymentCurrencyEnum.Dollar) {
          return t("currency.dollar");
        } else if (row.paymentCurrency === StakeholderDTOPaymentCurrencyEnum.Euro) {
          return t("currency.euro");
        } else {
          return t("currency.pound");
        }
      },
      type: "select-one" as filterValueTypes,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.45,
      field: "contactPerson",
      headerName: t("stakeholders.contact"),
      disableColumnMenu: true,
      sortable: false,
      minWidth: mdMediaQuery ? undefined : 40,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.7,
      field: "actions",
      headerName: t("stakeholders.actions"),
      disableColumnMenu: true,
      sortable: false,
      minWidth: mdMediaQuery ? 100 : 40,
      renderCell: data => (
        <Grid container justifyContent="flex-start">
          <Tooltip title={t("tooltip.access.detail", { mdNameOfThe: t(`of.the.${stakeholderType}`) })}>
            <IconButton id="info-button" onClick={() => handleStakeholderOpen(data.row)}>
              <Icon icon="search" color={COLORS.secondary} />
            </IconButton>
          </Tooltip>
          {checkUserRoleUseCase.isRole(UserRoleEnum.PortalAdmin) || checkUserRoleUseCase.isRole(UserRoleEnum.SuperAdmin) ? (
            <TitlePopover
              key="info-popover-card"
              handleEdit={() => handleEdit(data.row.id)}
              handleActive={() => handleActive(data.row)}
              activateTitle={data.row.active ? t("root.deactivate") : t("root.activate")}
            />
          ) : (
            <></>
          )}
        </Grid>
      )
    }
  ];
};
