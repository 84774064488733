import { Tooltip } from "@mui/material";
import { CSSProperties, ReactNode } from "react";

interface CustomTooltipCellProps {
  children: ReactNode;
  tooltipTitle?: string;
  customContainerStyles?: CSSProperties;
}
/**
 *
 * @param children: ReactNode - The children that will be contained in the Tooltip tag
 * @param tooltipTitle: string - (optional) The title of the Tooltip
 * @param customContainerStyles: CSSProperties - A custom set of CSS properties for the container
 * @returns
 */
export const CustomTooltipCell = ({ children, tooltipTitle, customContainerStyles }: CustomTooltipCellProps) => {
  return (
    <Tooltip title={tooltipTitle ? tooltipTitle : children} placement="bottom">
      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: "0.75rem",
          ...customContainerStyles
        }}>
        {children}
      </div>
    </Tooltip>
  );
};
