import { SetStateAction, useEffect, useState } from "react";
import { useSnackbar } from "@movicoders/ui";
import { TUserRoleEnum, UserRoleEnum } from "@domain/interface/User";
import {
  TMasterDataEnum,
  TReportEnum,
  TLogisticEnum,
  TTrackAndTraceEnum,
  MasterDataEnum
} from "@pages/settings/settings-interfaces";
import { ChangeSettingsUseCase } from "@application/settings/change-settings-use-case";
import { useResolve } from "@movicoders/di";
import { useTranslation } from "react-i18next";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { IUserService, UserService } from "@domain/services/IUserService";

export const useSettingsListViewModel = () => {
  const { t } = useTranslation();
  const { show } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorRecoveringPermission, setErrorRecoveringPermission] = useState(false);
  const [selectedRole, setSelectedRole] = useState<TUserRoleEnum>(UserRoleEnum.PortalUser);
  const [accessWarehouseViewer, setAccessWarehouseViewer] = useState<boolean>(false);
  const [accessMasterData, setAccessMasterData] = useState<TMasterDataEnum[]>([]);
  const [accessReports, setAccessReports] = useState<TReportEnum[]>([]);
  const [accessLogistic, setAccessLogistic] = useState<TLogisticEnum[]>([]);
  const [accessTrackAndTrace, setAccessTrackAndTrace] = useState<TTrackAndTraceEnum[]>([]);

  const changeSettingsUseCase = useResolve(ChangeSettingsUseCase);
  const settingsService = useResolve<ISettingsService>(SettingsService);
  const userService = useResolve<IUserService>(UserService);

  useEffect(() => {
    setLoading(true);
    setErrorRecoveringPermission(false);
    setSelectedRole(UserRoleEnum.PortalUser);
    changeSettingsUseCase
      .recoverFromRole(UserRoleEnum.PortalUser)
      .then(response => {
        setAccessMasterData(response.masterdataPermissions as unknown as SetStateAction<TMasterDataEnum[]>);
        setAccessReports(response.reportsPermissions as unknown as SetStateAction<TReportEnum[]>);
        setAccessLogistic(response.logisticOperationsPermissions as unknown as SetStateAction<TLogisticEnum[]>);
        setAccessTrackAndTrace(response.trackAndTracePermissions as unknown as SetStateAction<TTrackAndTraceEnum[]>);
      })
      .catch(() => {
        setAccessMasterData([]);
        setAccessReports([]);
        setAccessLogistic([]);
        setAccessTrackAndTrace([]);
        setErrorRecoveringPermission(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    accessMasterData.includes(MasterDataEnum.WarehouseViewer) ? setAccessWarehouseViewer(true) : setAccessWarehouseViewer(false);
  }, [accessMasterData]);

  const recoverPermissionByRole = (role: "MOB_USER" | "MOB_ADMIN" | "PORTAL_USER" | "PORTAL_ADMIN" | "SUPER_ADMIN" | null) => {
    setLoading(true);
    setErrorRecoveringPermission(false);
    setSelectedRole(role ?? UserRoleEnum.PortalUser);
    changeSettingsUseCase
      .recoverFromRole(role)
      .then(response => {
        setAccessMasterData(response.masterdataPermissions as unknown as SetStateAction<TMasterDataEnum[]>);
        setAccessReports(response.reportsPermissions as unknown as SetStateAction<TReportEnum[]>);
        setAccessLogistic(response.logisticOperationsPermissions as unknown as SetStateAction<TLogisticEnum[]>);
        setAccessTrackAndTrace(response.trackAndTracePermissions as unknown as SetStateAction<TTrackAndTraceEnum[]>);
      })
      .catch(() => {
        setAccessMasterData([]);
        setAccessReports([]);
        setAccessLogistic([]);
        setAccessTrackAndTrace([]);
        setErrorRecoveringPermission(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const savePermissionByRole = () => {
    setLoading(true);
    changeSettingsUseCase
      .execute({
        selectedRole,
        accessWarehouseViewer,
        accessMasterData,
        accessReports,
        accessLogistic,
        accessTrackAndTrace
      })
      .then(() => {
        if (userService.user?.roles.includes(selectedRole)) {
          settingsService.saveSettigsState({
            masterdataPermissions: accessMasterData,
            reportsPermissions: accessReports,
            logisticOperationsPermissions: accessLogistic,
            trackAndTracePermissions: accessTrackAndTrace
          });
        }
        show(t("permissions.update.correct"), "success");
      })
      .catch(() => {
        show(t("permissions.update.failed"), "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    selectedRole,
    accessMasterData,
    setAccessMasterData,
    accessReports,
    setAccessReports,
    accessLogistic,
    setAccessLogistic,
    accessTrackAndTrace,
    setAccessTrackAndTrace,
    loading,
    recoverPermissionByRole,
    errorRecoveringPermission,
    savePermissionByRole
  };
};
