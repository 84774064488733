// Generates various grid helpers so drawing non squares is easier.
export const generateGridHelpers = (whX: number, whY: number) => {
  const helperList = [];
  const whXNum = Number(whX);
  const whYNum = Number(whY);
  const helperSize = whXNum < whYNum ? whXNum : whYNum;

  const firstHelper = { size: helperSize, x: helperSize / 2 + 0.5, z: helperSize / 2 + 0.5 };
  helperList.push(firstHelper);

  if (whYNum < whXNum) {
    const totalGrids = Math.ceil(whXNum / whYNum);
    for (let i = 1; i < totalGrids; i++) {
      const newHelper = { size: helperSize, x: helperSize / 2 + (whXNum / i - helperSize) * i + 0.5, z: helperSize / 2 + 0.5 };
      helperList.push(newHelper);
    }
  } else if (whYNum > whXNum) {
    const totalGrids = Math.ceil(whYNum / whXNum);
    for (let i = 1; i < totalGrids; i++) {
      const newHelper = { size: helperSize, x: helperSize / 2 + 0.5, z: helperSize / 2 + (whYNum / i - helperSize) * i + 0.5 };
      helperList.push(newHelper);
    }
  }

  return helperList;
};
