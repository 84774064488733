import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";
import { ButtonsDetail } from "@components/master-data-view/buttons/buttons-detail";

export const LogisticDeleteDialog = (props: { open: boolean; onClose: () => void; handleDelete: () => void }) => {
  const { t } = useTranslation();
  const { open, onClose, handleDelete } = props;

  return open ? (
    <AggregoDialog title={t("logistic.delete.inbound")} onClose={onClose}>
      <Grid container flexDirection="column" className="masterdata-box" rowSpacing={2}>
        <Grid item>
          <div className="confirm-dialog-div-content">{t("logistic.form.delete")}</div>
        </Grid>
        <Grid item>
          <ButtonsDetail handleClose={onClose} handleSave={handleDelete} mode={"DELETE"} />
        </Grid>
      </Grid>
    </AggregoDialog>
  ) : (
    <></>
  );
};
