import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import { ROUTER_PATHS } from "@constants";
import { useResolve } from "@movicoders/di";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Warehouse from "@domain/model/Warehouse";
import { DrawerState } from "@domain/model/DrawerState";
import { warehouseCardStyles } from "./warehouseCardStyles";
import { Card } from "@components/master-data-view/card/card";
import { Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { DrawerService, IDrawerService } from "@domain/services/IDrawerService";
import { WarehouseCircularProgress } from "./circular-progress/circular-progress";

export const WarehouseCard = (props: {
  data: Warehouse;
  handleEdit: (id: string) => void;
  handleActive: (item: Warehouse) => void;
}): JSX.Element => {
  const { id, name, code, capacity, address, sizeX, sizeZ, active, maxSizeY } = props.data as Warehouse;
  const { handleEdit, handleActive } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const service = useResolve<IDrawerService>(DrawerService);
  const persistedState = service.persistedState ?? new DrawerState();
  const IS_NOT_SELECTED = props.data.id !== persistedState.selectedWarehouse;
  const styles = warehouseCardStyles;

  const renderChild = (name: string, value: string | number, className?: string) => {
    return (
      <Grid item>
        <div className={className}>
          <div className="title-content">
            <Typography variant="cardlabel" textAlign="left">
              {t(name)}
            </Typography>
            <Typography variant="cardvalue" data-cy={`${name.split(".")[1]}-card-value`} noWrap={false}>
              {value}
            </Typography>
          </div>
        </div>
      </Grid>
    );
  };

  return (
    <Card
      isActive={active ?? false}
      popover={{
        handleEdit: () => handleEdit(id ?? ""),
        handleActive: () => handleActive(props.data),
        activateTitle: active ? t("root.deactivate") : t("root.activate")
      }}
      id={"card-" + id}
      nameValue={name ?? ""}>
      <Grid container direction="column" px="1rem" id="wh-card-content">
        <Grid item display="flex" flexWrap="nowrap">
          <Grid container direction="column" rowSpacing={1}>
            {renderChild("warehouse.code", code ?? "", "code-value-card-container")}
            {renderChild("warehouse.capacity", capacity ?? 0, "capacity-value-card-container")}
            {renderChild("warehouse.size", sizeX + "x" + sizeZ, "maxX-value-card-container")}
            {renderChild("warehouse.size.max.y", maxSizeY ?? 0, "maxY-value-card-container")}
          </Grid>
          <WarehouseCircularProgress data={props.data} />
        </Grid>
        <Grid item py="1rem">
          {renderChild("warehouse.location", address ?? "", "address-value-card-container")}
        </Grid>
      </Grid>
      {props.data.active && (
        <Grid container px="1rem" justifyContent="space-between" id="wh-bottom-options">
          <Grid item pb="0.6rem">
            <Button
              variant="outlined"
              size="small"
              color="inherit"
              sx={{ color: IS_NOT_SELECTED ? COLORS.buttonGrayText : COLORS.secondary, ...styles.warehouse_select_button }}
              onClick={() => {
                service.saveDrawerState({ ...persistedState, selectedWarehouse: props.data.id ?? "" });
              }}
              startIcon={IS_NOT_SELECTED ? <Icon icon="task-off" /> : <Icon color={COLORS.success} icon="task-ok" />}>
              {IS_NOT_SELECTED ? t("warehouse.select") : t("warehouse.selected")}
            </Button>
          </Grid>
          <Grid item display="flex" alignItems="flex-start">
            <Tooltip title={t("tooltip.access.warehouse.viewer")}>
              <IconButton onClick={() => navigate(`${ROUTER_PATHS.warehouseViewer}`)} sx={{ mr: "1rem" }}>
                <Icon icon="3d" color={COLORS.secondary} />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("tooltip.access.dashboard")}>
              <IconButton onClick={() => navigate(`/${ROUTER_PATHS.home}/${props.data.id}`)}>
                <Icon icon="warehouse" color={COLORS.secondary} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )}
    </Card>
  );
};
