import { COLORS } from "@theme";
import Tile from "@domain/model/Tile";
import Zone from "@domain/model/Zone";
import Icon from "@components/icons/icon";
import { GridColDef } from "@mui/x-data-grid";
import { IconButton, Tooltip } from "@mui/material";
import LocationEntity from "@domain/model/Location";
import { NavigateFunction } from "react-router-dom";
import { ROUTER_PATHS, filterValueTypes } from "@constants";
import { CustomTooltipCell } from "@components/tooltip/CustomTooltipCell";
import { TitlePopover } from "@components/master-data-view/card/components/title/title-popover";

export const locationsColumns = (
  t: (text: string) => string,
  tiles: Tile[],
  zones: Zone[],
  mdMediaQuery: boolean,
  handleEdit?: (id: string) => void,
  handleActive?: (location: LocationEntity) => void,
  navigate?: NavigateFunction
): GridColDef[] => {
  return [
    {
      field: "id",
      disableColumnMenu: true,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1.5,
      field: "code",
      headerName: t("locations.code"),
      disableColumnMenu: true,
      minWidth: mdMediaQuery ? undefined : 100,
      type: "string",
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1,
      field: "capacity",
      headerName: t("locations.capacity"),
      disableColumnMenu: true,
      type: "number",
      minWidth: mdMediaQuery ? undefined : 50,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1.2,
      field: "positionX",
      headerName: t("locations.coord.x"),
      disableColumnMenu: true,
      minWidth: mdMediaQuery ? undefined : 40,
      type: "number",
      valueGetter: ({ row }) => {
        return tiles.find(t => t.id === row.tileId)?.x ?? "-";
      },
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1.2,
      field: "positionZ",
      headerName: t("locations.coord.z"),
      disableColumnMenu: true,
      minWidth: mdMediaQuery ? undefined : 40,
      type: "number",
      valueGetter: ({ row }) => {
        return tiles?.find(t => t.id === row.tileId)?.z ?? "-";
      },
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.6,
      field: "level",
      headerName: t("locations.level"),
      disableColumnMenu: true,
      type: "number",
      minWidth: mdMediaQuery ? undefined : 40,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1,
      field: "zone",
      headerName: t("locations.zone"),
      disableColumnMenu: true,
      minWidth: mdMediaQuery ? undefined : 100,
      type: "select-one" as filterValueTypes,
      valueGetter: ({ row }) => {
        const locationTile = tiles?.find(t => t.id === row.tileId) ?? {};
        const tileZone = zones?.find(z => locationTile?.zoneId !== undefined && z.id?.includes(locationTile.zoneId));
        return tileZone?.name ?? t("locations.zone.unassigned");
      },
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1,
      field: "status",
      headerName: t("locations.active"),
      disableColumnMenu: true,
      type: "string",
      minWidth: mdMediaQuery ? undefined : 70,
      valueGetter: ({ row }) => {
        return row.active ? t("locations.is.active") : t("locations.is.not.active");
      },
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1,
      field: "actions",
      headerName: t("locations.actions"),
      disableColumnMenu: true,
      sortable: false,
      align: "right",
      headerAlign: "right",
      minWidth: mdMediaQuery ? undefined : 80,
      renderCell: data => (
        <>
          <Tooltip title={t("tooltip.access.warehouse.viewer")}>
            <IconButton onClick={() => navigate?.(ROUTER_PATHS.warehouseViewer + "/" + data.row.tileId)}>
              <Icon icon="3d" color={COLORS.primary} />
            </IconButton>
          </Tooltip>

          <TitlePopover
            handleEdit={() => handleEdit && handleEdit(data.row.id)}
            handleActive={() => handleActive && handleActive(data.row)}
            activateTitle={data.row.active ? t("root.deactivate") : t("root.activate")}
          />
        </>
      )
    }
  ];
};
