export interface HierarchyPair{
  uuid: string,
  name: string
}

export class HierarchyState {

  currentParentId?: string;
  currentHierarchy?: Array<HierarchyPair>;

  constructor(currentParentId: string, currentHierarchy: Array<HierarchyPair>) {
    this.currentParentId = currentParentId;
    this.currentHierarchy = currentHierarchy;
  }
}
