/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RelabelFilterDTO
 */
export interface RelabelFilterDTO {
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof RelabelFilterDTO
     */
    caseInsensitive?: { [key: string]: boolean; };
    /**
     * 
     * @type {Date}
     * @memberof RelabelFilterDTO
     */
    endDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RelabelFilterDTO
     */
    initDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof RelabelFilterDTO
     */
    newContainerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RelabelFilterDTO
     */
    oldContainerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RelabelFilterDTO
     */
    operatorCode?: string;
    /**
     * 
     * @type {number}
     * @memberof RelabelFilterDTO
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof RelabelFilterDTO
     */
    searchText?: string;
    /**
     * 
     * @type {string}
     * @memberof RelabelFilterDTO
     */
    uom?: string;
    /**
     * 
     * @type {string}
     * @memberof RelabelFilterDTO
     */
    user?: string;
    /**
     * 
     * @type {string}
     * @memberof RelabelFilterDTO
     */
    warehouseCode?: string;
}

/**
 * Check if a given object implements the RelabelFilterDTO interface.
 */
export function instanceOfRelabelFilterDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RelabelFilterDTOFromJSON(json: any): RelabelFilterDTO {
    return RelabelFilterDTOFromJSONTyped(json, false);
}

export function RelabelFilterDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelabelFilterDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'caseInsensitive': !exists(json, 'caseInsensitive') ? undefined : json['caseInsensitive'],
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'initDate': !exists(json, 'initDate') ? undefined : (new Date(json['initDate'])),
        'newContainerCode': !exists(json, 'newContainerCode') ? undefined : json['newContainerCode'],
        'oldContainerCode': !exists(json, 'oldContainerCode') ? undefined : json['oldContainerCode'],
        'operatorCode': !exists(json, 'operatorCode') ? undefined : json['operatorCode'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'searchText': !exists(json, 'searchText') ? undefined : json['searchText'],
        'uom': !exists(json, 'uom') ? undefined : json['uom'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'warehouseCode': !exists(json, 'warehouseCode') ? undefined : json['warehouseCode'],
    };
}

export function RelabelFilterDTOToJSON(value?: RelabelFilterDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'caseInsensitive': value.caseInsensitive,
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'initDate': value.initDate === undefined ? undefined : (value.initDate.toISOString()),
        'newContainerCode': value.newContainerCode,
        'oldContainerCode': value.oldContainerCode,
        'operatorCode': value.operatorCode,
        'quantity': value.quantity,
        'searchText': value.searchText,
        'uom': value.uom,
        'user': value.user,
        'warehouseCode': value.warehouseCode,
    };
}

