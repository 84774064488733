import { ProviderDTOPaymentCurrencyEnum, ProviderDTOPaymentTermsEnum, StakeholderDTO } from "@clients/aggrego-proxy";

export default class Stakeholder {
  active: boolean;
  code: string;
  address?: string;
  city?: string;
  comment?: string;
  contactPerson?: string;
  country?: string;
  email?: string;
  fiscalId?: string;
  id?: string;
  name?: string;
  paymentCurrency?: ProviderDTOPaymentCurrencyEnum;
  paymentTerms?: ProviderDTOPaymentTermsEnum;
  phone?: string;
  postalCode?: string;
  isOwner?: boolean;
  isSupplier?: boolean;
  isManufacturer?: boolean;
  isCustomer?: boolean;

  constructor(
    active: boolean,
    code: string,
    address?: string,
    city?: string,
    comment?: string,
    contactPerson?: string,
    country?: string,
    email?: string,
    fiscalId?: string,
    id?: string,
    name?: string,
    paymentCurrency?: ProviderDTOPaymentCurrencyEnum,
    paymentTerms?: ProviderDTOPaymentTermsEnum,
    phone?: string,
    postalCode?: string,
    isOwner?: boolean,
    isSupplier?: boolean,
    isManufacturer?: boolean,
    isCustomer?: boolean
  ) {
    this.active = active;
    this.code = code;
    this.address = address;
    this.city = city;
    this.comment = comment;
    this.contactPerson = contactPerson;
    this.country = country;
    this.email = email;
    this.fiscalId = fiscalId;
    this.id = id;
    this.name = name;
    this.paymentCurrency = paymentCurrency;
    this.paymentTerms = paymentTerms;
    this.phone = phone;
    this.postalCode = postalCode;
    this.isOwner = isOwner;
    this.isSupplier = isSupplier;
    this.isManufacturer = isManufacturer;
    this.isCustomer = isCustomer;
  }

  static fromClientStakeholder(stakeholder: StakeholderDTO) {
    const result = new Stakeholder(
      stakeholder.active ?? true,
      stakeholder.code ?? "",
      stakeholder.address ?? "",
      stakeholder.city ?? "",
      stakeholder.comment ?? "",
      stakeholder.contactPerson ?? "",
      stakeholder.country ?? "",
      stakeholder.email ?? "",
      stakeholder.fiscalId ?? "",
      stakeholder.id ?? "",
      stakeholder.name ?? "",
      stakeholder.paymentCurrency ?? ProviderDTOPaymentCurrencyEnum.Dollar,
      stakeholder.paymentTerms ?? ProviderDTOPaymentTermsEnum.Net30,
      stakeholder.phone ?? "",
      stakeholder.postalCode ?? "",
      stakeholder.isOwner ?? false,
      stakeholder.isSupplier ?? false,
      stakeholder.isManufacturer ?? false,
      stakeholder.isCustomer ?? false
    );
    return result;
  }

  static toClientStakeholder(stakeholder: Stakeholder) {
    const result: StakeholderDTO = {};
    result.active = stakeholder.active;
    result.code = stakeholder.code;
    result.address = stakeholder.address;
    result.city = stakeholder.city;
    result.comment = stakeholder.comment;
    result.contactPerson = stakeholder.contactPerson;
    result.country = stakeholder.country;
    result.email = stakeholder.email;
    result.fiscalId = stakeholder.fiscalId;
    result.id = stakeholder.id;
    result.name = stakeholder.name;
    result.paymentCurrency = stakeholder.paymentCurrency;
    result.paymentTerms = stakeholder.paymentTerms;
    result.phone = stakeholder.phone;
    result.postalCode = stakeholder.postalCode;
    result.isOwner = stakeholder.isOwner;
    result.isSupplier = stakeholder.isSupplier;
    result.isManufacturer = stakeholder.isManufacturer;
    result.isCustomer = stakeholder.isCustomer;
    return result;
  }
}
