import { COLORS } from "@theme";
import { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import Stakeholder from "@domain/model/Stakeholder";
import { stakeholderTypesEnum } from "../hooks/useStakeholderSelection";
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Typography } from "@mui/material";

interface IDetail {
  stakeholder: Stakeholder;
  newStakeholdersListHandler: (type: stakeholderTypesEnum) => void;
  onChangeCheckboxes: (event: SyntheticEvent<Element, Event>, type: stakeholderTypesEnum) => void;
  formErrors: Map<string, boolean>;
}

export const StakeholderTypeCheckboxes = (props: IDetail) => {
  const { stakeholder, newStakeholdersListHandler, onChangeCheckboxes, formErrors } = props;
  const { t } = useTranslation();

  const disabled = stakeholder.active !== undefined && stakeholder.active === false;
  const statusTitleColor = disabled ? COLORS.gray : COLORS.secondary;

  const checkboxSelector = (type: stakeholderTypesEnum) => {
    if (type === stakeholderTypesEnum.customer && stakeholder.isCustomer) {
      return true;
    } else if (type === stakeholderTypesEnum.manuafacturers && stakeholder.isManufacturer) {
      return true;
    } else if (type === stakeholderTypesEnum.owners && stakeholder.isOwner) {
      return true;
    } else if (type === stakeholderTypesEnum.suppliers && stakeholder.isSupplier) {
      return true;
    } else return false;
  };

  return (
    <Grid container>
      <Grid item sx={{ mt: "2rem", ml: "1rem" }}>
        <FormGroup id="user-form-group">
          <FormControl>
            <Typography
              sx={{ color: (formErrors.get("stakeholder-stakeholders") as boolean) ? COLORS.alert : statusTitleColor }}
              variant="cardlabel_secondary">
              {t("stakeholders.assigned")}
            </Typography>
            {(formErrors.get("stakeholder-stakeholders") as boolean) && (
              <Typography sx={{ color: COLORS.alert }} variant="cardlabel_secondary">
                {t("stakeholders.stakeholders.empty")}
              </Typography>
            )}
            {Object.values(stakeholderTypesEnum).map((type, index) => (
              <FormControlLabel
                key={`checkbox-${type}`}
                name={`checkbox-${type}`}
                control={
                  <Checkbox
                    id={"stakeholder-type-" + type}
                    onClick={() => newStakeholdersListHandler(type)}
                    checked={checkboxSelector(type)}
                    disabled={disabled}
                  />
                }
                label={t(`${type}.loupe`)}
                onChange={(event: SyntheticEvent<Element, Event>) => {
                  onChangeCheckboxes(event, type);
                }}
              />
            ))}
          </FormControl>
        </FormGroup>
      </Grid>
    </Grid>
  );
};
