import { COLORS } from "@theme";
import { Theme } from "@mui/system";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OverridableStringUnion } from "@mui/types";
import { Button, ButtonPropsVariantOverrides, Grid, useMediaQuery } from "@mui/material";

export interface IMode {
  id: string;
  variant: string;
  text: string;
}

export type modeType = "DELETE" | "EDIT" | "ACTIVE" | "INACTIVE" | "CREATE" | "CONFIRM";

export interface IButtons<T> {
  handleSave: () => T;
  handleClose: () => void;
  mode: modeType;
  disabled?: boolean;
  pendingRequest?: boolean;
}

export const ButtonsDetail = function <T>(props: IButtons<T>) {
  const { t } = useTranslation();
  const { handleClose, handleSave, mode, disabled, pendingRequest } = props;
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up(350), { noSsr: true });

  const createMode: IMode = { id: "dialog-create-button", variant: "primary", text: "button.create" };
  const editMode: IMode = { id: "dialog-edit-button", variant: "primary", text: "button.save" };
  const deleteMode: IMode = { id: "dialog-delete-button", variant: "alert", text: "button.delete" };
  const activeMode: IMode = { id: "dialog-active-button", variant: "alert", text: "button.deactivate" };
  const inactiveMode: IMode = { id: "dialog-inactive-button", variant: "primary", text: "button.activate" };
  const confirmMode: IMode = { id: "dialog-cornfirm-button", variant: "primary", text: "button.confirm" };

  const [modeConfig, setModeConfig] = useState<IMode>({ id: "dialog-create-button", variant: "primary", text: "root.create" });

  const checkMode = () => {
    if (mode === "DELETE") {
      setModeConfig(deleteMode);
    } else if (mode === "EDIT") {
      setModeConfig(editMode);
    } else if (mode === "ACTIVE") {
      setModeConfig(activeMode);
    } else if (mode === "INACTIVE") {
      setModeConfig(inactiveMode);
    } else if (mode === "CONFIRM") {
      setModeConfig(confirmMode);
    } else {
      setModeConfig(createMode);
    }
  };

  useEffect(() => {
    checkMode();
  }, [mode]);

  return (
    <Grid container marginTop="1rem" flexGrow={1} justifyContent="space-between" direction={matches ? "row" : "column"}>
      <Button id="dialog-cancel-button" variant="secondary" onClick={handleClose}>
        {t("button.cancel")}
      </Button>
      <Button
        id={modeConfig.id}
        variant={modeConfig.variant as OverridableStringUnion<"text" | "outlined" | "contained", ButtonPropsVariantOverrides>}
        onClick={handleSave}
        autoFocus
        disabled={disabled === true ? disabled : pendingRequest ?? false}
        sx={{
          fontSize: matches && mode === "DELETE" ? "0.9rem" : "0.8rem",
          lineHeight: 1.2,
          backgroundColor: disabled || pendingRequest ? COLORS.lightgray : ""
        }}>
        {t(modeConfig.text)}
      </Button>
    </Grid>
  );
};
