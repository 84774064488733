import { t } from "i18next";
import { DomainPage } from "@movicoders/domain";
import { GridSortModel } from "@mui/x-data-grid";
import { Theme, useMediaQuery } from "@mui/material";
import { UUIDGenerator } from "@utils/uuid-generator";
import Company from "@domain/model/CompanyAdminBoard";
import { adminBoardColumns } from "../components/admin-board-columns";
import { CompanyDTO, FullCompanyItemDTO } from "@clients/aggrego-proxy";
import { DataGridDisplay } from "@components/data-grid-display/data-grid-display";
import { CompanyCreateDTO } from "../components/stepper-company/company-create-model";

interface IAdminBoardTable {
  dataPaginated: DomainPage<CompanyDTO | Company | CompanyCreateDTO | FullCompanyItemDTO>;
  handleEdit: (id: string) => void;
  handlePagination: (
    limit: number,
    offset: number,
    status?: "ACTIVE" | "INACTIVE" | "ALL" | undefined,
    filters?: object | undefined
  ) => void;
  sortByFieldBackend: (model: GridSortModel) => void;
}

const AdminBoardViewTable = (props: IAdminBoardTable) => {
  const { dataPaginated, handleEdit, handlePagination, sortByFieldBackend } = props;
  const matchesMD = useMediaQuery((theme: Theme) => theme.breakpoints.up(900), { noSsr: true });

  return (
    <DataGridDisplay
      data={
        dataPaginated?.content?.map((item: CompanyDTO) => {
          return { ...item, id: UUIDGenerator() };
        }) ?? []
      }
      columns={adminBoardColumns(t, handleEdit, matchesMD)}
      mode="server"
      viewName={t("admin.board.title")}
      hideColumnsByField={["id"]}
      handleBackendPagination={handlePagination}
      handleBackendSort={sortByFieldBackend}
      backendTotalElements={dataPaginated.totalElements ?? 0}
      rowId={UUIDGenerator}
      toolbarProps={{
        disableColumnsVisibilityByField: ["id"],
        disableFilterByFields: ["id", "warehousePolicy", "license", "actions", "status"],
        disableFieldsInDownload: ["id", "actions"],
        parseCSVInDownload: data => {
          data.active = data.active ? t("admin.board.active") : t("admin.board.inactive");
          return data;
        }
      }}
    />
  );
};

export default AdminBoardViewTable;
