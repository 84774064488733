import { useState } from "react";
import Family from "@domain/model/Family";
import { useResolve } from "@movicoders/di";
import { LastPageState } from "@domain/model/LastPageState";
import { ILastPageService } from "@domain/services/ILastPageService";
import { ListFamiliesPaginatedRequest } from "@clients/aggrego-proxy";
import { useMasterDataMessagges } from "@hooks/useMasterDataMessages";
import { FamilyRepository } from "@infrastructure/repositories/family-repository";

export const useFamilyActiveStatus = (
  currentPageService: ILastPageService,
  currentPagePersistedState: LastPageState,
  getFamiliesPaginated: (criteria: ListFamiliesPaginatedRequest) => Promise<void>,
  getCurrentStatus: (status: "ALL" | "ACTIVE" | "INACTIVE") => boolean | undefined
) => {
  const familyRepository = useResolve(FamilyRepository);
  const [isActivating, setIsActivating] = useState(false);
  const [selectedFamily4Status, setSelectedFamily4Status] = useState<Family | undefined>(undefined);
  const { successMessaggesStatus, errorMessaggesStatus } = useMasterDataMessagges();

  const onCloseDialog = () => {
    setIsActivating(false);
  };

  const handleOpenToggleActiveStatusDialog = (family: Family) => {
    setIsActivating(!isActivating);
    setSelectedFamily4Status(family);
  };

  /**
   * Toggles the active status of the family to its opposite value.
   * @param family The family whose value is going to reversed.
   */
  const toggleFamilyStatus = (family: Family) => {
    family.active = !family.active;
    const promise = familyRepository.update(family);

    promise
      .then(() => successMessaggesStatus("family", family.active, family.name))
      .catch(error => errorMessaggesStatus("family", family.active, family.name, error))
      .finally(() => {
        onCloseDialog();
        getFamiliesPaginated({
          limit: currentPageService.persistedState?.limit ?? 12,
          offset: currentPageService.persistedState?.offset ?? 0,
          customFamilyFilter: { ...currentPagePersistedState.filters, status: getCurrentStatus(currentPagePersistedState.status) }
        });
      });
  };

  return {
    isActivating,
    selectedFamily4Status,
    handleOpenToggleActiveStatusDialog,
    toggleFamilyStatus,
    onCloseDialog
  };
};
