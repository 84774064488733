import { Paper, RadioGroup, FormControlLabel, Radio, Grid, Box } from "@mui/material";
import { InfoDialog } from "../info-dialog";
import { useModeSelectionMenuViewmodel } from "./useModeSelectionMenuViewmodel";
import { MODES, MODES_VALUES } from "../../../constants/Modes";
import { TemplateSelectionDialog } from "../template-selection-dialog";
import { SearchByCodeMenu } from "../search-by-code/search-by-code-menu";
import { ZoneSelector3D } from "./zone-selection/zone-selector-3d";
import { ZoneEditionTools } from "./zone-edition-tools/zone-edition-tools";
import { SelectionTypeButtons } from "./selection-type-buttons/selection-type-buttons";
import { EndModifyingButtons } from "./end-modifying-buttons/end-modifying-buttons";

export const ModeSelection = () => {

  const {
    getSelectedMode,
    getModifyingWH,
    handleModeChange,
    getCanUseByMode
  } = useModeSelectionMenuViewmodel();

  const MODES_WITHOUT_MULTIPLE_SELECTION: MODES[] = ["VIEW", "CREATE", "ZONES"];

  return (
    <Grid sx={{
      width: "10rem",
      display: "grid",
      ml: "5rem",
      mt: "1rem",
      zIndex: 1
    }}>
      <Paper
        sx={{ p: "1rem", maxWidth: "10rem" }}>
        <Grid container flexDirection="row" flexWrap="nowrap" alignItems="flex-start">
          <RadioGroup
            value={getSelectedMode()}
            onChange={handleModeChange}>
            {MODES_VALUES.map((mode) => (
              <FormControlLabel
                key={mode}
                value={mode}
                disabled={!getCanUseByMode(mode)}
                control={<Radio size="small" />}
                label={
                  <Box component="div" sx={{ fontSize: "small" }}>
                    {mode}
                  </Box>
                }
              />
            ))}
          </RadioGroup>
          <InfoDialog />
        </Grid>

        {!MODES_WITHOUT_MULTIPLE_SELECTION.includes(getSelectedMode())
          && !getModifyingWH() && <SelectionTypeButtons />}
        {getSelectedMode() === "ZONES" && <ZoneSelector3D />}
        {getSelectedMode() !== "VIEW" && getModifyingWH() && <EndModifyingButtons />}
        {getSelectedMode() === "ZONES" && getModifyingWH() && <ZoneEditionTools />}
        {getSelectedMode() === "VIEW" && <SearchByCodeMenu />}
        {getSelectedMode() === "CREATE" && <TemplateSelectionDialog />}
      </Paper>
    </Grid>
  );
};
