import { SxProps, Theme } from "@mui/material";

export const formDateInputStyles = (size: string | undefined) => {
  const form_control: SxProps<Theme> = {
    "& .MuiInputLabel-formControl": {
      marginTop: size === "small" ? "-2.5px" : undefined
    },

    "& .MuiInputBase-root .MuiInputBase-input": {
      paddingTop: size === "small" ? "3.5px" : undefined
    }
  };

  return {
    form_control
  };
};
