import { useTranslation } from "react-i18next";
import { useRecoverPassword } from "../hooks/useRecoverPassword";
import { recoverPasswordCardStyles } from "./recover-password-card.Styles";
import { Grid, Typography, TextField, Button, Collapse } from "@mui/material";

type TRecoverPasswordCard = {
  handleClose: () => void;
  open: boolean;
};

const RecoverPasswordCard = ({ handleClose, open }: TRecoverPasswordCard) => {
  const { t } = useTranslation();
  const styles = recoverPasswordCardStyles();
  const { handleUsernameChange, handleConfirm, usernameError, disabled } = useRecoverPassword();

  return (
    <Grid container flexDirection="column" sx={styles.recover_password} style={{ display: open ? "" : "none" }}>
      <Typography variant="title" data-testid="recover-title">
        {t("recover.title")}
      </Typography>
      <TextField
        onChange={handleUsernameChange}
        label={t("login.username")}
        id="recover-username-input"
        error={usernameError}
        onKeyUp={event => {
          if (event.key === "Enter") handleConfirm();
        }}
      />
      <Collapse in={!open} timeout={{ appear: 300, enter: 300, exit: 300 }}>
        <TextField data-testid="password-textfield" type="password" label={t("login.password")} />
      </Collapse>
      <Button id="recover-password-button" color="secondary" onClick={handleConfirm} disabled={disabled}>
        {t("recover.confirm")}
      </Button>
      <Typography component="span" onClick={handleClose} className="hover" variant="subText" width="fit-content">
        {t("recover.return")}
      </Typography>
    </Grid>
  );
};

export default RecoverPasswordCard;
