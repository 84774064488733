import { GridColDef } from "@mui/x-data-grid";
import { filterValueTypes } from "@constants";
import { CustomTooltipCell } from "@components/tooltip/CustomTooltipCell";

export const relabelColumns = (t: (text: string) => string, lgMediaQuery: boolean): GridColDef[] => {
  return [
    {
      flex: 0.8,
      field: "oldContainerCode",
      headerName: t("relabel-old-container"),
      disableColumnMenu: true,
      minWidth: lgMediaQuery ? undefined : 290,
      sortable: false,
      valueGetter: row => {
        return row.value ? row.value : "-";
      },
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.8,
      field: "newContainerCode",
      headerName: t("relabel-new-container"),
      disableColumnMenu: true,
      minWidth: lgMediaQuery ? undefined : 290,
      sortable: false,
      valueGetter: row => {
        return row.value ? row.value : "-";
      },
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.5,
      field: "quantity",
      headerName: t("relabel-qty"),
      disableColumnMenu: true,
      type: "number",
      sortable: false,
      valueGetter: ({ row }) => {
        return row.quantity
          ? `${row.quantity} ${t("material.measure.abbreviation." + (row.uom && row.uom !== "") ? row.uom.toLowerCase() : "")}`
          : "-";
      },
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1,
      field: "relabelDate",
      headerName: t("relabel-date"),
      disableColumnMenu: true,
      sortable: false,
      minWidth: lgMediaQuery ? undefined : 85,
      type: "range-date" as filterValueTypes,
      valueGetter: ({ row }) => {
        return row.relabelDate
          ? row.relabelDate.toLocaleString("es-ES", {
              localeMatcher: "lookup",
              month: "numeric",
              day: "numeric",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit"
            })
          : "-";
      },
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.8,
      field: "user",
      headerName: t("relabel-license"),
      disableColumnMenu: true,
      sortable: false,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.6,
      field: "operatorCode",
      headerName: t("relabel-operator"),
      disableColumnMenu: true,
      sortable: false,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    }
  ];
};
