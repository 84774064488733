import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FamilyType from "@domain/model/FamilyType";
import { Card } from "@components/master-data-view/card/card";

const TypeOfFamilyCard = (props: {
  typeOfFamily: FamilyType;
  handleEdit: (id: string) => void;
  handleActive: (item: FamilyType) => void;
}) => {
  const { t } = useTranslation();
  const { typeOfFamily, handleEdit, handleActive } = props;

  const renderChild = (name: string, value: string) => {
    return (
      <div className="value-card-container" data-cy={`type-of-family-card-${name}-container`}>
        <div className="title-content">
          <Typography variant="cardlabel" textAlign="left">
            {t(name)}
          </Typography>
          <Typography variant="cardvalue" data-cy={`value`}>
            {value}
          </Typography>
        </div>
      </div>
    );
  };

  const rendererCard = (data: FamilyType) => {
    return (
      <Card
        isActive={data.active}
        key={data.id}
        popover={{
          handleEdit: () => handleEdit(data.id ?? ""),
          handleActive: () => handleActive(data),
          activateTitle: data.active ? t("root.deactivate") : t("root.activate")
        }}
        id={"id-card-" + data.id}
        nameValue={data.name}>
        {renderChild("type.of.families.status", data.active ? t("type.of.families.active") : t("type.of.families.deactivate"))}
      </Card>
    );
  };

  return rendererCard(typeOfFamily);
};

export default TypeOfFamilyCard;
