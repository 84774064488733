import { User } from "@domain/model/User";
import { useEffect, useState } from "react";
import Material from "@domain/model/Material";
import LocationEntity from "@domain/model/Location";
import { ContainerDTO } from "@clients/aggrego-proxy";

export const useMovementsGetDataByUrl = () => {
  const [searchBarFilter, setSearchBarFilter] = useState("");

  const applyUrlObtainedFilter = (preselectedFilterObject: Material | LocationEntity | ContainerDTO | User) => {
    if ("minStock" in preselectedFilterObject) {
      setSearchBarFilter((preselectedFilterObject as Material).code);
    } else if ("capacity" in preselectedFilterObject) {
      setSearchBarFilter((preselectedFilterObject as LocationEntity).code ?? "");
    } else if ("email" in preselectedFilterObject) {
      setSearchBarFilter((preselectedFilterObject as User).username);
    } else if (preselectedFilterObject as ContainerDTO) setSearchBarFilter(preselectedFilterObject.code ?? "");
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const movementReport = params.get("search");
    if (movementReport) {
      const preselectedFilterObject = JSON.parse(decodeURIComponent(movementReport));
      applyUrlObtainedFilter(preselectedFilterObject);
    }
  }, []);

  return {
    searchBarFilter
  };
};
