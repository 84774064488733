import { COLORS } from "@theme";
import { useTranslation } from "react-i18next";
import { Product } from "@domain/model/Product";
import { ProductChild } from "@domain/model/ProductChild";
import { Grid, Divider, Chip, Typography } from "@mui/material";
import { hierarchyReportStyles } from "../hierarchy-report.Styles";
import { HierarchyTrackingChildrenCard } from "./hierarchy-tracking-children-card";

export const HierarchyTrackingParentCard = (props: {
  product: Product;
  productChildren: ProductChild[];
  selectedCard: string;
  setSelectedCard: (value: string) => void;
  onChangeHierarchyLevel: (id?: string) => void;
  hasVersionLabel: boolean;
}) => {
  const { t } = useTranslation();
  const { product, productChildren, selectedCard, setSelectedCard, onChangeHierarchyLevel, hasVersionLabel } = props;

  const styles = hierarchyReportStyles();

  const renderChildrenItems = (childrenItems: ProductChild[]) =>
    childrenItems.map(c => (
      <HierarchyTrackingChildrenCard
        key={c.id}
        data={c}
        selectedCard={selectedCard}
        setSelectedCard={setSelectedCard}
        onChangeHierarchyLevel={onChangeHierarchyLevel}
        hasVersionLabel={hasVersionLabel}
      />
    ));
  return (
    <>
      <Grid
        container
        id="hierarchy-card-parent"
        onClick={() => {
          setSelectedCard(product.id);
        }}
        sx={{
          border: selectedCard === product.id ? "2px solid " + COLORS.secondary : "none",
          borderLeft: "8px solid transparent",
          ...styles.hierarchy_parent_product
        }}>
        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item xs={12} md={3.5}>
            <Typography variant="cardlabel">{t("products.serial")}</Typography>
            <Typography variant="cardvalue">{product.serialNumber !== "" ? product.serialNumber : "-"}</Typography>
          </Grid>
          {product.materialCode && (
            <Grid item xs={12} md={3.5}>
              <Typography variant="cardlabel">{t("products.hierarchy.material")}</Typography>
              <Typography variant="cardvalue">{product.materialCode}</Typography>
            </Grid>
          )}
          <Grid item xs={12} md={3.5}>
            <Typography variant="cardlabel">{hasVersionLabel ? t("products.version") : t("products.batch")}</Typography>
            <Typography variant="cardvalue">{product.batch !== "" ? product.batch : "-"}</Typography>
          </Grid>
          {product.state && (
            <Grid item xs={12} md={1.5}>
              <Typography variant="cardlabel">{t("products.state")}</Typography>
              <Chip
                className={`product-${product.state.toLowerCase()}`}
                size="small"
                label={t("products.state." + product.state.toLowerCase())}
                sx={{ width: "6.75rem" }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {product.manufacturingDate && (
            <Grid item xs={12} md={3.5}>
              <Typography variant="cardlabel">{t("products.hierarchy.date")}</Typography>
              <Typography variant="cardvalue">{product.manufacturingDate?.toLocaleString().replace(",", "")}</Typography>
            </Grid>
          )}
          <Grid item xs={12} md={3.5}>
            <Typography variant="cardlabel">{t("products.manufacture.order")}</Typography>
            <Typography variant="cardvalue">{product.manufactureOrder !== "" ? product.manufactureOrder : "-"}</Typography>
          </Grid>
          {product.qty !== undefined && (
            <Grid item xs={12} md={3.5}>
              <Typography variant="cardlabel">{t("products.qty")}</Typography>
              <Typography variant="cardvalue">
                {product.qty} {product.uom}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} md={1.5}></Grid>
        </Grid>
      </Grid>
      <Grid id="hierarchy-children-container">{renderChildrenItems(productChildren)}</Grid>
    </>
  );
};
