import { ChangeEvent } from "react";
import { TextField } from "@mui/material";

export interface IFormTextInput {
  id: string;
  name: string;
  value: string | undefined;
  label: string;
  errorText: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const FormTextInput = (props: IFormTextInput) => {
  return (
    <TextField
      error={props.errorText ? true : false}
      helperText={props.errorText}
      id={props.id}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      label={props.label}
    />
  );
};
