import Zone from "@domain/model/Zone";
import Tile from "@domain/model/Tile";
import { useResolve } from "@movicoders/di";
import { Dispatch, SetStateAction } from "react";
import { handleError } from "@utils/error-helper";
import LocationEntity from "@domain/model/Location";
import { CREATE_ID, TIMEOUT_ID } from "@constants";
import { LastPageState } from "@domain/model/LastPageState";
import { useMasterDataMessagges } from "@hooks/useMasterDataMessages";
import { FindTilesUseCase } from "@application/master-data/tiles/find-tiles-use-case";
import { LocationsCreateUseCase } from "@application/master-data/locations/locations-create-use-case";
import { FindZonesByTenantUseCase } from "@application/master-data/zones/find-zones-by-tenant-use-case";
import { GetTilesByWarehouseIDRequest, ListLocationsPaginatedRequest, ResponseError } from "@clients/aggrego-proxy";

export const useLocationSave = (
  selectedWarehouse: string,
  selectedLocation: LocationEntity,
  currentPagePersistedState: LastPageState,
  getCurrentStatus: (status: "ALL" | "ACTIVE" | "INACTIVE") => boolean | undefined,
  setTilesByWarehouse: Dispatch<SetStateAction<Tile[]>>,
  findTilesUseCase: FindTilesUseCase,
  setZonesByWarehouse: Dispatch<SetStateAction<Zone[]>>,
  findZonesByTenantUseCase: FindZonesByTenantUseCase,
  goToList: (criteria: ListLocationsPaginatedRequest) => Promise<void>,
  goToDetail: (id: string) => Promise<void>
) => {
  const { successMessaggesSaving, errorMessaggesSaving, errorMessaggesLoading } = useMasterDataMessagges();
  const locationsCreateUseCase = useResolve(LocationsCreateUseCase);

  /**
   * Handles the saving of the location
   * If the location id equals to "-1" sets the location active property to true
   * @param location The location to be saved
   * @param tile The tile to be saved
   */
  const handleSave = async (location: LocationEntity, tile: Tile) => {
    let response;
    try {
      if (location.id === CREATE_ID) {
        location.active = true;
      }
      await locationsCreateUseCase.execute({ ...location, warehouseId: selectedWarehouse }, tile);
      successMessaggesSaving("location", location.code ?? "", location.id);
    } catch (error) {
      response = await handleError(error as ResponseError);
      const errorToShow = response?.errors?.[0]?.errorCode?.toLowerCase().split(" ").join(".") ?? "unknown";
      errorMessaggesSaving("location", location.code ?? "", location.id, errorToShow);
    } finally {
      findTilesUseCase
        .byWarehouseId({ warehouseId: selectedWarehouse, fullDTO: false } as GetTilesByWarehouseIDRequest)
        .then(tilesResult => {
          setTilesByWarehouse(tilesResult);
        });
      findZonesByTenantUseCase.execute("ALL").then(result => {
        setZonesByWarehouse(result.filter((zone: Zone) => zone.warehouseId === selectedWarehouse));
      });
      goToList({
        xTenantId: "",
        limit: currentPagePersistedState.limit,
        offset: currentPagePersistedState.offset ?? 0,
        customLocationsFilterDTO: {
          ...currentPagePersistedState.filters,
          status: getCurrentStatus(currentPagePersistedState.status),
          warehouseId: selectedWarehouse
        }
      });
    }
  };

  const handleEdit = (id: string) => {
    goToDetail(id).catch(() => {
      errorMessaggesLoading("location", selectedLocation.code ?? "");
      selectedLocation.id = TIMEOUT_ID;
    });
  };

  return {
    handleSave,
    handleEdit
  };
};
