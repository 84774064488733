import { DomainPage } from "@movicoders/domain";
import Family from "@domain/model/Family";
import { IImpersonationService } from "@domain/services/IImpersonationService";
import {
  FamiliesApi,
  ListFamiliesByTenantIdRequest,
  ListFamiliesPaginatedRequest
} from "@clients/aggrego-proxy/apis/FamiliesApi";
import { FamilyDTO } from "@clients/aggrego-proxy";

export class FamilyApi {
  constructor(private readonly familiesApi: FamiliesApi, private readonly impersonationService: IImpersonationService) {}

  async get(): Promise<Family[]> {
    const result: Family[] = [];
    const apiResult = await this.familiesApi.listFamilies({
      fullDTO: false,
      status: "ALL",
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
    apiResult.forEach(familyClient => {
      result.push(Family.fromClientFamily(familyClient));
    });
    return result;
  }

  async getPaginated(pageCriteria: ListFamiliesPaginatedRequest): Promise<DomainPage<Family>> {
    return (await this.familiesApi.listFamiliesPaginated({
      ...pageCriteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    })) as unknown as Promise<DomainPage<Family>>;
  }

  async getByTenant(criteria: ListFamiliesByTenantIdRequest): Promise<Family[]> {
    const result: Family[] = [];
    const apiResult = await this.familiesApi.listFamiliesByTenantId({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });

    apiResult.forEach((clientFamily: FamilyDTO) => {
      result.push(Family.fromClientFamily(clientFamily));
    });
    return result;
  }
  async getById(id: string): Promise<Family> {
    const result = Family.fromClientFamily(
      await this.familiesApi.getFamilyById({
        id: id,
        fullDTO: true,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return result;
  }

  async create(entity: Family): Promise<Family> {
    const result = Family.fromClientFamily(
      await this.familiesApi.saveFamily({
        familyDTO: entity,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return result;
  }

  async update(entity: Family): Promise<Family> {
    const result = Family.fromClientFamily(
      await this.familiesApi.updateFamily({
        familyDTO: entity,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return result;
  }

  async remove(id: string): Promise<void> {
    await this.familiesApi.deleteFamily({
      id: id,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }
}
