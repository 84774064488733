import { t } from "i18next";
import { useSnackbar } from "@movicoders/ui";
import { Dispatch, SetStateAction, useState } from "react";
import IMMEntryWithDesc from "@domain/model/IMMEntryWithDesc";

const qtyValidator = (qty: number) => {
  const min = 0;
  const max = 1000000;
  if (qty > max) {
    return max;
  } else if (qty < min) {
    return min;
  } else {
    return qty;
  }
};

export const useEntriesForm = (
  iMMaterialList: IMMEntryWithDesc[],
  setIMMaterialList: Dispatch<SetStateAction<IMMEntryWithDesc[]>>
) => {
  const { show } = useSnackbar();

  const [selectedEntry, setSelectedEntry] = useState<IMMEntryWithDesc>({});

  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isClearingAll, setIsClearingAll] = useState(false);

  const handleOpenEditDialog = (entry: IMMEntryWithDesc) => {
    setIsEditing(true);
    setSelectedEntry(entry);
  };

  const handleOpenDeleteDialog = (entry: IMMEntryWithDesc) => {
    setIsDeleting(!isDeleting);
    setSelectedEntry(entry);
  };

  const onCloseDialog = () => {
    setIsDeleting(false);
    setIsEditing(false);
    setIsClearingAll(false);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    setSelectedEntry({
      ...selectedEntry,
      [fieldName]: fieldName === "qty" ? qtyValidator(+fieldValue) : fieldValue
    });
  };

  const handleSave = () => {
    const previousEntryId = selectedEntry.id?.split("±")[0];
    const newEntryId = selectedEntry.id?.split("±")[1];
    const isMaterialCodeEmpty = selectedEntry.materialCode === "";
    let isEntryIdRepeated = false;
    iMMaterialList.find((entry: IMMEntryWithDesc) => {
      if (entry.id === newEntryId) {
        isEntryIdRepeated = true;
        return true;
      }
      return false;
    });
    if (!isEntryIdRepeated && !isMaterialCodeEmpty) {
      const editedIMMaterialList = iMMaterialList.map(iMMaterial => {
        if (iMMaterial.id === previousEntryId) {
          return {
            ...iMMaterial,
            id: newEntryId !== undefined ? newEntryId : previousEntryId,
            materialCode: selectedEntry.materialCode,
            materialDescription: selectedEntry.materialDescription,
            qty: selectedEntry.qty,
            comment: selectedEntry.comment
          };
        } else {
          return iMMaterial;
        }
      });
      setIMMaterialList(editedIMMaterialList);
      setIsEditing(!isEditing);
    } else {
      if (isMaterialCodeEmpty) {
        show(t("material.dialog.empty.autocomplete.text"), "error");
      } else {
        show(t("inbounds.repeated"), "error");
      }
    }
  };

  const handleDelete = () => {
    iMMaterialList.map(iMMaterial => {
      setIMMaterialList(iMMaterialList.filter(item => item.id !== selectedEntry.id));
      return true;
    });
    setIsDeleting(false);
  };

  const handleOpenClearAllDialog = () => {
    setIsClearingAll(true);
  };

  const handleClearMaterials = () => {
    setIMMaterialList([]);
    setIsClearingAll(false);
  };

  return {
    selectedEntry,
    setSelectedEntry,
    isEditing,
    handleSave,
    handleOpenEditDialog,
    isDeleting,
    handleDelete,
    handleOpenDeleteDialog,
    onCloseDialog,
    onChange,
    isClearingAll,
    handleOpenClearAllDialog,
    handleClearMaterials
  };
};
