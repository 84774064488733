import Icon from "@components/icons/icon";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEntriesMediaQueries } from "../../hooks/useEntriesMediaQueries";

interface IEntriesBottom {
  noMaterials: boolean;
  handleOpenClearAllDialog: () => void;
  handleInSynchronize: () => void;
}

export const EntriesBottom = (props: IEntriesBottom) => {
  const { t } = useTranslation();
  const { matchesMd } = useEntriesMediaQueries();
  const { noMaterials, handleOpenClearAllDialog, handleInSynchronize } = props;

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Button
          id="logistic-clear-button"
          variant={"secondary"}
          sx={{ mt: "1rem", mb: matchesMd ? "1rem" : "0.5rem", width: matchesMd ? "auto" : "100%" }}
          disabled={!noMaterials}
          onClick={handleOpenClearAllDialog}>
          {t("logistic.clear.button")}
        </Button>
      </Grid>
      <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
        <Button
          id="sync-button"
          startIcon={<Icon icon="sync" />}
          variant={!noMaterials ? undefined : "primary"}
          sx={{ mt: "1rem", mb: matchesMd ? "1rem" : "0.5rem", width: matchesMd ? "auto" : "100%" }}
          disabled={!noMaterials}
          onClick={handleInSynchronize}>
          {t("logistic.sync.button")}
        </Button>
      </Grid>
    </Grid>
  );
};
