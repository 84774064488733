import { useState } from "react";
import Material from "@domain/model/Material";
import { GridSortModel } from "@mui/x-data-grid";
import { LastPageState } from "@domain/model/LastPageState";
import { ILastPageService } from "@domain/services/ILastPageService";
import { ListMaterialsPaginatedRequest, ListMaterialsPaginatedSortDirectionEnum } from "@clients/aggrego-proxy/apis/MaterialsApi";

export const useMaterialPagination = (
  currentPageService: ILastPageService,
  currentPagePersistedState: LastPageState,
  materialData: Material[],
  getCurrentStatus: (status: "ALL" | "ACTIVE" | "INACTIVE") => boolean | undefined,
  getMaterialPaginated: (criteria: ListMaterialsPaginatedRequest) => Promise<void>
) => {
  const DEBOUNCE_TIME = 500;
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);

  const sortByFieldBackend = (model: GridSortModel) => {
    materialData &&
      materialData.length > 0 &&
      getMaterialPaginated({
        limit: currentPagePersistedState.limit,
        offset: currentPagePersistedState.offset,
        status: currentPagePersistedState.status,
        customMaterialFilter: {
          ...currentPagePersistedState.filters,
          status: getCurrentStatus(currentPagePersistedState.status)
        },
        fullDTO: false,
        sortDirection: model[0]?.sort?.toUpperCase() ?? ListMaterialsPaginatedSortDirectionEnum.Desc,
        sortField: model[0]?.field?.toUpperCase()
      } as ListMaterialsPaginatedRequest);

    currentPageService.saveLastPageState({
      ...currentPagePersistedState,
      sortDirection: model[0]?.sort?.toUpperCase() as "ASC" | "DESC" | undefined,
      sortField: model[0]?.field?.toUpperCase()
    });
  };

  const handlePagination = (limit: number, offset: number, status?: "ALL" | "ACTIVE" | "INACTIVE", filters?: object) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    const newDebounceTimer = setTimeout(() => {
      getMaterialPaginated({
        xTenantId: "",
        limit: limit,
        offset: offset,
        fullDTO: false,
        customMaterialFilter: { status: getCurrentStatus(status ?? "ALL"), ...filters },
        sortDirection: currentPagePersistedState.sortDirection,
        sortField: currentPagePersistedState.sortField
      } as ListMaterialsPaginatedRequest);
    }, DEBOUNCE_TIME);
    setDebounceTimer(newDebounceTimer);
  };

  return {
    handlePagination,
    sortByFieldBackend
  };
};
