import { ContainerApi } from "../api/container-api";
import {
  CloseInventoryPeriodRequest,
  CreateInventoryPeriodRequest,
  GetInventoryPeriodByWarehouseRequest
} from "@clients/aggrego-proxy";
import { InvPeriodDTO } from "@domain/model/InvPeriod";

export class InventoryPeriodRepository {
  constructor(private readonly containerApi: ContainerApi) {}

  get(criteria: GetInventoryPeriodByWarehouseRequest): Promise<InvPeriodDTO[]> {
    const result = this.containerApi.getInventoryPeriodByWarehouseCode(criteria);
    return result;
  }

  create(criteria: CreateInventoryPeriodRequest): void {
    this.containerApi.createInventoryPeriod(criteria);
  }

  remove(criteria: CloseInventoryPeriodRequest): void {
    this.containerApi.closeInventoryPeriod(criteria);
  }
}
