import { CREATE_ID } from "@constants";
import Family from "@domain/model/Family";
import { useTranslation } from "react-i18next";
import FamilyType from "@domain/model/FamilyType";
import { SyntheticEvent, useEffect, useState } from "react";

export const useFamilyForm = (data: Family, typesOfFamilies: FamilyType[]) => {
  const { t } = useTranslation();

  const createType: FamilyType = {
    id: CREATE_ID,
    name: t("families.new.type"),
    active: true
  };

  const INITIAL_ERROR_MAP = new Map([
    ["family-name", false],
    ["family-type-autocomplete", false],
    ["type-name", false]
  ]);

  const [family, setFamily] = useState<Family>(data);
  const [options, setOptions] = useState<FamilyType[]>([]);

  // Type of family block
  const [myFamilyType, setMyFamilyType] = useState<FamilyType | null>(
    typesOfFamilies.find(ft => ft.id === family.familyTypeId) ?? null
  );
  const [newTypeOfFamily, setNewTypeOfFamily] = useState<boolean>(false);
  const [newFamilyTypeName, setNewFamilyTypeName] = useState<string | undefined>(undefined);
  // End type of family block

  const [formErrors, setFormErrors] = useState<Map<string, boolean>>(INITIAL_ERROR_MAP);
  const [pendingRequest, setPendingRequest] = useState<boolean>(false);

  useEffect(() => {
    setFamily(data);
    resetNewFamilyType();
    setFormErrors(INITIAL_ERROR_MAP);

    if (data.id !== CREATE_ID) {
      setMyFamilyType(typesOfFamilies.find(ft => ft.id === data.familyTypeId) ?? null);
    }

    setPendingRequest(false);
  }, [data]);

  useEffect(() => {
    setOptions([...typesOfFamilies, createType].filter(ft => ft.active === true));
  }, [typesOfFamilies]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFamily({ ...family, [event.target.name]: event.target.value });
  };

  const onChangeAutocomplete = <T>(event: SyntheticEvent<Element, Event>, value: T | null) => {
    let valueModified: FamilyType | undefined = undefined;
    if (value !== null) {
      valueModified = value as FamilyType;
    }
    setFamily({ ...family, familyTypeId: valueModified?.id });

    if ((value as FamilyType)?.id === "" || (value as FamilyType)?.id === null) {
      setNewTypeOfFamily(false);
    } else {
      (value as FamilyType)?.name === t("families.new.type") ? setNewTypeOfFamily(true) : setNewTypeOfFamily(false);
    }
    setMyFamilyType(value as FamilyType);
  };

  const onChangeNewType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewFamilyTypeName(event.target.value);
  };

  const resetNewFamilyType = () => {
    setMyFamilyType(null);
    setNewFamilyTypeName("");
    setNewTypeOfFamily(false);
  };

  const validateFormValues = () => {
    const familyNameValid = family.name !== null && family.name !== undefined && family.name.trim() !== "";
    const typeOfFamilyIdValid =
      family.familyTypeId !== null && family.familyTypeId !== undefined && family.familyTypeId.trim() !== "";
    const familyTypeNameValid = newFamilyTypeName !== null && newFamilyTypeName !== undefined && newFamilyTypeName.trim() !== "";

    setFormErrors(
      new Map([
        ["family-name", !familyNameValid],
        ["family-type-autocomplete", !typeOfFamilyIdValid],
        ["type-name", !familyTypeNameValid]
      ])
    );

    if (familyNameValid && typeOfFamilyIdValid && (familyTypeNameValid || family.familyTypeId !== CREATE_ID)) {
      setPendingRequest(true);
      return true;
    } else {
      return false;
    }
  };

  return {
    options,
    onChange,
    family,
    onChangeAutocomplete,
    onChangeNewType,
    resetNewFamilyType,
    formErrors,
    validateFormValues,
    myFamilyType,
    newTypeOfFamily,
    newFamilyTypeName,
    pendingRequest
  };
};
