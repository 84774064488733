import { useEffect } from "react";
import { useResolve } from "@movicoders/di";
import { parseJwt } from "@utils/token-helper";
import { LoginUseCase } from "@application/login/login-use-case";
import { IUserService, UserService } from "@domain/services/IUserService";

export const useCheckLoginStatus = () => {
  const loginUseCase = useResolve(LoginUseCase);
  const { user } = useResolve<IUserService>(UserService);

  let intervalToken: NodeJS.Timeout;

  const isValidToken = () => {
    const storagedToken = sessionStorage.getItem("sessionToken");
    if (storagedToken === null) {
      return false;
    }
    const parsedToken = parseJwt(storagedToken);
    // Check if the token is valid with the exp date.
    const now = Date.now();
    if (parsedToken.exp ?? 0 > now) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    intervalToken && clearInterval(intervalToken);
    intervalToken = setInterval(async () => {
      if (!isValidToken() && user && user?.token && !(await loginUseCase.isTokenValid(user?.token ?? ""))) {
        localStorage.clear();
        window.location.reload();
      }
    }, 5000);
  }, [sessionStorage.length]);
};
