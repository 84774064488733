/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  ListResponseDTO,
  MovicodersBadRequestException,
  MovicodersNotFoundException,
  MovicodersServiceException,
  TileDTO,
  TilePageDTO
} from "../models";
import {
  ListResponseDTOFromJSON,
  ListResponseDTOToJSON,
  MovicodersBadRequestExceptionFromJSON,
  MovicodersBadRequestExceptionToJSON,
  MovicodersNotFoundExceptionFromJSON,
  MovicodersNotFoundExceptionToJSON,
  MovicodersServiceExceptionFromJSON,
  MovicodersServiceExceptionToJSON,
  TileDTOFromJSON,
  TileDTOToJSON,
  TilePageDTOFromJSON,
  TilePageDTOToJSON
} from "../models";

export interface AddLocationToTileRequest {
  tileId: string;
  locationId: string;
  xTenantId?: string;
}

export interface AddTileInsideZoneRequest {
  zoneId: string;
  xTenantId?: string;
  tileDTO?: Array<TileDTO>;
}

export interface DeleteTileRequest {
  id: string;
  xTenantId?: string;
}

export interface DeleteTilesRequest {
  xTenantId?: string;
  requestBody?: Array<string>;
}

export interface GetTileByCodeRequest {
  code: string;
  xTenantId?: string;
}

export interface GetTileByIdRequest {
  id: string;
  fullDTO: boolean;
  xTenantId?: string;
}

export interface GetTileByIdsRequest {
  xTenantId?: string;
  requestBody?: Array<string>;
}

export interface GetTilesByCodeRequest {
  code: string;
  xTenantId?: string;
}

export interface GetTilesByWarehouseIDRequest {
  fullDTO: boolean;
  warehouseId: string;
  xTenantId?: string;
}

export interface ListTilesRequest {
  fullDTO: boolean;
  status: ListTilesStatusEnum;
  xTenantId?: string;
}

export interface ListTilesByTenantIdRequest {
  fullDTO: boolean;
  tenantId: string;
  status: ListTilesByTenantIdStatusEnum;
  xTenantId?: string;
}

export interface ListTilesPaginatedRequest {
  status: ListTilesPaginatedStatusEnum;
  limit: number;
  offset: number;
  xTenantId?: string;
  sortField?: ListTilesPaginatedSortFieldEnum;
  sortDirection?: ListTilesPaginatedSortDirectionEnum;
}

export interface SaveTileRequest {
  xTenantId?: string;
  tileDTO?: TileDTO;
}

export interface SaveTilesRequest {
  xTenantId?: string;
  tileDTO?: Array<TileDTO>;
}

export interface UpdateTileRequest {
  xTenantId?: string;
  tileDTO?: TileDTO;
}

export interface UpdateTilesRequest {
  xTenantId?: string;
  tileDTO?: Array<TileDTO>;
}

/**
 *
 */
export class TilesApi extends runtime.BaseAPI {
  /**
   * Save the location inside the tile
   */
  async addLocationToTileRaw(
    requestParameters: AddLocationToTileRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TileDTO>> {
    if (requestParameters.tileId === null || requestParameters.tileId === undefined) {
      throw new runtime.RequiredError(
        "tileId",
        "Required parameter requestParameters.tileId was null or undefined when calling addLocationToTile."
      );
    }

    if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
      throw new runtime.RequiredError(
        "locationId",
        "Required parameter requestParameters.locationId was null or undefined when calling addLocationToTile."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/tiles/{tileId}/locations/{locationId}`
          .replace(`{${"tileId"}}`, encodeURIComponent(String(requestParameters.tileId)))
          .replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => TileDTOFromJSON(jsonValue));
  }

  /**
   * Save the location inside the tile
   */
  async addLocationToTile(
    requestParameters: AddLocationToTileRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TileDTO> {
    const response = await this.addLocationToTileRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Given a list of Tiles, adds them to a zone
   * Add tiles inside existing zone.
   */
  async addTileInsideZoneRaw(
    requestParameters: AddTileInsideZoneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ListResponseDTO>> {
    if (requestParameters.zoneId === null || requestParameters.zoneId === undefined) {
      throw new runtime.RequiredError(
        "zoneId",
        "Required parameter requestParameters.zoneId was null or undefined when calling addTileInsideZone."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/tiles/{zoneId}`.replace(`{${"zoneId"}}`, encodeURIComponent(String(requestParameters.zoneId))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.tileDTO?.map(TileDTOToJSON)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ListResponseDTOFromJSON(jsonValue));
  }

  /**
   * Given a list of Tiles, adds them to a zone
   * Add tiles inside existing zone.
   */
  async addTileInsideZone(
    requestParameters: AddTileInsideZoneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ListResponseDTO> {
    const response = await this.addTileInsideZoneRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Takes an existing tile, deletes it, and returns the new object.
   * Delete an existing tile by id.
   */
  async deleteTileRaw(
    requestParameters: DeleteTileRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TileDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteTile."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/tiles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => TileDTOFromJSON(jsonValue));
  }

  /**
   * Takes an existing tile, deletes it, and returns the new object.
   * Delete an existing tile by id.
   */
  async deleteTile(
    requestParameters: DeleteTileRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TileDTO> {
    const response = await this.deleteTileRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the deleted Tiles
   */
  async deleteTilesRaw(
    requestParameters: DeleteTilesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<TileDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/tiles/delete-many`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(TileDTOFromJSON));
  }

  /**
   * Returns the deleted Tiles
   */
  async deleteTiles(
    requestParameters: DeleteTilesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<TileDTO>> {
    const response = await this.deleteTilesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns tile object by exact code.
   * Get tile object by exact code.
   */
  async getTileByCodeRaw(
    requestParameters: GetTileByCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TileDTO>> {
    if (requestParameters.code === null || requestParameters.code === undefined) {
      throw new runtime.RequiredError(
        "code",
        "Required parameter requestParameters.code was null or undefined when calling getTileByCode."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.code !== undefined) {
      queryParameters["code"] = requestParameters.code;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/tiles/code/exact`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => TileDTOFromJSON(jsonValue));
  }

  /**
   * Returns tile object by exact code.
   * Get tile object by exact code.
   */
  async getTileByCode(
    requestParameters: GetTileByCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TileDTO> {
    const response = await this.getTileByCodeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns one tile object by id.
   * Get a tile object by id.
   */
  async getTileByIdRaw(
    requestParameters: GetTileByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TileDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getTileById."
      );
    }

    if (requestParameters.fullDTO === null || requestParameters.fullDTO === undefined) {
      throw new runtime.RequiredError(
        "fullDTO",
        "Required parameter requestParameters.fullDTO was null or undefined when calling getTileById."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fullDTO !== undefined) {
      queryParameters["fullDTO"] = requestParameters.fullDTO;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/tiles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => TileDTOFromJSON(jsonValue));
  }

  /**
   * Returns one tile object by id.
   * Get a tile object by id.
   */
  async getTileById(
    requestParameters: GetTileByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TileDTO> {
    const response = await this.getTileByIdRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns tile objects by ids.
   * Get a tile object by id.
   */
  async getTileByIdsRaw(
    requestParameters: GetTileByIdsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<TileDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/tiles/find-many-by-id`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(TileDTOFromJSON));
  }

  /**
   * Returns tile objects by ids.
   * Get a tile object by id.
   */
  async getTileByIds(
    requestParameters: GetTileByIdsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<TileDTO>> {
    const response = await this.getTileByIdsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns tile objects by code.
   * Get tile objects by code.
   */
  async getTilesByCodeRaw(
    requestParameters: GetTilesByCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<TileDTO>>> {
    if (requestParameters.code === null || requestParameters.code === undefined) {
      throw new runtime.RequiredError(
        "code",
        "Required parameter requestParameters.code was null or undefined when calling getTilesByCode."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.code !== undefined) {
      queryParameters["code"] = requestParameters.code;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/tiles/code`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(TileDTOFromJSON));
  }

  /**
   * Returns tile objects by code.
   * Get tile objects by code.
   */
  async getTilesByCode(
    requestParameters: GetTilesByCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<TileDTO>> {
    const response = await this.getTilesByCodeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Return an existing Tile object by Warehouse id
   * Get Tile by Warehouse id.
   */
  async getTilesByWarehouseIDRaw(
    requestParameters: GetTilesByWarehouseIDRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<TileDTO>>> {
    if (requestParameters.fullDTO === null || requestParameters.fullDTO === undefined) {
      throw new runtime.RequiredError(
        "fullDTO",
        "Required parameter requestParameters.fullDTO was null or undefined when calling getTilesByWarehouseID."
      );
    }

    if (requestParameters.warehouseId === null || requestParameters.warehouseId === undefined) {
      throw new runtime.RequiredError(
        "warehouseId",
        "Required parameter requestParameters.warehouseId was null or undefined when calling getTilesByWarehouseID."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fullDTO !== undefined) {
      queryParameters["fullDTO"] = requestParameters.fullDTO;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/tiles/warehouses/{warehouseId}`.replace(
          `{${"warehouseId"}}`,
          encodeURIComponent(String(requestParameters.warehouseId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(TileDTOFromJSON));
  }

  /**
   * Return an existing Tile object by Warehouse id
   * Get Tile by Warehouse id.
   */
  async getTilesByWarehouseID(
    requestParameters: GetTilesByWarehouseIDRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<TileDTO>> {
    const response = await this.getTilesByWarehouseIDRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered Tiles
   */
  async listTilesRaw(
    requestParameters: ListTilesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<TileDTO>>> {
    if (requestParameters.fullDTO === null || requestParameters.fullDTO === undefined) {
      throw new runtime.RequiredError(
        "fullDTO",
        "Required parameter requestParameters.fullDTO was null or undefined when calling listTiles."
      );
    }

    if (requestParameters.status === null || requestParameters.status === undefined) {
      throw new runtime.RequiredError(
        "status",
        "Required parameter requestParameters.status was null or undefined when calling listTiles."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fullDTO !== undefined) {
      queryParameters["fullDTO"] = requestParameters.fullDTO;
    }

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/tiles`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(TileDTOFromJSON));
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered Tiles
   */
  async listTiles(
    requestParameters: ListTilesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<TileDTO>> {
    const response = await this.listTilesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Return tiles by tenantId.
   * Returns the list of all registered tiles by tenantId
   */
  async listTilesByTenantIdRaw(
    requestParameters: ListTilesByTenantIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<TileDTO>>> {
    if (requestParameters.fullDTO === null || requestParameters.fullDTO === undefined) {
      throw new runtime.RequiredError(
        "fullDTO",
        "Required parameter requestParameters.fullDTO was null or undefined when calling listTilesByTenantId."
      );
    }

    if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
      throw new runtime.RequiredError(
        "tenantId",
        "Required parameter requestParameters.tenantId was null or undefined when calling listTilesByTenantId."
      );
    }

    if (requestParameters.status === null || requestParameters.status === undefined) {
      throw new runtime.RequiredError(
        "status",
        "Required parameter requestParameters.status was null or undefined when calling listTilesByTenantId."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fullDTO !== undefined) {
      queryParameters["fullDTO"] = requestParameters.fullDTO;
    }

    if (requestParameters.tenantId !== undefined) {
      queryParameters["tenantId"] = requestParameters.tenantId;
    }

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/tiles/findByTenantId`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(TileDTOFromJSON));
  }

  /**
   * Return tiles by tenantId.
   * Returns the list of all registered tiles by tenantId
   */
  async listTilesByTenantId(
    requestParameters: ListTilesByTenantIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<TileDTO>> {
    const response = await this.listTilesByTenantIdRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered Tiles paginated
   */
  async listTilesPaginatedRaw(
    requestParameters: ListTilesPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TilePageDTO>> {
    if (requestParameters.status === null || requestParameters.status === undefined) {
      throw new runtime.RequiredError(
        "status",
        "Required parameter requestParameters.status was null or undefined when calling listTilesPaginated."
      );
    }

    if (requestParameters.limit === null || requestParameters.limit === undefined) {
      throw new runtime.RequiredError(
        "limit",
        "Required parameter requestParameters.limit was null or undefined when calling listTilesPaginated."
      );
    }

    if (requestParameters.offset === null || requestParameters.offset === undefined) {
      throw new runtime.RequiredError(
        "offset",
        "Required parameter requestParameters.offset was null or undefined when calling listTilesPaginated."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sortField"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sortDirection"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/tiles/paginated`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => TilePageDTOFromJSON(jsonValue));
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered Tiles paginated
   */
  async listTilesPaginated(
    requestParameters: ListTilesPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TilePageDTO> {
    const response = await this.listTilesPaginatedRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the registered Tile by Warehouse id and TileTemplate id
   */
  async saveTileRaw(
    requestParameters: SaveTileRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TileDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/tiles`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: TileDTOToJSON(requestParameters.tileDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => TileDTOFromJSON(jsonValue));
  }

  /**
   * Returns the registered Tile by Warehouse id and TileTemplate id
   */
  async saveTile(
    requestParameters: SaveTileRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TileDTO> {
    const response = await this.saveTileRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the registered Tiles
   */
  async saveTilesRaw(
    requestParameters: SaveTilesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ListResponseDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/tiles/add-many`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.tileDTO?.map(TileDTOToJSON)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ListResponseDTOFromJSON(jsonValue));
  }

  /**
   * Returns the registered Tiles
   */
  async saveTiles(
    requestParameters: SaveTilesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ListResponseDTO> {
    const response = await this.saveTilesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Takes an existing Tile object, updates it, and returns the Tile.
   * Update an existing Tile.
   */
  async updateTileRaw(
    requestParameters: UpdateTileRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TileDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/tiles`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: TileDTOToJSON(requestParameters.tileDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => TileDTOFromJSON(jsonValue));
  }

  /**
   * Takes an existing Tile object, updates it, and returns the Tile.
   * Update an existing Tile.
   */
  async updateTile(
    requestParameters: UpdateTileRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TileDTO> {
    const response = await this.updateTileRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the updated tiles
   */
  async updateTilesRaw(
    requestParameters: UpdateTilesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ListResponseDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/tiles/update-many`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.tileDTO?.map(TileDTOToJSON)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ListResponseDTOFromJSON(jsonValue));
  }

  /**
   * Returns the updated tiles
   */
  async updateTiles(
    requestParameters: UpdateTilesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ListResponseDTO> {
    const response = await this.updateTilesRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

/**
 * @export
 */
export const ListTilesStatusEnum = {
  Active: "ACTIVE",
  All: "ALL",
  Inactive: "INACTIVE"
} as const;
export type ListTilesStatusEnum = (typeof ListTilesStatusEnum)[keyof typeof ListTilesStatusEnum];
/**
 * @export
 */
export const ListTilesByTenantIdStatusEnum = {
  Active: "ACTIVE",
  All: "ALL",
  Inactive: "INACTIVE"
} as const;
export type ListTilesByTenantIdStatusEnum = (typeof ListTilesByTenantIdStatusEnum)[keyof typeof ListTilesByTenantIdStatusEnum];
/**
 * @export
 */
export const ListTilesPaginatedStatusEnum = {
  Active: "ACTIVE",
  All: "ALL",
  Inactive: "INACTIVE"
} as const;
export type ListTilesPaginatedStatusEnum = (typeof ListTilesPaginatedStatusEnum)[keyof typeof ListTilesPaginatedStatusEnum];
/**
 * @export
 */
export const ListTilesPaginatedSortFieldEnum = {
  Active: "ACTIVE",
  Code: "CODE",
  Locations: "LOCATIONS",
  Positionx: "POSITIONX",
  Positionz: "POSITIONZ",
  Warehouseid: "WAREHOUSEID",
  Zoneid: "ZONEID"
} as const;
export type ListTilesPaginatedSortFieldEnum =
  (typeof ListTilesPaginatedSortFieldEnum)[keyof typeof ListTilesPaginatedSortFieldEnum];
/**
 * @export
 */
export const ListTilesPaginatedSortDirectionEnum = {
  Asc: "ASC",
  Desc: "DESC"
} as const;
export type ListTilesPaginatedSortDirectionEnum =
  (typeof ListTilesPaginatedSortDirectionEnum)[keyof typeof ListTilesPaginatedSortDirectionEnum];
