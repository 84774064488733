import Warehouse from "@domain/model/Warehouse";
import { DrawerState } from "@domain/model/DrawerState";
import { IDrawerService } from "@domain/services/IDrawerService";
import { WarehouseRepository } from "@infrastructure/repositories/warehouse-repository";

export class WarehouseUpdateStatusUseCase {
  constructor(
    private readonly warehouseRepository: WarehouseRepository,
    private readonly drawerService: IDrawerService,
    private readonly persistedState = drawerService.getPersistedState() ?? new DrawerState()
  ) {}

  public async execute(type: Warehouse, cleanSelectedWarehouse: boolean) {
    const modifiedType = { ...type, active: !type.active };
    if (cleanSelectedWarehouse) {
      this.drawerService.saveDrawerState({
        ...this.persistedState,
        selectedWarehouse: ""
      });
    }
    return await this.warehouseRepository.update(modifiedType);
  }
}
