import { Autocomplete, Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Product } from "@domain/model/Product";
import { FormTextInput } from "../components/FormTextInput";
import { useEditChildViewmodel } from "./useEditChildViewmodel";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";
import { ButtonsDetail } from "@components/master-data-view/buttons/buttons-detail";
import { VIEW_I18N_ERROR } from "../form-children/ChildrenForm";
import { SyntheticEvent } from "react";

interface IEditChildDialogProps {
  child: Product;
  onSave: (product: Product) => void;
  onClose: () => void;
  hasVersionLabel: boolean;
}

const VIEW_I18N_TITLE = "hierarchy.creation.children.edit.";
const VIEW_I18N = "hierarchy.creation.children.";
const FORM_LABEL_I18N = "hierarchy.creation.children.form.label.";

const EditChildDialog = ({ onClose, onSave, child, hasVersionLabel }: IEditChildDialogProps) => {
  const { t } = useTranslation();
  const { editingChild, inputTextChanged, numericInputChanged, autocompleteInputChange, validatingEditingChild, errorMapState } =
    useEditChildViewmodel(child, onSave);

  const selectFieldLabel = (field: keyof Product) => {
    if (field !== "batch" || (field === "batch" && !hasVersionLabel)) {
      return t(FORM_LABEL_I18N + field);
    } else {
      return t(FORM_LABEL_I18N + "version");
    }
  };

  const selectErrorFieldMessage = (field: keyof Product) => {
    if (field !== "batch" || (field === "batch" && !hasVersionLabel)) {
      return t(VIEW_I18N_ERROR + field);
    } else {
      return t(VIEW_I18N_ERROR + "version");
    }
  };

  const renderTextInput = (field: keyof Product, width: number) => {
    return (
      <Grid item xs={12} md={6} lg={width}>
        <FormTextInput
          errorText={errorMapState.get(field) ? selectErrorFieldMessage(field) : ""}
          id={"input_" + field}
          name={field}
          onChange={inputTextChanged(field)}
          value={editingChild?.[field] as string}
          key={field}
          label={selectFieldLabel(field)}
        />
      </Grid>
    );
  };

  const renderNumericInput = (field: keyof Product, width: number) => (
    <Grid item xs={12} md={6} lg={width}>
      <FormTextInput
        errorText={errorMapState.get(field) ? t(VIEW_I18N_ERROR + field) : ""}
        id={"input_child_" + field}
        name={"child_" + field}
        onChange={numericInputChanged(field)}
        value={editingChild?.[field] as string}
        key={"child_" + field}
        label={t(VIEW_I18N + "form.label." + field)}
      />
    </Grid>
  );

  const renderAutocomplete = (field: keyof Product, width: number) => {
    return (
      <Grid item xs={12} md={6} lg={width}>
        <Autocomplete
          id={"input_" + field}
          value={editingChild.uom}
          options={["Unidades", "Kilogramos", "Litros"]}
          getOptionLabel={(option: string) => {
            if (option === "Unidades") {
              return t("material.measure.units");
            } else if (option === "Kilogramos") {
              return t("material.measure.kilograms");
            } else if (option === "Litros") {
              return t("material.measure.litres");
            } else {
              return "";
            }
          }}
          isOptionEqualToValue={(option, value) => {
            if (value === "") return false;
            return option === value;
          }}
          disableClearable
          onChange={(event: SyntheticEvent<Element, Event>, value: string | null) => {
            autocompleteInputChange(field, value ?? "");
          }}
          renderInput={params => (
            <TextField
              {...params}
              name={field}
              label={t(VIEW_I18N + "form.label." + field)}
              className={"blue-border-input"}
              error={errorMapState.get(field)}
              helperText={errorMapState.get(field) ? t(VIEW_I18N_ERROR + field) : ""}
            />
          )}
        />
      </Grid>
    );
  };

  return (
    <AggregoDialog title={t(VIEW_I18N_TITLE + "item")} onClose={onClose}>
      <Grid container className="masterdata-box" rowSpacing={2} columnSpacing={1} sx={{ ml: -0.5 }}>
        {renderTextInput("materialCode", 4)}
        {renderTextInput("serialNumber", 4)}
        {renderTextInput("batch", 4)}
        {renderNumericInput("qty", 3)}
        {renderAutocomplete("uom", 3)}
        <Grid item xs={12}>
          <ButtonsDetail handleClose={onClose} handleSave={() => validatingEditingChild()} mode={"EDIT"} />
        </Grid>
      </Grid>
    </AggregoDialog>
  );
};

export default EditChildDialog;
