import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import { useResolve } from "@movicoders/di";
import { useTranslation } from "react-i18next";
import { settingsStyles } from "./settings-styles";
import { UserRoleEnum } from "@domain/interface/User";
import { SettingsTitle } from "./components/settings-title";
import AccessCheckboxForm from "./components/access-checkbox-form";
import { FullUserDTOCompanyLicenseEnum } from "@clients/aggrego-proxy";
import { IUserService, UserService } from "@domain/services/IUserService";
import { Autocomplete, Button, Container, Grid, TextField } from "@mui/material";
import { useSettingsListViewModel } from "./hooks/view-model/useSettingsListViewModel";
import { MasterDataEnum, ReportEnum, LogisticEnum, TrackAndTraceEnum } from "./settings-interfaces";
import { LoadingSpinnerMasterView } from "@components/loading-circular-progress/loading-spinner-master-view";
import { PersistedAlert } from "@components/persistedAlert/PersistedAlert";

const SettingsView = () => {
  const { t } = useTranslation();

  const {
    selectedRole,
    accessMasterData,
    setAccessMasterData,
    accessReports,
    setAccessReports,
    accessLogistic,
    setAccessLogistic,
    accessTrackAndTrace,
    setAccessTrackAndTrace,
    loading,
    recoverPermissionByRole,
    errorRecoveringPermission,
    savePermissionByRole
  } = useSettingsListViewModel();

  const styles = settingsStyles();
  const ROLE_OPTIONS = [UserRoleEnum.PortalUser, UserRoleEnum.PortalAdmin];

  const userService = useResolve<IUserService>(UserService);
  const userCompanyLicense = (userService.user?.companyLicense as FullUserDTOCompanyLicenseEnum) ?? undefined;

  return (
    <Container sx={styles.settings_view_container} style={{ maxWidth: "none", marginBottom: "3rem" }}>
      <SettingsTitle name={t("settings")} icon={<Icon icon="settings" color={COLORS.primary} />} />
      <Grid container display={"block"}>
        <Grid item id="role-selector-autocomplete-grid" display={"flex"} width={"20rem"} sx={{ mt: "3rem", mb: "1rem" }}>
          <Autocomplete
            id="role-selector-autocomplete"
            value={selectedRole ?? UserRoleEnum.PortalUser}
            options={ROLE_OPTIONS}
            disableClearable
            fullWidth
            onChange={(event, value) => {
              recoverPermissionByRole(value);
            }}
            renderInput={params => (
              <TextField
                name={"role-selection"}
                {...params}
                label={t("user.role.selection")}
                className={"blue-border-input"}
                InputProps={{
                  ...params.InputProps
                }}
              />
            )}></Autocomplete>
        </Grid>
        {!errorRecoveringPermission ? (
          <>
            <AccessCheckboxForm
              accessData={accessMasterData}
              setAccessData={setAccessMasterData}
              options={Object.values(MasterDataEnum)}
              label={"masterdata"}
              role={selectedRole}
            />
            <AccessCheckboxForm
              accessData={accessReports}
              setAccessData={setAccessReports}
              options={
                userCompanyLicense === "WM_WITH_CONTAINER"
                  ? Object.values(ReportEnum)
                  : Object.values(ReportEnum).filter(report => report !== ReportEnum.RelabelReport)
              }
              label={"reports.title"}
            />
            {userCompanyLicense === "IM" && (
              <AccessCheckboxForm
                accessData={accessLogistic}
                setAccessData={setAccessLogistic}
                options={Object.values(LogisticEnum)}
                label={"logistic.title"}
              />
            )}
            <AccessCheckboxForm
              accessData={accessTrackAndTrace}
              setAccessData={setAccessTrackAndTrace}
              options={Object.values(TrackAndTraceEnum)}
              label={"track.and.trace.title"}
            />

            <Grid item id="save-button-grid" display={"flex"} justifyContent={"flex-end"}>
              <Button id={"save_settings_button"} variant={"primary"} sx={{ mt: "2rem" }} onClick={savePermissionByRole}>
                {t("button.save")}
              </Button>
            </Grid>
          </>
        ) : (
          <PersistedAlert message={t("no.user.with.role")} goTo="users" />
        )}
        <LoadingSpinnerMasterView loading={loading} />
      </Grid>
    </Container>
  );
};

export default SettingsView;
