import { register } from "./di.register";
import { ROUTER_PATHS } from "./constants";
import HomeView from "./ui/pages/home/home-view";
import { AppBase, Resource } from "@movicoders/ui";
import LoginView from "./ui/pages/login/login-view";
import HomeDetail from "./ui/pages/home/home-detail";
import { Layout } from "./ui/components/layout/layout";
import { UserRoleEnum } from "./domain/interface/User";
import ErrorView from "./ui/pages/error/error-page-view";
import ProfileView from "./ui/pages/profile/profile-view";
import SettingsView from "./ui/pages/settings/settings-view";
import Warehouse3DViewer from "./ui/pages/3d-viewer/warehouse-viewer-view";
import Detail3DViewer from "./ui/pages/3d-viewer/detail/detail-viewer-view";
import WorkInProgessView from "@pages/work-in-progress/work-in-progress-view";
import { ZonesMasterView } from "./ui/pages/master-data/zones/zones-master-view";
import { InboundsView } from "./ui/pages/logistic/entries/inbounds/inbounds-view";
import RecoverPasswordView from "./ui/pages/recover-password/recover-password-view";
import { OutboundsView } from "./ui/pages/logistic/entries/outbounds/outbounds-view";
import { ProductsView } from "./ui/pages/track-and-trace/products/main/products-view";
import FamiliesMasterView from "./ui/pages/master-data/families/families-master-view";
import { InboundsSyncView } from "./ui/pages/logistic/entries/inbounds/inbounds-sync-view";
import { AdminBoardView } from "./ui/pages/portal-admin/admin-board/admin-board-master-view";
import { WithTenantResource } from "./ui/components/with-tenant-resource/WithTenantResource";
import { MaterialsStockView } from "./ui/pages/reports/materials-stock/materials-stock-view";
import { OperatorsMasterView } from "./ui/pages/master-data/operators/operators-master-view";
import { LocationsMasterView } from "./ui/pages/master-data/locations/locations-master-view";
import { MaterialsMasterView } from "./ui/pages/master-data/materials/materials-master-view";
import { OutboundsSyncView } from "./ui/pages/logistic/entries/outbounds/outbounds-sync-view";
import { InventoryReportView } from "./ui/pages/reports/inventory-report/inventory-report-view";
import { MovementsReportView } from "./ui/pages/reports/movements-report/movements-report-view";
import { WarehousesMasterView } from "./ui/pages/master-data/warehouses/warehouses-master-view";
import { WarehouseStatusView } from "./ui/pages/track-and-trace/status/main/WarehouseStatusView";
import ProductHierarchyView from "./ui/pages/track-and-trace/products/detail/ProductHierarchyView";
import { StakeholderMasterView } from "./ui/pages/master-data/stakeholders/stakeholder-master-view";
import { InventoryMaterialView } from "./ui/pages/logistic/inventory-material/inventory-material-view";
import { UsersMasterView as UserMasterDetailView } from "./ui/pages/master-data/users/users-master-view";
import { InventoryRegisterView } from "./ui/pages/reports/inventory-register/main/inventory-register-view";
import { DashboardSearchEngineView } from "./ui/pages/home-search/components/dashboard-search-engine-view";
import { HierarchyCreationView } from "./ui/pages/track-and-trace/hierarchy-creation/HierarchyCreationView";
import TypesOfFamiliesMasterView from "./ui/pages/master-data/type-of-families/types-of-families-master-view";
import { InventoryMaterialSyncView } from "./ui/pages/logistic/inventory-material/inventory-material-sync-view";
import { RelabelReportView } from "@pages/reports/relabel-report/relabel-report-view";

const App = () => {
  return (
    <AppBase register={register} layout={<Layout />} catchAll={<ErrorView />}>
      <Resource path={ROUTER_PATHS.home} main={HomeView} detail={HomeDetail} />
      <WithTenantResource path={ROUTER_PATHS.typeOfFamilies} main={TypesOfFamiliesMasterView} />
      <WithTenantResource path={ROUTER_PATHS.materials} main={MaterialsMasterView} />
      <WithTenantResource path={ROUTER_PATHS.dashboardSearch} main={DashboardSearchEngineView} />
      <Resource path={ROUTER_PATHS.login} main={LoginView} />
      {/* Do not delete, uncomment this code if you want to see the companies view. 
      <WithTenantResource
        permissions={[UserRoleEnum.PortalAdmin, UserRoleEnum.SuperAdmin]}
        path={ROUTER_PATHS.companies}
        main={CompaniesMasterView}
      /> */}
      <WithTenantResource path={ROUTER_PATHS.locations} main={LocationsMasterView} />
      <WithTenantResource path={ROUTER_PATHS.families} main={FamiliesMasterView} />
      <WithTenantResource path={ROUTER_PATHS.warehouses} main={WarehousesMasterView} />
      <WithTenantResource path={ROUTER_PATHS.zones} main={ZonesMasterView} />
      <WithTenantResource path={ROUTER_PATHS.products} main={ProductsView} />
      <WithTenantResource path={ROUTER_PATHS.productHierarchyView} main={ProductHierarchyView} />
      <WithTenantResource path={ROUTER_PATHS.hierarchyCreation} main={HierarchyCreationView} />
      <WithTenantResource path={ROUTER_PATHS.invreport} main={InventoryReportView} />
      <WithTenantResource path={ROUTER_PATHS.stock} main={MaterialsStockView} />
      <WithTenantResource path={ROUTER_PATHS.warehouseViewer} main={Warehouse3DViewer} />
      <WithTenantResource path={ROUTER_PATHS.warehouseViewerDetails} main={Detail3DViewer} />
      <WithTenantResource path={ROUTER_PATHS.whstatus} main={WarehouseStatusView} />
      <WithTenantResource path={ROUTER_PATHS.inbounds} permissions={["SUPER_ADMIN", "PORTAL_ADMIN"]} main={InboundsView} />
      <WithTenantResource
        path={ROUTER_PATHS.inboundsSync}
        permissions={["SUPER_ADMIN", "PORTAL_ADMIN"]}
        main={InboundsSyncView}
      />
      <WithTenantResource path={ROUTER_PATHS.outbounds} permissions={["SUPER_ADMIN", "PORTAL_ADMIN"]} main={OutboundsView} />
      <WithTenantResource
        path={ROUTER_PATHS.inventoryMaterial}
        permissions={["SUPER_ADMIN", "PORTAL_ADMIN"]}
        main={InventoryMaterialView}
      />
      <WithTenantResource
        path={ROUTER_PATHS.inventoryMaterialSync}
        permissions={["SUPER_ADMIN", "PORTAL_ADMIN"]}
        main={InventoryMaterialSyncView}
      />
      <WithTenantResource
        path={ROUTER_PATHS.outboundsSync}
        permissions={["SUPER_ADMIN", "PORTAL_ADMIN"]}
        main={OutboundsSyncView}
      />
      <WithTenantResource path={ROUTER_PATHS.movreport} main={MovementsReportView} />
      <WithTenantResource path={ROUTER_PATHS.customers} main={StakeholderMasterView} />
      <WithTenantResource path={ROUTER_PATHS.manufacturers} main={StakeholderMasterView} />
      <WithTenantResource path={ROUTER_PATHS.owners} main={StakeholderMasterView} />
      <WithTenantResource path={ROUTER_PATHS.suppliers} main={StakeholderMasterView} />
      <WithTenantResource path={ROUTER_PATHS.operators} main={OperatorsMasterView} />
      <Resource path={ROUTER_PATHS.adminBoard} main={AdminBoardView} />
      <WithTenantResource
        permissions={[UserRoleEnum.PortalAdmin, UserRoleEnum.SuperAdmin]}
        path={ROUTER_PATHS.users}
        main={UserMasterDetailView}
      />
      <WithTenantResource path={ROUTER_PATHS.invregistry} main={InventoryRegisterView} />
      <WithTenantResource path={ROUTER_PATHS.relabelreport} main={RelabelReportView} />
      <Resource path={ROUTER_PATHS.profile} main={ProfileView} />
      <Resource path={ROUTER_PATHS.recoverPassword} main={RecoverPasswordView} />
      <Resource path={ROUTER_PATHS.recommendUbication} main={<WorkInProgessView />} />
      <Resource path={ROUTER_PATHS.downloadApk} main={<WorkInProgessView />} />
      <Resource path={ROUTER_PATHS.settings} main={<SettingsView />} />
    </AppBase>
  );
};

export default App;
