/* eslint-disable max-statements */
import { useResolve } from "@movicoders/di";
import { GridColDef } from "@mui/x-data-grid";
import { SelectChangeEvent } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { LastPageState } from "@domain/model/LastPageState";
import { ToolbarFilter } from "../../../config/data-grid-display-status";
import { useHandleFilterValidations } from "./useHandleFilterValidations";
import { ILastPageService, LastPageService } from "@domain/services/ILastPageService";

export const useHandleFilter = (
  columns: GridColDef[],
  disabledFilters: string[],
  isTable: boolean,
  setCurrentFilter: (newFilter: ToolbarFilter) => void
) => {
  const [selectedField, setSelectedField] = useState(columns.filter(col => !disabledFilters.includes(col.field))[0].field || "");
  const [selectedType, setSelectedType] = useState(columns.filter(col => !disabledFilters.includes(col.field))[0].type || "");
  const [selectedColumn, setSelectedColumn] = useState(columns.find(column => column.field === selectedField));
  const [currentValue, setCurrentValue] = useState<string>("");
  const [selectOneValue, setSelectOneValue] = useState("");
  const [multipleSelectedValues, setMultipleSelectedValues] = useState<string[]>([]);
  const [dateEntry, setDateEntry] = useState<string>("");
  const [dateFinal, setDateFinal] = useState<string>("");
  const [isViewInitialized, setIsViewInitialized] = useState(true);
  const [validColumnsLoaded, setValidColumnsLoaded] = useState(false);
  const [valuesSelectUnfolded, setValuesSelectUnfolded] = useState(false);

  /* Block Autocomplete-one */
  const lastPageService = useResolve<ILastPageService>(LastPageService);
  const lastPagePersistedState = lastPageService.persistedState ?? new LastPageState();
  const [masterdataPlaceholder, setMasterdataPlaceholder] = useState<string>("");
  /* End block Autocomplete-one */

  // Defines if the menu is open or closed and its position.
  const [anchorElFilterMenu, setAnchorElFilterMenu] = useState<null | HTMLElement>(null);
  const openFilterMenu = Boolean(anchorElFilterMenu);

  const { dateEntryErrorText, dateFinalErrorText, filterButtonIsDisabled, setFilterButtonIsDisabled, checkInitDateBelowFinal } =
    useHandleFilterValidations({
      dateEntry: dateEntry,
      dateFinal: dateFinal,
      selectedType: selectedType
    });

  //Sets correct input-type depending the first option on table-filter-menu-select (first load)
  useEffect(() => {
    setSelectedColumn(columns.find(column => column.field === selectedField));
  }, [columns]);

  const handleOpenFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElFilterMenu(event.currentTarget);
  };

  const handleCloseFilterMenu = () => {
    setAnchorElFilterMenu(null);
  };

  const handleChangeField = (e: SelectChangeEvent<string>) => {
    setSelectedField(e.target.value);
    handleChangeType(e.target.value);
    setSelectedColumn(columns.find(column => column.field === e.target.value));
    if (e.target.value === "date" || e.target.value === "created" || e.target.value === "modified") {
      setFilterButtonIsDisabled(true);
    } else {
      setFilterButtonIsDisabled(false);
    }
  };

  const handleChangeType = (field: string) => {
    cleanFilterText();
    const column = columns.find(col => col.field === field);
    setSelectedType(column?.type ?? "");
  };

  const handleChangeValue = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCurrentValue(e.target.value);
  };

  const handleChangeSelectOneValues = (event: SelectChangeEvent<unknown>) => {
    setSelectOneValue(event.target.value as string);
  };

  const handleChangeMultipleValues = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value }
    } = event;

    setMultipleSelectedValues(typeof value === "string" ? value.split(",") : (value as string[]));
  };

  /* Block Autocomplete-one */
  const handleChangeAutocompleteOneValue = (masterdataSelected: string) => {
    setMasterdataPlaceholder(masterdataSelected);
    setSelectOneValue(masterdataSelected);
  };

  const DEBOUNCE_TIME = 1000;
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //will disable search by filter button until the search of materials is done (even if there is no results)
    selectedType === "autocomplete-one" && setFilterButtonIsDisabled(true);
    setMasterdataPlaceholder(event.target.value);
    setSelectOneValue(event.target.value);
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    const newDebounceTimer = setTimeout(() => {
      if (!lastPagePersistedState.autocompleteFilterLoading) {
        lastPageService.saveLastPageState({
          ...lastPagePersistedState,
          autocompleteFilterText: event.target.value
        });
      }
    }, DEBOUNCE_TIME);
    setDebounceTimer(newDebounceTimer);
  };
  /* End block Autocomplete-one */

  const handleSubmitFilter = () => {
    if (currentValue !== "" && selectedType !== "multiple-select") {
      setCurrentFilter({
        field: selectedField,
        value: currentValue,
        isFilterActive: true
      });
    } else if (multipleSelectedValues.length !== 0) {
      setCurrentFilter({
        field: selectedField,
        value: multipleSelectedValues,
        isFilterActive: true
      });
    } else if (selectedType === "range-date") {
      setCurrentFilter({
        field: selectedField,
        value: [dateEntry, dateFinal],
        isFilterActive: true
      });
    } else if (selectedType === "select-one" || selectedType === "autocomplete-one") {
      setCurrentFilter({
        field: selectedField,
        value: selectOneValue,
        isFilterActive: true
      });
    } else {
      handleClearFilter();
    }
    handleCloseFilterMenu();
    lastPageService.saveLastPageState({ ...lastPagePersistedState, autocompleteFilterText: undefined });
  };

  const cleanFilterText = () => {
    setCurrentValue("");
    setDateEntry("");
    setDateFinal("");
    setSelectOneValue("");
    setMultipleSelectedValues([]);
    setMasterdataPlaceholder("");
    lastPageService.saveLastPageState({ ...lastPagePersistedState, autocompleteFilterText: undefined });
  };

  useEffect(() => {
    setValidColumnsLoaded(true);
  }, [columns, disabledFilters]);

  useEffect(() => {
    setSelectedField(columns.filter(column => !disabledFilters.includes(column.field))[0].field || "");
    setSelectedType(columns.filter(column => !disabledFilters.includes(column.field))[0].type || "");
  }, [validColumnsLoaded]);

  const handleClearFilter = () => {
    if (!isViewInitialized || isTable) {
      setCurrentFilter({ field: "", value: "", isFilterActive: false });
      cleanFilterText();
      setSelectedField(columns.filter(column => !disabledFilters.includes(column.field))[0].field || "");
      setSelectedType(columns.filter(column => !disabledFilters.includes(column.field))[0].type || "");
      setFilterButtonIsDisabled(false);
      setSelectedColumn(columns.find(column => column.field !== "date"));
    }

    lastPageService.saveLastPageState({
      limit: lastPageService.persistedState?.limit ?? 10,
      offset: 0,
      fullDTO: false,
      status: "ALL",
      filters: undefined,
      sortDirection: lastPageService.persistedState?.sortDirection ?? "DESC",
      sortField: lastPageService.persistedState?.sortField ?? ""
    });
  };

  const handleToggleSelectOpened = () => {
    setValuesSelectUnfolded(prevIsOptionsOpen => !prevIsOptionsOpen);
  };
  useEffect(() => {
    handleClearFilter();
    setIsViewInitialized(false);
    lastPageService.saveLastPageState({
      ...lastPagePersistedState,
      autocompleteFilterLoading: false,
      autocompleteFilterText: undefined
    });
  }, []);

  //Button is disabled when results for autocomplete are loading
  useEffect(() => {
    setFilterButtonIsDisabled(lastPageService.persistedState?.autocompleteFilterLoading ?? false);
  }, [lastPageService.persistedState?.autocompleteFilterLoading]);

  return {
    openFilterMenu,
    anchorElFilterMenu,
    handleOpenFilterMenu,
    handleChangeValue,
    handleToggleSelectOpened,
    handleSubmitFilter,
    dateEntryErrorText,
    dateFinalErrorText,
    filterButtonIsDisabled,
    selectedField,
    checkInitDateBelowFinal,
    handleChangeSelectOneValues,
    handleChangeMultipleValues,
    masterdataPlaceholder,
    handleChangeAutocompleteOneValue,
    handleSearchInputChange,
    handleChangeField,
    selectedColumn,
    currentValue,
    selectOneValue,
    multipleSelectedValues,
    dateEntry,
    dateFinal,
    valuesSelectUnfolded,
    setDateEntry,
    setDateFinal,
    handleCloseFilterMenu,
    handleClearFilter,
    lastPagePersistedState
  };
};
