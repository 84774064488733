import {
  Paper,
  Typography,
  Tooltip,
  Zoom,
  LinearProgress,
  linearProgressClasses,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Divider,
  Button
} from "@mui/material";
import { SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { ROUTER_PATHS } from "../../../../../constants";
import Icon from "../../../../components/icons/icon";
import { CAPACITY_COLORS, DEFAULT_COLORS } from "../../constants/Colors";
import { Location3D } from "../../types/Location3D";
import { getShorterBinCode } from "../../utils/bin-helper";
import { ContainerDTO } from "../../../../../clients/aggrego-proxy";

const DetailDialog = (props: { selectedBins: Location3D[]; open: boolean; setOpen: (value: boolean) => void }) => {
  const { selectedBins, open, setOpen } = props;
  const [expanded, setExpanded] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleCloseDialog = () => {
    setExpanded("");
    setOpen(false);
  };

  // Handler to change the currently open accordion.
  const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : "");
  };

  const handleGoToMovements = (container: ContainerDTO) => {
    const searchObjectJson = JSON.stringify(container);
    const encodedComponent = encodeURIComponent(searchObjectJson);
    navigate(ROUTER_PATHS.movreport + "?search=" + encodedComponent);
  };

  const getColorByPercentage = (percentage: number) => {
    if (percentage === 0) return CAPACITY_COLORS.green;
    else if (percentage < 100) return CAPACITY_COLORS.yellow;
    else return CAPACITY_COLORS.red;
  };

  return (
    <Paper
      sx={{
        height: "100vh",
        width: "30vw",
        display: open ? "block" : "none",
        position: "absolute",
        right: 0,
        overflowY: "scroll",
        textAlign: "start"
      }}>
      {selectedBins?.map(selectedBin => {
        const fillPercentage = ((selectedBin?.containers?.length ?? 0) / (selectedBin?.capacity ?? 0)) * 100;
        const color = getColorByPercentage(fillPercentage);

        return (
          <div key={selectedBin.id}>
            <Typography ml={2} my="1rem" variant="h5">
              {getShorterBinCode(selectedBin, 40)}
            </Typography>
            <Typography ml={2}>
              {t("viewer.details.bin.level")} {selectedBin?.level ?? ""}
            </Typography>
            <Typography ml={2}>
              {t("viewer.details.bin.capacity")} {selectedBin?.capacity ?? ""}
            </Typography>

            {isNaN(fillPercentage) && (
              <Typography ml={2} mt={1} color={color}>
                {t("viewer.details.capacity.undefined")}
              </Typography>
            )}

            {!isNaN(fillPercentage) && (
              <>
                <Typography ml={2} mt={1} color={color}>
                  {Math.round(fillPercentage * 100) / 100}% full
                </Typography>
                <Tooltip
                  title={"" + selectedBin?.containers?.length + " / " + selectedBin?.capacity}
                  TransitionComponent={Zoom}
                  followCursor={true}>
                  <LinearProgress
                    sx={{
                      backgroundColor: DEFAULT_COLORS.grey,
                      height: 10,
                      mx: "1rem",
                      mb: "2rem",
                      mt: "5px",
                      [`& .${linearProgressClasses.bar}`]: {
                        backgroundColor: color
                      }
                    }}
                    variant="determinate"
                    value={fillPercentage}
                  />
                </Tooltip>
              </>
            )}

            <Grid container direction="column" px="1rem" rowGap={2}>
              {selectedBin?.containers?.map(container => {
                return (
                  <Accordion
                    key={container.id}
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={expanded === container?.id}
                    onChange={handleChange(container?.id ?? "")}>
                    <AccordionSummary>
                      {expanded === container?.id ? <Icon icon="chevron-down" /> : <Icon icon="chevron-right" />}
                      <Typography>{container.code}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item display="flex" flexDirection="column" alignItems="flex-start">
                        <Grid container justifyContent="space-between">
                          <Typography>{t("viewer.details.container.content")} </Typography>
                          <IconButton
                            onClick={e => {
                              e.stopPropagation();
                              handleGoToMovements(container);
                            }}
                            sx={{ p: 0 }}>
                            <Icon icon="search" />
                          </IconButton>
                        </Grid>
                        <Typography>
                          {container.qty} {container.uom} {t("viewer.details.of")} {container.material}
                        </Typography>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Grid>
            {selectedBins.length > 1 && <Divider sx={{ mt: 2 }} />}
          </div>
        );
      })}
      <Grid container direction="column" px="1rem" rowGap={2} my="1rem">
        <Button onClick={handleCloseDialog}>{t("viewer.details.close")}</Button>
      </Grid>
    </Paper>
  );
};

export default DetailDialog;
