import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyDTO } from "@clients/aggrego-proxy";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";
import { ButtonsDetail } from "@components/master-data-view/buttons/buttons-detail";

export const AdminBoardActivationDialog = (props: {
  open: boolean;
  onClose: () => void;
  onActive: (company: CompanyDTO) => void;
  company: CompanyDTO;
}) => {
  const { t } = useTranslation();
  const { open, onClose, onActive, company } = props;
  const [pendingRequest, setPendingRequest] = useState<boolean>(false);

  useEffect(() => {
    setPendingRequest(false);
  }, [company]);

  return open ? (
    <AggregoDialog
      title={t(!company.active ? "admin.board.deactivate.title" : "admin.board.activate.title", { company: company.name })}
      onClose={onClose}>
      <Grid container flexDirection="column" className="masterdata-box" rowSpacing={2}>
        <Grid item>
          <div className="confirm-dialog-div-content">
            {t(!company.active ? "admin.board.deactivate.text" : "admin.board.activate.text")}
          </div>
        </Grid>
        <Grid item>
          <ButtonsDetail
            handleClose={onClose}
            handleSave={() => {
              setPendingRequest(true);
              onActive(company);
            }}
            mode={!company.active ? "ACTIVE" : "INACTIVE"}
            pendingRequest={pendingRequest}
          />
        </Grid>
      </Grid>
    </AggregoDialog>
  ) : (
    <></>
  );
};
