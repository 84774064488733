import { useState } from "react";
import { useResolve } from "@movicoders/di";
import { DrawerState } from "@domain/model/DrawerState";
import { ISubmenusConf } from "../interfaces/drawer-interfaces";
import { FullUserDTOCompanyLicenseEnum } from "@clients/aggrego-proxy";
import { IUserService, UserService } from "@domain/services/IUserService";
import { DRAWER_OPEN_WIDTH, DRAWER_CLOSE_WIDTH } from "../drawer-constants";
import { DrawerService, IDrawerService } from "@domain/services/IDrawerService";
import { IsValidImpersonationUseCase } from "@application/impersonation/is-valid-impersonation-use-case";
import { drawerLogistic, drawerMasterData, drawerReports, drawerTrackAndTrace } from "../drawer-paths-configuration";
import { useSettings } from "@hooks/useSettings";

export const useDrawerViewModel = () => {
  const service = useResolve<IDrawerService>(DrawerService);
  const persistedState = service.getPersistedState() ?? new DrawerState();
  const { drawer, masterdataMenu, logiscticMenu, reportsMenu, trackAndTraceMenu } = persistedState;

  const { logisticPermissions, masterDataPermissions, reportPermissions, trackAndTracePermissions } = useSettings();
  const userService = useResolve<IUserService>(UserService);
  const userCompanyLicense = (userService.user?.companyLicense as FullUserDTOCompanyLicenseEnum) ?? undefined;

  const isValidImpersonationUseCase = useResolve(IsValidImpersonationUseCase);

  const [collapsedStatus, setCollapsedStatus] = useState({
    isDrawerOpen: drawer,
    isMasterDataOpen: masterdataMenu,
    isLogisticOpen: logiscticMenu,
    isReportsOpen: reportsMenu,
    isTrackAndTraceOpen: trackAndTraceMenu
  });
  const DRAWER_WIDTH = collapsedStatus.isDrawerOpen ? DRAWER_OPEN_WIDTH : DRAWER_CLOSE_WIDTH;

  const handleDrawerOpen = () => {
    service.saveDrawerState({ ...persistedState, drawer: !collapsedStatus.isDrawerOpen });
    setCollapsedStatus({ ...collapsedStatus, isDrawerOpen: !collapsedStatus.isDrawerOpen });
  };

  const onChangeState = (isColapsedOpen: boolean, collapsed: "MASTER-DATA" | "REPORTS" | "LOGISTIC" | "TRACK-AND-TRACE") => {
    const isOpen = collapsedStatus.isDrawerOpen ? !isColapsedOpen : true;
    setCollapsedStatus({
      ...collapsedStatus,
      isMasterDataOpen: collapsed === "MASTER-DATA" ? isOpen : false,
      isReportsOpen: collapsed === "REPORTS" ? isOpen : false,
      isLogisticOpen: collapsed === "LOGISTIC" ? isOpen : false,
      isTrackAndTraceOpen: collapsed === "TRACK-AND-TRACE" ? isOpen : false,
      isDrawerOpen: true
    });

    service.saveDrawerState({
      ...persistedState,
      masterdataMenu: collapsed === "MASTER-DATA" ? isOpen : false,
      reportsMenu: collapsed === "REPORTS" ? isOpen : false,
      trackAndTraceMenu: collapsed === "TRACK-AND-TRACE" ? isOpen : false,
      logiscticMenu: collapsed === "LOGISTIC" ? isOpen : false
    });
  };

  const masterdataSubmenuConf: ISubmenusConf = {
    drawerWidth: DRAWER_WIDTH,
    handleOpen: handleDrawerOpen,
    iconName: "master-data",
    isDrawerOpen: collapsedStatus.isDrawerOpen,
    menuId: "drawer-master-data",
    menuOpen: collapsedStatus.isMasterDataOpen,
    onChange: () => onChangeState(collapsedStatus.isMasterDataOpen, "MASTER-DATA"),
    paths: drawerMasterData(userCompanyLicense),
    tooltipKey: "master.data",
    settingsArray: masterDataPermissions
  };

  const reportsSubmenuConf: ISubmenusConf = {
    drawerWidth: DRAWER_WIDTH,
    handleOpen: handleDrawerOpen,
    iconName: "reports",
    isDrawerOpen: collapsedStatus.isDrawerOpen,
    menuId: "drawer-reports",
    menuOpen: collapsedStatus.isReportsOpen,
    onChange: () => onChangeState(collapsedStatus.isReportsOpen, "REPORTS"),
    paths: drawerReports(userCompanyLicense),
    tooltipKey: "reports",
    settingsArray: reportPermissions
  };

  const logisticSubmenuConf: ISubmenusConf = {
    drawerWidth: DRAWER_WIDTH,
    handleOpen: handleDrawerOpen,
    iconName: "box",
    isDrawerOpen: collapsedStatus.isDrawerOpen,
    menuId: "drawer-logistic",
    menuOpen: collapsedStatus.isLogisticOpen,
    onChange: () => onChangeState(collapsedStatus.isLogisticOpen, "LOGISTIC"),
    paths: drawerLogistic(userCompanyLicense),
    tooltipKey: "logistic",
    settingsArray: logisticPermissions
  };

  const trackAndTraceSubmenuConf: ISubmenusConf = {
    drawerWidth: DRAWER_WIDTH,
    handleOpen: handleDrawerOpen,
    iconName: "track",
    isDrawerOpen: collapsedStatus.isDrawerOpen,
    menuId: "drawer-track-and-trace",
    menuOpen: collapsedStatus.isTrackAndTraceOpen,
    onChange: () => onChangeState(collapsedStatus.isTrackAndTraceOpen, "TRACK-AND-TRACE"),
    paths: drawerTrackAndTrace(userCompanyLicense),
    tooltipKey: "track.and.trace",
    settingsArray: trackAndTracePermissions
  };

  return {
    masterdataSubmenuConf,
    reportsSubmenuConf,
    trackAndTraceSubmenuConf,
    logisticSubmenuConf,
    isDrawerOpen: collapsedStatus.isDrawerOpen,
    handleDrawerOpen,
    service,
    DRAWER_WIDTH,
    persistedState,
    isValidImpersonationUseCase
  };
};
