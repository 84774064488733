/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  CustomFamilyFilter,
  FamilyDTO,
  FamilyPageDTO,
  ListResponseDTO,
  MovicodersBadRequestException,
  MovicodersNotFoundException,
  MovicodersServiceException
} from "../models";
import {
  CustomFamilyFilterFromJSON,
  CustomFamilyFilterToJSON,
  FamilyDTOFromJSON,
  FamilyDTOToJSON,
  FamilyPageDTOFromJSON,
  FamilyPageDTOToJSON,
  ListResponseDTOFromJSON,
  ListResponseDTOToJSON,
  MovicodersBadRequestExceptionFromJSON,
  MovicodersBadRequestExceptionToJSON,
  MovicodersNotFoundExceptionFromJSON,
  MovicodersNotFoundExceptionToJSON,
  MovicodersServiceExceptionFromJSON,
  MovicodersServiceExceptionToJSON
} from "../models";

export interface DeleteFamiliesRequest {
  xTenantId?: string;
  requestBody?: Array<string>;
}

export interface DeleteFamilyRequest {
  id: string;
  xTenantId?: string;
}

export interface GetFamiliesByNameRequest {
  name: string;
  xTenantId?: string;
}

export interface GetFamilyByIdRequest {
  id: string;
  fullDTO: boolean;
  xTenantId?: string;
}

export interface GetFamilyByIdsRequest {
  xTenantId?: string;
  requestBody?: Array<string>;
}

export interface ListFamiliesRequest {
  fullDTO: boolean;
  status: ListFamiliesStatusEnum;
  xTenantId?: string;
}

export interface ListFamiliesByTenantIdRequest {
  fullDTO: boolean;
  tenantId: string;
  status: ListFamiliesByTenantIdStatusEnum;
  xTenantId?: string;
}

export interface ListFamiliesPaginatedRequest {
  limit: number;
  offset: number;
  xTenantId?: string;
  sortField?: ListFamiliesPaginatedSortFieldEnum;
  sortDirection?: ListFamiliesPaginatedSortDirectionEnum;
  customFamilyFilter?: CustomFamilyFilter;
}

export interface SaveFamiliesRequest {
  xTenantId?: string;
  familyDTO?: Array<FamilyDTO>;
}

export interface SaveFamilyRequest {
  xTenantId?: string;
  familyDTO?: FamilyDTO;
}

export interface UpdateFamilyRequest {
  xTenantId?: string;
  familyDTO?: FamilyDTO;
}

/**
 *
 */
export class FamiliesApi extends runtime.BaseAPI {
  /**
   * Returns the deleted Families
   */
  async deleteFamiliesRaw(
    requestParameters: DeleteFamiliesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<FamilyDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/families/delete-many`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(FamilyDTOFromJSON));
  }

  /**
   * Returns the deleted Families
   */
  async deleteFamilies(
    requestParameters: DeleteFamiliesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<FamilyDTO>> {
    const response = await this.deleteFamiliesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Takes an existing family, deletes it, and returns the new object.
   * Delete an existing family by id.
   */
  async deleteFamilyRaw(
    requestParameters: DeleteFamilyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<FamilyDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteFamily."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/families/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => FamilyDTOFromJSON(jsonValue));
  }

  /**
   * Takes an existing family, deletes it, and returns the new object.
   * Delete an existing family by id.
   */
  async deleteFamily(
    requestParameters: DeleteFamilyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<FamilyDTO> {
    const response = await this.deleteFamilyRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns a list of families that contains the provided name.
   * Get a family objects by name.
   */
  async getFamiliesByNameRaw(
    requestParameters: GetFamiliesByNameRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<FamilyDTO>>> {
    if (requestParameters.name === null || requestParameters.name === undefined) {
      throw new runtime.RequiredError(
        "name",
        "Required parameter requestParameters.name was null or undefined when calling getFamiliesByName."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.name !== undefined) {
      queryParameters["name"] = requestParameters.name;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/families/name`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(FamilyDTOFromJSON));
  }

  /**
   * Returns a list of families that contains the provided name.
   * Get a family objects by name.
   */
  async getFamiliesByName(
    requestParameters: GetFamiliesByNameRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<FamilyDTO>> {
    const response = await this.getFamiliesByNameRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns one family object by id.
   * Get a family object by id.
   */
  async getFamilyByIdRaw(
    requestParameters: GetFamilyByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<FamilyDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getFamilyById."
      );
    }

    if (requestParameters.fullDTO === null || requestParameters.fullDTO === undefined) {
      throw new runtime.RequiredError(
        "fullDTO",
        "Required parameter requestParameters.fullDTO was null or undefined when calling getFamilyById."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fullDTO !== undefined) {
      queryParameters["fullDTO"] = requestParameters.fullDTO;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/families/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => FamilyDTOFromJSON(jsonValue));
  }

  /**
   * Returns one family object by id.
   * Get a family object by id.
   */
  async getFamilyById(
    requestParameters: GetFamilyByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<FamilyDTO> {
    const response = await this.getFamilyByIdRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns family objects by ids.
   * Get a family object by id.
   */
  async getFamilyByIdsRaw(
    requestParameters: GetFamilyByIdsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<FamilyDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/families/find-many-by-id`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(FamilyDTOFromJSON));
  }

  /**
   * Returns family objects by ids.
   * Get a family object by id.
   */
  async getFamilyByIds(
    requestParameters: GetFamilyByIdsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<FamilyDTO>> {
    const response = await this.getFamilyByIdsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered Families
   */
  async listFamiliesRaw(
    requestParameters: ListFamiliesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<FamilyDTO>>> {
    if (requestParameters.fullDTO === null || requestParameters.fullDTO === undefined) {
      throw new runtime.RequiredError(
        "fullDTO",
        "Required parameter requestParameters.fullDTO was null or undefined when calling listFamilies."
      );
    }

    if (requestParameters.status === null || requestParameters.status === undefined) {
      throw new runtime.RequiredError(
        "status",
        "Required parameter requestParameters.status was null or undefined when calling listFamilies."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fullDTO !== undefined) {
      queryParameters["fullDTO"] = requestParameters.fullDTO;
    }

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/families`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(FamilyDTOFromJSON));
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered Families
   */
  async listFamilies(
    requestParameters: ListFamiliesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<FamilyDTO>> {
    const response = await this.listFamiliesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Return families by tenantId.
   * Returns the list of all registered families by tenantId
   */
  async listFamiliesByTenantIdRaw(
    requestParameters: ListFamiliesByTenantIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<FamilyDTO>>> {
    if (requestParameters.fullDTO === null || requestParameters.fullDTO === undefined) {
      throw new runtime.RequiredError(
        "fullDTO",
        "Required parameter requestParameters.fullDTO was null or undefined when calling listFamiliesByTenantId."
      );
    }

    if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
      throw new runtime.RequiredError(
        "tenantId",
        "Required parameter requestParameters.tenantId was null or undefined when calling listFamiliesByTenantId."
      );
    }

    if (requestParameters.status === null || requestParameters.status === undefined) {
      throw new runtime.RequiredError(
        "status",
        "Required parameter requestParameters.status was null or undefined when calling listFamiliesByTenantId."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fullDTO !== undefined) {
      queryParameters["fullDTO"] = requestParameters.fullDTO;
    }

    if (requestParameters.tenantId !== undefined) {
      queryParameters["tenantId"] = requestParameters.tenantId;
    }

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/families/findByTenantId`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(FamilyDTOFromJSON));
  }

  /**
   * Return families by tenantId.
   * Returns the list of all registered families by tenantId
   */
  async listFamiliesByTenantId(
    requestParameters: ListFamiliesByTenantIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<FamilyDTO>> {
    const response = await this.listFamiliesByTenantIdRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered Families paginated
   */
  async listFamiliesPaginatedRaw(
    requestParameters: ListFamiliesPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<FamilyPageDTO>> {
    if (requestParameters.limit === null || requestParameters.limit === undefined) {
      throw new runtime.RequiredError(
        "limit",
        "Required parameter requestParameters.limit was null or undefined when calling listFamiliesPaginated."
      );
    }

    if (requestParameters.offset === null || requestParameters.offset === undefined) {
      throw new runtime.RequiredError(
        "offset",
        "Required parameter requestParameters.offset was null or undefined when calling listFamiliesPaginated."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sortField"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sortDirection"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/families/paginated`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CustomFamilyFilterToJSON(requestParameters.customFamilyFilter)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => FamilyPageDTOFromJSON(jsonValue));
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered Families paginated
   */
  async listFamiliesPaginated(
    requestParameters: ListFamiliesPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<FamilyPageDTO> {
    const response = await this.listFamiliesPaginatedRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the registered Families
   */
  async saveFamiliesRaw(
    requestParameters: SaveFamiliesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ListResponseDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/families/add-many`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.familyDTO?.map(FamilyDTOToJSON)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ListResponseDTOFromJSON(jsonValue));
  }

  /**
   * Returns the registered Families
   */
  async saveFamilies(
    requestParameters: SaveFamiliesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ListResponseDTO> {
    const response = await this.saveFamiliesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the registered Family
   */
  async saveFamilyRaw(
    requestParameters: SaveFamilyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<FamilyDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/families`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: FamilyDTOToJSON(requestParameters.familyDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => FamilyDTOFromJSON(jsonValue));
  }

  /**
   * Returns the registered Family
   */
  async saveFamily(
    requestParameters: SaveFamilyRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<FamilyDTO> {
    const response = await this.saveFamilyRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Takes an existing Family object, updates it, and returns the Family.
   * Update an existing Family.
   */
  async updateFamilyRaw(
    requestParameters: UpdateFamilyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<FamilyDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/families`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: FamilyDTOToJSON(requestParameters.familyDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => FamilyDTOFromJSON(jsonValue));
  }

  /**
   * Takes an existing Family object, updates it, and returns the Family.
   * Update an existing Family.
   */
  async updateFamily(
    requestParameters: UpdateFamilyRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<FamilyDTO> {
    const response = await this.updateFamilyRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

/**
 * @export
 */
export const ListFamiliesStatusEnum = {
  Active: "ACTIVE",
  All: "ALL",
  Inactive: "INACTIVE"
} as const;
export type ListFamiliesStatusEnum = (typeof ListFamiliesStatusEnum)[keyof typeof ListFamiliesStatusEnum];
/**
 * @export
 */
export const ListFamiliesByTenantIdStatusEnum = {
  Active: "ACTIVE",
  All: "ALL",
  Inactive: "INACTIVE"
} as const;
export type ListFamiliesByTenantIdStatusEnum =
  (typeof ListFamiliesByTenantIdStatusEnum)[keyof typeof ListFamiliesByTenantIdStatusEnum];
/**
 * @export
 */
export const ListFamiliesPaginatedSortFieldEnum = {
  Familytype: "FAMILYTYPE",
  Name: "NAME",
  Status: "STATUS"
} as const;
export type ListFamiliesPaginatedSortFieldEnum =
  (typeof ListFamiliesPaginatedSortFieldEnum)[keyof typeof ListFamiliesPaginatedSortFieldEnum];
/**
 * @export
 */
export const ListFamiliesPaginatedSortDirectionEnum = {
  Asc: "ASC",
  Desc: "DESC"
} as const;
export type ListFamiliesPaginatedSortDirectionEnum =
  (typeof ListFamiliesPaginatedSortDirectionEnum)[keyof typeof ListFamiliesPaginatedSortDirectionEnum];
