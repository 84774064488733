import { useState } from "react";
import Zone from "@domain/model/Zone";
import { useResolve } from "@movicoders/di";
import { LastPageState } from "@domain/model/LastPageState";
import MasterDataConstants from "../../../MasterDataConstants";
import { ListZonesPaginatedRequest } from "@clients/aggrego-proxy";
import { ILastPageService } from "@domain/services/ILastPageService";
import { useMasterDataMessagges } from "@hooks/useMasterDataMessages";
import { ZoneUpdateStatusUseCase } from "@application/master-data/zones/zone-update-status-use-case";

export const useZoneActiveStatus = (
  currentPageService: ILastPageService,
  currentPagePersistedState: LastPageState,
  selectedWarehouse: string,
  getCurrentStatus: (status: "ALL" | "ACTIVE" | "INACTIVE") => boolean | undefined,
  getZonesPaginated: (criteria: ListZonesPaginatedRequest) => Promise<void>
) => {
  const [isActivating, setIsActivating] = useState(false);
  const [selectedZone4Activation, setSelectedZone4Activation] = useState<Zone | undefined>(undefined);
  const { successMessaggesStatus, errorMessaggesStatus } = useMasterDataMessagges();

  const zoneUpdateStatusUseCase = useResolve(ZoneUpdateStatusUseCase);

  const onCloseDialog = () => {
    setIsActivating(false);
  };

  const handleOpenToggleActiveStatusDialog = (zone: Zone) => {
    setIsActivating(!isActivating);
    setSelectedZone4Activation(zone);
  };

  const toggleZoneStatus = (zone: Zone) => {
    const promise = zoneUpdateStatusUseCase.execute(zone);
    promise
      .then(() => successMessaggesStatus("zone", zone.active, zone.name))
      .catch(error => errorMessaggesStatus("zone", zone.active, zone.name, error))
      .finally(() => {
        setIsActivating(false);
        getZonesPaginated({
          limit: MasterDataConstants.ITEMS_PER_PAGE,
          offset: currentPageService.persistedState?.offset ?? 0,
          customZoneFilter: {
            status: getCurrentStatus(currentPagePersistedState.status),
            ...currentPagePersistedState.filters,
            warehouseId: selectedWarehouse
          }
        });
      });
  };

  return {
    isActivating,
    selectedZone4Status: selectedZone4Activation,
    onCloseDialog,
    toggleZoneStatus,
    handleOpenToggleActiveStatusDialog
  };
};
