import { SxProps, Theme } from "@mui/material";

const drawer_paper: SxProps<Theme> = {
  cursor: "pointer",
  zIndex: 10000,
  top: 0,
  left: 0,
  height: "100%",
  position: "fixed",
  boxShadow: "5px 1px 8px 0px rgba(0, 0, 0, 0.28)",
  WebkitBoxShadow: "5px 1px 8px 0px rgba(0, 0, 0, 0.28)",
  borderRadius: "unset",
  display: "flex",
  flexDirection: "column"
};

export const drawerStyles = {
  drawer_paper
};
