import { useTranslation } from "react-i18next";
import Warehouse from "@domain/model/Warehouse";
import { TextField, Grid } from "@mui/material";
import { CREATE_ID, TIMEOUT_ID } from "@constants";
import React, { ChangeEvent, useEffect, useState } from "react";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";
import { ButtonsDetail } from "@components/master-data-view/buttons/buttons-detail";
import { LoadingSpinnerDetailView } from "@components/loading-circular-progress/loading-spinner-detail-view";
import { useMaterialsStockViewModel } from "../../reports/materials-stock/hooks/viewmodel/useMaterialsStockViewModel";

interface IDetail {
  warehouse: Warehouse;
  loading: boolean;
  onClose: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeNumeric: (event: ChangeEvent<HTMLInputElement>) => void;
  onSave: (warehouse: Warehouse) => Promise<void>;
}

const WarehousesDetailView = (props: IDetail) => {
  const { warehouse, loading, onClose, onChange, onChangeNumeric, onSave } = props;
  const { t } = useTranslation();
  const [showErrors, setShowErrors] = useState(false);

  const { getConfigStock, setConfigStock } = useMaterialsStockViewModel();
  const [stockHours, setStockHours] = useState(2);
  const [pendingRequest, setPendingRequest] = useState<boolean>(false);

  const setterHours = async () => {
    try {
      setStockHours((await getConfigStock(warehouse.code ?? "")) ?? 2);
    } catch (e) {
      setStockHours(2);
    }
  };

  useEffect(() => {
    warehouse.code && setterHours();
  }, [warehouse.code]);

  useEffect(() => {
    setShowErrors(false);
    setPendingRequest(false);
  }, [warehouse]);

  const isNotValid = (value: string | number | undefined): boolean => {
    return value?.toString().trim() === "" || value?.toString().trim() === "0" || Number(value) <= 0 || value === undefined;
  };

  const isNotFrequencyValid = (value: number): boolean => {
    return value < 2 || value > 25;
  };

  const disabled = warehouse.active !== undefined && warehouse.active === false;

  const renderInput = (id: string, name: string, value: string | number, label: string, type?: string, isDisabled?: boolean) => {
    const error = isNotValid(value) && showErrors;
    const realValue = type === "number" && value !== "" ? Math.round(Number(value)) : value;
    return (
      <TextField
        error={error}
        helperText={error ? t(`warehouse.dialog.empty.field.${type ?? "text"}`) : ""}
        id={id}
        name={name}
        className={disabled ? "disabled-input" : "blue-border-input"}
        disabled={isDisabled ?? disabled}
        value={type === "number" && realValue === "" ? "0" : realValue}
        onChange={type === "number" ? onChangeNumeric : onChange}
        label={label}
      />
    );
  };
  const renderHoursInput = (id: string, name: string, value: number, label: string, type?: string) => {
    const error = isNotFrequencyValid(value) && showErrors;
    const realValue = Math.round(Number(value));
    return (
      <TextField
        error={error}
        helperText={error ? t("warehouse.dialog.hours.field.error") : ""}
        id={id}
        type={"number"}
        name={name}
        className={disabled ? "disabled-input" : "blue-border-input"}
        disabled={disabled}
        value={realValue}
        onChange={e =>
          Number(e.target.value) > 2
            ? Number(e.target.value) < 25
              ? setStockHours(Number(e.target.value))
              : setStockHours(24)
            : setStockHours(2)
        }
        label={label}
      />
    );
  };

  return (
    <React.Fragment>
      {warehouse.id !== undefined && warehouse.id !== TIMEOUT_ID ? (
        <AggregoDialog
          title={
            warehouse.id === CREATE_ID
              ? t("warehouse.create.title")
              : t("warehouse.edit.title", { warehouse: warehouse.name ?? "" })
          }
          onClose={onClose}>
          <Grid container>
            <Grid container rowSpacing={2} mb={1} mt={2}>
              {renderInput("warehouse-name", "name", warehouse.name ?? "", t("warehouse.name"))}
              <Grid container item columnSpacing={{ xs: "1rem" }}>
                <Grid item xs={7}>
                  {renderInput(
                    "warehouse-code",
                    "code",
                    warehouse.code ?? "",
                    t("warehouse.code"),
                    "text",
                    warehouse.id !== CREATE_ID ? true : disabled
                  )}
                </Grid>
                <Grid item xs={5}>
                  {renderInput(
                    "warehouses-capacity-input",
                    "capacity",
                    warehouse.capacity ?? "",
                    t("warehouse.capacity"),
                    "number",
                    warehouse.id !== CREATE_ID ? true : disabled
                  )}
                </Grid>
              </Grid>
              <Grid container item columnSpacing={{ xs: "1rem" }}>
                <Grid item xs={4}>
                  {renderInput("warehouses-size-x", "sizeX", warehouse.sizeX ?? "", t("warehouse.size.x"), "number")}
                </Grid>
                <Grid item xs={4}>
                  {renderInput("warehouses-size-z", "sizeZ", warehouse.sizeZ ?? "", t("warehouse.size.z"), "number")}
                </Grid>
                <Grid item xs={4}>
                  {renderInput("warehouses-max-size-y", "maxSizeY", warehouse.maxSizeY ?? "", t("warehouse.levels"), "number")}
                </Grid>
              </Grid>
              <Grid container item columnSpacing={{ xs: "1rem" }}>
                <Grid item xs={6}>
                  {renderInput("warehouses-address", "address", warehouse.address ?? "", t("warehouse.location"))}
                </Grid>
                <Grid item xs={6}>
                  {renderHoursInput("warehouses-frecuency", "frecuency", stockHours, t("warehouse.frecuency"))}
                </Grid>
              </Grid>
            </Grid>
            <ButtonsDetail
              handleClose={onClose}
              handleSave={() => {
                if (
                  isNotValid(warehouse.name) ||
                  isNotValid(warehouse.code) ||
                  isNotValid(warehouse.capacity) ||
                  isNotValid(warehouse.sizeX) ||
                  isNotValid(warehouse.sizeZ) ||
                  isNotValid(warehouse.maxSizeY) ||
                  isNotValid(warehouse.address) ||
                  isNotFrequencyValid(stockHours)
                ) {
                  setShowErrors(true);
                } else {
                  setPendingRequest(true);
                  onSave(warehouse);
                  setConfigStock(24 / stockHours, warehouse.code ?? "");
                }
              }}
              mode={warehouse.id === CREATE_ID ? "CREATE" : "EDIT"}
              disabled={disabled}
              pendingRequest={pendingRequest}
            />
          </Grid>
        </AggregoDialog>
      ) : (
        <LoadingSpinnerDetailView loading={loading} selectedId={warehouse?.id ?? ""} />
      )}
    </React.Fragment>
  );
};

export default WarehousesDetailView;
