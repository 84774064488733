import TileTemplate from "@domain/model/TileTemplate";
import { TileTemplateApi } from "../api/tile-template-api";
import { IRepository, DomainPage } from "@movicoders/domain";
import { ListTileTemplatesPaginatedRequest } from "@clients/aggrego-proxy/apis/TileTemplatesApi";

export class TileTemplateRepository implements IRepository<TileTemplate, null, ListTileTemplatesPaginatedRequest> {
  constructor(private readonly tileTemplateApi: TileTemplateApi) {}
  get(): Promise<TileTemplate[]> {
    return this.tileTemplateApi.get();
  }
  getPaginated(pageCriteria: ListTileTemplatesPaginatedRequest): Promise<DomainPage<TileTemplate>> {
    return this.tileTemplateApi.getPaginated(pageCriteria);
  }
  getById(id: string): Promise<TileTemplate> {
    return this.tileTemplateApi.getById(id);
  }
  async create(entity: TileTemplate): Promise<TileTemplate> {
    await this.tileTemplateApi.create(entity);
    return entity;
  }
  async update(entity: TileTemplate): Promise<TileTemplate> {
    return await this.tileTemplateApi.update(entity);
  }
  async remove(id: string): Promise<void> {
    await this.tileTemplateApi.remove(id);
  }
}
