import { Grid } from "@mui/material";
import FamilyCard from "./family-card";
import Family from "@domain/model/Family";
import FamilyType from "@domain/model/FamilyType";
import { GridValidRowModel } from "@mui/x-data-grid";
import { useResponsiveGrid } from "@hooks/useResponsiveGrid";
import { NoRecordsFound } from "@components/no-records-found/no-records-found";

interface ICardsDisplay {
  gridData: GridValidRowModel[];
  dataTypesOfFamilies: FamilyType[];
  handleOpenUpsertDialog: (id: string) => void;
  handleOpenToggleActiveStatusDialog: (family: Family) => void;
}

export const FamiliesCardsDisplay = (props: ICardsDisplay) => {
  const { handleMediaQuery } = useResponsiveGrid();
  const columns = handleMediaQuery(4, 3, 2, 2);
  const CARD_GRID_MD = Math.round(12 / columns);
  const CARD_GRID_SM = Math.round(8 / columns);

  return props.gridData?.length && props.gridData.length > 0 ? (
    <>
      {(props.gridData as Family[]).map((family: Family) => (
        <Grid item xs={12} sm={CARD_GRID_SM} md={CARD_GRID_MD} lg={CARD_GRID_MD} key={`grid-card-${family.id}`}>
          <FamilyCard
            family={family}
            handleEdit={props.handleOpenUpsertDialog}
            handleActive={props.handleOpenToggleActiveStatusDialog}
            typesOfFamilies={props.dataTypesOfFamilies}
          />
        </Grid>
      ))}
    </>
  ) : (
    <NoRecordsFound />
  );
};
