import { useMovementsPagination } from "./useMovementsPagination";
import { useMovementsHandleData } from "./useMovementsHandleData";
import { useMovementsGetDataByUrl } from "./useMovementsGetDataByUrl";
import { useMovementsFilterAutocomplete } from "./useMovementsFilterAutocomplete";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { useResolve } from "@movicoders/di";

export const useMovementsViewModel = () => {
  const settingsService = useResolve<ISettingsService>(SettingsService);

  const { data, columnsByMode, currentPagePersistedState, currentPageService, families, loading, movementsFetch } =
    useMovementsHandleData();
  const { filteredMaterialPaginatedData } = useMovementsFilterAutocomplete(currentPageService, currentPagePersistedState);
  const { searchBarFilter } = useMovementsGetDataByUrl();
  const { search } = useMovementsPagination(currentPageService, currentPagePersistedState, movementsFetch);

  return {
    columnsByMode,
    data,
    loading,
    search,
    searchBarFilter,
    families,
    filteredMaterialPaginatedData,
    settingsService
  };
};
