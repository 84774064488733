/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProviderDTO
 */
export interface ProviderDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ProviderDTO
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProviderDTO
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDTO
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDTO
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDTO
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDTO
     */
    contactPerson?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDTO
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDTO
     */
    fiscalId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDTO
     */
    paymentCurrency?: ProviderDTOPaymentCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ProviderDTO
     */
    paymentTerms?: ProviderDTOPaymentTermsEnum;
    /**
     * 
     * @type {string}
     * @memberof ProviderDTO
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderDTO
     */
    postalCode?: string;
}


/**
 * @export
 */
export const ProviderDTOPaymentCurrencyEnum = {
    Dollar: 'DOLLAR',
    Euro: 'EURO',
    PoundSterling: 'POUND_STERLING'
} as const;
export type ProviderDTOPaymentCurrencyEnum = typeof ProviderDTOPaymentCurrencyEnum[keyof typeof ProviderDTOPaymentCurrencyEnum];

/**
 * @export
 */
export const ProviderDTOPaymentTermsEnum = {
    Cash: 'CASH',
    Net30: 'NET30',
    Net60: 'NET60',
    Net90: 'NET90'
} as const;
export type ProviderDTOPaymentTermsEnum = typeof ProviderDTOPaymentTermsEnum[keyof typeof ProviderDTOPaymentTermsEnum];


/**
 * Check if a given object implements the ProviderDTO interface.
 */
export function instanceOfProviderDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProviderDTOFromJSON(json: any): ProviderDTO {
    return ProviderDTOFromJSONTyped(json, false);
}

export function ProviderDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProviderDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'contactPerson': !exists(json, 'contactPerson') ? undefined : json['contactPerson'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'fiscalId': !exists(json, 'fiscalId') ? undefined : json['fiscalId'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'paymentCurrency': !exists(json, 'paymentCurrency') ? undefined : json['paymentCurrency'],
        'paymentTerms': !exists(json, 'paymentTerms') ? undefined : json['paymentTerms'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
    };
}

export function ProviderDTOToJSON(value?: ProviderDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'address': value.address,
        'city': value.city,
        'code': value.code,
        'comment': value.comment,
        'contactPerson': value.contactPerson,
        'country': value.country,
        'email': value.email,
        'fiscalId': value.fiscalId,
        'id': value.id,
        'name': value.name,
        'paymentCurrency': value.paymentCurrency,
        'paymentTerms': value.paymentTerms,
        'phone': value.phone,
        'postalCode': value.postalCode,
    };
}

