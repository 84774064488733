import { useState } from "react";
import { CustomMovementEventFilterActionEnum, MovementEventDTO } from "@clients/aggrego-proxy";
import { MovementsRepository } from "@infrastructure/repositories/movements-repository";
import { useResolve } from "@movicoders/di";

enum MovementModeEnum {
  inbound = "INBOUND",
  outbound = "OUTBOUND"
}

export const useHomeLastMovements = (selectedWarehouseCode: string | undefined) => {
  const movementsRepository = useResolve<MovementsRepository>(MovementsRepository);
  //Last 10 "ENTRANCE" movements data
  const [lastInboundsMovements, setLastInboundsMovements] = useState<MovementEventDTO[]>([]);
  //Last 10 "EXIT" movements data
  const [lastOutboundsMovements, setLastOutboundsMovements] = useState<MovementEventDTO[]>([]);

  const calculateWarehouseLastMovements = () => {
    getLastMovements(MovementModeEnum.inbound, ["ENTRANCE"]);
    getLastMovements(MovementModeEnum.outbound, ["EXIT"]);
  };

  //Gets last five ["ENTRANCE" / "EXIT"] movements
  const getLastMovements = (mode: MovementModeEnum, types: CustomMovementEventFilterActionEnum[]) => {
    movementsRepository
      .getPaginated({
        limit: 5,
        offset: 0,
        customMovementEventFilter: {
          warehouse: selectedWarehouseCode,
          action: types
        }
      })
      .then(result => {
        mode === MovementModeEnum.inbound
          ? setLastInboundsMovements(result.content ?? [])
          : setLastOutboundsMovements(result.content ?? []);
      });
  };

  const resetAllMovements = () => {
    setLastInboundsMovements([]);
    setLastOutboundsMovements([]);
  };

  return { calculateWarehouseLastMovements, lastInboundsMovements, lastOutboundsMovements, resetAllMovements };
};
