import { create } from "@movicoders/store";
import { ImpersonationState } from "@domain/model/ImpersonationState";
import { IImpersonationService } from "@domain/services/IImpersonationService";

export const useImpersonationService = create<IImpersonationService>((set, get) => ({
  saveImpersonationState: (newState: ImpersonationState) => set({ persistedState: newState }),
  clearImpersonationState: () => set({ persistedState: undefined })
}))
  .withPersist(ImpersonationState, "ImpersonationState")
  .build();
