import { DomainPage } from "@movicoders/domain";
import Warehouse from "@domain/model/Warehouse";
import {
  ListWarehousesByTenantIdRequest,
  ListWarehousesPaginatedRequest,
  WarehousesApi
} from "@clients/aggrego-proxy/apis/WarehousesApi";
import { IImpersonationService } from "@domain/services/IImpersonationService";

export class WarehouseApi {
  constructor(private readonly warehousesApi: WarehousesApi, private readonly impersonationService: IImpersonationService) {}

  async get(): Promise<Warehouse[]> {
    const result: Warehouse[] = [];

    const apiResult = await this.warehousesApi.listWarehouses({
      fullDTO: false,
      status: "ALL",
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
    apiResult.forEach(warehouseFromBE => {
      result.push(Warehouse.fromClientWarehouse(warehouseFromBE));
    });
    return result;
  }

  //GETS ALL WH's BY TENANT ID
  async getByTenant(tenantId: string): Promise<Warehouse[]> {
    const result: Warehouse[] = [];
    const apiResult = await this.warehousesApi.listWarehouses({ fullDTO: false, status: "ALL", xTenantId: tenantId });
    apiResult.forEach(warehouseFromBE => {
      result.push(Warehouse.fromClientWarehouse(warehouseFromBE));
    });
    return result;
  }

  //New call to get all warehouses by a company tenant id
  async getByTenantId(pageCriteria: ListWarehousesByTenantIdRequest): Promise<Warehouse[]> {
    const result: Warehouse[] = [];
    const apiResult = await this.warehousesApi.listWarehousesByTenantId(pageCriteria);
    apiResult.forEach(warehouseFromBE => {
      result.push(Warehouse.fromClientWarehouse(warehouseFromBE));
    });
    return result;
  }

  async getPaginated(pageCriteria: ListWarehousesPaginatedRequest): Promise<DomainPage<Warehouse>> {
    return (await this.warehousesApi.listWarehousesPaginated({
      ...pageCriteria,
      fullDTO: false,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    })) as unknown as Promise<DomainPage<Warehouse>>;
  }

  async getById(id: string): Promise<Warehouse> {
    return Warehouse.fromClientWarehouse(
      await this.warehousesApi.getWarehouseById({
        id: id,
        fullDTO: false,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
  }

  async getFullDTOById(id: string): Promise<Warehouse> {
    return Warehouse.fromClientWarehouse(
      await this.warehousesApi.getWarehouseById({
        id: id,
        fullDTO: true,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
  }

  async create(entity: Warehouse): Promise<Warehouse> {
    return Warehouse.fromClientWarehouse(
      await this.warehousesApi.saveWarehouse({
        warehouseDTO: entity,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
  }

  async update(entity: Warehouse): Promise<Warehouse> {
    return Warehouse.fromClientWarehouse(
      await this.warehousesApi.updateWarehouse({
        warehouseDTO: entity,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
  }

  async remove(id: string): Promise<void> {
    await this.warehousesApi.deleteWarehouse({
      id: id,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }
}
