import { SxProps } from "@mui/material";

export const dashboardSearchEngineStyles = () => {
  const clickable_title: SxProps = {
    "&:hover": {
      color: "#4BADB1", // Color al pasar el ratón por encima
      cursor: "pointer" // Cambiar el cursor al pasar el ratón por encima
    }
  };

  return {
    clickable_title
  };
};
