import {
  GetParentsByBatchAndMaterialCodeRequest,
  GetParentsBySerialNumberAndMaterialCodeRequest,
  GetProductByBatchAndMaterialCodeRequest,
  GetProductBySerialNumberRequest,
  GetProductHierarchyByBatchAndMaterialCodeRequest,
  ListProductsPaginatedRequest,
  ProductDTO,
  ProductPageDTO,
  ProductsApi
} from "@clients/aggrego-proxy";
import { DomainPage } from "@movicoders/domain";
import { Product } from "@domain/model/Product";
import GenericPage, { DTOPage } from "@domain/model/GenericPage";
import { IImpersonationService } from "@domain/services/IImpersonationService";

export class ProductApi {
  constructor(private readonly productsApi: ProductsApi, private readonly impersonationService: IImpersonationService) {}

  getPaginated(criteria: ListProductsPaginatedRequest): Promise<DomainPage<Product>> {
    return new Promise((res, rej) => {
      this.productsApi
        .listProductsPaginated({ ...criteria, xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? "" })
        .then((productPageDTO: ProductPageDTO) => {
          res(new GenericPage<Product>(Product).fromDTOPage(productPageDTO as DTOPage<Product>));
        });
    });
  }

  getBySerialNumber(criteria: GetProductBySerialNumberRequest): Promise<DomainPage<Product>> {
    return new Promise((res, rej) => {
      this.productsApi
        .getProductBySerialNumber({ ...criteria, xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? "" })
        .then((productPageDTO: ProductPageDTO) => {
          res(new GenericPage<Product>(Product).fromDTOPage(productPageDTO as DTOPage<Product>));
        });
    });
  }

  getByBatchAndMaterialCode(criteria: GetProductByBatchAndMaterialCodeRequest): Promise<DomainPage<Product>> {
    return new Promise((res, rej) => {
      this.productsApi
        .getProductByBatchAndMaterialCode({
          ...criteria,
          xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
        })
        .then((productPageDTO: ProductPageDTO) => {
          res(new GenericPage<Product>(Product).fromDTOPage(productPageDTO as DTOPage<Product>));
        });
    });
  }

  async getParentBySerialNumberAndMaterialCode(
    criteria: GetParentsBySerialNumberAndMaterialCodeRequest
  ): Promise<ProductPageDTO> {
    const apiResult = await this.productsApi.getParentsBySerialNumberAndMaterialCode({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
    return apiResult;
  }

  async getParentByBatchAndMaterialCode(criteria: GetParentsByBatchAndMaterialCodeRequest): Promise<ProductPageDTO> {
    const apiResult = await this.productsApi.getParentsByBatchAndMaterialCode({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
    return apiResult;
  }

  getById(id: string): Promise<Product> {
    return new Promise((res, rej) => {
      this.productsApi
        .getProductById({ id, xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? "" })
        .then((productDTO: ProductDTO) => {
          res(new Product().fromDTO(productDTO));
        })
        .catch(err => rej());
    });
  }

  async getProductHierarchyByBatchAndMaterialCode(
    criteria: GetProductHierarchyByBatchAndMaterialCodeRequest
  ): Promise<ProductPageDTO> {
    const apiResult = await this.productsApi.getProductHierarchyByBatchAndMaterialCode({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
    return apiResult;
  }
}
