import { useTranslation } from "react-i18next";
import { Button, Grid, Theme, useMediaQuery } from "@mui/material";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";

const InvRegistryDialog = (props: { open: boolean; isPeriodActive: boolean; onClose: () => void; onInit: () => void }) => {
  const { t } = useTranslation();
  const { open, isPeriodActive, onClose, onInit } = props;
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up(350), { noSsr: true });

  return open ? (
    <AggregoDialog title={t("invregistry.init.dialog.title")} onClose={onClose}>
      <Grid container flexDirection="column" className="masterdata-box" rowSpacing={2}>
        <Grid item>
          <div className="confirm-dialog-div-content">
            {" "}
            {isPeriodActive ? t("invregistry.closing.dialog.content") : t("invregistry.initiating.dialog.content")}?
          </div>
        </Grid>
        <Grid item>
          <Grid container marginTop="1rem" flexGrow={1} justifyContent="space-between" direction={matches ? "row" : "column"}>
            <Button id="invregistry-cancel-button" variant="secondary" onClick={onClose}>
              {t("button.cancel")}
            </Button>
            <Button id="invregistry-init-button" variant="primary" onClick={onInit} autoFocus>
              {t("button.accept")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </AggregoDialog>
  ) : (
    <></>
  );
};

export default InvRegistryDialog;
