/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FilterDTO
 */
export interface FilterDTO {
    /**
     * 
     * @type {string}
     * @memberof FilterDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterDTO
     */
    firstName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterDTO
     */
    roles?: Array<FilterDTORolesEnum>;
    /**
     * 
     * @type {string}
     * @memberof FilterDTO
     */
    searchText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FilterDTO
     */
    status?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FilterDTO
     */
    username?: string;
}


/**
 * @export
 */
export const FilterDTORolesEnum = {
    MobAdmin: 'MOB_ADMIN',
    MobUser: 'MOB_USER',
    PortalAdmin: 'PORTAL_ADMIN',
    PortalUser: 'PORTAL_USER',
    SuperAdmin: 'SUPER_ADMIN'
} as const;
export type FilterDTORolesEnum = typeof FilterDTORolesEnum[keyof typeof FilterDTORolesEnum];


/**
 * Check if a given object implements the FilterDTO interface.
 */
export function instanceOfFilterDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FilterDTOFromJSON(json: any): FilterDTO {
    return FilterDTOFromJSONTyped(json, false);
}

export function FilterDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
        'searchText': !exists(json, 'searchText') ? undefined : json['searchText'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'username': !exists(json, 'username') ? undefined : json['username'],
    };
}

export function FilterDTOToJSON(value?: FilterDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'firstName': value.firstName,
        'roles': value.roles,
        'searchText': value.searchText,
        'status': value.status,
        'username': value.username,
    };
}

