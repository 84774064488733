import { useEffect, useState } from "react";
import FamilyType from "@domain/model/FamilyType";

export interface IFormErrors {
  name: boolean;
}

export const useTypeOfFamilyForm = (data: FamilyType) => {
  const INITIAL_ERROR_MAP = new Map([["type-of-family-name", false]]);
  const [typeOfFamily, setTypeOfFamily] = useState<FamilyType>(data);
  const [formErrors, setFormErrors] = useState<Map<string, boolean>>(INITIAL_ERROR_MAP);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTypeOfFamily({ ...typeOfFamily, [event.target.name]: event.target.value });
  };

  const validateFormValues = () => {
    const nameValid = typeOfFamily.name !== null && typeOfFamily.name !== undefined && typeOfFamily.name.trim() !== "";
    setFormErrors(new Map([["type-of-family-name", !nameValid]]));
    return nameValid;
  };

  useEffect(() => {
    setTypeOfFamily(data);
    setFormErrors(INITIAL_ERROR_MAP);
  }, [data]);

  return { onChange, typeOfFamily, formErrors, validateFormValues };
};
