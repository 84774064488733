import { useTranslation } from "react-i18next";
import { Grid, TextField } from "@mui/material";
import { AutocompleteIMEntry } from "./logistic-autocomplete";
import IMMEntryWithDesc from "@domain/model/IMMEntryWithDesc";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useEntriesValidations } from "../entries/hooks/useEntriesValidations";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";
import { ButtonsDetail } from "@components/master-data-view/buttons/buttons-detail";

interface IDetail {
  title: string;
  entry: IMMEntryWithDesc;
  createdIMMaterial: IMMEntryWithDesc;
  setEntry: Dispatch<SetStateAction<IMMEntryWithDesc>>;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  handleSave: () => void;
  onClose: () => void;
}

export const LogisticDetailView: FC<IDetail> = ({ title, entry, createdIMMaterial, setEntry, onChange, onClose, handleSave }) => {
  const disabled = false;
  const { t } = useTranslation();
  const [prevId, setPrevId] = useState("INIT");
  const { formErrors } = useEntriesValidations(createdIMMaterial);

  useEffect(() => {
    setPrevId(entry.id ?? "NO STATE");
  }, []);

  const renderInput = (id: string, name: string, value: string | number, label: string, type?: string) => {
    return (
      <TextField
        id={id}
        type={type ?? "text"}
        name={name}
        className={disabled ? "disabled-input" : "blue-border-input"}
        disabled={disabled}
        value={value}
        onChange={onChange}
        label={label}
      />
    );
  };

  return (
    <AggregoDialog title={title} onClose={onClose}>
      <Grid container flexDirection="column" className="masterdata-box" rowSpacing={2}>
        <Grid item xs={12}>
          <AutocompleteIMEntry
            id="edit-material-entries-autocomplete"
            iMMaterial={entry}
            onChangeMaterial={value => {
              setEntry((prevEntry: IMMEntryWithDesc) => ({
                ...prevEntry,
                id: prevId.concat("±" + (value?.id ?? "0")),
                materialCode: value?.code ?? "",
                materialDescription: value?.name ?? ""
              }));
            }}
            formErrors={formErrors}
          />
        </Grid>
        <Grid item xs={12}>
          {renderInput("logistic-qty", "qty", entry?.qty ?? 0, t("logistic.quantity"), "number")}
        </Grid>
        <Grid item xs={12}>
          {renderInput("logistic-comment", "comment", entry?.comment ?? "", t("logistic.comment"), "text")}
        </Grid>
        <Grid item xs={12}>
          <ButtonsDetail handleClose={onClose} handleSave={handleSave} mode={"EDIT"} disabled={disabled} />
        </Grid>
      </Grid>
    </AggregoDialog>
  );
};
