import { COLORS } from "../theme";

const reportsNames = ["whstatus", "invreport", "movreport", "stock", "invregistry"];

const reportsPathNameConversion = (path: string) => {
  switch (path) {
    case "whstatus":
      return "warehouse-status";
    case "invreport":
      return "inventory-report";
    case "movreport":
      return "movements-report";
    case "stock":
      return "materials-stock";
    case "invregistry":
      return "inventory-registry";
    default:
      return path;
  }
};

const handlePathText = (route: string, path: string) => {
  if (reportsNames.some(i => reportsNames.includes(path))) {
    path = reportsPathNameConversion(path);
  }
  if (path.charAt(0) !== "/") {
    return `/${path}`.replaceAll(".", "-");
  }
  return path.replaceAll(".", "-");
};

export const checkIconColor = (path: string, isReport?: boolean): string => {
  const route = `/${window.location.pathname.split("/")?.[1]}`;
  path = handlePathText(route, path);
  return route === path ? COLORS.secondary : "inherit";
};
