import { COLORS } from "@theme";
import { SxProps, Theme } from "@mui/material";

export const logisticStyles = () => {
  const divider: SxProps<Theme> = {
    border: "2px solid #EBEBEB",
    opacity: 1,
    width: "100%",
    marginTop: 1,
    marginBottom: 1
  };
  const content_divider: SxProps<Theme> = {
    border: "1px solid #EBEBEB",
    opacity: 1,
    width: "100%",
    marginTop: 0.5,
    marginBottom: 1.5
  };
  const logistic_button: SxProps<Theme> = {
    height: "45px",
    marginTop: "-5px"
  };

  const logistic_sync_title: SxProps<Theme> = {
    color: COLORS.secondary,
    fontSize: "x-large",
    fontWeight: 600
  };

  const logistic_sync_paper: SxProps<Theme> = {
    p: 1,
    borderRadius: "6px 6px 6px 6px",
    boxShadow: "0px 3px 6px #00000029"
  };

  return {
    divider,
    content_divider,
    logistic_button,
    logistic_sync_title,
    logistic_sync_paper
  };
};
