import { create } from "@movicoders/store";
import { OperatorState } from "@domain/model/OperatorState";
import { IOperatorService } from "@domain/services/IOperatorService";

export const useOperatorService = create<IOperatorService>((set, get) => ({
  saveOperatorState: (newState: OperatorState) => set({ persistedState: newState }),
  clearOperatorState: () => set({ persistedState: undefined })
}))
  .withPersist(OperatorState, "OperatorState")
  .build();
