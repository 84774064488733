import { CREATE_ID, TIMEOUT_ID } from "@constants";
import Stakeholder from "@domain/model/Stakeholder";
import { LastPageState } from "@domain/model/LastPageState";
import { stakeholderTypesEnum } from "../useStakeholderSelection";
import { useMasterDataMessagges } from "@hooks/useMasterDataMessages";
import { ListStakeholdersPaginatedRequest } from "@clients/aggrego-proxy";

export const useStakeholderSave = (
  selectedStakeholder: Stakeholder,
  stakeholderType: stakeholderTypesEnum,
  stakeholderTypeForFilters: string,
  currentPagePersistedState: LastPageState,
  save: (entity: Stakeholder) => Promise<void>,
  fetchOne: (id: string) => Promise<void>,
  goToList: (criteria: ListStakeholdersPaginatedRequest) => Promise<void>
) => {
  const { successMessaggesSaving, errorMessaggesSaving, errorMessaggesLoading } = useMasterDataMessagges();

  const handleEdit = (id: string) => {
    fetchOne(id).catch(() => {
      errorMessaggesLoading(stakeholderType, selectedStakeholder.name ?? "");
      selectedStakeholder.id = TIMEOUT_ID;
    });
  };

  const handleSave = async (stakeholder: Stakeholder) => {
    if (stakeholder.id === CREATE_ID) {
      stakeholder.active = true;
    }
    save(stakeholder)
      .then(() => successMessaggesSaving(stakeholderType, stakeholder.name ?? "", stakeholder.id))
      .catch(error => errorMessaggesSaving(stakeholderType, stakeholder.name ?? "", stakeholder.id, error))
      .finally(() => {
        goToList({
          limit: currentPagePersistedState.limit,
          offset: currentPagePersistedState.offset,
          status: currentPagePersistedState.status,
          customStakeholderFilter: {
            ...currentPagePersistedState.filters,
            types: [stakeholderTypeForFilters]
          },
          sortDirection: currentPagePersistedState.sortDirection,
          sortField: currentPagePersistedState.sortField
        } as ListStakeholdersPaginatedRequest);
      });
  };

  return {
    handleEdit,
    handleSave
  };
};
