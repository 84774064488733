import { useState } from "react";
import { useResolve } from "@movicoders/di";
import { handleError } from "@utils/error-helper";
import LocationEntity from "@domain/model/Location";
import { LastPageState } from "@domain/model/LastPageState";
import { useMasterDataMessagges } from "@hooks/useMasterDataMessages";
import { ListLocationsPaginatedRequest, ResponseError } from "@clients/aggrego-proxy";
import { LocationUpdateStatusUseCase } from "@application/master-data/locations/location-update-status-use-case";
import { useSnackbar } from "@movicoders/ui";
import { useTranslation } from "react-i18next";

export const useLocationActiveStatus = (
  currentPagePersistedState: LastPageState,
  selectedWarehouse: string,
  getLocationData: (criteria: ListLocationsPaginatedRequest) => Promise<void>,
  getCurrentStatus: (status: "ALL" | "ACTIVE" | "INACTIVE") => boolean | undefined
) => {
  const { t } = useTranslation();
  const { show } = useSnackbar();
  const { successMessaggesStatus, errorMessaggesStatus } = useMasterDataMessagges();
  const locationStatusUseCase = useResolve(LocationUpdateStatusUseCase);

  // Status management related variables
  const [isActivating, setIsActivating] = useState(false);
  const [activeLocation, setActiveLocation] = useState<LocationEntity | undefined>(undefined);

  const onCloseActive = () => {
    setIsActivating(false);
  };

  const handleActiveConfirm = (location: LocationEntity) => {
    setIsActivating(!isActivating);
    setActiveLocation(location);
  };

  const handleLocationStatus = async (location: LocationEntity) => {
    let response;
    try {
      await locationStatusUseCase.execute(location).then(() => {
        successMessaggesStatus("location", location?.active ?? false, location.code ?? "");
      });
    } catch (error) {
      response = await handleError(error as ResponseError);
      if (response?.status === 400 && response.errors && response.errors[0].errorCode === "11/404") {
        const errorToShow = response?.errors?.[0]?.message?.toLowerCase().split(">")[1].replaceAll(" ", ".");
        show(t("deactivate".concat(errorToShow ?? "location.unknown,message")), "error");
      } else {
        const errorToShow = response?.errors?.[0]?.errorCode?.toLowerCase().split(" ").join(".") ?? "unknown";
        errorMessaggesStatus("location", location.active ?? false, location.code ?? "", errorToShow);
      }
    } finally {
      setIsActivating(!isActivating);
      getLocationData({
        xTenantId: "",
        limit: currentPagePersistedState.limit,
        offset: currentPagePersistedState.offset,

        customLocationsFilterDTO: {
          ...currentPagePersistedState.filters,
          status: getCurrentStatus(currentPagePersistedState.status),
          warehouseId: selectedWarehouse
        }
      } as ListLocationsPaginatedRequest);
    }
  };

  return {
    activeLocation,
    isActivating,
    onCloseActive,
    handleActiveConfirm,
    handleLocationStatus
  };
};
