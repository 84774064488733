import { useState } from "react";
import Material from "@domain/model/Material";
import { LastPageState } from "@domain/model/LastPageState";
import { useMasterDataMessagges } from "@hooks/useMasterDataMessages";
import { ListMaterialsPaginatedRequest } from "@clients/aggrego-proxy/apis/MaterialsApi";

export const useMaterialActiveStatus = (
  currentPagePersistedState: LastPageState,
  save: (entity: Material) => Promise<void>,
  getMaterialPaginated: (criteria: ListMaterialsPaginatedRequest) => Promise<void>,
  getCurrentStatus: (status: "ACTIVE" | "INACTIVE" | "ALL") => boolean | undefined
) => {
  const [isActivating, setIsActivating] = useState(false);
  const [material4Activation, setMaterial4Activation] = useState<Material | undefined>(undefined);
  const { successMessaggesStatus, errorMessaggesStatus } = useMasterDataMessagges();

  const handleActiveConfirm = (material: Material) => {
    setIsActivating(!isActivating);
    setMaterial4Activation(material);
  };

  const onCloseActive = () => {
    setIsActivating(false);
  };

  const handleMaterialStatus = async (material: Material) => {
    save({ ...material, active: !material.active })
      .then(() => successMessaggesStatus("material", material.active, material.name))
      .catch(error => errorMessaggesStatus("material", material.active, material.name, error))
      .finally(() => {
        setIsActivating(false);
        getMaterialPaginated({
          xTenantId: "",
          limit: currentPagePersistedState.limit,
          offset: currentPagePersistedState.offset,
          fullDTO: true,
          customMaterialFilter: {
            ...currentPagePersistedState.filters,
            status: getCurrentStatus(currentPagePersistedState.status)
          },
          sortDirection: currentPagePersistedState.sortDirection,
          sortField: currentPagePersistedState.sortField
        } as ListMaterialsPaginatedRequest);
      });
  };

  return {
    isActivating,
    material4Activation,
    onCloseActive,
    handleActiveConfirm,
    handleMaterialStatus
  };
};
