import { Grid } from "@mui/material";
import Stakeholder from "@domain/model/Stakeholder";
import { StakeholdersCard } from "./stakeholders-cards";
import { useResponsiveGrid } from "@hooks/useResponsiveGrid";
import { NoRecordsFound } from "@components/no-records-found/no-records-found";

interface ICardsDisplay {
  dataPaginated: Stakeholder[];
  handleEdit: (id: string) => void;
  handleActive: (item: Stakeholder) => void;
  handleStakeholderOpen: (stakeholder: Stakeholder) => void;
}

export const CardsDisplay = (props: ICardsDisplay) => {
  const { handleMediaQuery } = useResponsiveGrid();
  const columns = handleMediaQuery(2, 2, 2, 2);
  const CARD_GRID_MD = Math.round(12 / columns);
  const CARD_GRID_SM = Math.round(8 / columns);

  return props.dataPaginated?.length && props.dataPaginated.length > 0 ? (
    <Grid container id="container-stakeholder-cards" rowSpacing={1} columnSpacing={1} mt="0.75rem" mb="0.5rem">
      {props.dataPaginated?.map((stakeholder: Stakeholder, index: number) => (
        <Grid item xs={12} sm={CARD_GRID_SM} md={CARD_GRID_MD} lg={CARD_GRID_MD} key={`grid-card-${stakeholder?.code ?? ""}`}>
          <StakeholdersCard
            stakeholder={stakeholder}
            handleEdit={props.handleEdit}
            handleActive={props.handleActive}
            handleStakeholderOpen={props.handleStakeholderOpen}
          />
        </Grid>
      ))}
    </Grid>
  ) : (
    <NoRecordsFound />
  );
};
