import { ReportApi } from "../api/report-api";
import { DomainPage, IRepository } from "@movicoders/domain";
import { WarehouseStatusDTO } from "@domain/model/WarehouseStatus";
import { GetWarehouseStatusReportByWarehouseAndFilterRequest } from "@clients/aggrego-proxy";

export class WarehouseStatusRepository
  implements IRepository<WarehouseStatusDTO, null, GetWarehouseStatusReportByWarehouseAndFilterRequest>
{
  constructor(private readonly warehouseStatusApi: ReportApi) {}
  get(criteria?: null | undefined): Promise<WarehouseStatusDTO[]> {
    throw new Error("Method not implemented.");
  }
  getById(id: string): Promise<WarehouseStatusDTO> {
    throw new Error("Method not implemented.");
  }
  create(entity: WarehouseStatusDTO): Promise<WarehouseStatusDTO> {
    throw new Error("Method not implemented.");
  }
  update(entity: WarehouseStatusDTO): Promise<WarehouseStatusDTO> {
    throw new Error("Method not implemented.");
  }
  remove(id: string): Promise<void> {
    throw new Error("Method not implemented.");
  }

  getPaginated(pageCriteria: GetWarehouseStatusReportByWarehouseAndFilterRequest): Promise<DomainPage<WarehouseStatusDTO>> {
    return this.warehouseStatusApi.getWarehouseStatusPaginated(pageCriteria);
  }
}
