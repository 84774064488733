/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContainerDTO } from './ContainerDTO';
import {
    ContainerDTOFromJSON,
    ContainerDTOFromJSONTyped,
    ContainerDTOToJSON,
} from './ContainerDTO';
import type { ProductDTO } from './ProductDTO';
import {
    ProductDTOFromJSON,
    ProductDTOFromJSONTyped,
    ProductDTOToJSON,
} from './ProductDTO';

/**
 * 
 * @export
 * @interface ContainerWithProductDTO
 */
export interface ContainerWithProductDTO {
    /**
     * 
     * @type {ContainerDTO}
     * @memberof ContainerWithProductDTO
     */
    containerDTO?: ContainerDTO;
    /**
     * 
     * @type {ProductDTO}
     * @memberof ContainerWithProductDTO
     */
    productDTO?: ProductDTO;
}

/**
 * Check if a given object implements the ContainerWithProductDTO interface.
 */
export function instanceOfContainerWithProductDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContainerWithProductDTOFromJSON(json: any): ContainerWithProductDTO {
    return ContainerWithProductDTOFromJSONTyped(json, false);
}

export function ContainerWithProductDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContainerWithProductDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'containerDTO': !exists(json, 'containerDTO') ? undefined : ContainerDTOFromJSON(json['containerDTO']),
        'productDTO': !exists(json, 'productDTO') ? undefined : ProductDTOFromJSON(json['productDTO']),
    };
}

export function ContainerWithProductDTOToJSON(value?: ContainerWithProductDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'containerDTO': ContainerDTOToJSON(value.containerDTO),
        'productDTO': ProductDTOToJSON(value.productDTO),
    };
}

