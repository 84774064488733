import Family from "@domain/model/Family";
import MaterialCard from "./material-card";
import Material from "@domain/model/Material";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { GridValidRowModel } from "@mui/x-data-grid";

interface ICardDisplay {
  gridData: GridValidRowModel[];
  handleEdit: (id: string) => void;
  handleActiveConfirm: (item: Material) => void;
  families: Family[];
}

export const CardDisplay = (props: ICardDisplay) => {
  const { gridData, handleActiveConfirm, handleEdit } = props;
  return (
    <Grid container id="container-material-cards" rowSpacing={1} mt="0.75rem" mb="0.5rem">
      {(gridData as Material[]).map((material: Material) => (
        <Grid item xs={12} key={material.id}>
          <MaterialCard material={material} handleEdit={handleEdit} handleActive={handleActiveConfirm} />
        </Grid>
      ))}
    </Grid>
  );
};

export const PDFCardDisplay = (props: ICardDisplay & { tableName: string }) => {
  const { tableName, gridData, handleActiveConfirm, handleEdit, families } = props;
  const { t } = useTranslation();
  return (
    <>
      <Grid container justifyContent="space-between">
        <Typography variant="title">{t("table.download.pdf.title", { name: tableName })}</Typography>
        <Typography variant="title">{new Date().toLocaleString()}</Typography>
      </Grid>
      <CardDisplay gridData={gridData} handleActiveConfirm={handleActiveConfirm} handleEdit={handleEdit} families={families} />
    </>
  );
};
