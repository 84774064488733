import { PivotControls } from "@react-three/drei";
import { Suspense } from "react";
import { useCustomPivotControlsController } from "./useCustomPivotControlsController";


export const CustomPivotControls = () => {
  const {
    pivotRef,
    pivotContentRef,
    pivotMatrix,
    getActiveAxes,
    getAxisColors,
    handleDrag,
    handleDragEnd
  } = useCustomPivotControlsController();

  return (
    <Suspense>
      <PivotControls
        anchor={[0, 0, 0]}
        scale={2}
        ref={pivotRef}
        disableRotations
        disableSliders
        activeAxes={getActiveAxes()}
        depthTest={false}
        autoTransform={false}
        matrix={pivotMatrix}
        lineWidth={10}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
        axisColors={getAxisColors()}>
        <group ref={pivotContentRef} />
      </PivotControls>
    </Suspense>
  );
};
