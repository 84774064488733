import { useState } from "react";
import { useResolve } from "@movicoders/di";
import { CompanyDTO } from "@clients/aggrego-proxy";
import { useAdminMessagges } from "./useAdminMessagges";
import { CompanyUpdateStatusUseCase } from "@application/master-data/companies/company-update-status-use-case";

export const useHandleCompanyStatus = () => {
  const [isTogglingState, setIsTogglingState] = useState(false);
  const updateStatusUseCase = useResolve(CompanyUpdateStatusUseCase);
  const [togglingStatusCompany, setTogglingStatusCompany] = useState<CompanyDTO>();

  const { successMessaggesStatus, errorMessaggesStatus } = useAdminMessagges();

  const onCloseChangeStatus = () => {
    setTogglingStatusCompany(undefined);
    setIsTogglingState(false);
  };

  const toggleCompanyStatus = (company: CompanyDTO) => {
    updateStatusUseCase
      .execute(company)
      .then(() => successMessaggesStatus(company))
      .catch(error => errorMessaggesStatus(company, error))
      .finally(() => {
        setIsTogglingState(!isTogglingState);
      });
  };

  const handleCompanyChangeStatus = (company: CompanyDTO) => {
    setTogglingStatusCompany(company);
    setIsTogglingState(!isTogglingState);
  };

  return {
    isTogglingState,
    togglingStatusCompany,
    onCloseChangeStatus,
    toggleCompanyStatus,
    handleCompanyChangeStatus
  };
};
