import Material from "@domain/model/Material";
import { MaterialApi } from "../api/material-api";
import { DomainPage, IRepositoryPaginated } from "@movicoders/domain";
import { ListMaterialsPaginatedRequest } from "@clients/aggrego-proxy/apis/MaterialsApi";

export class MaterialRepository implements IRepositoryPaginated<Material, ListMaterialsPaginatedRequest> {
  constructor(private readonly materialApi: MaterialApi) {}
  get(): Promise<Material[]> {
    return this.materialApi.get();
  }

  getPaginated(pageCriteria: ListMaterialsPaginatedRequest): Promise<DomainPage<Material>> {
    return this.materialApi.getPaginated(pageCriteria);
  }

  getPaginatedDTOselection(pageCriteria: ListMaterialsPaginatedRequest): Promise<DomainPage<Material>> {
    return this.materialApi.getPaginated(pageCriteria);
  }

  getById(id: string): Promise<Material> {
    return this.materialApi.getById(id);
  }

  getByExactCode(code: string): Promise<Material> {
    return this.materialApi.getByExactCode(code);
  }

  async create(entity: Material): Promise<Material> {
    await this.materialApi.create(entity);
    return entity;
  }

  async update(entity: Material): Promise<Material> {
    return await this.materialApi.update(entity);
  }

  async remove(id: string): Promise<void> {
    await this.materialApi.remove(id);
  }
}
