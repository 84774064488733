import { useWarehouseSave } from "./useWarehousesSave";
import { useWarehousePagination } from "./useWarehousesPagination";
import { useWarehouseHandleData } from "./useWarehousesHandleData";
import { useWarehouseActiveStatus } from "./useWarehousesActiveStatus";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";
import { useResolve } from "@movicoders/di";

export const useWarehouseViewModel = () => {
  const settingsService = useResolve<ISettingsService>(SettingsService);

  const {
    impersonationService,
    currentPagePersistedState,
    loading,
    loadingDetail,
    warehouses,
    selectedWarehouse,
    getCurrentStatus,
    goToCreate,
    goToDetail,
    goToList,
    save,
    warehouseGetPaginated
  } = useWarehouseHandleData();

  const { handleEdit, handleSave, onChange, onChangeNumeric, warehouse } = useWarehouseSave(
    currentPagePersistedState,
    selectedWarehouse,
    save,
    goToDetail,
    warehouseGetPaginated,
    getCurrentStatus
  );

  const { handleDeactivateConfirm, handleWarehouseStatus, isDeactivating, onCloseDeactivate, deactivateWarehouse } =
    useWarehouseActiveStatus(currentPagePersistedState, getCurrentStatus, warehouseGetPaginated);

  const { handlePagination } = useWarehousePagination(
    currentPagePersistedState,
    warehouseGetPaginated,
    getCurrentStatus,
    impersonationService
  );

  return {
    loading,
    loadingDetail,
    selectedWarehouse,
    warehouses,
    warehouse,
    isDeactivating,
    deactivateWarehouse,
    goToCreate,
    goToList,
    onChange,
    onChangeNumeric,
    handleSave,
    handleEdit,
    handleDeactivateConfirm,
    handleWarehouseStatus,
    handlePagination,
    onCloseDeactivate,
    settingsService
  };
};
