/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TileDTO } from './TileDTO';
import {
    TileDTOFromJSON,
    TileDTOFromJSONTyped,
    TileDTOToJSON,
} from './TileDTO';
import type { ZoneDTO } from './ZoneDTO';
import {
    ZoneDTOFromJSON,
    ZoneDTOFromJSONTyped,
    ZoneDTOToJSON,
} from './ZoneDTO';

/**
 * 
 * @export
 * @interface WarehouseDTO
 */
export interface WarehouseDTO {
    /**
     * 
     * @type {boolean}
     * @memberof WarehouseDTO
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WarehouseDTO
     */
    address?: string;
    /**
     * 
     * @type {number}
     * @memberof WarehouseDTO
     */
    capacity?: number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseDTO
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof WarehouseDTO
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof WarehouseDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof WarehouseDTO
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof WarehouseDTO
     */
    maxSizeY?: number;
    /**
     * 
     * @type {string}
     * @memberof WarehouseDTO
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof WarehouseDTO
     */
    sizeX?: number;
    /**
     * 
     * @type {number}
     * @memberof WarehouseDTO
     */
    sizeZ?: number;
    /**
     * 
     * @type {Array<TileDTO>}
     * @memberof WarehouseDTO
     */
    tiles?: Array<TileDTO>;
    /**
     * 
     * @type {Array<ZoneDTO>}
     * @memberof WarehouseDTO
     */
    zones?: Array<ZoneDTO>;
}

/**
 * Check if a given object implements the WarehouseDTO interface.
 */
export function instanceOfWarehouseDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WarehouseDTOFromJSON(json: any): WarehouseDTO {
    return WarehouseDTOFromJSONTyped(json, false);
}

export function WarehouseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WarehouseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'capacity': !exists(json, 'capacity') ? undefined : json['capacity'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'maxSizeY': !exists(json, 'maxSizeY') ? undefined : json['maxSizeY'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'sizeX': !exists(json, 'sizeX') ? undefined : json['sizeX'],
        'sizeZ': !exists(json, 'sizeZ') ? undefined : json['sizeZ'],
        'tiles': !exists(json, 'tiles') ? undefined : ((json['tiles'] as Array<any>).map(TileDTOFromJSON)),
        'zones': !exists(json, 'zones') ? undefined : ((json['zones'] as Array<any>).map(ZoneDTOFromJSON)),
    };
}

export function WarehouseDTOToJSON(value?: WarehouseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'address': value.address,
        'capacity': value.capacity,
        'code': value.code,
        'companyId': value.companyId,
        'id': value.id,
        'image': value.image,
        'maxSizeY': value.maxSizeY,
        'name': value.name,
        'sizeX': value.sizeX,
        'sizeZ': value.sizeZ,
        'tiles': value.tiles === undefined ? undefined : ((value.tiles as Array<any>).map(TileDTOToJSON)),
        'zones': value.zones === undefined ? undefined : ((value.zones as Array<any>).map(ZoneDTOToJSON)),
    };
}

