import { Drawer } from "./drawer/drawer";
import { User } from "@domain/model/User";
import { ROUTER_PATHS, TELTRONIC_FISCALID } from "@constants";
import { Toolbar } from "./toolbar/toolbar";
import { useResolve } from "@movicoders/di";
import { useLocation } from "react-router-dom";
import { Snackbar } from "../snackbar/snackbar";
import { UserInfo } from "./user-info/userInfo";
import React, { useEffect, useState } from "react";
import { Theme, useMediaQuery } from "@mui/material";
import { UUIDGenerator } from "@utils/uuid-generator";
import { useHandlePortal } from "@hooks/useHandlePortal";
import { useAppNavigation } from "./hooks/useAppNavigation";
import { useCheckLoginStatus } from "./hooks/useCheckLoginStatus";
import { ImpersonationInfo } from "./impersonation/ImpersonationInfo";
import { IUserService, UserService } from "@domain/services/IUserService";
import { useCompanyDataViewModel } from "@pages/profile/hooks/useCompanyDataViewModel";

export const Layout = () => {
  const { pathname } = useLocation();
  const { showLayout } = useAppNavigation();
  const { isPortalAggrego } = useHandlePortal();
  const { currentCompany, multimedia } = useCompanyDataViewModel();
  const userService = useResolve<IUserService>(UserService);

  const [isToolbarFirstLoading, setIsToolbarFirstLoading] = useState(true);
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"), { noSsr: true });
  const isValidRoute =
    pathname.split("/")[1].length > 0 &&
    !pathname.split("/")[1].includes("recover-") &&
    !pathname.split("/")[1].includes(ROUTER_PATHS.warehouseViewer.substring(1));

  useCheckLoginStatus();

  useEffect(() => {
    if (sessionStorage.getItem("sessionToken") && (!userService.user?.companyImage || userService.user?.companyImage === "")) {
      userService.save(
        new User({
          ...userService.user,
          hasVersion: currentCompany?.fiscalId === TELTRONIC_FISCALID,
          companyImage: multimedia?.url
        } as Partial<User>)
      );
    }
  }, [pathname, multimedia]);

  return (
    <React.Fragment>
      {matches && isValidRoute && !userService.user?.roles.includes("SUPER_ADMIN") && (
        <UserInfo userService={userService} fullUserMode={true} />
      )}
      {isPortalAggrego && <ImpersonationInfo />}
      {showLayout() && !matches && (
        <Toolbar
          key={UUIDGenerator()}
          isToolbarFirstLoading={isToolbarFirstLoading}
          setIsToolbarFirstLoading={setIsToolbarFirstLoading}
        />
      )}
      {showLayout() && matches && <Drawer key={UUIDGenerator()} />}
      <Snackbar />
    </React.Fragment>
  );
};
