/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AttributeInfoDTO,
  CustomOperatorFilter,
  MovicodersBadRequestException,
  MovicodersNotFoundException,
  MovicodersServiceException,
  OperatorDTO,
  OperatorPageDTO,
  OperatorValidationRequestDTO,
} from '../models';
import {
    AttributeInfoDTOFromJSON,
    AttributeInfoDTOToJSON,
    CustomOperatorFilterFromJSON,
    CustomOperatorFilterToJSON,
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
    OperatorDTOFromJSON,
    OperatorDTOToJSON,
    OperatorPageDTOFromJSON,
    OperatorPageDTOToJSON,
    OperatorValidationRequestDTOFromJSON,
    OperatorValidationRequestDTOToJSON,
} from '../models';

export interface GetOperatorByIdRequest {
    id: string;
    xTenantId?: string;
}

export interface ListOperatorsRequest {
    xTenantId?: string;
}

export interface ListOperatorsPaginatedRequest {
    limit: number;
    offset: number;
    xTenantId?: string;
    sortField?: ListOperatorsPaginatedSortFieldEnum;
    sortDirection?: ListOperatorsPaginatedSortDirectionEnum;
    customOperatorFilter?: CustomOperatorFilter;
}

export interface SaveOperatorRequest {
    xTenantId?: string;
    operatorDTO?: OperatorDTO;
}

export interface ToggleOperatorRequest {
    id: string;
    xTenantId?: string;
    attributeInfoDTO?: AttributeInfoDTO;
}

export interface UpdateOperatorRequest {
    xTenantId?: string;
    operatorDTO?: OperatorDTO;
}

export interface ValidateOperatorRequest {
    xTenantId?: string;
    operatorValidationRequestDTO?: OperatorValidationRequestDTO;
}

/**
 * 
 */
export class OperatorsApi extends runtime.BaseAPI {

    /**
     * Returns one operator object by id.
     * Get an operator object by id.
     */
    async getOperatorByIdRaw(requestParameters: GetOperatorByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OperatorDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOperatorById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/operators/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OperatorDTOFromJSON(jsonValue));
    }

    /**
     * Returns one operator object by id.
     * Get an operator object by id.
     */
    async getOperatorById(requestParameters: GetOperatorByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OperatorDTO> {
        const response = await this.getOperatorByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * No filter or order is applied
     * Returns the list of all registered Operators
     */
    async listOperatorsRaw(requestParameters: ListOperatorsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OperatorDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/operators`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OperatorDTOFromJSON));
    }

    /**
     * No filter or order is applied
     * Returns the list of all registered Operators
     */
    async listOperators(requestParameters: ListOperatorsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OperatorDTO>> {
        const response = await this.listOperatorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
     * Returns the list of all registered operators paginated
     */
    async listOperatorsPaginatedRaw(requestParameters: ListOperatorsPaginatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OperatorPageDTO>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling listOperatorsPaginated.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling listOperatorsPaginated.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.sortDirection !== undefined) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/operators/paginated`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomOperatorFilterToJSON(requestParameters.customOperatorFilter),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OperatorPageDTOFromJSON(jsonValue));
    }

    /**
     * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
     * Returns the list of all registered operators paginated
     */
    async listOperatorsPaginated(requestParameters: ListOperatorsPaginatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OperatorPageDTO> {
        const response = await this.listOperatorsPaginatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the registered Operator
     */
    async saveOperatorRaw(requestParameters: SaveOperatorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OperatorDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/operators`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OperatorDTOToJSON(requestParameters.operatorDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OperatorDTOFromJSON(jsonValue));
    }

    /**
     * Returns the registered Operator
     */
    async saveOperator(requestParameters: SaveOperatorRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OperatorDTO> {
        const response = await this.saveOperatorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Toggles the active attribute to TRUE/FALSE
     */
    async toggleOperatorRaw(requestParameters: ToggleOperatorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OperatorDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling toggleOperator.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/operators/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AttributeInfoDTOToJSON(requestParameters.attributeInfoDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OperatorDTOFromJSON(jsonValue));
    }

    /**
     * Toggles the active attribute to TRUE/FALSE
     */
    async toggleOperator(requestParameters: ToggleOperatorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OperatorDTO> {
        const response = await this.toggleOperatorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the updated Operator. To change the password, the new value must be sent in the object, otherwise, the field must be sent empty.
     */
    async updateOperatorRaw(requestParameters: UpdateOperatorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OperatorDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/operators`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OperatorDTOToJSON(requestParameters.operatorDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OperatorDTOFromJSON(jsonValue));
    }

    /**
     * Returns the updated Operator. To change the password, the new value must be sent in the object, otherwise, the field must be sent empty.
     */
    async updateOperator(requestParameters: UpdateOperatorRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OperatorDTO> {
        const response = await this.updateOperatorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Validate an Operator - action is allowed also for MOB_USER and MOB_ADMIN
     */
    async validateOperatorRaw(requestParameters: ValidateOperatorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OperatorDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
            headerParameters['X-Tenant-Id'] = String(requestParameters.xTenantId);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/operators/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OperatorValidationRequestDTOToJSON(requestParameters.operatorValidationRequestDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OperatorDTOFromJSON(jsonValue));
    }

    /**
     * Validate an Operator - action is allowed also for MOB_USER and MOB_ADMIN
     */
    async validateOperator(requestParameters: ValidateOperatorRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OperatorDTO> {
        const response = await this.validateOperatorRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ListOperatorsPaginatedSortFieldEnum = {
    Code: 'CODE',
    Firstname: 'FIRSTNAME',
    Firstsurname: 'FIRSTSURNAME',
    Secondsurname: 'SECONDSURNAME',
    Status: 'STATUS'
} as const;
export type ListOperatorsPaginatedSortFieldEnum = typeof ListOperatorsPaginatedSortFieldEnum[keyof typeof ListOperatorsPaginatedSortFieldEnum];
/**
 * @export
 */
export const ListOperatorsPaginatedSortDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type ListOperatorsPaginatedSortDirectionEnum = typeof ListOperatorsPaginatedSortDirectionEnum[keyof typeof ListOperatorsPaginatedSortDirectionEnum];
