import { t } from "i18next";
import Family from "@domain/model/Family";
import Material from "@domain/model/Material";
import { DomainPage } from "@movicoders/domain";
import { materialColumns } from "../components/material-columns";
import { useHandleCardQueries } from "@hooks/useHandleCardQueries";
import { GridSortModel, GridValidRowModel } from "@mui/x-data-grid";
import { CardDisplay, PDFCardDisplay } from "../components/card-display";
import { DataGridDisplay } from "@components/data-grid-display/data-grid-display";
import { ChangeDisplayButton } from "@components/data-grid-display/toolbar/components/ChangeDisplayButton";

interface IMaterialsTable {
  materialData: DomainPage<Material>;
  families: Family[];
  handleEdit: (id: string) => void;
  handleActiveConfirm: (material: Material) => void;
  handleStatusFilter: () => boolean;
  sortByFieldBackend: (model: GridSortModel) => void;
  handlePagination: (
    limit: number,
    offset: number,
    status?: "ACTIVE" | "INACTIVE" | "ALL" | undefined,
    filters?: object | undefined
  ) => void;
  searchBarFilter: string;
}

const MaterialsViewTable = (props: IMaterialsTable) => {
  const {
    materialData,
    families,
    handleEdit,
    handleActiveConfirm,
    sortByFieldBackend,
    handleStatusFilter,
    handlePagination,
    searchBarFilter
  } = props;

  const { isCardDisplay, setIsCardDisplay, matchesMD } = useHandleCardQueries();

  return (
    <DataGridDisplay
      data={(materialData.content as GridValidRowModel[]) ?? []}
      columns={materialColumns(t, handleEdit, handleActiveConfirm, matchesMD)}
      mode="server"
      viewName="materials"
      hideColumnsByField={handleStatusFilter() ? ["id", "actions"] : ["id"]}
      handleBackendPagination={handlePagination}
      backendTotalElements={materialData.totalElements ?? 0}
      handleBackendSort={sortByFieldBackend}
      customDisplayComponent={data =>
        isCardDisplay
          ? CardDisplay({
              gridData: data,
              handleActiveConfirm: handleActiveConfirm,
              handleEdit: handleEdit,
              families: families
            })
          : undefined
      }
      customPDF={
        isCardDisplay
          ? ({ data, tableName }) =>
              PDFCardDisplay({
                gridData: data,
                handleActiveConfirm: handleActiveConfirm,
                handleEdit: handleEdit,
                families: families,
                tableName: tableName
              })
          : undefined
      }
      toolbarProps={{
        multipleSelectOptions: new Map<string, string[]>([
          ["family", families.sort((a, b) => t(a?.name).localeCompare(t(b?.name))).map(family => family.name) ?? []]
        ]),
        isActiveFilterHidden: handleStatusFilter(),
        disableColumnsVisibilityByField: ["id"],
        disableFilterByFields: ["id", "actions", "status"],
        disableFieldsInDownload: ["id", "actions"],
        addHTMLRight: ChangeDisplayButton({ isCardDisplay: isCardDisplay, setIsCardDisplay: setIsCardDisplay }),
        parseCSVInDownload: data => {
          const dataForCsv = { ...data };
          let concatNames = "";
          dataForCsv.families.forEach((family: Family, index: number) => {
            concatNames += `${family.name}, `;
          });
          concatNames = concatNames.slice(0, -2);
          dataForCsv.family = concatNames ?? t("material.family.unassigned");
          dataForCsv.status = dataForCsv.active ? t("material.active") : t("material.inactive");
          return dataForCsv;
        }
      }}
      searchBarFilter={searchBarFilter}
    />
  );
};

export default MaterialsViewTable;
