import { useSnackbar } from "@movicoders/ui";
import { useResolve } from "@movicoders/di";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { RecoverPasswordUseCase } from "@application/recover-password/recover-password-use-case";

export const useRecoverPassword = () => {
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const DEBOUNCE_TIME = 500;
  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);
  const [disabled, setDisabled] = useState(false);
  const { show } = useSnackbar();
  const recoverUseCase = useResolve(RecoverPasswordUseCase);

  const handleUsernameChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handleConfirm = () => {
    setDisabled(true);
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    const newDebounceTimer = setTimeout(() => {
      const userField = !username.trim();

      setUsernameError(userField);

      if (userField) {
        setDisabled(false);
        return;
      }

      recoverUseCase
        .execute(username)
        .then(res => {
          res ? show(t("recover.snackbar"), "success") : show(t("recover.snackbar.error"), "error");
        })
        .catch(() => {
          show(t("recover.snackbar.error"), "error");
        })
        .finally(() => {
          setDisabled(false);
        });
    }, DEBOUNCE_TIME);
    setDebounceTimer(newDebounceTimer);
  };

  return {
    handleUsernameChange,
    handleConfirm,
    usernameError,
    disabled
  };
};
