import { Grid } from "@mui/material";
import Zone from "@domain/model/Zone";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AggregoDialog } from "@components/master-data-view/dialog/AggregoDialog";
import { ButtonsDetail } from "@components/master-data-view/buttons/buttons-detail";

const ZoneActivationDialog = (props: { open: boolean; onClose: () => void; onActivation: (zone: Zone) => void; zone: Zone }) => {
  const { t } = useTranslation();
  const { open, zone, onClose, onActivation } = props;

  const [pendingRequest, setPendingRequest] = useState<boolean>(false);

  useEffect(() => {
    setPendingRequest(false);
  }, [zone]);

  return open ? (
    <AggregoDialog
      title={(zone.active ? t("zones.deactivate.title") : t("zones.active.title")) + zone.name + "?"}
      onClose={onClose}>
      <Grid container flexDirection="column" className="masterdata-box" rowSpacing={2}>
        <Grid item>
          <div className="confirm-dialog-div-content">{zone.active ? t("zones.form.deactivate") : t("zones.form.activate")}</div>
        </Grid>

        <Grid item>
          <ButtonsDetail
            handleClose={onClose}
            handleSave={() => {
              setPendingRequest(true);
              onActivation(zone);
            }}
            mode={zone.active ? "ACTIVE" : "INACTIVE"}
            pendingRequest={pendingRequest}
          />
        </Grid>
      </Grid>
    </AggregoDialog>
  ) : (
    <></>
  );
};

export default ZoneActivationDialog;
