import { useResolve } from "@movicoders/di";
import FamilyType from "@domain/model/FamilyType";
import { useEffect, useRef, useState } from "react";
import { LastPageState } from "@domain/model/LastPageState";
import { useListDetailPaginatedViewModel } from "@movicoders/ui";
import { FamilyRepository } from "@infrastructure/repositories/family-repository";
import { ILastPageService, LastPageService } from "@domain/services/ILastPageService";
import { FindTypeOfFamiliesByTenantUseCase } from "@application/master-data/type-of-families/find-type-of-family-by-tenant-use-case";
import { MasterDataEnum } from "@pages/settings/settings-interfaces";
import { ISettingsService, SettingsService } from "@domain/services/ISettingsService";

export const useFamilyHandleData = () => {
  const {
    loading,
    loadingDetail,
    data,
    selected: selectedFamily,
    create,
    fetchOne,
    goToList,
    getPaginated
  } = useListDetailPaginatedViewModel(FamilyRepository);

  const getFamiliesPaginated = useRef(getPaginated).current;
  const currentPageService = useResolve<ILastPageService>(LastPageService);
  const settingsService = useResolve<ISettingsService>(SettingsService);

  const currentPagePersistedState = currentPageService.persistedState ?? new LastPageState();

  const findTypeOfFamiliesByTenantUseCase = useResolve(FindTypeOfFamiliesByTenantUseCase);
  const [dataTypesOfFamilies, setDataTypesOfFamilies] = useState<FamilyType[]>([]);

  useEffect(() => {
    if (settingsService.persistedState?.masterdataPermissions.includes(MasterDataEnum.Families)) {
      findTypeOfFamiliesByTenantUseCase.execute("ALL").then(result => {
        setDataTypesOfFamilies(result);
      });
    }
  }, [settingsService.persistedState]);

  return {
    currentPageService,
    currentPagePersistedState,
    loading,
    loadingDetail,
    families: data,
    selectedFamily,
    dataTypesOfFamilies,
    setDataTypesOfFamilies,
    findTypeOfFamiliesByTenantUseCase,
    getFamiliesPaginated,
    goToCreate: create,
    goToDetail: fetchOne,
    goToList
  };
};
