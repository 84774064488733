import { useResolve } from "@movicoders/di";
import { TileRepository } from "@infrastructure/repositories/tile-repository";
import { ZoneRepository } from "@infrastructure/repositories/zone-repository";
import { UserRepository } from "@infrastructure/repositories/user-repository";
import { MaterialRepository } from "@infrastructure/repositories/material-repository";
import { LocationRepository } from "@infrastructure/repositories/location-repository";
import { ContainerRepository } from "@infrastructure/repositories/container-repository";

export const useHomeAutocompleteRepositories = () => {
  //Materials
  const materialRepository = useResolve<MaterialRepository>(MaterialRepository);

  //Locations
  const locationRepository = useResolve<LocationRepository>(LocationRepository);
  const tileRepository = useResolve<TileRepository>(TileRepository);
  const zoneRepository = useResolve<ZoneRepository>(ZoneRepository);
  const userRepository = useResolve<UserRepository>(UserRepository);
  const containerRepository = useResolve<ContainerRepository>(ContainerRepository);

  return {
    materialRepository,
    locationRepository,
    tileRepository,
    zoneRepository,
    userRepository,
    containerRepository
  };
};
