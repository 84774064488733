/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  BasicFilterDTO,
  FamilyTypeDTO,
  FamilyTypePageDTO,
  ListResponseDTO,
  MovicodersBadRequestException,
  MovicodersNotFoundException,
  MovicodersServiceException
} from "../models";
import {
  BasicFilterDTOFromJSON,
  BasicFilterDTOToJSON,
  FamilyTypeDTOFromJSON,
  FamilyTypeDTOToJSON,
  FamilyTypePageDTOFromJSON,
  FamilyTypePageDTOToJSON,
  ListResponseDTOFromJSON,
  ListResponseDTOToJSON,
  MovicodersBadRequestExceptionFromJSON,
  MovicodersBadRequestExceptionToJSON,
  MovicodersNotFoundExceptionFromJSON,
  MovicodersNotFoundExceptionToJSON,
  MovicodersServiceExceptionFromJSON,
  MovicodersServiceExceptionToJSON
} from "../models";

export interface DeleteFamilyTypeRequest {
  id: string;
  xTenantId?: string;
}

export interface DeleteFamilyTypesRequest {
  xTenantId?: string;
  requestBody?: Array<string>;
}

export interface GetFamilyTypeByIdRequest {
  id: string;
  fullDTO: boolean;
  xTenantId?: string;
}

export interface GetFamilyTypeByIdsRequest {
  xTenantId?: string;
  requestBody?: Array<string>;
}

export interface GetFamilyTypesByNameRequest {
  name: string;
  xTenantId?: string;
}

export interface ListFamilyTypesRequest {
  fullDTO: boolean;
  status: ListFamilyTypesStatusEnum;
  xTenantId?: string;
}

export interface ListFamilyTypesByTenantIdRequest {
  fullDTO: boolean;
  tenantId: string;
  status: ListFamilyTypesByTenantIdStatusEnum;
  xTenantId?: string;
}

export interface ListFamilyTypesPaginatedRequest {
  limit: number;
  offset: number;
  xTenantId?: string;
  sortField?: ListFamilyTypesPaginatedSortFieldEnum;
  sortDirection?: ListFamilyTypesPaginatedSortDirectionEnum;
  basicFilterDTO?: BasicFilterDTO;
}

export interface SaveFamilyTypeRequest {
  xTenantId?: string;
  familyTypeDTO?: FamilyTypeDTO;
}

export interface SaveFamilyTypesRequest {
  xTenantId?: string;
  familyTypeDTO?: Array<FamilyTypeDTO>;
}

export interface UpdateFamilyTypeRequest {
  xTenantId?: string;
  familyTypeDTO?: FamilyTypeDTO;
}

/**
 *
 */
export class FamilyTypesApi extends runtime.BaseAPI {
  /**
   * Takes an existing familytype, deletes it, and returns the new object.
   * Delete an existing familytype by id.
   */
  async deleteFamilyTypeRaw(
    requestParameters: DeleteFamilyTypeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<FamilyTypeDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteFamilyType."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/family-types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => FamilyTypeDTOFromJSON(jsonValue));
  }

  /**
   * Takes an existing familytype, deletes it, and returns the new object.
   * Delete an existing familytype by id.
   */
  async deleteFamilyType(
    requestParameters: DeleteFamilyTypeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<FamilyTypeDTO> {
    const response = await this.deleteFamilyTypeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the deleted FamilyTypes
   */
  async deleteFamilyTypesRaw(
    requestParameters: DeleteFamilyTypesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<FamilyTypeDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/family-types/delete-many`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(FamilyTypeDTOFromJSON));
  }

  /**
   * Returns the deleted FamilyTypes
   */
  async deleteFamilyTypes(
    requestParameters: DeleteFamilyTypesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<FamilyTypeDTO>> {
    const response = await this.deleteFamilyTypesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns one familytype object by id.
   * Get a familytype object by id.
   */
  async getFamilyTypeByIdRaw(
    requestParameters: GetFamilyTypeByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<FamilyTypeDTO>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getFamilyTypeById."
      );
    }

    if (requestParameters.fullDTO === null || requestParameters.fullDTO === undefined) {
      throw new runtime.RequiredError(
        "fullDTO",
        "Required parameter requestParameters.fullDTO was null or undefined when calling getFamilyTypeById."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fullDTO !== undefined) {
      queryParameters["fullDTO"] = requestParameters.fullDTO;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/family-types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => FamilyTypeDTOFromJSON(jsonValue));
  }

  /**
   * Returns one familytype object by id.
   * Get a familytype object by id.
   */
  async getFamilyTypeById(
    requestParameters: GetFamilyTypeByIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<FamilyTypeDTO> {
    const response = await this.getFamilyTypeByIdRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns familytype objects by ids.
   * Get a familytype object by id.
   */
  async getFamilyTypeByIdsRaw(
    requestParameters: GetFamilyTypeByIdsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<FamilyTypeDTO>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/family-types/find-many-by-id`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(FamilyTypeDTOFromJSON));
  }

  /**
   * Returns familytype objects by ids.
   * Get a familytype object by id.
   */
  async getFamilyTypeByIds(
    requestParameters: GetFamilyTypeByIdsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<FamilyTypeDTO>> {
    const response = await this.getFamilyTypeByIdsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns family type objects by name.
   * Get family type objects by name.
   */
  async getFamilyTypesByNameRaw(
    requestParameters: GetFamilyTypesByNameRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<FamilyTypeDTO>>> {
    if (requestParameters.name === null || requestParameters.name === undefined) {
      throw new runtime.RequiredError(
        "name",
        "Required parameter requestParameters.name was null or undefined when calling getFamilyTypesByName."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.name !== undefined) {
      queryParameters["name"] = requestParameters.name;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/family-types/name`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(FamilyTypeDTOFromJSON));
  }

  /**
   * Returns family type objects by name.
   * Get family type objects by name.
   */
  async getFamilyTypesByName(
    requestParameters: GetFamilyTypesByNameRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<FamilyTypeDTO>> {
    const response = await this.getFamilyTypesByNameRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered FamilyTypes
   */
  async listFamilyTypesRaw(
    requestParameters: ListFamilyTypesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<FamilyTypeDTO>>> {
    if (requestParameters.fullDTO === null || requestParameters.fullDTO === undefined) {
      throw new runtime.RequiredError(
        "fullDTO",
        "Required parameter requestParameters.fullDTO was null or undefined when calling listFamilyTypes."
      );
    }

    if (requestParameters.status === null || requestParameters.status === undefined) {
      throw new runtime.RequiredError(
        "status",
        "Required parameter requestParameters.status was null or undefined when calling listFamilyTypes."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fullDTO !== undefined) {
      queryParameters["fullDTO"] = requestParameters.fullDTO;
    }

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/family-types`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(FamilyTypeDTOFromJSON));
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered FamilyTypes
   */
  async listFamilyTypes(
    requestParameters: ListFamilyTypesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<FamilyTypeDTO>> {
    const response = await this.listFamilyTypesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Return FamilyTypes by tenantId.
   * Returns the list of all registered FamilyTypes by tenantId
   */
  async listFamilyTypesByTenantIdRaw(
    requestParameters: ListFamilyTypesByTenantIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<FamilyTypeDTO>>> {
    if (requestParameters.fullDTO === null || requestParameters.fullDTO === undefined) {
      throw new runtime.RequiredError(
        "fullDTO",
        "Required parameter requestParameters.fullDTO was null or undefined when calling listFamilyTypesByTenantId."
      );
    }

    if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
      throw new runtime.RequiredError(
        "tenantId",
        "Required parameter requestParameters.tenantId was null or undefined when calling listFamilyTypesByTenantId."
      );
    }

    if (requestParameters.status === null || requestParameters.status === undefined) {
      throw new runtime.RequiredError(
        "status",
        "Required parameter requestParameters.status was null or undefined when calling listFamilyTypesByTenantId."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.fullDTO !== undefined) {
      queryParameters["fullDTO"] = requestParameters.fullDTO;
    }

    if (requestParameters.tenantId !== undefined) {
      queryParameters["tenantId"] = requestParameters.tenantId;
    }

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/family-types/findByTenantId`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(FamilyTypeDTOFromJSON));
  }

  /**
   * Return FamilyTypes by tenantId.
   * Returns the list of all registered FamilyTypes by tenantId
   */
  async listFamilyTypesByTenantId(
    requestParameters: ListFamilyTypesByTenantIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<FamilyTypeDTO>> {
    const response = await this.listFamilyTypesByTenantIdRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered FamilyTypes paginated
   */
  async listFamilyTypesPaginatedRaw(
    requestParameters: ListFamilyTypesPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<FamilyTypePageDTO>> {
    if (requestParameters.limit === null || requestParameters.limit === undefined) {
      throw new runtime.RequiredError(
        "limit",
        "Required parameter requestParameters.limit was null or undefined when calling listFamilyTypesPaginated."
      );
    }

    if (requestParameters.offset === null || requestParameters.offset === undefined) {
      throw new runtime.RequiredError(
        "offset",
        "Required parameter requestParameters.offset was null or undefined when calling listFamilyTypesPaginated."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.sortField !== undefined) {
      queryParameters["sortField"] = requestParameters.sortField;
    }

    if (requestParameters.sortDirection !== undefined) {
      queryParameters["sortDirection"] = requestParameters.sortDirection;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/family-types/paginated`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BasicFilterDTOToJSON(requestParameters.basicFilterDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => FamilyTypePageDTOFromJSON(jsonValue));
  }

  /**
   * USER_PORTAL role returns only the active elements (filter is ignored), for SUPER_ADMIN and PORTAL_ADMIN roles, the filter is applied.
   * Returns the list of all registered FamilyTypes paginated
   */
  async listFamilyTypesPaginated(
    requestParameters: ListFamilyTypesPaginatedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<FamilyTypePageDTO> {
    const response = await this.listFamilyTypesPaginatedRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the registered FamilyType
   */
  async saveFamilyTypeRaw(
    requestParameters: SaveFamilyTypeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<FamilyTypeDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/family-types`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: FamilyTypeDTOToJSON(requestParameters.familyTypeDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => FamilyTypeDTOFromJSON(jsonValue));
  }

  /**
   * Returns the registered FamilyType
   */
  async saveFamilyType(
    requestParameters: SaveFamilyTypeRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<FamilyTypeDTO> {
    const response = await this.saveFamilyTypeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Returns the registered FamilyTypes
   */
  async saveFamilyTypesRaw(
    requestParameters: SaveFamilyTypesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ListResponseDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/family-types/add-many`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.familyTypeDTO?.map(FamilyTypeDTOToJSON)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => ListResponseDTOFromJSON(jsonValue));
  }

  /**
   * Returns the registered FamilyTypes
   */
  async saveFamilyTypes(
    requestParameters: SaveFamilyTypesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ListResponseDTO> {
    const response = await this.saveFamilyTypesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Takes an existing FamilyType object, updates it, and returns the FamilyType.
   * Update an existing FamilyType.
   */
  async updateFamilyTypeRaw(
    requestParameters: UpdateFamilyTypeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<FamilyTypeDTO>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xTenantId !== undefined && requestParameters.xTenantId !== null) {
      headerParameters["X-Tenant-Id"] = String(requestParameters.xTenantId);
    }

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
    }

    const response = await this.request(
      {
        path: `/family-types`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: FamilyTypeDTOToJSON(requestParameters.familyTypeDTO)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, jsonValue => FamilyTypeDTOFromJSON(jsonValue));
  }

  /**
   * Takes an existing FamilyType object, updates it, and returns the FamilyType.
   * Update an existing FamilyType.
   */
  async updateFamilyType(
    requestParameters: UpdateFamilyTypeRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<FamilyTypeDTO> {
    const response = await this.updateFamilyTypeRaw(requestParameters, initOverrides);
    return await response.value();
  }
}

/**
 * @export
 */
export const ListFamilyTypesStatusEnum = {
  Active: "ACTIVE",
  All: "ALL",
  Inactive: "INACTIVE"
} as const;
export type ListFamilyTypesStatusEnum = (typeof ListFamilyTypesStatusEnum)[keyof typeof ListFamilyTypesStatusEnum];
/**
 * @export
 */
export const ListFamilyTypesByTenantIdStatusEnum = {
  Active: "ACTIVE",
  All: "ALL",
  Inactive: "INACTIVE"
} as const;
export type ListFamilyTypesByTenantIdStatusEnum =
  (typeof ListFamilyTypesByTenantIdStatusEnum)[keyof typeof ListFamilyTypesByTenantIdStatusEnum];
/**
 * @export
 */
export const ListFamilyTypesPaginatedSortFieldEnum = {
  Name: "NAME",
  Status: "STATUS"
} as const;
export type ListFamilyTypesPaginatedSortFieldEnum =
  (typeof ListFamilyTypesPaginatedSortFieldEnum)[keyof typeof ListFamilyTypesPaginatedSortFieldEnum];
/**
 * @export
 */
export const ListFamilyTypesPaginatedSortDirectionEnum = {
  Asc: "ASC",
  Desc: "DESC"
} as const;
export type ListFamilyTypesPaginatedSortDirectionEnum =
  (typeof ListFamilyTypesPaginatedSortDirectionEnum)[keyof typeof ListFamilyTypesPaginatedSortDirectionEnum];
