import Zone from "@domain/model/Zone";
import {
  GetZonesByWarehouseIDRequest,
  ListZonesByTenantIdRequest,
  ListZonesPaginatedRequest,
  ZonesApi
} from "@clients/aggrego-proxy/apis/ZonesApi";
import { DomainPage } from "@movicoders/domain";
import { ZoneDTO } from "@clients/aggrego-proxy";
import { IImpersonationService } from "@domain/services/IImpersonationService";

export class ZoneApi {
  constructor(private readonly zoneApi: ZonesApi, private readonly impersonationService: IImpersonationService) {}
  async get(): Promise<Zone[]> {
    const result: Zone[] = [];

    const apiResult = await this.zoneApi.listZones({
      fullDTO: false,
      status: "ALL",
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
    apiResult.forEach(zoneClient => {
      result.push(Zone.fromDTO(zoneClient));
    });
    return result;
  }

  async getPaginated(pageCriteria: ListZonesPaginatedRequest): Promise<DomainPage<Zone>> {
    return (await this.zoneApi.listZonesPaginated({
      ...pageCriteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    })) as unknown as Promise<DomainPage<Zone>>;
  }

  async getById(id: string): Promise<Zone> {
    const result = Zone.fromDTO(
      await this.zoneApi.getZonesById({
        id: id,
        fullDTO: true,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return result;
  }

  async getByTenantId(criteria: ListZonesByTenantIdRequest): Promise<Zone[]> {
    const result: Zone[] = [];

    const apiResult = await this.zoneApi.listZonesByTenantId({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });

    apiResult.forEach((clientZone: ZoneDTO) => {
      result.push(Zone.fromDTO(clientZone));
    });
    return result;
  }

  async create(entity: Zone): Promise<Zone> {
    const result = Zone.fromDTO(
      await this.zoneApi.saveZone1({
        zoneDTO: entity,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return result;
  }

  async update(entity: Zone): Promise<Zone> {
    const result = Zone.fromDTO(
      await this.zoneApi.updateZones({
        zoneDTO: entity,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return result;
  }

  async remove(id: string): Promise<void> {
    await this.zoneApi.deleteZones({ id: id, xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? "" });
  }

  async getByWarehouseId(request: GetZonesByWarehouseIDRequest): Promise<Array<ZoneDTO>> {
    return await this.zoneApi.getZonesByWarehouseID({
      ...request,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }
}
