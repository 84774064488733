import { Typography, Box } from "@mui/material";
import { homeContentTitleStyles } from "./homeContentTitleStyles";
import { useTranslation } from "react-i18next";

export const HomeContentTitle = (props: { name: string }) => {
  const { t } = useTranslation();
  const { name } = props;
  const styles = homeContentTitleStyles();
  return (
    <Box component="div" sx={styles.title_summary_container}>
      <Typography variant="summarydashboard" textAlign="left" marginTop={"0.5rem"}>
        {t(name)}
      </Typography>
    </Box>
  );
};
