import { useState } from "react";
import { IMMaterialEntryDTO } from "@clients/aggrego-proxy";

export const useEntriesValidations = (createdIMMaterial: IMMaterialEntryDTO) => {
  const [formErrors, setFormErrors] = useState<Map<string, boolean>>(
    new Map([
      ["material-code", false],
      ["material-qty", false]
    ])
  );

  const validateFormValues = () => {
    const materialValid =
      createdIMMaterial.materialCode !== null &&
      createdIMMaterial.materialCode !== undefined &&
      createdIMMaterial.materialCode !== "";

    const qtyValid = createdIMMaterial.qty !== null && createdIMMaterial.qty !== undefined && createdIMMaterial.qty > 0;

    setFormErrors(
      new Map([
        ["material-code", !materialValid],
        ["material-qty", !qtyValid]
      ])
    );
    return materialValid && qtyValid;
  };

  return {
    validateFormValues,
    formErrors
  };
};
