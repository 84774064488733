import { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import Stakeholder from "@domain/model/Stakeholder";
import { Autocomplete, TextField } from "@mui/material";
import { ProviderDTOPaymentTermsEnum } from "@clients/aggrego-proxy";

interface StakeHolderAutocompleteProps {
  disabled: boolean;
  modifiedStakeholder: Stakeholder;
  onChangeAutocomplete: (event: SyntheticEvent<Element, Event>, value: string | null) => void;
}

export const StakeholderPaymenMethodAutocomplete = (props: StakeHolderAutocompleteProps) => {
  const { t } = useTranslation();
  const { disabled, modifiedStakeholder, onChangeAutocomplete } = props;

  const paymentMethodOptions = [
    ProviderDTOPaymentTermsEnum.Net30,
    ProviderDTOPaymentTermsEnum.Net60,
    ProviderDTOPaymentTermsEnum.Net90,
    ProviderDTOPaymentTermsEnum.Cash
  ];

  return (
    <Autocomplete
      id="stakeholder-paymentMethod-autocomplete"
      value={modifiedStakeholder.paymentTerms ?? ProviderDTOPaymentTermsEnum.Net30}
      options={paymentMethodOptions}
      disableClearable
      disabled={disabled}
      getOptionLabel={(option: string) => {
        if (option === ProviderDTOPaymentTermsEnum.Net30) {
          return t("payment.method.30");
        } else if (option === ProviderDTOPaymentTermsEnum.Net60) {
          return t("payment.method.60");
        } else if (option === ProviderDTOPaymentTermsEnum.Net90) {
          return t("payment.method.90");
        } else {
          return t("payment.method.cash");
        }
      }}
      onChange={(event, value) => {
        onChangeAutocomplete(event, value);
      }}
      renderInput={params => (
        <TextField
          name={"paymentTerms"}
          {...params}
          label={t("stakeholders.paymentTerms")}
          className={
            modifiedStakeholder?.active !== undefined && modifiedStakeholder.active === false
              ? "disabled-input"
              : "blue-border-input"
          }
          disabled={disabled}
          InputProps={{
            ...params.InputProps
          }}
        />
      )}
    />
  );
};
