import { useState } from "react";
import { StockDTO } from "../../../../../../domain/model/Stock";
import { StockReportDTO } from "../../../../../../clients/aggrego-proxy";
import { StockRepository } from "../../../../../../infrastructure/repositories/stock-repository";

export const useMaterialsStockDialog = (stockRepository: StockRepository) => {
  const [materialDetailOpen, setMaterialDetailOpen] = useState(false);
  const [exportingPaginatedData, setExportingPaginatedData] = useState<StockReportDTO>();

  const handleDialogOpen = async (material: StockDTO) => {
    const result = await stockRepository.getByMaterial({ materialCode: material?.materialCode ?? "" });
    setExportingPaginatedData(result);
    setMaterialDetailOpen(!materialDetailOpen);
  };

  const onCloseDialog = () => {
    setMaterialDetailOpen(false);
  };

  return {
    materialDetailOpen,
    exportingPaginatedData,
    handleDialogOpen,
    onCloseDialog
  };
};
