/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    codeEmail?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoginResponse
     */
    enabledNFAEmail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoginResponse
     */
    enabledNFAOPT?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    secretNFA?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    token?: string;
}

/**
 * Check if a given object implements the LoginResponse interface.
 */
export function instanceOfLoginResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LoginResponseFromJSON(json: any): LoginResponse {
    return LoginResponseFromJSONTyped(json, false);
}

export function LoginResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'codeEmail': !exists(json, 'codeEmail') ? undefined : json['codeEmail'],
        'enabledNFAEmail': !exists(json, 'enabledNFAEmail') ? undefined : json['enabledNFAEmail'],
        'enabledNFAOPT': !exists(json, 'enabledNFAOPT') ? undefined : json['enabledNFAOPT'],
        'secretNFA': !exists(json, 'secretNFA') ? undefined : json['secretNFA'],
        'token': !exists(json, 'token') ? undefined : json['token'],
    };
}

export function LoginResponseToJSON(value?: LoginResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'codeEmail': value.codeEmail,
        'enabledNFAEmail': value.enabledNFAEmail,
        'enabledNFAOPT': value.enabledNFAOPT,
        'secretNFA': value.secretNFA,
        'token': value.token,
    };
}

