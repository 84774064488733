// Disabled because elements from ThreeJS that start with lowercase
// are not allowed by eslint no-unknown-property rule.
/* eslint-disable react/no-unknown-property */
import { useFrame } from "@react-three/fiber";
import { BoxGeometry, Mesh, MeshBasicMaterial } from "three";
import { useRef } from "react";
import { Text } from "@react-three/drei";
import { COLORS } from "../../../../../theme";
import { BoxLineGeometry } from "three-stdlib";

export const RotatingCube = () => {
  const boxGeometry = new BoxGeometry(2, 2, 2);
  const boxMaterial = new MeshBasicMaterial({
    color: COLORS.secondary
  });
  const helperGeometry = new BoxLineGeometry(2.01, 2.01, 2.01);
  const helperMaterial = new MeshBasicMaterial({
    color: COLORS.white
  });
  const boxRef = useRef<Mesh>(null);

  useFrame(() => {
    if (boxRef.current)
      boxRef.current.rotation.y += 0.02;
  });

  return (
    <group>
      <mesh
        ref={boxRef}
        position={[0, 0, 0]}
        geometry={boxGeometry}
        material={boxMaterial}
      >
        <boxHelper geometry={helperGeometry} material={helperMaterial} />
      </mesh>
      <group position={[0, -0.1, 2.5]}>
        <Text color={COLORS.white}>
          A
        </Text>
        <Text fontSize={0.2} position={[0.3, 0.4, 0]} color={COLORS.white}>
          LS
        </Text>
        <Text fontSize={0.2} position={[0, -1, 0]} color={COLORS.secondary}>
          Loading, please wait...
        </Text>
      </group>
    </group>
  );
}