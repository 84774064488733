import { OrbitControls as OrbitControlsType } from "three-stdlib";
import { Vector3 } from "three";
import { useFrame } from "@react-three/fiber";
import { RefObject } from "react";


interface ITargetHandler {
  target: Vector3 | undefined;
  setTarget: (target: Vector3 | undefined) => void;
  controls: RefObject<OrbitControlsType>;
}

// useFrame needs to be inside a component inside a canvas to work properly.
export const ClickAnimationProvider = ({ target, controls, setTarget }: ITargetHandler) => {

  const valToChange = 0.1;

  const doGetNewPos = (newPosValue: number, targetValue: number) => {
    if ((Math.abs(newPosValue - targetValue) < valToChange) || (Math.abs(targetValue - newPosValue) < valToChange))
      return targetValue;
    else if (newPosValue > targetValue)
      return newPosValue - valToChange
    else
      return newPosValue + valToChange;
  };

  useFrame(() => {
    if (target) {
      const tempControls = controls.current as OrbitControlsType;
      const newPos = tempControls.object.position;

      // Set new position values
      if (tempControls.object.position.x !== target.x)
        newPos.setX(doGetNewPos(newPos.x, target.x));

      if (tempControls.object.position.y !== target.y)
        newPos.setY(doGetNewPos(newPos.y, target.y));

      if (tempControls.object.position.z !== target.z)
        newPos.setZ(doGetNewPos(newPos.z, target.z));

      //Move the camera inside the controls.
      (controls.current as OrbitControlsType).object.position.copy(newPos);

      //Change the target of the controls to the current target.
      if (newPos.equals(target))
        setTarget(undefined);
    }
  });
  return null;
}