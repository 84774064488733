import { useResolve } from "@movicoders/di";
import { useRef, useEffect } from "react";
import { Group, Matrix4, Quaternion, Vector3 } from "three";
import { ICommon3DObjectsStore } from "../../stores/common-3d-objects-store/ICommon3DObjectsStore";
import { useCommon3DObjectsStore } from "../../stores/common-3d-objects-store/common-3d-objects-store";
import { IEditionStatesStore } from "../../stores/edition-states-store/IEditionStatesStore";
import { useEditionStatesStore } from "../../stores/edition-states-store/edition-states-store";
import { useDragEndHelpers } from "../../utils/drag-end-helpers";
import { useHandleMoveTiles } from "../../utils/tile-helpers/move-tiles-helper";
import { useThree } from "@react-three/fiber";
import { IWarehouse3DStore } from "../../stores/warehouse-3d-store/IWarehouse3DStore";
import { useWarehouse3DStore } from "../../stores/warehouse-3d-store/warehouse-3d-store";

export const useCustomPivotControlsController = () => {
  const { scene } = useThree()
  const { handleMoveTiles } = useHandleMoveTiles(scene)
  const { getWarehouse } = useResolve<IWarehouse3DStore>(useWarehouse3DStore);
  const {
    getSelectedMode,
    getSelectedTileTemplate,
    getModifyMaxLevel,
    getModifyMinMax
  } = useResolve<IEditionStatesStore>(useEditionStatesStore);
  const {
    setPivotControls,
    setPivotControlsContent,
    getTilesBeingModified,
    getStartPoint,
    getEndPoint,
    setEndPointPosition
  } = useResolve<ICommon3DObjectsStore>(useCommon3DObjectsStore);
  const {
    showCreatedTiles,
    showCreatedTemplates,
    showReducedTiles,
    showEditedTiles
  } = useDragEndHelpers(scene)

  // Pivot object and its content references.
  const pivotRef = useRef<Group>(null);
  const pivotContentRef = useRef<Group>(null);

  // PivotControls Matrix
  const pivotMatrix = new Matrix4();

  const canDragInCreationOrEdition = (pos: Vector3) => {
    const endPoint = getEndPoint();
    const startPoint = getStartPoint();

    return endPoint !== undefined &&
      startPoint !== undefined &&
      startPoint.position.x + Math.floor(pos.x) > 0 &&
      startPoint.position.x + Math.floor(pos.x) <= (getWarehouse().sizeX ?? 0) &&
      startPoint.position.z + Math.floor(pos.z) > 0 &&
      startPoint.position.z + Math.floor(pos.z) <= (getWarehouse().sizeZ ?? 0) &&
      startPoint.position.y + Math.floor(pos.y) >= 0 &&
      startPoint.position.y + Math.floor(pos.y) < (getWarehouse().maxSizeY ?? 0);
  };

  const canDragInDeletion = (pos: Vector3) => {
    const endPoint = getEndPoint();

    return endPoint !== undefined &&
      getTilesBeingModified().length > 0 &&
      getModifyMaxLevel() + Math.floor(pos.y) > 0 &&
      endPoint.position.y + Math.floor(pos.y) < getModifyMaxLevel();
  };

  const canDragInMovement = (pos: Vector3) => {
    return getTilesBeingModified().length > 0 &&
      getModifyMinMax().minX + Math.floor(pos.x) > 0 &&
      getModifyMinMax().maxX + Math.floor(pos.x) <= (getWarehouse().sizeX ?? 0) &&
      getModifyMinMax().minZ + Math.floor(pos.z) > 0 &&
      getModifyMinMax().maxZ + Math.floor(pos.z) <= (getWarehouse().sizeZ ?? 0)
  };

  const handleDrag = (m: Matrix4) => {
    const pos = new Vector3();
    const rotation = new Quaternion();
    const scale = new Vector3();
    m.decompose(pos, rotation, scale);
    const endPoint = getEndPoint();
    const startPoint = getStartPoint();

    switch (true) {
      case getSelectedMode() === "CREATE" ||
        (getSelectedMode() === "EDIT" && getTilesBeingModified().length > 0 && Math.floor(pos.y) >= 0):
        if (endPoint !== undefined && startPoint !== undefined && canDragInCreationOrEdition(pos)) {
          const newPos = m.compose(pos.floor(), rotation, scale.floor());
          pivotRef.current?.matrix.copyPosition(newPos);
          const newEndPointPos = new Vector3(
            startPoint.position.x + Math.floor(pos.x),
            startPoint.position.y + Math.floor(pos.y),
            startPoint.position.z + Math.floor(pos.z)
          );
          setEndPointPosition(newEndPointPos);
        }
        break;
      case getSelectedMode() === "DELETE":
        if (endPoint !== undefined && canDragInDeletion(pos)) {
          const newPos = m.compose(pos.floor(), rotation, scale);
          pivotRef.current?.matrix.copyPosition(newPos);
          setEndPointPosition(new Vector3(
            endPoint.position.x,
            Math.floor(pos.y) + getModifyMaxLevel() - 1,
            endPoint.position.z
          ))
        }
        break;
      case getSelectedMode() === "MOVE":
        if (canDragInMovement(pos)) pivotRef.current?.matrix.setPosition(pos.floor());
        break;
      default:
        break;
    }
  };

  // Handler to create a temporary tile group when selecting creation zone.
  const handleDragEnd = () => {
    switch (getSelectedMode()) {
      case "CREATE":
        if (getStartPoint() !== undefined && getEndPoint() !== undefined) {
          if (getSelectedTileTemplate() === "STORAGE")
            showCreatedTiles();
          else showCreatedTemplates();
        }
        break;
      case "DELETE":
        showReducedTiles();
        break;
      case "EDIT":
        showEditedTiles();
        break;
      case "MOVE":
        handleMoveTiles();
        break;
      default:
        break;
    }
  };

  const getActiveAxes = (): [boolean, boolean, boolean] => {
    if (getSelectedMode() === "DELETE")
      return [false, true, false];

    if (getSelectedMode() === "MOVE" || (getSelectedMode() === "CREATE" && getSelectedTileTemplate() !== "STORAGE"))
      return [true, false, true];

    return [true, true, true]
  };

  const getAxisColors = (): [string, string, string] => {
    if (getSelectedMode() !== "DELETE")
      return ["#ff0000", "#00ff00", "#0000ff"];

    return ["#0000ff", "#0000ff", "#0000ff"];
  }

  // Assign pivot and pivot content to its respective states.
  useEffect(() => {
    pivotRef.current && setPivotControls(pivotRef.current);
    pivotContentRef.current && setPivotControlsContent(pivotContentRef.current);
  }, [pivotRef.current, pivotContentRef.current]);

  return {
    pivotRef,
    pivotContentRef,
    pivotMatrix,
    getActiveAxes,
    getAxisColors,
    handleDrag,
    handleDragEnd
  }
}
