import { useResolve } from "@movicoders/di";
import { IDialogsStatesStore } from "../../../stores/dialogs-states-store/IDialogsStatesStore";
import { useDialogsStatesStore } from "../../../stores/dialogs-states-store/dialogs-states-store";
import { ITilesComparison } from "./useReplaceOrKeepEditDialog";
import { Tile3D, tileDTOFrom3D } from "../../../types/Tile3D";
import { TileRepository } from "../../../../../../infrastructure/repositories/tile-repository";
import { IWarehouse3DStore } from "../../../stores/warehouse-3d-store/IWarehouse3DStore";
import { useWarehouse3DStore } from "../../../stores/warehouse-3d-store/warehouse-3d-store";
import { useRef } from "react";
import { IEditionStatesStore } from "../../../stores/edition-states-store/IEditionStatesStore";
import { useEditionStatesStore } from "../../../stores/edition-states-store/edition-states-store";

export const useReplaceOrKeepDialogHelpers = () => {
  const { getWarehouse, setWarehouse } = useResolve<IWarehouse3DStore>(useWarehouse3DStore);
  const { getReplaceOrKeepDialogProps } = useResolve<IDialogsStatesStore>(useDialogsStatesStore);
  const { getSelectedMode } = useResolve<IEditionStatesStore>(useEditionStatesStore);
  const tileRepository = useRef(useResolve<TileRepository>(TileRepository)).current;

  const getItemToKeep = (item: ITilesComparison) => {
    const existingTileToSave = getReplaceOrKeepDialogProps().tilesToReplace
      .find(ttr => ttr.x === item.tilesPos.x && ttr.z === item.tilesPos.z);

    if (existingTileToSave) {
      const tileToSave: Tile3D = {
        ...existingTileToSave, locations: existingTileToSave.locations?.map(loc => {
          return {
            ...loc,
            active: true,
            warehouseId: getWarehouse().id
          }
        }) ?? []
      };
      tileToSave.active = true;
      tileToSave.maxY = Math.max(...(tileToSave.locations?.filter((l) => l.active).map((l) => l.level ?? 0) ?? []), 1)
      return tileToSave;
    } else return undefined;
  }

  const getItemsToSave = (item: ITilesComparison): { tileToSave?: Tile3D, disabledTile?: Tile3D } => {
    const tileToReplace = getReplaceOrKeepDialogProps().tilesToReplace
      .find(ttr => ttr.x === item.tilesPos.x && ttr.z === item.tilesPos.z);

    if (getSelectedMode() !== "MOVE" && getReplaceOrKeepDialogProps().movingIdPairs === undefined) {
      const tileToSave = getReplaceOrKeepDialogProps().newTiles
        .find(newTile => newTile.x === item.tilesPos.x && newTile.z === item.tilesPos.z);

      if (tileToReplace && tileToSave) {
        tileToSave.id = tileToReplace.id;
        //Non repeated location codes
        const newLocCodes = tileToSave.locations?.map(loc => loc.code);
        const locationsToReplace = tileToReplace.locations?.filter(oldLoc => newLocCodes?.includes(oldLoc.code)) ?? [];

        tileToSave.locations = tileToSave.locations?.map(newLoc => {
          const oldLocation = locationsToReplace.find(locToReplace => locToReplace.code === newLoc.code);
          newLoc.warehouseId = getWarehouse().id;
          newLoc.tileId = tileToSave.id;
          if (locationsToReplace.map(locToReplace => locToReplace.code).includes(newLoc.code ?? "") && oldLocation) {
            newLoc.id = oldLocation.id;
          }
          return newLoc;
        });

        return { tileToSave };
      } else return {};
    } else {
      const currentPair = getReplaceOrKeepDialogProps().movingIdPairs?.find((pair) => pair.oldTileId === tileToReplace?.id);
      const tileToDisable = [...getWarehouse().tiles ?? []].find((t) => t.id === currentPair?.newTileId);
      if (tileToReplace && tileToDisable) {
        // Enable and add the data of the old tile into the new position.
        tileToReplace.active = true;
        const locationsToSave = [...tileToDisable.locations ?? []].map(location => {
          location.warehouseId = getWarehouse().id;
          return location;
        });
        tileToReplace.locations = [...tileToReplace.locations ?? []].concat([...locationsToSave]);
        tileToReplace.maxY = Math.max(...(tileToReplace.locations?.filter((l) => l.active).map((l) => l.level ?? 0) ?? []), 1)
        tileToReplace.templateId = tileToDisable.templateId;
        tileToReplace.zoneId = tileToDisable.zoneId;

        // Disable moved tile
        tileToDisable.active = false;
        tileToDisable.locations = [];
        tileToDisable.zoneId = undefined;
        return { tileToSave: tileToReplace, disabledTile: tileToDisable };
      } else {
        const warehouseTileToReplace = [...getWarehouse().tiles ?? []].find((t) => t.x === item.tilesPos.x && t.z === item.tilesPos.z)
        if (warehouseTileToReplace && tileToReplace && !tileToDisable) {
          warehouseTileToReplace.active = true
          warehouseTileToReplace.locations = [...warehouseTileToReplace.locations ?? []].concat([...item.newTileLocations]);
          warehouseTileToReplace.maxY = Math.max(...(tileToReplace.locations?.filter((l) => l.active).map((l) => l.level ?? 0) ?? []), 1)
          warehouseTileToReplace.templateId = item.templates.new;
          warehouseTileToReplace.zoneId = item.zones.new;
          return { tileToSave: warehouseTileToReplace };
        } else return {};
      }
    }
  }

  const handleUpdateTiles = (itemsToSave: Tile3D[]) => {
    let success = true;
    return tileRepository.updateMany({ tileDTO: itemsToSave.map(tileDTOFrom3D) })
      .then((res) => {
        const tempWH = { ...getWarehouse() };
        const tempTiles = [...(getWarehouse().tiles ?? [])];
        // Add maxY to tile
        itemsToSave.forEach((itm) => {
          if (res.content?.[itm?.code ?? ""] === "OK") {
            const editedTile = tempTiles.find(t => t.id === itm?.id);
            const indexOfTile = tempTiles.indexOf(editedTile ?? {});
            if (editedTile && indexOfTile !== -1 && tempTiles && itm) {
              tempTiles.splice(indexOfTile, 1);
              tempTiles.push(itm);
            }
          } else
            success = false;
        })
        tempWH.tiles = tempTiles;
        setWarehouse(tempWH);
        return success;
      })
  }

  return {
    getItemToKeep,
    getItemsToSave,
    handleUpdateTiles
  }
}