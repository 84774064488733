import FamilyType from "@domain/model/FamilyType";
import { TypeOfFamilyRepository } from "@infrastructure/repositories/type-of-families-repository";

export class TypeOfFamilyUpdateStatusUseCase {
  constructor(private readonly typeOfFamilyRepository: TypeOfFamilyRepository) {}

  public async execute(type: FamilyType) {
    const modifiedType = { ...type, active: !type.active };
    return await this.typeOfFamilyRepository.update(modifiedType);
  }
}
