// Disabled because elements from ThreeJS that start with lowercase
// are not allowed by eslint no-unknown-property rule.
/* eslint-disable react/no-unknown-property */

import { Text } from "@react-three/drei";
import { useTranslation } from "react-i18next";
import { CylinderGeometry } from "three";
import { Location3D } from "../../types/Location3D";
import { getCylinderHeight } from "../utils/cylinder-height-helper";
import { defaultVerticalPlaneGeometry } from "../utils/detail-geometries";
import { defaultWhiteMaterial, getEmptyMaterial, getFilledMaterial } from "../utils/capacity-materials";
import { DEFAULT_PLANE_MATERIAL } from "../../constants/default-materials";
import { DEFAULT_PLANE_GEOMETRY } from "../../constants/default-geometries";

export const MultipleBin = (props: {
  locationQuant: number;
  level: number;
  locations: Location3D[];
  handleClick: (bins: Location3D[], pos: number[]) => void;
}) => {
  const { locationQuant, level, locations, handleClick } = props;
  const { t } = useTranslation();
  const totalCapacity = locations.map(loc => loc.capacity).reduce((partialSum, currentVal) => (partialSum ?? 0) + (currentVal ?? 0), 0) ?? 0;
  const totalContainers = locations.map(loc => loc.containers?.length).reduce((partialSum, currentVal) => (partialSum ?? 0) + (currentVal ?? 0), 0) ?? 0;

  return (
    <group>
      {totalContainers / totalCapacity !== 0 && totalCapacity !== 0 && (
        <mesh
          position={[0, level - 1 + getCylinderHeight(totalContainers / totalCapacity) / 2, 0]}
          geometry={new CylinderGeometry(0.3, 0.3, getCylinderHeight(totalContainers / totalCapacity), 16)}
          material={getFilledMaterial(totalContainers, totalCapacity)}
          onClick={() => {
            handleClick(locations, [0, level - 0.7, 0]);
          }}
        />
      )}
      {totalContainers / totalCapacity !== 1 && totalCapacity !== 0 && (
        <mesh
          position={[0, level - 0.7 + getCylinderHeight(totalContainers / totalCapacity) / 2, 0]}
          geometry={new CylinderGeometry(0.3, 0.3, getCylinderHeight(1 - totalContainers / totalCapacity), 16)}
          material={getEmptyMaterial(totalContainers, totalCapacity)}
          onClick={() => {
            handleClick(locations, [0, level - 0.7, 0]);
          }}
        />
      )}
      {totalCapacity === 0 && (
        <mesh
          position={[0, level - 0.7, 0]}
          geometry={new CylinderGeometry(0.3, 0.3, 0.6, 16)}
          material={defaultWhiteMaterial}
          onClick={() => {
            handleClick(locations, [0, level - 0.7, 0]);
          }}
        />
      )}
      <mesh position={[0, level - 0.95, 0.499]} material={defaultWhiteMaterial} geometry={defaultVerticalPlaneGeometry} />
      <Text color="black" anchorX="center" anchorY="bottom" position={[0, level - 0.99, 0.5]} fontSize={0.08}>
        {t("viewer.details.multiple.bins.level", { bins: locationQuant })}
      </Text>
      <mesh position={[0, level - 1, 0]} geometry={DEFAULT_PLANE_GEOMETRY} material={DEFAULT_PLANE_MATERIAL} />
    </group>
  );
};
