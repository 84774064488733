import { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import Stakeholder from "@domain/model/Stakeholder";
import { ALL_COUNTRIES, CountryObject } from "@constants";
import { Autocomplete, Box, TextField } from "@mui/material";

interface StakeHolderAutocompleteProps {
  disabled: boolean;
  modifiedStakeholder: Stakeholder;
  onChangeAutocomplete: (event: SyntheticEvent<Element, Event>, value: CountryObject | null) => void;
}

export const StakeholderCountryAutocomplete = (props: StakeHolderAutocompleteProps) => {
  const { t } = useTranslation();
  const { disabled, modifiedStakeholder, onChangeAutocomplete } = props;

  return (
    <Autocomplete
      id="stakeholder-country-autocomplete"
      value={{ label: modifiedStakeholder.country ?? "", code: "", phone: "" } ?? ALL_COUNTRIES}
      options={ALL_COUNTRIES.sort((a, b) => t(a?.label).localeCompare(t(b?.label)))}
      disableClearable
      disabled={disabled}
      autoHighlight
      getOptionLabel={option => t(option.label)}
      onChange={(event, value) => {
        onChangeAutocomplete(event, value);
      }}
      renderOption={(props, option) => (
        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {t(option.label)}
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          className={
            modifiedStakeholder?.active !== undefined && modifiedStakeholder.active === false
              ? "disabled-input"
              : "blue-border-input"
          }
          label={t("stakeholders.country")}
          disabled={disabled}
          inputProps={{
            ...params.inputProps
          }}
        />
      )}
    />
  );
};
