import { COLORS } from "@theme";
import { SxProps, Theme } from "@mui/material";

export const MasterDataTitleStyles = () => {
  const add_icon_button: SxProps<Theme> = {
    backgroundColor: COLORS.secondary,
    "&:hover": {
      backgroundColor: COLORS.secondary
    },
    width: 40,
    height: 40
  };

  const divider: SxProps<Theme> = {
    border: "2px solid #EBEBEB",
    opacity: 1,
    width: "100%",
    marginTop: 1,
    marginBottom: 1
  };

  return { add_icon_button, divider };
};
