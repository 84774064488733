import {
  ListResponseDTO,
  ListStakeholdersPaginatedRequest,
  ListStakeholdersRequest,
  SaveStakeholdersRequest,
  StakeholderDTO,
  StakeholdersApi
} from "@clients/aggrego-proxy";
import { DomainPage } from "@movicoders/domain";
import Stakeholder from "@domain/model/Stakeholder";
import { IUserService } from "@domain/services/IUserService";
import { IImpersonationService } from "@domain/services/IImpersonationService";

export class StakeholderApi {
  constructor(
    private readonly stakeholderApi: StakeholdersApi,
    private readonly impersonationService: IImpersonationService,
    private readonly userService: IUserService
  ) {}

  async get(criteria: ListStakeholdersRequest): Promise<Stakeholder[]> {
    const result: Stakeholder[] = [];

    const apiResult = await this.stakeholderApi.listStakeholders({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });

    apiResult.forEach((stakeholderClient: StakeholderDTO) => {
      result.push(Stakeholder.fromClientStakeholder(stakeholderClient));
    });
    return result;
  }

  async getPaginated(pageCriteria: ListStakeholdersPaginatedRequest): Promise<DomainPage<Stakeholder>> {
    const apiResult = (await this.stakeholderApi.listStakeholdersPaginated({
      ...pageCriteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? "",
      customStakeholderFilter: pageCriteria.customStakeholderFilter ?? {}
    })) as unknown as Promise<DomainPage<Stakeholder>>;
    return apiResult;
  }

  async getById(id: string): Promise<Stakeholder> {
    const apiResult = Stakeholder.fromClientStakeholder(
      await this.stakeholderApi.getStakeholderById({
        id: id,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return apiResult;
  }

  async getManyById(idList: string[]): Promise<Stakeholder[]> {
    const result: Stakeholder[] = [];

    const apiResult = await this.stakeholderApi.getStakeholderByIds({
      requestBody: idList,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });

    apiResult.forEach((stakeholderClient: StakeholderDTO) => {
      result.push(Stakeholder.fromClientStakeholder(stakeholderClient));
    });
    return result;
  }

  async add(stakeholder: Stakeholder): Promise<Stakeholder> {
    const stakeholderDTO = Stakeholder.toClientStakeholder(stakeholder);
    const apiResult = Stakeholder.fromClientStakeholder(
      await this.stakeholderApi.addStakeholderToCompany({
        stakeholderDTO: stakeholderDTO,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return apiResult;
  }

  async addMany(criteria: SaveStakeholdersRequest): Promise<ListResponseDTO> {
    const apiResult = await this.stakeholderApi.saveStakeholders({
      ...criteria,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
    return apiResult;
  }

  async update(stakeholder: Stakeholder): Promise<Stakeholder> {
    const stakeholderDTO = Stakeholder.toClientStakeholder(stakeholder);
    const apiResult = Stakeholder.fromClientStakeholder(
      await this.stakeholderApi.updateStakeholder({
        stakeholderDTO: stakeholderDTO,
        xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
      })
    );
    return apiResult;
  }

  async delete(id: string): Promise<void> {
    await this.stakeholderApi.deleteStakeholder({
      id: id,
      xTenantId: this.impersonationService.persistedState?.impersonatedTenant ?? ""
    });
  }
}
