import Icon from "../icons/icon";
import { COLORS, MEDIA } from "@theme";
import { MasterDataTitleStyles } from "./MasterDataTitleStyles";
import { Grid, Typography, Button, useMediaQuery, Theme, IconButton, Divider } from "@mui/material";

export const MasterDataTitle = (props: {
  name: string;
  icon: JSX.Element;
  buttonText?: string;
  onAddButton?: () => void;
  showButton?: boolean;
  disableButton?: boolean;
}) => {
  const { name, icon, buttonText, onAddButton, showButton, disableButton } = props;
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up(MEDIA.xsmall), { noSsr: true });
  const styles = MasterDataTitleStyles();

  return (
    <>
      <Grid item display="flex" flexDirection="row" justifyContent="space-between" md={12}>
        <Grid id="master-data-title" className="main-view-title" item>
          {icon}
          <Typography id="master-data-title" ml="1rem">
            {name}
          </Typography>
        </Grid>
        {showButton &&
          (matches ? (
            <Button
              startIcon={<Icon icon="plus" color={COLORS.white} />}
              variant="primary"
              onClick={onAddButton}
              sx={{
                backgroundColor: disableButton ? COLORS.lightgray : ""
              }}
              disabled={disableButton ?? false}
              id="master-data-create-button">
              {buttonText}
            </Button>
          ) : (
            <IconButton sx={styles.add_icon_button} onClick={onAddButton} id="master-data-create-icon-button">
              <Icon icon="plus" color={COLORS.white} />
            </IconButton>
          ))}
      </Grid>
      <Divider sx={styles.divider} />
    </>
  );
};
