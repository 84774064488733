import { SxProps, Theme } from "@mui/material";

const solid_alert: SxProps<Theme> = {
  color: "white",
  letterSpacing: 1.25
};

export const snackbarStyles = {
  solid_alert
};
