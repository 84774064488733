/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductsBasicFilterDTO
 */
export interface ProductsBasicFilterDTO {
    /**
     * 
     * @type {string}
     * @memberof ProductsBasicFilterDTO
     */
    batch?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductsBasicFilterDTO
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductsBasicFilterDTO
     */
    manufactureOrder?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProductsBasicFilterDTO
     */
    manufacturingDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductsBasicFilterDTO
     */
    manufacturingEndDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductsBasicFilterDTO
     */
    manufacturingInitDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProductsBasicFilterDTO
     */
    materialCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductsBasicFilterDTO
     */
    onlyParent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductsBasicFilterDTO
     */
    serialNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductsBasicFilterDTO
     */
    serialNumberEnd?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductsBasicFilterDTO
     */
    serialNumberInit?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductsBasicFilterDTO
     */
    state?: ProductsBasicFilterDTOStateEnum;
}


/**
 * @export
 */
export const ProductsBasicFilterDTOStateEnum = {
    Aggregated: 'AGGREGATED',
    Destroyed: 'DESTROYED',
    Ready: 'READY',
    Shipped: 'SHIPPED'
} as const;
export type ProductsBasicFilterDTOStateEnum = typeof ProductsBasicFilterDTOStateEnum[keyof typeof ProductsBasicFilterDTOStateEnum];


/**
 * Check if a given object implements the ProductsBasicFilterDTO interface.
 */
export function instanceOfProductsBasicFilterDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProductsBasicFilterDTOFromJSON(json: any): ProductsBasicFilterDTO {
    return ProductsBasicFilterDTOFromJSONTyped(json, false);
}

export function ProductsBasicFilterDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductsBasicFilterDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'batch': !exists(json, 'batch') ? undefined : json['batch'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'manufactureOrder': !exists(json, 'manufactureOrder') ? undefined : json['manufactureOrder'],
        'manufacturingDate': !exists(json, 'manufacturingDate') ? undefined : (new Date(json['manufacturingDate'])),
        'manufacturingEndDate': !exists(json, 'manufacturingEndDate') ? undefined : (new Date(json['manufacturingEndDate'])),
        'manufacturingInitDate': !exists(json, 'manufacturingInitDate') ? undefined : (new Date(json['manufacturingInitDate'])),
        'materialCode': !exists(json, 'materialCode') ? undefined : json['materialCode'],
        'onlyParent': !exists(json, 'onlyParent') ? undefined : json['onlyParent'],
        'serialNumber': !exists(json, 'serialNumber') ? undefined : json['serialNumber'],
        'serialNumberEnd': !exists(json, 'serialNumberEnd') ? undefined : json['serialNumberEnd'],
        'serialNumberInit': !exists(json, 'serialNumberInit') ? undefined : json['serialNumberInit'],
        'state': !exists(json, 'state') ? undefined : json['state'],
    };
}

export function ProductsBasicFilterDTOToJSON(value?: ProductsBasicFilterDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'batch': value.batch,
        'description': value.description,
        'manufactureOrder': value.manufactureOrder,
        'manufacturingDate': value.manufacturingDate === undefined ? undefined : (value.manufacturingDate.toISOString()),
        'manufacturingEndDate': value.manufacturingEndDate === undefined ? undefined : (value.manufacturingEndDate.toISOString()),
        'manufacturingInitDate': value.manufacturingInitDate === undefined ? undefined : (value.manufacturingInitDate.toISOString()),
        'materialCode': value.materialCode,
        'onlyParent': value.onlyParent,
        'serialNumber': value.serialNumber,
        'serialNumberEnd': value.serialNumberEnd,
        'serialNumberInit': value.serialNumberInit,
        'state': value.state,
    };
}

