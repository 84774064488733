import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import Material from "@domain/model/Material";
import { useTranslation } from "react-i18next";
import { UUIDGenerator } from "@utils/uuid-generator";
import { warehouseStatusStyles } from "./warehouseStatusStyles";
import { WarehouseStatusDTO } from "@domain/model/WarehouseStatus";
import { PersistedAlert } from "@components/persistedAlert/PersistedAlert";
import { OutletContainer } from "@components/outlet-container/outlet-container";
import { FailedConsumptionDialog } from "./components/failed-consumption-dialog";
import { DataGridDisplay } from "@components/data-grid-display/data-grid-display";
import { useWarehouseStatusViewModel } from "./hooks/useWarehouseStatusViewModel";
import { MaterialConsumptionDialog } from "./components/material-consumption-dialog";
import { CircularProgress, Divider, Grid, Theme, useMediaQuery } from "@mui/material";
import { useWarehouseStatusRechargeView } from "./hooks/useWarehouseStatusRechargeView";
import { warehouseStatusColumns } from "@track-and-trace/status/main/warehouse-status-columns";
import { useMaterialConsumptionDialogViewModel } from "./hooks/useMaterialConsumptionDialogViewModel";
import { LoadingSpinnerMasterView } from "@components/loading-circular-progress/loading-spinner-master-view";
import { GridValidRowModel } from "@mui/x-data-grid";

export const WarehouseStatusView = () => {
  const { t } = useTranslation();

  const {
    dataPaginated,
    filteredMaterialPaginatedData,
    families,
    columnsByMode,
    loading,
    searchBarFilter,
    search,
    sortByFieldBackend,
    isViewPageReadyToLoad,
    settingsService
  } = useWarehouseStatusViewModel();

  const {
    loadingDetail,
    openDetailDialog,
    openFailedDialog,
    onCloseDetailDialog,
    onCloseFailedDialog,
    handleMultipleSelectionDialogOpen,
    selectedstatusReportList,
    setSelectedstatusReportList,
    failedstatusReportConsumptionList,
    handleStatusReportSelection,
    multipleTotalConsumptionByLicense,
    deleteItemFromList
  } = useMaterialConsumptionDialogViewModel(search);

  const { isPermissionForPageLoaded } = useWarehouseStatusRechargeView(settingsService);

  const styles = warehouseStatusStyles;
  const matchesSM = useMediaQuery((theme: Theme) => theme.breakpoints.up(500), { noSsr: true });

  return (
    <>
      {isPermissionForPageLoaded ? (
        <OutletContainer>
          <Grid container id="first-grid-container" direction="column" mb={1}>
            <Grid item>
              <Grid container wrap="nowrap" spacing={{ xs: "1rem" }} id="second-grid-container">
                <Grid item alignSelf="center">
                  <Icon icon="warehouse" color={COLORS.primary} />
                </Grid>
                <Grid item id="report-title" sx={{ color: COLORS.secondary, fontSize: "x-large", fontWeight: 600 }}>
                  {t("warehouse.status.title")}
                </Grid>
              </Grid>
              <Divider sx={styles.title_divider} />
            </Grid>
            {isViewPageReadyToLoad() ? (
              <DataGridDisplay
                backendTotalElements={dataPaginated?.totalElements ?? 0}
                data={
                  dataPaginated?.content?.map((item: WarehouseStatusDTO) => {
                    return { ...item, id: UUIDGenerator() };
                  }) ?? []
                }
                columns={warehouseStatusColumns(t, matchesSM)}
                mode="server"
                viewName="whstatus"
                hideColumnsByField={columnsByMode}
                handleBackendPagination={(
                  limit: number,
                  offset: number,
                  status?: "ALL" | "ACTIVE" | "INACTIVE" | undefined,
                  filters?: object | undefined
                ) => {
                  search(limit, offset, filters);
                }}
                customDisplayComponent={() => undefined}
                handleBackendSort={sortByFieldBackend}
                toolbarProps={{
                  multipleSelectOptions: new Map<string, string[]>([
                    ["family", families.sort((a, b) => t(a?.name).localeCompare(t(b?.name))).map(family => family.name) ?? []],
                    [
                      "material",
                      (filteredMaterialPaginatedData as unknown as Material[])?.map((value: Material) => value.code) ?? []
                    ],
                    [
                      "description",
                      (filteredMaterialPaginatedData as unknown as Material[])?.map((value: Material) => value.name) ?? []
                    ]
                  ]),
                  disableColumnsVisibilityByField: [...columnsByMode, "actions"],
                  disableFilterByFields: [...columnsByMode, "actions"],
                  disableFieldsInDownload: [...columnsByMode, "actions"],
                  isActiveFilterHidden: true
                }}
                searchBarFilter={searchBarFilter}
                rowId={(row: GridValidRowModel) => row["container"]}
                checkboxSelection={true}
                fieldToUseAsId={"container"}
                selectionRowState={selectedstatusReportList}
                setSelectionRowState={setSelectedstatusReportList}
                handleCheckboxSelection={handleStatusReportSelection}
                handleMultipleSelectionAction={handleMultipleSelectionDialogOpen}
              />
            ) : (
              <PersistedAlert message={t("whstatus.alert.warehouse.no.created")} goTo="warehouses" />
            )}
            <MaterialConsumptionDialog
              open={openDetailDialog}
              onClose={onCloseDetailDialog}
              loadingDetail={loadingDetail}
              selectedstatusReportList={selectedstatusReportList ?? []}
              consumptionByLicense={multipleTotalConsumptionByLicense}
              deleteItemFromList={deleteItemFromList}
            />
            <FailedConsumptionDialog
              open={openFailedDialog}
              onClose={onCloseFailedDialog}
              failedstatusReportList={failedstatusReportConsumptionList}
            />
            <LoadingSpinnerMasterView loading={loading} />
          </Grid>
        </OutletContainer>
      ) : (
        <CircularProgress color="secondary" />
      )}
    </>
  );
};
