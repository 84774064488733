import { DTOConvertibleEntity } from "./GenericPage";
import { UUIDGenerator } from "@utils/uuid-generator";
import { StockReportDTO } from "@clients/aggrego-proxy";

/**
 * Aggrego Logistic Operations service (dev)
 * Warehouse status report
 */
export class StockDTO extends DTOConvertibleEntity<StockReportDTO, StockDTO> {
  public constructor();
  public constructor(obj?: Partial<StockDTO>);
  public constructor(obj?: Partial<StockDTO>) {
    super();
    obj && Object.assign(this, obj);
  }
  id?: string = "";
  materialCode?: string = "";
  materialName?: string = "";
  date?: Date = new Date();
  family?: Array<string> = [];
  qty?: number = 0;
  uom?: string = "";
  containersIds?: string = "";

  public fromDTO(obj: Partial<StockReportDTO>): StockDTO {
    return new StockDTO({
      id: UUIDGenerator(),
      materialCode: obj.materialCode ?? "",
      materialName: obj.materialName ?? "",
      date: new Date(obj.date ?? 0),
      family: obj.family ?? [],
      qty: obj.qty ?? 0,
      uom: obj.uom ?? "",
      containersIds: obj.containersIds
    });
  }

  public toDTO(arg: StockDTO): StockReportDTO {
    return {
      id: arg.id,
      materialName: arg.materialName,
      materialCode: arg.materialCode,
      date: arg.date,
      family: arg.family,
      qty: arg.qty,
      uom: arg.uom,
      containersIds: arg.containersIds
    } as unknown as StockDTO;
  }
}
