import { COLORS } from "@theme";
import { useEffect, useRef, useState } from "react";
import Icon from "@components/icons/icon";
import { ROUTER_PATHS } from "@constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { productViewStyles } from "./productsViewStyles";
import { useProductViewModel } from "./hooks/useProductsViewModel";
import { CircularProgress, Divider, Grid, Theme, useMediaQuery } from "@mui/material";
import { ProductsSearchBar } from "./components/products-search-bar";
import { productsColumns, productsParentsFilter } from "./products-columns";
import { OutletContainer } from "@components/outlet-container/outlet-container";
import { DataGridDisplay } from "@components/data-grid-display/data-grid-display";
import { useHierarchyService } from "@infrastructure/services/useHierarchyService";
import { LoadingSpinnerMasterView } from "@components/loading-circular-progress/loading-spinner-master-view";
import { TrackAndTraceEnum } from "@pages/settings/settings-interfaces";

export const ProductsView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const styles = productViewStyles;
  const matchesSM = useMediaQuery((theme: Theme) => theme.breakpoints.up(500), { noSsr: true });
  const matchesLG = useMediaQuery((theme: Theme) => theme.breakpoints.up(1250), { noSsr: true });
  const { clearHierarchyState, saveHierarchyState, persistedState } = useRef(useHierarchyService()).current;

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    clearHierarchyState();
  }, [clearHierarchyState]);

  const onNavigateToHierarchy = (id?: string) => {
    saveHierarchyState({
      ...persistedState,
      currentHierarchy: [],
      currentParentId: id
    });
    navigate(`${ROUTER_PATHS.products}/${id}`);
  };

  const {
    exportingPaginatedData: dataPaginated,
    repositoryLoading: loading,
    sortByFieldBackend,
    searchPaginated,
    saveSearchValues,
    settingsService,
    findAbsoluteProductParentFromInside,
    searchMode,
    setSearchMode,
    hierarchyFiltersService,
    selectedParentFilter,
    setSelectedParentFilter,
    hasVersionLabel
  } = useProductViewModel(onNavigateToHierarchy);

  return (
    <>
      {settingsService.persistedState?.trackAndTracePermissions.includes(TrackAndTraceEnum.TrackingReport) ? (
        <>
          <OutletContainer>
            <Grid container id="first-grid-container" direction="column" mb={1}>
              <Grid item>
                <Grid container wrap="nowrap" spacing={{ xs: "1rem" }} id="second-grid-container">
                  <Grid item alignSelf="center">
                    <Icon icon="palet" color={COLORS.primary} />
                  </Grid>
                  <Grid id="tracking-report-title" item sx={{ color: COLORS.secondary, fontSize: "x-large", fontWeight: 600 }}>
                    {t("tracking.report")}
                  </Grid>
                </Grid>
                <Divider sx={styles.title_divider} />
              </Grid>

              <DataGridDisplay
                backendTotalElements={dataPaginated?.totalElements ?? 0}
                data={dataPaginated?.content ?? []}
                columns={productsColumns(
                  t,
                  onNavigateToHierarchy,
                  findAbsoluteProductParentFromInside,
                  matchesSM,
                  matchesLG,
                  hasVersionLabel
                )}
                mode="server"
                viewName="products"
                rowId={row => row["id"]}
                handleBackendSort={sortByFieldBackend}
                handleBackendPagination={(
                  limit: number,
                  offset: number,
                  status?: "ALL" | "ACTIVE" | "INACTIVE" | undefined,
                  filters?: object | undefined
                ) => {
                  if (!isFirstLoad) {
                    searchPaginated(limit, offset, hierarchyFiltersService.persistedState?.filter ?? filters);
                  }
                  setIsFirstLoad(false);
                }}
                toolbarProps={{
                  disableColumnsVisibilityByField: ["actions"],
                  disableFilterByFields: ["actions"],
                  disableFieldsInDownload: ["actions"],
                  customToolbarFieldFilter: productsParentsFilter,
                  customSearchBar: doFilter =>
                    ProductsSearchBar({ loading, searchMode, setSearchMode, selectedParentFilter, setSelectedParentFilter }),
                  customSearchBarFilter: saveSearchValues,
                  isFilterIconButtonHidden: true,
                  isActiveFilterHidden: true
                }}
              />
            </Grid>
          </OutletContainer>
          <LoadingSpinnerMasterView loading={loading} />
        </>
      ) : (
        <CircularProgress color="secondary" />
      )}
    </>
  );
};
