import { useResolve } from "@movicoders/di";
import { useSnackbar } from "@movicoders/ui";
import { useTranslation } from "react-i18next";
import { InvPeriodDTO } from "@domain/model/InvPeriod";
import { Dispatch, SetStateAction, useState } from "react";
import { InventoryRegistryUseCase } from "@application/inventory-registry/inventory-registry-create-use-case";

export const useInventoryRegistryInventory = (
  selectedWarehouseCode: string | undefined,
  periods: InvPeriodDTO[],
  getAllPeriods: () => void,
  sortPeriods: () => void,
  setIsActivePeriod: Dispatch<SetStateAction<boolean>>
) => {
  const { t } = useTranslation();
  const { show } = useSnackbar();

  const [isInitPeriodButtonDisabled, setIsInitPeriodButtonDisabled] = useState(false);
  const inventoryPeriodUseCase = useResolve(InventoryRegistryUseCase);
  const [showInvRegistryDialog, setShowInvRegistryDialog] = useState(false);

  const handleDialogVisibility = () => {
    setShowInvRegistryDialog(!showInvRegistryDialog);
  };

  const onCloseInvRegistryDialog = () => {
    setShowInvRegistryDialog(false);
  };

  const handleInitInventory = async () => {
    setShowInvRegistryDialog(false);
    setIsInitPeriodButtonDisabled(true);
    inventoryPeriodUseCase
      .openNewPeriod(selectedWarehouseCode ?? "")
      .then(() => {
        show(t("invregistry.init.confirm"), "success");
        setIsActivePeriod(true);
        //Recharge all periods for select
        getAllPeriods();
        periods.length > 0 && sortPeriods();
      })
      .catch(error => show(t("invregistry.init.error"), "error"))
      .finally(() => {
        setIsInitPeriodButtonDisabled(false);
      });
  };

  const handleCloseInventory = async () => {
    setShowInvRegistryDialog(false);
    setIsInitPeriodButtonDisabled(true);
    inventoryPeriodUseCase
      .closePeriod(selectedWarehouseCode ?? "")
      .then(() => {
        show(t("invregistry.close.confirm"), "success");
        setIsActivePeriod(false);
      })
      .catch(error => show(t("invregistry.close.error"), "error"))
      .finally(() => {
        setIsInitPeriodButtonDisabled(false);
      });
  };

  return {
    showInvRegistryDialog,
    isInitPeriodButtonDisabled,
    handleDialogVisibility,
    handleCloseInventory,
    onCloseInvRegistryDialog,
    handleInitInventory
  };
};
