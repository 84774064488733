import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useZoneSelector3D } from "./useZoneSelector3D"
import { useTranslation } from "react-i18next";

export const ZoneSelector3D = () => {
  const { t } = useTranslation();
  const {
    getActiveZones,
    getSelectedZone,
    handleChange,
    getModifyingWH
  } = useZoneSelector3D();

  return (
    <FormControl sx={{ mt: "1.4rem", mb: getModifyingWH() ? 1 : 0 }} size="small" fullWidth disabled={getModifyingWH()}>
      <InputLabel id="zone-selection-label">{t("locations.zone")}</InputLabel>
      <Select
        value={getSelectedZone()?.id ?? ""}
        onChange={handleChange}
        fullWidth
        labelId="zone-selection-label"
      >
        <MenuItem value="">
          <em>{t("locations.zone.unselected")}</em>
        </MenuItem>
        {getActiveZones().map(zone => (
          <MenuItem key={zone.id} value={zone.id}>
            {zone.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
