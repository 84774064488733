import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material"
import Icon from "../../../../../../components/icons/icon"
import { useResolve } from "@movicoders/di";
import { IZonesEdition3DStore } from "../../../../stores/zones-edition-3d-store/IZonesEdition3DStore";
import { useZonesEdition3DStore } from "../../../../stores/zones-edition-3d-store/zones-edition-3d-store";
import { COLORS } from "../../../../../../../theme";

export const ZoneEditionTools = () => {

  const { getZonesEditingMode, setZonesEditingMode } = useResolve<IZonesEdition3DStore>(useZonesEdition3DStore);

  return (
    <Grid container mt={1} justifyContent="center">
      <ToggleButtonGroup
        exclusive
        size="large"
        value={getZonesEditingMode()}
        onChange={(event, value) => setZonesEditingMode(value)}
      >
        <ToggleButton value="WRITE">
          <Icon icon="edit" color={COLORS.primary} />
        </ToggleButton>
        <ToggleButton value="REMOVE">
          <Icon icon="delete" color={COLORS.primary} />
        </ToggleButton>
      </ToggleButtonGroup>
    </Grid>
  )
}
