import { TileTemplateDTO } from "@clients/aggrego-proxy/models/TileTemplateDTO";

export const TILE_TEMPLATE_TYPES_VALUES = ["STORAGE", "CORRIDOR", "WALL"] as const;

export type TileTemplateTypes = (typeof TILE_TEMPLATE_TYPES_VALUES)[number];

export default class TileTemplate {
  active?: boolean;
  color?: string;
  companyId?: string;
  description?: string;
  editable?: boolean;
  id?: string;
  name?: string;
  walkable?: boolean;
  type?: TileTemplateTypes;

  constructor(
    active?: boolean,
    color?: string,
    companyId?: string,
    description?: string,
    editable?: boolean,
    id?: string,
    name?: string,
    walkable?: boolean,
    type?: TileTemplateTypes
  ) {
    this.active = active;
    this.color = color;
    this.companyId = companyId;
    this.description = description;
    this.editable = editable;
    this.id = id;
    this.name = name;
    this.walkable = walkable;
    this.type = type;
  }

  static fromClientTileTemplate(template: TileTemplateDTO) {
    const result = new TileTemplate(
      template.active,
      template.color,
      template.companyId,
      template.description,
      template.editable,
      template.id,
      template.name,
      template.walkable,
      template.type
    );
    return result;
  }

  static toClientTileTemplate(template: TileTemplate) {
    const result: TileTemplateDTO = {};
    result.active = template.active;
    result.color = template.color;
    result.companyId = template.companyId;
    result.description = template.description;
    result.editable = template.editable;
    result.id = template.id;
    result.name = template.name;
    result.walkable = template.walkable;
    result.type = template.type;
    return result;
  }
}
