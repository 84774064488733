import { useResolve } from "@movicoders/di";
import { SELECTION_TYPES } from "../../../../constants/SelectionTypes";
import { IEditionStatesStore } from "../../../../stores/edition-states-store/IEditionStatesStore";
import { useEditionStatesStore } from "../../../../stores/edition-states-store/edition-states-store";
export const useSelectionTypeButtonsViewmodel = () => {
  const {
    getSelectedMode,
    getSelectionType,
    setSelectionType,
    getCanUseZones
  } = useResolve<IEditionStatesStore>(useEditionStatesStore);

  const handleChangeSelectionType = (newValue: SELECTION_TYPES) => {
    setSelectionType(newValue);
  };

  return {
    getSelectedMode,
    getSelectionType,
    getCanUseZones,
    handleChangeSelectionType
  };
};
