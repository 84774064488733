import { useState } from "react";
import { User } from "@domain/model/User";
import { LastPageState } from "@domain/model/LastPageState";
import MasterDataConstants from "../../../MasterDataConstants";
import { useResolve } from "@movicoders/di/lib/Container/useResolve";
import { useMasterDataMessagges } from "@hooks/useMasterDataMessages";
import { GetUsersByTenantPaginatedRequest } from "@clients/aggrego-proxy";
import { UserRepository } from "@infrastructure/repositories/user-repository";
import { IImpersonationService } from "@domain/services/IImpersonationService";

export const useUserActiveStatus = (
  currentPagePersistedState: LastPageState,
  impersonationService: IImpersonationService,
  getUsersPaginated: (criteria: GetUsersByTenantPaginatedRequest) => Promise<void>,
  getCurrentStatus: (status: "ACTIVE" | "INACTIVE" | "ALL") => boolean | undefined
) => {
  const { successMessaggesStatus, errorMessaggesStatus } = useMasterDataMessagges();
  const userRepository = useResolve(UserRepository);
  const [isActivating, setIsActivating] = useState(false);
  const [selectedUser4Status, setSelectedUser4Status] = useState<User | undefined>(undefined);

  const onCloseDialog = () => {
    setIsActivating(false);
  };

  const handleOpenToggleActiveStatusDialog = (user: User) => {
    setIsActivating(!isActivating);
    setSelectedUser4Status(user);
  };

  /**
   * Toggles the active status of the user to its opposite value.
   * @param credentialsId The credentialsId of the user whose value is going to reversed.
   * @param currentStatus The current status of the user
   */
  const toggleUserStatus = (credentialsId: string, currentStatus: boolean, userName: string) => {
    const promise = currentStatus ? userRepository.deactivate(credentialsId) : userRepository.activate(credentialsId);
    promise
      .then(() => successMessaggesStatus("user", currentStatus, userName))
      .catch(error => errorMessaggesStatus("user", currentStatus, userName, error))
      .finally(() => {
        getUsersPaginated({
          limit: MasterDataConstants.ITEMS_PER_PAGE,
          offset: 0,
          filterDTO: {
            status: getCurrentStatus(currentPagePersistedState.status),
            ...currentPagePersistedState.filters
          },
          xTenantId: impersonationService.persistedState?.impersonatedTenant ?? ""
        } as GetUsersByTenantPaginatedRequest);
        setIsActivating(false);
      });
  };

  return {
    isActivating,
    selectedUser4Status,
    handleOpenToggleActiveStatusDialog,
    onCloseDialog,
    toggleUserStatus
  };
};
