import { ChangeEvent } from "react";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MAX_LENGTH, MAX_LENGTH_MAP } from "./stakeholder-detail-constants";

interface ITextfield {
  id: string;
  name: string;
  value: string | number;
  label: string;
  type?: string;
  formErrors: Map<string, boolean>;
  disabled?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const StakeholderTextfield = (props: ITextfield) => {
  const { t } = useTranslation();
  const { id, name, value, label, type, formErrors, disabled, onChange } = props;

  const getHelperText = (id: string, name: string) => {
    const maxLength = MAX_LENGTH_MAP[name] || MAX_LENGTH;

    if (name === "fiscalId" || name === "name") {
      if (formErrors.get(id) as boolean) {
        return name === "fiscalId" ? t(`companies.cif.incorrect`) : t(`material.dialog.empty.field.text`);
      } else {
        return "";
      }
    } else {
      return (formErrors.get(id) as boolean)
        ? t("stakeholders.dialog.too.large.field.text", { maxLength: maxLength ?? "0" })
        : "";
    }
  };

  return (
    <TextField
      error={formErrors.get(id) as boolean}
      helperText={getHelperText(id, name)}
      id={id}
      value={value}
      name={name}
      type={type ?? "text"}
      onChange={onChange}
      label={label}
      disabled={name === "code" ? true : disabled}
      multiline={name === "comment"}
      rows={5}
      className={disabled || name === "code" ? "disabled-input" : "blue-border-input"}
    />
  );
};
