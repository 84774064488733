import { useRef } from "react";
import { useResolve } from "@movicoders/di";
import Operator from "@domain/model/Operator";
import { useCheckStatus } from "@hooks/useCheckStatus";
import { IRepositoryPaginated } from "@movicoders/domain";
import { LastPageState } from "@domain/model/LastPageState";
import { useListDetailPaginatedViewModel } from "@movicoders/ui";
import { ObjectType } from "@movicoders/ui/lib/ViewModels/types";
import { ListOperatorsPaginatedRequest } from "@clients/aggrego-proxy";
import { ILastPageService, LastPageService } from "@domain/services/ILastPageService";
import { OperatorRepository } from "@infrastructure/repositories/operator-repository";

export const useOperatorHandleData = () => {
  const currentPageService = useResolve<ILastPageService>(LastPageService);
  const currentPagePersistedState = currentPageService.persistedState ?? new LastPageState();

  const {
    selected: selectedOperator,
    fetchOne,
    getPaginated,
    data,
    goToList,
    loading,
    loadingDetail,
    create,
    save
  } = useListDetailPaginatedViewModel(
    OperatorRepository as unknown as ObjectType<IRepositoryPaginated<Operator, ListOperatorsPaginatedRequest>>
  );
  const getOperatorsPaginated = useRef(getPaginated).current;
  const { getCurrentStatus } = useCheckStatus();

  return {
    loading,
    loadingDetail,
    selectedOperator,
    operatorData: data,
    currentPageService,
    currentPagePersistedState,
    goToCreate: create,
    goToDetail: fetchOne,
    goToList,
    save,
    getOperatorsPaginated,
    getCurrentStatus
  };
};
