import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import { GridColDef } from "@mui/x-data-grid";
import { filterValueTypes } from "@constants";
import { CustomTooltipCell } from "@components/tooltip/CustomTooltipCell";
import { CompanyDTOLicenceEnum, CompanyDTOWarehousePolicyEnum } from "@clients/aggrego-proxy";
import { TitlePopover } from "@components/master-data-view/card/components/title/title-popover";

export const adminBoardColumns = (
  t: (text: string) => string,
  handleEdit: (id: string) => void,
  mdMediaQuery: boolean
): GridColDef[] => {
  return [
    {
      flex: 1,
      field: "companyName",
      headerName: t("admin.board.company.name"),
      disableColumnMenu: true,
      minWidth: mdMediaQuery ? undefined : 40,
      type: "string",
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1,
      field: "tenantName",
      headerName: t("admin.board.tenant"),
      disableColumnMenu: true,
      minWidth: mdMediaQuery ? undefined : 40,
      type: "string",
      sortable: false,
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1,
      field: "address",
      headerName: t("admin.board.company.address"),
      disableColumnMenu: true,
      minWidth: mdMediaQuery ? undefined : 40,
      type: "string",
      valueGetter: ({ row }) => {
        return row.address.replaceAll("±", " ");
      },
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1,
      field: "fiscalId",
      headerName: t("admin.board.fiscal.id"),
      disableColumnMenu: true,
      minWidth: mdMediaQuery ? undefined : 40,
      type: "string",
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 0.8,
      field: "status",
      headerName: t("admin.board.status"),
      disableColumnMenu: true,
      minWidth: mdMediaQuery ? undefined : 40,
      type: "string",
      align: "center",
      renderCell: data => {
        return (
          <>{data.row.active ? <Icon icon="task-ok" color={COLORS.success} /> : <Icon icon="task-ko" color={COLORS.alert} />}</>
        );
      }
    },
    {
      flex: 1,
      field: "created",
      headerName: t("admin.board.creation.date"),
      disableColumnMenu: true,
      minWidth: 100,
      type: "range-date" as filterValueTypes,
      sortable: false,
      valueGetter: ({ row }) => {
        const parseDate = new Date(row.created.toString());
        return row.created
          ? parseDate.toLocaleString("es-ES", {
              localeMatcher: "lookup",
              month: "numeric",
              day: "numeric",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit"
            })
          : "-";
      },
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1,
      field: "modified",
      headerName: t("admin.board.modification.date"),
      disableColumnMenu: true,
      minWidth: 100,
      type: "range-date" as filterValueTypes,
      sortable: false,
      valueGetter: ({ row }) => {
        const parseDate = new Date(row.modified.toString());
        return row.modified
          ? parseDate.toLocaleString("es-ES", {
              localeMatcher: "lookup",
              month: "numeric",
              day: "numeric",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit"
            })
          : "-";
      },
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1.2,
      field: "warehousePolicy",
      headerName: t("admin.board.warehouse.policy"),
      disableColumnMenu: true,
      minWidth: 100,
      type: "string",
      sortable: false,
      valueGetter: ({ row }) => {
        let policy = "";
        switch (row.warehousePolicy) {
          case CompanyDTOWarehousePolicyEnum.Lifo:
            policy = t("LIFO");
            break;
          case CompanyDTOWarehousePolicyEnum.StrictFifo:
            policy = t("STRICT_FIFO");
            break;
          case CompanyDTOWarehousePolicyEnum.TimeIntervalFifo:
            policy = t("TIME_INTERVAL_FIFO");
            break;
        }
        return policy;
      },
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1,
      field: "license",
      headerName: t("admin.board.license"),
      disableColumnMenu: true,
      minWidth: mdMediaQuery ? undefined : 40,
      type: "string",
      sortable: false,
      valueGetter: ({ row }) => {
        let licence = "";
        switch (row.licence) {
          case CompanyDTOLicenceEnum.Im:
            licence = t("IM");
            break;
          case CompanyDTOLicenceEnum.Wm:
            licence = t("WM");
            break;
          case CompanyDTOLicenceEnum.WmWithContainer:
            licence = t("WM_WITH_CONTAINER");
            break;
        }
        return licence;
      },
      renderCell: params => <CustomTooltipCell>{params.value}</CustomTooltipCell>
    },
    {
      flex: 1,
      field: "actions",
      headerName: t("admin.board.actions"),
      disableColumnMenu: true,
      sortable: false,
      align: "right",
      headerAlign: "right",
      minWidth: mdMediaQuery ? undefined : 40,
      renderCell: data => {
        return (
          <>
            <TitlePopover handleEdit={() => handleEdit(data.row.tenant)} />
          </>
        );
      }
    }
  ];
};
