import { COLORS } from "@theme";
import Icon from "@components/icons/icon";
import { hierarchyReportStyles } from "../../hierarchy-report.Styles";
import { Breadcrumbs, Theme, Typography, useMediaQuery } from "@mui/material";
import { useHierarchyBreadcrumbsViewModel } from "./useHierarchyBreadcrumbsViewModel";
import { ProductDTO } from "@clients/aggrego-proxy";

export const HierarchyBreadcrumbs = (props: { currentProduct: ProductDTO }) => {
  const { currentProduct } = props;
  const { handleClickParent, currentParents } = useHierarchyBreadcrumbsViewModel(currentProduct);

  const { breadcrumbs_text } = hierarchyReportStyles();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up(750), { noSsr: true });

  return (
    <Breadcrumbs separator={<Icon icon="chevron-right" color={COLORS.gray} />} itemsBeforeCollapse={matches ? 5 : 0}>
      {currentParents.map(parent => (
        <Typography
          key={parent.id}
          className="hover"
          color={COLORS.gray}
          sx={breadcrumbs_text}
          onClick={() => handleClickParent(parent.id)}>
          {parent.value}
        </Typography>
      ))}
    </Breadcrumbs>
  );
};
