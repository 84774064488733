import { useState } from "react";
import Icon from "@components/icons/icon";
import { Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import { homeTabStyles } from "../../home/homeTabsStyles";

export const InventoryMaterialTabs = (props: { onClick: (id: string) => void }) => {
  const { onClick } = props;
  const data = [tabsStatus.options[0], tabsStatus.options[1], tabsStatus.options[2]];

  const [selectedTab, setSelectedTab] = useState(data[0].value);

  const { t } = useTranslation();
  const styles = homeTabStyles;

  return (
    <Tabs
      id="inventory-materials-tabs"
      value={selectedTab === "" ? false : selectedTab}
      TabIndicatorProps={{
        style: {
          display: "none"
        }
      }}>
      {data.map((tab, index) => {
        return (
          <Tab
            id={`tab-${index}`}
            value={tab.value}
            sx={selectedTab === tab.value ? styles.logistic_tabs_true : styles.logistic_tabs_false}
            key={tab.value}
            label={t(tab.name)}
            icon={tab.icon}
            iconPosition="start"
            onClick={() => {
              setSelectedTab(tab.value);
              onClick(tab.value);
            }}
          />
        );
      })}
    </Tabs>
  );
};

export const tabsStatus = {
  options: [
    {
      name: "table.status.all",
      value: "*",
      icon: ""
    },
    {
      name: "table.status.correct",
      value: "correct",
      icon: <Icon icon="task-ok" style={{ width: "1rem", height: "1rem" }} />
    },
    {
      name: "table.status.failed",
      value: "failed",
      icon: <Icon icon="task-ko" style={{ width: "1rem", height: "1rem", padding: 0 }} />
    }
  ]
};
