import { CREATE_ID } from "@constants";
import { useEffect, useState } from "react";
import Operator from "@domain/model/Operator";

interface IOperatorForm {
  selectedOperator: Operator;
}

export const useOperatorForm = (props: IOperatorForm) => {
  const INITIAL_ERROR_MAP = new Map([
    ["operator-code", false],
    ["operator-firstName", false],
    ["operator-firstSurname", false],
    ["operator-secondSurname", false],
    ["operator-password", false],
    ["operator-confirmPassword", false]
  ]);
  const { selectedOperator } = props;
  const [modifiedOperator, setModifiedOperator] = useState<Operator>(selectedOperator);

  const [passwordValue, setPasswordValue] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const [isSamePassword, setIsSamePassword] = useState(false);
  const [formErrors, setFormErrors] = useState<Map<string, boolean>>(INITIAL_ERROR_MAP);

  useEffect(() => {
    setModifiedOperator(selectedOperator);
    if (selectedOperator.id === CREATE_ID) {
      setModifiedOperator({
        ...modifiedOperator,
        id: CREATE_ID,
        code: "",
        firstName: "",
        firstSurname: "",
        secondSurname: "",
        password: ""
      });
    }
    setFormErrors(INITIAL_ERROR_MAP);
  }, [selectedOperator]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nameField = event.target.name;
    if (nameField !== "password" && nameField !== "confirmPassword") {
      setModifiedOperator({ ...modifiedOperator, [nameField]: event.target.value });
    } else {
      if (nameField === "password") {
        setPasswordValue(event.target.value);
      }
      if (nameField === "confirmPassword") {
        setConfirmPasswordValue(event.target.value);
      }
    }
  };

  const validateFormValues = (isCreation: boolean) => {
    const firstNameValid =
      modifiedOperator.firstName !== null &&
      modifiedOperator.firstName !== undefined &&
      modifiedOperator.firstName !== "" &&
      modifiedOperator.firstName.trim() !== "";
    const firstSurnameValid =
      modifiedOperator.firstSurname !== null &&
      modifiedOperator.firstSurname !== undefined &&
      modifiedOperator.firstSurname !== "" &&
      modifiedOperator.firstSurname.trim() !== "";
    const codeValid =
      modifiedOperator.code !== null &&
      modifiedOperator.code !== undefined &&
      modifiedOperator.code !== "" &&
      modifiedOperator.code.trim() !== "";
    const passwordValid = isCreation
      ? passwordValue !== null && passwordValue !== undefined && passwordValue.trim() !== ""
      : true;
    const passwordConfirmValid = isCreation
      ? confirmPasswordValue !== null && confirmPasswordValue !== undefined && confirmPasswordValue.trim() !== ""
      : true;
    const passwordEqualValid = passwordValue === confirmPasswordValue;

    setFormErrors(
      new Map([
        ["operator-code", !codeValid],
        ["operator-firstName", !firstNameValid],
        ["operator-firstSurname", !firstSurnameValid],
        ["operator-password", !passwordValid],
        ["operator-confirm-password", !passwordConfirmValid],
        ["operator-equal-password", !passwordEqualValid]
      ])
    );
    return firstNameValid && firstSurnameValid && codeValid && passwordValid && passwordConfirmValid && passwordEqualValid;
  };

  useEffect(() => {
    isSamePassword && setModifiedOperator({ ...modifiedOperator, password: isSamePassword ? passwordValue : "" });
  }, [isSamePassword]);

  useEffect(() => {
    setIsSamePassword(passwordValue === confirmPasswordValue);
  }, [passwordValue, confirmPasswordValue]);

  return {
    modifiedOperator,
    onChange,
    validateFormValues,
    formErrors,
    confirmPasswordValue,
    setConfirmPasswordValue,
    passwordValue,
    setPasswordValue,
    isSamePassword
  };
};
