import { COLORS } from "@theme";
import Icon from "../icons/icon";
import { Trans } from "react-i18next";
import { useResolve } from "@movicoders/di";
import { snackbarStyles } from "./snackbarStyles";
import { SNACKBAR, SnackbarService } from "@movicoders/ui";
import { Alert, AlertColor, Snackbar as MUISnackbar } from "@mui/material";

export const Snackbar = () => {
  const {
    state: { isVisible, message, severity },
    hide
  } = useResolve<SnackbarService>(SNACKBAR);

  const styles = snackbarStyles;

  const alertBackgroundColor = () => {
    if (severity === "success") return COLORS.snackbarSuccess;
    if (severity === "error") return COLORS.snackbarError;
    if (severity === "warning") {
      return COLORS.snackbarWarning;
    }
    return COLORS.snackbarInfo;
  };

  return (
    <MUISnackbar open={isVisible} id="snackbar" onClick={hide} onClose={hide}>
      <Alert
        id="snackbar-alert"
        iconMapping={{
          success: <Icon icon="task-ok" color="white" />,
          error: <Icon icon="task-ko" color="white" />,
          warning: <Icon icon="warning" color="white" />,
          info: <Icon icon="info" color="white" />
        }}
        severity={severity as AlertColor}
        sx={{ backgroundColor: alertBackgroundColor(), ...styles.solid_alert }}
        action={<Icon icon="close" color="white" size={24} />}>
        <Trans i18nKey={message} />
      </Alert>
    </MUISnackbar>
  );
};
