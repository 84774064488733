import Tile from "@domain/model/Tile";
import Zone from "@domain/model/Zone";
import { User } from "@domain/model/User";
import { useEffect, useState } from "react";
import Location from "@domain/model/Location";
import Material from "@domain/model/Material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ContainerDTO } from "@clients/aggrego-proxy";
import { useCheckLicense } from "@hooks/useCheckLicense";
import { SearchDataEnum } from "@domain/model/SearchDataEnum";
import { HOME_AUTOCOMPLETE_DEBOUNCE_TIME, ROUTER_PATHS } from "@constants";
import { useHandleSelectedWarehouse } from "@hooks/useHandleSelectedWarehouse";
import { ResultData, FoundResultData } from "../interfaces/foundResultData-interface";
import { useHomeAutocompleteRepositories } from "./home-autocomplete-helpers/useHomeAutocompleteRepositories";
import { HomeAutocompleteViewModelHelper } from "./home-autocomplete-helpers/home-autocomplete-view-model-helper";

export const useHomeAutocompleteViewModel = (preselectedValue?: ResultData) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedWarehouseCode } = useHandleSelectedWarehouse();

  const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);
  const [itemSelected, setItemSelected] = useState<Material | Location | User | ContainerDTO | undefined>();
  const emptyResultData: ResultData = { typeData: SearchDataEnum.DEFAULT, idData: "", nameData: "" };
  //Options
  const [options, setOptions] = useState<ResultData[]>([]);
  const [dataPlaceholder, setDataPlaceholder] = useState<ResultData>(emptyResultData);
  const [loading, setLoading] = useState(false);
  //To detail view
  const [finalPackage, setFinalPackage] = useState<FoundResultData>();

  const { materialRepository, locationRepository, tileRepository, zoneRepository, userRepository, containerRepository } =
    useHomeAutocompleteRepositories();

  HomeAutocompleteViewModelHelper({ setDataPlaceholder, preselectedValue });

  const { checkLicense } = useCheckLicense();

  // Materials

  const obtainMaterialPaginated = async (limit: number, offset: number, searchValue: string) => {
    await materialRepository
      .getPaginated({
        xTenantId: "",
        limit: limit,
        offset: offset,
        fullDTO: false,
        customMaterialFilter: { searchText: searchValue }
      })
      .then(result => {
        const filterMaterials = result.content?.filter((item: Material) => {
          return item.code?.toLowerCase().includes(searchValue.toLowerCase());
        });
        filterMaterials?.forEach((item: Material) => {
          setOptions(prevOptions => [
            ...prevOptions,
            { typeData: t(SearchDataEnum.MATERIAL), idData: item.id ?? "", nameData: item.code ?? "" }
          ]);
        });
      });
  };

  // Locations

  const [tile, setTile] = useState<Tile>();
  const [zone, setZone] = useState<Zone>();

  const obtainLocationPaginated = async (limit: number, offset: number, searchValue: string) => {
    await locationRepository
      .getPaginated({
        xTenantId: "",
        limit: limit,
        offset: offset,
        customLocationsFilterDTO: { searchText: searchValue }
      })
      .then(result => {
        const filterLocations = result.content?.filter((item: Location) => {
          return item.code?.toLowerCase().includes(searchValue.toLowerCase());
        });
        filterLocations?.forEach((item: Location) => {
          setOptions(prevOptions => [
            ...prevOptions,
            { typeData: t(SearchDataEnum.LOCATION), idData: item.id ?? "", nameData: item.code ?? "" }
          ]);
        });
      });
  };

  // Users

  const obtainUserPaginated = async (limit: number, offset: number, searchValue: string) => {
    await userRepository
      .getPaginated({
        xTenantId: "",
        limit: limit,
        offset: offset,
        filterDTO: { searchText: searchValue }
      })
      .then(result => {
        const filterUsers = result.content?.filter((item: User) => {
          return item.username?.toLowerCase().includes(searchValue.toLowerCase());
        });
        filterUsers?.forEach((item: User) => {
          setOptions(prevOptions => [
            ...prevOptions,
            { typeData: t(SearchDataEnum.USER), idData: item.credentialsId ?? "", nameData: item.username ?? "" }
          ]);
        });
      });
  };

  // Containers

  const obtainContainerPaginated = async (limit: number, offset: number, searchValue: string) => {
    await containerRepository
      .getPaginated({
        xTenantId: "",
        limit: limit,
        offset: offset,
        warehouseCode: selectedWarehouseCode
      })
      .then(result => {
        const filterContainers = result.content?.filter((item: ContainerDTO) => {
          return item.code?.toLowerCase().includes(searchValue.toLowerCase());
        });
        filterContainers?.forEach((item: ContainerDTO) => {
          setOptions(prevOptions => [
            ...prevOptions,
            { typeData: t(SearchDataEnum.CONTAINER), idData: item.id ?? "", nameData: item.code ?? "" }
          ]);
        });
      });
  };

  const handleSearchByLicense = (textValue: string) => {
    const materialPromise = obtainMaterialPaginated(10, 0, textValue);
    const locationPromise =
      (checkLicense("WM_WITH_CONTAINER") || checkLicense("WM")) && obtainLocationPaginated(10, 0, textValue);
    const userPromise = obtainUserPaginated(10, 0, textValue);
    const containerPromise = checkLicense("WM_WITH_CONTAINER") && obtainContainerPaginated(10, 0, textValue);

    if (checkLicense("WM_WITH_CONTAINER")) {
      return [materialPromise, locationPromise, userPromise, containerPromise];
    } else if (checkLicense("WM")) {
      return [materialPromise, locationPromise, userPromise];
    } else {
      return [materialPromise, userPromise];
    }
  };

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const textValue = event.target.value;
    setOptions([]);
    setItemSelected(undefined);
    setTile(undefined);
    setZone(undefined);
    setFinalPackage(undefined);
    setDataPlaceholder({ ...dataPlaceholder, nameData: textValue });
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    const newDebounceTimer = setTimeout(() => {
      if (textValue.length >= 3) {
        if (textValue !== "") {
          setLoading(true);
          Promise.all(handleSearchByLicense(textValue)).then(() => {
            setLoading(false);
          });
        }
      }
    }, HOME_AUTOCOMPLETE_DEBOUNCE_TIME);
    setDebounceTimer(newDebounceTimer);
  };

  const onChangeAutocomplete = async (value: ResultData) => {
    setDataPlaceholder(value);
    setTile(undefined);
    setZone(undefined);
    switch (value.typeData) {
      case t(SearchDataEnum.MATERIAL):
        setItemSelected(await materialRepository.getById(value.idData));
        break;
      case t(SearchDataEnum.LOCATION):
        setItemSelected(
          await locationRepository.getById(value.idData).then(locationResult =>
            tileRepository.getByIdFalseDTO(locationResult.tileId ?? "").then(async tileResult => {
              setTile(tileResult);
              if (tileResult.zoneId) {
                setZone(await zoneRepository.getById(tileResult.zoneId ?? ""));
              } else {
                setZone(undefined);
              }
              return locationResult;
            })
          )
        );
        break;
      case t(SearchDataEnum.USER):
        setItemSelected(await userRepository.getInfoUser(value.idData));
        break;
      case t(SearchDataEnum.CONTAINER):
        setItemSelected(await containerRepository.getById({ id: value.idData, warehouseCode: selectedWarehouseCode }));
        break;
    }
  };

  useEffect(() => {
    setFinalPackage({ itemSelected: itemSelected, tile: tile, zone: zone });
  }, [itemSelected, tile, zone]);

  const goToSearchView = () => {
    if (itemSelected) {
      navigate(ROUTER_PATHS.dashboardSearch, {
        state: { finalPackage: finalPackage, preselectedValue: dataPlaceholder }
      });
    }
  };

  return { options, dataPlaceholder, finalPackage, loading, handleSearchInputChange, onChangeAutocomplete, goToSearchView };
};
