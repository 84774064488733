export const CAPACITY_COLORS = {
  green: "#13cf24",
  yellow: "#cfc44c",
  red: "#cf1313",
  selectedblue: "#2626cf",
  selectedorange: "#C5931E",
  materialyellow: "#f0f000",
  lightgreen: "#88ff88",
  lightyellow: "#f8f888",
  lightred: "#ff8888"
};

export const DEFAULT_COLORS = {
  grey: "#dbdbdb",
  white: "#ffffff",
  darkgrey: "#404040"
};
