/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InventoryRegistryDTO
 */
export interface InventoryRegistryDTO {
    /**
     * 
     * @type {string}
     * @memberof InventoryRegistryDTO
     */
    consolidated?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryRegistryDTO
     */
    containerCode?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryRegistryDTO
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryRegistryDTO
     */
    error?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryRegistryDTO
     */
    inventoryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryRegistryDTO
     */
    inventoryDate?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryRegistryDTO
     */
    locationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryRegistryDTO
     */
    materialCode?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryRegistryDTO
     */
    operatorCode?: string;
    /**
     * 
     * @type {number}
     * @memberof InventoryRegistryDTO
     */
    qty?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryRegistryDTO
     */
    user?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryRegistryDTO
     */
    warehouseCode?: string;
}

/**
 * Check if a given object implements the InventoryRegistryDTO interface.
 */
export function instanceOfInventoryRegistryDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InventoryRegistryDTOFromJSON(json: any): InventoryRegistryDTO {
    return InventoryRegistryDTOFromJSONTyped(json, false);
}

export function InventoryRegistryDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryRegistryDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'consolidated': !exists(json, 'consolidated') ? undefined : json['consolidated'],
        'containerCode': !exists(json, 'containerCode') ? undefined : json['containerCode'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'inventoryCode': !exists(json, 'inventoryCode') ? undefined : json['inventoryCode'],
        'inventoryDate': !exists(json, 'inventoryDate') ? undefined : json['inventoryDate'],
        'locationCode': !exists(json, 'locationCode') ? undefined : json['locationCode'],
        'materialCode': !exists(json, 'materialCode') ? undefined : json['materialCode'],
        'operatorCode': !exists(json, 'operatorCode') ? undefined : json['operatorCode'],
        'qty': !exists(json, 'qty') ? undefined : json['qty'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'warehouseCode': !exists(json, 'warehouseCode') ? undefined : json['warehouseCode'],
    };
}

export function InventoryRegistryDTOToJSON(value?: InventoryRegistryDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'consolidated': value.consolidated,
        'containerCode': value.containerCode,
        'created': value.created,
        'error': value.error,
        'inventoryCode': value.inventoryCode,
        'inventoryDate': value.inventoryDate,
        'locationCode': value.locationCode,
        'materialCode': value.materialCode,
        'operatorCode': value.operatorCode,
        'qty': value.qty,
        'user': value.user,
        'warehouseCode': value.warehouseCode,
    };
}

