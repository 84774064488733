import { COLORS, MEDIA } from "@theme";
import Icon from "@components/icons/icon";
import { useTranslation } from "react-i18next";
import { stepperStyles } from "../stepper-Styles";
import { CompanyCreateDTO } from "../company-create-model";
import { Avatar, Grid, Theme, Typography, useMediaQuery } from "@mui/material";

export const StepPreview = (props: { companyCreate: CompanyCreateDTO }) => {
  const { companyCreate } = props;
  const styles = stepperStyles();
  const matchesMd = useMediaQuery((theme: Theme) => theme.breakpoints.up(MEDIA.medium), { noSsr: true });
  const { t } = useTranslation();

  return (
    <Grid container flexDirection="column" className="masterdata-box" rowSpacing={2}>
      <Grid item>
        <Typography sx={styles.categories_typography}>{t("company.data")}</Typography>
      </Grid>

      <Grid item>
        <Grid container alignItems="center" spacing={{ xs: 1.5 }}>
          <Grid item sx={styles.company_data_blue} xs={12} sm>
            {typeof companyCreate.image !== "string" && (
              <Avatar
                id="company-avatar"
                alt="Company logo"
                src={URL.createObjectURL(companyCreate.image as unknown as Blob)}
                sx={{ width: matchesMd ? "15rem" : "12rem", height: matchesMd ? "15rem" : "12rem" }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Typography sx={styles.categories_typography}>{t("company.data")}</Typography>
      </Grid>

      <Grid item>
        <Grid container alignItems="center" spacing={{ xs: 1.5 }}>
          <Grid item display="flex" xs={12} md={3}>
            <Icon icon="company" color={COLORS.primary} />
            <Typography sx={styles.item_typography}>{t("step-master-data-company-name")}</Typography>
          </Grid>
          <Grid item sx={styles.company_data_blue} xs={12} sm>
            {companyCreate.companyName ?? t("admin.board.no.company")}
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container alignItems="center" spacing={{ xs: 1.5 }}>
          <Grid item display="flex" xs={12} md={3}>
            <Icon icon="company" color={COLORS.primary} />
            <Typography sx={styles.item_typography}>{t("step-master-data-fiscalid")}</Typography>
          </Grid>
          <Grid item sx={styles.company_data_blue} xs={12} sm>
            {companyCreate.fiscalId ?? t("admin.board.no.company")}
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container alignItems="center" spacing={{ xs: 1.5 }}>
          <Grid item display="flex" xs={12} md={3}>
            <Icon icon="company" color={COLORS.primary} />
            <Typography sx={styles.item_typography}>{t("step-master-data-company-address")}</Typography>
          </Grid>
          <Grid item sx={styles.company_data_blue} xs={12} sm>
            {companyCreate.address?.replaceAll("±", " ") ?? t("admin.board.no.company")}
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Typography sx={styles.categories_typography}>{t("step-configuration")}</Typography>
      </Grid>
      <Grid item>
        <Grid container alignItems="center" spacing={{ xs: 1.5 }}>
          <Grid item display="flex" xs={12} md={3}>
            <Icon icon="company" color={COLORS.primary} />
            <Typography sx={styles.item_typography}>{t("step-configuration-company-license")}</Typography>
          </Grid>
          <Grid item sx={styles.company_data_blue} xs={12} sm>
            {t(`${companyCreate.licence}`) ?? t("admin.board.no.company")}
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container alignItems="center" spacing={{ xs: 1.5 }}>
          <Grid item display="flex" xs={12} md={3}>
            <Icon icon="company" color={COLORS.primary} />
            <Typography sx={styles.item_typography}>{t("step-configuration-company-warehouse-policy")}</Typography>
          </Grid>
          <Grid item sx={styles.company_data_blue} xs={12} sm>
            {t(`${companyCreate.warehousePolicy}`) ?? t("admin.board.no.company")}
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Typography sx={styles.categories_typography}>{t("step-create-user-admin-data")}</Typography>
      </Grid>
      <Grid item>
        <Grid container alignItems="center" spacing={{ xs: 1.5 }}>
          <Grid item display="flex" xs={12} md={3}>
            <Icon icon="company" color={COLORS.primary} />
            <Typography sx={styles.item_typography}>{t("step-master-data-company-name")}</Typography>
          </Grid>
          <Grid item sx={styles.company_data_blue} xs={12} sm>
            {`${companyCreate.firstName} ${companyCreate.surname}`}
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container alignItems="center" spacing={{ xs: 1.5 }}>
          <Grid item display="flex" xs={12} md={3}>
            <Icon icon="company" color={COLORS.primary} />
            <Typography sx={styles.item_typography}>{t("step-create-user-email")}</Typography>
          </Grid>
          <Grid item sx={styles.company_data_blue} xs={12} sm>
            {companyCreate.email ?? t("admin.board.no.company")}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography sx={styles.categories_typography}>{t("step-create-user-access-data")}</Typography>
      </Grid>
      <Grid item>
        <Grid container alignItems="center" sx={{ height: 60 }} spacing={{ xs: 1.5 }}>
          <Grid item display="flex" xs={12} md={3}>
            <Icon icon="company" color={COLORS.primary} />
            <Typography sx={styles.item_typography}>{t("step-create-user-username")}</Typography>
          </Grid>
          <Grid item sx={styles.company_data_blue} xs={12} sm>
            {companyCreate.username}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
