import { COLORS } from "@theme";
import { useState } from "react";
import Icon from "@components/icons/icon";
import { useTranslation } from "react-i18next";
import Collapse from "@mui/material/Collapse/Collapse";
import { ProductChild } from "@domain/model/ProductChild";
import { Chip, Grid, Paper, Typography } from "@mui/material";
import { hierarchyReportStyles } from "../hierarchy-report.Styles";

export const HierarchyTrackingChildrenCard = (props: {
  data: ProductChild;
  selectedCard: string;
  setSelectedCard: (value: string) => void;
  onChangeHierarchyLevel: (id?: string) => void;
  hasVersionLabel: boolean;
}) => {
  const { t } = useTranslation();
  const { data, selectedCard, setSelectedCard, onChangeHierarchyLevel, hasVersionLabel } = props;
  const [openSide, setOpenSide] = useState(false);

  const styles = hierarchyReportStyles();

  return (
    <Grid item xs={12} paddingTop={1} display="flex" justifyContent="flex-end">
      <Collapse
        onMouseEnter={() => setOpenSide(true)}
        onMouseLeave={() => setOpenSide(false)}
        in={openSide}
        orientation="horizontal"
        timeout={200}
        sx={styles.hierarchy_children_collapse}>
        <Grid paddingX="0.7rem" paddingY="1.5rem" display="flex" alignItems="center" id="hierarchy-children-collapse-content">
          <Icon icon="view" color={COLORS.secondary} onClick={() => onChangeHierarchyLevel(data.id)} />
        </Grid>
      </Collapse>
      <Grid item xs={11}>
        <Paper
          id={`hierarchy-card-children-${data.id}`}
          data-cy="hierarchy-card-children"
          onClick={() => {
            setSelectedCard(data.id ?? "");
          }}
          onMouseEnter={() => setOpenSide(true)}
          onMouseLeave={() => setOpenSide(false)}
          sx={{
            border: selectedCard === data.id ? "2px solid " + COLORS.secondaryHighlight : "none",
            borderLeft: "8px solid transparent",
            ...styles.hierarchy_children_product
          }}>
          <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
            <Grid item xs={12} md={3}>
              <Typography variant="cardlabel">{t("products.serial")}</Typography>
              <Typography
                variant="cardvalue"
                data-cy="hierarchy-children-batch"
                data-serial-number={`child-${data.serialNumber}`}>
                {data.serialNumber !== "" ? data.serialNumber : "-"}
              </Typography>
            </Grid>
            {data.materialCode && (
              <Grid item xs={12} md={3}>
                <Typography variant="cardlabel">{t("products.material")}</Typography>
                <Typography variant="cardvalue">{data.materialCode}</Typography>
              </Grid>
            )}
            <Grid item xs={12} md={3}>
              <Typography variant="cardlabel">{hasVersionLabel ? t("products.version") : t("products.batch")}</Typography>
              <Typography variant="cardvalue" data-cy="hierarchy-children-batch">
                {data.batch !== "" ? data.batch : "-"}
              </Typography>
            </Grid>
            {data.qty && (
              <Grid item xs={12} md={2}>
                <Typography variant="cardlabel">{t("products.qty")}</Typography>
                <Typography variant="cardvalue">
                  {data.qty} {data.uom}
                </Typography>
              </Grid>
            )}
            {data.state && (
              <Grid item xs={12} md={1}>
                <Typography variant="cardlabel">{t("products.state")}</Typography>
                <Chip
                  className={`product-${data.state.toLowerCase()}`}
                  size="small"
                  label={data.state && t("products.state." + data.state.toLowerCase())}
                  sx={{ width: "6.75rem" }}
                />
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
