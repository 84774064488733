import { Scene, Object3D, Mesh, PlaneGeometry, MeshBasicMaterial, DoubleSide, Vector3 } from "three";
import { boxExists, getAllNear, getByZone } from "../../box-finders";
import { DEFAULT_PLANE_GEOMETRY } from "../../../constants/default-geometries";
import { IEditionStatesStore } from "../../../stores/edition-states-store/IEditionStatesStore";
import { useEditionStatesStore } from "../../../stores/edition-states-store/edition-states-store";
import { useResolve } from "@movicoders/di";
import { ICommon3DObjectsStore } from "../../../stores/common-3d-objects-store/ICommon3DObjectsStore";
import { useCommon3DObjectsStore } from "../../../stores/common-3d-objects-store/common-3d-objects-store";
import { IDialogsStatesStore } from "../../../stores/dialogs-states-store/IDialogsStatesStore";
import { useDialogsStatesStore } from "../../../stores/dialogs-states-store/dialogs-states-store";
import { useSnackbar } from "@movicoders/ui";
import { useTranslation } from "react-i18next";

export const useClickControlsHandlers = (scene: Scene) => {
  const {
    getSelectionType,
    setModifyingWH,
    setModifyMinMax,
    setModifyMaxLevel
  } = useResolve<IEditionStatesStore>(useEditionStatesStore);
  const {
    getPivotControlsContent,
    getHighlightMesh,
    getPivotControls,
    setTilesBeingModified,
    setStartPoint,
    setEndPoint
  } = useResolve<ICommon3DObjectsStore>(useCommon3DObjectsStore);
  const { handleOpenDeleteBoxDialog } = useResolve<IDialogsStatesStore>(useDialogsStatesStore);
  const { t } = useTranslation();
  const { show } = useSnackbar();

  const createEditPoints = (posX: number, posY: number, posZ: number): { startPoint: Mesh; endPoint: Mesh } => {
    const currentEditLevel = new Mesh(DEFAULT_PLANE_GEOMETRY);
    currentEditLevel.name = "currentLevel";
    currentEditLevel.visible = false;
    setEndPoint(currentEditLevel);
    currentEditLevel.position.copy(new Vector3(posX, posY, posZ));

    const editStartPoint = new Mesh(DEFAULT_PLANE_GEOMETRY);
    editStartPoint.name = "editStart";
    editStartPoint.visible = false;
    editStartPoint.position.copy(new Vector3(posX, posY, posZ));
    setStartPoint(editStartPoint);

    return { startPoint: editStartPoint, endPoint: currentEditLevel };
  };

  const setArrayMinMax = (elements: Object3D[]) => {
    const maxX = Math.max(...elements.map(g => g.userData.tile.x));
    const minX = Math.min(...elements.map(g => g.userData.tile.x));
    const maxZ = Math.max(...elements.map(g => g.userData.tile.z));
    const minZ = Math.min(...elements.map(g => g.userData.tile.z));
    setModifyMinMax({ maxX: maxX, minX: minX, maxZ: maxZ, minZ: minZ });
  }

  const startTileCreation = () => {
    const creationGeometry = new PlaneGeometry(1, 1);
    const creationMaterial = new MeshBasicMaterial({ side: DoubleSide, color: "#0000ff" });

    const startPoint = new Mesh(creationGeometry, creationMaterial);
    startPoint.name = "createStart";
    startPoint.rotateX(-Math.PI / 2);
    scene.add(startPoint);
    startPoint.position.set(getHighlightMesh().position.x, 0, getHighlightMesh().position.z);
    setStartPoint(startPoint);

    const endPoint = new Mesh(creationGeometry, creationMaterial);
    endPoint.name = "createEnd";
    endPoint.rotateX(-Math.PI / 2);
    scene.add(endPoint);
    getPivotControlsContent().children.push(endPoint);
    endPoint.position.set(getHighlightMesh().position.x, 0, getHighlightMesh().position.z);
    setEndPoint(endPoint);

    getPivotControls().matrix.setPosition(getHighlightMesh().position.x, 0, getHighlightMesh().position.z);
    setModifyingWH(true);
  };

  const startTileMovement = () => {
    let totalElements: Object3D[] = [];
    const object = boxExists(scene, getHighlightMesh())[0];

    if (getSelectionType() === "MULTIPLE") {
      getAllNear(scene, getHighlightMesh().position.x, getHighlightMesh().position.z, totalElements, object.userData.tile.templateId);
    } else if (getSelectionType() === "SINGLE") {
      totalElements.push(object);
    } else if (getSelectionType() === "BY_ZONE") {
      const zone = object.userData.tile.zoneId;
      if (zone !== undefined)
        totalElements = getByZone(scene, zone);
      else show(t("viewer.mode.zone.error"), "error");
    }

    if (totalElements.length > 0) {
      setArrayMinMax(totalElements)
      setTilesBeingModified(totalElements);
      getPivotControlsContent().children = totalElements;
      setModifyingWH(true);
    }
  };

  const deleteTile = () => {
    let totalElements: Object3D[] = [];
    const objectToRemove = boxExists(scene, getHighlightMesh())[0];

    if (getSelectionType() === "MULTIPLE") {
      getAllNear(scene, getHighlightMesh().position.x, getHighlightMesh().position.z, totalElements, objectToRemove.userData.tile.templateId);
    } else if (getSelectionType() === "SINGLE") {
      totalElements.push(objectToRemove)
    } else if (getSelectionType() === "BY_ZONE") {
      const zone = objectToRemove.userData.tile.zoneId;
      if (zone !== undefined)
        totalElements = getByZone(scene, zone);
      else show(t("viewer.mode.zone.error"), "error");
    }

    if (totalElements.length > 0) {
      handleOpenDeleteBoxDialog(totalElements.map(e => e.userData.tile).filter((t) => t.active), []);
      const hlMeshMaterial = getHighlightMesh().material as MeshBasicMaterial;
      hlMeshMaterial.color.setHex(0x00ff00);
    }
  };

  const startTileReduction = () => {
    let totalElements: Object3D[] = [];
    const currentReduceLevel = new Mesh(DEFAULT_PLANE_GEOMETRY);
    currentReduceLevel.name = "currentLevel";
    currentReduceLevel.visible = false;

    const objectToReduce = boxExists(scene, getHighlightMesh())[0];

    if (getSelectionType() === "MULTIPLE") {
      getAllNear(scene, getHighlightMesh().position.x, getHighlightMesh().position.z, totalElements, objectToReduce.userData.tile.templateId);
    } else if (getSelectionType() === "SINGLE") {
      totalElements.push(objectToReduce);
    } else if (getSelectionType() === "BY_ZONE") {
      const zone = objectToReduce.userData.tile.zoneId;
      if (zone !== undefined)
        totalElements = getByZone(scene, zone);
      else show(t("viewer.mode.zone.error"), "error");
    }

    if (totalElements.length > 0) {
      setTilesBeingModified(totalElements);

      const maxLevel = Math.max(...totalElements.map(g => g.userData.tile.maxY));
      setModifyMaxLevel(maxLevel);

      setEndPoint(currentReduceLevel);
      currentReduceLevel.position.copy(new Vector3(getHighlightMesh().position.x, maxLevel - 1, getHighlightMesh().position.z));
      getPivotControlsContent().children = [currentReduceLevel];

      setModifyingWH(true);
    }
  };

  const startTileEdition = () => {
    let totalElements: Object3D[] = [];
    const objectToEdit = boxExists(scene, getHighlightMesh())[0];

    if (getSelectionType() === "MULTIPLE") {
      getAllNear(scene, getHighlightMesh().position.x, getHighlightMesh().position.z, totalElements, objectToEdit.userData.tile.templateId);
    } else if (getSelectionType() === "SINGLE") {
      totalElements.push(objectToEdit);
    } else if (getSelectionType() === "BY_ZONE") {
      const zone = objectToEdit.userData.tile.zoneId;
      if (zone !== undefined)
        totalElements = getByZone(scene, zone);
      else show(t("viewer.mode.zone.error"), "error");
    }

    if (totalElements.length > 0) {
      setTilesBeingModified(totalElements);
      setArrayMinMax(totalElements);

      const maxLevel = Math.max(...totalElements.map(g => g.userData.tile.maxY));
      setModifyMaxLevel(maxLevel);

      const minLevel = Math.min(...totalElements.map(g => g.userData.tile.maxY));
      createEditPoints(
        getHighlightMesh().position.x,
        minLevel - 1,
        getHighlightMesh().position.z
      );

      getPivotControlsContent().children = totalElements;
      setModifyingWH(true);
    }
  };

  return {
    startTileCreation,
    startTileMovement,
    deleteTile,
    startTileReduction,
    startTileEdition
  }
};
