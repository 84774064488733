/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    credentialsId?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    firstSurname?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof Profile
     */
    logisticOperationsPermissions?: Set<ProfileLogisticOperationsPermissionsEnum>;
    /**
     * 
     * @type {Set<string>}
     * @memberof Profile
     */
    masterdataPermissions?: Set<ProfileMasterdataPermissionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    profileId?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof Profile
     */
    reportsPermissions?: Set<ProfileReportsPermissionsEnum>;
    /**
     * 
     * @type {Set<string>}
     * @memberof Profile
     */
    roles?: Set<ProfileRolesEnum>;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    secondSurname?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    status?: ProfileStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    tenant?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof Profile
     */
    trackAndTracePermissions?: Set<ProfileTrackAndTracePermissionsEnum>;
}


/**
 * @export
 */
export const ProfileLogisticOperationsPermissionsEnum = {
    InventoryMaterial: 'INVENTORY_MATERIAL',
    MaterialEntry: 'MATERIAL_ENTRY',
    MaterialExit: 'MATERIAL_EXIT'
} as const;
export type ProfileLogisticOperationsPermissionsEnum = typeof ProfileLogisticOperationsPermissionsEnum[keyof typeof ProfileLogisticOperationsPermissionsEnum];

/**
 * @export
 */
export const ProfileMasterdataPermissionsEnum = {
    Company: 'COMPANY',
    Customer: 'CUSTOMER',
    Family: 'FAMILY',
    FamilyType: 'FAMILY_TYPE',
    Location: 'LOCATION',
    Manufacturer: 'MANUFACTURER',
    Material: 'MATERIAL',
    Operator: 'OPERATOR',
    Owner: 'OWNER',
    Supplier: 'SUPPLIER',
    TileTemplate: 'TILE_TEMPLATE',
    User: 'USER',
    Warehouse: 'WAREHOUSE',
    WarehouseViewer: 'WAREHOUSE_VIEWER',
    Zone: 'ZONE'
} as const;
export type ProfileMasterdataPermissionsEnum = typeof ProfileMasterdataPermissionsEnum[keyof typeof ProfileMasterdataPermissionsEnum];

/**
 * @export
 */
export const ProfileReportsPermissionsEnum = {
    InventoryRegistry: 'INVENTORY_REGISTRY',
    InventoryReport: 'INVENTORY_REPORT',
    MovementsReport: 'MOVEMENTS_REPORT',
    RelabelReport: 'RELABEL_REPORT',
    StockReport: 'STOCK_REPORT',
    WarehouseStatus: 'WAREHOUSE_STATUS'
} as const;
export type ProfileReportsPermissionsEnum = typeof ProfileReportsPermissionsEnum[keyof typeof ProfileReportsPermissionsEnum];

/**
 * @export
 */
export const ProfileRolesEnum = {
    MobAdmin: 'MOB_ADMIN',
    MobUser: 'MOB_USER',
    PortalAdmin: 'PORTAL_ADMIN',
    PortalUser: 'PORTAL_USER',
    SuperAdmin: 'SUPER_ADMIN'
} as const;
export type ProfileRolesEnum = typeof ProfileRolesEnum[keyof typeof ProfileRolesEnum];

/**
 * @export
 */
export const ProfileStatusEnum = {
    Accepted: 'ACCEPTED',
    Denied: 'DENIED',
    Requested: 'REQUESTED'
} as const;
export type ProfileStatusEnum = typeof ProfileStatusEnum[keyof typeof ProfileStatusEnum];

/**
 * @export
 */
export const ProfileTrackAndTracePermissionsEnum = {
    Hierarchy: 'HIERARCHY',
    Product: 'PRODUCT'
} as const;
export type ProfileTrackAndTracePermissionsEnum = typeof ProfileTrackAndTracePermissionsEnum[keyof typeof ProfileTrackAndTracePermissionsEnum];


/**
 * Check if a given object implements the Profile interface.
 */
export function instanceOfProfile(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProfileFromJSON(json: any): Profile {
    return ProfileFromJSONTyped(json, false);
}

export function ProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): Profile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'credentialsId': !exists(json, 'credentialsId') ? undefined : json['credentialsId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'firstSurname': !exists(json, 'firstSurname') ? undefined : json['firstSurname'],
        'logisticOperationsPermissions': !exists(json, 'logisticOperationsPermissions') ? undefined : json['logisticOperationsPermissions'],
        'masterdataPermissions': !exists(json, 'masterdataPermissions') ? undefined : json['masterdataPermissions'],
        'profileId': !exists(json, 'profileId') ? undefined : json['profileId'],
        'reportsPermissions': !exists(json, 'reportsPermissions') ? undefined : json['reportsPermissions'],
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
        'secondSurname': !exists(json, 'secondSurname') ? undefined : json['secondSurname'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'tenant': !exists(json, 'tenant') ? undefined : json['tenant'],
        'trackAndTracePermissions': !exists(json, 'trackAndTracePermissions') ? undefined : json['trackAndTracePermissions'],
    };
}

export function ProfileToJSON(value?: Profile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'credentialsId': value.credentialsId,
        'email': value.email,
        'firstName': value.firstName,
        'firstSurname': value.firstSurname,
        'logisticOperationsPermissions': value.logisticOperationsPermissions === undefined ? undefined : Array.from(value.logisticOperationsPermissions as Set<any>),
        'masterdataPermissions': value.masterdataPermissions === undefined ? undefined : Array.from(value.masterdataPermissions as Set<any>),
        'profileId': value.profileId,
        'reportsPermissions': value.reportsPermissions === undefined ? undefined : Array.from(value.reportsPermissions as Set<any>),
        'roles': value.roles === undefined ? undefined : Array.from(value.roles as Set<any>),
        'secondSurname': value.secondSurname,
        'status': value.status,
        'tenant': value.tenant,
        'trackAndTracePermissions': value.trackAndTracePermissions === undefined ? undefined : Array.from(value.trackAndTracePermissions as Set<any>),
    };
}

